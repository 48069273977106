import { Mark, markInputRule, markPasteRule, mergeAttributes } from '@tiptap/core';
import { getMarkType } from '@tiptap/react';

import { getBackgroundColor, getHexWithOpacity } from '@components/tags/colors';
import { mergeDeep } from '@helpers/mergeDeep';

import * as Queries from '../helpers/queries';

interface HighlightOptions {
  HTMLAttributes: Record<string, any>;
}

export interface HighlightAttributes {
  color: string;
  highlightId: number;
  isTmp?: boolean;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    highlight: {
      setHighlight: (attributes: Partial<HighlightAttributes>) => ReturnType;
      toggleHighlight: (attributes: Partial<HighlightAttributes>) => ReturnType;
      unsetHighlight: (highlightId: number) => ReturnType;
      updateHighlight: (highlightId: number, attributes: Partial<HighlightAttributes>) => ReturnType;
    };
  }
}

export const inputRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))$/;
export const pasteRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))/g;

export const Highlight = Mark.create<HighlightOptions>({
  name: 'highlight',

  addOptions() {
    return {
      HTMLAttributes: {}
    };
  },

  addAttributes() {
    return {
      highlightId: {
        default: null
      },
      isTmp: {
        default: null
      },
      color: {
        default: 'default',
        parseHTML: (element) => element.getAttribute('data-color') || element.style.backgroundColor,
        renderHTML: (attributes) => {
          if (!attributes.color) {
            return {};
          }

          const bgColor = attributes.color.startsWith('#')
            ? getHexWithOpacity(attributes.color, 0.15)
            : getBackgroundColor(attributes.color, attributes.color === 'default' ? 0.8 : 0.15);

          return {
            'data-color': attributes.color,
            'data-highlight-id': attributes.highlightId,
            style: `background-color: ${bgColor}`
          };
        }
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'mark'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { range, highlightId, ...attrs } = mergeAttributes(this.options.HTMLAttributes, HTMLAttributes);
    return ['mark', attrs, 0];
  },

  addCommands() {
    return {
      setHighlight:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes);
        },
      toggleHighlight:
        (attributes) =>
        ({ editor, dispatch, tr, state, commands }) => {
          const pluginAttrs = mergeDeep(editor.getAttributes('highlight'), attributes) as HighlightAttributes;
          const { from, to, empty } = state.selection;

          if (dispatch && pluginAttrs.highlightId) {
            const type = getMarkType('highlight', state.schema);

            if (empty) {
              const range = Queries.getActiveHighlightRange(editor.state);

              if (range) {
                tr.addMark(range.from, range.to, type.create(pluginAttrs));
              }
            } else {
              tr.addMark(from, to, type.create(pluginAttrs));
            }

            dispatch(tr);
          }

          return true;
        },
      unsetHighlight:
        (highlightId) =>
        ({ editor, tr, dispatch }) => {
          if (dispatch) {
            const marks = Queries.findMarksByHighlightId(editor.state, highlightId);

            marks.forEach(({ node, pos }) => {
              dispatch(tr.removeMark(pos, pos + node.nodeSize, editor.schema.marks.highlight));
            });
          }

          return true;
        },
      updateHighlight:
        (highlightId, attributes) =>
        ({ editor, state, tr, dispatch }) => {
          if (dispatch) {
            const marks = Queries.findMarksByHighlightId(editor.state, highlightId);

            const firstMark = marks[0];
            const lastMark = marks[marks.length - 1];

            const from = firstMark.pos;
            const to = lastMark.pos + lastMark.node.nodeSize;

            tr.addMark(
              from,
              to,
              getMarkType('highlight', state.schema).create(mergeDeep(editor.getAttributes('highlight'), attributes))
            );

            dispatch(tr);
          }

          return true;
        }
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: inputRegex,
        type: this.type
      })
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: pasteRegex,
        type: this.type
      })
    ];
  }
});
