import React, { forwardRef, SVGProps } from 'react';

export const FitWidthAndHeight = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2940_17865)'>
      <rect x='0.5' y='0.5' width='15' height='15' rx='1.5' stroke='currentColor' />
      <g clipPath='url(#clip1_2940_17865)'>
        <path
          d='M6.75 4.5625L8 3.3125L9.25 4.5625'
          stroke='currentColor'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.25 11.4375L8 12.6875L6.75 11.4375'
          stroke='currentColor'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8 3.3125V12.6875'
          stroke='currentColor'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.5625 9.25L3.3125 8L4.5625 6.75'
          stroke='currentColor'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.4375 6.75L12.6875 8L11.4375 9.25'
          stroke='currentColor'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.3125 8H12.6875'
          stroke='currentColor'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_2940_17865'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
      <clipPath id='clip1_2940_17865'>
        <rect width='10' height='10' fill='white' transform='translate(3 3)' />
      </clipPath>
    </defs>
  </svg>
));
