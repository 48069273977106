import * as React from 'react';
import { createContext, useContext, useReducer } from 'react';

// store
type T = {
  [key: string]: {
    events: NylasEvent[];
    failed?: boolean;
  };
};

export const buildKey = ({ userId, calendarId, weekIdx }: Partial<UserAndCalendar>): string =>
  `${userId}__${calendarId}__${weekIdx || -1}`;

const store = createContext<{
  state: T;
  dispatch: React.Dispatch<A>;
}>({
  state: { events: null as any },
  dispatch: () => null
});

// provider
const Provider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer<React.Reducer<T, A>>(reducer, { events: null as any });

  return <store.Provider value={{ state, dispatch }}>{children}</store.Provider>;
};

// actions
const SET_EVENTS = 'SET_EVENTS';
const SET_FAILED = 'SET_FAILED';

type A =
  | { type: typeof SET_EVENTS; key: Partial<UserAndCalendar>; events: NylasEvent[] }
  | { type: typeof SET_FAILED; key: Partial<UserAndCalendar> };

// reducer
const reducer: React.Reducer<T, A> = (state, action) => {
  switch (action.type) {
    case SET_EVENTS:
      return {
        ...state,
        [buildKey(action.key)]: { events: action.events }
      };
    case SET_FAILED:
      return {
        ...state,
        [buildKey(action.key)]: { events: null as any, failed: true }
      };
    default:
      return state;
  }
};

export { SET_EVENTS, SET_FAILED, store, T as State, A as Action, Provider as NylasEventsProvider };
