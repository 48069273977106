import * as React from 'react';
import { useMemo } from 'react';

import { useEditorState } from '@components/RepoSessionApp/ClipPage/hooks/useEditorState';
import { ClipTranscript } from '@components/shared/ClipTranscript';

type Props = {
  clip?: Clip;
};

export const ClipContent: React.FC<React.PropsWithChildren<Props>> = ({ clip }) => {
  const { getTextNodes, state } = useEditorState({ documentId: clip?.highlight?.document_id });

  const textNodes = useMemo(() => {
    return getTextNodes([clip?.from || 0, clip?.to || 0]);
  }, [clip?.from, clip?.to, state]);

  return (
    <>
      {clip && textNodes && (
        <div className='my-4 px-6'>
          <ClipTranscript slideoutView textNodes={textNodes} />
        </div>
      )}
    </>
  );
};
