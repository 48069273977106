import React from 'react';

import { MAX_BATCH_SIZE } from '../consts';
import { Batching as BatchingType } from '../types';

import { BatchingRequiredAlert, BatchTooLargeAlert } from './alerts';
import { BatchFields } from './BatchFields';
import { ContinuousFields } from './ContinuousFields';
import { ExistingBatch } from './ExistingBatch';

type Props = {
  addToBatch: boolean;
  batch: BatchingType;
  continuous?: boolean;
  count: number;
  event: StudyMessageEvent;
  existingBatch?: boolean;
  onChange?: (batch: BatchingType, addToBatch: boolean, valid: boolean) => void;
  required?: boolean;
  title?: string;
  target?: boolean;
};

export const Batching: React.FC<React.PropsWithChildren<Props>> = ({
  addToBatch,
  batch,
  continuous = false,
  count,
  event,
  existingBatch = false,
  onChange,
  required = false,
  target = false,
  title
}) => {
  const isBatchTooLarge = batch.on && batch.size > MAX_BATCH_SIZE;

  const handleChange = (newBatch: BatchingType, newAddToBatch: boolean) => {
    onChange?.(newBatch, newAddToBatch, !isBatchTooLarge);
  };

  return (
    <>
      <BatchFields
        batch={batch}
        event={event}
        setBatch={(newBatch) => handleChange(newBatch, addToBatch)}
        target={target}
        title={title}
      />

      {!batch.on && required && <BatchingRequiredAlert count={count} event={event} />}
      {batch.on && isBatchTooLarge && <BatchTooLargeAlert />}

      {continuous && <ContinuousFields batch={batch} setBatch={(newBatch) => handleChange(newBatch, addToBatch)} />}

      {existingBatch && (
        <ExistingBatch
          addToBatch={addToBatch}
          batch={batch}
          setAddToBatch={(enabled) => handleChange(batch, enabled)}
        />
      )}
    </>
  );
};
