import * as React from 'react';

export const status: React.FC<React.PropsWithChildren<any>> = (props) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.5 7.7132L4.92917 9.74145C4.98338 9.82243 5.05614 9.88929 5.14141 9.93647C5.22668 9.98365 5.32198 10.0098 5.41938 10.0127C5.51679 10.0156 5.61348 9.9952 5.70141 9.9532C5.78934 9.9112 5.86597 9.8488 5.92492 9.7712L10.5 3.98279'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.6875 0.43689H1.3125C0.829251 0.43689 0.4375 0.82864 0.4375 1.31189V12.6869C0.4375 13.1701 0.829251 13.5619 1.3125 13.5619H12.6875C13.1707 13.5619 13.5625 13.1701 13.5625 12.6869V1.31189C13.5625 0.82864 13.1707 0.43689 12.6875 0.43689Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
