// libs
import * as React from 'react';
// hooks
import { useState } from 'react';

import cn from 'classnames';
import { useForm } from 'react-hook-form';

// api
import { api } from '@api/reduxApi';
import { Button } from '@components/common';
import { Checkbox, FormGroup, Input, Label, Textarea } from '@components/fields';
import { useGetWhoamiQuery } from '@components/stores/whoami';
import { PreviewSignupPage } from '@components/StudiesApp/components/StudyDraft/pages/Assets/PreviewSignupPage/PreviewSignupPage';
import { PencilSVG } from '@components/svgs';
// utils
import { useToaster } from '@stores/toaster';
import { CopyURLButton } from 'components/shared/CopyURLButton';
import { BrandLogo } from 'components/StudiesApp/components/StudyDraft/pages/Assets/BrandLogo';
import {
  checkAnyValuesChanged,
  getDefaultValues
} from 'components/StudiesApp/components/StudyDraft/pages/Assets/EditSignupPage';
// components
import * as toasts from 'components/StudiesApp/components/StudyDraft/toasts';

export const PanelLandingPage: React.FC<React.PropsWithChildren<PageProps>> = ({
  landing_page,
  onSave,
  study,
  setSaving
}) => {
  const [edit, setEdit] = useState(false);

  const showToast = useToaster();

  const { register, handleSubmit, errors, reset } = useForm<Partial<LandingPage>>({
    defaultValues: getDefaultValues(landing_page as any)
  });

  const [updateStudyLandingPage] = api.useUpdateStudyLandingPageMutation();

  const onSubmit = async (data: Partial<LandingPage>) => {
    const dataNotChanged = !checkAnyValuesChanged(data, landing_page as any) || Object.keys(data).length === 0;

    if (dataNotChanged) {
      setEdit(false);
      return;
    }

    setSaving(true);

    try {
      const resp = await updateStudyLandingPage({ study_id: study.id, ...data }).unwrap();

      setEdit(false);
      onSave({ id: study.id, slug: resp.slug }); // is there another way to refresh the study?
      reset(getDefaultValues(resp)); // why this? To set new values as default
      // https://react-hook-form.com/kr/v6/api/
      // Important: defaultValues is cached at the first render within the custom hook.
      // If you want to reset the defaultValues, you should use the reset api.
    } catch (_) {
      showToast(toasts.failedUpdate());
    }

    setSaving(false);
  };

  const wrapperClass = cn(
    { 'rounded-md': edit, 'shadow-lg': !edit },
    'w-full bg-white max-w-4xl p-8 relative mx-auto border border-gray-200'
  );

  const { data: whoami } = useGetWhoamiQuery();
  const host = whoami?.account_public_domain.base_url;
  const publicUrl = host + '/' + study.public_path;

  return (
    <div className={wrapperClass}>
      <div className='flex space-x-3 pb-8'>
        <div className='flex-grow'>
          <BrandLogo study={study} className='h-8' edit={edit} />
        </div>

        {!edit && (
          <>
            {study.state !== 'pending' && (
              <CopyURLButton disabled={!study.slug} icon='link' className='mr-2' text={publicUrl} />
            )}
            <Button iconSuffix='externalLink' disabled={!study.slug} href={publicUrl} target='_blank'>
              Preview
            </Button>
            <Button iconSuffix='pencil' onClick={() => setEdit(true)}>
              Edit
            </Button>
          </>
        )}

        {edit && (
          <Button type='submit' primary onClick={handleSubmit(onSubmit)}>
            Done
          </Button>
        )}
      </div>

      {!edit && <PreviewSignupPage landing_page={landing_page as any} study={study} />}

      {edit && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h4 className='mb-4 font-bold text-gray-700'>Page</h4>
          <FormGroup>
            <Label labelFor='title'>Headline</Label>
            <Input name='title' id='title' errors={errors} register={register} />
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <Textarea name='description' id='description' errors={errors} register={register} minRows={1} />
          </FormGroup>

          <FormGroup>
            <Label>Button text</Label>
            <Input name='button_text' placeholder='' register={register} />
          </FormGroup>
          <FormGroup>
            <Label>Personal Note</Label>
            <Textarea name='personal_note' placeholder='' register={register} />
          </FormGroup>

          <h4 className='mt-6 font-bold text-gray-700'>Sidebar</h4>
          <FormGroup>
            <Label>Title</Label>
            <Input name='sidebar_title' placeholder='' register={register} />
          </FormGroup>
          <FormGroup>
            <Label>Subtitle</Label>
            <Input name='sidebar_subtitle' placeholder='' register={register} />
          </FormGroup>
          <FormGroup>
            <Label className='mb-2'>Items</Label>
            <Checkbox name='sidebar_items' value='video_call' label='Interviews' register={register} />
            <Checkbox name='sidebar_items' value='survey' label='Surveys' register={register} />
            <Checkbox name='sidebar_items' value='unmoderated_test' label='Unmoderated tests' register={register} />
            <Checkbox name='sidebar_items' value='online_task' label='Online tasks' register={register} />
          </FormGroup>
          <h4 className='mt-6 font-bold text-gray-700'>Signup form</h4>
          <FormGroup>
            <Label labelFor='booking_page_title'>Page title</Label>
            <Input name='booking_page_title' errors={errors} register={register} />
          </FormGroup>

          <FormGroup>
            <Label>Share URL</Label>
            <div className='flex'>
              <div className='mr-1 flex flex-col justify-center text-gray-700'>
                {host}/{study.public_path?.split('/')[0]}/
              </div>
              <div className='flex-grow'>
                <Input name='slug' placeholder='' register={register} />
              </div>
            </div>
          </FormGroup>
        </form>
      )}
    </div>
  );
};
