import React, { useMemo, useState } from 'react';

import cn from 'classnames';
import { ColumnDef } from '@tanstack/react-table';

import { Text, Toggle } from '@components/common';
import { AgreementRate } from '@components/ScreenerResults/components/CardSortResponse/types';
import { InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { Table } from 'components/shared/Table';

import { LEGEND_ITEMS } from '../constants';

import { AgreementMatrixData, buildAgreementMatrixData } from './helpers/buildAgreementMatrixData';
import { getAgreementMatrixColumnDefs } from './helpers/getAgreementMatrixColumnDefs';

interface Props {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
  answersWithMergedCategories: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
  categories: string[];
  mergedCategoriesNames: string[];
  cards: string[];
  showMergedCategoriesToggle?: boolean;
  mergedCategories: { name: string; categories: string[] }[];
}

export type DefinitionFnReturn = ColumnDef<AgreementRate>[];

export type DefinitionFn = () => DefinitionFnReturn;

export const AgreementMatrix: React.FC<React.PropsWithChildren<Props>> = ({
  mergedCategoriesNames,
  answersWithMergedCategories,
  answers,
  categories,
  cards,
  showMergedCategoriesToggle,
  mergedCategories
}) => {
  const [showMergedCategories, setShowMergedCategories] = useState(mergedCategoriesNames.length > 0);
  const [showPercentage, setShowPercentage] = useState(true);

  const data = useMemo(() => {
    return buildAgreementMatrixData({
      answers: showMergedCategories ? answersWithMergedCategories : answers,
      categories: showMergedCategories ? mergedCategoriesNames : categories,
      cards
    });
  }, [cards, categories, answers, showMergedCategories, mergedCategoriesNames]);

  const columns = useMemo(
    () =>
      getAgreementMatrixColumnDefs({
        showPercentage,
        categories: showMergedCategories ? mergedCategoriesNames : categories,
        answers: showMergedCategories ? answersWithMergedCategories : answers,
        cards,
        mergedCategories
      }),
    [categories, showPercentage, answers, cards, showMergedCategories, mergedCategoriesNames, mergedCategories]
  );

  return (
    <div className='w-full'>
      <div className='mb-4 flex items-center'>
        <Text bold className='mr-1'>
          Agreement matrix
        </Text>
        <Tooltip
          content='The agreement matrix gives you a visual representation of how often users agree that a card belongs in each
          category.'
        >
          <InfoCircleIcon className='ml-1 h-4 w-4' />
        </Tooltip>
      </div>
      <div className='mb-4 flex flex-wrap items-center'>
        <div className='flex flex-1 items-center'>
          <div className='flex items-center space-x-6'>
            {LEGEND_ITEMS.map(({ style, from, to }) => (
              <div key={from} className='flex flex-nowrap items-center space-x-2'>
                <div className='h-2 w-2 flex-shrink-0 rounded-full' style={style} />
                <span className='h400 whitespace-nowrap'>{`${from} - ${to}%`}</span>
              </div>
            ))}
          </div>
        </div>
        {showMergedCategoriesToggle && (
          <div className='mr-2 flex items-center'>
            <Toggle
              on={showMergedCategories}
              onToggle={setShowMergedCategories}
              disabled={!mergedCategoriesNames.length}
            />
            <div className={cn('h400 ml-2 mr-1', !mergedCategoriesNames.length && 'text-gray-400')}>
              Show merged categories
            </div>
          </div>
        )}
        <div className='flex items-center'>
          <Toggle on={showPercentage} onToggle={setShowPercentage} />
          <div className='h400 ml-2 mr-1'>Show %</div>
          <Tooltip content='Toggle between raw counts and counts as a percentage of total responses'>
            <InfoCircleIcon className='h-4 w-4' />
          </Tooltip>
        </div>
      </div>

      <div className='relative max-w-full overflow-auto border border-gray-200'>
        <Table<AgreementMatrixData> columnPinning={{ left: ['card'] }} data={data} columns={columns} />
      </div>
    </div>
  );
};
