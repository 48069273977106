import React, { useEffect, useMemo, useState } from 'react';

import { Navigate, Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { useBulkUploadActions, useBulkUploadState } from '@components/BulkUploadFlow';
import { Button, LayoutToggle, Tabs } from '@components/common';
import { PageTitle } from '@components/common/helmets';
import { HeaderCta } from '@components/RepositoryApp/components/HeaderCta';
import { SettingsMovedModal } from '@components/RepositoryApp/components/SettingsMovedModal';
import { PageHeader } from '@components/shared/PageHeader';
import { AlgoliaData, algoliaProxyAdapter, AlgoliaProxyConfig, Search } from '@components/shared/Search';
import { SortDropdown } from '@components/shared/SortDropdown';
import { AnyAllToggle, SegmentActions, TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { useCollectionView } from '@components/stores/view';
import { track } from '@components/tracking';
import { usePermission } from '@hooks/usePermission';
import { useFeature } from 'hooks/useFeature';

import { BulkAddToHighlightReel } from './components/BulkAddToHighlightReel';
import { BulkAddToInsightDropdown } from './components/BulkAddToInsightDropdown';
import { ExportCsvButton } from './components/ExportCsvButton';
import { PreviewContentSlideout } from './components/PreviewContentSlideout';
import { StateResults } from './components/StateResults';
import { buildRepoFilterDefs } from './filters/buildRepoFilterDefs';
import { SORT_OPTIONS, SortOption } from './filters/sortOptions';
import { PAGE_SIZE } from './hits/artifacts';
import { useAlgoliaFilters } from './hooks/useAlgoliaFilters';
import { useRepositoryContext } from './hooks/useRepositoryContext';
import { ArtifactHits } from './hits';
import { ArtifactHit } from './types';
import { extraFilter, getTabLabels } from './utils';

type Props = {
  stickyHeader?: boolean;
};

function indexToSort(index: SortOption): CollectionView['sort'] {
  return { value: index, desc: false };
}

export function sortToIndex(sort: CollectionView['sort']): SortOption {
  return sort.value as SortOption;
}

export const RepositoryIndex: React.FC<React.PropsWithChildren<Props>> = ({ stickyHeader }) => {
  const hasPaginatedRepository = useFeature('paginated_repository');
  const [createInsight] = api.useCreateInsightMutation();

  const { selectedArtifacts, setSelectedArtifacts, previewSlideout, setPreviewSlideout, study, reload } =
    useRepositoryContext();

  const studyId = study?.id;

  const match = useMatch(studyId ? '/studies/:id/:tab/:repoTab' : '/repository/:repoTab');

  const tabs = Object.keys(getTabLabels(study?.style));

  const canCreateInsight = usePermission('canCreate')();

  const { uploadFinished } = useBulkUploadState();

  const viewHook = useCollectionView();

  const { view, setView } = viewHook;

  let currentTab: string;

  if (tabs.includes(match?.params.repoTab as any)) {
    currentTab = match?.params.repoTab as string;
  } else if (view.tab && tabs.includes(view.tab)) {
    currentTab = view.tab;
  } else currentTab = 'insights';

  const resultsTabIsActive = ['summary_responses', 'individual_responses'].includes(match?.params.repoTab as any);

  const sortIndex = sortToIndex(view.sort);

  function setSortIndex(index: SortOption) {
    setView({ sort: indexToSort(index) });
  }

  const setLayout = (layout: LayoutMode) => {
    setView({ layout });
  };

  const navigate = useNavigate();

  const onModalClose = () => {
    setView({ tab: 'insights' });

    navigate(`/studies/${studyId}/repository/insights`);
  };

  const { search } = useLocation();

  const [searchValue, setSearchValue] = useState('');

  const definitions = useMemo(() => {
    const repoFilterDefs = buildRepoFilterDefs(studyId);
    if (studyId) {
      return repoFilterDefs;
    } else {
      return repoFilterDefs.filter((f) => f.id !== 'artifact_type');
    }
  }, []);

  const filtersHook = useTableFilters<ArtifactHit>({
    definitions,
    trackKey: 'repo',
    syncWithURL: true
  });

  const algoliaFilters = useAlgoliaFilters(filtersHook, {
    extraFilter: extraFilter(study, view.tab, currentTab)
  });

  const [fetch] = api.useLazyGetRepoArtifactsQuery();
  const algoliaProxyConfig = useMemo<AlgoliaProxyConfig>(
    () => ({
      fetch,
      sortBy: sortIndex,
      hitsPerPage: hasPaginatedRepository ? PAGE_SIZE : 9
    }),
    [fetch, sortIndex]
  );

  const canBulkDelete = false; // not implt yet
  const canSelect = canCreateInsight || canBulkDelete;

  const { finishUpload, openManagerZDS } = useBulkUploadActions();

  useEffect(() => {
    if (uploadFinished) {
      finishUpload();
      reload();
    }
  }, [uploadFinished]);

  useEffect(() => {
    if ((!match?.params.repoTab || !tabs.includes(match?.params.repoTab as string)) && view.tab) {
      navigate(view.tab);
    }
  }, []);

  useEffect(() => {
    if (view.tab !== currentTab) {
      setView({ tab: currentTab });
    }
  }, []);

  const onTabSelect = (tab) => {
    setView({ tab });
    navigate(`${tab}${search}`);
  };

  const handleClickNewInsight = async () => {
    track('clicked_new_story', { cta: 'insights zds', page: 'study insights' });
    const story = await createInsight({ project_id: studyId }).unwrap();
    navigate(`/insights/${story.slug}`);
  };

  const renderCta = () => {
    return !selectedArtifacts.length ? (
      <HeaderCta
        studyId={studyId}
        handleClickNewInsight={handleClickNewInsight}
        handleClickNewVideo={() => openManagerZDS(studyId)}
      />
    ) : null;
  };

  const renderBulkActions = () => {
    return selectedArtifacts.length ? (
      <>
        {view.layout !== 'list' && (
          <Button medium icon='deselect' onClick={() => setSelectedArtifacts([])}>
            Deselect
          </Button>
        )}
        <BulkAddToHighlightReel selectedArtifacts={selectedArtifacts} studyId={studyId} />
        <BulkAddToInsightDropdown primary selectedArtifacts={selectedArtifacts} studyId={studyId} />
      </>
    ) : null;
  };

  const renderFilters = () => (
    <div className='px-page flex items-center border-t border-gray-200'>
      <div className='flex-1'>
        <TableFilters<ArtifactHit> hook={filtersHook} defaultShowInput={!filtersHook.filters.length} />
      </div>
      <div className='flex space-x-3 border-l border-gray-200 py-3 pl-3'>
        <AnyAllToggle value={filtersHook.op} onChange={filtersHook.setOp} />
        <SegmentActions onClear={filtersHook.clearFilters} anyFilters={filtersHook.filters.length > 0} />
      </div>
    </div>
  );

  const renderTabs = () => {
    return (
      <Tabs
        current={currentTab}
        labels={getTabLabels(study?.style)}
        onSelect={onTabSelect}
        className='px-page flex items-center border-t border-gray-200'
        tabs={tabs}
      />
    );
  };
  const getTabElement = (tab) => {
    switch (tab) {
      case 'summary_responses':
        return (
          <SettingsMovedModal
            onClose={onModalClose}
            ctaText='Go to Overall'
            title='Summary responses have moved'
            desc='We’ve moved the Summary responses from Repository > Summary responses into Summary > Overall. Please navigate there to view the responses.'
            onConfirm={() => {
              setView({ tab: 'insights' });
              navigate(`/studies/${studyId}/summary/overall`);
            }}
          />
        );
      case 'individual_responses':
        return (
          <SettingsMovedModal
            onClose={onModalClose}
            ctaText='Go to Individual'
            title='Individual responses have moved'
            desc='We’ve moved the Individual responses from Repository > Individual responses into Summary > Individual. Please navigate there to view the responses.'
            onConfirm={() => {
              setView({ tab: 'insights' });
              navigate(`/studies/${studyId}/summary/individual`);
            }}
          />
        );
      default:
        return <ArtifactHits hook={filtersHook} canSelect={canSelect} layout={view.layout} />;
    }
  };

  return (
    <Search<AlgoliaData, AlgoliaProxyConfig>
      query={searchValue}
      filters={algoliaFilters}
      adapter={algoliaProxyAdapter}
      config={algoliaProxyConfig}
      infiniteResults={!hasPaginatedRepository}
    >
      {({ setData }) => {
        return (
          <>
            {previewSlideout && (
              <PreviewContentSlideout
                setData={setData}
                artifact={previewSlideout}
                closeSlideout={() => setPreviewSlideout(null)}
              />
            )}
            <PageTitle>Repository</PageTitle>
            <PageHeader
              sticky={stickyHeader}
              hideActions={resultsTabIsActive}
              renderTabs={renderTabs}
              transparent={!!study}
              renderExportButton={() => <ExportCsvButton study={study} currentTab={currentTab} />}
              renderSortBy={() => (
                <SortDropdown
                  wrapperClass='w-full'
                  value={sortIndex}
                  onChange={({ value }) => setSortIndex(value as SortOption)}
                  options={SORT_OPTIONS}
                  trackKey='repo'
                />
              )}
              hideDefaultButtons={!!selectedArtifacts.length}
              filtersApplied={!!filtersHook.filters.length}
              renderBulkActions={renderBulkActions}
              renderLayout={() => <LayoutToggle value={view.layout} onChange={setLayout} />}
              searchProps={{
                value: searchValue,
                onSearch: setSearchValue
              }}
              renderCta={renderCta}
              renderFilters={renderFilters}
              h1='Repository'
            />
            <StateResults
              layout={view.layout}
              tab={currentTab}
              screenerResults={resultsTabIsActive}
              studyId={studyId}
              anyFilters={filtersHook.filters.length > 0}
            >
              <div className='px-page py-gutter relative flex-grow'>
                <Routes>
                  {tabs.map((tab) => (
                    <Route key={tab} path={tab} element={getTabElement(tab)} />
                  ))}
                  <Route path='*' element={<Navigate to={currentTab} replace />} />
                </Routes>
              </div>
            </StateResults>
          </>
        );
      }}
    </Search>
  );
};
