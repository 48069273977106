import * as React from 'react';

import classNames from 'classnames';

import { api } from '@api/reduxApi';

interface Props {
  className?: string;
  interviewTemplate: InterviewTemplate;
  setInterviewTemplate: (interviewTemplate: InterviewTemplate) => void;
  placeholder?: string;
}
export const EditableTitle: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  interviewTemplate,
  setInterviewTemplate,
  placeholder
}) => {
  const [update] = api.useUpdateInterviewTemplateMutation();
  const { id, title } = interviewTemplate;

  async function handleBlur() {
    await update({ id, title });
  }

  return (
    <input
      name='title'
      className={classNames(className, 'h800 focus:outline-none h700 flex-grow rounded-md placeholder-gray-400')}
      value={title}
      onChange={(e) => setInterviewTemplate({ ...interviewTemplate, title: e.currentTarget.value })}
      onBlur={handleBlur}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.currentTarget.blur();
        }
      }}
      placeholder={placeholder || 'Template title...'}
    />
  );
};
