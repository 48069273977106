import React from 'react';

import { useStripe } from '@stripe/react-stripe-js';

import { Loading } from '@components/common';

export const StripeProvider: React.FC<PropsWithChildrenFn<object, { stripe: any }>> = ({ children }) => {
  const stripe = useStripe();
  if (!stripe) {
    return <Loading />;
  }
  return typeof children === 'function' ? children({ stripe }) : children;
};
