import * as React from 'react';

import { Link, LinkProps, useNavigate } from 'react-router-dom';

import { getClassName } from '@components/common/Dropdown/components/utils';
import { track } from '@components/tracking';

type ReactLink = LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;

interface Props extends ReactLink {
  className?: string;
  disabled?: boolean;
  color?: string;
  hoverColor?: string;
  trackEvent?: string;
  trackProps?: Record<string, any>;
}

export const PopperDropdownLink: React.FC<React.PropsWithChildren<Props>> = ({
  color = 'gray-700',
  hoverColor = 'indigo-600',
  trackEvent,
  disabled,
  className,
  trackProps,
  children,
  onClick,
  to,
  ...rest
}) => {
  const navigate = useNavigate();

  const onLinkClick = (e) => {
    onClick?.(e);

    if (trackEvent) {
      track(trackEvent, trackProps);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClick?.(e);
      navigate(to);

      if (trackEvent) {
        track(trackEvent, trackProps);
      }
    }
  };

  const linkProps = {
    role: 'button',
    tabIndex: 0,
    className: getClassName({ disabled, color, hoverColor, className }),
    onKeyDown,
    onClick: onLinkClick,
    to,
    ...rest
  };

  return disabled ? (
    <span className={getClassName({ disabled, color, hoverColor, className })}>{children}</span>
  ) : (
    <Link {...linkProps}>{children}</Link>
  );
};
