import React, { useState } from 'react';

import { Button, Input, Modal, ModalHeading, Text } from '@components/common';

interface Props {
  onClose: () => void;
  onSubmit: (value: string) => void;
}

export const ResearchGoalModal = ({ onClose, onSubmit }: Props) => {
  const [value, setValue] = useState<string>('');

  return (
    <Modal onClose={onClose} size='md'>
      <ModalHeading className='mb-4'>Sorry, we can't suggest a title</ModalHeading>
      <form className='w-full' onSubmit={() => onSubmit(value)}>
        <section className='mb-6 mt-4'>
          <Text h='400'>
            Fill in a study goal to enable this feature. Our AI assistant uses this information to provide accurate
            suggestions.
          </Text>
          <label htmlFor='research_goal' className='h500-bold mb-2 mt-4 block'>
            Research goal
          </label>
          <Input
            id='research_goal'
            placeholder='e.g. understand customer perception of feature X'
            className='w-full'
            onChange={setValue}
            required
          />
        </section>
        <div className='flex justify-end'>
          <Button onClick={onClose} className='mr-2'>
            Close
          </Button>
          <Button type='submit' primary>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
