import * as React from 'react';

import { Route, Routes } from 'react-router-dom';

import { CandidatesIndex } from './CandidatesIndex';
import { CandidatesShow } from './CandidatesShow';

export const CandidatesApp: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Routes>
      <Route path=':id' element={<CandidatesShow />} />
      <Route path='' element={<CandidatesIndex />} />
    </Routes>
  );
};
