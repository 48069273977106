import {
  closestCorners,
  DroppableContainer,
  getFirstCollision,
  KeyboardCode,
  KeyboardCoordinateGetter,
  UniqueIdentifier
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import * as Models from '@components/SurveyBuilder/types/models';
import { uid } from '@components/utils';

import { FlattenedItem, SensorContext } from './types';

export const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

const getDragDepth = (offset: number, indentationWidth: number) => Math.round(offset / indentationWidth);

const getMaxDepth = ({ previousItem }: { previousItem: FlattenedItem }) => {
  if (previousItem) {
    return previousItem.depth + 1;
  }

  // maybe it should be 1
  return 0;
};

const getMinDepth = ({ nextItem }: { nextItem: FlattenedItem }) => {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
};

export const getProjection = (
  items: FlattenedItem[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number
) => {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;
  const maxDepth = getMaxDepth({
    previousItem
  });
  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  return { depth, maxDepth, minDepth, parent_id: getParentId() };

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parent_id;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parent_id;

    return newParent ?? null;
  }
};

export const getHasSelectedNode = (item: Models.TreeItem): boolean => {
  return item.nodes.some((node) => node.selected || getHasSelectedNode(node));
};

const flatten = (items: Models.TreeItem[], parent_id: UniqueIdentifier | null = null, depth = 0): FlattenedItem[] => {
  return items.reduce<FlattenedItem[]>((acc, item, index) => {
    const hasSelectedNode = getHasSelectedNode(item);

    acc.push({
      ...item,
      parent_id,
      depth,
      index,
      hasSelectedNode
    });

    if (Array.isArray(item.nodes)) {
      acc.push(...flatten(item.nodes, item.id, depth + 1));
    }

    return acc;
  }, []);
};

export function flattenTree(items: Models.TreeItem[]): FlattenedItem[] {
  return flatten(items);
}

export function buildTree(flattenedItems: FlattenedItem[]): Models.TreeItem[] {
  const root: Models.TreeItem = {
    id: 'root',
    nodes: [],
    label: 'Initial',
    selected: false,
    parent_id: null
  } as unknown as Models.TreeItem;
  const currentNodes: Record<string, Models.TreeItem> = { [root.id]: root } as unknown as Record<
    string,
    Models.TreeItem
  >;
  const items = flattenedItems.map((item) => ({ ...item, nodes: [] }));

  // changed
  for (const item of items) {
    const { id, nodes, label, selected } = item;
    const parent_id = item.parent_id ?? root.id;
    const parent = currentNodes[parent_id] ?? findItem(items, parent_id);

    currentNodes[id] = { id, nodes, label, selected, parent_id } as unknown as Models.TreeItem;
    parent.nodes.push(item);
  }

  return root.nodes;
}

export function findItem(items: Models.TreeItem[], itemId: UniqueIdentifier) {
  return items.find(({ id }) => id === itemId);
}

export function findItemDeep(items: Models.TreeItem[], itemId: UniqueIdentifier): Models.TreeItem | undefined {
  for (const item of items) {
    const { id, nodes } = item;

    if (id === itemId) {
      return item;
    }

    if (nodes?.length) {
      const node = findItemDeep(nodes, itemId);

      if (node) {
        return node;
      }
    }
  }

  return undefined;
}

export function removeItem(items: Models.TreeItem[], id: UniqueIdentifier) {
  const newItems: Models.TreeItem[] = [];

  for (const item of items) {
    const newItem = { ...item };
    if (newItem.id === id) {
      continue;
    }

    if (newItem.nodes.length) {
      newItem.nodes = removeItem(newItem.nodes, id);
    }

    newItems.push(newItem);
  }

  return newItems;
}

export function addItem(
  items: Models.TreeItem[],
  newItem: Models.TreeItem,
  parent_id: UniqueIdentifier | null = null
): Models.TreeItem[] {
  return items.map((item) => {
    // Create a shallow copy of the item to avoid mutation
    const newItemCopy = { ...item };

    if (newItemCopy.id === parent_id) {
      // Ensure we are not mutating the original nodes directly
      newItemCopy.nodes = [...newItemCopy.nodes, newItem];
      newItemCopy.selected = false;
    } else if (newItemCopy.nodes.length) {
      // Recursively add item to nodes
      newItemCopy.nodes = addItem(newItemCopy.nodes, newItem, parent_id);
    }

    return newItemCopy; // Return the modified item
  });
}

export function setProperty<T extends keyof Models.TreeItem>(
  items: Models.TreeItem[],
  id: UniqueIdentifier | null,
  property: T,
  setter: (value: Models.TreeItem[T]) => Models.TreeItem[T]
) {
  return items.map((item) => {
    const newItem = { ...item };

    if (newItem.id === id || id === null) {
      newItem[property] = setter(newItem[property]);
    }

    if (newItem.nodes?.length) {
      newItem.nodes = setProperty(newItem.nodes, id, property, setter);
    }

    return newItem;
  });
}

export function removeProperty<T extends keyof Models.TreeItem>(
  items: Models.TreeItem[],
  property: T,
  excludeId?: UniqueIdentifier
) {
  return items.map((item) => {
    const newItem = { ...item };

    if (newItem.id !== excludeId) {
      delete newItem[property];
    }

    if (newItem.nodes.length) {
      newItem.nodes = removeProperty(newItem.nodes, property, excludeId);
    }

    return newItem;
  });
}

function countNodes(items: Models.TreeItem[], count = 0): number {
  return items.reduce((acc, { nodes }) => {
    if (nodes.length) {
      return countNodes(nodes, acc + 1);
    }

    return acc + 1;
  }, count);
}

export function getNodeCount(items: Models.TreeItem[], id: UniqueIdentifier) {
  const item = findItemDeep(items, id);

  return item ? countNodes(item.nodes) : 0;
}

export function removeNodesOf(items: FlattenedItem[], ids: UniqueIdentifier[]) {
  const excludeParentIds = [...ids];

  return items.filter((item) => {
    if (item.parent_id && excludeParentIds.includes(item.parent_id)) {
      if (item.nodes.length) {
        excludeParentIds.push(item.id);
      }
      return false;
    }

    return true;
  });
}

const directions: string[] = [KeyboardCode.Down, KeyboardCode.Right, KeyboardCode.Up, KeyboardCode.Left];

const horizontal: string[] = [KeyboardCode.Left, KeyboardCode.Right];

export const sortableTreeKeyboardCoordinates: (
  context: SensorContext,
  indicator: boolean,
  indentationWidth: number
) => KeyboardCoordinateGetter =
  (context, indicator, indentationWidth) =>
  (event, { currentCoordinates, context: { active, over, collisionRect, droppableRects, droppableContainers } }) => {
    if (directions.includes(event.code)) {
      if (!active || !collisionRect) {
        return;
      }

      event.preventDefault();

      const {
        current: { items, offset }
      } = context;

      if (horizontal.includes(event.code) && over?.id) {
        const { depth, maxDepth, minDepth } = getProjection(items, active.id, over.id, offset, indentationWidth);

        switch (event.code) {
          case KeyboardCode.Left:
            if (depth > minDepth) {
              return {
                ...currentCoordinates,
                x: currentCoordinates.x - indentationWidth
              };
            }
            break;
          case KeyboardCode.Right:
            if (depth < maxDepth) {
              return {
                ...currentCoordinates,
                x: currentCoordinates.x + indentationWidth
              };
            }
            break;
        }

        return undefined;
      }

      const containers: DroppableContainer[] = [];

      droppableContainers.forEach((container) => {
        if (container?.disabled || container.id === over?.id) {
          return;
        }

        const rect = droppableRects.get(container.id);

        if (!rect) {
          return;
        }

        switch (event.code) {
          case KeyboardCode.Down:
            if (collisionRect.top < rect.top) {
              containers.push(container);
            }
            break;
          case KeyboardCode.Up:
            if (collisionRect.top > rect.top) {
              containers.push(container);
            }
            break;
        }
      });

      const collisions = closestCorners({
        active,
        collisionRect,
        pointerCoordinates: null,
        droppableRects,
        droppableContainers: containers
      });
      let closestId = getFirstCollision(collisions, 'id');

      if (closestId === over?.id && collisions.length > 1) {
        closestId = collisions[1].id;
      }

      if (closestId && over?.id) {
        const activeRect = droppableRects.get(active.id);
        const newRect = droppableRects.get(closestId);
        const newDroppable = droppableContainers.get(closestId);

        if (activeRect && newRect && newDroppable) {
          const newIndex = items.findIndex(({ id }) => id === closestId);
          const newItem = items[newIndex];
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const activeItem = items[activeIndex];

          if (newItem && activeItem) {
            const { depth } = getProjection(
              items,
              active.id,
              closestId,
              (newItem.depth - activeItem.depth) * indentationWidth,
              indentationWidth
            );
            const isBelow = newIndex > activeIndex;
            const modifier = isBelow ? 1 : -1;
            const offset = indicator ? (collisionRect.height - activeRect.height) / 2 : 0;

            const newCoordinates = {
              x: newRect.left + depth * indentationWidth,
              y: newRect.top + modifier * offset
            };

            return newCoordinates;
          }
        }
      }
    }

    return undefined;
  };

export function getRelativeIndex(
  items: Models.TreeItem[],
  id: UniqueIdentifier,
  parent_id: UniqueIdentifier | null
): number {
  if (parent_id) {
    const parent = findItemDeep(items, parent_id);

    if (!parent) {
      return 0;
    }

    return parent.nodes.findIndex(({ id: itemId }) => itemId === id);
  } else {
    return items.findIndex(({ id: itemId }) => itemId === id);
  }
}

export function buildNewTreeItem(treeItem?: Partial<Models.TreeItem>): Models.TreeItem {
  const tempId = uid();

  return { id: tempId, label: '', nodes: [], parent_id: null, selected: false, ...treeItem } as Models.TreeItem;
}

export const getSelectedItemPath = (items: Models.TreeItem[]): UniqueIdentifier[][] => {
  const paths: UniqueIdentifier[][] = [];

  const traverse = (item: Models.TreeItem, path: UniqueIdentifier[] = []) => {
    const newPath = [...path, item.id];

    if (item.selected && !item.nodes.length) {
      paths.push(newPath);
    }

    item.nodes.forEach((node) => {
      traverse(node, newPath);
    });
  };

  items.forEach((item) => {
    traverse(item);
  });

  return paths;
};

export const getMaxDepthCount = (items: Models.TreeItem[]): number => {
  return items.reduce((acc, item) => {
    const nodeDepth = getMaxDepthCount(item.nodes);

    return Math.max(acc, nodeDepth + 1);
  }, 0);
};

export const getPathById = (items: Models.TreeItem[], id: UniqueIdentifier): UniqueIdentifier[] => {
  const path: UniqueIdentifier[] = [];

  const traverse = (item: Models.TreeItem): boolean => {
    path.push(item.id);

    if (item.id === id) {
      return true;
    }

    for (const node of item.nodes) {
      if (traverse(node)) {
        return true;
      }
    }

    path.pop();

    return false;
  };

  items.some((item) => traverse(item));

  return path;
};

export const getLastNodePosition = (items: Models.TreeItem[], parent_id: UniqueIdentifier | null): number => {
  if (!parent_id) {
    return items[items.length - 1]?.position || 0;
  }

  const parent = findItemDeep(items, parent_id);

  if (parent) {
    return parent.nodes[parent.nodes.length - 1]?.position || 0;
  }

  return 0;
};
