import React, { forwardRef, HTMLAttributes } from 'react';

import cn from 'classnames';
import { useDroppable } from '@dnd-kit/core';

import { Region } from '../constants';
import { useDrawerContext } from '../hooks/useDrawerContext';

export const DropRegions = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ className, ...rest }, ref) => {
  const {
    state: { isExpanded, contentHeight },
    setIsExpanded
  } = useDrawerContext();

  const { setNodeRef: setExpandRegionNodeRef } = useDroppable({
    id: Region.Expand
  });

  const { setNodeRef: setCollapseRegionRef } = useDroppable({
    id: Region.Collapse
  });

  return (
    <div
      ref={ref}
      className={cn(
        'absolute inset-0 flex flex-col',
        {
          'pointer-events-none': !isExpanded,
          'pointer-events-auto': isExpanded
        },
        className
      )}
      {...rest}
    >
      <div
        ref={setExpandRegionNodeRef}
        data-testid='drawer-expand-drop-zone'
        style={{ flex: 1 }}
        onClick={() => setIsExpanded(false)}
      />
      <div ref={setCollapseRegionRef} data-testid='drawer-collapse-drop-zone' style={{ height: contentHeight }} />
    </div>
  );
});
