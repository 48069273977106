import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import type { FC } from 'react';

import { Popper } from '@components/common';
import { VideoSeekBar, VideoSeekBarProvider } from '@components/common/VideoSeekBar';
import {
  MiniPlayerSVG,
  PlayerFullScreen,
  PlayerMute,
  PlayerPause,
  PlayerPlay,
  PlayerSpeaker,
  StepBack,
  StepForward
} from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

import { PLAYBACK_RATES } from '../constants';
import { ControlsProps } from '../types';
import { formatSeconds } from '../utils';

import { PlaybackRates } from './PlaybackRates';
import { RangeBar } from './RangeBar';

export const ControlsWithTimecodes: FC<React.PropsWithChildren<ControlsProps>> = ({
  isPlaying,
  duration,
  currentTime,
  buffer,
  volume,
  isFullscreen,
  playbackSpeed,
  className,
  show,
  hasMiniPlayer,
  play,
  pause,
  setVolume,
  setPlaybackSpeed,
  enterFullscreen,
  exitFullscreen,
  setMiniPlayerEnabled,
  rewind,
  fastForward,
  handleOnProgressBarChange,
  chapters,
  liveStream,
  textTracks,
  renderPreview
}) => {
  const [playbackRate, setPlaybackRate] = useState<number>(playbackSpeed);
  const [popperIsOpen, setPopperIsOpen] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const formatPlaybackRate = (rate: number): string => `${rate.toString()}x`;

  const handleOnVolumeBarChange = (value: number) => {
    setVolume((value * 1) / 100);
  };
  const handleOnPlaybackRateChange = (rate: number, closePopper: () => void) => {
    setPlaybackSpeed(rate);
    setPlaybackRate(rate);
    closePopper();
  };

  useEffect(() => {
    if (liveStream) {
      setPlaybackSpeed(1);
      setPlaybackRate(1);
    }
  }, []);

  return (
    <section
      aria-label='Video controls'
      ref={wrapperRef}
      className={cn(
        'video-controls absolute bottom-0 w-full pb-2 text-sm text-white',
        {
          ['opacity-100']: show || popperIsOpen,
          ['opacity-0']: !show && !popperIsOpen
        },
        className
      )}
    >
      <VideoSeekBarProvider>
        <VideoSeekBar
          className='pb-2'
          currentTime={currentTime * 1000}
          max={duration * 1000}
          bufferTime={buffer * 1000}
          textTracks={textTracks}
          onTimeChange={(time) => handleOnProgressBarChange(((time / 1000) * 100) / duration)}
          chapters={chapters ?? []}
          renderPreview={renderPreview}
        />
      </VideoSeekBarProvider>
      <div className='flex items-center px-4'>
        <div className='flex flex-1 items-center space-x-3 text-white'>
          {isPlaying ? (
            <Tooltip content='Pause'>
              <button type='button' aria-label='Pause' className='focus:outline-none' onClick={pause}>
                <PlayerPause className='h-5 w-5' />
              </button>
            </Tooltip>
          ) : (
            <Tooltip content='Play'>
              <button type='button' aria-label='Play' className='focus:outline-none' onClick={play}>
                <PlayerPlay className='h-5 w-5' />
              </button>
            </Tooltip>
          )}
          {volume === 0 ? (
            <Tooltip content='Unmute'>
              <button type='button' className='focus:outline-none' onClick={() => setVolume(1)} aria-label='Unmute'>
                <PlayerMute className='h-5 w-5' />
              </button>
            </Tooltip>
          ) : (
            <Tooltip content='Mute'>
              <button type='button' className='focus:outline-none' onClick={() => setVolume(0)} aria-label='Mute'>
                <PlayerSpeaker className='h-5 w-5' />
              </button>
            </Tooltip>
          )}
          <RangeBar
            className='w-14'
            max={1}
            value={volume}
            onChange={handleOnVolumeBarChange}
            barClassName='bg-white'
          />
        </div>
        <div className='flex flex-1 items-center justify-center'>
          <span className='flex-shrink-0 select-none leading-none text-white'>
            {formatSeconds(currentTime)} / {formatSeconds(duration)}
          </span>
        </div>
        <div className='ml-auto flex flex-1 items-center justify-end space-x-3'>
          <Tooltip content='Step back 5 seconds'>
            <button type='button' className='focus:outline-none flex-shrink-0 text-white' onClick={() => rewind(5)}>
              <StepBack className='h-5 w-5' />
            </button>
          </Tooltip>
          <Tooltip content='Step forward 5 seconds'>
            <button
              type='button'
              className='focus:outline-none flex-shrink-0 text-white'
              onClick={() => fastForward(5)}
            >
              <StepForward className='h-5 w-5' />
            </button>
          </Tooltip>
          <Popper
            content={({ closePopper }) => (
              <PlaybackRates
                rates={PLAYBACK_RATES.concat([]).reverse()}
                activeRate={playbackRate}
                orientation='vertical'
                onChange={(rate) => handleOnPlaybackRateChange(rate, closePopper)}
              />
            )}
            offset={[0, 10]}
            zIndex={40}
            boundary={isFullscreen && wrapperRef.current ? wrapperRef.current : undefined}
            renderOnBodyRoot={false}
            onOpen={() => setPopperIsOpen(true)}
            onClose={() => setPopperIsOpen(false)}
            closeOnClickOutside
          >
            <div className='inline-flex'>
              <Tooltip content='Playback speed'>
                <button className='focus:outline-none flex-shrink-0 cursor-pointer text-sm leading-none text-white'>
                  {formatPlaybackRate(playbackRate)}
                </button>
              </Tooltip>
            </div>
          </Popper>
          {hasMiniPlayer && (
            <Tooltip content='Mini player'>
              <button
                type='button'
                className='focus:outline-none flex-shrink-0 leading-none'
                onClick={() => setMiniPlayerEnabled?.(true)}
              >
                <MiniPlayerSVG className='h-5 w-5' />
              </button>
            </Tooltip>
          )}
          {isFullscreen && exitFullscreen && (
            <Tooltip content='Exit full screen'>
              <button type='button' className='focus:outline-none flex-shrink-0 text-white' onClick={exitFullscreen}>
                <PlayerFullScreen className='h-5 w-5' />
              </button>
            </Tooltip>
          )}
          {!isFullscreen && enterFullscreen && (
            <Tooltip content='Full screen'>
              <button type='button' className='focus:outline-none flex-shrink-0 text-white' onClick={enterFullscreen}>
                <PlayerFullScreen className='h-5 w-5' />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
    </section>
  );
};
