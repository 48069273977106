import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { api } from '@api/reduxApi';
import { Button, SlideOut } from '@components/common';
import { useTiptapFromDocumentId } from '@components/shared/Tiptap';
import { DEFAULT_EMAIL_MERGE_TAGS } from '@components/shared/Tiptap/extensions/MergeTag';
import { getCtaProps } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip/helpers';
import { getCtaText } from '@components/StudyMessages/utils/getCtaText';
import { useCreateTemplate } from '@components/TemplatesApp/hooks/useCreateTemplate';
import { useAccount } from '@hooks/useAccount';
import { usePermission } from '@hooks/usePermission';
import { useToaster } from '@stores/toaster';
import { CancelEditModal } from 'components/shared/CancelEditModal/CancelEditModal';

import * as toasts from '../../toasts';

import { EmailTemplateForm } from './form';

interface Props {
  emailTemplate: EmailTemplate | null;
  onClose: () => void;
  createNew?: boolean;
}
export const EditEmailTemplate: React.FC<React.PropsWithChildren<Props>> = ({ emailTemplate, onClose, createNew }) => {
  const [cancelModal, setCancelModal] = useState(false);
  const [tiptapIsDirty, setTiptapIsDirty] = useState(false);

  const canUpdate = usePermission<EmailTemplate>('updateInterviewTemplate')(emailTemplate || undefined);

  const {
    account: { id: accountId }
  } = useAccount();

  const isFromThisAccount = emailTemplate ? accountId === emailTemplate.account_id : true;

  const [update, { isLoading: isUpdating, isSuccess: updateSuccess, isError: updateError }] =
    api.useUpdateEmailTemplateMutation();

  const { create, creating } = useCreateTemplate({
    createMutation: api.useCreateEmailTemplateMutation as any,
    kind: 'EmailTemplate'
  });

  const showToast = useToaster();
  const {
    handleSubmit,
    watch,
    formState: { isDirty },
    setError,
    clearErrors,
    errors
  } = useFormContext();

  const event = watch('event');

  const mergeTags = useMemo(
    () => ({
      tags: ['booked', 'booked_reminder', 'cancel_interview'].includes(event)
        ? [...DEFAULT_EMAIL_MERGE_TAGS, 'interview.date_and_time', 'interview.join_url']
        : [...DEFAULT_EMAIL_MERGE_TAGS]
    }),
    [event]
  );

  const saving = creating || isUpdating;

  const tiptap = useTiptapFromDocumentId({
    autoSave: false,
    readonly: !isFromThisAccount || !canUpdate || saving,
    documentId: emailTemplate?.document_id,
    scrollToTop: false,
    ctaProps: getCtaProps({
      event
    }),
    config: {
      headings: false,
      image: { enable: true },
      link: { enable: true },
      highlight: { enable: false },
      artifacts: false,
      templates: false,
      cta: { enable: true, default: getCtaText(event) },
      mergeTags,
      placeholder: 'Write your email content…'
    },
    onEditorUpdate: () => setTiptapIsDirty(true)
  });
  const handleClose = () => {
    if (isDirty || tiptapIsDirty) {
      setCancelModal(true);
    } else {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    if (!tiptap.editor?.getText()) {
      setError('text', { type: 'manual' });
      return;
    }
    if (!emailTemplate || createNew) {
      const res = await create(data);
      await tiptap.saveContent(res.document_id);
    } else {
      update({ ...data, id: emailTemplate.id });
      await tiptap.saveContent(emailTemplate.document_id);
    }
    onClose();
  };

  const onError = () => {
    if (!tiptap.editor?.getText()) {
      setError('text', { type: 'manual' });
      return;
    }
  };

  useEffect(() => {
    if (updateSuccess) {
      showToast(toasts.successUpdate());
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (updateError) {
      showToast(toasts.failedUpdate());
    }
  }, [updateError]);

  useEffect(() => {
    if (tiptap.editor?.getText() && errors?.['text']) {
      clearErrors('text');
    }
  }, [tiptap.editor?.getText(), errors?.['text']]);

  return (
    <SlideOut
      title={!emailTemplate || createNew ? 'Create email template' : 'Edit email template'}
      subtitle='Teammates on your account will be able to find and use these.'
      onClose={onClose}
      size='2xl'
      renderFooter={() => (
        <div className='flex w-full flex-row space-x-6'>
          <Button
            data-testid='xx-send-email'
            disabled={saving || !canUpdate}
            className='xx-send-email'
            onClick={handleSubmit(onSubmit, onError)}
            primary
          >
            {!emailTemplate || createNew ? 'Create template' : 'Save changes'}
          </Button>
          <Button onClick={handleClose}>Discard template</Button>
        </div>
      )}
    >
      <div className='w-full border-t border-gray-200 p-6'>
        <EmailTemplateForm
          event={event}
          tiptap={tiptap}
          emailTemplate={emailTemplate}
          disabled={!isFromThisAccount || !canUpdate || saving}
        />
      </div>
      <CancelEditModal
        discardMessage="Your email wasn't updated."
        setEdit={onClose}
        onClose={() => setCancelModal(false)}
        cancelModal={cancelModal}
      />
    </SlideOut>
  );
};
