import React from 'react';

import { Input } from '@components/common';

import { Consents } from './Consent';

export const NameQuestion: React.FC<React.PropsWithChildren<{ studyId: number }>> = ({ studyId }) => (
  <div className='max-w-40 flex-1'>
    <div className='text-custom-brand block text-xl font-bold leading-normal'>Your details</div>
    <div className='mb-6 mt-5'>
      <label htmlFor='email' className='text-custom-brand mb-2 font-bold'>
        Your full name
      </label>
      <Input className='w-full' disabled value='' placeholder='Enter…' />
    </div>
    <div className='mb-6'>
      <label htmlFor='email' className='text-custom-brand mb-2 font-bold'>
        Your email address
      </label>
      <Input className='w-full' disabled value='' placeholder='Enter…' />
    </div>
    <div>
      <Consents studyId={studyId} />
    </div>
  </div>
);
