// state
import * as React from 'react';
import { useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

// components
import { DropdownLink } from '@components/common';
import { SolidKebabSVG, TrashSVG } from '@components/svgs';
import { useOnClickOutside } from '@components/utils';
import { useMuxVideoDownloadUrl } from '@hooks/useMuxVideoDownloadUrl';
import { usePermission } from '@hooks/usePermission';

import { DeleteSessionModal } from './DeleteSessionModal';

type Props = {
  className?: string;
  session: RepoSession;
};

export const OptionsDropdown: React.FC<React.PropsWithChildren<Props>> = ({ className, session }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const closeDropdown = () => setDropdownOpen(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const skip = !session.recording?.mux_video?.download_ready;
  const { downloadUrl } = useMuxVideoDownloadUrl(session.recording?.mux_video?.id, skip);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, closeDropdown);

  const isDeletable = session.kind === 'recording';
  const canDelete = usePermission('canCreate')() && isDeletable;

  const onDeleteClick = () => {
    setDeleteModalOpen(true);
    closeDropdown();
  };

  const navigate = useNavigate();

  return (
    <>
      <DeleteSessionModal
        uuid={session.uuid}
        title={session.title}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onSubmit={() => {
          navigate('/repository');
          closeDropdown();
        }}
      />

      {(downloadUrl || canDelete) && (
        <button
          data-testid='more'
          onClick={toggleDropdown}
          className={[className, 'focus:outline-none'].join(' ')}
          name='more'
        >
          <SolidKebabSVG className='h-4 w-4' />
          <div ref={dropdownRef} className='relative whitespace-nowrap text-left'>
            {dropdownOpen && (
              <div className='absolute right-0 top-5 z-10 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5'>
                {downloadUrl && (
                  <DropdownLink className='flex items-center' onClick={() => window.open(downloadUrl)}>
                    Download video
                  </DropdownLink>
                )}

                {canDelete && (
                  <DropdownLink
                    className='flex items-center'
                    color='red-600'
                    hoverColor='red-700'
                    onClick={onDeleteClick}
                  >
                    <TrashSVG className='mr-2' />
                    Delete session…
                  </DropdownLink>
                )}
              </div>
            )}
          </div>
        </button>
      )}
    </>
  );
};
