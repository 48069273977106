import * as React from 'react';

import Tippy from '@tippyjs/react';

import { humanize } from 'components/utils';

interface Props {
  status: string[];
}

interface TagProps {
  bg: string;
}

const Tag: React.FC<React.PropsWithChildren<TagProps>> = ({ bg, children }) => {
  return <span className={`mx-0.5 rounded-full px-2.5 py-1 text-xs ${bg} font-medium`}>{children}</span>;
};

const HealthStatus: React.FC<React.PropsWithChildren<Props>> = ({ status }) => {
  if (status.length === 0) {
    return (
      <div>
        <Tag bg='bg-green-100'>Eligible</Tag>
      </div>
    );
    // return <div></div>
  } else if (status.length === 1) {
    return <Tag bg='bg-orange-100'>{humanize(status[0])}</Tag>;
  } else {
    return (
      <Tippy content={status.map(humanize).join(' & ')}>
        <div>
          <Tag bg='bg-orange-100'>Ineligible</Tag>
        </div>
      </Tippy>
    );
  }
};
export default HealthStatus;
