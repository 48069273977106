import { forwardRef } from 'react';
import * as React from 'react';

import cn from 'classnames';

import { Avatar, Text } from '@components/common';
import { ItemProps } from '@components/shared/SelectDropdown/components/Item';
import { AddPeopleSVG } from '@components/svgs';

interface Props extends ItemProps {
  candidates?: Candidate[];
}

export const CandidateItem = forwardRef<HTMLLIElement, Props>(
  ({ candidates, __dropdownItemProps: { item, active, highlighted }, ...rest }, ref) => {
    const candidate = candidates?.find(({ id }) => item.value === id.toString());

    return (
      <li
        ref={ref}
        {...rest}
        data-testid='create-item'
        role='option'
        aria-selected={active}
        className={cn('xx-combo-option flex cursor-pointer flex-row items-center justify-between px-3 py-2', {
          'bg-indigo-600 text-white': highlighted
        })}
      >
        {item.created ? (
          <div className='flex items-center space-x-2'>
            <AddPeopleSVG className='flex-shrink-0' />
            <Text h='400' bold={active} truncate>
              {`Create "${item.label}"`}
            </Text>
          </div>
        ) : (
          <div className='flex flex-row items-center space-x-4'>
            {candidate && <Avatar user={candidate as Partial<TeamUser>} />}
            <span className='flex flex-grow flex-col'>
              <span>{item.label}</span>
              <span className='mt-1 text-sm'>{candidate?.email}</span>
            </span>
            <span className={'flex-0 text-xs font-semibold ' + (highlighted ? 'text-white' : 'text-gray-500')}></span>
          </div>
        )}
      </li>
    );
  }
);
