export const focusInput = (name: string) => {
  const observer = new MutationObserver(() => {
    const input = document.querySelector(`input[name="${name}"]`);

    if (input instanceof HTMLInputElement) {
      input.focus();
      observer.disconnect();
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });
};
