import React, { forwardRef, HTMLAttributes, useEffect, useRef } from 'react';

import cn from 'classnames';
import mergeRefs from 'react-merge-refs';
import { usePopper } from 'react-popper';
import { VirtualElement } from '@popperjs/core';

import { Text } from '@components/common/Text';
import { secondsToTimestamp } from '@components/utils';

import { parseImageFragmentStyle } from '../helpers/parseImageFragment';
import { useVideoSeekBarContext } from '../hooks/useVideoSeekBarContext';
import * as Types from '../types';

interface Props extends HTMLAttributes<HTMLDivElement> {
  imageFragment?: Types.ImageFragment;
}

export const Preview = forwardRef<HTMLDivElement, Props>(({ children, className, imageFragment, ...rest }, ref) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const rect = useRef<VirtualElement>();

  const { styles, attributes, update } = usePopper(rect.current, innerRef.current, { placement: 'top' });

  const {
    state: { hoverTime, isHovering, max, trackWidth, trackX, trackY }
  } = useVideoSeekBarContext();

  const positionX = (hoverTime / max) * trackWidth;

  useEffect(() => {
    const domRect = { width: 0, height: 0, left: positionX + trackX, top: trackY - 12 } as DOMRect;

    rect.current = { getBoundingClientRect: () => domRect };

    update?.();
  }, [positionX]);

  const style = imageFragment ? parseImageFragmentStyle(imageFragment) : {};

  return (
    <div
      ref={mergeRefs([innerRef, ref])}
      className={cn('pointer-events-none transition-all', { 'opacity-0': !isHovering })}
      style={styles.popper}
      {...attributes.popper}
      {...rest}
    >
      <div
        data-testid='video-seek-bar-preview'
        className='flex flex-col items-center justify-center'
        style={{ width: style.width }}
      >
        {children ?? (
          <>
            <div
              data-testid='video-seek-bar-preview-image'
              className='overflow-hidden rounded-md border-2 border-white'
              style={style}
            ></div>

            <Text className='py-1 text-sm text-white'>{secondsToTimestamp(Math.floor(hoverTime / 1000), 4)}</Text>
          </>
        )}
      </div>
    </div>
  );
});
