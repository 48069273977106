import * as React from 'react';

import { api } from '@api/reduxApi';
import { usePermission } from '@hooks/usePermission';
import { useUser } from 'hooks/useUser';

import { TemplatePage } from '../TemplatePage';
import { TemplatesIndexPage } from '../TemplatesIndexPage';

import { buildColumns } from './buildColumns';
import { SurveyTemplatePage } from './SurveyTemplatePage';

export const SurveyTemplatesIndex: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <TemplatesIndexPage<SurveyTemplate>
      kind='SurveyTemplate'
      buildColumns={buildColumns}
      getQuery={api.useGetSurveyTemplatesQuery as any}
      createMutation={api.useCreateSurveyTemplateMutation as any}
    />
  );
};
export const SurveyTemplatesEdit: React.FC<React.PropsWithChildren<unknown>> = () => (
  <TemplatePage<SurveyTemplate>
    kind='SurveyTemplate'
    getQuery={api.useGetSurveyTemplateQuery as any}
    render={([surveyTemplate, setSurveyTemplate]) => (
      <SurveyTemplatePage editable surveyTemplate={surveyTemplate} setSurveyTemplate={setSurveyTemplate} />
    )}
  />
);
export const SurveyTemplatesShow: React.FC<React.PropsWithChildren<unknown>> = () => (
  <TemplatePage<SurveyTemplate>
    kind='SurveyTemplate'
    getQuery={api.useGetSurveyTemplateQuery as any}
    render={([surveyTemplate, setSurveyTemplate]) => (
      <SurveyTemplatePage surveyTemplate={surveyTemplate} setSurveyTemplate={setSurveyTemplate} />
    )}
    trackEvent='viewed_interview_template'
  />
);
