import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Text } from '@components/common';
import { SortableTree } from '@components/common/SortableTree';
import { getMaxDepthCount } from '@components/common/SortableTree/utils';
import * as Models from '@components/SurveyBuilder/types/models';

import { findNodeDeep, parseItems } from '../utils';

import { ResultsTableItem } from './ResultsTableItem';

interface Props {
  answers: NonNullable<ScreenerResponseAnswerValue<'tree_test'>>[];
  showPercentage: boolean;
  nodes: Models.TreeItem[];
}

type ResultsTreeItem = Models.TreeItem & { count: number; directCount: number };

export const ResultsTable: React.FC<React.PropsWithChildren<Props>> = ({ answers, nodes, showPercentage }) => {
  const maxDepth = useMemo(() => getMaxDepthCount(nodes), [nodes]);

  const parsedItems = useMemo(() => parseItems(nodes, nodes, answers), [nodes, answers]);

  const [items, setItems] = useState<Models.TreeItem[]>(parsedItems);

  useEffect(() => {
    setItems(parsedItems);
  }, [parsedItems]);

  const renderItem = useCallback(
    (props: any) => {
      const currentItem = findNodeDeep<ResultsTreeItem>(parsedItems, props.id);

      return (
        <ResultsTableItem
          {...props}
          maxDepth={maxDepth}
          showPercentage={showPercentage}
          answers={answers}
          directCount={currentItem?.directCount}
          count={currentItem?.count}
        />
      );
    },

    [nodes, answers, showPercentage, maxDepth]
  );

  return (
    <div className='relative mt-6 max-w-full overflow-auto'>
      <div className='mb-4 flex w-full flex-shrink-0 items-center justify-between'>
        <div className='h400 w-100 flex-shrink-0 font-bold' style={{ marginRight: maxDepth * 16 }}>
          Tree structure
        </div>
        <div className='flex flex-nowrap items-center space-x-8'>
          <Text h='400' className='w-44' bold>
            Result
          </Text>
          <Text h='400' className='w-44' bold>
            Direct
          </Text>
        </div>
      </div>

      <SortableTree collapsible setItems={setItems} renderItem={renderItem} indentationWidth={16} items={items} />
    </div>
  );
};
