import * as React from 'react';
import { useMemo } from 'react';

import { Button, DropdownCombobox, Text } from '@components/common';
import { DropdownItem } from '@components/common/DropdownCombobox';
import { usePopUp } from '@hooks/usePopUp';
import { NO_OUTLINE } from 'components/CandidateAttrs';

import { TIME_ZONE_OPTIONS } from '../../../options';

interface Props {
  renderInput?: (inputProps: any) => React.ReactElement;
  readOnly?: boolean;
  timeSlot: RecurringTimeSlot | OneOffTimeSlot;
  changeTimeSlot: (attr: string, val: string | number | number[]) => void;
  renderSelected: (item: DropdownItem | undefined) => string | undefined;
  inputProps?: React.HTMLAttributes<HTMLInputElement>;
}
export const TimeZoneSelect: React.FC<React.PropsWithChildren<Props>> = ({
  renderSelected,
  inputProps,
  changeTimeSlot,
  timeSlot,
  readOnly
}) => {
  const onTimezoneSelect = (v: DropdownItem | null) => {
    if (v?.value) {
      changeTimeSlot('timezone', v.value);
    }
  };

  const renderTimezone = (item: DropdownItem, isHighlighted?: boolean) => (
    <div onClick={() => onTimezoneSelect(item)} className='flex justify-between text-sm'>
      <Text className='mr-2'>{item.label}</Text>
      <Text color={isHighlighted ? 'white' : 'gray-500'}>{item.text}</Text>
    </div>
  );

  const selectedItem = React.useMemo(
    () => TIME_ZONE_OPTIONS.find((i) => i.value === timeSlot.timezone),
    [timeSlot.timezone]
  );

  const { ref, togglePopUp, closePopUp, open } = usePopUp();

  const selectedTimezone = useMemo(() => {
    const selectedTZ = TIME_ZONE_OPTIONS.find((i) => i.value === selectedItem?.value);
    return renderSelected(selectedTZ);
  }, [selectedItem]);

  const onSelect = (v: DropdownItem | null): void => {
    closePopUp();
    onTimezoneSelect(v);
  };

  return (
    <div ref={ref} className='relative w-auto'>
      <Button
        data-testid='timezone-select-button'
        text
        small
        className='rounded border border-gray-200 text-sm'
        onClick={togglePopUp}
      >
        {selectedTimezone}
      </Button>
      {open && (
        <div className='absolute top-10 z-40 w-auto'>
          <DropdownCombobox
            items={TIME_ZONE_OPTIONS}
            disabled={readOnly}
            selectedItem={selectedItem}
            onFocus={(event) => event.currentTarget.select()}
            placeholder='Search by city or time zone...'
            dropdownWidth={96}
            unlimitedItems
            renderInput={(props) => (
              <div className='overflow-hidden rounded-t-md border border-gray-200 shadow'>
                <input
                  {...props}
                  type='text'
                  autoFocus
                  style={NO_OUTLINE}
                  className='w-96 py-4'
                  name='dropdown_combobox'
                  {...inputProps}
                />
              </div>
            )}
            onSelect={onSelect}
            renderItem={renderTimezone}
          />
        </div>
      )}
    </div>
  );
};
