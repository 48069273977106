import React from 'react';

import { api } from '@api/reduxApi';
import { Text } from '@components/common';
import { Highlight } from '@components/shared/Highlight';
import { useAccount } from '@hooks/useAccount';

interface Props {
  highlightReel?: HighlightReel;
  clips?: Clip[];
}

export const ReelContent: React.FC<React.PropsWithChildren<Props>> = ({ highlightReel }) => {
  const clips = highlightReel?.clips ?? [];
  const artifactIds = clips.map((c) => `Clip_${c.id}`);

  const { getUserById } = useAccount();

  const { data: artifacts = [] } = api.useGetArtifactsQuery(artifactIds, {
    skip: !artifactIds
  });

  return (
    <div className='flex w-full flex-col space-y-3 border-b border-gray-200 bg-white px-4 py-3'>
      {artifacts.map((artifact, index) => (
        <Highlight
          key={artifact.objectID}
          clip={{
            created_at: artifact.created_at,
            from: artifact.from ?? 0,
            to: artifact.to ?? 0,
            title: artifact.title,
            tag_ids: artifact.tag_ids
          }}
          renderActions={() => <div />}
          creator={getUserById(artifact.owner_id)}
          renderTranscript={() => <Text className='text-sm'>{artifact.body}</Text>}
        />
      ))}
    </div>
  );
};
