import { useMemo } from 'react';

import { ColumnDef } from '@tanstack/react-table';

import { useFeature } from '@hooks/useFeature';

import { activityStudiesColumnDefs } from '../helpers/activityStudiesColumnDefs';
import { baseStudiesColumnDefs } from '../helpers/baseStudiesColumnDefs';
import { customAttrsStudiesColumnDefs } from '../helpers/customAttrsStudiesColumnDefs';

interface Props {
  groupBy?: string;
  getStudyModerators: (moderator_ids: number[]) => TeamUser[];
  getStudyCreator: (creator_id: number) => TeamUser | undefined;
  studyAttrs: Attr_[];
  getColumnLabel: (key: string) => string;
}

export const useStudiesColumnDefinitions = ({
  groupBy,
  getStudyModerators,
  getStudyCreator,
  studyAttrs,
  getColumnLabel
}: Props): ColumnDef<SimpleStudy>[] => {
  const hasPaginatedStudies = useFeature('paginated_studies');

  const definitions = useMemo<ColumnDef<SimpleStudy>[]>(() => {
    return [
      ...baseStudiesColumnDefs({
        getColumnLabel,
        groupBy,
        getStudyModerators,
        getStudyCreator
      }),
      ...activityStudiesColumnDefs({ getColumnLabel }),
      ...customAttrsStudiesColumnDefs({ getColumnLabel, studyAttrs, hasPaginatedStudies })
    ];
  }, [getColumnLabel, groupBy, studyAttrs]);

  return definitions;
};
