import * as React from 'react';

import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import Tippy from '@tippyjs/react';

import { Button, Text } from '@components/common';
import { compact } from '@components/utils';

import { CreatedBy } from '../../components/cells/CreatedBy';
import { IsDefault } from '../../components/cells/IsDefault';
import { LastUpdated } from '../../components/cells/LastUpdated';
import { DeleteTemplateButton } from '../../components/DeleteTemplateButton';
import { DuplicateEmailButton } from '../../components/DuplicateEmailButton';
import { DEFAULT_TITLES, EMAIL_TEMPLATE_PURPOSES } from '../../consts';

type Params = {
  canUpdate: (template: EmailTemplate) => boolean;
  onEdit: (template: EmailTemplate, createNew?: boolean) => void;
};

export const buildColumns = (params: Params): Column<EmailTemplate>[] =>
  compact([
    {
      id: 'title',
      Cell: ({ row }) => <Link to={`${row.original.id}`}>{row.original.title || DEFAULT_TITLES.EmailTemplate}</Link>
    },
    {
      Header: () => <>Use case</>,
      id: 'purpose',
      Cell: ({ row }) => <Text h='400'>{EMAIL_TEMPLATE_PURPOSES[row.original.event]}</Text>
    },
    {
      id: 'created by',
      Cell: CreatedBy
    },
    {
      id: 'last updated',
      Cell: LastUpdated
    },
    {
      id: 'default',
      Header: () => <></>,
      Cell: IsDefault
    },
    {
      id: 'extra',
      Header: () => <></>,
      Cell: ({ row }) => (
        <div className='whitespace-nowrap pr-6 text-right'>
          {params.canUpdate(row.original) && (
            <>
              <DuplicateEmailButton onClick={() => params.onEdit(row.original, true)} />
              <Tippy className='rounded-lg bg-gray-500' content='Edit template' arrow={false}>
                <Button text rounded small onClick={() => params.onEdit(row.original)} icon='pencil' />
              </Tippy>
              <DeleteTemplateButton template={row.original} kind='EmailTemplate' />
            </>
          )}
        </div>
      )
    }
  ]);
