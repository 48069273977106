import * as React from 'react';

import { InputWithAddons, Select, Text } from '@components/common';

import { DropdownProps } from '../../types';

type Props<D = any> = DropdownProps<D, number>;

export const Number: React.FC<React.PropsWithChildren<Props>> = ({
  period,
  definition,
  operator,
  value,
  range,
  onChange
}) => {
  const { range: defRange, inputSuffix, changeFn, periodOptions, transformValue } = definition;

  const wantsRange = operator === 'is_between';

  const onMaxChange = (value: string) => {
    const max = changeFn ? changeFn(value) : parseInt(value);
    onChange({ range: { min: range?.min, max } } as any);
  };

  const onMinChange = (value: string) => {
    const min = changeFn ? changeFn(value) : parseInt(value);
    onChange({ range: { min, max: range?.max } } as any);
  };

  return (
    <div className='min-w-60'>
      {wantsRange && (
        <div>
          <InputWithAddons
            type='number'
            prefix=''
            suffix={inputSuffix || ''}
            placeholder='Enter number'
            value={transformValue ? transformValue(range?.min) : String(range?.min || '')}
            onChange={onMinChange}
          />
          <Text h='400' color='gray-500' className='py-4'>
            and
          </Text>
          <InputWithAddons
            type='number'
            prefix=''
            suffix={inputSuffix || ''}
            placeholder='Enter number'
            value={transformValue ? transformValue(range?.max) : String(range?.max || '')}
            onChange={onMaxChange}
          />
        </div>
      )}
      {!wantsRange && (
        <InputWithAddons
          type='number'
          prefix=''
          suffix={inputSuffix || ''}
          placeholder='Enter number'
          value={transformValue ? transformValue(value) : String(value === null ? '' : value)}
          onChange={(value) => onChange({ value: changeFn ? changeFn(value) : parseInt(value as string) })}
        />
      )}
      {defRange && (
        <Text className='mt-4' h='400' color='gray-500'>
          Current range is {defRange.min.toLocaleString()} to {defRange.max.toLocaleString()}.
        </Text>
      )}
      {periodOptions && (
        <>
          <Text bold color='gray-500' h='200' className='mb-2 mt-4'>
            Period
          </Text>
          <Select onChange={(period) => onChange({ period })} value={period || 'all'} options={periodOptions} />
        </>
      )}
    </div>
  );
};
