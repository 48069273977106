import { EligibilityRule, EligibilityRulesResp } from '@components/GovernanceApp/components/Eligiblity/types';
import { FilterDefinition, FilterState } from '@components/shared/TableFilters/types';
import { parse as decode } from '@components/shared/TableFilters/utils/decode';
import { parse } from '@components/shared/TableFilters/utils/encode';
import { getDefaultValueCopy } from '@components/shared/TableFilters/utils/getDefaultValueCopy';
import { getOperatorCopy } from '@components/shared/TableFilters/utils/getOperatorCopy';
import { getPeriodCopy } from '@components/shared/TableFilters/utils/getPeriodCopy';

export const decodeRules = <D extends Record<string, any>>(
  rules: EligibilityRulesResp[],
  definitions: FilterDefinition<D>[]
): EligibilityRule[] => {
  const rulesArray = rules.map(({ label, title, description, filters: encoded }) => {
    const filters = encoded.map((filter) => decode<D>(filter, definitions)).filter(Boolean) as FilterState<D>[];

    return {
      name: label,
      title,
      description,
      filters: { op: 'all' as const, filters }
    };
  });

  return rulesArray;
};

type UpdateRulesArray = {
  oldArray: EligibilityRulesResp[];
  selected: EligibilityRule;
  updatedRule: EligibilityRulesResp;
};

export const updateRulesArray = ({ oldArray, selected, updatedRule }: UpdateRulesArray): EligibilityRulesResp[] => {
  const currentRuleIndex = oldArray.findIndex((r) => r.label === selected.name);

  const newRulesArray = [...oldArray];

  newRulesArray[currentRuleIndex] = updatedRule;

  return newRulesArray;
};

export const getFiltersString = (filters: FilterState<EligibilityRule>[]): string => {
  return filters
    .map((state) => {
      const name = state.definition.name;
      const operator = getOperatorCopy(state.definition.type, state.operator);
      const value = getDefaultValueCopy(state);
      const period = state.period ? ` ${getPeriodCopy(state.period)}` : '';

      return `${name} ${operator} ${value}${period}`;
    })
    .join(', ');
};
