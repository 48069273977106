import React, { createContext, FC, Reducer, useReducer } from 'react';

const store = createContext<UserStore>({} as UserStore);

const { Provider: ProviderEl } = store;

export const Provider: FC<React.PropsWithChildren<{ initialState: UserStore['state'] }>> = ({
  initialState,
  children
}) => {
  return <ProviderEl value={{ state: initialState }}>{children}</ProviderEl>;
};

export default store;
