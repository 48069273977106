import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Heading, Text } from '@components/common';

const OptedOutSVG: React.FC<React.PropsWithChildren<any>> = (props) => (
  <svg {...props} width='100' height='88' viewBox='0 0 100 88' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M99.2031 86.7416C99.0283 86.9861 98.8519 87.2289 98.6772 87.4698C98.175 87.8163 97.5794 88.0012 96.9695 88H3.03016C2.44756 88.0005 1.87731 87.8318 1.38845 87.5145C1.22621 87.3003 1.0658 87.0843 0.903564 86.8683L1.0658 86.779L48.622 60.3826C49.0439 60.1489 49.5181 60.026 50.0003 60.0254C50.4825 60.0248 50.957 60.1464 51.3796 60.379L99.0426 86.6523L99.2031 86.7416Z'
      fill='#5850EC'
    />
    <path
      d='M96.9697 31.2379L55.7543 7.40284C54.0208 6.40034 52.0544 5.87208 50.0526 5.8711C48.0508 5.87011 46.0839 6.39642 44.3494 7.39721L3.0303 31.2379C2.22697 31.239 1.45686 31.5591 0.888812 32.1279C0.320766 32.6967 0.00113643 33.4679 0 34.2724V84.9661C0.000935976 85.7706 0.3205 86.5419 0.888589 87.1107C1.45668 87.6796 2.2269 87.9996 3.0303 88.0005H96.9697C97.773 87.9992 98.5429 87.679 99.1109 87.1103C99.6789 86.5415 99.9986 85.7704 100 84.9661V34.2724C99.9989 33.4679 99.6792 32.6967 99.1112 32.1279C98.5431 31.5591 97.773 31.239 96.9697 31.2379ZM99.6435 84.9661C99.6418 85.6756 99.3595 86.3557 98.8585 86.8574C98.3574 87.3592 97.6783 87.6418 96.9697 87.6435H3.0303C2.32156 87.6423 1.64221 87.3598 1.14105 86.8579C0.639895 86.3561 0.357781 85.6758 0.356506 84.9661V34.2724C0.357369 33.5625 0.639348 32.882 1.1406 32.38C1.64184 31.8781 2.32143 31.5957 3.0303 31.5949L44.3494 7.75421C46.0839 6.75342 48.0508 6.22711 50.0526 6.22809C52.0544 6.22908 54.0208 6.75734 55.7543 7.75984L96.9697 31.5949C97.6786 31.5957 98.3582 31.8781 98.8594 32.38C99.3606 32.882 99.6426 33.5625 99.6435 34.2724V84.9661Z'
      fill='#9FA6B2'
    />
    <path
      d='M50.0534 59.9192C49.743 59.9202 49.4346 59.8684 49.1415 59.7659L49.0875 59.7469L49.0766 59.6268C49.043 59.6434 49.0091 59.6593 48.9752 59.6743L48.9393 59.69L48.9034 59.6742C48.8283 59.6411 48.7537 59.6037 48.6817 59.5631L15.8645 41.0968V3.39208C15.8655 2.51657 16.2132 1.67721 16.8315 1.05813C17.4497 0.439055 18.2879 0.0908264 19.1622 0.0898438H80.8378C81.7121 0.0908264 82.5503 0.439055 83.1685 1.05813C83.7867 1.67721 84.1345 2.51657 84.1354 3.39208V41.145L84.09 41.1707L51.4251 59.5632C51.0058 59.7973 50.5335 59.9199 50.0534 59.9192Z'
      fill='white'
    />
    <path
      d='M50.0534 60.0078C49.7329 60.0089 49.4145 59.9554 49.1119 59.8495L49.004 59.8115L48.9994 59.7605L48.9393 59.7869L48.8676 59.7553C48.7898 59.7211 48.7126 59.6824 48.638 59.6403L15.7754 41.1485V3.39148C15.7764 2.49232 16.1336 1.63027 16.7685 0.994467C17.4034 0.358661 18.2643 0.00101764 19.1622 0H80.8378C81.7357 0.00102141 82.5966 0.358665 83.2315 0.994469C83.8664 1.63027 84.2236 2.49232 84.2246 3.39148V41.1967L51.4688 59.6404C51.0361 59.882 50.5488 60.0085 50.0534 60.0078ZM49.338 59.5479C49.9934 59.7363 50.6965 59.6577 51.2942 59.3291L83.8681 40.9878V3.39148C83.8672 2.58696 83.5476 1.81566 82.9795 1.24678C82.4114 0.677898 81.6412 0.357904 80.8378 0.356998H19.1622C18.3588 0.3579 17.5885 0.677894 17.0204 1.24677C16.4524 1.81566 16.1328 2.58696 16.1319 3.39148V40.9395L18.2691 42.1414L48.8128 59.3292C48.8544 59.3527 48.8966 59.3747 48.9394 59.3954C48.9822 59.3747 49.0244 59.3527 49.0658 59.3293L49.3059 59.1939L49.338 59.5479Z'
      fill='#9FA6B2'
    />
    <path
      d='M99.2103 32.5542L86.2745 39.837L84.0463 41.0918L51.3815 59.4844C50.9755 59.7111 50.5183 59.8297 50.0534 59.8289C49.7531 59.8298 49.4548 59.7797 49.1711 59.6807C49.0922 59.6555 49.0148 59.6257 48.9394 59.5915C48.8663 59.5594 48.7951 59.5237 48.7255 59.4844L18.1818 42.2967L15.9537 41.0437L0.816413 32.5257C0.775253 32.502 0.745061 32.4631 0.732384 32.4173C0.719707 32.3716 0.725567 32.3226 0.748698 32.2811C0.771891 32.24 0.810444 32.2097 0.85589 32.197C0.901336 32.1843 0.949961 32.1902 0.991098 32.2133L15.9537 40.6331L18.1818 41.8862L48.9002 59.172C49.0021 59.2293 49.1081 59.2788 49.2174 59.3202C49.2638 59.338 49.312 59.3541 49.3601 59.3684C49.6652 59.4621 49.9861 59.4931 50.3035 59.4593C50.6209 59.4256 50.9281 59.3279 51.2068 59.172L84.0463 40.6831L86.2745 39.4282L99.0356 32.2437C99.056 32.2322 99.0784 32.2248 99.1016 32.222C99.1248 32.2192 99.1484 32.221 99.1709 32.2273C99.1934 32.2336 99.2144 32.2443 99.2328 32.2587C99.2512 32.2732 99.2666 32.2911 99.2781 32.3115C99.2895 32.3319 99.2969 32.3543 99.2997 32.3776C99.3025 32.4008 99.3007 32.4244 99.2944 32.4469C99.2881 32.4694 99.2774 32.4905 99.263 32.5089C99.2486 32.5274 99.2307 32.5428 99.2103 32.5542Z'
      fill='#9FA6B2'
    />
    <path
      d='M29.2469 11.7808H24.2559C23.8777 11.7808 23.5149 11.6303 23.2475 11.3625C22.9801 11.0947 22.8298 10.7315 22.8298 10.3528C22.8298 9.97407 22.9801 9.61085 23.2475 9.34305C23.5149 9.07525 23.8777 8.9248 24.2559 8.9248H29.2469C29.6252 8.9248 29.9879 9.07525 30.2553 9.34305C30.5227 9.61085 30.673 9.97407 30.673 10.3528C30.673 10.7315 30.5227 11.0947 30.2553 11.3625C29.9879 11.6303 29.6252 11.7808 29.2469 11.7808Z'
      fill='#5850EC'
    />
    <path
      d='M35.1293 16.6001H24.2559C23.8777 16.6001 23.5149 16.4497 23.2475 16.1819C22.9801 15.9141 22.8298 15.5509 22.8298 15.1721C22.8298 14.7934 22.9801 14.4302 23.2475 14.1624C23.5149 13.8946 23.8777 13.7441 24.2559 13.7441H35.1293C35.5075 13.7441 35.8702 13.8946 36.1376 14.1624C36.4051 14.4302 36.5553 14.7934 36.5553 15.1721C36.5553 15.5509 36.4051 15.9141 36.1376 16.1819C35.8702 16.4497 35.5075 16.6001 35.1293 16.6001Z'
      fill='#E5E7EB'
    />
    <path
      d='M65.508 29.4527H34.6702C34.292 29.4527 33.9292 29.3022 33.6618 29.0344C33.3944 28.7666 33.2441 28.4034 33.2441 28.0247C33.2441 27.6459 33.3944 27.2827 33.6618 27.0149C33.9292 26.7471 34.292 26.5967 34.6702 26.5967H65.508C65.8862 26.5967 66.2489 26.7471 66.5163 27.0149C66.7837 27.2827 66.934 27.6459 66.934 28.0247C66.934 28.4034 66.7837 28.7666 66.5163 29.0344C66.2489 29.3022 65.8862 29.4527 65.508 29.4527Z'
      fill='#5850EC'
    />
    <path
      d='M68.36 34.9859H31.6399C31.2617 34.9859 30.899 34.8354 30.6315 34.5676C30.3641 34.2998 30.2139 33.9366 30.2139 33.5579C30.2139 33.1791 30.3641 32.8159 30.6315 32.5481C30.899 32.2803 31.2617 32.1299 31.6399 32.1299H68.36C68.7382 32.1299 69.101 32.2803 69.3684 32.5481C69.6358 32.8159 69.7861 33.1791 69.7861 33.5579C69.7861 33.9366 69.6358 34.2998 69.3684 34.5676C69.101 34.8354 68.7382 34.9859 68.36 34.9859Z'
      fill='#E5E7EB'
    />
    <path
      d='M68.36 40.3413H31.6399C31.2617 40.3413 30.899 40.1909 30.6315 39.9231C30.3641 39.6553 30.2139 39.2921 30.2139 38.9133C30.2139 38.5346 30.3641 38.1714 30.6315 37.9036C30.899 37.6358 31.2617 37.4854 31.6399 37.4854H68.36C68.7382 37.4854 69.101 37.6358 69.3684 37.9036C69.6358 38.1714 69.7861 38.5346 69.7861 38.9133C69.7861 39.2921 69.6358 39.6553 69.3684 39.9231C69.101 40.1909 68.7382 40.3413 68.36 40.3413Z'
      fill='#E5E7EB'
    />
  </svg>
);

interface Props {
  accountName: string;
  handleOptIn: () => void;
  saving?: boolean;
}
export const OptedOut: React.FC<React.PropsWithChildren<Props>> = ({ accountName, saving, handleOptIn }) => {
  const { t } = useTranslation('OptedOut');

  return (
    <div className='tablet:border rounded-md border-gray-200 p-10 text-center'>
      <OptedOutSVG className='mx-auto mb-4' />
      <Heading className='mb-2 leading-normal'>{t('page_title')}</Heading>
      <Text h='400' className='mb-4'>
        {t('page_desc', { accountName: accountName })}
      </Text>
      <Button primary disabled={saving} onClick={handleOptIn}>
        {t('optin_cta')}
      </Button>
    </div>
  );
};
