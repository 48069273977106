import React, { useMemo, useState } from 'react';

import { Route, Routes } from 'react-router-dom';

import { NewInterview } from '@components/NewInterview';
import { useShowAiChatBubble } from '@stores/aiChat';
import { Provider as CollectionView } from 'components/stores/view';

import { SortOption } from './filters/sortOptions';
import { Context, RepositoryContext } from './hooks/useRepositoryContext';
import { RepositoryIndex } from './RepositoryIndex';

interface Props {
  study?: Study;
  setBackgroundTasks?: (backgroundTask: BackgroundTask) => void;
}

export const RepositoryApp: React.FC<React.PropsWithChildren<Props>> = ({ study, setBackgroundTasks }) => {
  useShowAiChatBubble(!study);
  const [selectedArtifacts, setSelectedArtifacts] = useState<string[]>([]);
  const [previewSlideout, setPreviewSlideout] = useState<Context['previewSlideout']>(null);
  const [key, setKey] = useState(0);

  const reload = () => setKey(key + 1);

  const context = useMemo<Context>(
    () => ({
      study,
      reload,
      selectedArtifacts,
      setSelectedArtifacts,
      previewSlideout,
      setPreviewSlideout,
      setBackgroundTasks
    }),
    [reload, selectedArtifacts, setSelectedArtifacts, previewSlideout, setPreviewSlideout, setBackgroundTasks]
  );

  if (study) {
    return (
      <CollectionView
        scope='repository'
        page={`study-${study.id}`}
        default={{ layout: 'grid', tab: 'insights', filters: '', sort: { value: 'updated_at', desc: false } }}
      >
        <RepositoryContext.Provider value={context}>
          <RepositoryIndex key={key} stickyHeader={false} />
        </RepositoryContext.Provider>
      </CollectionView>
    );
  }

  return (
    <Routes>
      <Route
        path='*'
        element={
          <>
            <CollectionView
              defaultable
              scope='repository'
              default={{
                layout: 'grid',
                tab: 'insights',
                filters: '',
                sort: { value: 'updated_at', desc: false }
              }}
            >
              <RepositoryContext.Provider value={context}>
                <RepositoryIndex key={key} />
              </RepositoryContext.Provider>
            </CollectionView>
            <Routes>
              <Route path='new' element={<NewInterview />} />
            </Routes>
          </>
        }
      />
    </Routes>
  );
};
