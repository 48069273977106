import * as React from 'react';

import { Text } from 'components/common';

import { daysToWholeUnits } from './utils';

interface Props {
  data: number;
  unit: string;
  size?: { width: number; height: number };
}

// function formatVale(value: string | number | null, unit: string): string {
//   if (unit == 'day') {
//   }
//   return value.toLocaleString();
// }

// display days and hours and minutes
export const NullNumberChart: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div className='flex flex-col items-center justify-center p-4'>
    <Text h='700'>-</Text>
    <Text h='400' mt='2'>
      No data
    </Text>
  </div>
);

const DaysNumberChart: React.FC<React.PropsWithChildren<{ data: number }>> = ({ data }) => {
  const dayData = daysToWholeUnits(data);
  if (dayData === null) {
    return <NullNumberChart />;
  }
  const dayDataStr = dayData
    .map((val, index) => {
      const unit = ['days', 'hours', 'mins'][index];
      return [val, unit];
    })
    .filter(([val]) => +val > 0)
    .slice(0, 2);
  return (
    <div className='flex flex-row items-center justify-center p-4'>
      {dayDataStr.map(([val, unit], index) => (
        <div className='flex flex-col items-center'>
          <Text h='700'>{val}</Text>
          <Text h='400' mt='2'>
            {unit}
          </Text>
        </div>
      ))}
    </div>
  );
};
const BasicNumberChart = ({ data, unit, size }) => {
  return (
    <div className='flex flex-col items-center justify-center p-4'>
      <Text h='700'>{data}</Text>
      <Text h='400' mt='2'>
        {unit}
      </Text>
    </div>
  );
};
export const NumberChart: React.FC<React.PropsWithChildren<Props>> = ({ data, unit, size }) => {
  if (data === null) {
    return <NullNumberChart />;
  } else if (unit == 'days') {
    return <DaysNumberChart data={data} />;
  } else {
    return <BasicNumberChart data={data} unit={unit} size={size} />;
  }
};
