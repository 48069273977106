import * as React from 'react';
import { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { Spinner } from '@components/common';
import { TagList } from '@components/tags';

import { useBulkUploadActions } from '../../../context';

import { CellFC } from './types';

export const Tags: CellFC = ({ row: { original: item }, tabIndex }) => {
  const { session } = item;

  const [updateRepoSession, { isSuccess, data }] = api.useUpdateRepoSessionMutation();
  const { setItem } = useBulkUploadActions();

  useEffect(() => {
    if (isSuccess) {
      setItem({ ...item, session: data });
    }
  }, [isSuccess]);

  const handleChange = (tagIds: number[]) => {
    updateRepoSession({
      uuid: session.uuid,
      tag_ids: tagIds
    });
    setItem({ ...item, tagIds });
  };

  return (
    <TagList
      tagIds={item.tagIds}
      previewShowLimit={2}
      onChange={handleChange}
      wrapperClassName='w-64'
      dropdownClassName='z-50'
      placement='bottom'
      className='flex h-10 items-center space-x-2 px-4'
      tabIndex={tabIndex}
      hideManagerLinks
    />
  );
};
