import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import useInterval from 'use-interval';

import { api } from '@api/reduxApi';
import { compact } from '@components/utils';

const DEFAULT_DELAY = 5000;

type Options = { delay?: number };

export const useRecordingPoller = (
  items: UploadManagerItem[],
  setItemRecording: (item: UploadManagerItem, recording: Recording) => void,
  setItemDone: (id: number) => void,
  opts: Options = {}
): void => {
  const [index, setIndex] = useState(0);
  const [queue, setQueue] = useState<UploadManagerItem[]>([]);

  const anythingToFetch = queue.length > 0;

  const item = anythingToFetch ? queue[index] : null;
  const id = item?.session?.recording?.id as any;
  const { data, isSuccess, isError } = api.useGetRecordingQuery({ id }, { skip: !anythingToFetch });

  const dispatch = useDispatch();

  useEffect(() => {
    if (anythingToFetch && ((isSuccess && data?.id === id) || isError)) {
      const newIndex = index + 1;
      if (newIndex >= queue.length) {
        setQueue([]);
        setIndex(0);
      } else {
        setIndex(newIndex);
      }

      dispatch(api.util.invalidateTags([{ type: 'Recording', id }]));

      if (isSuccess && data?.id === id) {
        setItemRecording(item as any, data as any);

        if (data?.mux_video?.status === 'ready') {
          setItemDone(item?.id as any);
        }
      }
    }
  }, [isSuccess, isError, data, queue, index, setItemRecording]);

  useInterval(() => {
    if (!anythingToFetch) {
      setQueue(items.filter(({ session }) => session.recording?.id));
    }
  }, opts.delay || DEFAULT_DELAY);
};
