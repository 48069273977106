import React, { HTMLAttributes } from 'react';

import cn from 'classnames';

export const BasicHeader: React.FC<React.PropsWithChildren<{ renderActions?: () => JSX.Element }>> = ({
  renderActions,
  children
}) => {
  return (
    <header className='z-10 border-b border-gray-200 bg-white px-12 py-4'>
      <section className='flex justify-between'>
        <div className='flex flex-grow flex-row items-center space-x-4'>{children}</div>
        <div className='flex flex-row items-center space-x-4'>{renderActions?.()}</div>
      </section>
    </header>
  );
};

export const PageLayout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className='desktop:h-screen desktop:mx-auto desktop:overflow-hidden flex min-h-screen flex-1 flex-col'>
    {children}
  </div>
);
export const PageBody: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <section className='desktop:flex-row desktop:h-screen relative flex w-full flex-col overflow-hidden bg-white'>
    {children}
  </section>
);
export const PageLeft = React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...rest }, ref) => (
    <section
      ref={ref}
      className={cn('desktop:flex-1 desktop:overflow-y-auto relative flex flex-col pb-6 pl-12 pr-6 pt-6', className)}
      {...rest}
    >
      {children}
    </section>
  )
);
export const PageRight: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className, children }) => (
  <section
    data-testid='page-right'
    className={cn('desktop:h-full desktop:border-l flex flex-col overflow-hidden border-gray-200', className)}
  >
    {children}
  </section>
);
