import * as React from 'react';

import { Text } from '@components/common';

type Props = {
  label: string;
};

export const FieldRow: React.FC<React.PropsWithChildren<Props>> = ({ children, label }) => (
  <div className='py-2'>
    <div className='flex items-start'>
      <div className='w-36 flex-shrink-0'>
        <Text h='400' bold className='py-2.5'>
          {label}
        </Text>
      </div>
      {children}
    </div>
  </div>
);
