import React, { useState } from 'react';

import SanitizedHTML from 'react-sanitized-html';

import { Dropdown, DropdownLink, Text } from '@components/common';

import { BusySVG, InfoSVG } from '../eventPopupSvgs';

interface Props {
  disabled?: boolean;
  description?: string;
  busy?: boolean;
  onChangeBusy?: (busy: boolean) => void;
}

export const DescAndStatus: React.FC<React.PropsWithChildren<Props>> = ({
  disabled,
  description,
  busy: initialBusy,
  onChangeBusy
}) => {
  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(initialBusy);

  const onChange = (v: boolean) => {
    setBusy(v);
    onChangeBusy?.(v);
    setOpen(false);
  };

  return (
    <>
      {description && (
        <div className='mb-6 flex'>
          <div className='mr-4 flex h-6 items-center'>
            <InfoSVG />
          </div>

          <SanitizedHTML className='max-h-40 overflow-y-auto whitespace-pre-line' html={description} />
        </div>
      )}

      <div className='flex'>
        <div className='mr-4 flex h-6 items-center'>
          <BusySVG />
        </div>

        {!onChangeBusy && <Text>{busy ? 'Busy' : 'Free'}</Text>}

        {onChangeBusy && (
          <Dropdown
            disabled={disabled}
            isOpen={open}
            onClick={() => setOpen(!open)}
            onClose={() => setOpen(false)}
            position='left'
            dropdownWidth='24'
            buttonClassName='w-24'
            className='bg-white'
            text={busy ? 'Busy' : 'Free'}
            aboveButton
          >
            <DropdownLink onClick={() => onChange(false)}>Free</DropdownLink>
            <DropdownLink onClick={() => onChange(true)}>Busy</DropdownLink>
          </Dropdown>
        )}
      </div>
    </>
  );
};
