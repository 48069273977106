import React, { FC, useState } from 'react';

import cn from 'classnames';

import { AIBots } from '@api/chat-gpt';
import { AskAiButton } from '@components/AiChat/components/AskAiButton';
import { Text } from '@components/common';
import { AvatarFromId } from '@components/common/Avatar';
import { ArtifactBreadcrumbs } from '@components/RepositoryApp/components/ArtifactBreadcrumbs';
import { Title } from '@components/shared/AI';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import { ParticipationPill } from '@components/shared/ParticipationPill';
import { SessionShareAccessDropdown } from '@components/shared/ShareAccess/SessionShareAccessDropdown';
import { UpdateModeratorModal } from '@components/StudiesApp/components/StudyPublished/components/UpdateModeratorModal';
import { ChangeUserSVG } from '@components/svgs';
import { TagList } from '@components/tags';
import { useAccount } from '@hooks/useAccount';
import { useDeviceType } from '@hooks/useDeviceType';
import { usePermission } from '@hooks/usePermission';
import { usePopUp } from '@hooks/usePopUp';

import { Views } from '../../shared/Views';

import { OptionsDropdown } from './components/OptionsDropdown';
import { EventDate, EventUrl, MarkDropdown, Participant } from './components';

export interface Props {
  study?: Study | null;
  title: string | null;
  owner_id: number | null;
  track: (event: string, data?: any) => void;
  candidate?: Candidate | null;
  participation?: Participation | null;
  markAsComplete?: () => void;
  markAsNoShow?: () => void;
  markAsCanceled?: () => void;
  onCandidateClick?: () => void;
  session?: RepoSession;
  canUpdate: boolean;
  updateSession: (params: Partial<Omit<RepoSession, 'uuid'>>) => void;
  aiChatContext: AiChatContext | null;
  onAddBackgroundTask: (backgroundTask: BackgroundTask) => void;
  renderBackgroundTask?: () => React.ReactElement;
}

export const Header: FC<React.PropsWithChildren<Props>> = ({
  study,
  participation,
  track,
  title: initialTitle,
  session,
  owner_id,
  candidate,
  markAsComplete,
  markAsNoShow,
  markAsCanceled,
  onCandidateClick,
  canUpdate,
  updateSession,
  aiChatContext,
  onAddBackgroundTask,
  renderBackgroundTask
}) => {
  const [updateModeratorModalOpen, setUpdateModeratorModalOpen] = useState(false);

  const canUpdateStudy = usePermission<Study>('updateStudy');
  const canChangeModerator = session?.study && session?.participation && canUpdateStudy(session.study);

  const {
    account: { team }
  } = useAccount();

  const {
    isMobile,
    isTablet,
    device: { select }
  } = useDeviceType();

  const { PopUp, togglePopUp, open, ref } = usePopUp();

  const onSetCandidate = (candidate: Candidate) => updateSession({ candidate_id: candidate.id });

  const trackViewsModal = () => {
    track('page_views', { page: 'repo_session' });
  };

  const moderator = team.find(({ name, id }) => participation?.moderator_id === id);
  const owner = team.find(({ name, id }) => owner_id === id);

  return (
    <header className='desktop:px-12 z-30 border-b border-gray-200 bg-white px-4 pt-4'>
      <ArtifactBreadcrumbs shortened={isMobile} artifactTitle={initialTitle ?? ''} study={study} />
      <div className='flex items-start items-center justify-between'>
        <Title<{ repo_session_uuid?: string }>
          readOnly={!canUpdate}
          disabled={!canUpdate}
          bot={AIBots.SessionTitle}
          context={{ repo_session_uuid: session?.uuid }}
          onSave={(value) => updateSession({ title: value })}
          value={initialTitle ?? ''}
          disableAi
        />
        <div className='flex flex-nowrap items-center justify-end space-x-3'>
          {session?.uuid && <AskAiButton pageContext={aiChatContext} />}
          <CopyURLButton className='h-10' icon='link' text={window.location.href} />
          {session && !isMobile && !isTablet && <Views views={session.views} onOpen={trackViewsModal} />}
          {session && (
            <SessionShareAccessDropdown uuid={session.uuid} value={session.access} readOnly={!canUpdateStudy} />
          )}

          {!isMobile && (
            <div className='flex space-x-3'>
              {participation?.status === 'booked' && canUpdate && (
                <MarkDropdown
                  markAsComplete={markAsComplete}
                  markAsNoShow={markAsNoShow}
                  markAsCanceled={markAsCanceled}
                />
              )}
              {session && <OptionsDropdown session={session} />}
            </div>
          )}
        </div>
      </div>
      <div className='desktop:flex-row desktop:items-center mb-2 flex flex-col'>
        <div className='desktop:w-auto w-full overflow-hidden'>
          <div className='flex items-center overflow-hidden'>
            {participation && <ParticipationPill participation={participation} />}

            {(isMobile || isTablet) && (
              <TagList
                readOnly={!canUpdate}
                tagIds={session?.tag_ids ?? []}
                onChange={(tag_ids) => updateSession({ tag_ids })}
                className='focus:outline-none flex h-10 w-full flex-nowrap items-center space-x-2 overflow-hidden px-4 hover:bg-gray-50 focus:bg-gray-50'
                previewShowLimit={select({ DESKTOP_L: 5, DESKTOP_M: 3, TABLET: 3, MOBILE: 2 })}
                placement={isMobile ? 'bottom-end' : 'bottom-start'}
                studyId={study?.id}
              />
            )}
          </div>
        </div>
        <div className='flex flex-wrap items-center justify-start space-x-4'>
          {(moderator || owner) && (
            <div className='relative'>
              <div
                className={cn('xx-session-moderator flex items-center', {
                  'cursor-pointer hover:text-indigo-500': canChangeModerator,
                  'ml-4': !isTablet && !isMobile
                })}
                onClick={() => canChangeModerator && togglePopUp()}
              >
                <AvatarFromId size='sm' userId={moderator?.id || owner?.id} />
                <Text h='400' className='ml-1 leading-5'>
                  {moderator?.name || owner?.name}
                </Text>
              </div>
              {canChangeModerator && (
                <PopUp ref={ref} open={open} className='top-8 rounded-md bg-white py-3 shadow-lg'>
                  <Text medium className='mb-3 px-3 text-xs' color='gray-500'>
                    MODERATOR
                  </Text>
                  <div
                    className='xx-change-moderator flex cursor-pointer items-center px-3 py-1 hover:bg-indigo-50 hover:text-indigo-600'
                    onClick={(e) => setUpdateModeratorModalOpen(true)}
                  >
                    <ChangeUserSVG className='mr-2' />
                    <span className='text-sm'>Change Moderator</span>
                  </div>
                </PopUp>
              )}
            </div>
          )}
          <Participant
            editable={canUpdate && !participation}
            participationId={participation?.id}
            candidate={candidate}
            onCandidateClick={onCandidateClick}
            onSetCandidate={onSetCandidate}
          />
          {session?.session_at && <EventDate startTime={session?.session_at} />}
          {participation && session && <EventUrl participation={participation} session={session} />}
          {!isMobile && !isTablet && (
            <TagList
              readOnly={!canUpdate}
              tagIds={session?.tag_ids ?? []}
              onChange={(tag_ids) => updateSession({ tag_ids })}
              className='focus:outline-none flex h-10 w-full flex-nowrap items-center space-x-2 overflow-hidden px-4 hover:bg-gray-50 focus:bg-gray-50'
              previewShowLimit={select({ DESKTOP_L: 5, DESKTOP_M: 3, TABLET: 3, MOBILE: 2 })}
              placement={isMobile ? 'bottom-end' : 'bottom-start'}
              studyId={study?.id}
            />
          )}
        </div>
      </div>
      {session?.study && session.participation && updateModeratorModalOpen && (
        <UpdateModeratorModal
          study={session.study}
          participations={[session.participation]}
          onClose={() => setUpdateModeratorModalOpen(false)}
          onSuccess={(backgroundTask) => {
            onAddBackgroundTask(backgroundTask);
            setUpdateModeratorModalOpen(false);
          }}
        />
      )}
      {renderBackgroundTask?.()}
    </header>
  );
};
