import * as React from 'react';

import pluralize from 'pluralize';

import { Alert } from '@components/common';

export const BatchingRequiredAlert: React.FC<React.PropsWithChildren<{ count: number; event: string }>> = ({
  count,
  event
}) => (
  <Alert type='warning'>
    Due to email sending limits, please send your {count.toLocaleString()} {pluralize(event, count)} in batches.
  </Alert>
);
