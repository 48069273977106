import * as React from 'react';

import { SearchSVG } from '@components/svgs';

interface Props {
  value?: string;
  setValue: (value: string) => void;
  mb?: number;
}

export const FilterSearch: React.FC<React.PropsWithChildren<Props>> = ({ value = '', setValue, mb = 4 }) => {
  return (
    <div className={`relative flex items-center mb-${mb}`}>
      <SearchSVG className='absolute left-4 text-indigo-600' />
      <input
        className='tablet:text-sm w-full rounded-md border border-gray-200 py-1.5 pl-10 focus:border-indigo-600 focus:ring-indigo-600'
        placeholder='Search...'
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};
