import * as React from 'react';

import { Button, Modal, Text } from '@components/common';
import { RadioInput } from '@components/common/RadioGroup';

interface Props {
  onClose: (v) => void;
  onConfirm: () => Promise<void>;
  setUpdateCurrentTemplate: (v: boolean) => void;
  updateCurrentTemplate: boolean;
  type: 'sender' | 'template';
  isLoading: boolean;
}

const LABELS = {
  title: { sender: 'Confirm email sender change', template: 'Edit email template' },
  subtitle: { sender: 'Decide how the change gets applied.', template: 'Note: This will not send the email.' },
  options: {
    sender: [
      {
        label: 'Apply to just this email',
        value: 'no'
      },
      {
        label: 'Apply to all emails on this study',
        value: 'yes'
      }
    ],
    template: [
      {
        label: 'This email only',
        value: 'no'
      },
      {
        label: 'This email and the email template on this study',
        value: 'yes'
      }
    ]
  },
  buttons: {
    sender: {
      cancel: 'Back to editing',
      confirm: 'Confirm & save'
    },
    template: {
      cancel: 'Cancel',
      confirm: 'Confirm'
    }
  }
};

export const UpdateModal: React.FC<React.PropsWithChildren<Props>> = ({
  onClose,
  onConfirm,
  setUpdateCurrentTemplate,
  updateCurrentTemplate,
  type,
  isLoading
}) => {
  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <Modal
      onClose={onClose}
      renderFooter={() => (
        <div className='flex w-full flex-row justify-end space-x-4'>
          <Button onClick={onClose}>{LABELS.buttons[type].cancel}</Button>
          <Button loading={isLoading} primary onClick={onConfirm}>
            {LABELS.buttons[type].confirm}
          </Button>
        </div>
      )}
    >
      <h3 className='mb-2 text-xl font-bold text-gray-700'>{LABELS.title[type]}</h3>
      <Text h='400' color='gray-500'>
        {LABELS.subtitle[type]}
      </Text>
      <section className='mb-6 mt-5 space-y-3'>
        {LABELS.options[type].map(({ label, value }, i) => (
          <label key={label} className='flex cursor-pointer flex-row space-x-3'>
            <div className='flex h-5 items-center'>
              <RadioInput
                id={value}
                value={String(value)}
                onChange={(e) => {
                  setUpdateCurrentTemplate(e.currentTarget.value === 'yes');
                }}
                checked={value === 'yes' ? updateCurrentTemplate : !updateCurrentTemplate}
              />
            </div>
            <div className='flex flex-col'>
              <Text h='400'>{label}</Text>
            </div>
          </label>
        ))}
      </section>
    </Modal>
  );
};
