import * as React from 'react';
import { useState } from 'react';

import pluralize from 'pluralize';
import { FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Dropdown, DropdownLink, Text } from '@components/common';
import { DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { PageHeader } from '@components/shared/PageHeader';
import { EmailTemplateIcon, InterviewTemplateIcon, SurveyTemplateIcon } from '@components/svgs';
import { useCreateTemplate } from '@components/TemplatesApp/hooks/useCreateTemplate';
import { EditEmailTemplate } from '@components/TemplatesApp/pages/emails/edit';
import { PageTitle } from 'components/common/helmets';

export const TemplatesHome: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [createMenuOpen, setCreateMenuOpen] = useState<boolean>();

  const { create: createInterviewTemplate, creating: interviewIsCreating } = useCreateTemplate<InterviewTemplate>({
    createMutation: api.useCreateInterviewTemplateMutation as any,
    kind: 'InterviewTemplate'
  });

  const { create: createSurveyTemplate, creating: surveyIsCreating } = useCreateTemplate<SurveyTemplate>({
    createMutation: api.useCreateSurveyTemplateMutation as any,
    kind: 'SurveyTemplate'
  });

  const { creating, emailTemplate, showSlideOut, setShowSlideOut, methods, setEmailTemplate } =
    useCreateTemplate<EmailTemplate>({
      createMutation: api.useCreateEmailTemplateMutation as any,
      kind: 'EmailTemplate'
    });

  const { data: interviewTemplates } = api.useGetInterviewTemplatesQuery();

  const { data: emailTemplates } = api.useGetEmailTemplatesQuery();

  const { data: surveyTemplates } = api.useGetSurveyTemplatesQuery();

  const onEmailCreate = () => {
    setEmailTemplate(null);
    setShowSlideOut(true);
  };

  return (
    <DashboardLayout>
      <PageTitle>Templates</PageTitle>
      <PageHeader
        h1='Templates'
        hideDefaultButtons
        renderCta={() => (
          <div className='space-x-4'>
            <Dropdown
              primary
              medium
              text='Create a template'
              isOpen={createMenuOpen}
              onClick={() => setCreateMenuOpen(true)}
              onClose={() => setCreateMenuOpen(false)}
            >
              <DropdownLink disabled={interviewIsCreating} onClick={createInterviewTemplate as any}>
                Interview
              </DropdownLink>
              <DropdownLink disabled={creating} onClick={onEmailCreate}>
                Email
              </DropdownLink>
              <DropdownLink disabled={surveyIsCreating} onClick={createSurveyTemplate as any}>
                Survey
              </DropdownLink>
            </Dropdown>
          </div>
        )}
      />

      <DashboardLayoutBody>
        <div className='tablet:flex-row tablet:space-x-6 flex w-full flex-col'>
          <Link
            to='interviews'
            className='flex-1 border border-gray-200 bg-white p-6 text-center text-black hover:border-indigo-600 hover:bg-gray-50'
          >
            <div className='mb-4 flex justify-center'>
              <InterviewTemplateIcon />
            </div>
            <Text h='600' className='mb-2'>
              Interview guide
            </Text>
            <Text h='400' className='mb-8'>
              For customer interview note-taking, discussion guides, or summaries.
            </Text>
            {interviewTemplates && (
              <Text h='500' className='inline-block w-full rounded border border-gray-200 p-2'>
                {interviewTemplates.length} {pluralize('template', interviewTemplates.length)}
              </Text>
            )}
          </Link>

          <Link
            to='emails'
            className='flex-1 border border-gray-200 bg-white p-6 text-center text-black hover:border-indigo-600 hover:bg-gray-50'
          >
            <div className='mb-4 flex justify-center'>
              <EmailTemplateIcon />
            </div>
            <Text h='600' className='mb-2'>
              Email
            </Text>
            <Text h='400' className='mb-8'>
              For outreach to participants at different stages of the research process.
            </Text>
            {emailTemplates && (
              <Text h='500' className='inline-block w-full rounded border border-gray-200 p-2'>
                {emailTemplates.length} {pluralize('template', emailTemplates.length)}
              </Text>
            )}
          </Link>

          <Link
            to='surveys'
            className='flex-1 border border-gray-200 bg-white p-6 text-center text-black hover:border-indigo-600 hover:bg-gray-50'
          >
            <div className='mb-4 flex justify-center'>
              <SurveyTemplateIcon />
            </div>
            <Text h='600' className='mb-2'>
              Survey
            </Text>
            <Text h='400' className='mb-8'>
              For collecting information, getting roadmap feedback, and more.
            </Text>
            {surveyTemplates && (
              <Text h='500' className='inline-block w-full rounded border border-gray-200 p-2'>
                {surveyTemplates.length} {pluralize('template', surveyTemplates.length)}
              </Text>
            )}
          </Link>
        </div>
      </DashboardLayoutBody>

      {showSlideOut && (
        <FormProvider {...methods}>
          <EditEmailTemplate emailTemplate={emailTemplate} onClose={() => setShowSlideOut(false)} />
        </FormProvider>
      )}
    </DashboardLayout>
  );
};
