import * as React from 'react';
import { useContext, useState } from 'react';

import DatePicker from 'react-datepicker';
import tinytime from 'tinytime';

import { GridContext } from '@components/CandidatesApp/CandidatesIndex/CandidatesListPage';
import { EditableCell } from '@components/shared/GridTable/components/inputs/types';
import { CellWrapper } from '@components/shared/GridTable/components/shared';
import { getStateKey, onSelectValue } from '@components/shared/GridTable/components/utils';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

export const DateCell: EditableCell = (props) => {
  const { state, setState } = useContext(GridContext);

  const { value: initialValue, row, column, updateCellData } = props;

  const stateKey = getStateKey(row);

  const [editing, setEditing] = useState(false);

  const onSave = async (currentValue) => {
    setEditing(false);

    await onSelectValue({
      currentValue,
      initialValue,
      rowId: String(stateKey),
      setState,
      updateCellData,
      columnId: column.id
    });
  };

  const cellValue = () => {
    if (state?.[stateKey]?.[column?.id]) {
      return dateTemplate.render(new Date(state?.[stateKey]?.[column?.id]));
    }
    if (initialValue && initialValue !== 'null') {
      const date = new Date(initialValue);
      if (!Number.isNaN(date.valueOf())) {
        return dateTemplate.render(date);
      }
    }
    return '';
  };

  if (!editing) {
    return (
      <CellWrapper
        wrapperClass='cursor-pointer hover:bg-indigo-50 hover:text-indigo-600'
        onClick={() => setEditing(true)}
      >
        {cellValue()}
      </CellWrapper>
    );
  }

  return (
    <DatePicker
      autoFocus
      onChange={onSave}
      onBlur={() => setEditing(false)}
      className='h400 outline-none h-full w-full rounded-md border border-transparent px-1 py-3 focus:border-indigo-600'
      placeholderText='Enter...'
      startOpen={true}
      value={cellValue()}
      popperPlacement='bottom-start'
      customInput={<input />}
      showYearDropdown
    />
  );
};
