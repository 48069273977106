import React, { FC, useEffect, useMemo } from 'react';

import cn from 'classnames';
import { HeaderGroup, Row } from 'react-table';
import { VirtualItem, Virtualizer } from '@tanstack/virtual-core';

interface Props extends Pick<Virtualizer<Element, Element>, 'resizeItem'> {
  headers: HeaderGroup[];
  rows: Row[];
  virtualColumn: VirtualItem;
  virtualRow: VirtualItem;
}

export const GridCell: FC<Props> = ({ headers, rows, virtualColumn, virtualRow, resizeItem }) => {
  const header = headers[virtualColumn.index];

  const row = useMemo(() => {
    // skip the extra added row for headers
    if (virtualRow.index === 0) {
      return null;
    }

    return rows[virtualRow.index - 1];
  }, [headers, rows, virtualRow.index]);

  const cell = useMemo(() => {
    if (row) {
      return row.cells[virtualColumn.index];
    }

    return null;
  }, [headers, row, virtualColumn.index]);

  const style = useMemo(
    () => ({
      position: 'absolute',
      width: `${virtualColumn.size}px`,
      height: `${virtualRow.size}px`,
      top: virtualRow.start,
      left: virtualColumn.start
    }),
    [virtualColumn, virtualRow]
  );

  useEffect(() => {
    resizeItem(virtualColumn.index, header.width);
  }, [header.width]);

  return (
    <div
      style={style}
      className={cn('flex items-center border-b border-gray-200 bg-white', {
        'xx-first-grid': virtualRow.index > 0 && virtualColumn.index === 0
      })}
      {...(virtualRow.index !== 0 && { role: 'cell' })}
    >
      {virtualRow.index === 0 ? (
        <>
          <div
            className='absolute right-0 top-0 z-10 inline h-full px-1 hover:bg-indigo-600'
            {...header.getResizerProps()}
          />

          {header.render('Header')}
        </>
      ) : (
        <>{cell ? cell.render('Cell') : null}</>
      )}
    </div>
  );
};
