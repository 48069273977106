import * as React from 'react';

import { LinkSVG, LocationSVG } from '@components/svgs';

interface Props {
  option: MeetingProvider;
  className?: string;
}

export const MeetingProviderIcon: React.FC<React.PropsWithChildren<Props>> = ({
  option,
  className = 'w-6 h-6 mr-3'
}) => {
  if (option.name == 'In Person') {
    return <LocationSVG className={className} />;
  } else if (option.name == 'Custom link') {
    return <LinkSVG className={className} />;
  } else {
    return <img alt='' className={className} src={option.logo_path} />;
  }
};
