import * as React from 'react';
import { useMemo, useRef, useState } from 'react';

import { useCombobox } from 'downshift';
import { useDebouncedCallback } from 'use-debounce';

import cn from 'classnames';

import { api } from 'api/reduxApi';
import { CaretDownSVG } from 'components/svgs';

import { Spinner } from '@components/common';
import { Error } from '@components/shared/AI';

import { UserNameTag, UserInfo } from './UserNameTag';

export type SenderComboboxItem = EmailSender & Pick<TeamUser, 'img' | 'errors' | 'email_integration_id'>;

type Props = {
  placeholder?: string | React.ReactNode;
  onSelect: (items: EmailSender[]) => void;
  context?: UserSuggestionContext;
  showCurrentlySelected?: EmailSender;
  errorMessage?: string;
};
export const SenderCombobox: React.FC<React.PropsWithChildren<Props>> = ({
  placeholder,
  onSelect,
  showCurrentlySelected,
  errorMessage: parentErrorMessage,
  context
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [query, setQuery] = useState<string>();
  const { callback } = useDebouncedCallback((value) => {
    setQuery(value);
  }, 200);
  const { data: usersSuggestionsData = [], isFetching: isFetchingUserSuggestions } = api.useGetUsersSuggestionsQuery({
    query,
    context
  });
  const { data: emailSendersData = [], isFetching: isFetchingEmailSenders } = api.useGetEmailSendersQuery({ query });
  const isFetching = isFetchingUserSuggestions || isFetchingEmailSenders;

  const castedUserSuggestionData: SenderComboboxItem[] = useMemo(() => {
    const castedUserSuggestionData = usersSuggestionsData.map((user) => ({
      id: user.email_integration_id || null,
      user_id: user.id,
      email: user.email,
      errors: user.errors,
      first_name: user.first_name,
      name: user.name,
      delivery_method: user.email === 'support@greatquestion.co' ? 'vero' : 'nylas'
    })) as SenderComboboxItem[];
    return castedUserSuggestionData;
  }, [usersSuggestionsData]);

  const senderSuggestions: SenderComboboxItem[] = useMemo(() => {
    if (showCurrentlySelected) {
      return [
        showCurrentlySelected,
        ...castedUserSuggestionData.filter((emailSender) => emailSender.email !== showCurrentlySelected.email),
        ...emailSendersData.filter((emailSender) => emailSender.email !== showCurrentlySelected.email)
      ];
    }

    return [...castedUserSuggestionData, ...emailSendersData];
  }, [castedUserSuggestionData, emailSendersData, showCurrentlySelected]);

  const {
    isOpen,
    openMenu,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
    setInputValue,
    reset
  } = useCombobox<EmailSender>({
    items: senderSuggestions,
    onSelectedItemChange: ({ selectedItem }) => {
      inputRef.current?.blur();
      reset();
      closeMenu();
      if (selectedItem) {
        onSelect([selectedItem]);
      }
    },
    itemToString: (item) => item?.name || '',
    onInputValueChange: ({ inputValue }) => {
      callback(inputValue);
      if (errorMessage) {
        setErrorMessage(parentErrorMessage);
      }
    },
    onIsOpenChange: ({ isOpen }) => {
      if (!isOpen) {
        setInputValue('');
      }
    }
  });

  const inputProps = getInputProps({
    ref: inputRef,
    onFocus: openMenu
  });

  const wrapperClass = cn('relative', { 'w-full': true });

  const dropdownVisible = isOpen && !isFetchingUserSuggestions;

  const dropdownClass = cn('xx-user-combobox absolute z-40 overflow-y-auto w-full rounded-b-md', {
    'bg-white pt-2 pb-2 divide-y divide-y-gray-200 border border-gray-200 shadow-lg': dropdownVisible
  });

  const inputPlaceholder = typeof placeholder === 'string' ? placeholder : '';

  const errorMessageToDisplay = parentErrorMessage || errorMessage;

  return (
    <div ref={ref} className={wrapperClass}>
      <div {...getComboboxProps()}>
        <input
          {...inputProps}
          className={cn(
            'h400 focus:outline-none w-full rounded-md border border-gray-200 px-4 py-2.5 text-gray-700 placeholder-gray-400 focus:border-indigo-600',
            { 'border-red-600': !!errorMessageToDisplay }
          )}
          placeholder={inputPlaceholder}
          autoComplete='off'
          aria-label='Select sender'
          name='dropdown_combobox'
        />
        {!!placeholder && typeof placeholder !== 'string' && !isOpen && (
          <div className='pointer-events-none absolute inset-0 flex items-center justify-between'>
            {placeholder}
            <CaretDownSVG className='mr-2' />
          </div>
        )}
      </div>
      <ul {...getMenuProps()} className={dropdownClass}>
        {isOpen && isFetching && (
          <li className='group flex h-52 w-full items-center justify-center bg-white'>
            <Spinner className='h-4 w-4 text-indigo-600' />
          </li>
        )}
        {dropdownVisible &&
          senderSuggestions.map((item, index) => {
            const userInfo = {
              ...item,
              id: item?.user_id
            } as UserInfo;

            return (
              <UserNameTag
                key={index}
                user={userInfo}
                index={index}
                highlightedIndex={highlightedIndex}
                getItemProps={getItemProps}
                showCurrentlySelected={showCurrentlySelected}
              />
            );
          })}
      </ul>
      {errorMessageToDisplay && <Error className='mt-3'>{errorMessageToDisplay}</Error>}
    </div>
  );
};
