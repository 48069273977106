import * as React from 'react';
import { useMemo } from 'react';

import { Column, Row } from 'react-table';
import tinytime from 'tinytime';
import { ELocalStorageKeys } from '@constants/localStorageKeys';

import HealthStatus from '@components/CandidatesApp/CandidatesIndex/HealthStatus';
import { CORE_ATTRS } from '@components/config';
import { CellWrapper, NameCell, NameHeader } from '@components/shared/GridTable/components';
import { BooleanCell } from '@components/shared/GridTable/components/inputs/BooleanCell';
import { DateCell } from '@components/shared/GridTable/components/inputs/DateCell';
import { FreeTextCell } from '@components/shared/GridTable/components/inputs/FreeTextCell';
import { MultipleChoiceCell } from '@components/shared/GridTable/components/inputs/MultipleChoiceCell';
import { NumberCell } from '@components/shared/GridTable/components/inputs/NumberCell';
import { SingleChoiceCell } from '@components/shared/GridTable/components/inputs/SingleChoiceCell';
import { TextCell } from '@components/shared/GridTable/components/inputs/TextCell';
import { OtherHeader as Header } from '@components/shared/GridTable/components/OtherHeader';
import { ATTR_TO_SORT_ICON_MAPPING } from '@components/shared/GridTable/components/OtherHeader/constants';
import { getFormattedDate, getFormattedMoney } from '@components/shared/GridTable/components/utils';
import { OnHover, OnToggle } from '@components/shared/GridTable/types';
import { FilterDefinition } from '@components/shared/TableFilters/types';
import { compact } from '@components/utils';
import { ContactAccessCell } from 'components/shared/GridTable/components/inputs/ContactAccessCell';

interface BuildCandidatesColumnsProps {
  columnsWidth: Record<string, number> | null;
  customAttrs?: Attr_[];
  canPII: boolean;
  onClickCandidate: (c: Candidate) => void;
  handleToggleCheckBox: OnToggle;
  resetOnClick: () => void;
  handleMouseOver: OnHover;
  hoveredRows: number[];
  setSortValue: (value: string, desc: boolean) => void;
  pageCount?: number;
  setShowSelectAll?: (value: boolean) => void;
  setAllSelected?: (value: boolean) => void;
  enableTeams?: boolean;
  teams?: Team[];
  addFilter: (filter: string) => void;
  definitions: FilterDefinition<Candidate>[];
}

type BuildCandidatesColumns = (props: BuildCandidatesColumnsProps) => Column<Candidate>[];

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

const getCoreAttrDesc = (key) => CORE_ATTRS.find((attr) => attr.name === key)?.description;

export const buildCandidatesColumns: BuildCandidatesColumns = ({
  columnsWidth,
  customAttrs,
  canPII,
  onClickCandidate,
  handleToggleCheckBox,
  resetOnClick,
  handleMouseOver,
  hoveredRows,
  setSortValue,
  pageCount,
  setShowSelectAll,
  setAllSelected,
  enableTeams,
  teams,
  definitions,
  addFilter
}) => {
  const OtherHeader = (props) => {
    const definition = definitions.find((d) => d.id === props.column.id);

    return (
      <Header<Candidate>
        {...props}
        addFilter={definition ? addFilter : undefined}
        storageKey={ELocalStorageKeys.CANDIDATES_TABLE_ORDER}
        setSortValue={setSortValue}
      />
    );
  };

  const NameColumnHeader = (props) => (
    <NameHeader
      {...props}
      pageCount={pageCount || 1}
      setAllSelected={setAllSelected}
      setShowSelectAll={setShowSelectAll}
      resetOnClick={resetOnClick}
      setSortValue={setSortValue}
    />
  );

  const CandidateNameCell = (props) => {
    const {
      setSelectedRowIds,
      state: { selectedRowIds }
    } = props;

    const selectedIds = useMemo(() => Object.keys(selectedRowIds).map((str) => parseInt(str)), [selectedRowIds]);

    const setCandidates = ({ startIndex, endIndex, row, rows }) => {
      // create array from row indexes IN THE RANGE
      const rowIndexes = Array.from({ length: endIndex - startIndex + 1 }, (_, index) => startIndex + index);
      // get the ids of the participations in the range
      const ids = rowIndexes.map((i) => (rows[i] as Row<Candidate>).original.id);
      // create a new array from the selected ids and existing ids
      const newIds = row.isSelected ? selectedIds.filter((id) => !ids.includes(id)) : [...selectedIds, ...ids];
      // transform array to object with ids as keys
      const selectedRows = newIds.reduce((a, v) => ({ ...a, [v]: true }), {});

      setSelectedRowIds(selectedRows);
    };

    return (
      <NameCell
        {...props}
        setSelectedIds={setCandidates}
        onClickCandidate={onClickCandidate}
        onToggle={handleToggleCheckBox}
        handleMouseOver={handleMouseOver}
        hoveredRows={hoveredRows}
      />
    );
  };

  return compact([
    {
      accessor: 'name',
      Header: (props) => <NameColumnHeader {...props} description={getCoreAttrDesc('name')} />,
      headerLabel: 'Name',
      Cell: CandidateNameCell,
      width: columnsWidth?.name || 250
    },
    {
      accessor: 'opted_in',
      Header: (props) => <OtherHeader {...props} description={getCoreAttrDesc('opted_in')} />,
      headerLabel: 'Opt-in',
      Cell: BooleanCell,
      width: columnsWidth?.opted_in || 80
    },
    {
      accessor: 'consented_at',
      Header: (props) => <OtherHeader {...props} description={getCoreAttrDesc('consented_at')} />,
      headerLabel: 'Opt-in date',
      Cell: (props) => <TextCell {...props} value={getFormattedDate(props.row.original.consented_at)} />,
      sortIconType: 'date',
      width: columnsWidth?.consented_at || 120
    },
    {
      accessor: 'created_at',
      Header: (props) => <OtherHeader {...props} description={getCoreAttrDesc('created_at')} />,
      headerLabel: 'Join date',
      Cell: (props) => <TextCell {...props} value={getFormattedDate(props.row.original.created_at)} />,
      sortIconType: 'date',
      width: columnsWidth?.created_at || 120
    },
    canPII
      ? {
          accessor: 'email',
          Header: (props) => <OtherHeader {...props} description={getCoreAttrDesc('email')} />,
          headerLabel: 'Email',
          Cell: SingleChoiceCell,
          width: columnsWidth?.email || 200
        }
      : false,
    {
      accessor: 'timezone',
      Header: (props) => <OtherHeader {...props} description={getCoreAttrDesc('timezone')} />,
      headerLabel: 'Timezone',
      Cell: (props) => <TextCell {...props} />,
      width: columnsWidth?.timezone || 200
    },
    {
      accessor: 'phone_number',
      Header: (props) => <OtherHeader {...props} description={getCoreAttrDesc('phone_number')} />,
      headerLabel: 'Phone number',
      Cell: SingleChoiceCell,
      width: columnsWidth?.phone_number || 200,
      sortIconType: 'number'
    },
    {
      accessor: 'participations_count',
      Header: OtherHeader,
      headerLabel: '# of studies',
      Cell: (props) => <TextCell {...props} />,
      width: columnsWidth?.participations_count || 80,
      sortIconType: 'number'
    },
    {
      accessor: 'average_rating',
      Header: OtherHeader,
      headerLabel: 'Average rating',
      Cell: (props) => <TextCell {...props} />,
      width: columnsWidth?.average_rating || 150,
      sortIconType: 'number'
    },
    {
      accessor: 'completed_participations_count',
      Header: OtherHeader,
      headerLabel: '# of completed studies',
      Cell: (props) => <TextCell {...props} />,
      width: columnsWidth?.completed_participations_count || 80,
      sortIconType: 'number'
    },
    {
      accessor: 'invited_participations_count',
      Header: OtherHeader,
      headerLabel: '# of invited studies',
      Cell: (props) => <TextCell {...props} />,
      width: columnsWidth?.invited_participations_count || 80,
      sortIconType: 'number'
    },
    {
      accessor: 'responded_participations_count',
      Header: OtherHeader,
      headerLabel: '# of responded studies',
      Cell: (props) => <TextCell {...props} />,
      width: columnsWidth?.responded_participations_count || 80,
      sortIconType: 'number'
    },
    {
      accessor: 'all_time_usd_amount_in_cents',
      Header: OtherHeader,
      headerLabel: 'Incentives Paid (All Time)',
      Cell: (props) => (
        <TextCell {...props} value={getFormattedMoney(props.row.original.all_time_usd_amount_in_cents)} />
      ),
      width: columnsWidth?.all_time_usd_amount_in_cents || 80,
      sortIconType: 'number'
    },
    {
      accessor: 'current_year_usd_amount_in_cents',
      Header: OtherHeader,
      headerLabel: 'Incentives Paid (Current Year)',
      Cell: (props) => (
        <TextCell {...props} value={getFormattedMoney(props.row.original.current_year_usd_amount_in_cents)} />
      ),
      width: columnsWidth?.current_year_usd_amount_in_cents || 80,
      sortIconType: 'number'
    },
    enableTeams && {
      accessor: 'team_ids',
      Header: (props) => <OtherHeader {...props} tooltip='Which teams can contact this candidate' />,
      headerLabel: 'Contact access',
      Cell: (props) => (
        <ContactAccessCell
          {...props}
          candidate={props.row.original}
          teamIds={props.row.original.team_ids}
          teams={teams}
        />
      ),
      width: columnsWidth?.team_ids || 200
    },
    {
      accessor: 'last_contacted_at',
      Header: OtherHeader,
      headerLabel: 'Last invited',
      Cell: (props) => <TextCell {...props} value={props.row.original.last_contacted_at_str} />,
      width: columnsWidth?.last_contacted_at || 200,
      sortIconType: 'date'
    },
    {
      accessor: 'unsubscribed_at',
      Header: OtherHeader,
      headerLabel: 'Unsubscribed at',
      Cell: (props) => (
        <TextCell
          {...props}
          value={
            props.row.original.unsubscribed_at ? dateTemplate.render(new Date(props.row.original.unsubscribed_at)) : ''
          }
        />
      ),
      width: columnsWidth?.unsubscribed_at || 200,
      sortIconType: 'date'
    },
    ...(customAttrs || []).map(({ name, label, attr_type, description }) => {
      const col: Column<Candidate> = {
        headerLabel: label,
        Header: (props) => <OtherHeader {...props} description={description} />,
        accessor: `extra.${name}` as any,
        id: `extra.${name}`,
        sortIconType: ATTR_TO_SORT_ICON_MAPPING[attr_type] || 'text',
        width: columnsWidth?.[`extra.${name}`] || 150,
        Cell: SingleChoiceCell
      };
      switch (attr_type) {
        case 'text':
          break;
        case 'free_text':
          col.Cell = FreeTextCell;
          break;
        case 'multiple_choice':
          col.Cell = MultipleChoiceCell;
          col.sortType = (rowA, rowB, id) =>
            (rowA.values[id]?.join(', ') || '').localeCompare(rowB.values[id]?.join(', ') || '');
          break;
        case 'number':
          col.Cell = NumberCell;
          break;
        case 'datetime':
          col.Cell = DateCell;
          break;
        case 'boolean':
          col.Cell = BooleanCell;
          break;
        case 'url':
          col.Cell = FreeTextCell;
          break;
      }
      return col;
    }),
    {
      Header: OtherHeader,
      headerLabel: 'Eligible',
      id: 'health_status',
      accessor: 'health_status',
      width: columnsWidth?.health_status || 150,
      Cell: ({ value }) => (
        <CellWrapper>
          <HealthStatus status={value || []} />
        </CellWrapper>
      )
    }
  ]);
};
