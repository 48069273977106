import React from 'react';

import copy from 'copy-to-clipboard';

import { api } from '@api/reduxApi';
import { Menu } from '@components/common/Menu';
import { useSessionContext } from '@components/RepoSessionApp/SessionPage';
import * as toasts from '@components/RepoSessionApp/toasts';
import { BulkAddToHighlightReel } from '@components/RepositoryApp/components/BulkAddToHighlightReel';
import { BulkAddToInsightDropdown } from '@components/RepositoryApp/components/BulkAddToInsightDropdown';
import * as Icons from '@components/shared/Tiptap/icons';
import { AddToInsightSVG, FullPageViewSVG, HighlightReelSVG, LinkSVG, SolidKebabSVG, TrashSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { track } from '@components/tracking';
import { usePermission } from '@hooks/usePermission';
import { useToaster } from '@stores/toaster';

interface Props {
  highlight: DocumentHighlight;
}

export const HighlightDropdown: React.FC<React.PropsWithChildren<Props>> = ({ highlight }) => {
  const [destroyHighlight] = api.useDestroyHighlightMutation();

  const canCreate = usePermission('canCreate')();

  const { videoPlayerRef } = useSessionContext();

  const showToast = useToaster();

  const handleOnPlayClick = (currentTime: number) => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.setCurrentTime(currentTime / 1000);
      videoPlayerRef.current.play();
    }
  };

  const onDelete = async () => {
    try {
      await destroyHighlight({ highlightUuid: highlight.uuid }).unwrap();

      track('deleted_highlight', { page: 'interview_room', tab: 'highlights' });
      track('deleted_clip', { highlight: highlight.id });
      showToast(toasts.successDeleteHighlight());
    } catch {
      showToast(toasts.failedDeleteHighlight());
    }
  };

  return (
    <div className='flex space-x-2'>
      <div
        data-testid='copy-url-button'
        tabIndex={0}
        role='button'
        onClick={() => {
          showToast({
            heading: 'URL copied to the clipboard',
            text: 'Paste it wherever you’d like.',
            icon: 'success'
          });
          copy(`${window.location.origin}/h/${highlight?.uuid}`);
        }}
        className='focus:outline-none flex-shrink-0 rounded-full p-1 hover:bg-indigo-100 hover:text-indigo-600'
      >
        <LinkSVG />
      </div>
      {videoPlayerRef?.current && typeof highlight?.clip?.from !== 'undefined' && (
        <div
          role='button'
          tabIndex={0}
          data-testid='play-button'
          className='focus:outline-none flex-shrink-0 rounded-full p-1 hover:bg-indigo-100 hover:text-indigo-600'
          onClick={() => handleOnPlayClick(highlight?.clip?.from as number)}
        >
          <Icons.Play />
        </div>
      )}
      <Menu
        className='rounded-md border border-gray-200 bg-white text-sm shadow-lg'
        popperProps={{ zIndex: 30, renderOnBodyRoot: false }}
        renderTrigger={() => (
          <button
            data-testid='more'
            className='focus:outline-none flex-shrink-0 rounded-full p-1 hover:bg-indigo-100 hover:text-indigo-600'
            onClick={(e) => e.stopPropagation()}
          >
            <SolidKebabSVG className='h-4 w-4 flex-shrink-0' />
          </button>
        )}
      >
        {({ closePopper }) => (
          <>
            {canCreate && highlight && (
              <>
                <BulkAddToHighlightReel
                  studyId={highlight?.study?.id}
                  selectedArtifacts={[`Clip_${highlight.clip_id}`]}
                  popperPlacement='left-start'
                  renderOnBodyRoot={false}
                  popperOffset={[0, 170]}
                  renderButton={(props) => (
                    <div>
                      <Tooltip
                        className='w-full'
                        isDisabled={!!highlight?.clip}
                        content='Only available for highlights with video'
                      >
                        <button
                          data-testid='add-to-reel'
                          disabled={!highlight?.clip}
                          className='focus:outline-none flex h-10 w-full items-center border-b border-gray-200 px-4 text-sm text-gray-700 hover:bg-gray-50 focus:bg-gray-50 disabled:opacity-50'
                          {...props}
                        >
                          <HighlightReelSVG className='mr-2 h-4 w-4' /> Add to reel
                        </button>
                      </Tooltip>
                    </div>
                  )}
                />
                <BulkAddToInsightDropdown
                  studyId={highlight?.study?.id}
                  popperOffset={[0, 170]}
                  renderOnBodyRoot={false}
                  selectedArtifacts={highlight.clip_id ? [`Clip_${highlight.clip_id}`] : [`Highlight_${highlight.id}`]}
                  popperPlacement='left-start'
                  renderButton={(props) => (
                    <button
                      data-testid='add-to-insight'
                      className='focus:outline-none flex h-10 w-full items-center border-b border-gray-200 px-4 text-sm text-gray-700 hover:bg-gray-50 focus:bg-gray-50'
                      {...props}
                    >
                      <AddToInsightSVG className='mr-2 h-4 w-4' /> Add to insight
                    </button>
                  )}
                />
              </>
            )}

            <a
              href={`/h/${highlight?.uuid}`}
              target='_blank'
              className='focus:outline-none flex h-10 w-full items-center border-b border-gray-200 px-4 text-sm text-gray-700 hover:bg-gray-50 hover:text-gray-700 focus:bg-gray-50 focus:text-gray-700'
            >
              <FullPageViewSVG className='mr-2 h-4 w-4' /> View on full page
            </a>
            {canCreate && highlight && (
              <button
                className='focus:outline-none flex h-10 w-full items-center px-4 text-sm text-red-700 hover:bg-gray-50 focus:bg-gray-50'
                onClick={() => {
                  onDelete();
                  closePopper();
                }}
              >
                <TrashSVG className='mr-2 h-4 w-4' /> Delete highlight
              </button>
            )}
          </>
        )}
      </Menu>
    </div>
  );
};
