import * as React from 'react';

import { CardTitle, Text } from '@components/common';

import { NoParticipantsSVG } from './NoParticipantsSVG';

export const NoParticipantsZDS = () => (
  <div className='px-page py-gutter flex h-full flex-col'>
    <CardTitle className='mb-6'>Participants</CardTitle>
    <div className='flex flex-grow flex-col items-center justify-center rounded border border-gray-200 bg-white p-4'>
      <NoParticipantsSVG />
      <Text h='700' bold className='mb-2 mt-6'>
        No participants
      </Text>
      <Text color='gray-500'>There were no participants in this study.</Text>
    </div>
  </div>
);
