import React, { HTMLAttributes, useMemo } from 'react';

import { UniqueIdentifier } from '@dnd-kit/core';

import { Table } from '@components/shared/Table';
import { TreeItem } from '@components/SurveyBuilder/types/models';

import { getTableColumnDefs } from './helpers/getTableColumnDefs';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  answers: NonNullable<ScreenerResponseAnswerValue<'tree_test'>>[];
  setPreview: (answers: NonNullable<ScreenerResponseAnswerValue<'tree_test'>>) => void;
  nodes: TreeItem[];
  correctAnswers: UniqueIdentifier[][];
}

export const ResponsesTable: React.FC<React.PropsWithChildren<Props>> = ({
  correctAnswers,
  nodes,
  answers,
  setPreview
}) => {
  const columns = useMemo(
    () =>
      getTableColumnDefs({
        setPreview,
        nodes,
        correctAnswers
      }),
    [nodes, correctAnswers, answers]
  );

  return (
    <div className='relative max-w-full overflow-auto'>
      <Table
        columnPinning={{ left: ['participation_name'] }}
        data={answers}
        columns={columns}
        className='w-full table-fixed border border-b-0 border-gray-200 bg-white'
      />
    </div>
  );
};
