import React, { forwardRef, HTMLAttributes, ReactElement } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { ArtifactAttributes } from '@components/RepositoryApp/types';
import { track } from '@components/tracking';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  linkTo?: string;
  canSelect?: boolean;
  selected?: boolean;
  actions?: ReactElement[];
  copyLink?: string;
  onAddToInsight?: () => void;
  onToggle?: (selected: boolean) => void;
  handleClickContent?: (e) => void;
  kind: ArtifactAttributes['kind'];
  studyId?: number;
  page?: 'dashboard' | 'data' | 'study_data' | 'embedded_artifacts' | 'insights' | 'embedded_artifacts_modal';
}

export const Artifact = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      className,
      linkTo,
      selected,
      actions = [],
      copyLink,
      onAddToInsight,
      onToggle,
      canSelect,
      handleClickContent,
      kind,
      studyId,
      page,
      ...rest
    },
    ref
  ) => {
    const navigate = useNavigate();

    const onClick = (e) => {
      e.stopPropagation();
      if (handleClickContent) {
        handleClickContent(e);
        track('clicked_artifact', { page });
        return;
      }
      if (linkTo) {
        navigate(linkTo);
        track('clicked_artifact', { page });
      }
    };

    return (
      <div
        className={cn(
          'relative flex h-96 cursor-pointer flex-col overflow-hidden rounded-lg border border-gray-200 bg-white',
          { 'border-indigo-600': selected },
          className
        )}
        ref={ref}
        aria-selected={selected}
        data-testid='artifact'
        role={linkTo ? 'link' : undefined}
        onClick={onClick}
        {...rest}
      >
        {children}
      </div>
    );
  }
);
