import React from 'react';

import { Button, Text } from '@components/common';
import { BlockKind } from '@components/SurveyBuilder/types/models';

const TASK_TITLE: Partial<Record<BlockKind, string>> = {
  prototype_test: 'Prototype',
  card_sort: 'Card sort',
  tree_test: 'Tree test'
};

interface Props {
  onBeginTask: () => void;
  blockKind: BlockKind;
}

export const TaskLoadedBlocker = ({ onBeginTask, blockKind }: Props) => {
  return (
    <div className='absolute inset-0 z-50 flex items-start justify-center bg-gray-700 bg-opacity-50 py-6'>
      <div className='w-1/2 max-w-3xl px-4'>
        <div className='w-full rounded bg-white px-4 py-6'>
          <Text h='600' className='mb-2' bold>
            {TASK_TITLE[blockKind] || 'Task'} is loaded
          </Text>
          <Text h='400' className='mb-6'>
            Once you’ve read the instructions above, please begin the task.
          </Text>
          <Button className='btn-custom-brand' type='button' onClick={onBeginTask} small noStyle fullWidth>
            Begin task
          </Button>
        </div>
      </div>
    </div>
  );
};
