import * as React from 'react';

const DEFAULT_WIDTH = '630';
const DEFAULT_HEIGHT = '396';
const FLEXIBLE_RATIO = parseInt(DEFAULT_HEIGHT) / parseInt(DEFAULT_WIDTH);

interface Props {
  id: string;
  flexible?: boolean;
}

interface IframeProps {
  id: string;
  className?: string;
  width?: string;
  height?: string;
}

const Iframe: React.FC<React.PropsWithChildren<IframeProps>> = ({
  id,
  className = '',
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT
}) => {
  return (
    <iframe
      title='Loom video preview'
      allowFullScreen
      width={width}
      height={height}
      src={`https://www.loom.com/embed/${id}`}
      className={className}
      frameBorder='0'
    ></iframe>
  );
};
export const Preview: React.FC<React.PropsWithChildren<Props>> = ({ id, flexible = false }) => {
  if (flexible) {
    return (
      <div className='relative w-full' style={{ paddingTop: `${FLEXIBLE_RATIO * 100}%` }}>
        <Iframe id={id} width='100%' height='100%' className='absolute inset-0' />
      </div>
    );
  } else {
    return <Iframe id={id} />;
  }
};
// import { oembed } from '@loomhq/loom-embed';
// export const Preview: React.FC<{ id: string }> = ({ id }) => {
//   const [videoHTML, setVideoHTML] = useState<string>();
//   const sharedUrl = `https://loom.com/share/${id}`;
//   console.log(sharedUrl);
//   useEffect(() => {
//     (async () => {
//       const { html } = await oembed(sharedUrl, { width: 400 });
//       setVideoHTML(html);
//     })();
//   }, []);
//   console.log(videoHTML);
//   if (videoHTML) {
//     return <div dangerouslySetInnerHTML={{ __html: videoHTML }}></div>;
//   } else {
//     return null;
//   }
//   // return <iframe width='630' height='394' src={`https://www.loom.com/embed/${id}`} frameBorder='0'></iframe>;
// };
