import * as React from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Button, DropdownLink, Popper } from '@components/common';
import { Grid } from '@components/common/Grid';
import { useRecruitingRequest } from '@components/StudiesApp/components/StudyDraft/hooks/useRecruitingRequest';
import { AddExistingIconSVG, AddManualSVG, ImportIconSVG, InviteLinkIconSVG, RepositorySVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

import { ADD_EXISTING, ADD_MANUAL, INVITE_VIA_LINK, StudyAction } from './ACTIONS';

function Title({ children }) {
  return <h3 className='mb-2 mt-4 text-base font-bold'>{children}</h3>;
}
function Text({ children }) {
  return <p className='text-sm text-gray-500'>{children}</p>;
}
interface OptionProps {
  onClick?: () => void;
  href?: string;
  className?: string;
}

const Option: React.FC<React.PropsWithChildren<OptionProps>> = ({ onClick, href, className = '', children }) => {
  function handleClick(e) {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  }
  return (
    <a
      href={href}
      onClick={handleClick}
      className={cn(
        className,
        'hover:gb-gray-50 group flex cursor-pointer flex-col items-center justify-center rounded-md border border-gray-200 px-2 py-6'
      )}
    >
      {children}
    </a>
  );
};

interface Props {
  setMode: (mode: StudyAction | null) => void;
  study: Study;
  setAddCandidatesDefaultStudyLimit: (studyLimit: StudyLimit | null) => void;
  studyLimits?: StudyLimit[];
}

export const ZDS: React.FC<React.PropsWithChildren<Props>> = ({
  setMode,
  study,
  setAddCandidatesDefaultStudyLimit,
  studyLimits
}) => {
  const hasExternalCandidatesAccess = study.external_candidates_enabled;

  const navigate = useNavigate();

  const handleUpdatedRequest = (newRequest: ExternalCandidatesRequest) => {
    navigate(`/studies/${study.id}/recruitment_requests/${newRequest.id}`);
  };

  const { handleNew } = useRecruitingRequest({
    study: study,
    handleUpdatedRequest,
    skip: true
  });

  return (
    <div className='w-full rounded border border-gray-200 bg-white p-6'>
      <h2 className='mb-6 text-xl font-bold text-gray-700'>
        {hasExternalCandidatesAccess ? 'Recruit your customers' : 'Build your candidate pool'}
      </h2>
      <Grid className='w-full' gap={6} mobile={1} tablet={2} desktop={2} monitor={4}>
        <Option href={`/customer_imports/new?study_id=${study.id}`}>
          <div className='flex flex-1 items-center'>
            <ImportIconSVG />
          </div>
          <div className='flex-0 text-center'>
            <Title>Import new list</Title>
            <Text>Upload a CSV file with people to invite.</Text>
          </div>
          <Button
            trackEvent='clicked_csv_import'
            trackProps={{ page: 'participants_zds' }}
            medium
            className='mt-6'
            icon='import'
            href={`/customer_imports/new?study_id=${study.id}`}
          >
            Import
          </Button>
        </Option>
        <Option
          onClick={() => {
            setAddCandidatesDefaultStudyLimit(null);
            setMode(ADD_EXISTING);
          }}
        >
          <div className='flex flex-1 items-center'>
            <AddExistingIconSVG />
          </div>
          <div className='flex-0 text-center'>
            <Title>Add existing candidates</Title>
            <Text>Add from your Great Question database.</Text>
          </div>
          {studyLimits && studyLimits.length > 0 ? (
            <Popper
              zIndex={50}
              closeOnClickOutside
              placement='bottom-start'
              offset={[0, 8]}
              content={({ closePopper }) => (
                <div
                  className='w-60 rounded-md border border-gray-200 bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-5'
                  role='menu'
                  aria-orientation='vertical'
                  aria-labelledby='options-menu'
                >
                  <DropdownLink
                    aria-label='Select existing candidates'
                    trackEvent='clicked_add_existing_candidates'
                    trackProps={{ page: 'participants_zds' }}
                    onClick={() => {
                      setMode(ADD_EXISTING);
                      closePopper();
                    }}
                  >
                    Select existing candidates
                  </DropdownLink>
                  <hr className='my-2' />
                  {studyLimits.map((limit) => (
                    <DropdownLink
                      aria-label={`Add from ${limit.name ? limit.name : `Segment #${limit.order}`}`}
                      key={limit.id}
                      trackEvent='clicked_add_candidates_from_segment'
                      trackProps={{ page: 'participants_zds' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setAddCandidatesDefaultStudyLimit(limit);
                        setMode(ADD_EXISTING);
                        closePopper();
                      }}
                    >
                      Add from {limit.name ? limit.name : `Segment #${limit.order}`}
                    </DropdownLink>
                  ))}
                </div>
              )}
            >
              <Button
                trackEvent='clicked_add_existing_candidates'
                trackProps={{ page: 'participants_zds' }}
                aria-label='Browse candidates'
                medium
                iconSuffix='caretDown'
                className='mt-6 w-auto'
              >
                <RepositorySVG className='mr-2 inline' />
                Browse candidates
              </Button>
            </Popper>
          ) : (
            <Button
              trackEvent='clicked_add_existing_candidates'
              dataTestId='add-existing-candidates-button'
              trackProps={{ page: 'participants_zds' }}
              medium
              className='xx-study-add-existing mt-6'
              icon='repository'
            >
              Browse candidates
            </Button>
          )}
        </Option>
        {['active', 'pending'].includes(study.state) && (
          <Option onClick={() => setMode(INVITE_VIA_LINK)}>
            <div className='flex flex-1 items-center'>
              {study.landing_page?.screenshot_url ? (
                <img
                  alt="Thumbnail of this study's landing page"
                  className='mb-4 h-24 shadow-lg'
                  src={study.landing_page?.screenshot_url}
                />
              ) : (
                <InviteLinkIconSVG />
              )}
            </div>
            <div className='flex-0 mb-6 text-center'>
              <Title>Invite via link</Title>
              <Text>Send or share a link with your audience.</Text>
            </div>
            <Tooltip isDisabled={study.state !== 'pending'} content='Study requires approval before you can invite.'>
              <Button
                disabled={study.state !== 'active'}
                trackEvent='clicked_invite_via_link'
                trackProps={{ page: 'participants_zds' }}
                medium
                icon='link'
                onClick={() => setMode(INVITE_VIA_LINK)}
              >
                Select link
              </Button>
            </Tooltip>
          </Option>
        )}
        <Option onClick={() => setMode(ADD_MANUAL)}>
          <div className='flex flex-1 items-center'>
            <AddManualSVG />
          </div>
          <div className='flex-0 text-center'>
            <Title>Add someone manually</Title>
            <Text>Enter the person’s information to add.</Text>
          </div>
          <Button
            trackEvent='clicked_add_manually'
            trackProps={{ page: 'participants_zds' }}
            medium
            className='mt-6'
            icon='addPeople'
            onClick={() => setMode(ADD_MANUAL)}
          >
            Add candidate
          </Button>
        </Option>
      </Grid>
      {hasExternalCandidatesAccess && ['active', 'pending'].includes(study.state) && (
        <>
          <h2 className='mb-6 mt-12 text-xl font-bold text-gray-700'>
            Recruit participants from an external recruiting service
          </h2>
          <Grid className='w-full' gap={6} mobile={1} tablet={2} desktop={2} monitor={4}>
            <Option onClick={handleNew}>
              <img src='/temp-assets/candidates/respondent.png' alt='respondent-logo' />
              <div className='flex-0 text-center'>
                <Title>Recruit external candidates</Title>
                <Text>Find B2B or B2C participants.</Text>
              </div>
              <Tooltip isDisabled={study.state !== 'pending'} content='Study requires approval before you can invite.'>
                <Button
                  trackEvent='clicked_add_external_candidates'
                  trackProps={{ page: 'participants_zds' }}
                  medium
                  className='mt-6'
                  icon='externalCandidates'
                  onClick={handleNew}
                >
                  Create request
                </Button>
              </Tooltip>
            </Option>
          </Grid>
        </>
      )}
    </div>
  );
};
