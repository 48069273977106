import React from 'react';

import { useUser } from '@hooks/useUser';

export const EmailPreview: React.FC<React.PropsWithChildren<{ brand: Brand; accountName: string }>> = ({
  brand,
  accountName
}) => {
  const { button_color, text_color, logo_alignment, logo, button_text_color } = brand;

  const { name: userName } = useUser();
  return (
    <div style={{ maxWidth: 600, margin: '0 auto' }} className='email-container'>
      <div role='presentation' style={{ margin: 'auto' }}>
        <div>
          <div>
            <div style={{ padding: '30px 0', textAlign: 'center' }}></div>
          </div>
          <div>
            <div style={{ backgroundColor: '#ffffff', border: '1px solid #e5e7eb' }} className='darkmode-bg'>
              <div className='w-full'>
                <div style={{ padding: '30px 30px 30px 30px' }}>
                  <div className='logo-align-brand block'>
                    <div className='inline-block' style={{ width: 150, paddingBottom: 20 }}>
                      <img
                        height='auto'
                        alt={`${accountName} logo`}
                        src={logo || ''}
                        style={{
                          display: 'block',
                          outline: 'none',
                          textDecoration: 'none',
                          height: 'auto',
                          width: '100%'
                        }}
                        width={150}
                      />
                    </div>
                  </div>

                  <div>
                    <div>
                      <div
                        style={{
                          fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                          padding: '10px 0px'
                        }}
                      >
                        <p
                          style={{
                            fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                            margin: 0
                          }}
                        />
                        <p>
                          <i>This email is intended for: email@example.com</i>
                        </p>
                        <p />
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontFamily: 'sans-serif',
                          padding: '10px 0px',
                          fontSize: 16,
                          color: text_color
                        }}
                      >
                        <p
                          style={{
                            fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                            margin: 0
                          }}
                        >
                          Hi Participant,
                        </p>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontFamily: 'sans-serif',
                          padding: '10px 0px',
                          fontSize: 16,
                          color: text_color
                        }}
                      >
                        <p
                          style={{
                            fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                            margin: 0
                          }}
                        >
                          We’ve kicked off a new research project at gq and would love to invite you to be a part of it.
                        </p>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontFamily: 'sans-serif',
                          padding: '10px 0px',
                          fontSize: 16,
                          color: text_color
                        }}
                      >
                        <p
                          style={{
                            fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                            margin: 0
                          }}
                        >
                          Your participation will help shape the future of the product experience, and potentially the
                          company as a whole.
                        </p>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontFamily: 'sans-serif',
                          padding: '10px 0px',
                          fontSize: 16,
                          color: text_color
                        }}
                      >
                        <p
                          style={{
                            fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                            margin: 0
                          }}
                        >
                          Here are the details:
                        </p>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          padding: '0 20px 0 0',
                          fontFamily: 'sans-serif',
                          fontSize: 15,
                          color: text_color
                        }}
                      >
                        <ul
                          style={{
                            padding: 0,
                            margin: '0 0 0 0',
                            listStyleType: 'disc'
                          }}
                        >
                          <li style={{ margin: '0 0 10px 30px' }}>
                            <p
                              style={{
                                fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                                fontSize: 16,
                                color: text_color
                              }}
                            >
                              1:1 Video Call
                            </p>
                          </li>
                          <li style={{ margin: '0 0 10px 30px' }}>
                            <p
                              style={{
                                fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                                fontSize: 16,
                                color: text_color
                              }}
                            >
                              30 minutes
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontFamily: 'sans-serif',
                          padding: '10px 0px',
                          fontSize: 16,
                          color: text_color
                        }}
                      >
                        <p
                          style={{
                            fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                            margin: 0
                          }}
                        >
                          If this sounds like something you’re interested in, click the link below.
                        </p>
                      </div>
                    </div>
                    <div className='flex items-center justify-center py-5'>
                      <div
                        style={{
                          borderRadius: 4,
                          background: '#f30000',
                          cursor: 'pointer'
                        }}
                      >
                        <a
                          style={{
                            background: button_color,
                            fontFamily: 'sans-serif',
                            fontSize: '16px',
                            lineHeight: '16px',
                            texDecoration: 'none',
                            padding: '13px 17px',
                            color: button_text_color,
                            display: 'block',
                            borderRadius: 4,
                            cursor: 'pointer'
                          }}
                        >
                          Get started
                        </a>
                      </div>
                    </div>

                    <div>
                      <div
                        style={{
                          fontFamily: 'sans-serif',
                          padding: '10px 0px',
                          fontSize: 16,

                          color: text_color
                        }}
                      >
                        <p
                          style={{
                            fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                            margin: 0
                          }}
                        >
                          Thanks for your time,
                        </p>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontFamily: 'sans-serif',
                          padding: '10px 0px',
                          fontSize: 16,
                          color: text_color
                        }}
                      >
                        <p
                          style={{
                            fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                            margin: 0
                          }}
                        >
                          {userName}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: 0,
                          padding: '10px 0',
                          paddingTop: 10,
                          paddingRight: 0,
                          paddingBottom: 10,
                          paddingLeft: 0,
                          wordBreak: 'break-word'
                        }}
                      >
                        <p
                          style={{
                            borderTop: 'solid 1px #e5e7eb',
                            fontSize: 1,
                            margin: '0px auto',
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: 0,
                          marginHorizontal: 'auto',
                          padding: 10,
                          paddingTop: 0,
                          paddingRight: 10,
                          paddingBottom: 0,
                          paddingLeft: 10,
                          wordBreak: 'break-word'
                        }}
                      >
                        <div
                          style={{
                            borderCollapse: 'collapse',
                            borderSpacing: 0
                          }}
                        >
                          <div className='flex justify-center'>
                            <div style={{ width: 140 }}>
                              <a>
                                <img
                                  height='auto'
                                  alt='Powered by Great Question'
                                  src='https://greatquestion.co/messaging/powered_by.png'
                                  style={{
                                    border: 0,
                                    display: 'block',
                                    outline: 'none',
                                    textDecoration: 'none',
                                    height: 'auto',
                                    width: '100%',
                                    fontSize: 13
                                  }}
                                  width={140}
                                  className='CToWUd'
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: 0,
                          padding: 10,
                          wordBreak: 'break-word'
                        }}
                      >
                        <div
                          style={{
                            fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                            fontSize: 16,
                            lineHeight: '1.6',
                            textAlign: 'center',
                            color: '#6b7280'
                          }}
                        >
                          {accountName} partners with Great Question to help facilitate product research with great
                          people like you.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div aria-hidden='true' style={{ fontSize: 0, lineHeight: 0 }}>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: '20px',
          fontFamily: 'sans-serif',
          fontSize: '12px',
          lineHeight: '15px',
          textAlign: 'center',
          color: '#999999'
        }}
      >
        <a href='https://greatquestion.co' target='_blank'>
          Great Question Inc.
          <br />
          <span className='unstyle-auto-detected-links'>2261 Market Street #4639, San Francisco, CA 94114</span>
          <br />
          <br />
          Don't like these emails?
        </a>
        <a href='#'>
          <div style={{ color: '#999999', textDecoration: 'underline' }}>unsubscribe</div>
        </a>
      </div>
    </div>
  );
};
