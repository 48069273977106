import React, { forwardRef, HTMLAttributes } from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { Grid } from '@components/common/Grid';
import { PreloadedImage } from '@components/shared/AnimatedOnHoverImage';
import { ErrorRecordingSVG, NoRecordingSVG } from '@components/svgs';

interface Props extends HTMLAttributes<HTMLDivElement> {
  thumbnail?: string;
  animatedThumbnail?: string;
  streamUrl?: string;
  error?: boolean;
  clip?: [number, number];
  modalTitle?: string;
  hover?: boolean;
  clips?: HighlightReelClip[];
}

// 16:9
export const PADDING = '56.25%';

export const Interview = forwardRef<HTMLDivElement, Props>(
  ({ clips, hover, thumbnail, animatedThumbnail, streamUrl, error, modalTitle, clip, className, ...rest }, ref) => {
    const renderIcon = () => {
      if (!thumbnail) {
        return <NoRecordingSVG data-testid='zds-svg' className='text-gray-400' />;
      }

      if (error) {
        return <ErrorRecordingSVG className='text-gray-400' />;
      }

      return null;
    };

    const clipsToShow = clips && clips?.length > 8 ? clips.slice(0, 7) : clips;

    return (
      <section ref={ref} className={cn('relative h-full w-full bg-gray-200', className)} {...rest}>
        <div
          className={cn(
            'absolute z-10 flex h-full w-full cursor-pointer items-center justify-center bg-opacity-40',
            thumbnail ? 'bg-indigo-1000' : 'bg-gray-200'
          )}
        >
          {renderIcon()}
        </div>
        {thumbnail && (
          <div className='absolute flex h-full w-full justify-center'>
            <img
              className='object-cover'
              src={hover && animatedThumbnail ? animatedThumbnail : thumbnail}
              alt={modalTitle}
            />
            {animatedThumbnail && <PreloadedImage url={animatedThumbnail} />}
          </div>
        )}
        {clipsToShow && (
          <Grid gap={0.5} columns={8} className='absolute bottom-3 left-0 right-0 px-4'>
            {clipsToShow.map(
              (clip) =>
                clip.thumbnail_url && (
                  <div
                    key={clip.id}
                    style={{ paddingBottom: PADDING }}
                    className='relative flex-1 rounded-sm border border-white'
                  >
                    <img
                      className='absolute h-full w-full object-cover'
                      src={clip.thumbnail_url}
                      alt='clip_thumbnail'
                    />
                  </div>
                )
            )}
            {clips && clips?.length > 8 && (
              <div className='flex flex-1 items-center justify-center rounded-sm border border-white bg-gray-700 opacity-20'>
                <Text h='200' color='white'>
                  +{clips?.length - 7}
                </Text>
              </div>
            )}
          </Grid>
        )}
      </section>
    );
  }
);
