import * as React from 'react';
import { useEffect, useState } from 'react';

import cn from 'classnames';
import DatePicker from 'react-datepicker';
import tinytime from 'tinytime';

import { Button, Select, SlideOut, Text } from '@components/common';
import { LocationInput } from '@components/common/LocationInput';
import { InfoCircleIcon, PlusSVG, TrashSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { without } from '@components/utils';
import {
  escapedCommas,
  skipLogicValueIsArray,
  slValueFromArray,
  slValueToArray
} from 'components/Public/GQSurvey/skipLogic';

import { DefaultQuestionSelect } from './DefaultQuestionSelect';
import { OperatorSelect } from './OperatorSelect';
import { QuestionSelect } from './QuestionSelect';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

function getQuestionStr(q: ScreenerField): string {
  const { position, label } = q;
  return `${position}. ${label || '(Untitled question)'}`;
}

export const END_OF_SURVEY = -1;
export const END_OF_SURVEY_WITH_AUTO_REVIEW = -5;
export const END_OF_SURVEY_WITHOUT_SUBMIT = -99;

export const endOfSurveyIds = [END_OF_SURVEY, END_OF_SURVEY_WITH_AUTO_REVIEW, END_OF_SURVEY_WITHOUT_SUBMIT];

export const isEndOfSurvey = (id: number): boolean => {
  return endOfSurveyIds.includes(id);
};

export function shouldClearSkipLogic(oldType: string, newType: string): boolean {
  // return true if the types of the skip_logic.value or skip_logic.op are incompatible if the question
  // changes from one type to the other. so we have to null it out.
  if (oldType === 'multiple_choice') return true;
  if (hasOptions(oldType) && !hasOptions(newType)) return true;
  if (isText(oldType) && !isText(newType)) return true;
  if (isNumber(oldType) && !isNumber(newType)) return true;
  if (isDate(oldType) && !isDate(newType)) return true;
  if (isLocation(oldType) && !isLocation(newType)) return true;
  return false;
}

const hasOptions = (fieldType: string) => ['yes_no', 'multiple_choice', 'single_choice'].includes(fieldType);
const isText = (fieldType: string) => ['long_text', 'short_text', 'email', 'website'].includes(fieldType);
const isNumber = (fieldType: string) => ['number', 'number_range'].includes(fieldType);
const isDate = (fieldType: string) => fieldType === 'date';
const isLocation = (fieldType: string) => fieldType === 'location';

const DEFAULT_SKIP_LOGIC: Pick<SkipLogic, 'op' | 'value'> & { to: number | null } = {
  to: null,
  op: 'equal',
  value: ''
};

interface Props {
  open: boolean;
  onClose: () => void;
  autoReview: boolean;
  hasAnIdealAnswer: boolean;
  questions: ScreenerField[];
  question: ScreenerField;
  onChange: (value: SkipLogic[] | null, nextQuestionId: number | null) => void;
  allowSkipSubmit: boolean;
}
export const SkipLogicSlideOut: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  autoReview,
  hasAnIdealAnswer,
  questions,
  question,
  onChange,
  allowSkipSubmit
}) => {
  const defaultSkipLogic = DEFAULT_SKIP_LOGIC as SkipLogic;
  const defaultMultiSkipLogic =
    question.skip_logic && question.skip_logic.length ? question.skip_logic : ([DEFAULT_SKIP_LOGIC] as SkipLogic[]);

  const [multiSkipLogic, setMultiSkipLogic] = useState<SkipLogic[]>(defaultMultiSkipLogic);
  const [defaultNextQuestionId, setDefaultNextQuestionId] = useState<number | null>(question.next_screener_field_id);

  const enableExcludeFromAutoReview = autoReview && !hasAnIdealAnswer;

  const handleSave = (value: SkipLogic[] | null, nextQuestionId: number | null) => {
    value = value ? value.filter((sl) => sl.to !== null) : null;
    if (value?.length === 0) {
      value = null;
    }

    onChange(value, nextQuestionId);
  };

  const updateSkipLogicBranch = (index: number, skipLogic: SkipLogic) => {
    setMultiSkipLogic(Object.assign([], multiSkipLogic, { [index]: skipLogic }));
  };

  const addNewBranch = () => {
    setMultiSkipLogic([...multiSkipLogic, defaultSkipLogic]);
  };

  const removeBranch = (index: number) => {
    const result = multiSkipLogic.filter((_, i) => i !== index);
    setMultiSkipLogic(result.length > 0 ? result : [defaultSkipLogic]);
  };

  const isValueDisabled = (skipLogic: SkipLogic) => ['any_answer', 'no_answer'].includes(skipLogic.op);

  useEffect(() => {
    setMultiSkipLogic(defaultMultiSkipLogic);
    setDefaultNextQuestionId(question.next_screener_field_id);
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <SlideOut
      title={`Edit logic for #${question.position}`}
      closeOnEsc
      zIndex='50'
      onClose={onClose}
      renderFooter={() => (
        <div className='space-x-2'>
          <Button onClick={() => multiSkipLogic && handleSave(multiSkipLogic, defaultNextQuestionId)} primary>
            Save
          </Button>
          <Button onClick={() => handleSave(null, null)}>Remove skip logic</Button>
        </div>
      )}
    >
      <div className='p-4 pt-0'>
        {multiSkipLogic.map((skipLogic, skipLogicIndex) => {
          const shouldSelectSkipAnswer = skipLogic.op !== 'any_answer' && skipLogic.op !== 'no_answer';

          return (
            <section key={skipLogicIndex}>
              <header className='mb-2 flex items-center justify-between'>
                <Text h='200' color='gray-700'>
                  Branch #{skipLogicIndex + 1}
                </Text>
                <button className='focus:outline-none' onClick={() => removeBranch(skipLogicIndex)}>
                  <TrashSVG />
                </button>
              </header>

              <div className='mb-4 rounded bg-gray-50 p-2'>
                <div className='mb-2 text-xs font-bold'>If {getQuestionStr(question)}</div>

                <div className='mb-2'>
                  {hasOptions(question.field_type) &&
                    (() => {
                      const options = question.field_type === 'yes_no' ? ['Yes', 'No'] : [...(question.options || [])];

                      const isMultipleChoice = question.field_type === 'multiple_choice';
                      const isSingleChoice = question.field_type === 'single_choice';

                      if (question.other && (isMultipleChoice || isSingleChoice)) {
                        options?.push('Other');
                      }

                      const isValueAnArray = skipLogicValueIsArray(question.field_type, skipLogic as SkipLogic);

                      const isOptionChecked = isValueAnArray
                        ? (option: string) => slValueToArray(skipLogic.value).includes(option)
                        : (option: string) => option === skipLogic.value;

                      const isOptionDisabled = (option: string): boolean =>
                        !!multiSkipLogic.find(
                          ({ value }, index) => skipLogicIndex !== index && slValueToArray(value).includes(option)
                        );

                      const onChangeOption = (e: React.FormEvent<HTMLInputElement>, option: string) => {
                        // for single choice (radio) question the value is just the option
                        // but... we could allow multiple options to trigger the skip logic?
                        if (!isValueAnArray) {
                          updateSkipLogicBranch(skipLogicIndex, { ...skipLogic, value: option });
                          return;
                        }

                        // for multiple choice (Multi Select) questions, the skipLogic.value is a csv
                        // so either remove it or add it to the string
                        const { checked } = e.currentTarget;
                        if (checked) {
                          updateSkipLogicBranch(skipLogicIndex, {
                            ...skipLogic,
                            value: slValueFromArray(slValueToArray(skipLogic.value).concat(option))
                          });
                        } else {
                          updateSkipLogicBranch(skipLogicIndex, {
                            ...skipLogic,
                            value: slValueFromArray(without(slValueToArray(skipLogic.value), option))
                          });
                        }
                      };

                      if (!isValueAnArray) {
                        return (
                          <div className='flex items-center space-x-2'>
                            <div className='flex-1'>
                              <OperatorSelect
                                question={question}
                                value={skipLogic.op}
                                onChange={(op) => updateSkipLogicBranch(skipLogicIndex, { ...skipLogic, op })}
                              />
                            </div>
                            {!isValueDisabled(skipLogic) && (
                              <div className='flex-1'>
                                <Select
                                  value={skipLogic.value}
                                  options={options.map((option) => ({
                                    label: option,
                                    value: escapedCommas(option),
                                    disabled: isOptionDisabled(option)
                                  }))}
                                  onChange={(value) => {
                                    updateSkipLogicBranch(skipLogicIndex, { ...skipLogic, value });
                                  }}
                                  renderLabel={({ label, disabled }) => (
                                    <div className={cn({ 'text-gray-500': disabled })}>
                                      <Text h='400'>{label}</Text>
                                    </div>
                                  )}
                                  className='bg-white'
                                />
                              </div>
                            )}
                          </div>
                        );
                      }

                      return (
                        <>
                          <OperatorSelect
                            question={question}
                            value={skipLogic.op}
                            onChange={(op) => updateSkipLogicBranch(skipLogicIndex, { ...skipLogic, op })}
                          />
                          {shouldSelectSkipAnswer &&
                            options?.map((option) => {
                              const idd = `branch-${skipLogicIndex + 1}-${question.id}-${option}`;
                              return (
                                <div key={idd} className='my-4 flex items-center'>
                                  <input
                                    id={idd}
                                    name={`branch-${skipLogicIndex + 1}-question-${question.id}-skiplogicvalue`}
                                    type={isValueAnArray ? 'checkbox' : 'radio'}
                                    onChange={(e) => onChangeOption(e, option)}
                                    checked={isOptionChecked(option)}
                                  />
                                  <label className='ml-2 cursor-pointer' htmlFor={idd}>
                                    {option}
                                  </label>
                                </div>
                              );
                            })}
                        </>
                      );
                    })()}
                  {isText(question.field_type) && (
                    <div className='flex items-center space-x-2'>
                      <div className='flex-1'>
                        <OperatorSelect
                          question={question}
                          value={skipLogic.op}
                          onChange={(op) => updateSkipLogicBranch(skipLogicIndex, { ...skipLogic, op })}
                        />
                      </div>
                      {!isValueDisabled(skipLogic) && (
                        <div className='flex-1'>
                          {shouldSelectSkipAnswer && (
                            <input
                              name='value'
                              placeholder='Enter words'
                              className='tablet:text-sm my-2 block w-full rounded-sm border border-gray-300 p-2 focus:border-indigo-500 focus:ring-indigo-500'
                              value={skipLogic.value}
                              onChange={(e) =>
                                updateSkipLogicBranch(skipLogicIndex, { ...skipLogic, value: e.currentTarget.value })
                              }
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {isNumber(question.field_type) && (
                    <div className='flex items-center space-x-2'>
                      <div className='flex-1'>
                        <OperatorSelect
                          question={question}
                          value={skipLogic.op}
                          onChange={(op) => updateSkipLogicBranch(skipLogicIndex, { ...skipLogic, op })}
                        />
                      </div>
                      {!isValueDisabled(skipLogic) && (
                        <div className='flex-1'>
                          {shouldSelectSkipAnswer && (
                            <input
                              name='value'
                              placeholder='Enter number'
                              type='number'
                              className='tablet:text-sm my-2 block w-full rounded-sm border border-gray-300 p-2 focus:border-indigo-500 focus:ring-indigo-500'
                              value={skipLogic.value}
                              onChange={(e) =>
                                updateSkipLogicBranch(skipLogicIndex, { ...skipLogic, value: e.currentTarget.value })
                              }
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {isDate(question.field_type) && (
                    <div className='flex items-center space-x-2'>
                      <div className='flex-1'>
                        <OperatorSelect
                          question={question}
                          value={skipLogic.op}
                          onChange={(op) => updateSkipLogicBranch(skipLogicIndex, { ...skipLogic, op })}
                        />
                      </div>
                      {!isValueDisabled(skipLogic) && (
                        <div className='flex-1'>
                          {shouldSelectSkipAnswer && (
                            <DatePicker
                              onChange={(v) =>
                                updateSkipLogicBranch(skipLogicIndex, {
                                  ...skipLogic,
                                  value: v ? Date.parse(v as any).toString() : ''
                                })
                              }
                              wrapperClassName='w-full'
                              className='tablet:text-sm my-2 block w-full rounded-sm border border-gray-300 p-2 focus:border-indigo-500 focus:ring-indigo-500'
                              placeholderText='Enter date'
                              name='date'
                              value={skipLogic.value ? dateTemplate.render(new Date(Number(skipLogic.value))) : null}
                              popperPlacement='bottom-start'
                              customInput={<input />}
                              showYearDropdown
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {isLocation(question.field_type) && (
                    <div className='flex items-center space-x-2'>
                      <div className='flex-1'>
                        <OperatorSelect
                          question={question}
                          value={skipLogic.op}
                          onChange={(op) => updateSkipLogicBranch(skipLogicIndex, { ...skipLogic, op })}
                        />
                      </div>
                      {!isValueDisabled(skipLogic) && (
                        <div className='flex-1'>
                          {shouldSelectSkipAnswer && (
                            <LocationInput
                              id={String(question.id)}
                              placeholder='Enter location'
                              requestTypes={['(regions)']}
                              inputValue={skipLogic.value || ''}
                              setValue={(value) =>
                                updateSkipLogicBranch(skipLogicIndex, { ...skipLogic, value: value as string })
                              }
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className='mb-2 text-xs font-bold'>Then go to</div>
                <div>
                  <QuestionSelect
                    currentQuestion={question}
                    questions={questions}
                    value={skipLogic.to as SkipLogic['to']}
                    onChange={(to) => {
                      updateSkipLogicBranch(skipLogicIndex, { ...skipLogic, to });
                    }}
                    endValue={isEndOfSurvey(skipLogic.to) ? skipLogic.to : END_OF_SURVEY}
                  />
                </div>
                {isEndOfSurvey(skipLogic.to) && (autoReview || allowSkipSubmit) && (
                  <>
                    <div className='mt-2 flex items-center text-xs font-bold'>And</div>
                    {autoReview && (
                      <Tooltip
                        isDisabled={enableExcludeFromAutoReview}
                        content='The ideal answer criteria will be used as part of the automatic review.'
                      >
                        <div className='mt-2 flex items-center text-xs'>
                          <input
                            id={`no-auto-review-${question.id}`}
                            name={`no-auto-review-${question.id}`}
                            type='checkbox'
                            className={cn({ 'border-gray-300': !enableExcludeFromAutoReview })}
                            onChange={(e) => {
                              updateSkipLogicBranch(skipLogicIndex, {
                                ...skipLogic,
                                to: e.currentTarget.checked ? END_OF_SURVEY : END_OF_SURVEY_WITH_AUTO_REVIEW
                              });
                            }}
                            checked={enableExcludeFromAutoReview && skipLogic.to === END_OF_SURVEY}
                            disabled={!enableExcludeFromAutoReview}
                          />
                          <label
                            className={`ml-2 cursor-pointer text-xs ${cn({
                              'text-gray-500': !enableExcludeFromAutoReview
                            })}`}
                            htmlFor={`no-auto-review-${question.id}`}
                          >
                            Exclude from automatic review
                          </label>
                          {enableExcludeFromAutoReview && (
                            <Tooltip content='Even if the all of their answers match your ideal answers, they will not be considered as Ideal.'>
                              <InfoCircleIcon className='ml-2 h-4 w-4' />
                            </Tooltip>
                          )}
                        </div>
                      </Tooltip>
                    )}
                    {allowSkipSubmit && (
                      <div className='mt-2 flex items-center text-xs'>
                        <input
                          id={`allow-skip-submit-${question.id}`}
                          name={`allow-skip-submit-${question.id}`}
                          type='checkbox'
                          onChange={(e) => {
                            updateSkipLogicBranch(skipLogicIndex, {
                              ...skipLogic,
                              to: e.currentTarget.checked ? END_OF_SURVEY_WITHOUT_SUBMIT : END_OF_SURVEY
                            });
                          }}
                          checked={skipLogic.to === END_OF_SURVEY_WITHOUT_SUBMIT}
                        />
                        <label className='ml-2 cursor-pointer text-xs' htmlFor={`no-auto-review-${question.id}`}>
                          Don't store response
                        </label>
                        <Tooltip content='They will fill out the form as usual, but no data will be stored and they will not appear as a Participant in Great Question.'>
                          <InfoCircleIcon className='ml-2 h-4 w-4' />
                        </Tooltip>
                      </div>
                    )}
                  </>
                )}
              </div>
            </section>
          );
        })}

        <button
          className='focus:outline-none my-4 flex items-center space-x-2 text-sm text-indigo-600'
          onClick={addNewBranch}
        >
          <PlusSVG className='h-4 w-4' />
          <span>Add branch</span>
        </button>

        <div className='mb-2 text-xs font-bold'>All other responses will go to</div>
        <div className='mb-12'>
          <DefaultQuestionSelect
            questions={questions}
            question={question}
            skipLogic={multiSkipLogic}
            onChange={setDefaultNextQuestionId}
          />
        </div>
      </div>
    </SlideOut>
  );
};
