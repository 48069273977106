import React from 'react';
import { useContext, useEffect, useReducer } from 'react';
import { createContext } from 'react';

type CacheStatus = 'init' | 'fetching' | 'loaded' | 'saved' | 'error';

export type Cacheable = {
  id: number;
  updated_at: number;
};

export type KeyState<T extends Cacheable> = {
  page: number;
  status: CacheStatus;
  data: T[];
  totalRecords?: number;
};
type Action = React.Dispatch<{ key: string; data: Partial<KeyState<Cacheable>> }>;
type State<T extends Cacheable> = Record<string, KeyState<T>>;
interface Store {
  state: State<any>;
  dispatch: Action;
}

export const store = createContext<Store>({} as Store);

export const Provider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    return { ...state, [action.key]: { ...(state[action.key] || {}), ...action.data } };
  }, {});

  return <store.Provider value={{ state, dispatch }}>{children}</store.Provider>;
};

const INITIAL_DATA: KeyState<Cacheable> = {
  page: 1,
  data: [],
  status: 'init'
};

type DataContextHook<T extends Cacheable> = {
  state: KeyState<T>;
  setState: (data: Partial<KeyState<T>>) => void;
};

export function useCache<T extends Cacheable>(key: string): DataContextHook<T> {
  const { state: storeState, dispatch } = useContext(store);

  const state: KeyState<T> = storeState[key] || (INITIAL_DATA as KeyState<T>);
  useEffect(() => {
    if (!storeState[key]) {
      setState(INITIAL_DATA);
    }
  }, []);

  function setState(data: Partial<KeyState<any>>) {
    dispatch({ key, data });
  }
  return { state, setState };
}
