import { add, format, setHours } from 'date-fns';
import jstz from 'jstz';
import tinytime from 'tinytime';
import { getTimeZones } from '@vvo/tzdb';

import { DropdownItem } from '@components/common/DropdownCombobox';

const dateTemplate = tinytime('{h}:{mm} {a}');

const getCurrentTime = (offset) => {
  const currentTime = Date.now();
  const localTime = currentTime + offset * 60000 + (jstz.determine() as any).timezoneOffset() * 60000;
  return dateTemplate.render(new Date(localTime));
};

export const TIME_ZONE_OPTIONS: DropdownItem[] = getTimeZones()
  .sort((a, b) => a.currentTimeOffsetInMinutes - b.currentTimeOffsetInMinutes)
  .map(({ name, abbreviation, currentTimeOffsetInMinutes }) => ({
    label: `${abbreviation} (${name})`,
    value: name,
    text: getCurrentTime(currentTimeOffsetInMinutes),
    data: { name, abbreviation }
  }));

export const CALL_BUFFER_OPTIONS = [
  { label: 'No call buffer', value: 0 },
  { label: '5 minutes', value: 5 },
  { label: '10 minutes', value: 10 },
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '60 minutes', value: 60 },
  { label: '75 minutes', value: 75 },
  { label: '90 minutes', value: 90 },
  { label: '120 minutes', value: 120 }
];

export const SCHEDULING_INCREMENTS = [
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 }
];

export const VIEW_DAYS_OPTIONS = [
  { label: 'Full week (Mon - Sun)', value: '0' },
  { label: 'Weekdays only (Mon - Fri)', value: '1' }
];
