import * as React from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Checkbox, Tabs, Text } from '@components/common';
import { AskAiSVG, ChevronDownSVG, ChevronRightSVG, ChevronUpSVG, ExternalLinkSVG, PlusSVG } from '@components/svgs';
import { useTagManagerActions } from '@components/TagManager';
import { getBackgroundColor, TAG_COLORS } from '@components/tags/colors';
import { Swatch } from '@components/tags/Swatch';
import { Tooltip } from '@components/Tooltip';

import { BaseTagPill, TagPill } from '../../TagPill';
import { Hook as UseMultiselectTags } from '../../useMultiselectTags';

interface Props {
  className?: string;
  studyId?: number | null;
  canManage: boolean;
  multiselectTags: UseMultiselectTags;
  alwaysOpen?: boolean;
}
export const TagListItems: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  studyId,
  canManage,
  alwaysOpen,
  multiselectTags: {
    isOpen: dropdownOpen,
    highlightedIndex,
    visibleItems,
    selectedItems,
    getItemProps,
    getMenuProps,
    mode,
    setMode,
    toggleMenu,
    showCreateLink
  }
}) => {
  const { openManager } = useTagManagerActions();

  const isOpen = alwaysOpen || dropdownOpen;

  const lastSelected = [...selectedItems].pop();
  const showLastSelectedBorder = (itemId: string) =>
    lastSelected?.id === itemId && !showCreateLink() && selectedItems.length !== visibleItems.length - 1;

  const onlyManageLinkIsShowing = visibleItems.length === 1 && visibleItems[0].type === 'with_manage_tags';

  return (
    <div
      className={cn(className, 'max-h-80 overflow-auto', {
        hidden: !isOpen
      })}
      {...getMenuProps({}, { suppressRefError: true })}
    >
      {studyId && (
        <div className='px-2'>
          <Tabs
            customTabPaddings='py-1'
            current={mode}
            tabs={['study', 'global']}
            onSelect={setMode}
            labels={{ study: 'Study tags', global: 'Global tags' }}
          />
        </div>
      )}
      {isOpen && onlyManageLinkIsShowing && (
        <div className='w-full px-4 py-3'>
          <Text h='400'>No {mode} tags created.</Text>
          <Text h='200' color='gray-500'>
            Type to create one.
          </Text>
        </div>
      )}
      <ul>
        {isOpen && onlyManageLinkIsShowing && !canManage && (
          <li key='empty' className='w-full px-4 py-3'>
            <Text h='400'>No tags match your search</Text>
            <Text h='200' color='gray-500'>
              Please search for another tag or ask your account administrator to add a new tag to your library.
            </Text>
          </li>
        )}
        {isOpen &&
          visibleItems.map((item, index) => (
            <li
              key={item.id}
              className={cn('w-full', {
                'cursor-pointer': item.type !== 'with_group_header',
                'bg-gray-50': highlightedIndex === index && item.type !== 'with_group_header'
              })}
              {...getItemProps({ item, index })}
            >
              {item.type === 'with_group_header' && (
                <div className='flex w-full items-center space-x-2 border-t border-gray-200 px-2 py-2'>
                  <Text h='200' color='gray-500'>
                    {item.header}
                  </Text>
                </div>
              )}
              {item.type === 'with_tag' && (
                <div
                  className={cn('flex w-full items-center space-x-2 px-4 py-2', {
                    'border-b border-gray-200': showLastSelectedBorder(item.id)
                  })}
                >
                  <Checkbox selected={selectedItems.some((i) => i.id === item.id)} className='rounded' />
                  <TagPill tag={item.tag} size='sm' />
                  {item.suggested && (
                    <Tooltip content='AI generated suggestion'>
                      <AskAiSVG className='text-indigo-600' aria-label='AI generated tag suggestion' />
                    </Tooltip>
                  )}
                </div>
              )}
              {item.type === 'with_tag_group' && (
                <div
                  className={cn('flex w-full items-center space-x-2 px-4 py-2', {
                    'border-b border-gray-200': !item.open
                  })}
                >
                  <div
                    className='mr-2 flex h-4 w-4 items-center justify-center rounded-sm'
                    style={{ backgroundColor: getBackgroundColor(item.tagGroup.color, 0.2) }}
                  />
                  <Text h='400' color={item.tagGroup.name ? undefined : 'gray-500'} className='flex-1'>
                    {item.tagGroup.name || 'Unnamed Group'}
                  </Text>
                  <div>
                    {!!item.count && (
                      <div
                        className={cn('h200 inline rounded-sm px-2', {
                          'text-white': TAG_COLORS[item.tagGroup.color].whiteText
                        })}
                        style={{ backgroundColor: getBackgroundColor(item.tagGroup.color, 0.2) }}
                      >
                        {item.count}
                      </div>
                    )}
                  </div>
                  {item.open && <ChevronUpSVG />}
                  {!item.open && <ChevronDownSVG />}
                </div>
              )}
              {item.type === 'with_name_and_create_link' && (
                <a
                  data-testid='create-tag'
                  className='flex items-center border-t border-gray-200 px-4 py-3 text-sm text-gray-700 hover:text-gray-700'
                >
                  <PlusSVG className='mr-2 h-4 w-4' />
                  <Text className='mr-2' h='400'>
                    Create {mode === 'study' ? 'study tag' : 'global tag'}:
                  </Text>
                  <BaseTagPill tag={item.name} color='default' size='sm' count={0} />
                </a>
              )}
              {item.type === 'with_group_picker' && (
                <a className='flex items-center border-t border-gray-200 px-4 py-3 text-sm text-gray-700 hover:text-gray-700'>
                  <Swatch className='mr-2' color={item.tagGroup?.color} />
                  <Text className='flex-1' h='400' truncate>
                    {item.tagGroup?.name || 'Ungrouped'}
                  </Text>
                  <ChevronRightSVG />
                </a>
              )}
              {item.type === 'with_manage_tags' &&
                (mode === 'study' ? (
                  <Link
                    className='flex items-center border-t border-gray-200 px-4 py-1.5 text-sm text-gray-700 hover:text-gray-700'
                    to={`/studies/${studyId}/tags`}
                  >
                    <ExternalLinkSVG className='mr-2 h-4 w-4' />
                    Manage study tags
                  </Link>
                ) : (
                  <a
                    className='flex items-center border-t border-gray-200 px-4 py-1.5 text-sm text-gray-700 hover:text-gray-700'
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleMenu();
                      openManager();
                    }}
                  >
                    <ExternalLinkSVG className='mr-2 h-4 w-4' />
                    Manage global tags
                  </a>
                ))}
            </li>
          ))}
      </ul>
    </div>
  );
};
