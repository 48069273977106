import qs from 'qs';
import { configureStore } from '@reduxjs/toolkit';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  setupListeners
} from '@reduxjs/toolkit/query/react';

import { ArtifactHit } from '@components/RepositoryApp/types';
import type { AlgoliaProxyApiArgs, AlgoliaProxyApiResult } from '@components/shared/Search';
import { PARTICIPATION_STATUSES } from '@components/StudiesApp/constants';
import { compact } from '@components/utils';
import { PartyAction } from 'components/StudiesApp/components/modals/CloseStudyModal/CloseStudyModal';

import {
  buildActivity,
  buildApprovalRequest,
  buildAttr_,
  buildCalendarBookingDetail,
  buildCalendarEvent,
  buildCandidateMessage,
  buildClip,
  buildConnectedAccount,
  buildConsentForm,
  buildCustomer,
  buildCustomerImport,
  buildEmailTemplate,
  buildExternalCandidatesRequest,
  buildHighlight,
  buildIncentive,
  buildInsight,
  buildInterviewTemplate,
  buildLandingPage,
  buildParticipation,
  buildProject,
  buildRecord,
  buildRecording,
  buildRepoSession,
  buildRoleRequest,
  buildScreener,
  buildScreenerField,
  buildSimpleStudy,
  buildStudyTemplate,
  buildZoomRecording
} from './builders';
import { getToken } from './client';
import type { Paged, PagedCountless, WithPagy } from './queries';
import { ConfirmStudyAllocationProps, PagyRequest, StudyAllocationProps } from './queries';
export const defaultBaseQuery = fetchBaseQuery({
  baseUrl: '/api/v1/',
  prepareHeaders: (headers) => {
    const token = getToken();
    if (token) {
      headers.set('Authorization', token);
    }
    return headers;
  }
});

export const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  baseApi,
  extraOptions
) => {
  const result = await defaultBaseQuery(args, baseApi, extraOptions);
  if (result.error && result.error.status === 401) {
    await defaultBaseQuery({ url: 'users/session', method: 'DELETE' }, baseApi, extraOptions);

    window.localStorage.removeItem('ajs_user_id');
    window.localStorage.removeItem('ajs_user_traits');
    window.location.assign('/users/sign_in');
  }
  return result;
};
const CANDIDATE_PAGE_SIZE = 5000;
const PARTICIPATIONS_PAGE_SIZE = 1000;
export const api = createApi({
  reducerPath: 'api',
  tagTypes: [
    'SamlProvider',
    'Recording',
    'RepoSession',
    'RepoSessionModerator',
    'Tag',
    'Team',
    'TagGroup',
    'Screener',
    'InterviewTemplate',
    'EmailTemplate',
    'Study',
    'Plan',
    'StudyLocation',
    'StudyStats',
    'StudyLimits',
    'StudyLimitMatches',
    'StudyParticipationCounts',
    'StudySlotsBalance',
    'Participations',
    'Screener',
    'Candidate',
    'CandidateActivites',
    'CandidateAnswers',
    'CandidateInsights',
    'CandidateParticipations',
    'CustomerSegment',
    'CustomerImport',
    'SurveyTemplate',
    'Highlight',
    'HighlightReel',
    'CandidateAttrs',
    'StudyAttrs',
    'Document',
    'StudyCalendar',
    'SavedView',
    'Insight',
    'StudyUsers',
    'CandidateAttrSuggestion',
    'StudySettings',
    'StudyHighlights',
    'StudyHighlightGroups',
    'EntityGroups',
    'Whoami',
    'AiSuggestions',
    'Brand',
    'StudyMessage',
    'ConnectedAccounts',
    'Artifacts',
    'Guide',
    'SurveyBuilderBlocks',
    'ExternalCandidatesRequest',
    'ExternalCandidatesRequests',
    'EligibilityRules',
    'ApprovalRequest',
    'UserSuggestion',
    'Bookability',
    'DisabledFeatures',
    'ConsentForm',
    'CustomerImport',
    'BackgroundTask',
    'AccountInvitation',
    'CalendarEventBookingDetail'
  ],
  baseQuery,
  endpoints: (builder) => ({
    getAccounts: builder.query<PartialAccount[], void>({
      query: () => 'accounts',
      transformResponse: (resp: any) => resp.data.map(buildRecord)
    }),
    getPlans: builder.query<Plan[], void>({
      query: () => 'plans',
      transformResponse: (resp: any) => resp.data.map(buildRecord),
      providesTags: ['Plan']
    }),
    getAccountUsage: builder.query<AccountUsage, PlanLimit>({
      query: (quotaName) => `/account_usages/${quotaName}`
    }),
    updateUserConfig: builder.mutation<{ interview_profile_attrs: string[] }, Partial<UserConfig>>({
      query: ({ interview_profile_attrs }) => ({
        url: '/config',
        method: 'PUT',
        body: { interview_profile_attrs }
      }),
      transformResponse: (resp: any) => resp.data,
      invalidatesTags: ['Whoami']
    }),
    searchCandidates: builder.query<Candidate[], { study_id?: number; q: string; size: number }>({
      query: (params) => ({
        url: 'candidates',
        params
      }),
      transformResponse: (resp: any) => resp.data.map(buildCustomer)
    }),
    getCandidate: builder.query<Candidate, { id: number }>({
      query: ({ id }) => `candidates/${id}`,
      transformResponse: (resp: any) => buildCustomer(resp.data),
      providesTags: (q, i, { id }) => [{ type: 'Candidate', id }]
    }),
    getCandidateActivities: builder.query<any[], { id: number }>({
      query: ({ id }) => `/activities?candidate_id=${id}`,
      transformResponse: (resp: any) => resp.data.map(buildActivity),
      providesTags: (q, i, { id }) => (q ? [{ type: 'CandidateActivites', id }] : [])
    }),
    getCandidateAnswers: builder.query<any[], { id: number }>({
      query: ({ id }) => `/candidates/${id}/answers`,
      transformResponse: (resp: any) => resp.data,
      providesTags: (q, i, { id }) => (q ? [{ type: 'CandidateAnswers', id }] : [])
    }),
    getCandidateParticipations: builder.query<Participation[], { id: number }>({
      query: ({ id }) => `/candidates/${id}/participations`,
      transformResponse: (resp: any) => resp.data.map(buildParticipation),
      providesTags: (q, i, { id }) => (q ? [{ type: 'CandidateParticipations', id }] : [])
    }),
    destroyCandidate: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/candidates/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (q, i, { id }) => [
        { type: 'Candidate', id },
        { type: 'CandidateActivites', id },
        { type: 'CandidateAnswers', id },
        { type: 'CandidateInsights', id },
        { type: 'CandidateParticipations', id }
      ]
    }),
    updateCandidate: builder.mutation<
      Candidate,
      Patch<Candidate> & {
        originalQueryArgs?: {
          items: number;
          page: number;
          updatedSince?: number;
          searchQuery?: string;
          op?: string;
          sort?: string;
          sortDesc?: boolean;
          filters?: string[];
        };
      }
    >({
      query: ({ originalQueryArgs, ...candidate }) => ({
        url: `/candidates/${candidate.id}`,
        method: 'PUT',
        body: candidate
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Candidate', id }],
      transformResponse: (resp: any) => buildCustomer(resp.data),
      onQueryStarted: async ({ originalQueryArgs, ...candidate }, { dispatch, queryFulfilled }) => {
        if (!originalQueryArgs) return;

        try {
          const result = await queryFulfilled;
          // update the cached candidate instead of refetching the whole list
          dispatch(
            api.util.updateQueryData('getServerSideCandidates', originalQueryArgs, (draft) => {
              const index = draft.data.findIndex((c) => c.id === result.data.id);
              if (index > -1) {
                draft.data[index] = { ...draft.data[index], ...result.data }; // Use the server's response
              }
            })
          );
        } catch (err) {
          console.error('Error updating candidate:', err);
        }
      }
    }),
    getCandidatesPaged: builder.query<Paged<Candidate>, { page: number; updatedSince?: number; size: number }>({
      query: ({ page, updatedSince: updated_since }) =>
        `candidates?${qs.stringify({ items: CANDIDATE_PAGE_SIZE, page, updated_since })}`,
      transformResponse: (resp: any) => ({
        data: resp.data.map(buildCustomer),
        meta: resp.meta
      })
    }),
    getCandidatesStats: builder.query<
      CandidateStats,
      {
        items: number;
        updatedSince?: number;
        searchQuery?: string;
        op?: string;
        filters?: string[];
      }
    >({
      query: ({ items, updatedSince: updated_since, searchQuery: q, op, filters }) =>
        `candidates/stats?${qs.stringify(
          { items, updated_since, q, op, filters },
          { encode: true, arrayFormat: 'brackets', encodeValuesOnly: true }
        )}`
    }),
    getServerSideCandidates: builder.query<
      PagedCountless<Candidate>,
      {
        items: number;
        page: number;
        updatedSince?: number;
        searchQuery?: string;
        op?: string;
        sort?: string;
        sortDesc?: boolean;
        filters?: string[];
      }
    >({
      query: ({ items, page, updatedSince: updated_since, searchQuery: q, op, sort, sortDesc: sort_desc, filters }) =>
        `server_side_candidates?${qs.stringify(
          { items, page, updated_since, q, op, sort, sort_desc, filters },
          { encode: true, arrayFormat: 'brackets', encodeValuesOnly: true }
        )}`,
      transformResponse: (resp: any) => ({
        data: resp.data.map(buildCustomer),
        meta: resp.meta
      }),
      providesTags: () => [{ type: 'Candidate', id: 'LIST' }]
    }),
    updateCandidateContactAccess: builder.mutation<
      void,
      {
        contact_access: 'public' | 'needs_team';
        team_ids: number[];
        candidates: { ids?: number[]; query?: ServerFilterQuery };
      }
    >({
      query: ({ contact_access, team_ids, candidates }) => ({
        url: `/candidate_access`,
        method: 'PUT',
        body: { contact_access, team_ids, candidates }
      }),
      invalidatesTags: (q, i, { team_ids }) => team_ids.map((id) => ({ type: 'Team', id }))
    }),
    canAccessCandidates: builder.query<
      boolean,
      { candidates: { ids?: number[]; query?: FilterQuery }; team_id?: number | null }
    >({
      query: ({ candidates, team_id }) => ({
        url: `/candidate_access`,
        method: 'POST',
        body: { candidates, team_id }
      }),
      transformResponse: (resp: any) => resp.can_access
    }),
    joinOrLeaveTeam: builder.mutation<void, { teamId: number; leave?: boolean }>({
      query: ({ teamId, leave = false }) => ({
        url: `/teams/${teamId}/join`,
        method: leave ? 'DELETE' : 'PUT'
      }),
      invalidatesTags: () => [{ type: 'Team', id: 'LIST' }]
    }),
    getSegments: builder.query<CustomerSegment[], void>({
      query: () => '/segments',
      transformResponse: (resp: any) => resp.data.map(buildRecord),
      providesTags: ['CustomerSegment']
    }),
    createSegment: builder.mutation<CustomerSegment, Partial<CustomerSegment>>({
      query: (segment) => ({
        url: '/segments',
        method: 'POST',
        body: { segment }
      }),
      transformResponse: (resp: any) => buildRecord(resp.data),
      invalidatesTags: ['CustomerSegment']
    }),
    updateSegment: builder.mutation<CustomerSegment, Patch<CustomerSegment>>({
      query: (segment) => ({
        url: `/segments/${segment.id}`,
        method: 'PUT',
        body: { segment }
      }),
      transformResponse: (resp: any) => buildRecord(resp.data),
      invalidatesTags: ['CustomerSegment']
    }),
    destroySegment: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/segments/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['CustomerSegment']
    }),
    getQuestionTemplates: builder.query<ScreenerField[], void>({
      query: () => 'question_templates',
      transformResponse: (resp: any) => resp.data
    }),
    getQuestionHistory: builder.query<ScreenerField[], void>({
      query: () => 'question_templates/history',
      transformResponse: (resp: any) => resp.data.map(({ attributes }) => attributes)
    }),
    getInterviewTemplate: builder.query<InterviewTemplate, number>({
      query: (id) => `study_templates/${id}`,
      transformResponse: (resp: any) => buildInterviewTemplate(resp.data),
      providesTags: (q, i, id) => [{ type: 'InterviewTemplate', id }]
    }),
    getSurveyTemplate: builder.query<SurveyTemplate, number>({
      query: (id) => `study_templates/${id}`,
      transformResponse: (resp: any) => buildStudyTemplate(resp.data) as any,
      providesTags: (q, i, id) => [{ type: 'SurveyTemplate', id }]
    }),
    createInterviewTemplate: builder.mutation<InterviewTemplate, void>({
      query: () => ({
        url: 'study_templates',
        body: { study_template: { kind: 'interview' } },
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildInterviewTemplate(resp.data),
      invalidatesTags: () => [{ type: 'InterviewTemplate', id: 'LIST' }]
    }),
    createSurveyTemplate: builder.mutation<SurveyTemplate, void>({
      query: () => ({
        url: 'study_templates',
        body: { study_template: { kind: 'survey' } },
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildStudyTemplate(resp.data) as any,
      invalidatesTags: () => [{ type: 'SurveyTemplate', id: 'LIST' }]
    }),
    updateInterviewTemplate: builder.mutation<void, Patch<InterviewTemplate>>({
      query: (study_template) => ({
        url: `study_templates/${study_template.id}`,
        method: 'PUT',
        body: { study_template }
      }),
      invalidatesTags: (q, i, { id }) => [
        { type: 'InterviewTemplate', id },
        { type: 'InterviewTemplate', id: 'LIST' },
        { type: 'SurveyTemplate', id },
        { type: 'SurveyTemplate', id: 'LIST' }
      ],
      transformResponse: (resp: any) => buildStudyTemplate(resp.data) as any
    }),
    updateSurveyTemplateScreener: builder.mutation<Screener, { id: number; fields: ScreenerField[] }>({
      query: ({ id, fields }) => ({
        url: `survey_templates/${id}/screener`,
        method: 'PUT',
        body: { fields }
      }),
      invalidatesTags: (q, i, { id }) => [
        { type: 'SurveyTemplate', id },
        { type: 'SurveyTemplate', id: 'LIST' }
      ],
      transformResponse: (resp: any) => buildScreener(resp.data)
    }),
    destroyInterviewTemplate: builder.mutation<void, number>({
      query: (id) => ({
        url: `study_templates/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: () => [{ type: 'InterviewTemplate', id: 'LIST' }]
    }),
    destroySurveyTemplate: builder.mutation<void, number>({
      query: (id) => ({
        url: `study_templates/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: () => [{ type: 'SurveyTemplate', id: 'LIST' }]
    }),
    duplicateStudyTemplate: builder.mutation<AbstractTemplate, { id: number }>({
      query: ({ id }) => ({
        url: `study_templates/${id}/duplicate`,
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildStudyTemplate(resp.data) as any,
      invalidatesTags: () => [
        { type: 'SurveyTemplate', id: 'LIST' },
        { type: 'InterviewTemplate', id: 'LIST' }
      ]
    }),
    getInterviewTemplates: builder.query<InterviewTemplate[], void>({
      query: () => '/study_templates?kind=interview',
      transformResponse: (resp: any) => resp.data.map(buildInterviewTemplate),
      providesTags: () => [{ type: 'InterviewTemplate', id: 'LIST' }]
    }),
    getSurveyTemplates: builder.query<SurveyTemplate[], void>({
      query: () => '/study_templates?kind=survey',
      transformResponse: (resp: any) => resp.data.map(buildStudyTemplate),
      providesTags: () => [{ type: 'SurveyTemplate', id: 'LIST' }]
    }),
    getEmailTemplate: builder.query<EmailTemplate, number>({
      query: (id) => `email_templates/${id}`,
      transformResponse: (resp: any) => buildEmailTemplate(resp.data),
      providesTags: (q, i, id) => [{ type: 'EmailTemplate', id }]
    }),
    createEmailTemplate: builder.mutation<EmailTemplate, Partial<EmailTemplate> | undefined>({
      query: (email_template) => ({
        url: 'email_templates',
        method: 'POST',
        body: { email_template }
      }),
      transformResponse: (resp: any) => buildEmailTemplate(resp.data),
      invalidatesTags: () => [{ type: 'EmailTemplate', id: 'LIST' }]
    }),
    duplicateEmailTemplate: builder.mutation<EmailTemplate, { id: number }>({
      query: ({ id }) => ({
        url: `email_templates/${id}/duplicate`,
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildEmailTemplate(resp.data),
      invalidatesTags: () => [{ type: 'EmailTemplate', id: 'LIST' }]
    }),
    updateEmailTemplate: builder.mutation<void, Patch<EmailTemplate>>({
      query: (email_template) => ({
        url: `email_templates/${email_template.id}`,
        method: 'PUT',
        body: { email_template }
      }),
      invalidatesTags: (q, i, { id }) => [
        { type: 'EmailTemplate', id },
        { type: 'EmailTemplate', id: 'LIST' }
      ]
    }),
    destroyEmailTemplate: builder.mutation<void, number>({
      query: (id) => ({
        url: `email_templates/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: () => [{ type: 'EmailTemplate', id: 'LIST' }]
    }),
    getEmailTemplates: builder.query<EmailTemplate[], { event?: EmailTemplate['event'] } | void>({
      query: (params) => ({
        url: 'email_templates',
        params: {
          event: params?.event
        }
      }),
      transformResponse: (resp: any) => resp.data.map(buildEmailTemplate),
      providesTags: () => [{ type: 'EmailTemplate', id: 'LIST' }]
    }),
    createRecording: builder.mutation<
      Recording,
      {
        zoom_meeting_id?: string;
        filename?: string;
        mux_passthrough?: string;
        byte_size?: number;
        participation_id?: number;
      }
    >({
      query: ({ filename, mux_passthrough, byte_size }) => {
        const recording = { filename, byte_size };
        const mux_video = { passthrough: mux_passthrough };

        return {
          url: '/recordings',
          method: 'POST',
          body: { recording, mux_video }
        };
      },
      transformResponse: (resp: any) => buildRecording(resp.data)
    }),
    getRecording: builder.query<Recording, { id: number }>({
      query: ({ id }) => `recordings/${id}`,
      transformResponse: (resp: any) => buildRecording(resp.data),
      providesTags: (q, i) => (q ? [{ type: 'Recording', id: q?.id }] : [])
    }),
    destroyRecording: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `recordings/${id}`,
        method: 'DELETE'
      })
    }),
    getRepoSession: builder.query<RepoSession, { uuid: string | undefined }>({
      query: ({ uuid }) => `repo/sessions/${uuid}`,
      transformResponse: (resp: any) => buildRepoSession(resp.data) as RepoSession,
      providesTags: (q, i) =>
        q
          ? [
              { type: 'RepoSession', uuid: q?.uuid },
              {
                type: 'RepoSessionModerator',
                uuid: q?.uuid
              }
            ]
          : []
    }),
    getRepoSessions: builder.query<RepoSession[], { studyId: number }>({
      query: ({ studyId }) => `repo/sessions?study_id=${studyId}`,
      transformResponse: (resp: any) => resp.data.map((s) => buildRepoSession(s) as RepoSession),
      providesTags: () => [{ type: 'RepoSession', id: 'LIST' }]
    }),
    createRepoSession: builder.mutation<RepoSession, Partial<RepoSession>>({
      query: (repo_session) => ({
        url: 'repo/sessions',
        method: 'POST',
        body: { repo_session }
      }),
      transformResponse: (resp: any) => buildRepoSession(resp.data),
      invalidatesTags: (_q, _i, { project_id }) => [
        { type: 'RepoSession', id: 'LIST' },
        { type: 'Study', id: project_id || undefined }
      ]
    }),
    updateRepoSession: builder.mutation<RepoSession, UuidPatch<RepoSession>>({
      query: (repo_session) => ({
        url: `repo/sessions/${repo_session?.uuid}`,
        method: 'PUT',
        body: { repo_session }
      }),
      onQueryStarted({ uuid, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData(`getRepoSession`, { uuid }, (draft) => {
            Object.assign(draft, patch);
          })
        );
        queryFulfilled.catch(patchResult.undo);
      },
      transformResponse: (resp: any) => buildRepoSession(resp.data),
      invalidatesTags: (q, i) =>
        compact([q && { type: 'RepoSession', uuid: q?.uuid }, { type: 'RepoSession', id: 'LIST' }, 'Tag'])
    }),
    destroyRepoSession: builder.mutation<void, { uuid: RepoSession['uuid']; study_id?: number }>({
      query: ({ uuid }) => ({
        url: `repo/sessions/${uuid}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_q, _i, { uuid, study_id }) => [
        { type: 'RepoSession', uuid },
        { type: 'RepoSession', id: 'LIST' },
        { type: 'Study', id: study_id || undefined }
      ]
    }),
    createZoomBulkImport: builder.mutation<
      { recordings: Recording[] },
      { zoom_meetings: { meeting_id: number; uuid: string }[] }
    >({
      query: ({ zoom_meetings }) => ({
        url: 'zoom/bulk_import',
        method: 'POST',
        body: { bulk_import: { zoom_meetings } }
      }),
      transformResponse: (resp: any) => ({
        recordings: resp.recordings.data.map(buildRecording)
      })
    }),
    getStudiesPaged: builder.query<
      Paged<Study>,
      PagyRequest<{
        teamId?: number | null;
        includeArchived?: boolean;
        tab?: string;
      }>
    >({
      query: ({ sortDesc: sort_desc, teamId: team_id, includeArchived: include_archived, ...params }) =>
        `/studies?${qs.stringify(
          {
            ...params,
            paginate: true,
            sort_desc,
            team_id,
            include_archived
          },
          { encode: true, arrayFormat: 'brackets', encodeValuesOnly: true }
        )}`,
      transformResponse: (resp: any) => ({
        data: resp.data.map(buildProject),
        meta: resp.meta
      }),
      providesTags: () => [{ type: 'Study', id: 'LIST' }]
    }),
    getStudiesCount: builder.query<number, void>({
      query: () => '/studies_count',
      transformResponse: (resp: any) => resp.count
    }),
    getStudySenders: builder.query<EmailSender[], Study['id']>({
      query: (id) => `/studies/${id}/senders`,
      transformResponse: (resp: any) => resp.data
      // providesTags: ['Tag']
    }),
    getStudySettings: builder.query<StudySettings, number>({
      query: (id) => `/studies/${id}/settings`,
      transformResponse: (resp: any) => resp.data,
      providesTags: (q, i, id) => [{ type: 'StudySettings', id }]
    }),
    getStudyConsentCheckboxes: builder.query<ConsentCheckbox[], number>({
      query: (id) => `/studies/${id}/consent_checkboxes`,
      transformResponse: (resp: any) => resp.data
    }),
    updateStudySettings: builder.mutation<StudySettings, { id: number; settings: StudySettings }>({
      query: ({ id, settings }) => ({
        url: `/studies/${id}/settings`,
        method: 'PUT',
        body: { settings }
      }),
      invalidatesTags: (q, i, { id }) => [
        { type: 'Study', id },
        { type: 'StudySettings', id }
      ]
    }),
    getStudyZoomLiveStreamPermissions: builder.query<ZoomLiveStreamPermissions, number>({
      query: (studyId) => `/studies/${studyId}/zoom_live_stream_permissions`,
      transformResponse: (resp: any) => resp.data
    }),
    getUsersSuggestions: builder.query<TeamUser[], { query?: string; context?: UserSuggestionContext }>({
      query: ({ query, context }) => `/users_suggestions?${qs.stringify({ q: query, context })}`,
      transformResponse: (resp: any) => resp.data.map((d) => d.attributes),
      providesTags: (q, i, obj) => [{ type: 'UserSuggestion', id: 'LIST' }]
    }),
    getEmailSenders: builder.query<EmailSender[], { query?: string }>({
      query: ({ query }) => `/email/senders?${qs.stringify({ q: query })}`,
      transformResponse: (resp: any) => resp.data.map((d) => d.attributes)
    }),
    createStudyFromTemplate: builder.mutation<Study, { templateId?: number }>({
      query: ({ templateId }) => ({
        url: `/studies?template_study_id=${templateId}`,
        method: 'POST'
      }),

      transformResponse: (resp: any) => resp.data.map(buildProject)
    }),
    createStudy: builder.mutation<Study, Partial<Study>>({
      query: (study) => ({
        url: `/studies`,
        method: 'POST',
        body: { study }
      }),
      transformResponse: (resp: any) => buildProject(resp.data) as any
    }),
    getStudyInsight: builder.query<Insight, { studyId: number }>({
      query: ({ studyId }) => `/studies/${studyId}/story`,
      transformResponse: (resp: any) => buildInsight(resp.data)
    }),
    getStudySlotsBalance: builder.query<StudySlotsBalance, number>({
      query: (id) => `/studies/${id}/slots_balance`,
      providesTags: (q, i, id) => [{ type: 'StudySlotsBalance', id }],
      transformResponse: (resp: any) => resp.data
    }),
    getProlificFilters: builder.query<ExternalCandidatesFilterListResponse[], void>({
      query: () => '/external_candidates/prolific_filters'
    }),
    getRespondentIndustries: builder.query<ExternalCandidatesIndustry[], void>({
      query: () => '/external_candidates/respondent_industries'
    }),
    getRespondentJobTitles: builder.query<ExternalCandidatesJobTitle[], { query?: string }>({
      query: ({ query }) => `/external_candidates/respondent_job_titles?${query ? qs.stringify({ query: query }) : ''}`
    }),
    getRespondentSkills: builder.query<ExternalCandidatesSkill[], { query?: string }>({
      query: ({ query }) => `/external_candidates/respondent_skills?${query ? qs.stringify({ query: query }) : ''}`
    }),
    getRespondentTopics: builder.query<ExternalCandidatesTopic[], { query?: string }>({
      query: ({ query }) => `/external_candidates/respondent_topics?${query ? qs.stringify({ query: query }) : ''}`
    }),
    getTags: builder.query<Tag[], void | { studyId?: number }>({
      query: (p) => `/tags?${p?.studyId ? qs.stringify({ project_id: p.studyId }) : ''}`,
      transformResponse: (resp: any) => resp.data.map((d) => d.attributes),
      providesTags: ['Tag']
    }),
    getHighlights: builder.query<DocumentHighlight[], void>({
      query: () => '/highlights',
      transformResponse: (resp: any) => resp.data.map((d) => d.attributes)
    }),
    getHighlightsFromDocument: builder.query<DocumentHighlight[], number>({
      query: (id) => `/documents/${id}/highlights`,
      transformResponse: (resp: any) => resp.data.map((d) => d.attributes),
      providesTags: () => [{ type: 'Highlight', id: 'LIST' }]
    }),
    getHighlightReels: builder.query<HighlightReel[], void>({
      query: () => '/highlight_reels',
      transformResponse: (resp: any) => resp.data.map(buildRecord)
    }),
    getHighlightReel: builder.query<HighlightReel, string>({
      query: (token) => `/highlight_reels/${token}`,
      transformResponse: (resp: any) => buildRecord(resp.data),
      providesTags: (q, i, token) => [{ type: 'HighlightReel', token }]
    }),
    createHighlightReel: builder.mutation<HighlightReel, Partial<HighlightReel>>({
      query: (highlight_reel) => ({
        url: '/highlight_reels',
        method: 'POST',
        body: { highlight_reel }
      }),
      transformResponse: (resp: any) => buildRecord(resp.data)
    }),
    updateHighlightReel: builder.mutation<HighlightReel, Partial<HighlightReel>>({
      query: (highlightReel) => ({
        url: `/highlight_reels/${highlightReel.token}`,
        method: 'PUT',
        body: { highlight_reel: highlightReel }
      }),
      invalidatesTags: (q) => [
        'Tag',
        { type: 'HighlightReel', token: q?.token },
        { type: 'Document', id: q?.document_id }
      ],
      transformResponse: (resp: any) => buildRecord(resp.data)
    }),
    destroyHighlightReel: builder.mutation<void, string>({
      query: (token) => ({
        url: `/highlight_reels/${token}`,
        method: 'DELETE'
      })
    }),
    getHighlightReelClips: builder.query<Clip[], string>({
      query: (token) => `/highlight_reels/${token}/clips`,
      transformResponse: (resp: any) => resp.data.map(buildClip),
      providesTags: (q, i, token) => [{ type: 'HighlightReel', token }]
    }),
    addClipsToHighlightReel: builder.mutation<void, { token: string; clips: { id: number }[] }>({
      query: ({ token, clips }) => ({
        url: `/highlight_reels/${token}/clips`,
        method: 'POST',
        body: { highlight_reel: { clips } }
      }),
      invalidatesTags: (q, i, p) => [{ type: 'HighlightReel', token: p.token }]
    }),
    getClip: builder.query<Clip, { highlightUuid: string }>({
      query: ({ highlightUuid }) => `/clips/${highlightUuid}`,
      transformResponse: (resp: any) => buildClip(resp.data)
    }),
    destroyClip: builder.mutation<void, { highlightUuid: string }>({
      query: ({ highlightUuid }) => ({
        method: 'DELETE',
        url: `/clips/${highlightUuid}`
      }),
      invalidatesTags: [{ type: 'StudyHighlights' }, { type: 'Artifacts' }]
    }),
    getClipHighlightReels: builder.query<HighlightReel[], { highlightUuid: string }>({
      query: ({ highlightUuid }) => `/clips/${highlightUuid}/highlight_reels`,
      transformResponse: (resp: any) => resp.data.map(buildRecord)
    }),
    getHighlightById: builder.query<DocumentHighlight, { id: string }>({
      query: ({ id }) => `/highlights/${id}`,
      transformResponse: (resp: any) => resp.data.attributes
    }),
    updateHighlight: builder.mutation<DocumentHighlight, Partial<DocumentHighlight> & { uuid: string }>({
      query: (highlight) => ({
        method: 'PUT',
        url: `/highlights/${highlight.uuid}`,
        body: { highlight }
      }),
      invalidatesTags: [{ type: 'StudyHighlights' }, { type: 'Artifacts' }],
      transformResponse: (resp: any) => buildHighlight(resp.data)
    }),
    destroyHighlight: builder.mutation<void, { highlightUuid: string }>({
      query: ({ highlightUuid }) => ({
        method: 'DELETE',
        url: `/highlights/${highlightUuid}`
      }),
      invalidatesTags: [
        { type: 'StudyHighlights' },
        { type: 'Artifacts' },
        { type: 'Document' },
        {
          type: 'Highlight',
          id: 'LIST'
        }
      ]
    }),
    createHighlightWithClip: builder.mutation<
      { highlight: DocumentHighlight; clip: Clip },
      {
        documentId: number;
        text: string;
        sessionUuid?: string;
        recordingId: number;
        tags?: string[];
        tag_ids?: number[];
        from: number;
        to: number;
      }
    >({
      query: ({ documentId, text, recordingId, tags, tag_ids, from, to }) => ({
        url: '/highlight_with_clips',
        method: 'POST',
        body: { document_id: documentId, text, recording_id: recordingId, tags, tag_ids, from, to }
      }),
      transformResponse: ({ highlight, clip }: { highlight: DocumentHighlight; clip: Clip }) => ({
        highlight: buildHighlight(highlight),
        clip: buildClip(clip)
      }),
      invalidatesTags: (q, i, { recordingId, sessionUuid }) => [
        'Tag',
        { type: 'Recording', id: recordingId },
        { type: 'RepoSession', uuid: sessionUuid },
        { type: 'StudyHighlights', id: 'LIST' }
      ]
    }),
    createTag: builder.mutation<Tag, Partial<Tag>>({
      query: (tag) => ({ url: '/tags', method: 'post', body: { tag } }),
      transformResponse: (resp: any) => resp.data.attributes,
      invalidatesTags: ['Tag']
    }),
    getTagGroups: builder.query<TagGroup[], void>({
      query: () => '/tag_groups',
      transformResponse: (resp: any) => resp.data.map((d) => d.attributes),
      providesTags: ['TagGroup']
    }),
    createTagGroup: builder.mutation<TagGroup, Partial<TagGroup>>({
      query: (tag_group) => ({
        url: '/tag_groups',
        method: 'POST',
        body: { tag_group }
      }),
      transformResponse: (resp: any) => resp.data.attributes,
      invalidatesTags: ['TagGroup']
    }),
    updateTagGroup: builder.mutation<void, Patch<TagGroup>>({
      query: (tag_group) => ({
        url: `/tag_groups/${tag_group.id}`,
        method: 'PUT',
        body: { tag_group }
      }),
      invalidatesTags: ['TagGroup', 'Tag']
    }),
    destroyTagGroup: builder.mutation<void, number>({
      query: (id) => ({
        url: `/tag_groups/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['TagGroup', 'Tag']
    }),
    updateTag: builder.mutation<void, { key: string } & Partial<Tag>>({
      query: (tag) => ({
        url: `/tags/${encodeURIComponent(tag.key)}?${qs.stringify({ project_id: tag.project_id })}`,
        method: 'PUT',
        body: { tag }
      }),
      invalidatesTags: ['Tag']
    }),
    bulkUpdateTags: builder.mutation<void, { ids: number[]; tags: Partial<Tag>; studyId?: number }>({
      query: ({ ids, tags, studyId }) => ({
        url: `/bulk_tags?${qs.stringify({ project_id: studyId })}`,
        method: 'PUT',
        body: { ids, tags }
      }),
      invalidatesTags: ['Tag']
    }),
    bulkDestroyTags: builder.mutation<void, { ids: number[] }>({
      query: (body) => ({
        url: `/bulk_tags`,
        method: 'DELETE',
        body
      }),
      invalidatesTags: ['Tag']
    }),
    bulkUpdateMergeTags: builder.mutation<void, { ids: number[]; target: Partial<Tag> }>({
      query: (body) => ({
        url: `/bulk_tags/merge`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Tag']
    }),
    getStudy: builder.query<Study, number>({
      query: (id) => `/studies/${id}`,
      transformResponse: (resp: any) => buildProject(resp.data),
      providesTags: (q, i, id) => [{ type: 'Study', id }]
    }),
    previewMaximumSlots: builder.mutation<Study, { id: number } & Partial<Study>>({
      query: (study) => {
        return {
          url: `/studies/${study.id}/preview_maximum_slots`,
          method: 'PUT',
          body: { study }
        };
      }
    }),
    createStudyAllocation: builder.mutation<WalletAllocation, { id: number; allocation?: StudyAllocationProps }>({
      query: ({ id, allocation }) => ({
        url: `/wallet/studies/${id}/allocations`,
        method: 'POST',
        body: { allocation }
      }),
      transformResponse: (resp: any) => buildRecord(resp.data)
    }),
    confirmStudyAllocation: builder.mutation<
      any,
      { id: number; allocation: ConfirmStudyAllocationProps & StudyAllocationProps }
    >({
      query: ({ id, allocation }) => ({
        url: `/wallet/studies/${allocation?.study_id}/allocations/${id}/confirm`,
        method: 'POST',
        body: { allocation }
      }),
      invalidatesTags: (q, i, { allocation }) => [
        { type: 'Study', id: allocation.study_id },
        { type: 'ExternalCandidatesRequests', id: allocation.study_id }
      ]
    }),
    requestStudyAllocation: builder.mutation<
      any,
      { id: number; allocation: { study_id: number; usd_amount_in_cents?: number } & StudyAllocationProps }
    >({
      query: ({ id, allocation }) => ({
        url: `/wallet/studies/${allocation?.study_id}/allocations/${id}/request`,
        method: 'POST',
        body: { allocation }
      }),
      invalidatesTags: (q, i, { allocation }) => [{ type: 'Study', id: allocation.study_id }]
    }),
    createTeamAllocation: builder.mutation<
      void,
      { id: number; type: 'funds' | 'credits'; usd_amount_in_cents: number; source_team_id?: number }
    >({
      query: ({ id, type, usd_amount_in_cents, source_team_id }) => ({
        url: `/wallet/teams/${id}/allocations`,
        method: 'POST',
        body: { type: type, usd_amount_in_cents: usd_amount_in_cents, source_team_id: source_team_id }
      }),
      invalidatesTags: [{ type: 'Team', id: 'LIST_WITH_WALLETS' }]
    }),
    createStudyUser: builder.mutation<
      void,
      | { user_id: number; study_id: number; role?: StudyUser['role'] }
      | { study_id: number; role?: StudyUser['role']; email: string }
    >({
      query: ({ study_id, ...body }) => ({
        url: `/studies/${study_id}/users`,
        method: 'POST',
        body
      }),
      invalidatesTags: (q, i, { study_id }) => [
        { type: 'Study', id: study_id },
        { type: 'StudyUsers', id: study_id },
        { type: 'UserSuggestion', id: 'LIST' }
      ]
    }),
    updateStudyUser: builder.mutation<
      Study,
      {
        user_id: number;
        study_id: number;
        role?: StudyUser['role'];
        calendar_id?: StudyUser['calendar_id'];
      }
    >({
      query: ({ user_id, study_id, ...body }) => ({
        url: `/studies/${study_id}/users/${user_id}`,
        method: 'PUT',
        body
      }),
      transformResponse: (resp: any) => buildProject(resp.data),
      invalidatesTags: (q, i, { study_id }) => [
        { type: 'Study', id: study_id },
        { type: 'StudyUsers', id: study_id },
        { type: 'Bookability', id: study_id },
        { type: 'UserSuggestion', id: 'LIST' }
      ]
    }),
    deleteStudyUser: builder.mutation<Study, { study_id: number; user_id: number }>({
      query: ({ study_id, user_id }) => ({
        url: `/studies/${study_id}/users/${user_id}`,
        method: 'DELETE'
      }),
      transformResponse: (resp: any) => buildProject(resp.data),
      invalidatesTags: (q, i, { study_id }) => [
        { type: 'Study', id: study_id },
        { type: 'StudyUsers', id: study_id },
        { type: 'UserSuggestion', id: 'LIST' }
      ]
    }),
    getStudyUsers: builder.query<StudyUser[], number>({
      query: (id) => `/studies/${id}/users`,
      transformResponse: (resp: any) => resp.data,
      providesTags: (q, i, id) => [{ type: 'StudyUsers', id }]
    }),
    updateStudy: builder.mutation<
      Study,
      { id: number; shouldInvalidateStudiesList?: boolean } & Partial<Study> & {
          originalQueryArgs?: PagyRequest<{
            teamId?: number | null | undefined;
            includeArchived?: boolean | undefined;
            tab?: string | undefined;
          }>;
        }
    >({
      query: ({ originalQueryArgs, shouldInvalidateStudiesList, ...study }) => {
        const updatesStudy = { ...study };
        delete updatesStudy.label;

        return {
          url: `/studies/${study.id}`,
          method: 'PUT',
          body: { study: updatesStudy }
        };
      },
      transformResponse: (resp: any) => buildProject(resp.data),
      onQueryStarted: async ({ originalQueryArgs }, { dispatch, queryFulfilled }) => {
        if (!originalQueryArgs) return;

        try {
          const result = await queryFulfilled;
          // update the cached study instead of refetching the whole list
          dispatch(
            api.util.updateQueryData('getStudiesPaged', originalQueryArgs, (draft) => {
              const index = draft.data.findIndex((s) => s.id === result.data.id);
              if (index > -1) {
                draft.data[index] = { ...draft.data[index], ...result.data }; // Use the server's response
              }
            })
          );
        } catch (err) {
          console.error('Error updating study:', err);
        }
      },
      invalidatesTags: (q, i, { id, shouldInvalidateStudiesList = true }) =>
        [
          { type: 'Study', id },
          { type: 'StudySlotsBalance', id },
          { type: 'ExternalCandidatesRequests', id },
          { ...(shouldInvalidateStudiesList ? { type: 'Study', id: 'LIST' } : {}) },
          { type: 'Bookability', id },
          { type: 'UserSuggestion', id: 'LIST' }
        ] as any[]
    }),
    updateStudyScreenerTemplate: builder.mutation<
      Study,
      { id: number; screener_template_id?: number; screener_id?: number }
    >({
      query: ({ id, screener_template_id, screener_id }) => ({
        url: `/studies/${id}/update_screener_template`,
        method: 'POST',
        body: { study: { screener_template_id, screener_id } }
      }),
      transformResponse: (resp: any) => buildProject(resp.data),
      invalidatesTags: (q, i, { id }) => [{ type: 'Study', id }]
    }),

    duplicateStudy: builder.mutation<
      Study,
      { id: number; study?: Partial<Pick<Study, 'id' | 'title' | 'slug' | 'owner_id' | 'team_id'>> }
    >({
      query: ({ id, study }) => ({
        url: `/studies/${id}/duplicate`,
        method: 'POST',
        body: { study }
      }),
      transformResponse: (resp: any) => buildProject(resp.data) as any
    }),

    closeStudy: builder.mutation<Study, { id: number; method: AllocationMethod; resolution: PartyAction }>({
      query: ({ id, method, resolution }) => ({
        url: `/studies/${id}/close`,
        method: 'POST',
        body: { method, resolution }
      }),
      transformResponse: (resp: any) => buildProject(resp.data),
      invalidatesTags: (q, i, { id }) => [
        { type: 'Study', id },
        { type: 'Study', id: 'LIST' }
      ]
    }),
    transitionStudy: builder.mutation<Study, { id: number; transition: StudyTransition }>({
      query: ({ id, transition }) => ({
        url: `/studies/${id}/${transition}`,
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildProject(resp.data),
      invalidatesTags: (q, i, { id }) => [
        { type: 'Study', id },
        { type: 'Study', id: 'LIST' }
      ]
    }),
    deleteStudy: builder.mutation<null, { id: number }>({
      query: ({ id }) => ({
        url: `/studies/${id}`,
        method: 'DELETE'
      }),
      // TODO: remove optimistic updates when paginated_studies feature flag is removed
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getSimpleStudies', undefined, (draft) => {
            return draft?.filter((s) => s.id !== id);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },

      invalidatesTags: (q, i, { id }) => [
        { type: 'Study', id },
        { type: 'Study', id: 'LIST' }
      ]
    }),
    approveStudy: builder.mutation<Study, { id: number }>({
      query: ({ id }) => ({
        url: `/studies/${id}/approve`,
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildProject(resp.data),
      invalidatesTags: (q, i, { id }) => [
        { type: 'Study', id },
        { type: 'ApprovalRequest', id }
      ]
    }),
    createApprovalRequest: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/studies/${id}/approval_requests`,
        method: 'POST'
      }),
      invalidatesTags: (q, i, { id }) => [{ type: 'ApprovalRequest', id }]
    }),
    getApprovalRequests: builder.query<ApprovalRequest[], { id: number }>({
      query: ({ id }) => `/studies/${id}/approval_requests`,
      transformResponse: (resp: any) => resp.data.map(buildApprovalRequest),
      providesTags: (q, i, { id }) => [{ type: 'ApprovalRequest', id }]
    }),
    getSimpleStudies: builder.query<SimpleStudy[], void>({
      query: (id) => '/simple_studies',
      transformResponse: (resp: any) => resp.data.map(buildSimpleStudy),
      providesTags: () => [{ type: 'Study', id: 'LIST' }]
    }),
    getSimpleStudy: builder.query<SimpleStudy, number>({
      query: (id) => `/simple_studies/${id}`,
      transformResponse: (resp: any) => buildSimpleStudy(resp.data),
      providesTags: (q, i, id) => [{ type: 'Study', id }]
    }),
    getStudyStats: builder.query<StudyStatsEvents, number>({
      query: (id) => `/studies/${id}/stats`,
      transformResponse: (resp: any) => resp.data.attributes,
      providesTags: (q, i, id) => [{ type: 'StudyStats', id }]
    }),
    getStudyParticipationCandidateSummary: builder.query<Record<number, number[]>, void>({
      query: () => '/participation_candidate_summary'
    }),
    getParticipations: builder.query<Participation[], { id?: number; fields?: string[] }>({
      query: ({ id, fields }) => ({
        url: `/participations?${qs.stringify(
          {
            project_id: id,
            fields
          },
          { arrayFormat: 'brackets', encode: false }
        )}`
      }),
      transformResponse: (resp: any) => resp.data.map(buildParticipation),
      providesTags: (q, i, { id }) => [{ type: 'Participations', id }]
    }),
    getParticipation: builder.query<Participation, { id: number }>({
      query: ({ id }) => `/participations/${id}`,
      transformResponse: (resp: any) => buildParticipation(resp.data),
      providesTags: (q, i, { id }) => [{ type: 'Participations', id }]
    }),
    getParticipationsPaged: builder.query<
      Paged<Participation>,
      {
        study_id: number;
        page: number;
        updatedSince?: number;
        fields?: string[];
        items?: number;
        query?: ParticipantsFilterQuery;
        ids?: number[];
      }
    >({
      // Doing it this way as was hitting issues with RTKquery caching (i think) for participations refetch
      query: ({ study_id, page, updatedSince: updated_since, fields, query, ids, items = PARTICIPATIONS_PAGE_SIZE }) =>
        `participations?${qs.stringify(
          {
            project_id: study_id,
            items,
            page,
            updated_since,
            fields,
            query,
            ids
          },
          { encode: false, arrayFormat: 'brackets' }
        )}`,
      transformResponse: (resp: any) => ({
        data: resp.data.map(buildParticipation),
        meta: resp.meta
      }),
      forceRefetch: () => true
    }),
    getParticipationsStatusesCounts: builder.query<
      ParticipationStatusesCounts,
      {
        study_id: number;
        query?: ParticipantsFilterQuery;
      }
    >({
      query: ({ study_id, query }) => ({
        url: `/studies/${study_id}/participation_statuses_counts?${qs.stringify(
          { query },
          { encode: false, arrayFormat: 'brackets' }
        )}`
      })
    }),
    getParticipationMessages: builder.query<ParticipationMessage[], { id?: number }>({
      query: ({ id }) => ({
        url: `/participations/${id}/messages`
      }),
      transformResponse: (resp: any) => resp.data
    }),
    getParticipationConsentForm: builder.query<SignedConsentForm[], { id?: number }>({
      query: ({ id }) => ({
        url: `/participations/${id}/consent_forms`
      }),
      transformResponse: (resp: any) => resp.data
      // providesTags: (q, i, { id }) => [{ type: 'Participations', id }]
    }),
    getParticipationCalendarEvents: builder.query<CalendarEvent[], { id?: number }>({
      query: ({ id }) => ({
        url: `/participations/${id}/calendar_events`
      }),
      transformResponse: (resp: any) => resp.data.map(buildCalendarEvent)
    }),
    getParticipationCalendarBookingDetails: builder.query<CalendarBookingDetail, { id: number }>({
      query: ({ id }) => ({
        url: `/participations/${id}/calendar_booking_details`
      }),
      transformResponse: (resp: any) => buildCalendarBookingDetail(resp.data),
      providesTags: (q, i, { id }) => [{ type: 'CalendarEventBookingDetail', id }]
    }),
    updateParticipationCalendarBookingDetails: builder.mutation<
      void,
      { id: number; bookingDetail: DeepPartial<CalendarBookingDetail> }
    >({
      query: ({ id, bookingDetail }) => ({
        url: `/participations/${id}/calendar_booking_details`,
        method: 'PUT',
        body: { ...bookingDetail }
      }),
      invalidatesTags: (q, i, { id }) => [{ type: 'CalendarEventBookingDetail', id }]
    }),
    getCalendarEvent: builder.query<CalendarEvent, { id?: number }>({
      query: ({ id }) => ({
        url: `/calendar_events/${id}`
      }),
      transformResponse: (resp: any) => buildCalendarEvent(resp.data)
    }),
    updateParticipation: builder.mutation<
      Participation,
      Partial<Pick<Participation, 'interview_at' | 'rating' | 'incentive_in_cents'>> & { id: number }
    >({
      query: (participation) => ({
        url: `/participations/${participation.id}`,
        method: 'PUT',
        body: { participation }
      }),
      invalidatesTags: (q, i, { id }) => [{ type: 'Participations', id }],
      transformResponse: (resp: any) => buildParticipation(resp.data)
    }),
    createParticipationManualBooking: builder.mutation<
      Participation,
      {
        id: number;
        message_id: number | null;
        start_time: Date | null;
        moderator_id: number | null;
      }
    >({
      query: ({ id, message_id, start_time, ...participation }) => ({
        url: `/participations/${id}/manual_bookings`,
        method: 'POST',
        body: {
          message_id,
          start_time: start_time ? Math.floor(+start_time / 1000) : null,
          participation
        }
      }),
      transformResponse: (resp: any) => buildParticipation(resp.data),
      invalidatesTags: (q, i, { id }) => [{ type: 'Participations', id }]
    }),
    getRepoSessionsLiveStream: builder.query<RepoSessionsLiveStream, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/repo/sessions/${uuid}/live_streams`
      }),
      transformResponse: (resp: any) => resp.data?.attributes
    }),
    createParticipationZoom: builder.mutation<void, { id: number; uuid: string }>({
      query: ({ id }) => ({
        url: `/participations/${id}/zoom`,
        method: 'POST'
      }),
      invalidatesTags: (q, i, { uuid }) => [{ type: 'RepoSession', uuid }]
    }),
    updateStudyScreener: builder.mutation<Screener, Partial<Screener>>({
      query: ({ project_id, id, fields }) => ({
        url: `/studies/${project_id}/screeners/${id}`,
        method: 'PUT',
        body: { screener: { fields } }
      }),
      transformResponse: (resp: any) => buildScreener(resp.data),
      invalidatesTags: (q, i, { project_id }) => [
        { type: 'Study', id: project_id },
        { type: 'ExternalCandidatesRequests', id: project_id }
      ]
    }),
    updateStudyScreenerFieldImage: builder.mutation<ScreenerField, { id: number; body: FormData }>({
      query: ({ id, body }) => ({
        url: `/screeners/fields/${id}`,
        method: 'PUT',
        body
      }),
      transformResponse: (resp: any) => buildScreenerField(resp.data)
    }),
    duplicateStudyScreenerField: builder.mutation<ScreenerField, number>({
      query: (id) => ({
        url: `/screeners/fields/${id}/duplicate`,
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildScreenerField(resp.data)
    }),
    getStudyScreenerResultsPaged: builder.query<
      WithPagy<{ results: ScreenerResponseResults }>,
      { study_id: number; screener_id: number; page: number; items?: number }
    >({
      query: ({ study_id, screener_id, page, items = 500 }) => ({
        url: `/studies/${study_id}/screeners/${screener_id}/results?items=${items}&page=${page}`,
        params: {
          page,
          items
        }
      })
    }),
    getScreenerResponse: builder.query<
      ScreenerResponse,
      { studyId?: number; screenerId?: number; participationId?: number }
    >({
      query: ({ studyId, screenerId, participationId }) =>
        `/studies/${studyId}/screeners/${screenerId}/screener_responses/${participationId}`,
      transformResponse: ({ data }) => buildRecord(data) as ScreenerResponse
    }),
    updateAccountUser: builder.mutation<WhoamiPlan, { id: number; role: AccountRole }>({
      query: ({ id, role }) => ({
        url: `/account_users/${id}`,
        method: 'PUT',
        body: {
          account_user: { role }
        }
      }),
      transformResponse: (resp: any) => resp.data?.plan
    }),
    getInviteSlideOutIds: builder.mutation<
      { inviteable_ids: number[]; reinviteable_ids: number[]; contactable_ids: number[]; ineligible_ids: number[] },
      { participationsIds: number[]; communicationMethod: string }
    >({
      query: ({ participationsIds, communicationMethod }) => ({
        url: `/candidates/invite_slide_out_ids`,
        method: 'POST',
        body: {
          participations_ids: participationsIds,
          communication_method: communicationMethod
        }
      }),
      transformResponse: (resp: any) => resp
    }),
    getInviteSlideOutCounts: builder.mutation<
      { inviteable_count: number; reinviteable_count: number; contactable_count: number; ineligible_count: number },
      { studyId: number; candidateIds?: number[]; query?: ServerFilterQuery }
    >({
      query: ({ studyId, candidateIds, query }) => ({
        url: `/candidates/invite_slide_out_counts`,
        method: 'POST',
        body: {
          study_id: studyId,
          candidate_ids: candidateIds,
          query: query
        }
      })
    }),
    getShortlistSlideOutCounts: builder.mutation<
      { shortlistable_count: number },
      { studyId: number; candidateIds?: number[]; query?: ServerFilterQuery }
    >({
      query: ({ studyId, candidateIds, query }) => ({
        url: `/candidates/shortlist_slide_out_counts`,
        method: 'POST',
        body: {
          study_id: studyId,
          candidate_ids: candidateIds,
          query: query
        }
      }),
      transformResponse: (resp: any) => resp
    }),
    getDashboardStats: builder.query<any, void>({
      query: () => 'dashboard_stats'
    }),
    getRoleRequests: builder.query<any, void>({
      query: () => `role_requests`,
      transformResponse: (resp: any) => resp.data.map(buildRoleRequest)
    }),
    getActivities: builder.query<any, { limit?: number }>({
      query: ({ limit = 5 }) => `activities?limit=${limit}`,
      transformResponse: (resp: any) => resp.data.map(buildActivity)
    }),
    getUpcomingInterviews: builder.query<any, { limit?: number }>({
      query: ({ limit = 5 }) => `upcoming_interviews?limit=${limit}`,
      transformResponse: (resp: any) => resp.data.map(buildParticipation)
    }),
    deleteUserSession: builder.mutation<void, void>({
      query: () => ({ url: '/users/session', method: 'DELETE' })
    }),
    getOnboardingChecklist: builder.query<OnboardingChecklist, void>({
      query: () => '/users/onboarding_checklist'
    }),
    getStudyLimits: builder.query<StudyLimit[], number>({
      query: (studyId) => `/studies/${studyId}/limits`,
      transformResponse: (resp: any) => resp.data.map(buildRecord),
      providesTags: ['StudyLimits']
    }),
    getStudyLimitsProgress: builder.query<StudyLimitsProgress, number>({
      query: (studyId) => `/studies/${studyId}/limits/progress`,
      providesTags: ['StudyLimits']
    }),
    updateStudyLimits: builder.mutation<void, { studyId: number; limits: StudyLimit[] }>({
      query: ({ studyId, limits }) => ({
        url: `/studies/${studyId}/limits`,
        method: 'PUT',
        body: { limits }
      }),
      invalidatesTags: ['StudyLimits']
    }),
    getStudyLimitMatches: builder.query<
      Record<number, number>,
      { studyId: number; candidateIds?: number[]; query?: ServerFilterQuery }
    >({
      query: ({ studyId, candidateIds, query }) => ({
        method: 'POST',
        url: `/studies/${studyId}/study_limits/matches`,
        body: { candidate_ids: candidateIds, query }
      }),
      transformResponse: (resp: Record<string, number>) =>
        Object.fromEntries(Object.entries(resp.data).map(([k, v]) => [parseInt(k), v])),
      providesTags: (q, i, params) => [{ type: 'StudyLimitMatches', id: params.studyId }]
    }),
    getStudyQualtricsSurveys: builder.query<ConnectedAccountSurvey[], number>({
      query: (studyId) => `/studies/${studyId}/connected_account_surveys?provider=qualtrics`
    }),
    acceptParticipationTimeProposal: builder.mutation<BackgroundTask, { participationId: number; time: string }>({
      query: ({ time, participationId }) => ({
        url: `/participations/${participationId}/time_proposal/accept`,
        method: 'POST',
        body: { time }
      })
    }),
    getDailyInviteLimit: builder.query<DailyInviteLimit, { sender: EmailSender; event: StudyMessageEvent }>({
      query: ({ sender, event }) => `/messaging/daily_limits/${event}?${qs.stringify({ sender: sender.email })}`,
      transformResponse: (resp: any) => resp.data
    }),
    getStudyBookability: builder.query<Bookability, { studyId: number; event: BookabilityEvent }>({
      query: ({ studyId, event }) => `/studies/${studyId}/bookability?event=${event}`,
      transformResponse: (resp: any) => resp.data,
      providesTags: (q, i, { studyId }) => [{ type: 'Bookability', id: studyId }]
    }),
    candidatesBulkParticipationInvite: builder.mutation<
      BackgroundTask,
      {
        studyId: number;
        sender: EmailSender;
        customer_ids?: number[];
        query?: ServerFilterQuery;
        resend: boolean;
        invite: BatchParams;
        send_now?: boolean;
        exclude_ineligible: boolean;
        template_name?: string;
        message_id?: number | null;
      }
    >({
      query: ({
        studyId,
        sender,
        customer_ids,
        invite,
        template_name,
        send_now,
        exclude_ineligible,
        resend,
        message_id,
        query
      }) => ({
        url: `/studies/${studyId}/participations/bulk/invite`,
        method: 'POST',
        body: {
          sender,
          customer_ids,
          invite,
          send_now,
          exclude_ineligible,
          resend,
          template_name,
          message_id,
          query
        }
      })
    }),
    studiesBulkParticipationInvite: builder.mutation<
      BackgroundTask,
      {
        studyId: number;
        sender: EmailSender;
        participation_ids?: number[];
        query?: ServerFilterQuery;
        resend?: boolean;
        invite: BatchParams;
        send_now?: boolean;
        exclude_ineligible: boolean;
        template_name?: string;
        message_id?: number | null;
      }
    >({
      query: ({
        studyId,
        sender,
        participation_ids,
        invite,
        template_name,
        send_now,
        exclude_ineligible,
        message_id,
        query
      }) => ({
        url: `/studies/${studyId}/participations_bulk_invite`,
        method: 'POST',
        body: {
          sender,
          participation_ids,
          invite,
          send_now,
          exclude_ineligible,
          template_name,
          message_id,
          query
        }
      })
    }),
    studiesBulkParticipationComplete: builder.mutation<
      { data: [] },
      { studyId: number; ids?: number[]; query?: ServerFilterQuery; fields?: string[] }
    >({
      query: ({ studyId, ids, query, fields }) => ({
        url: `/studies/${studyId}/participations/bulk/complete`,
        method: 'POST',
        body: { ids, fields, query }
      })
    }),
    bulkParticipationModerator: builder.mutation<
      BackgroundTask,
      {
        studyId: number;
        participationIds?: number[];
        query?: ServerFilterQuery;
        userId: number;
        sessionUuid?: string;
      }
    >({
      query: ({ studyId, participationIds, userId, sessionUuid }) => ({
        url: `/studies/${studyId}/participations/bulk/moderator`,
        method: 'PUT',
        body: { participation_ids: participationIds, user_id: userId }
      }),
      invalidatesTags: (q, i, { sessionUuid }) =>
        sessionUuid ? [{ type: 'RepoSessionModerator', id: sessionUuid }] : []
    }),
    bulkCandidateDelete: builder.mutation<
      BackgroundTask,
      { ids?: number[]; query?: ServerFilterQuery; deletion_type: Candidate['deletion_type'] }
    >({
      query: ({ ids, query, deletion_type }) => ({
        url: `/candidates/bulk/delete`,
        method: 'POST',
        body: { ids, query, deletion_type }
      })
    }),
    bulkCandidateEdit: builder.mutation<
      BackgroundTask,
      { ids?: number[]; query?: ServerFilterQuery; customer: Partial<Candidate> }
    >({
      query: ({ ids, query, customer }) => ({
        url: `/candidates/bulk`,
        method: 'PUT',
        body: { ids, query, customer }
      }),
      transformResponse: (resp: any) => resp.data
    }),
    bulkShortlist: builder.mutation<
      { background_task: BackgroundTask; count: number },
      { studyId: number; customer_ids?: number[]; query?: ServerFilterQuery }
    >({
      query: ({ studyId, customer_ids, query }) => ({
        url: `/studies/${studyId}/bulk_shortlist`,
        method: 'POST',
        body: { customer_ids, query }
      }),
      transformResponse: (resp: any) => resp.data,
      transformErrorResponse: (resp: any) => resp.data
    }),
    candidateBulkScreenerInvite: builder.mutation<
      BackgroundTask,
      {
        batch: BatchParams;
        screenerId: number;
        customer_ids?: number[];
        exclude_ineligible: boolean;
        resend: boolean;
        query?: ServerFilterQuery;
        message_id?: number | null;
        sender: EmailSender;
      }
    >({
      query: ({ screenerId, ...request }) => ({
        url: `/screeners/${screenerId}/bulk/invites`,
        method: 'POST',
        body: { request }
      }),
      transformResponse: (resp: any) => resp.data
    }),
    participationBulkScreenerInvite: builder.mutation<
      BackgroundTask,
      {
        batch: BatchParams;
        studyId: number;
        participation_ids?: number[];
        exclude_ineligible: boolean;
        query?: ServerFilterQuery;
        message_id?: number | null;
        sender: EmailSender;
      }
    >({
      query: ({ studyId, ...request }) => ({
        url: `studies/${studyId}/screeners_bulk_invite`,
        method: 'POST',
        body: request
      }),
      transformResponse: (resp: any) => resp.data
    }),
    createCsvExport: builder.mutation<void, CsvExportParams>({
      query: ({ columns, ids, query, kind }) => ({
        url: `/csv_exports`,
        method: 'POST',
        body: { columns, ids, query, kind }
      })
    }),
    createParticipationCsvExport: builder.mutation<void, ParticipationCsvExportParams>({
      query: ({ study_id, columns, ids, query, kind }) => ({
        url: `studies/${study_id}/csv_exports`,
        method: 'POST',
        body: { columns, ids, query, kind }
      })
    }),
    createCandidateFileAsset: builder.mutation<Candidate, { candidate_id: number; signed_id: number; name: string }>({
      query: ({ candidate_id, name, signed_id }) => ({
        url: `/candidates/${candidate_id}/files`,
        method: 'POST',
        body: { name, signed_id }
      }),
      transformResponse: (resp: any) => buildCustomer(resp.data)
    }),
    createStudyScreenerCsvExport: builder.mutation<void, { study_id: number; screener_id: number }>({
      query: ({ study_id, screener_id }) => ({
        url: `/studies/${study_id}/screeners/${screener_id}/csv_exports`,
        method: 'POST'
      })
    }),
    createSampleData: builder.mutation({
      query: () => ({
        url: 'sample_data',
        method: 'POST'
      })
    }),
    getZoomRecordings: builder.query<ZoomRecording[], void>({
      query: () => '/zoom/recordings',
      transformResponse: (resp: any) => resp.data.meetings.map(buildZoomRecording)
    }),
    calendarPreview: builder.mutation<{ preview_url: string }, number>({
      query: (studyId) => ({
        url: `/studies/${studyId}/calendar_preview`,
        method: 'POST'
      })
    }),
    getStudyTemplates: builder.query<StudyTemplate[], void>({
      query: () => '/study_templates',
      transformResponse: (resp: any) => resp.data.map(buildStudyTemplate)
    }),
    updateDataRetention: builder.mutation<{ data_retention: boolean }, void>({
      query: (data_retention) => ({
        url: `/data_retention`,
        method: 'PUT',
        body: { data_retention }
      })
    }),
    createInsight: builder.mutation<Insight, Partial<Insight>>({
      query: (story) => ({
        url: '/stories',
        method: 'POST',
        body: { story }
      }),
      transformResponse: (resp: any) => buildInsight(resp.data),
      invalidatesTags: (q, i) => [{ type: 'Insight', slug: 'LIST' }]
    }),
    updateInsight: builder.mutation<Insight, Partial<Insight>>({
      query: (story) => ({
        url: `/stories/${story.slug}`,
        method: 'PUT',
        body: { story }
      }),
      transformResponse: (resp: any) => buildInsight(resp.data),
      invalidatesTags: (q, i, { slug }) => ['Tag', { type: 'Insight', slug }, { type: 'Insight', slug: 'LIST' }]
    }),
    deleteInsight: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/stories/${slug}`,
        method: 'DELETE'
      }),
      invalidatesTags: (q, i, slug) => [
        { type: 'Insight', slug },
        { type: 'Insight', slug: 'LIST' }
      ]
    }),
    updateInsightCoverImage: builder.mutation<Insight, { slug: string; signed_id: string }>({
      query: ({ signed_id, slug }) => ({
        url: `/stories/${slug}/cover_image`,
        method: 'PUT',
        body: { cover_image: { signed_id } }
      }),
      transformResponse: (resp: any) => buildInsight(resp.data),
      invalidatesTags: (q, i, { slug }) => [
        { type: 'Insight', slug },
        { type: 'Insight', slug: 'LIST' }
      ]
    }),
    deleteInsightCoverImage: builder.mutation<Insight, { slug: string }>({
      query: ({ slug }) => ({
        url: `/stories/${slug}/cover_image`,
        method: 'DELETE'
      }),
      transformResponse: (resp: any) => buildInsight(resp.data),
      invalidatesTags: (q, i, { slug }) => [
        { type: 'Insight', slug },
        { type: 'Insight', slug: 'LIST' }
      ]
    }),
    getInsight: builder.query<Insight, string>({
      query: (slug) => `/stories/${slug}`,
      transformResponse: (resp: any) => buildInsight(resp.data),
      providesTags: (q, i, slug) => [{ type: 'Insight', slug }]
    }),
    getInsights: builder.query<Insight[], void>({
      query: () => '/stories',
      transformResponse: (resp: any) => resp.data.map(buildInsight),
      providesTags: () => [{ type: 'Insight', slug: 'LIST' }]
    }),
    insertArtifactsIntoDocument: builder.mutation<void, { documentId: number; artifactIds: string[] }>({
      query: ({ documentId, artifactIds }) => ({
        url: `/documents/${documentId}/insert`,
        method: 'POST',
        body: { document: { artifact_ids: artifactIds } }
      })
    }),
    getArtifacts: builder.query<ArtifactHit[], string[]>({
      query: (objectIds) => `/artifacts?object_ids=${objectIds.join(',')}`,
      transformResponse: (resp: { data: ArtifactHit[] }) => resp.data,
      providesTags: ['Artifacts']
    }),
    getMuxDirectUploadUrl: builder.mutation<{ url: string; passthrough: string }, void>({
      query: () => ({ url: '/mux/direct_uploads', method: 'POST' })
    }),
    createMuxPlaybackUrls: builder.mutation<MuxPlaybackUrls, { playbackId: string }>({
      query: ({ playbackId }) => ({ url: `/mux/playback_urls?playback_id=${playbackId}`, method: 'POST' })
    }),
    createMuxDownloadUrl: builder.mutation<{ download_url: string }, { muxVideoId: number }>({
      query: ({ muxVideoId }) => ({ url: `/mux/download_url?mux_video_id=${muxVideoId}`, method: 'POST' })
    }),
    getCandidateAttrs: builder.query<Attr_[], void>({
      query: () => '/candidate_attrs',
      transformResponse: (resp: any) => resp.data.map(buildAttr_),
      providesTags: ['CandidateAttrs']
    }),
    createCandidateAttr: builder.mutation<Attr_, Partial<Attr_>>({
      query: (attr) => ({
        method: 'POST',
        url: '/candidate_attrs',
        body: { custom_attr: attr }
      }),
      transformResponse: (resp: any) => buildAttr_(resp.data),
      invalidatesTags: ['CandidateAttrs']
    }),
    updateCandidateAttr: builder.mutation<Attr_, Partial<Attr_>>({
      query: (attr) => ({
        method: 'PUT',
        url: `/candidate_attrs/${attr.id}`,
        body: { custom_attr: attr }
      }),
      transformResponse: (resp: any) => buildAttr_(resp.data),
      invalidatesTags: ['CandidateAttrs']
    }),
    deleteCandidateAttr: builder.mutation<Attr_, Partial<Attr_>>({
      query: (attr) => ({
        url: `/candidate_attrs/${attr.id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['CandidateAttrs']
    }),
    getStudyAttrs: builder.query<Attr_[], void>({
      query: () => '/study_attrs',
      transformResponse: (resp: any) =>
        resp.data.map((d) => ({ ...buildAttr_(d), lookup: `custom_attributes.${d.attributes.name}` })),
      providesTags: ['StudyAttrs']
    }),
    createStudyAttr: builder.mutation<Attr_, Partial<Attr_>>({
      query: (attr) => ({
        method: 'POST',
        url: '/study_attrs',
        body: { custom_attr: attr }
      }),
      transformResponse: (resp: any) => buildAttr_(resp.data),
      invalidatesTags: ['StudyAttrs']
    }),
    updateStudyAttr: builder.mutation<Attr_, Partial<Attr_>>({
      query: (attr) => ({
        method: 'PUT',
        url: `/study_attrs/${attr.id}`,
        body: { custom_attr: attr }
      }),
      transformResponse: (resp: any) => buildAttr_(resp.data),
      invalidatesTags: ['StudyAttrs']
    }),
    deleteStudyAttr: builder.mutation<Attr_, Partial<Attr_>>({
      query: ({ id }) => ({
        method: 'DELETE',
        url: `/study_attrs/${id}`
      }),
      invalidatesTags: ['StudyAttrs']
    }),
    createCandidateSubset: builder.mutation<{ candidate_ids: number[] }, { query: ServerFilterQuery; subset: Subset }>({
      query: ({ query, subset }) => ({
        url: `/candidates/subset_ids`,
        method: 'POST',
        body: { query, subset }
      })
    }),
    getConnectedAccounts: builder.query<ConnectedAccount[], void>({
      query: () => 'users/connected_accounts',
      transformResponse: (resp: any) => resp.data.map(buildConnectedAccount),
      providesTags: ['ConnectedAccounts']
    }),
    getUserBookingsIntegrationStatus: builder.query<boolean, { studyId: number; userId: number }>({
      query: ({ userId, studyId }) => `users/${userId}/bookings/integrations_status?study_id=${studyId}`,
      transformResponse: (resp: any) => resp.data.valid
    }),
    getUserCalendars: builder.query<Calendar[], number>({
      query: (userId) => `/users/${userId}/calendars`,
      transformResponse: (resp: any) => resp.data.map(({ attributes }) => attributes)
    }),
    getSavedViews: builder.query<SavedView[], void>({
      query: () => 'views',
      transformResponse: (resp: any) => resp.data.map(buildRecord),
      providesTags: () => [{ type: 'SavedView', id: 'LIST' }]
    }),
    updateSavedDefaultView: builder.mutation<void, { data: CollectionView } & Pick<SavedView, 'key' | 'scope'>>({
      query: ({ scope, key, data }) => ({
        url: `/views/defaults/${scope}`,
        method: 'PUT',
        body: { view: { key, data } }
      }),
      invalidatesTags: (q) => [{ type: 'SavedView', id: 'LIST' }]
    }),
    getCandidateAttrsSuggestions: builder.query<string[], { id?: number; q: string }>({
      query: ({ id, q }) => `/candidate_attrs/${id}/suggestions?q=${q}`,
      transformResponse: (resp: any) => resp.data,
      providesTags: () => [{ type: 'CandidateAttrSuggestion', id: 'LIST' }]
    }),
    createExternalCandidatesRequest: builder.mutation<ExternalCandidatesRequest, ExternalCandidatesRequest>({
      query: (externalCandidatesRequest) => ({
        url: `/studies/${externalCandidatesRequest.project_id}/external_candidates_requests`,
        method: 'POST',
        body: externalCandidatesRequest
      }),
      transformResponse: (resp: any) => buildExternalCandidatesRequest(resp.data),
      invalidatesTags: (q, i, { project_id }) => [
        { type: 'ExternalCandidatesRequests', id: project_id },
        { type: 'Study', id: project_id },
        { type: 'StudySlotsBalance', id: project_id }
      ]
    }),
    createExternalCandidatesRequestDuplicate: builder.mutation<
      ExternalCandidatesRequest,
      { studyId: number; externalCandidatesRequestId: number }
    >({
      query: ({ studyId, externalCandidatesRequestId }) => ({
        url: `/studies/${studyId}/external_candidates_requests/${externalCandidatesRequestId}/duplicate`,
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildExternalCandidatesRequest(resp.data),
      invalidatesTags: (q, i, { studyId }) => [{ type: 'ExternalCandidatesRequests', id: studyId }]
    }),
    updateExternalCandidatesRequest: builder.mutation<ExternalCandidatesRequest, ExternalCandidatesRequest>({
      query: (externalCandidatesRequest) => ({
        url: `/studies/${externalCandidatesRequest.project_id}/external_candidates_requests/${externalCandidatesRequest.id}`,
        method: 'PUT',
        body: externalCandidatesRequest
      }),
      transformResponse: (resp: any) => buildExternalCandidatesRequest(resp.data),
      invalidatesTags: (q, i, { id, project_id }) => [
        { type: 'ExternalCandidatesRequest', id: id },
        { type: 'ExternalCandidatesRequests', id: project_id },
        { type: 'Study', id: project_id },
        { type: 'StudySlotsBalance', id: project_id }
      ]
    }),
    publishExternalCandidatesRequest: builder.mutation<
      ExternalCandidatesRequest,
      { studyId: number; externalCandidatesRequestId: number }
    >({
      query: ({ studyId, externalCandidatesRequestId }) => ({
        url: `/studies/${studyId}/external_candidates_requests/${externalCandidatesRequestId}/publish`,
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildExternalCandidatesRequest(resp.data),
      invalidatesTags: (q, i, { studyId }) => [
        { type: 'ExternalCandidatesRequests', id: studyId },
        { type: 'Study', id: studyId },
        { type: 'StudySlotsBalance', id: studyId }
      ]
    }),
    closeExternalCandidatesRequest: builder.mutation<
      ExternalCandidatesRequest,
      { studyId: number; externalCandidatesRequestId: number }
    >({
      query: ({ studyId, externalCandidatesRequestId }) => ({
        url: `/studies/${studyId}/external_candidates_requests/${externalCandidatesRequestId}/close`,
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildExternalCandidatesRequest(resp.data),
      invalidatesTags: (q, i, { studyId }) => [
        { type: 'ExternalCandidatesRequests', id: studyId },
        { type: 'Study', id: studyId },
        { type: 'StudySlotsBalance', id: studyId }
      ]
    }),
    getExternalCandidatesRequests: builder.query<ExternalCandidatesRequest[], number>({
      query: (studyId) => `/studies/${studyId}/external_candidates_requests`,
      transformResponse: (resp: any) => resp.data.map(buildExternalCandidatesRequest),
      providesTags: (q, i, id) => [{ type: 'ExternalCandidatesRequests', id }]
    }),
    getExternalCandidatesRequest: builder.query<
      ExternalCandidatesRequest,
      { studyId: number; externalCandidatesRequestId: number }
    >({
      query: ({ studyId, externalCandidatesRequestId }) =>
        `/studies/${studyId}/external_candidates_requests/${externalCandidatesRequestId}`,
      transformResponse: (resp: any) => buildExternalCandidatesRequest(resp.data),
      providesTags: (q, i, { externalCandidatesRequestId }) =>
        q ? [{ type: 'ExternalCandidatesRequest', id: externalCandidatesRequestId }] : []
    }),
    createExternalCandidatesRequestSubmissionsRevertToDraft: builder.mutation<
      ExternalCandidatesRequest,
      { studyId: number; externalCandidatesRequestId: number }
    >({
      query: ({ studyId, externalCandidatesRequestId }) => ({
        url: `/studies/${studyId}/external_candidates_requests/${externalCandidatesRequestId}/revert_to_draft`,
        method: 'POST'
      }),
      invalidatesTags: (q, i, { studyId }) => [
        { type: 'ExternalCandidatesRequests', id: studyId },
        { type: 'Study', id: studyId },
        { type: 'StudySlotsBalance', id: studyId }
      ]
    }),
    createExternalCandidatesRequestPause: builder.mutation<
      ExternalCandidatesRequest,
      { studyId: number; externalCandidatesRequestId: number }
    >({
      query: ({ studyId, externalCandidatesRequestId }) => ({
        url: `/studies/${studyId}/external_candidates_requests/${externalCandidatesRequestId}/pause`,
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildExternalCandidatesRequest(resp.data),
      invalidatesTags: (q, i, { studyId }) => [{ type: 'ExternalCandidatesRequests', id: studyId }]
    }),
    createExternalCandidatesRequestUnpause: builder.mutation<
      ExternalCandidatesRequest,
      { studyId: number; externalCandidatesRequestId: number }
    >({
      query: ({ studyId, externalCandidatesRequestId }) => ({
        url: `/studies/${studyId}/external_candidates_requests/${externalCandidatesRequestId}/unpause`,
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildExternalCandidatesRequest(resp.data),
      invalidatesTags: (q, i, { studyId }) => [{ type: 'ExternalCandidatesRequests', id: studyId }]
    }),
    getRepoArtifacts: builder.query<AlgoliaProxyApiResult, AlgoliaProxyApiArgs>({
      query: ({ sortBy, filters, page, hitsPerPage, query }) =>
        `/repo/artifacts?${qs.stringify(
          { query, filters, page, sort_by: sortBy, items_per_page: hitsPerPage },
          { arrayFormat: 'brackets' }
        )}`
    }),
    getSlackConfig: builder.query<SlackConfig, void>({
      query: () => '/slack_config'
    }),
    updateSlackConfig: builder.mutation<void, Partial<SlackConfig>>({
      query: ({ digest_frequency }) => ({
        url: '/slack_config',
        method: 'PUT',
        body: {
          account: { slack_digest_frequency: digest_frequency }
        }
      })
    }),
    getAiScopes: builder.query<AiChatContext[], void>({
      query: () => '/ai/scopes'
    }),
    getAccountDisabledFeatures: builder.query<{ disabled_features: string[] }, void>({
      query: () => '/account/disabled_features',
      providesTags: ['DisabledFeatures']
    }),
    updateAccountDisabledFeatures: builder.mutation<{ disabled_features: string[] }, { disabled_features: string[] }>({
      query: ({ disabled_features }) => ({
        url: '/account/disabled_features',
        method: 'PUT',
        body: { account: { disabled_features } }
      }),
      invalidatesTags: ['DisabledFeatures']
    }),
    getMessageRequests: builder.query<MessageRequest[], number>({
      query: (id) => `/studies/${id}/message_requests`,
      transformResponse: (resp: any) => resp.data
    }),
    cancelMessageRequest: builder.mutation<void, { studyId: number; id: number }>({
      query: ({ studyId, id }) => ({
        url: `/studies/${studyId}/message_requests/${id}/cancel`,
        method: 'POST'
      }),
      invalidatesTags: (q, i, { id }) => []
    }),
    getStudyCandidateMessages: builder.query<
      Paged<CandidateMessage>,
      PagyRequest<{
        id: number;
      }>
    >({
      query: ({ id, sortDesc, ...params }) =>
        `/studies/${id}/candidate_messages?${qs.stringify(
          {
            ...params,
            sort_desc: sortDesc
          },
          { encode: true, arrayFormat: 'brackets', encodeValuesOnly: true }
        )}`,
      transformResponse: (resp: any) => ({
        data: resp.data.map(buildCandidateMessage),
        meta: resp.meta
      })
    }),
    getStudyCandidateMessageCounts: builder.query<
      Record<CandidateMessage['state'], number>,
      {
        id: number;
        q?: string;
        filters?: string[];
      }
    >({
      query: ({ id, filters, q }) => ({
        url: `/studies/${id}/candidate_message_counts?${qs.stringify(
          {
            filters,
            q
          },
          { arrayFormat: 'brackets' }
        )}`
      })
    }),
    getCustomerImport: builder.query<CustomerImport, number>({
      query: (id) => `/customer_imports/${id}`,
      transformResponse: (resp: any) => buildCustomerImport(resp.data),
      providesTags: (q, i, id) => [{ type: 'CustomerImport', id }]
    }),
    updateCustomerImport: builder.mutation<CustomerImport, Partial<CustomerImport>>({
      query: (customerImport) => ({
        url: `/customer_imports/${customerImport.id}`,
        method: 'PUT',
        body: { customer_import: customerImport }
      }),
      invalidatesTags: (q, i, { id }) => [{ type: 'CustomerImport', id }]
    }),
    runCustomerImport: builder.mutation<{ message: string }, { id: number }>({
      query: ({ id }) => ({
        url: `/customer_imports/${id}/run`,
        method: 'POST'
      })
    }),
    updateStudyLandingPage: builder.mutation<LandingPage, Partial<LandingPage>>({
      query: (attributes) => ({
        url: `/studies/${attributes.study_id}/landing_page`,
        method: 'PUT',
        body: { landing_page: attributes }
      }),
      transformResponse: (resp: any) => buildLandingPage(resp.data)
    }),
    createCustomerImport: builder.mutation<
      CustomerImport,
      {
        studyId?: number | null;
        teamId?: number | null;
        file: File;
      }
    >({
      query: ({ studyId, teamId, file }) => {
        const body = new FormData();
        body.append('customer_import[csv]', file);
        if (studyId) body.append('customer_import[project_id]', studyId.toString());
        if (teamId) body.append('customer_import[team_id]', teamId.toString());

        return { url: '/customer_imports', method: 'POST', body };
      },
      transformErrorResponse: (resp: any) => resp.data,
      transformResponse: (resp: any) => buildCustomerImport(resp.data)
    }),
    getParticipationSessionInfo: builder.query<{ uuid: string; recording_id: number }, number | string>({
      query: (id) => `/participations/${id}/session_info`,
      transformResponse: (resp: any) => resp.data
    }),
    getRecordingSessionUuid: builder.query<string, number | string>({
      query: (id) => `/recordings/${id}/session_uuid`,
      transformResponse: (resp: any) => resp.data
    }),
    getConsentForms: builder.query<ConsentForm[], void>({
      query: () => '/consent_forms',
      transformResponse: (resp: any) => resp.data.map(buildConsentForm),
      providesTags: ['ConsentForm']
    }),
    createConsentForm: builder.mutation<ConsentForm, FormData>({
      query: (body) => ({
        url: '/consent_forms',
        method: 'POST',
        body
      }),
      transformResponse: (resp: any) => buildConsentForm(resp.data),
      invalidatesTags: ['ConsentForm']
    }),
    updateConsentForm: builder.mutation<ConsentForm, { id: number; consent_form: Partial<ConsentForm> }>({
      query: ({ id, consent_form }) => ({
        url: `/consent_forms/${id}`,
        method: 'PUT',
        body: { consent_form }
      }),
      transformResponse: (resp: any) => buildConsentForm(resp.data),
      invalidatesTags: ['ConsentForm']
    }),
    deleteConsentForm: builder.mutation<void, number>({
      query: (id) => ({
        url: `/consent_forms/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ConsentForm']
    }),
    switchAccount: builder.mutation<GQAccount, number>({
      query: (id) => ({
        url: `/accounts/${id}/switch`,
        method: 'POST',
        body: { id }
      })
    }),
    destroyUserSession: builder.mutation<void, void>({
      query: () => ({
        url: '/users/session',
        method: 'DELETE'
      })
    }),
    getInvitations: builder.query<AccountInvitation[], void>({
      query: () => '/account_invitations',
      transformResponse: (resp: any) => resp.data,
      providesTags: [{ type: 'AccountInvitation', id: 'LIST' }]
    }),
    getInvitation: builder.query<AccountInvitation, string>({
      query: (token) => `/account_invitations/${token}`,
      transformResponse: (resp: any) => resp.data,
      providesTags: (q, i, token) => [{ type: 'AccountInvitation', id: token }]
    }),
    createInvitation: builder.mutation<AccountInvitation, Partial<AccountInvitation>>({
      query: (invitation) => ({
        url: '/account_invitations',
        method: 'POST',
        body: { invitation }
      }),
      transformResponse: (resp: any) => resp.data,
      invalidatesTags: [{ type: 'AccountInvitation', id: 'LIST' }]
    }),
    updateInvitation: builder.mutation<AccountInvitation, { token: string; invitation: Partial<AccountInvitation> }>({
      query: ({ invitation, token }) => ({
        url: `/account_invitations/${token}`,
        method: 'PUT',
        body: { invitation }
      }),
      invalidatesTags: (q, i, { token }) => [
        { type: 'AccountInvitation', id: 'LIST' },
        {
          type: 'AccountInvitation',
          id: token
        }
      ],
      transformResponse: (resp: any) => resp.data
    }),
    deleteInvitation: builder.mutation<void, string>({
      query: (token) => ({
        url: `/account_invitations/${token}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'AccountInvitation', id: 'LIST' }]
    }),
    createParticipation: builder.mutation<
      Participation,
      {
        project_id: number;
        customer_id: number;
        transition: ParticipationAction;
        source: string;
        email?: string;
        repo_only?: boolean;
        force?: boolean;
      }
    >({
      query: (participation) => ({
        url: '/participations',
        method: 'POST',
        body: participation
      }),
      transformResponse: (resp: any) => buildParticipation(resp.data)
    }),
    deleteCustomerImport: builder.mutation<void, number>({
      query: (id) => ({
        url: `/customer_imports/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['CustomerImport']
    }),
    getIncentives: builder.query<Incentive[], void>({
      query: () => '/incentives',
      transformResponse: (resp: any) => resp.data.map(buildIncentive)
    }),
    createParticipationSubset: builder.mutation<
      { participation_ids: number[] },
      { query: ServerFilterQuery; subset: Subset; studyId: number }
    >({
      query: ({ query, subset, studyId }) => ({
        url: `/studies/${studyId}/participations/subset_ids`,
        method: 'POST',
        body: { query, subset }
      })
    })
  })
});

export const store = configureStore({
  reducer: { api: api.reducer },
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ serializableCheck: false }), api.middleware]
});

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
