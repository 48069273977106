import * as React from 'react';
import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import tinytime from 'tinytime';
import Tippy from '@tippyjs/react';

import { api } from '@api/reduxApi';
import { SlideOut, Text } from '@components/common';
import {
  EVENT_TYPE_LABELS,
  timeRangeStr
} from '@components/RepoSessionApp/ProfileSlideout/components/CalendarEvents/CalendarEventLink';
import { CalendarEventStatusPill } from '@components/RepoSessionApp/ProfileSlideout/components/CalendarEvents/CalendarEventStatusPill';
import { useProfileSlideoutNavigate } from '@components/RepoSessionApp/ProfileSlideout/routerContext';
import { ExternalLinkSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { compact } from '@components/utils';

import { Basic } from '../Skeleton';
import { TimezoneIndicator } from '../TimezoneIndicator';

import { Attendees } from './Attendees';

const dateFormat = tinytime('{dddd}, {MMMM} {DD}');
const timeFormat = tinytime('{h}:{mm}{a}');

const TimeRow: React.FC<React.PropsWithChildren<{ time: Date }>> = ({ time }) => (
  <div className='flex flex-col space-y-1'>
    <Text h='400'>
      {dateFormat.render(time)}, {timeFormat.render(time)}
    </Text>
    <TimezoneIndicator className='text-gray-500' />
  </div>
);

export const buildDataRows = (calendarEvent: CalendarEvent): [string, React.ReactNode][] =>
  compact([
    ['Date', dateFormat.render(calendarEvent.start_at)],
    [
      'Time',
      <div className='flex flex-col space-y-1'>
        <Text h='400'>{timeRangeStr(calendarEvent)}</Text>
        <TimezoneIndicator className='text-gray-500' />
      </div>
    ],
    ['Duration', Math.floor(+calendarEvent.end_at - +calendarEvent.start_at) / 1000 / 60 + ' minutes'],
    !!calendarEvent.conference_provider && ['Call location', calendarEvent.conference_provider],
    [
      'Join URL',
      (() => {
        const url = calendarEvent.conference_url || calendarEvent.location;
        if (url) {
          return (
            <a href={url} title={url} target='_blank' className='h400 flex items-center space-x-2 truncate'>
              <ExternalLinkSVG className='h-4 w-4 flex-shrink-0' />
              <span>{url}</span>
            </a>
          );
        }
        return (
          <Text h='400' color='gray-500' truncate>
            None
          </Text>
        );
      })()
    ],
    ['Attendees', <Attendees calendarEvent={calendarEvent} />],
    ['Description', <div className='h400 whitespace-pre-wrap'>{calendarEvent.description}</div>],
    ['Created', <TimeRow time={calendarEvent.created_at} />],
    ['Last updated at', <TimeRow time={calendarEvent.updated_at} />],
    !!calendarEvent.last_synced_at && ['Last synced at', <TimeRow time={calendarEvent.last_synced_at} />],
    !!calendarEvent.discarded_at && [
      calendarEvent.replaced_at ? 'Replaced at' : 'Canceled at',
      <TimeRow time={calendarEvent.discarded_at} />
    ]
  ]);

type Props = {
  id: number;
  onClose: () => void;
};

export const CalendarEventSlideOut: React.FC<React.PropsWithChildren<Props>> = ({ id, onClose }) => {
  const navigate = useProfileSlideoutNavigate();
  const onBack = () => navigate('profile');
  const { data: calendarEvent } = api.useGetCalendarEventQuery({ id });

  useEffect(() => {
    if (calendarEvent?.event_type) {
      track('viewed_calendar_event_slideout', { event_type: calendarEvent.event_type });
    }
  }, [calendarEvent?.event_type]);

  return (
    <SlideOut size='2xl' onClose={onClose} onBack={onBack}>
      <div className='px-4'>
        {calendarEvent ? (
          <Text h='700' truncate>
            {calendarEvent.title}
          </Text>
        ) : (
          <Basic mb='4' />
        )}
        {calendarEvent?.discarded_at && (
          <div className='mt-2'>
            <Tippy content='The interview was updated. A new event was generated.'>
              <span>
                <CalendarEventStatusPill calendarEvent={calendarEvent} />
              </span>
            </Tippy>
          </div>
        )}
        {calendarEvent ? (
          <Text bold mb='4'>
            {EVENT_TYPE_LABELS[calendarEvent.event_type]} event details
          </Text>
        ) : (
          <Basic h='4' />
        )}
        {calendarEvent && (
          <table className='w-full table-fixed'>
            <tbody>
              {buildDataRows(calendarEvent).map(([label, value]) => (
                <tr key={label}>
                  <td className='w-1/4 py-2 pr-6 align-top'>
                    <Text h='400' medium className='whitespace-nowrap'>
                      {label}
                    </Text>
                  </td>
                  <td className='py-2'>{typeof value === 'string' ? <Text h='400'>{value}</Text> : value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </SlideOut>
  );
};
