import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { CtaView } from '@components/shared/Tiptap/components/CtaView';

export const Cta = Node.create({
  name: 'cta',

  content: 'text*',

  group: 'block',

  selectable: true,

  draggable: true,

  editable: false,

  atom: true,

  addNodeView() {
    return ReactNodeViewRenderer(CtaView);
  },

  parseHTML() {
    return [
      {
        tag: 'cta-component'
      }
    ];
  },

  renderHTML(props) {
    return ['cta-component', mergeAttributes(props.HTMLAttributes)];
  }
});
