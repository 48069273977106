import * as React from 'react';

import { BrowserRouter as Router, Navigate, Route, Routes, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { api } from '@api/reduxApi';
import { AiChatsApp } from '@components/AiChatsApp';
import { AppLoader } from '@components/AppLoader';
import { CandidatesApp } from '@components/CandidatesApp/CandidatesApp';
import { CustomerImportApp } from '@components/CustomerImports';
import { DashboardApp } from '@components/DashboardApp/DashboardApp';
import { GettingStarted } from '@components/GettingStarted/GettingStarted';
import { GuidesApp } from '@components/GuidesApp/GuidesApp';
import { IncentivesApp } from '@components/IncentivesApp/IncentivesApp';
import { Container } from '@components/layouts/Container';
import { RepoClipApp } from '@components/RepoSessionApp/RepoClipApp';
import { RepoSessionApp } from '@components/RepoSessionApp/RepoSessionApp';
import { RepositoryApp } from '@components/RepositoryApp/RepositoryApp';
import { StudiesApp } from '@components/StudiesApp/StudiesApp';
import { TemplatesApp } from '@components/TemplatesApp/TemplatesApp';
import { renderComp } from '@components/utils';
import { useKeyboardShortcut } from '@hooks/useKeyboardShortcut';

import { AccountSwitcher } from './AccountSwitcher/AccountSwitcher';
import { TeamCandidatesIndex } from './CandidatesApp/TeamCandidatesIndex';
import { HighlightReelsApp } from './HighlightReelsApp/HighlightReelsApp';
import { InsightsShow } from './InsightsApp/InsightsShow';
import { NewStudyAction } from './StudiesApp/components/StudyNew';
import { TeamStudiesIndex } from './StudiesApp/components/TeamStudiesIndex';
import { Loading } from './common';
import { NavShortcuts } from './NavShortcuts';
import { ReportingApp } from './ReportingApp';
import { TrackRouteChange } from './TrackRouteChange';

const InterviewRedirector = () => {
  const { token } = useParams<{ token: string }>();
  const { data, isLoading } = api.useGetParticipationSessionInfoQuery(token ?? skipToken);

  if (isLoading) {
    return <Loading absolute />;
  }

  if (!data || !data.uuid) {
    return null;
  }

  return <Navigate replace to={`/sessions/${data.uuid}`} />;
};

const RecordingRedirector = () => {
  const { token } = useParams<{ token: string }>();
  const { data: uuid, isLoading } = api.useGetRecordingSessionUuidQuery(token ?? skipToken);

  if (isLoading) {
    return <Loading absolute />;
  }

  if (!uuid) {
    return null;
  }

  return <Navigate replace to={`/sessions/${uuid}`} />;
};

const HighlightRedirector = () => {
  const { token } = useParams<{ token: string }>();
  return <Navigate replace to={`/h/${token}`} />;
};

export const NewStudyShortcut: React.FC<React.PropsWithChildren<{ openSlideout: () => void }>> = ({ openSlideout }) => {
  useKeyboardShortcut({
    keys: { N: true, S: true },
    handler: openSlideout,
    disableWhenInputFocused: true
  });

  return null;
};

export const AppRouter = () => {
  return (
    <Router>
      <TrackRouteChange />
      <NavShortcuts />
      <AppLoader hideLoader>
        <NewStudyAction>{({ openSlideout }) => <NewStudyShortcut openSlideout={openSlideout} />}</NewStudyAction>
        <Container sidebar aiChat>
          <Routes>
            <Route path='candidates/*' element={<CandidatesApp />} />
            <Route path='teams/:teamId/candidates' element={<TeamCandidatesIndex />} />
            <Route path='teams/:teamId/studies' element={<TeamStudiesIndex />} />
            <Route path='studies/*' element={<StudiesApp />} />
            <Route path='incentives/*' element={<IncentivesApp />} />
            <Route path='study_templates/*' element={<TemplatesApp />} />
            <Route path='repository/*' element={<RepositoryApp />} />
            <Route path='sessions/*' element={<RepoSessionApp />} />
            <Route path='insights/:slug' element={<InsightsShow />} />
            <Route path='insights' element={<Navigate to='/repository/insights' />} />
            <Route path='h/*' element={<RepoClipApp />} />
            <Route path='clips/:token' element={<HighlightRedirector />} />
            <Route path='getting_started' element={<GettingStarted />} />
            <Route path='guides/*' element={<GuidesApp />} />
            <Route path='customer_imports/*' element={<CustomerImportApp />} />
            <Route path='interviews/:token' element={<InterviewRedirector />} />
            <Route path='recordings/:token' element={<RecordingRedirector />} />
            <Route path='accounts/choose' element={<AccountSwitcher />} />
            <Route path='highlight_reels/*' element={<HighlightReelsApp />} />
            <Route path='ai/chats/*' element={<AiChatsApp />} />
            <Route path='reporting' element={<ReportingApp />} />
            <Route path='' element={<DashboardApp />} />
          </Routes>
        </Container>
      </AppLoader>
    </Router>
  );
};

export function mount(): void {
  renderComp('app-spa', () => <AppRouter />);
}
