import * as React from 'react';
import { useMemo } from 'react';

import { UserAvatars, UserAvatarsProps } from './UserAvatars';

type Props = UserAvatarsProps & {
  maxPerRow: number;
};

export const WrappedUserAvatars: React.FC<React.PropsWithChildren<Props>> = ({ user_ids, maxPerRow, ...rest }) => {
  const ids = useMemo<number[][]>(() => {
    const rows: number[][] = [];

    for (let i = 0; i < user_ids.length; i += maxPerRow) {
      rows.push(user_ids.slice(i, i + maxPerRow));
    }

    return rows;
  }, [user_ids, maxPerRow]);

  return (
    <div>
      {ids.map((row, i) => (
        <UserAvatars key={i} user_ids={row} {...rest} />
      ))}
    </div>
  );
};
