import { track } from 'components/tracking';

import { useAccount } from './useAccount';
import { useFeature } from './useFeature';
import { useLocalStorage } from './useLocalStorage';
import { useUser } from './useUser';

type PaginatedStudiesConfig = {
  paginatedStudiesView: boolean;
  paginatedStudiesFeature: boolean;
  togglePaginatedStudiesView: () => void;
};
export const usePaginatedStudiesToggle = (): PaginatedStudiesConfig => {
  const hasPaginatedStudiesFeature = useFeature('paginated_studies');
  const user = useUser();
  const { account } = useAccount();
  const [storedPaginatedStudiesView, setStoredPaginatedStudiesView] = useLocalStorage<boolean>(
    'paginated-studies-view',
    true
  );
  const paginatedView = !!storedPaginatedStudiesView && hasPaginatedStudiesFeature;

  const togglePaginatedStudiesView = () => {
    if (storedPaginatedStudiesView) {
      track('disabled_paginated_studies_view', { user_id: user.id, account_id: account.id });
    }
    setStoredPaginatedStudiesView(!storedPaginatedStudiesView);
  };

  return {
    paginatedStudiesView: paginatedView,
    paginatedStudiesFeature: hasPaginatedStudiesFeature,
    togglePaginatedStudiesView
  };
};
