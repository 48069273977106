import { useGetGuideDocumentQuery } from './api';
import { Props as TiptapProps, TiptapHook, useTiptap } from './useTiptap';

type Props = TiptapProps & {
  guideToken: string;
};

export const useTiptapFromGuideToken = ({ guideToken, ...props }: Props): TiptapHook => {
  const { data } = useGetGuideDocumentQuery(guideToken || '', {
    skip: !guideToken,
    refetchOnMountOrArgChange: true
  });

  return useTiptap({
    ...props,
    data
  });
};
