import React, { forwardRef, HTMLAttributes } from 'react';

import cn from 'classnames';

import { DropdownMenuProps } from '../types';

type Props = HTMLAttributes<HTMLUListElement> & DropdownMenuProps;

export const Menu = forwardRef<HTMLUListElement, Props>(
  ({ __dropdownMenuProps: { isOpen, loading, inputValue, items }, className, children, ...rest }, ref) => {
    return (
      <ul
        ref={ref}
        {...rest}
        data-testid='combobox-menu'
        className={cn(
          'xx-combobox-menu z-50 mt-1 max-h-60 w-full overflow-hidden overflow-y-auto rounded border border-gray-200 bg-white shadow-lg',
          {
            invisible: !isOpen
          },
          className
        )}
      >
        {children}
      </ul>
    );
  }
);
