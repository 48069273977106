import React from 'react';

import { Alert } from '@components/common';

import { MAX_BATCH_SIZE } from '../../consts';

export const BatchTooLargeAlert: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Alert type='warning'>
    Batch sizes are limited to {MAX_BATCH_SIZE.toLocaleString()} due to email sending limits.
  </Alert>
);
