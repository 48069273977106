import * as React from 'react';

import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { TrackRouteChange } from '@components/TrackRouteChange';
import { usePlan } from 'hooks/usePlan';

// import { PlansShow } from './PlansShow';
// import { PlansThanks } from './PlansThanks';
import { PlansProvider } from './components/PlansProvider';
import { PlansIndex } from './PlansIndex';

export const PlansApp: React.FC<React.PropsWithChildren<unknown>> = () => {
  // Get current plan and show the right schedule for that
  const { interval, id } = usePlan();
  const defaultSched = interval === 'year' ? 'annual' : 'monthly';

  // FIXME: Handle downgrade to monthly.
  return (
    <PlansProvider>
      <Router>
        <TrackRouteChange />
        <Routes>
          {/* <Route path='/plans/:sched(annual|monthly)/:plan' component={PlansShow} /> */}
          {/* <Route path='/plans/:plan/thanks' component={PlansThanks} /> */}
          <Route path='/plans/annual' element={<PlansIndex />} />
          <Route
            path='/plans/monthly'
            element={id && interval === 'year' ? <Navigate to={`/plans/annual`} /> : <PlansIndex />}
          />
          <Route path='/plans' element={<Navigate to={`/plans/${defaultSched}`} />} />
        </Routes>
      </Router>
    </PlansProvider>
  );
};
