import * as React from 'react';

import cn from 'classnames';

import { Tooltip } from '@components/Tooltip';

interface Props<T extends string = string> {
  current: T;
  tabs: T[];
  onSelect?: (tab: T) => void;
  onClick?: (tab: string) => void;
  labels?: Record<T, string>;
  customTabPaddings?: string;
  className?: string;
  disabled?: T[];
  tooltips?: Record<T, string>;
}

export const Tabs = <T extends string = string>(props: Props<T>): React.ReactElement => {
  const { current, tooltips, disabled, tabs, onSelect, onClick, labels, customTabPaddings, className } = props;

  const handleTabClick = (tab) => {
    if (disabled?.includes(tab)) return;
    onClick?.(tab);

    if (current != tab) {
      onSelect?.(tab);
    }
  };

  const visibleTabs = React.useMemo(
    () => (current && !tabs.includes(current) ? tabs.concat([current]) : tabs),
    [tabs, current]
  );

  const createTabClass = (tab: T) =>
    cn('xx-tab text-center font-bold border-b-2 text-sm whitespace-nowrap', customTabPaddings, {
      'text-indigo-600 border-indigo-600': current === tab,
      'text-gray-500 border-transparent': current !== tab,
      'pt-4 pb-2': !customTabPaddings,
      'opacity-50': disabled?.includes(tab),
      'cursor-pointer hover:text-indigo-500': !disabled?.includes(tab)
    });

  return (
    <div
      role='tablist'
      className={cn(
        'desktop:space-x-5 relative flex items-center space-x-3 overflow-x-auto overflow-y-visible',
        className
      )}
    >
      {visibleTabs.map((tab) => (
        <Tooltip key={tab} isDisabled={!tooltips} content={tooltips?.[tab]}>
          <button
            role='tab'
            aria-label={labels?.[tab] || tab}
            aria-selected={current === tab}
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={createTabClass(tab)}
          >
            {labels?.[tab] || tab}
          </button>
        </Tooltip>
      ))}
    </div>
  );
};
