import * as React from 'react';
import { useState } from 'react';

import { useInputState } from '@components/CandidateAttrs/hooks/useInputState';
import { getWrapperClass, NO_OUTLINE } from '@components/CandidateAttrs/index';
import { Input, Text } from '@components/common';
import { EditConfirmationModal } from '@components/shared/GridTable/components';
import { ErrorSvg } from 'components/svgs';

export const TextInput: React.FC<React.PropsWithChildren<CandidateAttrInput<string>>> = ({
  value,
  label,
  styles,
  setValue,
  onSave,
  autoFocus,
  onFocus,
  onBlur,
  attr,
  customerName,
  errorMessage
}) => {
  const { handleBlur, handleFocus, focused, initValue } = useInputState(value, setValue, onBlur, onFocus);

  const [modalOpen, setModalOpen] = useState(false);

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      if (errorMessage) return;
      if ((attr.name === 'phone_number' || attr.name === 'email') && initValue) {
        setModalOpen(true);
        return;
      }
      onSave?.(value);
    }
  };

  const onClickOutside = () => {
    if (errorMessage) return;
    if (value === initValue) {
      handleBlur();
      return;
    }

    if ((attr.name === 'phone_number' || attr.name === 'email') && initValue) {
      setModalOpen(true);
      return;
    }

    onSave?.(value);
    handleBlur();
  };

  const onModalClose = () => {
    setModalOpen(false);
    setValue(initValue);
    handleBlur();
  };

  const onModalConfirm = () => {
    onSave?.(value);
    setModalOpen(false);
    handleBlur();
  };

  return (
    <tr data-testid='attr-field' className={getWrapperClass(styles, focused, !!errorMessage)}>
      <td className={styles?.label}>{label}</td>
      <td className={styles?.value}>
        <Input
          autoFocus={autoFocus}
          value={value}
          onFocus={handleFocus}
          onBlur={onClickOutside}
          style={NO_OUTLINE}
          onChange={setValue}
          onKeyDown={handleEnter}
          className='tablet:text-sm focus:outline-none active:outline-none w-full flex-1 border-0 px-4 py-2.5'
          placeholder='Enter…'
        />
        {errorMessage && (
          <Text color='red-600' h='400' className='col-span-3 flex items-center truncate'>
            <ErrorSvg className='mr-2' />
            {errorMessage}
          </Text>
        )}
        {modalOpen && (
          <EditConfirmationModal
            currentValue={value}
            previousValue={initValue}
            candidateName={customerName || 'Unnamed candidate'}
            attrName={attr.label.toLowerCase()}
            onClose={onModalClose}
            onConfirm={onModalConfirm}
          />
        )}
      </td>
    </tr>
  );
};
