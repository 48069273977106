import * as React from 'react';

export const ReplacedCalendarEventSVG: React.FC = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z'
      fill='#FDD3D0'
    />
    <path
      d='M11.051 20.949L20.949 11.051M16 9C19.866 9 23 12.134 23 16C23 19.866 19.866 23 16 23C12.134 23 9 19.866 9 16C9 12.134 12.134 9 16 9Z'
      stroke='#252F3F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
