import React, { FC, useMemo } from 'react';

import { Text } from '@components/common';
import { FullStarSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

interface Props {
  screenerResult?: { ideal: boolean; value: string | string[] | null };
}

export const ScreenerFieldCell: FC<Props> = ({ screenerResult }) => {
  const parsedValue = useMemo(() => {
    if (!screenerResult) return '-';

    const { value } = screenerResult;

    if (!value) return '-';

    if (Array.isArray(value)) {
      return value.join(', ');
    }

    return value;
  }, [screenerResult]);

  return (
    <Tooltip content={parsedValue} isDisabled={!screenerResult?.value} width={200}>
      <div className='flex h-full w-full items-center px-2'>
        <>{screenerResult?.ideal && <FullStarSVG aria-label='Is ideal' className='mr-2 flex-shrink-0' />}</>

        <Text h='400' truncate>
          {parsedValue}
        </Text>
      </div>
    </Tooltip>
  );
};
