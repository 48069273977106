import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button, Modal, ModalHeading, RadioGroup } from '@components/common';
import { track } from '@components/tracking';
import { useToaster } from '@stores/toaster';

import { useUpdateTeamMutation } from '../api';
import * as toasts from '../toasts';

type Access = 'open' | 'closed';

type Props = {
  team: Team;
  open: boolean;
  onClose: () => void;
};

export const EditAccessModal: React.FC<React.PropsWithChildren<Props>> = ({ team, open, onClose }) => {
  const [access, setAccess] = useState<Access>(team.allow_join ? 'open' : 'closed');

  const showToast = useToaster();

  const [updateTeam, { isLoading, isSuccess, isError }] = useUpdateTeamMutation();

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successUpdateTeam());
      onClose();
      track('updated_team_access', { access });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedUpdateTeam());
    }
  }, [isError]);

  const handleSubmit = () => {
    updateTeam({
      id: team.id,
      allow_join: access === 'open'
    });
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      size='md'
      renderFooter={() => (
        <div className='flex justify-end space-x-4'>
          <Button disabled={isLoading} onClick={onClose}>
            Cancel changes
          </Button>
          <Button loading={isLoading} disabled={isLoading} primary onClick={handleSubmit}>
            Save changes
          </Button>
        </div>
      )}
    >
      <ModalHeading className='mb-6'>Edit access</ModalHeading>

      <RadioGroup<Access>
        name='access'
        selected={access}
        onChange={setAccess}
        options={[
          {
            label: 'Open: Can be joined by anyone in the workspace.',
            value: 'open'
          },
          {
            label: 'Closed: Only admins can add members.',
            value: 'closed'
          }
        ]}
      />
    </Modal>
  );
};
