import * as React from 'react';
import { useState } from 'react';

import cn from 'classnames';
import maxSize from 'popper-max-size-modifier';
import { Modifier, usePopper } from 'react-popper';

import { Text } from '@components/common';
import { Tooltip } from '@components/Tooltip';
import { useDeviceType } from '@hooks/useDeviceType';
import { usePopUp } from '@hooks/usePopUp';

import { FilterState } from '../types';
import { getDefaultValueCopy } from '../utils/getDefaultValueCopy';
import { getOperatorCopy } from '../utils/getOperatorCopy';
import { getPeriodCopy } from '../utils/getPeriodCopy';
import { isFilterApplyable } from '../utils/isFilterApplyable';

import { DropdownMenu } from './DropdownMenu';
import * as svgs from './svgs';

const applyMaxSize = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['maxSize'],
  fn({ state }) {
    const { height } = state.modifiersData.maxSize;
    state.styles.popper.maxHeight = `${height - 20}px`;
  }
};

type Props = {
  className?: string;
  state: FilterState<any>;
  hasNoPopup?: boolean;
  onClickRemove?: () => void;
  onChange?: (state: FilterState<any>) => void;
  editable?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
};

export const Filter: React.FC<React.PropsWithChildren<Props>> = ({
  className: propClassName,
  state,
  hasNoPopup = false,
  onChange,
  onClickRemove,
  editable,
  open,
  setOpen
}) => {
  const { definition, operator } = state;
  const { isMobile } = useDeviceType();

  const { ref, togglePopUp, PopUp, closePopUp } = usePopUp({
    open,
    setOpen
  });

  const [triggerRef, setTriggerRef] = useState<HTMLElement | null>(null);
  const [menuRef, setMenuRef] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(triggerRef, menuRef, {
    ...{ placement: editable && !isMobile ? 'left-start' : 'bottom-start' },
    modifiers: [
      maxSize,
      applyMaxSize as Modifier<any>,
      {
        name: 'offset',
        options: {
          offset: editable ? [34, 8] : [0, 0]
        }
      }
    ]
  });

  const className = cn(
    propClassName,
    'inline-flex cursor-pointer items-center truncate',
    'py-1.5 px-2.5 rounded-md border border-indigo-600',
    'bg-indigo-50 hover:text-indigo-600'
  );

  const handleClickClose: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    onClickRemove?.();
  };

  const applyable = isFilterApplyable(state);

  const readOnly = !onClickRemove || !onChange;

  return (
    <div ref={ref}>
      <Tooltip content={definition.tooltip} isDisabled={!definition.tooltip}>
        <div
          ref={setTriggerRef}
          role='button'
          tabIndex={0}
          className={className}
          onClick={!(readOnly || hasNoPopup) ? togglePopUp : undefined}
        >
          <div className='max-w-72 flex items-center space-x-1'>
            <Text h='400' className='max-w-64 overflow-hidden truncate'>
              {definition.displayName || definition.name}
            </Text>
            {operator && applyable && <Text h='400'>{getOperatorCopy(definition.type, operator)}</Text>}
            {applyable && (
              <Text className='truncate' h='400'>
                {definition.renderValue?.(state.value) || getDefaultValueCopy(state)}
              </Text>
            )}
            {applyable && state.period && <Text h='400'>{getPeriodCopy(state.period)}</Text>}
          </div>
          {!readOnly && (
            <button className='ml-2.5 cursor-pointer' name='close' onClick={handleClickClose}>
              <svgs.close />
            </button>
          )}
        </div>
      </Tooltip>
      {!readOnly && (
        <PopUp
          open={open}
          className={cn({ invisible: !open, 'pt-2': !editable })}
          ref={setMenuRef}
          style={styles.popper}
          {...attributes.popper}
          zIndex={50}
        >
          <DropdownMenu
            values={definition.values}
            state={state}
            onClear={onClickRemove}
            onChange={onChange}
            onClose={closePopUp}
          />
        </PopUp>
      )}
    </div>
  );
};
