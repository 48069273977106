import { usePermission } from '@hooks/usePermission';

export type StatusAction =
  | 'close'
  | 'pause'
  | 'unpause'
  | 'duplicate'
  | 'archive'
  | 'delete'
  | 'approve'
  | 'reactivate';

const STATUS_ACTION_OPTIONS: Record<Study['state'], StatusAction[]> = {
  draft: ['duplicate', 'archive', 'delete'],
  pending: ['archive', 'delete', 'approve'],
  active: ['close', 'pause', 'duplicate'],
  paused: ['duplicate', 'unpause', 'close'],
  closed: ['reactivate', 'archive', 'duplicate', 'delete'],
  cancelled: ['archive', 'delete'], // Is this even used?
  archived: ['reactivate', 'delete']
};

const ALWAYS_DELETEABLE = ['draft', 'pending'];

export function useStudyActionOptions(study: SimpleStudy): StatusAction[] {
  const canUpdate = usePermission('updateStudy')();

  if (!canUpdate) {
    return [];
  }

  const options = STATUS_ACTION_OPTIONS[study.state];
  if (study.incentive_method === 'tremendous' && !ALWAYS_DELETEABLE.includes(study.state)) {
    return options.filter((o) => o !== 'delete');
  } else {
    return options;
  }
}
