import React, { FC, useMemo } from 'react';

import { EditorState } from 'prosemirror-state';

import { Text } from '@components/common';
import { TextNode } from '@components/RepoSessionApp/ClipPage/hooks/useEditorState';
import { formatDuration } from '@components/shared/Artifact/components/utils';

type Props = {
  className?: string;
  getTextNodes: (range: [number, number]) => TextNode[];
  range: [number, number];
  state: EditorState | undefined;
};

export const ClipTranscript: FC<React.PropsWithChildren<Props>> = ({ getTextNodes, className, range, state }) => {
  const textNodes = useMemo(() => getTextNodes(range), [range, state]);

  return (
    <div className={className}>
      {textNodes.map(({ speaker, text, timestamp }, i) => (
        <div key={i} className='mb-4'>
          <div className='mb-2 flex items-center'>
            <Text as='span' h='400' className='ml-2 font-medium text-gray-700'>
              {speaker.name}
            </Text>
            <Text as='span' h='400' color='gray-500' className='ml-4'>
              {formatDuration(Math.round(timestamp / 1000))}
            </Text>
          </div>
          <Text as='p' h='400' className='normal-case'>
            {text}
          </Text>
        </div>
      ))}
    </div>
  );
};
