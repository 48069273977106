import * as React from 'react';

import pluralize from 'pluralize';

interface Props {
  count: number;
  word?: string;
  onClick: React.MouseEventHandler;
}
export const ViewAllLink: React.FC<React.PropsWithChildren<Props>> = ({ count, word = '', onClick }) => (
  <button className='leading-base' onClick={onClick}>
    View all {pluralize(word, count, true)}
  </button>
);
