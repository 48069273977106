import React, { useCallback } from 'react';

import { SUPPORT_EMAIL } from '@constants/emails';

import { Alert, Button } from '@components/common';

import { useConnectedAccountSync } from './hooks/useConnectedAccountSync';
import { MESSAGES } from './constants';

export const ConnectedAccountSync = ({ provider }: Props) => {
  const { message: syncStatus, account } = useConnectedAccountSync({ provider });

  const retryUrl = `/user/connected_accounts/${account?.id}/reauthentication`;
  const contactUrl = `mailto:${SUPPORT_EMAIL}`;
  const learnMoreUrl = 'https://greatq.helpdocs.io/support/integrations';

  const getIsPending24h = () => {
    if (!account || syncStatus !== 'pending') return false;

    return Math.abs(new Date().getTime() - account.created_at.getTime()) / (1000 * 60 * 60) >= 1;
  };

  const renderer = useCallback(() => {
    if (!account) return null;

    switch (syncStatus) {
      case 'pending': {
        const isPending24h = getIsPending24h();

        return isPending24h ? (
          <Alert type='warning' heading={MESSAGES['pending24h'].title}>
            {MESSAGES['pending24h'].body}
            <div className='mt-2 space-x-2'>
              <Button href={learnMoreUrl} target='_blank' small>
                Learn more
              </Button>
              <Button href={contactUrl} small>
                Contact support
              </Button>
            </div>
          </Alert>
        ) : (
          <Alert type='warning' heading={MESSAGES['pending'].title}>
            {MESSAGES['pending'].body}
            <div className='mt-2'>
              <Button href={learnMoreUrl} target='_blank' small>
                Learn more
              </Button>
            </div>
          </Alert>
        );
      }
      case 'finished':
        return (
          <Alert type='success' heading={MESSAGES['finished'].title}>
            {MESSAGES['finished'].body}
          </Alert>
        );
      case 'failed':
        return (
          <Alert type='error' heading={MESSAGES['failed'].title}>
            {MESSAGES['failed'].body}
            <div className='mt-2'>
              <Button href={retryUrl} small>
                Retry
              </Button>
            </div>
          </Alert>
        );
      default:
        return null;
    }
  }, [syncStatus]);

  if (!account || !syncStatus) return null;

  return <div className='desktop:px-12 my-3'>{renderer()}</div>;
};
