import * as React from 'react';
import { useEffect } from 'react';

import { DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { Basic, Card } from '@components/shared/Skeleton';
import { Circle } from '@components/shared/Skeleton/Circle';
import { useSidebar } from '@hooks/useSidebar';

export const Skeleton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const sidebar = useSidebar();
  useEffect(() => {
    sidebar.setHidden(true);
    return () => sidebar.setHidden(false);
  }, []);

  return (
    <div className='flex h-full flex-col'>
      <div className='border-b border-gray-200 bg-white'>
        <div className='px-page h-18 flex items-center space-x-3 py-4'>
          <Basic width={320} height={40} />
          <div className='flex-grow space-x-3 text-right'>
            <div className='flex flex-row items-center justify-end -space-x-2'>
              <Circle inline width={40} />
              <Circle inline width={40} />
            </div>
          </div>

          <Basic inline width={90} height={30} />
        </div>

        <div className='border-t border-gray-200 bg-white'>
          <ol className='tablet:flex tablet:divide-y-0 px-page justify-between space-x-4 divide-y divide-gray-300'>
            {Array(6)
              .fill(1)
              .map((i, idx) => (
                <li key={idx} className='flex h-12 items-center space-x-4'>
                  <Basic height={20} width={150} />
                  <div className='tablet:block h-full w-5'>
                    <svg
                      className='h-full w-full text-gray-300'
                      viewBox='0 0 22 80'
                      fill='none'
                      preserveAspectRatio='none'
                    >
                      <path
                        d='M0 -2L20 40L0 82'
                        vectorEffect='non-scaling-stroke'
                        stroke='currentcolor'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                </li>
              ))}
          </ol>
        </div>
      </div>

      <DashboardLayoutBody className='mx-auto flex h-full w-full max-w-3xl flex-1 flex-col'>
        <div className='h-full'>
          <Card height='100%' />
        </div>
      </DashboardLayoutBody>
    </div>
  );
};
