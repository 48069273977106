import { api } from '@api/reduxApi';

type CreateParams = { id: number | string; study_message: Partial<StudyMessage> & { document?: any } };

type UpdateParams = { id: number | string; studyId: number; study_message: Partial<StudyMessage> };

const StudyMessagesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudyMessage: builder.query<StudyMessage, { id: number | string; studyId: number }>({
      query: ({ studyId, id }) => `/studies/${studyId}/messages/${id}`,
      transformResponse: (resp: any) => resp.data,
      providesTags: (q, i, { id }) => [{ type: 'StudyMessage', id }]
    }),
    createStudyMessage: builder.mutation<StudyMessage, CreateParams>({
      query: ({ id, study_message }) => ({
        method: 'POST',
        url: `/studies/${id}/messages`,
        body: { study_message }
      }),
      transformResponse: (resp: any) => resp.data,
      invalidatesTags: (q, i, { id }) => [{ type: 'StudyMessage', id }]
    }),
    updateStudyMessage: builder.mutation<StudyMessage, UpdateParams>({
      query: ({ id, studyId, study_message }) => ({
        method: 'PUT',
        url: `/studies/${studyId}/messages/${id}`,
        body: { study_message }
      }),
      transformResponse: (resp: any) => resp.data,
      invalidatesTags: (q, i, { id }) => [{ type: 'StudyMessage', id }]
    }),
    sendStudyMessage: builder.mutation<
      { task: BackgroundTask; count: number },
      {
        id: number;
        studyId: number;
        participation_ids?: number[];
        query?: ParticipantsFilterQuery;
        sender: Partial<EmailSender>;
      }
    >({
      query: ({ id, studyId, participation_ids, sender, query }) => ({
        url: `/studies/${studyId}/messages/${id}/send`,
        method: 'POST',
        body: { participation_ids, sender, query }
      }),
      transformResponse: (resp: any) => resp.data
    })
  })
});

export const {
  useGetStudyMessageQuery,
  useCreateStudyMessageMutation,
  useUpdateStudyMessageMutation,
  useSendStudyMessageMutation
} = StudyMessagesApi;
