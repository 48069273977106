import * as React from 'react';
import { forwardRef } from 'react';

import cn from 'classnames';
import type { HTMLAttributes, ReactNode } from 'react';
enum ScreenSizes {
  'monitor' = 'monitor',
  'desktop' = 'desktop',
  'tablet' = 'tablet',
  'mobile' = 'mobile',
  'tiny' = 'tiny'
}

type ColumnScreenSize = {
  [key in ScreenSizes]?: number;
};

export interface GridProps extends ColumnScreenSize {
  children?: ReactNode;
  /**
   * tailwind spacing
   * https://tailwindcss.com/docs/customizing-spacing#default-spacing-scale
   */
  gap?: number;
  /**
   * amount of columns per row (will override breakpoints)
   */
  columns?: number;
  className?: string;
  testId?: string;
}

export interface ColumnProps extends ColumnScreenSize {
  children?: ReactNode;
  className?: string;
}

const createGridCssClasses = (screenSizes: {
  tablet: number | undefined;
  tiny: number | undefined;
  desktop: number | undefined;
  mobile: number | undefined;
  monitor: number | undefined;
}): string =>
  cn(
    Object.keys(ScreenSizes).map((screenSize) =>
      screenSize === 'tiny'
        ? {
            [`grid-cols-${screenSizes['tiny']}`]: !!screenSizes['tiny']
          }
        : {
            [`${screenSize}:grid-cols-${screenSizes[screenSize]}`]: !!screenSizes[screenSize]
          }
    )
  );

const createColumnCssClasses = (screenSizes: ColumnScreenSize): string =>
  cn(
    (Object.keys(ScreenSizes) || []).map((screenSize) =>
      screenSize === 'tiny'
        ? {
            [`col-span-${screenSizes['tiny']}`]: !!screenSizes['tiny'],
            hidden: screenSizes['tiny'] === 0,
            block: screenSizes['tiny'] && screenSizes['tiny'] > 0
          }
        : {
            [`${screenSize}:col-span-${screenSizes[screenSize]}`]: !!screenSizes[screenSize],
            [`${screenSize}:hidden`]: screenSizes[screenSize] === 0,
            [`${screenSize}:block`]: screenSizes[screenSize] > 0
          }
    )
  );

export const Column = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & ColumnProps>(
  ({ children, monitor, desktop, tablet, mobile, tiny, className, ...rest }, ref) => (
    <div
      ref={ref}
      className={cn(createColumnCssClasses({ monitor, desktop, tablet, mobile, tiny }), className)}
      {...rest}
    >
      {children}
    </div>
  )
);

export const Grid = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & GridProps>(
  ({ monitor, desktop, tablet, mobile, tiny, gap, columns, className, children, testId, ...rest }, ref) => (
    <section
      className={cn(
        'grid',
        'auto-rows-fr',
        createGridCssClasses({ monitor, desktop, tablet, mobile, tiny }),
        { [`gap-${gap}`]: !!gap },
        { [`grid-cols-${columns}`]: !!columns },
        className
      )}
      ref={ref}
      data-testid={testId}
      {...rest}
    >
      {children}
    </section>
  )
);
