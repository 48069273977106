import * as React from 'react';

import pluralize from 'pluralize';

import { ButtonProps } from '@components/common/Button';
import { compact } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { useUser } from '@hooks/useUser';

import { BookabilityRuleAlert } from './BookabilityRuleAlert';

const WARNING_COPY = 'Participants will see reduced availability.';
const ERROR_COPY = 'Participants will see an empty calendar with no available times.';

export const ModeratorsNeedCalendarSelectedAlert: BookabilityRuleAlertComponent = ({ level, extra, onClickCta }) => {
  const {
    account: { team }
  } = useAccount();
  const user = useUser();
  const userIds = extra?.user_ids || [];
  const users = compact(userIds.map((id) => team.find((u) => u.id === id)));

  let heading = `${pluralize('moderators', users.length, true)} have no calendars selected.`;
  if (users.length === 1) {
    heading = `${users[0].name} has no calendar selected.`;
    if (users[0].id === user.id) {
      heading = 'You have no calendar selected.';
    }
  }

  const ctas: ButtonProps[] = userIds.includes(user.id)
    ? [
        {
          children: 'Re-connect',
          href: '/user/connected_accounts',
          onClick: onClickCta
        }
      ]
    : [];

  return (
    <BookabilityRuleAlert
      level={level}
      heading={heading}
      message={level === 'error' ? ERROR_COPY : WARNING_COPY}
      ctas={ctas}
    />
  );
};
