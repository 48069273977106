import React, { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { Popover, Props as PopoverProps } from '@components/Popover';
import { createPolymorphicComponent } from '@helpers/react/createPolymorphicComponent';

interface Props
  extends Pick<PopoverProps, 'isDisabled' | 'isInteractive' | 'maxWidth' | 'placement' | 'width' | 'renderRelativeTo'> {
  className?: string;
  content: ReactNode;
  tooltipClassName?: string;
}

export const Tooltip = createPolymorphicComponent<Props, 'div'>(
  ({
    as,
    children,
    className,
    content,
    placement = 'top',
    renderRelativeTo = 'body',
    tooltipClassName,
    maxWidth = 350,
    ...rest
  }) => {
    return (
      <Popover
        as={as}
        className={twMerge('text-xs leading-normal', tooltipClassName)}
        colorScheme='dark'
        handle={children}
        handleWrapperClassName={className}
        maxWidth={maxWidth}
        isCompact
        isHoverable
        isInline
        offset={8}
        placement={placement}
        renderRelativeTo={renderRelativeTo}
        {...rest}
      >
        {content}
      </Popover>
    );
  }
);
