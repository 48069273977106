import * as React from 'react';
import { forwardRef, MutableRefObject, useEffect, useRef } from 'react';

import cn from 'classnames';

interface Props {
  indeterminate?: boolean;
  active?: boolean;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

const IndeterminateCheckbox = forwardRef<any, Props>(({ indeterminate = false, active, ...rest }, ref) => {
  const defaultRef = useRef<HTMLInputElement>();
  const resolvedRef = (ref || defaultRef) as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const inputClass = cn('w-4 h-4 text-indigo-600 rounded focus:ring-indigo-500', {
    'border-gray-300': !active,
    'border-indigo-600 border-2': active
  });

  return (
    <>
      <input type='checkbox' aria-label='Toggle row' ref={resolvedRef} {...rest} className={inputClass} />
    </>
  );
});

export default IndeterminateCheckbox;
