import { useEffect, useRef, useState } from 'react';

import { getCandidateHighlights } from '@api/queries';

interface Hook {
  highlights: DocumentHighlight[];
}

export const useCandidateHighlights = (id: number): Hook => {
  const [highlights, setHighlights] = useState<DocumentHighlight[]>([]);

  const isMountedRef = useRef(true);

  useEffect(() => {
    getCandidateHighlights(id)
      .then((resp) => {
        if (resp && isMountedRef.current) {
          setHighlights(resp);
        }
      })
      .catch((e) => {
        if (isMountedRef.current) {
          console.log('Highlights fetching failed', e);
        }
      });

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return { highlights };
};
