import { INLINE_ERROR_MESSAGES } from './consts';

export const getInlineErrorMessage = (rule: BookabilityRule, extra: BookabilityCase['extra']) => {
  const message = INLINE_ERROR_MESSAGES[rule] || '';
  return typeof message === 'function' ? message(extra) : message;
};

export const getBookabilitySignificantErrorLevel = (
  bookability: Bookability,
  rules: BookabilityRule[]
): BookabilityCase['level'] => {
  let level: BookabilityCase['level'] = null;

  for (const rule of rules) {
    if (bookability[rule]?.[0] === 'error') {
      return 'error';
    }
    if (bookability[rule]?.[0] === 'warning') {
      level = 'warning';
    }
  }

  return level;
};

export const sortBookabilityRules = (bookability: Bookability, rules: BookabilityRule[]): BookabilityRule[] =>
  rules.slice().sort((a, b) => {
    const aLevel = bookability[a]?.[0];
    const bLevel = bookability[b]?.[0];
    if (aLevel === 'error' && bLevel === 'warning') return -1;
    if (aLevel === 'warning' && bLevel === 'error') return 1;
    return 0;
  });
