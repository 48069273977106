import * as React from 'react';

import cn from 'classnames';

import { without } from '@components/utils';

export type CheckboxesGroupOption = {
  label: string;
  helper: string;
  value: string;
  extra?: any;
  name?: string;
  disabled?: boolean;
  className?: string;
};
interface CheckboxesGroupProps {
  options: CheckboxesGroupOption[];
  selected: string[];
  disabled?: boolean;
  borderColor?: string;
  onChange: (selected: string[]) => void;
  disableRule?: (v) => boolean;
}

function optionClass(i, length) {
  if (i === 0) {
    return 'rounded-tl-md rounded-tr-md';
  } else if (i === length - 1) {
    return 'rounded-bl-md rounded-br-md';
  } else {
    return '';
  }
}

interface CheckboxProps<T extends any = any> {
  id?: string;
  value?: T;
  name?: string;
  disabled?: boolean;
  selected: boolean;
  onChange?: (selected: boolean) => void;
  className?: string;
  register?: any;
}
export const Checkbox: React.FC<React.PropsWithChildren<CheckboxProps>> = ({
  id,
  value,
  name,
  disabled = false,
  selected,
  onChange,
  className,
  register,
  ...rest
}) => {
  return (
    <div className='flex h-5 items-center'>
      <input
        id={id}
        type='checkbox'
        data-testid='checkbox'
        aria-label={value}
        value={value}
        name={name}
        ref={register}
        disabled={disabled}
        readOnly={!onChange}
        onChange={onChange ? () => onChange(!selected) : undefined}
        checked={selected}
        className={cn(
          'h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500',
          { 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled },
          className
        )}
        {...rest}
      />
    </div>
  );
};

export const CheckboxesGroup: React.FC<React.PropsWithChildren<CheckboxesGroupProps>> = ({
  options,
  selected,
  disabled: allDisabled,
  onChange,
  borderColor = 'gray-200',
  disableRule
}) => {
  return (
    <div className={`-space-y-px rounded-md bg-white ${allDisabled ? 'opacity-75' : ''}`}>
      {options.map(({ label, helper, value, name, extra, disabled, className = '' }, i) => (
        <div
          key={label}
          className={`${className} ${
            disabled ? 'opacity-50' : ''
          } relative flex border p-4 border-${borderColor} ${optionClass(i, options.length)}`}
        >
          <label
            className={`flex flex-row space-x-3 ${disabled || allDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          >
            <Checkbox
              value={value}
              name={name}
              disabled={disabled || allDisabled || disableRule?.(value)}
              selected={selected.includes(value)}
              onChange={(v) => (v ? onChange([...selected, value]) : onChange(without(selected, value)))}
            />
            <div className='flex flex-col'>
              <span className='h400-medium block font-bold'>{label}</span>
              <span className='h400 mt-1 block text-gray-500'>{helper}</span>
              {extra}
            </div>
          </label>
        </div>
      ))}
    </div>
  );
};
