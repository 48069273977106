import * as React from 'react';
import { useEffect } from 'react';

import { endOfDay, getUnixTime, startOfDay } from 'date-fns';

import { api } from '@api/reduxApi';
import * as dropdowns from '@components/shared/TableFilters/components/dropdowns';
import { Meta as StudiesMeta } from '@components/shared/TableFilters/components/dropdowns/Studies';
import * as svgs from '@components/shared/TableFilters/components/svgs';
import { FilterDefinition } from '@components/shared/TableFilters/types';
import { compact } from '@components/utils';

import { ArtifactHit } from '../types';

import { artifactTypeOptions } from './artifactTypeOptions';

export const buildRepoFilterDefs = (studyId?: number | null): FilterDefinition<ArtifactHit>[] => {
  const definitions: FilterDefinition<ArtifactHit>[] = compact([
    {
      id: 'date_added',
      type: 'date',
      name: 'Date added',
      defaultOperator: 'is_between',
      Icon: svgs.date,
      func: () => true,
      toAlgoliaFilter: ({ range, operator, value }) => {
        if (!range && !value) return '';

        switch (operator) {
          case 'is_between': {
            if (!range) return '';

            const min = getUnixTime(startOfDay(range.min));
            const max = getUnixTime(endOfDay(range.max));

            return `created_at_timestamp > ${min} AND created_at_timestamp < ${max}`;
          }
          case 'is': {
            if (!value) return '';

            const min = getUnixTime(startOfDay(value));
            const max = getUnixTime(endOfDay(value));

            return `created_at_timestamp >= ${min} AND created_at_timestamp <= ${max}`;
          }
          case 'is_not': {
            if (!value) return '';

            const min = getUnixTime(startOfDay(value));
            const max = getUnixTime(endOfDay(value));

            return `created_at_timestamp < ${min} OR created_at_timestamp > ${max}`;
          }
          case 'is_greater': {
            if (!value) return '';

            const max = getUnixTime(endOfDay(value));

            return `created_at_timestamp > ${max}`;
          }
          case 'is_lesser': {
            if (!value) return '';

            const min = getUnixTime(startOfDay(value));

            return `created_at_timestamp < ${min}`;
          }
          default:
            return '';
        }
      }
    },
    {
      id: 'added_by',
      type: 'team_members',
      name: 'Added by',
      defaultOperator: 'includes_any',
      Icon: svgs.eligibility,
      Component: dropdowns.TeamMember,
      func: () => true,
      toAlgoliaFilter: ({ value }) => {
        if (!value) return '';

        return value.map((userId: number) => `study_user_ids:${userId}`).join(' OR ');
      }
    },
    !!studyId && {
      id: 'participant',
      type: 'participant',
      name: 'Participant',
      defaultOperator: 'includes_any',
      Icon: svgs.participant,
      Component: (props) => <dropdowns.Participant {...props} studyId={studyId} />,
      func: () => true,
      toAlgoliaFilter: ({ value }) => {
        if (!value) return '';

        return value.map((userId: number) => `candidate_ids:${userId}`).join(' OR ');
      }
    },
    {
      id: 'artifact_type',
      type: 'artifact_type',
      name: 'Type',
      defaultOperator: 'includes_any',
      Icon: svgs.artifactType,
      Component: (props) => <dropdowns.MultiSelectWithSearch {...props} options={artifactTypeOptions()} />,
      func: () => true,
      toAlgoliaFilter: ({ value }) => {
        if (!value) return '';

        return value.map((type: string) => `kind:"${type}"`).join(' OR ');
      }
    },
    {
      id: 'tag',
      type: 'tag',
      name: 'Tag',
      defaultOperator: 'includes_any',
      Icon: svgs.tag,
      Component: (props) => <dropdowns.Tag {...props} studyId={studyId} />,
      func: () => true,
      toAlgoliaFilter: ({ value, operator }) => {
        if (!value) return '';

        switch (operator) {
          case 'includes_all':
            return value.map((tag: string) => `tag_ids:"${tag}"`).join(' AND ');
          case 'includes_any':
            return value.map((tag: string) => `tag_ids:"${tag}"`).join(' OR ');
          default:
            return '';
        }
      }
    },
    !studyId && {
      id: 'studies',
      type: 'studies',
      name: 'Studies',
      defaultOperator: 'includes_any',
      func: () => true,
      Component: dropdowns.Studies,
      Icon: svgs.studies,
      hook: ({ setMeta }) => {
        const studies = api.useGetSimpleStudiesQuery();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (studies.isSuccess) {
            setMeta({ studies: studies.data } as StudiesMeta);
          }
        }, [studies.isSuccess]);
      },
      toAlgoliaFilter: ({ value }) => {
        if (!value) return '';

        return value.map((studyId: number) => `study_ids:${studyId}`).join(' OR ');
      }
    }
  ]);

  return definitions;
};
