import { createContext } from 'react';

export type StateRouterContext = {
  pushPath: (path: string) => void;
  popPath: () => void;
  currentPath: string;
  hasPrevious: boolean;
};

const defaultContext: StateRouterContext = {
  pushPath: () => {},
  popPath: () => {},
  currentPath: '',
  hasPrevious: false
};

export const createStateRouterContext = () => createContext<StateRouterContext>(defaultContext);
