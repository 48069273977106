import { MutableRefObject } from 'react';

import { isRef } from './isRef';

export const unRef = <T = HTMLElement | null>(target: MaybeRef<T>): T => {
  if (isRef(target)) {
    return (target as MutableRefObject<T>).current;
  }

  return target as T;
};
