import React from 'react';

import { UniqueIdentifier } from '@dnd-kit/core';
import Tippy from '@tippyjs/react';

import { Modal, ModalHeading } from '@components/common';
import { PersonSVG, VideoCameraSVG } from '@components/svgs';
import { TreeTest } from 'components/SurveyBuilder/types/models';

import { ResponseDetails } from './ResponseDetails';

interface Props {
  response: ScreenerResponseAnswerValue<'tree_test'>;
  onClose: () => void;
  title: string;
  treeTestData: TreeTest;
  correctAnswers: UniqueIdentifier[][];
}

export const ResponseDetailsModal: React.FC<React.PropsWithChildren<Props>> = ({
  correctAnswers,
  title,
  treeTestData,
  response,
  onClose
}) => {
  return (
    <Modal size='lg' className='max-h-screen' onClose={onClose}>
      <div className='max-h-screen'>
        <ModalHeading>{title}</ModalHeading>
        <div className='mb-6 mt-3 flex items-center'>
          <PersonSVG className='mr-1 text-gray-500' />
          <div className='mr-2 text-gray-500'>{response.participation_name || 'Unnamed'}</div>
          <Tippy
            aria={{ content: 'labelledby', expanded: 'auto' }}
            appendTo={() => document.body}
            interactive
            arrow={false}
            content='View full session room.'
          >
            <a href={`/sessions/${response.session_uuid}`} target='_blank'>
              <VideoCameraSVG className='h-4 w-4' />
            </a>
          </Tippy>
        </div>
      </div>
      <ResponseDetails nodes={treeTestData?.nodes || []} correctAnswers={correctAnswers} answer={response} />
    </Modal>
  );
};
