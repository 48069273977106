import { SelectOption } from '@components/common';
import { DEFAULT_UNMODERATED_FIELD_TITLE } from '@components/GQSurveyBuilder/QuestionCard';
import { calculateNumber } from 'components/GQSurveyBuilder/utils';

export type QuestionSelectItemData = {
  question: ScreenerField | null;
  tooltip: string | null;
};
export const buildItems = (
  currentQuestion: ScreenerField,
  questions: ScreenerField[]
): SelectOption<string, QuestionSelectItemData>[] => {
  const startIdx = questions.findIndex((q) => q.field_type === 'start_loom');
  const stopIdx = questions.findIndex((q) => q.field_type === 'stop_loom');
  const curIdx = questions.findIndex((q) => q.id === currentQuestion.id);
  const isCurInsideZone = startIdx >= 0 && stopIdx >= 1 && curIdx >= startIdx && curIdx < stopIdx;

  const items: SelectOption<string, QuestionSelectItemData>[] = questions
    .map((q, i) => {
      let tooltip: string | null = null;
      let disabled = q.id === currentQuestion.id || q.position <= currentQuestion.position + 1;

      if (startIdx >= 0 && (stopIdx < 1 || i <= stopIdx) && i >= startIdx && curIdx <= startIdx) {
        disabled = true;
        tooltip = 'Cannot skip over the start recording step.';
      }
      if (isCurInsideZone && i > stopIdx) {
        disabled = true;
        tooltip = 'Cannot skip over the stop recording step.';
      }

      const title =
        {
          task: DEFAULT_UNMODERATED_FIELD_TITLE,
          start_loom: 'Start recording',
          stop_loom: 'End recording'
        }[q.field_type] ||
        q.label ||
        'Write your question…';

      const hasNumber = !['start_loom', 'stop_loom'].includes(q.field_type);

      const label = hasNumber ? `${calculateNumber(questions, i)}. ${title}` : title;

      return {
        label,
        value: String(q.id),
        disabled,
        data: {
          question: q,
          tooltip
        }
      };
    })
    .filter((i) => i.data?.question?.field_type !== 'start_loom');

  items.push({
    label: 'End of the survey',
    value: 'end',
    disabled: isCurInsideZone,
    data: {
      question: null,
      tooltip: isCurInsideZone ? 'Cannot skip over the stop recording step.' : null
    }
  });

  return items;
};
