import qs from 'qs';

import { api } from '@api/reduxApi';

const backgroundTasksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBackgroundTasks: builder.query<BackgroundTask[], { actions?: BackgroundTaskAction[] }>({
      query: ({ actions }) => {
        if (actions) {
          return `/background_tasks?${qs.stringify({ background_task_action: actions }, { arrayFormat: 'brackets' })}`;
        } else {
          return '/background_tasks';
        }
      }
    })
  })
});

const enabledIntegrationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEnabledIntegrations: builder.query<string[], void>({
      query: () => `users/integrations/enabled`,
      transformResponse: (resp: any) => resp.data as string[]
    })
  })
});

export const { useGetBackgroundTasksQuery } = backgroundTasksApi;
export const { useGetEnabledIntegrationsQuery } = enabledIntegrationsApi;
