import React from 'react';

import { ArtifactAttributes } from '@components/RepositoryApp/types';
import { AlgoliaHit } from '@components/shared/Search';
import { Table } from '@components/shared/Table';

import { DefinitionFnReturn } from './baseArtifactsColumnDefs';

export const activityArtifactsColumnDefs = ({ getColumnLabel }): DefinitionFnReturn => {
  const columns: DefinitionFnReturn = [
    {
      cell: (props) => <Table.Cell<AlgoliaHit<ArtifactAttributes>> {...props} type='datetime' />,
      id: 'created_at',
      accessorKey: 'created_at',
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort>
          {getColumnLabel('created_at')}
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => <Table.Cell<AlgoliaHit<ArtifactAttributes>> {...props} type='datetime' />,
      id: 'updated_at',
      accessorKey: 'updated_at',
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort>
          {getColumnLabel('updated_at')}
        </Table.ColumnHeader>
      )
    }
  ];

  return columns;
};
