import * as React from 'react';

import { api } from '@api/reduxApi';

import { AssetShareAccessDropdown } from './AssetShareAccessDropdown';

type Props = {
  readOnly?: boolean;
  uuid: string;
  value: ShareAccess;
};
export const SessionShareAccessDropdown: React.FC<React.PropsWithChildren<Props>> = ({ uuid, value, readOnly }) => {
  const [updateRepoSession] = api.useUpdateRepoSessionMutation();

  return (
    <AssetShareAccessDropdown
      initialValue={value}
      onUpdate={(access) => updateRepoSession({ uuid, access })}
      readOnly={readOnly}
    />
  );
};
