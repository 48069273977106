import React from 'react';

import { EditorState } from 'prosemirror-state';
import { format as timeagoFormat } from 'timeago.js';

import { Avatar, InfoLabel, Text } from '@components/common';
import { TextNode } from '@components/RepoSessionApp/ClipPage/hooks/useEditorState';
import { HighlightDropdown } from '@components/RepoSessionApp/components/HighlightsTab/components/HighlightDropdown';
import { useRenderTagsList } from '@components/RepositoryApp/hooks/useRenderTagsList';
import { formatDuration } from '@components/shared/Artifact/components/utils';
import { AskAiSVG } from '@components/svgs';
import { getHighlightDisplayTitle } from '@components/utils';

import { ClipTranscript } from './ClipTranscript';

const renderContent = (content: HighlightContentElement[]) => {
  const containImages = content.some((c) => c.type === 'image');
  const containText = content.some((c) => c.type === 'text');

  if (!containImages) {
    const text = content.map((c: TextContentElement) => c.text).join(' ');
    return <Text h='400'>{text}</Text>;
  }

  if (!containText) {
    return <img src={(content[0] as ImageContentElement).src} alt='' className='h-48 w-auto' />;
  }

  const contentAsText = content.map((c) => (c.type === 'text' ? c.text : '[image]'));

  return (
    <Text h='400' as='span'>
      {contentAsText.join(' ')}
    </Text>
  );
};

interface Props {
  highlight: DocumentHighlight;
  creator?: TeamUser | null;
  sessionTitle?: string;
  getTextNodes: (timeRange: [number, number]) => TextNode[];
  state: EditorState | undefined;
}

export const HighlightCard: React.FC<React.PropsWithChildren<Props>> = ({
  getTextNodes,
  state,
  sessionTitle,
  highlight,
  creator
}) => {
  const { renderTagsList } = useRenderTagsList();

  return (
    <div className='rounded-md border border-gray-200 p-4'>
      <div className='flex items-center justify-end'>
        <HighlightDropdown highlight={highlight} />
      </div>
      <Text bold className='mt-1 pr-4'>
        {getHighlightDisplayTitle(highlight)}
      </Text>
      <div className='mt-1 flex flex-wrap items-center'>
        <div className='mr-3 mt-1 flex flex-nowrap items-center space-x-1 py-px'>
          {highlight.ai ? (
            <>
              <AskAiSVG className='mr-1 h-4 w-4' />
              <Text h='400' className='leading-5'>
                Great Question AI
              </Text>
            </>
          ) : (
            creator && (
              <>
                <Avatar user={creator} size='sm' />
                <Text color='gray-500' h='200'>
                  {creator?.name}
                </Text>
              </>
            )
          )}
        </div>
        {sessionTitle && <InfoLabel h='200' className='mr-3 mt-1 text-gray-500' icon='checklist' text={sessionTitle} />}
        {highlight.created_at && (
          <InfoLabel
            h='200'
            className='mr-3 mt-1 text-gray-500'
            icon='changedAt'
            text={timeagoFormat(highlight.created_at)}
          />
        )}
        {highlight.clip && (
          <InfoLabel
            h='200'
            className='mr-3 mt-1 text-gray-500'
            icon='clock'
            text={formatDuration(Math.round(highlight.clip.to / 1000 - highlight.clip.from / 1000))}
          />
        )}
      </div>

      {!highlight.clip && !!highlight.content?.length && (
        <section className='mt-4 pl-2'>{renderContent(highlight.content)}</section>
      )}
      {highlight.clip && getTextNodes && state && (
        <section className='mt-4 border-l-2 border-gray-200 pl-2'>
          <ClipTranscript
            className='mb-2 mt-4'
            range={[highlight.clip.from, highlight.clip.to]}
            getTextNodes={getTextNodes}
            state={state}
          />
        </section>
      )}

      <div className='mt-3'>{renderTagsList(highlight.tag_ids || [], { max: 6 })}</div>
    </div>
  );
};
