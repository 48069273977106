import React from 'react';

export const BusySVG: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.4375 3.93808H13.5625V12.6881H0.4375V3.93808Z'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.625 3.93808C9.625 3.24189 9.34844 2.57421 8.85615 2.08192C8.36387 1.58964 7.69619 1.31308 7 1.31308C6.30381 1.31308 5.63613 1.58964 5.14384 2.08192C4.65156 2.57421 4.375 3.24189 4.375 3.93808'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M0.4375 7.43808H6.125' stroke='#374151' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M7.875 7.43808H13.5625'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7 8.75C6.76794 8.75 6.54538 8.65781 6.38128 8.49372C6.21719 8.32962 6.125 8.10706 6.125 7.875V7C6.125 6.76794 6.21719 6.54538 6.38128 6.38128C6.54538 6.21719 6.76794 6.125 7 6.125C7.23206 6.125 7.45462 6.21719 7.61872 6.38128C7.78281 6.54538 7.875 6.76794 7.875 7V7.875C7.875 8.10706 7.78281 8.32962 7.61872 8.49372C7.45462 8.65781 7.23206 8.75 7 8.75Z'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const InfoSVG: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.3125 9.625H7.875C7.64294 9.625 7.42038 9.53281 7.25628 9.36872C7.09219 9.20462 7 8.98206 7 8.75V6.5625C7 6.44647 6.95391 6.33519 6.87186 6.25314C6.78981 6.17109 6.67853 6.125 6.5625 6.125H6.125'
      stroke='black'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.78125 3.9375C6.73799 3.9375 6.69569 3.95033 6.65972 3.97437C6.62375 3.9984 6.59571 4.03257 6.57915 4.07254C6.56259 4.11251 6.55826 4.15649 6.5667 4.19893C6.57514 4.24136 6.59598 4.28034 6.62657 4.31093C6.65716 4.34152 6.69614 4.36236 6.73857 4.3708C6.78101 4.37924 6.82499 4.37491 6.86496 4.35835C6.90493 4.34179 6.9391 4.31375 6.96313 4.27778C6.98717 4.24181 7 4.19951 7 4.15625C7 4.09823 6.97695 4.04259 6.93593 4.00157C6.89491 3.96055 6.83927 3.9375 6.78125 3.9375Z'
      stroke='black'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.4375 7C0.4375 8.74048 1.1289 10.4097 2.35961 11.6404C3.59032 12.8711 5.25952 13.5625 7 13.5625C8.74048 13.5625 10.4097 12.8711 11.6404 11.6404C12.8711 10.4097 13.5625 8.74048 13.5625 7C13.5625 5.25952 12.8711 3.59032 11.6404 2.35961C10.4097 1.1289 8.74048 0.4375 7 0.4375C5.25952 0.4375 3.59032 1.1289 2.35961 2.35961C1.1289 3.59032 0.4375 5.25952 0.4375 7V7Z'
      stroke='black'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const GuestsSVG: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.9375 3.5C3.9375 4.31223 4.26016 5.09118 4.83449 5.66551C5.40882 6.23984 6.18777 6.5625 7 6.5625C7.81223 6.5625 8.59118 6.23984 9.16551 5.66551C9.73984 5.09118 10.0625 4.31223 10.0625 3.5C10.0625 2.68777 9.73984 1.90882 9.16551 1.33449C8.59118 0.760155 7.81223 0.4375 7 0.4375C6.18777 0.4375 5.40882 0.760155 4.83449 1.33449C4.26016 1.90882 3.9375 2.68777 3.9375 3.5V3.5Z'
      stroke='black'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.3125 13.5625C1.3125 12.0541 1.91172 10.6074 2.97833 9.54083C4.04494 8.47422 5.49158 7.875 7 7.875C8.50842 7.875 9.95506 8.47422 11.0217 9.54083C12.0883 10.6074 12.6875 12.0541 12.6875 13.5625'
      stroke='black'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const CalendarSVG: React.FC<React.PropsWithChildren<any>> = (props) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.438721 2.1875H13.5637V13.5625H0.438721V2.1875Z'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.438721 5.6875H13.5637'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M3.93872 3.5V0.4375' stroke='#374151' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10.0637 3.5V0.4375' stroke='#374151' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />

    <defs>
      <rect width='14' height='14' fill='white' />
    </defs>
  </svg>
);

export const CheckedSVG: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <svg {...props} width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1 4L2.71429 6L7 1' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export const UserProfileSVG: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='12' cy='12' r='11.5' fill='#F9FAFB' stroke='#9FA6B2' />
    <path
      d='M8.9375 11.5C8.9375 12.3122 9.26016 13.0912 9.83449 13.6655C10.4088 14.2398 11.1878 14.5625 12 14.5625C12.8122 14.5625 13.5912 14.2398 14.1655 13.6655C14.7398 13.0912 15.0625 12.3122 15.0625 11.5C15.0625 10.6878 14.7398 9.90882 14.1655 9.33449C13.5912 8.76016 12.8122 8.4375 12 8.4375C11.1878 8.4375 10.4088 8.76016 9.83449 9.33449C9.26016 9.90882 8.9375 10.6878 8.9375 11.5V11.5Z'
      stroke='#9FA6B2'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.3125 21.5625C6.3125 20.0541 6.91172 18.6074 7.97833 17.5408C9.04494 16.4742 10.4916 15.875 12 15.875C13.5084 15.875 14.9551 16.4742 16.0217 17.5408C17.0883 18.6074 17.6875 20.0541 17.6875 21.5625'
      stroke='#9FA6B2'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
