import * as React from 'react';

export const repository: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg
    className='mr-3 h-4 w-4 text-white group-hover:text-gray-300'
    width='16'
    height='16'
    viewBox='0 0 14 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.99996 0C3.66577 0 0.290283 0.88671 0.290283 2.58065V13.4194C0.290283 15.1133 3.66577 16 6.99996 16C10.3342 16 13.7096 15.1133 13.7096 13.4194V2.58065C13.7096 0.88671 10.3342 0 6.99996 0ZM12.6774 13.4194C12.6774 13.9453 10.6733 14.9677 6.99996 14.9677C3.32667 14.9677 1.32254 13.9453 1.32254 13.4194V11.2428C2.57467 11.9964 4.79351 12.3871 6.99996 12.3871C9.20641 12.3871 11.4253 11.9964 12.6774 11.2428V13.4194ZM12.6774 9.80645C12.6774 10.3324 10.6733 11.3548 6.99996 11.3548C3.32667 11.3548 1.32254 10.3324 1.32254 9.80645V7.62994C2.57467 8.38348 4.79351 8.77419 6.99996 8.77419C9.20641 8.77419 11.4253 8.38348 12.6774 7.62994V9.80645ZM12.6774 6.19355C12.6774 6.71948 10.6733 7.74194 6.99996 7.74194C3.32667 7.74194 1.32254 6.71948 1.32254 6.19355V4.01703C2.57467 4.77058 4.79351 5.16129 6.99996 5.16129C9.20641 5.16129 11.4253 4.77058 12.6774 4.01703V6.19355ZM6.99996 4.12903C3.32667 4.12903 1.32254 3.10658 1.32254 2.58065C1.32254 2.05471 3.32667 1.03226 6.99996 1.03226C10.6733 1.03226 12.6774 2.05471 12.6774 2.58065C12.6774 3.10658 10.6733 4.12903 6.99996 4.12903Z'
      fill='white'
    />
  </svg>
);
