import React from 'react';

import cn from 'classnames';
import Tippy from '@tippyjs/react';

import { Text } from '@components/common';
import { Props as TreeItemProps } from '@components/common/SortableTree/components/TreeItem';
import { getStyle, getValue } from '@components/ScreenerResults/components/TreeTestResponse/utils';
import * as Models from '@components/SurveyBuilder/types/models';
import { CheckSVG, ChevronDownSVG } from '@components/svgs';

interface Props extends TreeItemProps {
  showPercentage: boolean;
  maxDepth: number;
  items: Models.TreeItem[];
  answers: NonNullable<ScreenerResponseAnswerValue<'tree_test'>>[];
  count: number;
  directCount: number;
}

export const ResultsTableItem = (props: Props) => {
  const {
    id,
    answers,
    showPercentage,
    onCollapse,
    childCount,
    value,
    maxDepth,
    selected,
    indentationWidth,
    depth,
    hasSelectedNode,
    collapsed,
    count,
    directCount
  } = props;

  const marginLeft = indentationWidth * depth;
  const marginRight = (maxDepth || 0) * indentationWidth - marginLeft;

  return (
    <div className='mb-1 flex items-center justify-between'>
      <div
        className={cn(
          (hasSelectedNode || selected) && 'bg-green-50',
          'h400 w-100 flex flex-shrink-0 items-center rounded-md border border-gray-200 pr-4'
        )}
        style={{
          marginLeft: `${marginLeft}px`,
          marginRight: `${marginRight}px`
        }}
      >
        <div className='flex h-10 w-full items-center'>
          <div className='flex h-full w-10 items-center justify-center border-r border-gray-200'>
            <Tippy maxWidth={240} arrow={false} content={collapsed ? 'Expand' : 'Collapse'}>
              <button
                className={cn(
                  'focus:outline-none flex h-6 w-6 transform items-center justify-center rounded-full transition duration-200 ease-in-out hover:bg-gray-100',
                  {
                    ['-rotate-90']: collapsed || !onCollapse
                  }
                )}
                onClick={() => onCollapse?.(id)}
                aria-label={collapsed ? 'Expand' : 'Collapse'}
                type='button'
              >
                <ChevronDownSVG className={childCount && childCount > 0 ? 'text-gray-700' : 'text-gray-400'} />
              </button>
            </Tippy>
          </div>
          <div className='ml-3 flex flex-1 items-center justify-between truncate'>
            <div className='flex w-full items-center justify-between truncate whitespace-nowrap'>
              {selected ? (
                <>
                  <span className='truncate'>{value}</span>
                  <CheckSVG className='ml-2 text-green-600' />
                </>
              ) : (
                <span className='truncate'>{value}</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-nowrap items-center space-x-8'>
        <Text
          style={getStyle(count, answers.length)}
          h='600'
          className='flex h-10 w-44 items-center justify-center rounded-md'
        >
          {getValue({ showPercentage, totalAnswers: answers.length, value: count })}
        </Text>
        <Text
          style={getStyle(directCount, answers.length)}
          h='600'
          className='flex h-10 w-44 items-center justify-center rounded-md'
        >
          {getValue({ showPercentage, totalAnswers: answers.length, value: directCount })}
        </Text>
      </div>
    </div>
  );
};
