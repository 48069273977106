import React, { FC } from 'react';

import pluralize from 'pluralize';
import Tippy from '@tippyjs/react';

import { GridGallery, GridGalleryBlock, Text } from '@components/common';
import { MergeCells } from '@components/shared/Tiptap/icons';

interface Props {
  answer: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>;
  mergedCategories: { name: string; categories: string[] }[];
  colsBreakpoints?: number | { default: number; [key: number]: number } | { [key: number]: number };
}

const DEFAULT_BREAKPOINTS = {
  768: 1,
  1024: 2,
  1280: 3,
  default: 3
};

export const ResponseDetails: FC<Props> = ({ colsBreakpoints, answer, mergedCategories }) => {
  return (
    <div className='w-full flex-grow overflow-auto'>
      <GridGallery breakpointCols={colsBreakpoints || DEFAULT_BREAKPOINTS}>
        {answer.results.map(({ cards, name }) => {
          const mergedCategory = mergedCategories.find(({ categories }) => categories.includes(name));

          return (
            <GridGalleryBlock className='rounded-md border border-gray-200 bg-indigo-50 p-4' key={name}>
              <div className='mb-4'>
                <Text bold>{name}</Text>

                {mergedCategory && (
                  <div className='mt-2.5 flex items-center'>
                    <Tippy arrow={false} content='Raw categories that were merged.'>
                      <span>
                        <MergeCells className='text-gray-500' />
                      </span>
                    </Tippy>
                    <Text className='ml-2' h='400' color='gray-500'>
                      {mergedCategory.name}
                    </Text>
                  </div>
                )}
              </div>
              {cards?.map((card) => (
                <Text key={card} h='400' className='mb-1 rounded-md border border-gray-200 bg-white px-4 py-2'>
                  {card}
                </Text>
              ))}
              <Text h='200' color='gray-500 mt-4'>
                {pluralize('cards', cards?.length || 0, true)}
              </Text>
            </GridGalleryBlock>
          );
        })}
      </GridGallery>
    </div>
  );
};
