import React, { forwardRef, HtmlHTMLAttributes } from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { TextProps } from '@components/common/Text';
import { ErrorSvg, WarningSVG } from '@components/svgs';

type Size = 'medium' | 'small';

interface Props extends HtmlHTMLAttributes<HTMLDivElement> {
  icon?: 'error' | 'warning';
  size?: Size;
}

const H: Record<Size, TextProps['h']> = {
  small: '200',
  medium: '400'
};

const ICON_SIZE = {
  small: 3,
  medium: 4
};

export const ErrorMessage = forwardRef<HTMLDListElement, Props>(
  ({ size = 'medium', icon = 'error', className, children, ...rest }, ref) => {
    const SvgComponent = icon === 'error' ? ErrorSvg : WarningSVG;

    return (
      <section
        ref={ref}
        className={cn(
          'flex items-center',
          { 'text-red-600': icon === 'error', 'text-yellow-600': icon === 'warning' },
          className
        )}
        {...rest}
      >
        <SvgComponent className={`w-${ICON_SIZE[size]} h-${ICON_SIZE[size]} mr-2 flex-shrink-0`} />
        <Text
          color={icon === 'error' ? 'red-600' : 'yellow-600'}
          h={H[size]}
          role='alert'
          aria-live='assertive'
          className='no-branding'
        >
          {children || 'This field is required.'}
        </Text>
      </section>
    );
  }
);
