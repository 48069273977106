import React, { FC } from 'react';

import cn from 'classnames';

import { BLOCK_PALETTES } from '../constants';
import * as Models from '../types/models';

interface Props {
  kind: Models.BlockKind;
  isCompact?: boolean;
  className?: string;
  isDisabled?: boolean;
}

const DISABLED_COLOR = '#9fa6b2'; // gray-400

export const BlockIcon: FC<Props> = ({ isDisabled, className, kind, isCompact }) => {
  return (
    <div
      className={cn(
        'flex items-center justify-center rounded',
        { 'h-6 w-6': isCompact, 'h-10 w-10': !isCompact },
        className
      )}
      style={{ backgroundColor: isDisabled ? DISABLED_COLOR : BLOCK_PALETTES[kind] }}
    >
      <svg className={cn('h-6 w-6 text-white', isCompact && 'scale-50 transform')}>
        <use href={`#${kind}`} />
      </svg>
    </div>
  );
};
