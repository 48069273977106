import React, { forwardRef } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Button } from '@components/common';
import { ButtonProps } from '@components/common/Button';
import { Item, Menu } from '@components/common/Menu';

import { BETA_KINDS, BLOCK_KIND_LABELS } from '../constants';
import { INFO_TOOLTIPS } from '../formConstants';
import { getBlockLabel } from '../helpers/getBlockLabel';
import { useGetAvailableBlockKinds } from '../hooks/useGetAvailableBlockKinds';
import * as Models from '../types/models';

import { Block } from './Block';
import { BlockIcon } from './BlockIcon';

interface Props extends ButtonProps {
  onPickBlock?: (kind: Models.BlockKind) => void;
}

export const AddBlockButton = forwardRef<HTMLButtonElement, Props>(({ onPickBlock, ...rest }, ref) => {
  const availableBlockKinds = useGetAvailableBlockKinds();

  const { id } = useParams<{ id: string }>();

  const { data: study } = api.useGetStudyQuery(Number(id));

  return (
    <Menu
      renderTrigger={() => (
        <Button ref={ref} icon='plus' small {...rest}>
          Add block
        </Button>
      )}
      popperProps={{ placement: 'bottom-start' }}
      onItemSelect={onPickBlock}
      className='h-64 w-60 divide-y divide-gray-200 overflow-auto rounded border border-gray-200 bg-white shadow-lg'
    >
      {availableBlockKinds.map((kind: Models.BlockKind) => {
        const isDisabled = study?.device_type === 'mobile' && kind === 'card_sort';

        return (
          <Item
            tooltip={isDisabled ? 'Card Sort is not available on mobile.' : ''}
            key={kind}
            tabIndex={0}
            value={kind}
            isDisabled={isDisabled}
          >
            <Block
              tabIndex={-1}
              aria-label={`${getBlockLabel(kind)} block`}
              className={cn(isDisabled ? 'bg-gray-200' : 'hover:bg-gray-100')}
              isCompact
              infoTooltip={INFO_TOOLTIPS[kind]}
              isBeta={BETA_KINDS.includes(kind)}
              title={BLOCK_KIND_LABELS[kind]}
              icon={<BlockIcon kind={kind} isCompact isDisabled={isDisabled} />}
            />
          </Item>
        );
      })}
    </Menu>
  );
});
