import React, { forwardRef, HTMLAttributes, useMemo } from 'react';

import { Table } from '@components/shared/Table';

import { buildCategoriesTableData } from './helpers/buildCategoriesTableData';
import { getCategoriesTableColumnDefs } from './helpers/getCategoriesTableColumnDefs';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
  categories: string[];
  avgAgreements: { category: string; avgAgreement: number }[];
  setMergeModal: (value: { name: string; categories: string[] }) => void;
  setUnmergeModal: (name: string) => void;
  mergedCategories: { name: string; categories: string[] }[];
  renderMergeButton: () => JSX.Element;
}

export interface CategoriesTableData {
  category: string;
  cards: { name: string; frequency: number }[];
  avgAgreement: number;
}

export const CategoriesTable = forwardRef<HTMLDivElement, Props>(
  (
    { renderMergeButton, mergedCategories, avgAgreements, setMergeModal, setUnmergeModal, answers, categories },
    ref
  ) => {
    const columns = useMemo(
      () =>
        getCategoriesTableColumnDefs({
          mergedCategories,
          setUnmergeModal,
          setMergeModal,
          renderMergeButton
        }),
      [setMergeModal, setUnmergeModal, mergedCategories, renderMergeButton]
    );

    const data = useMemo(() => {
      return buildCategoriesTableData({ answers, mergedCategories, categories, avgAgreements });
    }, [categories, answers, avgAgreements, mergedCategories]);

    return (
      <div className='relative max-w-full overflow-auto'>
        <Table<CategoriesTableData>
          data={data}
          columns={columns}
          columnPinning={{ left: ['category'], right: ['expand'] }}
          className='w-full table-fixed border border-gray-200 bg-white'
          cellClassName='border-b border-gray-200 align-top'
        />
      </div>
    );
  }
);
