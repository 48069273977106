import React from 'react';

import cn from 'classnames';

import { Text } from 'components/common';

import { ZDS_TEXT } from '../constants';

import { NoResponsesIndividualSVG } from './NoResponsesIndividualSVG';
import { NoResponsesSummarySVG } from './NoResponsesSummarySVG';

interface Props {
  withBorders?: boolean;
  tab: 'individual' | 'summary';
  type: 'no_results' | 'no_answers';
}

export const ScreenerResponsesZDS: React.FC<React.PropsWithChildren<Props>> = ({ tab, withBorders, type }) => {
  return (
    <div
      className={cn(
        'relative flex h-full flex-col items-center justify-center bg-white p-6',
        withBorders && 'rounded-md border border-gray-200'
      )}
    >
      {tab === 'individual' ? <NoResponsesIndividualSVG /> : <NoResponsesSummarySVG />}
      <Text bold h='700' className='mb-2 mt-6 text-center'>
        {ZDS_TEXT[type].title}
      </Text>
      <Text className='max-w-xl text-center' color='gray-500'>
        {ZDS_TEXT[type].description}
      </Text>
    </div>
  );
};
