import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import { api } from '@api/reduxApi';
import { UseVideoPlayer } from '@components/VideoPlayer';
import { Miniplayer } from 'components/VideoPlayer/components/MiniPlayer';

export type MiniPlayerContext = {
  playbackId: string | null;
  setPlaybackId: (playbackId: string | null) => void;
};

const context = createContext<MiniPlayerContext>({} as any);

export const useMiniPlayerContext = () => useContext(context);

export const MiniPlayerProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [playbackId, setPlaybackId] = useState<string | null>(null);
  const [createMuxUrls] = api.useCreateMuxPlaybackUrlsMutation();
  const [src, setSrc] = useState<string>();

  const ref = useRef<UseVideoPlayer | null>(null);

  useEffect(() => {
    if (playbackId) {
      createMuxUrls({ playbackId })
        .unwrap()
        .then((urls: MuxPlaybackUrls) => {
          setSrc(urls.stream_url);
        });
    }
  }, [playbackId]);

  return (
    <context.Provider value={{ playbackId, setPlaybackId }}>
      {playbackId && <Miniplayer ref={ref} autoPlay src={src} onClose={() => setPlaybackId(null)} />}
      {children}
    </context.Provider>
  );
};
