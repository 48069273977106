import * as React from 'react';

import pluralize from 'pluralize';

import { Text } from '@components/common';
import { GuestDisplay } from '@components/shared/BookingDetailsForm/fields/Guests/GuestDisplay';

import { EmailChange } from './utils';

type Props = {
  changes: EmailChange[];
};

type GuestSectionProps = {
  guests: EmailChange[];
  message: string;
};

const GuestSection = ({ guests, message }: GuestSectionProps) => (
  <div>
    <Text as='h4' h='500' bold mb='2'>
      {guests.length} {pluralize('attendee', guests.length)} {message}
    </Text>
    <div className='flex flex-col space-y-2'>
      {guests.map((change) => (
        <GuestDisplay key={change.guest.email} guest={change.guest} />
      ))}
    </div>
  </div>
);

export const EmailChanges = ({ changes }: Props) => {
  const newGuests = changes.filter((change) => change.type === 'new');
  const updatedGuests = changes.filter((change) => change.type === 'updated');
  const cancelledGuests = changes.filter((change) => change.type === 'cancelled');

  if (changes.length === 0) return null;

  return (
    <div className='flex flex-col space-y-6'>
      <Text as='h3' h='600' bold mb='6'>
        {changes.length} {pluralize('email', changes.length)} will be sent
      </Text>

      {newGuests.length > 0 && <GuestSection guests={newGuests} message='will receive a calendar event invite' />}

      {updatedGuests.length > 0 && (
        <GuestSection guests={updatedGuests} message='will receive an updated calendar event' />
      )}

      {cancelledGuests.length > 0 && (
        <GuestSection guests={cancelledGuests} message='will receive a cancellation notice' />
      )}
    </div>
  );
};
