import * as React from 'react';

import cn from 'classnames';

import { DropdownLink } from '@components/common';

import { Role } from '../TeamUserRow';

import { MemberDropdown } from './MemberDropdown';

interface Props {
  studyRole: Role;
  isOwnerOrCreator: boolean;
  onRemove?: () => void;
  isCurrentUser?: boolean;
}

export const CanViewDropdowns: React.FC<React.PropsWithChildren<Props>> = ({
  isCurrentUser,
  studyRole,
  isOwnerOrCreator,
  onRemove
}) => {
  return isOwnerOrCreator ? (
    <div className='w-32 px-4 text-sm text-gray-700'>{studyRole}</div>
  ) : (
    <MemberDropdown studyRole={studyRole}>
      <div className={cn('px-4', isCurrentUser && 'border-b')}>
        <p className='py-2 text-sm text-gray-700'>
          To give creator or owner access, <a>upgrade role permissions</a>
        </p>

        {isCurrentUser && (
          <DropdownLink color='red-600' onClick={onRemove}>
            Leave
          </DropdownLink>
        )}
      </div>
    </MemberDropdown>
  );
};
