import React from 'react';

import { Column } from 'react-table';

import { Button, Text } from '@components/common';
import { BooleanCell } from '@components/shared/GridTable/components/inputs/BooleanCell';
import { InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { compact, humanize } from '@components/utils';

import { CellFC } from '../components/AttrsTable';

const NameCell: CellFC = ({ cell }) => (
  <div className='flex w-full flex-grow items-center overflow-hidden'>
    <Text truncate className='text-left'>
      {humanize(cell?.value.toString())}
    </Text>
    {cell.row.original?.description && (
      <Tooltip content={cell.row.original?.description}>
        <InfoCircleIcon className='ml-2 h-4 w-4 flex-shrink-0' />
      </Tooltip>
    )}
  </div>
);

const Cell: CellFC = ({ cell }) => (
  <Text truncate className='text-left'>
    {humanize(cell?.value.toString())}
  </Text>
);

const NameHeader: CellFC = ({ column }) => (
  <Text h='400' className='flex-grow text-left' bold>
    {humanize(column.id)}
  </Text>
);

const Header: CellFC = ({ column }) => (
  <Text h='400' className={`text-left ${column.id === 'label' && 'flex-grow'}`} bold>
    {humanize(column.id)}
  </Text>
);

const PiiHeader: CellFC = ({ column }) => (
  <Text h='400' className='text-left' bold>
    Is PII
  </Text>
);

type Params = {
  canEdit: boolean;
  setEdit: (v: Attr_ | null) => void;
};

export const buildColumns = ({ canEdit, setEdit }: Params): Column<Attr_>[] =>
  compact([
    {
      id: 'label',
      accessor: (originalRow) => originalRow.label,
      width: 300,
      Header: NameHeader,
      Cell: NameCell,
      sortType: (a, b, id) => a.original[id].localeCompare(b.original[id])
    },
    {
      id: 'is_pii',
      accessor: (originalRow) => originalRow.is_pii,
      Header: PiiHeader,
      Cell: BooleanCell,
      width: 120,
      sortType: (a, b, id) => {
        if (a.original[id] > b.original[id]) return -1;
        if (b.original[id] > a.original[id]) return 1;
        return 0;
      }
    },
    {
      id: 'attr_type',
      Header,
      Cell,
      accessor: (originalRow) => originalRow.attr_type,
      width: 140
    },
    canEdit && {
      id: 'extra',
      width: 100,
      Header: () => <></>,
      Cell: ({ row }) => (
        <div className='whitespace-nowrap pr-6 text-right'>
          <Button text rounded small data-testid='edit' icon='pencil' onClick={() => setEdit(row.original)} />
        </div>
      )
    }
  ]);
