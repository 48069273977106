import * as React from 'react';
import { useMemo } from 'react';

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { Text } from '@components/common';
import { CORE_ATTRS } from '@components/config';
import { useToaster } from '@components/stores/toaster';
import { without } from '@components/utils';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';

import { AddQuestionBar } from './AddQuestionBar';
import { EndRecording, RecordingZone, StartRecording } from './components';
import { QuestionCard } from './QuestionCard';
import { calculateNumber, fixedXAxisStyling, reposition } from './utils';

interface Props {
  screener: Screener;
  screener_review: ScreenerReview;
  preScreenerWithRecruitment: boolean;
  questions: ScreenerField[];
  editable: boolean;
  style: Study['style'];
  hasImgUpload: boolean;
  recordingZone?: boolean;
  onDelete: (q: ScreenerField) => void;
  onDuplicate: (q: ScreenerField) => void;
  onInsert: (index: number, q?: ScreenerField) => void;
  onDebouncedChange: (value: ScreenerField[]) => void;
  onChange: (value: ScreenerField[]) => void;
  onReplace: (id: number, question: ScreenerField) => void;
  isUpdating?: boolean;
}

export const QuestionCardList: React.FC<React.PropsWithChildren<Props>> = ({
  screener,
  screener_review,
  preScreenerWithRecruitment,
  questions,
  editable,
  recordingZone,
  style,
  hasImgUpload,
  onInsert,
  onDebouncedChange,
  onChange,
  onDelete,
  onDuplicate,
  onReplace: handleReplaceQuestion,
  isUpdating
}) => {
  const { candidateAttrs } = useCandidateAttrs();
  const showToast = useToaster();

  const attrs = useMemo(() => [...CORE_ATTRS.filter((a) => a.name != 'email'), ...candidateAttrs], [candidateAttrs]);

  function handleDebouncedChange(newQuestion: ScreenerField) {
    onDebouncedChange(
      questions.map((q) => {
        return newQuestion.id === q.id ? newQuestion : q;
      })
    );
  }

  function handleChange(newQuestion: ScreenerField) {
    onChange(
      questions.map((q) => {
        return newQuestion.id === q.id ? newQuestion : q;
      })
    );
  }

  function handleDelete(questionToBeRemoved: ScreenerField) {
    onDelete(questionToBeRemoved);
    const newListOfQuestions = reposition(without(questions, questionToBeRemoved)).map((q) => {
      if (q.next_screener_field_id === questionToBeRemoved.id) {
        q.next_screener_field_id = null;
      }
      return q;
    });
    onChange(newListOfQuestions);
  }

  const startIndex = questions.findIndex((q) => q.field_type === 'start_loom');
  const endIndex = questions.findIndex((q) => q.field_type === 'stop_loom');

  const qEls = questions.map((q, idx) => {
    const number = calculateNumber(questions, idx);

    const isRecorded = idx > startIndex && idx < endIndex;
    const isControl = ['start_loom', 'stop_loom'].includes(q.field_type);
    return (
      <React.Fragment key={q.id}>
        {q.field_type == 'start_loom' && (
          <Draggable index={idx} draggableId={String(q.id)}>
            {(provided, snapshot) => {
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={fixedXAxisStyling(provided, snapshot)}
                >
                  <StartRecording />
                </div>
              );
            }}
          </Draggable>
        )}
        {!isControl && (
          <>
            <RecordingZone show={!!(recordingZone && isRecorded)}>
              <Draggable draggableId={String(q.id)} index={idx} isDragDisabled={!editable}>
                {(provided) => (
                  <div className='py-2' ref={provided.innerRef} {...provided.draggableProps}>
                    <QuestionCard
                      isUpdating={isUpdating}
                      screener={screener}
                      screener_review={screener_review}
                      questions={questions}
                      disableImgUpload={!hasImgUpload}
                      editable={editable}
                      question={q}
                      attrs={attrs}
                      style={style}
                      number={number}
                      dragHandleProps={provided.dragHandleProps}
                      debouncedChange={handleDebouncedChange}
                      onChange={handleChange}
                      onDuplicate={() => onDuplicate(q)}
                      onDelete={() => handleDelete(q)}
                      onReplace={handleReplaceQuestion}
                    />
                  </div>
                )}
              </Draggable>
            </RecordingZone>
            {idx !== questions.length - 1 && editable && (
              <RecordingZone show={isRecorded}>
                <AddQuestionBar onlyShowOnHover onClick={() => onInsert(idx + 1)} disabled={isUpdating} />
              </RecordingZone>
            )}
          </>
        )}
        {q.field_type === 'stop_loom' && (
          <Draggable index={idx} draggableId={String(q.id)}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={fixedXAxisStyling(provided, snapshot)}
              >
                <EndRecording />
              </div>
            )}
          </Draggable>
        )}
      </React.Fragment>
    );
  });

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    const isMovingStart = result.source.index === startIndex;
    const isMovingEnd = result.source.index === endIndex;
    if (
      (isMovingStart && result.destination.index >= endIndex - 1) ||
      (isMovingEnd && result.destination.index <= startIndex + 1)
    ) {
      showToast({
        icon: 'error',
        heading: 'Must record at least one question!',
        text: 'The recording zone must contain at least one question.'
      });
      return;
    }

    const newQuestions = [...questions];
    const [removed] = newQuestions.splice(result.source.index, 1);
    -newQuestions.splice(result.destination.index, 0, removed);

    onChange(
      newQuestions.map((question, idx) => ({
        ...question,
        position: idx + 1
      }))
    );
  }

  const helperText = preScreenerWithRecruitment
    ? 'Please enter at least 2 questions and 1 ideal answer.'
    : 'Please enter at least 1 question.';

  return (
    <>
      <Text bold className='mb-2'>
        Questions
      </Text>
      <Text h='400' className='mb-2'>
        {helperText}
      </Text>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='list'>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {qEls}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
