import React, { useCallback, useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { Button, SlideOut } from '@components/common';
import { SearchInput } from '@components/GQSurveyBuilder/QuestionBank/components';
import { TextEditorWrapper } from '@components/shared/TextEditorWrapper';
import { useTiptapFromDocumentId } from '@components/shared/Tiptap';
import { useCreateDocumentMutation, useGetDocumentQuery } from '@components/shared/Tiptap/api';
import { useToaster } from '@components/stores/toaster';
import { TemplateCard } from '@components/TemplatesApp/components/TemplateCard';
import { usePermission } from '@hooks/usePermission';
import { useSearch } from '@hooks/useSearch';

interface Props {
  study: Study;
}

export const InterviewGuide: React.FC<React.PropsWithChildren<Props>> = ({ study }) => {
  const [open, setOpen] = useState(false);
  const showToast = useToaster();

  const { data: interviewTemplates = [], isLoading } = api.useGetInterviewTemplatesQuery();
  const [updateStudy] = api.useUpdateStudyMutation();
  const [createDocument] = useCreateDocumentMutation();

  const canEdit = usePermission<Study>('updateStudy')(study);

  const [chosenTemplate, setChosenTemplate] = useState<Pick<InterviewTemplate, 'title' | 'document_id'> | null>();
  const [search, setSearch] = useState('');

  const { results: templates } = useSearch<InterviewTemplate>(interviewTemplates, ['title'], search, [
    interviewTemplates
  ]);

  const { data: templateDocument, isLoading: documentLoading } = useGetDocumentQuery(
    chosenTemplate?.document_id as number,
    {
      skip: !chosenTemplate?.document_id
    }
  );

  const onEditorUpdate = useCallback(() => {
    if (chosenTemplate) {
      setChosenTemplate(null);
      updateStudy({ id: study.id, template_study_id: null });
    }
  }, [chosenTemplate]);

  const tiptap = useTiptapFromDocumentId({
    readonly: !canEdit,
    documentId: study?.custom_interview_script_document_id as number,
    scrollToTop: false,
    editorClass: 'h-full flex flex-col',
    onEditorUpdate,
    config: {
      image: { enable: true },
      link: { enable: true },
      highlight: { enable: false },
      artifacts: false,
      templates: false,
      placeholder:
        'Use this space to craft your questions, plan, or just a cheat sheet to use during interviews. Choose a template or start typing...'
    }
  });

  useEffect(() => {
    if (templateDocument?.doc) {
      tiptap?.editor?.commands.loadContent(templateDocument?.doc);
    }
  }, [templateDocument?.doc]);

  const chooseTemplate = (template: InterviewTemplate) => {
    setOpen(false);
    setChosenTemplate(template);
    updateStudy({ ...study, template_study_id: template.id });
  };

  const newDocument = async () => {
    try {
      const document = await createDocument().unwrap();

      updateStudy({ id: study.id, custom_interview_script_document_id: document.id });

      if (!study.template_study_id) {
        setChosenTemplate({
          title: 'Choose a template or start typing',
          document_id: document.id
        });
      }
    } catch {
      showToast({
        heading: 'Could not create a new document.',
        text: 'Something went wrong. Please try again or contact support.',
        icon: 'error'
      });
    }
  };

  useEffect(() => {
    if (!study.custom_interview_script_document_id) {
      newDocument();
    }
  }, [study.custom_interview_script_document_id]);

  useEffect(() => {
    if (study.template_study_id && interviewTemplates) {
      const template = interviewTemplates.find((t) => t.id === study.template_study_id);

      if (template) {
        chooseTemplate(template);
      }
    }
  }, [interviewTemplates, study.template_study_id]);

  return (
    <>
      <TextEditorWrapper
        renderButtons={() =>
          canEdit ? (
            <Button medium icon='template' data-testid='templates' onClick={() => setOpen(!open)}>
              Templates
            </Button>
          ) : null
        }
        isLoading={isLoading || documentLoading}
        title='Interview guide'
        subtitle='Craft the questions that you want to ask in your interviews.'
        tiptap={tiptap}
      />
      {open && (
        <SlideOut size='md' nonBlocking onClose={() => setOpen(false)} zIndex='50' title='Interview templates'>
          <SearchInput onChange={setSearch} value={search} />
          <div className='space-y-4 overflow-auto p-6 pt-0'>
            {templates?.map((t, i) => (
              <TemplateCard<InterviewTemplate>
                allowUpdate={false}
                className='m-3'
                template={t}
                key={i}
                kind='InterviewTemplate'
                useTemplateButton
                onTemplateUse={chooseTemplate}
              />
            ))}
          </div>
        </SlideOut>
      )}
    </>
  );
};
