// libs
import * as React from 'react';

import cn from 'classnames';

// components
import { BooleanInput } from './BooleanInput';
import { DateInput } from './DateInput';
import { MultipleChoiceInput } from './MultipleChoiceInput';
import { NumberInput } from './NumberInput';
import { SingleChoiceInput } from './SingleChoiceInput';
import { TextAreaInput } from './TextAreaInput';
import { TextInput } from './TextInput';
import { UrlInput } from './UrlInput';

export const NO_OUTLINE = { outline: 'none', outlineOffset: 'none', boxShadow: 'none', border: 'none' };

type GetWrapperClass = (styles?: StyleTypeValues, focused?: boolean, errorMessage?: boolean) => string;

export const getWrapperClass: GetWrapperClass = (styles, focused, errorMessage) =>
  cn(`group ${styles?.row} border-b w-full`, {
    'border-indigo-600': focused && !errorMessage,
    'border-gray-200': !focused && !errorMessage,
    'border-red-600': errorMessage
  });

export const INPUTS: {
  [key in Attr_['attr_type'] | 'default']: React.FC<React.PropsWithChildren<CandidateAttrInput<unknown>>>;
} = {
  free_text: TextAreaInput,
  multiple_choice: MultipleChoiceInput,
  text: SingleChoiceInput,
  datetime: DateInput,
  boolean: BooleanInput,
  number: NumberInput,
  url: UrlInput,
  default: TextInput
};
