import React from 'react';

import { Basic } from '@components/shared/Skeleton';

export const FooterSkeleton = () => {
  return (
    <div className='flex flex-nowrap items-center justify-end space-x-3'>
      <Basic width='95px' />
      <Basic width='85px' />
      <Basic width='170px' />
      <Basic width='50px' />
    </div>
  );
};
