import * as React from 'react';

import cn from 'classnames';

import { Text } from '@components/common';

import { UseTableFilters } from '../hook/useTableFilters';

import { AddFilterButton } from './AddFilterButton';
import { Filter } from './Filter';

type Props<T extends object> = {
  hook: UseTableFilters<T>;
  editable?: boolean;
  advancedLimits?: boolean;
  defaultShowInput?: boolean;
  canRemove?: boolean;
  isError?: boolean;
};

export const TableFilters = <T extends object>({
  hook,
  editable,
  advancedLimits,
  defaultShowInput,
  canRemove = true,
  isError
}: Props<T>): React.ReactElement => {
  const wrapperClass = editable ? 'flex flex-col items-start' : 'flex items-center space-x-2.5 -my-2 flex-wrap';
  const canAddFilter = hook.filters.length === 0 || canRemove;
  return (
    <div className={wrapperClass}>
      {hook.filters.map((state, i) => (
        <div className='flex items-center space-x-2' key={state.id}>
          <Filter
            editable={editable}
            className='my-2'
            state={state}
            onClickRemove={() => hook.removeFilter(state.id)}
            onChange={(s) => hook.changeFilterState(state.id, s)}
            hasNoPopup={state.definition.type === 'segment'}
            open={state.id === hook.openFilter}
            setOpen={(o) => hook.setOpenFilter(o ? state.id : null)}
          />
          {i !== hook.definitions.length - 1 && canRemove && (
            <Text h='400' color='gray-500'>
              {hook.op === 'all' ? 'and' : 'or'}
            </Text>
          )}
        </div>
      ))}
      {canAddFilter && (
        <AddFilterButton
          className={cn(!advancedLimits && 'my-2', isError && 'border-red-600')}
          hook={hook}
          advancedLimits={advancedLimits}
          defaultShowInput={defaultShowInput}
        />
      )}
    </div>
  );
};
