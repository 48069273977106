import React from 'react';

import { Text, Toggle } from '@components/common';

import { useUpdateAiSettingsMutation } from './api';

interface AiToggleSettingProps {
  setting: 'auto_highlights' | 'auto_tags';
  name: string;
  children: React.ReactNode;
  isLoading: boolean;
  isEnabled: boolean | undefined; // Directly use this prop to control the toggle state
  onUpdate: () => void;
}

export const AiToggleSetting: React.FC<React.PropsWithChildren<AiToggleSettingProps>> = ({
  setting,
  name,
  children,
  isLoading,
  isEnabled,
  onUpdate
}) => {
  const [updateAiSettings] = useUpdateAiSettingsMutation();

  const handleToggle = async (enabled: boolean) => {
    const settingsUpdate = { [setting]: enabled };
    await updateAiSettings(settingsUpdate);
    onUpdate();
  };

  return (
    <div className='mt-4 flex flex-row space-x-4'>
      <Toggle on={isEnabled} disabled={isLoading} onToggle={handleToggle} />
      <div>
        <Text h='400' bold>
          {name}
        </Text>
        <div className='text-sm'>{children}</div>
      </div>
    </div>
  );
};
