import React, { SVGProps } from 'react';

export const SummaryChartSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3313_29200)'>
      <path
        d='M15.5 14.1667C15.5 14.9 14.9 15.5 14.1667 15.5H1.83333C1.1 15.5 0.5 14.9 0.5 14.1667V1.83333C0.5 1.1 1.1 0.5 1.83333 0.5H14.1667C14.9 0.5 15.5 1.1 15.5 1.83333V14.1667Z'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.96643 12.2999C4.96643 12.6999 4.69976 12.9666 4.29976 12.9666H3.6331C3.2331 12.9666 2.96643 12.6999 2.96643 12.2999V10.4333C2.96643 10.0333 3.2331 9.7666 3.6331 9.7666H4.29976C4.69976 9.7666 4.96643 10.0333 4.96643 10.4333V12.2999Z'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.9668 12.2999C8.9668 12.6999 8.70013 12.9666 8.30013 12.9666H7.63346C7.23346 12.9666 6.9668 12.6999 6.9668 12.2999V4.43327C6.9668 4.03327 7.23346 3.7666 7.63346 3.7666H8.30013C8.70013 3.7666 8.9668 4.03327 8.9668 4.43327V12.2999Z'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.9668 12.2996C12.9668 12.6996 12.7001 12.9663 12.3001 12.9663H11.6335C11.2335 12.9663 10.9668 12.6996 10.9668 12.2996V7.76628C10.9668 7.36628 11.2335 7.09961 11.6335 7.09961H12.3001C12.7001 7.09961 12.9668 7.36628 12.9668 7.76628V12.2996Z'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_3313_29200'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
