import * as React from 'react';

import { DropdownLink, Text } from '@components/common';
import { getConnectTeamsButtonProps } from '@components/getConnectTeamsButtonProps';
import { getConnectWebexButtonProps } from '@components/getConnectWebexButtonProps';
import { getConnectZoomButtonProps } from '@components/getConnectZoomButtonProps';
import { RestrictedAction } from '@components/shared/RestrictedAction';
import { Tooltip } from '@components/Tooltip';

import { MeetingProviderIcon } from './MeetingProviderIcon';

interface MeetingProviderProps {
  option: MeetingProvider;
  onClick: (e: any) => void;
  isOwner: boolean;
}

export const MeetingProviderOption: React.FC<React.PropsWithChildren<MeetingProviderProps>> = ({
  option,
  onClick,
  isOwner
}) => {
  const getAdditionalProps = () => {
    if (option.name === 'Microsoft Teams' && option.connected_account_id) {
      if (!option.connected) {
        return getConnectTeamsButtonProps(option.connected_account_id);
      }
    } else if (option.name === 'Zoom' && !option.connected && isOwner) {
      return getConnectZoomButtonProps();
    } else if (option.name === 'Webex' && !option.connected) {
      return getConnectWebexButtonProps();
    }
  };

  const content = (disabled: boolean) => (
    <DropdownLink
      disabled={disabled}
      key={option.video_url_type}
      aria-label={option.video_url_type}
      data-testid={option.video_url_type}
      onClick={onClick}
      className='flex w-full items-center'
      {...getAdditionalProps()}
    >
      <MeetingProviderIcon option={option} />

      <Text h='400' className='flex-1'>
        {option.name}
      </Text>
      {!option.connected && (
        <Text h='400' color='indigo-600'>
          Connect
        </Text>
      )}
    </DropdownLink>
  );

  if (option.name === 'In Person') {
    return (
      <RestrictedAction feature='in_person' tipPlacement='right'>
        {({ may }) => content(!may)}
      </RestrictedAction>
    );
  } else if (option.name !== 'In Person') {
    return (
      <Tooltip className='w-full' isDisabled={option.tooltip === ''} content={option.tooltip}>
        {content(option.tooltip !== '' && !option.connected)}
      </Tooltip>
    );
  } else {
    return null;
  }
};
