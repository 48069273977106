import * as React from 'react';

import { Text, TimeSelect } from '@components/common';

import { SCHEDULING_INCREMENT } from '../../../constants';
import { getTime } from '../../NylasCalendar/utils';

import { useTimeSelect } from './hooks/useTimeSelect';

interface Props {
  onChange: (params: Partial<SlotInstance>) => void;
  slot: SlotInstance;
  startHour: number;
  endHour: number;
  disabled?: boolean;
}

export const AvailabilitySlot: React.FC<React.PropsWithChildren<Props>> = ({
  disabled,
  slot,
  onChange,
  startHour,
  endHour
}) => {
  const {
    onChangeStartTime,
    onChangeEndTime,
    error,
    currentStartMin,
    currentStartHour,
    currentEndMin,
    currentEndHour
  } = useTimeSelect({ slot, onChange });

  return (
    <div>
      <div className='tablet:space-x-1.5 flex items-center space-x-0.5'>
        <TimeSelect
          disabled={disabled}
          minHour={0}
          maxHour={24}
          increment={SCHEDULING_INCREMENT}
          initialValue={{ hour: currentStartHour, min: currentStartMin }}
          onChange={(time) => onChangeStartTime(getTime(time))}
          label='start time'
          inputClassName='h-8'
          inputWidth='20'
        />
        <span className='self-center text-sm text-gray-700'>-</span>
        <TimeSelect
          disabled={disabled}
          minHour={0}
          maxHour={24}
          increment={SCHEDULING_INCREMENT}
          initialValue={{ hour: currentEndHour, min: currentEndMin }}
          onChange={(time) => onChangeEndTime(getTime(time))}
          label='end time'
          addOneMoreOption
          inputClassName='h-8'
          inputWidth='20'
        />
      </div>
      {error && (
        <Text color='red-600' h='200' className='mt-px w-full text-center'>
          Start time must be before end time
        </Text>
      )}
    </div>
  );
};
