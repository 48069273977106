import * as React from 'react';
import { useContext, useState } from 'react';

import Tippy from '@tippyjs/react';

import { GridContext } from '@components/CandidatesApp/CandidatesIndex/CandidatesListPage';
import { Button, Modal, Textarea } from '@components/common';
import { EditableCell } from '@components/shared/GridTable/components/inputs/types';
import { CellWrapper } from '@components/shared/GridTable/components/shared';
import { getStateKey, onSelectValue } from '@components/shared/GridTable/components/utils';
import { useOnEscape } from '@components/utils';
import { useEditCandidateAttr } from '@hooks/useEditCandidateAttr';
import { Portal } from 'components/Portal';

export const FreeTextCell: EditableCell = (props) => {
  const { state, setState } = useContext(GridContext);

  const { value: initialValue, row, column, updateCellData } = props;

  const stateKey = getStateKey(row);

  const [value, setValue] = useState<string>(initialValue);
  const [editing, setEditing] = useState(false);

  const { canEdit, attr } = useEditCandidateAttr({ attrName: column.id });

  const cellValue = value || '';
  const previousValue = state?.[stateKey]?.[column?.id] || initialValue || '';

  const startEditing = () => {
    if (!canEdit) return;

    setEditing(true);
  };

  const cancel = () => {
    setValue(previousValue);
    setEditing(false);
  };

  useOnEscape(() => {
    if (editing) {
      cancel();
    }
  }, [editing]);

  const save = async () => {
    setValue(value);
    setEditing(false);

    await onSelectValue({
      currentValue: value,
      initialValue,
      rowId: String(stateKey),
      setState,
      updateCellData,
      columnId: column.id
    });
  };

  const tippyContent = cellValue.split('\n').map((v, i) => <p key={v + i}>{v}</p>);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editing) {
    return (
      <CellWrapper
        wrapperClass='cursor-pointer hover:bg-indigo-50 hover:text-indigo-600 overflow-hidden'
        position='start'
        onClick={() => startEditing()}
      >
        <Tippy content={tippyContent}>
          <span>{cellValue}</span>
        </Tippy>
      </CellWrapper>
    );
  }

  return (
    <>
      <Portal target={document.getElementById('app-main')}>
        <Modal
          title={`Edit ${attr?.name} for ${row.original.participation?.customer?.name || row.original.name}`}
          size='md'
          onClose={cancel}
          renderFooter={() => (
            <>
              <Button onClick={cancel}>Cancel</Button>
              <Button primary onClick={save}>
                Save
              </Button>
            </>
          )}
        >
          <Textarea value={cellValue} onChange={setValue} />
        </Modal>
      </Portal>
    </>
  );
};
