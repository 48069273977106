import { useCallback, useMemo } from 'react';

import pluralize from 'pluralize';

interface Status {
  defaultVisible: boolean;
  key: Participation['status'];
  position: number;
}

const STATUS_TOOLTIP: Record<Participation['status'], string> = {
  shortlisted: "Participants you're considering to invite, but haven't been contacted yet.",
  applied: 'Participants who submitted a screener for this study.',
  invited: 'Participants who were invited to apply for this study.',
  requested: 'Participants who were invited to participate in this study.',
  booked: 'Participants who scheduled an interview.',
  started: 'Participants who have begun the assigned task.',
  completed: 'Participants who completed the study.',
  canceled: 'Participation has been canceled by the participant or the researcher.',
  no_show: "Participants who were scheduled but didn't show up",
  rejected: 'Participants who were removed from this study.',
  opted_out: 'Participants who opted-out of the study.',
  // not sure why we need these 2 on the frontend
  approved: '',
  fresh: ''
};

export const STATUS_LABEL: Record<Participation['status'], string> = {
  shortlisted: 'Shortlisted',
  applied: 'Applied',
  invited: 'Sent screener',
  requested: 'Invited',
  booked: 'Scheduled',
  started: 'Started',
  completed: 'Completed',
  canceled: 'Canceled',
  no_show: 'No shows',
  rejected: 'Removed',
  opted_out: 'Opted out',
  // not sure why we need these 2 on the frontend
  approved: '',
  fresh: ''
};

const STATUS: Status[] = [
  { key: 'shortlisted', position: 1, defaultVisible: true },
  { key: 'invited', position: 2, defaultVisible: false },
  { key: 'applied', position: 3, defaultVisible: false },
  { key: 'requested', position: 4, defaultVisible: true },
  { key: 'booked', position: 5, defaultVisible: false },
  { key: 'started', position: 6, defaultVisible: false },
  { key: 'completed', position: 7, defaultVisible: true },
  { key: 'canceled', position: 8, defaultVisible: true },
  { key: 'no_show', position: 9, defaultVisible: false },
  { key: 'rejected', position: 10, defaultVisible: true },
  { key: 'opted_out', position: 11, defaultVisible: true }
];

export interface Result {
  label: string;
  status: Participation['status'];
  position: number;
  tooltip: string;
}

export const useParticipationsStatus = (
  study: Study,
  participationStatusesCounts?: ParticipationStatusesCounts
): Result[] => {
  const getLabel = useCallback(
    (key: Participation['status']) => {
      let label = STATUS_LABEL[key];

      if (participationStatusesCounts) {
        const count = participationStatusesCounts[key];

        label += ` (${count ?? 0})`;
      }

      return label;
    },
    [participationStatusesCounts]
  );

  const getTooltip = useCallback(
    (key: Participation['status']) => {
      const tooltip = STATUS_TOOLTIP[key];
      if (key !== 'started' || !study.maximum_as_started_in_minutes) return tooltip;

      if (study.maximum_as_started_in_minutes >= 60) {
        return `${tooltip} They will move back to Invited after ${pluralize('hour', Math.floor(study.maximum_as_started_in_minutes / 60), true)}.`;
      } else {
        return `${tooltip} They will move back to Invited after ${pluralize('minute', study.maximum_as_started_in_minutes, true)}.`;
      }
    },
    [study.maximum_as_started_in_minutes]
  );

  const status = useMemo(
    () =>
      STATUS.reduce<Result[]>((acc, { key, defaultVisible, position }) => {
        const result: Result = { label: getLabel(key), status: key, tooltip: getTooltip(key), position };

        if (defaultVisible) {
          return [...acc, result];
        }

        switch (key) {
          case 'applied':
          case 'invited': {
            if (study.pre_screener) {
              return [...acc, result];
            }

            break;
          }

          case 'booked':
          case 'no_show': {
            if (study.style === 'video_call') {
              return [...acc, result];
            }

            break;
          }

          case 'started': {
            if (study.style !== 'video_call') {
              return [...acc, result];
            }

            break;
          }
        }

        return acc;
      }, []),
    [participationStatusesCounts, study]
  );

  return status.sort((a, b) => a.position - b.position);
};
