import * as React from 'react';
import { useRef, useState } from 'react';

import { DropdownLink } from '@components/common';
import { SolidKebabSVG, TrashSVG } from '@components/svgs';
import { useOnClickOutside } from '@components/utils';
import { usePermission } from '@hooks/usePermission';

import { DeleteHighlightReelModal } from './DeleteHighlightReelModal';

type Props = {
  className?: string;
  highlightReel: HighlightReel;
  onRemoveHighlightReel: () => void;
};

export const OptionsDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  highlightReel,
  onRemoveHighlightReel
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const closeDropdown = () => setDropdownOpen(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, closeDropdown);

  const canDelete = usePermission('canCreate')();

  const onDeleteClick = () => {
    setDeleteModalOpen(true);
    closeDropdown();
  };

  return (
    <>
      <DeleteHighlightReelModal
        token={highlightReel.token}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onSubmit={() => {
          onRemoveHighlightReel();
          closeDropdown();
        }}
      />

      <button
        onClick={toggleDropdown}
        className={[className, 'focus:outline-none'].join(' ')}
        name='more'
        aria-label='more'
      >
        <SolidKebabSVG className='h-4 w-4' />
        <div ref={dropdownRef} className='relative whitespace-nowrap text-left'>
          {dropdownOpen && (
            <div className='absolute right-0 top-5 z-10 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5'>
              {canDelete && (
                <DropdownLink
                  className='flex items-center'
                  color='red-600'
                  hoverColor='red-700'
                  onClick={onDeleteClick}
                >
                  <TrashSVG className='mr-2' />
                  Delete highlight reel…
                </DropdownLink>
              )}
              {children}
            </div>
          )}
        </div>
      </button>
    </>
  );
};
