import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button, Modal, Text } from '@components/common';
import { track } from '@components/tracking';
import { useLocalStorage } from '@hooks/useLocalStorage';

export const WelcomeModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [seen, setSeen] = useLocalStorage<boolean>('new-csv-import-welcome-modal');
  const [open, setOpen] = useState<boolean | null>(!seen);

  useEffect(() => {
    track('viewed_new_csv_import_modal');
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSeen(true);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      size='md'
      title='Streamlining the CSV import experience'
      subtitle='We have improved the CSV import, making it simpler, faster and clearer.'
      onClose={handleClose}
    >
      <img
        width={450}
        src='/temp-assets/csv-import-announcement.png'
        alt='Screenshot of the new CSV import flow'
        className='mb-4 w-full'
      />

      <div className='mb-6'>
        <Text bold mb='4'>
          Key changes:
        </Text>
        <ul>
          <li className='ml-6 list-disc'>
            <strong>Reduced steps:</strong> simplified from 4 to 1 dynamic step
          </li>
          <li className='ml-6 list-disc'>
            <strong>Improved layout:</strong> changed from horizontal to vertical
          </li>
        </ul>
      </div>
      <div className='flex justify-end'>
        <Button primary onClick={handleClose}>
          Continue with import
        </Button>
      </div>
    </Modal>
  );
};
