import React, { useEffect, useState } from 'react';

import { format as timeagoFormat } from 'timeago.js';

import { api } from '@api/reduxApi';
import { Alert, AlertHeading, AlertMessage, Button, Modal } from '@components/common';
import { useRecordSync } from '@hooks/useRecordSync';
import { MessagingRequestsChannel } from '@hooks/useWebsocket';

import { MessageRequestProps } from '../types';

import { BatchingSVG } from './svgs';

const ACTIVE_STATUSES = ['pending', 'sending'];

export const Banner: React.FC<React.PropsWithChildren<MessageRequestProps>> = ({
  study,
  messageRequest,
  canAction,
  onSent
}) => {
  const [cancelMessageRequest, { isSuccess, isError }] = api.useCancelMessageRequestMutation();

  const [cancelled, setCancelled] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const syncedMessageRequest = useRecordSync<MessageRequest>(messageRequest, MessagingRequestsChannel);

  const handleAction = () => {
    cancelMessageRequest({ studyId: study.id, id: syncedMessageRequest.id });
    setShowModal(false);
    setCancelled(true);
  };

  let alertMessage = `We're currently sending invitations to shortlisted candidates `;

  if (syncedMessageRequest?.multiple_batches) {
    alertMessage = `We're currently sending invitations in batches to shortlisted candidates. `;

    if (syncedMessageRequest?.next_batch_at) {
      alertMessage += `Next batch of ${syncedMessageRequest.next_batch_size} will go out in ${timeagoFormat(
        syncedMessageRequest.next_batch_at
      )}.`;
    }
  }

  useEffect(() => {
    if (syncedMessageRequest.status === 'sent' && onSent) {
      onSent();
    }
  }, [syncedMessageRequest.status]);

  if (cancelled || !ACTIVE_STATUSES.includes(syncedMessageRequest.status)) {
    return <></>;
  }

  return (
    <>
      <Alert
        className='my-4'
        Icon={BatchingSVG}
        type='success'
        cta={canAction && <Button onClick={() => setShowModal(true)}>{'Cancel unsent invites'}</Button>}
      >
        <AlertHeading>Screener invitations in progress</AlertHeading>
        <AlertMessage>{alertMessage}</AlertMessage>
      </Alert>
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          title='Confirm cancellation'
          renderFooter={() => (
            <div className='flex w-full justify-end space-x-6'>
              <Button onClick={() => setShowModal(false)}>Dismiss</Button>
              <Button primary onClick={handleAction}>
                Confirm{' '}
              </Button>
            </div>
          )}
        >
          Are you sure you want to cancel any unsent invites?
        </Modal>
      )}
    </>
  );
};
