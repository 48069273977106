import { IframeHTMLAttributes } from 'react';

export enum MessageType {
  INITIAL_LOAD = 'INITIAL_LOAD',
  MOUSE_PRESS_OR_RELEASE = 'MOUSE_PRESS_OR_RELEASE',
  PRESENTED_NODE_CHANGED = 'PRESENTED_NODE_CHANGED'
}

export enum EventType {
  RESTART = 'RESTART',
  NAVIGATE_FORWARD = 'NAVIGATE_FORWARD',
  NAVIGATE_BACKWARD = 'NAVIGATE_BACKWARD'
}

export enum Scaling {
  minZoom = 'min-zoom',
  scaleDown = 'scale-down',
  scaleDownWidth = 'scale-down-width',
  contain = 'contain'
}

export interface IframeProps extends IframeHTMLAttributes<HTMLIFrameElement> {
  disableDefaultKeyboardNav?: boolean;
  hideHotspots?: boolean;
  hideUi?: boolean;
  nodeId?: string;
  scaling?: `${Scaling}`;
  onPrototypeInitialLoad?: () => void;
  onPrototypeMousePress?: (data: MessageEvent['data']) => void;
  onPrototypePresentedNodeChanged?: (data: MessageEvent['data']) => void;
  onPrototypeFirstEvent?: () => void;
}

export interface MessageEvent {
  data: {
    handled?: boolean;
    presentedNodeId: string;
    targetNodeId?: string;
    targetNodeMousePosition?: {
      x: number;
      y: number;
    };
    nearestScrollingFrameId?: string;
    nearestScrollingFrameMousePosition?: {
      x: number;
      y: number;
    };
    nearestScrollingFrameOffset?: {
      x: number;
      y: number;
    };
    isStoredInHistory?: boolean;
    stateMappings?: Record<string, unknown>;
  };
  type: Exclude<MessageType, MessageType.INITIAL_LOAD>;
}

export interface InitialLoadMessageEvent {
  data: Record<string, never>;
  type: MessageType.INITIAL_LOAD;
}
