// libs
import * as React from 'react';

import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { format } from 'timeago.js';

// components
import { AvatarFromId, Text } from '@components/common';
import { useRenderAvatars } from '@components/RepositoryApp/hooks/useRenderAvatars';
// hooks
import { useRenderTagsList } from '@components/RepositoryApp/hooks/useRenderTagsList';
import { VideoCameraSVG } from '@components/svgs';
import { getBackgroundColor } from '@components/tags/colors';

interface Props {
  loading?: boolean;
  className?: string;
  interactable?: boolean;
  highlight: DocumentHighlight;
  fullWidth?: boolean;
}

const MIN_CARD_HEIGHT = '16rem';

const getOpacity = (color: string) => (color === 'default' ? 1 : 0.25);

function getCSSStyle(color: string): React.CSSProperties {
  const rgba = getBackgroundColor(color, getOpacity(color));
  return { backgroundColor: rgba };
}

export const HighlightCard: React.FC<React.PropsWithChildren<Props>> = ({
  loading,
  fullWidth,
  className,
  interactable,
  children,
  highlight
}) => {
  const { renderTagsList } = useRenderTagsList(); // this usage is deprecated
  const { renderAvatars } = useRenderAvatars();

  const cardClass = cn(className, 'flex flex-col bg-white border border-gray-200 rounded-md w-full', {
    'tablet:w-128': !fullWidth,
    'hover:bg-gray-50 hover:border-indigo-600': interactable
  });

  return (
    <div className={cardClass} style={{ minHeight: MIN_CARD_HEIGHT }}>
      <div className='mb-4 h-40 p-4' style={highlight ? getCSSStyle(highlight.color) : {}}>
        {!loading ? (
          <Text h='400' className='line-clamp-7 text-gray-700'>
            {children}
          </Text>
        ) : (
          <Skeleton className='bg-gray-50' count={2} />
        )}
      </div>

      <section className='flex flex-1 flex-col px-4 pb-4'>
        {!loading ? (
          <>
            <div className='flex flex-row items-center'>
              <VideoCameraSVG className='mr-2' />
              <Text h='400' as='h5' className='items-center truncate font-semibold normal-case text-gray-700'>
                {highlight.document_title || 'Untitled'}
              </Text>
            </div>

            <section className='mt-auto flex flex-wrap justify-between overflow-hidden'>
              <span className='flex flex-row items-center space-x-2'>
                {renderAvatars([highlight.creator_id])}
                <Text h='200' color='gray-500'>
                  {' '}
                  {format(new Date(highlight.created_at))}{' '}
                </Text>
              </span>
              {renderTagsList(highlight.tag_ids, { max: 1 })}
            </section>
          </>
        ) : (
          <Skeleton className='bg-gray-50' count={2} />
        )}
      </section>
    </div>
  );
};
