import * as React from 'react';

import { Tooltip } from '@components/Tooltip';

import { Text } from '../Text';

export const Stat: React.FC<React.PropsWithChildren<{ title: string; tip: string }>> = ({ title, tip, children }) => {
  return (
    <div className='mr-2 flex flex-1 items-center space-x-2 py-3'>
      <Text h='400' bold>
        {children === 0 ? '-' : children}
      </Text>
      <div className='flex cursor-pointer flex-row items-center space-x-2'>
        <Tooltip isDisabled={!tip} content={tip}>
          <Text h='400' color='gray-500'>
            {title}
          </Text>
        </Tooltip>
      </div>
    </div>
  );
};
