import React, { useEffect } from 'react';

import pluralize from 'pluralize';

import { api } from '@api/reduxApi';
import { Alert, Loading, SlideOut } from '@components/common';
import { useParticipantsCounts } from '@components/StudyMessages/hooks/useParticipantsCounts';
import { usePlan } from '@hooks/usePlan';
import { useToaster } from '@stores/toaster';

import { StudyMessageSlideOut } from './StudyMessageSlideOut';
import { failedSendScreener, successSendScreener } from './toasts';
import { SendMessageParams } from './types';

interface Props {
  study: Study;
  participations: Participation[];
  resend?: boolean;
  onSuccess: (backgroundTask: BackgroundTask) => void;
  onClose: () => void;
  allSelected?: boolean;
  query?: ServerFilterQuery;
  selectedCandidateIds?: number[];
  selectedParticipationIds?: number[];
  previewParticipation?: Participation;
}

export const ParticipationsScreenerSlideOutServerSide: React.FC<React.PropsWithChildren<Props>> = ({
  allSelected,
  query,
  selectedParticipationIds,
  selectedCandidateIds,
  previewParticipation,
  study,
  participations,
  onClose,
  onSuccess
}) => {
  const showToast = useToaster();
  const { hasQuota, getQuota } = usePlan();
  const communicationMethod = study?.comms_medium || 'email';
  const [getData, { data, isSuccess, isLoading }] = api.useGetInviteSlideOutIdsMutation();
  const [participationBulkScreenerInvite, { isLoading: isSending }] = api.useParticipationBulkScreenerInviteMutation();

  const {
    invitableCount: invitable,
    ineligibleCount: ineligible,
    reinviteableCount: reinviteable,
    contactableCount: contactable,
    isLoading: countsLoading
  } = useParticipantsCounts({
    studyId: study.id,
    query,
    ids: selectedParticipationIds
  });

  useEffect(() => {
    getData({
      participationsIds: participations.map((p) => p.id),
      communicationMethod
    });
  }, []);

  const screener = study.pre_screener;

  async function handleSend({ batch, excludeIneligible, message, sender }: SendMessageParams) {
    if (!screener) {
      showToast(failedSendScreener());
      return;
    }

    try {
      const batch_params = {
        batch_on: batch.on,
        batch_size: batch.size,
        batch_wait_hours: batch.wait_hours,
        batch_target: batch.target
      };

      if (query && query.count && excludeIneligible) {
        query.count -= ineligible;
      }

      const backgroundTask: BackgroundTask = await participationBulkScreenerInvite({
        batch: batch_params,
        studyId: study.id,
        exclude_ineligible: excludeIneligible,
        ...(allSelected ? { query } : { participation_ids: selectedParticipationIds }),
        message_id: message && message.custom ? message.id : null,
        sender
      }).unwrap();

      showToast(successSendScreener());
      onSuccess(backgroundTask);
    } catch (e) {
      showToast(failedSendScreener(e.data.error));
      onClose();
    }
  }

  const count = allSelected ? invitable : isSuccess && data ? data.inviteable_ids.length : 0;
  const resendCount = allSelected ? reinviteable : isSuccess && data ? data.reinviteable_ids.length : 0;

  const ineligibleCount = allSelected ? ineligible : isSuccess && data ? data.ineligible_ids.length : 0;

  const selectedCount = allSelected ? query?.count || 0 : participations.length;

  const hasInviteQuota = hasQuota('invites', count);

  if (!screener) {
    return (
      <SlideOut title='Screener invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Alert type='warning'>Cannot send as there is no screener associated with this study</Alert>
        </div>
      </SlideOut>
    );
  }

  if (isLoading || !data || countsLoading) {
    return (
      <SlideOut title='Screener invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Loading absolute />
        </div>
      </SlideOut>
    );
  }

  if (!hasInviteQuota) {
    return (
      <SlideOut title='Screener invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Alert type='warning'>
            Cannot send all {pluralize('messages', count, true)} as this exceeds your remaining quota of{' '}
            {getQuota('invites')}
          </Alert>
        </div>
      </SlideOut>
    );
  }

  return (
    <StudyMessageSlideOut
      title='Screener invite'
      batchingTitle='screener invites'
      batchingTarget={true}
      batchable
      cta='Send Screener'
      study={study}
      event='screener'
      customizable
      contactableCount={contactable}
      totalCount={selectedCount}
      inviteableCount={count}
      ineligibleCount={ineligibleCount}
      resendCount={resendCount}
      previewCandidate={previewParticipation?.customer}
      previewParticipation={previewParticipation}
      onClose={onClose}
      onSend={handleSend}
    >
      <>{isSending && <Loading absolute />}</>
    </StudyMessageSlideOut>
  );
};
