import { DropResult } from 'react-beautiful-dnd';

import { CardSortResult } from '@components/Unmoderated/types';

interface Args {
  categories: CardSortResult[];
  destination: DropResult['destination'];
  source: DropResult['source'];
  draggableId: string;
}

export const reorderCards = ({ destination, source, draggableId, categories }: Args): CardSortResult[] => {
  const newCategories = categories.map((c) => {
    if (source.droppableId === c.name && destination?.droppableId === c.name) {
      const newCategory = { ...c };
      const cardIndex = newCategory.cards.findIndex((c) => c === draggableId);

      const startIndex = cardIndex > -1 ? cardIndex : newCategory.cards.length;
      const endIndex = destination?.index || 0;

      const cards = [...c.cards];
      const [removed] = cards.splice(startIndex, 1);
      cards.splice(endIndex, 0, removed);
      newCategory.cards = cards;

      return newCategory;
    }

    if (c.name === source.droppableId) {
      const currentCategory = { ...c };
      const cards = currentCategory.cards.filter((c) => c !== draggableId);

      return { ...currentCategory, cards };
    }

    if (c.name === destination?.droppableId) {
      const newCategory = { ...c };

      const endIndex = destination?.index || 0;

      const cards = [...c.cards];
      cards.splice(endIndex, 0, draggableId);
      newCategory.cards = cards;

      return newCategory;
    }

    return c;
  });

  return newCategories;
};
