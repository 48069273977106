import { TreeItem } from '@components/SurveyBuilder/types/models';

export const getSelectedIdsPath = (items: TreeItem[]): number[][] => {
  const paths: number[][] = [];

  const traverse = (item: TreeItem, path: number[] = []) => {
    const newPath = [...path, Number(item.id)];

    if (item.selected && !item.nodes.length) {
      paths.push(newPath);
    }

    item.nodes.forEach((node) => {
      traverse(node, newPath);
    });
  };

  items.forEach((item) => {
    traverse(item);
  });

  return paths;
};

export const getHasSelectedNode = (nodes: TreeItem[]): boolean => {
  return nodes.some((node) => node.selected || getHasSelectedNode(node.nodes));
};

export const getHasUnlabelledNode = (nodes: TreeItem[]): boolean => {
  return nodes.some((node) => !node.label || getHasUnlabelledNode(node.nodes));
};
