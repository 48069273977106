import React, { InputHTMLAttributes } from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { useAiTitle, UseAiTitleProps } from '@components/shared/AI/hooks/useAiTitle';
import { blurOnEnter } from '@components/utils';
import { composeEventHandlers } from '@helpers/composeEventHandlers';

export interface Handle<C extends Record<string, any>> {
  getSuggestions: (context?: C) => void;
}

export type Props<C extends Record<string, any>> = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'children' | 'value' | 'readOnly' | 'size'
> &
  UseAiTitleProps<C>;

export const Title = <C extends Record<string, any>>(props: Props<C>) => {
  const {
    readOnly,
    onSave,
    onReject,
    autoAccept,
    value: initialValue,
    bot,
    context,
    disableAi,
    fetchOnMount,
    handle,
    onGetSuggestions,
    size = 'lg',
    onBlur,
    onChange,
    disabled,
    tooltip,
    ...rest
  } = props;

  const { editable, value, handleOnInputClick, handleOnBlur, handleOnChange, AiActions } = useAiTitle({
    value: initialValue,
    bot,
    context,
    disableAi,
    fetchOnMount,
    handle,
    onGetSuggestions,
    disabled,
    readOnly,
    onSave,
    onReject,
    autoAccept,
    size,
    tooltip
  });

  return (
    <div className={cn({ 'mr-2 flex items-center': editable || size !== 'sm' })}>
      {editable ? (
        <span
          className={cn('input-sizer font-bold', {
            'h600 leading-9': size === 'lg',
            'h700 leading-9': size === 'xl',
            h400: size === 'sm'
          })}
          data-value={value}
        >
          <input
            className='h-full font-bold'
            size={1}
            value={value}
            onBlur={composeEventHandlers(onBlur, handleOnBlur)}
            onChange={composeEventHandlers(onChange, handleOnChange)}
            autoFocus
            {...blurOnEnter()}
            {...rest}
          />
        </span>
      ) : (
        <span className={cn({ 'cursor-pointer': !disabled && !editable })} onClick={handleOnInputClick}>
          <Text
            as='h2'
            h={{ sm: 400, lg: 600, xl: 700 }[size] as any}
            className={cn('inline', { 'leading-9': size !== 'sm' })}
            bold
          >
            {value || rest.placeholder}
          </Text>
        </span>
      )}
      <AiActions />
    </div>
  );
};
