import React, { PropsWithChildren, ReactNode, useEffect, useLayoutEffect } from 'react';

import cn from 'classnames';
import { useDraggable } from '@dnd-kit/core';

import { useMutationObserver } from '@hooks/useMutationObserver';

import { useBoundaryRect } from '../hooks/useBoundaryRect';
import { useDraggableCardContext } from '../hooks/useDraggableCardContext';
import { useStyles } from '../hooks/useStyles';

import { Header } from './Header';

interface Props {
  boundary: 'window' | 'parent';
  header?: (isMinimized: boolean) => ReactNode;
  prepend?: (isMinimized: boolean) => ReactNode;
}

export const Container = ({ boundary, children, header, prepend, ...rest }: PropsWithChildren<Props>) => {
  const {
    state: { isMinimized, startPosition, width, height, isTouched, coordinates },
    setBoundaryElement,
    setCoordinates,
    setHeight
  } = useDraggableCardContext();

  const { attributes, listeners, setNodeRef, transform, node } = useDraggable({
    id: 'draggableCard'
  });

  const { styles } = useStyles(transform);

  const boundaryRect = useBoundaryRect();

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      if (node.current && node.current.clientWidth > 0) {
        setHeight(node.current.clientHeight);
      }
    });
  }, [node.current]);

  useEffect(() => {
    if (!boundaryRect || isTouched || height === 0) {
      return;
    }

    switch (startPosition) {
      case 'top-left':
        setCoordinates({ x: boundaryRect.left, y: boundaryRect.top });
        break;

      case 'top-right':
        setCoordinates({ x: boundaryRect.width - width, y: boundaryRect.top });
        break;

      case 'bottom-left':
        setCoordinates({ x: boundaryRect.left, y: boundaryRect.height - height });
        break;

      case 'bottom-right':
        setCoordinates({ x: boundaryRect.width - width, y: boundaryRect.height - height });
        break;

      case 'center':
        setCoordinates({ x: (boundaryRect.width - width) / 2, y: (boundaryRect.height - height) / 2 });
        break;

      default:
        break;
    }
  }, [startPosition, width, height, isTouched, boundaryRect]);

  // restrain the card within the boundary
  useEffect(() => {
    if (!isTouched || !node.current || !boundaryRect || height === 0 || (coordinates.x === 0 && coordinates.y === 0)) {
      return;
    }

    if (boundaryRect.width <= width || boundaryRect.height - 20 <= height) {
      return;
    }

    if (coordinates.x < boundaryRect.left) {
      setCoordinates({ x: boundaryRect.left, y: coordinates.y });
    }

    if (coordinates.x + width > boundaryRect.width) {
      setCoordinates({ x: boundaryRect.width - width, y: coordinates.y });
    }

    if (coordinates.y < boundaryRect.top) {
      setCoordinates({ x: coordinates.x, y: boundaryRect.top });
    }

    if (coordinates.y + height > boundaryRect.height) {
      setCoordinates({ x: coordinates.x, y: boundaryRect.height - height });
    }
  }, [boundaryRect, coordinates.x, coordinates.y, width, height, isTouched]);

  useEffect(() => {
    if (!node.current) {
      return;
    }

    if (boundary === 'window') {
      setBoundaryElement(window);
    }

    if (boundary === 'parent' && node.current.parentElement) {
      setBoundaryElement(node.current.parentElement);
    }
  }, [boundary, node]);

  useMutationObserver(
    node,
    (mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList') {
          requestAnimationFrame(() => {
            if (node.current) {
              setHeight(node.current.clientHeight);
            }
          });
        }
      }
    },
    { subtree: true, childList: true }
  );

  return (
    <section
      ref={setNodeRef}
      className='absolute overflow-hidden rounded border border-gray-700 bg-white shadow-lg'
      data-testid='draggable-card-container'
      style={styles}
      {...rest}
    >
      {prepend?.(isMinimized)}

      <Header draggableProps={{ attributes, listeners }}>{header}</Header>

      <div className={cn('px-6 pb-6', { hidden: isMinimized })} data-testid='draggable-card-content'>
        {children}
      </div>
    </section>
  );
};
