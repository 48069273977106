import React, { forwardRef, HTMLProps, useEffect, useRef } from 'react';

import cn from 'classnames';
import mergeRefs from 'react-merge-refs';

interface Props extends HTMLProps<HTMLInputElement> {
  indeterminate?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(({ indeterminate, className, ...rest }, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && indeterminate) {
      inputRef.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [inputRef, indeterminate]);

  return (
    <div className='flex h-11 items-center justify-center border-b border-gray-200 bg-white px-2'>
      <input
        type='checkbox'
        ref={mergeRefs([ref, inputRef])}
        className={cn(
          'h-4 w-4 cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-500',
          className
        )}
        {...rest}
      />
    </div>
  );
});
