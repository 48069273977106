import React, { FC, useEffect, useState } from 'react';

import cn from 'classnames';
import { Control } from 'react-hook-form';

import { Button } from '@components/common';
import { FigmaPrototype, FigmaPrototypeTypes } from '@components/common/FigmaPrototype';
import { Portal } from '@components/Portal';
import { Enums, getBlockLabel, Models } from '@components/SurveyBuilder';
import { CompletedStatus } from '@components/Unmoderated/components/CompletedStatus';
import { TaskHeader } from '@components/Unmoderated/components/TaskHeader';
import { TaskLoadedBlocker } from '@components/Unmoderated/components/TaskLoadedBlocker';
import { useDeviceType } from '@hooks/useDeviceType';

import { usePrototypeTaskTracking } from '../hooks/usePrototypeTaskTracking';
import { usePrototypeTaskValidation } from '../hooks/usePrototypeTaskValidation';
import { useUnmoderatedContext } from '../hooks/useUnmoderatedContext';
import * as Types from '../types';

import { PrototypeTestConfirmationModal } from './PrototypeTestConfirmationModal';
import { ResponsiveCard } from './ResponsiveCard';

interface Props {
  block: Models.Block<Enums.Kind.prototypeTest>;
  control: Control<Types.FormData>;
  iframePortalId: string;
  onComplete?: () => void;
  onSubmit: () => Promise<void>;
}

export const PrototypeTestTask: FC<Props> = ({ block, control, iframePortalId, onComplete, onSubmit }) => {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false);
  const [interactionModalOpen, setInteractionModalOpen] = useState<boolean>(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [blocker, setBlocker] = useState<boolean>(false);

  const { isComplete, onIframePrototypeNodeChange } = usePrototypeTaskValidation({ block });
  const { events, onMousePress, onNodeChange } = usePrototypeTaskTracking();
  const { blocks, deviceType, topBarUnmoderatedLayout } = useUnmoderatedContext();
  const { isMobile } = useDeviceType();

  const startingNodeId = block.blockable.paths?.[0]?.steps?.find((step) => !step.last)?.external_element_id;
  const scaling = block.blockable.settings?.scaling ?? FigmaPrototypeTypes.Scaling.minZoom;
  const disableKeyboardNav = block.blockable.settings?.disable_keyboard_nav ?? false;

  const handleOnLoad = () => {
    setIsLoading(false);
  };

  const handleOnEndTask = () => {
    if (isLoading) {
      setLoadingModalOpen(true);
    } else if (!isTouched) {
      setInteractionModalOpen(true);
    } else {
      onSubmit?.();
    }
  };

  useEffect(() => {
    control.register('prototype_test_task');
  }, [control]);

  useEffect(() => {
    control.setValue('prototype_test_task', { isComplete, events });

    if (isComplete) {
      onComplete?.();
    }
  }, [control, events, isComplete]);

  const renderActions = () => {
    return (
      <>
        <FigmaPrototype
          className='h-full w-full'
          data-testid='prototype-test-task-iframe'
          disableDefaultKeyboardNav={disableKeyboardNav}
          hideUi
          hideHotspots
          nodeId={startingNodeId}
          scaling={scaling}
          src={block.blockable.url ?? ''}
          onPrototypeFirstEvent={handleOnLoad}
          onPrototypeInitialLoad={() => setBlocker(true)}
          onPrototypePresentedNodeChanged={(data) => {
            onIframePrototypeNodeChange(data);
            onNodeChange?.(data);
          }}
          onPrototypeMousePress={(data) => {
            onMousePress?.(data);
            setIsTouched(true);
          }}
        />
        {loadingModalOpen && (
          <PrototypeTestConfirmationModal
            text='Are you sure? The prototype test is still loading.'
            onClose={() => setLoadingModalOpen(false)}
            onConfirm={onSubmit}
          />
        )}

        {interactionModalOpen && (
          <PrototypeTestConfirmationModal
            text='We detected that there was no interaction with the prototype. Are you sure you want to end the task?'
            onClose={() => setInteractionModalOpen(false)}
            onConfirm={onSubmit}
          />
        )}
      </>
    );
  };

  const renderFooter = () => {
    return isComplete ? (
      <Button className='btn-custom-brand' type='submit' icon='arrowRight' small noStyle>
        {block.blockable.settings?.indicate_completion ? 'Continue' : 'End task'}
      </Button>
    ) : (
      <Button
        aria-label='End task'
        className='btn-custom-brand'
        type='button'
        icon='checkInCircle'
        onClick={handleOnEndTask}
        small
        noStyle
      >
        End task
      </Button>
    );
  };

  if (deviceType === 'desktop' && topBarUnmoderatedLayout) {
    return (
      <div className='flex h-screen w-full flex-col overflow-hidden bg-white pt-4'>
        <TaskHeader
          isMinimized={isMinimized}
          setIsMinimized={setIsMinimized}
          block={block}
          renderOptions={renderFooter}
        />
        <div className='relative flex w-full flex-1 overflow-hidden bg-gray-50'>
          <div className='flex w-full flex-1 flex-col items-center overflow-auto'>
            <div data-testid='prototype-test-task-wrapper' className='flex h-screen w-full flex-col overflow-hidden'>
              {isComplete && block.blockable.settings?.indicate_completion && (
                <CompletedStatus textClassName='text-white' className='bg-green-600'>
                  Completed
                </CompletedStatus>
              )}
              {renderActions()}
            </div>
          </div>
          {blocker && (
            <TaskLoadedBlocker
              blockKind={block.kind}
              onBeginTask={() => {
                setBlocker(false);
                setIsMinimized(true);
              }}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <ResponsiveCard
        blockKind={getBlockLabel(block.kind)}
        blockPosition={block.position}
        description={block.description}
        title={block.title}
        isComplete={isComplete && block.blockable.settings?.indicate_completion}
        totalBlocks={blocks.length}
        renderFooter={renderFooter}
      >
        <Portal target={document.getElementById(iframePortalId)}>
          <div data-testid='prototype-test-task-wrapper' className={cn('h-full w-full', { 'pb-16': isMobile })}>
            {renderActions()}
          </div>
        </Portal>
      </ResponsiveCard>
    </>
  );
};
