import * as React from 'react';

import { Text } from '@components/common';
import { GuestDisplay } from '@components/shared/BookingDetailsForm/fields/Guests/GuestDisplay';

import { DiffRow } from './DiffRow';

type Props = {
  before?: CalendarEventGuest[];
  after?: CalendarEventGuest[];
  bookingDetail: CalendarBookingDetail;
  changes: DeepPartial<CalendarBookingDetail>;
};

export const GuestsDiff: React.FC<Props> = ({ before = [], after = [], bookingDetail, changes }) => {
  const oldModeratorEmail = bookingDetail.candidate_event?.moderator_email;
  const newModeratorEmail = changes.candidate_event?.moderator_email || oldModeratorEmail;
  const isModeratorChanging = oldModeratorEmail !== newModeratorEmail;

  return (
    <DiffRow
      leftSide={
        <div className='flex flex-col space-y-3 line-through'>
          {before.length > 0 ? (
            before.map((guest) => (
              <GuestDisplay
                key={guest.email}
                className='w-40'
                guest={guest}
                isHost={guest.email === oldModeratorEmail}
              />
            ))
          ) : (
            <Text h='400' color='gray-500'>
              None.
            </Text>
          )}
        </div>
      }
      rightSide={
        <div className='flex flex-col items-end space-y-3'>
          {after.length > 0 ? (
            after.map((guest) => {
              const isHost = isModeratorChanging
                ? guest.email === newModeratorEmail
                : guest.email === oldModeratorEmail;

              return <GuestDisplay key={guest.email} className='w-40' guest={guest} isHost={isHost} />;
            })
          ) : (
            <Text h='400' color='gray-500'>
              None.
            </Text>
          )}
        </div>
      }
    />
  );
};
