import React, { forwardRef, HTMLAttributes } from 'react';

import cn from 'classnames';
import { Options } from '@popperjs/core';

const ANIMATE_CLASSNAME = {
  top: 'translate-y-10',
  bottom: '-translate-y-10',
  'bottom-start': '-translate-y-10',
  left: 'translate-x-10',
  right: '-translate-x-10',
  'right-start': '-translate-x-10'
};

interface Props extends HTMLAttributes<HTMLDivElement> {
  isVisible: boolean;
  placement?: Options['placement'];
}

export const MotionWrapper = forwardRef<HTMLDivElement, Props>(
  ({ isVisible, placement = 'top', children, className, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(className, {
          'z-30': isVisible,
          'invisible z-0 h-0 w-0': !isVisible
        })}
        {...rest}
      >
        <div
          className={cn(
            'shadow-custom transform-gpu rounded border border-gray-200 bg-white text-sm',
            {
              [ANIMATE_CLASSNAME[placement]]: !isVisible,
              'pointer-events-none opacity-0': !isVisible,
              'pointer-events-auto translate-x-0 translate-y-0 opacity-100 transition-all duration-150 ease-in-out':
                isVisible
            },
            className
          )}
        >
          {children}
        </div>
      </div>
    );
  }
);
