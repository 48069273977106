import * as React from 'react';
import { useRef, useState } from 'react';

import cn from 'classnames';
import { useCombobox } from 'downshift';
import { Link } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Input, Text } from '@components/common';
import { Basic } from '@components/shared/Skeleton';
import { CloseSVG } from '@components/svgs';
import { capitalize } from '@components/utils';

import { getUrlFromContextObj } from '../utils/context';

const MAX_ITEMS = 10;

type Props = {
  context: AiChatContext | null;
  setContext: (context: AiChatContext | null) => void;
};

export const ScopeInput: React.FC<Props> = ({ context, setContext }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const { data, isLoading } = api.useGetAiScopesQuery();

  const items = (data || [])
    .filter((item) => item.context_name?.toLowerCase().includes(inputValue.toLowerCase()))
    .slice(0, MAX_ITEMS);

  const { getComboboxProps, getInputProps, getItemProps, getMenuProps, isOpen, openMenu, highlightedIndex, reset } =
    useCombobox<AiChatContext>({
      items,
      inputValue,
      onSelectedItemChange: ({ selectedItem }) => {
        if (selectedItem) {
          setContext(selectedItem);
          inputRef.current?.blur();
          reset();
        }
      }
    });

  const handleClickClearContext = () => {
    setInputValue('');
    setContext(null);
    setImmediate(() => inputRef.current?.focus());
  };

  const displayStr = context?.context_name || 'This page';
  const scopeUrl = context ? getUrlFromContextObj(context) : null;
  if (context) {
    return (
      <div className='flex h-8 items-center space-x-3 rounded-md bg-gray-100 px-2'>
        <span className='h400 max-w-80 truncate'>
          {scopeUrl ? <Link to={scopeUrl}>{displayStr}</Link> : displayStr}
        </span>
        <button name='Clear context' aria-label='Clear context' onClick={handleClickClearContext}>
          <CloseSVG className='h-3 w-3' />
        </button>
      </div>
    );
  }

  return (
    <div {...getComboboxProps()} className='relative w-full'>
      {isLoading && <Basic h='4' width='100%' />}

      <Input
        {...getInputProps()}
        aria-label='scope'
        name='scope'
        ref={inputRef}
        value={inputValue}
        onChange={setInputValue}
        className='h-8 w-80'
        placeholder='Enter interview or study name…'
        hidden={isLoading}
        onFocus={openMenu}
      />

      <ul
        {...getMenuProps({ hidden: !isOpen || items.length === 0 })}
        className='list-style-none overflow-none absolute top-8 z-10 mt-1 rounded-md border border-gray-200 bg-white px-4 py-2 shadow-sm'
      >
        {items.map((item, index) => (
          <li
            key={`${item.context_name}-${index}`}
            role='option'
            aria-selected='false'
            {...getItemProps({ item, index })}
          >
            <div
              className={cn('px-.5 py-1.5', {
                'bg-gray-50 text-indigo-700': highlightedIndex === index
              })}
            >
              <Text truncate h='400'>
                {item.context_name}
              </Text>
              <Text h='200' color='gray-500'>
                {capitalize(item.context_type || '')}
              </Text>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
