import React, { FC } from 'react';

import { useOnboardingContext } from '@components/Onboarding/Onboarding';

import { BasicProfile } from '../BasicProfile';
import { Form } from '../Form';
import { StartResearch } from '../StartResearch';

import { Integrations } from './components';

export const Steps: FC<React.PropsWithChildren<{ redirectTo: string | null }>> = ({ redirectTo }) => {
  const { currentStep, setCurrentStep } = useOnboardingContext();

  const nextStep = () => {
    if (setCurrentStep && currentStep) {
      setCurrentStep(currentStep + 1);
    }
  };

  switch (currentStep) {
    case 1:
      return (
        <Form onNext={nextStep} redirectTo={redirectTo}>
          <BasicProfile />
        </Form>
      );
    case 2:
      return <Integrations onNext={nextStep} />;
    case 3:
      return <StartResearch redirectTo={redirectTo} />;
    default:
      return (
        <Form onNext={nextStep} redirectTo={redirectTo}>
          <BasicProfile />
        </Form>
      );
  }
};
