import React, { FC, ReactNode } from 'react';

import { createPortal } from 'react-dom';

import { getPortalTarget } from '@helpers/react/getPortalTarget';
import { unRef } from '@helpers/react/unRef';
import { useTrapFocus } from '@hooks/useTrapFocus';

interface Props {
  children: ReactNode;
  isDisabled?: boolean;
  /**
   * Whether to trap focus within the portal, if enabled the content will be wrapped in a extra div.
   */
  hasTrapFocus?: boolean;
  target?: MaybeRef<HTMLElement> | null;
}

export const Portal: FC<React.PropsWithChildren<Props>> = ({ children, target, isDisabled, hasTrapFocus }) => {
  const { ref } = useTrapFocus({ isDisabled: !hasTrapFocus });

  const targetElement = target ? unRef(target) : getPortalTarget();

  if (isDisabled || !targetElement) {
    return <>{children}</>;
  }

  return createPortal(hasTrapFocus ? <div ref={ref}>{children}</div> : children, targetElement);
};
