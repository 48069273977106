import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Loading } from '@components/common';
import { useAccount } from '@hooks/useAccount';

import { Invite } from './Invite';

export const EditUser: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [user, setUser] = useState<any>();
  const {
    account: { team }
  } = useAccount();

  const [updateAccountUser] = api.useUpdateAccountUserMutation();

  const params = useParams();
  const accountUserId = parseInt(params.id || '');

  useEffect(() => {
    if (accountUserId) {
      setUser(team.find(({ account_user_id }) => accountUserId === account_user_id));
    }
  }, [accountUserId]);

  const onUpdate = ({ role }) => updateAccountUser({ id: user.account_user_id, role }).unwrap();

  return user ? (
    <Invite
      accountUserId={accountUserId}
      title='Update teammate'
      type='User'
      method='update'
      record={user}
      onUpdate={(params) => onUpdate({ role: params.role })}
    />
  ) : (
    <Loading />
  );
};
