import * as React from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useDeviceType } from '@hooks/useDeviceType';

type State = {
  open: boolean;
  fixed: boolean;
  hidden: boolean;
  toggle: () => void;
  setOpen: (open: boolean) => void;
  setHidden: (open: boolean) => void;
  setFixed: (fixed: boolean) => void;
  userInfo: {
    open: boolean;
    toggle: () => void;
  };
  recentStudies: {
    open: boolean;
    toggle: () => void;
  };
  resources: {
    open: boolean;
    toggle: () => void;
  };
  background_tasks: {
    open: boolean;
    toggle: () => void;
  };
};

export const context = createContext<State>({} as State);

export const useSidebar = (): State => useContext(context);

export const useFixedSidebar = (): State => {
  const sidebar = useSidebar();

  useEffect(() => {
    sidebar.setFixed(true);
    sidebar.setOpen(false);
    return () => sidebar.setFixed(false);
  }, []);
  return sidebar;
};

export const Provider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { isMobile } = useDeviceType();
  const [open, setOpen] = useState(!isMobile && window.localStorage.getItem('navCollapsed') != 'true');
  const [hidden, setHidden] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [userInfoOpen, setUserInfoOpen] = useState(false);
  const [recentStudiesOpen, setRecentStudiesOpen] = useState(false);
  const [resourcesOpen, setResourcesOpen] = useState(false);
  const [backgroundTasksOpen, setBackgroundTasksOpen] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  useEffect(() => {
    const sidebarPortal = document.getElementById('sidebar-portal');
    if (fixed && sidebarPortal) {
      sidebarPortal.classList.add('fixed', 'h-full', 'z-50');
    }
    return () => {
      if (fixed && sidebarPortal) {
        sidebarPortal.classList.remove('fixed', 'h-full', 'z-50');
      }
    };
  }, [fixed, open]);

  const state: State = {
    open,
    fixed,
    hidden,
    setOpen,
    setFixed,
    setHidden,
    toggle: () => {
      if (open) {
        setOpen(false);
        window.localStorage.setItem('navCollapsed', 'true');
      } else {
        setOpen(true);
        window.localStorage.removeItem('navCollapsed');
      }
    },
    userInfo: {
      open: userInfoOpen,
      toggle: () => setUserInfoOpen(!userInfoOpen)
    },
    recentStudies: {
      open: recentStudiesOpen,
      toggle: () => setRecentStudiesOpen(!recentStudiesOpen)
    },
    resources: {
      open: resourcesOpen,
      toggle: () => setResourcesOpen(!resourcesOpen)
    },
    background_tasks: {
      open: backgroundTasksOpen,
      toggle: () => setBackgroundTasksOpen(!backgroundTasksOpen)
    }
  };
  return <context.Provider value={state}>{children}</context.Provider>;
};
