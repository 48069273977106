import React from 'react';

import * as dropdowns from '@components/shared/TableFilters/components/dropdowns';
import * as svgs from '@components/shared/TableFilters/components/svgs';
import { FilterDefinition } from '@components/shared/TableFilters/types';

type DropdownOption<T> = { label: string; value: T };

export type Statuses = 'draft' | 'published';

export const GUIDE_STATUS_LABELS = {
  draft: 'Draft',
  published: 'Published'
};

const STATUSES_OPTIONS: DropdownOption<Statuses>[] = [
  { label: GUIDE_STATUS_LABELS['draft'], value: 'draft' },
  { label: GUIDE_STATUS_LABELS['published'], value: 'published' }
];

export const buildGuidesFilterDefs = (): FilterDefinition<Guide>[] => [
  {
    defaultOperator: 'includes_any',
    func: ({ published }, { value }) => {
      if (!value) return true;

      return published ? value.includes('published') : value.includes('draft');
    },
    id: 'status',
    name: 'Status',
    type: 'guide_status',
    Icon: svgs.status,
    Component: (props) => <dropdowns.Choose {...props} options={STATUSES_OPTIONS} />
  },
  {
    defaultOperator: 'includes_any',
    func: ({ creator }, { value }) => {
      if (!value) return true;

      return value.includes(creator.id);
    },
    id: 'owner',
    name: 'Owner',
    type: 'team_members',
    Icon: svgs.owner,
    Component: dropdowns.TeamMember
  }
];
