import * as React from 'react';

import { Provider } from 'react-redux';

import { store } from '@api/reduxApi';
import { AppErroredPage } from '@components/AppErroredPage';
import { Loading } from '@components/common';
import { SentryProvider } from '@components/providers';
import { Provider as AccountProvider } from '@stores/account';
import { Provider as ConfigProvider } from '@stores/config';
import { Provider as ToastProvider } from '@stores/toaster';
import { Provider as UserProvider } from '@stores/user';
import { WhoamiContainer } from '@stores/whoami';

const BasicContext: React.FC<React.PropsWithChildren<{ whoami: Whoami }>> = ({ whoami, children }) => (
  <AccountProvider value={{ account: whoami.account, plan: whoami.plan }}>
    <UserProvider initialState={{ user: whoami.user, auth_method: whoami.auth_method }}>
      <ConfigProvider initialState={{ config: whoami.config }}>
        <ToastProvider>
          <SentryProvider whoami={whoami} renderComponent={(props) => <AppErroredPage {...props} />}>
            {children}
          </SentryProvider>
        </ToastProvider>
      </ConfigProvider>
    </UserProvider>
  </AccountProvider>
);

export const BasicAppLoader: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Provider store={store}>
      <WhoamiContainer>
        {({ whoami, isLoading }) => (isLoading ? <Loading /> : <BasicContext whoami={whoami}>{children}</BasicContext>)}
      </WhoamiContainer>
    </Provider>
  );
};
