import * as React from 'react';

interface CellWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick?: () => void;
  wrapperClass?: string;
  position?: string;
}

export const CellWrapper: React.FC<React.PropsWithChildren<CellWrapperProps>> = ({
  children,
  onClick,
  position = 'center',
  wrapperClass,
  ...rest
}) => (
  <div onClick={onClick} className={`h400 flex h-full w-full px-2 items-${position} ${wrapperClass}`} {...rest}>
    {children}
  </div>
);
