import * as React from 'react';

import { Button, Modal, ModalHeading, Text } from '@components/common';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};
export const SaveYourChangesModal: React.FC<React.PropsWithChildren<Props>> = ({ open, onClose, onSubmit }) =>
  open ? (
    <Modal
      onClose={onClose}
      renderFooter={() => (
        <div className='flex w-full flex-row justify-end space-x-4'>
          <Button onClick={onClose}>Back</Button>
          <Button danger onClick={onSubmit}>
            Cancel &amp; close
          </Button>
        </div>
      )}
    >
      <ModalHeading className='mb-4'>Cancel remaining uploads?</ModalHeading>
      <section className='mb-6 mt-4'>
        <Text h='400'>If you close this window, any unsaved changes will be lost.</Text>
      </section>
    </Modal>
  ) : null;
