import * as React from 'react';

export const SVG: React.FC<React.PropsWithChildren<any>> = (props) => (
  <svg width='152' height='92' viewBox='0 0 152 92' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_310_8065)'>
      <path
        d='M152 71.4155C152.003 72.0284 151.762 72.6175 151.331 73.0532C150.9 73.4889 150.313 73.7354 149.701 73.7386H48.55C48.2465 73.737 47.9463 73.6757 47.6665 73.5581C47.3868 73.4405 47.1329 73.269 46.9194 73.0532C46.7059 72.8375 46.537 72.5819 46.4223 72.3009C46.3076 72.0199 46.2494 71.719 46.251 71.4156V2.32312C46.2478 1.7102 46.4882 1.1211 46.9193 0.685433C47.3505 0.249764 47.937 0.00320532 48.55 0H149.701C150.004 0.00157865 150.304 0.0629191 150.584 0.180518C150.864 0.298117 151.118 0.469671 151.331 0.685387C151.545 0.901102 151.714 1.15676 151.828 1.43775C151.943 1.71874 152.001 2.01957 152 2.32306V2.32312V71.4155Z'
        fill='#E5E7EB'
      />
      <path
        d='M150.807 70.2839C150.807 70.8797 150.571 71.451 150.15 71.8723C149.728 72.2935 149.157 72.5302 148.561 72.5302H49.7609C49.1652 72.5302 48.5938 72.2935 48.1726 71.8723C47.7513 71.451 47.5146 70.8797 47.5146 70.2839V3.48603C47.5146 2.89028 47.7513 2.31893 48.1726 1.89767C48.5938 1.47641 49.1652 1.23975 49.7609 1.23975H148.566C149.161 1.24094 149.731 1.47813 150.151 1.89926C150.571 2.32039 150.807 2.89106 150.807 3.48603V70.2839Z'
        fill='white'
      />
      <path
        d='M52.7942 4.14014H145.632C145.985 4.14014 146.324 4.28061 146.574 4.53065C146.824 4.7807 146.965 5.11983 146.965 5.47344V68.0379C146.965 68.3915 146.824 68.7306 146.574 68.9807C146.324 69.2307 145.985 69.3712 145.632 69.3712H52.7942C52.4406 69.3712 52.1015 69.2307 51.8515 68.9807C51.6014 68.7306 51.4609 68.3915 51.4609 68.0379V5.47344C51.4609 5.11983 51.6014 4.7807 51.8515 4.53065C52.1015 4.28061 52.4406 4.14014 52.7942 4.14014Z'
        fill='#E5E7EB'
      />
      <path
        d='M53.97 5.64062H144.356C144.71 5.64063 145.049 5.7811 145.299 6.03114C145.549 6.28119 145.689 6.62032 145.689 6.97393V66.1999C145.689 66.5535 145.549 66.8926 145.299 67.1427C145.049 67.3927 144.71 67.5332 144.356 67.5332H53.97C53.6164 67.5332 53.2773 67.3927 53.0272 67.1427C52.7772 66.8926 52.6367 66.5535 52.6367 66.1999V6.97393C52.6367 6.62032 52.7772 6.28119 53.0272 6.03114C53.2773 5.7811 53.6164 5.64062 53.97 5.64062Z'
        fill='white'
      />
      <path
        d='M117.789 46.7834C117.433 46.7832 117.081 46.7082 116.756 46.5634C116.681 46.5299 116.608 46.4889 116.541 46.4411L109.972 41.8173C109.639 41.5828 109.367 41.2716 109.179 40.91C108.992 40.5484 108.894 40.147 108.894 39.7396V33.4336C108.894 33.0262 108.992 32.6248 109.179 32.2632C109.367 31.9016 109.639 31.5905 109.972 31.356L116.541 26.7321C116.608 26.6843 116.681 26.6434 116.756 26.6098C117.143 26.4378 117.567 26.3651 117.989 26.3985C118.411 26.4319 118.818 26.5703 119.173 26.801C119.528 27.0317 119.82 27.3475 120.022 27.7197C120.224 28.0919 120.33 28.5086 120.33 28.9321V44.2412C120.33 44.9152 120.062 45.5616 119.586 46.0383C119.109 46.5149 118.463 46.7826 117.789 46.7826L117.789 46.7834Z'
        fill='#12102F'
      />
      <path
        d='M103.087 48.0236H84.7659C81.0281 48.0205 77.999 45.6044 77.9951 42.623V30.551C77.999 27.5696 81.0281 25.1535 84.7659 25.1504H103.135C106.846 25.1539 109.853 27.5527 109.858 30.5129V42.623C109.854 45.6044 106.825 48.0205 103.087 48.0236Z'
        fill='#12102F'
      />
      <path
        d='M135.723 63.7248C138.733 63.7248 141.172 61.2853 141.172 58.276C141.172 55.2667 138.733 52.8271 135.723 52.8271C132.714 52.8271 130.274 55.2667 130.274 58.276C130.274 61.2853 132.714 63.7248 135.723 63.7248Z'
        fill='#E5E7EB'
      />
      <path
        d='M138.553 58.1785L134.209 55.6709C134.192 55.661 134.173 55.6558 134.153 55.6558C134.133 55.6558 134.114 55.661 134.096 55.6709C134.079 55.6808 134.065 55.695 134.055 55.7122C134.045 55.7293 134.04 55.7487 134.04 55.7685V60.7837C134.04 60.8035 134.045 60.823 134.055 60.8401C134.065 60.8573 134.079 60.8715 134.096 60.8814C134.114 60.8913 134.133 60.8965 134.153 60.8965C134.173 60.8965 134.192 60.8913 134.209 60.8814L138.553 58.3738C138.57 58.3639 138.584 58.3497 138.594 58.3325C138.604 58.3154 138.609 58.2959 138.609 58.2761C138.609 58.2563 138.604 58.2369 138.594 58.2197C138.584 58.2026 138.57 58.1884 138.553 58.1785L134.209 55.6709C134.192 55.661 134.173 55.6558 134.153 55.6558C134.133 55.6558 134.114 55.661 134.096 55.6709C134.079 55.6808 134.065 55.695 134.055 55.7122C134.045 55.7293 134.04 55.7487 134.04 55.7685V60.7837C134.04 60.8035 134.045 60.823 134.055 60.8401C134.065 60.8573 134.079 60.8715 134.096 60.8814C134.114 60.8913 134.133 60.8965 134.153 60.8965C134.173 60.8965 134.192 60.8913 134.209 60.8814L138.553 58.3738C138.57 58.3639 138.584 58.3497 138.594 58.3325C138.604 58.3154 138.609 58.2959 138.609 58.2761C138.609 58.2563 138.604 58.2369 138.594 58.2197C138.584 58.2026 138.57 58.1884 138.553 58.1785Z'
        fill='white'
      />
      <path
        d='M128.648 79.7016C128.651 80.3145 128.411 80.9036 127.98 81.3393C127.548 81.775 126.962 82.0215 126.349 82.0247H25.1985C24.895 82.0231 24.5948 81.9618 24.315 81.8442C24.0352 81.7266 23.7813 81.5551 23.5678 81.3393C23.3543 81.1236 23.1854 80.868 23.0708 80.587C22.9561 80.306 22.8979 80.0051 22.8994 79.7017V10.6093C22.8962 9.99633 23.1367 9.40723 23.5678 8.97157C23.9989 8.5359 24.5855 8.28934 25.1984 8.28613H126.349C126.652 8.28771 126.953 8.34905 127.232 8.46665C127.512 8.58425 127.766 8.7558 127.98 8.97152C128.193 9.18723 128.362 9.44289 128.477 9.72388C128.591 10.0049 128.65 10.3057 128.648 10.6092V10.6093V79.7016Z'
        fill='#E5E7EB'
      />
      <path
        d='M127.455 78.5701C127.455 79.1658 127.218 79.7372 126.797 80.1584C126.376 80.5797 125.804 80.8163 125.209 80.8163H26.4084C25.8126 80.8163 25.2413 80.5797 24.82 80.1584C24.3988 79.7372 24.1621 79.1658 24.1621 78.5701V11.7722C24.1621 11.1764 24.3988 10.6051 24.82 10.1838C25.2413 9.76254 25.8126 9.52588 26.4084 9.52588H125.213C125.808 9.52708 126.378 9.76426 126.799 10.1854C127.219 10.6065 127.455 11.1772 127.455 11.7722V78.5701Z'
        fill='white'
      />
      <path
        d='M29.4427 12.4263H122.28C122.634 12.4263 122.973 12.5667 123.223 12.8168C123.473 13.0668 123.613 13.406 123.613 13.7596V76.3241C123.613 76.6777 123.473 77.0168 123.223 77.2669C122.973 77.5169 122.634 77.6574 122.28 77.6574H29.4427C29.0891 77.6574 28.7499 77.5169 28.4999 77.2669C28.2498 77.0168 28.1094 76.6777 28.1094 76.3241V13.7596C28.1094 13.406 28.2498 13.0668 28.4999 12.8168C28.7499 12.5667 29.0891 12.4263 29.4427 12.4263Z'
        fill='#E5E7EB'
      />
      <path
        d='M30.6185 13.9268H121.005C121.358 13.9268 121.697 14.0672 121.947 14.3173C122.197 14.5673 122.338 14.9064 122.338 15.2601V74.486C122.338 74.8396 122.197 75.1787 121.947 75.4288C121.697 75.6788 121.358 75.8193 121.005 75.8193H30.6185C30.2648 75.8193 29.9257 75.6788 29.6757 75.4288C29.4256 75.1787 29.2852 74.8396 29.2852 74.486V15.2601C29.2852 14.9064 29.4256 14.5673 29.6757 14.3173C29.9257 14.0672 30.2648 13.9268 30.6185 13.9268Z'
        fill='white'
      />
      <path
        d='M94.4371 55.0696C94.0814 55.0693 93.7296 54.9944 93.4046 54.8496C93.329 54.816 93.2569 54.775 93.1894 54.7273L86.6205 50.1034C86.2874 49.8689 86.0155 49.5577 85.8278 49.1961C85.6401 48.8346 85.5421 48.4332 85.542 48.0258V41.7198C85.5421 41.3124 85.6401 40.9109 85.8278 40.5494C86.0155 40.1878 86.2874 39.8766 86.6205 39.6421L93.1894 35.0182C93.2569 34.9705 93.329 34.9295 93.4046 34.8959C93.7916 34.7239 94.2154 34.6513 94.6375 34.6847C95.0597 34.7181 95.4668 34.8564 95.8219 35.0871C96.1769 35.3179 96.4687 35.6336 96.6707 36.0058C96.8728 36.378 96.9786 36.7947 96.9786 37.2182V52.5273C96.9786 53.2013 96.7108 53.8478 96.2342 54.3244C95.7576 54.801 95.1111 55.0688 94.4371 55.0688L94.4371 55.0696Z'
        fill='#12102F'
      />
      <path
        d='M79.7353 56.3097H61.4143C57.6765 56.3066 54.6474 53.8905 54.6436 50.9091V38.8371C54.6474 35.8557 57.6765 33.4396 61.4143 33.4365H79.7831C83.4943 33.44 86.5017 35.8388 86.5061 38.799V50.9091C86.5022 53.8905 83.4731 56.3066 79.7353 56.3097Z'
        fill='#12102F'
      />
      <path
        d='M112.372 72.0104C115.381 72.0104 117.82 69.5709 117.82 66.5616C117.82 63.5523 115.381 61.1128 112.372 61.1128C109.362 61.1128 106.923 63.5523 106.923 66.5616C106.923 69.5709 109.362 72.0104 112.372 72.0104Z'
        fill='#E5E7EB'
      />
      <path
        d='M115.201 66.4641L110.858 63.9565C110.841 63.9466 110.821 63.9414 110.801 63.9414C110.781 63.9414 110.762 63.9466 110.745 63.9565C110.728 63.9664 110.713 63.9807 110.704 63.9978C110.694 64.0149 110.688 64.0344 110.688 64.0542V69.0694C110.688 69.0892 110.694 69.1087 110.704 69.1258C110.713 69.1429 110.728 69.1572 110.745 69.1671C110.762 69.177 110.781 69.1822 110.801 69.1822C110.821 69.1822 110.841 69.177 110.858 69.1671L115.201 66.6595C115.218 66.6496 115.232 66.6353 115.242 66.6182C115.252 66.601 115.257 66.5816 115.257 66.5618C115.257 66.542 115.252 66.5226 115.242 66.5054C115.232 66.4883 115.218 66.474 115.201 66.4641L110.858 63.9565C110.841 63.9466 110.821 63.9414 110.801 63.9414C110.781 63.9414 110.762 63.9466 110.745 63.9565C110.728 63.9664 110.713 63.9807 110.704 63.9978C110.694 64.0149 110.688 64.0344 110.688 64.0542V69.0694C110.688 69.0892 110.694 69.1087 110.704 69.1258C110.713 69.1429 110.728 69.1572 110.745 69.1671C110.762 69.177 110.781 69.1822 110.801 69.1822C110.821 69.1822 110.841 69.177 110.858 69.1671L115.201 66.6595C115.218 66.6496 115.232 66.6353 115.242 66.6182C115.252 66.601 115.257 66.5816 115.257 66.5618C115.257 66.542 115.252 66.5225 115.242 66.5054C115.232 66.4883 115.218 66.474 115.201 66.4641Z'
        fill='white'
      />
      <path
        d='M105.749 89.1929C105.752 89.8058 105.511 90.3949 105.08 90.8306C104.649 91.2662 104.063 91.5128 103.45 91.516H2.29904C1.99555 91.5144 1.69535 91.4531 1.41556 91.3355C1.13578 91.2179 0.881898 91.0463 0.668415 90.8306C0.454932 90.6149 0.286028 90.3592 0.171346 90.0782C0.0566647 89.7972 -0.00154771 89.4964 3.1637e-05 89.1929V20.1005C-0.00317326 19.4875 0.237235 18.8984 0.668371 18.4628C1.09951 18.0271 1.68605 17.7805 2.29898 17.7773H103.45C103.753 17.7789 104.053 17.8403 104.333 17.9579C104.613 18.0755 104.867 18.247 105.08 18.4627C105.294 18.6784 105.463 18.9341 105.577 19.2151C105.692 19.4961 105.75 19.7969 105.749 20.1004V20.1005V89.1929Z'
        fill='#E5E7EB'
      />
      <path
        d='M104.556 88.0617C104.556 88.6575 104.319 89.2289 103.898 89.6501C103.476 90.0714 102.905 90.308 102.309 90.308H3.50898C2.91323 90.308 2.34188 90.0714 1.92062 89.6501C1.49936 89.2289 1.2627 88.6575 1.2627 88.0617V21.2639C1.2627 20.6681 1.49936 20.0968 1.92062 19.6755C2.34188 19.2542 2.91323 19.0176 3.50898 19.0176H102.314C102.909 19.0188 103.479 19.256 103.899 19.6771C104.319 20.0982 104.556 20.6689 104.556 21.2639V88.0617Z'
        fill='white'
      />
      <path
        d='M6.54327 21.9175H99.3806C99.7342 21.9175 100.073 22.058 100.323 22.308C100.573 22.558 100.714 22.8972 100.714 23.2508V85.8152C100.714 86.1689 100.573 86.508 100.323 86.758C100.073 87.0081 99.7342 87.1486 99.3806 87.1486H6.54327C6.18965 87.1486 5.85052 87.0081 5.60048 86.758C5.35043 86.508 5.20996 86.1689 5.20996 85.8152V23.2508C5.20996 22.8972 5.35043 22.558 5.60048 22.308C5.85052 22.058 6.18965 21.9175 6.54327 21.9175Z'
        fill='#E5E7EB'
      />
      <path
        d='M7.71807 23.418H98.1042C98.2793 23.418 98.4526 23.4525 98.6144 23.5195C98.7762 23.5865 98.9232 23.6847 99.047 23.8085C99.1708 23.9323 99.269 24.0793 99.336 24.241C99.403 24.4028 99.4375 24.5762 99.4375 24.7513V83.9772C99.4375 84.1523 99.403 84.3257 99.336 84.4874C99.269 84.6492 99.1708 84.7962 99.047 84.92C98.9232 85.0438 98.7762 85.142 98.6144 85.209C98.4526 85.276 98.2793 85.3105 98.1042 85.3105H7.71807C7.36446 85.3105 7.02533 85.17 6.77528 84.92C6.52524 84.6699 6.38477 84.3308 6.38477 83.9772V24.7513C6.38477 24.3977 6.52524 24.0585 6.77528 23.8085C7.02533 23.5584 7.36446 23.418 7.71807 23.418Z'
        fill='white'
      />
      <path
        d='M71.5377 64.5608C71.1819 64.5606 70.8302 64.4856 70.5052 64.3408C70.4296 64.3072 70.3575 64.2663 70.29 64.2185L63.7211 59.5946C63.388 59.3601 63.1161 59.049 62.9284 58.6874C62.7407 58.3258 62.6427 57.9244 62.6426 57.517V51.211C62.6427 50.8036 62.7407 50.4022 62.9284 50.0406C63.1161 49.679 63.388 49.3678 63.7211 49.1333L70.29 44.5094C70.3575 44.4617 70.4296 44.4207 70.5052 44.3871C70.8922 44.2151 71.316 44.1425 71.7381 44.1759C72.1602 44.2093 72.5673 44.3476 72.9224 44.5783C73.2775 44.8091 73.5693 45.1249 73.7713 45.497C73.9733 45.8692 74.0792 46.2859 74.0792 46.7094V62.0185C74.0792 62.6926 73.8114 63.339 73.3348 63.8156C72.8582 64.2923 72.2117 64.56 71.5377 64.56L71.5377 64.5608Z'
        fill='#5850EC'
      />
      <path
        d='M56.8359 65.8009H38.5149C34.7771 65.7978 31.748 63.3817 31.7441 60.4003V48.3283C31.748 45.3469 34.7771 42.9308 38.5149 42.9277H56.8837C60.5949 42.9312 63.6023 45.3301 63.6067 48.2902V60.4003C63.6028 63.3817 60.5737 65.7978 56.8359 65.8009Z'
        fill='#5850EC'
      />
      <path
        d='M89.4713 81.5022C92.4806 81.5022 94.9201 79.0626 94.9201 76.0533C94.9201 73.044 92.4806 70.6045 89.4713 70.6045C86.462 70.6045 84.0225 73.044 84.0225 76.0533C84.0225 79.0626 86.462 81.5022 89.4713 81.5022Z'
        fill='#12102F'
      />
      <path
        d='M92.3015 75.9558L87.9582 73.4482C87.9411 73.4383 87.9216 73.4331 87.9018 73.4331C87.882 73.4331 87.8626 73.4383 87.8455 73.4482C87.8283 73.4581 87.8141 73.4724 87.8042 73.4895C87.7943 73.5066 87.7891 73.5261 87.7891 73.5459V78.5611C87.7891 78.5809 87.7943 78.6003 87.8042 78.6175C87.8141 78.6346 87.8283 78.6489 87.8455 78.6588C87.8626 78.6687 87.882 78.6739 87.9018 78.6739C87.9216 78.6739 87.9411 78.6687 87.9582 78.6588L92.3015 76.1512C92.3187 76.1413 92.3329 76.127 92.3428 76.1099C92.3527 76.0927 92.3579 76.0733 92.3579 76.0535C92.3579 76.0337 92.3527 76.0143 92.3428 75.9971C92.3329 75.98 92.3187 75.9657 92.3015 75.9558L87.9582 73.4482C87.9411 73.4383 87.9216 73.4331 87.9018 73.4331C87.882 73.4331 87.8626 73.4383 87.8455 73.4482C87.8283 73.4581 87.8141 73.4724 87.8042 73.4895C87.7943 73.5066 87.7891 73.5261 87.7891 73.5459V78.5611C87.7891 78.5809 87.7943 78.6003 87.8042 78.6175C87.8141 78.6346 87.8283 78.6489 87.8455 78.6588C87.8626 78.6687 87.882 78.6739 87.9018 78.6739C87.9216 78.6739 87.9411 78.6687 87.9582 78.6588L92.3015 76.1512C92.3187 76.1413 92.3329 76.127 92.3428 76.1099C92.3527 76.0927 92.3579 76.0733 92.3579 76.0535C92.3579 76.0337 92.3527 76.0142 92.3428 75.9971C92.3329 75.98 92.3187 75.9657 92.3015 75.9558Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_310_8065'>
        <rect width='152' height='91.516' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const ErrorSVG: React.FC<React.PropsWithChildren<any>> = (props) => (
  <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20 27.5C19.8764 27.5 19.7555 27.5367 19.6528 27.6053C19.55 27.674 19.4699 27.7716 19.4226 27.8858C19.3753 28 19.3629 28.1257 19.387 28.2469C19.4111 28.3682 19.4706 28.4795 19.5581 28.5669C19.6455 28.6544 19.7568 28.7139 19.8781 28.738C19.9993 28.7621 20.125 28.7497 20.2392 28.7024C20.3534 28.6551 20.451 28.575 20.5197 28.4722C20.5883 28.3695 20.625 28.2486 20.625 28.125C20.625 27.9592 20.5592 27.8003 20.4419 27.6831C20.3247 27.5658 20.1658 27.5 20 27.5Z'
      stroke='#E02424'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M20 22.5V10' stroke='#E02424' strokeWidth='2.5' strokeMiterlimit='10' strokeLinecap='round' />
    <path
      d='M2.13324 22.1352C1.56767 21.5689 1.25 20.8013 1.25 20.001C1.25 19.2007 1.56767 18.4331 2.13324 17.8668L17.8666 2.13517C18.1465 1.85458 18.479 1.63197 18.8451 1.48008C19.2111 1.32818 19.6036 1.25 19.9999 1.25C20.3962 1.25 20.7887 1.32818 21.1548 1.48008C21.5208 1.63197 21.8533 1.85458 22.1332 2.13517L37.8666 17.8668C38.4321 18.4331 38.7498 19.2007 38.7498 20.001C38.7498 20.8013 38.4321 21.5689 37.8666 22.1352L22.1332 37.8668C21.5673 38.4323 20.8 38.7499 19.9999 38.7499C19.1999 38.7499 18.4325 38.4323 17.8666 37.8668L2.13324 22.1352Z'
      stroke='#E02424'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
