import * as React from 'react';
import { useEffect } from 'react';

import { Card } from '@components/common';

import { StudyPublishValidator } from '../../hooks/useStudyPublishValidator';
import { useDraftStudyContext } from '../../StudyDraft';
import { StepHelper, StepTitle } from '../shared';

import { GreenCheckRowItem, RequestFundInfo, WalletInfo } from './components';

interface Props {
  study: Study;
  validator: StudyPublishValidator;
}

export const Publish: React.FC<React.PropsWithChildren<Props>> = ({ validator, study }) => {
  const { funder, prefundLimit } = useDraftStudyContext();

  useEffect(() => {
    funder.prepare({ participant_limit: prefundLimit });
  }, [study, prefundLimit]);

  let fundInfo: React.ReactElement | null = null;
  switch (funder.mode) {
    case 'request':
      fundInfo = <RequestFundInfo amount={funder.displayTotal} study={study} />;
      break;
    case 'fund':
      fundInfo = <WalletInfo study={study} funder={funder} />;
      break;
  }

  return (
    <div className='pt-gutter'>
      <div className='text-center'>
        <StepTitle>Review and create</StepTitle>
        <StepHelper>No invites will be sent yet and you can modify anything later.</StepHelper>
      </div>
      <Card className='mx-auto max-w-3xl px-12'>
        {validator.items.map((item, i) => (
          <GreenCheckRowItem study={study} key={i} noBorder={i === validator.items.length - 1 && !fundInfo} {...item} />
        ))}
        {fundInfo}
        {study.state !== 'draft' && (
          <div className='mt-6 space-x-4 py-6 text-center'>
            <span className='h500'>Study is in {study.state} state.</span>
          </div>
        )}
      </Card>
    </div>
  );
};
