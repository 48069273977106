import * as React from 'react';

import { SlideOut, Text } from '@components/common';
import { CheckMark2SVG } from '@components/svgs';

const COLORS = [
  '374151', // gray
  'F05252', // red
  '0E9F6E', // green
  '3F83F8', // blue
  'FACA15', // yellow
  'E74694', // pink
  '9061F9', // purple
  '0694A2', // teal
  'FF5A1F' // orange
];

interface Props {
  guide: Guide;
  onChangeColor: (color: string) => void;
  onClose: () => void;
}
export const BackgroundSlideout: React.FC<React.PropsWithChildren<Props>> = ({ guide, onChangeColor, onClose }) => (
  <SlideOut onClose={onClose}>
    <div className='px-6'>
      <Text className='mb-10' h='700'>
        Select a background
      </Text>
      <div className='grid grid-cols-3 gap-4'>
        {COLORS.map((color, i) => (
          <div className='h-24' key={i}>
            <button
              aria-label={color}
              className='flex h-full w-full rounded-md p-2'
              style={{ backgroundColor: `#${color}` }}
              onClick={() => onChangeColor(color)}
            >
              {guide.bg_color === color && (
                <div className='flex h-6 w-6 items-center justify-center rounded-full bg-white'>
                  <CheckMark2SVG className='text-indigo-600' />
                </div>
              )}
            </button>
          </div>
        ))}
      </div>
    </div>
  </SlideOut>
);
