import { hasInterviewRoom } from '@components/StudiesApp/components/StudyPublished/utils';

interface Params {
  study: Study;
  activeParticipation: Participation;
}

export const getProfileSlideOutSections = ({ study, activeParticipation }: Params) => ({
  about: true,
  messages: { participation: activeParticipation },
  consent: { participation: activeParticipation },
  interviewDetails: hasInterviewRoom(activeParticipation) ? { participation: activeParticipation } : undefined,
  loomRecordingId: activeParticipation.loom_recording_id,
  preScreenerResponses: study.pre_screener && {
    participationId: activeParticipation.id,
    studyId: study.id,
    screenerId: study.pre_screener.id,
    title: 'Screener'
  },
  surveyScreenerResponses: study.survey_screener && {
    participationId: activeParticipation.id,
    studyId: study.id,
    screenerId: study.survey_screener.id,
    title: 'Survey'
  },
  generalSections: true,
  calendarEvents: study.style === 'video_call',
  bookingDetails: study.style === 'video_call'
});

export const statusFilter = (currentStatus: Participation['status']): ((party: Participation) => boolean) => {
  return ({ status }) => status === currentStatus;
};

export const getSortPrefix = (value, definitions) => {
  if (value === 'name') {
    return 'customer:name';
  } else if (definitions.some(({ id }) => id === `customer:${value}`)) {
    return `customer:${value}`;
  } else {
    return value;
  }
};
