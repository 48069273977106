import React, { FC, useState } from 'react';

import cn from 'classnames';
import pluralize from 'pluralize';
import Tippy from '@tippyjs/react';

import { EditableTitle, ErrorMessage, Text } from '@components/common';
import { ChevronDownSVG, TrashSVG } from '@components/svgs';
import { CardSortResult, EditCategoryArgs } from '@components/Unmoderated/types';

import { CardItem } from '../../shared';

interface Props {
  category: CardSortResult;
  cards: string[];
  onNameChange: (args: EditCategoryArgs) => void;
  dropProvided: any;
  categories: CardSortResult[];
  onCategorySelect: (params: {
    card: string;
    newCategory?: string;
    initialCategory?: string;
    categories: CardSortResult[];
  }) => void;
  isDraggingOver: boolean;
  onCategoryDelete: (category: CardSortResult) => void;
}

export const CategoryCard: FC<Props> = ({
  dropProvided,
  onNameChange,
  category,
  categories,
  onCategorySelect,
  cards,
  isDraggingOver,
  onCategoryDelete
}) => {
  const [expanded, setExpanded] = useState(true);
  const [titleError, setTitleError] = useState<'isEmpty' | 'isTaken'>();

  const onBlur = (value: string) => {
    if (value !== category.name) {
      if (value.trim() === '') {
        setTitleError('isEmpty');
      } else if (categories.some((c) => c.name === value.trim())) {
        setTitleError('isTaken');
      } else {
        onNameChange({ category: value.trim(), previous: category.name });
      }
    }
  };

  return (
    <div
      className={cn(
        'relative overflow-hidden rounded border bg-white p-4',
        isDraggingOver ? 'border-dashed border-indigo-600' : 'border-gray-200',
        expanded ? 'pt-16' : 'pt-12'
      )}
      ref={dropProvided.innerRef}
      {...dropProvided.droppableProps}
    >
      <div className='absolute left-4 right-4 top-4'>
        <div className='flex items-center justify-between space-x-1'>
          {category.custom ? (
            <EditableTitle
              size='sm'
              lineClamp='1'
              autofocus={category.custom}
              initialValue={category.name}
              onBlur={onBlur}
            />
          ) : (
            <Tippy content={category.name} arrow={false}>
              <div className='h500 truncate font-bold' id={category.name}>
                {category.name}
              </div>
            </Tippy>
          )}
          <button
            type='button'
            onClick={() => setExpanded(!expanded)}
            aria-label={expanded ? 'Collapse' : 'Expand'}
            className='flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full hover:bg-gray-100'
          >
            <ChevronDownSVG className={cn('h-4 w-4 transform', { ['rotate-180']: expanded })} />
          </button>
        </div>
        {titleError === 'isEmpty' && (
          <ErrorMessage className='mt-1' size='small'>
            The category name is required
          </ErrorMessage>
        )}
        {titleError === 'isTaken' && (
          <ErrorMessage className='mt-1' size='small'>
            The category name has already been taken
          </ErrorMessage>
        )}
      </div>
      {expanded && (
        <>
          {!cards.length ? (
            <div className='h400 mb-5 w-full rounded border border-dashed border-gray-200 py-2 text-center leading-6 text-gray-500'>
              This category is empty
            </div>
          ) : (
            <div className='flex flex-col'>
              {cards.map((card, i) => (
                <CardItem
                  category={category.name}
                  onCategorySelect={onCategorySelect}
                  key={card}
                  card={card}
                  categories={categories}
                  index={i}
                />
              ))}
            </div>
          )}
        </>
      )}
      <div className='mt-4 flex w-full items-center justify-between'>
        <Text h='200' className='text-gray-500'>
          {pluralize('cards', cards.length, true)}
        </Text>
        {category.custom && (
          <button
            type='button'
            aria-label='Delete category'
            onClick={() => onCategoryDelete(category)}
            className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-gray-100'
          >
            <TrashSVG className='text-gray-700' />
          </button>
        )}
      </div>
    </div>
  );
};
