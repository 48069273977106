import * as React from 'react';
import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { Input, Text } from '@components/common';

import { useBulkUploadActions } from '../../../context';

import { CellFC } from './types';

const NO_TITLE = 'Set title…';

export const Name: CellFC = ({ row: { original: item }, tabIndex }) => {
  const [value, setValue] = useState(item.session.title);
  const [editing, setEditing] = useState(false);

  const [updateRepoSession, { isLoading, isSuccess }] = api.useUpdateRepoSessionMutation();
  const { setItem } = useBulkUploadActions();

  useEffect(() => {
    if (editing && isSuccess) {
      setEditing(false);
    }
  }, [isSuccess]);

  if (editing) {
    return (
      <Input
        autoFocus
        className='h-10 w-40 truncate'
        textSize='base'
        disabled={isLoading}
        value={value}
        name='title'
        onChange={setValue}
        onBlur={() => {
          setItem({ ...item, session: { ...item.session, title: value } });
          updateRepoSession({ uuid: item.session.uuid, title: value });
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur();
          }
        }}
        tabIndex={tabIndex}
      />
    );
  }

  return (
    <Text
      tabIndex={tabIndex}
      className='w-40 cursor-text truncate px-3 py-2'
      color={item.session.title ? undefined : 'gray-500'}
      onClick={() => setEditing(true)}
      onFocus={() => setEditing(true)}
    >
      {item.session.title || NO_TITLE}
    </Text>
  );
};
