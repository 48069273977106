import isEqual from 'fast-deep-equal';
import { isPlainObject } from 'lodash';

export const filterUnchangedValues = (changes: any, original: any): any => {
  if (!original) return changes;

  return Object.entries(changes).reduce((acc, [key, value]) => {
    const originalValue = original[key];
    if (isPlainObject(value) && isPlainObject(originalValue)) {
      const filteredValue = filterUnchangedValues(value, originalValue);
      if (Object.keys(filteredValue).length > 0) {
        acc[key] = filteredValue;
      }
    } else if (!isEqual(value, originalValue)) {
      acc[key] = value;
    }
    return acc;
  }, {});
};
