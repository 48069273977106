import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Alert, AlertHeading, AlertMessage, Button } from '@components/common';

import { AlertProps } from '../types';

import { CalendarSVG } from './svgs';

// This should really only be when total === target?
export const Filled: React.FC<React.PropsWithChildren<AlertProps>> = ({ slots, study, canAction }) => {
  const navigate = useNavigate();
  function handleAction() {
    navigate(`/studies/${study.id}/plan`);
  }

  return (
    <Alert
      className='my-4'
      Icon={CalendarSVG}
      type='success'
      cta={canAction && <Button onClick={handleAction}> Increase limit </Button>}
    >
      <>
        <AlertHeading>All slots filled</AlertHeading>
        <AlertMessage>{`All ${slots.target} slots for this study have been booked or completed.`}</AlertMessage>
      </>
    </Alert>
  );
};
