import * as React from 'react';

import { Provider } from 'react-redux';

import { store } from '@api/reduxApi';
import { AppErroredPage } from '@components/AppErroredPage';
import { Loading } from '@components/common';
import { SentryProvider } from '@components/providers';
import { WhoamiContainer } from '@components/stores/whoami';
import { renderComp } from '@components/utils';
import { Provider as ToastProvider } from '@stores/toaster';
import { Provider as UserProvider } from '@stores/user';

import { NewCard } from './components/NewCard';

export function mountCard(): void {
  renderComp('NewCard', ({}) => (
    <Provider store={store}>
      <WhoamiContainer>
        {({ whoami, isLoading }) =>
          isLoading ? (
            <Loading />
          ) : (
            <UserProvider initialState={{ user: whoami.user, auth_method: whoami.auth_method }}>
              <SentryProvider whoami={whoami} renderComponent={(props) => <AppErroredPage {...props} />}>
                <ToastProvider>
                  <NewCard />
                </ToastProvider>
              </SentryProvider>
            </UserProvider>
          )
        }
      </WhoamiContainer>
    </Provider>
  ));
}
