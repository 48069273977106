import * as React from 'react';
import { useEffect, useState } from 'react';

import { CellProps, Row } from 'react-table';

import IndeterminateCheckbox from '@components/common/tables/IndeterminateCheckbox';
import { ParticipationTableItem } from '@components/StudiesApp/components/StudyPublished/ParticipationTable/helpers/buildParticipantsColumns';

import { OnHover, OnToggle } from '../../types';
import { NAME_CHECKBOX_STYLE } from '../constants';

interface Props extends React.PropsWithChildren<CellProps<Candidate | ParticipationTableItem>> {
  onClickCandidate: (row: Candidate | ParticipationTableItem) => void;
  onToggle: OnToggle;
  handleMouseOver: OnHover;
  hoveredRows: number[];
  setSelectedIds?: (params: {
    startIndex: number;
    endIndex: number;
    row: Row<Candidate | ParticipationTableItem>;
    rows: Row<Candidate | ParticipationTableItem>[];
    selectedIds?: number[];
  }) => void;
}

export const NameCell: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    row,
    value,
    onClickCandidate,
    onToggle,
    toggleRowSelected,
    setSelectedIds,
    rows,
    handleMouseOver,
    hoveredRows
  } = props;
  const [active, setActive] = useState(false);

  const index = rows.findIndex((i) => i === row);

  // Handling checkboxes border color changing on shift press
  useEffect(() => {
    if (hoveredRows.includes(index)) {
      setActive(true);
    }
  }, [hoveredRows]);

  const onClick: React.EventHandler<any> = (e) => {
    onToggle({
      index,
      e,
      toggleRowSelected,
      setSelectedIds,
      rows,
      row
    });
  };

  const onHover: React.EventHandler<any> = (e) => {
    handleMouseOver({
      index,
      e,
      rows,
      row
    });
  };

  return (
    <div className='xx-candidate-name flex h-full w-full items-center'>
      <div className='h400 flex h-full items-center justify-center bg-white' style={NAME_CHECKBOX_STYLE}>
        <IndeterminateCheckbox
          {...row.getToggleRowSelectedProps()}
          onClick={onClick}
          onMouseEnter={onHover}
          active={active}
        />
      </div>
      <div className='flex h-full flex-1 items-center overflow-hidden bg-white'>
        <div
          aria-label={value || 'Unnamed candidate'}
          className='xx-candidate-link h400 cursor-pointer truncate pl-2 font-bold text-indigo-600'
          onClick={(e) => {
            if (onClickCandidate) {
              e.preventDefault();
              onClickCandidate(row.original);
            }
          }}
        >
          {value || <span className='font-light'>Unnamed candidate</span>}
        </div>
      </div>
    </div>
  );
};
