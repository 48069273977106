import { useCallback, useEffect, useMemo, useState } from 'react';

type Hook<T> = [T | null, (value: T | null) => void];

interface CustomEventDetail<T> {
  value: T;
  key: string;
}

export enum StorageEvents {
  UPDATE = 'updateStorage'
}

export const useLocalStorage = <T = unknown>(key: string | null, defaultValue?: T): Hook<T> => {
  const localValue = useMemo<T | null>(() => {
    try {
      if (key) {
        const item = window.localStorage.getItem(key);

        try {
          return item ? JSON.parse(item) : (defaultValue ?? null);
        } catch (_error) {
          // if JSON.parse fails, return the item as is
          return item;
        }
      } else {
        return defaultValue ?? null;
      }
    } catch (error) {
      // catch any storage related errors
      console.error(error);
      return defaultValue ?? null;
    }
  }, [key, defaultValue]);

  const [value, setValue] = useState<T | null>(localValue);

  const setLocalValue = useCallback(
    (newValue: T | null) => {
      try {
        if (!key) return;

        if (newValue !== null) {
          window.localStorage.setItem(key, JSON.stringify(newValue));
        } else {
          window.localStorage.removeItem(key);
        }

        window.dispatchEvent(
          new CustomEvent<CustomEventDetail<T | null>>(StorageEvents.UPDATE, {
            detail: { key, value: newValue }
          })
        );
      } catch (error) {
        console.error(error);
      }
    },
    [key]
  );

  const handleStorageUpdate = useCallback(
    (event: CustomEvent<CustomEventDetail<T>>) => {
      if (event.detail.key === key) {
        setValue(event.detail.value);
      }
    },
    [setValue]
  );

  useEffect(() => {
    window.addEventListener(StorageEvents.UPDATE, handleStorageUpdate);

    return () => {
      window.removeEventListener(StorageEvents.UPDATE, handleStorageUpdate);
    };
  }, [handleStorageUpdate]);

  return [value, setLocalValue];
};
