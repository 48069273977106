// scripts for Capybara tests
import { polyfill } from 'es6-promise';
import LocalTime from 'local-time';
import { pdfjs } from 'react-pdf';
import { docReady } from 'src/docReady';
import { start as activeStorageStart } from '@rails/activestorage';
import Rails from '@rails/ujs';

import { mount as mountAccountDataRetentionApp } from 'components/AccountDataRetentionApp/mount';
import { mount as mountAccountLegalApp } from 'components/AccountLegalApp/mount';
import { mount as mountAccountSwitcherApp } from 'components/AccountSwitcher';
import { mount as mountSpa } from 'components/AppRouter';
import { mountCard } from 'components/Billing/mount';
import { mount as mountOnboarding } from 'components/Onboarding/mount';
import { mount as mountPlansApp } from 'components/PlansApp/mount';
import { mount as mountCandidateConsent } from 'components/Public/CandidateConsent/mount';
// TODO: Move this into AppRouter
import { mount as mountSurvey } from 'components/Public/GQSurvey/mount';
import { mount as mountScheduler } from 'components/Public/Scheduler/mount';
import { mount as mountAccountSidebar } from 'components/SettingsApp/components/mount';
import { mount as mountSettingsApp } from 'components/SettingsRouter';
import { mount as mountSidebarShim } from 'components/Sidebar/mount';
import { mountJillianVideo } from 'components/static/MarketingVideo';
import { mountDaniVideo } from 'components/static/MarketingVideo';
import { mount as mountStaticPricingPage } from 'components/static/pricing';

import './system';
import 'channels';
import 'src/sentry';
import 'src/datadog';
import 'stylesheets/application';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '@copilotkit/react-ui/styles.css';
import 'src/confirm';
import 'src/direct_uploads';
import 'src/forms';
import 'src/timezone';
import 'src/tooltips';
import 'src/clipboard';
import 'src/mobile_nav';
import 'src/tracking';
import 'src/password';
import 'controllers';

// Rails functionality
activeStorageStart();
window['Rails'] = Rails;

polyfill();
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

function onReady() {
  // new
  mountSpa();

  // old
  mountOnboarding();
  mountAccountLegalApp();
  mountAccountDataRetentionApp();
  mountSurvey();
  mountScheduler();
  mountSettingsApp();
  mountCandidateConsent();
  mountStaticPricingPage();
  mountPlansApp();
  mountAccountSidebar();
  // not a route
  mountJillianVideo();
  mountDaniVideo();
  mountSidebarShim();

  mountCard();

  mountAccountSwitcherApp();
}

docReady(onReady);

LocalTime.start();

Rails.start();
