import React, { ReactNode, useState } from 'react';

import cn from 'classnames';
import pluralize from 'pluralize';

import { Button, Option, PopperDropdown, PopperDropdownButton, Text } from '@components/common';
import { RadioInput } from '@components/common/RadioGroup';
import { PastParticipation as PastParticipationSVG } from '@components/StudiesApp/components/StudyPublished/pages/ExternalCandidatesRequest/components/svgs';
import { ChevronDownSVG } from '@components/svgs';

interface Props {
  icon: React.FC<React.PropsWithChildren<unknown>>;
  title: string;
  subtitle: ReactNode;
  true_label: string;
  false_label: string;
  disabled?: boolean;
  onChange: (v: boolean) => void;
  value: boolean;
}

export const BooleanField: React.FC<React.PropsWithChildren<Props>> = ({
  icon: Icon,
  title,
  subtitle,
  true_label,
  false_label,
  disabled,
  onChange,
  value
}) => {
  const TRUE_OPTION = 'true';
  const FALSE_OPTION = 'false';
  const RADIO_OPTIONS = [TRUE_OPTION, FALSE_OPTION];

  const [selectedOption, setSelectedOption] = useState<string>(value ? TRUE_OPTION : FALSE_OPTION);
  const [open, setOpen] = useState(false);

  const handleChangeOption = (v) => {
    setSelectedOption(v);
    onChange(v === TRUE_OPTION);
  };

  return (
    <div className='flex items-start pt-4'>
      <div className='flex flex-shrink-0 items-center justify-center p-1' onClick={() => setOpen(!open)}>
        <Icon />
      </div>
      <div className='ml-4 flex-1 flex-shrink-0'>
        <div className='flex flex-1 items-center justify-between'>
          <div>
            <Text bold onClick={() => setOpen(!open)}>
              {title}
            </Text>
            {!open && (
              <Text h='400' className='mt-1' onClick={() => setOpen(!open)}>
                {value ? true_label : false_label}
              </Text>
            )}
            {open && (
              <Text h='400' className='mt-1'>
                {subtitle}
              </Text>
            )}
          </div>
          <Button
            className='xx-toggle-boolean-field rounded-full pb-3 pl-3 pr-3 pt-3'
            text
            aria-label={title}
            onClick={() => setOpen(!open)}
          >
            <ChevronDownSVG className={cn('h-4 w-4 transform', { ['rotate-180']: open })} />
          </Button>
        </div>
        {open && (
          <div className={`mt-6 -space-y-px rounded-md bg-white ${disabled ? 'opacity-75' : ''}`}>
            {RADIO_OPTIONS.map((v, i) => {
              const label = v === TRUE_OPTION ? `Yes: ${true_label}` : `No: ${false_label}`;

              return (
                <div key={i} className='w-full'>
                  <label
                    className={cn(
                      'flex w-full flex-row items-start space-x-3',
                      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                      i == 2 && 'pt-2.5',
                      i == 0 && 'pb-1.5'
                    )}
                  >
                    <div className='flex items-center'>
                      <RadioInput
                        value={v}
                        name={v}
                        disabled={disabled}
                        onChange={(e) => handleChangeOption(e.target.value)}
                        checked={selectedOption === v}
                      />
                    </div>

                    <Text h='400'>{label}</Text>
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
