import React, {
  ChangeEventHandler,
  FocusEventHandler,
  MouseEventHandler,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

import cn from 'classnames';

import { AI, useLazySuggestions } from '@api/chat-gpt';
import { Text } from '@components/common';
import { Handle } from '@components/shared/AI/components/Title/Title';
import { AskAiSVG, ErrorSvg, RoundCheckMarkSVG, RoundCloseSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { useAccount } from '@hooks/useAccount';

export interface UseAiTitleProps<C extends Record<string, any>> {
  value?: string;
  bot: AI.UseQueryArg<C>['id'];
  context: AI.UseQueryArg<C>['context'];
  handle?: MutableRefObject<Handle<C> | undefined>;
  readOnly?: boolean;
  disableAi?: boolean;
  tooltip?: string;
  size?: 'sm' | 'lg' | 'xl';
  fetchOnMount?: boolean;
  autoAccept?: boolean;
  onSave?: (value: string) => void;
  onGetSuggestions?: () => void;
  onReject?: (id: number) => void;
  disabled?: boolean;
  resetValueOnUpdate?: boolean;
}

type Hook = {
  editable: boolean;
  value: string;
  handleOnInputClick: MouseEventHandler<HTMLDivElement>;
  handleOnBlur: FocusEventHandler<HTMLInputElement>;
  handleOnChange: ChangeEventHandler<HTMLInputElement>;
  AiActions: React.FC<React.PropsWithChildren<unknown>>;
};

const DEFAULT_TOOLTIP = 'Suggest title';

export const useAiTitle = <C extends Record<string, any>>({
  value: initialValue,
  bot,
  context,
  disableAi,
  fetchOnMount,
  handle,
  onGetSuggestions,
  disabled,
  readOnly,
  onSave,
  onReject,
  autoAccept,
  size = 'lg',
  tooltip = DEFAULT_TOOLTIP,
  resetValueOnUpdate = true
}: UseAiTitleProps<C>): Hook => {
  const { account } = useAccount();

  const [editable, setEditable] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialValue ?? '');
  const [showError, setShowError] = useState<boolean>(false);

  const cachedValue = useRef<string>(initialValue ?? '');

  const aiEnabled = account.ai_enabled;

  const {
    value: suggestions,
    isLoading,
    isError,
    fetch,
    accept,
    reject,
    clear
  } = useLazySuggestions<string, C>({ id: bot, context }, { skip: disableAi });
  const suggestion = suggestions && suggestions.length > 0 ? suggestions[0] : undefined;

  const handleOnInputClick: MouseEventHandler<HTMLDivElement> = () => {
    if (!readOnly) {
      setEditable(true);
    }
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.currentTarget.value);
  };

  const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    clear();
    setEditable(false);

    if (!e.currentTarget.value) {
      setValue(cachedValue.current);
      return;
    }

    cachedValue.current = e.currentTarget.value;

    if (e.currentTarget.value !== initialValue) {
      onSave?.(value);
    }
  };

  const handleOnGetSuggestions: MouseEventHandler<HTMLButtonElement> = () => {
    onGetSuggestions?.();

    if (!disabled) {
      fetch();
    }
  };

  const handleOnAccept: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (suggestion) {
      accept(suggestion.id);
      setValue(suggestion.result);
      onSave?.(suggestion.result);
    }
  };

  const handleOnReject: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (suggestion) {
      reject(suggestion.id);
      setValue(initialValue ?? '');
      onReject?.(suggestion.id);
    }
  };

  useEffect(() => {
    if (suggestion?.result) {
      setValue(suggestion.result);

      if (autoAccept) {
        accept(suggestion.id);
        onSave?.(suggestion.result);
      }
    }
  }, [suggestion?.result]);

  useEffect(() => {
    if (initialValue && resetValueOnUpdate) {
      setValue(initialValue);

      cachedValue.current = initialValue;
    }
  }, [initialValue]);

  useEffect(() => {
    if (isError) {
      setShowError(true);
    }

    const timeout = setTimeout(() => {
      setShowError(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isError]);

  useEffect(() => {
    if (aiEnabled && fetchOnMount) {
      fetch();
    }
  }, [fetchOnMount]);

  useImperativeHandle(handle, () => ({
    getSuggestions: (context?) => fetch(context)
  }));

  return {
    editable,
    value,
    handleOnInputClick,
    handleOnBlur,
    handleOnChange,
    AiActions: () => (
      <>
        {!disableAi && aiEnabled && !suggestion && !isLoading && !showError && (
          <span className='align-middle'>
            <Tooltip content={tooltip}>
              <button
                aria-label='Suggest title'
                className={cn('focus:outline-none mr-3 text-gray-700', {
                  'opacity-50': disabled,
                  'hover:text-indigo-600': !disabled,
                  'ml-4': size !== 'sm',
                  'ml-2': size === 'sm'
                })}
                onClick={handleOnGetSuggestions}
              >
                <AskAiSVG className='h-4 w-4' />
              </button>
            </Tooltip>
          </span>
        )}

        {isLoading && (
          <span
            className={cn('ml- inline-flex items-center bg-white align-middle text-indigo-600', {
              'my-1 ml-4 rounded px-4 py-2 shadow-md': size !== 'sm',
              'ml-2': size === 'sm'
            })}
          >
            <AskAiSVG className='mr-1 h-4 w-4' />
            <Text h={size === 'sm' ? '200' : '400'}>AI is generating...</Text>
          </span>
        )}

        {showError && (
          <span
            role='alert'
            aria-live='assertive'
            className={cn('inline-flex items-center bg-white align-middle text-red-600', {
              'my-1 ml-4 rounded px-4 py-2 shadow-md': size !== 'sm',
              'ml-2': size === 'sm'
            })}
          >
            <ErrorSvg className='mr-1 h-4 w-4' />
            <Text h={size === 'sm' ? '200' : '400'}>The bot is busy, please try again later</Text>
          </span>
        )}

        {suggestion && !isLoading && !autoAccept && (
          <span
            className={cn('inline-flex items-center space-x-2 bg-white align-middle text-indigo-600', {
              'my-1 ml-4 rounded px-4 py-2 shadow-md': size !== 'sm',
              'ml-2': size
            })}
          >
            <Tooltip content='Accept suggestion'>
              <button
                className='focus:outline-none flex items-center text-sm text-green-600 hover:underline'
                onClick={handleOnAccept}
                aria-label='Accept suggested title'
              >
                <RoundCheckMarkSVG />
              </button>
            </Tooltip>
            <Tooltip content='Reject suggestion'>
              <button
                className='focus:outline-none flex items-center text-sm text-red-600 hover:underline'
                onClick={handleOnReject}
                aria-label='Reject suggested title'
              >
                <RoundCloseSVG />
              </button>
            </Tooltip>
          </span>
        )}
      </>
    )
  };
};
