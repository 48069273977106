import React, { forwardRef, HTMLAttributes, useRef } from 'react';

import cn from 'classnames';
import mergeRefs from 'react-merge-refs';

import { useAccordionContext } from '../hooks/useAccordionContext';
import { useSectionContext } from '../hooks/useSectionContext';

export const Panel = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...rest }, ref) => {
    const { getPanelProps } = useAccordionContext();
    const sectionIndex = useSectionContext();
    const panelRef = useRef<HTMLDivElement>();

    return (
      <div
        ref={mergeRefs([panelRef, ref])}
        {...getPanelProps({ ref: panelRef, index: sectionIndex })}
        className={cn('overflow-hidden border-b border-gray-200 px-6 pb-5', className)}
        {...rest}
      >
        {children}
      </div>
    );
  }
);
