import * as React from 'react';
import { createContext, createRef, useContext, useEffect, useReducer, useRef } from 'react';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Notification } from '@components/common';
import { NotificationProps } from '@components/common/Notification';
import { NOTIFICATION_TIMEOUT } from '@components/config';
import { uid } from '@components/utils';

const ALERT_TRANSITION_CSS_CLASS = 'alert-transition';

// types
export type Toast = Pick<NotificationProps, 'heading' | 'text' | 'action' | 'actions' | 'icon' | 'timeoutSeconds'>;
type T = {
  stack: {
    id: number;
    toast: Toast;
    nodeRef: React.RefObject<any>;
    timeout?: NodeJS.Timeout | null;
  }[];
};
export const store = createContext<{
  state: T;
  dispatch: React.Dispatch<A>;
}>({
  state: { stack: [] },
  dispatch: () => null
});

// actions
const CLEAR = 'CLEAR';
const SET_TIMEOUT = 'SET_TIMEOUT';
const SHOW_TOAST = 'SHOW_TOAST';
export type A =
  | { type: typeof CLEAR; id: number }
  | { type: typeof SET_TIMEOUT; id: number; timeout: NodeJS.Timeout }
  | { type: typeof SHOW_TOAST; toast: Toast | null };

const reducer: React.Reducer<T, A> = (state, action) => {
  switch (action.type) {
    case CLEAR:
      return { stack: state.stack.filter((i) => i.id !== action.id) };
    case SET_TIMEOUT:
      return {
        stack: state.stack.map((i) => (i.id === action.id ? { ...i, timeout: action.timeout } : i))
      };
    case SHOW_TOAST:
      return {
        stack: action.toast ? [...state.stack, { id: uid(), nodeRef: createRef(), toast: action.toast }] : state.stack
      };
    default:
      return state;
  }
};

// hook
type ShowToast = (toast: Toast) => void;
type HideToast = () => void;
type ClearToastTimeout = () => void;

export const useToaster = (): ShowToast => {
  const { dispatch } = useContext(store);
  return (toast: Toast) => dispatch({ type: SHOW_TOAST, toast });
};

export const useToasterWithTimeout = (): [ShowToast, HideToast, ClearToastTimeout] => {
  const { dispatch } = useContext(store);
  const show = (toast: Toast) => dispatch({ type: SHOW_TOAST, toast });
  const hide = () => dispatch({ type: CLEAR, id: 1 });
  const clear = () => {
    //
  };

  return [show, hide, clear];
};

// provider
const { Provider: ProviderEl } = store;

export const Provider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer<React.Reducer<T, A>>(reducer, { stack: [] });

  useEffect(() => {
    let id: NodeJS.Timeout | undefined = undefined;

    for (const item of state.stack) {
      if (!item.timeout) {
        id = setTimeout(
          () => {
            dispatch({ type: CLEAR, id: item.id });
          },
          (item.toast.timeoutSeconds || NOTIFICATION_TIMEOUT) * 1000
        );

        dispatch({ type: SET_TIMEOUT, id: item.id, timeout: id });
        break;
      }
    }
  }, [state.stack]);

  if (process.env.NODE_ENV === 'test') {
    (window as any).clearToast = () => dispatch({ type: CLEAR, id: 1 });
  }

  return (
    <ProviderEl value={{ state, dispatch }}>
      <div className='tablet:p-6 tablet:items-start px-gutter py-gutter z-60 pointer-events-none fixed inset-0 flex flex-col items-center justify-end space-y-6'>
        <TransitionGroup component={null}>
          {state.stack.map(({ id, toast, nodeRef }) => (
            <CSSTransition key={id} appear nodeRef={nodeRef} timeout={200} classNames={ALERT_TRANSITION_CSS_CLASS}>
              <div className='w-full' ref={nodeRef}>
                <Notification key={id} {...toast} onClose={() => dispatch({ type: CLEAR, id })} />
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
      {children}
    </ProviderEl>
  );
};
