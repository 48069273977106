import * as React from 'react';
import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { Card, Select, Text, Toggle } from '@components/common';
import { Basic } from '@components/shared/Skeleton';

export const NotificationsIndex: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { data, isLoading } = api.useGetSlackConfigQuery();
  const [update] = api.useUpdateSlackConfigMutation();

  useEffect(() => {
    if (data) {
      setFrequency(data.digest_frequency || 'never');
      if (data.digest_frequency !== 'never') {
        setDigestEnabled(true);
      }
    }
  }, [data]);

  const isSlackConnected = data?.channel !== null;

  const [digestEnabled, setDigestEnabled] = useState(false);
  const [frequency, setFrequency] = useState<DigestFrequency>('never');

  const handleChangeFrequency = (value: DigestFrequency) => {
    setFrequency(value);
    update({ digest_frequency: value });
  };

  const handleToggle = (value: boolean) => {
    handleChangeFrequency(digestEnabled ? 'never' : 'daily');
    setDigestEnabled(value);
  };

  return (
    <>
      <Text as='h1' h='700' bold className='monitor:mb-6 mb-4 flex items-center'>
        Notifications
      </Text>

      <Card>
        {isLoading && (
          <>
            <Basic h='8' />
            <Basic h='8' />
          </>
        )}
        {!isLoading && (
          <>
            <div className='mb-6'>
              <Text mb='1'>
                Slack is {isSlackConnected && <strong>enabled</strong>}
                {!isSlackConnected && <span>not connected</span>} in this workspace.
              </Text>
              {isSlackConnected && <Text>Posting into {data?.channel}.</Text>}
            </div>
            <Text bold h='600' mb='4'>
              Research Summary
            </Text>
            <div className='mb-2 flex items-center'>
              <Text className='flex-1'>Post a research summary into Slack:</Text>
              <Toggle disabled={!isSlackConnected} on={digestEnabled && isSlackConnected} onToggle={handleToggle} />
            </div>
            <Select<DigestFrequency>
              wrapperClass='w-40'
              disabled={!digestEnabled || !isSlackConnected}
              options={[
                { value: 'daily', label: 'Daily' },
                { value: 'weekly', label: 'Weekly' },
                { value: 'monthly', label: 'Monthly' }
              ]}
              value={frequency}
              onChange={handleChangeFrequency}
            />
          </>
        )}
      </Card>
    </>
  );
};
