import * as React from 'react';
import { useState } from 'react';

import { useDropzone } from 'react-dropzone';

import { Alert, Button, Spinner, Text } from '@components/common';

interface Props {
  fileTypesText?: string | React.ReactNode;
  supportedFileTypes: string[];
  allowMultiple?: boolean;
  uploading?: boolean;
  onDrop: (files: File[]) => Promise<void> | void;
}
type MimeType =
  | 'video/mp4'
  | 'video/x-m4v'
  | 'video/quicktime'
  | 'audio/mpeg'
  | 'application/pdf'
  | 'image/jpeg'
  | 'image/png'
  | 'image/gif';

const ALL_TYPES = {
  'video/mp4': ['.mp4'],
  'video/x-m4v': ['.mv4'],
  'video/quicktime': ['.mov'],
  'audio/mpeg': ['.mp3'],
  'application/pdf': ['.pdf'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif']
};

type Accept = Partial<Record<MimeType, string[]>>;

const mapFileTypesToAccept = (fileTypes: string[]): Accept => {
  const accept: Accept = {};
  for (const fileType of fileTypes) {
    for (const [mimeType, exts] of Object.entries(ALL_TYPES)) {
      if (exts.includes(fileType)) {
        accept[mimeType] = accept[mimeType] || [];
        accept[mimeType].push(fileType);
      }
    }
  }
  return accept;
};

export const UploadFileZone: React.FC<React.PropsWithChildren<Props>> = ({
  fileTypesText,
  supportedFileTypes,
  allowMultiple,
  uploading,
  onDrop
}) => {
  const [error, setError] = useState<{
    heading: string;
    message: string;
  } | null>(null);

  function handleDrop(files: File[]) {
    if (supportedFileTypes) {
      for (const file of files) {
        if (!supportedFileTypes.some((ext) => file.name.toLowerCase().endsWith(ext))) {
          const parts = file.name.split('.');
          const badExt = parts.length > 1 ? `.${parts[parts.length - 1]} files` : 'this kind of file';

          setError({
            heading: `Sorry, we don’t support ${badExt}`,
            message: `Please upload a ${supportedFileTypes.join(', ')} file.`
          });
          return;
        }
      }
    }

    onDrop(files);
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openDropzone
  } = useDropzone({
    disabled: uploading,
    onDrop: handleDrop,
    noClick: true,
    accept: mapFileTypesToAccept(supportedFileTypes)
  });

  return (
    <>
      {error && (
        <Alert type='error' className='my-6'>
          <span className='mb-1 block font-bold'>{error.heading}</span>
          <span className='block'>{error.message}</span>
        </Alert>
      )}

      {!uploading && (
        <>
          <input {...getInputProps()} data-testid='upload-file-input' />

          <div
            {...getRootProps()}
            className='my-6 flex h-60 items-center justify-center rounded-lg border border-dashed border-indigo-600 bg-gray-50'
          >
            <div className='text-center'>
              {!isDragActive && (
                <>
                  <span className='block pb-2 font-bold text-gray-700'>
                    Drag &amp; drop {allowMultiple ? 'files' : 'a file'} here or{' '}
                    <span
                      tabIndex={0}
                      role='button'
                      onClick={openDropzone}
                      className='text-indigo-600 hover:text-indigo-700'
                    >
                      browse
                    </span>
                  </span>

                  {supportedFileTypes.length > 0 && !fileTypesText && (
                    <Text className='mt-2 text-sm text-gray-500'>We accept {supportedFileTypes.join(', ')} files.</Text>
                  )}
                </>
              )}
              {!uploading && isDragActive && (
                <span className='block pb-2 font-bold text-gray-700'>
                  Drop your {allowMultiple ? 'files' : 'file'} here!
                </span>
              )}
              {!uploading && fileTypesText && <div className='text-gray-500'>{fileTypesText}</div>}
              {uploading && <Spinner />}
            </div>
          </div>
        </>
      )}

      {uploading && (
        <div className='my-6 flex h-60 items-center justify-center rounded-lg bg-gray-50'>
          <Spinner className='h-10 w-10' />
        </div>
      )}
    </>
  );
};

export { Props as UploadFileZoneProps };
