import * as React from 'react';
import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Loading } from '@components/common';
import { track } from '@components/tracking';

import { useGuide } from './hooks/useGuide';
import { GuidePage } from './GuidePage';

export const GuidesShow: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { token } = useParams<{ token: string }>();
  const { loading, guide } = useGuide(token || '');

  useEffect(() => {
    track('viewed_guide', { token: token });
  }, []);

  return (
    <>
      {loading && <Loading absolute />}
      {guide && <GuidePage editable={!guide.published} guide={guide} />}
    </>
  );
};
