import * as React from 'react';

import { SUPPORT_EMAIL } from '@constants/emails';

import { Alert, CheckboxesGroup, Select, Text, Toggle } from '@components/common';
import { ROLE_OPTIONS } from '@components/GovernanceApp/components/constants';
import { DefaultShareAccessSelect, PermissionsMenu } from '@components/GovernanceApp/components/Permissions/components';
import { useGetProviderQuery } from '@components/GovernanceApp/components/Permissions/components';
import { SamlProvider } from '@components/GovernanceApp/components/Permissions/components';
import { RestrictedAction } from '@components/shared/RestrictedAction';
import { compact } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { useFeature } from '@hooks/useFeature';
import { usePlan } from '@hooks/usePlan';
import { useCurrentAuthMethod } from 'hooks/useCurrentAuthMethod';

import { DisabledFeatures } from '../DisabledFeatures';

export const Permissions: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    account: {
      domain,
      permissions,
      auth_methods,
      openid_tenant_id,
      interview_room_access,
      highlight_reel_access,
      clip_access,
      insight_access
    },
    update
  } = useAccount();

  const authMethod = useCurrentAuthMethod();

  const handleToggleAccess = async (v: boolean) => {
    const newVal = v ? 'observer' : null;
    await update({ permissions: { ...permissions, join_by_domain: newVal as any } });
  };

  const handleToggleFundStudy = async (v: boolean) => {
    const newVal = v ? 'admin' : 'creator';
    await update({ permissions: { ...permissions, fund_study: newVal } });
  };

  const handleToggleApproveStudies = async (v: boolean) => {
    const newVal = v ? 'admin' : 'creator';
    await update({ permissions: { ...permissions, approve_studies: newVal } });
  };

  const handleSetPermission = async (permission: string, role: AccountRole) => {
    await update({ permissions: { ...permissions, [permission]: role } });
  };

  const handleToggleInterviewRoomAccess = async (v: ShareAccess) => {
    await update({ interview_room_access: v });
  };

  const handleToggleHighlightReelAccess = async (v: ShareAccess) => {
    await update({ highlight_reel_access: v });
  };

  const handleToggleClipAccess = async (v: ShareAccess) => {
    await update({ clip_access: v });
  };

  const handleToggleInsightAccess = async (v: ShareAccess) => {
    await update({ insight_access: v });
  };

  const { hasFeature } = usePlan();
  const { data: samlProvider } = useGetProviderQuery();

  return (
    <>
      <Text as='h1' h='700' className='mb-1'>
        Permissions
      </Text>
      <Text h='500' color='gray-500' className='monitor:mb-6 mb-4'>
        Manage who can do what, authentication methods, and more.
      </Text>
      <div className='flex-1 rounded-md border border-gray-200 bg-white p-6'>
        <div className='mb-10'>
          <Text h='600' bold className='mb-1'>
            Workspace access
          </Text>
          <Text h='400' color='gray-500'>
            Enabling access allows users with an email address with this domain to join your workspace as observers
            without a direct invitation.
          </Text>
          {domain ? (
            <div className='mt-4 flex flex-row items-center space-x-2'>
              <Toggle on={!!permissions.join_by_domain} onToggle={handleToggleAccess} />
              <Text h='400'>
                Enable access for <b>{domain}.</b>
              </Text>
            </div>
          ) : (
            <Alert type='warning' className='mt-4'>
              You do not have a domain set on your account. Please contact {SUPPORT_EMAIL} to enable this feature.
            </Alert>
          )}
        </div>
        {/* When useFeature('team_access_control') is removed it should probably be replaced with useFeature('teams') */}
        {useFeature('team_access_control') && (
          <div className='mb-10'>
            <Text h='600' bold className='mb-1'>
              View all candidates
            </Text>
            <Text h='400' color='gray-500'>
              Which roles can view candidates outside of their team?
            </Text>
            <Select<AccountRole>
              className='max-w-64 mt-2 w-full'
              options={ROLE_OPTIONS}
              value={permissions.view_all_candidates}
              onChange={(r) => handleSetPermission('view_all_candidates', r)}
            />
          </div>
        )}
        <div className='mb-10'>
          <Text h='600' bold className='mb-1'>
            Visibility of candidate PII
          </Text>
          <Text h='400' color='gray-500'>
            Set which roles can see candidate's Personally Identifiable Information (PII)
          </Text>
          <Select<AccountRole>
            className='max-w-64 mt-2 w-full'
            options={ROLE_OPTIONS}
            value={permissions.view_pii}
            onChange={(r) => handleSetPermission('view_pii', r)}
          />
        </div>
        {/* When useFeature('team_access_control') is removed it should probably be replaced with useFeature('teams') */}
        {useFeature('team_access_control') && (
          <div className='mb-10'>
            <Text h='600' bold className='mb-1'>
              View all studies
            </Text>
            <Text h='400' color='gray-500'>
              Which roles can view studies and study artifacts outside of their team?
            </Text>
            <Select<AccountRole>
              className='max-w-64 mt-2 w-full'
              options={ROLE_OPTIONS}
              value={permissions.view_all_studies}
              onChange={(r) => handleSetPermission('view_all_studies', r)}
            />
          </div>
        )}
        <div className='mb-10'>
          <Text h='600' bold className='mb-2'>
            Visibility of repository artifacts
          </Text>
          <div className='mb-4'>
            <Text h='500' bold className='mb-1'>
              Interview room visibility
            </Text>
            <Text h='400' color='gray-500'>
              Default sharing settings for every interview room, including observer live streams.
            </Text>
            <div className='mt-4 flex flex-row items-center space-x-2'>
              <DefaultShareAccessSelect value={interview_room_access} onChange={handleToggleInterviewRoomAccess} />
            </div>
          </div>
          <div className='mb-4'>
            <Text h='500' bold className='mb-1'>
              Reel visibility
            </Text>
            <Text h='400' color='gray-500'>
              Default sharing settings for every highlight reel.
            </Text>
            <div className='mt-4 flex flex-row items-center space-x-2'>
              <DefaultShareAccessSelect value={highlight_reel_access} onChange={handleToggleHighlightReelAccess} />
            </div>
          </div>
          <div className='mb-4'>
            <Text h='500' bold className='mb-1'>
              Highlight visibility
            </Text>
            <Text h='400' color='gray-500'>
              Default sharing settings for every highlight.
            </Text>
            <div className='mt-4 flex flex-row items-center space-x-2'>
              <DefaultShareAccessSelect value={clip_access} onChange={handleToggleClipAccess} />
            </div>
          </div>
          <div className='mb-4'>
            <Text h='500' bold className='mb-1'>
              Insight visibility
            </Text>
            <Text h='400' color='gray-500'>
              Default sharing settings for every insight.
            </Text>
            <div className='mt-4 flex flex-row items-center space-x-2'>
              <DefaultShareAccessSelect value={insight_access} onChange={handleToggleInsightAccess} />
            </div>
          </div>
        </div>
        <div className='mb-10'>
          <Text h='600' bold className='mb-1'>
            Creating custom attributes
          </Text>
          <Text h='400' color='gray-500'>
            Set which roles can update and create new attribute definitions.
          </Text>
          <Select<AccountRole>
            className='max-w-64 mt-4 w-full'
            options={ROLE_OPTIONS}
            value={permissions.manage_attrs}
            onChange={(r) => handleSetPermission('manage_attrs', r)}
          />
        </div>
        <div className='mb-10'>
          <Text h='600' bold mb='4'>
            Authentication methods
          </Text>
          <Text h='400' color='gray-500' mb='4'>
            Specify which authentication methods can be used to accept invites to your workspace.
          </Text>
          <RestrictedAction feature='set_auth_methods'>
            {({ can, may }) => (
              <>
                <CheckboxesGroup
                  options={compact([
                    {
                      label: 'Email & Password',
                      value: 'password',
                      helper: 'Allow email and password for access to your workspace.',
                      disabled: authMethod === 'password'
                    },
                    {
                      label: 'Google SSO',
                      value: 'google',
                      helper: 'Members can sign in with a connected Google account.',
                      disabled: authMethod === 'google'
                    },
                    {
                      label: 'SAML',
                      value: 'saml',
                      helper: 'Integrate your workspace with SAML/OKTA.',
                      disabled: !samlProvider && authMethod === 'saml'
                    },
                    openid_tenant_id && {
                      label: 'Open ID',
                      value: 'openid_connect',
                      helper: 'Integrate your workspace with OpenID.',
                      disabled: authMethod === 'openid_connect'
                    }
                  ])}
                  selected={auth_methods}
                  disabled={!can || !may}
                  onChange={(v) => update({ auth_methods: v as AuthMethod[] })}
                />
                {!hasFeature('saml') && (
                  <Alert type='promo' className='mt-4'>
                    <span>
                      SAML integration is only available on Enterprise accounts.
                      <a href='/plans' className='ml-1 font-bold'>
                        View plans
                      </a>
                    </span>
                  </Alert>
                )}
              </>
            )}
          </RestrictedAction>
        </div>
        {hasFeature('saml') && (
          <div className='mb-10'>
            <Text h='600' bold mb='4'>
              SAML configuration
            </Text>
            <SamlProvider />
          </div>
        )}
        <div className='mb-10'>
          <Text h='600' bold className='mb-4'>
            Role configuration
          </Text>
          <RestrictedAction feature='set_auth_methods'>
            {({ can, may }) => (
              <div className='my-1 flex flex-row items-center space-x-2'>
                <Toggle
                  name='role_for_fund_study'
                  on={permissions.fund_study === 'admin'}
                  onToggle={handleToggleFundStudy}
                  disabled={!can || !may}
                />
                <Text h='400' color={can && may ? 'gray-700' : 'gray-500'}>
                  Require Admin approval for funding of studies.
                </Text>
              </div>
            )}
          </RestrictedAction>
          <RestrictedAction feature='set_auth_methods'>
            {({ can, may }) => (
              <div className='my-1 flex flex-row items-center space-x-2'>
                <Toggle
                  name='role_for_approve_studies'
                  on={permissions.approve_studies === 'admin'}
                  onToggle={handleToggleApproveStudies}
                  disabled={!can || !may}
                />
                <Text h='400' color={can && may ? 'gray-700' : 'gray-500'}>
                  Require Admin approval for activating studies.
                </Text>
              </div>
            )}
          </RestrictedAction>
        </div>
        <Text h='600' bold mb='4'>
          Tag management
        </Text>
        <Text h='400' color='gray-500' mb='4'>
          Set which roles can add, edit or delete tags.
        </Text>
        <PermissionsMenu />
        <div className='mt-10'>
          <DisabledFeatures />
        </div>
      </div>
    </>
  );
};
