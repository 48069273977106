import * as React from 'react';
import { forwardRef, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { DirectUpload } from '@rails/activestorage';
import Tippy from '@tippyjs/react';

import { Button, Loading, Modal } from '@components/common';
import { Actions, FormGroup, Input, Label, Submit } from '@components/fields';
import { usePermission } from '@hooks/usePermission';
import { api } from 'api/reduxApi';

import AssetsZDSIcon from './AssetsZDSIcon';
import ZDS from './ZDS';

const DocIcon: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM12 10H4V12H12V10ZM12 14H4V16H12V14ZM2 18H14V7H9V2H2V18Z'
      fill='#6B7280'
    />
  </svg>
);

const LinkIcon: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg
    className='fill-current'
    width='20'
    height='10'
    viewBox='0 0 20 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8ZM15 0H11V2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM14 4H6V6H14V4Z'
      fill='currentColor'
    />
  </svg>
);

const PlusIcon: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg
    className='stroke-current'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.8125 7.3125L9 4.5L6.1875 7.3125'
      stroke='currentColor'
      strokeWidth='1.125'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M9 12.9375V4.5' stroke='currentColor' strokeWidth='1.125' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M0.5625 9C0.5625 11.2378 1.45145 13.3839 3.03379 14.9662C4.61612 16.5486 6.76224 17.4375 9 17.4375C11.2378 17.4375 13.3839 16.5486 14.9662 14.9662C16.5486 13.3839 17.4375 11.2378 17.4375 9C17.4375 6.76224 16.5486 4.61612 14.9662 3.03379C13.3839 1.45145 11.2378 0.5625 9 0.5625C6.76224 0.5625 4.61612 1.45145 3.03379 3.03379C1.45145 4.61612 0.5625 6.76224 0.5625 9V9Z'
      stroke='currentColor'
      strokeWidth='1.125'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Header: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <h4 className='text-2xl font-normal text-gray-700'>{children}</h4>;
};

const FileUploader = forwardRef<HTMLInputElement, { customerId: number; onSuccess: (resp: any) => void }>(
  ({ customerId, onSuccess }, ref) => {
    const URL = '/rails/active_storage/direct_uploads';

    const [createCandidateFileAsset] = api.useCreateCandidateFileAssetMutation();

    async function onUpload({ signed_id, name }) {
      try {
        const resp = await createCandidateFileAsset({ candidate_id: customerId, signed_id, name });
        onSuccess(resp);
      } catch (error) {
        console.log('Error uploading file', error);
      }
    }

    function uploadFile(file) {
      const upload = new DirectUpload(file, URL, {
        // directUploadWillStoreFileWithXHR: (request) => {
        // request.upload.addEventListener('progress', event => {
        // setProgress({loaded: event.loaded, total: event.total})
        // })
        // }
      });
      upload.create((error, blob) => {
        if (error) {
          console.log('File upload error', error);
          // Handle the error
        } else {
          // setProgress({loaded: progress.total, total: progress.total})
          onUpload({ signed_id: blob.signed_id, name: file.name });
        }
      });
    }

    function handleFileChange(e) {
      Array.from(e.target.files).forEach((file) => uploadFile(file));
    }

    return <input type='file' className='hidden' ref={ref} multiple onChange={handleFileChange} />;
  }
);

function prependHttp(s) {
  const REGEX = /^https?:\/\//;
  return s.match(REGEX) ? s : 'http://' + s;
}

export const FileAsset: React.FC<React.PropsWithChildren<{ asset: any }>> = ({ asset }) => {
  return (
    <li className='group flex flex-row items-center px-6 py-4 hover:bg-gray-50'>
      <DocIcon />
      <a href={asset.url} target='_blank' className='ml-4 font-medium'>
        {asset.name}
      </a>
    </li>
  );
};

const ASSET_COMPS = {
  file: FileAsset
};
function buildAssets(assets, type) {
  return (assets || []).map((old) => {
    const a = { ...old };
    a.type = type;
    a.created_at = new Date(a.created_at);
    return a;
  });
}

function renderAsset(a) {
  const Asset = ASSET_COMPS[a.type];
  return <Asset key={`${a.title}${a.id}${a.type}`} asset={a} />;
}

interface Props {
  customerId: number;
  participationId?: number;
  fileAssets: any[];
  onUpdate: (resp: any) => void;
}
export const Assets: React.FC<React.PropsWithChildren<Props>> = ({
  customerId,
  participationId,
  fileAssets = [],
  onUpdate
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const canCreate = usePermission('canCreate')();

  const assets = buildAssets(fileAssets, 'file');

  function handleAddFile() {
    fileInputRef.current && fileInputRef.current.click();
  }

  function handleSuccess(resp) {
    onUpdate(resp);
    setShowLinkModal(false);
  }

  return (
    <div>
      {canCreate && (
        <div className='absolute right-0 top-0 mr-6 mt-6 flex flex-row items-center space-x-6'>
          <FileUploader customerId={customerId} ref={fileInputRef} onSuccess={onUpdate} />
          <Tippy content='Upload file' arrow={false}>
            <div className='xx-add-file-asset cursor-pointer text-indigo-600' onClick={handleAddFile}>
              <PlusIcon />
            </div>
          </Tippy>
        </div>
      )}
      {assets.length === 0 ? (
        <ZDS Icon={AssetsZDSIcon}>Attach files assets.</ZDS>
      ) : (
        <ul>{assets.map((a) => renderAsset(a))}</ul>
      )}
    </div>
  );
};
