import * as React from 'react';
import { useState } from 'react';

import { Dropdown, DropdownLink } from '@components/common';
import { RegroupSVG } from '@components/svgs';
import { useDeviceType } from '@hooks/useDeviceType';

import { ConfirmRegroupModal } from './ConfirmRegroupModal';

type Option = { label: string; value: string; Icon: React.FC<React.PropsWithChildren<unknown>> };

type Props = {
  options: Option[];
  onClick: (value: string) => void;
};

export const RegroupBy: React.FC<React.PropsWithChildren<Props>> = ({ options, onClick }) => {
  const [open, setOpen] = useState(false);

  const [option, setOption] = useState<Option | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { isMobile } = useDeviceType();
  const handleClickRegroupBy = (option: Option) => {
    setOption(option);
    setShowConfirmModal(true);
  };
  const submitRegroupBy = (value: string) => {
    onClick(value);
    setOpen(false);
    setShowConfirmModal(false);
  };

  return (
    <>
      {showConfirmModal && option && (
        <ConfirmRegroupModal
          groupLabel={option.label}
          onSubmit={() => submitRegroupBy(option.value)}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
      <Dropdown
        medium
        text={
          <div className='flex items-center space-x-2'>
            <RegroupSVG />
            {!isMobile && <span className='font-normal'>Re-group by</span>}
          </div>
        }
        buttonClassName='bg-white font-normal'
        menuClassName='divide-y divide-gray-200'
        isOpen={open}
        onClick={() => setOpen(!open)}
        onClose={() => setOpen(false)}
      >
        {options.map((option) => (
          <DropdownLink key={option.value} onClick={() => handleClickRegroupBy(option)}>
            <div className='flex items-center space-x-2'>
              <option.Icon />
              <span>{option.label}</span>
            </div>
          </DropdownLink>
        ))}
      </Dropdown>
    </>
  );
};
