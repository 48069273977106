import * as React from 'react';
import { useRef } from 'react';

import { SlideOut } from '@components/common';
import { ArtifactWithDetails } from '@components/HighlightReelsApp/hooks/useHighlightReelsContext';
import { useContentRenderer } from '@components/RepositoryApp/components/PreviewContentSlideout/hooks/useContentRenderer';
import { ArtifactHit } from '@components/RepositoryApp/types';
import { AlgoliaData } from '@components/shared/Search';
import { UseVideoPlayer } from '@components/VideoPlayer';

import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { FooterSkeleton } from './components/skeletons/FooterSkeleton';
import { VideoPreview } from './components/VideoPreview';

export type HighlightArtifact = ArtifactWithDetails & {
  documentId?: number;
  highlightId?: number;
};

type Props = {
  artifact: HighlightArtifact;
  closeSlideout: () => void;
  reelPreviewProps?: { onAddToHighlightReel: () => void; onRemove: () => void };
  setData?: React.Dispatch<React.SetStateAction<AlgoliaData>>;
};

export const PreviewContentSlideout: React.FC<React.PropsWithChildren<Props>> = ({
  reelPreviewProps,
  artifact,
  closeSlideout,
  setData
}) => {
  const ref = useRef<UseVideoPlayer>(null);
  const pauseVideo = () => {
    ref.current?.pause();
  };

  const { renderData, isLoading } = useContentRenderer(artifact);

  const { canUpdate, model, content, canDelete, skeleton, downloadUrl, updateModel } = renderData;
  const updateData = (data: Partial<ArtifactHit>) => {
    updateModel(data);

    setData?.((prev) => {
      const currentIndex = prev.hits.findIndex((curr) => curr.objectID === artifact.objectID);
      if (currentIndex < 0) return prev;

      const newHits = [...prev.hits];

      newHits[currentIndex] = { ...newHits[currentIndex], ...data };

      return { ...prev, hits: newHits };
    });
  };

  const type = artifact.objectID.split('_')[0];

  const shouldVideoPreview = ['Repo::Session', 'Clip', 'HighlightReel', 'Story'].includes(type);

  return (
    <SlideOut
      size='xl'
      title={artifact.kind === 'HighlightReel' || artifact.kind !== 'ScreenerResponse' ? <></> : artifact.title}
      noHeader
      zIndex='50'
      className='overflow-y-scroll'
      onClose={closeSlideout}
      renderFooter={() =>
        model ? (
          <Footer
            reelPreviewProps={reelPreviewProps}
            pauseVideo={pauseVideo}
            isLoading={isLoading}
            access={model?.access}
            model={'uuid' in model ? (model as DocumentHighlight) : undefined}
            canUpdate={canUpdate}
            canDelete={canDelete}
            artifact={artifact}
            views={model.views}
            href={artifact.href}
            downloadUrl={downloadUrl}
            onClose={closeSlideout}
            updateModel={updateModel}
          />
        ) : (
          <FooterSkeleton />
        )
      }
    >
      {shouldVideoPreview && (
        <div className='px-6 pt-12'>
          <VideoPreview artifact={artifact} ref={ref} page={reelPreviewProps ? 'reels_editor' : 'repository'} />
        </div>
      )}
      <Header updateData={canUpdate ? updateData : undefined} artifact={artifact} studyId={artifact.study_ids?.[0]} />

      {isLoading ? skeleton : content}
    </SlideOut>
  );
};
