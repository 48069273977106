import * as React from 'react';
import { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { Card, Input, InputWithAddons } from '@components/common';
import { blurOnEnter, ChangeFn, getChangeFn } from '@components/utils';

import { FormGroup, Helper, Label } from './Plan/components/IncventivesOptions';
import { StepHelper, StepTitle } from './shared';

export const Task: React.FC<React.PropsWithChildren<PageProps>> = ({ study, onSave }) => {
  const [externalUrl, setExternalUrl] = useState(study.external_url);

  const change = getChangeFn<Study>(study, onSave);

  const debounced = useDebouncedCallback(change as ChangeFn, 300);

  const debouncedChange: ChangeFn = debounced.callback;

  return (
    <div className='pt-gutter'>
      <div className='text-center'>
        <StepTitle>Set up your task</StepTitle>
        <StepHelper>If you’re not ready, you can skip ahead to complete other parts of the setup process.</StepHelper>
      </div>
      <Card className='mx-auto max-w-3xl px-12 py-8'>
        <FormGroup>
          <Label>Task link</Label>
          <Helper>This will be shared with candidates you invite to the study.</Helper>
          <Input
            type='text'
            className='w-96 border border-gray-200 px-4 py-2'
            name='external_url'
            value={externalUrl || ''}
            onChange={(value) => {
              setExternalUrl(value);
              debouncedChange('external_url', value);
            }}
            placeholder='e.g. UsabilityHub.com/xyz'
            {...blurOnEnter()}
          />
        </FormGroup>
      </Card>
    </div>
  );
};
