import React from 'react';

import { format as timeagoFormat } from 'timeago.js';

import { getMessage } from '@components/shared/BackgroundTaskStatus/constants';
import { Avatar, Text } from 'components/common';
import { useAccount } from 'hooks/useAccount';
const IGNORE = ['calendars_sync'];

import Tippy from '@tippyjs/react';

const StatusIcon: React.FC<React.PropsWithChildren<{ status: BackgroundTask['status'] }>> = ({ status }) => {
  const getStatusTooltip = (status: BackgroundTask['status']) => {
    switch (status) {
      case 'finished':
        return 'Task finished successfully';
      case 'failed':
        return 'Task failed';
      case 'cancelled':
        return 'Task was cancelled';
      case 'processing':
        return 'Task is currently processing';
      case 'pending':
        return 'Task is pending';
      default:
        return 'Unknown status';
    }
  };

  const icon = (() => {
    if (status === 'finished') return <span className='text-green-500'>✅</span>;
    if (status === 'failed' || status === 'cancelled') return <span className='text-red-500'>❌</span>;
    if (status === 'processing') return <span className='text-blue-500'>🔄</span>;
    if (status === 'pending') return <span className='text-gray-500'>⏳</span>;
    return <span className='text-gray-500'>⏳</span>;
  })();

  return <Tippy content={getStatusTooltip(status)}>{icon}</Tippy>;
};

export const BackgroundTaskItem: React.FC<React.PropsWithChildren<{ task: BackgroundTask }>> = ({ task }) => {
  const message = getMessage(task.action);
  const { getUserById } = useAccount();
  const user = getUserById(task.user_id);
  if (IGNORE.includes(task.action)) return null;
  return (
    <li className='flex flex-col border-b border-gray-200 px-6 py-4'>
      <div className='mb-2 flex items-center justify-between space-x-4'>
        <Text bold>{message.title}</Text>
        <StatusIcon status={task.status} />
      </div>
      <Text h='200'>{message.heading}</Text>
      <div className='mt-4 flex items-center justify-between'>
        {user && (
          <div className='flex items-center space-x-2'>
            <Avatar user={user} />
            <Text>{user?.name}</Text>
          </div>
        )}
        <Text h='200'>{timeagoFormat(new Date(task.updated_at))}</Text>
      </div>
    </li>
  );
};
