import * as React from 'react';

import { Text } from '@components/common';

import { DEFAULT_TITLES } from '../consts';

interface Props {
  className?: string;
  template: AbstractTemplate;
  kind: TemplateKind;
}
export const PublishedTitle: React.FC<React.PropsWithChildren<Props>> = ({ className, template, kind }) => {
  return (
    <Text h='800' className={className}>
      {template.title || DEFAULT_TITLES[kind]}
    </Text>
  );
};
