import * as React from 'react';

import { Portal } from '@components/Portal';
import { ScreenerResponsesZDS } from '@components/ScreenerResults/components/ScreenerResponsesZDS';
import { ScreenerChartWithTitle as ScreenerChart } from '@components/ScreenerResults/ScreenerChart';
import { Card } from '@components/shared/Skeleton';
import { PrototypeTaskMetricsSpriteSVG } from '@components/svgs';

interface Props {
  results: ScreenerResponseResults['summary'];
  isLoading?: boolean;
  withBorders?: boolean;
}

export const ScreenerResultsSummary: React.FC<React.PropsWithChildren<Props>> = ({
  withBorders,
  isLoading,
  results
}) => {
  const noResults = results.every((result) => result.answers.length === 0);

  const onlyEmptyResults =
    !!results.length &&
    results.every((result) =>
      ['info', 'start_loom', 'stop_loom', 'task', 'permissions'].includes(result.field.field_type)
    );

  if (isLoading)
    return (
      <div className='h-full'>
        <Card height='100%' />
      </div>
    );

  if (noResults || onlyEmptyResults) {
    return (
      <ScreenerResponsesZDS
        tab='summary'
        type={onlyEmptyResults ? 'no_answers' : 'no_results'}
        withBorders={withBorders}
      />
    );
  }

  return (
    <div className='rounded border border-gray-200 bg-white p-6'>
      {results.map((result) => (
        <ScreenerChart
          id={result.field.id}
          key={result.field.id}
          title={result.field.label}
          type={result.field.field_type}
          answers={result.answers}
          blockableId={result.blockable_id}
        />
      ))}
      <Portal>
        {/* Prototype task metrics icons sprite */}
        <PrototypeTaskMetricsSpriteSVG style={{ display: 'none' }} />
      </Portal>
    </div>
  );
};
