import React, { forwardRef, HTMLAttributes, MouseEvent, MouseEventHandler, useLayoutEffect, useRef } from 'react';

import mergeRefs from 'react-merge-refs';

import { useVideoSeekBarContext } from '../hooks/useVideoSeekBarContext';

interface Props extends HTMLAttributes<HTMLDivElement> {
  onTimeChange?: (time: number) => void;
}

export const Track = forwardRef<HTMLDivElement, Props>(({ children, onTimeChange, ...rest }, ref) => {
  const innerRef = useRef<HTMLDivElement>(null);

  const {
    state: { currentTime, isDragging, isHovering, hoverTime, max, trackX },
    setIsDragging,
    setIsHovering,
    setTrackWidth,
    setTrackX,
    setTrackY,
    setHoverTime,
    setCurrentTime
  } = useVideoSeekBarContext();

  const getPositionPercentage = (event: MouseEvent<HTMLDivElement>) => {
    const { pageX } = event;

    if (innerRef.current) {
      const { offsetWidth } = innerRef.current;

      let value = ((pageX - trackX) / offsetWidth) * 100;
      if (value <= 0) value = 0;
      if (value >= 100) value = 100;

      return Math.floor(value);
    }

    return 0;
  };

  const handleOnMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
    const position = getPositionPercentage(event);
    const time = (position * max) / 100;

    setCurrentTime(time);
    onTimeChange?.(time);
    setIsDragging(true);
  };

  const handleOnMouseUp: MouseEventHandler<HTMLDivElement> = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  const handleOnMouseMove: MouseEventHandler<HTMLDivElement> = (event) => {
    if (isHovering) {
      const position = getPositionPercentage(event);
      const time = (position * max) / 100;

      setHoverTime(time);

      if (isDragging) {
        setCurrentTime(time);
        onTimeChange?.(time);
      }
    }
  };

  useLayoutEffect(() => {
    if (innerRef.current) {
      setTrackWidth(innerRef.current.offsetWidth);
      setTrackX(innerRef.current.getBoundingClientRect().left);
      setTrackY(innerRef.current.getBoundingClientRect().top);
    }
  }, [hoverTime]);

  return (
    <div
      ref={mergeRefs([innerRef, ref])}
      role='slider'
      tabIndex={0}
      aria-valuemin={0}
      aria-valuemax={max}
      aria-valuenow={currentTime}
      className='relative h-1 w-full transform cursor-pointer hover:scale-y-150'
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onMouseDown={handleOnMouseDown}
      onMouseUp={handleOnMouseUp}
      onMouseMove={handleOnMouseMove}
      {...rest}
    >
      {children}
    </div>
  );
});
