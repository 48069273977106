import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { Loading } from '@components/common';
import { BackgroundTasks } from '@components/shared/BackgroundTasks';
import { useToaster } from '@components/stores/toaster';
import { useRecruitingRequest } from '@components/StudiesApp/components/StudyDraft/hooks/useRecruitingRequest';
import { kFormat } from '@components/utils';
import { useFeature } from '@hooks/useFeature';

import { useGetStudyBackgroundTasksQuery } from '../../api';

import { ExternalCandidatesRequestForm } from './components/ExternalCandidatesRequestForm';

export const ExternalCandidatesRequest: React.FC<React.PropsWithChildren<{ study: Study }>> = ({ study }) => {
  const studyId = study.id;

  const params = useParams();

  const navigate = useNavigate();
  const showToast = useToaster();

  const [inlineSave, setInlineSave] = useState<boolean | undefined>();

  const handleUpdatedRequest = (modifiedRequest: ExternalCandidatesRequest) => {
    if (inlineSave) {
      setBackgroundTasks(modifiedRequest.background_tasks || []);
      return;
    }

    if (modifiedRequest.valid_request) {
      navigate(`/studies/${studyId}/recruitment_requests`);
    } else if (modifiedRequest.request_errors?.length) {
      showToast({
        heading: 'Error submitting request:',
        text: modifiedRequest.request_errors.join('; '),
        icon: 'error'
      });
    } else {
      showToast({
        heading: 'Error submitting request.',
        text: 'Please review required fields and try again.',
        icon: 'error'
      });
    }
  };

  const { request, refetchRequest, handleChange, requestInitialized, isLoading } = useRecruitingRequest({
    study: study,
    requestId: Number(params.externalCandidatesRequestId),
    handleUpdatedRequest
  });

  const [eligibleAudience, setEligibleAudience] = useState<number | undefined>();

  useEffect(() => {
    if (request && inlineSave) {
      const oldVal = eligibleAudience;
      const newVal = request.attrs.eligible_audience;
      const oldStr: string = oldVal !== undefined ? kFormat(oldVal) : '';
      const newStr: string = newVal !== undefined ? kFormat(newVal) : '';

      if (oldStr && newStr && newStr !== oldStr) {
        showToast({
          heading: 'Audience estimate updated:',
          text: `Changed from ${oldStr} to ${newStr}`,
          icon: 'success'
        });
      } else if (newStr) {
        showToast({
          heading: 'Audience estimate added:',
          text: `Set to ${newStr}`,
          icon: 'success'
        });
      }
      setEligibleAudience(newVal);
    }
  }, [request?.attrs.eligible_audience]);

  const [backgroundTasks, setBackgroundTasks] = useState<BackgroundTask[]>(request?.background_tasks || []);

  const { callback: debouncedHandleChange } = useDebouncedCallback(handleChange, 1000);

  return (
    <div className='px-page py-gutter'>
      {isLoading && <Loading absolute />}
      <BackgroundTasks
        onFinished={refetchRequest}
        setBackgroundTasks={setBackgroundTasks}
        backgroundTasks={backgroundTasks}
        params={{ objectId: studyId }}
        backgroundTasksQuery={useGetStudyBackgroundTasksQuery}
        hidden={true}
      />
      {requestInitialized && request && (
        <ExternalCandidatesRequestForm
          study={study}
          request={request}
          onChange={(v) => {
            setInlineSave(true);
            setEligibleAudience(request.attrs.eligible_audience);
            debouncedHandleChange(v);
          }}
          onSave={(v) => {
            setInlineSave(false);
            handleChange(v);
          }}
          onCancel={() => navigate(`/studies/${studyId}/recruitment_requests`)}
        />
      )}
    </div>
  );
};
