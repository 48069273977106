import * as React from 'react';

import { Alert, Toggle } from '@components/common';
import { Tooltip } from '@components/Tooltip';
import { uniq, without } from '@components/utils';
import { usePlan } from 'hooks/usePlan';

interface Props {
  disabled?: boolean;
  study: Study;
  options: PlanOption[];
  setOptions: (v: PlanOption[]) => void;
}

export const ExternalCandidatesOptions: React.FC<React.PropsWithChildren<Props>> = ({
  disabled,
  study,
  setOptions,
  options
}) => {
  const { hasFeature } = usePlan();

  const hasExternalRecruit = hasFeature('external_recruitment');

  const enabled = options.includes('external_candidates');
  const preventToggle = enabled && study.currently_active_external_candidates_requests_count > 0;
  const tooltip = 'With active recruitment requests this setting cannot be changed.';

  function handleToggle(v: boolean) {
    if (v) {
      // External recruits also require screeners and incentives
      setOptions(uniq([...options, 'external_candidates', 'incentive', 'screener']));
    } else {
      setOptions(without(options, 'external_candidates'));
    }
  }
  return (
    <div className='border-b border-gray-200 py-6'>
      <div className='flex justify-between'>
        <div className='h500-bold flex items-center'>Recruit external candidates</div>

        <Tooltip isDisabled={!preventToggle} content={tooltip}>
          <Toggle
            disabled={disabled || preventToggle}
            testId='external-candidates-toggle'
            name='ExternalCandidates'
            on={enabled}
            onToggle={handleToggle}
          />
        </Tooltip>
      </div>
      <span className='h400 block text-gray-500'>
        We partner with Respondent to help you recruit B2B or B2C candidates.{' '}
        <a href='https://greatquestion.co/support/studies/external-participant-recruitment' target='_blank'>
          Learn more
        </a>
      </span>
      {!hasExternalRecruit && enabled && (
        <Alert type='warning' className='mt-4'>
          External recruitment is only available on paid accounts. You will be prompted to subscribe on publish of your
          recruitment.
        </Alert>
      )}
    </div>
  );
};
