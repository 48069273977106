import { api as reduxApi } from '@api/reduxApi';
import { useFeature } from 'hooks/useFeature';

type UseParticipantsCounts = {
  invitableCount: number;
  ineligibleCount: number;
  contactableCount: number;
  reinviteableCount: number;
  isLoading: boolean;
};

interface Props {
  studyId: number;
  query?: ServerFilterQuery;
  ids?: number[];
  skipInvitable?: boolean;
  skipIneligible?: boolean;
  skipContactable?: boolean;
  skipReinviteable?: boolean;
}

export const useParticipantsCounts = ({
  studyId,
  query,
  ids,
  skipIneligible,
  skipInvitable,
  skipContactable,
  skipReinviteable
}: Props): UseParticipantsCounts => {
  const serversideParticipantsEnabled = useFeature('serverside_participations');

  const { data: invitable, isLoading: invitableLoading } = reduxApi.useGetParticipationsPagedQuery(
    {
      study_id: studyId,
      fields: ['id'],
      page: 1,
      query: {
        filters: [...(query?.filters || []), 'inviteable is true'],
        searchQuery: query?.q
      },
      ids,
      items: 1
    },
    { skip: !serversideParticipantsEnabled || skipInvitable }
  );

  const { data: ineligible, isLoading: ineligibleLoading } = reduxApi.useGetParticipationsPagedQuery(
    {
      study_id: studyId,
      fields: ['id'],
      page: 1,
      query: {
        filters: [...(query?.filters || []), 'customer:eligibility is false'],
        searchQuery: query?.q
      },
      ids,
      items: 1
    },
    { skip: !serversideParticipantsEnabled || skipIneligible }
  );

  const { data: contactable, isLoading: contactableLoading } = reduxApi.useGetParticipationsPagedQuery(
    {
      study_id: studyId,
      fields: ['id'],
      page: 1,
      query: {
        filters: [...(query?.filters || []), 'customer:contactable is true'],
        searchQuery: query?.q
      },
      ids,
      items: 1
    },
    { skip: !serversideParticipantsEnabled || skipContactable }
  );

  const { data: reinviteable, isLoading: reinviteableLoading } = reduxApi.useGetParticipationsPagedQuery(
    {
      study_id: studyId,
      fields: ['id'],
      page: 1,
      query: {
        filters: [...(query?.filters || []), 'reinviteable is true'],
        searchQuery: query?.q
      },
      ids,
      items: 1
    },
    { skip: !serversideParticipantsEnabled || skipReinviteable }
  );

  return {
    invitableCount: invitable?.meta.count || 0,
    ineligibleCount: ineligible?.meta.count || 0,
    contactableCount: contactable?.meta.count || 0,
    reinviteableCount: reinviteable?.meta.count || 0,
    isLoading: reinviteableLoading || invitableLoading || ineligibleLoading || contactableLoading
  };
};
