import * as React from 'react';

import { ButtonProps } from '@components/common/Button';
import { capitalizeAllWords, compact } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { useUser } from '@hooks/useUser';

import { BookabilityRuleAlert } from './BookabilityRuleAlert';

export const ModeratorsNeedConferencingAlert: BookabilityRuleAlertComponent = ({ level, extra, onClickCta }) => {
  const {
    account: { team }
  } = useAccount();
  const user = useUser();
  const userIds = extra?.user_ids || [];
  const users = compact(userIds.map((id) => team.find((u) => u.id === id)));

  const platformWithSpaces = extra?.platform?.replace(/_/g, ' ') || 'video conferencing';
  const videoPlatform = capitalizeAllWords(platformWithSpaces);

  let heading = `Moderators’ ${videoPlatform}s are disconnected`;
  if (users.length === 1) {
    heading = `${users[0].name}'s ${videoPlatform} is disconnected`;
    if (users[0].id === user.id) {
      heading = `Your ${videoPlatform} is disconnected`;
    }
  }

  const ctas: ButtonProps[] = userIds.includes(user.id)
    ? [
        {
          children: 'Re-connect',
          href: '/user/connected_accounts',
          onClick: onClickCta
        }
      ]
    : [];

  return (
    <BookabilityRuleAlert
      level={level}
      heading={heading}
      message='Participants won’t receive a call link to join when they schedule.'
      ctas={ctas}
    />
  );
};
