import * as React from 'react';
import { useContext } from 'react';

import { Link } from 'react-router-dom';
import * as timeago from 'timeago.js';
import { LocaleFunc } from 'timeago.js';
import tinytime from 'tinytime';

import { Avatar } from '@components/common';
import { LogoSVG } from '@components/svgs';
import userStore from '@stores/user';

import ActivityInfo from './ActivityInfo';

// https://stackoverflow.com/questions/47662423/twitter-style-time-ago-formatting-2h-with-javascript
const locale = (number, index, total_sec) => {
  return [
    ['now', 'now'],
    ['%ss', '%ss'],
    ['1m', '1m'],
    ['%sm', '%sm'],
    ['1hr', '1hr'],
    ['%shr', '%sshr'],
    ['1d', '1d'],
    ['%sd', '%sd'],
    ['1w', '1w'],
    ['%sw', '%sw'],
    ['1mo', '1mo'],
    ['%smo', '%smo'],
    ['1yr', '1yr'],
    ['%syr', '%syr']
  ][index];
};
timeago.register('pt_ABBR', locale as LocaleFunc);

const dateTemplate = tinytime('{MM} {DD}, {YYYY} at {H}:{mm}', { padHours: true });

function OwnerTag({ customer, owner }) {
  const current_user = {} as User;
  if (!owner) {
    return 'Someone';
  } else if (owner.type === 'Customer') {
    return <Link to={`/candidates/${customer.id}`}>{customer.name || 'A candidate'}</Link>;
  } else if (owner.type === 'User' && owner.id === current_user.id) {
    return 'You';
  } else {
    return owner.name || null;
  }
}

interface Props {
  scope: any;
  activity: PublicActivity;
}

const Activity: React.FC<React.PropsWithChildren<Props>> = ({ scope, activity }) => {
  const {
    state: { user }
  } = useContext(userStore);
  const { customer, owner, created_at } = activity;
  const avatarUser = owner
    ? {
        name: owner.name,
        initials: owner.initials,
        img: owner.img
      }
    : { name: 'Someone', initials: '-' };
  return (
    <li className='py-4'>
      <div className='flex space-x-3'>
        {owner?.type === 'Account' ? (
          <div className='flex h-8 w-8 flex-row items-center justify-center rounded-full bg-indigo-600'>
            <LogoSVG className='stroke-current text-white' width='90%' height='90%' />
          </div>
        ) : (
          <Avatar user={avatarUser} size='lg' />
        )}
        <div className='flex-1 space-y-1'>
          <div className='flex items-center justify-between'>
            <h3 className='text-sm font-medium leading-5'>
              <OwnerTag customer={customer} owner={owner} />
            </h3>
            <p className='text-sm leading-5 text-gray-500'>
              <time dateTime={created_at.toISOString()} title={dateTemplate.render(created_at)}>
                {timeago.format(created_at, 'pt_ABBR')}
              </time>
            </p>
          </div>
          <p className='text-sm leading-5 text-gray-500'>
            <ActivityInfo scope={scope} user={user} activity={activity} />
          </p>
        </div>
      </div>
    </li>
  );
};

export default Activity;
