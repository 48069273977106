import React, { FC, useState } from 'react';

import cn from 'classnames';
import { DailyVideo, useLocalSessionId } from '@daily-co/daily-react';

import { Text } from '@components/common';
import { StopSVG } from '@components/svgs';
import { MicrophoneWarning, useGetLocalActiveBlock } from '@components/Unmoderated';
import { useUnmoderatedContext } from '@components/Unmoderated/hooks/useUnmoderatedContext';
import { useDeviceType } from '@hooks/useDeviceType';
import { Enums } from 'components/SurveyBuilder';

import { useParticipantRecording } from '../hooks/useParticipantRecording';

import { CancelConfirmModal } from './CancelConfirmationModal';

export const RecordingIndicator: FC = () => {
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const { isEnabled, endRecording, isRecording, participation } = useParticipantRecording();

  const sessionId = useLocalSessionId();

  const { isMobile } = useDeviceType();

  const { topBarUnmoderatedLayout } = useUnmoderatedContext();

  const activeBlock = useGetLocalActiveBlock(participation);

  const buttonClass = 'hover:bg-gray-100 hover:text-indigo-600 m-1 text-gray-700 rounded-full cursor-pointer';

  if (!isEnabled) {
    return null;
  }

  return (
    <div
      className={
        topBarUnmoderatedLayout
          ? undefined
          : cn('fixed', {
              'absolute left-1/2 top-2 -translate-x-1/2 transform': isMobile,
              'bottom-6 left-6': !isMobile
            })
      }
      style={topBarUnmoderatedLayout ? {} : { zIndex: 9999 }}
    >
      <div
        className={cn(
          'flex items-center space-x-2 border border-gray-200 bg-white p-2',
          topBarUnmoderatedLayout ? 'rounded-md' : 'rounded-full shadow-lg'
        )}
      >
        {!isMobile && (
          <div className='flex h-6 w-6 items-center overflow-hidden rounded-full bg-gray-100'>
            <DailyVideo className='h-full w-full' sessionId={sessionId} type='video' fit='cover' />
          </div>
        )}

        <div
          className={cn('h-2 w-2 rounded-full', {
            'bg-red-500': isRecording,
            'bg-gray-200': !isRecording
          })}
        />

        <Text h='300'>{isRecording ? 'recording' : 'not recording'}</Text>

        {isRecording && (
          <button
            type='button'
            name='Stop Recording'
            aria-label='Stop Recording'
            className={buttonClass}
            onClick={() => setConfirmModalIsOpen(true)}
          >
            <StopSVG className='h-4 w-4' />
          </button>
        )}

        {isRecording && (
          <MicrophoneWarning
            isDisabled={activeBlock?.kind === Enums.Kind.info}
            message="We can't hear you! Don't forget to unmute your microphone and think out loud"
          />
        )}
      </div>

      {confirmModalIsOpen && (
        <CancelConfirmModal
          onCancel={() => setConfirmModalIsOpen(false)}
          onConfirm={() => {
            endRecording(true);

            setTimeout(() => {
              window.location.reload();
            }, 100);
          }}
        />
      )}
    </div>
  );
};
