import * as React from 'react';

import classNames from 'classnames';
import Tippy from '@tippyjs/react';

import { PlusSVG } from '@components/svgs';

interface Props {
  onlyShowOnHover?: boolean;
  onClick: () => void;
  disabled?: boolean;
}
export const AddQuestionBar: React.FC<React.PropsWithChildren<Props>> = ({ onlyShowOnHover, disabled, onClick }) => (
  <button
    name='add'
    aria-label='Add question'
    className={classNames('focus:outline-none group flex w-full', {
      'opacity-0 hover:opacity-100': onlyShowOnHover,
      'cursor-not-allowed': disabled
    })}
    onClick={() => (disabled ? null : onClick())}
  >
    <div
      className={classNames('h-4 flex-1 border-b border-gray-200', { 'group-hover:border-indigo-600': !disabled })}
    ></div>
    <Tippy content='Add new question' disabled={disabled}>
      <div
        className={classNames(
          'flex h-8 w-8 items-center justify-center rounded-full border border-gray-200 bg-white text-gray-700',
          { 'hover:border-indigo-600 group-hover:text-indigo-600': !disabled }
        )}
      >
        <PlusSVG className='current-color' />
      </div>
    </Tippy>
    <div
      className={classNames('h-4 flex-1 border-b border-gray-200', { 'group-hover:border-indigo-600': !disabled })}
    ></div>
  </button>
);
