const MESSAGES: Record<BackgroundTaskAction | 'default', BackgroundTaskMessage> = {
  default: {
    failed_heading: '',
    heading: 'We are processing your request',
    message: 'This might take a while.',
    title: 'Process Request'
  },
  book: {
    failed_heading: 'When marking candidates as scheduled',
    heading: 'Marking candidates as scheduled',
    message: 'This might take a while.',
    title: 'Mark Candidates as Scheduled'
  },
  bulk_delete: {
    failed_heading: 'When deleting candidates',
    heading: 'We are deleting candidate data',
    message: 'This might take a while.',
    title: 'Delete Candidates'
  },
  bulk_edit_candidates: {
    failed_heading: 'When updating candidates',
    heading: 'We are updating the candidates',
    message: 'This might take a while.',
    title: 'Update Candidates'
  },
  bulk_shortlist: {
    failed_heading: 'When shortlisting candidates',
    heading: 'We are shortlisting candidates',
    message: 'This might take a while.',
    title: 'Shortlist Candidates'
  },
  cancel: {
    failed_heading: 'When marking candidates as canceled',
    heading: 'Marking candidates as canceled',
    message: 'This might take a while.',
    title: 'Cancel Candidates'
  },
  candidate_import: {
    failed_heading: 'When importing candidates',
    heading: 'We are importing candidates',
    message: 'This might take a while.',
    title: 'Import Candidates'
  },
  integration_sync_salesforce: {
    failed_heading: 'Please, try to import candidates again in a few minutes',
    heading: 'We are importing the candidates from Salesforce',
    message: 'This might take a while.',
    title: 'Sync with Salesforce'
  },
  integration_sync_snowflake: {
    failed_heading: 'Please, try to import candidates again in a few minutes',
    heading: 'We are importing the candidates from Snowflake',
    message: 'This might take a while.',
    title: 'Sync with Snowflake'
  },
  invite: {
    cancelled_heading: 'Candidate invites task canceled',
    failed_heading: 'When inviting candidates',
    heading: 'We are inviting candidates',
    message: 'This might take a while.',
    title: 'Invite Candidates'
  },
  mark_invited: {
    failed_heading: 'When marking candidates as invited',
    heading: 'Marking candidates as invited',
    message: 'This might take a while.',
    title: 'Mark Candidates as Invited'
  },
  no_show: {
    failed_heading: 'When marking candidates as no-showed',
    heading: 'Marking candidates as no-showed',
    message: 'This might take a while.',
    title: 'Mark Candidates as No-Showed'
  },
  shortlist: {
    failed_heading: 'When shortlisting candidates',
    heading: 'We are shortlisting the candidates',
    message: 'This might take a while.',
    title: 'Shortlist Candidates'
  },
  start: {
    failed_heading: 'When marking candidates as started',
    heading: 'Marking candidates as started',
    message: 'This might take a while.',
    title: 'Mark Candidates as Started'
  },
  thanks: {
    failed_heading: 'When sending candidates a thank you email',
    heading: 'Sending candidates a thank you email',
    message: 'This might take a while.',
    title: 'Send Thank You Email'
  },
  update_moderator: {
    failed_heading: 'When updating moderator',
    heading: 'Changing moderator on interview',
    message: 'This might take a while.',
    title: 'Update Moderator'
  },
  draft_external_request: {
    failed_heading: 'When syncing your request with the external platform:',
    heading: 'Updating your request',
    message: 'This may take a few moments.',
    title: 'Draft External Request'
  },
  update_external_request: {
    failed_heading: 'When syncing your request with the external platform:',
    heading: 'Updating your request',
    message: 'This may take a few moments.',
    title: 'Update External Request'
  },
  publish_external_request: {
    failed_heading: 'When publishing your request with the external platform:',
    heading: 'Publishing your request',
    message: 'This may take a few moments.',
    title: 'Publish External Request'
  },
  close_external_request: {
    failed_heading: 'When closing your request with the external platform:',
    heading: 'Closing your request',
    message: 'This may take a few moments.',
    title: 'Close External Request'
  },
  external_request_pause: {
    failed_heading: 'When pausing your request with the external platform:',
    heading: 'Pausing your request',
    message: 'This may take a few moments.',
    title: 'Pause External Request'
  },
  external_request_unpause: {
    failed_heading: 'When unpausing your request with the external platform:',
    heading: 'Unpausing your request',
    message: 'This may take a few moments.',
    title: 'Unpause External Request'
  },
  accept_participation_time_proposal: {
    failed_heading: 'When scheduling proposed time',
    heading: 'Scheduling event at a time proposed by candidate',
    message: 'This may take a few moments.',
    title: 'Accept Participation Time Proposal'
  },
  consent_form_export: {
    failed_heading: 'When generating the bulk consent form export:',
    heading: 'You will receive an email with a link to download the consent forms',
    message: 'This may take a few moments.',
    title: 'Export Consent Forms'
  },
  reschedule_request: {
    failed_heading: 'When rescheduling the request',
    heading: 'Rescheduling the request',
    message: 'This may take a few moments.',
    title: 'Reschedule Request'
  },
  remove: {
    failed_heading: 'When removing candidates',
    heading: 'Removing candidates',
    message: 'This might take a while.',
    title: 'Remove Candidates'
  }
};

type BackgroundTaskMessage = {
  cancelled_action?: string;
  cancelled_heading?: string;
  failed_heading: string;
  heading: string;
  message: string;
  title: string;
};
export const getMessage: (BackgroundTaskAction) => BackgroundTaskMessage = (jobName) =>
  MESSAGES[jobName] || MESSAGES['default'];
