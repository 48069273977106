import React, { useState } from 'react';

import { NewStudySlideout } from '@components/StudiesApp/components/StudyNew/components/NewStudySlideout';
import { Button } from 'components/common';
import { DashboardZDSSVG } from 'components/svgs';
import { usePlan } from 'hooks/usePlan';

export const InterviewsZDS = () => {
  const { hasQuota } = usePlan();
  const [showStudySlideOut, setShowStudySlideOut] = useState<boolean>(false);

  return (
    <>
      <div className='flex flex-grow flex-col items-center p-10'>
        <DashboardZDSSVG />
        <h3 className='h600 mb-2 mt-4 font-bold'>No upcoming interviews</h3>
        <p className='h500 text-gray-500'>
          When you have interviews scheduled with participants, we’ll show them here.
        </p>
        <div className='mt-8 space-x-2'>
          {hasQuota('studies') && (
            <Button primary onClick={() => setShowStudySlideOut(true)}>
              Create new study
            </Button>
          )}
        </div>
      </div>
      {showStudySlideOut && <NewStudySlideout isVisible onClose={() => setShowStudySlideOut(false)} />}
    </>
  );
};
