import React from 'react';

import cn from 'classnames';

import { api } from '@api/reduxApi';
import { isDisplayable, ScreenerAnswer } from '@components/ScreenerResults';
import { Basic } from '@components/shared/Skeleton';

type Props = {
  className?: string;
  screenerId?: number;
  studyId?: number;
  participation?: Participation | null;
};

export const ResponsesTab: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  screenerId,
  studyId,
  participation
}) => {
  const { data: screenerResponse } = api.useGetScreenerResponseQuery(
    {
      screenerId,
      studyId,
      participationId: participation?.id
    },
    {
      skip: !studyId || !participation?.id || !screenerId
    }
  );
  const answers = screenerResponse?.answers_json || [];

  return (
    <div data-testid='Responses' className={cn('p-8', className)}>
      <div className='flex flex-col space-y-8'>
        {!screenerResponse && (
          <>
            <Basic dataTestid='skeleton' className='h-4' width='full' />
            <Basic className='h-4' width='full' />
            <Basic className='h-4' width='full' />
          </>
        )}
        {answers
          .filter(({ field_type }) => isDisplayable(field_type))
          .map((answer, idx) => (
            <ScreenerAnswer key={idx} answer={answer} slideOutView />
          ))}
      </div>
    </div>
  );
};
