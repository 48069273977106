import React, { FC, Fragment } from 'react';

import pluralize from 'pluralize';
import { UniqueIdentifier } from '@dnd-kit/core';

import { Text } from '@components/common';
import { findNodeDeep, getAnswerLabels } from '@components/ScreenerResults/components/TreeTestResponse/utils';
import { TreeItem } from '@components/SurveyBuilder/types/models';
import { CheckSVG, ChevronRightSVG } from '@components/svgs';

interface Props {
  answer: ScreenerResponseAnswerValue<'tree_test'>;
  correctAnswers: UniqueIdentifier[][];
  nodes: TreeItem[];
}

export const ResponseDetails: FC<Props> = ({ nodes, answer, correctAnswers }) => {
  const answerLabels = getAnswerLabels(answer.result, nodes);

  return (
    <div className='flex w-full flex-1 flex-grow flex-col divide-y divide-gray-200 rounded-md border border-gray-200'>
      <div className='max-h-96 overflow-auto p-6'>
        <Text className='mb-3' bold h='400'>
          Participant’s response
        </Text>
        {answerLabels.map((label, index) => (
          <div
            key={index}
            className='h400 w-100 mb-1 flex items-center justify-between rounded-md border border-gray-200 bg-green-50 px-4 py-2.5'
            style={
              {
                marginLeft: `${16 * index}px`
              } as React.CSSProperties
            }
          >
            {index === answer.result.answer.length - 1 ? (
              <>
                <span>{label}</span>
                <CheckSVG className='text-green-600' />
              </>
            ) : (
              <span>{label}</span>
            )}
          </div>
        ))}
      </div>
      <div className='max-h-96 overflow-auto p-6'>
        <Text className='mb-3' bold h='400'>
          {`Correct ${pluralize('answer', correctAnswers.length)}`}
        </Text>
        {correctAnswers.map((answers, index) => (
          <div key={index} className='mb-3 flex flex-nowrap items-center space-x-2'>
            {answers.map((answer, index) => (
              <Fragment key={index}>
                <Text
                  h='400'
                  bold={index === answers.length - 1}
                  color={index < answers.length - 1 ? 'gray-500' : 'green-600'}
                >
                  {findNodeDeep(nodes, answer)?.label}
                </Text>
                {index < answers.length - 1 && <ChevronRightSVG className='text-gray-500' />}
              </Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
