import React, { HTMLAttributes } from 'react';
import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import type { FC } from 'react';
import { usePopper } from 'react-popper';

import { composeEventHandlers } from '@helpers/composeEventHandlers';
interface Props {
  value: number;
  max: number;
  buffer?: number;
  className?: string;
  hideThumb?: boolean;
  barClassName?: string;
  onChange?: (value: number) => void;
  onFinalUpdate?: (value: number) => void;
  onHover?: (event: MouseEvent, seconds: number) => void;
  onMouseEnter?: HTMLAttributes<HTMLDivElement>['onMouseEnter'];
  onMouseLeave?: HTMLAttributes<HTMLDivElement>['onMouseEnter'];
}

const Thumb = ({ value, hide }: { value: number; hide: boolean }) => (
  <div
    style={{ left: `${value}%` }}
    aria-label='Slider thumb'
    className={cn(
      'range-bar-thumb pointer-events-none absolute top-1/2 z-10 -ml-1.5 h-3 w-3 -translate-y-1/2 transform rounded-full bg-white shadow-lg',
      {
        'opacity-0': hide
      }
    )}
  />
);

const FilledBar = ({ value, isBuffer, className }: { value: number; isBuffer?: boolean; className?: string }) => (
  <div
    style={{ width: `${value}%` }}
    className={cn('absolute top-1/2 h-1 -translate-y-1/2 transform', { ['bg-opacity-30']: isBuffer }, className)}
  />
);

export const RangeBar: FC<React.PropsWithChildren<Props>> = ({
  value: initialValue = 0,
  buffer: initialBuffer = 0,
  max,
  className,
  hideThumb,
  barClassName,
  onChange,
  onFinalUpdate,
  onHover,
  onMouseEnter,
  onMouseLeave
}) => {
  const [value, setValue] = useState<number>(0);
  const [buffer, setBuffer] = useState<number>(0);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const trackRef = useRef<HTMLDivElement>(null);

  const getValue = (event: MouseEvent) => {
    const { pageX } = event;
    const { offsetWidth } = trackRef.current as any;
    const offsetLeft = trackRef.current?.getBoundingClientRect().left as any;

    let newValue = ((pageX - offsetLeft) / offsetWidth) * 100;

    if (newValue <= 0) newValue = 0;
    if (newValue >= 100) newValue = 100;
    return Math.floor(newValue);
  };

  const setCurrentValue = (v: number) => {
    setValue(v);
    onChange?.(v);
  };

  const handleOnMouseMove = (event: MouseEvent) => {
    if (isDragging || isHover) {
      const nValue = getValue(event);
      onHover?.(event, nValue);
      if (isDragging) setCurrentValue(nValue);
    }
  };

  const handleOnMouseDown = (event: MouseEvent) => {
    setIsDragging(true);
    setCurrentValue(getValue(event));
  };

  const handleOnMouseUp = () => {
    if (isDragging) {
      onFinalUpdate?.(value);
      setIsDragging(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleOnMouseMove);
    document.addEventListener('mouseup', handleOnMouseUp);
    if (trackRef.current) trackRef.current.addEventListener('mousedown', handleOnMouseDown);

    return () => {
      document.removeEventListener('mousemove', handleOnMouseMove);
      document.removeEventListener('mouseup', handleOnMouseUp);
      if (trackRef.current) trackRef.current.removeEventListener('mousedown', handleOnMouseDown);
    };
  }, [handleOnMouseMove, handleOnMouseUp, handleOnMouseDown]);

  useEffect(() => {
    if (!isDragging) setValue(Math.min((initialValue / max) * 100, 100));
  }, [initialValue]);

  useEffect(() => {
    setBuffer(Math.min((initialBuffer / max) * 100, 100));
  }, [initialBuffer]);

  return (
    <div
      className={cn('relative', 'h-3', 'cursor-pointer', className)}
      role='slider'
      tabIndex={0}
      aria-valuemin={0}
      aria-valuemax={max}
      aria-valuenow={value}
      onMouseEnter={composeEventHandlers(() => setIsHover(true), onMouseEnter)}
      onMouseLeave={composeEventHandlers(() => setIsHover(false), onMouseLeave)}
    >
      <div className='absolute z-10 h-full w-full' ref={trackRef} />
      <Thumb value={value} hide={Boolean(hideThumb && !isHover)} />
      <div className='absolute top-1/2 h-1 w-full -translate-y-1/2 transform overflow-hidden bg-gray-200'>
        <FilledBar value={value} className={barClassName} />
        {buffer > 0 && <FilledBar value={buffer} className={barClassName} isBuffer />}
      </div>
    </div>
  );
};
