import React from 'react';

import { Link } from 'react-router-dom';

import { RatingCell } from '@components/shared/GridTable/components/inputs/RatingCell';
import { LimitCell } from '@components/shared/ParticipationsTable/components/LimitCell';
import { Table } from '@components/shared/Table';

import { IncentiveCell } from '../components/IncentiveCell';
import { StatusCell } from '../components/StatusCell';
import { UpdateModeratorCell } from '../components/UpdateModeratorCell';
import * as Types from '../types';

export const studyColumnDefinitions: Types.DefinitionFn = ({
  hasParticipantLevelIncentives,
  study,
  participationStatus,
  hasInterviewRoom,
  getColumnLabel,
  setBackgroundTask,
  addFilter,
  limits,
  onTimeProposalClick,
  partyIdToLimit
}) => {
  const columns: Types.DefinitionFnReturn = [
    {
      id: 'status',
      accessorKey: 'status',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          render={({ row }) => (
            <StatusCell participation={row.original} onClickProposedTime={() => onTimeProposalClick?.(row.original)} />
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('status')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'token',
      accessorKey: 'token',
      cell: (props) => <Table.Cell<Participation> {...props} type='text' />,
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('token')}
        </Table.ColumnHeader>
      )
    }
  ];

  if (hasParticipantLevelIncentives) {
    columns.push({
      id: 'incentive_in_whole_currency',
      accessorKey: 'incentive_in_whole_currency',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          render={({ row, table }) => (
            <IncentiveCell
              currencySymbol={study?.currencySymbol}
              incentive={row.original.incentive_in_whole_currency}
              isEditable={!props.row.original.completed_at}
              onIncentiveChange={(value) =>
                table.options.meta?.updateCellData?.({ row, columnId: 'incentive_in_whole_currency', value })
              }
            />
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('incentive_in_whole_currency')}
        </Table.ColumnHeader>
      )
    });
  }

  if (participationStatus !== 'invited' && study?.pre_screener?.has_ideal_answers) {
    columns.push(
      {
        id: 'preScreenerField.ideal_response',
        accessorKey: 'ideal_pre_screener_response',
        cell: (props) => <Table.Cell<Participation> {...props} type='boolean' />,
        header: (props) => (
          <Table.ColumnHeader addFilter={addFilter} {...props}>
            {getColumnLabel?.('preScreenerField.ideal_response')}
          </Table.ColumnHeader>
        )
      },
      {
        id: 'pre_screener_response:match_score',
        accessorKey: 'pre_screener_response_match_score',
        cell: (props) => <Table.Cell<Participation> {...props} type='number' />,
        header: (props) => (
          <Table.ColumnHeader addFilter={addFilter} {...props}>
            {getColumnLabel?.('pre_screener_response:match_score')}
          </Table.ColumnHeader>
        )
      }
    );
  }

  if (study?.has_external_candidates_requests) {
    columns.push({
      id: 'recruiting_source',
      accessorKey: 'recruiting_source',
      cell: (props) => <Table.Cell<Participation> {...props} type='text' />,
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('recruiting_source')}
        </Table.ColumnHeader>
      )
    });
  }

  if (participationStatus === 'booked') {
    columns.push({
      id: 'join_url',
      accessorKey: 'join_url',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='url'
          render={({ row }) => (
            <Link className='truncate px-2' to={row.original.join_url ?? ''}>
              Join url
            </Link>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('join_url')}
        </Table.ColumnHeader>
      )
    });
  }

  if (participationStatus === 'completed' && study?.style === 'survey' && study?.on_gq) {
    columns.push({
      id: 'study_path',
      accessorKey: 'study_path',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='url'
          render={({ row }) => (
            <Link className='truncate px-2' to={row.original.study_path ?? ''}>
              View survey
            </Link>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('study_path')}
        </Table.ColumnHeader>
      )
    });
  }

  if (
    ['booked', 'completed', 'no_show'].includes(participationStatus as ParticipationStatus) &&
    study?.style === 'video_call'
  ) {
    columns.push({
      id: 'moderator',
      accessorKey: 'moderator',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='text'
          render={({ row }) => (
            <UpdateModeratorCell
              moderatorName={row.original.moderator?.name}
              participation={row.original}
              study={study}
              setBackgroundTask={setBackgroundTask}
            />
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('moderator')}
        </Table.ColumnHeader>
      )
    });
  }

  if (
    ['completed', 'canceled', 'no_show'].includes(participationStatus as ParticipationStatus) &&
    study?.style === 'video_call'
  ) {
    columns.push({
      id: 'rating',
      accessorKey: 'rating',
      minSize: 160,
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='number'
          render={({ value, setValue }) => (
            <div className='flex h-full'>
              <RatingCell value={value} onClick={setValue} />
            </div>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('rating')}
        </Table.ColumnHeader>
      )
    });
  }

  if (hasInterviewRoom) {
    columns.push({
      id: 'interview_path',
      accessorKey: 'interview_path',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='url'
          render={({ row }) => (
            <Link className='truncate px-2' to={row.original.interview_path ?? ''}>
              Interview room
            </Link>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('interview_path')}
        </Table.ColumnHeader>
      )
    });
  }

  if (limits) {
    columns.push({
      id: 'limit',
      header: (props) => <Table.ColumnHeader {...props}>{getColumnLabel?.('limit')}</Table.ColumnHeader>,
      accessorFn: (row) => partyIdToLimit?.(row.id),
      cell: (props) => <Table.Cell<Participation> {...props} render={(props) => <LimitCell {...props} />} />
    });
  }

  return columns;
};
