import { useRef, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import * as Types from '../types';

interface UseTreeTestTaskTrackingArgs {
  debounceDelay?: number;
  onEvent?: (events: Types.TreeTestEvent[]) => void;
}

interface UseTreeTestTaskTrackingReturn {
  events: Types.TreeTestEvent[];
  onSelectNode: (nodeId: number) => void;
  onDeselectNode: (nodeId: number) => void;
  onCollapseNode: (nodeId: number) => void;
  onExpandNode: (nodeId: number) => void;
}

export const useTreeTestTaskTracking = (args: UseTreeTestTaskTrackingArgs): UseTreeTestTaskTrackingReturn => {
  const { debounceDelay = 500, onEvent } = args;

  const [events, setEvents] = useState<Types.TreeTestEvent[]>([]);

  const cachedEvents = useRef<Types.TreeTestEvent[]>([]);

  const send = () => {
    onEvent?.(cachedEvents.current);
    setEvents(cachedEvents.current);
  };

  const { callback: debouncedSend } = useDebouncedCallback(send, debounceDelay);

  const onSelectNode = (nodeId) => {
    const event: Types.TreeTestEvent<'select_node'> = {
      at: new Date(),
      nodeId,
      event: 'select_node'
    };

    cachedEvents.current.push(event);

    debouncedSend();
  };

  const onDeselectNode = (nodeId) => {
    const event: Types.TreeTestEvent<'deselect_node'> = {
      at: new Date(),
      nodeId,
      event: 'deselect_node'
    };

    cachedEvents.current.push(event);
  };

  const onCollapseNode = (nodeId) => {
    const event: Types.TreeTestEvent<'collapse_node'> = {
      at: new Date(),
      nodeId,
      event: 'collapse_node'
    };

    cachedEvents.current.push(event);
    debouncedSend();
  };

  const onExpandNode = (nodeId) => {
    const event: Types.TreeTestEvent<'expand_node'> = {
      at: new Date(),
      nodeId,
      event: 'expand_node'
    };

    cachedEvents.current.push(event);

    debouncedSend();
  };

  return { events, onSelectNode, onDeselectNode, onCollapseNode, onExpandNode };
};
