import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { useCombobox } from 'downshift';
import { useDebouncedCallback } from 'use-debounce';
import Tippy from '@tippyjs/react';

import { api } from '@api/reduxApi';
import { Input, Spinner } from '@components/common';
import { Basic } from '@components/shared/Skeleton';
import { CalendarSVG, CaretDownSVG, PersonSVG } from '@components/svgs';
import { usePopUp } from '@hooks/usePopUp';

interface Props {
  disabled?: boolean;
  editable: boolean;
  participationId?: number;
  candidate?: Candidate | null;
  onCandidateClick?: () => void;
  onSetCandidate: (candidate: Candidate) => void;
}

const DEBOUNCE_TIME = 300;

export const Participant: React.FC<React.PropsWithChildren<Props>> = ({
  disabled,
  editable,
  participationId,
  candidate,
  onCandidateClick,
  onSetCandidate
}) => {
  const { PopUp, ref, open, togglePopUp } = usePopUp();

  const [value, setValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const { data: fetchedData, isLoading } = api.useGetServerSideCandidatesQuery({
    items: 50,
    page: 1,
    searchQuery
  });
  const { callback: debouncedSetSearchQuery } = useDebouncedCallback(setSearchQuery, DEBOUNCE_TIME);
  useEffect(() => {
    if (value === '') {
      setSearchQuery('');
    } else {
      debouncedSetSearchQuery(value);
    }
  }, [value]);

  const { data: calendarEvents, isLoading: isFetchingEvents } = api.useGetParticipationCalendarEventsQuery(
    {
      id: participationId
    },
    {
      skip: !participationId
    }
  );
  const now = +new Date();
  const futureCalendarEventsCount = calendarEvents?.filter((event) => +event.start_at > now)?.length || 0;
  const showCalendarEvents = futureCalendarEventsCount > 0;

  const shouldShowSpinner = value !== searchQuery || isLoading;

  const results: Candidate[] = fetchedData ? fetchedData.data : [];

  const items = useMemo(
    () => results.map(({ name, id }) => ({ label: name || 'Unnamed candidate', value: id })) || [],
    [results]
  );

  const {
    highlightedIndex,
    selectedItem,
    getToggleButtonProps,
    getComboboxProps,
    getMenuProps,
    getInputProps,
    getItemProps
  } = useCombobox({
    isOpen: true,
    items,
    itemToString: (item) => (item ? item.label : ''),
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        const candidate = results.find((i) => i.id === selectedItem.value);
        if (candidate) {
          onSetCandidate(candidate);
          togglePopUp();
        }
      }
    }
  });

  const shouldInputBeInline = !candidate;

  const inputEl = (
    <Input
      {...getInputProps({ disabled }, { suppressRefError: true })}
      autoFocus
      icon={shouldInputBeInline ? undefined : 'search'}
      className={cn('h400 w-full', {
        'border-0 py-0 ring-0': shouldInputBeInline
      })}
      value={value}
      onChange={setValue}
      placeholder='Search…'
    />
  );

  return (
    <div ref={ref} className='relative flex items-center space-x-2'>
      <div {...getComboboxProps({ disabled })}>
        {editable && (
          <div className='flex items-center'>
            <button
              {...getToggleButtonProps()}
              className={cn('h400 flex items-center space-x-2 whitespace-nowrap px-2.5 py-1.5', {
                'rounded-md border border-gray-200': editable && !shouldInputBeInline
              })}
              onClick={togglePopUp}
            >
              <PersonSVG className='flex-shrink-0' />
              {candidate && <span className='truncate'>{candidate.name || 'Unnamed candidate'}</span>}
              {!candidate && !open && <span className='truncate text-gray-400'>Add participant</span>}
              {open && shouldInputBeInline && inputEl}
              {!shouldInputBeInline && <CaretDownSVG className='flex-shrink-0' />}
            </button>
            {shouldShowSpinner && <Spinner className='h-4 w-4' />}
          </div>
        )}
        {!editable && (
          <div className='h400 flex items-center space-x-2 whitespace-nowrap px-2.5 py-1.5'>
            {isFetchingEvents && <Basic h='4' width={140} />}

            {!isFetchingEvents && (
              <>
                {showCalendarEvents ? <CalendarSVG /> : <PersonSVG />}
                {candidate && (
                  <Tippy content='View profile' arrow={false}>
                    <span
                      onClick={onCandidateClick}
                      className='xx-session-candidate cursor-pointer whitespace-nowrap hover:text-indigo-500'
                    >
                      {candidate.name || 'Unnamed candidate'}
                    </span>
                  </Tippy>
                )}
                {!candidate && <span className='text-gray-200'>No candidate set</span>}
              </>
            )}
          </div>
        )}

        <PopUp open={open} className='left-0 top-8 mt-2 w-80 rounded-md border border-gray-200 bg-white shadow-lg'>
          <div>
            {!shouldInputBeInline && (
              <>
                <div className='p-4'>{inputEl}</div>
                <div className='border-b border-gray-200' />
              </>
            )}
            <div className='p-4'>
              <ul {...getMenuProps({ disabled, className: 'max-h-60 overflow-y-scroll' }, { suppressRefError: true })}>
                {items.map((item, index) => (
                  <li
                    key={`${item.label}${index}`}
                    {...getItemProps({ disabled, item, index })}
                    className={cn('h400 p-1', {
                      'bg-gray-50': highlightedIndex === index,
                      'text-indigo-600': selectedItem === item
                    })}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </PopUp>
      </div>
    </div>
  );
};
