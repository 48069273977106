import React from 'react';

import Mustache from 'mustache';

import { useGetWhoamiQuery } from '@components/stores/whoami';
import { DocumentPreview } from '@components/StudyMessages/components/DocumentPreview';
import { getCtaProps } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip/helpers';
import { getCtaUrl } from '@components/StudyMessages/utils/getCtaUrl';
import { useAccount } from '@hooks/useAccount';
import { BrandLogo } from 'components/StudiesApp/components/StudyDraft/pages/Assets/BrandLogo';

interface Props {
  message: StudyMessage | null;
  previewContext: Record<string, any>;
  renderMergeTag: (str: string) => JSX.Element;
  evalIfCondition: (str: string) => boolean;
  study: SimpleStudy;
  edit?: boolean;
}

export const MessagePreview: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { previewContext, study, message, edit, renderMergeTag, evalIfCondition } = props;

  const { data: whoami } = useGetWhoamiQuery();
  const accountPublicBaseUrl = whoami?.account_public_domain?.base_url;

  const {
    account: { workspace }
  } = useAccount();

  return (
    <>
      {message?.layout !== 'rich_text' && (
        <div className='flex justify-center'>
          <BrandLogo study={study} className='mb-2 mt-4 h-8' edit={edit} />
        </div>
      )}
      {message && (
        <div className='rounded-md'>
          {message.document_id && (
            <DocumentPreview
              key={message?.text}
              id={message.document_id}
              ctaProps={getCtaProps({
                ctaUrl: getCtaUrl(workspace, study, message, accountPublicBaseUrl) || '',
                event: message.event,
                studyStyle: study.style,
                screenerInviteCta: study.screener_invite_cta,
                tremendous: study.incentive_method === 'tremendous',
                videoUrlType: study.video_url_type,
                videoUrl: study.video_url_type === 'in_person' ? study.location.address : study.video_url || ''
              })}
              renderMergeTag={renderMergeTag}
              evalIfCondition={evalIfCondition}
              ctaAs={message.layout === 'rich_text' ? 'link' : 'button'}
            />
          )}
          {!message.document_id && (
            <div className='px-6 py-4'>
              {message.text?.split('\n').map((p, i) => (
                <p className='h500 my-1' key={i}>
                  {Mustache.render(p, previewContext)}
                </p>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};
