import * as React from 'react';

import { SUPPORT_EMAIL } from '@constants/emails';

import { Button, Text } from '@components/common';
import { track } from '@components/tracking';

import { AppErroredSVG } from './AppErroredSVG';

interface ErrorData {
  error: unknown;
  componentStack: string | null;
}

const SHOW_DEV_ERROR_PAGE =
  document.querySelector<HTMLMetaElement>('meta[name="show_dev_error_page"]')?.content === 'true';

export const trackError = (error: unknown): void => {
  if (error instanceof Error) {
    track('app_error', { error: error.name });
  } else if (typeof error === 'string') {
    track('app_error', { error });
  } else {
    track('app_error', { error: 'Unknown error' });
  }
};

export const AppErroredPage: React.FC<React.PropsWithChildren<ErrorData>> = ({
  error,
  componentStack
}): React.ReactElement => {
  trackError(error);

  return (
    <div className='py-gutter desktop:pt-20 h-full w-full'>
      <div className='mx-auto max-w-xl'>
        <div className='text-left'>
          <div className='mb-10 text-center'>
            <AppErroredSVG />
          </div>
          <Text h='700' mb='2'>
            Sorry, we couldn’t load this page.
          </Text>
          <Text mb='4'>We must’ve encountered an unexpected condition that well, we didn’t expect. You can try:</Text>
          <ul className='mb-16 list-disc'>
            <li>
              <Text>Re-loading the page</Text>
            </li>
            <li>
              <Text>Clearing your caches or cookies</Text>
            </li>
            <li>
              <Text>Come back later or try again</Text>
            </li>
            <li>
              <Text>
                Contact us at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
              </Text>
            </li>
          </ul>
          <div className='mb-4 flex justify-center space-x-6'>
            <Button primary href='/'>
              Go to Dashboard
            </Button>
            <Button href='http://greatquestion.co/support/'>Visit help center</Button>
          </div>
          {SHOW_DEV_ERROR_PAGE && error instanceof Error && (
            <div className='h200 max-w-3xl whitespace-pre-wrap rounded-none border-0 bg-red-50 p-4 leading-loose'>
              <Text bold mb='3'>
                {error.name}
              </Text>
              <Text h='400' mb='4' color='gray-500'>
                {error.message}
              </Text>
              {componentStack}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
