import React from 'react';

import { useLocation } from 'react-router-dom';
import Tippy from '@tippyjs/react';

import type { AiChatPage } from '@components/AiChat/types';
import { useIsValidAiChatContext } from '@components/AiChat/utils/useGetAssumedContext';
import { Button } from '@components/common';
import { AskAiRainbowSVG } from '@components/svgs';
import { AskAiGreySVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { track } from '@components/tracking';
import { useAiChat } from '@stores/aiChat';

export interface Props {
  pageContext: AiChatContext | null;
  style?: string;
  showWhenUnavailable?: boolean;
}

export const AskAiButton = ({ pageContext, style, showWhenUnavailable = true }: Props) => {
  const { enabled, context, openWith, setOpen, messages } = useAiChat();
  const isValidContext = useIsValidAiChatContext(pageContext);

  const validScope = pageContext ? isValidContext : false;
  const showButton = enabled && pageContext && (showWhenUnavailable || validScope);

  const { pathname } = useLocation();

  if (!showButton) {
    // Do not display button if we don't have this feature or no context passed in
    return null;
  }

  const handleClick = (e, via) => {
    e.stopPropagation();
    track('open_ask_ai', { via, path: pathname, context: pageContext ?? null });
    if (pageContext) {
      // Only resume if there are messages and the context is the same
      if (
        messages.length > 0 &&
        pageContext &&
        context &&
        pageContext.context_type === context.context_type &&
        pageContext.id === context.id
      ) {
        setOpen(true);
      } else {
        // Otherwise reset the chat & set the new context
        openWith(pageContext.context_type as AiChatPage, pageContext);
      }
    }
  };

  const tooltipText = 'Ask AI is currently only available for video call studies and interviews with valid transcripts';
  if (style === 'small') {
    return (
      <>
        <Tippy content='Ask AI' arrow={false}>
          <button
            className='focus:outline-none rounded-full bg-white p-1 hover:bg-indigo-100 hover:text-indigo-600'
            aria-label='Ask AI button, small'
            disabled={!validScope}
            onClick={(e) => {
              handleClick(e, 'ask-ai-button-sm');
            }}
          >
            <AskAiGreySVG className='h-4 w-4' />
          </button>
        </Tippy>
      </>
    );
  }
  return (
    <>
      <Tooltip isDisabled={validScope} content={tooltipText}>
        <Button
          aria-label='Ask AI button'
          onClick={(e) => handleClick(e, 'ask-ai-button')}
          className='h-10'
          disabled={!validScope}
        >
          <AskAiRainbowSVG className='mr-2 h-6 w-6' />
          Ask AI
        </Button>
      </Tooltip>
    </>
  );
};
