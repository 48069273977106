import React from 'react';

import { Button, Input, Text } from '@components/common';
import { humanize } from 'components/utils';

import { Icon } from './Icon';
import { Preview } from './Preview';

interface Props {
  questions: ScreenerField[];
  addedQuestions: ScreenerField[];
  handleAddQuestion: (question: ScreenerField) => void;
}

export const Questions: React.FC<React.PropsWithChildren<Props>> = ({
  questions,
  addedQuestions,
  handleAddQuestion
}) => (
  <div>
    {questions.map((question) => {
      return (
        <Preview question={question}>
          <div
            className='mr-1 flex items-center justify-between border-b border-gray-200 py-2 pl-4'
            key={question.label}
          >
            <div className='flex'>
              {question.candidate_attr && <Icon field={question} />}
              <Text h='400' className='px-2 capitalize'>
                {question.candidate_attr ? humanize(question.candidate_attr) : question.label}
              </Text>
            </div>
            {addedQuestions.includes(question) ? (
              <Text color='green-600' className='pr-4'>
                Added
              </Text>
            ) : (
              <Button small link onClick={() => handleAddQuestion(question)} icon='plus' />
            )}
          </div>
        </Preview>
      );
    })}
  </div>
);
