import React from 'react';

import { Text } from '@components/common';
import { InfoCircleIcon } from '@components/svgs';
import { Tooltip } from 'components/Tooltip';

import { RecordingZone } from './RecordingZone';

export const StartRecording: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <RecordingZone show top className='pb-4 pt-3'>
      <div className='flex flex-row items-center space-x-2'>
        <div className='h-3 w-3 rounded-full bg-red-600' />
        <Text h='400'>Recorded zone</Text>
        <Tooltip content='Configure the questions that are captured with screen recording.'>
          <InfoCircleIcon className='h-4 w-4' />
        </Tooltip>{' '}
      </div>
    </RecordingZone>
  );
};
