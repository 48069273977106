import * as React from 'react';
import { useEffect, useState } from 'react';

import { Spinner } from '@components/common';
import { api } from 'api/reduxApi';
import { FilterSearch } from 'components/CandidatesApp/CandidatesIndex/FilterSearch';
import { useSearchWithinFilter } from 'components/CandidatesApp/CandidatesIndex/hooks';

import { DropdownProps } from '../../types';

type Props<D = Candidate> = DropdownProps<D, number[]>;

export const HasParticipatedInStudies: React.FC<React.PropsWithChildren<Props>> = ({ value, onChange }) => {
  type Option = { label: string; value: number };
  const [options, setOptions] = useState<Option[]>([]);

  const { data: fetchedStudies, isSuccess, isFetching } = api.useGetSimpleStudiesQuery();

  useEffect(() => {
    if (isSuccess) {
      setOptions(
        fetchedStudies.filter(Boolean).map((s) => ({
          value: s.id,
          label: s.title
        }))
      );
    }
  }, [isSuccess]);

  const { searchValue, setSearchValue, filteredOptions } = useSearchWithinFilter<Option>({
    options,
    getFilteredOptions: (option, search) =>
      option.label.toLocaleLowerCase().includes(search?.toLocaleLowerCase() || ''),
    sort: 'alpha'
  });

  const toggleFilter = (option: number) => {
    const newValue = value?.includes(option) ? value.filter((opt) => opt !== option) : (value || []).concat(option);
    onChange({ value: newValue });
  };

  if (isFetching) {
    return (
      <div className='flex h-40 items-center justify-center'>
        <Spinner className='h-5 w-5' />
      </div>
    );
  }

  return (
    <div>
      <FilterSearch value={searchValue} setValue={setSearchValue} />
      <div className='max-h-32 space-y-1.5 overflow-auto py-1'>
        {filteredOptions.map((option, i) => (
          <label
            key={'filterOption' + `${option}${i}`}
            className='focus:outline-none flex max-w-md items-center text-sm normal-case leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900'
          >
            <input
              type='checkbox'
              className='form-checkbox ml-1 mr-2'
              onChange={() => toggleFilter(option.value)}
              value={option.label}
              checked={value?.includes(option.value) || false}
            />
            {option ? (
              <span className='truncate'>{option.label}</span>
            ) : (
              <span className='text-ellipsis italic text-gray-500'>Untitled study</span>
            )}
          </label>
        ))}
      </div>
    </div>
  );
};
