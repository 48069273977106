import { getLimitInputName } from '@components/StudiesApp/components/StudyDraft/pages/Plan/Plan';
import { compact } from '@components/utils';

const DEFAULT = { on: false, size: 5, wait_hours: 12, auto_restart: false };
export function getBatchingSettings(count: number, study?: Study) {
  if (!study) return DEFAULT;

  const studyLimit = study[getLimitInputName(study)] || 0;

  const on = study.batch_on || count > studyLimit;

  return {
    on,
    size:
      on && study.batch_size
        ? study.batch_size
        : Math.max(Math.min(...compact([study.batch_size || DEFAULT.size, count])), 1),
    wait_hours: study.batch_wait_hours || DEFAULT.wait_hours,
    auto_restart: false
  };
}
