import * as React from 'react';

import { CellProps, Column } from 'react-table';
import tinytime from 'tinytime';
import { ELocalStorageKeys } from '@constants/localStorageKeys';

import { TextCell } from '@components/shared/GridTable/components/inputs/TextCell';
import { OtherHeader } from '@components/shared/GridTable/components/OtherHeader';
import { capitalize, compact } from '@components/utils';

import { CANCELATION_REASONS } from './consts';

const dateTemplate = tinytime('{MM} {DD}, {YYYY} {h}:{mm} {a}', { padHours: true });

interface Props {
  addFilter: (filter: string) => void;
  setSortValue: (value: string, desc: boolean) => void;
  columnsWidth: Record<string, number> | null;
  storageKey: ELocalStorageKeys;
  onClickCandidate: (id: number) => void;
  team: GQAccount['team'];
  showCancelationReason: boolean;
}

export const buildMessagesTableColumns = ({
  columnsWidth,
  onClickCandidate,
  team,
  showCancelationReason,
  ...headerProps
}: Props): Column<CandidateMessage>[] =>
  compact([
    {
      id: 'name',
      accessor: 'name',
      width: columnsWidth?.name || 200,
      headerLabel: 'Recipient name',
      Header: (props) => <OtherHeader {...props} {...headerProps} />,
      Cell: (props: CellProps<CandidateMessage>) => (
        <div
          aria-label={props.value || 'Unnamed candidate'}
          className='xx-candidate-link h400 cursor-pointer pl-2 font-bold text-indigo-600'
          onClick={(e) => {
            e.preventDefault();
            onClickCandidate(props.row.original.participation_id);
          }}
        >
          {props.value || <span className='font-light'>Unnamed candidate</span>}
        </div>
      )
    },
    {
      id: 'email',
      accessor: 'email',
      width: columnsWidth?.email || 200,
      headerLabel: 'Recipient email',
      Header: (props) => <OtherHeader {...props} {...headerProps} />,
      Cell: (props: CellProps<CandidateMessage>) => <TextCell {...props} />
    },
    {
      id: 'sender_name',
      width: columnsWidth?.email || 200,
      headerLabel: 'Sender name',
      Header: (props) => <OtherHeader {...props} {...headerProps} />,
      Cell: ({
        row: {
          original: { user_id }
        },
        ...rest
      }) => <TextCell {...rest} value={team.find((t) => t.id === user_id)?.name || '-'} />
    },
    {
      id: 'sender',
      accessor: 'sender',
      width: columnsWidth?.sender || 200,
      headerLabel: 'Sender email',
      Header: (props) => <OtherHeader {...props} {...headerProps} />,
      Cell: (props: CellProps<CandidateMessage>) => <TextCell {...props} />
    },
    {
      id: 'state',
      accessor: 'state',
      width: columnsWidth?.state || 80,
      headerLabel: 'Event',
      Header: (props) => <OtherHeader {...props} {...headerProps} />,
      Cell: ({ value, ...rest }) => <TextCell value={capitalize(value)} {...rest} />
    },
    showCancelationReason && {
      id: 'cancelation_reason',
      accessor: 'cancelation_reason',
      width: columnsWidth?.cancelation_reason || 200,
      headerLabel: 'Reason',
      Header: OtherHeader,
      Cell: ({ value, ...rest }) => (
        <TextCell value={CANCELATION_REASONS[value]?.short} tip={CANCELATION_REASONS[value]?.tooltip} {...rest} />
      )
    },
    {
      id: 'state_timestamp',
      accessor: 'state_timestamp',
      width: columnsWidth?.state_timestamp || 200,
      headerLabel: 'Event date & time',
      Header: OtherHeader,
      Cell: ({ value, ...rest }) => <TextCell value={value ? dateTemplate.render(value) : '-'} {...rest} />
    }
  ]);
