import React from 'react';

import { Button as ButtonEl, Popper } from '@components/common';
import { usePopUp } from 'hooks/usePopUp';

import { getAnswerText, getOperatorOptions, isSet } from '../utils';

import { Form } from './components';

interface Props {
  field: ScreenerField;
  editable?: boolean;
  onSave: (answer: IdealAnswer) => void;
}

export const Button: React.FC<React.PropsWithChildren<Props>> = ({ field, editable, onSave }) => {
  const hasAnswer = isSet(field);
  const operatorOptions = getOperatorOptions(field.field_type);
  const text = getAnswerText(field);

  if (operatorOptions.length === 0) {
    return null;
  }

  return (
    <div className='relative'>
      <Popper
        event='click'
        offset={[0, 6]}
        placement='bottom-end'
        zIndex={50}
        closeOnEscape
        closeOnClickOutside
        content={({ closePopper }) => (
          <Form
            field={field}
            onSave={(answer: IdealAnswer) => {
              onSave(answer);
              closePopper();
            }}
            options={operatorOptions}
          />
        )}
      >
        <ButtonEl small disabled={!editable} primary={hasAnswer} outline icon='star'>
          {text}
        </ButtonEl>
      </Popper>
    </div>
  );
};
