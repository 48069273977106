import * as React from 'react';

import tinytime from 'tinytime';

import { Text } from '@components/common';

import { getReadingTime } from '../utils';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

const mdDot = <div className='tablet:block hidden rounded-full border-2 border-gray-400' />;

interface Props {
  guide: Guide;
}
export const PublishedTitle: React.FC<React.PropsWithChildren<Props>> = ({ guide }) => {
  return (
    <div className='bg-white px-6 py-4'>
      <div className='pb-4'>
        <Text h='700'>{guide.title || 'Untitled guide'}</Text>
      </div>
      <div className='flex items-center'>
        <div className='flex-grow'>
          <Text h='400'>{guide.creator.name}</Text>
        </div>
        <div className='flex items-center space-x-2'>
          <Text h='400' color='gray-500'>
            {guide.published
              ? dateTemplate.render(guide.published_at)
              : `Created ${dateTemplate.render(guide.created_at)}`}
          </Text>
          {guide.published && mdDot}
          {guide.published && (
            <Text h='400' color='gray-500'>
              {getReadingTime(guide.word_count)} read
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};
