import * as React from 'react';

import { Route, Routes } from 'react-router-dom';

import { ClipPage } from './ClipPage';
import { Provider as InterviewPageProvider } from './context';

export const RepoClipApp: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <InterviewPageProvider>
      <Routes>
        <Route path=':highlightUuid' element={<ClipPage />} />
      </Routes>
    </InterviewPageProvider>
  );
};
