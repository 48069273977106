import * as React from 'react';
import { useEffect } from 'react';

import { DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { Basic, Card } from '@components/shared/Skeleton';
import { Circle } from '@components/shared/Skeleton/Circle';
import { useSidebar } from '@hooks/useSidebar';

export const Skeleton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const sidebar = useSidebar();
  useEffect(() => {
    sidebar.setHidden(true);
    return () => sidebar.setHidden(false);
  }, []);

  return (
    <div className='flex h-full flex-col'>
      <div className='flex w-full justify-between bg-white px-6 py-2.5'>
        <div className='flex items-center space-x-4 self-center text-sm text-gray-500'>
          <div className='flex items-center space-x-4'>
            <Basic width={320} height={30} />
          </div>
        </div>
        <div className='tablet:flex tablet:items-end tablet:justify-between'>
          <div className='flex flex-grow space-x-3 text-right'>
            <div className='flex flex-row items-center justify-end -space-x-2'>
              <Circle inline width={40} />
              <Circle inline width={40} />
              <Circle inline width={40} />
            </div>
            <Basic inline width={90} height={40} />
            <Circle inline width={40} />
          </div>
        </div>
      </div>
      <div className='border-b border-t border-gray-200 bg-white px-6 py-3'>
        <div className='tablet:mt-0 tablet:flex-row tablet:flex-wrap flex flex-col space-x-6'>
          <Basic height={20} width={80} />
          <Basic height={20} width={90} />
          <Basic height={20} width={60} />
          <Basic height={20} width={80} />
          <Basic height={20} width={50} />
        </div>
      </div>
      <div className='xx-study-tab relative flex flex-1'>
        <div className='flex h-full w-60 flex-col overflow-y-auto border-r border-gray-200 bg-white'>
          <div className='relative flex flex-shrink-0 items-center justify-between'>
            <nav className='flex flex-1 flex-col items-start space-y-3 px-6 py-12'>
              <Basic height={20} width={120} />
              <Basic height={20} width={110} />
              <Basic height={20} width={140} />
              <Basic height={20} width={100} />
              <Basic height={20} width={120} />
              <Basic height={20} width={150} />
              <Basic height={20} width={110} />
              <Basic height={20} width={110} />
              <Basic height={20} width={130} />
            </nav>
          </div>
        </div>
        <DashboardLayoutBody className='flex flex-1 flex-col'>
          <div className='h-full'>
            <Card height='100%' />
          </div>
        </DashboardLayoutBody>
      </div>
    </div>
  );
};
