import * as React from 'react';
import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Loading } from '@components/common';
import { useRenderAvatars } from '@components/RepositoryApp/hooks/useRenderAvatars';
import { InterviewCard } from '@components/shared/InterviewCard';
import { track } from 'components/tracking';

const PanelHeader: React.FC<React.PropsWithChildren<{ study: Study }>> = ({ study }) => {
  const publicUrl = window.location.protocol + '//' + window.location.host + '/' + study.public_path;
  return (
    <>
      <div className='mb-2 mt-2 md:flex md:items-start md:justify-between'>
        <div className='min-w-0 flex-1'>
          <h2 className='text-2xl font-bold text-gray-700 sm:truncate sm:text-3xl sm:leading-9'>{study.title}</h2>
        </div>
        <div className='flex-0 space-x-2'></div>
      </div>
    </>
  );
};

type StudyTab = 'landing_page' | 'welcome_email' | 'settings' | 'survey' | 'screener' | 'activities';
interface StudyTabProps {
  study: Study;
  setStudy: (study: Study) => void;
  saving: boolean;
  setSaving: (saving: boolean) => void;
}

// const TAB_LABELS: {[key in StudyTab]?: string}  = {
//   landing_page: 'Landing Page',
//   survey: 'Signup form',
//   welcome_email: 'Welcome email',
//   activities: 'Activity log',
//   settings: 'Settings',
// }

export const Phantom: React.FC<React.PropsWithChildren<{ study: Study }>> = ({ study }) => {
  const { renderAvatars } = useRenderAvatars();
  const { data: participations = [], isLoading: fetching, refetch } = api.useGetParticipationsQuery({ id: study.id });

  useEffect(() => {
    track('viewed_study', { style: study.style });
  }, []);

  return (
    <div>
      <div className='border-b border-gray-200 bg-white px-6 pt-6'>
        <PanelHeader study={study} />
      </div>

      <div className='relative bg-gray-50 p-4 md:p-6'>
        {fetching && (
          <div className='relative flex items-center justify-center px-24 py-24'>
            <Loading bg='transparent' />
          </div>
        )}
        {!fetching &&
          participations.length > 0 &&
          participations.map((interview) => (
            <InterviewCard
              className='my-1 inline-block cursor-pointer'
              loading={false}
              href={`/interviews/${interview.token}`}
              interviewAt={interview.interview_at}
              recordingId={interview.recording_id as any}
              studyTitle={study.title}
              userIds={[interview.moderator_id]}
              token={interview.token}
              candidateName={interview.name || ''}
              showLink={!!interview.token}
              renderAvatars={renderAvatars}
            />
          ))}
        {!fetching && participations.length === 0 && (
          <div className='relative flex items-center justify-center px-24 py-24'>
            <div className='text-center text-gray-500'>
              <div className='mb-4 text-4xl font-bold'>No Participants Yet</div>
              <div className='text-xl'>
                This study was created for incentive purposes.
                <br /> You can send incentives to candidates through this study by going to{' '}
                <Link to='/incentives'>Incentives</Link>.
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
