import React from 'react';

import { Tooltip } from '@components/Tooltip';
import { Text } from 'components/common';

export const LimitCell: React.FC<React.PropsWithChildren<{ value: string }>> = ({ value }) => {
  return (
    <Tooltip content="Doesn't match any segment" isDisabled={value !== 'No match'} width={200}>
      <div className='truncate px-2'>
        <Text h='400' truncate>
          {value}
        </Text>
      </div>
    </Tooltip>
  );
};
