import React, { ElementRef, ElementType, isValidElement, ReactElement, ReactNode, Ref } from 'react';

// External props allow `as` to be an element type or a React element.
type AsProp<E extends ElementType> = {
  as?: E | ReactElement;
  innerRef?: Ref<ElementRef<E>>;
  children?: ReactNode;
};

type ExternalProps<P, E extends ElementType> = AsProp<E> & P;

type InternalProps<P, E extends ElementType> = { as: E } & Omit<AsProp<E>, 'as'> & P;

export const createPolymorphicComponent = <P, E extends ElementType = 'div'>(
  render: (props: InternalProps<P, E>) => ReactElement
) => {
  return <C extends ElementType = E>(props: ExternalProps<P, C>) => {
    const { as, ...rest } = props;

    if (isValidElement(as)) {
      return React.cloneElement(as, { ...rest });
    }

    const Component = as as C;

    return render({ as: Component, ...rest } as InternalProps<P, E>);
  };
};
