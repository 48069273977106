import React, { FC } from 'react';

import { Text } from '@components/common';
import { LockSVG } from '@components/svgs';

export const RestrictedArtifactCard: FC = () => (
  <div className='border border-gray-200 bg-gray-50 px-6 py-12 text-center'>
    <LockSVG className='mx-auto mb-3' />
    <Text bold mb='1'>
      This artifact is restricted
    </Text>
    <Text h='400' color='gray-500' mb='4'>
      The artifact’s privacy settings require you to sign into your Great Question account, or ask the owner to make it
      public.
    </Text>
    <a className='h400 underline' href='/users/sign_in'>
      Sign-in
    </a>
  </div>
);
