import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { ChatShow } from './ChatShow';
import { ChatsIndex } from './ChatsIndex';

export const AiChatsApp: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Routes>
    <Route path='/:uuid' element={<ChatShow />} />
    <Route path='' element={<ChatsIndex />} />
  </Routes>
);
