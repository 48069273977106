import * as React from 'react';

import cn from 'classnames';

import { InputProps } from '../types';

export const LongTextInput: React.FC<React.PropsWithChildren<InputProps>> = ({
  field: { id, required },
  placeholder = 'Enter your answer',
  onFocus,
  register,
  error,
  autofocus
}) => {
  // TODO: Autoresize textarea
  return (
    <textarea
      id={String(id)}
      rows={3}
      className={cn(
        'focus-border-custom-brand tablet:text-sm tablet:leading-5 w-full rounded-md border-gray-300 transition duration-150 ease-in-out',
        { 'focus:ring-red border-red-300 text-red-900 placeholder-red-300 focus:border-red-300': error }
      )}
      name={String(id)}
      placeholder={placeholder}
      onFocus={onFocus}
      autoFocus={autofocus}
      ref={register({ required })}
      aria-invalid={error ? 'true' : 'false'}
      aria-describedby={`description-for-${id}`}
      aria-required={required ? 'true' : 'false'}
    ></textarea>
  );
};
