import * as React from 'react';

import { SelectOption, Text } from '@components/common';
import { humanize } from '@components/utils';

const STYLES: Study['style'][] = ['video_call', 'survey', 'online_task', 'unmoderated_test'];

export const STUDY_STYLE_OPTIONS: SelectOption<Study['style']>[] = STYLES.map((style) => ({
  value: style,
  label: humanize(style)
}));
export const FormGroup: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className='mb-6 w-full'>{children}</div>
);
export const Label: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Text h='500' bold className='mb-2'>
    {children}
  </Text>
);
export const SubLabel: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '', children }) => (
  <Text h='400' color='gray-500' className={'mb-2 ' + className}>
    {children}
  </Text>
);
export const SmallLabel: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '', children }) => (
  // <label>
  <Text h='400' className={'mb-2 ' + className}>
    {children}
  </Text>
);
