import * as React from 'react';

import { Button } from '@components/common';

import { Props as RestrictedActionProps } from './RestrictedAction/RestrictedAction';
import { RestrictedAction } from './RestrictedAction';

export interface Props extends Omit<RestrictedActionProps, 'children'> {
  action: string;
  className?: string;
  buttonProps?: React.ComponentProps<typeof Button>;
}

export const RestrictedButton: React.FC<React.PropsWithChildren<Props>> = ({
  action: cta,
  className = '',
  children,
  buttonProps,
  ...props
}) => (
  <RestrictedAction {...props}>
    {({ can, may }) =>
      can && may ? (
        <>{children}</>
      ) : (
        <Button inactive className={className} {...buttonProps}>
          {cta}
        </Button>
      )
    }
  </RestrictedAction>
);
