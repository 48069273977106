import * as React from 'react';

import cn from 'classnames';

import { Text } from '@components/common';

import { Spinner } from '../Spinner';

interface Props {
  hidden?: boolean;
  bg?: string;
  absolute?: boolean;
  text?: string;
  zIndex?: number;
}

export const Loading: React.FC<React.PropsWithChildren<Props>> = ({
  zIndex = 50,
  hidden,
  bg = 'gray-200',
  absolute = false,
  text
}) => (
  <div
    data-testid='loading'
    className={cn('xx-loading inset-0 flex items-center justify-center opacity-50', `z-${zIndex}`, `bg-${bg}`, {
      hidden,
      absolute,
      fixed: !absolute
    })}
  >
    {text ? (
      <div className='flex flex-col items-center space-y-1'>
        <Spinner className='h-5 w-5' />
        <Text>{text}</Text>
      </div>
    ) : (
      <Spinner className='h-5 w-5' />
    )}
  </div>
);
