import * as React from 'react';

import { Select, Text } from '@components/common';
import { rangeMap } from '@components/utils';

export const NumberRange: QuestionCardBodyComponent = ({ question: q, onChange, readOnly }) => {
  const numberRangeOpts = q.number_range_options as any;
  const { min, max, labels } = numberRangeOpts;

  const change = (value: Partial<NumberRangeOptions>) =>
    onChange({ ...q, number_range_options: { ...numberRangeOpts, ...value } as any });
  const onChangeMin = (min: number) => change({ min });
  const onChangeMax = (max: number) => change({ max });
  const onChangeLabels = (value: number, label: string) => {
    const newLabels = { ...labels };
    if (label) {
      newLabels[value] = label;
    } else {
      delete newLabels[value];
    }
    change({ labels: newLabels });
  };

  const labelControls = rangeMap(min, max, (i) => ({
    i,
    label: labels[i],
    onChange: (label: string) => onChangeLabels(i, label)
  }));

  return (
    <div className='text-gray-700'>
      <div className='flex items-center space-x-2 pb-4 text-sm'>
        <span>From</span>
        <Select
          noBorder
          disabled={readOnly}
          options={rangeMap(0, max - 1, (i) => ({ label: String(i), value: String(i) }))}
          value={String(min)}
          onChange={(value) => onChangeMin(parseInt(value))}
          caretClass='text-indigo-600'
        />
        <span>to</span>
        <Select
          noBorder
          disabled={readOnly}
          options={rangeMap(min + 1, 10, (i) => ({ label: String(i), value: String(i) }))}
          value={String(max)}
          onChange={(value) => onChangeMax(parseInt(value))}
          caretClass='text-indigo-600'
        />
      </div>

      <div className='space-y-3'>
        {labelControls.map(({ i, label, onChange }) => (
          <div className='flex' key={i}>
            <div className='mr-4 w-5 text-sm font-bold'>{i}</div>
            {readOnly ? (
              label && label != '' && <Text>{label}</Text>
            ) : (
              <input
                type='text'
                className='w-full max-w-sm border-0 p-0 px-1 text-sm placeholder-gray-400'
                value={label || ''}
                placeholder='Add label'
                onChange={(e) => !readOnly && onChange(e.currentTarget.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.currentTarget.blur();
                  }
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
