import * as React from 'react';
import { useState } from 'react';

import { Radio } from '@components/common';
import { InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { useFeature } from '@hooks/useFeature';

interface Props {
  readOnly?: boolean;
  type: Study['nylas_calendar_type'];
  conflictScope: Study['conflict_scope'];
  studyId: number;
  onChange: (study: { id: number } & Partial<Study>) => void;
}

export const CalendarTypeToggle: React.FC<React.PropsWithChildren<Props>> = ({
  studyId,
  readOnly,
  type: initialCalType,
  conflictScope: initialConflictScope,
  onChange
}) => {
  const [calType, setCalType] = useState(initialCalType);
  const [conflictScope, setConflictScope] = useState(initialConflictScope);
  const hasVirtualCalendar = useFeature('virtual_calendar');

  const toggleChangeCalType = () => {
    const newValue = calType === 'user' ? 'virtual' : 'user';

    setCalType(newValue);
    onChange({ id: studyId, nylas_calendar_type: newValue });
  };

  const toggleChangeConflictScope = () => {
    const newValue = conflictScope === 'global' ? 'local' : 'global';

    setConflictScope(newValue);
    onChange({ id: studyId, conflict_scope: newValue });
  };

  return (
    <div className='py-3'>
      <div className='flex items-center space-x-1'>
        <Tooltip
          isDisabled={!hasVirtualCalendar}
          content='Given your current calendar setup doesn’t share event data with us, we’re unable to automatically detect scheduling conflicts.'
        >
          <Radio
            disabled={readOnly || hasVirtualCalendar}
            type='checkbox'
            checked={calType === 'user' && !hasVirtualCalendar}
            name='cal_type'
            id='cal_type'
            onChange={toggleChangeCalType}
            label='Check for Moderator conflicts'
          ></Radio>
        </Tooltip>
        <Tooltip content='When checked, we’ll ensure participants can’t schedule time over your existing events unless marked as Free.'>
          <InfoCircleIcon className='h-4 w-4' />
        </Tooltip>
      </div>
      <div className='mt-3 flex items-center space-x-1'>
        <Tooltip
          isDisabled={calType !== 'user'}
          content='If checking for conflicts, double bookings will automatically be prevented'
        >
          <Radio
            disabled={calType === 'user' || readOnly}
            type='checkbox'
            checked={calType === 'user' || conflictScope === 'global'}
            id='conflict_scope'
            name='conflict_scope'
            onChange={toggleChangeConflictScope}
            label='Prevent double bookings on Great Question'
          />
        </Tooltip>
        <Tooltip
          content='Ensure that only one interview is booked on the moderators calendar across all Great Question studies. When
          disabled, double bookings may occur across studies with similar availability.'
        >
          <InfoCircleIcon className='h-4 w-4' />
        </Tooltip>
      </div>
    </div>
  );
};
