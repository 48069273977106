import { useContext, useMemo } from 'react';

import qs from 'qs';

import store from '@stores/config';

const WHITELISTED_FEATURES: GQFeature[] = ['unmoderated_layout'];

export const useFeature = (f: GQFeature): boolean => {
  const context = useContext<ConfigStore>(store);
  const features: GQFeatures = context?.state?.config?.features ?? {};

  const mergedFeatures = useMemo(() => {
    const merged: GQFeatures = { ...features };

    const { features: urlFeatures = {} } = qs.parse(window.location.search.replace('?', '')) as Record<
      string,
      Partial<GQFeatures>
    >;

    for (const feature in urlFeatures) {
      if (feature in features && WHITELISTED_FEATURES.includes(feature as GQFeature)) {
        merged[feature] = urlFeatures[feature] === 'true';
      }
    }

    return merged;
  }, [context]);

  return !!mergedFeatures[f];
};
