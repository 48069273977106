import * as React from 'react';

import { Heading, Text } from '@components/common';

const StepTitle: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Heading as='h2' className='mb-2'>
    {children}
  </Heading>
);
const StepHelper: React.FC<React.PropsWithChildren<{ mb?: string }>> = ({ mb = '8', children }) => (
  <Text h='500' color='gray-700' className={`block mb-${mb} font-normal`}>
    {children}
  </Text>
);

export { StepTitle, StepHelper };
