import React, { useState } from 'react';

import { Editor } from '@tiptap/react';

import { Button, Checkbox, Input, Loading, Modal, ModalHeading, Text } from '@components/common';
import { Column, Grid } from '@components/common/Grid';
import { Props as TemplateCardProps, TemplateCard } from '@components/TemplatesApp/components/TemplateCard';
import { track } from '@components/tracking';
import { useSearch } from '@hooks/useSearch';
import { useToaster } from '@stores/toaster';
import { getDocument } from 'api/queries';

import { useGetInterviewTemplatesQuery } from '../api';
import * as Icons from '../icons';
import * as toasts from '../toasts';
import { Props as TiptapProps } from '../useTiptap';

interface Props {
  editor: Editor;
  isOpen: boolean;
  onClose: () => void;
  onTemplatePick: TiptapProps['onTemplatePick'];
}

export const TemplatesModal = ({ editor, isOpen, onClose, onTemplatePick }: Props) => {
  const { data = [], isLoading } = useGetInterviewTemplatesQuery();
  const [query, setQuery] = useState<string>('');
  const [updateAll, setUpdateAll] = useState<boolean>(false);
  const { results: templates } = useSearch<InterviewTemplate>(data, ['title'], query, [data]);
  const [isFetchingDocument, setIsFetchingDocument] = useState(false);
  const showToast = useToaster();

  const onTemplateUse: TemplateCardProps<InterviewTemplate>['onTemplateUse'] = async (template) => {
    if (updateAll) {
      onTemplatePick?.({ editor, templateId: template.id });
    }

    setIsFetchingDocument(true);
    const [error, document] = await getDocument(template.document_id);
    if (error) {
      showToast(toasts.failedUseTemplate());
    } else {
      editor.commands.insertContent(document.doc);
      track('added_study_template_to_document', { documentId: document.id });
    }
    setIsFetchingDocument(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Modal
      onClose={onClose}
      size='xl'
      footerHeight='16'
      noPadding
      renderFooter={() => (
        <label className='flex cursor-pointer'>
          <Checkbox className='mr-2' selected={updateAll} onChange={(checked) => setUpdateAll(checked)} />
          Use template for all interviews on this study
        </label>
      )}
    >
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <>
          {templates.length || data.length ? (
            <>
              <div className='p-6'>
                <ModalHeading className='mb-3'>Choose an interview template</ModalHeading>
                {isFetchingDocument && <Loading />}
                <Input
                  type='search'
                  value={query}
                  onChange={setQuery}
                  icon='search'
                  size='md'
                  placeholder='Search for template…'
                />
              </div>

              <div className='flex-grow overflow-y-auto border-b border-t border-gray-200 p-6'>
                <Grid gap={3} desktop={3} tablet={3} mobile={2} className='mt-4 overflow-auto pb-4'>
                  {templates.map((template) => (
                    <Column key={template.id} className='flex w-full'>
                      <TemplateCard<InterviewTemplate>
                        template={template}
                        useTemplateButton
                        onTemplateUse={onTemplateUse}
                        kind='InterviewTemplate'
                      />
                    </Column>
                  ))}
                </Grid>
              </div>
            </>
          ) : (
            <div className='-mt-16 flex h-full flex-1 flex-col items-center justify-center'>
              <div className='mb-4 flex justify-center'></div>
              <Icons.TemplatesZDS className='mx-auto mb-4' />
              <Text h='600' mb='3'>
                You have no interview templates set up
              </Text>
              <Text h='400' color='gray-500' mb='4'>
                Create a template to use on your interview studies.
              </Text>
              <Button icon='externalLink' primary href='/study_templates'>
                Configure interview templates
              </Button>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};
