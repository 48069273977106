import * as React from 'react';

import cn from 'classnames';

// components
import { XSVG } from '@components/svgs';

const UploadingSVG: React.FC<React.PropsWithChildren<any>> = (props) => (
  <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.75 28.75C18.75 31.4022 19.8036 33.9457 21.6789 35.8211C23.5543 37.6964 26.0978 38.75 28.75 38.75C31.4022 38.75 33.9457 37.6964 35.8211 35.8211C37.6964 33.9457 38.75 31.4022 38.75 28.75C38.75 26.0978 37.6964 23.5543 35.8211 21.6789C33.9457 19.8036 31.4022 18.75 28.75 18.75C26.0978 18.75 23.5543 19.8036 21.6789 21.6789C19.8036 23.5543 18.75 26.0978 18.75 28.75Z'
      stroke='#E5E7EB'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M28.75 33.75V23.75' stroke='#E5E7EB' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M28.75 23.75L25 27.5' stroke='#E5E7EB' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M28.75 23.75L32.5 27.5' stroke='#E5E7EB' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M13.75 33.75H3.75C3.08696 33.75 2.45107 33.4866 1.98223 33.0178C1.51339 32.5489 1.25 31.913 1.25 31.25V3.75C1.25 3.08696 1.51339 2.45107 1.98223 1.98223C2.45107 1.51339 3.08696 1.25 3.75 1.25H21.465C22.1276 1.25014 22.763 1.5133 23.2317 1.98167L28.0183 6.76833C28.4867 7.237 28.7499 7.87242 28.75 8.535V13.75'
      stroke='#E5E7EB'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.4017 21.1302C10.2276 21.2172 10.0342 21.2581 9.83981 21.2493C9.64544 21.2405 9.45656 21.1821 9.29108 21.0798C9.12561 20.9774 8.98904 20.8344 8.89434 20.6645C8.79964 20.4945 8.74996 20.3031 8.75 20.1086V12.3919C8.74996 12.1973 8.79964 12.006 8.89434 11.836C8.98904 11.666 9.12561 11.5231 9.29108 11.4207C9.45656 11.3184 9.64544 11.26 9.83981 11.2512C10.0342 11.2423 10.2276 11.2833 10.4017 11.3702L18.12 15.2302C18.3095 15.3249 18.469 15.4704 18.5804 15.6506C18.6919 15.8307 18.7509 16.0384 18.7509 16.2502C18.7509 16.4621 18.6919 16.6697 18.5804 16.8499C18.469 17.0301 18.3095 17.1756 18.12 17.2702L10.4017 21.1302Z'
      stroke='#E5E7EB'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const ProcessingSVG: React.FC<React.PropsWithChildren<any>> = (props) => (
  <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M20 1.245V7.495' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M20 38.745V32.495' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M6.74167 6.73666L11.1617 11.1567'
      stroke='white'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M33.2583 33.2533L28.8383 28.835'
      stroke='white'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M1.25 19.995H7.5' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M38.75 19.995H32.5' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M6.74167 33.2533L11.1617 28.835'
      stroke='white'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M33.2583 6.73666L28.8383 11.1567'
      stroke='white'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const NoVideoSVG: React.FC<React.PropsWithChildren<any>> = (props) => (
  <svg {...props} width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.0625 21.5625C14.0625 23.5516 14.8527 25.4593 16.2592 26.8658C17.6657 28.2723 19.5734 29.0625 21.5625 29.0625C23.5516 29.0625 25.4593 28.2723 26.8658 26.8658C28.2723 25.4593 29.0625 23.5516 29.0625 21.5625C29.0625 19.5734 28.2723 17.6657 26.8658 16.2592C25.4593 14.8527 23.5516 14.0625 21.5625 14.0625C19.5734 14.0625 17.6657 14.8527 16.2592 16.2592C14.8527 17.6657 14.0625 19.5734 14.0625 21.5625Z'
      stroke='currentColor'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.26 26.8635L26.865 16.2598'
      stroke='currentColor'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.3125 25.3125H2.8125C2.31522 25.3125 1.83831 25.115 1.48667 24.7633C1.13504 24.4117 0.9375 23.9348 0.9375 23.4375V2.8125C0.9375 2.31522 1.13504 1.83831 1.48667 1.48667C1.83831 1.13504 2.31522 0.9375 2.8125 0.9375H16.0987C16.5957 0.937606 17.0723 1.13498 17.4238 1.48625L21.0138 5.07625C21.365 5.42775 21.5624 5.90432 21.5625 6.40125V10.3125'
      stroke='currentColor'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.80125 15.8477C7.67069 15.9129 7.52564 15.9436 7.37986 15.937C7.23408 15.9304 7.09242 15.8866 6.96831 15.8098C6.84421 15.733 6.74178 15.6258 6.67076 15.4984C6.59973 15.3709 6.56247 15.2274 6.5625 15.0814V9.29393C6.56247 9.148 6.59973 9.00449 6.67076 8.87701C6.74178 8.74953 6.84421 8.64232 6.96831 8.56555C7.09242 8.48879 7.23408 8.44501 7.37986 8.43838C7.52564 8.43176 7.67069 8.46249 7.80125 8.52768L13.59 11.4227C13.7322 11.4936 13.8517 11.6028 13.9353 11.7379C14.0189 11.8731 14.0632 12.0288 14.0632 12.1877C14.0632 12.3466 14.0189 12.5023 13.9353 12.6374C13.8517 12.7726 13.7322 12.8817 13.59 12.9527L7.80125 15.8477Z'
      stroke='currentColor'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

interface Props {
  bgClass?: string;
  state?: 'processing' | 'errored' | 'uploading' | null;
  className?: string;
}

export const PlaceholderPlayer: React.FC<React.PropsWithChildren<Props>> = ({
  state,
  className,
  bgClass = 'bg-indigo-1000'
}) => (
  <div className={cn('flex h-40 w-full items-center justify-center', bgClass, className)}>
    {state === 'uploading' && <UploadingSVG className='animate-pulse text-white' />}
    {state === 'errored' && <XSVG className='text-white' />}
    {state === 'processing' && <ProcessingSVG className='animate-pulse' />}
    {!state && <NoVideoSVG className='text-gray-400' />}
  </div>
);
