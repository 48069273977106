import * as React from 'react';

import { capitalize } from '@components/utils';
import { usePopUp } from '@hooks/usePopUp';

import { FilterDefinition, Operator } from '../types';
import { getOperatorCopy } from '../utils/getOperatorCopy';

type Props = {
  filterDefType: FilterDefinition<any>['type'];
  operator: Operator | null;
  options: Operator[];
  onChange: (op: Operator) => void;
};
export const OperatorDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  filterDefType,
  operator,
  options,
  onChange
}) => {
  const { ref, PopUp, open, closePopUp, togglePopUp } = usePopUp();

  const handleClick = (op: Operator) => () => {
    onChange(op);
    closePopUp();
  };

  return (
    <div ref={ref} className='inline-block'>
      <button
        type='button'
        name='operator'
        className='h400 rounded-md border border-gray-200 px-2.5 py-1.5 hover:border-indigo-600 hover:text-indigo-600'
        onClick={togglePopUp}
      >
        {operator ? capitalize(getOperatorCopy(filterDefType, operator)) : 'Choose…'}
      </button>
      <PopUp open={open} className='pt-2'>
        <ul className='rounded-md border border-gray-200 bg-white shadow-lg'>
          {options.map((op) => (
            <li
              className='tw-ui-dropdown-link text-gray-700 hover:text-indigo-600'
              key={op}
              role='option'
              aria-selected={op === operator}
              onClick={handleClick(op)}
            >
              {capitalize(getOperatorCopy(filterDefType, op))}
            </li>
          ))}
        </ul>
      </PopUp>
    </div>
  );
};
