import React, { FC, useState } from 'react';

import { Control, Controller } from 'react-hook-form';

import { Button, ZoomableImage } from '@components/common';
import {
  BlockableComponent,
  BlockableComponents,
  Enums,
  getBlockLabel,
  isBlockOfKind,
  Models
} from '@components/SurveyBuilder';
import { Forms } from '@components/SurveyBuilder';
import { TaskHeader } from '@components/Unmoderated/components/TaskHeader';

import { useUnmoderatedContext } from '../hooks/useUnmoderatedContext';
import * as Types from '../types';

import { ResponsiveCard } from './ResponsiveCard';

interface Props {
  block: Models.Block;
  control: Control<Types.FormData>;
  isValid: boolean;
}

const EMPTY_BLOCKABLE_BLOCKS: `${Enums.Kind}`[] = [Enums.Kind.info, Enums.Kind.welcome, Enums.Kind.thankYou];

export const Question: FC<Props> = ({ block, control, isValid }) => {
  const { blocks, deviceType, topBarUnmoderatedLayout } = useUnmoderatedContext();

  const [isMinimized, setIsMinimized] = useState(false);

  const renderer: Forms.FormController = ({ value, onChange }) => {
    if (!block) {
      return <></>;
    }

    const { kind } = block;

    if (EMPTY_BLOCKABLE_BLOCKS.includes(kind)) {
      return <></>;
    }

    if (isBlockOfKind(block, Enums.Kind.linearScale)) {
      return (
        <BlockableComponent<BlockableComponents.LinearScale>
          kind={kind}
          componentProps={{
            onChange,
            options: block.blockable.options,
            className: topBarUnmoderatedLayout ? 'bg-white p-6 rounded-lg border border-gray-200' : 'mt-4'
          }}
        />
      );
    }

    if (isBlockOfKind(block, Enums.Kind.multiSelect) || isBlockOfKind(block, Enums.Kind.singleSelect)) {
      return (
        <BlockableComponent<BlockableComponents.MultiSelect | BlockableComponents.SingleSelect>
          kind={kind}
          componentProps={{
            onChange,
            options: block.blockable.options,
            other: block.blockable.other,
            wrapperClassName: topBarUnmoderatedLayout ? 'bg-white p-6 rounded-lg border border-gray-200' : undefined
          }}
        />
      );
    }

    if (isBlockOfKind(block, Enums.Kind.yesNo)) {
      return (
        <BlockableComponent<BlockableComponents.YesNo>
          kind={kind}
          componentProps={{
            onChange,
            options: ['Yes', 'No'],
            wrapperClassName: topBarUnmoderatedLayout ? 'bg-white p-6 rounded-lg border border-gray-200' : undefined
          }}
        />
      );
    }

    if (isBlockOfKind(block, Enums.Kind.date)) {
      return (
        <BlockableComponent<BlockableComponents.Date>
          kind={kind}
          componentProps={{
            onChange: (date) => onChange(date?.toString()),
            selected: value && typeof value === 'string' ? new Date(value) : null
          }}
        />
      );
    }

    return <BlockableComponent kind={kind} componentProps={{ onChange }} />;
  };

  if (!block) {
    return null;
  }

  if (deviceType === 'desktop' && topBarUnmoderatedLayout) {
    return (
      <div className='flex h-screen w-full flex-col overflow-hidden bg-white pt-4'>
        <TaskHeader
          isMinimized={isMinimized}
          setIsMinimized={setIsMinimized}
          block={block}
          renderOptions={() => (
            <Button
              aria-label='Continue'
              className='btn-custom-brand'
              icon='arrowRight'
              type='submit'
              disabled={!isValid}
              small
              noStyle
            >
              Continue
            </Button>
          )}
        />

        <div className='flex w-full flex-1 overflow-hidden bg-gray-50'>
          <div className='flex w-full flex-1 flex-col items-center overflow-auto'>
            <div className='w-1/2 max-w-3xl px-4 py-6 text-left'>
              {block.image_url && (
                <div className='mb-6 flex w-full justify-center'>
                  <ZoomableImage
                    src={block.image_url}
                    alt='Question image'
                    style={{ width: '100%', maxHeight: '384px' }}
                    className='rounded-lg object-contain object-center'
                  />
                </div>
              )}
              <Controller
                control={control}
                key={block.id}
                name='value'
                render={renderer}
                rules={{
                  required: block.required,
                  validate: block.kind === 'multi_select' ? (value) => value.length > 0 : undefined
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ResponsiveCard
      blockKind={getBlockLabel(block.kind)}
      blockPosition={block.position}
      description={block.description}
      title={block.title}
      totalBlocks={blocks.length}
      renderFooter={() => (
        <Button
          aria-label='Continue'
          className='btn-custom-brand'
          icon='arrowRight'
          type='submit'
          disabled={!isValid}
          small
          noStyle
        >
          Continue
        </Button>
      )}
    >
      <div className='my-4'>
        <Controller
          control={control}
          key={block.id}
          name='value'
          render={renderer}
          rules={{
            required: block.required,
            validate: block.kind === 'multi_select' ? (value) => value.length > 0 : undefined
          }}
        />
      </div>
    </ResponsiveCard>
  );
};
