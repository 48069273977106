import * as React from 'react';

import { Radio } from '@components/common';

import { DropdownProps } from '../../types';

type Props<D = any> = DropdownProps<D, boolean | undefined>;

type BooleanOption = [string, boolean | undefined];

export const BOOLEAN_OPTIONS: BooleanOption[] = [
  ['Yes', true],
  ['No', false]
];

export const Boolean: React.FC<React.PropsWithChildren<Props<boolean | undefined>>> = ({
  value,
  onChange,
  definition
}) => {
  return (
    <div className='space-y-1.5'>
      {BOOLEAN_OPTIONS.map((option) => (
        <div className='flex' key={option[0]}>
          <Radio
            id={option[0]}
            checked={value === option[1]}
            type='radio'
            value={option[0]}
            label={option[0]}
            onChange={() => onChange({ value: option[1], allowNotSet: true })}
          />
        </div>
      ))}
    </div>
  );
};
