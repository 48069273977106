import { useCallback, useEffect, useMemo, useState } from 'react';

import { api } from 'api/reduxApi';

import { useUser } from './useUser';
import { UsersConnectedAccountCreatedChannel, UsersConnectedAccountsChannel, useWebsocket } from './useWebsocket';

export const useUserConnectedAccounts = () => {
  const { data, refetch, isUninitialized, isLoading } = api.useGetConnectedAccountsQuery();
  const [connectedAccounts, setConnectedAccounts] = useState<ConnectedAccount[]>([]);
  const user = useUser();

  useEffect(() => {
    if (data) {
      setConnectedAccounts(data);
    }
  }, [data]);

  const handleChannelUpdate = useCallback(() => {
    refetch();
  }, [refetch]);

  const params = useMemo(() => ({ user_id: user.id as number }), [user.id]);

  useWebsocket<{ user_id: number }, Document>({
    channel: UsersConnectedAccountsChannel,
    params,
    onReceived: handleChannelUpdate
  });

  useWebsocket<{ user_id: number }, Document>({
    channel: UsersConnectedAccountCreatedChannel,
    params,
    onReceived: handleChannelUpdate
  });

  return { connectedAccounts };
};
