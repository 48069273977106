import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import Tippy from '@tippyjs/react';

import { Button, Card, Dropdown, DropdownLink, Text } from '@components/common';
import { UsersConnectedAccountCreatedChannel } from '@hooks/useWebsocket';
import configStore from '@stores/config';
import consumer from 'helpers/consumer';

import { StepHelper, StepTitle } from '../../shared';
import { CALENDLY_AUTH_PATH } from '../constants';
import * as svgs from '../svgs';

import { AuthButton } from './AuthButton';

const ConnectedAccountCreatedChannel = (user_id) =>
  consumer.subscriptions.create(
    {
      channel: UsersConnectedAccountCreatedChannel,
      user_id: user_id
    },
    {}
  );

interface Props {
  onClick: (mode: 'nylas' | 'calendly' | 'custom') => void;
  currentUser: User;
  study: Study;
  studyOwner: Study['owner'];
  refetch: () => void;
}

export const CalendarModePicker: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
  study,
  refetch,
  currentUser,
  studyOwner
}) => {
  const [calendlyOpen, setCalendlyOpen] = useState(false);

  const isOwner = currentUser.id === studyOwner?.id;
  const [nylasConnected, setNylasConnected] = useState<boolean>(Boolean(studyOwner?.nylas));

  const {
    state: {
      config: { disabledCalendarModes }
    }
  } = useContext(configStore);

  const hasCalendlyFeature = !disabledCalendarModes.includes('calendly');

  useEffect(() => {
    ConnectedAccountCreatedChannel(studyOwner?.id).received = (data) => {
      if (data.message.provider === 'nylas') {
        refetch();
        setNylasConnected(true);
        onClick('nylas');
      }
    };
  }, []);

  return (
    <div className='py-gutter text-gray-700'>
      <div className='text-center'>
        <StepTitle>When do you want to schedule calls?</StepTitle>
        <StepHelper>Decide whether to schedule through your own calendar or Calendly.</StepHelper>
      </div>
      <Card className='mx-auto flex w-full max-w-3xl divide-x divide-gray-200 py-10'>
        <div className='flex-1 px-6 text-center'>
          <div className='mb-3 flex justify-center space-x-6'>
            {(studyOwner?.nylas_provider === 'google' || !studyOwner?.nylas) && <svgs.GCAL className='h-20 w-20' />}
            {(studyOwner?.nylas_provider === 'microsoft' || !studyOwner?.nylas) && (
              <img alt='' src='/temp-assets/o365.png' className='h-20 w-20' />
            )}
          </div>
          <Text bold>
            {studyOwner?.nylas && studyOwner?.nylas_provider === 'google' && 'Google Calendar'}
            {studyOwner?.nylas && studyOwner?.nylas_provider === 'microsoft' && 'O365 Calendar'}
            {!studyOwner?.nylas && 'Connect your calendar'}
          </Text>
          <Text h='400' color='gray-500'>
            {!studyOwner?.nylas &&
              'Integrate your Google or Microsoft Outlook calendar to schedule events directly to and around your schedule.'}
            {studyOwner?.nylas && 'We’ll check your calendar to find times that work for you or your team.'}
          </Text>
          {nylasConnected && (
            <Button className='mt-4' onClick={() => onClick('nylas')} primary>
              {study?.owner?.nylas_provider === 'google' && 'Use Google Calendar'}
              {study?.owner?.nylas_provider === 'microsoft' && 'Use O365 Calendar'}
            </Button>
          )}
          {/* {isOwner && !connected && ( */}
          {!nylasConnected && isOwner && (
            <AuthButton primary provider='nylas' className='mt-2'>
              Connect my calendar
            </AuthButton>
          )}
          {!nylasConnected && !isOwner && (
            <Tippy content={`${studyOwner?.name} must connect their calendar before you can choose this option.`}>
              <div>
                <Button className='mt-4' disabled>
                  Connect my calendar
                </Button>
              </div>
            </Tippy>
          )}
        </div>

        {hasCalendlyFeature && (
          <div className='flex-1 px-6 text-center'>
            <div className='mb-3'>
              <svgs.Calendly className='mx-auto h-20 w-20' />
            </div>
            <Text bold>Calendly</Text>
            <Text h='400' color='gray-500' className='mb-4'>
              You can connect your Calendly events or enter a URL for the calendar booking.
            </Text>
            <Dropdown
              isOpen={calendlyOpen}
              onClick={() => setCalendlyOpen(true)}
              onClose={() => setCalendlyOpen(false)}
              text={studyOwner?.calendly ? 'Use Calendly' : 'Connect Calendly'}
            >
              {studyOwner?.calendly && <DropdownLink onClick={() => onClick('calendly')}>Use Calendly</DropdownLink>}
              {!studyOwner?.calendly && isOwner && (
                <DropdownLink href={CALENDLY_AUTH_PATH} data-method='post'>
                  Connect Calendly account
                </DropdownLink>
              )}
              {!studyOwner?.calendly && !isOwner && (
                <Tippy content={`${studyOwner?.name} must connect their Calendly before you can choose this option.`}>
                  <div>
                    <DropdownLink disabled>Connect Calendly account</DropdownLink>
                  </div>
                </Tippy>
              )}
              <div className='px-4'>
                <hr />
              </div>
              <DropdownLink onClick={() => onClick('custom')}>Enter a link</DropdownLink>
            </Dropdown>
          </div>
        )}
      </Card>
    </div>
  );
};
