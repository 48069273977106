import * as React from 'react';

import { Spinner } from '@components/common';

import { DropdownProps } from '../../types';

import { MultiSelectWithSearch } from './MultiSelectWithSearch';

export type Meta = { studies: Study[] };

type Props<D = any> = DropdownProps<D, number[], Meta>;

export const Studies: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { meta } = props;

  type Option = { label: string; value: number };
  let options: Option[] = [];

  if (meta) {
    options = meta.studies.filter(Boolean).map((s) => ({
      value: s.id,
      label: s.title
    }));
  }

  if (!meta) {
    return (
      <div className='flex h-40 items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  return <MultiSelectWithSearch {...props} options={options} />;
};
