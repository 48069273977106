import React, { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { api as reduxApi } from '@api/reduxApi';
import { FilterSearch } from '@components/CandidatesApp/CandidatesIndex/FilterSearch';
import { Spinner, Text } from '@components/common';

import { DropdownProps } from '../../types';

export type Meta = { participations: Pick<Participation, 'customer_id' | 'name'>[] };

type Props<D = any> = DropdownProps<D, number[] | undefined, Meta> & { studyId: number };
type Option = { label: string; value: number };

const ITEMS_PER_PAGE = 200;
const PARTICIPANT_FIELDS = ['customer_id', 'name'];
const DEBOUNCE_TIME = 500;

export const Participant: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { studyId, localValue, value, onChange, meta } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const [participations, setParticipations] = useState<Meta['participations']>(meta?.participations || []);

  const { data, isLoading, isFetching } = reduxApi.useGetParticipationsPagedQuery({
    study_id: studyId,
    fields: PARTICIPANT_FIELDS,
    page: 1,
    query: {
      searchQuery: query,
      sort: 'updated_at',
      sort_desc: true
    },
    items: ITEMS_PER_PAGE
  });

  const { callback: debouncedSetQuery } = useDebouncedCallback(setQuery, DEBOUNCE_TIME);

  const options: Option[] | undefined = data?.data.map((p) => ({
    value: p.customer_id,
    label: p.name || 'Unnamed'
  }));

  const toggleFilter = (option: number) => {
    const newValue = value?.includes(option) ? value.filter((opt) => opt !== option) : (value || []).concat(option);
    const dirtyParticipations = participations.concat(data?.data.find((d) => d.customer_id === option) || []);

    setParticipations(dirtyParticipations);
    onChange({ value: newValue, ...(dirtyParticipations ? { meta: { participations: dirtyParticipations } } : {}) });
  };

  return (
    <div>
      <FilterSearch
        value={searchValue}
        setValue={(v) => {
          setSearchValue(v);
          debouncedSetQuery(v);
        }}
      />
      <div className='min-h-32 space-y-1.5 overflow-auto py-1'>
        {!!localValue?.length && !searchValue && (
          <div className='border-b border-gray-200 pb-1'>
            {localValue.map((id, i) => {
              const participant = participations?.find(({ customer_id }) => customer_id === id);

              if (!participant) return null;

              return (
                <label
                  key={'filterOption' + `${participant.customer_id}${i}`}
                  className='focus:outline-none flex max-w-md cursor-pointer items-center text-sm normal-case leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900'
                >
                  <input
                    type='checkbox'
                    name={participant.name || 'Unnamed'}
                    className='form-checkbox ml-1 mr-2 cursor-pointer'
                    onChange={() => toggleFilter(participant.customer_id)}
                    value={participant.customer_id}
                    checked={value?.includes(participant.customer_id) || false}
                  />
                  <Text h='400' truncate>
                    {participant.name || 'Unnamed'}
                  </Text>
                </label>
              );
            })}
          </div>
        )}
        <div className='h-32 overflow-auto'>
          {!options?.length && !isLoading && !isFetching && (
            <Text h='400' className='ml-1'>
              No results found
            </Text>
          )}
          {isLoading || isFetching ? (
            <div className='flex h-full items-center justify-center'>
              <Spinner className='h-6 w-6' />
            </div>
          ) : (
            options?.map((option, i) => {
              const toggled = localValue?.includes(option.value);

              if (toggled) return null;

              return (
                <label
                  key={'filterOption' + `${option}${i}`}
                  className='focus:outline-none flex max-w-md cursor-pointer items-center text-sm normal-case leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900'
                >
                  <input
                    type='checkbox'
                    className='form-checkbox ml-1 mr-2 cursor-pointer'
                    name={option.label}
                    onChange={() => toggleFilter(option.value)}
                    value={option.value}
                    checked={value?.includes(option.value) || false}
                  />
                  <Text h='400' truncate>
                    {option.label}
                  </Text>
                </label>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};
