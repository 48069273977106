import React, { HTMLAttributes, MouseEventHandler, ReactElement, useMemo, useState } from 'react';

import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { Placement } from '@popperjs/core';

import { api } from '@api/reduxApi';
import { Button, Modal, ModalHeading, Text } from '@components/common';
import { Offset } from '@components/common/Popper';
import { MAX_CLIPS } from '@components/HighlightReelsApp/consts';
import { HighlightsLimitModal } from '@components/shared/HighlightsLimitModal';
import { track } from '@components/tracking';
import { useToaster } from '@stores/toaster';

import { BulkAddDropdown } from '../../shared/BulkAddDropdown';

import { NotOnlyVideoHighlightsModal } from './NotOnlyVideoHighlightsModal';
import { NoVideoHighlightsModal } from './NoVideoHighlightsModal';
import * as toasts from './toasts';
import { getIDFromObjectID, getTypeFromObjectID } from './utils';

interface Props {
  disabled?: boolean;
  selectedArtifacts: string[];
  studyId?: number | null;
  medium?: boolean;
  renderButton?: (props: HTMLAttributes<HTMLButtonElement>) => ReactElement;
  popperPlacement?: Placement;
  popperOffset?: Offset;
  renderOnBodyRoot?: boolean;
  onlyContent?: boolean;
  hide?: () => void;
  trackCreateClick?: (count: number) => void;
  trackAddClick?: (count: number) => void;
}

export const BulkAddToHighlightReel = ({
  trackCreateClick,
  trackAddClick,
  studyId,
  selectedArtifacts = [],
  disabled: propDisabled,
  ...rest
}: Props) => {
  const [modal, setModal] = useState<null | 'noVideo' | 'notOnlyVideo'>(null);
  const [clipsLimitModalOpen, setClipLimitsModalOpen] = useState(false);
  const [selectedHlr, setSelectedHlr] = useState<HighlightReel>();
  const anyClips = selectedArtifacts.some((objectID) => getTypeFromObjectID(objectID) === 'Clip');
  const allClips = selectedArtifacts.every((objectID) => getTypeFromObjectID(objectID) === 'Clip');
  const onlyClips = selectedArtifacts.filter((objectID) => getTypeFromObjectID(objectID) === 'Clip');

  const clipIds = useMemo(() => onlyClips.map(getIDFromObjectID), [onlyClips]);

  const onClick = () => {
    if (allClips) return;

    setModal(anyClips ? 'notOnlyVideo' : 'noVideo');
  };

  const navigate = useNavigate();

  const showToast = useToaster();

  const [insertClips, { isLoading: isInserting }] = api.useAddClipsToHighlightReelMutation();

  const disabled = propDisabled || isInserting;

  const handleClickCreate = () => {
    trackCreateClick?.(clipIds.length);

    const params: any = { artifacts: onlyClips.join(',') };
    if (studyId) {
      params.project_id = studyId;
    }
    navigate(`/highlight_reels/new${qs.stringify(params, { encode: false, addQueryPrefix: true })}`);
  };

  const handleAddToHighlightReel = (highlightReel: HighlightReel) => {
    setSelectedHlr(highlightReel);

    const clipsToAdd = clipIds.map(Number).map((id) => ({ id }));

    if (highlightReel.clips.length + clipsToAdd.length > MAX_CLIPS) {
      setClipLimitsModalOpen(true);
      return;
    }

    trackAddClick?.(clipIds.length);

    insertClips({
      token: highlightReel.token,
      clips: clipsToAdd
    })
      .unwrap()
      .then(() => {
        showToast(toasts.successAddedHighlights(highlightReel));
      })
      .catch(() => {
        showToast(toasts.failedAddedHighlights());
      });
  };

  return (
    <>
      <BulkAddDropdown<HighlightReel>
        disabled={disabled}
        onClick={allClips ? undefined : (onClick as MouseEventHandler<HTMLButtonElement>)}
        icon='highlightReel'
        selectedCount={onlyClips.length}
        studyId={studyId}
        getQuery={api.useGetHighlightReelsQuery}
        hasCreateMode={false}
        onClickCreate={handleClickCreate}
        artifactType='reel'
        handleAdd={handleAddToHighlightReel}
        {...rest}
      />
      {clipsLimitModalOpen && (
        <HighlightsLimitModal
          existingClips={selectedHlr?.clips.length}
          clipsToAdd={clipIds.length}
          onClose={() => setClipLimitsModalOpen(false)}
        />
      )}
      {modal === 'noVideo' && <NoVideoHighlightsModal onClose={() => setModal(null)} />}
      {modal === 'notOnlyVideo' && <NotOnlyVideoHighlightsModal onClose={() => setModal(null)} />}
    </>
  );
};
