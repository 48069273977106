import * as React from 'react';
import { useEffect, useState } from 'react';

import type { FC } from 'react';

import { Button, Modal, ModalHeading, Radio, Text } from '@components/common';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteRecordingModal: FC<React.PropsWithChildren<Props>> = ({
  isOpen: defaultIsOpen,
  onClose,
  onDelete
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen);

  const handleConfirmButtonClick = () => {
    onDelete();
    onClose();
  };

  useEffect(() => {
    setIsOpen(defaultIsOpen);
  }, [defaultIsOpen]);

  return isOpen ? (
    <Modal onClose={onClose}>
      <ModalHeading className='mb-4'>Are you sure?</ModalHeading>
      <section className='mb-6 mt-4'>
        <Text h='400'>Deleting the video will also remove the transcript and any data associated with it.</Text>
      </section>
      <div className='flex'>
        <Button onClick={handleConfirmButtonClick} primary>
          Confirm &amp; delete
        </Button>
        <Button onClick={onClose} link>
          Don't delete
        </Button>
      </div>
    </Modal>
  ) : null;
};
