import * as React from 'react';

import pluralize from 'pluralize';
import tinytime from 'tinytime';

// TODO move to constants.
import { INCENTIVE_STATUS_LABELS } from '@components/IncentivesApp/filters/buildIncentivesFilterDefs';
import { ARTIFACT_TYPE_LABELS } from '@components/RepositoryApp/filters/artifactTypeOptions';
import { bigIntToString } from '@components/shared/TableFilters/utils/bigIntToString';
import { GUIDE_STATUS_LABELS } from 'components/GuidesApp/filters/buildGuidesFilterDefs';

import { Meta as ParticipantMeta } from '../components/dropdowns/Participant';
import { FilterState } from '../types';

import { TagsValue } from './TagsValue';

const dateTemplate = tinytime('{DD} {MM} {YY}');

const getMinMaxValue = (state: FilterState<any, any>, v: 'min' | 'max') => {
  const { type: defType, inputSuffix, transformValue } = state.definition;

  if (defType === 'date' && state.range?.[v]) {
    return dateTemplate.render(state.range[v]);
  }

  if (transformValue) {
    return `${transformValue(state.range?.[v]) || '0'}${v === 'max' && inputSuffix ? ` ${inputSuffix}` : ''}`;
  }

  return `${state.range?.[v] || '0'}${v === 'max' && inputSuffix ? ` ${inputSuffix}` : ''}` || null;
};

// This does not scale. It needs to be pushed into the config.
export const getDefaultValueCopy = (state: FilterState<any, any>): React.ReactNode => {
  const { type: defType, transformValue, inputSuffix } = state.definition;
  if (defType === 'segment') {
    return state.definition.name;
  }
  if (defType === 'artifact_type') {
    return state.value.map((v) => ARTIFACT_TYPE_LABELS[v]).join(', ');
  }
  if (defType === 'guide_status') {
    return state.value.map((v) => GUIDE_STATUS_LABELS[v]).join(', ');
  }
  if (defType === 'incentive_status') {
    return state.value.map((v) => INCENTIVE_STATUS_LABELS[v]).join(', ');
  }
  if (defType === 'participated_in_studies' || defType === 'studies') {
    return pluralize('study', state.value.length, true);
  }
  if (defType === 'participant') {
    const meta = state.meta as ParticipantMeta;
    if (state.value.length === 1 && state.meta) {
      const participant = meta.participations.find((p) => p.customer_id === state.value[0]);
      if (participant) {
        return participant.name || 'Unnamed Candidate';
      }
    }
    return pluralize('participant', state.value.length, true);
  }
  if (defType === 'team_ids') {
    return pluralize('team', state.value.length, true);
  }
  if (defType === 'team_members') {
    return pluralize('users', state.value.length, true);
  }
  if (state.operator === 'is_between') {
    const min = getMinMaxValue(state, 'min');
    const max = getMinMaxValue(state, 'max');

    if (min != null && max != null) {
      return `${min} — ${max}`;
    }
    if (min == null && max != null) {
      return `? — ${max}`;
    }
    if (min != null && max == null) {
      return `${min} —`;
    }
    return '?';
  }
  if (defType === 'eligibility') {
    return state.value ? 'Yes' : 'No';
  }
  if (defType === 'boolean' && state.value === undefined) {
    return '';
  }
  if (state.value == null) {
    return '';
  }
  if (defType === 'multiple_choice') {
    return Array.isArray(state.value) ? state.value.join(', ') : state.value;
  }
  if (defType === 'tag') {
    return <TagsValue ids={state.value} />;
  }
  if (defType === 'date') {
    return state.value ? dateTemplate.render(state.value) : '';
  }

  if (transformValue) {
    return `${transformValue(state.value) || '0'}${inputSuffix ? ` ${inputSuffix}` : ''}`;
  }

  return bigIntToString(state.value) || 'None';
};
