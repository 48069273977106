import React, { forwardRef, HTMLAttributes } from 'react';

import cn from 'classnames';

import { useFeature } from '@hooks/useFeature';
import { getBackgroundColor, getHexWithOpacity } from 'components/tags/colors';

import { PADDING } from './Interview';

interface Props extends HTMLAttributes<HTMLDivElement> {
  color?: string;
}

export const HighlightedParagraph = forwardRef<HTMLDivElement, Props>(
  ({ children, color, className, ...rest }, ref) => {
    let finalColor = '';

    if (color) {
      finalColor = color.startsWith('#') ? getHexWithOpacity(color, 0.15) : getBackgroundColor(color, 0.2);
    }

    return (
      <section
        ref={ref}
        style={{ backgroundColor: finalColor }}
        className={cn('h-full w-full bg-gray-50 px-3 pt-9', className)}
        {...rest}
      >
        {children}
      </section>
    );
  }
);
