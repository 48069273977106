import React, { FC, useMemo } from 'react';

import { Grid } from '@components/common/Grid';
import { SummaryColumn } from '@components/ScreenerResults/components';
import { PrototypeTestType } from '@components/SurveyBuilder/types/models';

interface Props {
  answers: NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>[];
  testType?: PrototypeTestType;
}

export const Summary: FC<Props> = ({ answers, testType }) => {
  const completed = answers.filter((answer) => answer.completed);

  const successRate = useMemo(() => {
    const successCount = answers.filter((answer) => answer.completed).length;

    return (successCount / answers.length) * 100;
  }, [answers]);

  const clicksToGoal = useMemo(() => {
    const totalClicksToGoal = completed.reduce((acc, answer) => acc + (answer.clicks_to_goal ?? 0), 0);

    return totalClicksToGoal / completed.length;
  }, [answers]);

  const totalClicks = useMemo(() => {
    return answers.reduce((acc, answer) => acc + (answer.clicks?.length ?? 0), 0);
  }, [answers]);

  const missClickRate = useMemo(() => {
    const totalMissClicks = answers.reduce((acc, answer) => acc + (answer.miss_clicks ?? 0), 0);

    return totalMissClicks / answers.length;
  }, [answers]);

  const avgDuration = useMemo(() => {
    const totalDuration = completed.reduce((acc, answer) => acc + (answer.time_in_task ?? 0), 0);

    return totalDuration / completed.length / 1000;
  }, [answers]);

  return (
    <section className='rounded border border-gray-200 p-4'>
      <Grid desktop={5} tablet={5} mobile={2}>
        {testType === 'open-ended' && (
          <SummaryColumn
            value={totalClicks}
            label='# of Clicks'
            tooltip='The total number of clicks users make while completing a task.'
            iconHref='#prototypeTaskMetrics-avgClicksToGoal'
          />
        )}
        {testType === 'goal-based' && (
          <>
            <SummaryColumn
              value={`${successRate.toFixed(1)}%`}
              label='Success rate'
              tooltip='Percentage of responses that successfully reached the goal screen'
              iconHref='#prototypeTaskMetrics-success'
            />
            <SummaryColumn
              value={isNaN(clicksToGoal) ? '-' : clicksToGoal.toFixed(1)}
              label='Avg. Clicks to goal'
              tooltip='Average number of clicks it took to reach the goal screen'
              iconHref='#prototypeTaskMetrics-avgClicksToGoal'
            />
            <SummaryColumn
              value={`${missClickRate.toFixed(1)}%`}
              label='Misclick rate'
              tooltip='The number of clicks outside click hotspots, as a percentage of total clicks'
              iconHref='#prototypeTaskMetrics-misClickRate'
            />
          </>
        )}

        <SummaryColumn
          value={isNaN(avgDuration) ? '-' : `${avgDuration.toFixed(1)}s`}
          label='Avg. Duration'
          tooltip='How long it took users to reach the goal screen, on average'
          iconHref='#prototypeTaskMetrics-avgDuration'
        />
      </Grid>
    </section>
  );
};
