import * as React from 'react';
// hooks
import { useState } from 'react';

import { Link } from 'react-router-dom';

import { ViewAllLink } from '@components/RepositoryApp/components/ViewAllLink';
// components
import { HighlightCard } from '@components/shared/HighlightCard';
import { getHost } from '@components/utils';
import { HighlightsZDSIcon } from 'components/CandidatesApp/CandidatesShow/components/HighlightsZDSIcon';
// This should be called useCandidateHighlights
import { useCandidateHighlights } from 'hooks/useCandidateHighlights';

const ZDS: React.FC<React.PropsWithChildren<{ firstName: string }>> = ({ firstName }) => (
  <div className='flex flex-col items-center py-6'>
    <div className='mx-auto'>
      <HighlightsZDSIcon />
    </div>
    <p className='mt-4 text-gray-500'>We'll show {firstName ? `${firstName}'s` : 'their'} highlights here.</p>
  </div>
);

interface Props {
  id: number;
  firstName: string | null;
}

export const Highlights: React.FC<React.PropsWithChildren<Props>> = ({ id, firstName }) => {
  const [showAllHighlights, setShowAllHighlights] = useState(false);

  const { highlights } = useCandidateHighlights(id);

  const count = highlights.length;

  const highlightsToShow = showAllHighlights ? highlights : highlights.slice(0, 3);

  if (highlightsToShow.length === 0 && firstName) return <ZDS firstName={firstName} />;

  // TODO: There is a new version of Link that might make this easier to accomplish.
  // Updating this would be more effort than it's worth right now.
  const relativeHighlightUrl = (url: string) => {
    const basePath = getHost();

    return url.replace(basePath, '');
  };

  return (
    <div className='pb-10'>
      <div className='-mx-3 flex flex-col pb-4'>
        {highlightsToShow?.map((highlight) => (
          <Link key={highlight.id} className='m-3 block' to={relativeHighlightUrl(highlight.document_source_url)}>
            <HighlightCard
              fullWidth
              interactable
              highlight={{
                ...highlight,
                tag_ids: highlight.tag_ids || [],
                id: highlight.id,
                uuid: highlight.uuid
              }}
            >
              <span>
                <span>{highlight.text}</span>
              </span>
            </HighlightCard>
          </Link>
        ))}
      </div>
      {!showAllHighlights && count > highlightsToShow.length && (
        <ViewAllLink count={count} onClick={() => setShowAllHighlights(true)} />
      )}
    </div>
  );
};
