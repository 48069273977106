import * as React from 'react';

import { Button } from '@components/common';
import { track } from '@components/tracking';

interface ZDSButtonProps {
  disabled?: boolean;
  heading: string;
  subheading: string;
  icon: React.ElementType;
  href?: string;
  onClick?: (e) => void;
  className?: string;
  trackEvent?: string;
  trackProps?: any;
}

export const ZDSButton: React.FC<React.PropsWithChildren<ZDSButtonProps>> = ({
  disabled,
  heading,
  subheading,
  href,
  icon: SVGIcon,
  onClick,
  trackEvent,
  trackProps
}) => {
  const handleClick = (e: React.MouseEvent): void => {
    if (trackEvent) {
      track(trackEvent, trackProps);
    }

    onClick?.(e);
  };

  return (
    <Button
      className='pb-4 pl-4 pr-4 pt-4'
      href={onClick ? undefined : href}
      onClick={disabled ? undefined : handleClick}
    >
      <div className='flex w-full space-x-4'>
        <div className='flex-shrink-0 text-center'>
          <SVGIcon className='text-indigo-600' />
        </div>
        <div className='text-left'>
          <div className='h500 mb-2 font-bold'>{heading}</div>
          <div className='h400'>{subheading}</div>
        </div>
      </div>
    </Button>
  );
};
