import React from 'react';

import { Button } from '@components/common';
import { usePermission } from '@hooks/usePermission';
import { useCollectionView } from 'components/stores/view';
import { ResetSVG } from 'components/svgs';

interface Props {
  resetView?: () => void;
  setViewDisabled?: boolean;
  setView?: () => void;
}

export const DefaultViewControls: React.FC<React.PropsWithChildren<Props>> = ({
  resetView,
  setView,
  setViewDisabled
}) => {
  const isAdmin = usePermission('isAdmin')();
  const viewHook = useCollectionView();

  if (!viewHook) return null;

  return (
    <>
      <div className='flex border-t border-gray-200 px-4 py-2'>
        <div className='flex w-1/2'>
          {resetView && (
            <button
              onClick={resetView}
              data-testid='reset-to-default'
              className='flex cursor-pointer items-center space-x-2 whitespace-nowrap text-indigo-600'
            >
              <ResetSVG />
              <span className='text-sm'>Reset to default</span>
            </button>
          )}
        </div>
        {isAdmin && setView && (
          <div className='w-1/2'>
            <Button
              data-testid='set-as-default'
              medium
              className='w-full whitespace-nowrap'
              onClick={setView}
              disabled={setViewDisabled}
            >
              Set view as default
            </Button>
          </div>
        )}
      </div>
      {isAdmin && (
        <div className='px-4 text-xs text-gray-500'>
          Default views will also include any filters you currently have set.
        </div>
      )}
    </>
  );
};
