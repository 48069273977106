import React, { useCallback, useEffect, useRef } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import Tippy from '@tippyjs/react';

import { Spinner, Text } from '@components/common';
import { Pill } from '@components/common';
import { ChatPrompt } from '@components/shared/AI';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import { AskAiRainbowSVG, CloseSVG, TrashSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { uniqueId } from '@components/utils';
import { useTrackDuration } from '@hooks/useTrackDuration';
import { useUser } from '@hooks/useUser';
import { useAiChat } from '@stores/aiChat';

import { useCreateAiMessageMutation, useSubmitMessageFeedbackMutation } from '../api';
import { createChannel } from '../utils/createChannel';

import { ScopeInput } from './ScopeInput';
import { AiChatZeroDataScreen, Message } from './';

export const AiChatSlideOut: React.FC = () => {
  const user = useUser();

  const {
    open,
    page,
    context,
    setContext,
    content,
    messages,
    chat,
    createChat,
    isChatLoading,
    isChatError,
    setOpen,
    setContent,
    setMessages,
    channel,
    reset
  } = useAiChat();

  const [createMessage, { isLoading: isMessageLoading, isError: isMessageError }] = useCreateAiMessageMutation();
  const [submitFeedback, { isLoading: isSubmitFeedbackLoading, isError: isSubmitFeedbackError }] =
    useSubmitMessageFeedbackMutation();

  const isError = isMessageError || isChatError;
  const lastMessage = messages[messages.length - 1];

  function appendMessage(message: AiMessage) {
    setMessages((messages) => [...(messages || []), message]);
    requestAnimationFrame(scrollToBottom);
  }

  function updateOrCreateMessage(message: AiMessage) {
    const index = messages.findIndex((m) => m.id === message.id);
    if (index >= 0) {
      const newMessages = [...messages];
      newMessages[index] = message;
      setMessages(newMessages);
      requestAnimationFrame(scrollToBottom);
    } else {
      appendMessage(message);
    }
  }

  const trackAiChatResponseStart = useTrackDuration({ action: 'ai_chat_response_start' });
  const trackAiChatResponseCompletion = useTrackDuration({ action: 'ai_chat_response_completed' });

  // Send events on slideOut open and close
  useEffect(() => {
    // Runs on mount
    track('ai_chat_open', { page, context });
    return () => {
      // Runs on unmount
      track('ai_chat_close', { page, context });
    };
  }, []);

  // Run when last message changes
  useEffect(() => {
    if (lastMessage != null) {
      if (!isGenerating) {
        stopTimers(false, 'ai_chat_response_completed');
      }
    }
  }, [messages[messages.length - 1]]);

  const updateOrCreateMessageRef = useRef(updateOrCreateMessage);
  useEffect(() => {
    updateOrCreateMessageRef.current = updateOrCreateMessage;
  }, [updateOrCreateMessage]);

  useEffect(() => {
    if (chat != null) {
      const uuid = uniqueId();
      channel.current[uuid] = createChannel(chat.uuid, {
        received: (data) => {
          updateOrCreateMessageRef.current(data.message);
        }
      });
      return () => {
        channel.current[uuid]?.unsubscribe();
      };
    }
  }, [chat]);

  async function handleSubmit() {
    // TODO: consider doing this from the server response.
    appendMessage({ id: '-1', content, role: 'user' });
    const aiChat = chat || (await createChat({ context }));
    createMessage({ chat_id: aiChat.uuid, content });
    setContent('');
    requestAnimationFrame(scrollToBottom);

    // Start trackers
    track('ai_chat_submit_message', { page, context, chatId: aiChat.uuid });
    startTimers();
  }

  function startTimers() {
    trackAiChatResponseStart.start();
    trackAiChatResponseCompletion.start();
  }

  function stopTimers(earlyTermination: boolean, trackerType?: string) {
    // If user exits while the duration trackers are in progress, send a stop event and note that the message was in progress
    if ((!trackerType || trackerType === 'ai_chat_response_completed') && trackAiChatResponseCompletion.isTracking) {
      trackAiChatResponseCompletion.stop({
        chat_id: chat?.uuid,
        message_id: lastMessage?.id,
        earlyTermination // We assume that if the tracker is running, there is a message in progress
      });
    }
    if ((!trackerType || trackerType === 'ai_chat_response_start') && trackAiChatResponseStart.isTracking) {
      trackAiChatResponseStart.stop({
        chat_id: chat?.uuid,
        message_id: lastMessage?.id,
        earlyTermination // We assume that if the tracker is running, there is a message in progress
      });
    }
  }

  function handleOnClose() {
    const DDRum = (window as any).DD_RUM as typeof datadogRum;
    if (DDRum != null) {
      // Stop recording session replay
      DDRum.stopSessionReplayRecording();
    }
    setOpen(false);
  }

  async function handleSubmitFeedback(message_id: string, rating: number) {
    if (chat != null && chat.uuid != null) {
      let response;
      try {
        response = await submitFeedback({ chat_id: chat.uuid, message_id, rating });
      } catch (e) {
        return;
      }
      updateOrCreateMessage(response.data.message);
      track('ai_chat_feedback', { chat_id: chat.uuid, message_id, rating });
    }
  }

  function scrollToBottom() {
    document.getElementById('slideout-inner')?.scrollIntoView({ block: 'end' });
  }

  const lastMessageIsUser = lastMessage?.role === 'user';
  const isLoading = isMessageLoading || isChatLoading || lastMessageIsUser;
  const zeroData = messages == null || messages.length === 0;
  const isGenerating = !zeroData && !lastMessageIsUser && lastMessage?.status !== 'completed';
  const isScopeLoaded = context != null && context.id != null;

  // Runs when lastMessageIsUser changes
  useEffect(() => {
    if (!lastMessageIsUser && messages.length > 1 && trackAiChatResponseStart.isTracking) {
      // AI response has started arriving, stop the responseStart tracker
      stopTimers(false, 'ai_chat_response_start');
    }
  }, [lastMessageIsUser]);

  const clearChat = useCallback(async () => {
    reset();
    track('ai_chat_clear_chat', { page, context });
    stopTimers(true);
  }, [page, context, reset]);

  const handleChangeContext = useCallback(
    (newContext: AiChatContext) => {
      setContext(newContext);
      clearChat();
      track('ai_chat_change_context', { page, context: newContext });
    },
    [clearChat]
  );

  if (!open) {
    return null;
  }

  const copyLink = chat != null ? `${location.origin}/ai/chats/${chat.uuid}` : undefined;

  return (
    <section
      data-testid='ai-chat-slideout'
      className='z-50 flex h-full flex-col border-l border-gray-200 bg-white'
      style={{ width: '28rem' }}
    >
      <div className='flex flex-shrink-0 border-b border-gray-200 px-4 py-3'>
        <div className='flex flex-1 items-center'>
          <AskAiRainbowSVG className='mr-3 h-8 w-8 text-indigo-600' />
          <Text h='600' bold as='h2' className='mr-3'>
            Ask AI
          </Text>
          <Pill color='blue'>Beta Preview</Pill>
        </div>
        <div className='-mt-1 flex items-center space-x-1'>
          <Tippy content='Clear chat'>
            <button
              className='rounded-full p-1 hover:bg-gray-50 hover:text-indigo-600'
              name='Clear chat'
              aria-label='Clear chat'
              onClick={clearChat}
            >
              <TrashSVG className='h-4 w-4' />
            </button>
          </Tippy>
          {copyLink && (
            <CopyURLButton
              className='focus:ring-indigo rounded-full p-1 text-gray-700 hover:bg-gray-50 hover:text-indigo-700 active:text-indigo-700'
              noStyle
              icon='link'
              text={copyLink}
            />
          )}
          <button
            className='rounded-full p-1 hover:bg-gray-50 hover:text-indigo-600'
            name='Close'
            aria-label='Close'
            onClick={handleOnClose}
          >
            <CloseSVG className='h-4 w-4' />
          </button>
        </div>
      </div>
      <div className='flex items-center border-b border-gray-200 px-4 py-2'>
        <Text h='400' className='mr-4'>
          Scope
        </Text>
        <ScopeInput context={context} setContext={handleChangeContext} />
      </div>
      <div className='flex-1 overflow-y-auto py-4'>
        <div id='slideout-inner'>
          {zeroData && <AiChatZeroDataScreen setContent={setContent} context={context} />}
          {messages.map((message, i) => (
            <Message
              {...message}
              chat_id={chat?.uuid}
              handleSubmit={handleSubmitFeedback}
              user={user ? { id: user.id, name: user.name } : undefined}
              key={`ai-message-${i}`}
            />
          ))}
          {isLoading && (
            <div className='mx-4 my-2'>
              <Spinner className='h-6 w-6' id='ai-chat-loading' />
            </div>
          )}
        </div>
      </div>
      <div className='p-4'>
        {isScopeLoaded && (
          <ChatPrompt
            id='ai-prompt'
            className='flex-shrink-0'
            value={content}
            onChange={(value) => setContent(value)}
            onSubmit={handleSubmit}
            isInvalid={isError}
            placeholder={'Enter any query...'}
            data-testid='ai-chat-prompt'
            fillWithPlaceholder
            isLoading={isLoading || isGenerating}
            autoFocus
          />
        )}
      </div>
    </section>
  );
};
