import * as React from 'react';

import { Provider } from 'react-redux';

import { store } from '@api/reduxApi';
import { AppErroredPage } from '@components/AppErroredPage';
import { Loading } from '@components/common';
import { SentryProvider } from '@components/providers';
import { WhoamiContainer } from '@components/stores/whoami';
import { renderComp } from '@components/utils';
import { Provider as ToastProvider } from '@stores/toaster';

import { Onboarding } from './Onboarding';

export function mount(): void {
  renderComp('onboarding-app', ({ redirect_to }) => (
    <Provider store={store}>
      <WhoamiContainer>
        {({ whoami, isLoading }) =>
          isLoading ? (
            <Loading />
          ) : (
            <SentryProvider whoami={whoami} renderComponent={(props) => <AppErroredPage {...props} />}>
              <ToastProvider>
                <Onboarding redirectTo={redirect_to} />
              </ToastProvider>
            </SentryProvider>
          )
        }
      </WhoamiContainer>
    </Provider>
  ));
}
