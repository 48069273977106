import { FilterState } from '../types';

import * as funcs from './funcs';

export const getDefaultFunc = <T>(recordValue: any, state: FilterState<T>): boolean => {
  if (!state.allowNotSet) {
    if (!recordValue && recordValue !== 0 && state.operator !== 'is_blank') {
      return false;
    }
  }

  // TODO: make sure all type cases are explicitly covered (Record<FilterType,>)
  switch (state.definition.type) {
    case 'free_text':
      return funcs.text(recordValue, state.operator, state.value);
    case 'text':
      return funcs.singleChoice(recordValue, state.operator, state.value);
    case 'number':
      return funcs.number(recordValue, state.range || {}, state.operator, state.value);
    case 'boolean':
      return funcs.boolean(recordValue, state.operator, state.value);
    case 'date':
      return funcs.date(recordValue, state.range || {}, state.operator, state.value);
    case 'multiple_choice':
      return funcs.multipleChoice(recordValue, state.operator, state.value);
    default:
      return true;
  }
};
