import * as React from 'react';

import Tippy from '@tippyjs/react';

import { Button } from '@components/common';
import { DuplicateSVG } from '@components/svgs';

interface Props {
  onClick: React.MouseEventHandler;
}

export const DuplicateEmailButton: React.FC<React.PropsWithChildren<Props>> = ({ onClick }) => {
  return (
    <Tippy className='rounded-lg bg-gray-500' content='Duplicate template' arrow={false}>
      <Button text rounded small onClick={onClick} className='xx-duplicate-email-template'>
        <DuplicateSVG className='-mt-1 mr-1 inline' />
      </Button>
    </Tippy>
  );
};
