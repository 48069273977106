import React, { useState } from 'react';

import { Button, Modal } from 'components/common';
import { ChangeIncentiveModal, StudyFunder } from 'components/Wallet';

import { IncentiveForm } from '../IncentivesForm';

export const AddIncentiveModal: React.FC<
  React.PropsWithChildren<{
    study: Study;
    onClose: () => void;
    onSave: PageProps['onSave'];
    funder: StudyFunder;
    limitCount: number;
  }>
> = ({ onClose, onSave, study: initialStudy, funder, limitCount }) => {
  const [study, setStudy] = useState<Study>(initialStudy);
  const [confirm, setConfirm] = useState<boolean>(false);
  const requiresFunding = study.incentive_method === 'tremendous';

  function handleSave() {
    if (requiresFunding) {
      setConfirm(true);
    } else {
      onSave(study);
      onClose();
    }
  }
  if (confirm) {
    return (
      <ChangeIncentiveModal
        limitCount={limitCount}
        funder={funder}
        study={study}
        onCancel={onClose}
        onConfirm={() => {
          onSave(study);
          onClose();
        }}
      />
    );
  }

  return (
    <Modal
      title='Add Incentive'
      size='md'
      onClose={onClose}
      renderFooter={() => (
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button primary onClick={handleSave}>
            {requiresFunding ? 'Fund incentive…' : 'Add incentive'}
          </Button>
        </>
      )}
    >
      <IncentiveForm study={study} onSave={setStudy} />
    </Modal>
  );
};
