import React from 'react';

import { Basic } from '@components/shared/Skeleton';

export const SkeletonLoader = () => {
  return (
    <div aria-label='Loading skeleton' className='w-full divide-y divide-gray-200'>
      {Array(6)
        .fill(6)
        .map((_, idx) => (
          <div key={idx} className='w-full flex-1 px-6 py-4'>
            <Basic width='100%' h='4' />
          </div>
        ))}
    </div>
  );
};
