import React, { FC, useEffect, useMemo, useState } from 'react';

import { Column, Grid } from '@components/common/Grid';
import { Portal } from '@components/Portal';
import { SurveyBuilderSpriteSVG } from '@components/svgs';
import { pick } from '@components/utils';

import { Blocks } from './components/Blocks';
import { BlockSettings } from './components/BlockSettings';
import { SurveyBuilderSkeleton } from './components/SurveyBuilderSkeleton';
import { isBlockOfKind } from './helpers/isBlockOfKind';
import { usePatchBlocksQuery } from './hooks/usePatchBlocksQuery';
import * as Enums from './types/enums';
import * as Forms from './types/forms';
import * as Models from './types/models';
import { surveyBuilderApi as api } from './api';

export interface Props {
  surveyId: number;
}

export const SurveyBuilder: FC<Props> = ({ surveyId }) => {
  const [activeId, setActiveId] = useState<Models.Block['id']>();

  const { data: blocks = [], isLoading } = api.useGetSurveyBuilderBlocksQuery(surveyId);
  const [createBlock] = api.useCreateSurveyBuilderBlockMutation();
  const [updateBlock] = api.useUpdateSurveyBuilderBlockMutation();
  const [createBlockable] = api.useCreateSurveyBuilderBlockableMutation();
  const [updateBlockable] = api.useUpdateSurveyBuilderBlockableMutation();
  const [updateBlockableSettings] = api.useUpdateSurveyBuilderBlockableSettingsMutation();

  const [{ update }] = usePatchBlocksQuery(surveyId);

  const activeBlock = useMemo(() => blocks?.find(({ id }) => id === activeId), [blocks, activeId]);

  const handleOnSave = async (formData: Forms.Data) => {
    if (!activeBlock || !activeId) return;

    const { config, description, required, settings, title } = formData;

    if (config) {
      await updateBlockable({ id: activeBlock.blockable_id, kind: activeBlock.kind, config }).unwrap();
    }

    if (settings && Object.keys(settings).length > 0) {
      await updateBlockableSettings({ id: activeBlock.blockable_id, kind: activeBlock.kind, settings }).unwrap();
    }

    const block = await updateBlock({ description, id: activeId, required, title }).unwrap();

    if (isBlockOfKind(block, Enums.Kind.prototypeTest)) {
      update<Enums.Kind.prototypeTest>(activeId, { blockable: pick(block.blockable, 'title', 'paths') });
    } else if (isBlockOfKind(block, Enums.Kind.websiteTest)) {
      update<Enums.Kind.websiteTest>(activeId, { blockable: pick(block.blockable, 'is_iframable') });
    }
  };

  const handleOnChange = (formData: Forms.Data) => {
    if (activeBlock) {
      update(activeBlock.id, {
        title: formData.title,
        description: formData.description,
        required: formData.required,
        blockable: { ...activeBlock.blockable, ...formData.config, settings: formData.settings }
      });
    }
  };

  const handleOnCreate = async (kind: Models.Block['kind']) => {
    try {
      const blockable = await createBlockable({ kind }).unwrap();

      return createBlock({
        blockable_id: blockable.id,
        description: null,
        kind,
        required: false,
        surveyId,
        title: null
      }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (blocks && blocks.length > 0 && !activeId) {
      setActiveId(blocks[0].id);
    }
  }, [blocks, activeId]);

  if (isLoading) {
    return <SurveyBuilderSkeleton />;
  }

  return (
    <div className='w-full flex-1 overflow-hidden rounded border border-gray-200 bg-white'>
      <Grid desktop={12} className='h-full'>
        <Column
          id='surveyBuilderBlocks'
          desktop={4}
          className='min-h-full border-r border-gray-200'
          style={{ contain: 'size' }}
        >
          {blocks && blocks.length > 0 && (
            <Blocks
              active={activeBlock}
              onCreateBlock={handleOnCreate}
              setActive={(block) => setActiveId(block?.id)}
              surveyId={surveyId}
            />
          )}
        </Column>
        <Column id='surveyBuilderBlockSettings' desktop={8} className='min-h-full' style={{ height: 'fit-content' }}>
          {activeBlock && (
            <BlockSettings
              block={activeBlock}
              onChange={handleOnChange}
              onSave={handleOnSave}
              style={{ minHeight: 628 }}
            />
          )}
        </Column>
      </Grid>

      <Portal>
        {/* Survey builder icons sprite */}
        <SurveyBuilderSpriteSVG style={{ display: 'none' }} />
      </Portal>
    </div>
  );
};
