import * as React from 'react';

import DatePicker from 'react-datepicker';

import { useInputState } from '@components/CandidateAttrs/hooks/useInputState';
import { getWrapperClass } from '@components/CandidateAttrs/index';

export const DateInput: React.FC<React.PropsWithChildren<CandidateAttrInput<string>>> = ({
  value,
  styles,
  label,
  setValue,
  onSave,
  onFocus,
  onBlur
}) => {
  const { handleBlur, handleFocus, focused } = useInputState(value, setValue, onBlur, onFocus);
  const selected = value && value !== '' && new Date(value);

  const handleChange = (v) => {
    setValue(v);
    onSave && onSave(v);
  };

  if (!styles) return null;

  return (
    <tr data-testid='attr-field' className={getWrapperClass(styles, focused)}>
      <td className={styles.label}>{label}</td>
      <td className={styles.value}>
        <DatePicker
          autoFocus
          selected={selected || null}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className='tablet:text-base no-outline w-full flex-1 px-4 py-2.5'
          placeholderText='Enter...'
          showYearDropdown
          startOpen={true}
          customInput={<input />}
        />
      </td>
    </tr>
  );
};
