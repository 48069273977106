import * as React from 'react';

import { Basic } from '@components/shared/Skeleton';

export const InsightSkeleton = () => {
  return (
    <div className='px-6 py-3'>
      {Array(12)
        .fill(1)
        .map(() => (
          <Basic h='6' width='full' />
        ))}
    </div>
  );
};
