import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { AskAiSVG } from '@components/svgs';

export const Chapters: React.FC<React.PropsWithChildren<InterviewChapters>> = ({ chapters }) => {
  const location = useLocation();
  return (
    <div className='mb-7 text-sm'>
      <div className='flex items-center text-sm text-gray-500'>
        <AskAiSVG className='mr-2' /> Generated by Great Question AI
      </div>
      <div className='my-2'>
        {chapters.map((chapter, index) => {
          const linkTo = `${location.pathname}?ts=${chapter.timestamp}`;
          return (
            <div key={index} className='mb-2 flex items-center'>
              <Link to={linkTo} className='mr-3 rounded bg-gray-200 px-2 font-mono' style={{ fontFamily: 'monospace' }}>
                {chapter.time_str}
              </Link>
              <Link to={linkTo}>{chapter.title}</Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};
