import * as React from 'react';

import Skeleton from 'react-loading-skeleton';

import { api } from '@api/reduxApi';
import { Text } from '@components/common';
import { useRenderAvatars } from '@components/RepositoryApp/hooks/useRenderAvatars';
import { InterviewCard } from '@components/shared/InterviewCard';

interface Props {
  candidateId: number;
}
export const Interviews: React.FC<React.PropsWithChildren<Props>> = ({ candidateId: id }) => {
  const { data: participations, isLoading } = api.useGetCandidateParticipationsQuery({ id });
  const { renderAvatars } = useRenderAvatars();

  const interviews = participations?.filter((item) => item.style === 'video_call');

  return (
    <div className='divide-y-gray-200 divide-y'>
      {isLoading && <Skeleton className='h-12 bg-gray-50' />}

      {!isLoading && interviews?.length === 0 && (
        <Text color='gray-500' mt='2' mb='6'>
          This candidate has no interview recordings.
        </Text>
      )}

      {!isLoading && interviews && interviews.length > 0 && (
        <div className='grid grid-cols-2 gap-4'>
          {interviews.map(
            ({ id: participationId, interview_at, name, token, study, customer_id, tags, recording_id }) => (
              <InterviewCard
                key={participationId}
                interviewAt={interview_at}
                href={`/interviews/${token}`}
                candidateName={name || ''}
                token={token}
                studyTitle={study?.title}
                userIds={[customer_id]}
                renderAvatars={renderAvatars}
                recordingId={recording_id as any}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};
