import * as React from 'react';
import { useEffect } from 'react';

import { Button, Modal } from '@components/common';
import { api } from 'api/reduxApi';
import { useToaster } from 'components/stores/toaster';
import * as toasts from 'components/StudiesApp/components/StudyDraft/toasts';

interface ModalProps {
  onSubmit: () => void;
  onCancel: () => void;
  open: boolean;
  teamUserToAdd?: TeamUser;
  study: SimpleStudy;
}

export const AddUserToStudyModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  onSubmit,
  onCancel,
  open,
  teamUserToAdd,
  study
}) => {
  const [createStudyUser, { isSuccess, isError }] = api.useCreateStudyUserMutation();
  const showToast = useToaster();

  const confirm = () => {
    if (teamUserToAdd) createStudyUser({ study_id: study.id, user_id: teamUserToAdd.id, role: null });
  };

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedUpdate());
      onCancel();
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successUpdate());
      onSubmit();
    }
  }, [isSuccess]);

  if (!open || !teamUserToAdd) {
    return null;
  }

  return (
    <Modal
      title={`Do you want to add ${teamUserToAdd.name} as an editor on the study?`}
      onClose={onCancel}
      renderFooter={() => (
        <>
          <Button link onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button primary onClick={confirm}>
            Confirm
          </Button>
        </>
      )}
    >
      In order to add as an email sender, {teamUserToAdd.name} needs to be added as an editor on the study too.
    </Modal>
  );
};
