import React, { CSSProperties, FC, useMemo, useState } from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { Column, Grid } from '@components/common/Grid';
import { ChevronDownSVG } from '@components/svgs';
import { formatSeconds } from '@components/VideoPlayer';

import { ClickPointer } from './ClickPointer';

interface Props {
  answers: NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>[];
}

export const Timelines: FC<Props> = ({ answers }) => {
  const [sortTimeBy, setSortTimeBy] = useState<'asc' | 'desc'>('asc');

  const maxTimeInTask = useMemo(
    () =>
      answers.reduce(
        (max, answer) => (answer && answer.time_in_task && answer.time_in_task > max ? answer.time_in_task : max),
        0
      ),
    [answers]
  );

  const sortedAnswers = useMemo(() => {
    return answers.sort((a, b) => {
      if (a.time_in_task && b.time_in_task) {
        return sortTimeBy === 'asc' ? a.time_in_task - b.time_in_task : b.time_in_task - a.time_in_task;
      }

      return 0;
    });
  }, [answers, sortTimeBy]);

  const timeChart = [0, maxTimeInTask / 2 / 1000, maxTimeInTask / 1000];

  const timelineStyle = (timeInTask: number | null): CSSProperties => {
    const style: CSSProperties = {};

    if (timeInTask) {
      const percentage = (timeInTask / maxTimeInTask) * 100;
      style.width = `${percentage}%`;
    }

    return style;
  };

  const clickMap = (
    answer: ScreenerResponseAnswerValue<'prototype_test'>
  ): [PrototypeClick, 'sm' | 'lg', CSSProperties][] => {
    const map: [PrototypeClick, 'sm' | 'lg', CSSProperties][] = [];

    if (!answer || !answer.time_in_task || !answer.start_at) {
      return map;
    }

    const answerStartAt = new Date(answer.start_at).getTime();

    answer.clicks.forEach((click, index) => {
      const style: CSSProperties = {};

      const clickAt = new Date(click.at).getTime();

      let size: 'sm' | 'lg' = 'lg';

      if (index === answer.clicks.length - 1) {
        if (click.handled || !answer.completed) {
          size = 'lg';
        } else {
          size = 'sm';
        }

        style.left = '100%';
      } else {
        size = click.handled ? 'lg' : 'sm';

        const percentage = ((clickAt - answerStartAt) / answer.time_in_task!) * 100;
        style.left = `${percentage}%`;
      }

      style.marginLeft = `-${size === 'lg' ? 24 : 12}px`;

      map.push([click, size, style]);
    });

    return map;
  };

  const clickPointerContent = (
    click: PrototypeClick,
    answer: NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>
  ): string | null => {
    const content = `${click.response_path_index + 1}`;

    if (!click.handled) {
      return null;
    }

    if (click.response_path_index + 1 === answer.response_path.length - 1 && answer.completed) {
      return null;
    }

    return content;
  };

  return (
    <div>
      <div role='rowgroup'>
        <Grid role='row' desktop={12}>
          <Column role='columnheader' desktop={1} className='py-3'>
            <Text className='text-sm' bold>
              Name
            </Text>
          </Column>
          <Column role='columnheader' desktop={1} className='py-3'>
            <button
              type='button'
              className='focus:outline-none flex items-center space-x-2'
              aria-label='Sort by time'
              onClick={() => setSortTimeBy(sortTimeBy === 'asc' ? 'desc' : 'asc')}
            >
              <Text as='span' className='text-sm' bold>
                Time
              </Text>
              <ChevronDownSVG className={cn('h-3 w-3', { 'rotate-180 transform': sortTimeBy === 'asc' })} />
            </button>
          </Column>
          <Column role='columnheader' desktop={10} className='py-3'>
            <div className='flex h-full items-end justify-between'>
              {timeChart.map((time, index) => (
                <Text key={index} className='text-xs text-gray-500'>
                  {formatSeconds(time)}
                </Text>
              ))}
            </div>
          </Column>
        </Grid>
      </div>
      <div role='rowgroup' className='overflow-y-auto overflow-x-hidden' style={{ maxHeight: 384 }}>
        {sortedAnswers.map((answer, index) => (
          <Grid key={index} role='row' desktop={12}>
            <Column role='cell' desktop={1} className='py-3'>
              <Text className='w-full truncate text-sm'>{answer.participation_name}</Text>
            </Column>
            <Column role='cell' desktop={1} className='py-3'>
              <Text className='text-sm'>{answer.time_in_task ? formatSeconds(answer.time_in_task / 1000) : ''}</Text>
            </Column>
            <Column role='cell' desktop={10} className='border-l border-r border-dashed border-gray-200 px-4 py-3'>
              <div
                aria-label='Prototype test response timeline'
                className='relative flex h-6 items-center rounded-3xl bg-indigo-200'
                style={timelineStyle(answer.time_in_task)}
              >
                <div className='relative h-4 w-full'>
                  {clickMap(answer).map(([click, size, style], index) => (
                    <ClickPointer
                      key={index}
                      size={size}
                      failure={!click.handled}
                      style={style}
                      className='absolute top-1/2 -translate-y-1/2 transform'
                    >
                      {clickPointerContent(click, answer)}
                    </ClickPointer>
                  ))}
                </div>
              </div>
            </Column>
          </Grid>
        ))}
      </div>
    </div>
  );
};
