import * as React from 'react';

import cn from 'classnames';

import { XSVG } from '@components/GQSurveyBuilder/questions/shared';
import { InfoCircleIcon } from '@components/svgs';
import { useLocalStorage } from '@hooks/useLocalStorage';

import { AlertPosition, AlertType } from './consts';
import { AdvertisingMegaphoneIcon, AlertTriangleIcon, CheckCircleIcon } from './svgs';

export type AlertProps = {
  Icon?: React.FC<React.PropsWithChildren<any>>;
  className?: string;
  position?: AlertPosition;
  border?: boolean;
  heading?: string;
  bg?: string;
  type?: AlertType;
  cta?: React.ReactNode;
  noIcon?: boolean;
  dismissKey?: string;
};

const COLORS: Record<AlertType, string> = {
  info: 'bg-gray-50',
  warning: 'bg-yellow-50',
  error: 'bg-red-50',
  success: 'bg-green-50',
  promo: 'bg-indigo-50'
};

const ACCENT_COLORS: Record<AlertType, string> = {
  info: 'gray-700',
  warning: 'yellow-600',
  error: 'red-600',
  success: 'green-600',
  promo: 'indigo-600'
};

const ICONS: Record<AlertType, React.FC<React.PropsWithChildren<{ className?: string }>>> = {
  info: InfoCircleIcon,
  warning: AlertTriangleIcon,
  error: AlertTriangleIcon,
  success: CheckCircleIcon,
  promo: AdvertisingMegaphoneIcon
};

export const AlertHeading: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <h3 className='h500-bold pb-2'>{children}</h3>
);
export const AlertMessage: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className='h400'>{children}</div>
);
export const AlertLink: React.FC<
  React.PropsWithChildren<{ href?: string; handleClick?: () => void; className?: string }>
> = ({ href, children, handleClick, className = 'ml-2 font-bold text-gray-700 underline' }) => (
  <a
    className={className}
    href={href}
    onClick={(e) => {
      if (handleClick) {
        e.preventDefault();
        handleClick();
      }
    }}
  >
    {children}
  </a>
);

export const Alert: React.FC<React.PropsWithChildren<AlertProps>> = ({
  Icon: customIcon,
  noIcon = false,
  children,
  heading,
  cta,
  bg = '',
  border = false,
  position = 'center',
  type = 'info',
  className = '',
  dismissKey: dismissKey
}) => {
  const [dismissed, setDismissed] = useLocalStorage<'true'>(dismissKey || null);

  const Icon = customIcon || ICONS[type];
  const accentColor = ACCENT_COLORS[type];
  const color = COLORS[type];

  if (dismissed) {
    return null;
  }

  return (
    <div
      className={cn('rounded', className, bg ? `bg-${bg}` : color, 'relative flex border-l-4', `border-${accentColor}`)}
      data-testid='xx-alert'
    >
      {dismissKey && (
        <button aria-label='Close' name='Close' onClick={() => setDismissed('true')}>
          <XSVG className='absolute right-2 top-2' />
        </button>
      )}
      <div className={border ? 'w-full rounded border-b border-r border-t border-gray-200 p-4' : 'w-full p-4'}>
        <div
          className={cn('flex w-full', {
            'items-center': position === 'center',
            'items-start': position === 'top'
          })}
        >
          {!noIcon && (
            <div className='flex flex-shrink-0 items-center'>
              <Icon className={cn(`text-${accentColor}`, 'h-6 w-6')} />
            </div>
          )}
          <div className='h400 ml-4 flex flex-1 flex-col items-start justify-between'>
            {heading ? (
              <>
                <AlertHeading>{heading}</AlertHeading>
                <AlertMessage>{children}</AlertMessage>
              </>
            ) : (
              children
            )}
          </div>
          <div className='flex-0'>{cta || ''}</div>
        </div>
      </div>
    </div>
  );
};
