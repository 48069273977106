import { BusinessPlanSVG, EnterprisePlanSVG, ProPlanSVG, StarterPlanSVG, TeamPlanSVG } from '@components/svgs';

import { ActionsKey, UpgradeCopy } from './types';

export const ACTIONS: Record<ActionsKey, string> = {
  studies: 'create studies',
  invites: 'send invites',
  candidates: 'add candidates',
  createStudy: 'create new studies',
  createAccountInvitation: 'invite team members',
  addCandidate: 'add candidates',
  createTemplate: 'create template',
  canCreate: 'create assets',
  updateParticipation: 'update participation',
  approveStudy: 'approve studies',
  storage: 'store more data',
  disableFeatures: 'disable features',
  manageAttrs: 'manage attributes',
  manageSegments: 'manage segments'
};

export const FEATURES: Record<PlanFeature, string> = {
  highlight_reels: 'create highlight reels',
  custom_consent: 'create custom consent forms',
  crm_integrations: 'connect your CRM via API, Snowflake, or Salesforce',
  round_robin: 'set round robin interviews',
  in_person: 'set in person interviews',
  incentives: 'access additional types of incentives',
  fund_with_invoice: 'fund your account with an invoices',
  set_auth_methods: 'specify which authentication methods you allow',
  data_retention: 'toggle data retention',
  study_language: 'select the study language',
  saml: 'use SAML authentication',
  focus_groups: 'schedule focus groups',
  continuous: 'schedule continuous interviews',
  basic_branding: 'customize particpant facing assets to match your brand',
  hipaa: 'store health information in your CRM',
  external_recruitment: 'recruit participants from external panels',
  transcript_download: 'download transcripts',
  reporting: 'access reporting',
  custom_eligibility: 'add custom eligibility rules',
  unmoderated: 'enable unmoderated testing'
};

export const FEATURES_v2: Record<PlanFeature, UpgradeCopy> = {
  highlight_reels: {
    text: 'Highlight reels',
    title: 'Highlight reels',
    benefits: [
      'Stitch clips together into a single, shareable highlight reel for your team to watch',
      'Share clips of multiple participants with stakeholders'
    ]
  },
  custom_consent: {
    text: 'Custom Consent Forms',
    title: 'Custom Consent Forms',
    benefits: ['Insert as many consent forms as needed for your organization', 'Provide legal coverage for new studies']
  },
  crm_integrations: {
    text: 'CRM Integrations',
    title: 'CRM Integrations',
    benefits: [
      'Connect your CRM to Great Question via API, Snowflake, or Salesforce',
      'Sync participant data to your CRM'
    ]
  },
  round_robin: {
    text: 'Round robin',
    title: 'Round Robin Scheduling',
    benefits: ['Share the research load', 'Flexibility for panelists']
  },
  continuous: {
    text: 'Continuous interviews',
    title: 'Continuous interviews',
    benefits: [
      'Set up weekly calls with new participants',
      'Keep your calendar open on an ongoing basis',
      'Automatically restart sending invites when slots open up'
    ]
  },
  basic_branding: {
    text: 'Custom branding',
    title: 'Custom branding',
    benefits: [
      'Set custom text color and background color for your participant facing assets',
      'Customize look and feel of email and landing pages',
      'Use your brand colors to keep your participants engaged'
    ]
  },
  focus_groups: {
    text: 'Focus groups',
    title: 'Focus groups',
    benefits: [
      'Schedule group calls with multiple participants',
      'Set up multiple sessions that participants can choose from'
    ]
  },
  in_person: {
    text: 'In-person interviews',
    title: 'In-person interviews',
    benefits: ['Conduct field interviews', 'Invite participants to physical location for observation studies']
  },
  saml: {
    text: 'SAML authentication',
    title: 'Sign in with SAML provider (such as OKTA)',
    benefits: ['Streamline user management']
  },
  incentives: {
    text: 'Incentives',
    title: 'Incentives',
    benefits: ['Send incentives directly through Great Question', 'Automate paying rewards.']
  },
  external_recruitment: {
    text: 'External recruitment',
    title: 'External recruitment',
    benefits: ['Recruit participants from external panels', 'Access over 2m participants']
  },
  fund_with_invoice: {
    text: 'Fund with invoice',
    title: 'Fund incentives with an invoice',
    benefits: ['Easier to budget incentive spend', 'Give finance teams insight into incentive spend']
  },
  set_auth_methods: {
    text: 'Set authentication methods',
    title: 'Being able to set authentication methods',
    benefits: [
      'Pick how new users are authenticated for access to Great Question',
      ' Allow admins to pick between SSO and SAML for authentication'
    ]
  },
  data_retention: {
    text: 'Data retention policy',
    title: 'Data retention policies',
    benefits: [
      'Determine how long you want to retain candidate data',
      'Determine what kind of data you keep, and set schedules for regular deletion/cleaning'
    ]
  },
  study_language: {
    text: 'Internationalization / Local language content',
    title: 'Local language content',
    benefits: [
      'Convert all participants facing content into their local languages ',
      'Create a great participant experience, with no extra work'
    ]
  },
  hipaa: {
    text: 'HIPAA compliance',
    title: 'HIPAA compliance',
    benefits: [
      'Store health information in your CRM',
      'Ensure that your data is secure and compliant with HIPAA regulations',
      'Sign a BAA with Great Question'
    ]
  },
  transcript_download: {
    text: 'Download transcripts',
    title: 'Download transcripts',
    benefits: ['Analyse your tanscript data locally', 'Use your transcript data with other services']
  },
  reporting: {
    text: 'Reporting',
    title: 'Reporting',
    benefits: ['Charts that surface how much research is happening, recruitment metrics, and more']
  },
  custom_eligibility: {
    text: 'custom eligibility rules',
    title: 'custom eligibility rules',
    benefits: [
      'Add more guardrails to govern who can be contacted',
      'Use your own custom attributes for the new rules',
      'Add custom titles, labels and criteria'
    ]
  },
  unmoderated: {
    text: 'Unmoderated testing',
    title: 'Unmoderated testing',
    benefits: [
      'Run browser-based tests on desktop or mobile (no apps required)',
      'Test with your own users or recruit from our external panel',
      'View performance with metrics, timelines, paths, and click maps',
      'Dig deeper with recordings and transcripts, and create highlight reels'
    ]
  }
};
export const PlanSVGs: Record<Exclude<GQPlanKey, 'trial'>, React.FC<React.PropsWithChildren<any>>> = {
  free: StarterPlanSVG,
  v3_team: TeamPlanSVG,
  v3_enterprise: EnterprisePlanSVG
};
