import React from 'react';

import cn from 'classnames';
import { ColumnDef } from '@tanstack/react-table';

import { Text } from '@components/common';
import { capitalize } from '@components/utils';

import { getMatrixValue, getStyles, renderMergedCategories } from '../../utils';

import { AgreementMatrixData } from './buildAgreementMatrixData';

export type DefinitionFnReturn = ColumnDef<AgreementMatrixData>[];

export type DefinitionFn = (args: {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
  categories: string[];
  showPercentage: boolean;
  cards: string[];
  mergedCategories: { name: string; categories: string[] }[];
}) => DefinitionFnReturn;

export const getAgreementMatrixColumnDefs: DefinitionFn = ({
  mergedCategories,
  showPercentage,
  categories,
  answers
}) => {
  const columns: DefinitionFnReturn = [
    {
      id: 'card',
      accessorKey: 'card',
      cell: (props) => (
        <div
          style={{ minWidth: '100px', maxWidth: '200px' }}
          className='h-9 w-full overflow-hidden border border-l-0 border-t-0 border-gray-200 bg-white p-2'
        >
          <Text className='h400 truncate'>{props.getValue<string>()}</Text>
        </div>
      ),
      header: () => <div className='h-9 border border-l-0 border-t-0 border-gray-200 bg-white' />
    },
    ...categories.map((category) => ({
      id: category,
      accessorFn: (row) => row.categoriesWithAgreement[row.card][category],
      cell: (props) => {
        const value = props.getValue() ? Math.round(((props.getValue() || 0) / answers.length || 1) * 100) : 0;

        const { textColor, backgroundColor } = getStyles(value);

        return (
          <div
            className='h400 flex h-9 items-center justify-center border border-l-0 border-t-0 border-gray-200 p-2'
            style={{ backgroundColor, minWidth: '100px', maxWidth: '200px' }}
          >
            <Text className={cn('h400 truncate', textColor)}>
              {getMatrixValue(showPercentage, props.getValue(), answers.length)}
            </Text>
          </div>
        );
      },
      header: () => (
        <Text
          style={{ minWidth: '100px', maxWidth: '200px' }}
          className='h400 flex h-9 items-center border border-l-0 border-t-0 border-gray-200 bg-white'
        >
          <div className='truncate pl-2'>{capitalize(category)}</div>
          {renderMergedCategories({ mergedCategories, category })}
        </Text>
      )
    }))
  ];
  return columns;
};
