import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import tinytime from 'tinytime';

import { Loading, Text } from '@components/common';
import {
  DashboardBreadcrumb,
  DashboardHeaderShelf,
  DashboardLayout,
  DashboardLayoutBody
} from '@components/layouts/DashboardLayout';
import { track } from '@components/tracking';

import { useGetAiChatQuery } from '../../api';

import { ChatCard } from './ChatCard';

type Params = {
  uuid: string;
};

export const ChatShow = () => {
  const { uuid } = useParams<Params>() as Params;
  const { data: chat, isLoading, isError } = useGetAiChatQuery(uuid);

  useEffect(() => {
    if (chat) {
      track('ai_chat_view', { uuid: chat.uuid });
    }
  }, [chat]);

  return (
    <DashboardLayout>
      <DashboardHeaderShelf>
        <DashboardBreadcrumb to={`/ai/chats`}>Chats</DashboardBreadcrumb>
      </DashboardHeaderShelf>

      <DashboardLayoutBody className='min-h-full'>
        {isError && <Text>Something went wrong loading the chat</Text>}
        {isLoading && <Loading absolute />}
        {chat && <ChatCard chat={chat} />}
      </DashboardLayoutBody>
    </DashboardLayout>
  );
};
