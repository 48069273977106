import * as React from 'react';

import { api } from '@api/reduxApi';
import { Text } from '@components/common';
import { Basic } from '@components/shared/Skeleton';

import { CalendarEventLink } from './CalendarEventLink';

export const CalendarEvents: React.FC<React.PropsWithChildren<{ participation: Participation }>> = ({
  participation
}) => {
  const id = participation?.id;
  const { data: calendarEvents, isLoading: isLoadingCalendarEvents } = api.useGetParticipationCalendarEventsQuery(
    { id },
    {
      skip: !id
    }
  );
  return (
    <>
      <div className='flex flex-col space-y-3'>
        {isLoadingCalendarEvents && <Basic />}
        {!isLoadingCalendarEvents && calendarEvents?.length === 0 && <Text color='gray-500'>No events.</Text>}
        {calendarEvents?.map((calendarEvent) => (
          <CalendarEventLink key={calendarEvent.id} calendarEvent={calendarEvent} />
        ))}
      </div>
    </>
  );
};
