import * as React from 'react';
import { useEffect, useState } from 'react';

import cn from 'classnames';

import { api } from '@api/reduxApi';
import { Button, Text } from '@components/common';
import { TAG_COLORS } from '@components/tags/colors';
import { sample, uid, useOnClickOutside, useOnEscape } from '@components/utils';
import { useToaster } from '@stores/toaster';

import * as toasts from '../../toasts';

type Props = {
  tagGroupId?: number;
  tagGroupColor?: string;
  studyId?: number;
  tags?: Tag[];
  addTagToTags: (tag: Tag) => void;
  className?: string;
};

export const AddNewTagButton: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  tagGroupId,
  tagGroupColor,
  studyId,
  addTagToTags,
  tags
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const showToast = useToaster();

  const randomTagColor = sample(Object.keys(TAG_COLORS));

  const [value, setValue] = useState('');
  const [isAddingTag, setIsAddingTag] = useState(false);

  const [createTag, { isError }] = api.useCreateTagMutation();

  const handleClick = () => {
    setIsAddingTag(true);
  };

  const handleSubmit = (keepAdding = false) => {
    const trimmedValue = (value ?? '').trim();

    if (!trimmedValue || trimmedValue === '') return;
    if (nameCollision()) return;

    const color = tagGroupColor || randomTagColor;
    const tag: Tag = {
      id: uid(),
      name: trimmedValue,
      color,
      taggings: [],
      tag_group_id: tagGroupId === undefined ? null : tagGroupId,
      project_id: studyId || null
    };

    createTag(tag);
    setValue('');
    if (!keepAdding) {
      setIsAddingTag(false);
    }
  };

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedCreateTag());
    }
  }, [isError]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(true);
    }

    if (e.key === 'Escape') {
      setIsAddingTag(false);
    }
  };

  useOnClickOutside(ref, () => {
    setIsAddingTag(false);
  });

  useOnEscape(() => {
    setIsAddingTag(false);
  }, [isAddingTag]);

  const nameCollision = () => (tags || []).some((tag) => tag.name.toLowerCase() === value.trim().toLowerCase());

  return (
    <div ref={ref}>
      {isAddingTag && (
        <div className='rounded border border-gray-300 bg-white'>
          <div className='inline-flex space-x-4'>
            <input
              name='name'
              value={value}
              autoFocus
              className='focus:outline-none relative block inline-flex w-64 items-center justify-center space-x-2 rounded border-0 bg-white px-2 py-1 text-sm font-medium leading-5 text-gray-700 active:text-gray-800 disabled:opacity-50'
              placeholder='Add new tag...'
              onKeyDown={handleKeyDown}
              onChange={handleChange}
            />
            <div className='flex items-center pr-3'>
              {value === '' || nameCollision() ? (
                <Button className='my-2 text-sm' small inactive>
                  Create
                </Button>
              ) : (
                <Button
                  className='my-2 text-sm'
                  trackEvent='created_tag'
                  trackProps={{ page: 'tags_manager' }}
                  onClick={() => handleSubmit()}
                  primary
                  small
                >
                  Create
                </Button>
              )}
            </div>
          </div>

          {value && (
            <div className='mx-2 flex justify-between border-t-2 py-2'>
              <Text h='400' color='gray-500'>
                Enter to save
              </Text>
              {nameCollision() && (
                <Text h='400' color='red-600'>
                  This tag already exists
                </Text>
              )}
            </div>
          )}
        </div>
      )}

      <Button
        rounded={false}
        icon='plus'
        fullWidth
        onClick={handleClick}
        className={cn(['rounded text-gray-700', className])}
      >
        Add new tag
      </Button>
    </div>
  );
};
