import React, { CSSProperties, forwardRef, HTMLAttributes, useMemo } from 'react';

import cn from 'classnames';

import { useVideoSeekBarContext } from '../hooks/useVideoSeekBarContext';

interface Props extends HTMLAttributes<HTMLDivElement> {
  chapterStartTime: number;
  chapterEndTime: number;
  time: number;
  kind: 'hover' | 'play' | 'buffer';
}

export const Progress = forwardRef<HTMLDivElement, Props>(
  ({ children, chapterEndTime, chapterStartTime, kind, time, ...rest }, ref) => {
    const {
      state: { isHovering }
    } = useVideoSeekBarContext();

    const scale = useMemo(() => {
      if (chapterEndTime <= time) return 1;

      const isActiveTime = time >= chapterStartTime && time <= chapterEndTime;
      const timeDiff = chapterEndTime - chapterStartTime;
      const timeDiffWithCurrent = time - chapterStartTime;

      return isActiveTime ? timeDiffWithCurrent / timeDiff : 0;
    }, [chapterEndTime, chapterStartTime, time]);

    const style: CSSProperties = {
      transform: `scaleX(${scale})`,
      transformOrigin: '0 0'
    };

    return (
      <div
        ref={ref}
        data-testid='video-seek-bar-progress'
        className={cn('absolute left-0 h-full w-full transition-opacity', {
          'bg-indigo-600': kind === 'play',
          'bg-white bg-opacity-30': kind === 'buffer',
          'bg-white bg-opacity-40': kind === 'hover',
          'opacity-0': !isHovering && kind === 'hover'
        })}
        style={style}
        {...rest}
      >
        {children}
      </div>
    );
  }
);
