import * as React from 'react';
import { useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import Tippy from '@tippyjs/react';

import { CreateTeamModal } from '@components/AccountTeamsApp/modals/CreateTeamModal';
import { Text } from '@components/common';
import { PlusSVG, SearchTeamsSVG } from '@components/svgs';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { usePermission } from '@hooks/usePermission';
import { useTeams } from '@hooks/useTeams';
import { useUser } from '@hooks/useUser';

import { TeamNav } from './TeamNav';

export const Teams: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser();
  const { teams } = useTeams();
  const { pathname } = useLocation();
  const canManage = usePermission('manageTeams')();

  const [modalOpen, setModalOpen] = useState(false);
  const [openNavsOrNull, setOpenNavs] = useLocalStorage<number[]>('sidebar-open-teams');
  const openNavs = openNavsOrNull || [];

  if (!canManage && teams?.length === 0) {
    return null;
  }

  const teamsToShow = teams?.filter((team) => team.users.some(({ id }) => id === user.id));

  return (
    <div className='my-4 border-b border-t border-gray-500 pt-4'>
      <div className='mb-1 flex items-center px-4 py-1'>
        <Text className='flex-1' color='white' h='200' bold>
          Teams
        </Text>
        <Tippy content='Manage teams'>
          <a title='Manage teams' href='/teams'>
            <SearchTeamsSVG />
          </a>
        </Tippy>
        {canManage && (
          <Tippy content='Create Team'>
            <button
              className='ml-2 text-white'
              name='Create Team'
              aria-label='Create Team'
              onClick={() => setModalOpen(true)}
            >
              <PlusSVG />
            </button>
          </Tippy>
        )}
      </div>
      <div className='max-h-60 overflow-y-auto pb-4'>
        {teamsToShow?.map((team) => (
          <TeamNav
            key={team.id}
            isOpen={openNavs.includes(team.id)}
            onOpen={() => setOpenNavs([...openNavs, team.id])}
            onClose={() => setOpenNavs(openNavs.filter((id) => id !== team.id))}
            team={team}
            pathname={pathname}
          />
        ))}
      </div>
      <CreateTeamModal trackKey='sidebar' open={modalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
};
