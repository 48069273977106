import { isSameDay } from 'date-fns';

type BookingField =
  | 'start_time'
  | 'duration'
  | 'moderators'
  | 'observers'
  | 'candidate_location'
  | 'team_location'
  | 'live_stream_enabled';

type FieldConfig = {
  path: string | string[];
  type: 'candidate' | 'team';
};

export const FIELD_CONFIGS: Record<BookingField, FieldConfig> = {
  start_time: { path: ['candidate_event.time.start_time'], type: 'candidate' },
  duration: { path: ['candidate_event.time.duration_in_minutes'], type: 'candidate' },
  moderators: { path: ['candidate_event.moderators'], type: 'candidate' },
  candidate_location: {
    path: ['candidate_event.video_url_type', 'candidate_event.conferencing.provider'],
    type: 'candidate'
  },
  observers: { path: ['team_event.observers'], type: 'team' },
  team_location: { path: ['team_event.video_url_type', 'team_event.conferencing.provider'], type: 'team' },
  live_stream_enabled: { path: ['team_event.live_stream_enabled'], type: 'team' }
} as const;

const tryPath = (obj: any, path: string): any => {
  return path.split('.').reduce((acc, key) => (acc ? acc[key] : undefined), obj);
};

const getNestedValue = <T>(obj: any, paths: string | string[]): T | undefined => {
  const pathArray = Array.isArray(paths) ? paths : [paths];

  for (const path of pathArray) {
    const value = tryPath(obj, path);
    if (value !== undefined) {
      return value as T;
    }
  }

  return undefined;
};

export const hasChangeFor = (
  field: BookingField,
  original: CalendarBookingDetail,
  changes: DeepPartial<CalendarBookingDetail>
): boolean => {
  const config = FIELD_CONFIGS[field];
  const originalValue = getNestedValue(original, config.path);
  const changedValue = getNestedValue(changes, config.path);

  if (field === 'live_stream_enabled') {
    return changedValue !== undefined;
  }

  if (!originalValue || !changedValue) {
    return false;
  }

  return true;
};

export const getValueFor = <T>(
  field: BookingField,
  bookingDetail: CalendarBookingDetail | DeepPartial<CalendarBookingDetail>,
  defaultValue: T
): T => {
  const config = FIELD_CONFIGS[field];
  const value = getNestedValue(bookingDetail, config.path);

  if (value === undefined || value === null) {
    return defaultValue;
  }

  return value as T;
};

export const hasModeratorChanges = (changes: DeepPartial<CalendarBookingDetail>): boolean => {
  return (
    getNestedValue(changes, FIELD_CONFIGS.moderators.path) !== undefined ||
    getNestedValue(changes, FIELD_CONFIGS.candidate_location.path) !== undefined ||
    getNestedValue(changes, ['candidate_event.video_url_type']) !== undefined
  );
};

export const hasObserverChanges = (changes: DeepPartial<CalendarBookingDetail>): boolean => {
  return (
    getNestedValue(changes, FIELD_CONFIGS.observers.path) !== undefined ||
    getNestedValue(changes, FIELD_CONFIGS.team_location.path) !== undefined ||
    getNestedValue(changes, ['team_event.video_url_type']) !== undefined ||
    getNestedValue(changes, FIELD_CONFIGS.live_stream_enabled.path) !== undefined
  );
};

export const hasSettingsChanges = (changes: DeepPartial<CalendarBookingDetail>): boolean => {
  return (
    getNestedValue(changes, FIELD_CONFIGS.start_time.path) !== undefined ||
    getNestedValue(changes, FIELD_CONFIGS.duration.path) !== undefined
  );
};

export type EmailChange = {
  type: 'new' | 'updated' | 'cancelled';
  guest: CalendarEventGuest;
  eventType: 'candidate' | 'team';
};

export const hasEmailChanges = (
  original: CalendarBookingDetail,
  changes: DeepPartial<CalendarBookingDetail>
): boolean => {
  const hasModeratorGuestChanges = getNestedValue(changes, FIELD_CONFIGS.moderators.path) !== undefined;
  const hasObserverGuestChanges = getNestedValue(changes, FIELD_CONFIGS.observers.path) !== undefined;
  const hasTimeChanges = getNestedValue(changes, FIELD_CONFIGS.start_time.path) !== undefined;
  const hasDurationChanges = getNestedValue(changes, FIELD_CONFIGS.duration.path) !== undefined;

  if (!hasModeratorGuestChanges && !hasObserverGuestChanges && !hasTimeChanges && !hasDurationChanges) {
    return false;
  }

  return getEmailChanges(original, changes).length > 0;
};

export const getEmailChanges = (
  original: CalendarBookingDetail,
  changes: DeepPartial<CalendarBookingDetail>
): EmailChange[] => {
  const result: EmailChange[] = [];
  const getGuests = (event?: any, isChangedEvent: boolean = false) => [
    ...(event?.moderators || []),
    ...(event?.observers || []),
    ...(isChangedEvent && event?.additional_guests !== undefined ? event.additional_guests : [])
  ];
  const findGuest = (guests: CalendarEventGuest[], email: string) => guests.find((guest) => guest.email === email);

  const handleEventChanges = (
    originalGuests: CalendarEventGuest[],
    changedGuests: CalendarEventGuest[],
    eventType: 'candidate' | 'team'
  ) => {
    const originalEvent = eventType === 'candidate' ? original.candidate_event : original.team_event;
    const changedEvent = eventType === 'candidate' ? changes.candidate_event : changes.team_event;
    const originalAdditionalGuests = (originalEvent?.additional_guests || []) as CalendarEventGuest[];
    const hasAdditionalGuestChanges = changedEvent?.additional_guests !== undefined;
    const changedAdditionalGuests = hasAdditionalGuestChanges
      ? ((changedEvent.additional_guests || []) as CalendarEventGuest[])
      : originalAdditionalGuests;

    const hasGuestChanges =
      originalGuests.length !== changedGuests.length ||
      originalGuests.some((guest) => !findGuest(changedGuests, guest.email)) ||
      changedGuests.some((guest) => !findGuest(originalGuests, guest.email)) ||
      (hasAdditionalGuestChanges &&
        (originalAdditionalGuests.length !== changedAdditionalGuests.length ||
          originalAdditionalGuests.some((guest) => !findGuest(changedAdditionalGuests, guest.email)) ||
          changedAdditionalGuests.some((guest) => !findGuest(originalAdditionalGuests, guest.email))));

    if (!hasGuestChanges) return;

    changedGuests.forEach((guest) => {
      const originalGuest = findGuest(originalGuests, guest.email);
      if (!originalGuest && !result.some((change) => change.guest.email === guest.email)) {
        result.push({ type: 'new', guest, eventType });
      }
    });

    originalGuests.forEach((guest) => {
      const isInChangedGuests = findGuest(changedGuests, guest.email);
      const isAlreadyInResult = result.some((change) => change.guest.email === guest.email);
      if (!isInChangedGuests && !isAlreadyInResult) {
        result.push({ type: 'cancelled', guest, eventType });
      }
    });

    if (hasAdditionalGuestChanges) {
      changedAdditionalGuests.forEach((guest) => {
        const originalGuest = findGuest(originalAdditionalGuests, guest.email);
        if (!originalGuest && !result.some((change) => change.guest.email === guest.email)) {
          result.push({ type: 'new', guest, eventType });
        }
      });

      originalAdditionalGuests.forEach((guest) => {
        const isInChangedGuests = findGuest(changedAdditionalGuests, guest.email);
        const isAlreadyInResult = result.some((change) => change.guest.email === guest.email);
        if (!isInChangedGuests && !isAlreadyInResult) {
          result.push({ type: 'cancelled', guest, eventType });
        }
      });
    }
  };

  handleEventChanges(getGuests(original.candidate_event), getGuests(changes.candidate_event, true), 'candidate');
  handleEventChanges(getGuests(original.team_event), getGuests(changes.team_event, true), 'team');

  if (hasChangeFor('start_time', original, changes) || hasChangeFor('duration', original, changes)) {
    const handleTimeChanges = (guests: CalendarEventGuest[], eventType: 'candidate' | 'team') => {
      guests.forEach((guest) => {
        if (!result.some((change) => change.guest.email === guest.email)) {
          result.push({ type: 'updated', guest, eventType });
        }
      });
    };

    handleTimeChanges(getGuests(original.candidate_event), 'candidate');
    handleTimeChanges(getGuests(original.team_event), 'team');
  }

  return result;
};

export const getUrlValue = (event?: DeepPartial<CalendarBookingDetailEvent> | null) => {
  const videoUrlType = event?.video_url_type;
  return videoUrlType === 'manual'
    ? event?.conferencing?.url
    : videoUrlType
      ? 'a new link will be generated'
      : undefined;
};

export const hasDateChange = (
  original: CalendarBookingDetail,
  changes: DeepPartial<CalendarBookingDetail>
): boolean => {
  const originalDate = getValueFor<Date>('start_time', original, new Date());
  const changedDate = getValueFor<Date>('start_time', changes, new Date());

  return !isSameDay(originalDate, changedDate);
};

export const hasTimeChange = (
  original: CalendarBookingDetail,
  changes: DeepPartial<CalendarBookingDetail>
): boolean => {
  const originalDate = getValueFor<Date>('start_time', original, new Date());
  const changedDate = getValueFor<Date>('start_time', changes, new Date());

  return originalDate.getHours() !== changedDate.getHours() || originalDate.getMinutes() !== changedDate.getMinutes();
};
