import * as React from 'react';
import { useState } from 'react';

import { ShareAccessDropdown } from './components/ShareAccessDropdown';

type Props = {
  readOnly?: boolean;
  initialValue: ShareAccess;
  onUpdate: (access: ShareAccess) => void;
};
export const AssetShareAccessDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  initialValue,
  onUpdate,
  readOnly
}) => {
  const [value, setValue] = useState<ShareAccess>(initialValue);

  const shareUrl = window.location.href.replace('/share', '');

  const handleChange = (access: ShareAccess) => {
    setValue(access);
    onUpdate(access);
  };

  return <ShareAccessDropdown value={value} onChange={handleChange} linkUrl={shareUrl} readOnly={readOnly} />;
};
