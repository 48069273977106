import React, { SVGProps } from 'react';

export const AskAiGreySVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_707_41499)'>
      <path
        d='M14.5 7V12.5C14.5 12.7652 14.3946 13.0196 14.2071 13.2071C14.0196 13.3946 13.7652 13.5 13.5 13.5H5.5L1.5 14.5L2.5 11.5V3.5C2.5 3.23478 2.60536 2.98043 2.79289 2.79289C2.98043 2.60536 3.23478 2.5 3.5 2.5H8'
        stroke='#374151'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.50916 4.93834C8.16361 4.87721 8.16361 4.37279 8.50916 4.31166C9.76104 4.0902 10.7567 3.12059 11.0285 1.85838L11.0493 1.76163C11.1241 1.41435 11.6103 1.41219 11.6881 1.75879L11.7134 1.87154C11.9951 3.1278 12.9911 4.08921 14.2395 4.31006C14.5868 4.3715 14.5868 4.8785 14.2395 4.93994C12.9911 5.16079 11.9951 6.1222 11.7134 7.37846L11.6881 7.49121C11.6103 7.83781 11.1241 7.83565 11.0493 7.48837L11.0285 7.39162C10.7567 6.12941 9.76104 5.1598 8.50916 4.93834Z'
        stroke='#374151'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_707_41499'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
