import * as React from 'react';
import { useState } from 'react';

import cn from 'classnames';
import { useCombobox } from 'downshift';
import pluralize from 'pluralize';

import { Button, Input, Loading, Text } from '@components/common';
import { HasTitle } from '@components/shared/BulkAddDropdown';
import { useSearch } from '@hooks/useSearch';

type Props<T> = {
  handleAdd: (target: T) => void;
  closePopper?: () => void;
  onCreate?: (v: string) => void;
  artifactType: 'insight' | 'reel';
  data?: T[];
  disabled?: boolean;
  selectedCount: number;
  isLoading?: boolean;
  onClickCreate?: () => void;
  hasCreateMode: boolean;
};

type Mode = 'select_existing' | 'create_new';

const ContentInner = <T extends HasTitle>(
  {
    data = [],
    handleAdd,
    closePopper,
    onCreate,
    artifactType,
    selectedCount,
    isLoading,
    onClickCreate,
    disabled,
    hasCreateMode
  }: Props<T>,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const [mode, setMode] = useState<Mode>('select_existing');

  const [search, setSearch] = useState('');

  const { results } = useSearch<T>(data, ['title'], search);

  const items =
    results.map((i: any) => ({
      label: i.title || `Untitled ${artifactType}`,
      value: i.id
    })) || [];

  const { highlightedIndex, selectedItem, getComboboxProps, getMenuProps, getInputProps, getItemProps, inputValue } =
    useCombobox({
      isOpen: true,
      items,
      itemToString: (item) => (item ? item.label : ''),
      onSelectedItemChange: ({ selectedItem }) => {
        if (selectedItem) {
          const artifact = data?.find((i) => i.id === selectedItem.value);

          if (artifact) {
            handleAdd(artifact);
          }
        }
      }
    });

  return (
    <>
      {mode === 'create_new' && (
        <div>
          <div className='p-4'>
            <Text h='400' mb='4'>
              Create new {artifactType}
            </Text>
            <Input placeholder='Add a name' className='h400 w-full' value={search} onChange={setSearch} />
          </div>

          <div className='border-b border-gray-200' />
          <div className='flex items-center p-4'>
            <div className='flex-1'>
              <Button text small onClick={() => setMode('select_existing')}>
                Back
              </Button>
            </div>
            <div>
              <Button
                disabled={!search}
                primary
                small
                onClick={() => {
                  onCreate?.(search);
                  closePopper?.();
                }}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      )}

      {mode === 'select_existing' && (
        <div {...getComboboxProps({ disabled }, { suppressRefError: true })}>
          <div className='p-4'>
            <Text h='400' mb='4'>
              Selected: {pluralize(artifactType === 'reel' ? 'highlight' : 'artifact', selectedCount, true)}
            </Text>
            <Input
              {...getInputProps({ disabled, ref }, { suppressRefError: true })}
              icon='search'
              className='h400 w-full'
              value={search}
              onChange={setSearch}
            />
          </div>
          <div className='border-b border-gray-200' />
          <div className='h-60 overflow-y-auto p-4'>
            {isLoading ? (
              <div className='relative h-52'>
                <Loading absolute bg='white' />
              </div>
            ) : (
              <ul {...getMenuProps({ disabled }, { suppressRefError: true })}>
                {items.map((item, index) => (
                  <li
                    key={`${item.label}${index}`}
                    {...getItemProps({ disabled, item, index })}
                    className={cn('h400 cursor-pointer truncate p-1', {
                      'bg-gray-50': highlightedIndex === index,
                      'text-indigo-600': selectedItem === item
                    })}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className='border-b border-gray-200' />
          <div className='flex items-center p-4'>
            <div className='flex-1'>
              <Button text small onClick={closePopper}>
                Cancel
              </Button>
            </div>
            <div>
              <Button
                data-testid='create_new'
                primary
                small
                onClick={hasCreateMode ? () => setMode('create_new') : onClickCreate}
              >
                Create new {artifactType}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const BulkAddDropdownContent = React.forwardRef(ContentInner) as <T>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof ContentInner>;
