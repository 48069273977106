import * as React from 'react';

import cn from 'classnames';
import Tippy from '@tippyjs/react';

import { humanize } from '@components/utils';

const STATUS_COLORS = {
  draft: 'bg-yellow-400',
  pending: 'bg-orange-400',
  active: 'bg-green-600',
  closed: 'bg-gray-400',
  paused: 'bg-gray-400',
  archived: 'bg-gray-400'
};

const TIPS: Record<Study['state'], string> = {
  draft: "Study setup is internal only, and participants can't be invited yet.",
  pending: 'Awaiting approval or funding before inviting participants.',
  active: 'Study is live and accepting participations.',
  paused: 'Candidate access to this study is paused.',
  closed: 'Access has been closed to participants.',
  cancelled: '',
  archived: 'Study has been removed from view.'
};

interface Props {
  status: string;
  noTip?: boolean;
  className?: string;
}

export const StudyStatus: React.FC<React.PropsWithChildren<Props>> = ({ noTip, status, className }) => (
  <Tippy disabled={noTip} content={TIPS[status]}>
    <div className={cn('flex items-center', className)}>
      <span className={`h-3 w-3 rounded-full ${STATUS_COLORS[status]} mr-2 flex-shrink-0`} />
      <span className='xx-study-status truncate'>{humanize(status)}</span>
    </div>
  </Tippy>
);
