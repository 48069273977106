import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import { Location } from 'history';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Button, Loading, Text } from '@components/common';
import { PageTitle } from '@components/common/helmets';
import { WindowFooter, WindowLayout, WindowLayoutBody, WindowStickyContainer } from '@components/layouts/WindowLayout';
import { Stepper } from '@components/shared/Stepper';
import { Header } from '@components/StudiesApp/components/StudyPublished/components/Header';
import { STUDY_MUTATIONS } from '@components/StudiesApp/constants';
import { StudyCheckoutInline } from '@components/StudyCheckout';
import { useStudyBookability } from '@components/StudyMessages/hooks/useStudyBookability';
import { ChevronLeftSVG, ChevronRightSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { StudyFunder, useStudyFunder } from '@components/Wallet';
import { useBrand } from '@hooks/useBrand';
import { usePendingRequests } from '@hooks/usePendingRequests';
import { usePermission } from '@hooks/usePermission';

import { PublishStudyButtonConditional } from './components/PublishStudyButtonConditional';
import { useStudyPlan } from './hooks/useStudyPlan';
import { useStudyPublishValidator } from './hooks/useStudyPublishValidator';
import { buildSteps, getStudyDraftSteps } from './utils/buildSteps';
import { Calendar, Plan, Publish, Recruiting, Screener, Survey, Task } from './pages';

interface Props {
  study: Study;
  refetch: () => void;
}

interface Context {
  funder: StudyFunder;
  prefundLimit: number;
  setPrefundLimit: (v: number) => void;
}

export const DraftStudyContext = React.createContext<Context>({} as Context);

export const useDraftStudyContext = () => {
  const context = useContext<Context>(DraftStudyContext);

  if (context === undefined) throw new Error();

  return context;
};

export const StudyDraft: React.FC<React.PropsWithChildren<Props>> = ({ study, refetch }) => {
  useBrand();
  const [saving, setSaving] = useState(false);
  const [prefundLimit, setPrefundLimit] = useState(study.participant_limit || 0);
  const funder = useStudyFunder({ study });
  const [updateStudy] = api.useUpdateStudyMutation();

  const { pre_screener, survey_screener, landing_page } = study;

  const showSavingIndicator = usePendingRequests({ mutations: STUDY_MUTATIONS });

  const location = useLocation();
  const validator = useStudyPublishValidator({ study });

  const canEdit = usePermission<Study>('updateStudy')(study);

  function trackPage(location: Location): void {
    const tab = location.pathname.split('/').pop();
    track('viewed_edit_study', { status: study.state, tab });
  }

  // TODO: this will need to be fixed asap.
  useEffect(() => {
    trackPage(location);
  }, [location]);

  useEffect(() => {
    setPrefundLimit(study.maximum_slots);
  }, [study.maximum_slots]);

  useEffect(() => {
    const app = document.getElementById('app-main');
    if (app) app.scrollTop = 0;
  }, [location.pathname]);

  const [planOptions, setPlanOptions] = useStudyPlan(study, updateStudy);

  const { bookability = {}, isLoading: isLoadingBookability } = useStudyBookability({ studyId: study.id });

  const draftSteps = getStudyDraftSteps(study, planOptions);

  const { steps, currentStep, nextHref } = buildSteps(
    `/studies/${study.id}`,
    draftSteps,
    bookability,
    location.pathname
  );

  const currentStepIdx = currentStep ? steps.indexOf(currentStep) : 0;
  const backHref = currentStepIdx > 0 && steps[currentStepIdx - 1].href;

  const pageProps: PageProps = {
    study,
    pre_screener,
    survey_screener,
    landing_page,
    saving,
    setSaving,
    onSave: updateStudy
  };

  return (
    <WindowLayout>
      <PageTitle>{study.title}</PageTitle>
      {saving && <Loading />}
      <WindowStickyContainer>
        <Header
          study={study}
          updateStudy={updateStudy}
          setLoading={setSaving}
          isUpdating={showSavingIndicator}
          canEdit={canEdit}
        />
        <Stepper steps={steps} component={(props) => <Link to={props.href} {...props} />} />
      </WindowStickyContainer>
      <DraftStudyContext.Provider value={{ funder, prefundLimit, setPrefundLimit }}>
        <WindowLayoutBody fixedHeader>
          <div className='mx-auto w-full pb-28'>
            <Routes>
              <Route
                path='plan'
                element={<Plan options={planOptions} setOptions={setPlanOptions} {...pageProps} study={study} />}
              />
              <Route path='task' element={<Task {...pageProps} />} />
              <Route path='test' element={<Survey {...pageProps} />} />
              <Route
                path='calendar'
                element={
                  <div className='pt-gutter'>
                    <Calendar refetch={refetch} {...pageProps} />
                  </div>
                }
              />
              <Route path='survey' element={<Survey {...pageProps} />} />
              <Route
                path='screener'
                element={
                  <Screener
                    includeScreener={study.has_screener}
                    setIncludeScreener={() =>
                      updateStudy({
                        id: study.id,
                        has_screener: !study.has_screener
                      })
                    }
                    {...pageProps}
                  />
                }
              />
              <Route path='recruiting' element={<Recruiting {...pageProps} />} />
              <Route path='review' element={<Publish validator={validator} {...pageProps} />} />
              <Route path='*' element={<Navigate replace to={`${steps[0].href}`} />} />
            </Routes>
            <StudyCheckoutInline study={study} />
          </div>
        </WindowLayoutBody>

        <WindowFooter>
          <div className='flex w-full items-center justify-center space-x-2'>
            {study.state === 'draft' && (
              <>
                {backHref && (
                  <Button href={backHref} spa>
                    <ChevronLeftSVG className='mr-2' /> Back
                  </Button>
                )}
                <Text h='400' color='gray-500' className='px-6'>
                  Step {currentStepIdx + 1} of {steps.length}
                </Text>
                {nextHref && (
                  <Button href={nextHref} spa primary>
                    Next <ChevronRightSVG className='ml-2' />
                  </Button>
                )}
                {!isLoadingBookability && draftSteps[currentStepIdx] === 'review' && (
                  <PublishStudyButtonConditional
                    study={study}
                    saving={saving}
                    setSaving={setSaving}
                    hasErrors={!validator.canPublish}
                  />
                )}
              </>
            )}
          </div>
        </WindowFooter>
      </DraftStudyContext.Provider>
    </WindowLayout>
  );
};
