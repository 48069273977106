const AVERAGE_WPM = 250;

export const getReadingTime = (wordCount: number): string => {
  const mins = wordCount / AVERAGE_WPM;

  if (mins < 1) {
    const secs = Math.floor(mins * 60);
    return `${secs} sec`;
  }
  return `${Math.floor(mins)} min`;
};

export const sortGuides = (initialGuides: Guide[] | null, sortBy: string) => {
  if (!initialGuides?.length) {
    return [];
  }

  const guides = [...initialGuides];

  if (['updated_at', 'created_at'].includes(sortBy)) {
    guides.sort((a, b) => +b[sortBy] - +a[sortBy]);
  } else {
    guides.sort((a, b) => a[sortBy].toString().localeCompare(b[sortBy].toString()));
  }

  return guides;
};
