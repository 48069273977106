import React from 'react';

import qs from 'qs';

import { parseValue } from '@components/shared/TableFilters/utils/encode';
import { PopperDropdownAnchor } from 'components/common';

interface Props {
  tag: Tag;
}

export const ViewOccurrencesDropdownLink: React.FC<React.PropsWithChildren<Props>> = ({ tag }) => {
  const query = qs.stringify(
    {
      filters: [`tag includes_any ${parseValue(tag.id)}`]
    },
    { arrayFormat: 'brackets' }
  );

  return (
    <PopperDropdownAnchor
      onClick={(e) => e.stopPropagation()}
      target={tag.project_id ? undefined : '_blank'}
      href={`${tag.project_id ? 'repository' : '/repository'}?${query}`}
    >
      View occurrences…
    </PopperDropdownAnchor>
  );
};
