import * as React from 'react';

import classNames from 'classnames';
import pluralize from 'pluralize';

import { Button, Text } from '@components/common';
import { PlanSVGs } from '@components/shared/RestrictedAction/constants';

import { TRIAL_LENGTH } from '../constants';

// TODO: Move to components/svgs
import { PlanFeatures } from './PlanFeatures';
import { StartTrialLink } from './StartTrialLink';

// FIXE:sub the prefix  so that we use any version.
interface Props {
  isSignedIn: boolean;
  isCurrent: boolean;
  canBuy: boolean;
  canTrial: boolean;
  plan: Plan;
  previousTierName: string | null;
  recommended?: boolean;
}

const getViwerLimitText = (viewersLimit) => {
  if (!viewersLimit) {
    return 'Unlimited viewers & seats';
  }
  const viewers = `${viewersLimit ? `${pluralize('viewer', viewersLimit, true)}, ` : 'Unlimited viewers, '}`;
  return `${viewers}`;
};

export const PricingTierCard: React.FC<React.PropsWithChildren<Props>> = ({
  previousTierName,
  canBuy,
  canTrial,
  isSignedIn,
  plan,
  isCurrent,
  recommended
}) => {
  const { name, description: subtitle, public_key: planKey, id: planId, seat_cost } = plan;
  const { free_seats: minSeats, observer_seats: viewersLimit } = plan.limits;
  const isCustomPrice = !plan.self_serve;

  const SVG = PlanSVGs[planKey];

  return (
    <div
      key={[planId, name].join('-')}
      className={classNames(
        'min-w-60 relative mr-3 mt-4 flex-1 flex-shrink-0 rounded-lg shadow-sm',
        recommended || isCurrent ? 'border-2 border-indigo-600' : 'border border-gray-200'
      )}
    >
      <div
        className={classNames(
          'rounded-t-lg p-4',
          recommended || isCurrent ? 'border-b-2 border-indigo-600 bg-indigo-50' : 'border-b border-gray-200 bg-gray-50'
        )}
      >
        {SVG && <SVG className='mb-3 h-12 w-12' />}
        <h2 className='text-2xl font-bold leading-8'>{name}</h2>
        <p className='line-clamp-2 mb-3 h-8 text-xs leading-4 text-gray-500'>{subtitle}</p>
        <div className='flex items-end'>
          {isCustomPrice && <p className='mr-2 text-4xl font-bold leading-9'>Custom</p>}
          {!isCustomPrice &&
            (seat_cost ? (
              <>
                <p className='mr-2 text-4xl font-bold leading-9'>${seat_cost}</p>
                <p className='text-xs'>per seat / {plan.interval}</p>
              </>
            ) : (
              <p className='text-4xl font-bold leading-9'>Free</p>
            ))}
        </div>
        <div className='my-4 flex h-8 flex-col'>
          <div className='mb-1'>
            {!!minSeats && plan.public_key !== 'free' && (
              <p className='text-xs font-bold leading-4'>{pluralize('seat', minSeats, true)} minimum</p>
            )}
          </div>
          <p className='text-xs leading-4 text-gray-500'>{getViwerLimitText(viewersLimit)}</p>
        </div>
        {isSignedIn && !isCurrent && canBuy && !planId && <div className='h-10' />}
        {isSignedIn && !isCurrent && canBuy && (planId || isCustomPrice) && (
          <Button
            className='whitespace-nowrap text-base font-bold'
            fullWidth
            trackEvent={isCustomPrice ? 'clicked_schedule_demo' : 'clicked_buy_plan'}
            trackProps={{ plan_key: planKey, position: 'plan' }}
            primary={recommended}
            href={isCustomPrice ? '/enterprise' : `/subscription/new?plan=${planId}`}
          >
            {isCustomPrice && <>Schedule demo</>}
            {!isCustomPrice && <>Buy {name}</>}
          </Button>
        )}
        {isSignedIn && (isCurrent || !canBuy) && <div className='h-10' />}
        {!isSignedIn && isCustomPrice && (
          <Button
            primary={recommended}
            trackEvent='clicked_schedule_demo'
            trackProps={{ plan_key: planKey, position: 'plan' }}
            className='whitespace-nowrap text-base font-bold'
            href='/enterprise'
            fullWidth
          >
            Schedule demo
          </Button>
        )}
        {!isSignedIn && !isCustomPrice && (
          <Button
            primary={recommended}
            className='whitespace-nowrap text-base font-bold'
            trackEvent='clicked_get_started'
            trackProps={{ plan_key: planKey, position: 'plan' }}
            href='/users/sign_up'
            fullWidth
          >
            Start for free
          </Button>
        )}
        {isSignedIn &&
          canTrial &&
          (!isCustomPrice && plan.seat_cost > 0 ? <StartTrialLink plan={plan} /> : <div className='h-10' />)}
        {/* {!isSignedIn && !isCustomPrice && (
          <Text h='200' color='gray-500' mt='2'>
            No credit card required
          </Text>
        )} */}
      </div>
      <PlanFeatures plan={plan} previousTierName={previousTierName} />
      {(recommended || isCurrent) && (
        <div className='absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 transform rounded border border-indigo-600 bg-white px-2 py-1 text-xs font-bold'>
          {isCurrent ? 'CURRENT PLAN' : 'RECOMMENDED'}
        </div>
      )}
    </div>
  );
};
