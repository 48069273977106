import React, { FC, ReactElement, useEffect } from 'react';

import * as Sentry from '@sentry/react';

interface FallbackProps {
  error: unknown;
  componentStack: string | null;
  resetError: () => void;
}

export type FallbackRender = (props: FallbackProps) => ReactElement;

interface Props {
  whoami: Whoami;
  renderComponent: FallbackRender;
}

export const SentryProvider: FC<React.PropsWithChildren<Props>> = ({ whoami, renderComponent, children }) => {
  useEffect(() => {
    Sentry.setUser({
      id: String(whoami.user.id),
      name: whoami.user.name,
      account: whoami?.account?.name
    });
  }, []);

  return <Sentry.ErrorBoundary fallback={renderComponent}>{children}</Sentry.ErrorBoundary>;
};
