import React, { useEffect, useRef, useState } from 'react';

import { EditorContent, useEditor } from '@tiptap/react';

import { Transcript } from '@components/shared/Tiptap/extensions';
import { buildExtensions } from '@components/shared/Tiptap/helpers/extensions';
import { DocumentsChannel } from '@hooks/useWebsocket';
import consumer from 'helpers/consumer';

const DocumentChannel = (id, opts = {}) =>
  consumer.subscriptions.create(
    {
      channel: DocumentsChannel,
      document_id: id
    },
    opts
  );

export const LiveDocument: React.FC<React.PropsWithChildren<{ documentId: number }>> = ({ documentId }) => {
  const [doc, setDoc] = useState(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const editor = useEditor({
    extensions: buildExtensions({}, [
      Transcript.configure({
        readonly: true
      })
    ]),
    editable: false
  });

  useEffect(() => {
    editor?.commands.setContent(doc);
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
    }
  }, [editor, doc]);

  useEffect(() => {
    DocumentChannel(documentId).received = (data) => {
      setDoc(data.message.doc);
    };
  }, []);

  return (
    <div className='mt-4 overflow-scroll' ref={wrapperRef}>
      <EditorContent editor={editor} />
    </div>
  );
};
