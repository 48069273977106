import * as React from 'react';

import { api } from '@api/reduxApi';

import { AssetShareAccessDropdown } from './AssetShareAccessDropdown';

type Props = {
  readOnly?: boolean;
  uuid: string;
  highlight: DocumentHighlight;
};
export const HighlightShareAccessDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  readOnly,
  uuid,
  highlight
}) => {
  const [updateHighlight] = api.useUpdateHighlightMutation();

  return (
    <AssetShareAccessDropdown
      readOnly={readOnly}
      initialValue={highlight.access}
      onUpdate={(access) => updateHighlight({ uuid, access })}
    />
  );
};
