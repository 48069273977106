import * as React from 'react';
import { useRef } from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { ButtonHoverOverlay } from '@components/shared/ButtonHoverOverlay';
import { Basic } from '@components/shared/Skeleton';
import { Tiptap, TiptapHook } from '@components/shared/Tiptap';

interface Props {
  title?: string;
  subtitle?: string;
  isLoading?: boolean;
  tiptap: TiptapHook;
  renderButtons?: () => React.ReactNode;
  renderCover?: () => React.ReactNode;
  withOverlay?: boolean;
  readOnly?: boolean;
  customizeTemplate?: () => void;
  saving?: boolean;
  showMenu?: boolean;
}

const ContentWrapper = ({ children, withOverlay, readOnly, customizeTemplate }) =>
  withOverlay ? (
    <ButtonHoverOverlay
      enabled={readOnly}
      buttonProps={{ children: 'Customize', icon: 'pencil', onClick: customizeTemplate }}
      className='z-10'
    >
      {children}
    </ButtonHoverOverlay>
  ) : (
    children
  );

export const TextEditorWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  withOverlay,
  renderButtons,
  title,
  subtitle,
  tiptap,
  isLoading,
  readOnly,
  customizeTemplate,
  renderCover,
  saving,
  showMenu = true
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className='px-page py-gutter'>
      <div className='mx-auto h-full w-full max-w-6xl'>
        {(title || renderButtons) && (
          <>
            <div className='flex items-center'>
              <Text h='600' bold className='flex-1'>
                {title}
              </Text>
              {renderButtons?.()}
            </div>
          </>
        )}
        {subtitle && (
          <Text h='400' className='mt-2'>
            {subtitle}
          </Text>
        )}
        <Tiptap {...tiptap.getTiptapProps()}>
          {showMenu && (
            <tiptap.Menu
              ref={ref}
              saving={saving}
              {...tiptap.getMenuProps({
                className: 'mt-4 px-3 sticky top-0 z-20 bg-white border border-gray-200 rounded-t-md overflow-hidden'
              })}
            />
          )}
          <div
            className={cn(
              'h-full rounded-md border border-gray-200 bg-white p-4 text-gray-700',
              showMenu && 'rounded-t-none border-t-0'
            )}
          >
            {renderCover?.()}
            <div className='min-h-96 mx-auto h-full w-full max-w-3xl'>
              <ContentWrapper readOnly={readOnly} customizeTemplate={customizeTemplate} withOverlay={withOverlay}>
                {isLoading ? (
                  <Basic className='h-full' width='100%' />
                ) : (
                  <tiptap.Content {...tiptap.getContentProps()} />
                )}
              </ContentWrapper>
            </div>
          </div>
        </Tiptap>
      </div>
    </div>
  );
};
