import * as React from 'react';

import cn from 'classnames';

import { Button } from '@components/common';
import { ImageSVG } from '@components/InsightsApp/svgs';
import { TrashSVG } from '@components/svgs';

interface Props {
  imageUrl?: string;
  onRemove: () => void;
  openAttachModal: () => void;
}

export const InsightCoverImage: React.FC<React.PropsWithChildren<Props>> = ({
  imageUrl,
  onRemove,
  openAttachModal
}) => (
  <div style={imageUrl ? { paddingBottom: '29.75%' } : {}} className='relative mb-4 h-32 w-full'>
    {imageUrl && <img alt='Cover' className='absolute h-full w-full rounded-md object-cover' src={imageUrl} />}
    <div
      className={cn(
        'tablet:space-y-0 tablet:flex-row tablet:items-end tablet:justify-end absolute flex h-full w-full flex-col items-center justify-end space-y-2 rounded-md p-2',
        { 'bg-gray-200': !imageUrl }
      )}
    >
      <div className='flex space-x-2'>
        {imageUrl ? (
          <Button className='whitespace-nowrap' medium onClick={openAttachModal}>
            <ImageSVG className='mr-2' />
            Change
          </Button>
        ) : (
          <Button className='whitespace-nowrap' medium onClick={openAttachModal}>
            <ImageSVG className='mr-2' />
            Add cover photo
          </Button>
        )}
        {imageUrl && (
          <Button medium onClick={onRemove}>
            <TrashSVG />
          </Button>
        )}
      </div>
    </div>
  </div>
);
