import React from 'react';

export const SegmentsZdsSvg: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg width='168' height='100' viewBox='0 0 168 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='167' height='99' rx='3.5' fill='white' stroke='#9FA6B2' />
    <path
      d='M153.875 8.875V14.125H159.125C159.125 12.7326 158.572 11.3973 157.587 10.4127C156.603 9.42812 155.267 8.875 153.875 8.875V8.875Z'
      stroke='#5850EC'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M152.125 10.625C151.087 10.625 150.072 10.9329 149.208 11.5098C148.345 12.0867 147.672 12.9066 147.275 13.8659C146.877 14.8252 146.773 15.8808 146.976 16.8992C147.178 17.9176 147.678 18.8531 148.413 19.5873C149.147 20.3215 150.082 20.8216 151.101 21.0241C152.119 21.2267 153.175 21.1227 154.134 20.7254C155.093 20.328 155.913 19.6551 156.49 18.7917C157.067 17.9284 157.375 16.9134 157.375 15.875H152.125V10.625Z'
      stroke='#5850EC'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <rect x='8' y='8' width='34' height='14' rx='2' fill='#E5E7EB' />
    <rect x='50' y='8' width='34' height='14' rx='2' fill='#E5E7EB' />
    <rect x='92.5' y='8.5' width='33' height='13' rx='1.5' fill='white' stroke='#9FA6B2' />
    <rect x='8' y='30' width='152' height='1' fill='#9FA6B2' />
    <rect x='8' y='39' width='152' height='14' rx='2' fill='#E5E7EB' />
    <rect x='8' y='57' width='152' height='14' rx='2' fill='#E5E7EB' />
    <rect x='8' y='75' width='152' height='14' rx='2' fill='#E5E7EB' />
  </svg>
);
