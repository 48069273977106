import * as React from 'react';
import { useContext } from 'react';

import classNames from 'classnames';

import { Avatar, Card, DropdownLink, ErrorMessage, Spinner, Text } from '@components/common';
import { CaretDownSVG, CheckmarkInCircleSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { useFeature } from '@hooks/useFeature';
import { usePopUp } from '@hooks/usePopUp';
import { useUser } from '@hooks/useUser';
import configStore from '@stores/config';

import { useUserCalendars } from '../../../hooks/useUserCalendars';
import { AuthButton } from '../../AuthButton';
import { EVENT_COLORS } from '../NylasEvent';

import { ModeratorCalendarPickerTitle } from './ModeratorCalendarPickerTitle';
import { VirtualCalendarModerator } from './VirtualCalendarModerator';

export type Props = {
  isOwner?: boolean;
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  calendars?: Calendar[] | null;
  user: TeamUser;
  calendarId?: number | null;
  onChangeCalendar?: (cal: Calendar) => void;
  onChangeCalendarId?: (calendar_id: number) => void;
  onClickRemove: () => void;
  onClickSwitchToCalendly?: () => void;
  type: 'observer' | 'moderator';
  requireCalendar: boolean;
  studyUserColors: Record<number, UserColor | undefined>;
  inlineErrors: BookabilityRule[];
};
export const Moderator: React.FC<React.PropsWithChildren<Props>> = ({
  isOwner,
  user,
  disabled,
  loading,
  calendars: initialCalendars,
  calendarId,
  onChangeCalendar,
  onChangeCalendarId,
  onClickRemove,
  onClickSwitchToCalendly,
  type,
  requireCalendar,
  studyUserColors,
  inlineErrors = []
}) => {
  const { id: currentUserId } = useUser();

  const { ref, togglePopUp, closePopUp, PopUp, open } = usePopUp();
  const { calendars, isLoading: isLoadingCalendars } = useUserCalendars({ userId: user.id });

  const handleClickRemove = () => {
    onClickRemove();
    closePopUp();
  };

  const handleClickCalendarId = (cal: Calendar) => {
    onChangeCalendar?.(cal);
    onChangeCalendarId?.(cal.id);
    closePopUp();
  };

  const calendar = React.useMemo(() => calendars?.find(({ id }) => id === calendarId), [calendars, calendarId]);
  const [color, grade] = user.is_phantom ? ['gray', 400] : studyUserColors?.[user.id] || EVENT_COLORS[0];
  const hasDropdown = !disabled && !loading && calendars;
  const isSelf = currentUserId === user.id;

  const isNotSelected = inlineErrors.includes('moderators_need_calendar_selected');
  const isDisconnected = inlineErrors.includes('moderators_need_calendar');

  const {
    state: {
      config: { disabledCalendarModes }
    }
  } = useContext(configStore);
  const hasCalendlyFeature = !disabledCalendarModes.includes('calendly');
  const hasVirtualCalendar = useFeature('virtual_calendar');

  if (hasVirtualCalendar) {
    return <VirtualCalendarModerator {...{ isOwner, user, disabled, onClickRemove, studyUserColors, type }} />;
  }

  return (
    <div
      key={user.id}
      ref={ref}
      data-testid='moderator'
      className={classNames('xx-moderator group relative -mx-6 flex cursor-pointer items-center px-6 py-3', {
        'hover:bg-gray-50': !disabled
      })}
      onClick={hasDropdown ? togglePopUp : undefined}
    >
      <div className={classNames('mr-4 flex-shrink-0', `border-${color}-${grade} rounded-full border-2`)}>
        <Avatar size='lg' user={user} />
      </div>
      <div className='flex-grow truncate'>
        <Text h='400' medium mb='0.5'>
          {user.name}
          {user.is_phantom && '*'}
        </Text>
        <div className='flex items-center space-x-1'>
          {calendar ? (
            <div className='flex items-center space-x-1'>
              <CheckmarkInCircleSVG className='h-4 w-4 text-green-600' />
              <Text h='400' color='gray-500' truncate>
                {calendar?.name}
              </Text>
            </div>
          ) : isLoadingCalendars ? (
            <div className='flex items-center space-x-1'>
              <Spinner className='h-4 w-4' />
              <Text h='400' color='gray-500'>
                Loading calendars…
              </Text>
            </div>
          ) : isNotSelected ? (
            <ErrorMessage>Calendar not selected</ErrorMessage>
          ) : isDisconnected ? (
            <ErrorMessage>Disconnected</ErrorMessage>
          ) : null}
        </div>
      </div>
      {hasDropdown && (
        <button className='hidden group-hover:block' name='more'>
          <CaretDownSVG />
        </button>
      )}
      {loading && <Spinner className='h-4 w-4' />}
      <PopUp open={open} zIndex={30} className='left-0 right-0 top-full px-6' whitespace='normal'>
        <Card className='max-h-48 overflow-y-scroll shadow-lg' noPadding>
          <ModeratorCalendarPickerTitle user={user} calendars={calendars} type={type} isOwner={isOwner} />

          {calendars.map((cal) => (
            <DropdownLink
              disabled={!cal.write_access || disabled}
              key={cal.id}
              onClick={() => handleClickCalendarId(cal)}
            >
              {cal.name}
            </DropdownLink>
          ))}
          {isDisconnected && isSelf && (
            <div className='mb-3 px-4'>
              <Text h='400' color='gray-500' mb='4'>
                Please re-connect your calendar to prevent scheduling issues.
              </Text>
              <AuthButton small provider='nylas'>
                Re-connect
              </AuthButton>
            </div>
          )}
          {isDisconnected && !isSelf && (
            <div className='mb-3 px-4'>
              <Text h='400' color='gray-500'>
                Please ask them to re-connect or remove them as moderator.
              </Text>
            </div>
          )}
          <hr />
          {requireCalendar && hasCalendlyFeature && onClickSwitchToCalendly && (
            <DropdownLink onClick={onClickSwitchToCalendly}>Switch to Calendly…</DropdownLink>
          )}

          <Tooltip
            content={`Please assign another study owner before removing ${user.name} from the ${type} list`}
            isDisabled={!isOwner}
          >
            <DropdownLink disabled={isOwner || disabled} onClick={handleClickRemove}>
              Remove as {type}
            </DropdownLink>
          </Tooltip>
        </Card>
      </PopUp>
    </div>
  );
};
