import * as React from 'react';
import { useState } from 'react';

import classNames from 'classnames';

interface Props {
  initialValue?: string | null;
  className?: string;
  placeholder?: string;
  mode?: 'title' | 'description';
  onBlur?: (value: string) => void;
  readOnly?: boolean;
}

export const EditableHeader: React.FC<React.PropsWithChildren<Props>> = ({
  readOnly,
  initialValue,
  className,
  placeholder,
  mode = 'title',
  onBlur
}) => {
  const [value, setValue] = useState(initialValue || '');

  return (
    <input
      disabled={readOnly}
      name={mode}
      aria-label={mode}
      className={classNames(className, 'focus:outline-none placeholder-gray-400', {
        'h600 font-bold': mode === 'title',
        h400: mode === 'description'
      })}
      value={value}
      onChange={(e) => {
        setValue(e.currentTarget.value);
      }}
      onBlur={(e) => onBlur?.(e.currentTarget.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.currentTarget.blur();
        }
      }}
      placeholder={placeholder}
    />
  );
};
