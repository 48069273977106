import { useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { Consumer, Mixin, Subscription } from '@rails/actioncable';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Editor } from '@tiptap/core';

import consumer from '@helpers/consumer';

import { highlightApi } from '../api';
import { HighlightAttributes } from '../extensions';

type Channels = Record<
  number,
  {
    subscription: Subscription<Consumer> & Mixin;
    isLoading: boolean;
  }
>;

export const useHighlightAITitle = (editor: Editor | null) => {
  const channels = useRef<Channels>({});

  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, AnyAction>>();

  const attributes = editor?.getAttributes('highlight') as HighlightAttributes | undefined;

  const updateTitle = (highlight: DocumentHighlight) => {
    channels.current[highlight.id].isLoading = false;

    const params = { documentId: highlight.document_id, highlightId: highlight.id };

    const patch = highlightApi.util.updateQueryData('getDocumentHighlight', params, (draft) => {
      draft.title = highlight.title;
    });

    dispatch(patch);
  };

  const register = (highlightId: number) => {
    const subscription = consumer.subscriptions.create(
      { channel: 'Repo::HighlightsChannel', id: highlightId },
      { received: ({ message }: { message: DocumentHighlight }) => updateTitle(message) }
    );

    channels.current[highlightId] = { subscription, isLoading: true };
  };

  useEffect(() => {
    if (!editor || !attributes) return;

    if (attributes.highlightId) {
      register(attributes.highlightId);
    }
  }, [channels.current, attributes?.highlightId]);

  useEffect(() => {
    return () => {
      Object.values(channels.current).forEach((channel) => {
        channel.subscription.unsubscribe();
        channel.isLoading = false;
      });
    };
  }, []);

  return { register };
};
