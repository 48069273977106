import React, { forwardRef, HTMLAttributes, useLayoutEffect, useRef } from 'react';

import cn from 'classnames';
import mergeRefs from 'react-merge-refs';

import { GrabberSVG } from '@components/svgs';
import { useMutationObserver } from '@hooks/useMutationObserver';

import { Drawer } from '../hooks/useDrawer';
import { useDrawerContext } from '../hooks/useDrawerContext';

type Props = PropsWithChildrenFn<HTMLAttributes<HTMLDivElement>, Pick<Drawer['state'], 'isExpanded'>>;

export const Header = forwardRef<HTMLDivElement, Props>(({ className, children, ...rest }, ref) => {
  const innerRef = useRef<HTMLDivElement>(null);

  const { setHeaderHeight } = useDrawerContext();

  useLayoutEffect(() => {
    if (innerRef.current) {
      setHeaderHeight(innerRef.current.offsetHeight);
    }
  }, []);

  useMutationObserver(
    innerRef,
    (mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList' && innerRef.current) {
          setHeaderHeight(innerRef.current.offsetHeight);
        }
      }
    },
    { subtree: true, childList: true }
  );

  return (
    <header
      data-testid='drawer-header'
      ref={mergeRefs([innerRef, ref])}
      className={cn(
        'jutify-center relative z-10 flex w-full flex-shrink-0 flex-col items-center overflow-hidden bg-white',
        className
      )}
      {...rest}
    >
      <GrabberSVG className='absolute left-1/2 right-0 top-2 -translate-x-1/2 -translate-y-1/2 transform' />
      {children}
    </header>
  );
});
