import { useContext, useEffect, useState } from 'react';

import localforage from 'localforage';

import { decrypt, encrypt } from '@components/crypto';
import { useAccount } from '@hooks/useAccount';
import { useUser } from '@hooks/useUser';
import configStore from '@stores/config';

localforage.config({
  name: 'gq-candidates-store'
});

export const maxValue = <K extends string, T extends Record<K, number>>(key: K, values: T[]): number | undefined => {
  let m: number | undefined;
  for (const v of values) {
    const d = new Date(v[key]);
    if (!m || +d > m) m = +d;
  }
  return m;
};

type Foraged<T> = {
  id: number;
  updatedAt: number;
  data: T;
};

type Hook<T> = {
  isFetching: boolean;
  isEncrypting: boolean;
  isDecrypting: boolean;
  updatedAt?: number;
  data?: T;
  storeData: (value: T) => Promise<void>;
  errored: boolean;
  remove: (ids: number[]) => Promise<void>;
  fetch: () => Promise<void>;
};
export const useForage = <T extends { id: number; updated_at: number }[]>(
  key: string,
  defaultValue?: T,
  skip?: boolean
): Hook<T> => {
  const [isFetching, setIsFetching] = useState(true);
  const [updatedAt, setUpdatedAt] = useState<number>();
  const [data, setData] = useState<T | undefined>(defaultValue);
  const [decrypting, setDecrypting] = useState(false);
  const [encrypting, setEncrypting] = useState(false);
  const [errored, setErrored] = useState(false);

  const { account } = useAccount();
  const user = useUser();

  const {
    state: {
      config: { localCandidateDbKey }
    }
  } = useContext(configStore);
  const compoundKey = `user:${user.id};account:${account.id};${key}`;

  if (!('clearLocalForage' in window)) {
    (window as any).clearLocalForage = async () => {
      await localforage.clear();
      console.log('cleared localForage');
    };
  }

  const fetch = async () => {
    setIsFetching(true);
    let blob: string | null;

    const keys = await localforage.keys();
    const keysToDecrypt = keys.filter((key) => key.includes(`${compoundKey}-page-`));

    let newData: any;
    let newUpdatedAt = updatedAt;
    setDecrypting(true);
    for (const key of keysToDecrypt) {
      try {
        blob = await localforage.getItem(key);
      } catch (e) {
        console.error(e);
        setIsFetching(false);
        setDecrypting(false);
        setErrored(true);
        return;
      }

      let value: Foraged<T>;

      try {
        value = await decrypt<Foraged<T>>(blob as any, localCandidateDbKey as any);
      } catch (e) {
        console.error(e);
        setIsFetching(false);
        setDecrypting(false);
        setErrored(true);
        await localforage.clear();
        return;
      }
      const { data: dataToAppend, updatedAt: updatedAtToCompare } = value;

      newData = (newData || []).concat(dataToAppend);
      if (!newUpdatedAt || newUpdatedAt < updatedAtToCompare) newUpdatedAt = updatedAtToCompare;
    }

    setData(newData);
    setUpdatedAt(newUpdatedAt);
    setIsFetching(false);
    setDecrypting(false);
  };

  const storeData = async (value: T) => {
    const maxUpdatedAt = maxValue('updated_at', value);
    setData(value);
    setUpdatedAt(maxUpdatedAt);
    setEncrypting(true);
    let blob: string;

    const chunkSize = 10000;
    let i: number;
    let j: number;
    for (i = 0, j = value.length; i < j; i += chunkSize) {
      try {
        const chunk = value.slice(i, i + chunkSize);
        blob = await encrypt({ data: chunk, updatedAt: maxUpdatedAt }, localCandidateDbKey as any);
      } catch (e) {
        console.error(e);
        setErrored(true);
        return;
      } finally {
        setEncrypting(false);
      }
      const page = i / chunkSize + 1;
      try {
        await localforage.setItem(`${compoundKey}-page-${page}`, blob);
      } catch (e) {
        console.error(e);
        setErrored(true);
      }
    }
  };

  const remove = async (ids: number[]) => {
    setIsFetching(true);
    let blob: string | null;
    try {
      blob = await localforage.getItem(compoundKey);
    } catch (e) {
      console.error(e);
      setErrored(true);
      return;
    } finally {
      setIsFetching(false);
    }

    let value: Foraged<T>;
    setDecrypting(true);
    try {
      value = await decrypt<Foraged<T>>(blob as any, localCandidateDbKey as any);
      value.data && value.updatedAt;
    } catch (e) {
      console.error(e);
      setIsFetching(false);
      setErrored(true);
      await localforage.clear();
      return;
    } finally {
      setDecrypting(false);
    }
    const { data } = value;

    const newData = data.filter((item) => !ids.includes(item.id));

    storeData(newData as T);
  };

  useEffect(() => {
    if (!skip) {
      fetch();
    }
  }, []);

  return {
    isDecrypting: decrypting,
    isEncrypting: encrypting,
    isFetching,
    data,
    updatedAt,
    errored,
    storeData,
    remove,
    fetch
  };
};
