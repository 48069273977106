import * as React from 'react';

import { Button, Text } from '@components/common';
import { ScreenerZDSSVG } from '@components/StudiesApp/components/StudyPublished/components/ScreenerZDSSVG';

import { StudyScreenerBuilder } from '../components/StudyScreenerBuilder';

interface Props {
  includeScreener: boolean;
  setIncludeScreener?: (val: boolean) => void;
}

export const Screener: React.FC<React.PropsWithChildren<PageProps & Props>> = ({
  pre_screener,
  onSave,
  includeScreener,
  setIncludeScreener,
  study
}) => {
  if (!includeScreener) {
    return (
      <div className='pt-gutter'>
        <div className='mx-auto max-w-xl text-center'>
          <ScreenerZDSSVG className='mx-auto pb-8' />
          <Text h='600' bold className='mb-2'>
            Ask screening questions
          </Text>
          <Text className='mb-6'>You can review each candidates’ answers before inviting them to participate.</Text>
          {study.state !== 'closed' && (
            <Button primary onClick={() => setIncludeScreener?.(true)}>
              Create Screener
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='mx-auto max-w-3xl'>
        <StudyScreenerBuilder
          screener={{ ...pre_screener, style: study.style, project_id: study.id } as any}
          builderTitle
          page='studyBuilder'
          onSave={onSave}
          study={study}
          preScreener
        />
      </div>
      <div id='pre-publish-survey-actions' className='tablet:top-8 tablet:right-12 absolute right-0 top-0 z-10' />
    </div>
  );
};
