import React from 'react';

import Skeleton from 'react-loading-skeleton';

import Activities from '@components/Activities';
import { ActivitiesZDS } from '@components/DashboardApp/ActivitiesZDS';
import { api } from 'api/reduxApi';

const ACTIVITIES_TO_SHOW = 4;

export const ActivityTable = () => {
  const { data: activities, isLoading } = api.useGetActivitiesQuery({ limit: ACTIVITIES_TO_SHOW + 1 });

  return (
    <div>
      <h2 className='h600 mb-4 mt-12 font-bold'>Recent Activity</h2>
      <div>
        {isLoading && <Skeleton className='h-24' />}
        {!isLoading && activities?.length ? (
          <div className='rounded-md border border-gray-200 bg-white px-6'>
            <Activities activities={activities.slice(0, ACTIVITIES_TO_SHOW)} />
            {activities.length > 4 && (
              <div className='border-t border-gray-200 py-4'>
                <a href='/activities' target='_blank' className='text-sm font-medium'>
                  View all
                </a>
              </div>
            )}
          </div>
        ) : (
          <ActivitiesZDS />
        )}
      </div>
    </div>
  );
};
