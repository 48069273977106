import * as React from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { CheckSVG, LockSVG, WorldSVG } from '@components/svgs';

import { ACCESS_DESCRIPTION, ACCESS_LABELS } from '../consts';

export const ICONS: Record<ShareAccess, React.FC<React.PropsWithChildren<unknown>>> = {
  public: WorldSVG,
  private: LockSVG
};

type Props = {
  value: ShareAccess;
  checked: boolean;
  onClick?: React.MouseEventHandler;
};
export const Option: React.FC<React.PropsWithChildren<Props>> = ({ value, onClick, checked }) => {
  const Icon = ICONS[value];

  return (
    <div
      role='button'
      tabIndex={0}
      aria-label={`Set share access to ${value}`}
      className={cn('p-4', {
        'bg-indigo-600 text-white': checked,
        'hover:bg-gray-50 hover:text-indigo-600': !checked
      })}
      onClick={onClick}
    >
      <div className=''>
        <div className='mb-1 flex items-center space-x-2'>
          <Icon />
          <Text className='flex-1' bold>
            {ACCESS_LABELS[value]}
          </Text>
          {checked && <CheckSVG />}
        </div>
        <Text h='400'>{ACCESS_DESCRIPTION[value]}</Text>
      </div>
    </div>
  );
};
