import * as React from 'react';
import { ReactNode, useEffect } from 'react';

import { api } from '@api/reduxApi';
import { ClipContent } from '@components/RepositoryApp/components/PreviewContentSlideout/components/ClipContent';
import { HighlightContent } from '@components/RepositoryApp/components/PreviewContentSlideout/components/HighlightContent';
import { InsightContent } from '@components/RepositoryApp/components/PreviewContentSlideout/components/InsightContent';
import { InterviewContent } from '@components/RepositoryApp/components/PreviewContentSlideout/components/InterviewContent';
import { ReelContent } from '@components/RepositoryApp/components/PreviewContentSlideout/components/ReelContent';
import { ClipSkeleton } from '@components/RepositoryApp/components/PreviewContentSlideout/components/skeletons/ClipSkeleton';
import { InsightSkeleton } from '@components/RepositoryApp/components/PreviewContentSlideout/components/skeletons/InsightSkeleton';
import { InterviewSkeleton } from '@components/RepositoryApp/components/PreviewContentSlideout/components/skeletons/InterviewSkeleton';
import { ReelSkeleton } from '@components/RepositoryApp/components/PreviewContentSlideout/components/skeletons/ReelSkeleton';
import { ArtifactHit } from '@components/RepositoryApp/types';
import { ArtifactType } from '@components/shared/Artifact/components/Info';
import { useMuxVideoDownloadUrl } from '@hooks/useMuxVideoDownloadUrl';
import { usePermission } from '@hooks/usePermission';
import { Toast, useToaster } from '@stores/toaster';

export type Attrs = { title: string; access: ShareAccess };

type MutationParams = {
  updateModel: (attrs: Partial<Attrs>) => void;
  skeleton: ReactNode;
  downloadUrl?: string;
  model?: Insight | RepoSession | Clip | HighlightReel | DocumentHighlight;
  content: ReactNode;
  canDelete: boolean;
  canUpdate: boolean;
};

type Data = Record<Exclude<ArtifactHit['kind'], 'ScreenerResponse' | 'Participation'>, MutationParams>;

type Hook = { renderData: MutationParams; isLoading: boolean };

const ERROR_TOAST: Toast = {
  heading: 'Failed to get preview data!',
  icon: 'error',
  text: 'Something went wrong. Please try again later.'
};

const UPDATE_ERROR_TOAST: Toast = {
  heading: 'Failed to update!',
  icon: 'error',
  text: 'Something went wrong. Please try again later.'
};

const updateSuccess = (v: ArtifactHit['kind']): Toast => ({
  heading: 'Update successful!',
  text: `${ArtifactType[v]} was successfully updated.`,
  icon: 'success'
});

export const useContentRenderer = (artifact: ArtifactHit): Hook => {
  const isClip = artifact.objectID.startsWith('Clip');
  const isHighlight = artifact.objectID.startsWith('Highlight');

  const {
    data: highlightReel,
    isLoading: reelLoading,
    isError: reelError
  } = api.useGetHighlightReelQuery(artifact.model_id as string, {
    skip: artifact.kind !== 'HighlightReel'
  });

  const {
    data: insight,
    isLoading: insightLoading,
    isError: insightError
  } = api.useGetInsightQuery(artifact.href.slice(10) as string, { skip: artifact.kind !== 'Story' });

  const {
    data: session,
    isLoading: sessionLoading,
    isError: sessionError
  } = api.useGetRepoSessionQuery({ uuid: artifact.href.slice(10) }, { skip: artifact.kind !== 'Repo::Session' });

  const {
    data: clip,
    isLoading: clipLoading,
    isError: clipError
  } = api.useGetClipQuery({ highlightUuid: String(artifact.model_id) }, { skip: !isClip });

  const {
    data: highlight,
    isLoading: highlightLoading,
    isError: highlightError
  } = api.useGetHighlightByIdQuery({ id: String(artifact.model_id) }, { skip: !isHighlight });

  const { data: clips, isError: clipsError } = api.useGetHighlightReelClipsQuery(artifact.model_id as string, {
    skip: artifact.kind !== 'HighlightReel'
  });

  const { downloadUrl: clipDownloadUrl } = useMuxVideoDownloadUrl(clip?.mux_video?.id);

  const skip = !session?.recording?.mux_video?.download_ready;
  const { downloadUrl: sessionDownloadUrl } = useMuxVideoDownloadUrl(session?.recording?.mux_video?.id, skip);

  const downloadReady = highlightReel?.mux_video?.download_ready;

  const { downloadUrl: reelDownloadUrl } = useMuxVideoDownloadUrl(highlightReel?.mux_video?.id, !downloadReady);

  const canDeleteInsight = usePermission<Insight>('deleteInsight')();
  const canDeleteInterview = usePermission('canCreate')() && session?.kind === 'recording';
  const canDeleteReel = usePermission('canCreate')();

  const canUpdateInsight = usePermission('updateInsight')();
  const canUpdateReel = usePermission('updateReel')();
  const canUpdateClip = usePermission('updateClip')();
  const canUpdateInterview = usePermission('updateRepo')();

  const [updateRepoSession, { isError: interviewUpdateError, isSuccess: interviewSuccess }] =
    api.useUpdateRepoSessionMutation();
  const [updateHighlightReel, { isError: reelUpdateError, isSuccess: reelSuccess }] =
    api.useUpdateHighlightReelMutation();
  const [updateInsight, { isError: insightUpdateError, isSuccess: insightSuccess }] = api.useUpdateInsightMutation();
  const [updateHighlight, { isError: highlightUpdateError, isSuccess: highlightSuccess }] =
    api.useUpdateHighlightMutation();

  const data: Data = {
    Story: {
      updateModel: (attrs) => updateInsight({ slug: artifact.href.slice(10), ...attrs }),
      skeleton: <InsightSkeleton />,
      model: insight,
      content: <InsightContent insight={insight} />,
      canDelete: canDeleteInsight,
      canUpdate: canUpdateInsight
    },
    'Repo::Session': {
      updateModel: (attrs) => updateRepoSession({ uuid: session?.uuid as any, ...(attrs as any) }),
      skeleton: <InterviewSkeleton />,
      downloadUrl: sessionDownloadUrl,
      model: session,
      content: <InterviewContent session={session ?? ({} as RepoSession)} />,
      canDelete: canDeleteInterview,
      canUpdate: canUpdateInterview
    },
    HighlightReel: {
      updateModel: (attrs) => updateHighlightReel({ token: highlightReel?.token, ...attrs }),
      skeleton: <ReelSkeleton />,
      downloadUrl: reelDownloadUrl,
      model: highlightReel,
      content: <ReelContent highlightReel={highlightReel} clips={clips} />,
      canDelete: canDeleteReel,
      canUpdate: canUpdateReel
    },
    Clip: {
      updateModel: (attrs) => updateHighlight({ uuid: String(clip?.highlight?.uuid), ...attrs }),
      skeleton: <ClipSkeleton />,
      downloadUrl: clipDownloadUrl,
      model: clip,
      content: <ClipContent clip={clip} />,
      canDelete: canUpdateClip,
      canUpdate: canUpdateClip
    },
    Highlight: {
      updateModel: (attrs) => updateHighlight({ uuid: String(highlight?.uuid), ...attrs }),
      skeleton: <ClipSkeleton />,
      model: highlight,
      content: <HighlightContent highlight={highlight} />,
      canDelete: canUpdateClip,
      canUpdate: canUpdateClip
    }
  };

  const showToast = useToaster();

  // GET errors

  useEffect(() => {
    if (clipError) {
      showToast(ERROR_TOAST);
    }
  }, [clipError]);

  useEffect(() => {
    if (sessionError) {
      showToast(ERROR_TOAST);
    }
  }, [sessionError]);

  useEffect(() => {
    if (reelError || clipsError) {
      showToast(ERROR_TOAST);
    }
  }, [reelError, clipsError]);

  useEffect(() => {
    if (insightError) {
      showToast(ERROR_TOAST);
    }
  }, [insightError]);

  // UPDATE errors

  useEffect(() => {
    if (interviewUpdateError) {
      showToast(UPDATE_ERROR_TOAST);
    }
  }, [interviewUpdateError]);

  useEffect(() => {
    if (reelUpdateError) {
      showToast(UPDATE_ERROR_TOAST);
    }
  }, [reelUpdateError]);

  useEffect(() => {
    if (insightUpdateError) {
      showToast(UPDATE_ERROR_TOAST);
    }
  }, [insightUpdateError]);

  // UPDATE success

  useEffect(() => {
    if (interviewSuccess) {
      showToast(updateSuccess(artifact.kind));
    }
  }, [interviewSuccess]);

  useEffect(() => {
    if (reelSuccess) {
      showToast(updateSuccess(artifact.kind));
    }
  }, [reelSuccess]);

  useEffect(() => {
    if (insightSuccess) {
      showToast(updateSuccess(artifact.kind));
    }
  }, [insightSuccess]);

  const isLoading = insightLoading || sessionLoading || reelLoading || clipLoading || highlightLoading;

  const type = artifact.objectID.split('_')[0];

  return {
    isLoading,
    renderData: data[type]
  };
};
