import React from 'react';

import cn from 'classnames';
import pluralize from 'pluralize';
import { ColumnDef } from '@tanstack/react-table';

import { ProgressBar, Text } from '@components/common';
import { CategoriesOptionsDropdown } from '@components/ScreenerResults/components/CardSortResponse/components/CategoriesOptionsDropdown';
import { renderMergedCategories } from '@components/ScreenerResults/components/CardSortResponse/utils';
import { Table } from '@components/shared/Table';
import { ChevronDownSVG, InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { pct } from '@components/utils';

import { CategoriesTableData } from '../CategoriesTable';

export type DefinitionFnReturn = ColumnDef<CategoriesTableData>[];

export type DefinitionFn = (args: {
  mergedCategories: { name: string; categories: string[] }[];
  setUnmergeModal: (name: string) => void;
  setMergeModal: (value: { name: string; categories: string[] }) => void;
  renderMergeButton: () => JSX.Element;
}) => DefinitionFnReturn;

export const getCategoriesTableColumnDefs: DefinitionFn = ({
  renderMergeButton,
  mergedCategories,
  setMergeModal,
  setUnmergeModal
}) => {
  const columns: DefinitionFnReturn = [
    {
      id: 'category',
      accessorKey: 'category',
      cell: (props) => (
        <Table.Cell<CategoriesTableData>
          {...props}
          render={({ row }) => (
            <div className='flex flex-col px-2'>
              <div className='flex items-center'>
                <div className='truncate'>{row.original.category || 'Unnamed'}</div>
                {renderMergedCategories({ category: row.original.category, mergedCategories })}
              </div>
              {row.getIsExpanded() && (
                <div className='mt-6 flex flex-col space-y-3 overflow-hidden'>
                  <Text bold h='200' truncate>
                    Cards
                  </Text>
                  {row.original.cards.map(({ name }) => (
                    <Text h='400' truncate>
                      {name}
                    </Text>
                  ))}
                </div>
              )}
            </div>
          )}
          className={cn('h400 flex flex-col justify-center bg-white', props.row.getIsExpanded() ? 'py-3' : 'h-11')}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} disableHide>
          Category
        </Table.ColumnHeader>
      )
    },
    {
      id: 'cards_number',
      accessorFn: (row) => row.cards.length,
      cell: (props) => (
        <Table.Cell<CategoriesTableData>
          {...props}
          render={({ row }) => (
            <div className='flex w-full flex-col px-2'>
              <div className='truncate'>{pluralize('card', row.original.cards.length || 0, true)}</div>
              {row.getIsExpanded() && (
                <div className='mt-6 flex w-full flex-col space-y-3 overflow-hidden'>
                  <div className='flex items-center space-x-2'>
                    <div className='h200 truncate font-bold'>Frequency</div>
                    <Tooltip content='The number of responses where this card was put into this category'>
                      <InfoCircleIcon className='h-4 w-4' />
                    </Tooltip>
                  </div>
                  {row.original.cards.map(({ frequency }) => (
                    <Text h='400' truncate>
                      {frequency}
                    </Text>
                  ))}
                </div>
              )}
            </div>
          )}
          className={cn('h400 flex flex-col justify-center bg-white', props.row.getIsExpanded() ? 'py-3' : 'h-11')}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} disableHide sortIconType='number'>
          Number of cards
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<CategoriesTableData>
          render={({ row }) => (
            <div className='flex flex-1 flex-col px-2'>
              <div className='flex flex-1 items-center'>
                <div className='flex-grow'>
                  <ProgressBar size='sm' progress={pct(row.original.avgAgreement, 1)} />
                </div>
                <div className='h400 ml-4 w-8 whitespace-nowrap'>{pct(row.original.avgAgreement, 1)} %</div>
              </div>
            </div>
          )}
          {...props}
          className={cn('h400 flex flex-col justify-center bg-white', {
            'h-11': !props.row.getIsExpanded(),
            'py-3': props.row.getIsExpanded()
          })}
        />
      ),
      id: 'avg_agreement',
      accessorFn: (row) => row.avgAgreement,
      header: (props) => (
        <Table.ColumnHeader {...props} disableHide sortIconType='number'>
          <div className='mr-1 flex w-full flex-nowrap items-center space-x-1'>
            <span className='truncate'>Avg. Agreement</span>
            <Tooltip content='The average agreement is calculated as a simple average of non-zero agreement rates for this category'>
              <InfoCircleIcon className='h-4 w-4' />
            </Tooltip>
          </div>
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<CategoriesTableData>
          {...props}
          render={({ row }) => {
            const mergedCategory = mergedCategories.find((merged) => merged.name === row.original.category);
            return (
              <div className='flex w-full items-center justify-end space-x-2 px-2'>
                {mergedCategory && (
                  <CategoriesOptionsDropdown
                    onEdit={() => setMergeModal(mergedCategory)}
                    onUnmerge={() => setUnmergeModal(mergedCategory?.name)}
                  />
                )}

                <button
                  aria-label={row.getIsExpanded() ? 'Collapse' : 'Extend'}
                  className='focus:ring-blue focus:outline-none flex-shrink-0 rounded-full bg-white p-2 hover:bg-gray-50 focus:ring'
                  onClick={row.getToggleExpandedHandler()}
                >
                  <ChevronDownSVG className={cn('h-4 w-4 transform', { ['rotate-180']: row.getIsExpanded() })} />
                </button>
              </div>
            );
          }}
          className={cn('h400 flex flex-col justify-center bg-white', props.row.getIsExpanded() ? 'py-3' : 'h-11')}
        />
      ),
      id: 'expand',
      header: (props) => <Table.ColumnHeader {...props} isStatic render={renderMergeButton} />
    }
  ];

  return columns;
};
