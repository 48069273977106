import * as React from 'react';

import Tippy from '@tippyjs/react';

import { Button, Radio, Text } from '@components/common';

type Props = {
  label: string;
  value: string;
  isPast: boolean;
  checked: boolean;
  onChange: () => void;
};
export const TimeSlotRadioButton: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  value,
  isPast,
  checked,
  onChange
}) => {
  const button = (
    <Button disabled={isPast} className='flex w-full items-center space-x-2' onClick={isPast ? undefined : onChange}>
      <Radio disabled={isPast} name='time' value={value} checked={checked} onChange={onChange} />
      <Text className='flex-1 text-center'>{label}</Text>
    </Button>
  );

  if (isPast) {
    return (
      <Tippy content='This time slot has already passed.'>
        <div className='w-full'>{button}</div>
      </Tippy>
    );
  }

  return button;
};
