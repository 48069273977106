import React, { useEffect, useState } from 'react';

import { Button, Input, LabelText, Loading, Modal, Select, SelectOption, Text } from 'components/common';

import { useGenerateSampleCandidatesMutation } from './api';

const COUNT_OPTIONS: SelectOption[] = [
  { value: '10', label: '10' },
  { value: '100', label: '100' },
  { value: '1000', label: '1,000' }
];
export const GenerateCandidatesModal: React.FC<React.PropsWithChildren<{ onClose: () => void }>> = ({ onClose }) => {
  const [generateCandidates, { isSuccess, isLoading }] = useGenerateSampleCandidatesMutation();
  const [count, setCount] = useState<number>(100);
  const [attrs, setAttrs] = useState<string[]>([]);
  useEffect(() => {
    if (isSuccess) {
      onClose();
      window.location.href = '/candidates';
    }
  }, [isSuccess]);

  function handleGenerate() {
    generateCandidates({ count, custom_attrs: attrs });
  }
  return (
    <Modal
      size='md'
      onClose={onClose}
      title='Generate sample candidates'
      renderFooter={() => (
        <Button primary onClick={handleGenerate}>
          Generate
        </Button>
      )}
    >
      {isLoading && <Loading absolute />}
      <Text className='mb-4'>Generate sample candidates for your demo account.</Text>
      <LabelText className='mb-4'>How many?</LabelText>
      {/* <Input type='number' label='Number of candidates' value={count.toString()} onChange={(v) => setCount(parseInt(v))} /> */}
      <Select options={COUNT_OPTIONS} onChange={(v) => setCount(parseInt(v))} value={count.toString()} />
    </Modal>
  );
};
