import * as React from 'react';
import { useState } from 'react';

import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import Tippy from '@tippyjs/react';

import {
  Avatar,
  PopperDropdown,
  PopperDropdownAnchor,
  PopperDropdownButton,
  PopperDropdownLink,
  Text
} from '@components/common';
import { CTA } from '@components/Sidebar/CTA';
import { AskAiSVG, CaretDownSVG, CaretSVG, CogSVG, LogoExpandSVG } from '@components/svgs';
import { SignOutSVG } from '@components/svgs/SignOutSVG';
import { SwitchAccountsSVG } from '@components/svgs/SwitchAccountsSVG';
import { useAccount } from '@hooks/useAccount';
import { useDeviceType } from '@hooks/useDeviceType';
import { useFeature } from '@hooks/useFeature';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { usePermission } from '@hooks/usePermission';
import { usePlan } from '@hooks/usePlan';
import { useUser } from '@hooks/useUser';
import { RestrictedAction } from 'components/shared/RestrictedAction';

import { NAV_SHORTCUT_DEFINITIONS as SHORTCUTS } from './consts';
import { useSidebar } from './context';
import { NavItem } from './NavItem';
import * as svgs from './svgs';
import { Teams } from './Teams';

const newTabs: string[] = [];

export const Sidebar: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [mouseOver, setMouseOver] = useState(false);
  const { isMobile, isTablet } = useDeviceType();
  const sidebar = useSidebar();
  const { account } = useAccount();
  const user = useUser();
  const canCreate = usePermission('createStudy');
  const isAdmin = usePermission('isAdmin')();
  const hasSwitcher = useFeature('account_switcher');
  const hasBackgroundTasksSlideout = useFeature('background_tasks_slideout');
  const { hasFeature, subscribed } = usePlan();
  const { pathname } = useLocation();
  const enableTeams = useFeature('teams');
  const hasAiChat = account.ai_enabled;

  const [hiddenNewLabels, setHiddenNewLabels] = useLocalStorage<string[]>('sidebar-hidden-new-labels');

  const className = cn('bg-indigo-1000 flex-col w-full h-full', {
    'absolute transition-left': sidebar.fixed && !isMobile,
    hidden: sidebar.hidden,
    'desktop:flex flex': !sidebar.hidden,
    'tablet:w-60': sidebar.open,
    'tablet:w-12': !sidebar.open
  });

  React.useEffect(() => {
    setMouseOver(false);
  }, [sidebar.open]);

  return (
    <>
      {sidebar.open && sidebar.fixed && !isMobile && <div className='fixed inset-0' onClick={sidebar.toggle} />}
      <div
        className={className}
        id='app-nav'
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        style={{ left: sidebar.open ? 0 : -60 }}
      >
        {!sidebar.open && (
          <>
            <div
              className={cn('relative flex flex-1 cursor-pointer flex-col', isMobile ? 'py-3' : 'pt-4')}
              onClick={sidebar.toggle}
            >
              <Tippy
                disabled={isMobile}
                className='whitespace-nowrap'
                placement='right'
                arrow={false}
                content='Show Navigation'
              >
                <button
                  name='nav_toggle'
                  aria-label='Show Navigation'
                  className='tablet:flex hidden h-8 w-8 items-center justify-center self-center rounded-lg hover:bg-gray-700'
                  onClick={sidebar.toggle}
                  aria-expanded={sidebar.open}
                >
                  <div className='mx-auto flex h-full w-full items-center justify-center' aria-expanded='false'>
                    {mouseOver ? <svgs.navExpand /> : <LogoExpandSVG className='text-white' />}
                  </div>
                </button>
              </Tippy>
              <div className='desktop:hidden tablet:mb-4 relative flex flex-shrink-0 items-center justify-between px-4'>
                {(!isTablet || sidebar.open) && (
                  <Link aria-label='Great Question logo' to='/'>
                    <svgs.logo />
                  </Link>
                )}

                <button
                  type='button'
                  aria-label='Show Navigation'
                  className='tablet:hidden focus:outline-none mobile-nav-toggle flex items-center text-white focus:text-white'
                  onClick={sidebar.toggle}
                  aria-expanded={sidebar.open}
                >
                  {isMobile ? <svgs.hamburger /> : <LogoExpandSVG className='text-white' />}
                </button>
              </div>
            </div>
            {!subscribed && (
              <Tippy
                disabled={isMobile}
                className='whitespace-nowrap'
                placement='right'
                arrow={false}
                content='Upgrade plans'
              >
                <a
                  aria-label='Upgrade plans'
                  href='/plans'
                  className='focus:outline-none group flex place-content-center items-center bg-indigo-600 px-2 py-2 text-sm font-medium text-white visited:text-white hover:bg-indigo-700 hover:text-white focus:text-white'
                  data-target='collapse.expanded'
                >
                  <svgs.upgrade className='h-6 w-6 text-white' />
                </a>
              </Tippy>
            )}
          </>
        )}
        {sidebar.open && (
          <>
            <div className={cn('no-scrollbar flex flex-1 flex-col overflow-y-auto py-3', isMobile ? 'py-3' : 'pt-4')}>
              <div className='relative mb-4 flex flex-shrink-0 items-center justify-between pl-4 pr-3'>
                <Link to='/' aria-label='Great Question logo'>
                  <svgs.logo />
                </Link>
                <Tippy
                  disabled={isMobile}
                  className='whitespace-nowrap'
                  placement='right'
                  arrow={false}
                  content='Hide Navigation'
                >
                  <button
                    aria-expanded={sidebar.open}
                    aria-label='Hide navigation'
                    className='desktop:flex hidden h-8 w-8 items-center justify-center rounded-lg hover:bg-gray-700'
                    onClick={sidebar.toggle}
                  >
                    <div className='mx-auto flex h-full w-full items-center justify-center'>
                      <svgs.navCollapse />
                    </div>
                  </button>
                </Tippy>
                <button
                  type='button'
                  aria-expanded={sidebar.open}
                  aria-label='Hide navigation'
                  className='desktop:hidden focus:outline-none mobile-nav-toggle flex items-center text-white focus:text-white'
                  onClick={sidebar.toggle}
                >
                  <svgs.hamburger />
                </button>
              </div>
              <div className='bg-indigo-1000 flex flex-shrink-0 flex-col border-b border-t border-gray-500 hover:bg-gray-700'>
                <PopperDropdown
                  medium
                  className='group mx-4 block flex-1 flex-shrink-0 border-y border-gray-500'
                  aria-label={`${user.name} User menu`}
                  name='account_nav_toggle'
                  dropdownClass='w-full'
                  hideCaret
                  popperProps={{
                    closeOnClickOutside: true,
                    renderOnBodyRoot: false,
                    placement: 'bottom',
                    zIndex: 50,
                    className: 'px-2',
                    syncWidth: true
                  }}
                  text={
                    <div className='flex flex-1 items-center justify-between py-2.5 text-left'>
                      <div>
                        <Avatar size='lg' user={user} />
                      </div>
                      <div className='ml-3 flex-1 overflow-hidden'>
                        <Text h='400' truncate medium color='white'>
                          {user.name}
                        </Text>
                        <Text h='200' truncate medium color='gray-300' className='group-hover:text-gray-200'>
                          {account.workspace_name || account.name}
                        </Text>
                      </div>
                      <CaretDownSVG className='text-white' />
                    </div>
                  }
                >
                  {hasAiChat && (
                    <PopperDropdownLink
                      className='h400 block flex w-full cursor-pointer items-center space-x-2 px-4 pb-1.5 pt-1 text-gray-700 hover:text-indigo-600'
                      to='/ai/chats'
                      onClick={sidebar.userInfo.toggle}
                    >
                      <AskAiSVG className='h-4 w-4' />
                      <span>Ask AI History</span>
                    </PopperDropdownLink>
                  )}
                  <PopperDropdownAnchor
                    className={`h400 block flex w-full items-center space-x-2 px-4 pt-1 text-gray-700 hover:text-indigo-600 ${
                      hasSwitcher ? 'pb-1.5' : 'pb-3'
                    } cursor-pointer`}
                    href='/users/edit'
                  >
                    <CogSVG width='14' height='14' />
                    <span>Settings</span>
                  </PopperDropdownAnchor>
                  {hasSwitcher && (
                    <PopperDropdownLink
                      className='h400 block flex w-full cursor-pointer items-center space-x-2 px-4 pb-3 pt-1.5 text-gray-700 hover:text-indigo-600'
                      to='/accounts/choose'
                      onClick={sidebar.userInfo.toggle}
                    >
                      <SwitchAccountsSVG />
                      <span>Switch workspaces</span>
                    </PopperDropdownLink>
                  )}
                  <PopperDropdownButton
                    className={`h400 tw-ui-dropdown-link flex w-full items-center space-x-2 border-t border-gray-200 px-4 pt-3 text-gray-700 hover:text-indigo-600 ${
                      user.gq_admin ? 'pb-3' : 'pb-1'
                    } cursor-pointer`}
                    onClick={() => {
                      const form = document.createElement('form');
                      form.method = 'POST';
                      form.action = '/users/sign_out';

                      const methodInput = document.createElement('input');
                      methodInput.type = 'hidden';
                      methodInput.name = '_method';
                      methodInput.value = 'delete';

                      const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
                      if (csrfToken) {
                        const csrfInput = document.createElement('input');
                        csrfInput.type = 'hidden';
                        csrfInput.name = 'authenticity_token';
                        csrfInput.value = csrfToken;
                        form.appendChild(csrfInput);
                      }

                      form.appendChild(methodInput);
                      document.body.appendChild(form);
                      form.submit();
                    }}
                  >
                    <SignOutSVG /> <span>Sign out</span>
                  </PopperDropdownButton>
                  {user.gq_admin && (
                    <>
                      <div className='border-t border-gray-200 pb-1.5 pl-4 pt-3 text-xs font-semibold text-gray-500'>
                        GQ INTERNAL
                      </div>
                      <PopperDropdownAnchor
                        className='h400 block w-full cursor-pointer px-4 py-1.5 text-gray-700 hover:text-indigo-600'
                        href='/admin'
                      >
                        Super Admin
                      </PopperDropdownAnchor>
                      <PopperDropdownAnchor
                        className='h400 block w-full cursor-pointer px-4 py-1.5 text-gray-700 hover:text-indigo-600'
                        target='_blank'
                        href='/admin/sidekiq'
                      >
                        Sidekiq
                      </PopperDropdownAnchor>
                    </>
                  )}
                </PopperDropdown>
              </div>
              <div className='p-4'>
                <CTA permission={canCreate()} />
              </div>
              <nav className='bg-indigo-1000 flex-1' aria-label='Sidebar'>
                <NavItem
                  name='Dashboard'
                  isActiveNav={pathname === '/'}
                  spa={SHORTCUTS.dashboard.spa}
                  shortcut={SHORTCUTS.dashboard.keys}
                  href={SHORTCUTS.dashboard.href}
                >
                  <svgs.dashboard />
                  Dashboard
                </NavItem>
                <NavItem
                  name='Candidates'
                  isActiveNav={pathname.startsWith('/candidates')}
                  spa={SHORTCUTS.candidates.spa}
                  shortcut={SHORTCUTS.candidates.keys}
                  href={SHORTCUTS.candidates.href}
                >
                  <svgs.candidates />
                  Candidates
                </NavItem>
                <div>
                  <NavItem
                    name='Studies'
                    isActiveNav={pathname.startsWith('/studies')}
                    spa={SHORTCUTS.studies.spa}
                    shortcut={SHORTCUTS.studies.keys}
                    href={SHORTCUTS.studies.href}
                  >
                    <svgs.studies />
                    Studies
                  </NavItem>
                </div>
                {hasFeature('incentives') && isAdmin && (
                  <NavItem
                    name='Incentives'
                    isActiveNav={pathname.startsWith('/incentives')}
                    spa={SHORTCUTS.incentives.spa}
                    shortcut={SHORTCUTS.incentives.keys}
                    href={SHORTCUTS.incentives.href}
                  >
                    <svgs.incentives />
                    Incentives
                  </NavItem>
                )}
                <NavItem
                  name='Repository'
                  spa={SHORTCUTS.repository.spa}
                  shortcut={SHORTCUTS.repository.keys}
                  href={SHORTCUTS.repository.href}
                  isActiveNav={pathname.startsWith('/repository')}
                >
                  <svgs.repository />
                  Repository
                </NavItem>
                <div className='bg-indigo-1000 mt-4 flex flex-shrink-0 flex-col hover:bg-gray-700'>
                  <button
                    name='toggle'
                    className='focus:outline-none group block w-full flex-shrink-0 px-4 py-3 text-xs font-semibold leading-4 text-white visited:text-white hover:bg-gray-700 hover:text-white focus:text-white'
                    onClick={sidebar.resources.toggle}
                  >
                    <div className='flex items-center space-x-3'>
                      <div>Resources</div>
                      {sidebar.resources.open && <CaretDownSVG className='text-white' height='10' width='10' />}
                      {!sidebar.resources.open && <CaretSVG className='text-white' height='10' width='10' />}
                    </div>
                  </button>
                  {sidebar.resources.open && (
                    <div className='text-white'>
                      <ul className='bg-indigo-1000 pb-2 pt-2'>
                        {hasFeature('reporting') && isAdmin && (
                          <li>
                            <NavItem
                              onClick={() => {
                                if (newTabs.includes('Reporting')) {
                                  setHiddenNewLabels([...(hiddenNewLabels || []), 'Reporting']);
                                }
                              }}
                              isNew={newTabs.includes('Reporting') && !hiddenNewLabels?.includes('Reporting')}
                              spa
                              name='Reporting'
                              href='/reporting'
                              isActiveNav={pathname === '/reporting'}
                            >
                              <svgs.reporting />
                              Reporting
                            </NavItem>
                          </li>
                        )}

                        <li>
                          <NavItem
                            name='Getting started'
                            spa={SHORTCUTS.getting_started.spa}
                            shortcut={SHORTCUTS.getting_started.keys}
                            href={SHORTCUTS.getting_started.href}
                            isActiveNav={pathname === '/getting_started'}
                          >
                            <svgs.gettingStarted />
                            Getting started
                          </NavItem>
                        </li>
                        <li>
                          <NavItem
                            name='Guides'
                            spa={SHORTCUTS.guides.spa}
                            shortcut={SHORTCUTS.guides.keys}
                            href={SHORTCUTS.guides.href}
                            isActiveNav={pathname === '/guides'}
                          >
                            <svgs.guides />
                            Guides
                          </NavItem>
                        </li>
                        <li>
                          <NavItem
                            name='Templates'
                            spa={SHORTCUTS.templates.spa}
                            shortcut={SHORTCUTS.templates.keys}
                            href={SHORTCUTS.templates.href}
                            isActiveNav={pathname === '/study_templates'}
                          >
                            <svgs.templates />
                            Templates
                          </NavItem>
                        </li>
                        <li>
                          <NavItem
                            name='Help center'
                            spa={SHORTCUTS.help_center.spa}
                            shortcut={SHORTCUTS.help_center.keys}
                            href={SHORTCUTS.help_center.href}
                            targetBlank
                          >
                            <svgs.helpCenter />
                            Help center
                          </NavItem>
                        </li>
                        <li>
                          <NavItem
                            name='Chat with us'
                            href='#'
                            onClick={() => {
                              const pylon = (window as any).Pylon;
                              if (pylon) {
                                pylon('show');
                              }
                            }}
                          >
                            <svgs.chat />
                            Chat with us
                          </NavItem>
                        </li>
                        <li>
                          <NavItem
                            name='Changelog'
                            spa={SHORTCUTS.changelog.spa}
                            shortcut={SHORTCUTS.changelog.keys}
                            href={SHORTCUTS.changelog.href}
                            targetBlank
                          >
                            <svgs.changelog />
                            Changelog
                          </NavItem>
                        </li>

                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  )}
                </div>
                {enableTeams && <Teams />}
              </nav>
              <div>
                {hasBackgroundTasksSlideout && (
                  <div className='flex flex-1 flex-col justify-end'>
                    <NavItem spa name='View Tasks' onClick={sidebar.background_tasks.toggle} href='#'>
                      Background Tasks
                    </NavItem>
                  </div>
                )}
                <div className='flex flex-1 flex-col justify-end'>
                  <RestrictedAction permission='createAccountInvitation'>
                    {({ may, can }) => (
                      <NavItem
                        name='Invite teammate'
                        href={may && can ? '/invitations/new' : '#'}
                        shortcut='Mod+Shift+I'
                      >
                        <svgs.invite />
                        Invite teammate
                      </NavItem>
                    )}
                  </RestrictedAction>
                </div>
              </div>
            </div>
            {!subscribed && (
              <a
                href='/plans'
                className='focus:outline-none group flex items-center bg-indigo-600 px-2 py-2 text-sm font-medium text-white visited:text-white hover:bg-indigo-700 hover:text-white focus:text-white'
                data-target='collapse.expanded'
              >
                <svgs.upgrade className='mr-3 h-6 w-6 text-white' />
                Upgrade plans
              </a>
            )}
          </>
        )}
      </div>
    </>
  );
};
