import React, { FC } from 'react';

import { Button, Popper } from '@components/common';

interface Props {
  markAsComplete?: () => void;
  markAsNoShow?: () => void;
  markAsCanceled?: () => void;
}

export const MarkDropdown: FC<React.PropsWithChildren<Props>> = ({ markAsComplete, markAsNoShow, markAsCanceled }) => (
  <Popper
    content={() => (
      <div className='relative flex flex-col rounded-md border border-gray-200 bg-white py-2 ring-1 ring-black ring-opacity-5'>
        {markAsComplete && (
          <button
            className='xx-mark-completed w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-indigo-600'
            onClick={markAsComplete}
          >
            Mark as completed...
          </button>
        )}
        {markAsCanceled && (
          <button
            className='xx-mark-canceled w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-indigo-600'
            onClick={markAsCanceled}
          >
            Mark as canceled...
          </button>
        )}
        {markAsNoShow && (
          <button
            className='w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-indigo-600'
            onClick={markAsNoShow}
          >
            Mark as no-show
          </button>
        )}
      </div>
    )}
    placement='bottom-end'
    offset={[0, 16]}
    closeOnClickOutside
    zIndex={30}
  >
    <Button className='whitespace-nowrap' aria-label='Mark as…' iconSuffix='caretDown' primary>
      Mark as…
    </Button>
  </Popper>
);
