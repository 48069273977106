import * as React from 'react';

import DatePicker from 'react-datepicker';
import tinytime from 'tinytime';

import { Input, Text } from '@components/common';

import { DropdownProps } from '../../types';

const dateTemplate = tinytime('{Do} {MMMM} {YYYY}');

type Props<D = any> = DropdownProps<D, Date>;

export const Date: React.FC<React.PropsWithChildren<Props>> = ({ definition, range, value, onChange, operator }) => {
  const defRange = definition.range;

  const wantsRange = operator === 'is_between';
  const changeMin = (min: Date) => onChange({ range: { min, max: range?.max } } as any);
  const changeMax = (max: Date) => onChange({ range: { min: range?.min, max } } as any);

  return (
    <div>
      {wantsRange && (
        <div>
          <DatePicker
            placeholderText='Select date'
            customInput={<Input />}
            value={range?.min && dateTemplate.render(range?.min)}
            onChange={(value) => changeMin(value as Date)}
            minDate={defRange?.min}
            maxDate={defRange?.max}
            isClearable
            showYearDropdown
          />

          <Text h='400' color='gray-500' className='py-4'>
            and
          </Text>
          <DatePicker
            placeholderText='Select date'
            customInput={<Input />}
            value={range?.max && dateTemplate.render(range?.max)}
            onChange={(value) => changeMax(value as Date)}
            minDate={defRange?.min}
            maxDate={defRange?.max}
            isClearable
            showYearDropdown
          />
        </div>
      )}
      {!wantsRange && (
        <DatePicker
          showYearDropdown
          placeholderText='Select date'
          customInput={<Input />}
          selected={value}
          onChange={(value) => onChange({ value: value as any })}
          value={value && dateTemplate.render(value)}
          minDate={defRange?.min}
          maxDate={defRange?.max}
          isClearable
        />
      )}
      {defRange && (
        <Text className='mt-4' h='400' color='gray-500'>
          Current range is {defRange.min.toLocaleString()} to {defRange.max.toLocaleString()}.
        </Text>
      )}
    </div>
  );
};
