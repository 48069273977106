import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { Text } from '@components/common';
import { StudyInfo } from '@components/StudiesApp/components/StudyDraft/pages/Assets/PreviewSchedulingPage/components/StudyInfo';

interface Props {
  landing_page: LandingPage;
  study: Study;
}

export const PreviewSchedulingPage: React.FC<React.PropsWithChildren<Props>> = ({ landing_page, study }) => {
  const { t } = useTranslation('PreviewSchedulingPage');

  const title = landing_page.booking_page_title || t(study.style || 'default');

  return (
    <div className='brand'>
      <div className='text-center'>
        <Text h='600' className='pb-4'>
          {title}
        </Text>
      </div>
      <div className='flex divide-x divide-gray-200 border border-gray-200'>
        <StudyInfo study={study} landing_page={landing_page} />
        <div className='flex-3 p-6'>
          <div className='flex items-center justify-center'>
            <Text className='p-20' color='gray-500' h='400'>
              {t('booking_widget')}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
