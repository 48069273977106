import * as React from 'react';
import { useState } from 'react';

import { Button, Modal, ModalHeading, Text } from '@components/common';
import { TrashSVG } from '@components/svgs';
import { useToaster } from '@stores/toaster';
import { api } from 'api/reduxApi';

import * as toasts from '../toasts';

interface Props {
  formId: number;
  loading: boolean;
  onDelete: () => void;
}
export const DeleteFormButton: React.FC<React.PropsWithChildren<Props>> = ({ formId, loading, onDelete }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const showToast = useToaster();

  const [deleteConsentForms] = api.useDeleteConsentFormMutation();

  const submit = async () => {
    try {
      await deleteConsentForms(formId).unwrap();
      showToast(toasts.successDelete());
    } catch (_) {
      showToast(toasts.failedDelete());
    }
  };

  return (
    <>
      {modalOpen && (
        <Modal
          icon='danger'
          onClose={() => setModalOpen(false)}
          renderFooter={() => (
            <div className='flex w-full flex-row justify-end space-x-4'>
              <Button onClick={() => setModalOpen(false)}>Cancel</Button>
              <Button disabled={loading} danger onClick={submit}>
                Delete
              </Button>
            </div>
          )}
        >
          <ModalHeading>Delete consent form?</ModalHeading>
          <Text h='400' className='mt-2'>
            Any studies currently using this consent form will revert back to the Great Question default form.
          </Text>
        </Modal>
      )}
      <button
        className='flex h-8 w-8 items-center justify-center rounded-full border border-gray-200 text-gray-500 hover:bg-gray-100'
        name='delete'
        disabled={loading}
        onClick={() => setModalOpen(true)}
      >
        <TrashSVG />
      </button>
    </>
  );
};
