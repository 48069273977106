import * as React from 'react';
import { useRef } from 'react';

import cn from 'classnames';
import { useSelect } from 'downshift';
import maxSize from 'popper-max-size-modifier';
import { Modifier, usePopper } from 'react-popper';
import * as PopperJS from '@popperjs/core';

import { Text } from '@components/common';
import { ChevronDownSVG } from '@components/svgs';
import { compact } from '@components/utils';

export interface SelectOption<T extends string = string, U = any> {
  label: string;
  value: T;
  disabled?: boolean;
  data?: U;
  Icon?: React.FC<React.PropsWithChildren<{ className?: string }>>;
}

export interface SelectProps<T extends string = string, U = any> {
  id?: string;
  disabled?: boolean;
  error?: boolean;
  value: string;
  noBorder?: boolean;
  placeholder?: string;
  syncWidths?: boolean; // whether to snap the width of the dropdown menu to the size of the button
  ulClassName?: string;
  renderLabel?: (item: SelectOption<T>, highlighted?: boolean) => React.ReactNode;
  renderAriaLabel?: (item: SelectOption<T>) => React.ReactNode;
  renderSelected?: (item: SelectOption<T, U>) => React.ReactNode;
  className?: string;
  overflowClass?: string;
  caretClass?: string;
  prefix?: React.ReactNode;
  options: SelectOption<T, U>[];
  onChange?: (value: T) => void;
  onMouseDown?: () => void;
  register?: any;
  name?: string;
  style?: React.CSSProperties;
  maxH?: number | string;
  popperOptions?: Omit<Partial<PopperJS.Options>, 'modifiers'>;
  h?: number | string;
  wrapperClass?: string;
  dataTestId?: string;
}

export const IconLabel: React.FC<
  React.PropsWithChildren<{ disabled?: boolean; option: SelectOption; highlighted: boolean | undefined }>
> = ({ disabled, option, highlighted }) => (
  <div className='py-4'>
    <div className='flex items-center space-x-2'>
      {option.data?.icon}
      <Text h='400' bold as='span' color={disabled ? 'gray-500' : undefined} className='ml-3.5'>
        {option.label}
      </Text>
    </div>

    <div className={cn('mt-2', highlighted ? 'text-white' : 'text-gray-500')}>{option.data?.description}</div>
  </div>
);

const applyMaxSize = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['maxSize'],
  fn({ state }) {
    const { height } = state.modifiersData.maxSize;
    state.styles.popper.maxHeight = `${height - 20}px`;
  }
};

const DEFAULT_POPPER_OPTIONS: Omit<Partial<PopperJS.Options>, 'modifiers'> = {
  placement: 'bottom'
};

const Select = <T extends string = string, U = Record<string, any>>(props: SelectProps<T, U>): React.ReactElement => {
  const {
    options,
    disabled,
    error,
    value,
    noBorder,
    placeholder = 'Choose an option…',
    syncWidths = true,
    ulClassName = '',
    className: initialClassName = '',
    overflowClass = 'y-auto',
    renderLabel,
    renderAriaLabel,
    renderSelected,
    caretClass = '',
    prefix,
    onChange,
    onMouseDown,
    register,
    name,
    style,
    maxH,
    popperOptions = DEFAULT_POPPER_OPTIONS,
    h = 10,
    wrapperClass,
    dataTestId,
    ...rest
  } = props;
  const selectedItem = options.find((o) => o.value === value);

  const referenceRef = useRef<HTMLDivElement>(null);
  const floatingRef = useRef<HTMLUListElement>(null);

  const { styles, attributes, forceUpdate } = usePopper(referenceRef.current, floatingRef.current, {
    ...popperOptions,
    modifiers: !maxH ? [maxSize, applyMaxSize as Modifier<any>] : undefined
  });

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: options,
    selectedItem,
    onSelectedItemChange: (changes) => {
      requestAnimationFrame(() => {
        onChange?.(changes.selectedItem?.value as any);
      });
    }
  });

  const className = cn(
    initialClassName,
    `h-${h}`,
    'flex items-center text-left text-sm rounded-md w-full truncate py-2.5 px-4',
    error ? 'focus:ring-red-600 focus:border-red-600' : 'focus:ring focus:outline-none',
    {
      ' text-gray-700 hover:bg-gray-50': !disabled,
      ' border-red-600': error,
      ' border border-gray-200': !noBorder,
      ' items-center': prefix,
      ' border-gray-200 text-gray-500 bg-gray-50': disabled
    }
  );

  React.useEffect(() => {
    if (isOpen && forceUpdate) {
      requestAnimationFrame(forceUpdate);
    }
  }, [isOpen]);

  return (
    <div className={cn('relative', wrapperClass)} {...rest}>
      <button
        aria-label={name}
        type='button'
        disabled={disabled}
        className={className}
        style={style}
        data-testid={dataTestId || 'select-button'}
        {...getToggleButtonProps({
          className,
          ref: register ? register : referenceRef,
          name: name ? name : ''
        })}
        onMouseDown={onMouseDown}
      >
        {selectedItem?.Icon && <selectedItem.Icon className='mr-2' />}
        {prefix && <span className='mr-2 flex-initial'>{prefix}</span>}
        <span className='mr-2 flex-1 truncate leading-snug'>
          {selectedItem ? (renderSelected ? renderSelected(selectedItem) : selectedItem.label) : placeholder}
        </span>
        <ChevronDownSVG className={cn('flex-initial', { 'text-gray-400': disabled }, caretClass)} />
      </button>
      <ul
        {...getMenuProps({
          ref: floatingRef,
          className: compact([
            'mt-2 shadow-lg z-40 bg-white border border-gray-200 rounded-b-md focus:outline-none xx-select-options',
            maxH && `max-h-${maxH}`,
            `overflow-${overflowClass}`,
            ulClassName,
            syncWidths && 'w-full',
            !isOpen && 'invisible'
          ]).join(' ')
        })}
        data-testid={dataTestId ? `${dataTestId}-options` : 'select-options'}
        {...attributes.popper}
        style={{ ...(syncWidths && { width: referenceRef.current?.clientWidth }), ...styles.popper }}
      >
        {isOpen &&
          options.map((item, index) => (
            <li
              data-testid={dataTestId ? `${dataTestId}-option-${index}` : 'tw-ui-dropdown-link'}
              className={compact([
                'tw-ui-dropdown-link',
                'group flex w-full cursor-pointer items-center px-4',
                item.disabled && 'cursor-not-allowed opacity-50',
                !item.disabled && 'hover:bg-indigo-600 hover:text-white',
                !item.disabled && highlightedIndex === index && 'bg-indigo-600 text-white',
                highlightedIndex !== index && 'text-gray-700',
                index === options.length - 1 && 'rounded-b-md'
              ]).join(' ')}
              aria-label={renderAriaLabel ? renderAriaLabel(item) : item.label}
              key={`${item}${index}`}
              {...getItemProps({ item, index, disabled: item.disabled })}
            >
              {item.Icon && !renderLabel && <item.Icon className='mr-2' />}
              {renderLabel ? renderLabel(item, highlightedIndex === index) : item.label}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Select;
