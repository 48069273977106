import React, { useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import { api } from '@api/reduxApi';
import { SlideOut } from '@components/common';
import { compact, uniqBy } from '@components/utils';
import { track } from 'components/tracking';

import { History, Questions, SearchInput, Tabs, Templates } from './components';

export type Tab = 'Templates' | 'Questions' | 'History';

interface Props {
  screener: Screener;
  isOpen: boolean;
  addedQuestions: ScreenerField[];
  trackProps: Record<string, any>;
  onClose: () => void;
  addBankQuestion: (question: ScreenerField) => void;
  setAddedQuestions: (questions: ScreenerField[]) => void;
  onSave?: (v: Study) => void;
}

export const QuestionBank: React.FC<React.PropsWithChildren<Props>> = ({
  screener,
  isOpen,
  addedQuestions,
  trackProps,
  addBankQuestion,
  setAddedQuestions,
  onClose,
  onSave
}) => {
  const { data: questions } = api.useGetQuestionTemplatesQuery();
  const { data: history } = api.useGetQuestionHistoryQuery();
  const { data: templates } = api.useGetSurveyTemplatesQuery();

  const tabs: Tab[] = compact(['Templates', 'Questions', 'History']);

  const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);
  const [searchValue, setSearchValue] = useState('');
  const [activeTemplate, setActiveTemplate] = useState<number>();

  useEffect(() => {
    if (isOpen) {
      track('viewed_question_bank', trackProps);
    }
  }, [isOpen]);

  const handleAddQuestion = (question: ScreenerField) => {
    track('added_question', {
      ...trackProps,
      source: activeTab.toLowerCase(),
      field_type: question.field_type
    });
    addBankQuestion(question);
    setAddedQuestions([...addedQuestions, question]);
  };

  function handleSearch(value: string) {
    setSearchValue(value);
  }

  const filterQuestions = useCallback(
    ({ label, candidate_attr }: any) => {
      return [label.toLowerCase(), (candidate_attr || '').toLowerCase()].join(' ').includes(searchValue.toLowerCase());
    },
    [searchValue]
  );

  const filterTemplates = useCallback(
    ({ title }: any) => {
      return title.toLowerCase().includes(searchValue.toLowerCase());
    },
    [searchValue]
  );

  const uniqueHistory = useMemo(() => {
    return uniqBy<ScreenerField>(
      history || [],
      ({ label, helper, candidate_attr, field_type, options, other, required }) => {
        return [label, helper, candidate_attr, field_type, (options || []).join(''), other, required].join('');
      }
    );
  }, [history]);

  const filteredQuestions = (questions || []).filter(filterQuestions);

  const filteredHistory = uniqueHistory.filter(filterQuestions);

  const filteredTemplates = templates?.filter(filterTemplates);

  const tabButtonClass = (tab: Tab) =>
    classNames({
      'ml-4 pb-2 font-bold text-gray-400 focus:outline-none': true,
      'text-indigo-600 border-b-2 border-indigo-600': tab === activeTab
    });

  if (!isOpen) return null;

  return (
    <SlideOut nonBlocking onClose={onClose} zIndex='50' title='Library'>
      <SearchInput onChange={handleSearch} value={searchValue} />

      <Tabs tabs={tabs} onClick={setActiveTab} getClassName={tabButtonClass} />

      {activeTab === 'Templates' && (
        <Templates
          screener={screener}
          templates={filteredTemplates}
          activeTemplate={activeTemplate}
          setActiveTemplate={setActiveTemplate}
          onClose={onClose}
          onSave={onSave}
          resetBankQuestionsState={() => setAddedQuestions([])}
        />
      )}

      {activeTab === 'Questions' && questions && (
        <Questions
          addedQuestions={addedQuestions}
          handleAddQuestion={handleAddQuestion}
          questions={filteredQuestions}
        />
      )}

      {activeTab === 'History' && history && (
        <History history={filteredHistory} handleAddQuestion={handleAddQuestion} />
      )}
    </SlideOut>
  );
};
