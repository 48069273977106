import React, { memo } from 'react';

import cn from 'classnames';

import { CtaProps } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip/helpers';
import { getScrollableParent } from '@helpers/getScrollableParent';

import * as Queries from './helpers/queries';
import { BubbleMenu, HighlightMenu, LinkMenu, ScrollToTop, UpdateSpeakerModal } from './components';
import { TranscriptAttributes } from './extensions';
import { TiptapContext } from './hooks';
import { BubbleMenuPluginParams } from './plugins';
import { Props, TiptapHook } from './useTiptap';

export type ModalIds = 'link' | 'image' | 'embed' | 'speakers' | 'templates' | 'import_document';

export type TiptapProps = Pick<TiptapHook, 'editor'> &
  Pick<Props, 'documentId' | 'studyId' | 'config' | 'onReady' | 'scrollToTop' | 'sessionUuid' | 'recordingId'> & {
    data?: ApiDocument;
    speaker: Partial<TranscriptAttributes['speaker']> | null;
    activeModal: ModalIds;
    setActiveModal: (modal: ModalIds) => void;
    closeModal: () => void;
    editorClass?: string;
    slideoutView?: boolean;
    highlightReelToken?: string;
    artifactIds?: string[];
    ctaProps?: CtaProps;
  };

export const TiptapComponent: React.FC<React.PropsWithChildren<TiptapProps>> = ({
  editor,
  documentId,
  studyId,
  config = {},
  speaker,
  activeModal,
  setActiveModal,
  closeModal,
  scrollToTop,
  sessionUuid,
  children,
  editorClass,
  slideoutView,
  highlightReelToken,
  artifactIds,
  ctaProps,
  recordingId
}) => {
  const sel = editor?.state?.selection;
  const rangeKey = sel && [sel.from, sel.to].join('-');

  const shouldShowHighlighMenu: BubbleMenuPluginParams['shouldShow'] = () => {
    if (!editor) return false;

    const { from, to } = editor.state.selection;
    const range = Queries.getActiveHighlightRange(editor.state);

    if (editor.isActive('highlight')) {
      if (editor.isActive('image')) {
        return true;
      }

      if (editor.state.selection.empty) {
        if (range?.from === editor.state.selection.from) {
          return false;
        }
        return true;
      }

      return range?.from === from && range?.to === to;
    }

    if (range?.from === from && range?.to === to) {
      return false;
    }

    return (
      !editor.state.selection.empty &&
      !editor.isActive('artifacts') &&
      !editor.isActive('merge_tag') &&
      !editor.isActive('cta') &&
      !editor.isActive('image') &&
      !editor.isActive('columns') &&
      !editor.isActive('table')
    );
  };

  const shouldShowLinkMenu: BubbleMenuPluginParams['shouldShow'] = () => {
    if (!editor) return false;

    return editor.isActive('link');
  };

  if (!editor) return null;

  return (
    <TiptapContext.Provider
      value={{
        documentId,
        studyId,
        sessionUuid,
        slideoutView,
        highlightReelToken,
        artifactIds,
        ctaProps,
        recordingId
      }}
    >
      <div id='gq-editor' className={cn('relative', editorClass)}>
        {config.highlight?.enable && !config.highlight?.readOnly && (
          <BubbleMenu
            editor={editor}
            pluginKey='highlightMenu'
            popperOptions={{ placement: config.highlight.menuPlacement ?? 'top' }}
            shouldShow={shouldShowHighlighMenu}
          >
            {({ hide, isVisible }) =>
              documentId && (
                <HighlightMenu
                  isVisible={isVisible}
                  hide={hide}
                  editor={editor}
                  documentId={documentId}
                  studyId={studyId}
                  className='max-w-full overflow-hidden'
                />
              )
            }
          </BubbleMenu>
        )}
        {config.link?.enable && (
          <BubbleMenu
            editor={editor}
            pluginKey='linkMenu'
            popperOptions={{ placement: config.link.menuPlacement ?? 'bottom' }}
            shouldShow={shouldShowLinkMenu}
            hideOnEsc
          >
            {({ isVisible }) => (
              <LinkMenu editor={editor} isVisible={isVisible} openModal={() => setActiveModal('link')} />
            )}
          </BubbleMenu>
        )}

        {children}
      </div>

      <UpdateSpeakerModal editor={editor} speaker={speaker} isOpen={activeModal === 'speakers'} onClose={closeModal} />

      {scrollToTop && (
        <ScrollToTop data-testid='scroll-to-top' onClick={() => getScrollableParent(editor.view.dom)?.scrollTo(0, 0)} />
      )}
    </TiptapContext.Provider>
  );
};

export const Tiptap = memo(TiptapComponent);
