import React, { createContext } from 'react';

import { Drawer, useDrawer } from '../hooks/useDrawer';

export const DrawerContext = createContext<Drawer | undefined>(undefined);

export const DrawerProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const drawer = useDrawer();

  return <DrawerContext.Provider value={drawer}>{children}</DrawerContext.Provider>;
};

DrawerContext.displayName = 'DrawerContext';
