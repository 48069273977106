import React from 'react';

import { Button, Loading, Modal, Text } from '@components/common';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

export const ConfirmationModal: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  onConfirm,
  isLoading
}) => {
  if (!open) return null;

  return (
    <Modal
      title='Confirm changes'
      onClose={onClose}
      renderFooter={() => (
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button primary onClick={onConfirm} disabled={isLoading}>
            Confirm
          </Button>
        </>
      )}
    >
      {isLoading && <Loading absolute bg='white' />}
      <Text h='400' className='mb-6'>
        These branding changes will update all existing participant-facing emails, webpages, surveys and screeners.
        Please confirm & update to continue with these changes.
      </Text>
    </Modal>
  );
};
