import React from 'react';

import { Button } from '@components/common';
import { SegmentsZdsSvg } from '@components/SegmentsApp/components/SegmentsZdsSvg';
import { RestrictedAction } from '@components/shared/RestrictedAction';

export const SegmentsZDS = ({ onClick }) => (
  <div className='mt-6 flex w-full flex-grow flex-col items-center justify-center border-t border-gray-200 bg-white pt-6'>
    <SegmentsZdsSvg />
    <div className='mt-6 text-lg font-bold'>Create a segment</div>
    <div className='color-gray-500 mt-2 max-w-lg text-center'>
      Set selected filters and save them as a segment that you will be able to manage here.
    </div>
    <RestrictedAction permission='manageSegments'>
      {({ can }) => (
        <Button disabled={!can} className='my-8 text-sm' primary icon='plus' onClick={onClick}>
          Create a new segment
        </Button>
      )}
    </RestrictedAction>
  </div>
);
