import * as React from 'react';

import { useParams } from 'react-router-dom';

import { StudiesIndex } from '../StudiesIndex';

export const TeamStudiesIndex: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { teamId: teamIdStr } = useParams<{ teamId: string }>();
  const teamId = teamIdStr ? parseInt(teamIdStr) : null;

  return <StudiesIndex teamId={teamId} />;
};
