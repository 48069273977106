import React from 'react';

import { Alert, AlertHeading, AlertLink, AlertMessage } from '@components/common';
import { SERVERSIDE_PARTY_TABLE_INTERCOM_SURVEY_ID } from 'components/StudiesApp/constants';

import { AlertProps } from '../types';

export const ParticipantsTableFeedback: React.FC<React.PropsWithChildren<AlertProps>> = () => {
  const startIntercomSurvey = () => {
    if (window.Intercom) {
      window.Intercom('startSurvey', SERVERSIDE_PARTY_TABLE_INTERCOM_SURVEY_ID);
    } else {
      console.warn('[Intercom] Failed to start survey: Intercom is not available.');
    }
  };

  return (
    <Alert className='my-4' type='promo' dismissKey='participants-table-alert'>
      <AlertHeading>We’ve rebuilt the Participants table to improve performance and reliability.</AlertHeading>
      <AlertMessage>
        The updates should result in better performance for large studies and fewer crashes.{' '}
        <AlertLink href='#' handleClick={startIntercomSurvey} className='underline'>
          Please let us know
        </AlertLink>{' '}
        if you have any feedback!
      </AlertMessage>
    </Alert>
  );
};
