import React from 'react';

import Tippy from '@tippyjs/react';

import { PlusSVG } from '@components/svgs';

interface Props {
  toggleColumnDropdown: () => void;
}

export const AddColumnButton: React.FC<React.PropsWithChildren<Props>> = ({ toggleColumnDropdown }) => {
  return (
    <Tippy content='Add column'>
      <button
        name='add column'
        data-testid='add column'
        aria-label='Add column'
        type='button'
        className='flex h-10 w-10 items-center justify-center rounded-full text-gray-500 hover:bg-gray-100'
        onClick={toggleColumnDropdown}
      >
        <PlusSVG className='text-indigo-600 hover:text-indigo-700' />
      </button>
    </Tippy>
  );
};
