import React, { useEffect } from 'react';

import { Consumer, Mixin, Subscription } from '@rails/actioncable';

import consumer from '@helpers/consumer';

export const AiChatsChannel = 'Ai::ChatsChannel';
export const BackgroundTasksChannel = 'BackgroundTasksChannel';
export const TranscriptsChannel = 'TranscriptsChannel';
export const DocumentsChannel = 'DocumentsChannel';
export const MessagingRequestsChannel = 'Messaging::RequestsChannel';
export const UsersCalendarsChannel = 'Users::CalendarsChannel';
export const UsersConnectedAccountsChannel = 'Users::ConnectedAccountsChannel';
export const UsersConnectedAccountCreatedChannel = 'Users::ConnectedAccountCreatedChannel';
export const UserTeamsChannel = 'Users::TeamsChannel';
export const ConnectedAccountSyncChannel = 'ConnectedAccountSyncChannel';
export const RepoLiveStreamsChannel = 'Repo::Sessions::LiveStreamsChannel';
export const PrototypeTestsChannel = 'PrototypeTestsChannel';

export type ChannelName =
  | typeof AiChatsChannel
  | typeof BackgroundTasksChannel
  | typeof TranscriptsChannel
  | typeof DocumentsChannel
  | typeof MessagingRequestsChannel
  | typeof UsersCalendarsChannel
  | typeof UsersConnectedAccountsChannel
  | typeof UsersConnectedAccountCreatedChannel
  | typeof UserTeamsChannel
  | typeof ConnectedAccountSyncChannel
  | typeof RepoLiveStreamsChannel
  | typeof PrototypeTestsChannel;

type Args<Params, Return> = {
  channel: ChannelName;
  params: Params;
  skip?: boolean;
  onReceived?: (returnValue: Return) => void;
};

export const useWebsocket = <Params = { id: number }, Return = any>({
  channel,
  onReceived,
  skip = false,
  params
}: Args<Params, Return>) => {
  const ref = React.useRef<(Subscription<Consumer> & Mixin) | null>(null);

  useEffect(() => {
    if (ref.current) return;

    ref.current = consumer.subscriptions.create(
      { channel: channel, ...params },
      {
        received: ({ message }: { message: Return }) => {
          if (!skip) {
            onReceived?.(message);
          }
        }
      }
    );

    return () => {
      if (ref.current) {
        ref.current.unsubscribe();
        ref.current = null;
      }
    };
  }, [channel, onReceived, skip, params]);
};
