import * as React from 'react';

import { UseFormMethods } from 'react-hook-form';

// TODO: do we need aa default value

interface Props {
  register: UseFormMethods['register'];
  name: string;
  id?: string;
  label: string;
  helper?: string;
  value?: string;
  className?: string;
}
const Checkbox: React.FC<React.PropsWithChildren<Props>> = ({
  register,
  name,
  id,
  label,
  helper,
  value = 'foo',
  className = ''
}) => (
  <div className={'relative flex items-start ' + className}>
    <div className='flex h-5 items-center'>
      <input
        id={id}
        name={name}
        aria-label={name}
        type='checkbox'
        ref={register}
        className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
        value={value}
      />
    </div>
    <div className='ml-3 text-sm'>
      <label htmlFor={id} className='font-medium text-gray-700'>
        {label}
      </label>
      {helper && <p className='text-gray-500'>{helper}</p>}
    </div>
  </div>
);
export default Checkbox;
