import * as React from 'react';

import cn from 'classnames';

import { CheckMark2SVG, FailedBubbleSVG, WarningSVG } from '@components/svgs';

const renderATag: StepperProps['component'] = (props) => <a {...props} />; // eslint-disable-line jsx-a11y/anchor-has-content

export interface StepperProps {
  steps: {
    id: string;
    name: string;
    href: string;
    status: 'complete' | 'error' | 'warning' | 'current' | 'upcoming';
  }[];
  component?: React.FC<React.PropsWithChildren<{ href: string; className: string }>>;
}

export const Stepper: React.FC<React.PropsWithChildren<StepperProps>> = ({ steps, component: Link = renderATag }) => (
  <nav className='bg-white' aria-label='Progress'>
    <ol className='tablet:flex tablet:divide-y-0 px-page -ml-6 divide-y divide-gray-300 border-b border-gray-300'>
      {steps.map((step, stepIdx) => (
        <Link
          key={step.name}
          href={step.href}
          className='tablet:flex-1 tablet:flex relative'
          aria-current={stepIdx === 1 ? 'step' : null}
        >
          <li
            key={step.name}
            className={cn({
              'group flex w-full items-center': ['complete', 'warning'].includes(step.status),
              'flex items-center px-6 py-3 text-sm font-medium': step.status === 'current',
              'group flex items-center': step.status === 'upcoming'
            })}
          >
            <span
              className={cn('flex items-center', {
                'flex items-center px-6 py-3 text-sm font-medium': step.status !== 'current'
              })}
            >
              <span
                className={cn('flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full', {
                  'border-2 border-indigo-600': step.status === 'current',
                  'bg-indigo-600 group-hover:bg-indigo-800': step.status === 'complete',
                  'border-2 border-gray-300 group-hover:border-gray-400': step.status === 'upcoming'
                })}
              >
                {step.status === 'upcoming' && (
                  <span className='h400 text-gray-500 group-hover:text-gray-900'>{step.id}</span>
                )}
                {step.status === 'current' && <span className='h400 text-indigo-600'>{step.id}</span>}
                {step.status === 'complete' && <CheckMark2SVG className='h-4 w-4 text-white' aria-hidden='true' />}
                {step.status === 'error' && <FailedBubbleSVG />}
                {step.status === 'warning' && <WarningSVG className='h-4 w-4 text-yellow-600' />}
              </span>
              <span
                className={cn('ml-4 text-sm font-medium text-gray-900', {
                  'text-gray-500 group-hover:text-gray-900': ['upcoming'].includes(step.status)
                })}
              >
                {step.name}
              </span>
            </span>
          </li>

          {stepIdx !== steps.length - 1 ? (
            <>
              {/* Arrow separator for lg screens and up */}
              <div className='tablet:block absolute right-0 top-0 hidden h-full w-5' aria-hidden='true'>
                <svg className='h-full w-full text-gray-300' viewBox='0 0 22 80' fill='none' preserveAspectRatio='none'>
                  <path
                    d='M0 -2L20 40L0 82'
                    vectorEffect='non-scaling-stroke'
                    stroke='currentcolor'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </>
          ) : null}
        </Link>
      ))}
    </ol>
  </nav>
);
