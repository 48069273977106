import { buildDocument, buildHighlight } from '@api/builders';
import { api } from '@api/reduxApi';

interface DocumentImage {
  id: number;
  url: string;
}

interface SignedUpload {
  signedId: string;
}

const documentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDocument: builder.query<ApiDocument, number>({
      query: (documentId) => `/documents/${documentId}`,
      transformResponse: (resp: any) => resp.data.attributes as ApiDocument,
      providesTags: (_, __, id) => [{ type: 'Document', id }]
    }),
    getGuideDocument: builder.query<ApiDocument, string>({
      query: (token) => `/guides/${token}/document`,
      transformResponse: (resp: any) => resp.data.attributes as ApiDocument,
      providesTags: (_, __, id) => [{ type: 'Document', id }]
    }),
    createDocument: builder.mutation<ApiDocument, void>({
      query: () => ({
        url: `/documents`,
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildDocument(resp.data)
    }),
    updateDocument: builder.mutation<void, Pick<ApiDocument, 'id' | 'doc'>>({
      query: ({ id, doc }) => ({
        url: `/documents/${id}`,
        method: 'PUT',
        body: { document: { doc } }
      })
    }),
    createDocumentImage: builder.mutation<DocumentImage, SignedUpload & { documentId: ApiDocument['id'] }>({
      query: ({ documentId, signedId }) => ({
        url: `/documents/${documentId}/images`,
        method: 'POST',
        body: { image: { signed_id: signedId } }
      })
    })
  })
});

export const highlightApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentHighlight: builder.query<DocumentHighlight, { documentId: number; highlightId: number }>({
      query: ({ documentId, highlightId }) => `/documents/${documentId}/highlights/${highlightId}`,
      transformResponse: (resp: any) => buildHighlight(resp.data),
      providesTags: (_, __, { highlightId }) => [{ type: 'Highlight', id: highlightId }]
    }),
    createDocumentHighlight: builder.mutation<
      DocumentHighlight,
      { documentId: number; text: string; tag_ids?: number[]; content: HighlightContentElement[] }
    >({
      query: ({ documentId, text, tag_ids, content }) => ({
        url: `/documents/${documentId}/highlights`,
        method: 'POST',
        body: { highlight: { text, tag_ids, content } }
      }),
      transformResponse: (resp: any) => buildHighlight(resp.data),
      invalidatesTags: () => [{ type: 'Highlight', id: 'LIST' }]
    }),
    updateDocumentHighlight: builder.mutation<
      DocumentHighlight,
      { id: number; document_id: number } & Partial<DocumentHighlight>
    >({
      query: ({ document_id: documentId, id: highlightId, ...rest }) => ({
        url: `/documents/${documentId}/highlights/${highlightId}`,
        method: 'PUT',
        body: { highlight: { ...rest } }
      }),
      transformResponse: (resp: any) => buildHighlight(resp.data),
      invalidatesTags: (_, __, { id }) => ['Tag', 'Recording', { type: 'Highlight', id }]
    }),
    destroyDocumentHighlight: builder.mutation<void, { documentId: number; highlightId: number; sessionUuid?: string }>(
      {
        query: ({ documentId, highlightId }) => ({
          url: `/documents/${documentId}/highlights/${highlightId}`,
          method: 'DELETE'
        }),
        invalidatesTags: (_, __, { highlightId, sessionUuid }) => [
          'Tag',
          { type: 'Highlight', id: highlightId },
          { type: 'StudyHighlights', id: 'LIST' },
          { type: 'Highlight', id: 'LIST' },
          { type: 'RepoSession', uuid: sessionUuid },
          'Recording'
        ]
      }
    ),
    bulkUpdateHighlight: builder.mutation<
      void,
      { documentId: number; highlights: Pick<DocumentHighlight, 'id' | 'text'>[] }
    >({
      query: ({ documentId, highlights }) => ({
        url: `/documents/${documentId}/bulk/highlights`,
        method: 'PUT',
        body: { highlights }
      })
    }),
    updateTiptapClip: builder.mutation<Clip, { highlightUuid: string; clip: Partial<Clip> }>({
      query: ({ highlightUuid, clip }) => ({
        url: `/clips/${highlightUuid}`,
        method: 'PUT',
        body: { clip }
      }),
      invalidatesTags: (_, __, { clip: { highlight_id, session_uuid } }) => {
        const tags: any[] = [];

        if (highlight_id) {
          tags.push({ type: 'Highlight', id: highlight_id });
        }

        if (session_uuid) {
          tags.push({ type: 'RepoSession', uuid: session_uuid });
        }

        return tags;
      }
    })
  })
});

const transcriptsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resetTranscript: builder.mutation<Transcript, Transcript>({
      query: ({ id }) => ({
        url: `/transcripts/${id}/reset`,
        method: 'POST'
      }),
      invalidatesTags: (_, __, { document_id }) => [{ type: 'Document', document_id }, 'Recording']
    })
  })
});

const documentAssetsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createDocumentAsset: builder.mutation<any, any>({
      query: ({ documentId, signedId }) => ({
        url: `/documents/${documentId}/assets`,
        method: 'POST',
        body: { repo_asset: { signed_id: signedId } }
      })
    })
  })
});

export const {
  useGetDocumentQuery,
  useGetGuideDocumentQuery,
  useUpdateDocumentMutation,
  useCreateDocumentImageMutation,
  useCreateDocumentMutation,
  util: queryUtil
} = documentApi;
export const {
  useCreateDocumentHighlightMutation,
  useDestroyDocumentHighlightMutation,
  useGetDocumentHighlightQuery,
  useLazyGetDocumentHighlightQuery,
  useUpdateDocumentHighlightMutation,
  useBulkUpdateHighlightMutation,
  useUpdateTiptapClipMutation
} = highlightApi;
export const { useResetTranscriptMutation } = transcriptsApi;
export const {
  useSearchCandidatesQuery,
  useGetInterviewTemplatesQuery,
  useGetRecordingQuery,
  useCreateHighlightWithClipMutation,
  useCreateMuxPlaybackUrlsMutation,
  useDestroyRecordingMutation,
  useGetArtifactsQuery,
  useGetRepoSessionQuery
} = api;
export const { useCreateDocumentAssetMutation } = documentAssetsApi;
