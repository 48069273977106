import React, { forwardRef, HTMLAttributes, useEffect, useLayoutEffect, useRef } from 'react';

import cn from 'classnames';
import mergeRefs from 'react-merge-refs';

import { useMutationObserver } from '@hooks/useMutationObserver';

import { useDrawerContext } from '../hooks/useDrawerContext';

export const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...rest }, ref) => {
    const innerRef = useRef<HTMLDivElement>(null);

    const {
      state: { isExpanded },
      setContentHeight
    } = useDrawerContext();

    useLayoutEffect(() => {
      if (innerRef.current) {
        setContentHeight(innerRef.current.offsetHeight);
      }
    }, []);

    useEffect(() => {
      if (innerRef.current) {
        setContentHeight(innerRef.current.offsetHeight);
      }
    }, [children]);

    useMutationObserver(
      innerRef,
      (mutations) => {
        for (const mutation of mutations) {
          if (mutation.type === 'childList' && innerRef.current) {
            setContentHeight(innerRef.current.offsetHeight);
          }
        }
      },
      { subtree: true, childList: true }
    );

    return (
      <div
        ref={mergeRefs([innerRef, ref])}
        className={cn('bg-white px-4 pb-4', className)}
        aria-hidden={!isExpanded}
        {...rest}
      >
        {children}
      </div>
    );
  }
);
