import React, { FC, HTMLAttributes, MouseEvent } from 'react';

import cn from 'classnames';

import { Tooltip } from '@components/Tooltip';

import { useMenuContext } from '../hooks/useMenuContext';

export interface Props extends HTMLAttributes<HTMLLIElement> {
  value?: string;
  isDisabled?: boolean;
  tooltip?: string;
}

export const Item: FC<React.PropsWithChildren<Props>> = ({
  tooltip,
  value,
  isDisabled = false,
  children,
  onClick,
  onKeyDown,
  className,
  ...rest
}) => {
  const { setSelectedItem, closeMenu } = useMenuContext();

  const handleOnClick = (event: MouseEvent<HTMLLIElement>) => {
    if (!isDisabled && value) {
      setSelectedItem(value);
      closeMenu();
      onClick?.(event);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (!isDisabled && value) {
      if (event.key === 'Enter') {
        setSelectedItem(value);
        closeMenu();
      }

      onKeyDown?.(event);
    }
  };

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-noninteractive-element-to-interactive-role*/
  return (
    <li
      role='button'
      className={cn('cursor-pointer list-none', className)}
      onClick={handleOnClick}
      onKeyDown={handleKeyDown}
      {...rest}
    >
      <Tooltip className='w-full' content={tooltip} isDisabled={!tooltip}>
        {children}
      </Tooltip>
    </li>
  );
};
