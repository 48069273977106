import * as React from 'react';
import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { Column } from '@components/shared/ColumnBoard';
import { usePermission } from '@hooks/usePermission';
import { useToaster } from '@stores/toaster';

import * as toasts from '../../toasts';

import { AddNewTagButton } from './AddNewTagButton';
import { DeleteTagGroupModal } from './DeleteTagGroupModal';
import { DeleteTagModal } from './DeleteTagModal';
import { Tag } from './Tag';

type Props = {
  name?: string;
  description?: string;
  tagGroupId?: number;
  tagGroupColor?: string;
  studyId?: number;
  color?: Tag['color'];
  ungrouped?: boolean;
  tags?: Tag[];
  readOnly?: boolean;
  addTagToTags: (tag: Tag) => void;
  selectedTags: Tag[];
  setSelectedTags: React.Dispatch<React.SetStateAction<Tag[]>>;
  draggingTagId: number | null;
};

// test comment to force use task to generate tests for this file

export const GroupColumn: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  description,
  tagGroupId,
  tagGroupColor = '',
  studyId,
  color = 'default',
  ungrouped,
  tags,
  readOnly = false,
  addTagToTags,
  selectedTags,
  setSelectedTags,
  draggingTagId
}) => {
  const [tagToDelete, setTagToDelete] = useState<Tag | null>(null);

  const [updateTagGroup, { isLoading: isUpdatingTagGroup }] = api.useUpdateTagGroupMutation();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const canDelete = usePermission('canDeleteTagGroup')();
  const [destroyTagGroup, { isLoading, isSuccess: isSuccessDelete }] = api.useDestroyTagGroupMutation();
  const showToast = useToaster();

  const handleChange = (tag: Tag) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      setSelectedTags(selectedTags.filter((t) => t.id !== tag.id));
    }
  };

  const onSelectAll = () => {
    const tagsToSelect = (tags || []).filter((t) => !selectedTags.includes(t));
    setSelectedTags([...selectedTags, ...tagsToSelect]);
  };

  const onUnselectAll = () => {
    setSelectedTags(selectedTags.filter((t) => t.tag_group_id != tagGroupId));
  };

  const onClickDelete = (closeDropdown: () => void) => {
    if (!tagGroupId) {
      return;
    }
    return () => {
      if (tags ? tags.length === 0 : false) {
        destroyTagGroup(tagGroupId);
      } else {
        setDeleteModalOpen(true);
      }
      closeDropdown();
    };
  };

  useEffect(() => {
    if (isSuccessDelete) {
      showToast(toasts.successDeleteTagGroup());
    }
  }, [isSuccessDelete]);

  return (
    <Column
      id={tagGroupId}
      name={name}
      description={description}
      color={color}
      ungrouped={ungrouped}
      isUpdating={isUpdatingTagGroup}
      readOnly={readOnly}
      onChangeTitle={(value) => {
        if (value !== name) {
          updateTagGroup({ id: tagGroupId as any, name: value });
        }
      }}
      onChangeDescription={(value) => {
        if (value !== name) {
          updateTagGroup({ id: tagGroupId as any, description: value });
        }
      }}
      onChangeColor={(color) => {
        if (!tagGroupId) return;
        updateTagGroup({ id: tagGroupId, color });
      }}
      ungroupedLabel='Existing tags that haven’t been grouped'
      shouldRenderDroppablePlaceholder={tags && tags.length === 0}
      dropdownOptions={({ closeDropdown }) => ({
        onClickSelectAll: onSelectAll,
        onClickDeselectAll: onUnselectAll,
        onClickDelete: onClickDelete(closeDropdown)
      })}
      renderExtraInput={() =>
        readOnly ? null : (
          <AddNewTagButton
            className='mb-2 mt-2'
            tagGroupId={tagGroupId}
            tags={tags}
            addTagToTags={addTagToTags}
            tagGroupColor={tagGroupColor}
            studyId={studyId}
          />
        )
      }
    >
      {tagGroupId && (
        <DeleteTagGroupModal
          tagGroupId={tagGroupId}
          open={deleteModalOpen}
          isLoading={isLoading}
          onClose={() => setDeleteModalOpen(false)}
          destroyTagGroup={destroyTagGroup}
        />
      )}
      {tags &&
        tags.slice(0).map((tag) => {
          const isSelected = selectedTags.some((t) => t.id === tag.id);
          const isGhosting = isSelected && Boolean(draggingTagId) && draggingTagId !== tag.id;

          return (
            <Tag
              key={tag.id}
              id={tag.id}
              studyId={studyId}
              readOnly={readOnly}
              checked={isSelected}
              onChange={() => handleChange(tag)}
              tag={ungrouped ? tag : { ...tag, color: color as any }}
              isGhosting={isGhosting}
              selectedCount={selectedTags.length}
              onDeleteTag={(e) => {
                e.stopPropagation();
                setTagToDelete(tag);
              }}
            />
          );
        })}
      {tagToDelete && (
        <DeleteTagModal
          tags={[tagToDelete]}
          onClose={() => {
            setTagToDelete(null);
          }}
          onSubmit={() => {
            setTagToDelete(null);
          }}
        />
      )}
    </Column>
  );
};
