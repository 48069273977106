interface CsvRow {
  [key: string]: string;
}

const getAnswerValue = (value: any, kind: ScreenerFieldType): string => {
  switch (kind) {
    case 'card_sort':
      if (!value || !Array.isArray(value.results)) return '';

      return value.results
        .map((result: any) => {
          if (!result || typeof result.name !== 'string' || !Array.isArray(result.cards)) return '';
          const cards = result.cards.filter((card: any) => typeof card === 'string');
          return `${result.name} (${cards.join(', ')})`;
        })
        .join(', ');
    case 'tree_test':
      if (!value || typeof value !== 'object' || !value.result || !Array.isArray(value.result.answer_labels)) {
        return '';
      }

      return value.result.answer_labels.join(' > ');
    case 'prototype_test':
      if (typeof value !== 'object' || value.completed === undefined) {
        return 'Failed';
      }

      return value.completed ? 'Success' : 'Failed';

    case 'multiple_choice':
      if (!Array.isArray(value)) return '';

      return value.join(', ');
    default:
      return value;
  }
};

export const buildCsvData = (results: ScreenerResponseResultsIndividual[]): { headers: string[]; rows: CsvRow[] } => {
  if (results.length === 0) {
    return { headers: [], rows: [] };
  }

  const questionMap = new Map<number, string>();

  results.forEach((result) => {
    result.answers_json.forEach((answer) => {
      if (['info', 'start_loom', 'stop_loom', 'task', 'permissions'].includes(answer.field_type)) {
        return;
      }

      if (answer.label && answer.label.trim() !== '') {
        questionMap.set(answer.field_id, answer.label);
      }
    });
  });

  const headers = ['Name', 'Email', ...Array.from(questionMap.values())];

  const rows = results.map((result) => {
    const row: CsvRow = {};

    row['Name'] = result.participation.name || 'Unnamed';
    row['Email'] = result.participation.email as string;

    questionMap.forEach((label) => (row[label] = ''));

    result.answers_json.forEach((answer) => {
      const label = questionMap.get(answer.field_id);

      if (label) {
        row[label] = getAnswerValue(answer.value, answer.field_type);
      }
    });

    return row;
  });

  return { headers, rows };
};
