import * as React from 'react';

import { getClassName } from '@components/common/Dropdown/components/utils';
import { track } from 'components/tracking';

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
  disabled?: boolean;
  color?: string;
  hoverColor?: string;
  trackEvent?: string;
  trackProps?: Record<string, any>;
}

export const PopperDropdownAnchor: React.FC<React.PropsWithChildren<Props>> = ({
  className = '',
  disabled,
  href,
  color = 'gray-700',
  hoverColor = 'indigo-600',
  trackEvent,
  trackProps,
  children,
  ...rest
}) => {
  const anchorProps = {
    tabIndex: 0,
    onClick: (e) => {
      e.stopPropagation();
      if (trackEvent) {
        track(trackEvent, trackProps);
      }
    },
    onKeyDown: (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation();

        if (trackEvent) {
          track(trackEvent, trackProps);
        }
      }
    },
    href: !disabled ? href : undefined,
    className: getClassName({ disabled, color, hoverColor, className }),
    ...rest
  };

  return <a {...anchorProps}>{children}</a>;
};
