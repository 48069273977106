import * as React from 'react';

import { Column, Grid } from '@components/common/Grid';
import { Basic } from '@components/shared/Skeleton';
import { Circle } from '@components/shared/Skeleton/Circle';

export const Skeleton: React.FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <div className='desktop:px-12 tablet:px-6 mobile:px-3 desktop:flex-row flex flex-col justify-between border-b border-gray-200 py-6'>
      <div className='desktop:mb-0 mb-4 mr-4 flex flex-col'>
        <Basic mb='2' width={368} height={40} />

        <Basic width={352} height={16} />
      </div>
      <div className='flex items-center space-x-4 overflow-x-hidden'>
        <Circle width={40} />
        <Basic width={123} height={40} />
        <Basic width={95} height={40} />
        <Basic width={48} height={40} />
        <Basic width={48} height={40} />
        <Basic width={48} height={40} />
        <Basic width={16} height={16} />
      </div>
    </div>

    <div className='desktop:px-12 tablet:px-6 mobile:px-3 py-6'>
      <Grid desktop={12}>
        <Column mobile={0} tablet={0} desktop={3} className='desktop:block hidden' />
        <Column desktop={6}>
          <div className='mb-4'>
            <Basic width='full' height={324} />
          </div>
          <div className='flex flex-col space-y-2'>
            <Basic width={102} height={24} />
            <Basic width='full' height={40} />
          </div>
          <div className='flex flex-col'>
            <div className='pb-6 pl-10 pt-8'>
              <Basic width={61} height={16} />
            </div>
            <Basic width='full' height={80} />
          </div>
        </Column>
        <Column mobile={0} tablet={0} desktop={3} className='desktop:block hidden' />
      </Grid>
    </div>
  </>
);
