import * as React from 'react';

import Tippy from '@tippyjs/react';

import { FullStarSVG } from '@components/svgs';

import { CellWrapper } from '../shared';

export const TextCell: React.FC<
  React.PropsWithChildren<{ ideal?: boolean; value?: number | string | null; onClick?: () => void; tip?: string }>
> = ({ ideal, value, tip, onClick }) => {
  const text = value !== undefined && value !== null && String(value);

  const tippyContent = tip ? tip : text && text.split('\n').map((v, i) => <p key={v + i}>{v}</p>);
  return (
    <CellWrapper
      onClick={onClick}
      wrapperClass='cursor-pointer hover:bg-indigo-50 hover:text-indigo-600 overflow-hidden'
    >
      {ideal && <FullStarSVG className='mr-2' />}
      <Tippy content={tippyContent}>
        <span title={`${value}`}>{text}</span>
      </Tippy>
    </CellWrapper>
  );
};
