import * as React from 'react';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Tippy from '@tippyjs/react';

import { api } from '@api/reduxApi';
import { Button, Card, CardTitle, Text } from '@components/common';
import { BackgroundTasks } from '@components/shared/BackgroundTasks';
import { useRecruitingRequest } from '@components/StudiesApp/components/StudyDraft/hooks/useRecruitingRequest';
import { ErrorSvg, ExternalCandidatesSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { humanize, moneyFormat } from '@components/utils';
import { ChangeIncentiveModal, useStudyFunder } from '@components/Wallet';
import { usePermission } from '@hooks/usePermission';
import { useUser } from '@hooks/useUser';
import { Basic } from 'components/shared/Skeleton';

import { useGetStudyBackgroundTasksQuery } from '../api';
import { Alerts } from '../components/Alerts';
import { STATUS_LABEL, useParticipationsStatus } from '../hooks/useParticipationsStatus';

import { AudienceEstimate } from './ExternalCandidatesRequest/components/AudienceEstimate';
import { StateBadge } from './ExternalCandidatesRequest/components/StateBadge';
import { StepTitle } from './shared';

interface Props {
  study: Study;
}

const Metric: React.FC<React.PropsWithChildren<{ title: string; value: number }>> = ({ title, value }) => {
  return (
    <div className='mt-4 w-40 px-6'>
      <Text h='400'>{title}</Text>
      <div className='flex'>
        <Text h='400' className='mt-1.5'>
          {value}
        </Text>
      </div>
    </div>
  );
};

const Metrics: React.FC<
  React.PropsWithChildren<{
    study: Study;
    request: ExternalCandidatesRequest;
    onPublish: (e?: React.MouseEvent) => void;
    onRevertToDraft: (e?: React.MouseEvent) => void;
    onPause: (e?: React.MouseEvent) => void;
    onUnpause: (e?: React.MouseEvent) => void;
    onDuplicate: (e?: React.MouseEvent) => void;
  }>
> = ({ study, request, onPublish, onRevertToDraft, onPause, onUnpause, onDuplicate }) => {
  const maximum = request.attrs.maximum_candidates || 0;

  const status = useParticipationsStatus(study);

  const studyIsActive = study.state === 'active';

  const requestValid = (request.valid_request as boolean) && (request.valid_study as boolean) && studyIsActive;
  const requestErrors = [...(request.all_errors || []), ...(studyIsActive ? [] : ['Study is not Active'])].join('; ');

  const partitcipationCounts = status.reduce<Record<ParticipationStatus, number>>(
    (acc, { status }) => {
      const count = request.participations?.filter((p) => p.status === status).length ?? 0;
      acc[status] = count;

      return acc;
    },
    {} as Record<ParticipationStatus, number>
  );

  const nonActiveStatuses = ['canceled', 'completed', 'rejected', 'no_show', 'opted_out'];
  const hasActiveParticipants: boolean = !!request.participations?.filter((p) => !nonActiveStatuses.includes(p.status))
    .length;

  return (
    <Card className='mb-4'>
      <div className='flex flex-row items-center justify-between'>
        <CardTitle size='base'>{request.attrs.public_title}</CardTitle>
        <div className='flex flex-row items-center space-x-3'>
          <Button icon='pencil' href={`/studies/${request.project_id}/recruitment_requests/${request.id}`}>
            Edit
          </Button>
          {request.state === 'draft' && (
            <Tooltip isDisabled={!requestErrors.length} content={requestErrors}>
              <Button icon='promo' onClick={onPublish} disabled={!requestValid}>
                Publish
              </Button>
            </Tooltip>
          )}
          {request.state === 'submitted_publish' && (
            <Tippy content='Stop Publishing and return the request to Draft'>
              <Button icon='noCircle' onClick={onRevertToDraft}>
                Return to Draft
              </Button>
            </Tippy>
          )}
          {request.state === 'published' && !request.paused && (
            <Tippy content='Temporarily pause recruiting new participants'>
              <Button icon='pause' onClick={onPause}>
                Pause
              </Button>
            </Tippy>
          )}
          {request.state === 'published' && request.paused && (
            <Tippy content='Start recruiting new participants again'>
              <Button icon='play' onClick={onUnpause}>
                Unpause
              </Button>
            </Tippy>
          )}
          <Tippy content='Copy into a new Draft request'>
            <Button icon='copy' onClick={onDuplicate}>
              Duplicate
            </Button>
          </Tippy>
        </div>
      </div>
      <div className='mt-2 flex flex-row items-center space-x-2'>
        <StateBadge request={request} />
        <div className='rounded-full border-2 border-gray-200' />
        <span className='text-sm text-gray-500'>{request.platform_name}</span>
        <div className='rounded-full border-2 border-gray-200' />
        <span className='text-sm uppercase text-gray-500'>{request.attrs.market_type}</span>
        {request.attrs.eligible_audience !== undefined && (
          <>
            <div className='rounded-full border-2 border-gray-200' />
            <AudienceEstimate request={request} />
          </>
        )}
      </div>
      <div className='-ml-6 flex flex-wrap divide-x divide-gray-200'>
        <Metric title='Limit' value={maximum} />
        {Object.keys(partitcipationCounts).map((status) => (
          <Metric title={humanize(STATUS_LABEL[status] || status)} value={partitcipationCounts[status]} />
        ))}
      </div>
    </Card>
  );
};

const ZDS: React.FC<React.PropsWithChildren<{ study: Study; handleNew: () => void }>> = ({ study, handleNew }) => {
  const costPerB2BParticipant = moneyFormat(study.external_fee_per_b2b_participant_in_cents / 100, 0);
  const costPerB2CParticipant = moneyFormat(study.external_fee_per_b2c_participant_in_cents / 100, 0);

  return (
    <div className='flex'>
      <div className='w-9/12'>
        <Card>
          <div className='flex flex-col items-center justify-center'>
            <ExternalCandidatesSVG className='h-20 w-20 text-gray-500' />
            <span className='mt-4 font-bold text-gray-700'>Recruit external participants</span>
            <span className='mt-4 text-sm text-gray-500'>
              Submit a request to our panel providers and get participants that fit.
            </span>
            <div className='mt-6 flex flex-row items-center space-x-3'>
              <Button
                icon='newTab'
                href='https://greatquestion.co/support/studies/external-participant-recruitment'
                target='_blank'
              >
                Learn more
              </Button>
              <Button primary onClick={handleNew}>
                Create request
              </Button>
            </div>
          </div>
          <div className='mt-6 border-t border-gray-200 pt-6'>
            <span className='mt-4 font-bold text-gray-700'>How it works</span>
            <div className='items-top mt-6 flex flex-row'>
              <div className='w-1/12'>
                <div className='w-8 rounded-lg border border-gray-200 p-1 text-center'>1</div>
              </div>
              <div className='w-11/12'>
                <div className='text-sm text-gray-700'>Submit recruitment request form</div>
                <div className='mt-1 text-sm text-gray-500'>
                  Specify the details of who you’re looking to participate in research and our recruiting partners will
                  do the rest.
                </div>
              </div>
            </div>
            <div className='items-top mt-6 flex flex-row'>
              <div className='w-1/12'>
                <div className='w-8 rounded-lg border border-gray-200 p-1 text-center'>2</div>
              </div>
              <div className='w-11/12'>
                <div className='text-sm text-gray-700'>Screen candidates</div>
                <div className='mt-1 text-sm text-gray-500'>
                  You will review the candidates provided by our partners and decide who to invite.
                </div>
              </div>
            </div>
            <div className='items-top mt-6 flex flex-row'>
              <div className='w-1/12'>
                <div className='w-8 rounded-lg border border-gray-200 p-1 text-center'>3</div>
              </div>
              <div className='w-11/12'>
                <div className='text-sm text-gray-700'>Conduct the research</div>
                <div className='mt-1 text-sm text-gray-500'>
                  Just like you normally do in Great Question, you’ll be able to conduct the research with the
                  participants.
                </div>
              </div>
            </div>
            <div className='items-top mt-6 flex flex-row'>
              <div className='w-1/12'>
                <div className='w-8 rounded-lg border border-gray-200 p-1 text-center'>4</div>
              </div>
              <div className='w-11/12'>
                <div className='text-sm text-gray-700'>Pay incentives and recruiting fees</div>
                <div className='mt-1 text-sm text-gray-500'>
                  You’ll send the incentives to these participants, and pay a separate fee to our partners for each
                  participant. The fees will vary by partner.
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className='ml-6 w-3/12'>
        <span className='text-sm font-bold text-gray-500'>Our partner</span>
        <div className='mt-4 rounded border border-gray-200 p-4'>
          <div className='flex justify-center'>
            <img src='/temp-assets/candidates/respondent.png' alt='respondent-logo' />
          </div>
          <div className='mt-4'>
            <span className='text-xs leading-snug text-gray-700'>
              Leading research participant recruitment platform.
            </span>
            <span className='text-xs text-gray-700'>
              <ul className='list-disc pl-4'>
                <li>3M+ participants</li>
                <li>30 minute median time to first match</li>
                <li>Less than 5% no-show rate</li>
                <li>${costPerB2BParticipant} fee per B2B participant, plus incentives</li>
                <li>${costPerB2CParticipant} fee per B2C participant, plus incentives</li>
                <li>Contact sales@greatquestion.co to access bulk discounts on credit purchases</li>
              </ul>
            </span>
          </div>
          <div className='mt-4'>
            <Button icon='newTab' className='w-full' href='https://www.respondent.io/' target='_blank'>
              Visit website
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Skeleton = () => {
  return (
    <div className='relative mb-4 rounded border border-gray-200 bg-white p-6'>
      <Basic h='10' width={250} />
      <div className='-ml-6 mt-6 flex flex-row divide-x divide-gray-200'>
        <div className='flex flex-grow flex-col space-y-4 px-6'>
          <Basic h='8' width={136} />
          <Basic h='8' width={110} />
        </div>
        <div className='flex flex-grow flex-col space-y-4 px-6'>
          <Basic h='8' width={110} />
          <Basic h='8' width={60} />
        </div>
        <div className='flex flex-grow flex-col space-y-4 px-6'>
          <Basic h='8' width={120} />
          <Basic h='8' width={50} />
        </div>
        <div className='flex flex-grow flex-col space-y-4 px-6'>
          <Basic h='8' width={100} />
          <Basic h='8' width={50} />
        </div>
      </div>
    </div>
  );
};

export const ExternalCandidatesRequests: React.FC<React.PropsWithChildren<{ study: Study }>> = ({ study }) => {
  const user = useUser();
  const canUpdate = usePermission<Study>('updateStudy')(study);

  const [fundModalOpen, setFundModalOpen] = useState(false);

  const funder = useStudyFunder({ study });

  const navigate = useNavigate();

  const {
    data: externalCandidatesRequests,
    isLoading,
    isSuccess,
    refetch: refetchRequests
  } = api.useGetExternalCandidatesRequestsQuery(study.id);
  const [publishExternalCandidatesRequest, { data: publishedRequest }] =
    api.usePublishExternalCandidatesRequestMutation();
  const [revertToDraftExternalCandidatesRequest] =
    api.useCreateExternalCandidatesRequestSubmissionsRevertToDraftMutation();
  const [pauseExternalCandidatesRequest, { data: pausedRequest }] =
    api.useCreateExternalCandidatesRequestPauseMutation();
  const [unpauseExternalCandidatesRequest, { data: unpausedRequest }] =
    api.useCreateExternalCandidatesRequestUnpauseMutation();
  const [duplicateExternalCandidatesRequest, { data: duplicateRequest, isSuccess: duplicateIsSuccess }] =
    api.useCreateExternalCandidatesRequestDuplicateMutation();
  const { data: slots, refetch: refetchSlots } = api.useGetStudySlotsBalanceQuery(study.id, { skip: !study.id });

  const [backgroundTasks, setBackgroundTasks] = useState<BackgroundTask[]>([]);

  useEffect(() => {
    if (
      study.state !== 'closed' &&
      !study.can_delay_funding &&
      study.funding.usd_outstanding > 0 &&
      study.funding.recruitment_fees > 0
    ) {
      setFundModalOpen(true);
    }
  }, [study]);

  const updateBackgroundTasks = (request: ExternalCandidatesRequest) => {
    if (!request.background_tasks) return;

    const requestBackgroundTaskIds = request.background_tasks.map((bt) => bt.id);
    const nonRequestBackgroundTasks = backgroundTasks.filter((bt) => !requestBackgroundTaskIds.includes(bt.id));
    setBackgroundTasks([...nonRequestBackgroundTasks, ...request.background_tasks]);
  };

  useEffect(() => {
    if (publishedRequest) updateBackgroundTasks(publishedRequest);
  }, [publishedRequest]);

  useEffect(() => {
    if (duplicateIsSuccess && duplicateRequest) {
      navigate(`/studies/${study.id}/recruitment_requests/${duplicateRequest.id}`);
    }
  }, [duplicateIsSuccess]);

  useEffect(() => {
    refetchRequests();
    refetchSlots();
  }, [study, backgroundTasks]);

  useEffect(() => {
    if (pausedRequest) updateBackgroundTasks(pausedRequest);
  }, [pausedRequest]);

  useEffect(() => {
    if (unpausedRequest) updateBackgroundTasks(unpausedRequest);
  }, [unpausedRequest]);

  const handleUpdatedRequest = (newRequest: ExternalCandidatesRequest) => {
    navigate(`/studies/${study.id}/recruitment_requests/${newRequest.id}`);
  };

  const { handleNew } = useRecruitingRequest({
    study: study,
    handleUpdatedRequest,
    skip: true
  });

  function handlePublish(studyId, externalCandidatesRequestId) {
    publishExternalCandidatesRequest({ studyId, externalCandidatesRequestId });
  }

  function handleRevertToDraft(studyId, externalCandidatesRequestId) {
    revertToDraftExternalCandidatesRequest({ studyId, externalCandidatesRequestId });
  }

  function handlePause(studyId, externalCandidatesRequestId) {
    pauseExternalCandidatesRequest({ studyId, externalCandidatesRequestId });
  }

  function handleUnpause(studyId, externalCandidatesRequestId) {
    unpauseExternalCandidatesRequest({ studyId, externalCandidatesRequestId });
  }

  function handleDuplicate(studyId, externalCandidatesRequestId) {
    duplicateExternalCandidatesRequest({ studyId, externalCandidatesRequestId });
  }

  return (
    <div className='px-page py-gutter relative'>
      <Alerts
        slots={slots}
        user={user}
        study={study}
        openFundModal={() => setFundModalOpen(true)}
        canAction={true}
        keys={['must_fund']}
      />

      <BackgroundTasks
        onFinished={() => {
          refetchRequests();
          refetchSlots();
        }}
        setBackgroundTasks={setBackgroundTasks}
        backgroundTasks={backgroundTasks}
        params={{ objectId: study.id }}
        backgroundTasksQuery={useGetStudyBackgroundTasksQuery}
      />

      <div className='flex flex-row items-center justify-between pb-6'>
        <StepTitle>Recruitment requests</StepTitle>
        {isSuccess && !!externalCandidatesRequests?.length && (
          <Button primary className='ml-8' onClick={handleNew}>
            New Request
          </Button>
        )}
      </div>
      {isLoading ? (
        <Skeleton />
      ) : (
        !!externalCandidatesRequests?.length &&
        externalCandidatesRequests?.map((request) => (
          <Metrics
            key={`externalCandidatesRequest-metrics-${request.id}`}
            study={study}
            request={request}
            onPublish={(e) => handlePublish(request.project_id, request.id)}
            onRevertToDraft={(e) => handleRevertToDraft(request.project_id, request.id)}
            onPause={(e) => handlePause(request.project_id, request.id)}
            onUnpause={(e) => handleUnpause(request.project_id, request.id)}
            onDuplicate={(e) => handleDuplicate(request.project_id, request.id)}
          />
        ))
      )}
      {isSuccess && !externalCandidatesRequests?.length && <ZDS study={study} handleNew={handleNew} />}

      {fundModalOpen && (
        <ChangeIncentiveModal
          limitCount={study.maximum_slots}
          study={study}
          funder={funder}
          onCancel={() => setFundModalOpen(false)}
          onConfirm={() => setFundModalOpen(false)}
        />
      )}
    </div>
  );
};
