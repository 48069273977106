import * as React from 'react';
import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Alert, Text } from '@components/common';
import { UploadFileZone } from '@components/shared/UploadFileModal/UploadFileZone';
import { CloseSVG } from '@components/svgs';
import { useSidebar } from '@hooks/useSidebar';

const TIPS = [
  'File must be in .csv (comma-separated values) format',
  'First row must contain column headers',
  'All email addresses must be valid',
  'Remove extra commas (e.g., "Name" instead of ",Name")',
  'Ensure quotes are correctly paired (e.g., "NYC" instead of "NYC)',
  'Ensure column headers line up with the data',
  'Only import the data you need'
];

type Props = {
  onClose?: () => void;
  teamId?: number | null;
  studyId?: number | null;
};

export const UploadCSVModal: React.FC<Props> = ({ onClose, teamId, studyId }) => {
  const sidebar = useSidebar();
  const navigate = useNavigate();
  const [createCustomerImport, { error, data, isLoading, isSuccess, isError }] = api.useCreateCustomerImportMutation();

  const onDrop = ([file]: File[]) => {
    createCustomerImport({ file, teamId, studyId });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`/customer_imports/${data?.id}/edit`);
    }
  }, [isSuccess]);

  useEffect(() => {
    sidebar.setHidden(true);
    return () => sidebar.setHidden(false);
  }, []);

  const errorMessage = isError && ((error as any)?.csv || []).join(', ');

  return (
    <div className='h-full w-full bg-white'>
      <div className='flex items-center p-6'>
        <Text as='h1' h='700' className='flex-1'>
          Import CSV
        </Text>
        {onClose && (
          <button name='Close' aria-label='Close'>
            <CloseSVG />
          </button>
        )}
      </div>
      <div className='p-6'>
        <div className='mx-auto w-full max-w-xl'>
          {isError && (
            <Alert type='error' heading='Invalid file format'>
              {errorMessage && `An error occurred because: ${errorMessage}`}
              {!errorMessage && 'Please double-check your CSV file or try again.'}
            </Alert>
          )}
          <div className='mb-6'>
            <UploadFileZone uploading={isLoading || isSuccess} supportedFileTypes={['.csv']} onDrop={onDrop} />
          </div>
          <Text bold className='leading-loose'>
            Tips for your import:
          </Text>
          <ul>
            {TIPS.map((tip, index) => (
              <li key={index} className='ml-6 list-disc'>
                {tip}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
