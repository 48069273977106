import { getLimitInputName } from '@components/StudiesApp/components/StudyDraft/pages/Plan/Plan';
import { compact } from 'components/utils';

export function getBatchingSettings(count: number, study?: SimpleStudy) {
  if (!study) {
    return { on: false, size: 5, wait_hours: 12, auto_restart: false };
  }

  const studyLimit = study[getLimitInputName(study)] || 0;

  const on = study.batch_on || count > studyLimit;

  return {
    on,
    size: on && study.batch_size ? study.batch_size : Math.max(Math.min(...compact([study.batch_size || 5, count])), 1),
    wait_hours: study.batch_wait_hours || 12,
    auto_restart: false
  };
}
