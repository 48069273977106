import * as React from 'react';

import pluralize from 'pluralize';
import type { FC } from 'react';

import * as dropdowns from '../components/dropdowns';
import { ContactAccess } from '../components/dropdowns/ContactAccess';
import * as svgs from '../components/svgs';
import { FilterDefinition } from '../types';
import { getDefaultOperator } from '../utils/getDefaultOperator';

const ATTR_TYPES_TO_FILTER_TYPES: Record<Attr_['attr_type'], FilterDefinition<any>['type']> = {
  text: 'text',
  url: 'text',
  free_text: 'free_text',
  number: 'number',
  boolean: 'boolean',
  datetime: 'date',
  multiple_choice: 'multiple_choice'
};
const CORE_ATTR_TYPES_TO_FILTER_TYPES: Record<Attr_['attr_type'], FilterDefinition<any>['type']> = {
  text: 'free_text',
  url: 'text',
  free_text: 'free_text',
  number: 'number',
  boolean: 'boolean',
  datetime: 'date',
  multiple_choice: 'multiple_choice'
};
export const ATTR_TYPES_TO_ICON: Record<Attr_['attr_type'], FC<React.PropsWithChildren<unknown>>> = {
  text: svgs.text,
  url: svgs.url,
  free_text: svgs.text,
  number: svgs.number,
  boolean: svgs.boolean,
  datetime: svgs.date,
  multiple_choice: svgs.multipleChoice
};

type Params = {
  coreAttrs: Attr_[];
  customAttrs: Attr_[];
  segments: CustomerSegment[];
  enableTeams: boolean;
  teams?: Team[];
};
export const buildCandidateFilterDefs = (params: Params): FilterDefinition<Candidate>[] => {
  const definitions: FilterDefinition<Candidate>[] = [
    ...params.segments.map(
      (segment) =>
        ({
          id: `segment_${segment.id}`,
          onlyAllowOne: true,
          name: segment.name,
          displayName: 'Segment',
          type: 'segment',
          defaultOperator: 'is',
          Icon: svgs.segment
        }) as FilterDefinition<Candidate>
    ),
    {
      id: 'eligibility',
      onlyAllowOne: true,
      type: 'eligibility',
      name: 'Eligible',
      defaultOperator: 'is',
      defaultValue: true,
      Component: dropdowns.Eligibility,
      Icon: svgs.eligibility
    },
    {
      id: 'last_contacted_at',
      type: 'date',
      name: 'Last invited date',
      defaultOperator: 'is',
      Component: dropdowns.Date,
      Icon: svgs.date
    },
    {
      id: 'created_at',
      type: 'date',
      name: 'Join date',
      defaultOperator: 'is_greater',
      Component: dropdowns.Date,
      Icon: svgs.date
    },
    {
      id: 'participated_in_studies',
      type: 'participated_in_studies',
      name: 'Studies',
      defaultOperator: 'includes_any',
      defaultValue: [],
      Component: dropdowns.HasParticipatedInStudies,
      Icon: svgs.studies
    },
    ...params.customAttrs.map((attr: Attr_) => {
      const filterType = ATTR_TYPES_TO_FILTER_TYPES[attr.attr_type];
      return {
        id: attr.core ? attr.name : `extra.${attr.name}`,
        type: filterType,
        name: attr.label,
        defaultOperator: getDefaultOperator(filterType),
        Icon: ATTR_TYPES_TO_ICON[attr.attr_type]
      };
    }),
    ...params.coreAttrs
      .filter((attr: Attr_) => !['name', 'timezone', 'phone_number'].includes(attr.name))
      .map((attr: Attr_) => {
        const filterType = CORE_ATTR_TYPES_TO_FILTER_TYPES[attr.attr_type];

        return {
          id: attr.name,
          type: filterType,
          name: attr.label,
          defaultOperator: getDefaultOperator(filterType),
          Icon: ATTR_TYPES_TO_ICON[attr.attr_type]
        };
      })
  ];

  if (params.enableTeams && params.teams && params.teams.length > 0) {
    const teams = params.teams;

    definitions.push({
      id: 'team_ids',
      type: 'choose',
      name: 'Contact Access',
      defaultOperator: 'includes_any',
      operators: ['includes_any', 'includes_all', 'includes_none'],
      Icon: svgs.team,
      renderValue: (value) => {
        if (value.includes('all')) return 'Anyone';
        if (value.includes('none')) return 'No one';
        if (value.length === 1) {
          const teamName = teams.find(({ id }) => id === parseInt(value[0]))?.name;
          if (teamName) {
            return teamName;
          }
        }
        return pluralize('team', value.length, true);
      },
      Component: (props) => <ContactAccess {...props} teams={teams} />
    });
  }

  return definitions;
};
