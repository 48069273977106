import * as React from 'react';

import { Text } from '@components/common';

import { HeaderButtons } from './components/HeaderButtons';
import { HighlightGroups } from './components/HighlightGroups';
import { MiniPlayerProvider } from './components/MiniPlayer';
import { SynthesisProvider } from './context';

type Props = {
  study: Study;
};

export const SynthesisTab: React.FC<React.PropsWithChildren<Props>> = ({ study }) => (
  <div className='flex h-full flex-col'>
    <MiniPlayerProvider>
      <SynthesisProvider>
        <div className='px-page tablet:pt-6 monitor:pt-12 flex pb-4 pt-4'>
          <Text h='700' mb='2' className='flex-1'>
            Synthesis
          </Text>
          <HeaderButtons studyId={study.id} boardId={study.highlight_board_id} />
        </div>
        <div style={{ minHeight: 700 }} className='flex-1 overflow-hidden'>
          <HighlightGroups study={study} boardId={study.highlight_board_id} />
        </div>
      </SynthesisProvider>
    </MiniPlayerProvider>
  </div>
);
