import React from 'react';

import { StudyFunder } from '@components/Wallet';
import { useAccount } from 'hooks/useAccount';

import { ContinuousFundingInfo } from '../ContinuousFundingInfo';

interface Props {
  funder: StudyFunder;
  study: Study;
}

export const WalletInfo: React.FC<React.PropsWithChildren<Props>> = ({ funder, study }) => {
  return (
    <div className='py-6'>
      {study.continuous && <ContinuousFundingInfo study={study} />}
      <div className='mb-2 flex flex-row items-center justify-between'>
        <span className='h500-bold block'>Incentives budget</span>
        <span data-testid='budget-amount' className='text-md block font-bold'>
          {funder.displayIncentivesAmount}
        </span>
      </div>
      {funder.feeAmount > 0 && (
        <div className='mb-2 flex flex-row items-center justify-between'>
          <span className='h500-bold block'>Recruitment fees budget</span>
          <span data-testid='budget-amount' className='text-md block font-bold'>
            {funder.displayFeeAmount}
          </span>
        </div>
      )}
      <span className='block text-sm text-gray-500'>
        Upon creating, {funder.displayTotal} worth of incentives {funder.feeAmount > 0 && 'and recruitment fees '}will
        need to be funded or allocated to your study in USD based on the spot exchange rate. Any unused funds will be
        refunded on closing the study.
      </span>
    </div>
  );
};
