import * as React from 'react';
import { useRef, useState } from 'react';

import { DropdownLink } from '@components/common';
import { SolidKebabSVG, TrashSVG } from '@components/svgs';
import { useOnClickOutside } from '@components/utils';
import { useOnEscape } from '@components/utils';

import { ColorPicker } from './ColorPicker';

type Props = {
  className?: string;
  color: Tag['color'];
  onChangeColor?: (value: Tag['color']) => void;
  dropdownOptions: DropdownOptionsCallback;
  children?: (props: { closeDropdown: () => void }) => React.ReactNode;
};

export type DropdownOptionsCallback = (params: { closeDropdown: () => void }) => {
  onClickSelectAll?: () => void;
  onClickDeselectAll?: () => void;
  onClickDelete?: () => void;
};

export const ColumnOptionsDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  children,
  color,
  onChangeColor,
  dropdownOptions
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const closeDropdown = () => setDropdownOpen(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, closeDropdown);
  useOnEscape(closeDropdown, [dropdownOpen]);

  const options = dropdownOptions({ closeDropdown });

  return (
    <div ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={[className, 'focus:outline-none -mr-2 p-2 hover:bg-gray-50'].join(' ')}
        name='more'
        aria-haspopup={true}
        type='button'
        aria-label='more'
      >
        <SolidKebabSVG className='h-4 w-4' />
        <div className='relative whitespace-nowrap text-left'>
          {dropdownOpen && (
            <div className='absolute right-0 top-5 z-20 rounded-md bg-white px-2 py-1 shadow-lg ring-1 ring-black ring-opacity-5'>
              {onChangeColor && (
                <>
                  <div className='p-4'>
                    <ColorPicker
                      value={color}
                      onChange={(c) => {
                        onChangeColor(c);
                        closeDropdown();
                      }}
                    />
                  </div>
                  <hr />
                </>
              )}
              {options?.onClickSelectAll && (
                <DropdownLink className='flex items-center border-b' onClick={options.onClickSelectAll}>
                  Select all
                </DropdownLink>
              )}
              {options?.onClickDeselectAll && (
                <DropdownLink className='flex items-center' onClick={options.onClickDeselectAll}>
                  Unselect all
                </DropdownLink>
              )}
              {options?.onClickDelete && (
                <DropdownLink
                  className='flex items-center border-t'
                  color='red-600'
                  hoverColor='red-700'
                  onClick={options.onClickDelete}
                >
                  <TrashSVG className='mr-2' />
                  Delete group…
                </DropdownLink>
              )}
              {children?.({ closeDropdown })}
            </div>
          )}
        </div>
      </button>
    </div>
  );
};
