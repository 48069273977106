import React from 'react';

import { useTranslation } from 'react-i18next';
import { SDKResult } from '@loomhq/loom-sdk';

import { Alert, Button, List, Spinner, Text } from '@components/common';
import { RecorderStatus } from '@components/Loom/useRecorder';

import { PoweredByLoom } from '../helpers/PoweredByLoom';

interface Props {
  position: number;
  button: ReturnType<SDKResult['configureButton']>;
  status: RecorderStatus;
  isUnlimited: boolean;
  register: any;
}

export const StartLoom: React.FC<React.PropsWithChildren<Props>> = ({
  button,
  status,
  position,
  isUnlimited,
  register
}) => {
  const { t } = useTranslation('StartLoom');

  return (
    <div className='rounded border border-gray-200 bg-gray-50 p-4'>
      <Text h='600' bold mb='4'>
        {t('screen_recording')}
      </Text>
      <Text mb='6'>
        {t('for')} {position === 1 ? t('this_test') : t('the_next_part')}
        {t('asked_to_record')}
      </Text>
      <Text mb='4'>
        {t('please_ensure')}
        <List mt='4'>
          <li>{t('continue_recording')}</li>
          <li>{t('access_from_desktop')}</li>
          <li>{t('share_screen')}</li>
          <li>{t('enable_mic')}</li>
        </List>
      </Text>
      <PoweredByLoom />
      {['init', 'error', 'new', 'starting'].includes(status) && (
        <div className='mb-6 flex flex-row items-center space-x-4'>
          <Button
            noStyle
            className='btn-custom-brand'
            onClick={() => button.openPreRecordPanel()}
            disabled={['init', 'error', 'starting'].includes(status)}
          >
            {t('get_started')}
          </Button>
          {status === 'init' && <Spinner className='h-4 w-4' />}
        </div>
      )}
      {!isUnlimited && <Alert type='info'>{t('info_note')}</Alert>}
    </div>
  );
};
