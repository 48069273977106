import * as React from 'react';
import { useCallback } from 'react';

import {
  DateRangeInPast,
  InvalidCallLocationAlert,
  InvalidEmailSenderAlert,
  ModeratorsNeedCalendarAlert,
  ModeratorsNeedCalendarSelectedAlert,
  ModeratorsNeedConferencingAlert,
  ModeratorsNeedZoomLocalRecordingSettingAlert,
  NeedsAvailabilityAlert,
  NeedsSlotsAlert,
  NoScreenerQuestionsAlert,
  NoSurveyQuestionsAlert,
  UnderParticipantLimitAlert
} from './components';

type Props = {
  study: SimpleStudy;
  bookability: Bookability;
  onStartPolling?: () => void;
};

const COMPONENTS: Record<BookabilityRule, BookabilityRuleAlertComponent> = {
  moderators_need_calendar: ModeratorsNeedCalendarAlert,
  moderators_need_calendar_selected: ModeratorsNeedCalendarSelectedAlert,
  moderators_need_conferencing: ModeratorsNeedConferencingAlert,
  moderators_need_zoom_local_recording_setting: ModeratorsNeedZoomLocalRecordingSettingAlert,
  needs_slots: NeedsSlotsAlert,
  needs_availability: NeedsAvailabilityAlert,
  under_participant_limit: UnderParticipantLimitAlert,
  invalid_call_location: InvalidCallLocationAlert,
  no_screener_questions: NoScreenerQuestionsAlert,
  no_survey_questions: NoSurveyQuestionsAlert,
  date_range_in_past: DateRangeInPast,
  invalid_email_sender: InvalidEmailSenderAlert
};

export const BookabilityAlerts: React.FC<React.PropsWithChildren<Props>> = ({ study, bookability, onStartPolling }) => {
  const rules = Object.keys(bookability);

  const renderRule = useCallback(
    (rule: BookabilityRule) => {
      const Component = COMPONENTS[rule];
      const level = bookability[rule]?.[0];
      const extra = bookability[rule]?.[1];
      return (
        level &&
        Component && (
          <Component key={rule} study={study} rule={rule} level={level} extra={extra} onClickCta={onStartPolling} />
        )
      );
    },
    [bookability]
  );

  const displayableRules = rules.filter((rule) => bookability[rule]?.[0]);

  if (!displayableRules?.length) {
    return null;
  }

  const sortedRules = [...displayableRules].sort((a, b) => {
    const aStatus = bookability[a][0];
    const bStatus = bookability[b][0];

    if (aStatus === 'error' && bStatus === 'warning') {
      return -1;
    }

    if (aStatus === 'warning' && bStatus === 'error') {
      return 1;
    }

    return 0;
  });

  return <div className='mb-4 flex flex-col space-y-4'>{sortedRules.map(renderRule)}</div>;
};
