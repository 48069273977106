import * as React from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';

import { macOrWindowsify } from '@components/utils';
import { useDeviceType } from '@hooks/useDeviceType';

interface Props {
  name: string;
  href: string;
  spa?: boolean;
  targetBlank?: boolean;
  isActiveNav?: boolean;
  isNew?: boolean;
  shortcut?: string;
  onClick?: () => void;
}

const OFFSET = -12;
export const NavItem: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
  name,
  isActiveNav,
  targetBlank,
  isNew,
  href,
  shortcut,
  spa,
  children
}) => {
  const { isMobile } = useDeviceType();

  const tooltip = shortcut ? `${name} (${macOrWindowsify(shortcut)})` : name;

  const Element = spa ? Link : 'a';

  const props: any = {
    [Element === 'a' ? 'href' : 'to']: href,
    onClick,
    className: cn(
      { 'bg-gray-700': isActiveNav },
      'w-full text-white hover:bg-gray-700 hover:text-white visited:text-white focus:outline-none focus:text-white group flex items-center px-4 py-1.5 text-sm font-medium relative'
    ),
    target: targetBlank ? '_blank' : undefined,
    children: (
      <>
        {children}
        {isNew && (
          <span className='h300 absolute right-5 top-1 inline-block h-6 rounded-full bg-white px-2 py-1 text-right font-bold text-indigo-600'>
            NEW
          </span>
        )}
      </>
    )
  };

  return (
    <Tippy
      maxWidth={400}
      disabled={isMobile}
      offset={[0, OFFSET]}
      interactive={true}
      arrow={false}
      content={tooltip}
      placement='right'
    >
      {React.createElement(Element, props)}
    </Tippy>
  );
};
