import * as React from 'react';
import { useRef } from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';

import { ICONS } from '@components/common/Button';
import { track } from '@components/tracking';
import { useOnClickOutside, useOnEscape } from '@components/utils';
import { ChevronDownSVG } from 'components/svgs';
import { Tooltip } from 'components/Tooltip';

interface DropdownLinkProps {
  className?: string;
  href?: string;
  target?: '_blank';
  spa?: boolean;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  disabledTooltip?: string;
  color?: string;
  hoverColor?: string;
  trackEvent?: string;
  trackProps?: Record<string, any>;
  ['aria-label']?: string;
}

const DropdownLink: React.FC<React.PropsWithChildren<DropdownLinkProps>> = ({
  className = '',
  disabled,
  disabledTooltip,
  href,
  spa,
  color = 'gray-700',
  hoverColor = 'indigo-600',
  trackEvent,
  trackProps,
  onClick,
  ...rest
}) => {
  const theProps: any = { role: 'button', disabled, ...rest };

  const Element = spa ? Link : 'a';

  if (spa && !disabled) {
    theProps.to = href;
  } else if (!disabled) {
    theProps.href = href;
  }

  if (onClick || trackEvent) {
    theProps.onClick = ((e: React.MouseEvent): void => {
      if (trackEvent) {
        track(trackEvent, trackProps);
      }
      if (onClick) {
        onClick(e);
      }
    }) as React.MouseEventHandler;
  }

  theProps.className = `tw-ui-dropdown-link whitespace-nowrap text-${color} ${
    disabled ? 'opacity-50 hover:bg-white' : `hover:text-${hoverColor}`
  } ${className}`;

  if (disabled) {
    delete theProps.onClick;

    return (
      <Tooltip className='w-full' content={disabledTooltip} isDisabled={!disabledTooltip}>
        <Element {...theProps} />
      </Tooltip>
    );
  }

  return React.createElement(Element, theProps);
};

const STYLES = {
  primary: 'bg-indigo-600 hover:bg-indigo-500 text-white',
  text: 'border-0',
  default: 'border-gray-200 border hover:bg-gray-50'
};

interface Props {
  text: React.ReactNode;
  icon?: keyof typeof ICONS;
  className?: string;
  hideCaret?: boolean;
  disabled?: boolean;
  loading?: boolean;
  isOpen?: boolean;
  onClick?: React.MouseEventHandler;
  onClose?: () => void;
  position?: 'left' | 'right' | 'center';
  small?: boolean;
  primary?: boolean;
  textStyle?: boolean;
  menuClassName?: string;
  buttonClassName?: string;
  ellipsisButton?: boolean;
  error?: boolean;
  danger?: boolean;
  aboveButton?: boolean;
  dropdownWidth?: string;
  medium?: boolean;
  renderIcon?: () => React.ReactNode;
  renderButton?: () => React.ReactNode;
  renderHiddenChildren?: boolean;
  tooltip?: string;
  dataTestId?: string;
}

const Dropdown: React.FC<React.PropsWithChildren<Props>> = ({
  text,
  icon,
  className = '',
  hideCaret,
  disabled,
  loading,
  children,
  isOpen,
  onClick,
  onClose,
  menuClassName = '',
  buttonClassName = '',
  position = 'right',
  small = false,
  medium = false,
  error,
  danger,
  aboveButton,
  dropdownWidth = 56,
  renderIcon,
  renderButton,
  renderHiddenChildren = true,
  tooltip,
  dataTestId,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (isOpen) {
      onClose?.();
    }
  });

  useOnEscape(() => {
    if (isOpen) {
      onClose?.();
    }
  }, [isOpen]);

  let style = 'default';
  if (props.primary) {
    style = 'primary';
  } else if (props.textStyle) {
    style = 'text';
  }

  const buttonClass = cn(
    'whitespace-nowrap inline-flex items-center justify-center rounded-md text-sm font-medium focus:ring focus:ring-blue focus:outline-none',
    STYLES[style],
    buttonClassName,
    { 'px-2 py-1.5': small, 'px-3 py-1.5 h-8': medium, 'px-4 py-2.5': !small && !medium }
  );

  const dropdownWrapperClass = cn(`relative inline-block text-left ${className}`, { 'opacity-50': disabled });

  const mainButtonClass = cn({ 'border-red-600 shadow-sm ring-red': error }, buttonClass);

  const ItemsList = ({ children }) => {
    if (!isOpen && !renderHiddenChildren) {
      return <></>;
    }

    const wrapperClass = cn('absolute mt-2 rounded-md shadow-lg z-40', menuClassName, `w-${dropdownWidth}`, {
      hidden: !isOpen,
      'bottom-12': aboveButton,
      'top-10': !aboveButton && !medium,
      'top-8': !aboveButton && medium,
      'origin-top-left left-0': position === 'left',
      'origin-top-right right-0': position === 'right',
      'origin-top-center right-1/2 -mr-40': position === 'center'
    });

    return (
      <div className={wrapperClass}>
        <div
          className='relative rounded-md border border-gray-200 bg-white py-2 ring-1 ring-black ring-opacity-5'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='options-menu'
        >
          {children}
        </div>
      </div>
    );
  };

  const svgClass = cn('w-4 h-4 ml-2', { 'transform rotate-180': aboveButton });
  const buttonIconClass = cn('', { 'mr-2': medium, 'mr-1': !medium, 'text-red-500': danger });
  const ButtonIcon: any = icon && ICONS[icon];

  return (
    <div ref={ref} className={dropdownWrapperClass}>
      <div className='relative'>
        <div className='flex'>
          <ItemsList>{children}</ItemsList>
          <Tippy content={tooltip} disabled={!tooltip}>
            <div className='flex-1'>
              {renderButton?.() || (
                <button
                  onClick={onClick}
                  type='button'
                  className={mainButtonClass}
                  id='options-menu'
                  data-testid={dataTestId}
                  aria-haspopup='true'
                  aria-expanded='true'
                  aria-label='options-menu'
                  disabled={disabled}
                >
                  {icon && <ButtonIcon className={buttonIconClass} />}
                  {renderIcon?.()}
                  {text}
                  {!hideCaret && <ChevronDownSVG className={svgClass} />}
                </button>
              )}
            </div>
          </Tippy>
        </div>
      </div>
    </div>
  );
};

export { Dropdown, DropdownLink };
