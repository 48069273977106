import React from 'react';

import { SUPPORT_EMAIL } from '@constants/emails';

import { Button } from '@components/common';
import { ConnectionErrorSVG } from '@components/svgs';

export const ConnectionError: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className='py-gutter px-page mx-auto flex w-full max-w-2xl flex-1 flex-col items-center justify-center'>
      <ConnectionErrorSVG className='mx-auto mb-4' />
      <span className='mt-5 block text-center text-2xl font-bold text-gray-700'>Something went wrong!</span>
      <span className='mt-2 block text-center text-gray-700'>
        We could not connect to our cloud provider. Please try to reload the page. If the issue keeps happening, contact
        our support at {SUPPORT_EMAIL}.
      </span>

      <Button primary className='mt-6' icon='reload' onClick={() => window.location.reload()}>
        Reload page
      </Button>
    </div>
  );
};
