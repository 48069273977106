import React from 'react';

import { format as timeagoFormat } from 'timeago.js';

import { Table } from '@components/shared/Table';

import { DefinitionFn, DefinitionFnReturn } from '../types';

export const activityStudiesColumnDefs: DefinitionFn = ({ getColumnLabel }) => {
  const columns: DefinitionFnReturn = [
    {
      id: 'updated_at',
      minSize: 50,
      size: 100,
      accessorKey: 'updated_at',
      header: (props) => (
        <Table.ColumnHeader sortIconType='date' {...props}>
          {getColumnLabel('updated_at')}
        </Table.ColumnHeader>
      ),
      cell: (props) => (
        <Table.Cell
          {...props}
          render={({ row }) => <span className='truncate px-2'>{timeagoFormat(row.original.updated_at as Date)}</span>}
        />
      )
    },
    {
      id: 'created_at',
      minSize: 50,
      size: 100,
      accessorKey: 'created_at',
      header: (props) => (
        <Table.ColumnHeader sortIconType='date' {...props}>
          {getColumnLabel('created_at')}
        </Table.ColumnHeader>
      ),
      cell: (props) => (
        <Table.Cell
          {...props}
          render={({ row }) => <span className='truncate px-2'>{timeagoFormat(row.original.created_at as Date)}</span>}
        />
      )
    }
  ];

  return columns;
};
