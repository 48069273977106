import React, { useEffect, useMemo, useState } from 'react';

import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { useDebouncedCallback } from 'use-debounce';

import { track } from '@components/tracking';
import { compact, uid } from '@components/utils';
import { useCollectionView } from '@stores/view';

import { makeStateFromProxy } from '../components/segments/utils';
import { FilterDefinition, FilterState, GlobalFilter } from '../types';
import { decode } from '../utils/decode';
import { encode } from '../utils/encode';

import { buildGlobalFilter } from './buildGlobalFilter';
import { buildRawFilter } from './buildRawFilter';

export const getDefaultOp = (queryParam: string): UseTableFilters<any>['op'] => {
  const { op } = qs.parse(queryParam, { ignoreQueryPrefix: true });
  return op === 'any' ? 'any' : 'all';
};

export const defaultEligibilityFilterState = <D>(defs: FilterDefinition<D>[]): FilterState<D> | null => {
  const definition = defs.find((d) => d.id === 'eligibility');

  if (definition) {
    return makeNewFilterState(definition, {});
  } else {
    return null;
  }
};
const makeNewFilterState = <D>(definition: FilterDefinition<D>, filterMetas: Record<string, any>) =>
  ({
    id: uid(),
    definition,
    operator: definition.defaultOperator,
    value: definition.defaultValue || null,
    allowNotSet: definition.allowNotSet || false,
    meta: filterMetas[definition.id]
  }) as FilterState<D>;

export type Params<D extends object> = {
  trackKey: string;
  defaultFilters?: FilterState<D>[];
  initialState?: {
    filters?: ProxyFilterState[];
    op?: FilterOp;
  };
  definitions: FilterDefinition<D>[];
  syncWithURL?: boolean;
  query?: string;
  onChange?: (state: Pick<UseTableFilters<D>, 'filters' | 'op'>) => void;
  syncWithView?: boolean;
};

export type UseTableFilters<D extends object> = {
  op: FilterOp;
  setOp: React.Dispatch<React.SetStateAction<FilterOp>>;
  definitions: FilterDefinition<D>[];
  filters: FilterState<D>[];
  setFilters: React.Dispatch<React.SetStateAction<FilterState<D>[]>>;
  addFilter: (definition: FilterDefinition<D>) => void;
  removeFilter: (id: number) => void;
  replaceFilters: (definition: FilterDefinition<D>[]) => void;
  clearFilters: () => void;
  changeFilterState: (id: number, state: FilterState<D>) => void;
  globalFilter?: (rows: Row<D>[], columnIds: string[]) => Row<D>[];
  rawFilter: (records: D[]) => D[];
  openFilter: number | null;
  setOpenFilter: (id: number | null) => void;
  filterMetas: { [id: string]: any };
};

export const useTableFilters = <D extends object>(params: Params<D>): UseTableFilters<D> => {
  const { definitions, syncWithView = true } = params;
  const viewHook = useCollectionView();
  const [filterMetas, setFilterMetas] = useState<{ [id: string]: any }>({});
  const getFilterMetaById = (id: string) => filterMetas[id];

  const setFilterMetaById = (id: string, value: any) => {
    setFilterMetas({ ...filterMetas, [id]: value });

    filters.forEach((f) => {
      if (f.definition.id === id) {
        changeFilterState(f.id, { meta: value });
      }
    });
  };

  const navigate = useNavigate();

  const { pathname, search } = useLocation();

  const [op, setOp] = useState<FilterOp>('all');

  const [filters, setFilters] = useState<FilterState<D>[]>([]);

  const setFiltersWithMetas = (newFilters: FilterState<D>[]) => {
    const filtersWithMeta = newFilters.map((filter) => ({
      ...filter,
      meta: filter.meta || filterMetas[filter.definition.id]
    }));
    setFilters(filtersWithMeta);

    if (syncWithView) {
      viewHook.setView?.({ filters: encode(op, filtersWithMeta) });
    }
  };

  const { callback: debouncedSetURL } = useDebouncedCallback(() => {
    const query = encode(op, filters, params.query);

    if (search !== query) {
      navigate(`?${query}${location.hash}`, { replace: true });
    }
  });

  const removeFilter = (id: number) => {
    const newFilters = filters.filter((f) => f.id !== id);

    setFilters(newFilters);
    if (syncWithView) {
      viewHook.setView?.({ filters: encode(op, newFilters) });
    }
    params?.onChange?.({ filters: newFilters, op });
  };

  const addFilter = (definition: FilterDefinition<D>) => {
    track('add_filter', {
      table: params.trackKey,
      type: definition.type,
      namxe: definition.type === 'segment' ? 'Segment' : definition.name
    });

    const newFilter = makeNewFilterState(definition, filterMetas);
    const newFilters = [...filters, newFilter];

    setFilters(newFilters);
    if (syncWithView) {
      viewHook.setView?.({ filters: encode(op, newFilters) });
    }
    params?.onChange?.({ filters: newFilters, op });

    if (definition.type !== 'segment') {
      setOpenFilter(newFilter.id);
    }
  };

  const changeFilterState = (id: number, state: Partial<FilterState<D>>) => {
    const newFilters = filters.map((f) => (f.id === id ? { ...f, ...(state as any) } : f));

    setFiltersWithMetas(newFilters);

    params?.onChange?.({ filters: newFilters, op });
  };

  const clearFilters = () => {
    setFilters([]);
    if (syncWithView) {
      viewHook.setView?.({ filters: encode(op, []) });
    }
    params?.onChange?.({ filters: [], op });
  };

  const replaceFilters = (definitions: FilterDefinition<D>[]) => {
    const newFilters = definitions.map((d) => makeNewFilterState(d, filterMetas));

    setFilters(newFilters);
    if (syncWithView) {
      viewHook.setView?.({ filters: encode(op, newFilters) });
    }
    params?.onChange?.({ filters: newFilters, op });
  };

  const [globalFilter, setGlobalFilter] = useState<GlobalFilter<D>>(() => (rows) => rows);

  const rawFilter = useMemo(() => buildRawFilter<D>(filters, op), [op, filters]);

  useEffect(() => {
    setGlobalFilter(() => buildGlobalFilter<D>(filters, op));
  }, [op, filters]);

  useEffect(() => {
    if (params.defaultFilters && !search && params.syncWithURL && definitions) {
      setFiltersWithMetas(params.defaultFilters);
    }
  }, [params.syncWithURL, params.defaultFilters]);

  useEffect(() => {
    if (params.syncWithURL) {
      setOp(getDefaultOp(search));
    }
  }, [params.syncWithURL]);

  useEffect(() => {
    if (params.syncWithURL) {
      debouncedSetURL();
    }
  }, [params.syncWithURL, filters, op, params.query]);

  params.definitions.forEach((definition) => {
    if (!definition.hook) {
      return;
    }

    definition.hook({
      state: filters.find((f) => f.definition.id === definition.id),
      meta: getFilterMetaById(definition.id),
      setMeta: (meta) => setFilterMetaById(definition.id, meta)
    });
  });

  useEffect(() => {
    const decoded = decode(search, definitions);

    if (decoded.length > 0) {
      setFiltersWithMetas(decoded);
    }
  }, [pathname, definitions, filterMetas]);

  useEffect(() => {
    if (params.initialState?.op) {
      setOp(params.initialState.op);
    }

    if (params.initialState?.filters) {
      const initialFilters = compact(
        params.initialState.filters.map((filter) => {
          const d = definitions.find(({ id }) => id === filter.id);
          if (d) {
            return makeStateFromProxy(d, filter);
          }
        })
      );
      setFilters(initialFilters);
      if (syncWithView) {
        viewHook.setView?.({ filters: encode(params.initialState?.op || op, initialFilters) });
      }
    }
  }, [definitions, syncWithView]);

  useEffect(() => {
    if (
      syncWithView &&
      viewHook.view?.filters !== undefined &&
      params.syncWithURL &&
      search !== viewHook.view.filters
    ) {
      setOp(getDefaultOp(viewHook.view.filters));
      setFilters(decode(viewHook.view.filters, definitions));
    }
  }, [viewHook.view?.filters, definitions]);

  const [openFilter, setOpenFilter] = useState<number | null>(null);

  return {
    op,
    setOp,
    definitions,
    filters,
    setFilters: setFiltersWithMetas,
    addFilter,
    removeFilter,
    replaceFilters,
    changeFilterState,
    clearFilters,
    globalFilter,
    rawFilter,
    openFilter,
    setOpenFilter,
    filterMetas
  };
};
