import React from 'react';

import pluralize from 'pluralize';

import { Text } from '@components/common';

import { CheckedSVG, GuestsSVG, UserProfileSVG } from '../eventPopupSvgs';

interface Props {
  guests: {
    name?: string;
    email: string;
    status: 'yes' | 'maybe' | 'no' | 'noreply';
  }[];
}

export const NylasEventGuests: React.FC<React.PropsWithChildren<Props>> = ({ guests }) => {
  return (
    <>
      <div className='flex h-6 items-center'>
        <GuestsSVG />
        <Text h='400' className='ml-4'>
          {pluralize('guests', guests.length, true)}
        </Text>
      </div>

      <div className='ml-8 pb-6'>
        <div className='relative mt-4 max-h-52 w-full space-y-3 overflow-auto'>
          {guests.map((guest) => (
            <div key={guest.email} className='flex items-center'>
              <div className='relative flex h-4 w-4 items-center justify-center rounded-full'>
                <UserProfileSVG />
                {guest.status === 'yes' && (
                  <div className='absolute -right-1 bottom-0 z-10 flex h-2 w-2 items-center justify-center rounded-full bg-green-600'>
                    <CheckedSVG />
                  </div>
                )}
              </div>

              <Text h='400' truncate className='ml-4' key={guest.email}>
                {guest.email}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
