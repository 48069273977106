import * as React from 'react';
import { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { useToaster } from '@stores/toaster';
import { useTransitionStudy } from 'components/StudiesApp/hooks/useTransitionStudy';

import * as toasts from '../../../toasts';
import { StudyActionModal, StudyActionModalProps } from '../StudyActionModal/StudyActionModal';

export const ArchiveStudyModal: React.FC<React.PropsWithChildren<StudyActionModalProps>> = (props) => {
  const showToast = useToaster();
  const { transition, isLoading } = useTransitionStudy({
    id: props.id,
    transition: 'archive',
    onSuccess: () => {
      showToast(toasts.successArchive());
      props.onAfterSubmit?.();
      props.onClose();
    },
    onError: () => {
      showToast(toasts.failedArchive());
      props.onClose();
    }
  });

  return (
    <StudyActionModal
      {...props}
      icon='danger'
      heading='Archive study'
      loading={isLoading}
      primaryButtonProps={{
        danger: true,
        children: 'Archive'
      }}
      onSubmit={async () => {
        transition();
      }}
    >
      Are you sure you want to permanently archive “{props.title}“?
    </StudyActionModal>
  );
};
