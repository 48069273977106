const studyTrackFields = [
  'focus_group',
  'continuous',
  'id',
  'workspace',
  'title',
  'slug',
  'created_at',
  'updated_at',
  'deleted_at',
  'creator_id',
  'owner_id',
  'external_platform',
  'state',
  'style',
  'label',
  'has_screener',
  'maximum_slots',
  'session_capacity',
  'duration_in_minutes',
  'incentive',
  'incentive_method',
  'has_incentive',
  'video_url_type',
  'tags',
  'user_ids',
  'moderated',
  'sample',
  'time_proposals_enabled',
  'waitlist_enabled',
  'team_id',
  'external_candidates_enabled',
  'has_external_candidates_requests',
  'device_type',
  'live_stream_enabled'
] as const;

export type StudyTrack = Pick<Study, (typeof studyTrackFields)[number]>;

export const formatStudyToTrack = (study: Study): StudyTrack => {
  return Object.fromEntries(studyTrackFields.map((field) => [field, study[field]])) as StudyTrack;
};
