import * as React from 'react';

import { Text } from '@components/common';

type Props = {
  highlight?: DocumentHighlight;
};

export const renderHighlightContent = (content: HighlightContentElement[]) => {
  return (
    <>
      {content.map((c, i) => {
        if (c.type === 'image') {
          return <img key={i} src={c.src} alt='' className='my-6 h-auto w-full' />;
        } else {
          return (
            <Text key={i} h='500'>
              {c.text}
            </Text>
          );
        }
      })}
    </>
  );
};

export const HighlightContent: React.FC<React.PropsWithChildren<Props>> = ({ highlight }) => {
  if (!highlight) return null;

  if (!highlight?.content?.length) return <div className='mt-4 px-6'>{highlight.text}</div>;

  return <div className='my-4 px-6'>{renderHighlightContent(highlight.content)}</div>;
};
