import * as React from 'react';
import { createContext, useContext, useReducer } from 'react';

import { usePermission } from '@hooks/usePermission';

import { BulkZoomImportModal } from './modals/BulkZoomImportModal';
import { UploadManagerZDS } from './UploadManager/UploadManagerZDS/UploadManagerZDS';
import { UploadManager } from './UploadManager';

export type State = {
  modalOpen: boolean;
  managerOpen: boolean;
  managerZDSOpen: boolean;
  managerItems: UploadManagerItem[];
  studyId?: number;
  uploadFinished: boolean;
};

const INITIAL_STATE: State = {
  modalOpen: false,
  managerZDSOpen: false,
  managerOpen: false,
  managerItems: [],
  studyId: undefined,
  uploadFinished: false
};

export type Context = { state: State; dispatch: React.Dispatch<Action> };

export const context = createContext<Context>({} as Context);

export type Action =
  | { type: 'OPEN_MODAL'; studyId?: number }
  | { type: 'SUBMIT_MODAL'; items: UploadManagerItem[] }
  | { type: 'CLOSE_MODAL' }
  | { type: 'OPEN_MANAGER'; items: UploadManagerItem[] }
  | { type: 'ADD_ITEMS'; items: UploadManagerItem[] }
  | { type: 'SET_MANAGER_ITEM'; item: Patch<UploadManagerItem> }
  | { type: 'CLOSE_MANAGER' }
  | { type: 'FINISH_UPLOAD' }
  | { type: 'OPEN_MANAGER_ZDS'; studyId?: number }
  | { type: 'CLOSE_MANAGER_ZDS' };

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { ...state, modalOpen: true, studyId: action.studyId };
    case 'CLOSE_MODAL':
      return { ...state, modalOpen: false };
    case 'OPEN_MANAGER_ZDS':
      return { ...state, managerZDSOpen: true, studyId: action.studyId };
    case 'CLOSE_MANAGER_ZDS':
      return { ...state, managerZDSOpen: false };
    case 'SUBMIT_MODAL':
      return {
        ...state,
        modalOpen: false,
        managerZDSOpen: false,
        managerOpen: true,
        managerItems: state.managerOpen ? [...state.managerItems, ...action.items] : action.items
      };

    case 'OPEN_MANAGER':
      return { ...state, modalOpen: false, managerOpen: true, managerItems: action.items };
    case 'CLOSE_MANAGER':
      return { ...state, managerOpen: false, uploadFinished: true };
    case 'SET_MANAGER_ITEM':
      return {
        ...state,
        managerItems: state.managerItems.map(
          (item) => (item.id === action.item.id ? { ...item, ...action.item } : item) as UploadManagerItem
        )
      };
    case 'ADD_ITEMS':
      return {
        ...state,
        managerItems: [...state.managerItems, ...action.items]
      };
    case 'FINISH_UPLOAD':
      return {
        ...state,
        uploadFinished: false
      };
    default:
      return state;
  }
};

export const Provider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const bulkUploadEnabled = usePermission('canCreate')();

  return (
    <context.Provider value={{ state, dispatch }}>
      {children}
      {state.modalOpen && <BulkZoomImportModal />}
      {state.managerZDSOpen && <UploadManagerZDS />}
      {state.managerOpen && <UploadManager />}
    </context.Provider>
  );
};

export const useBulkUploadState = (): State => useContext(context).state;

type ActionsHook = {
  submitModal: (items: UploadManagerItem[]) => void;
  openZoomImportModal: (studyId?: number) => void;
  openManager: (items: UploadManagerItem[]) => void;
  openManagerZDS: (studyId?: number) => void;
  closeManagerZDS: () => void;
  closeModal: () => void;
  closeManager: () => void;
  setItem: (item: Patch<UploadManagerItem>) => void;
  addItems: (item: UploadManagerItem[]) => void;
  finishUpload: () => void;
  state: State;
};
export const useBulkUploadActions = (): ActionsHook => {
  const { state, dispatch } = useContext(context);

  return {
    openManager: (items: UploadManagerItem[]) => dispatch({ type: 'OPEN_MANAGER', items: items }),
    openManagerZDS: (studyId?: number) => dispatch({ type: 'OPEN_MANAGER_ZDS', studyId }),
    closeManagerZDS: () => dispatch({ type: 'CLOSE_MANAGER_ZDS' }),
    submitModal: (items) => dispatch({ type: 'SUBMIT_MODAL', items }),
    openZoomImportModal: (studyId?: number) => dispatch({ type: 'OPEN_MODAL', studyId }),
    closeModal: () => dispatch({ type: 'CLOSE_MODAL' }),
    closeManager: () => dispatch({ type: 'CLOSE_MANAGER' }),
    setItem: (item: Patch<UploadManagerItem>) => dispatch({ type: 'SET_MANAGER_ITEM', item }),
    addItems: (items: UploadManagerItem[]) => dispatch({ type: 'ADD_ITEMS', items }),
    finishUpload: () => dispatch({ type: 'FINISH_UPLOAD' }),
    state
  };
};
