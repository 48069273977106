import { Mark, Node } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { findChildrenByMark, findChildrenByType, findParentDomRefOfType, NodeWithPos } from 'prosemirror-utils';
import { EditorView } from 'prosemirror-view';
import { Editor, getMarkRange, getMarkType, getNodeType, MarkRange, NodeRange, Range } from '@tiptap/core';

import { HighlightAttributes } from '../extensions';

export const findMarksByHighlightId = (state: EditorState, highlightId: number) =>
  findChildrenByMark(state.doc, state.schema.marks.highlight).filter(({ node }) =>
    node.marks.find((mark) => mark.attrs.highlightId === highlightId)
  );

export const getAllNodesOfType = (state: EditorState, type: string, predicate?: (node: NodeWithPos) => boolean) => {
  const nodes = findChildrenByType(state.doc, getNodeType(type, state.schema));

  return predicate ? nodes.filter(predicate) : nodes;
};

export const getAllMarksOfType = (state: EditorState, type: string, predicate?: (mark: NodeWithPos) => boolean) => {
  const marks = findChildrenByMark(state.doc, getMarkType(type, state.schema));

  return predicate ? marks.filter(predicate) : marks;
};

export const getActiveMarkRange = (state: EditorState, type: string): MarkRange | void => {
  if (!state.schema.marks[type]) return;

  const { $to } = state.selection;
  const markType = getMarkType(type, state.schema);
  const mark = $to.marks().find((mark) => mark.type === markType);
  const range = getMarkRange(state.selection.$to, markType);

  if (mark && range) {
    const { to, from } = range;
    return {
      to,
      from,
      mark
    };
  }
};

export const getActiveHighlightRange = (state: EditorState): MarkRange | void => {
  const { $to } = state.selection;
  const markType = getMarkType('highlight', state.schema);
  const mark = $to.marks().find((mark) => mark.type === markType);

  if (mark) {
    const marks = findMarksByHighlightId(state, (mark.attrs as HighlightAttributes).highlightId);

    if (!marks.length) return;

    if (marks.length === 1) {
      const [{ node, pos }] = marks;
      return { from: pos, to: pos + node.nodeSize, mark };
    }

    const [firstMark] = marks;
    const lastMark = [...marks].pop() as NodeWithPos;

    return { from: firstMark.pos, to: lastMark.pos + lastMark.node.nodeSize, mark };
  }
};

export const getTextSelection = (editor: Editor, range?: Range | void) => {
  const { selection } = editor.state;
  let { from, to } = selection;

  if (range) {
    from = range.from;
    to = range.to;
  }

  return editor.state.doc.textBetween(from, to, '\n');
};

export const getNodesSelection = (editor: Editor, callback: any, range?: Range | void) => {
  const { selection } = editor.state;
  let { from, to } = selection;

  if (range) {
    from = range.from;
    to = range.to;
  }

  editor.state.doc.nodesBetween(from, to, callback);
};

export const getLargestRange = (...ranges: Range[]): Range => {
  let target = ranges[0];
  let diff = 0;

  ranges.forEach((range) => {
    if (range.to - range.from > diff) {
      target = range;
      diff = range.to - range.from;
    }
  });

  return target;
};

export const findActiveNode = (editor: Editor, type: string) =>
  findChildrenByType(editor.state.doc, getNodeType(type, editor.schema)).find(
    ({ pos }) => pos === editor.state.selection.from
  );

export const getMarksInRange = (editor: Editor, range: Range, type: string) => {
  const words: Mark[] = [];

  editor.state.doc.nodesBetween(range.from, range.to, (node) => {
    const found = node.marks.find((mark) => mark.type.name === type);

    if (found) {
      words.push(found);
    }
  });

  return words;
};

export const getMarksRangeInRange = (
  editor: Editor,
  range: Range,
  type: string,
  predicate?: (range: MarkRange) => boolean
) => {
  const ranges: MarkRange[] = [];

  editor.state.doc.nodesBetween(range.from, range.to, (node, pos) => {
    const mark = node.marks.find((mark) => mark.type.name === type);

    if (mark) {
      ranges.push({ from: pos, to: pos + node.nodeSize, mark });
    }
  });

  return predicate ? ranges.filter(predicate) : ranges;
};

export const getNodeRangeFromInitialPosition = (editor: Editor, type: string, initialPos: number): NodeRange | void => {
  const [target] = getAllNodesOfType(editor.state, type, ({ pos }) => pos === initialPos);

  if (target) {
    return {
      from: target.pos + 1,
      to: target.pos + target.node.nodeSize,
      node: target.node
    };
  }
};

// get the closest transcript dom to the current selection
// usefull to get a highlight parent transcript dom element
export const findTranscriptNodeDomRef = (editor: Editor, view: EditorView): Element => {
  const domAtPos = view.domAtPos.bind(view);

  return findParentDomRefOfType(editor.state.schema.nodes.transcript, domAtPos)(view.state.selection) as Element;
};

export const getTranscriptNodeTimestampRange = (node: Node): Range => {
  const { firstChild, lastChild } = node.content;
  const range: Range = { from: 0, to: 0 };

  if (firstChild && lastChild) {
    [firstChild, lastChild].forEach((n, i) => {
      const mark = n.marks.find((m) => m.type.name === 'transcript_word');

      if (!mark) return;

      if (i === 0) {
        range.from = mark.attrs.start_ts ?? 0;
      } else {
        range.to = mark.attrs.end_ts ?? 0;
      }
    });
  }

  return range;
};

export const getAiSuggestionRange = (
  state: EditorState,
  paragraphIndex: number,
  startIndex: number,
  endIndex: number
): Range | void => {
  const nodes = getAllNodesOfType(state, 'transcript');
  const paragraph = nodes[paragraphIndex - 1];

  if (!paragraph) return;

  let from: number | null = null;
  let to: number | null = null;

  paragraph.node.content.forEach((node, pos, i) => {
    if (i === startIndex) {
      from = pos + paragraph.pos + 1;
    }

    if (i === endIndex) {
      to = pos + node.nodeSize + paragraph.pos + 1;
    }
  });

  if (from === null || to === null) return;

  return { from, to };
};

export const isTranscriptDocumentEmpty = (state: EditorState): boolean => {
  const nodeTypes = state.doc.toJSON().content.map(({ type }) => type);

  return nodeTypes.includes('recording') && !nodeTypes.includes('transcript');
};
