import { FIELD_CONFIGS } from '@components/GQSurveyBuilder/QuestionCard';
import { Toast } from '@stores/toaster';

export const invalidAttr = (attr_type: string, field_type: ScreenerField['field_type']): Toast => ({
  heading: 'Invalid attribute selection',
  text: `${FIELD_CONFIGS[field_type].label} questions cannot be mapped to ${attr_type} fields.`,
  icon: 'error'
});

export const readOnlyAttr = (): Toast => ({
  heading: 'Read-only attribute',
  text: 'This attribute is read-only and cannot be mapped to a question.',
  icon: 'error'
});

export const invalidAttrName = (name: string): Toast => ({
  heading: 'Invalid attribute name',
  text: `"${name}" is not a permitted attribute name.`,
  icon: 'error'
});
