import React, { PropsWithChildren, useMemo } from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { Item, Menu, MenuProps } from '@components/common/Menu';
import { SORT_ICONS } from '@components/shared/GridTable/components/OtherHeader/constants';
import { Tooltip } from '@components/Tooltip';
import { move } from '@helpers/move';

import * as Icons from '../icons';
import { ColumnHeaderProps } from '../types';

export const ColumnHeader = <D extends Record<string, any>>({
  isStatic = false,
  header,
  table,
  children,
  disableHide = false,
  disableSort = false,
  sortIconType = 'text',
  tooltipText = '',
  addFilter,
  render
}: PropsWithChildren<ColumnHeaderProps<D>>) => {
  const columnId = header.column.id;
  const sortCopy = SORT_ICONS[sortIconType];

  const handleOnItemSelect: MenuProps['onItemSelect'] = (value) => {
    switch (value) {
      case 'hide':
        table.setColumnVisibility((previousVisibility) => {
          return { ...previousVisibility, [columnId]: false };
        });
        break;
      case 'filter':
        addFilter?.(columnId);
        break;
      case 'sort_asc':
        table.options.meta?.onSort?.({ value: columnId, desc: false });
        break;
      case 'sort_desc':
        table.options.meta?.onSort?.({ value: columnId, desc: true });
        break;
      case 'move_left':
      case 'move_right': {
        table.setColumnOrder((state) => {
          const columnVisibility = table.getState().columnVisibility;
          const visibleColumns = Object.keys(columnVisibility).filter((key) => columnVisibility[key]);
          const allColumns = table.getFlatHeaders().map((header) => header.id);

          const defaultColumnOrder = visibleColumns.length > 0 ? visibleColumns : allColumns;

          const previousOrder: string[] = state.length > 0 ? state : defaultColumnOrder;
          const index = previousOrder.findIndex((id) => id === columnId);

          if (
            (value === 'move_left' && index === 0) ||
            (value === 'move_right' && index === previousOrder.length - 1)
          ) {
            return previousOrder;
          }

          return move<string>(previousOrder, index, index + (value === 'move_left' ? -1 : 1));
        });
        break;
      }
      default:
        break;
    }
  };

  const dropdownDisabled =
    isStatic || (disableHide && disableSort && !addFilter && Boolean(header.column.getIsPinned()));

  return render ? (
    render()
  ) : (
    <Menu
      onItemSelect={handleOnItemSelect}
      className='w-60 overflow-hidden rounded-md border border-gray-200 bg-white text-sm shadow-lg'
      popperProps={{ placement: 'bottom-end', zIndex: 10, isDisabled: dropdownDisabled }}
      renderTrigger={({ isOpen }) => (
        <div
          className={cn('group relative flex h-11 items-center justify-between border-b border-gray-200 bg-white', {
            'cursor-pointer': !dropdownDisabled
          })}
        >
          <Tooltip content={tooltipText} isDisabled={!tooltipText} width={200}>
            <span className='h400 ml-2 flex items-center truncate font-bold'>{children}</span>
          </Tooltip>
          {!dropdownDisabled && (
            <Icons.ChevronDown
              className={cn('mr-2 flex-shrink-0 opacity-0 group-hover:opacity-100', { 'opacity-100': isOpen })}
            />
          )}
          {!isStatic && (
            <div
              className={cn(
                'cursor-col-resize absolute right-0 top-0 h-full w-1 select-none bg-gray-500 opacity-0 hover:opacity-100'
              )}
              onMouseDown={header.getResizeHandler()}
            />
          )}
        </div>
      )}
    >
      {addFilter && (
        <Item value='filter' className='flex items-center px-4 py-2 hover:bg-indigo-600 hover:text-white'>
          <Icons.Filter className='mr-2' /> Filter
        </Item>
      )}
      {!disableHide && (
        <Item value='hide' className='flex items-center px-4 py-2 hover:bg-indigo-600 hover:text-white'>
          <Icons.Hide className='mr-2' /> Hide
        </Item>
      )}
      {!disableSort && (
        <>
          <Item value='sort_asc' className='flex items-center px-4 py-2 hover:bg-indigo-600 hover:text-white'>
            <sortCopy.ascending.Icon className='mr-2' />
            <Text h='400'>{sortCopy.ascending.text}</Text>
          </Item>
          <Item value='sort_desc' className='flex items-center px-4 py-2 hover:bg-indigo-600 hover:text-white'>
            <sortCopy.descending.Icon className='mr-2' />
            <Text h='400'>{sortCopy.descending.text}</Text>
          </Item>
        </>
      )}
      {!header.column.getIsPinned() && (
        <>
          <Item value='move_left' className='flex items-center px-4 py-2 hover:bg-indigo-600 hover:text-white'>
            <Icons.MoveLeft className='mr-2' /> Move to left
          </Item>
          <Item value='move_right' className='flex items-center px-4 py-2 hover:bg-indigo-600 hover:text-white'>
            <Icons.MoveRight className='mr-2' /> Move to right
          </Item>
        </>
      )}
    </Menu>
  );
};
