import * as React from 'react';

import { HeaderProps } from 'react-table';

import { PopperDropdown } from '@components/common';
import IndeterminateCheckbox from '@components/common/tables/IndeterminateCheckbox';
import { ColumnsActionsDropdown } from '@components/shared/GridTable/components/shared';
import { renderTooltip } from '@components/shared/GridTable/components/utils';
import { ParticipationTableItem } from '@components/StudiesApp/components/StudyPublished/ParticipationTable/helpers/buildParticipantsColumns';
import { ChevronDownSVG } from '@components/svgs';

import { NAME_CHECKBOX_STYLE } from '../constants';

interface NameHeaderProps extends React.PropsWithChildren<HeaderProps<Candidate | ParticipationTableItem>> {
  resetOnClick: () => void;
  pageCount: number;
  setShowSelectAll?: (value: boolean) => void;
  addFilter?: (value: string) => void;
  description?: string;
}

export const NameHeader: React.FC<React.PropsWithChildren<NameHeaderProps>> = ({
  getToggleAllRowsSelectedProps,
  resetOnClick,
  column,
  setSortValue,
  pageCount,
  setShowSelectAll,
  setAllSelected,
  addFilter,
  description
}) => {
  const changeSort = (descending: boolean): void => {
    setSortValue(column.id, descending);
    column.toggleSortBy?.(descending);
  };

  const { onChange, checked, ...rest } = getToggleAllRowsSelectedProps();
  const allRowsSelectedProps = {
    ...rest,
    checked,
    onChange: (e) => {
      if (pageCount > 1) {
        if (checked) {
          setShowSelectAll?.(false);
          setAllSelected?.(false);
        } else {
          setShowSelectAll?.(true);
        }
      }
      (onChange as (e) => void)(e);
    }
  };

  return (
    <div className='flex h-full w-full items-center overflow-visible pr-2'>
      <div className='h400 relative flex-shrink-0 overflow-visible bg-white text-center' style={NAME_CHECKBOX_STYLE}>
        <IndeterminateCheckbox {...allRowsSelectedProps} onClick={resetOnClick} />
      </div>
      <div className='h400 flex h-full w-full cursor-pointer items-center overflow-hidden px-2 font-bold hover:bg-gray-50 hover:text-indigo-600'>
        <PopperDropdown
          hideCaret
          popperProps={{ placement: 'bottom-end', renderOnBodyRoot: false }}
          className='h400 group flex h-full flex-1 flex-shrink-0 cursor-pointer flex-col items-stretch px-2 font-bold'
          tooltip={renderTooltip('Name', description)}
          text={
            <div className='flex flex-1 justify-between pl-2'>
              <span className='xx-column-header flex flex-grow truncate font-bold' title='Name'>
                Name
              </span>
              <ChevronDownSVG className='hidden text-gray-500 group-hover:block' />
            </div>
          }
        >
          <ColumnsActionsDropdown
            addFilter={addFilter}
            changeSort={changeSort}
            columnAccessor={column.id}
            sortIconType='text'
          />
        </PopperDropdown>
      </div>
    </div>
  );
};
