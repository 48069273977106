import * as React from 'react';

import { Alert, Card, Loading, Text } from '@components/common';
import { usePermission } from '@hooks/usePermission';

import { getAutomationKeys } from '../helpers/getAutomationsKeys';
import { useStudySettings } from '../hooks/useStudySettings';

import { Setting } from './Setting';

interface Props {
  study: Study;
}

export const AutomationSettings: React.FC<React.PropsWithChildren<Props>> = ({ study }) => {
  const { loading, settings, handleToggleSetting } = useStudySettings(study);
  const canUpdate = usePermission<Study>('updateStudy')(study);

  const autoKeys = React.useMemo(() => getAutomationKeys(study), []);
  return (
    <div className='px-page py-gutter'>
      <Text h='700' bold className='mb-2'>
        Automation
      </Text>
      <Card className='p-6'>
        {loading && <Loading absolute />}
        <Text h='500' bold>
          Automatically…
        </Text>
        {!canUpdate && (
          <Alert type='info' className='my-3'>
            As an Observer, you can't update these settings.
          </Alert>
        )}
        <div className='divide-y divide-gray-200'>
          {autoKeys.map((key) => (
            <Setting
              disabled={!canUpdate}
              study={study}
              settingKey={key}
              value={settings.automation[key]}
              onToggle={() => handleToggleSetting('automation', key)}
            />
          ))}
        </div>
      </Card>
    </div>
  );
};
