import React, { SVGProps } from 'react';

export const BankSVG: React.FC<React.PropsWithChildren<any>> = (props) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.875 14.5625H14.125'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.125 5.81246H1.875L7.52692 2.4513C7.66803 2.3606 7.83225 2.31238 8 2.31238C8.16775 2.31238 8.33197 2.3606 8.47308 2.4513L14.125 5.81246Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.25 7.5625V12.8125H11.5V7.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.875 7.5625V12.8125H7.125V7.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.5 7.5625V12.8125H2.75V7.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
