import * as React from 'react';

export const KeysSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.259 12.9979C11.8863 12.9114 12.4903 12.7012 13.0357 12.3796L16.9646 16.3037C17.1911 16.5222 17.4945 16.6432 17.8094 16.6404C18.1243 16.6377 18.4256 16.5115 18.6482 16.2891C18.8709 16.0666 18.9972 15.7657 19 15.4511C19.0027 15.1365 18.8816 14.8335 18.6629 14.6072L14.7315 10.6831C15.2709 9.77045 15.4927 8.70511 15.3623 7.65331C15.2319 6.60151 14.7566 5.62242 14.0106 4.86881C13.2646 4.1152 12.2899 3.62947 11.2386 3.4874C10.1872 3.34534 9.11834 3.55494 8.19876 4.08349M5.80409 6.44455C6.12262 6.44455 6.4281 6.57095 6.65334 6.79596C6.87857 7.02096 7.00511 7.32613 7.00511 7.64433C7.00511 7.96253 6.87857 8.2677 6.65334 8.49271C6.4281 8.71771 6.12262 8.84412 5.80409 8.84412C5.48556 8.84412 5.18007 8.71771 4.95484 8.49271C4.7296 8.2677 4.60307 7.96253 4.60307 7.64433C4.60307 7.32613 4.7296 7.02096 4.95484 6.79596C5.18007 6.57095 5.48556 6.44455 5.80409 6.44455ZM5.80409 6.44455L5.80409 2.79967C5.80409 2.32237 5.99389 1.86462 6.33174 1.52711C6.6696 1.18961 7.12782 1 7.60562 1C8.08342 1 8.54164 1.18961 8.8795 1.52711C9.21735 1.86462 9.40715 2.32237 9.40715 2.79967V3.59073M10.6082 8.24435C10.6079 7.3468 10.3558 6.4673 9.88027 5.70575C9.40477 4.9442 8.72503 4.33111 7.91823 3.93613C7.11143 3.54114 6.20993 3.38009 5.3161 3.47126C4.42227 3.56243 3.57195 3.90217 2.86172 4.45189C2.15148 5.00162 1.60979 5.73929 1.29818 6.58113C0.986574 7.42296 0.91753 8.33523 1.09889 9.2143C1.28025 10.0934 1.70475 10.904 2.32418 11.5542C2.9436 12.2043 3.73312 12.6679 4.60307 12.8923V17.8002C4.60307 18.1184 4.7296 18.4236 4.95484 18.6486C5.18007 18.8736 5.48556 19 5.80409 19C6.12262 19 6.4281 18.8736 6.65334 18.6486C6.87857 18.4236 7.00511 18.1184 7.00511 17.8002V12.8923C8.03654 12.6263 8.9502 12.0253 9.60243 11.1839C10.2547 10.3426 10.6084 9.3085 10.6082 8.24435Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
