import * as React from 'react';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Consumer, Subscription } from '@rails/actioncable';

import { useCreateAiChatMutation } from '@components/AiChat/api';
import type { AiChatPage } from '@components/AiChat/types';
import { useAccount } from '@hooks/useAccount';
import { useFeature } from '@hooks/useFeature';

type DockMode = 'popup' | 'slideout';

type Channel = Record<string, Subscription<Consumer>>;

export type State = {
  enabled: boolean;
  open: boolean;
  mode: DockMode;
  page: AiChatPage;
  context: AiChatContext | null;
  content: string;
  chat?: AiChat;
  createChat: (params: Pick<AiChat, 'context'>) => Promise<AiChat>;
  openWith: (page: AiChatPage, context: AiChatContext) => void;
  isChatLoading: boolean;
  isChatError: boolean;
  messages: AiMessage[];
  showBubble: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMode: React.Dispatch<React.SetStateAction<DockMode>>;
  setPage: React.Dispatch<React.SetStateAction<AiChatPage>>;
  setContext: React.Dispatch<React.SetStateAction<AiChatContext | null>>;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  setMessages: React.Dispatch<React.SetStateAction<AiMessage[]>>;
  setShowBubble: React.Dispatch<React.SetStateAction<boolean>>;
  channel: React.MutableRefObject<Channel>;
  reset: () => void;
};

export const aiChatContext = createContext<State>({} as State);

export const useAiChat = (): State => useContext(aiChatContext);

export const useShowAiChatBubble = (show = true) => {
  const { setShowBubble } = useAiChat();

  useEffect(() => {
    if (!show) {
      return;
    }
    setShowBubble(true);
    return () => setShowBubble(false);
  }, []);
};

export const Provider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const {
    account: { ai_enabled }
  } = useAccount();

  const enabled = ai_enabled;
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<DockMode>('slideout');
  const [page, setPage] = useState<AiChatPage>('search');
  const [context, setContext] = useState<AiChatContext | null>(null);
  const [content, setContent] = useState<string>('');
  const [messages, setMessages] = useState<AiMessage[]>([]);
  const [showBubble, setShowBubble] = useState(false);
  const channel = useRef<Channel>({});

  const [create, { isLoading: isChatLoading, data: chat, isError: isChatError, reset: resetCreateChat }] =
    useCreateAiChatMutation();

  const reset = useCallback(() => {
    setContent('');
    setMessages([]);
    if (chat) {
      resetCreateChat();
    }
  }, [chat, resetCreateChat]);

  const openWith = useCallback(
    (page: AiChatPage, newContext: AiChatContext) => {
      setPage(page);
      reset();
      setContext(newContext);
      setOpen(true);
    },
    [reset]
  );

  const state: State = {
    enabled,
    open,
    mode,
    page,
    context,
    content,
    chat,
    createChat: useCallback((params: any) => create(params).unwrap(), [create]),
    isChatLoading,
    isChatError,
    messages,
    showBubble,
    setOpen,
    setMode,
    setPage,
    setContext,
    setContent,
    setMessages,
    setShowBubble,
    channel,
    reset,
    openWith
  };

  return <aiChatContext.Provider value={state}>{children}</aiChatContext.Provider>;
};
