import React, { useState } from 'react';

import { Option } from '@components/common';
import { SelectDropdown } from '@components/shared/SelectDropdown';

interface Props {
  value: string[] | null;
  options?: { value: string; label: string }[];
  setEditMode: (value: boolean) => void;
  handleCellUpdate: (newValue: string[] | null) => void;
}

export const MultiselectInput: React.FC<React.PropsWithChildren<Props>> = ({
  value: initialValue,
  options,
  setEditMode,
  handleCellUpdate
}) => {
  const [localValue, setLocalValue] = useState<string[] | null>(initialValue);

  const onCellUpdate = (newValue: string[]) => {
    setLocalValue(newValue);
    handleCellUpdate(newValue);
  };

  const handleOnBlur = (selectedOptions: Option[]) => {
    const noValues = !localValue?.length && !selectedOptions?.length;
    const currentValue = Array.isArray(localValue) ? [...localValue] : [];
    const newValue = selectedOptions?.map(({ value }) => value);

    const valuesAreEqualArrays =
      Array.isArray(currentValue) &&
      Array.isArray(newValue) &&
      JSON.stringify(currentValue.sort()) === JSON.stringify(newValue.sort());

    if (noValues || valuesAreEqualArrays) {
      setEditMode(false);
      return;
    }

    onCellUpdate(newValue);
    setEditMode(false);
  };

  const selected = localValue?.map((v) => ({ value: v, label: v }));

  return (
    <SelectDropdown
      options={options ?? []}
      value={selected ?? []}
      onClose={handleOnBlur}
      overrides={{
        Input: {
          props: {
            placeholder: 'Enter…',
            autoFocus: true
          }
        },
        Item: {
          props: {
            className: 'xx-combo-option bg-white'
          }
        }
      }}
      onCreate={({ value }) => {
        onCellUpdate([value]);
        setEditMode(false);
      }}
      creatable
      multi
      defaultIsOpen
      keepSelectedOptions
      displayAllItems
      closeOnSelect={false}
    />
  );
};
