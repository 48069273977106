import type { Toast } from '@stores/toaster';

export const successUpdate: Toast = {
  heading: 'Updated successfully.',
  text: 'The booking has been updated.',
  icon: 'success'
};

export const failedUpdate: Toast = {
  heading: 'Couldn’t update booking.',
  text: 'Something went wrong. Please try again or contact support.',
  icon: 'error'
};
