import * as React from 'react';

import { api } from '@api/reduxApi';

import { TemplatePage } from '../TemplatePage';
import { TemplatesIndexPage } from '../TemplatesIndexPage';

import { buildColumns } from './buildColumns';
import { ShowEmailTemplate } from './show';

export const EmailTemplatesIndex: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <TemplatesIndexPage<EmailTemplate>
      kind='EmailTemplate'
      buildColumns={buildColumns}
      getQuery={api.useGetEmailTemplatesQuery as any}
      createMutation={api.useCreateEmailTemplateMutation as any}
    />
  );
};

export const EmailTemplatesShow: React.FC<React.PropsWithChildren<unknown>> = () => (
  <TemplatePage<EmailTemplate>
    kind='EmailTemplate'
    getQuery={api.useGetEmailTemplateQuery as any}
    render={([emailTemplate]) => <ShowEmailTemplate emailTemplate={emailTemplate} />}
    trackEvent='viewed_email_template'
  />
);
