import React from 'react';

// FIXME: Move to a better place
import { useGetBackgroundTasksQuery } from '@components/CandidatesApp/CandidatesIndex/api';
import { Loading, SlideOut, Text } from '@components/common';
import { useSidebar } from 'hooks/useSidebar';

import { BackgroundTaskItem } from './components/BackgroundTaskItem';

export const BackgroundTasksSlideOut: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { data, isLoading } = useGetBackgroundTasksQuery({});
  const { background_tasks } = useSidebar();
  if (!background_tasks.open) return null;
  return (
    <SlideOut title='Background Tasks' onClose={background_tasks.toggle}>
      <div>
        {isLoading && <Loading absolute />}
        <ul>
          {(data || []).map((d) => (
            <BackgroundTaskItem key={d.id} task={d} />
          ))}
        </ul>
      </div>
      {!isLoading && data && data.length === 0 && (
        <div className='px-6'>
          <Text>No background tasks from the past week</Text>
        </div>
      )}
    </SlideOut>
  );
};
