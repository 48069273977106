import React from 'react';

import { Button, Modal } from 'components/common';

export const RemoveIncentiveModal: React.FC<
  React.PropsWithChildren<{ study: Study; onClose: () => void; onConfirm: () => void }>
> = ({ onClose, onConfirm }) => {
  return (
    <Modal
      title='Remove Incentive'
      size='md'
      onClose={onClose}
      renderFooter={() => (
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button primary onClick={onConfirm}>
            Confirm
          </Button>
        </>
      )}
    >
      By removing the incentive from this study, all participants will not receive an incentive, including those already
      invited.
    </Modal>
  );
};
