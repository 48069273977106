import * as React from 'react';
import { useState } from 'react';

import { PageTitle } from '@components/common/helmets';
import { HideTabsSVG, ShowTabsSVG } from '@components/svgs';
import { Text } from 'components/common';

export const PageNavbar: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [showTabs, setShowTabs] = useState(true);

  if (!showTabs) {
    return (
      <button
        className='tablet:absolute desktop:top-3 tablet:top-2 tablet:left-2 desktop:left-6 tablet:m-0 tablet:self-start focus:outline-none z-10 ml-6 mr-4 mt-3 self-end hover:text-indigo-600'
        onClick={() => setShowTabs(true)}
      >
        <ShowTabsSVG />
      </button>
    );
  }
  return (
    <div className='tablet:w-60 tablet:flex-shrink-0 tablet:h-full tablet:overflow-y-auto tablet:border-r tablet:border-gray-200 flex w-full flex-col bg-white'>
      <div className='relative flex flex-shrink-0 items-center justify-between'>
        <nav className='flex flex-1 flex-col items-start pb-3'>
          <div className='flex w-full items-center justify-between pl-3'>
            <PageTitle>Settings</PageTitle>
            <Text className='mb-1 mt-3' bold>
              Settings
            </Text>
            <button
              className='tablet:mr-2 focus:outline-none mb-1 mr-4 mt-3 self-end hover:text-indigo-600'
              onClick={() => setShowTabs(false)}
            >
              <HideTabsSVG />
            </button>
          </div>
          {children}
        </nav>
      </div>
    </div>
  );
};
