export const VIDEO_CALL_TITLE = 'Interviews';
export const VIDEO_CALL_SUBTITLE = 'Schedule calls with participants.';

export const CONTINUOUS_TITLE = 'Continuous interviews';
export const CONTINUOUS_SUBTITLE = 'Schedule weekly calls with new participants.';

export const FOCUS_GROUP_TITLE = 'Focus groups';
export const FOCUS_GROUP_SUBTITLE = 'Schedule group calls with participants.';

export const SURVEY_TITLE = 'Survey';
export const SURVEY_SUBTITLE = 'Create and send out questions.';

export const UNMODERATED_TITLE = 'Unmoderated test';
export const UNMODERATED_SUBTITLE = 'Gather insights asynchronously.';

export const ONLINE_TASK_TITLE = 'Online task';
export const ONLINE_TASK_SUBTITLE = 'Launch a test from a different provider.';

export const PANEL_TITLE = 'Panel recruitment';
export const PANEL_SUBTITLE = 'Drive opt-ins to participate in future research.';
