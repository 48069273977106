import React, { FC } from 'react';

import { Text } from '@components/common';
import { Tooltip } from '@components/Tooltip';
import { useFeature } from '@hooks/useFeature';
import { useTeams } from '@hooks/useTeams';

interface Props {
  candidateTeamIds: number[];
  contactAccess: ContactAccessType;
}

const DEFAULT_NO_CONTACT_TEXT = 'No one can contact this candidate';

export const ContactAccessCell: FC<Props> = ({ candidateTeamIds, contactAccess }) => {
  const enableTeams = useFeature('teams');
  const { teams = [] } = useTeams({ skip: !enableTeams });

  const teamsToShow = teams.filter((team) => candidateTeamIds.includes(team.id));
  const tooltipText = teamsToShow.map((team) => team.name).join(', ') || DEFAULT_NO_CONTACT_TEXT;

  if (contactAccess === 'public') {
    return <div className='truncate px-2 text-sm'>Anyone on the workspace</div>;
  }

  return (
    <div className='px-2'>
      {contactAccess === 'needs_team' && teamsToShow.length > 0 ? (
        <Tooltip content={tooltipText}>
          <div className='truncate text-sm'>{teamsToShow.map((team) => team.name).join(', ')}</div>
        </Tooltip>
      ) : (
        <Text className='truncate text-sm'>No one</Text>
      )}
    </div>
  );
};
