import * as React from 'react';
import { useEffect, useState } from 'react';

import { setHash } from '@components/utils';

import { ArrowButton } from './ScreenerChart/components/ArrowButton';

interface UseBrowseButtonsHook<T> {
  thing: T | undefined;
  renderBrowseButtons: () => React.ReactNode;
}

interface Params<T> {
  things: T[] | undefined;
  initialIndex?: number;
  hashField?: keyof T;
}

export const useBrowseButtons = <T,>({ things, initialIndex = 0, hashField }: Params<T>): UseBrowseButtonsHook<T> => {
  const [idx, setIdx] = useState(initialIndex);

  useEffect(() => {
    setIdx(initialIndex);
  }, [initialIndex]);

  const thing = things?.[idx];

  const updateIndex = (index: number) => {
    setIdx(index);

    if (!things) return;

    if (hashField) {
      setHash(`${things[index][hashField]}`);
    }
  };

  const handleOnFirst = () => {
    updateIndex(0);
  };

  const handleOnPrevious = () => {
    updateIndex(idx <= 0 ? 0 : idx - 1);
  };

  const handleOnNext = () => {
    if (!things) return;

    updateIndex(idx >= things.length - 1 ? things.length - 1 : idx + 1);
  };

  const handleOnLast = () => {
    if (!things) return;

    updateIndex(things.length - 1);
  };

  return {
    thing,
    renderBrowseButtons: () =>
      things ? (
        <div className='flex items-center'>
          <ArrowButton disabled={idx === 0} type='first' onClick={handleOnFirst} />
          <ArrowButton disabled={idx === 0} type='prev' onClick={handleOnPrevious} />
          <div className='mx-4 text-sm text-gray-500'>
            {idx + 1} of {things.length}
          </div>
          <ArrowButton disabled={idx === things.length - 1} type='next' onClick={handleOnNext} />
          <ArrowButton disabled={idx === things.length - 1} type='last' onClick={handleOnLast} />
        </div>
      ) : null
  };
};
