import * as React from 'react';

export const CalendarEventSVG: React.FC = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z'
      fill='#E5EDFF'
    />
    <path
      d='M9 14.6H23M12.7333 12.2667V9M19.2667 12.2667V9M9 10.8667H23V23H9V10.8667Z'
      stroke='#252F3F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
