import React, { FC, useMemo, useState } from 'react';

import { Tabs, Toggle } from '@components/common';
import { getSelectedItemPath } from '@components/common/SortableTree';
import { ResultsTable } from '@components/ScreenerResults/components/TreeTestResponse/components/ResultsTable';
import { api } from '@components/SurveyBuilder';
import { InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { compact } from '@components/utils';

import { ResponseDetailsModal } from './components/ResponseDetailsModal';
import { ResponsesTable, TreeTestSummary } from './components';
import { LABELS, TABS } from './constants';
import { TabValues } from './types';

interface Props {
  answers: ScreenerResponseAnswerValue<'tree_test'>[];
  blockableId: number;
  title: string;
}

export const TreeTestResponse: FC<Props> = ({ title, answers, blockableId }) => {
  const [activeTab, setActiveTab] = useState<TabValues>('table');
  const [preview, setPreview] = useState<NonNullable<ScreenerResponseAnswerValue<'tree_test'>> | null>(null);
  const [showPercentage, setShowPercentage] = useState<boolean>(true);

  const { data, isLoading } = api.useGetSurveyBuilderTreeTestBlockableQuery(
    { id: blockableId },
    { skip: !blockableId }
  );

  const validAnswers = compact(answers) as NonNullable<ScreenerResponseAnswerValue<'tree_test'>>[];

  const correctAnswers = useMemo(() => getSelectedItemPath(data?.nodes || []), [data?.nodes]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'table':
        return (
          <ResponsesTable
            correctAnswers={correctAnswers}
            answers={validAnswers}
            nodes={data?.nodes || []}
            setPreview={setPreview}
          />
        );
      case 'results':
        return <ResultsTable answers={validAnswers} nodes={data?.nodes || []} showPercentage={showPercentage} />;
      default:
        return null;
    }
  };

  if (validAnswers.length === 0 || isLoading) {
    return null;
  }

  return (
    <div>
      <TreeTestSummary answers={validAnswers} correctAnswers={correctAnswers} />
      <div className='mb-4 flex items-center justify-between'>
        <Tabs<TabValues> current={activeTab} tabs={TABS} labels={LABELS} onSelect={setActiveTab} />
        {activeTab === 'results' && (
          <div className='flex items-center'>
            <Toggle on={showPercentage} onToggle={setShowPercentage} />
            <div className='h400 ml-2 mr-1'>Show % of participants</div>
            <Tooltip content='Toggle between the number of participants and % of participants.'>
              <InfoCircleIcon className='h-4 w-4' />
            </Tooltip>
          </div>
        )}
      </div>

      {renderTabContent()}
      {preview && data && (
        <ResponseDetailsModal
          correctAnswers={correctAnswers}
          treeTestData={data}
          title={title}
          response={preview}
          onClose={() => setPreview(null)}
        />
      )}
    </div>
  );
};
