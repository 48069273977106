import * as React from 'react';

import { NodeViewProps } from '@tiptap/react';

import { useGetArtifactsQuery } from '../../api';
import { ArtifactsAttributes } from '../../extensions';

import { Cards } from './Cards';

export const ArtifactsView = (props: NodeViewProps) => {
  const { artifacts } = props.node.attrs as ArtifactsAttributes;
  const { data, isLoading } = useGetArtifactsQuery(artifacts);

  return <Cards isLoading={isLoading} data={data} {...props} />;
};
