import tinytime from 'tinytime';

import { SelectOption } from 'components/common';

import { FIELD_OPERATORS, NULL_ANSWER, OPERATOR_LABELS, OVERRIDE_LABELS } from './Button/constants';

const dateTemplate = tinytime('{MM} {DD}');

export function getDefaultOperator(q: ScreenerField): IdealAnswerOperator {
  return (q?.ideal_answer || {}).operator || FIELD_OPERATORS[q.field_type][0];
}

function getOperatorLabel(operator: IdealAnswerOperator, fieldType: ScreenerField['field_type']): string | undefined {
  if (operator) {
    return (OVERRIDE_LABELS[fieldType] || {})[operator] || OPERATOR_LABELS[operator];
  }
}

export function getOperatorOptions(fieldType: ScreenerField['field_type']): SelectOption<IdealAnswerOperator>[] {
  return FIELD_OPERATORS[fieldType].map((operator) => ({
    label: getOperatorLabel(operator, fieldType) || operator,
    value: operator
  }));
}

function renderValue(value: string, fieldType: ScreenerField['field_type']): string {
  if (fieldType === 'date' && value) {
    return dateTemplate.render(new Date(value));
  } else {
    return value;
  }
}

export function isSet(field: ScreenerField): boolean {
  const { ideal_answer: answer = NULL_ANSWER, field_type } = field;
  return !!answer?.operator && hasValue(field);
}

export function getAnswerText(field: ScreenerField): string {
  const { ideal_answer: answer = NULL_ANSWER, field_type } = field;
  const values: string[] = [answer.value].flat().map((v) => renderValue(v, field_type));

  return answer.operator && isSet(field)
    ? `${getOperatorLabel(answer.operator, field_type)}: ${
        values.length === 2 ? values.join(' & ') : values.join(', ')
      }`
    : 'Ideal answer';
}

const ARRAYS = ['between', 'contains_all', 'contains_any', 'contains_none'];

export function isArrayAnswer(field: ScreenerField): boolean {
  const { ideal_answer: answer = NULL_ANSWER, field_type } = field;
  return field_type === 'multiple_choice' || ARRAYS.includes(answer?.operator || '');
}
function hasValue(field: ScreenerField): boolean {
  const { ideal_answer: answer = NULL_ANSWER } = field;
  if (isArrayAnswer(field)) {
    return (answer.value || []).length > 0;
  } else {
    return answer.value;
  }
}

export type Args = {
  prev: IdealAnswerOperator | null | undefined;
  next: IdealAnswerOperator;
  fieldType: ScreenerField['field_type'];
  value: any;
};

export function transformAnswerValue({ prev, next, fieldType, value }: Args): any {
  const newInputIsRadio = fieldType === 'single_choice';
  const newInputIsCheckboxes =
    fieldType === 'multiple_choice' || ['contains_any', 'contains_all', 'contains_none'].includes(next);
  const valueIsArray = Array.isArray(value);

  // reset value if the field is single_choice and the value is an array with several values
  // return first array value if the array has only one value
  if (valueIsArray && newInputIsRadio) {
    return value.length > 1 ? null : value[0];
  }

  // transform value to array if valueInput is checkboxes
  if (newInputIsCheckboxes && !valueIsArray) {
    return value ? [value] : null;
  }

  // clear the value if the operator is changed to or from between because we operate with ranges in this case
  if (prev === 'between' || next === 'between') {
    return null;
  }

  return value;
}
