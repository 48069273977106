import React, { FC, useState } from 'react';

import cn from 'classnames';

import { Button, Text } from '@components/common';
import { Preview as MarkdownPreview } from '@components/common/MarkdownEditor';
import { RecordingIndicator } from '@components/ScreenSharingProvider/components/RecordingIndicator';
import { BLOCK_KIND_LABELS } from '@components/SurveyBuilder/constants';
import * as Models from '@components/SurveyBuilder/types/models';
import { useUnmoderatedContext } from '@components/Unmoderated/hooks/useUnmoderatedContext';

interface Props {
  block: Models.Block;
  renderOptions: () => JSX.Element;
  isMinimized: boolean;
  setIsMinimized: (isMinimized: boolean) => void;
}

export const TaskHeader: FC<Props> = ({ block, renderOptions, setIsMinimized, isMinimized }) => {
  const { blocks } = useUnmoderatedContext();

  const { description, title, position, kind } = block;

  return (
    <div className='max-h-2/3 flex w-full border-b border-gray-200 bg-white pb-4'>
      <div className='flex flex-1 self-start justify-self-start px-6'>
        <RecordingIndicator />
      </div>
      <div className='flex h-full w-1/2 max-w-3xl flex-col justify-center self-center px-4'>
        {!isMinimized && (
          <div className='mb-2 flex items-center space-x-4'>
            <Text className='text-custom-brand-secondary text-xs' uppercase bold>
              {BLOCK_KIND_LABELS[kind]}
            </Text>

            <Text className='text-custom-brand-secondary text-xs'>
              {position} / {blocks.length}
            </Text>
          </div>
        )}

        <Text h='600' className={cn('text-custom-brand-secondary', isMinimized && 'truncate')} bold>
          {title}
        </Text>

        {description && !isMinimized && (
          <div className='flex min-h-0 flex-1 flex-col'>
            <MarkdownPreview
              className='mt-2 flex-1 overflow-y-auto'
              components={{
                a: ({ children, href }) => (
                  <a href={href} target='_blank' rel='noopener noreferrer'>
                    {children}
                  </a>
                )
              }}
            >
              {description}
            </MarkdownPreview>
          </div>
        )}
      </div>

      <div className='flex flex-1 flex-wrap justify-end self-start px-6'>
        {renderOptions()}
        <Button
          className='ml-3'
          type='button'
          aria-label={isMinimized ? 'Expand' : 'Collapse'}
          icon={isMinimized ? 'chevronDown' : 'chevronUp'}
          onClick={() => setIsMinimized(!isMinimized)}
        />
      </div>
    </div>
  );
};
