import * as React from 'react';

import { Provider } from 'react-redux';
import { DailyProvider } from '@daily-co/daily-react';

import { Enums } from '@components/SurveyBuilder';
import { useUnmoderatedContext } from '@components/Unmoderated/hooks/useUnmoderatedContext';

import { RecordingIndicator } from './components/RecordingIndicator';
import { ParticipantRecordingProvider } from './hooks/useParticipantRecording';
import { store } from './api';

export type Props = {
  participation?: Participation;
  permissions?: { [Device in keyof typeof Enums.UnmoderatedDevice]: Enums.DevicePermission };
  device?: Study['device_type'];
};

export const ScreenSharingProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  participation,
  permissions,
  device = 'desktop'
}) => {
  const { topBarUnmoderatedLayout } = useUnmoderatedContext();

  return (
    <Provider store={store}>
      <DailyProvider>
        <ParticipantRecordingProvider participation={participation} permissions={permissions} device={device}>
          {!topBarUnmoderatedLayout && <RecordingIndicator />}

          {children}
        </ParticipantRecordingProvider>
      </DailyProvider>
    </Provider>
  );
};
