import * as React from 'react';

import { api } from '@api/reduxApi';
import { Skeleton as EditSkeleton } from '@components/StudiesApp/components/StudyDraft/components/Skeleton';
import { Skeleton as ShowSkeleton } from '@components/StudiesApp/components/StudyPublished/components/Skeleton';

interface Props {
  id: number;
  type: 'show' | 'edit';
}

export const StudyLoader: React.FC<PropsWithChildrenFn<Props, { study: Study; refetch: () => void }>> = ({
  id,
  children,
  type
}) => {
  const { data, refetch } = api.useGetStudyQuery(id);

  if (!data) {
    return type === 'show' ? <ShowSkeleton /> : <EditSkeleton />;
  }

  return typeof children === 'function' ? children({ study: data, refetch }) : children;
};
