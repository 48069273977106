import * as React from 'react';
import { useEffect } from 'react';

import Skeleton from 'react-loading-skeleton';
import tinytime from 'tinytime';

import { api } from '@api/reduxApi';
import { FullStarSVG } from '@components/svgs';
import { Toast, useToaster } from '@stores/toaster';

const dateTemplate = tinytime('{Do} {MMMM} {YYYY}');

interface Props {
  studyId: number;
  participationId: number;
  screenerId: number;
}

const getFailed: Toast = {
  icon: 'error',
  heading: 'There was an error fetching screener responses',
  text: 'Please try again later'
};

const ScreenerResponseAnswer: React.FC<React.PropsWithChildren<{ answer: ScreenerResponseAnswer }>> = ({ answer }) => {
  const { label, value, field_type, ideal } = answer;
  return (
    <>
      {label && <span className='mb-2 block font-bold text-gray-700'>{label || '(no title)'}</span>}
      <div className='flex flex-row'>
        {ideal && <FullStarSVG data-testid='ideal' className='mr-2 mt-1' />}
        {['short_text', 'number', 'single_choice', 'yes_no', 'email', 'website', 'number_range', 'location'].includes(
          field_type
        ) && <span className='text-gray-700'>{typeof value === 'string' && value}</span>}
        {['long_text'].includes(field_type) && (
          <span className='text-gray-700'>
            {(value as string).split('\n').map((a) => (
              <p>{a}</p>
            ))}
          </span>
        )}
        {['multiple_choice'].includes(field_type) && (
          <span className='text-gray-700'>{(Array.isArray(value) ? value : [value]).join(', ')}</span>
        )}
        {['start_loom'].includes(field_type) && (
          <iframe
            width='630'
            height='394'
            title='Loom Video'
            src={`https://www.loom.com/embed/${value}`}
            frameBorder='0'
          />
        )}
        {['date'].includes(field_type) && (
          <span className='text-gray-700'>{dateTemplate.render(new Date(value as string))}</span>
        )}
      </div>
    </>
  );
};
export const ScreenerResponses: React.FC<React.PropsWithChildren<Props>> = ({
  studyId,
  screenerId,
  participationId
}) => {
  const showToast = useToaster();

  const {
    data: result,
    error,
    isLoading,
    isError
  } = api.useGetScreenerResponseQuery(
    {
      studyId,
      screenerId,
      participationId
    },
    { skip: !studyId || !participationId || !screenerId }
  );

  useEffect(() => {
    if (isError && (error as any).status === 500) {
      showToast(getFailed);
    }
  }, [isError]);

  if (isLoading) {
    return (
      <div>
        <Skeleton duration={1} className='block h-8 w-full rounded-md bg-gray-50' count={4} />
      </div>
    );
  }

  if (!result || result.answers_json.length === 0) {
    return <div className='py-2 text-gray-500'>This participant did not submit a response.</div>;
  }

  return (
    <div className='py-2'>
      {result.answers_json.map((answer, idx) => (
        <div className='pb-6' key={idx}>
          <ScreenerResponseAnswer answer={answer} />
        </div>
      ))}
      {Object.keys(result.hidden_answers).map((label) => (
        <div className='pb-6' key={'hidden_' + label}>
          {label && <span className='mb-2 block font-bold text-gray-700'>{label || '(no title)'}</span>}
          <span className='text-gray-700'>{result.hidden_answers[label]}</span>
        </div>
      ))}
    </div>
  );
};
