import { useEffect, useState } from 'react';
import React from 'react';

import { SubscriptionOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { UseQueryStateOptions } from '@reduxjs/toolkit/dist/query/react/buildHooks';

import { uniqBy } from '@components/utils';
import { Cacheable, KeyState, useCache } from '@stores/cache';

import { useForage } from './useForage';

const DELAY_BETWEEN_PAGE_FETCHES = 1000;

export type Hook<T extends Cacheable> = {
  status: KeyState<T>['status'];
  page: number;
  data?: T[];
  totalRecords?: number;
  currentRecords?: number;
  initialFetchDone: boolean;
  isEncrypting: boolean;
  isDecrypting: boolean;
  isFetching: boolean;
  refetch: () => void;
  removeFromLocalStorage: (ids: number[]) => Promise<void>;
  updateRecord: (record: T) => void;
  updateRecords: (records: T[]) => void;
};

type Flat<T> = {
  [K in keyof T]: T[K] extends Date ? string : T[K];
};

export const convertStringsToDates = <T,>(data: Flat<T>): T => {
  const dateKeys = Object.keys(data).filter((k) => k.endsWith('_at'));
  const patch: Partial<T> = dateKeys.reduce((acc, key) => {
    if (data[key]) {
      acc[key] = new Date(data[key]);
    }
    return acc;
  }, {});
  return { ...data, ...patch } as T;
};

export function useCachedPagedQuery<T extends Cacheable>(props: {
  key: string;
  query: any;
  params?: Record<string, any>;
  options?: SubscriptionOptions & UseQueryStateOptions<any, any> & { skipPollingIfUnfocused?: boolean };
}): Hook<T> {
  const { key, query, params = {}, options = {} } = props;
  const { state, setState } = useCache<T>(key);

  const { data: records, page, status, totalRecords } = state;

  const forageQuery = useForage<T[]>(key, [], status !== 'init');
  const { updatedAt } = forageQuery;

  const queryResult = query({ page, updatedSince: updatedAt, ...params }, options);

  useEffect(() => {
    if (!queryResult.isUninitialized && status === 'fetching') {
      queryResult.refetch();
    }
  }, [status, queryResult.isUninitialized]);

  useEffect(() => {
    if (queryResult.isError) {
      setState({ status: 'error' });
    }
  }, [queryResult.isError]);

  useEffect(() => {
    if (!forageQuery.isFetching) {
      const data: T[] = forageQuery.data ? (forageQuery.data as Flat<T>[]).map(convertStringsToDates) : [];
      setState({ data, status: 'fetching' });
    }
  }, [forageQuery.isFetching]);

  useEffect(() => {
    if (status === 'loaded') {
      if (totalRecords && (forageQuery.data || []).length < totalRecords) {
        const uniqRecords = uniqBy([...(forageQuery.data || []), ...records], (c) => c.id);
        console.log(`storing ${uniqRecords.length} new records in localforage (${records.length} total)`);
        forageQuery.storeData(uniqRecords);
      }
      setState({ status: 'saved' });
    }
  }, [status, records, totalRecords]);

  useEffect(() => {
    const { data, isSuccess, isFetching } = queryResult;
    if (isSuccess && !isFetching && page === data?.meta?.page) {
      if (page === data.meta.page) {
        if (data.data.length > 0) {
          const newRecords = uniqBy([...records, ...data.data], (c) => c.id);
          setState({
            data: newRecords,
            totalRecords: data.meta.count + (forageQuery.data || []).length,
            status: page === data.meta.pages ? 'loaded' : 'fetching'
          });
          if (page < data.meta.pages) {
            setTimeout(() => {
              setState({ page: page + 1 });
            }, DELAY_BETWEEN_PAGE_FETCHES);
          }
        } else {
          setState({ totalRecords: data.meta.count + (forageQuery.data || []).length, status: 'loaded' });
        }
      }
    }
  }, [queryResult]);

  const updateRecord = (record: T) => {
    const newRecords = uniqBy([...(records || []), record], (c) => c.id);
    setState({ data: newRecords });
    forageQuery.storeData(newRecords);
  };

  const updateRecords = (additionalRecords: T[]) => {
    const newRecords = uniqBy([...(records || []), ...additionalRecords], (c) => c.id);
    setState({ data: newRecords });
    forageQuery.storeData(newRecords);
  };

  return {
    initialFetchDone: status !== 'init',
    isEncrypting: forageQuery.isEncrypting,
    isDecrypting: forageQuery.isDecrypting,
    isFetching: ['fetching', 'init'].includes(status),
    data: records,
    refetch: () => {
      setState({ page: 1, status: 'fetching' });
    },
    page,
    status,
    totalRecords,
    currentRecords: records.length,
    removeFromLocalStorage: async (ids: number[]) => {
      // Remove from data provider as well
      forageQuery.remove(ids);
    },
    updateRecord,
    updateRecords
  };
}
