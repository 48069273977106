import * as React from 'react';
import { useState } from 'react';

import { changeParticipationStatus } from '@api/queries';
import { Button } from '@components/common';
import { humanize } from '@components/utils';

import { COMPLETE_AND_THANKS, SEND_INVITES, SEND_THANKS } from './ACTIONS';

const ACTIONS = {
  default: {
    fresh: ['reject', 'shortlist'],
    invited: ['reject', 'shortlist'],
    applied: ['reject', 'request'],
    shortlisted: ['reject', 'request'],
    requested: ['complete'],
    started: ['complete'],
    completed: ['thanks'],
    rejected: ['shortlist']
  },
  video_call: {
    requested: ['book'],
    booked: ['no_show', 'complete']
  },
  online_task: {},
  unmoderated_test: {},
  survey: {}
};

const MENU_ACTIONS = {
  invited: ['request', 'book'],
  applied: ['request', 'book'],
  shortlisted: ['book']
};

function canDoAction({ participation: { thanked_at }, action }): boolean {
  switch (action) {
    case 'thanks':
      // thanked_at
      return !thanked_at;
    default:
      return true;
  }
}

const DEFAULT_ACTION_TEXT: { [key in ParticipationAction]?: string } = {
  resend_invite: 'resend invitation…',
  invite: 'invite to apply…',
  request: 'send request…',
  shortlist: 'shortlist',
  remove: 'remove',
  book: 'mark as booked',
  complete: 'mark as completed…',
  no_show: 'no show',
  thanks: 'send thanks…'
};

const ACTION_TEXT_OVERRIDES = {
  video_call: {
    request: 'send invite'
  },
  online_task: {
    request: 'send invite'
  },
  unmoderated_test: {
    request: 'send invite'
  },
  survey: {
    request: 'send invite'
  }
};
const FULL_STATUSES = {
  default: {
    fresh: 'new to study',
    invited: 'sent screener',
    requested: 'invited',
    rejected: 'removed'
  },
  video_call: {
    booked: 'scheduled'
  },
  online_task: {},
  unmoderated_test: {},
  survey: {}
};

function actionsFor({ status, style }) {
  return (
    {
      ...ACTIONS.default,
      ...(ACTIONS[style] || {})
    }[status] || []
  );
}

export function fullStatusText(status: Participation['status'], style = 'video_call'): string {
  const val =
    {
      ...FULL_STATUSES.default,
      ...(FULL_STATUSES[style] || {})
    }[status] || status;
  return humanize(val);
}

const LoadingButton = () => {
  return (
    <button
      type='button'
      className='focus:outline-none focus:ring-blue mr-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium leading-4 text-gray-700 hover:text-gray-500 focus:border-blue-300 active:bg-gray-50 active:text-gray-800'
      disabled
    >
      <svg
        className='mx-3 h-5 w-5 animate-spin text-gray-700'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
      >
        <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>{' '}
        <path
          className='opacity-75'
          fill='currentColor'
          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
        ></path>
      </svg>
    </button>
  );
};

const getActionText = (style) => ({
  ...DEFAULT_ACTION_TEXT,
  ...ACTION_TEXT_OVERRIDES[style]
});

function isPrimaryAction(action: ParticipationAction): boolean {
  return ['complete', 'request', 'shortlist', 'thanks'].includes(action);
}

interface ParticipationActionsProps {
  participation: Participation;
  study: Study;
  onChangeStatus: (data: Participation) => void;
  onOpenSlideout: (mode: string) => void;
}
export const ParticipationActions: React.FC<React.PropsWithChildren<ParticipationActionsProps>> = ({
  participation,
  study,
  onChangeStatus,
  onOpenSlideout
}) => {
  const { style } = participation;

  const [currentAction, setCurrentAction] = useState(null);

  const ACTION_TEXT = getActionText(style);
  const actions =
    study.state === 'closed'
      ? []
      : actionsFor(participation).filter((action) => canDoAction({ participation, action }));
  const menuActions =
    study.state === 'closed'
      ? []
      : (MENU_ACTIONS[participation.status] || []).filter((action) => canDoAction({ participation, action }));
  async function changeStatus(action) {
    const data = await changeParticipationStatus(participation.id, action);
    if (data) {
      onChangeStatus(data);
    }
    setCurrentAction(null);
  }

  function handleAction(action) {
    setCurrentAction(action);
    switch (action) {
      case 'invite':
        // show invite MODAL

        break;
      case 'request':
        onOpenSlideout(SEND_INVITES);
        break;
      case 'resend_invite':
        // show resend MODAL
        break;
      case 'thanks':
        onOpenSlideout(SEND_THANKS);
        break;
      case 'complete':
        onOpenSlideout(COMPLETE_AND_THANKS);
        // onClose()
        break;
      // <EmailSlideOut title='Send thanks' study={study} event='thanks' ids={selectedIds.length > 0 ? selectedCustomerIds : completedCustomerIds} onClose={closeAll} onSend={handleThanksSuccess}/>
      case 'shortlist':
      case 'reject':
      case 'book':
      case 'no_show':
        changeStatus(action);
        break;
    }
  }

  let buttons;
  if (currentAction) {
    buttons = <LoadingButton />;
  } else {
    buttons = actions.map((action) =>
      isPrimaryAction(action) ? (
        <Button
          primary
          key={`primary${action}`}
          className={`xx-participation-${action}`}
          onClick={() => handleAction(action)}
        >
          {humanize(ACTION_TEXT[action] || action)}
        </Button>
      ) : (
        <Button key={`button${action}`} className={`xx-participation-${action}`} onClick={() => handleAction(action)}>
          {humanize(ACTION_TEXT[action] || action)}
        </Button>
      )
    );
  }

  return <>{buttons}</>;
};
