import type { FeatureType } from '@components/shared/FeatureBanner';

type Params = {
  unmoderatedEnabled: boolean;
  unmoderatedFeatureDisabled: boolean;
  cardSortEnabled: boolean;
};

export const buildFeatureBanners = ({ unmoderatedEnabled, unmoderatedFeatureDisabled, cardSortEnabled }: Params) => {
  const banners: FeatureType[] = [];

  if (!unmoderatedFeatureDisabled) {
    if (unmoderatedEnabled) {
      banners.push('unmoderated_internal_launch');

      if (cardSortEnabled) {
        banners.push('unmoderated_card_sort');
      }
    } else {
      banners.push('unmoderated_upgrade');
    }
  }

  return banners;
};
