import * as React from 'react';

import classNames from 'classnames';

import { Avatar, Card, DropdownLink, Text } from '@components/common';
import { CaretDownSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { usePopUp } from '@hooks/usePopUp';

import { EVENT_COLORS } from '../NylasEvent';

export type VirtualCalendarModeratorProps = {
  isOwner?: boolean;
  user: TeamUser;
  disabled?: boolean;
  onClickRemove: () => void;
  studyUserColors: Record<number, UserColor | undefined>;
  type: 'observer' | 'moderator';
};

export const VirtualCalendarModerator: React.FC<React.PropsWithChildren<VirtualCalendarModeratorProps>> = ({
  isOwner,
  user,
  disabled,
  onClickRemove,
  studyUserColors,
  type
}) => {
  const { ref, togglePopUp, closePopUp, PopUp, open } = usePopUp();
  const [color, grade] = studyUserColors?.[user.id] || EVENT_COLORS[0];
  const handleClickRemove = () => {
    onClickRemove();
    closePopUp();
  };

  return (
    <div
      key={user.id}
      ref={ref}
      data-testid='moderator'
      className={classNames('xx-moderator group relative -mx-6 flex cursor-pointer items-center px-6 py-3', {
        'hover:bg-gray-50': !disabled
      })}
      onClick={togglePopUp}
    >
      <div className={classNames('mr-4 flex-shrink-0', `border-${color}-${grade} rounded-full border-2`)}>
        <Avatar size='lg' user={user} />
      </div>
      <div className='flex-grow truncate'>
        <Text h='400' medium mb='0.5'>
          {user.name}
        </Text>
        <div className='flex items-center space-x-1'>
          <div className='flex items-center space-x-1'>
            <Text h='400' color='gray-500' truncate>
              {user.email}
            </Text>
          </div>
        </div>
      </div>
      <button className='hidden group-hover:block' name='more'>
        <CaretDownSVG />
      </button>

      <PopUp open={open} zIndex={30} className='left-0 right-0 top-full px-6' whitespace='normal'>
        <Card className='max-h-48 overflow-y-scroll shadow-lg' noPadding>
          <Tooltip
            content={`Please assign another study owner before removing ${user.name} from the ${type} list`}
            isDisabled={!isOwner}
          >
            <DropdownLink disabled={isOwner || disabled} onClick={handleClickRemove}>
              Remove as {type}
            </DropdownLink>
          </Tooltip>
        </Card>
      </PopUp>
    </div>
  );
};
