import * as React from 'react';

import pluralize from 'pluralize';

import { Text } from '@components/common';
import { useAccountUsage } from '@hooks/useAccountUsage';
import { usePermission } from '@hooks/usePermission';
import { usePlan } from '@hooks/usePlan';

export const SeatInfo: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { costs: planCosts, interval } = usePlan();
  const { remaining, isLoading, isError, refetch } = useAccountUsage('seats');
  const canUseSeat = usePermission('canUseSeat')();
  const canBuySeat = usePermission('canBuySeat')();

  if (!canUseSeat) {
    return (
      <Text h='400' light className='mb-6'>
        You have <span className='font-bold'>unlimited</span> observer seats available. Please contact your admin to
        invite creators or admins
      </Text>
    );
  }
  if (isLoading) {
    return (
      <Text h='400' light className='mb-6'>
        Loading seats usage...
      </Text>
    );
  }
  if (isError) {
    return (
      <Text h='400' light className='mb-6'>
        No seats usage data available. <a onClick={refetch}>Try again</a>
      </Text>
    );
  }
  if (remaining > 0) {
    return (
      <Text h='400' light className='mb-6'>
        You have <span className='font-bold'>{remaining}</span> {pluralize('remaining creator seats', remaining)} and{' '}
        <span className='font-bold'>unlimited</span> observer seats available.
      </Text>
    );
  }
  if (canBuySeat && planCosts.seat) {
    return (
      <Text h='400' light className='mb-6'>
        You have <span className='font-bold'>unlimited</span> observer seats available. Each new admin or creator seat
        will cost{' '}
        <span className='font-bold'>
          ${planCosts.seat}/{interval}.
        </span>
      </Text>
    );
  }

  return (
    <Text h='400' light className='mb-6'>
      You have <span className='font-bold'>unlimited</span> observer seats available. Please upgrade for additional
      admin or creator seats or contact your Customer Success Manager.
    </Text>
  );
};
