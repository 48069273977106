import React, { useEffect } from 'react';

import { Select, SelectOption, Text } from '@components/common';
import { useAccount } from '@hooks/useAccount';
import { Toast, useToaster } from 'components/stores/toaster';
import { useUpdateAccountSettingMutation } from 'components/stores/whoami';
import { useFeature } from 'hooks/useFeature';

type ConsentPlacement = 'apply' | 'participate';
const PLACEMENT_OPTIONS: SelectOption<ConsentPlacement>[] = [
  { value: 'apply', label: 'Before Screener' },
  { value: 'participate', label: 'Before Participating/Booking' }
];

const failedToast = (): Toast => ({
  heading: 'Failed to update setting',
  icon: 'error',
  text: 'Something went wrong updating the setting. Please try again later.'
});

const successAttrUpdate = (): Toast => ({
  heading: 'Consent placement updated!',
  icon: 'success',
  text: 'This setting has now been updated on your account.'
});

export const ConsentPlacementSettings = () => {
  const {
    account: { consent_placement }
  } = useAccount();
  const showToast = useToaster();
  const hasFeatue = useFeature('consent_placement');
  const [update, { isSuccess, isError }] = useUpdateAccountSettingMutation();
  function updateSetting(key: string, value: any) {
    update({ key, value });
  }

  useEffect(() => {
    if (isSuccess) {
      showToast(successAttrUpdate());
    }
    if (isError) {
      showToast(failedToast());
    }
  }, [isSuccess, isError]);

  if (!hasFeatue) {
    return null;
  }
  return (
    <div className='py-4'>
      <Text bold>Consent Placement</Text>
      <Text mb='2'>Select where you want to place the consent forms in your study flow.</Text>
      <Select
        options={PLACEMENT_OPTIONS}
        className='max-w-sm'
        onChange={(v) => updateSetting('consent_placement', v)}
        value={consent_placement}
      />
    </div>
  );
};
