import * as React from 'react';

import cn from 'classnames';
import Tippy from '@tippyjs/react';

export const ExtraInfo: React.FC<
  React.PropsWithChildren<{
    Icon: React.FC<React.PropsWithChildren<{ className?: string }>>;
    tooltip?: string;
    color?: string;
    isCompact?: boolean;
    onClick?: () => void;
    className?: string;
    mr?: string;
  }>
> = ({ Icon, mr = '6', tooltip, className, color = 'gray', onClick, isCompact = false, children }) => {
  const inner = (
    <div
      className={cn(`flex items-center whitespace-nowrap leading-5 text-${color}-500`, className, {
        'cursor-pointer': onClick,
        [`h400 tablet:mr-${mr}`]: !isCompact,
        'text-xs': isCompact
      })}
      onClick={() => onClick?.()}
    >
      <Icon
        className={cn('path-current mr-1.5 flex-shrink-0 text-gray-400', {
          'h-5 w-5': !isCompact,
          'h-4 w-4': isCompact
        })}
      />
      {children}
      {tooltip && <span className='sr-only'>{tooltip}</span>}
    </div>
  );
  if (tooltip) {
    return <Tippy content={tooltip}>{inner}</Tippy>;
  } else {
    return inner;
  }
};
