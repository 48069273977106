import * as React from 'react';
import { useEffect, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { Dropdown, DropdownLink } from '../Dropdown';

interface Props {
  value: number;
  className?: string;
  disabled?: boolean;
  prefix?: string;
  suffix?: string;
  type?: 'text' | 'number';
  placeholder?: string;
  name?: string;
  onBlur?: () => void;
  onEnter?: () => void;
  onChange: (value: string | number) => void;
}

export const DurationInput: React.FC<React.PropsWithChildren<Props>> = ({
  className = '',
  value,
  disabled = false,
  onChange,
  onEnter,
  onBlur,
  placeholder = 'Enter amount',
  name
}) => {
  const suffixes = [
    {
      multiplier: 24 * 60,
      label: 'days'
    },
    {
      multiplier: 60,
      label: 'hours'
    },
    {
      multiplier: 1,
      label: 'minutes'
    }
  ];
  const [suffix, setSuffix] = useState(suffixes[1]);
  const [duration, setDuration] = useState<string>('');
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const closeDropdown = () => setDropdownOpen(false);

  useEffect(() => {
    const bestSuffix = suffixes.filter((s) => (value / s.multiplier) % 1 === 0)[0];
    setSuffix(bestSuffix);
    setDuration((value / bestSuffix.multiplier).toString());
  }, []);

  const { callback: debouncedChange } = useDebouncedCallback(onChange, 300);

  const changeCallback = (val: string, currentSuffix) => {
    const parsedVal = val == '' ? 0 : parseInt(val);
    setDuration(val);
    debouncedChange(parsedVal * currentSuffix.multiplier);
  };

  const handleSuffixChange = (index: number) => {
    setSuffix(suffixes[index]);
    closeDropdown();
    changeCallback(duration, suffixes[index]);
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const val = e.currentTarget.value.replace(/\D/g, '');
    changeCallback(val, suffix);
  };

  return (
    <div className='relative mt-1 flex'>
      <input
        type='number'
        value={duration}
        disabled={disabled}
        name={name}
        onKeyPress={(e) => e.key === 'Enter' && onEnter?.()}
        onBlur={() => onBlur?.()}
        className={
          className +
          ' h400 mr-2 block w-full flex-grow rounded-md border-gray-200 pl-4 pr-2 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500'
        }
        placeholder={placeholder}
        onChange={!disabled ? handleChange : undefined}
      />
      <div className='flex flex-shrink items-center'>
        <Dropdown
          text={suffix.label}
          isOpen={dropdownOpen}
          onClick={toggleDropdown}
          onClose={closeDropdown}
          position='right'
          className='flex flex-col'
        >
          {suffixes.map((suffix, index) => (
            <DropdownLink key={index} onClick={() => handleSuffixChange(index)}>
              {suffix.label}
            </DropdownLink>
          ))}
        </Dropdown>
      </div>
    </div>
  );
};
