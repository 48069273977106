import React, { FC, forwardRef, SVGAttributes } from 'react';

import { Text } from '@components/common';

import { useIsCorrectDevice } from '../hooks/useIsCorrectDevice';

type Props = {
  surveyDevice: Study['device_type'];
};

const MobileSVG = forwardRef<SVGSVGElement, SVGAttributes<SVGElement>>((props, ref) => (
  <svg ref={ref} width='55' height='110' viewBox='0 0 55 110' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M54.9955 26.1401H54.3895V9.58288C54.3895 8.32444 54.1409 7.07832 53.6581 5.91567C53.1752 4.75302 52.4674 3.69662 51.5752 2.80676C50.683 1.91691 49.6237 1.21104 48.458 0.729453C47.2922 0.247868 46.0428 0 44.781 0H9.60849C8.34669 -2.95665e-06 7.09724 0.247862 5.93149 0.729444C4.76573 1.21103 3.7065 1.91689 2.81427 2.80674C1.92204 3.69659 1.21428 4.753 0.731406 5.91565C0.248532 7.07829 2.42556e-06 8.32441 0 9.58285V100.417C-1.61703e-06 101.676 0.248523 102.922 0.731393 104.084C1.21426 105.247 1.92202 106.303 2.81425 107.193C3.70648 108.083 4.76571 108.789 5.93146 109.271C7.09722 109.752 8.34667 110 9.60847 110H44.7809C46.0427 110 47.2922 109.752 48.458 109.271C49.6237 108.789 50.6829 108.083 51.5752 107.193C52.4674 106.303 53.1752 105.247 53.658 104.084C54.1409 102.922 54.3894 101.676 54.3894 100.417V37.9258H54.9955V26.1401Z'
      fill='#374151'
    />
    <path
      d='M52.3448 9.6471V100.349C52.3448 101.289 52.1592 102.22 51.7985 103.088C51.4378 103.956 50.9091 104.745 50.2427 105.409C49.5763 106.074 48.7851 106.601 47.9144 106.96C47.0437 107.32 46.1105 107.504 45.1682 107.504H9.82642C8.88429 107.504 7.95138 107.319 7.08095 106.96C6.21052 106.6 5.41963 106.073 4.75344 105.409C4.08725 104.744 3.55881 103.955 3.1983 103.087C2.83779 102.219 2.65228 101.289 2.65234 100.349V9.6471C2.65228 8.70748 2.83779 7.77705 3.1983 6.90895C3.55881 6.04084 4.08725 5.25206 4.75344 4.58765C5.41963 3.92324 6.21052 3.39622 7.08095 3.03668C7.95138 2.67714 8.88429 2.49212 9.82642 2.49219H14.1153C13.9048 3.00847 13.8247 3.56843 13.8821 4.12283C13.9394 4.67722 14.1325 5.20907 14.4443 5.67158C14.7561 6.1341 15.1771 6.51312 15.6703 6.7753C16.1634 7.03748 16.7136 7.1748 17.2724 7.17517H37.4218C37.9809 7.1757 38.5316 7.03896 39.0253 6.77703C39.5189 6.51509 39.9403 6.13601 40.2523 5.67323C40.5643 5.21045 40.7573 4.67819 40.8143 4.12343C40.8713 3.56866 40.7904 3.00844 40.579 2.49219H45.1682C46.1105 2.49185 47.0437 2.67666 47.9144 3.03608C48.7851 3.39549 49.5763 3.92247 50.2427 4.5869C50.9091 5.25133 51.4378 6.04019 51.7985 6.90844C52.1592 7.77668 52.3448 8.70729 52.3448 9.6471Z'
      fill='white'
    />
    <path
      d='M41.351 56.7843H10.2866C9.74253 56.7836 9.22088 56.5678 8.83614 56.1841C8.4514 55.8004 8.23499 55.2801 8.23438 54.7375V53.7187C8.23499 53.176 8.4514 52.6558 8.83614 52.272C9.22088 51.8883 9.74253 51.6725 10.2866 51.6719H41.351C41.8951 51.6725 42.4168 51.8883 42.8015 52.272C43.1862 52.6558 43.4027 53.176 43.4033 53.7187V54.7375C43.4027 55.2801 43.1862 55.8004 42.8015 56.1841C42.4168 56.5678 41.8951 56.7836 41.351 56.7843Z'
      fill='#F0F0F0'
    />
    <path
      d='M41.351 65.5655H10.2866C9.74253 65.5649 9.22088 65.3491 8.83614 64.9653C8.4514 64.5816 8.23499 64.0614 8.23438 63.5187V62.4999C8.23499 61.9573 8.4514 61.437 8.83614 61.0533C9.22088 60.6696 9.74253 60.4537 10.2866 60.4531H41.351C41.8951 60.4537 42.4168 60.6696 42.8015 61.0533C43.1862 61.437 43.4027 61.9573 43.4033 62.4999V63.5187C43.4027 64.0614 43.1862 64.5816 42.8015 64.9653C42.4168 65.3491 41.8951 65.5649 41.351 65.5655Z'
      fill='#F0F0F0'
    />
    <path
      d='M42.9048 54.8171H11.8404C11.2691 54.8164 10.7214 54.5898 10.3174 54.1869C9.91343 53.784 9.6862 53.2377 9.68555 52.6679V51.6491C9.6862 51.0793 9.91343 50.5331 10.3174 50.1302C10.7214 49.7273 11.2691 49.5007 11.8404 49.5H42.9048C43.4761 49.5007 44.0238 49.7273 44.4278 50.1302C44.8318 50.5331 45.059 51.0793 45.0597 51.6491V52.6679C45.059 53.2377 44.8318 53.784 44.4278 54.1869C44.0238 54.5898 43.4761 54.8164 42.9048 54.8171ZM11.8404 49.9094C11.3779 49.9099 10.9345 50.0933 10.6075 50.4195C10.2805 50.7457 10.0965 51.1879 10.096 51.6491V52.6679C10.0965 53.1292 10.2805 53.5714 10.6075 53.8976C10.9345 54.2237 11.3779 54.4072 11.8404 54.4077H42.9048C43.3673 54.4072 43.8107 54.2237 44.1377 53.8976C44.4648 53.5714 44.6487 53.1292 44.6492 52.6679V51.6491C44.6487 51.1879 44.4648 50.7457 44.1377 50.4195C43.8107 50.0933 43.3673 49.9099 42.9048 49.9094H11.8404Z'
      fill='#374151'
    />
    <path
      d='M42.9048 63.5983H11.8404C11.2691 63.5977 10.7214 63.371 10.3174 62.9681C9.91343 62.5652 9.6862 62.019 9.68555 61.4492V60.4304C9.6862 59.8606 9.91343 59.3143 10.3174 58.9114C10.7214 58.5085 11.2691 58.2819 11.8404 58.2812H42.9048C43.4761 58.2819 44.0238 58.5085 44.4278 58.9114C44.8318 59.3143 45.059 59.8606 45.0597 60.4304V61.4492C45.059 62.019 44.8318 62.5652 44.4278 62.9681C44.0238 63.371 43.4761 63.5977 42.9048 63.5983ZM11.8404 58.6906C11.3779 58.6911 10.9345 58.8746 10.6075 59.2007C10.2805 59.5269 10.0965 59.9691 10.096 60.4304V61.4492C10.0965 61.9104 10.2805 62.3527 10.6075 62.6788C10.9345 63.005 11.3779 63.1884 11.8404 63.189H42.9048C43.3673 63.1884 43.8107 63.005 44.1377 62.6788C44.4648 62.3527 44.6487 61.9104 44.6492 61.4492V60.4304C44.6487 59.9691 44.4648 59.5269 44.1377 59.2007C43.8107 58.8746 43.3673 58.6911 42.9048 58.6906H11.8404Z'
      fill='#374151'
    />
    <path
      d='M52.3447 9.6471V29.3761C49.2015 30.2008 45.9104 30.2954 42.7248 29.6526C39.5392 29.0099 36.5438 27.6468 33.9692 25.6684C31.3946 23.69 29.3094 21.1488 27.874 18.2406C26.4386 15.3323 25.6912 12.1344 25.6895 8.89287C25.6895 8.31428 25.7128 7.74171 25.7594 7.17517H37.4217C37.9808 7.1757 38.5315 7.03896 39.0251 6.77703C39.5188 6.51509 39.9402 6.13601 40.2522 5.67323C40.5642 5.21045 40.7572 4.67819 40.8142 4.12343C40.8711 3.56866 40.7903 3.00844 40.5789 2.49219H45.1681C46.1104 2.49185 47.0436 2.67666 47.9143 3.03608C48.785 3.39549 49.5761 3.92247 50.2426 4.5869C50.909 5.25133 51.4377 6.04019 51.7984 6.90844C52.1591 7.77668 52.3447 8.70729 52.3447 9.6471Z'
      fill='#F0F0F0'
    />
    <path
      d='M20.5098 106.28C20.5098 106.696 20.4942 107.106 20.4606 107.512H9.82642C8.88429 107.512 7.95138 107.327 7.08095 106.967C6.21052 106.608 5.41963 106.081 4.75344 105.416C4.08725 104.752 3.55881 103.963 3.1983 103.095C2.83779 102.227 2.65228 101.297 2.65234 100.357V90.6552C4.88141 90.3661 7.14666 90.5541 9.29714 91.2067C11.4476 91.8594 13.4339 92.9617 15.1237 94.4401C16.8134 95.9186 18.1678 97.7393 19.0965 99.7808C20.0253 101.822 20.5071 104.038 20.5098 106.28Z'
      fill='#5850EC'
    />
    <path
      d='M32.5444 76.1514H17.7964C17.2523 76.1508 16.7306 75.935 16.3459 75.5513C15.9612 75.1676 15.7448 74.6473 15.7441 74.1047V73.0859C15.7448 72.5432 15.9612 72.0229 16.3459 71.6392C16.7306 71.2555 17.2523 71.0397 17.7964 71.0391H32.5444C33.0885 71.0397 33.6101 71.2555 33.9949 71.6392C34.3796 72.0229 34.596 72.5432 34.5966 73.0859V74.1047C34.596 74.6473 34.3796 75.1676 33.9949 75.5513C33.6101 75.935 33.0885 76.1508 32.5444 76.1514Z'
      fill='#5850EC'
    />
    <path
      d='M34.0982 74.1921H19.3502C18.7789 74.1914 18.2312 73.9648 17.8272 73.5619C17.4232 73.159 17.196 72.6127 17.1953 72.0429V71.0241C17.196 70.4543 17.4232 69.9081 17.8272 69.5052C18.2312 69.1023 18.7789 68.8757 19.3502 68.875H34.0982C34.6695 68.8757 35.2172 69.1023 35.6212 69.5052C36.0252 69.9081 36.2524 70.4543 36.2531 71.0241V72.0429C36.2524 72.6127 36.0252 73.159 35.6212 73.5619C35.2172 73.9648 34.6695 74.1914 34.0982 74.1921ZM19.3502 69.2844C18.8877 69.2849 18.4443 69.4683 18.1173 69.7945C17.7902 70.1207 17.6063 70.5629 17.6058 71.0241V72.0429C17.6063 72.5042 17.7902 72.9464 18.1173 73.2726C18.4443 73.5987 18.8877 73.7822 19.3502 73.7827H34.0982C34.5607 73.7822 35.0041 73.5987 35.3311 73.2726C35.6582 72.9464 35.8421 72.5042 35.8426 72.0429V71.0241C35.8421 70.5629 35.6582 70.1207 35.3311 69.7945C35.0041 69.4683 34.5607 69.2849 34.0982 69.2844H19.3502Z'
      fill='#374151'
    />
  </svg>
));

const DesktopSVG = forwardRef<SVGSVGElement, SVGAttributes<SVGElement>>((props, ref) => (
  <svg
    ref={ref}
    width='149'
    height='120'
    viewBox='0 0 149 120'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g id='Illustration' clipPath='url(#clip0_4630_38318)'>
      <path
        id='Vector'
        d='M94.1714 118.526L90.9773 117.181L88.7862 101.16H59.6076L57.2326 117.115L54.375 118.545C54.2383 118.613 54.1287 118.726 54.0639 118.864C53.9991 119.003 53.983 119.159 54.0181 119.308C54.0532 119.457 54.1375 119.59 54.2574 119.684C54.3773 119.779 54.5256 119.831 54.6785 119.831H93.9081C94.0648 119.831 94.2166 119.777 94.3378 119.678C94.4591 119.578 94.5423 119.44 94.5733 119.286C94.6043 119.133 94.5812 118.973 94.5079 118.835C94.4347 118.696 94.3158 118.587 94.1714 118.526Z'
        fill='#DCDCDC'
      />
      <path
        id='Vector_2'
        d='M137.969 103H11.0307C8.1029 102.993 5.29724 101.797 3.22929 99.6736C1.16134 97.5504 -1.37902e-05 94.6736 1.22811e-10 91.6742V80H149V91.6742C149 94.6736 147.839 97.5504 145.771 99.6737C143.703 101.797 140.897 102.993 137.969 103Z'
        fill='#374151'
      />
      <path
        id='Vector_3'
        d='M148.947 89.62H0V11.0328C0.00331584 8.10772 1.16613 5.30341 3.23333 3.23509C5.30054 1.16676 8.10332 0.00331763 11.0268 0H137.92C140.843 0.00331057 143.646 1.16675 145.713 3.23508C147.78 5.30341 148.943 8.10772 148.947 11.0328V89.62Z'
        fill='#374151'
      />
      <path
        id='Vector_4'
        d='M137.072 83.3409H11.8756C10.3913 83.3392 8.96838 82.7486 7.91887 81.6985C6.86937 80.6484 6.27902 79.2247 6.27734 77.7397V11.8825C6.27902 10.3975 6.86937 8.97375 7.91887 7.92367C8.96838 6.87359 10.3913 6.28292 11.8756 6.28125H137.072C138.556 6.28293 139.979 6.87359 141.029 7.92367C142.078 8.97375 142.669 10.3975 142.67 11.8825V77.7397C142.669 79.2247 142.078 80.6484 141.029 81.6985C139.979 82.7486 138.556 83.3392 137.072 83.3409Z'
        fill='white'
      />
      <path
        id='Vector_5'
        d='M89.351 38.7843H58.2866C57.7425 38.7836 57.2209 38.5678 56.8361 38.1841C56.4514 37.8004 56.235 37.2801 56.2344 36.7375V35.7187C56.235 35.176 56.4514 34.6558 56.8361 34.272C57.2209 33.8883 57.7425 33.6725 58.2866 33.6719H89.351C89.8951 33.6725 90.4168 33.8883 90.8015 34.272C91.1862 34.6558 91.4027 35.176 91.4033 35.7187V36.7375C91.4027 37.2801 91.1862 37.8004 90.8015 38.1841C90.4168 38.5678 89.8951 38.7836 89.351 38.7843Z'
        fill='#F0F0F0'
      />
      <path
        id='Vector_6'
        d='M89.351 47.5655H58.2866C57.7425 47.5649 57.2209 47.3491 56.8361 46.9653C56.4514 46.5816 56.235 46.0614 56.2344 45.5187V44.4999C56.235 43.9573 56.4514 43.437 56.8361 43.0533C57.2209 42.6696 57.7425 42.4537 58.2866 42.4531H89.351C89.8951 42.4537 90.4168 42.6696 90.8015 43.0533C91.1862 43.437 91.4027 43.9573 91.4033 44.4999V45.5187C91.4027 46.0614 91.1862 46.5816 90.8015 46.9653C90.4168 47.3491 89.8951 47.5649 89.351 47.5655Z'
        fill='#F0F0F0'
      />
      <path
        id='Vector_7'
        d='M90.9048 36.8171H59.8404C59.2691 36.8164 58.7214 36.5898 58.3174 36.1869C57.9134 35.784 57.6862 35.2377 57.6855 34.6679V33.6491C57.6862 33.0793 57.9134 32.5331 58.3174 32.1302C58.7214 31.7273 59.2691 31.5007 59.8404 31.5H90.9048C91.4761 31.5007 92.0238 31.7273 92.4278 32.1302C92.8318 32.5331 93.059 33.0793 93.0597 33.6491V34.6679C93.059 35.2377 92.8318 35.784 92.4278 36.1869C92.0238 36.5898 91.4761 36.8164 90.9048 36.8171ZM59.8404 31.9094C59.3779 31.9099 58.9345 32.0933 58.6075 32.4195C58.2805 32.7457 58.0965 33.1879 58.096 33.6491V34.6679C58.0965 35.1292 58.2805 35.5714 58.6075 35.8976C58.9345 36.2237 59.3779 36.4072 59.8404 36.4077H90.9048C91.3673 36.4072 91.8107 36.2237 92.1377 35.8976C92.4648 35.5714 92.6487 35.1292 92.6492 34.6679V33.6491C92.6487 33.1879 92.4648 32.7457 92.1377 32.4195C91.8107 32.0933 91.3673 31.9099 90.9048 31.9094H59.8404Z'
        fill='#374151'
      />
      <path
        id='Vector_8'
        d='M90.9048 45.5983H59.8404C59.2691 45.5977 58.7214 45.371 58.3174 44.9681C57.9134 44.5652 57.6862 44.019 57.6855 43.4492V42.4304C57.6862 41.8606 57.9134 41.3143 58.3174 40.9114C58.7214 40.5085 59.2691 40.2819 59.8404 40.2812H90.9048C91.4761 40.2819 92.0238 40.5085 92.4278 40.9114C92.8318 41.3143 93.059 41.8606 93.0597 42.4304V43.4492C93.059 44.019 92.8318 44.5652 92.4278 44.9681C92.0238 45.371 91.4761 45.5977 90.9048 45.5983ZM59.8404 40.6906C59.3779 40.6911 58.9345 40.8746 58.6075 41.2007C58.2805 41.5269 58.0965 41.9691 58.096 42.4304V43.4492C58.0965 43.9104 58.2805 44.3527 58.6075 44.6788C58.9345 45.005 59.3779 45.1884 59.8404 45.189H90.9048C91.3673 45.1884 91.8107 45.005 92.1377 44.6788C92.4648 44.3527 92.6487 43.9104 92.6492 43.4492V42.4304C92.6487 41.9691 92.4648 41.5269 92.1377 41.2007C91.8107 40.8746 91.3673 40.6911 90.9048 40.6906H59.8404Z'
        fill='#374151'
      />
      <path
        id='Vector_9'
        d='M80.5444 58.1514H65.7964C65.2523 58.1508 64.7306 57.935 64.3459 57.5513C63.9612 57.1676 63.7448 56.6473 63.7441 56.1047V55.0859C63.7448 54.5432 63.9612 54.0229 64.3459 53.6392C64.7306 53.2555 65.2523 53.0397 65.7964 53.0391H80.5444C81.0885 53.0397 81.6101 53.2555 81.9949 53.6392C82.3796 54.0229 82.596 54.5432 82.5966 55.0859V56.1047C82.596 56.6473 82.3796 57.1676 81.9949 57.5513C81.6101 57.935 81.0885 58.1508 80.5444 58.1514Z'
        fill='#5850EC'
      />
      <path
        id='Vector_10'
        d='M82.0982 56.1921H67.3502C66.7789 56.1914 66.2312 55.9648 65.8272 55.5619C65.4232 55.159 65.196 54.6127 65.1953 54.0429V53.0241C65.196 52.4543 65.4232 51.9081 65.8272 51.5052C66.2312 51.1023 66.7789 50.8757 67.3502 50.875H82.0982C82.6695 50.8757 83.2172 51.1023 83.6212 51.5052C84.0252 51.9081 84.2524 52.4543 84.2531 53.0241V54.0429C84.2524 54.6127 84.0252 55.159 83.6212 55.5619C83.2172 55.9648 82.6695 56.1914 82.0982 56.1921ZM67.3502 51.2844C66.8877 51.2849 66.4443 51.4683 66.1173 51.7945C65.7902 52.1207 65.6063 52.5629 65.6058 53.0241V54.0429C65.6063 54.5042 65.7902 54.9464 66.1173 55.2726C66.4443 55.5987 66.8877 55.7822 67.3502 55.7827H82.0982C82.5607 55.7822 83.0041 55.5987 83.3311 55.2726C83.6582 54.9464 83.8421 54.5042 83.8426 54.0429V53.0241C83.8421 52.5629 83.6582 52.1207 83.3311 51.7945C83.0041 51.4683 82.5607 51.2849 82.0982 51.2844H67.3502Z'
        fill='#374151'
      />
      <circle id='Ellipse 130' cx='125' cy='25' r='11' fill='#F0F0F0' />
      <circle id='Ellipse 131' cx='26' cy='60' r='15' fill='#5850EC' />
    </g>
    <defs>
      <clipPath id='clip0_4630_38318'>
        <rect width='149' height='120' fill='white' />
      </clipPath>
    </defs>
  </svg>
));

export const WrongDevice: FC<Props> = ({ surveyDevice }) => {
  const { errorDescription, errorMessage } = useIsCorrectDevice(surveyDevice);

  return (
    <section className='fixed inset-0 flex flex-col items-center justify-center px-3'>
      {surveyDevice === 'mobile' ? <MobileSVG /> : <DesktopSVG />}

      {errorMessage && (
        <Text as='h1' className='mt-6 text-2xl' bold>
          {errorMessage}
        </Text>
      )}

      {errorDescription && (
        <Text as='h2' className='mt-2 text-center text-sm'>
          {errorDescription}
        </Text>
      )}
    </section>
  );
};
