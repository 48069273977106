import * as React from 'react';

import { Input, Text } from '@components/common';
import { MeetingProvidersDropdown } from '@components/shared/MeetingProvidersDropdown';
import { LOCATION } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/constants';
import { ExternalLinkSVG, ZoomSVG } from '@components/svgs';
import { useUser } from '@hooks/useUser';

import { FieldRow } from '../components/FieldRow';

import type { BookingDetailsFormFieldComponentProps } from './types';

export const Location: React.FC<BookingDetailsFormFieldComponentProps> = ({
  study,
  bookingDetail,
  type = 'candidate_event',
  editMode,
  onChange
}) => {
  const event = bookingDetail[type];

  const user = useUser();

  if (!event?.conferencing) return null;

  const handleClick = (option: MeetingProvider) => {
    onChange({
      [type]: { video_url_type: option.video_url_type }
    });
  };

  const value = event.video_url_type || event.conferencing.provider;

  return (
    <FieldRow label='Location'>
      {!editMode && (
        <div className='overflow-auto'>
          <div className='flex items-center space-x-2 py-2.5'>
            {event.conferencing.provider === 'zoom' && <ZoomSVG />}
            <Text h='400'>{LOCATION[event.conferencing.provider]}</Text>
          </div>
          <div className='flex items-center space-x-2'>
            {event.conferencing.url ? (
              <>
                <ExternalLinkSVG className='flex-shrink-0 text-indigo-600' />
                <a
                  className='h400 truncate py-2.5 underline'
                  href={event.conferencing.url}
                  target='_blank'
                  rel='noreferrer'
                >
                  {event.conferencing.url}
                </a>
              </>
            ) : (
              <Text color='gray-500'>No link provided.</Text>
            )}
          </div>
        </div>
      )}
      {editMode && study?.owner && (
        <div className='w-full'>
          <MeetingProvidersDropdown user={user} study={study} value={value} onClick={handleClick} />
          {value === 'manual' && (
            <Input
              className='mt-2 w-full'
              label='Custom link'
              value={event.conferencing?.url}
              onChange={(url) =>
                onChange({
                  [type]: { conferencing: { provider: 'manual', url } }
                })
              }
            />
          )}
        </div>
      )}
    </FieldRow>
  );
};
