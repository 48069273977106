import * as React from 'react';

import cn from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

import { Alert, DropdownCombobox, Input, Radio, Text } from '@components/common';
import { Tiptap, TiptapHook } from '@components/shared/Tiptap';
import { ErrorSvg, WarningSVG } from '@components/svgs';

import { EMAIL_TEMPLATE_PURPOSE_DESCRIPTIONS, EMAIL_TEMPLATE_PURPOSES } from '../../../consts';

const Fieldset: React.FC<React.PropsWithChildren<{ id?: string; label: string; sub: string }>> = ({
  id,
  label,
  sub,
  children
}) => (
  <div className='mb-6'>
    <Text as='label' htmlFor={id} bold mb='1'>
      {label}
    </Text>
    <Text h='400' color='gray-500' mb='1'>
      {sub}
    </Text>
    {children}
  </div>
);

type Props = {
  emailTemplate: EmailTemplate | null;
  tiptap: TiptapHook;
  event: StudyMessage['event'];
  disabled?: boolean;
};

export const EmailTemplateForm: React.FC<React.PropsWithChildren<Props>> = ({
  disabled,
  emailTemplate,
  tiptap,
  event
}) => {
  const { control, errors } = useFormContext();

  return (
    <>
      <div>
        <Fieldset
          id='title'
          label='Template title'
          sub='Make it easy to recognize for you and your teammates to find it.'
        >
          <Controller
            rules={{ required: true }}
            control={control}
            name='title'
            defaultValue={emailTemplate?.title}
            render={({ onChange, value, name }) => (
              <Input
                disabled={disabled}
                placeholder='Enter title…'
                className='w-full'
                name={name}
                value={value || ''}
                onChange={onChange}
                error={!!errors?.['title']}
              />
            )}
          />
          {errors && errors['title'] && (
            <Text color='red-600' h='400' className='col-span-3 mt-3 flex items-center truncate'>
              <ErrorSvg className='mr-2' />
              Please enter a title for your email.
            </Text>
          )}
        </Fieldset>
        <Fieldset
          id='use_case'
          label='Email use case'
          sub='Select the use case so we know where we should make this template available.'
        >
          <Controller
            rules={{ required: true }}
            control={control}
            name='event'
            defaultValue={emailTemplate?.event}
            render={({ onChange, value, name }) => (
              <div className='space-y-2 pt-1'>
                <DropdownCombobox
                  inputClassName={cn({ 'focus:ring-red-600 focus:border-red-600 border-red-600': errors['event'] })}
                  placeholder='Search by use case…'
                  items={Object.entries(EMAIL_TEMPLATE_PURPOSES).map(([event, label]) => ({
                    value: event,
                    label: label
                  }))}
                  disabled={disabled}
                  selectedItem={{ value, label: EMAIL_TEMPLATE_PURPOSES[event] || event }}
                  onSelect={(item) => onChange(item?.value)}
                  renderItem={({ label, value }, isHighlighted) => (
                    <div>
                      <Text h='400' bold mb='1'>
                        {label}
                      </Text>
                      <Text h='400' color={isHighlighted ? 'white' : 'gray-500'}>
                        {EMAIL_TEMPLATE_PURPOSE_DESCRIPTIONS[value]}
                      </Text>
                    </div>
                  )}
                />
              </div>
            )}
          />
          {errors && errors['event'] && (
            <Text color='red-600' h='400' className='col-span-3 mt-3 flex items-center truncate'>
              <ErrorSvg className='mr-2' />
              Please select email use case.
            </Text>
          )}
        </Fieldset>
        <div className='mb-6'>
          <Controller
            control={control}
            name='default'
            defaultValue={emailTemplate?.default}
            render={({ onChange, value, name }) => (
              <Radio
                id='default'
                type='checkbox'
                disabled={disabled}
                name={name}
                checked={value}
                onChange={() => onChange(!value)}
                label='Set as default template on account'
              />
            )}
          />
          {control.getValues('default') && (
            <Alert type='warning' className='mb-4 mt-4'>
              Changes will apply only to new studies created.
            </Alert>
          )}
        </div>
        <Fieldset id='subject' label='Email subject line' sub='Make it catchy to increase email open conversion rates.'>
          <Controller
            rules={{ required: true }}
            control={control}
            name='subject'
            defaultValue={emailTemplate?.subject}
            render={({ onChange, value, name }) => (
              <Input
                disabled={disabled}
                className='w-full'
                placeholder='Enter subject…'
                name={name}
                value={value || ''}
                onChange={onChange}
                error={!!errors?.['subject']}
              />
            )}
          />
          {errors && errors['subject'] && (
            <Text color='red-600' h='400' className='col-span-3 mt-3 flex items-center truncate'>
              <ErrorSvg className='mr-2' />
              Please enter a subject line for your email.
            </Text>
          )}
        </Fieldset>

        <Fieldset label='Email body' sub='Write plain text and add attributes to utilize data in the system.'>
          <div
            className={cn('mt-2 rounded-md border border-gray-200 p-1', {
              'border-red-600 focus:border-red-600 focus:ring-red-600': errors?.['text']
            })}
          >
            <Tiptap {...tiptap.getTiptapProps()}>
              <tiptap.Menu {...tiptap.getMenuProps()} />
              <tiptap.Content
                {...tiptap.getContentProps({
                  className: 'monitor:max-w-5xl w-full h-full max-w-2xl p-4 mx-auto text-gray-700',
                  placeholder: 'Start writing or paste in your template…'
                })}
              />
            </Tiptap>
          </div>
          {errors && errors['text'] && (
            <Text color='red-600' h='400' className='col-span-3 mt-3 flex items-center truncate'>
              <ErrorSvg className='mr-2' />
              Please enter an email body.
            </Text>
          )}
        </Fieldset>
      </div>
    </>
  );
};
