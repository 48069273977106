import React from 'react';

import { Checkbox } from '@components/common';

export const StandardConsentForm: React.FC<React.PropsWithChildren<{ name?: string }>> = ({
  name = '{{Your company}}'
}) => (
  <div className='mx-10 my-6 flex-1 rounded-md border border-gray-200 p-4'>
    <div className='flex items-start space-x-2'>
      <Checkbox disabled selected={false} className='mt-1' />
      <span>I understand that my data is stored for research purposes for {name}</span>
    </div>
    <div className='flex items-start space-x-2'>
      <Checkbox disabled selected={false} className='mt-1' />
      <span>I understand that I can request for my research participation data to be deleted at any time</span>
    </div>
    <div className='flex items-start space-x-2'>
      <Checkbox disabled selected={false} className='mt-1' />
      <span>I opt-in to being contacted for future studies</span>
    </div>
  </div>
);
