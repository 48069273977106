import React, { memo, useMemo } from 'react';

import cn from 'classnames';
import { startOfDay } from 'date-fns';
import { DragDropContext } from 'react-beautiful-dnd';

import { Loading, Text } from '@components/common';
import { DayColumn } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/AttendeesCalendars/components';
import {
  NextWeekButton,
  PreviousWeekButton
} from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/AvailabilityPanel/components';
import {
  getStringFromDate,
  slotFromKey
} from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/utils';
import { useFetchEvents } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/useFetchEvents';
import { track } from '@components/tracking';
import { addDaysToDate, getDatesBetween, rangeMap, startOfWeek } from '@components/utils';

import { TIME_ZONE_OPTIONS } from '../../options';

interface Props {
  startHour: number;
  endHour: number;
  events: SlotInstance[];
  changeTimeSlot: (attr: string, val: string | number | number[]) => void;
  timeSlot: RecurringTimeSlot | OneOffTimeSlot;
  startDate: Date;
  weekIdx: number;
  weekDaysOnly: boolean;
  timezone: Timezone;
  schedulingIncrement: number;
  onChange: (v: SlotInstance[], debounce?: boolean) => void;
  initialDuration: number;
  frozen?: boolean;
  endDate: Date | null;
  showConflicts: boolean;
  userIdsWithHiddenEvents: number[];
  setWeekIdx: (v: number) => void;
  disablePrevWeekButton?: boolean;
  disableNextWeekButton?: boolean;
  currentUsers: { teamUser: TeamUser; studyUser: StudyUser }[];
  hidden?: boolean;
}

const AttendeesCalendarsComponent: React.FC<React.PropsWithChildren<Props>> = ({
  hidden,
  events,
  startHour,
  endHour,
  timeSlot,
  startDate,
  weekIdx,
  timezone,
  schedulingIncrement,
  onChange,
  initialDuration,
  frozen,
  endDate,
  weekDaysOnly,
  showConflicts,
  userIdsWithHiddenEvents,
  setWeekIdx,
  disablePrevWeekButton,
  disableNextWeekButton,
  currentUsers
}) => {
  const dates = useMemo<Date[]>(() => {
    const monday = startOfWeek(addDaysToDate(startDate, weekIdx * 7));
    return getDatesBetween(monday, addDaysToDate(monday, weekDaysOnly ? 4 : 6));
  }, [startDate, weekIdx, weekDaysOnly]);

  const calendars: UserAndCalendar[] = useMemo(
    () =>
      currentUsers.map(
        ({ teamUser: { default_nylas_calendar_id }, studyUser: { role, user_id: userId, calendar_id } }) =>
          ({
            userId,
            calendarId: calendar_id || default_nylas_calendar_id,
            conflicts: role === 'moderator'
          }) as UserAndCalendar
      ),
    [currentUsers]
  );

  const { initialFetchDone } = useFetchEvents({
    calendars,
    start_date: getStringFromDate(dates[0]),
    end_date: getStringFromDate(dates[dates.length - 1]),
    timezone: timezone.name,
    weekIdx
  });

  const updateEvent = (event: SlotInstance, debounce: boolean = false) => {
    const newEvents = events.map((src) => (src.id === event.id ? event : src));
    onChange(newEvents, debounce);
  };

  const handleCreateEvent = (slot: SlotInstance, id: string) => {
    const newEvent = {
      ...slot,
      id,
      duration: initialDuration
    };
    onChange([...events, newEvent]);
  };

  const handleDeleteEvent = (id: string) => {
    onChange(events.filter((e) => e.id !== id));
  };

  const currentTimezone = useMemo(() => {
    const selectedTZ = TIME_ZONE_OPTIONS.find((i) => i.data?.name === timeSlot.timezone);

    return selectedTZ?.data.abbreviation || '';
  }, [timeSlot.timezone]);

  return (
    <div
      aria-hidden={hidden}
      data-testid='calendar-wrapper'
      className={cn('min-w-160 relative flex flex-1 overflow-auto', hidden && 'hidden')}
    >
      <div className='px-4 text-gray-700'>
        <div className='mb-1.5 mt-1 flex flex-col items-center'>
          <div className='flex h-10 items-center justify-center'>
            <PreviousWeekButton
              withBorder
              disablePrevWeekButton={disablePrevWeekButton}
              onClick={() => setWeekIdx(weekIdx - 1)}
            />
          </div>
          <div className='my-px h-8 w-12 pt-0.5 text-center text-sm'>{currentTimezone}</div>
        </div>
        <div className='pt-px'>
          {rangeMap(startHour, endHour, (hour) => (
            <div key={hour} className='h-12'>
              <Text h='200' color='gray-500' uppercase className='leading-3'>
                {hour > 12 ? hour % 12 : hour} {hour >= 12 ? 'PM' : 'AM'}
              </Text>
            </div>
          ))}
        </div>
      </div>
      <div className='flex flex-1 flex-col'>
        <div className='flex divide-x divide-gray-200 border-l border-gray-200'>
          <DragDropContext
            onDragEnd={(result) => {
              if (result.reason === 'DROP' && result.destination) {
                const event = events.find((e) => e.id === result.draggableId);
                const slot = slotFromKey(result.destination.droppableId);
                if (event && slot) {
                  track('availability_slot_updated', { place: 'drag_and_drop' });
                  updateEvent({ ...event, ...slot }, true);
                }
              }
            }}
          >
            {dates.map((date, i) => {
              return (
                <DayColumn
                  userIdsWithHiddenEvents={userIdsWithHiddenEvents}
                  showConflicts={showConflicts}
                  frozen={frozen}
                  // TODO: not sure about !endDate
                  disabled={
                    startOfDay(date) < startOfDay(startDate) || (!!endDate && startOfDay(date) > startOfDay(endDate))
                  }
                  key={date.toDateString()}
                  calendars={calendars}
                  events={events.filter(
                    (e) => e.day === date.getDay() && (!e.single || e.date === getStringFromDate(date))
                  )}
                  date={getStringFromDate(date)}
                  startHour={startHour}
                  endHour={endHour}
                  timezone={timezone}
                  studyDuration={initialDuration}
                  onCreateEvent={handleCreateEvent}
                  onUpdateEvent={updateEvent}
                  onDeleteEvent={handleDeleteEvent}
                  schedulingIncrement={schedulingIncrement}
                  weekIdx={weekIdx}
                />
              );
            })}
          </DragDropContext>
        </div>
      </div>
      <div className='w-12 border-l border-gray-200'>
        <div className='mb-1.5 mt-1 w-full'>
          <div className='flex h-10 items-center justify-center'>
            <NextWeekButton
              withBorder
              disableNextWeekButton={disableNextWeekButton}
              onClick={() => setWeekIdx(weekIdx + 1)}
            />
          </div>
        </div>
      </div>
      {!initialFetchDone && <Loading absolute zIndex={20} />}
    </div>
  );
};

export const AttendeesCalendars = memo(AttendeesCalendarsComponent);
