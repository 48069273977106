import * as React from 'react';
import { useState } from 'react';

import pluralize from 'pluralize';

import { ExtraInfo } from '@components/common';
import {
  BankIconSVG,
  CalendlyIconSVG,
  CouponIconSVG,
  DurationIconSVG,
  FocusGroupsSVG,
  GiftIconSVG,
  IncentiveIconSVG,
  LanguageIconSVG,
  OnlineTaskSVG,
  SlotsIconSVG,
  SurveyIconSVG,
  VideoCallIconSVG
} from '@components/svgs';
import { humanize, noop } from '@components/utils';
import { ChangeIncentiveModal, useStudyFunder } from '@components/Wallet';

import { incentiveStr } from '../../helpers/incentiveStr';

const INCENTIVE_ICONS = {
  tremendous: IncentiveIconSVG,
  manual: IncentiveIconSVG,
  coupon: CouponIconSVG,
  product: GiftIconSVG,
  other: GiftIconSVG
};

const LANGUAGE_LABELS = {
  en: 'English',
  es: 'Spanish',
  'pt-BR': 'Brazilian Portuguese',
  de: 'German',
  fr: 'French',
  it: 'Italian'
};

const getStyleValue = (style: string) => {
  const styleMap = {
    video_call: 'Interviews'
  };

  return styleMap[style] || humanize(style);
};

export const Details: React.FC<React.PropsWithChildren<{ study: Study }>> = ({ study }) => {
  const { style, duration_in_minutes, incentive_method, moderated, start_url, language, is_nylas, focus_group } = study;

  const languageString = LANGUAGE_LABELS[language];

  const funder = useStudyFunder({ study });

  const [fundModalOpen, setFundModalOpen] = useState(false);

  const getIcon = () => {
    switch (style) {
      case 'online_task':
        return () => <OnlineTaskSVG className='mr-1.5 h-5 w-5' />;
      case 'video_call':
        return () =>
          focus_group ? <FocusGroupsSVG className='mr-1.5 h-5 w-5' /> : <VideoCallIconSVG className='mr-1.5 h-5 w-5' />;
      default:
        return () => <SurveyIconSVG className='mr-1.5 h-5 w-5' />;
    }
  };

  return (
    <div id='study-details' className='relative border-b border-t border-gray-200 bg-white px-6 py-3'>
      <div className='tablet:mt-0 tablet:flex-row tablet:flex-wrap tablet:space-y-0 flex flex-col items-center space-y-3'>
        {style && (
          <ExtraInfo tooltip='Study type' Icon={getIcon()}>
            {focus_group ? 'Focus Groups' : getStyleValue(style)}
          </ExtraInfo>
        )}
        <ExtraInfo tooltip='Duration' Icon={DurationIconSVG}>
          {duration_in_minutes ? `${duration_in_minutes} minutes` : <i>Not set</i>}
        </ExtraInfo>
        <ExtraInfo tooltip='Participant limit' Icon={SlotsIconSVG}>
          {study.maximum_slots ? pluralize('slots', study.maximum_slots, true) : <i>Unlimited</i>}
        </ExtraInfo>
        {incentive_method && (
          <ExtraInfo
            tooltip={`${humanize(incentive_method)} incentive`}
            Icon={INCENTIVE_ICONS[incentive_method]}
            className='max-w-80'
          >
            <span className='truncate'>{incentiveStr(study)}</span>
          </ExtraInfo>
        )}
        {study.state === 'closed' && study.funding.local_issued > 0 && (
          <ExtraInfo tooltip='Incentive funding' Icon={BankIconSVG}>
            {study.currencySymbol}
            {study.funding.local_issued / 100} spent
          </ExtraInfo>
        )}
        {study.state !== 'closed' && study.funding.usd_total > 0 && (
          <ExtraInfo tooltip='Incentive funding' Icon={BankIconSVG}>
            {study.currencySymbol}
            {study.funding.local_total / 100} funded
          </ExtraInfo>
        )}
        {study.funding.usd_total === 0 && study.funding.usd_outstanding > 0 && (
          <ExtraInfo onClick={() => setFundModalOpen(true)} tooltip='Pending funding' Icon={BankIconSVG}>
            ${study.funding.usd_outstanding / 100} to fund
          </ExtraInfo>
        )}
        {moderated && !is_nylas && (
          <ExtraInfo tooltip='Booking calendar' Icon={CalendlyIconSVG}>
            {start_url?.replace(/https?:\/\//, '')}
          </ExtraInfo>
        )}
        <ExtraInfo
          tooltip={`All system-generated communications on the participant experience will be shown in ${languageString}.`}
          Icon={LanguageIconSVG}
        >
          {languageString}
        </ExtraInfo>
      </div>
      {fundModalOpen && (
        <ChangeIncentiveModal
          limitCount={study.maximum_slots}
          study={study}
          funder={funder}
          onCancel={() => setFundModalOpen(false)}
          onConfirm={noop}
        />
      )}
    </div>
  );
};
