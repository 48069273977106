import React, { createContext, useContext, useEffect, useState } from 'react';

import { track } from '@components/tracking';
import { useDefaultView } from 'hooks/useDefaultView';
import { useLocalStorage } from 'hooks/useLocalStorage';

export interface CollectionViewContext {
  scope: string;
  view: CollectionView;
  setView: (view: Partial<CollectionView>) => void;
  default: CollectionView | null;
  isDefault: boolean;
  reset?: () => void;
  setAsDefault?: () => void;
}

export const store = createContext<CollectionViewContext>({} as CollectionViewContext);

export const useCollectionView = (): CollectionViewContext => useContext(store);

const { Provider: BaseProvider } = store;

function equalViews(a: CollectionView, b: CollectionView | null) {
  if (!b) {
    return false;
  }

  return (
    a.sort.value === b.sort.value &&
    a.sort.desc === b.sort.desc &&
    a.filters === b.filters &&
    (a.columns || []).join(',') === (b.columns || []).join(',') &&
    a.groupBy === b.groupBy &&
    a.layout === b.layout &&
    a.tab === b.tab
  );
}

export const INITIAL_VIEW: CollectionView = {
  sort: {
    value: 'updated_at',
    desc: true
  },
  filters: '',
  layout: 'list',
  tab: 'insights',
  tableTab: ''
};

export interface CollectionViewProvider {
  scope: SavedView['scope'];
  page?: string;
  defaultable?: boolean;
  default?: CollectionView;
}

export const Provider: React.FC<React.PropsWithChildren<CollectionViewProvider>> = ({
  scope,
  default: initial,
  page,
  defaultable = false,
  children
}) => {
  const { view: defaultView, isLoading: defaultIsLoading, update: updateDefaultView } = useDefaultView(scope);

  const [storedView, setStoredView] = useLocalStorage<CollectionView>(`${scope}-${page || 'view'}`);

  const [view, setView] = useState<CollectionView>(storedView || defaultView || initial || INITIAL_VIEW);

  useEffect(() => {
    if (defaultView && !storedView) {
      setView(defaultView);
    }
  }, [defaultIsLoading]);

  function setPartialView(partial: Partial<CollectionView>) {
    const newView = { ...view, ...partial };
    setView(newView);
    track('changed_view_layout', { page: scope, layout: newView.layout });
    setStoredView(newView);
  }

  const isDefault = React.useMemo(() => {
    if (!defaultable) {
      return true;
    }
    return !!defaultView && equalViews(view, defaultView);
  }, [view, defaultView]);

  const canReset = defaultable && !!defaultView && !isDefault;

  const context = {
    scope,
    view,
    setView: setPartialView,
    default: defaultView,
    isDefault,
    reset: canReset
      ? () => {
          setView(defaultView);
        }
      : undefined,
    setAsDefault:
      isDefault || !defaultable
        ? undefined
        : () => {
            track('created_default_view', { page: scope });
            updateDefaultView(view);
          }
  };
  return <BaseProvider value={context}>{children}</BaseProvider>;
};
