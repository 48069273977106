import * as React from 'react';

import { Text } from '@components/common';
import { InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

export const Label: React.FC<React.PropsWithChildren<{ desc?: string }>> = ({ children, desc }) => (
  <div className='mb-1 flex flex-nowrap items-center space-x-2'>
    <Text bold>{children}</Text>
    {desc && (
      <Tooltip content={desc}>
        <InfoCircleIcon className='h-4 w-4' />
      </Tooltip>
    )}
  </div>
);
