import React from 'react';

export const InterviewsZDSIcon: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <svg width='78' height='78' viewBox='0 0 78 78' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M77.5278 77.8261H0V-3.05176e-05H77.5278V77.8261Z' fill='#FFC32D' />
      <path
        d='M38.5 56C29.4019 56 22 48.3738 22 39C22 29.6262 29.4019 22 38.5 22C47.5981 22 55 29.6262 55 39C55 48.3738 47.5981 56 38.5 56ZM38.5 22.5537C29.7004 22.5537 22.5413 29.9314 22.5413 39C22.5413 48.0686 29.7004 55.4463 38.5 55.4463C47.2997 55.4463 54.4587 48.0686 54.4587 39C54.4587 29.9314 47.2997 22.5537 38.5 22.5537Z'
        fill='white'
      />
      <path d='M34 31V47L47 38.8906L34 31Z' fill='white' />
    </svg>
  );
};
