import * as React from 'react';
import { useState } from 'react';

import { Button, Input, InputWithAddons, Loading, Select, Text } from '@components/common';
import { api } from 'api/reduxApi';
import { PageTitle } from 'components/common/helmets';

import { FormGroup, SmallLabel, STUDY_STYLE_OPTIONS } from './shared';

interface Props {
  study: Study;
  setStudy: (study: Study) => void;
}

export const NewStudy: React.FC<React.PropsWithChildren<Props>> = ({ study, setStudy }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [createStudy] = api.useCreateStudyMutation();

  function change(key, v) {
    setStudy({
      ...study,
      [key]: v
    });
  }
  async function handleSave() {
    setLoading(true);
    try {
      const resp = await createStudy({
        ...study,
        is_phantom: true,
        incentive_method: 'tremendous',
        participant_limit: 1
      }).unwrap();
      setStudy(resp);
    } catch {
      setError('Error creating study');
    }
    setLoading(false);
  }
  return (
    <>
      <PageTitle>New Study</PageTitle>
      {loading && <Loading absolute />}
      {error && (
        <Text color='red-600' bold>
          {error}
        </Text>
      )}
      <FormGroup>
        <SmallLabel>Title </SmallLabel>
        <Input value={study.title} autoFocus onChange={(v) => change('title', v)} className='w-full' />
      </FormGroup>
      <FormGroup>
        <div className='flex flex-row space-x-4'>
          <div className='tablet:w-1/2 w-full'>
            <SmallLabel>Incentive</SmallLabel>
            <InputWithAddons
              type='number'
              ariaLabel='Incentive'
              value={study?.incentive || ''}
              onChange={(v) => change('incentive', v)}
              className='w-full'
            />
          </div>
          <div className='tablet:w-1/2 w-full'>
            <SmallLabel>Style</SmallLabel>
            <Select value={study.style} options={STUDY_STYLE_OPTIONS} onChange={(v) => change('style', v)} />
          </div>
        </div>
      </FormGroup>
      <div className='text-right'>
        <Button small className='mr-2' onClick={() => setStudy(null as any)}>
          Cancel
        </Button>
        <Button primary small onClick={handleSave} disabled={!study.style || !study.incentive}>
          Save
        </Button>
      </div>
    </>
  );
};
