import { useContext } from 'react';

import { useAccount } from '@hooks/useAccount';
import { useUser } from '@hooks/useUser';
import { store as permissionsStore } from '@stores/permissions';

export type PermissionCheck<K> = (arg?: K) => boolean;
type CheckGenerator<K> = (
  params: Record<AccountRole, boolean> &
    Pick<GQAccount, 'status' | 'permissions'> & { user: { id: number; team_ids: number[] } }
) => PermissionCheck<K>;

const isAny: CheckGenerator<null> = () => () => true;
const isAdmin: CheckGenerator<null> =
  ({ admin }) =>
  () =>
    admin;
const isAdminOrCreator: CheckGenerator<Study | Participation | Candidate | Insight | InterviewTemplate> =
  ({ admin, creator }) =>
  () =>
    admin || creator;

const isActiveAndAdminOrCreator: CheckGenerator<Study | Candidate | Insight> =
  ({ admin, creator, status }) =>
  () =>
    ['active', 'trial'].includes(status) && (admin || creator);

const fundStudy: CheckGenerator<Study> = (props) => (study: Study) => {
  return isAdmin(props)() || props.permissions.fund_study !== 'admin';
};
const manageTags: CheckGenerator<null> = (props) => () => {
  return isAdmin(props)() || (props.permissions.manage_tags === 'creator' && props.creator);
};
const manageStudyTags: CheckGenerator<Study> = (props) => (study) => {
  return isAdminOrCreator(props)();
};

const manageAttrs: CheckGenerator<null> = (props) => () => {
  return isAdmin(props)() || (props.permissions.manage_attrs === 'creator' && props.creator);
};

const updateStudy: CheckGenerator<Study> = (props) => (study: Study) => {
  return (
    isActiveAndAdminOrCreator(props)() &&
    (!study?.team_id ||
      props.user.team_ids.includes(study.team_id) ||
      isAdmin(props)() ||
      props.user.id === study.owner_id)
  );
};

const approveStudy: CheckGenerator<void> = (props) => () => {
  return isAdmin(props)() || props.permissions.approve_studies === 'creator';
};

const viewAllCandidates: CheckGenerator<null> = (props) => () => {
  return (
    isAdmin(props)() ||
    (props.permissions.view_all_candidates === 'creator' && props.creator) ||
    props.permissions.view_all_candidates === 'observer'
  );
};

const showPII: CheckGenerator<null> = (props) => () => {
  return (
    isAdmin(props)() ||
    (props.permissions.view_pii === 'creator' && props.creator) ||
    props.permissions.view_pii === 'observer'
  );
};

const viewAllStudies: CheckGenerator<null> = (props) => () => {
  return (
    isAdmin(props)() ||
    (props.permissions.view_all_studies === 'creator' && props.creator) ||
    props.permissions.view_all_studies === 'observer'
  );
};

const canJoin: CheckGenerator<Team> = (props) => (team) => (!props.observer && team?.allow_join) || isAdmin(props)();

// Turns out this is way too over engineered as we dont actually do any permission checks.
// BUT, for future reference this would be used like this:
// const updateStudy: PermissionCheck<Study> = ({admin, user}) => (study) => (admin || study.owner_id === user.id),
// where we would add user to PermissionCheck type
const CHECKS: Record<Permission, CheckGenerator<unknown>> = {
  isAdmin,
  canCreate: isAdminOrCreator,
  viewAllCandidates,
  showPII,
  viewAllStudies,
  canUseSeat: isAdmin,
  canBuySeat: isAdmin,
  createAccountInvitation: isActiveAndAdminOrCreator,
  addCandidate: isAdminOrCreator,
  exportCsv: isAdmin,
  updateParticipation: isAdminOrCreator as CheckGenerator<Participation>,
  updateCandidate: isAdminOrCreator as CheckGenerator<Candidate>,
  deleteCandidate: isAdmin,
  updateRepo: isAdminOrCreator,
  updateReel: isAdminOrCreator,
  updateClip: isAdminOrCreator,
  updateInsight: isAdminOrCreator as CheckGenerator<Insight>,
  deleteInsight: isAdminOrCreator as CheckGenerator<Insight>,
  createStudy: isActiveAndAdminOrCreator as CheckGenerator<Study>,
  updateStudy: updateStudy as CheckGenerator<Study>,
  approveStudy,
  fundStudy,
  addStudyUser: isActiveAndAdminOrCreator as CheckGenerator<Study>,
  createTemplate: isAdminOrCreator,
  updateInterviewTemplate: isAdminOrCreator as CheckGenerator<InterviewTemplate>,
  manageTags,
  manageStudyTags,
  manageAttrs,
  manageSegments: isAdminOrCreator,
  manageTeams: isAdmin,
  canDeleteTagGroup: isAdmin,
  canJoin,
  disableFeatures: isAdmin
};

export function usePermission<T = null>(permission: Permission): PermissionCheck<T> {
  const { role } = useContext(permissionsStore);
  const { account } = useAccount();
  const user = useUser();
  if (!account || !user) {
    return () => false;
  }
  const { status, permissions } = account;

  const roles = {
    admin: false,
    creator: false,
    observer: false,
    deactivated: false,
    [role]: true,
    status,
    permissions,
    user: {
      id: user.id,
      team_ids: (user.teams || []).map((t) => t.id)
    }
  };

  return CHECKS[permission](roles);
}
