import * as React from 'react';

import { Column, HeaderProps } from 'react-table';
import * as timeago from 'timeago.js';
import { ELocalStorageKeys } from '@constants/localStorageKeys';

import { EXTERNAL_ATTRS } from '@components/config';
import { calculateNumber } from '@components/GQSurveyBuilder/utils';
import { BooleanCell } from '@components/shared/GridTable/components/inputs/BooleanCell';
import { ContactAccessCell } from '@components/shared/GridTable/components/inputs/ContactAccessCell';
import { DateCell } from '@components/shared/GridTable/components/inputs/DateCell';
import { FreeTextCell } from '@components/shared/GridTable/components/inputs/FreeTextCell';
import { MultipleChoiceCell } from '@components/shared/GridTable/components/inputs/MultipleChoiceCell';
import { NumberCell } from '@components/shared/GridTable/components/inputs/NumberCell';
import { RatingCell } from '@components/shared/GridTable/components/inputs/RatingCell';
import { SingleChoiceCell } from '@components/shared/GridTable/components/inputs/SingleChoiceCell';
import { TextCell } from '@components/shared/GridTable/components/inputs/TextCell';
import { NameCell } from '@components/shared/GridTable/components/NameCell';
import { NameHeader } from '@components/shared/GridTable/components/NameHeader';
import { OtherHeader } from '@components/shared/GridTable/components/OtherHeader';
import {
  ATTR_TO_SORT_ICON_MAPPING,
  QUESTION_TYPE_TO_SORT_ICON_MAPPING
} from '@components/shared/GridTable/components/OtherHeader/constants';
import { getFormattedDate, getFormattedMoney } from '@components/shared/GridTable/components/utils';
import { OnHover, OnToggle } from '@components/shared/GridTable/types';
import { FilterDefinition } from '@components/shared/TableFilters/types';
import {
  booleanCompare,
  dateColumns,
  datetimeCompare,
  statusColumns,
  studyLimitCompare
} from '@components/StudiesApp/components/StudyPublished/ParticipationTable/components';
import { StatusCell } from '@components/StudiesApp/components/StudyPublished/ParticipationTable/components/statusCell';
import { compact } from '@components/utils';
import HealthStatus from 'components/CandidatesApp/CandidatesIndex/HealthStatus';
import { getRealStatus } from 'components/shared/ParticipationPill';

import {
  getCustomerFieldId,
  getPreScreenerFieldId,
  getSurveyScreenerFieldId
} from '../../components/buildParticipationsFilterDefs';

export type ParticipationTableItem = {
  participation: Participation;
  limit: {
    order: number;
    name: string | null;
  } | null;
};

type BuildParticipationsColumns = (props: {
  customAttrs: any[];
  canPII: boolean;
  onClickParticipation: (row: ParticipationTableItem) => void;
  onClickModerator: (row: ParticipationTableItem) => void;
  onClickReviewTimeProposal: (row: ParticipationTableItem) => void;
  onSelectIncentive: (row: ParticipationTableItem, value: number) => void;
  onClickRateParticipation: (row: ParticipationTableItem, rating: number) => void;
  status: Participation['status'];
  hasScreener: boolean;
  hasExternalCandidates: boolean;
  style: Study['style'];
  currencySymbol: Study['currencySymbol'];
  onGQ: boolean;
  isVisible: (name: string) => boolean;
  setSortValue: (value: string, desc: boolean) => void;
  resetOnClick: () => void;
  handleToggleCheckBox: OnToggle;
  handleMouseOver: OnHover;
  hoveredRows: number[];
  preScreenerHasIdealAnswers: boolean;
  preScreenerQuestions: ScreenerField[];
  surveyScreenerQuestions: ScreenerField[];
  hasParticipantLevelIncentives: boolean;
  focus_group?: boolean;
  columnsWidth: Record<string, number> | null;
  addFilter: (filter: string) => void;
  definitions: FilterDefinition<Participation>[];
  setAllSelected?: (value: React.SetStateAction<boolean>) => void;
  setShowSelectAll?: (value: ((prevState: boolean) => boolean) | boolean) => void;
  enableTeams?: boolean;
  serverSideParticipationsEnabled: boolean;
  teams?: Team[];
  incentiveMethod?: Study['incentive_method'];
}) => Column<ParticipationTableItem>[];

export const buildParticipantsColumns: BuildParticipationsColumns = ({
  preScreenerHasIdealAnswers,
  preScreenerQuestions,
  surveyScreenerQuestions,
  customAttrs,
  canPII,
  onClickParticipation,
  onClickModerator,
  onClickReviewTimeProposal,
  onSelectIncentive,
  onClickRateParticipation,
  status,
  hasScreener,
  hasExternalCandidates,
  style,
  currencySymbol,
  onGQ,
  isVisible,
  setSortValue,
  handleMouseOver,
  handleToggleCheckBox,
  resetOnClick,
  hoveredRows,
  hasParticipantLevelIncentives,
  focus_group,
  columnsWidth,
  addFilter,
  definitions,
  setAllSelected,
  setShowSelectAll,
  enableTeams,
  serverSideParticipationsEnabled,
  teams,
  incentiveMethod
}) => {
  const ParticipationOtherHeader: React.FC<React.PropsWithChildren<HeaderProps<ParticipationTableItem>>> = (props) => {
    const definition = definitions.find((d) => d.id === props.column.id);

    return (
      <OtherHeader<ParticipationTableItem>
        storageKey={ELocalStorageKeys.PARTICIPANTS_TABLE_ORDER}
        {...props}
        setSortValue={setSortValue}
        addFilter={definition ? addFilter : undefined}
      />
    );
  };

  const NameColumnHeader = (props) => (
    <NameHeader
      {...props}
      addFilter={addFilter}
      setAllSelected={setAllSelected}
      setShowSelectAll={setShowSelectAll}
      resetOnClick={resetOnClick}
      setSortValue={setSortValue}
    />
  );

  const BASE_COLUMNS: Column<ParticipationTableItem>[] = compact([
    {
      Header: NameColumnHeader,
      accessor: (originalRow) => originalRow.participation.customer?.name,
      id: 'name',
      headerLabel: 'Name',
      Cell: (props) => (
        <NameCell
          {...props}
          onClickCandidate={onClickParticipation}
          onToggle={handleToggleCheckBox}
          handleMouseOver={handleMouseOver}
          hoveredRows={hoveredRows}
          addFilter={addFilter}
        />
      ),
      width: columnsWidth?.name || 250,
      disableFilters: true,
      sortType: (rowA, rowB, columnId, desc) => {
        const a = rowA.values[columnId] || '';
        const b = rowB.values[columnId] || '';

        if (!desc) {
          if (a === '') return 1;
          if (b === '') return -1;
        }

        return a.localeCompare(b);
      }
    },
    {
      id: 'segment',
      Header: ParticipationOtherHeader,
      accessor: (originalRow) => originalRow.limit,
      headerLabel: 'Segment',
      Cell: (props) => {
        const { limit } = props.row.original;

        if (!limit) {
          return <TextCell {...props} value='No match' tip="Doesn't match any segment" />;
        }

        return <TextCell {...props} value={limit.name ? limit.name : `Segment #${limit.order}`} />;
      },
      width: columnsWidth?.segment || 180,
      sortType: studyLimitCompare
    },
    {
      id: getCustomerFieldId('participations_count', serverSideParticipationsEnabled),
      accessor: (originalRow: ParticipationTableItem) => originalRow.participation.customer?.participations_count,
      Header: OtherHeader,
      headerLabel: '# of studies',
      Cell: TextCell,
      width: columnsWidth?.participations_count || 80,
      sortIconType: 'number'
    },
    {
      id: getCustomerFieldId('consented_at', serverSideParticipationsEnabled),
      accessor: (originalRow: ParticipationTableItem) => originalRow.participation.customer?.consented_at,
      Header: ParticipationOtherHeader,
      headerLabel: 'Opt-in date',
      Cell: (props) => (
        <TextCell {...props} value={getFormattedDate(props.row.original.participation.customer.consented_at)} />
      ),
      width: columnsWidth?.consented_at || 180,
      sortIconType: 'date',
      sortType: datetimeCompare,
      disableFilters: true
    },
    {
      id: getCustomerFieldId('current_year_usd_amount_in_cents', serverSideParticipationsEnabled),
      accessor: (originalRow: ParticipationTableItem) =>
        originalRow.participation.customer?.current_year_usd_amount_in_cents,
      Header: ParticipationOtherHeader,
      headerLabel: 'Incentives Paid (Current Year)',
      Cell: (props) => (
        <TextCell
          {...props}
          value={getFormattedMoney(props.row.original.participation.customer.current_year_usd_amount_in_cents)}
        />
      ),
      width: columnsWidth?.current_year_usd_amount_in_cents || 180,
      sortIconType: 'number'
    },
    {
      id: getCustomerFieldId('all_time_usd_amount_in_cents', serverSideParticipationsEnabled),
      accessor: (originalRow: ParticipationTableItem) =>
        originalRow.participation.customer?.all_time_usd_amount_in_cents,
      Header: ParticipationOtherHeader,
      headerLabel: 'Incentives Paid (All Time)',
      Cell: (props) => (
        <TextCell
          {...props}
          value={getFormattedMoney(props.row.original.participation.customer.all_time_usd_amount_in_cents)}
        />
      ),
      width: columnsWidth?.all_time_usd_amount_in_cents || 180,
      sortIconType: 'number'
    },

    {
      id: getCustomerFieldId('opted_in', serverSideParticipationsEnabled),
      accessor: (originalRow: ParticipationTableItem) => originalRow.participation.customer?.opted_in,
      Header: ParticipationOtherHeader,
      headerLabel: 'Opt-in',
      Cell: (props) => <BooleanCell {...props} value={props.row.original.participation.customer?.opted_in} />,
      width: columnsWidth?.opted_in || 80
    },
    canPII
      ? {
          id: getCustomerFieldId('email', serverSideParticipationsEnabled),
          accessor: (originalRow: ParticipationTableItem) => originalRow.participation.customer?.email,
          Header: ParticipationOtherHeader,
          headerLabel: 'Email',
          Cell: SingleChoiceCell,
          width: columnsWidth?.[getCustomerFieldId('email', serverSideParticipationsEnabled)] || 200,
          disableFilters: true
        }
      : false,
    canPII
      ? {
          id: getCustomerFieldId('phone_number', serverSideParticipationsEnabled),
          accessor: (originalRow: ParticipationTableItem) => originalRow.participation.customer?.phone_number,
          Header: ParticipationOtherHeader,
          headerLabel: 'Phone number',
          Cell: SingleChoiceCell,
          width: columnsWidth?.[getCustomerFieldId('phone_number', serverSideParticipationsEnabled)] || 200,
          disableFilters: true
        }
      : false,
    {
      Header: ParticipationOtherHeader,
      headerLabel: 'Status',
      accessor: (originalRow: ParticipationTableItem) => getRealStatus(originalRow.participation),
      id: 'status',
      width: columnsWidth?.status || 180,
      Cell: (props) => (
        <StatusCell {...props} onClickReviewTimeProposal={() => onClickReviewTimeProposal(props.row.original)} />
      )
    },
    incentiveMethod == 'coupon' && {
      Header: ParticipationOtherHeader,
      headerLabel: 'Coupon Code',
      accessor: (originalRow) => originalRow.participation.incentive_coupon,
      id: 'incentive_coupon',
      width: columnsWidth?.status || 180,
      Cell: TextCell
    },
    incentiveMethod != 'coupon' &&
      hasParticipantLevelIncentives && {
        Header: (props) => <ParticipationOtherHeader {...props} disableSort />,
        headerLabel: 'Incentive',
        accessor: (originalRow: ParticipationTableItem) => originalRow.participation.incentive_in_whole_currency,
        id: 'incentive_in_whole_currency',
        width: columnsWidth?.incentive_in_whole_currency || 150,
        sortIconType: 'number',
        Cell: (props) => {
          if (props.row.original.participation.completed_at) {
            return (
              <TextCell value={`${currencySymbol}${props.row.original.participation.incentive_in_whole_currency}`} />
            );
          } else {
            return (
              <NumberCell
                {...props}
                onSelectValue={(value: number) => onSelectIncentive(props.row.original, value)}
                labelPrefix={currencySymbol}
                value={props.row.original.participation.incentive_in_whole_currency}
              />
            );
          }
        }
      },
    preScreenerHasIdealAnswers &&
      status !== 'invited' && {
        Header: ParticipationOtherHeader,
        headerLabel: 'Ideal?',
        accessor: (originalRow: ParticipationTableItem) => originalRow.participation.ideal_pre_screener_response,
        id: 'preScreenerField.ideal_response',
        width: columnsWidth?.['preScreenerField.ideal_response'] || 80,
        sortType: booleanCompare,
        Cell: BooleanCell
      },
    preScreenerHasIdealAnswers &&
      status !== 'invited' && {
        Header: ParticipationOtherHeader,
        headerLabel: '# of ideal answers',
        accessor: (originalRow: ParticipationTableItem) => originalRow.participation.pre_screener_response_match_score,
        id: serverSideParticipationsEnabled
          ? 'pre_screener_response:match_score'
          : 'preScreenerField.ideal_answers_count',
        width: columnsWidth?.['pre_screener_response:match_score'] || 150,
        sortIconType: 'number',
        Cell: NumberCell
      },
    {
      Header: ParticipationOtherHeader,
      headerLabel: 'Token',
      accessor: (originalRow: ParticipationTableItem) => originalRow.participation.token,
      id: 'token',
      width: columnsWidth?.token || 150,
      Cell: TextCell
    },
    {
      id: getCustomerFieldId('invited_participations_count', serverSideParticipationsEnabled),
      accessor: (originalRow: ParticipationTableItem) =>
        originalRow.participation.customer?.invited_participations_count,
      Header: ParticipationOtherHeader,
      headerLabel: '# of invited studies',
      Cell: TextCell,
      width: columnsWidth?.invited_participations_count || 80,
      sortIconType: 'number'
    },
    {
      id: getCustomerFieldId('responded_participations_count', serverSideParticipationsEnabled),
      accessor: (originalRow: ParticipationTableItem) =>
        originalRow.participation.customer?.responded_participations_count,
      Header: ParticipationOtherHeader,
      headerLabel: '# of responded studies',
      Cell: TextCell,
      width: columnsWidth?.responded_participations_count || 80,
      sortIconType: 'number'
    },
    {
      id: getCustomerFieldId('completed_participations_count', serverSideParticipationsEnabled),
      accessor: (originalRow: ParticipationTableItem) =>
        originalRow.participation.customer?.completed_participations_count,
      Header: ParticipationOtherHeader,
      headerLabel: '# of completed studies',
      Cell: TextCell,
      width: columnsWidth?.completed_participations_count || 80,
      sortIconType: 'number'
    },
    {
      id: getCustomerFieldId('average_rating', serverSideParticipationsEnabled),
      accessor: (originalRow: ParticipationTableItem) => originalRow.participation.customer?.average_rating,
      Header: ParticipationOtherHeader,
      headerLabel: 'Average rating',
      Cell: NumberCell,
      width: columnsWidth?.average_rating || 150,
      sortIconType: 'number'
    },
    ['completed', 'canceled', 'no_show'].includes(status) && {
      Header: (props) => <ParticipationOtherHeader {...props} tooltip='Click a star to select a rating' />,
      headerLabel: 'Participant rating',
      accessor: (originalRow: ParticipationTableItem) => originalRow.participation.rating,
      id: 'rating',
      width: columnsWidth?.rating || 200,
      sortIconType: 'number',
      Cell: (props) => (
        <RatingCell
          onClick={(rating: number) => onClickRateParticipation(props.row.original, rating)}
          value={props.row.original.participation.rating}
        />
      )
    },
    hasExternalCandidates
      ? {
          Header: ParticipationOtherHeader,
          headerLabel: 'Source',
          accessor: (originalRow: ParticipationTableItem) => originalRow.participation.recruiting_source,
          id: 'recruiting_source',
          width: columnsWidth?.recruiting_source || 150,
          Cell: TextCell
        }
      : false,
    enableTeams
      ? {
          id: 'contact_access',
          accessor: (originalRow) => originalRow.participation?.customer?.team_ids,
          Header: (props) => <ParticipationOtherHeader {...props} tooltip='Which teams can contact this candidate' />,
          headerLabel: 'Contact access',
          Cell: (props) => (
            <ContactAccessCell
              {...props}
              candidate={props.row.original.participation?.customer}
              teamIds={props.row.original.participation?.customer?.team_ids}
              teams={teams}
            />
          ),
          width: columnsWidth?.team_ids || 200
        }
      : false,
    ['completed', 'booked', 'no_show'].includes(status) &&
      style === 'video_call' && {
        Header: ParticipationOtherHeader,
        headerLabel: 'Moderator',
        accessor: (originalRow: ParticipationTableItem) => originalRow.participation.moderator?.name,
        id: 'moderator',
        width: columnsWidth?.moderator || 180,
        Cell: (props) => (
          <TextCell
            onClick={status === 'booked' ? () => onClickModerator(props.row.original) : undefined}
            value={props.row.original.participation.moderator?.name}
          />
        )
      },
    {
      Header: ParticipationOtherHeader,
      headerLabel: 'Timezone',
      accessor: (originalRow: ParticipationTableItem) => originalRow.participation.customer?.timezone,
      id: getCustomerFieldId('timezone', serverSideParticipationsEnabled),
      width: columnsWidth?.timezone || 180,
      Cell: (props) => <TextCell value={props.row.original.participation.customer?.timezone} />
    },
    {
      Header: ParticipationOtherHeader,
      headerLabel: 'Last invited',
      accessor: (originalRow) => originalRow.participation.customer?.last_contacted_at,
      id: getCustomerFieldId('last_contacted_at', serverSideParticipationsEnabled),
      sortType: datetimeCompare,
      sortIconType: 'date',
      Cell: (props) => (
        <TextCell
          {...props}
          value={props.value ? timeago.format(props.row.original.participation.customer.last_contacted_at) : ''}
        />
      ),
      width: columnsWidth?.last_contacted_at || 200
    },
    {
      Header: ParticipationOtherHeader,
      headerLabel: 'Unsubscribed at',
      accessor: (originalRow) => originalRow.participation.customer?.unsubscribed_at,
      id: getCustomerFieldId('unsubscribed_at', serverSideParticipationsEnabled),
      sortType: datetimeCompare,
      sortIconType: 'date',
      Cell: (props) => (
        <TextCell
          {...props}
          value={props.value ? timeago.format(props.row.original.participation.customer.unsubscribed_at) : ''}
        />
      ),
      width: columnsWidth?.unsubscribed_at || 200
    },
    status === 'shortlisted' && {
      Header: ParticipationOtherHeader,
      headerLabel: 'Eligible',
      accessor: (originalRow: ParticipationTableItem) => originalRow.participation.customer?.health_status,
      id: getCustomerFieldId('eligible', serverSideParticipationsEnabled),
      width: columnsWidth?.eligible || 150,
      Cell: ({ row }) => <HealthStatus status={row.original.participation.customer?.health_status || []} />
    },
    ...dateColumns(style, hasScreener, onGQ, ParticipationOtherHeader, columnsWidth),
    ...statusColumns({
      status,
      style,
      onGQ,
      ParticipationOtherHeader,
      onClickParticipation,
      showInterviewColumn: !focus_group,
      columnsWidth
    })
  ]);

  const getExtraColumns = (attrs: Attr_[]): Column<ParticipationTableItem>[] => {
    return attrs.map(({ name, label, attr_type }) => {
      const col = {
        id: `extra.${name}`,
        headerLabel: label,
        Header: ParticipationOtherHeader,
        accessor: (originalRow) => originalRow.participation.customer?.extra[name],
        sortIconType: ATTR_TO_SORT_ICON_MAPPING[attr_type] || 'text',
        Cell: SingleChoiceCell,
        width: columnsWidth?.[`extra.${name}`] || 150
      } as Column<ParticipationTableItem>;

      switch (attr_type) {
        case 'multiple_choice':
          col.Cell = MultipleChoiceCell;
          col.sortType = (rowA, rowB, id, descending) => {
            const a = Array.isArray(rowA.values[id]) ? rowA.values[id]?.join(' ') : (rowA.values[id] ?? '');
            const b = Array.isArray(rowB.values[id]) ? rowB.values[id]?.join(' ') : (rowB.values[id] ?? '');

            if (!descending) {
              if (a === '') return 1;
              if (b === '') return -1;
            }

            if (a > b) return 1;
            if (b > a) return -1;
            return 0;
          };
          break;

        case 'text':
          col.filter = 'inclusion';
          break;

        case 'number':
          col.Cell = NumberCell;
          break;

        case 'free_text':
          col.filter = 'inclusion';
          col.Cell = FreeTextCell;
          break;

        case 'datetime':
          col.Cell = DateCell;
          col.sortType = datetimeCompare;
          break;

        case 'boolean':
          col.Cell = BooleanCell;
          col.sortType = booleanCompare;
      }
      return col;
    });
  };

  const getExternalAttrsColumns = (attrs: Attr_[]): Column<ParticipationTableItem>[] => {
    return attrs.map(
      ({ name, label }) =>
        ({
          id: `extra.${name}`,
          headerLabel: label,
          Header: ParticipationOtherHeader,
          accessor: (originalRow) => originalRow.participation.customer?.extra[name],
          sortIconType: 'text',
          Cell: TextCell,
          width: columnsWidth?.[`extra.${name}`] || 150
        }) as Column<ParticipationTableItem>
    );
  };

  const CUSTOM_COLUMNS = getExtraColumns(customAttrs);
  const EXTERNAL_COLUMNS = hasExternalCandidates ? getExternalAttrsColumns(EXTERNAL_ATTRS) : [];

  const preScreenerCols = (preScreenerQuestions || []).map(
    ({ label, id, field_type }, i) =>
      ({
        id: getPreScreenerFieldId(id, serverSideParticipationsEnabled),
        Header: ParticipationOtherHeader,
        headerLabel: label || `Question #${calculateNumber(preScreenerQuestions, i)}`,
        accessor: (originalRow) => originalRow.participation.screenerResults?.[id]?.value,
        width: columnsWidth?.[getPreScreenerFieldId(id, serverSideParticipationsEnabled)] || 180,
        sortIconType: QUESTION_TYPE_TO_SORT_ICON_MAPPING[field_type] || 'text',

        Cell: (props) => (
          <TextCell
            {...props}
            ideal={(props.row.original.screenerResults || {})[id]?.ideal}
            value={Array.isArray(props.value) ? props.value.join(', ') : props.value}
          />
        )
      }) as Column<ParticipationTableItem>
  );

  const surveyScreenerCols = (surveyScreenerQuestions || []).map(
    ({ label, id, field_type }, i) =>
      ({
        id: getSurveyScreenerFieldId(id, serverSideParticipationsEnabled),
        Header: ParticipationOtherHeader,
        headerLabel: label || `Question #${calculateNumber(surveyScreenerQuestions, i)}`,
        accessor: (originalRow) => originalRow.participation.screenerResults?.[id]?.value,
        width: columnsWidth?.[`surveyScreenerField.${id}`] || 180,
        sortIconType: QUESTION_TYPE_TO_SORT_ICON_MAPPING[field_type] || 'text',

        Cell: (props) => (
          <TextCell
            {...props}
            ideal={(props.row.original.screenerResults || {})[id]?.ideal}
            value={Array.isArray(props.value) ? props.value.join(', ') : props.value}
          />
        )
      }) as Column<ParticipationTableItem>
  );

  return compact(
    [...BASE_COLUMNS, ...CUSTOM_COLUMNS, ...preScreenerCols, ...surveyScreenerCols, ...EXTERNAL_COLUMNS].filter(
      (col) => col?.id && isVisible(col?.id)
    )
  );
};
