import React, { useEffect, useMemo, useRef, useState } from 'react';

import { createPortal } from 'react-dom';
import {
  Announcements,
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  KeyboardSensor,
  MeasuringStrategy,
  Modifier,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Props as TreeItemProps } from '@components/common/SortableTree/components/TreeItem';
import * as Models from '@components/SurveyBuilder/types/models';

import { SortableTreeItem } from './components/SortableTreeItem';
import { FlattenedItem, SensorContext } from './types';
import {
  buildTree,
  findItemDeep,
  flattenTree,
  getNodeCount,
  getProjection,
  getRelativeIndex,
  getSelectedItemPath,
  removeNodesOf,
  removeProperty,
  setProperty,
  sortableTreeKeyboardCoordinates
} from './utils';

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always
  }
};

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5
        })
      }
    ];
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing
    });
  }
};

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25
  };
};

export interface Props {
  collapsible?: boolean;
  indentationWidth?: number;
  indicator?: boolean;
  removable?: boolean;
  onAddTreeItem?: (parent_id: UniqueIdentifier | null) => void;
  onEditTreeItem?: (id: UniqueIdentifier, data: Partial<Pick<Models.TreeItem, 'label' | 'selected'>>) => void;
  onMoveTreeItem?: (id: UniqueIdentifier, position: number, parent_id: UniqueIdentifier | null) => void;
  onRemoveTreeItem?: (id: UniqueIdentifier) => void;
  renderItem?: (props: TreeItemProps) => React.ReactElement;
  onSelectItem?: (id: UniqueIdentifier, selectedAnswer: UniqueIdentifier[][]) => void;
  onCollapseNode?: (id: UniqueIdentifier) => void;
  onExpandNode?: (id: UniqueIdentifier) => void;
  allowMultipleSelection?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  items: Models.TreeItem[];
  setItems: (items: Models.TreeItem[], preventSave?: boolean) => void;
}

export const SortableTree = ({
  collapsible,
  indicator = false,
  indentationWidth = 16,
  removable,
  onAddTreeItem,
  onEditTreeItem,
  onMoveTreeItem,
  onRemoveTreeItem,
  renderItem,
  onSelectItem,
  allowMultipleSelection,
  onCollapseNode,
  onExpandNode,
  placeholder,
  isLoading,
  items,
  setItems
}: Props) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    parent_id: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce(
      (acc, { nodes, collapsed, id }) => (collapsed && nodes.length ? [...acc, id] : acc),
      []
    );

    return removeNodesOf(flattenedTree, activeId != null ? [activeId, ...collapsedItems] : collapsedItems);
  }, [activeId, items]);

  const projected =
    activeId && overId ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth) : null;

  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft
  });

  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth)
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter
    })
  );

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);
  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;

  const handleDragStart = ({ active: { id: activeId } }: DragStartEvent) => {
    setActiveId(activeId);
    setOverId(activeId);

    const activeItem = flattenedItems.find(({ id }) => id === activeId);

    if (activeItem) {
      setCurrentPosition({
        parent_id: activeItem.parent_id,
        overId: activeId
      });
    }

    document.body.style.setProperty('cursor', 'grabbing');
  };

  const handleDragMove = ({ delta }: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  };

  const handleDragOver = ({ over }: DragOverEvent) => {
    setOverId(over?.id ?? null);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    resetState();

    if (projected && over) {
      const { depth, parent_id } = projected;
      const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)));
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parent_id };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);

      const parentNode = parent_id ? findItemDeep(newItems, parent_id) : null;

      let itemsToSave = newItems;

      if (parentNode?.selected) {
        itemsToSave = setProperty([...newItems], parent_id, 'selected', () => false);
      }

      setItems(itemsToSave);

      onMoveTreeItem?.(active.id, getRelativeIndex(itemsToSave, active.id, parent_id), parent_id);
    }
  };

  const handleDragCancel = () => {
    resetState();
  };

  const resetState = () => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty('cursor', '');
  };

  const handleAdd = (parent_id: UniqueIdentifier | null) => {
    onAddTreeItem?.(parent_id);
  };

  const handleChange = (id: UniqueIdentifier, newLabel: string) => {
    const updatedItems = setProperty(items, id, 'label', () => {
      onEditTreeItem?.(id, { label: newLabel });

      return newLabel;
    });

    setItems(updatedItems);
  };

  const handleSelect = (id: UniqueIdentifier) => {
    const currentItems = allowMultipleSelection ? items : removeProperty(items, 'selected', id);

    const updatedItems = setProperty(currentItems, id, 'selected', (selected) => {
      onEditTreeItem?.(id, { selected: !selected });

      return !selected;
    });

    onSelectItem?.(id, getSelectedItemPath(updatedItems));

    setItems(updatedItems);
  };

  const handleCollapse = (id: UniqueIdentifier) => {
    const updatedItems = setProperty(items, id, 'collapsed', (value) => {
      value ? onExpandNode?.(id) : onCollapseNode?.(id);
      return !value;
    });

    setItems(updatedItems, true);
  };

  const getMovementAnnouncement = (eventName: string, activeId: UniqueIdentifier, overId?: UniqueIdentifier) => {
    if (overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (currentPosition && projected.parent_id === currentPosition.parent_id && overId === currentPosition.overId) {
          return;
        } else {
          setCurrentPosition({
            parent_id: projected.parent_id,
            overId
          });
        }
      }

      const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)));
      const overIndex = clonedItems.findIndex(({ id }) => id === overId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;

      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling: FlattenedItem | undefined = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parent_id: UniqueIdentifier | null = previousSibling.parent_id;
            previousSibling = sortedItems.find(({ id }) => id === parent_id);
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }

    return;
  };

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft
    };
  }, [flattenedItems, offsetLeft]);

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement('onDragMove', active.id, over?.id);
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement('onDragOver', active.id, over?.id);
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement('onDragEnd', active.id, over?.id);
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    }
  };

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map(
          ({ id, position, parent_id, hasSelectedNode, selected, label, nodes, collapsed, depth }) => (
            <SortableTreeItem
              key={id}
              id={id}
              items={flattenedItems}
              parent_id={parent_id}
              position={position}
              value={label}
              placeholder={placeholder}
              depth={id === activeId && projected ? projected.depth : depth}
              indentationWidth={indentationWidth}
              indicator={indicator}
              collapsed={Boolean(collapsed && nodes.length)}
              onChange={(e) => handleChange(id, e.currentTarget.value)}
              onCollapse={collapsible && nodes.length ? handleCollapse : undefined}
              childCount={nodes.length}
              selected={selected}
              hasSelectedNode={hasSelectedNode}
              canSelect={nodes.length === 0}
              onRemove={removable && onRemoveTreeItem ? onRemoveTreeItem : undefined}
              onAdd={handleAdd}
              onSelect={handleSelect}
              renderItem={renderItem}
              isLoading={isLoading}
            />
          )
        )}
        {createPortal(
          <DragOverlay dropAnimation={dropAnimationConfig} modifiers={indicator ? [adjustTranslate] : undefined}>
            {activeId && activeItem ? (
              <SortableTreeItem
                id={activeId}
                parent_id={null}
                depth={activeItem.depth}
                clone
                childCount={getNodeCount(items, activeId) + 1}
                value={activeItem.label}
                indentationWidth={indentationWidth}
                renderItem={renderItem}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
    </DndContext>
  );
};
