import * as React from 'react';
import { useState } from 'react';

import { changeParticipationStatus } from '@api/queries';
import { Button, Loading, Modal } from '@components/common';
import { Toast, useToaster } from '@components/stores/toaster';

import { IncentiveAction } from '../types';

interface Props {
  incentives: Incentive[];
  action: IncentiveAction;
  onCancel: () => void;
  onSuccess: () => void;
}

const titles: { [key in IncentiveAction]?: string } = {
  cancel: 'Mark as canceled',
  no_show: 'Mark as no show'
};
const text: { [key in IncentiveAction]?: string } = {
  cancel: 'canceled',
  no_show: 'no show'
};

const toasts: { [key in IncentiveAction]?: Toast } = {
  cancel: {
    icon: 'success',
    heading: 'Incentives canceled',
    text: 'Incentives and their participations were canceled'
  },
  no_show: {
    icon: 'success',
    heading: 'Marked as no show',
    text: 'Participations were marked as no show and their incentives canceled.'
  }
};

export const ChangeStatusModal: React.FC<React.PropsWithChildren<Props>> = ({
  incentives,
  action,
  onCancel,
  onSuccess
}) => {
  const [loading, setLoading] = useState<boolean>();
  const showToast = useToaster();

  // TODO: Ensure it's valid? When can you not cancel?
  // TODO: On cancel - cancel incentive as well?
  // const partiesByStudy = incentives.reduce((m, incentive) => {
  //   m[incentive.study.id] ||= []
  //   m[incentive.study.id].push(incentive)
  //   return m
  // }, [])

  function handleAction() {
    setLoading(true);
    // TODO: bulk status change
    Promise.all(incentives.map((i) => changeParticipationStatus(i.participation.id, action))).then(() => {
      setLoading(false);
      showToast(toasts[action] as any);
      onSuccess();
    });
  }
  //
  return (
    <Modal
      title={`Cancel incentives`}
      size='md'
      onClose={onCancel}
      renderFooter={() => (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button primary onClick={handleAction}>
            Confirm
          </Button>
        </>
      )}
    >
      <>
        {loading && <Loading absolute />}
        <ul className='ml-6 list-disc space-y-1'>
          <li>Any sent or pendings incentives will be canceled.</li>
          <li>Participations will be moved to {text[action]} status on their studies.</li>
          <li>No emails will be sent.</li>
        </ul>
      </>
    </Modal>
  );
};
