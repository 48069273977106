import * as React from 'react';

import Tippy from '@tippyjs/react';

import { Pill, PillProps } from '@components/common/Pill';

export type PartyStatus = 'sent_screener' | 'sent_thanks' | 'pending' | 'invite_queued' | Participation['status'];

export const COLORS: Record<PartyStatus, PillProps['color']> = {
  fresh: 'indigo',
  invited: 'orange',
  shortlisted: 'indigo',
  approved: 'indigo',
  requested: 'indigo',
  sent_screener: 'indigo',

  booked: 'yellow',
  started: 'yellow',
  pending: 'yellow',
  invite_queued: 'yellow',

  completed: 'green',
  sent_thanks: 'green',

  applied: 'pink',

  rejected: 'red',
  canceled: 'red',
  no_show: 'red',
  opted_out: 'red'
};

export const TEXTS: Partial<Record<PartyStatus, string>> = {
  booked: 'scheduled',
  fresh: 'new to study',
  invited: 'sent screener',
  requested: 'invited',
  rejected: 'removed'
};

const CANCELATION_REASONS: Record<string, string> = {
  study_closed: 'Study closed',
  manually_from_participants_table: 'Manually in app',
  participant_on_public_page: 'By candidate in app',
  forget_customer: 'Candidate deleted',
  external_request_closed: 'External recruit closed',
  external_candidate_unsubscribed: 'External candidate unsubscribed',
  external_submission_action: 'External candidate canceled ',
  event_canceled: 'Calendar event canceled',
  event_canceled_by_moderator: 'Calendar event canceled by moderator',
  event_canceled_by_candidate: 'Calendar event canceled by candidate',
  event_deleted_by_provider: 'Calendar event deleted'
};

const getIncentiveStatus = (participation: Participation): string | undefined => {
  return participation.incentive?.status;
};

export const getRealStatus = (participation: Participation): PartyStatus => {
  const { status, invited_at, applied_at, in_request_batch } = participation;

  if (in_request_batch) {
    return 'invite_queued';
  }
  if (status === 'shortlisted') {
    if (applied_at) {
      return 'applied';
    } else if (invited_at) {
      return 'sent_screener';
    }
  } else if (status === 'completed') {
    if (participation.thanked_at) {
      return 'sent_thanks';
    } else if (participation.action_required == 'pay') {
      return 'pending';
    }
  }

  return status;
};

const getRsvpStatus = (participation: Participation): string => {
  switch (participation?.rsvp) {
    case 'yes':
      return 'The participant has accepted the calendar event.';
    case 'no':
      return 'The participant has declined the calendar event.';
    case 'maybe':
      return 'The participant has marked the calendar event with a maybe.';
    default:
      return '';
  }
};

const getRsvpIcon = (participation: Participation): string => {
  switch (participation?.rsvp) {
    case 'yes':
      return '✅';
    case 'no':
      return '❌';
    case 'maybe':
      return '❓';
    default:
      return '';
  }
};

interface Props {
  participation: Participation;
  publicPill?: boolean;
}

export const ParticipationPill: React.FC<React.PropsWithChildren<Props>> = ({ publicPill, participation }) => {
  const status = getRealStatus(participation);
  const incentiveStatus = getIncentiveStatus(participation);
  const cancelReason: string | undefined =
    participation.cancelation_reason && CANCELATION_REASONS[participation.cancelation_reason];
  const rsvpStatus = getRsvpStatus(participation);
  const pill = <Pill color={COLORS[status]}>{TEXTS[status] || status.replace('_', ' ')}</Pill>;

  if (publicPill) return pill;

  if (participation.recruiting_source && participation.external_status) {
    return (
      <Tippy content={`Status on ${participation.recruiting_source}: ${participation.external_status}`}>
        <span>{pill}</span>
      </Tippy>
    );
  } else if (participation.status === 'completed' && incentiveStatus) {
    return (
      <Tippy content={`Incentive ${incentiveStatus}`}>
        <span>{pill}</span>
      </Tippy>
    );
  } else if (participation.status === 'canceled' && cancelReason) {
    return (
      <Tippy content={cancelReason}>
        <span>{pill}</span>
      </Tippy>
    );
  } else if (participation.status === 'booked' && rsvpStatus) {
    const rsvpIcon = getRsvpIcon(participation);

    return (
      <Tippy content={rsvpStatus}>
        <span>
          {pill} {rsvpIcon}
        </span>
      </Tippy>
    );
  } else {
    return pill;
  }
};
