import * as React from 'react';
import { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import tinytime from 'tinytime';

import { Button, Text } from '@components/common';
import { WindowLayout, WindowLayoutBody, WindowStickyHeader } from '@components/layouts/WindowLayout';
import { Tiptap, useTiptapFromDocumentId } from '@components/shared/Tiptap';
import { getCtaProps } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip/helpers';
import { getCtaText } from '@components/StudyMessages/utils/getCtaText';
import { BackArrowSVG } from '@components/svgs';
import { EditEmailTemplate } from '@components/TemplatesApp/pages/emails/edit';
import { TemplateForm } from '@components/TemplatesApp/pages/TemplatesIndexPage';

import { DeleteTemplateButton } from '../../components/DeleteTemplateButton';
import { EMAIL_TEMPLATE_PURPOSES } from '../../consts';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

const mdDot = () => <div className='tablet:block hidden rounded-full border-2 border-gray-400' />;

interface Props {
  emailTemplate: EmailTemplate;
}
export const ShowEmailTemplate: React.FC<React.PropsWithChildren<Props>> = ({ emailTemplate }) => {
  const [showSlideOut, setShowSlideOut] = useState(false);

  const navigate = useNavigate();
  const handleClickDelete = () => navigate('/study_templates/emails');

  const tiptap = useTiptapFromDocumentId({
    documentId: emailTemplate.document_id,
    readonly: true,
    ctaProps: getCtaProps({
      event: emailTemplate.event
    }),
    config: {
      image: { enable: true },
      link: { enable: true },
      highlight: { enable: false },
      artifacts: false,
      templates: false,
      cta: {
        enable: true,
        as: 'button',
        default: getCtaText()
      },
      mergeTags: {
        tags: [
          'candidate.first_name',
          'candidate.name',
          'study.style',
          'study.duration',
          'study.incentive',
          'study.incentive_instructions',
          'sender.first_name',
          'sender.name',
          'account.company_name'
        ]
      }
    }
  });

  const methods = useForm<TemplateForm>({
    defaultValues: {
      title: emailTemplate.title,
      event: emailTemplate.event,
      subject: emailTemplate.subject,
      default: emailTemplate.default
    }
  });

  return (
    <Tiptap {...tiptap.getTiptapProps()}>
      <WindowLayout>
        <WindowStickyHeader>
          <div className='w-full'>
            <Link to='/study_templates/emails'>
              <BackArrowSVG className='text-indigo-600' />
            </Link>
            <div className='tablet:flex-row tablet:justify-between mb-2 mt-6 flex flex-col items-center'>
              <div className='tablet:mr-4 w-full truncate'>
                <Text h='800' mb='2' className='truncate'>
                  {emailTemplate.title || 'Untitled email template'}
                </Text>
              </div>
              <div className='flex flex-nowrap items-center'>
                <Button iconSuffix='pencil' onClick={() => setShowSlideOut(true)}>
                  Edit
                </Button>
                <DeleteTemplateButton
                  buttonClassName='ml-4'
                  onSubmit={handleClickDelete}
                  template={emailTemplate}
                  kind='EmailTemplate'
                />
              </div>
            </div>
            <div className='tablet:flex-row tablet:space-x-2 tablet:space-y-0 flex flex-col items-center space-y-2'>
              {emailTemplate.event && (
                <>
                  <Text h='400' color='gray-500'>
                    {EMAIL_TEMPLATE_PURPOSES[emailTemplate.event]}
                  </Text>
                  {mdDot()}
                </>
              )}
              <Text h='400' color='gray-500'>
                Created by {emailTemplate.creator.name}
              </Text>
              {mdDot()}
              <Text h='400' color='gray-500'>
                Last updated {dateTemplate.render(emailTemplate.updated_at)}
              </Text>
            </div>
          </div>
        </WindowStickyHeader>
        <WindowLayoutBody className='h-full w-full bg-white'>
          <div className='px-page py-gutter h-full'>
            <div className='mx-auto w-full max-w-2xl'>
              <div className='border border-gray-200'>
                <div className='border-b px-6 py-4'>
                  <Text h='400' color='gray-500' mb='2'>
                    Subject line
                  </Text>
                  <Text>{emailTemplate.subject || 'No subject'}</Text>
                </div>
                <div className='border-b px-6 py-4'>
                  <Text h='400' color='gray-500' mb='2'>
                    Email body
                  </Text>
                  <tiptap.Content {...tiptap.getContentProps()} className='w-full text-gray-700' />
                </div>
              </div>
            </div>
          </div>
        </WindowLayoutBody>
      </WindowLayout>
      {showSlideOut && (
        <FormProvider {...methods}>
          <EditEmailTemplate emailTemplate={emailTemplate} onClose={() => setShowSlideOut(false)} />
        </FormProvider>
      )}
    </Tiptap>
  );
};
