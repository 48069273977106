import React from 'react';

// libs
import { Control, Controller, FieldValues, UnpackNestedValue } from 'react-hook-form';

// components
import { INPUTS } from '@components/CandidateAttrs';
// utils
import { humanize } from '@components/utils';

interface Props {
  newAttr: Attr_[];
  control: Control;
  getValues: (name: string) => UnpackNestedValue<FieldValues>;
  styles: StyleTypeValues;
}

const DEFAULT_VALUES = {
  text: '',
  datetime: 0,
  boolean: null,
  url: '',
  multiple_choice: '',
  default: ''
};

export const AttributesTable: React.FC<React.PropsWithChildren<Props>> = ({ newAttr, control, getValues, styles }) => {
  return (
    <>
      {newAttr.map((attr, i) => {
        const { name, attr_type: type } = attr;

        const label = attr.label || humanize(name);

        const AttrInput = INPUTS[type] || INPUTS.default;

        return (
          <Controller
            key={name}
            name={name}
            control={control}
            defaultValue={DEFAULT_VALUES[type]}
            render={({ value, onChange }) => (
              <AttrInput styles={styles} value={value} label={label} autoFocus setValue={onChange} attr={attr} />
            )}
          />
        );
      })}
    </>
  );
};
