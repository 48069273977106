import * as React from 'react';

export const changelog: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg
    className='mr-3 h-4 w-4 text-white group-hover:text-gray-300'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.6087 0C13.3455 0 2.52466 0 2.39131 0C2.10319 0 1.86956 0.233594 1.86956 0.52175V15.4783C1.86956 15.7664 2.10316 16 2.39131 16H13.6087C13.8969 16 14.1305 15.7664 14.1305 15.4783V0.52175C14.1305 0.233594 13.8969 0 13.6087 0ZM6.66469 1.04347H9.33535V1.71484H6.66469V1.04347ZM13.087 14.9565H2.91303V1.04347H5.62122V2.23659C5.62122 2.52472 5.85482 2.75834 6.14297 2.75834H9.8571C10.1452 2.75834 10.3789 2.52475 10.3789 2.23659V1.04347H13.087V14.9565Z'
      fill='white'
    ></path>
    <path
      d='M10.9913 4.96069H5.00869C4.72056 4.96069 4.48694 5.19429 4.48694 5.48244C4.48694 5.7706 4.72053 6.00419 5.00869 6.00419H10.9913C11.2794 6.00419 11.513 5.7706 11.513 5.48244C11.513 5.19429 11.2794 4.96069 10.9913 4.96069Z'
      fill='white'
    ></path>
    <path
      d='M10.9913 8.33569H5.00869C4.72056 8.33569 4.48694 8.56929 4.48694 8.85744C4.48694 9.1456 4.72053 9.37919 5.00869 9.37919H10.9913C11.2794 9.37919 11.513 9.1456 11.513 8.85744C11.513 8.56929 11.2794 8.33569 10.9913 8.33569Z'
      fill='white'
    ></path>
    <path
      d='M10.9913 11.7107H5.00869C4.72056 11.7107 4.48694 11.9443 4.48694 12.2324C4.48694 12.5206 4.72053 12.7542 5.00869 12.7542H10.9913C11.2794 12.7542 11.513 12.5206 11.513 12.2324C11.513 11.9443 11.2794 11.7107 10.9913 11.7107Z'
      fill='white'
    ></path>
  </svg>
);
