import React, { useEffect, useRef, useState } from 'react';

import type MuxPlayerElement from '@mux/mux-player';
import MuxPlayer from '@mux/mux-player-react';

import { InfoCircleIcon } from '@components/svgs';
import { RepoLiveStreamsChannel } from '@hooks/useWebsocket';
import consumer from 'helpers/consumer';

import { SVG } from '../RecordingWidget/SVGImages';

import { liveStreamDefaultMessages, liveStreamMessageMap, liveStreamTitles } from './constants';
import { LiveDocument } from './LiveDocument';

const RepoSessionsLiveStreamChannel = (live_stream_id) =>
  consumer.subscriptions.create(
    {
      channel: RepoLiveStreamsChannel,
      live_stream_id: live_stream_id
    },
    {}
  );

interface Props {
  liveStream: RepoSessionsLiveStream;
  setLiveStreamLocked: React.Dispatch<React.SetStateAction<boolean>>;
  setLiveStreamStatus: React.Dispatch<React.SetStateAction<RepoSessionsLiveStream['status']>>;
}

const statusTitleMap = (status: RepoSessionsLiveStreamStatus, message: string | null) => {
  return {
    title: liveStreamTitles[status],
    message: liveStreamDefaultMessages[status] || (message !== null ? liveStreamMessageMap[message] : null) || message
  };
};

const StatusPresentation: React.FC<React.PropsWithChildren<{ title: string; message: string | null }>> = ({
  title,
  message
}) => (
  <div className='rounded-lg border border-dashed border-gray-200 bg-gray-50 p-6 text-center'>
    <SVG className='mx-auto mb-6' />
    <span className='mb-2 block font-bold text-gray-700'>{title}</span>
    {message && (
      <span className='mb-6 block text-sm text-gray-500'>
        <span>{liveStreamMessageMap[message] || message}</span>
      </span>
    )}
  </div>
);

export const LiveStreamWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  liveStream: initialLiveStream,
  setLiveStreamLocked,
  setLiveStreamStatus
}) => {
  const [liveStream, setLiveStream] = useState<RepoSessionsLiveStream>(initialLiveStream);
  const videoRef = useRef<MuxPlayerElement>(null);

  const onError = (_) => {
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.src = 'dummy'; // This is here just to force a refresh of the player
        videoRef.current.src = liveStream.playback_url;
      }
    }, 1000);
  };

  useEffect(() => {
    if (liveStream) {
      RepoSessionsLiveStreamChannel(liveStream.id).received = (data) => {
        setLiveStream(data.message);
        setLiveStreamLocked(data.message.status === 'live');
        setLiveStreamStatus(data.message.status);
      };
    }
  }, []);

  if (!liveStream) {
    return null;
  }

  const getLiveStreamComponent = () => {
    switch (liveStream.status) {
      case 'scheduled':
      case 'joined_waiting_room':
      case 'ended':
      case 'failed':
        return <StatusPresentation {...statusTitleMap(liveStream.status, liveStream.message)} />;
      case 'live':
        return (
          <MuxPlayer
            ref={videoRef}
            onError={onError}
            className='w-full bg-gray-900 object-cover'
            src={liveStream.playback_url}
            streamType='live'
            autoPlay
            muted
          />
        );
      default: {
        const exhaustiveCheck: never = liveStream.status;
        return exhaustiveCheck;
      }
    }
  };

  return (
    <div className='flex h-full flex-col'>
      {getLiveStreamComponent()}
      {liveStream.status !== 'ended' && (
        <div className='md mt-2 flex items-center space-x-4 rounded bg-gray-50 px-4 py-2.5'>
          <InfoCircleIcon className='h-3.5 w-3.5 flex-shrink-0' />
          <span className='text-sm'>
            A 10-second delay is applied to the live stream to maintain quality and smoothness.
          </span>
        </div>
      )}
      {liveStream.temp_transcript_document_id && <LiveDocument documentId={liveStream.temp_transcript_document_id} />}
    </div>
  );
};
