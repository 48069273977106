import * as React from 'react';

import { Popper } from '@components/common';
import { NylasEvent } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/NylasEvent';
import { NylasEventPopup } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/NylasEventPopup';

interface Props {
  event: NylasSlotInstance;
  maxHeight: number;
  color?: UserColor;
  timezone: Timezone;
  schedulingIncrement: number;
}

export const Event: React.FC<React.PropsWithChildren<Props>> = ({
  event: e,
  maxHeight,
  color,
  timezone,
  schedulingIncrement
}) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  return (
    <Popper
      key={e.id}
      content={() => <NylasEventPopup timezone={timezone} event={e as NylasSlotInstance} />}
      offset={[4, 0]}
      placement='left-start'
      onOpen={() => requestAnimationFrame(() => dropdownRef.current?.focus())}
      onClose={() => requestAnimationFrame(() => buttonRef.current?.focus())}
      zIndex={50}
      closeOnEscape
      closeOnClickOutside
    >
      <div className='relative flex-1'>
        <div style={{ zIndex: 9 }} className='absolute flex w-full flex-1'>
          <div aria-label='Event body' className='flex-1 overflow-hidden'>
            <NylasEvent
              ref={buttonRef}
              event={e}
              maxHeight={maxHeight}
              timezone={timezone}
              color={color}
              schedulingIncrement={schedulingIncrement}
            />
          </div>
        </div>
      </div>
    </Popper>
  );
};
