import * as React from 'react';

import * as svgs from './svgs';

export const STYLE_ICONS: { [key: string]: React.FC<React.PropsWithChildren<any>> } = {
  council: svgs.council,
  online_task: svgs.task,
  panel: svgs.council,
  survey: svgs.survey,
  unmoderated_test: svgs.task,
  video_call: svgs.videoCall
};

const INCENTIVE_ICONS: { [key: string]: React.FC<React.PropsWithChildren<any>> } = {
  manual: svgs.monetizationOn,
  tremendous: svgs.monetizationOn,
  coupon: svgs.ticket,
  product: svgs.gift,
  other: svgs.gift
};

const DEVICE_ICONS: { [key: string]: React.FC<React.PropsWithChildren<any>> } = {
  desktop: svgs.desktop,
  mobile: svgs.mobile
};

interface Props {
  study: Study;
  brand?: Brand;
}

export const StudyDetailsList: React.FC<Props> = ({ study, brand }) => {
  const getSidebarIcons = () => [
    STYLE_ICONS[study.style],
    svgs.alarm,
    INCENTIVE_ICONS[study.incentive_method ?? ''],
    DEVICE_ICONS[study.device_type]
  ];

  return (
    <ul className='h400 space-y-3'>
      {study.landing_page?.rendered_sidebar_items.map((renderedItem) => {
        const itemValue: string = renderedItem[1];
        const itemIndex: number = Number(renderedItem[2]);

        const Icon = getSidebarIcons()[itemIndex];

        if ((!study.has_incentive && itemIndex === 2) || (itemIndex === 3 && study.style !== 'unmoderated_test')) {
          return null;
        }

        return (
          <li key={itemIndex} className='flex items-center'>
            {Icon && (
              <div className='flex w-10 justify-center'>
                <Icon />
              </div>
            )}
            {itemValue}
          </li>
        );
      })}
    </ul>
  );
};
