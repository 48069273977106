import * as React from 'react';

import { CellFC } from './types';

export const Thumbnail: CellFC = ({ row: { original: item }, cell }) => {
  const src = item.session.recording?.mux_video?.thumbnail_url;

  const style = src
    ? {
        backgroundImage: `url(${src})`
      }
    : {};

  return <div className='h-10 w-16 rounded-sm bg-gray-200 bg-cover bg-center' style={style} />;
};
