import React from 'react';

import { CreateCsvExportButton } from '@components/common';
import { Tooltip } from '@components/Tooltip';
import { usePermission } from '@hooks/usePermission';
import { api } from 'api/reduxApi';
import { useFeature } from 'hooks/useFeature';

interface Props {
  study?: Study;
  currentTab: string;
}
export const ExportCsvButton: React.FC<React.PropsWithChildren<Props>> = ({ study, currentTab }) => {
  const [createCsvExport, { isSuccess: csvExportSuccess, error: csvExportError, isLoading: csvExportLoading }] =
    api.useCreateStudyScreenerCsvExportMutation();

  const cardSortEnabled = useFeature('unmoderated_card_sort');
  const treeTestEnabled = useFeature('unmoderated_tree_test');

  const isAdmin = usePermission('isAdmin')();

  const handleCsvExport = async () => {
    if (!study?.survey_screener?.id) return;

    await createCsvExport({ study_id: study.id, screener_id: study.survey_screener.id });
  };

  if (!study?.survey_screener || !['overall', 'individual'].includes(currentTab)) {
    return null;
  }

  return (
    <div className='flex flex-1 items-center justify-end'>
      <Tooltip
        content={!isAdmin ? 'Only admin users may export Survey results' : 'Coming soon'}
        isDisabled={isAdmin && (study.style !== 'unmoderated_test' || (!cardSortEnabled && !treeTestEnabled))}
      >
        <CreateCsvExportButton
          data-testid='export-csv-button'
          medium
          isLoading={csvExportLoading}
          isSuccess={csvExportSuccess}
          isError={!!csvExportError}
          disabled={!isAdmin || (study.style === 'unmoderated_test' && (cardSortEnabled || treeTestEnabled))}
          successMessage='Exported the responses to a CSV file. You will receive an email with the link when it is ready.'
          errorMessage='Something went wrong when exporting the responses as a CSV file. Please try again later.'
          onClick={handleCsvExport}
        />
      </Tooltip>
    </div>
  );
};
