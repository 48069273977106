import React, { useState } from 'react';

import { PopperDropdown } from '@components/common';
import { useDeviceType } from '@hooks/useDeviceType';
import { Toast, useToaster } from '@stores/toaster';
import { useCollectionView } from 'components/stores/view';
import { GroupBySVG, LayoutSVG, SortBySVG } from 'components/svgs';

import { ConfirmModal, DefaultViewControls } from './components';

interface Props {
  renderLayout?: () => React.ReactNode;
  renderGroupBy?: () => React.ReactNode;
  renderSortBy?: () => React.ReactNode;
  renderToggle?: () => React.ReactNode;
  renderOnBodyRoot?: boolean;
  isPopperOpen?: boolean;
}

const successReset = (): Toast => ({
  heading: 'View updated',
  icon: 'success',
  text: `We've reset to your default view.`
});

const successSet = (): Toast => ({
  heading: 'Default view saved',
  icon: 'success',
  text: `We've saved the view as the default.`
});

export const View: React.FC<React.PropsWithChildren<Props>> = ({
  renderOnBodyRoot,
  renderLayout,
  renderToggle,
  renderGroupBy,
  renderSortBy,
  isPopperOpen = false
}) => {
  const [confirmOpen, setConfirmOpen] = useState<boolean>();
  const { isMobile } = useDeviceType();
  const showToast = useToaster();

  const viewHook = useCollectionView();

  return (
    <>
      {confirmOpen && (
        <ConfirmModal
          title='Save view as default'
          message='Saving the current view as default will make it default for everyone on your account.'
          onCancel={() => setConfirmOpen(false)}
          onConfirm={() => {
            viewHook.setAsDefault?.();
            showToast(successSet());
            setConfirmOpen(false);
          }}
        />
      )}
      <PopperDropdown
        medium
        isOpen={isPopperOpen}
        closeOnDropdownClick={false}
        popperProps={{
          zIndex: 50,
          placement: isMobile ? 'bottom' : 'bottom-end',
          renderOnBodyRoot
        }}
        icon='view'
        buttonClassName='xx-send-menu bg-white'
        text='View'
        aria-label='View'
        dropdownClass='w-96'
      >
        <div className='space-y-3 px-4 py-2'>
          {renderLayout && (
            <div className='flex flex-1'>
              <div className='flex w-1/2 items-center whitespace-nowrap text-sm font-bold'>
                <LayoutSVG className='mr-2' />
                Layout
              </div>
              <div className='w-1/2'>{renderLayout()}</div>
            </div>
          )}
          {renderGroupBy?.()}
          {renderSortBy && (
            <div className='flex flex-1'>
              <div className='flex w-1/2 items-center whitespace-nowrap text-sm font-bold'>
                <SortBySVG className='mr-2' />
                Sort by
              </div>
              <div className='w-1/2'>{renderSortBy()}</div>
            </div>
          )}
          {renderToggle?.()}
        </div>
        {viewHook && (viewHook.reset || viewHook.setAsDefault) && (
          <DefaultViewControls
            resetView={
              viewHook.reset
                ? () => {
                    viewHook.reset?.();
                    showToast(successReset()); // move to provider?
                  }
                : undefined
            }
            setView={() => setConfirmOpen(true)}
            setViewDisabled={viewHook.isDefault}
          />
        )}
      </PopperDropdown>
    </>
  );
};
