import React, { forwardRef, HtmlHTMLAttributes } from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { ErrorSvg } from '@components/svgs';

export const Error = forwardRef<HTMLDListElement, HtmlHTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...rest }, ref) => {
    return (
      <section ref={ref} className={cn('flex items-center text-red-600', className)} {...rest}>
        <ErrorSvg className='mr-2 h-4 w-4 flex-shrink-0' />
        <Text h='400' role='alert' aria-live='assertive' className='text-red-600'>
          {children}
        </Text>
      </section>
    );
  }
);
