import React, { useState } from 'react';

import { format as timeagoFormat } from 'timeago.js';

import { api } from '@api/reduxApi';
import { Alert, AlertHeading, AlertMessage, Button, Modal } from '@components/common';

import { AlertProps } from '../types';

import { BatchingSVG } from './svgs';

export const BatchingOn: React.FC<React.PropsWithChildren<AlertProps>> = ({ study, canAction }) => {
  const [updateStudy] = api.useUpdateStudyMutation();

  const [showModal, setShowModal] = useState(false);

  const { batch_size, next_batch_at, continuous } = study;

  const handleAction = () => {
    setShowModal(true);
  };

  const alertMessage =
    next_batch_at &&
    (continuous
      ? `We’re currently sending batch invites and continuous invites are on. Next batch of ${batch_size} will go out in ${timeagoFormat(
          next_batch_at
        )}.`
      : `We're currently sending invitations in batches to shortlisted candidates.  Next batch of ${batch_size} will go out in ${timeagoFormat(
          next_batch_at
        )}.`);

  return (
    <>
      <Alert
        className='my-4'
        Icon={continuous ? undefined : BatchingSVG}
        type='success'
        cta={canAction && <Button onClick={handleAction}>{continuous ? 'Cancel' : 'Cancel unsent invites'}</Button>}
      >
        <AlertHeading>Invitations in progress</AlertHeading>
        <AlertMessage>{alertMessage}</AlertMessage>
      </Alert>
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          title='Cancel invite automation'
          renderFooter={() => (
            <div className='flex w-full justify-end space-x-6'>
              <Button onClick={() => setShowModal(false)}>Don’t cancel</Button>
              <Button primary onClick={() => updateStudy({ id: study.id, batch_on: false })}>
                Confirm &amp; cancel
              </Button>
            </div>
          )}
        >
          This will cancel any batch invites and turn off continuous invites. You can start this process again at
          anytime.
        </Modal>
      )}
    </>
  );
};
