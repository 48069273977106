import { useNavigate } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { track } from '@components/tracking';
import { useToaster } from 'components/stores/toaster';

import { useUser } from './useUser';

export type Args = { onSuccess?: (study: Study) => void } & Pick<Study, 'style'> &
  Partial<Omit<Study, 'id' | 'style'>> & {
    trackEvent?: string;
    trackProps?: Record<string, any>;
  };

export const useCreateStudy = () => {
  const [createStudy, { isLoading: isCreating }] = api.useCreateStudyMutation();

  const navigate = useNavigate();
  const showToast = useToaster();
  const user = useUser();

  const handleCreateStudy = async ({ trackEvent, trackProps, onSuccess, ...rest }: Args) => {
    if (trackEvent) {
      track(trackEvent, trackProps);
    }

    try {
      const study = await createStudy({ owner_id: user.id, ...rest }).unwrap();
      navigate(`/studies/${study.id}`);

      onSuccess?.(study);
    } catch {
      showToast({
        heading: 'Something went wrong',
        icon: 'error',
        text: 'We couldn’t create the study. Please try again later.'
      });
    }
  };

  return { createStudy: handleCreateStudy, isCreating };
};
