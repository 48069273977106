import consumer from '@helpers/consumer';
import { AiChatsChannel } from '@hooks/useWebsocket';

export const createChannel = (id, opts = {}) =>
  consumer.subscriptions.create(
    {
      channel: AiChatsChannel,
      chat_id: id
    },
    opts
  );
