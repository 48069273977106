import { RefObject, useRef } from 'react';

import { unRef } from '@helpers/react/unRef';

import { ORIGIN } from '../constants';
import * as Types from '../types';

interface UseFigmaPrototypeReturnType {
  iframeRef: RefObject<HTMLIFrameElement>;
  moveBackward: () => void;
  moveForward: () => void;
  restart: () => void;
}

export const useFigmaPrototype = (): UseFigmaPrototypeReturnType => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handlePostEvent = (type: Types.EventType) => {
    const iframe = unRef(iframeRef);

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage({ type }, ORIGIN);
    } else {
      throw new Error('Iframe is not available, make sure ref is set correctly');
    }
  };

  const moveBackward = () => {
    handlePostEvent(Types.EventType.NAVIGATE_BACKWARD);
  };

  const moveForward = () => {
    handlePostEvent(Types.EventType.NAVIGATE_FORWARD);
  };

  const restart = () => {
    handlePostEvent(Types.EventType.RESTART);
  };

  return { iframeRef, moveBackward, moveForward, restart };
};
