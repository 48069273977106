import * as React from 'react';

import { FieldError } from 'react-hook-form';

import { rangeMap } from '@components/utils';
import { getFormKey } from 'components/Public/GQSurvey/helpers/getFormKey';

import { CommonProps } from '../types';

export const NumberRangeInput: React.FC<React.PropsWithChildren<CommonProps & { error?: FieldError }>> = ({
  field,
  field: { id, required, number_range_options },
  register,
  watch,
  error,
  autofocus
}) => {
  if (!number_range_options) return null; // at least dont break
  const { min, max, labels } = number_range_options;

  const labelName = getFormKey(field);

  const selected = watch(labelName);
  if (min >= max) return null;

  const options = rangeMap(min, max, (i) => ({ label: labels[i], value: i }));

  return (
    <fieldset
      className={`grid grid-cols-1 tablet:grid-cols-${options.length}`}
      role='radiogroup'
      aria-labelledby={String(id)}
      id={`radiogroup${String(id)}`}
    >
      <legend className='sr-only'>{field.label}</legend>
      {options.map(({ label, value }, i) => (
        <div className='tablet:flex-col tablet:py-0 flex items-center py-2' key={value}>
          <span className='text-custom-brand tablet:w-auto tablet:text-center w-5 flex-none text-right text-sm'>
            {value}
          </span>
          <input
            aria-checked={value === parseInt(selected)}
            id={`${String(id)}${label}`}
            className='focus-ring-custom-brand tablet:mx-0 tablet:mb-4 input-custom-brand mx-2 flex-none'
            type='radio'
            name={labelName}
            value={value}
            autoFocus={i === 0 && autofocus}
            ref={register({ required })}
            aria-describedby={`description-for-${id}`}
            aria-labelledby={`${String(id)}${label}`}
          />
          {label && (
            <label
              htmlFor={`${String(id)}${label}`}
              id={`${String(id)}${label}`}
              className='text-custom-brand tablet:text-center flex-grow text-left text-sm'
            >
              {label}
            </label>
          )}
        </div>
      ))}
    </fieldset>
  );
};
