import React from 'react';

import { ColumnDef } from '@tanstack/react-table';

import { ATTR_TO_SORT_ICON_MAPPING } from '@components/shared/GridTable/components/OtherHeader/constants';
import { Table } from '@components/shared/Table';

export interface DefinitionFnArgs {
  getColumnLabel: (key: string) => string;
  groupBy?: string;
  getStudyModerators?: (moderator_ids?: number[]) => TeamUser[];
  getStudyCreator?: (creator_id: number) => TeamUser | undefined;
  studyAttrs: Attr_[];
  hasPaginatedStudies?: boolean;
}

export type DefinitionFnReturn = ColumnDef<SimpleStudy>[];

export type DefinitionFn = (args: DefinitionFnArgs) => DefinitionFnReturn;

export const customAttrsStudiesColumnDefs: DefinitionFn = ({ getColumnLabel, studyAttrs, hasPaginatedStudies }) => {
  const columns: DefinitionFnReturn = studyAttrs.map((attr) => {
    return {
      id: attr.name,
      accessorFn: (originalRow) => originalRow?.custom_attributes?.[attr.name],
      header: (props) => (
        <Table.ColumnHeader
          sortIconType={ATTR_TO_SORT_ICON_MAPPING[attr.attr_type] || 'text'}
          disableSort={hasPaginatedStudies}
          {...props}
        >
          {getColumnLabel(attr.label || attr.name)}
        </Table.ColumnHeader>
      ),
      cell: (props) => (
        <Table.Cell
          columnName={getColumnLabel(attr.label || attr.name)}
          rowName={props.row.original.title}
          type={attr.attr_type}
          options={attr.values}
          {...props}
        />
      )
    };
  });

  return columns;
};
