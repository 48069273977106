import * as React from 'react';

import { useLocalStorage } from '@hooks/useLocalStorage';
import { PlayerPlayNoCircle } from 'components/svgs';

import { PLAYBACK_RATES } from '../constants';
import type { ControlsProps } from '../types';

import { PlaybackRates } from './PlaybackRates';

export const PrePlayControls: React.FC<
  React.PropsWithChildren<Partial<Pick<ControlsProps, 'play' | 'setPlaybackSpeed'>> & { showPlaybackRates?: boolean }>
> = ({ play, setPlaybackSpeed, showPlaybackRates = true }) => {
  const [defaultPlaybackSpeed] = useLocalStorage<number>('gq-default-playback-speed');
  const [playbackRate, setPlaybackRate] = React.useState<number>(defaultPlaybackSpeed || PLAYBACK_RATES[0]);

  const handleOnPlaybackRateChange = (rate: number) => {
    setPlaybackSpeed?.(rate);
    setPlaybackRate(rate);
  };

  return (
    <section className='absolute inset-0 flex flex-col items-center justify-center bg-gray-700 bg-opacity-50'>
      <button
        className='tablet:w-20 tablet:h-20 tablet:mb-6 mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-white text-indigo-600'
        aria-label='Play'
        onClick={play}
      >
        <PlayerPlayNoCircle className='tablet:w-8 tablet:h-8 tablet:ml-1.5 ml-1 h-6 w-6' />
      </button>
      {showPlaybackRates && (
        <PlaybackRates
          rates={PLAYBACK_RATES}
          activeRate={playbackRate}
          onChange={(rate) => handleOnPlaybackRateChange(rate)}
        />
      )}
    </section>
  );
};
