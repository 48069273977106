import React from 'react';

import * as dropdowns from '@components/shared/TableFilters/components/dropdowns';
import { FilterType } from '@components/shared/TableFilters/types';
import { FolderSVG, PersonSVG } from '@components/svgs';
import { ATTR_TYPES_TO_ICON } from 'components/shared/TableFilters/definitions/buildCandidateFilterDefs';
import { humanize } from 'components/utils';

import { PartialCollection } from './types';

const CUSTOM_ICONS = {
  person: PersonSVG,
  folder: FolderSVG
};

const ATTR_TYPE_TO_TYPE: Record<Attr_['attr_type'], FilterType> = {
  number: 'number',
  boolean: 'boolean',
  datetime: 'date',
  multiple_choice: 'multiple_choice',
  text: 'text',
  url: 'text',
  free_text: 'free_text'
};

function getDefaultCustomAttrIcon(attr: Attr_): React.FC<React.PropsWithChildren<unknown>> {
  const { attr_type, name } = attr;
  return CUSTOM_ICONS[name] || ATTR_TYPES_TO_ICON[attr_type] || ATTR_TYPES_TO_ICON['string'];
}

export function customAttrToCollectionAttribute<D>(attr: Attr_, queryable?: boolean): PartialCollection<D> {
  const { name, label, attr_type, values, is_pii } = attr;
  const hasValues = Array.isArray(values) && values.length > 0;

  return {
    id: attr.lookup, // we might consider just having scope instead of dot sepereated ids.
    getValue: (record) => record[attr.lookup.split('.')[0]][name],
    type: attr_type === 'text' && !hasValues ? 'free_text' : ATTR_TYPE_TO_TYPE[attr_type],
    name: label,
    Icon: getDefaultCustomAttrIcon(attr),
    groupable: hasValues,
    editable: true,
    sortable: queryable,
    filterable: queryable,
    pii: is_pii,
    filter: {
      operators: hasValues ? ['includes_any', 'includes_none', 'includes_all', 'is_blank', 'is_present'] : undefined,
      Component: hasValues
        ? (props) => (
            <dropdowns.Choose
              {...props}
              options={(values || []).map((value) => ({
                label: value ? humanize(value) : 'None',
                value
              }))}
            />
          )
        : undefined
    },
    group: {
      options: hasValues ? (values || []).concat(['']) : [] // i wonder if this really just needs to be moved higer up
    }
  };
}
