import React from 'react';

import { Column, Grid } from '@components/common/Grid';
import { Basic } from '@components/shared/Skeleton';

export const Skeleton: React.FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <div className='desktop:px-12 tablet:px-6 mobile:px-3 desktop:flex-row flex flex-col justify-between border-b border-gray-200 py-4'>
      <div className='desktop:mb-0 mb-4 mr-4 flex flex-col'>
        <Basic mb='2' width={368} height={24} />

        <Basic width={352} height={16} />
        <Basic width={97} height={16} />
        <div className='flex items-center space-x-1.5'>
          <Basic width={18} height={18} />
          <Basic width={128} height={20} />
        </div>
      </div>
      <div className='flex items-start space-x-4 overflow-x-hidden'>
        {/* <Basic width={40} height={40} /> */}
        {/* <Basic width={85} height={40} /> */}
        <Basic width={95} height={40} />
        {/* <Basic width={48} height={40} /> */}
        <Basic width={48} height={40} />
        <Basic width={48} height={40} />
      </div>
    </div>

    <div className='desktop:px-12 tablet:px-6 mobile:px-3 py-6'>
      <Grid desktop={12}>
        <Column mobile={0} tablet={0} desktop={3} className='desktop:block hidden' />
        <Column desktop={6}>
          <Basic width='full' height={324} />
          <div className='flex justify-between border border-gray-200 p-3'>
            <Basic width={71} height={16} />
            <div className='flex space-x-3'>
              <Basic width={73} height={18} />
              <Basic width={34} height={18} />
            </div>
          </div>
          <div className='flex flex-col border border-t-0 border-gray-200 p-3'>
            <div className='flex justify-between py-3'>
              <Basic width={90} height={16} />
              <div className='flex space-x-1.5'>
                <Basic width={30} height={16} />
                <Basic width={14} height={16} />
              </div>
            </div>
            <Basic width='95%' height={16} />
            <Basic width='92%' height={16} />
            <Basic width='93%' height={16} />
            <Basic width='97%' height={16} />
            <Basic width='91%' height={16} />
            <Basic width='92%' height={16} />
            <Basic width='86%' height={16} />
            <Basic width='97%' height={16} />
            <Basic width='74%' height={16} />
            <div className='mt-3 flex justify-between border-t border-gray-200 py-3'>
              <Basic width={98} height={16} />
              <div className='flex space-x-1.5'>
                <Basic width={30} height={16} />
                <Basic width={14} height={16} />
              </div>
            </div>
          </div>
        </Column>
        <Column mobile={0} tablet={0} desktop={3} className='desktop:block hidden' />
      </Grid>
    </div>
  </>
);
