import * as React from 'react';
import { useState } from 'react';

import { useBeforeunload } from 'react-beforeunload';

import { Alert, Button, Text } from '@components/common';
import { WindowHeader, WindowLayout, WindowLayoutBody } from '@components/layouts/WindowLayout';
import { CloseIcon } from 'components/common/Modal';
import { track } from 'components/tracking';

import { useBulkUploadActions, useBulkUploadState } from '../context';

import { useBulkZoomImport } from './hooks/useBulkZoomImport';
import { useDrop } from './hooks/useDrop';
import { useRecordingPoller } from './hooks/useRecordingPoller';
import { useUploadFiles } from './hooks/useUploadFiles';
import { getCancelAllUploads } from './utils/getCancelAllUploads';
import { SaveYourChangesModal } from './SaveYourChangesModal';
import { UploadsTable } from './UploadsTable';

type Props = {
  disableHooks?: boolean;
};
export const UploadManager: React.FC<React.PropsWithChildren<Props>> = ({ disableHooks }) => {
  const { closeManager, openManager, setItem, openZoomImportModal } = useBulkUploadActions();
  const { managerItems: items, studyId } = useBulkUploadState();
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const { getRootProps, getInputProps, open } = useDrop();

  const allDone =
    items.length === 0 ||
    items.filter((i) => i.type !== 'zoom').every(({ status }) => ['done', 'errored', 'canceled'].includes(status));
  const cancelAllUploads = getCancelAllUploads(items, openManager);

  if (!disableHooks) {
    const recItems = items.filter((item) => item.type === 'recording') as RecordingItem[];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useUploadFiles(recItems, (r) => setItem(r));

    const zoomItems = items.filter((item) => item.type === 'zoom') as ZoomItem[];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useBulkZoomImport(zoomItems, (z) => setItem(z));

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRecordingPoller(
      items,
      (item, recording) => setItem({ id: item.id, session: { ...item.session, recording } }),
      (id) => setItem({ id, status: 'done' })
    );
  }

  const showWarningBanner = items.some(({ type, status }) => type === 'recording' && status === 'uploading');

  const showZoomOkToCloseBanner =
    !showWarningBanner && items.some(({ type, status }) => type === 'zoom' && status === 'uploading');

  useBeforeunload((event) => {
    if (showWarningBanner) {
      event.preventDefault();
    }
  });

  const handleClose = () => setCloseModalOpen(true);
  const handleCloseModalSubmit = () => {
    cancelAllUploads();
    closeManager();
  };

  React.useEffect(() => {
    track('opened_bulk_upload', { type: showWarningBanner ? 'recordings' : 'zoom_recordings' });
  }, []);

  React.useEffect(() => {
    if (allDone) {
      track('finished_bulk_upload', { amount: items.length });
    }
  }, [items, allDone]);

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className='fixed inset-0 z-50 bg-white'>
        <SaveYourChangesModal
          open={closeModalOpen}
          onClose={() => setCloseModalOpen(false)}
          onSubmit={handleCloseModalSubmit}
        />
        <WindowLayout>
          <WindowHeader>
            <div className='flex-1'>
              <Text h='700'>Upload manager</Text>
            </div>
            <div className='tablet:flex-row tablet:space-x-6 tablet:space-y-0 mr-6 flex flex-col items-center space-y-4'>
              <Button
                medium
                className='whitespace-nowrap'
                icon='uploadZoom'
                onClick={() => {
                  openZoomImportModal();
                }}
              >
                Import from Zoom
              </Button>
              <Button className='whitespace-nowrap' medium icon='uploadVideo' onClick={open}>
                Browse files
              </Button>
            </div>
            <button name='close' aria-label='Close' onClick={allDone ? closeManager : handleClose}>
              <CloseIcon className='h-5 w-5 text-indigo-600' />
            </button>
          </WindowHeader>
          <WindowLayoutBody className='flex-1 overflow-y-scroll'>
            <div className='px-page py-gutter h-full w-full'>
              {showWarningBanner && (
                <Alert className='mb-4' type='warning'>
                  Do not close the tab or window, there are currently uploads in progress.
                </Alert>
              )}
              {showZoomOkToCloseBanner && (
                <Alert className='mb-4' type='info'>
                  It is OK to close the tab or window. We will continue importing your Zoom recordings in the
                  background.
                </Alert>
              )}
              <UploadsTable items={items} studyId={studyId} />
            </div>
          </WindowLayoutBody>
          <div className='px-page z-20 flex w-full items-center border-0 border-t border-gray-200 bg-white py-4'>
            {allDone && (
              <Button primary onClick={closeManager}>
                Done
              </Button>
            )}
          </div>
        </WindowLayout>
      </div>
    </div>
  );
};
