import * as React from 'react';
import { useState } from 'react';

import { TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { makeProxyFilterSet } from '@components/shared/TableFilters/components/segments/utils';
import { FilterDefinition } from '@components/shared/TableFilters/types';
import { XSVG } from '@components/svgs';
import { blurOnEnter } from '@components/utils';

type Props = {
  limit: StudyLimit;
  removeLimit: () => void;
  idx: number;
  onChange: (limit: Partial<StudyLimit>) => void;
  definitions: FilterDefinition<Candidate>[];
  readOnly?: boolean;
};

export const StudyLimitField: React.FC<React.PropsWithChildren<Props>> = ({
  limit,
  onChange,
  definitions,
  removeLimit,
  idx,
  readOnly
}) => {
  const [value, setValue] = useState(limit.name);

  const placeholderTitle = `Segment #${idx + 1}`;

  const { filters } = limit;

  const filtersHook = useTableFilters<Candidate>({
    trackKey: 'studyLimits',
    initialState: filters,
    definitions,
    defaultFilters: [],
    syncWithView: false,
    onChange: ({ filters }) => onChange({ filters: makeProxyFilterSet('all', filters) })
  });

  return (
    <div className='w-full rounded-md border border-gray-200 p-4'>
      <div className='mb-2 flex'>
        <div className='flex-1'>
          <input
            name='name'
            aria-label='name'
            className='focus:outline-none h400 font-medium text-gray-700 placeholder-gray-700'
            placeholder={placeholderTitle}
            onChange={(e) => setValue(e.currentTarget.value)}
            onBlur={() => onChange({ name: value })}
            value={value || ''}
            readOnly={readOnly}
            {...blurOnEnter()}
          />
        </div>
        {!readOnly && (
          <button
            name='remove'
            aria-label='remove'
            onClick={removeLimit}
            className='flex cursor-pointer items-center rounded-full p-1 hover:bg-gray-50'
          >
            <XSVG className='h-4 w-4' />
          </button>
        )}
      </div>
      <div className='my-2'>
        <TableFilters<Candidate> hook={filtersHook} advancedLimits />
      </div>
    </div>
  );
};
