import * as React from 'react';

import { PopperDropdown, PopperDropdownButton } from '@components/common';
import { PencilSVG, SolidKebabSVG, UnmergeSVG } from '@components/svgs';

interface Props {
  onUnmerge: () => void;
  onEdit: () => void;
}

export const CategoriesOptionsDropdown: React.FC<React.PropsWithChildren<Props>> = ({ onUnmerge, onEdit }) => {
  return (
    <>
      <PopperDropdown
        text
        hideCaret
        name='more'
        buttonClassName='border-none rounded-full flex-shrink-0 pt-2 pr-2 pb-2 pl-2'
        renderIcon={() => <SolidKebabSVG className='h-4 w-4 flex-shrink-0' />}
      >
        <PopperDropdownButton onClick={onEdit}>
          <PencilSVG className='-mt-1 mr-1 inline' />
          Edit
        </PopperDropdownButton>
        <PopperDropdownButton onClick={onUnmerge}>
          <UnmergeSVG className='-mt-1 mr-1 inline' />
          Unmerge
        </PopperDropdownButton>
      </PopperDropdown>
    </>
  );
};
