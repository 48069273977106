import * as React from 'react';
import { useState } from 'react';

import { Dropdown, DropdownLink, Text } from '@components/common';
import { RadioInput } from '@components/common/RadioGroup';
import { PencilSVG } from '@components/svgs';
import { useAccount } from '@hooks/useAccount';
import { usePermission } from '@hooks/usePermission';

const PERMISSION_LABELS: Partial<Record<AccountRole, string>> = {
  admin: 'Admins only',
  creator: 'Admins or Creators'
};

export const PermissionsMenu: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    account: { permissions },
    update
  } = useAccount();

  const permission = permissions.manage_tags || 'admin';

  const isAdmin = usePermission('isAdmin')();
  const label = PERMISSION_LABELS[permission];

  function handleClick(value: AccountRole) {
    update({ permissions: { ...permissions, manage_tags: value } });
    setIsOpen(false);
  }

  return (
    <Dropdown
      onClick={() => setIsOpen(!isOpen)}
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
      disabled={!isAdmin}
      aria-label='Tag managament'
      position='left'
      dropdownWidth='72'
      buttonClassName='w-64 justify-between'
      text={
        <div className='flex flex-row items-center space-x-2'>
          <PencilSVG />
          <span>{label}</span>
        </div>
      }
    >
      <DropdownLink aria-label='creator' className='flex flex-row items-center' onClick={() => handleClick('creator')}>
        <div className='flex-1'>
          <Text h='400' bold>
            {PERMISSION_LABELS.creator}
          </Text>
          <Text h='400' color='gray-500'>
            Can add, edit and manage tags.
          </Text>
        </div>
        <RadioInput aria-label='Creator' checked={permission === 'creator'} />
      </DropdownLink>
      <DropdownLink className='flex flex-row items-center' onClick={() => handleClick('admin')}>
        <div className='flex-1'>
          <Text h='400' bold>
            {PERMISSION_LABELS.admin}
          </Text>
          <Text h='400' color='gray-500'>
            Can add, edit and manage tags.
          </Text>
        </div>
        <RadioInput aria-label='Admin' checked={permission === 'admin'} />
      </DropdownLink>
    </Dropdown>
  );
};
