import * as React from 'react';
import { useState } from 'react';

import { api } from '@api/reduxApi';
import { Tabs } from '@components/common';
import { ResponsesTab } from '@components/RepoSessionApp/components';
import { HighlightsTab } from '@components/RepoSessionApp/components/HighlightsTab';
import { TranscriptTab } from '@components/RepoSessionApp/components/TranscriptTab';
import { useTranscriptStatus } from '@components/RepoSessionApp/RecordingWidget/hooks/useTranscriptStatus';
import { Summary } from '@components/shared/Summary';
import { Tiptap, useTiptapFromDocumentId } from '@components/shared/Tiptap';
import { useGetRecordingQuery } from '@components/shared/Tiptap/api';
import { compact } from '@components/utils';

export const InterviewContent: React.FC<React.PropsWithChildren<{ session: RepoSession }>> = ({ session }) => {
  const [activeTab, setActiveTab] = useState('transcript');

  const { study, recording = null, document_id: documentId = null, uuid: sessionUuid } = session ?? {};

  const { data: recordingData } = useGetRecordingQuery({ id: recording?.id as number }, { skip: !recording?.id });
  const { data: highlights } = api.useGetHighlightsFromDocumentQuery(recording?.transcript?.document_id as number, {
    skip: !recording?.transcript?.document_id
  });

  const { message: transcriptStatus } = useTranscriptStatus(recording?.transcript);

  const tiptapWithTranscript = useTiptapFromDocumentId({
    documentId: recording?.transcript?.document_id as number,
    studyId: study?.id,
    sessionUuid,
    scrollToTop: false,
    readonly: true,
    slideoutView: true,
    config: {
      highlight: { enable: true, menuPlacement: 'left-start' }
    },
    withoutPlayer: true
  });

  const tiptapNotes = useTiptapFromDocumentId({
    documentId: documentId as number,
    studyId: study?.id,
    readonly: true,
    scrollToTop: false,
    config: {
      highlight: { enable: true, menuPlacement: 'bottom-start' },
      link: { enable: true },
      image: { enable: true },
      templates: true,
      artifacts: true,
      placeholder: 'No notes added.'
    }
  });

  const renderTabContent = () => {
    switch (activeTab) {
      case 'highlights':
        return <HighlightsTab readOnly highlights={highlights} recording={recordingData} />;
      case 'summary':
        return session ? (
          <div className='w-full px-4 py-3' data-testid='Summary'>
            <Summary session={session} readOnly />
          </div>
        ) : null;
      case 'transcript':
        return (
          <div className='w-full p-3' data-testid='Transcript'>
            <TranscriptTab
              readOnly
              transcriptStatus={transcriptStatus}
              tiptap={tiptapWithTranscript}
              sessionUuid={sessionUuid}
              studyId={study?.id}
              recording={recording}
            />
          </div>
        );
        break;
      case 'notes':
        return (
          <div className='w-full' data-testid='Notes'>
            <Tiptap {...tiptapNotes.getTiptapProps()}>
              <div className='px-6 py-4'>
                <tiptapNotes.Content {...tiptapNotes.getContentProps()} />
              </div>
            </Tiptap>
          </div>
        );
        break;
      case 'responses':
        return (
          <ResponsesTab
            className='w-full'
            screenerId={study?.survey_screener?.id}
            studyId={study?.id}
            participation={session?.participation}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Tabs
        current={activeTab}
        labels={{
          transcript: 'Transcript',
          highlights: 'Highlights',
          notes: 'Notes',
          summary: 'Summary',
          responses: 'Responses'
        }}
        onSelect={setActiveTab}
        className='flex items-center border-b border-gray-200 px-6'
        tabs={compact([
          'summary',
          'transcript',
          session?.kind === 'unmoderated_test' && 'responses',
          'highlights',
          'notes'
        ])}
      />
      {renderTabContent()}
    </>
  );
};
