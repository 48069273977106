import React, { FC, useState } from 'react';

import { UpdateModeratorModal } from '@components/StudiesApp/components/StudyPublished/components/UpdateModeratorModal';

interface Props {
  moderatorName?: string;
  participation: Participation;
  study?: Study;
  setBackgroundTask?: (task: BackgroundTask) => void;
}

export const UpdateModeratorCell: FC<Props> = ({ moderatorName, participation, study, setBackgroundTask }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className='flex h-11 w-full items-center px-2' onClick={() => setIsModalOpen(true)}>
        <span className='truncate'>{moderatorName ?? '-'}</span>
      </div>

      {study && moderatorName && isModalOpen && (
        <UpdateModeratorModal
          study={study}
          participations={[participation]}
          onClose={() => setIsModalOpen(false)}
          onSuccess={(task) => setBackgroundTask?.(task)}
        />
      )}
    </>
  );
};
