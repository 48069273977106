import React from 'react';

import cn from 'classnames';

import { Button, ButtonProps } from 'components/common/Button';

interface Props {
  buttonProps?: ButtonProps;
  enabled?: boolean;
  className?: string;
}

export const ButtonHoverOverlay: React.FC<React.PropsWithChildren<Props>> = ({
  enabled,
  buttonProps,
  className,
  children
}) => (
  <div className='group relative h-full w-full'>
    {enabled && (
      <div className={cn('absolute inset-0', className)}>
        <div className='absolute inset-0 hidden bg-black opacity-25 group-hover:block'></div>
        <div className='absolute inset-0 flex items-center justify-center'>
          <Button {...buttonProps} className='hidden group-hover:flex' />
        </div>
      </div>
    )}
    {children}
  </div>
);
