import React from 'react';

import cn from 'classnames';
import Tippy from '@tippyjs/react';

import { Text } from '@components/common';
import { ExternalLinkSVG, GLogoSVG, TremendousSVG } from '@components/svgs';

import { PROVIDERS } from './constants';

type RenderDestinationProps = {
  Icon: React.FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>;
  text: string;
  url?: string;
  className?: string;
};

const renderDestination = ({ Icon, text, url, className }: RenderDestinationProps) => {
  return (
    <div className={cn('flex flex-1 flex-nowrap px-4', className)}>
      {Icon && <Icon className='mr-2 h-4 w-4 text-gray-700' />}
      <Text as='div' h='400' className='flex-1 text-gray-700'>
        {text}
      </Text>

      {url && (
        <Tippy arrow={false} content='Open in new tab' placement='top-start'>
          <a href={url} target='_blank' className='flex items-center justify-center pl-3 text-gray-700'>
            <ExternalLinkSVG className='block h-4 w-4' />
          </a>
        </Tippy>
      )}
    </div>
  );
};

const getCallLinkInfo = (videoUrlType?: Study['video_url_type']) => {
  switch (videoUrlType) {
    case 'in_person':
    case 'manual':
      return 'Note: Each participant will receive the same link.';
    default:
      return 'Note: Unique URLs will be created for each scheduled participant.';
  }
};

const getInviteLinkTitle = (studyStyle) => {
  switch (studyStyle) {
    case 'video_call':
      return 'Scheduling page';
    case 'survey':
      return 'Survey page';
    case 'unmoderated_test':
    case 'online_task':
      return 'Test page';
    case 'panel':
      return 'Signup form page';
    default:
      return 'Participation page';
  }
};

interface Props {
  event: StudyMessageEvent;
  ctaUrl?: string;
  tremendous?: boolean;
  studyStyle?: Study['style'];
  screenerInviteCta?: Study['screener_invite_cta'];
  videoUrlType?: Study['video_url_type'];
  videoUrl?: string;
}

export interface CtaProps {
  renderDestination: () => JSX.Element;
  info?: string;
}

export const getCtaProps = ({
  event,
  ctaUrl,
  studyStyle,
  screenerInviteCta = 'form',
  tremendous,
  videoUrlType,
  videoUrl
}: Props): CtaProps => {
  switch (event) {
    case 'invite':
    case 'invited_reminder':
      return {
        renderDestination: () =>
          renderDestination({
            Icon: GLogoSVG,
            text: getInviteLinkTitle(studyStyle),
            url: ctaUrl as string,
            className: 'pb-3'
          }),
        info: 'Note: Emailed participants will receive a unique URL.'
      };
    case 'booked':
      return {
        renderDestination: () =>
          renderDestination({
            Icon: PROVIDERS[videoUrlType as string]?.icon || GLogoSVG,
            text: ['manual', 'in_person'].includes(videoUrlType as string)
              ? videoUrl
              : PROVIDERS[videoUrlType as string]?.name || 'Great Question',
            url: videoUrl as string,
            className: 'pb-3'
          }),
        info: getCallLinkInfo(videoUrlType)
      };
    case 'booked_reminder':
      return {
        renderDestination: () =>
          renderDestination({
            Icon: GLogoSVG,
            text: 'Confirmation page',
            className: 'pb-3'
          }),
        info: getCallLinkInfo(videoUrlType)
      };

    case 'reschedule_request':
      return {
        renderDestination: () =>
          renderDestination({
            Icon: GLogoSVG,
            text: getInviteLinkTitle(studyStyle),
            url: ctaUrl as string,
            className: 'pb-3'
          }),
        info: 'Note: Emailed participants will receive a unique URL.'
      };

    case 'thanks':
      return tremendous
        ? {
            renderDestination: () =>
              renderDestination({
                Icon: TremendousSVG,
                text: 'Tremendous redemption page',
                className: 'pb-3'
              }),
            info: 'Note: Unique URLs will be created for each redemption.'
          }
        : {
            renderDestination: () =>
              renderDestination({
                Icon: GLogoSVG,
                text: 'Redemption page'
              })
          };
    case 'screener':
      return {
        renderDestination: () =>
          renderDestination({
            Icon: GLogoSVG,
            text: screenerInviteCta === 'landing_page' ? 'Landing page' : 'Screener form',
            url: ctaUrl as string,
            className: 'pb-3'
          }),
        info: 'Note: Emailed participants will receive a unique URL.'
      };

    default:
      return {
        renderDestination: () =>
          renderDestination({
            Icon: GLogoSVG,
            text: 'Great Question'
          })
      };
  }
};
