import * as React from 'react';

export const GCAL: React.FC<React.PropsWithChildren<any>> = (props) => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' viewBox='186 38 76 76'>
    <path fill='#fff' d='M244 56h-40v40h40V56z' />
    <path fill='#EA4335' d='M244 114l18-18h-18v18z' />
    <path fill='#FBBC04' d='M262 56h-18v40h18V56z' />
    <path fill='#34A853' d='M244 96h-40v18h40V96z' />
    <path fill='#188038' d='M186 96v12c0 3.315 2.685 6 6 6h12V96h-18z' />
    <path fill='#1967D2' d='M262 56V44c0-3.315-2.685-6-6-6h-12v18h18z' />
    <path fill='#4285F4' d='M244 38h-52c-3.315 0 -6 2.685-6 6v52h18V56h40V38z' />
    <path
      fill='#4285F4'
      d='M212.205 87.03c-1.495-1.01-2.53-2.485-3.095-4.435l3.47-1.43c.315 1.2.865 2.13 1.65 2.79.78.66 1.73.985 2.84.985 1.135 0 2.11-.345 2.925-1.035s1.225-1.57 1.225-2.635c0-1.09-.43-1.98-1.29-2.67-.86-.69-1.94-1.035-3.23-1.035h-2.005V74.13h1.8c1.11 0 2.045-.3 2.805-.9.76-.6 1.14-1.42 1.14-2.465 0 -.93-.34-1.67-1.02-2.225-.68-.555-1.54-.835-2.585-.835-1.02 0 -1.83.27-2.43.815a4.784 4.784 0 0 0 -1.31 2.005l-3.435-1.43c.455-1.29 1.29-2.43 2.515-3.415 1.225-.985 2.79-1.48 4.69-1.48 1.405 0 2.67.27 3.79.815 1.12.545 2 1.3 2.635 2.26.635.965.95 2.045.95 3.245 0 1.225-.295 2.26-.885 3.11-.59.85-1.315 1.5-2.175 1.955v.205a6.605 6.605 0 0 1 2.79 2.175c.725.975 1.09 2.14 1.09 3.5 0 1.36-.345 2.575-1.035 3.64s-1.645 1.905-2.855 2.515c-1.215.61-2.58.92-4.095.92-1.755.005-3.375-.5-4.87-1.51zM233.52 69.81l-3.81 2.755-1.905-2.89 6.835-4.93h2.62V88h-3.74V69.81z'
    />
  </svg>
);

export const Calendly: React.FC<React.PropsWithChildren<any>> = (props) => (
  <svg {...props} height='2500' width='2342' viewBox='7.4 0 344.6 360' xmlns='http://www.w3.org/2000/svg'>
    <g fill='#676b74'>
      <path d='m313.8 360h-268.3c-21 0-38.1-17.1-38.1-38.1v-268.4c0-21 17.1-38.1 38.1-38.1h268.3c21 0 38.1 17.1 38.1 38.1v268.3c.1 21.1-17 38.2-38.1 38.2zm-268.3-323.5c-9.4 0-17 7.6-17 17v268.3c0 9.4 7.6 17 17 17h268.3c9.4 0 17-7.6 17-17v-268.3c0-9.4-7.6-17-17-17z' />
      <path d='m256.6 72.4c-4.5 0-8.1-3.6-8.1-8.1v-56.2c0-4.5 3.6-8.1 8.1-8.1s8.1 3.6 8.1 8.1v56.1c0 4.5-3.6 8.2-8.1 8.2zm-154.7 0c-4.5 0-8.1-3.6-8.1-8.1v-56.2c0-4.5 3.6-8.1 8.1-8.1s8.1 3.6 8.1 8.1v56.1c.1 4.5-3.6 8.2-8.1 8.2zm87.5 181.4c-33.6 0-60.9-27.3-60.9-60.9s27.3-60.9 60.9-60.9c15.2 0 29.7 5.6 40.9 15.8 1.4 1.2 1.5 3.4.2 4.7-1.2 1.4-3.4 1.5-4.7.2-10-9.1-22.9-14.1-36.4-14.1-29.9 0-54.2 24.3-54.2 54.2s24.3 54.2 54.2 54.2c13.5 0 26.4-5 36.4-14.1 1.4-1.2 3.5-1.1 4.7.2 1.2 1.4 1.1 3.5-.2 4.7-11.2 10.4-25.7 16-40.9 16z' />
    </g>
  </svg>
);
