import * as React from 'react';
import { useState } from 'react';

import { sendStudyMessage } from '@api/queries';
import { Loading } from '@components/common';
import { Toast, useToaster } from '@components/stores/toaster';
import { useFeature } from '@hooks/useFeature';
import { useCancelMutation } from 'components/StudiesApp/components/StudyPublished/api';

import { StudyMessageSlideOut } from './StudyMessageSlideOut';

interface Props {
  study: Study;
  participations: Participation[];
  onSuccess: (backgroundTask: BackgroundTask) => void;
  onClose: () => void;
  allSelected?: boolean;
  query?: ServerFilterQuery;
}

const toasts: { [key: string]: Toast } = {
  sent: {
    heading: 'Cancel emails sent!',
    text: `All participants were canceled and emailed.`,
    icon: 'success'
  },
  canceled: {
    heading: `Successfully canceled!`,
    text: 'All participants were marked as canceled.',
    icon: 'success'
  }
};

export const CancelSlideOut: React.FC<React.PropsWithChildren<Props>> = ({
  query,
  allSelected,
  study,
  participations,
  onSuccess,
  onClose
}) => {
  const serversideParticipantsEnabled = useFeature('serverside_participations');

  const showToast = useToaster();
  const [loading, setLoading] = useState<boolean>(false);
  const [cancel] = useCancelMutation();

  const [send, setSend] = useState(true);

  const parties = participations.filter(({ status }) => ['booked', 'started', 'completed'].includes(status));

  const selectedParticipationsIds = parties.map(({ id }) => id);
  const totalCount = serversideParticipantsEnabled && allSelected ? query?.count || 0 : parties.length;

  async function handleSend({ message, send, sender }) {
    setLoading(true);

    const backgroundTask = await cancel({
      studyId: study.id,
      ...(allSelected && serversideParticipantsEnabled ? { query } : { ids: selectedParticipationsIds })
    }).unwrap();

    if (backgroundTask) {
      onSuccess(backgroundTask);

      if (send) {
        await sendStudyMessage(
          { id: message.id, studyId: study.id },

          {
            ...(allSelected && serversideParticipantsEnabled
              ? { query }
              : { participation_ids: selectedParticipationsIds }),
            sender
          }
        );
        showToast(toasts.sent);
      } else {
        showToast(toasts.canceled);
      }
    }

    setLoading(false);
  }

  return (
    <StudyMessageSlideOut
      optional={{ send, setSend }}
      customizable
      title='Mark as canceled'
      subtitle={
        parties?.find((p) => p.interview_at && new Date(p.interview_at) < new Date())
          ? 'Note: Use "Mark as no-showed" for participants that did not attend'
          : undefined
      }
      cta='Confirm & Send'
      study={study}
      event='cancel_interview'
      previewCandidate={parties[0]?.customer}
      previewParticipation={parties[0]}
      totalCount={totalCount}
      inviteableCount={totalCount}
      contactableCount={totalCount}
      onClose={onClose}
      onSend={handleSend}
    >
      <>{loading && <Loading absolute />}</>
    </StudyMessageSlideOut>
  );
};
