import * as React from 'react';
import { useRef, useState } from 'react';

import cn from 'classnames';
import type { FC } from 'react';

import { CloseSVG, PlayerMute, PlayerPause, PlayerPlay, PlayerSpeaker, StepBack, StepForward } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { RangeBar } from '@components/VideoPlayer/components/RangeBar';

import { ControlsProps } from '../types';
import { formatSeconds } from '../utils';

type Props = Pick<
  ControlsProps,
  | 'isPlaying'
  | 'currentTime'
  | 'volume'
  | 'className'
  | 'play'
  | 'pause'
  | 'setVolume'
  | 'rewind'
  | 'fastForward'
  | 'duration'
  | 'buffer'
  | 'handleOnProgressBarChange'
> & { onClose: () => void };

export const ControlsLite: FC<React.PropsWithChildren<Props>> = ({
  isPlaying,
  currentTime,
  volume,
  className,
  play,
  pause,
  setVolume,
  rewind,
  fastForward,
  onClose,
  buffer,
  duration,
  handleOnProgressBarChange
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <section
      aria-label='Video controls'
      ref={wrapperRef}
      className={cn(
        'bg-gray-600',
        'absolute',
        'inset-0',
        'flex',
        'flex-col',
        'text-white',
        'bg-opacity-50',
        'opacity-0',
        'hover:opacity-100',
        className
      )}
    >
      <div className='flex items-center justify-between p-4'>
        <Tooltip content='Close'>
          <button type='button' aria-label='Close' className='focus:outline-none' onClick={onClose}>
            <CloseSVG className='h-5 w-5' />
          </button>
        </Tooltip>
        <span className='flex-shrink-0 select-none text-sm leading-none'>{formatSeconds(currentTime ?? 0)}</span>
      </div>
      <div className='mb-2 mt-auto px-4'>
        <RangeBar
          value={currentTime}
          max={duration}
          onChange={handleOnProgressBarChange}
          className='w-full'
          buffer={buffer}
          barClassName='bg-indigo-600'
          hideThumb
        />
      </div>
      <div className='flex w-full items-end justify-center space-x-4 pb-4'>
        {isPlaying ? (
          <Tooltip content='Pause'>
            <button type='button' aria-label='Pause' className='focus:outline-none' onClick={pause}>
              <PlayerPause className='h-6 w-6' />
            </button>
          </Tooltip>
        ) : (
          <Tooltip content='Play'>
            <button type='button' aria-label='Play' className='focus:outline-none' onClick={play}>
              <PlayerPlay className='h-6 w-6' />
            </button>
          </Tooltip>
        )}
        {volume === 0 ? (
          <Tooltip content='Unmute'>
            <button type='button' className='focus:outline-none' onClick={() => setVolume(1)} aria-label='Unmute'>
              <PlayerMute className='h-6 w-6' />
            </button>
          </Tooltip>
        ) : (
          <Tooltip content='Mute'>
            <button type='button' className='focus:outline-none' onClick={() => setVolume(0)} aria-label='Mute'>
              <PlayerSpeaker className='h-6 w-6' />
            </button>
          </Tooltip>
        )}
        <Tooltip content='Step back 5 seconds'>
          <button
            type='button'
            className='focus:outline-none'
            aria-label='Step back 5 seconds'
            onClick={() => rewind(5)}
          >
            <StepBack className='h-6 w-6' />
          </button>
        </Tooltip>
        <Tooltip content='Step forward 5 seconds'>
          <button
            type='button'
            className='focus:outline-none'
            aria-label='Step forward 5 seconds'
            onClick={() => fastForward(5)}
          >
            <StepForward className='h-6 w-6' />
          </button>
        </Tooltip>
      </div>
    </section>
  );
};
