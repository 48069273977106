import * as React from 'react';

import { api } from '@api/reduxApi';
import { Text } from '@components/common';
import { Basic } from '@components/shared/Skeleton';

import { CalendarEventLink } from '../components/CalendarEventLink';
import { FieldRow } from '../components/FieldRow';

import type { BookingDetailsFormFieldComponentProps } from './types';

export type Props = BookingDetailsFormFieldComponentProps & {
  participationId: number;
  onClose: () => void;
};

export const CalendarEvents: React.FC<Props> = ({
  participationId: id,
  type = 'candidate_event',
  editMode,
  bookingDetail,
  onClose
}) => {
  const event = bookingDetail[type];
  const { data: calendarEvents, isLoading } = api.useGetParticipationCalendarEventsQuery({ id });

  if (!event || editMode) {
    return null;
  }

  const filteredCalendarEvents = calendarEvents?.filter(
    ({ event_type }) => event_type === (type === 'candidate_event' ? 'candidate' : 'team')
  );

  return (
    <FieldRow label='Calendar events'>
      {isLoading && <Basic />}
      {!isLoading && filteredCalendarEvents && filteredCalendarEvents.length < 1 && (
        <Text className='py-2.5' h='400' color='gray-500'>
          No events.
        </Text>
      )}
      {filteredCalendarEvents && filteredCalendarEvents.length > 0 && (
        <div className='flex flex-col space-y-3 overflow-auto py-2.5'>
          {filteredCalendarEvents.map((calendarEvent) => (
            <CalendarEventLink key={calendarEvent.id} calendarEvent={calendarEvent} />
          ))}
        </div>
      )}
    </FieldRow>
  );
};
