import { useMemo, useState } from 'react';

import { api } from '@api/reduxApi';
import { useAccount } from '@hooks/useAccount';
import { compact } from 'components/utils';

interface State {
  loading: boolean;
  error?: boolean;
}

interface Params {
  studyId: number;
  ownerId: number;
  isRoundRobin: boolean;
}
interface Hook {
  loading: boolean;
  team: GQAccount['team'];
  allStudyUsers: { teamUser: TeamUser; studyUser: StudyUser }[] | null;
  fetchStudyUsers: () => void;
}

export const useStudyUsers = (params: Params): Hook => {
  const {
    data: studyUsers,
    refetch: refetchStudyUsers,
    isLoading: loading
  } = api.useGetStudyUsersQuery(params.studyId);

  const {
    account: { team }
  } = useAccount();

  const getAllStudyUsers = (studyUsers: StudyUser[]) => {
    const mapped = studyUsers.map((studyUser) => {
      const teamUser = team.find((u) => u.id === studyUser.user_id);

      if (!teamUser) return false;

      return { teamUser, studyUser };
    });

    return (compact(mapped) || []) as Hook['allStudyUsers'];
  };

  const allStudyUsers = useMemo(() => {
    return studyUsers ? getAllStudyUsers(studyUsers) : null;
  }, [studyUsers, team]);

  return {
    loading,
    fetchStudyUsers: refetchStudyUsers as () => void,
    team: params.isRoundRobin ? team : team.filter((u) => u.id !== params.ownerId),
    allStudyUsers
  };
};

export { Hook as UseStudyUsersHook, Params as UseStudyUsersParams };
