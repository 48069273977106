import React from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { TextProps } from '@components/common/Text';
import { ChevronLeftSVG, InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

interface PanelWrapperProps {
  onClose: () => void;
  title: string;
  className?: string;
}

export const PanelWrapper: React.FC<React.PropsWithChildren<PanelWrapperProps>> = ({
  className,
  onClose,
  title,
  children
}) => (
  <section className='flex w-full flex-col'>
    <button
      onClick={onClose}
      aria-label={`Close ${title} panel`}
      className='xx-close-panel focus:outline-none desktop:px-6 mt-px flex w-full cursor-pointer items-center border-b border-gray-200 px-4 py-3.5 hover:bg-gray-50 hover:text-indigo-600 focus:ring'
    >
      <ChevronLeftSVG className='h-4 w-4' />
      <Text className='ml-4 flex-grow' bold>
        {title}
      </Text>
    </button>
    <div className='desktop:p-6 flex-grow p-4'>{children}</div>
  </section>
);

export const FormGroup = ({ children, className = '' }) => <div className={cn('mb-6', className)}>{children}</div>;
export const Label: React.FC<
  React.PropsWithChildren<{
    tooltip?: string;
    h?: TextProps['h'];
    noMargin?: boolean;
  }>
> = ({ h = '400', noMargin = false, children, tooltip }) => (
  <label className={cn('flex items-center space-x-2', { 'mb-2': !noMargin })}>
    <Text h={h} bold>
      {children}
    </Text>
    {tooltip && (
      <Tooltip content={tooltip}>
        <InfoCircleIcon className='h-4 w-4' />
      </Tooltip>
    )}
  </label>
);
