import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Loading } from '@components/common';

import { Invite } from './Invite';

export const EditInvite: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { token } = useParams<{ token: string }>();

  // @ts-expect-error skip without token
  const { data: invitation, isLoading } = api.useGetInvitationQuery(token, { skip: !token });
  const [updateInvitation] = api.useUpdateInvitationMutation();

  if (isLoading) {
    return <Loading />;
  }

  if (!invitation) {
    return null;
  }

  return (
    <Invite
      type='Invitation'
      method='update'
      record={invitation}
      onUpdate={(params) => updateInvitation({ token: invitation.token, invitation: params }).unwrap()}
    />
  );
};
