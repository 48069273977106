import React from 'react';

import cn from 'classnames';

import { ErrorMessage, Text } from '@components/common';
import { getInlineErrorMessage } from '@components/shared/BookabilityAlerts/utils';
import { CalendarWithClockSVG, ChevronRightSVG, CogSVG } from '@components/svgs';
import { AdditionalOptionsSVG } from '@components/svgs/AdditionalOptions';
import { AttendeesSVG } from '@components/svgs/Attendees';
import { capitalize, humanize } from '@components/utils';

export type PanelType = 'event_details' | 'attendees' | 'availability' | 'additional_options';

interface Props {
  onClick: (v: PanelType) => void;
  type: PanelType;
  className?: string;
  inlineErrors?: {
    bookability?: Bookability;
    rules: BookabilityRule[];
  };
}

const BUTTON_ICONS = {
  event_details: <CogSVG className='h-6 w-6 flex-shrink-0' />,
  attendees: <AttendeesSVG className='flex-shrink-0' />,
  availability: <CalendarWithClockSVG className='h-6 w-6 flex-shrink-0' />,
  additional_options: <AdditionalOptionsSVG className='flex-shrink-0' />
};

export const hasInlineError = (key: BookabilityRule, bookability?: Bookability) => !!bookability?.[key]?.[0];

export const renderInlineError = (key: BookabilityRule, bookability: Bookability = {}) => {
  const level = bookability[key]?.[0] || null;
  const extra = bookability[key]?.[1] || {};
  const message = getInlineErrorMessage(key, extra);

  if (!level || !message) {
    return null;
  }

  return <ErrorMessage icon={level}>{message}</ErrorMessage>;
};

export const PanelButton: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  onClick,
  type,
  children,
  inlineErrors
}) => {
  return (
    <button
      aria-label={type}
      className={cn(
        className,
        'focus:outline-none z-10 flex w-full cursor-pointer border-b border-gray-200 p-6 text-left transition duration-150 ease-in-out hover:bg-gray-50 hover:text-indigo-600 focus:ring'
      )}
      onClick={() => onClick(type)}
    >
      {BUTTON_ICONS[type]}
      <div className='mx-3 flex-grow'>
        <div className='mb-3 flex items-center justify-between'>
          <Text bold>{humanize(capitalize(type))}</Text>

          <ChevronRightSVG className='h-4 w-4 flex-shrink-0' />
        </div>
        <div className='flex flex-col space-y-2'>
          {inlineErrors && inlineErrors.rules.map((rule) => renderInlineError(rule, inlineErrors.bookability))}
          {children}
        </div>
      </div>
    </button>
  );
};
