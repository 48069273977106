import * as React from 'react';
import { useContext } from 'react';

import { StateRouterContext } from './context';

type Props = {
  context: React.Context<StateRouterContext>;
};

export const Switch: React.FC<React.PropsWithChildren<Props>> = ({ context, children }) => {
  const { currentPath } = useContext(context);

  let renderable: React.ReactNode = null;

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      const routePath: string = child.props.path;
      const params: Record<string, string | number> = {};

      if (routePath.split('/').length === currentPath.split('/').length) {
        const matches = routePath.split('/').every((segment, index) => {
          if (segment.startsWith(':')) {
            const paramName = segment.slice(1);
            const value = currentPath.split('/')[index];
            params[paramName] = /^\d+$/.test(value) ? parseInt(value, 10) : value;
            return true;
          }
          return segment === currentPath.split('/')[index];
        });

        if (matches) {
          renderable = typeof child.props.element === 'function' ? child.props.element(params) : child.props.element;
        }
      }
    }
  });

  return renderable;
};
