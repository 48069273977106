import React, { useEffect, useState } from 'react';

import { Popper, Text } from '@components/common';
import { Label } from '@components/fields';
import { InfoCircleIcon } from '@components/svgs';

import { ChatPrompt } from '../ChatPrompt';
import { Error } from '../Error';
import { Loading } from '../Loading';

import { useGetSurveyQuestions } from './hooks/useGetSurveyQuestions';
import { shouldCreate, toScreenerField } from './helpers';

export interface Props {
  studyGoal?: string;
  limit?: number;
  fields: ScreenerField[];
  onSave?: (fields: ScreenerField) => void;
  onLoadingChange?: (isLoading: boolean) => void;
}

export const Survey = ({ limit, fields, studyGoal, onSave, onLoadingChange }: Props) => {
  const [prompt, setPrompt] = useState<string>('');

  const { fetch, questions, isLoading, isError, stop, clear } = useGetSurveyQuestions({
    research_goal: prompt,
    limit
  });

  const onAccept = () => {
    const suggestion = questions[questions.length - 1];

    if (suggestion && shouldCreate(fields, suggestion.result)) {
      onSave?.(toScreenerField(suggestion.result, fields.length + 1));
    }
  };

  useEffect(() => {
    return () => stop();
  }, []);

  useEffect(onAccept, [questions]);

  useEffect(() => {
    if (prompt) {
      clear();
    }
  }, [prompt]);

  useEffect(() => {
    onLoadingChange?.(isLoading);
  }, [isLoading]);

  return (
    <div className='mb-5'>
      {isLoading ? (
        <Loading className='h-12 w-full' stop={stop}>
          AI is generating...
        </Loading>
      ) : (
        <>
          <div className='mb-2 flex items-center space-x-2'>
            <Label labelFor='survey-ai-prompt' className='cursor-pointer text-base'>
              AI Prompt
            </Label>
            <Popper
              closeOnClickOutside
              placement='right-end'
              offset={[0, 8]}
              content={() => (
                <div className='w-48 rounded-xl bg-gray-700 p-2 text-white'>
                  <Text h='200'>
                    Enter the type of survey questions you want AI to draft for you. You can edit, add or remove
                    questions at any time.
                  </Text>
                </div>
              )}
            >
              <div className='cursor-pointer' aria-label='More info'>
                <InfoCircleIcon className='h-4 w-4' />
              </div>
            </Popper>
          </div>
          <ChatPrompt
            id='survey-ai-prompt'
            value={prompt}
            onChange={(value) => setPrompt(value)}
            onSubmit={fetch}
            isInvalid={isError}
            placeholder={studyGoal ?? 'Enter prompt e.g. understand why do customers churn'}
            fillWithPlaceholder
          />
          {isError && <Error className='mt-4'>The bot is busy, please try again later</Error>}
        </>
      )}
    </div>
  );
};
