import * as React from 'react';
import { forwardRef } from 'react';

import { AskAiSVG, SearchSVG } from '@components/svgs';
import { compact } from '@components/utils';

const ICONS = {
  search: SearchSVG,
  ai: AskAiSVG
};

export interface Props extends Omit<React.HTMLProps<HTMLInputElement>, 'size' | 'onChange' | 'ref'> {
  id?: string;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  type?: string;
  name?: string;
  ariaLabel?: string;
  autoFocus?: boolean;
  placeholder?: string;
  value?: string;
  textSize?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
  size?: string;
  icon?: keyof typeof ICONS | React.FC<React.PropsWithChildren<unknown>>;
  iconClassName?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  checked?: boolean;
  style?: React.CSSProperties;
  required?: boolean;
  wrapperClass?: string;
  tabIndex?: number;
  dataTestId?: string;
}
export const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    id,
    value,
    autoFocus,
    disabled,
    ariaLabel,
    placeholder,
    error,
    onBlur,
    onFocus,
    onKeyDown,
    onChange,
    icon,
    iconClassName,
    size,
    name,
    type = 'text',
    textSize = 'sm',
    checked,
    style,
    required,
    tabIndex,
    wrapperClass,
    className: propsClassName,
    dataTestId,
    ...rest
  } = props;

  const className = compact([
    'block border-gray-200 rounded-md placeholder-gray-400',
    `tablet:text-${textSize}`,
    icon && 'pl-10',
    error ? 'focus:ring-red-600 focus:border-red-600' : 'focus:ring-indigo-500 focus:border-indigo-500',
    size && `w-full max-w-${size}`,
    error && 'border-red-600',
    disabled && 'border-gray-200 text-gray-500 bg-gray-50',
    propsClassName
  ]).join(' ');

  const inputEl = (
    <>
      <input
        id={id}
        ref={ref}
        type={type}
        name={name}
        style={style}
        aria-label={ariaLabel}
        className={className}
        autoFocus={autoFocus}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange?.(e.currentTarget.value)}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        checked={checked}
        required={required}
        tabIndex={tabIndex}
        aria-invalid={error}
        data-testid={dataTestId}
        {...rest}
      />
    </>
  );

  if (!icon) return inputEl;

  const Icon = typeof icon === 'string' ? ICONS[icon] : icon;
  return (
    <span className={`relative flex items-center ${wrapperClass}${size ? `w-full max-w-${size}` : ''}`}>
      {inputEl}
      <Icon className={`absolute left-4 ${iconClassName || ''}`} />
    </span>
  );
});

export { Props as InputProps };
