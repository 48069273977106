import * as React from 'react';
import { useEffect, useState } from 'react';

import { createWalletDeposit } from '@api/queries';
import { Button, Input, Loading, Modal, Text } from '@components/common';
import { Toast, useToaster } from '@components/stores/toaster';
import { moneyFormat } from '@components/utils';
import { PaymentMethodSelect } from '@components/Wallet';
import { useAccount } from '@hooks/useAccount';

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}
export const errorToast: Toast = {
  heading: 'Failed to charge card!',
  icon: 'error',
  text: 'Something went wrong while trying to process your deposit. Please try again later.'
};
export const successToast: Toast = {
  heading: 'Payment successfully processed!',
  icon: 'success',
  text: 'Your deposit to your account was successful.'
};

// TODO: explicit add permissions here.
export const WalletDepositModal: React.FC<React.PropsWithChildren<Props>> = ({ onCancel, onSuccess }) => {
  const {
    refresh: refreshAccount,
    account: { card_type, card_last4, wallet, cards }
  } = useAccount();
  const [loading, setLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(cards.find(({ is_default }) => is_default) as any);
  const showToast = useToaster();

  async function handleSubmit() {
    setLoading(true);
    const [err, resp] = await createWalletDeposit({
      amount_in_cents: amount * 100,
      processor_id: paymentMethod.processor_id
    });
    if (err) {
      showToast(errorToast);
    } else {
      showToast(successToast);
      await refreshAccount();
      onSuccess();
    }
    setLoading(false);
  }
  const balanceAfter = wallet.available + amount * 100;

  const paymentMethodSelected = (selected: PaymentMethod) => {
    setPaymentMethod(selected);
  };

  return (
    <Modal
      size='md'
      title='Deposit fundings'
      // subtitle='You can withdraw any unused funds anytime.'
      onClose={onCancel}
      renderFooter={() => (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={handleSubmit} primary disabled={amount <= 0}>
            Fund ${moneyFormat(amount)}
          </Button>
        </>
      )}
    >
      {loading && <Loading absolute />}
      <div className='space-y-4 py-6'>
        <div className='flex flex-row items-center justify-between'>
          <Text bold>Current balance</Text>
          <Text>${moneyFormat(wallet.available / 100)}</Text>
        </div>
        <div className='flex flex-row items-center justify-between'>
          <Text bold>Amount to deposit</Text>
          <Input
            type='number'
            name='amount'
            value={amount === 0 ? '' : amount.toString()}
            onChange={(v) => {
              const amount = parseInt(v);
              setAmount(amount >= 0 ? amount : 0);
            }}
            ariaLabel='Amount to deposit'
            min={0}
          />
        </div>
        <div className='flex flex-row items-center justify-between'>
          <div>
            <Text bold>Balance after deposit</Text>
          </div>
          <Text aria-label='Amount in dollars'>${moneyFormat(balanceAfter / 100)}</Text>
        </div>
        <div className='flex flex-row items-center justify-between'>
          <Text bold>Card</Text>
          <PaymentMethodSelect selected={paymentMethod} methods={cards} onSelect={paymentMethodSelected} />
        </div>
      </div>
    </Modal>
  );
};
