import pluralize from 'pluralize';
import tinytime from 'tinytime';

import { duration } from 'components/utils';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

export type AlertProps = Record<ConflictAlert, { title: string; subtitle: string; cta?: string }>;

type GetAlertProps = (args: {
  dailyLimit: number;
  weeklyLimit: number;
  durationInMinutes: number;
  participantsLimit: number;
  minimumSchedulingNotice: number;
  maximumSchedulingNotice: number;
  callBuffer: number;
  startDate: Date;
  endDate: Date | null;
}) => AlertProps;

export const getAlertProps: GetAlertProps = ({
  dailyLimit,
  weeklyLimit,
  durationInMinutes,
  participantsLimit,
  minimumSchedulingNotice,
  maximumSchedulingNotice,
  callBuffer,
  startDate,
  endDate
}) => ({
  minimum_scheduling_notice: {
    title: 'Minimum scheduling notice active',
    subtitle: `This slot is unavailable because it falls within the minimum scheduling notice (${duration(
      minimumSchedulingNotice
    )}).`,
    cta: 'Edit notice'
  },
  buffer: {
    title: 'Event buffer active',
    subtitle: `This slot is unavailable because there is an event starting or ending within the event buffer (${pluralize(
      'minute',
      callBuffer,
      true
    )}).`,
    cta: 'Edit buffer'
  },
  calendar: {
    title: 'Moderator has a conflict',
    subtitle:
      "A moderator is busy at this time. To fix this, you'll need to reschedule or otherwise remove the conflicting event."
  },
  long_duration: {
    title: 'Duration too long',
    subtitle: `The slot’s duration (${pluralize(
      'minute',
      durationInMinutes,
      true
    )}) is too long for the available time today.`,
    cta: 'Edit duration'
  },
  participants_limit: {
    title: 'Total participant limit reached',
    subtitle: `There have already been ${pluralize('participant', participantsLimit, true)} that have booked.`,
    cta: 'Edit limit'
  },
  weekly_limit: {
    title: 'Weekly booking limit reached',
    subtitle: `There have already been ${pluralize(
      'participant',
      weeklyLimit,
      true
    )} that have booked interviews within this week.`,
    cta: 'Edit limit'
  },
  daily_limit: {
    title: 'Daily booking limit reached',
    subtitle: `There have already been ${pluralize(
      'participant',
      dailyLimit,
      true
    )} that have booked interviews on this date.`,
    cta: 'Edit limit'
  },
  outside_scheduling_dates: {
    title: 'Outside of scheduling dates',
    subtitle: `This slot is outside of the ${dateTemplate.render(startDate)} to ${
      endDate ? dateTemplate.render(endDate) : 'the date of cancellation'
    } study scheduling date range.`,
    cta: 'Edit dates'
  },
  outside_scheduling_hours: {
    title: 'Outside of scheduling hours',
    subtitle: 'This slot is outside of the study’s available scheduling hours.'
  },
  maximum_scheduling_notice: {
    title: 'Maximum scheduling notice active',
    subtitle: `This slot is unavailable because it falls outside of the maximum scheduling notice (${pluralize(
      'day',
      maximumSchedulingNotice,
      true
    )}).`,
    cta: 'Edit notice'
  }
});
