import type { Node as ProseMirrorNode } from 'prosemirror-model';
import { NodeSelection, TextSelection } from 'prosemirror-state';
import type { CommandProps } from '@tiptap/core';
import { mergeAttributes, Node } from '@tiptap/core';

import { Column } from './Column';
import type { Predicate } from './utils';
import { buildColumnBlock, buildNColumns, findParentNodeClosestToPos } from './utils';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    columnBlock: {
      setColumns: (columns: number) => ReturnType;
      insertColumns: (columns: number) => ReturnType;
      unsetColumns: () => ReturnType;
    };
  }
}

export interface ColumnBlockOptions {
  nestedColumns: boolean;
  columnType: Node;
}

export const ColumnBlock = Node.create<ColumnBlockOptions>({
  name: 'columnBlock',
  group: 'block',
  content: 'column{2,}',
  isolating: true,
  selectable: true,

  addOptions() {
    return {
      nestedColumns: false,
      columnType: Column
    };
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = mergeAttributes(HTMLAttributes, { class: 'column-block' });
    return ['div', attrs, 0];
  },

  addCommands() {
    const unsetColumns =
      () =>
      ({ tr, dispatch }: CommandProps) => {
        try {
          if (!dispatch) {
            return;
          }

          // find the first ancestor
          const pos = tr.selection.$from;
          const where: Predicate = ({ node }) => {
            if (!this.options.nestedColumns && node.type == this.type) {
              return true;
            }
            return node.type == this.type;
          };
          const firstAncestor = findParentNodeClosestToPos(pos, where);
          if (firstAncestor === undefined) {
            return;
          }

          // find the content inside of all the columns
          let nodes: Array<ProseMirrorNode> = [];
          firstAncestor.node.descendants((node, _, parent) => {
            if (parent?.type.name === Column.name) {
              nodes.push(node);
            }
          });
          nodes = nodes.reverse().filter((node) => node.content.size > 0);

          // resolve the position of the first ancestor
          const resolvedPos = tr.doc.resolve(firstAncestor.pos);
          const sel = new NodeSelection(resolvedPos);

          // insert the content inside of all the columns and remove the column layout
          tr = tr.setSelection(sel);
          nodes.forEach((node) => (tr = tr.insert(firstAncestor.pos, node)));
          tr = tr.deleteSelection();
          return dispatch(tr);
        } catch (error) {
          console.error(error);
        }
      };

    const setColumns =
      (n: number) =>
      ({ tr, dispatch }: CommandProps) => {
        const { doc, selection } = tr;
        if (!dispatch) {
          console.log('no dispatch');
          return true;
        }

        const columns = buildNColumns(n);
        const columnBlock = buildColumnBlock({ content: columns });

        const newNode = doc.type.schema.nodeFromJSON(columnBlock);
        if (newNode === null) {
          return true;
        }

        const offset = tr.selection.anchor + 1;

        tr.replaceSelectionWith(newNode)
          .scrollIntoView()
          .setSelection(TextSelection.near(tr.doc.resolve(offset)));
        return true;
      };

    return {
      unsetColumns,
      setColumns
    };
  }
});
