import * as React from 'react';

import cn from 'classnames';

import { Avatar, Popper, Text } from '@components/common';

const MAX_MODERATORS = 5;

export const ModeratorsCell: React.FC<React.PropsWithChildren<{ moderators?: TeamUser[] }>> = ({ moderators }) => {
  if (!moderators?.length) {
    return <span className='px-2'>-</span>;
  }

  if (moderators.length === 1) {
    return (
      <div className='flex flex-nowrap items-center truncate'>
        {moderators[0] && <Avatar className='truncate px-2' noTippy user={moderators[0]} />}
        {moderators[0].name && (
          <Text h='400' className='truncate'>
            {moderators[0].name}
          </Text>
        )}
      </div>
    );
  }

  return (
    <Popper
      event='hover'
      placement='bottom-end'
      offset={[0, 8]}
      content={() => (
        <div className='rounded-md bg-white p-4 shadow-lg'>
          <Text className='mb-4 uppercase' h='200' color='gray-500'>
            Moderators
          </Text>
          <ul className='flex flex-col space-y-4'>
            {moderators?.map((user) => (
              <li key={user.id} className='flex items-center'>
                <Avatar noTippy user={user} size='xl' />
                <div className='ml-4 flex flex-1 flex-col'>
                  <p className='h400 font-medium text-gray-700'>{user.name}</p>
                  <p className='h200 text-gray-500'>{user.job_title}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    >
      <div className='flex cursor-pointer flex-nowrap items-center overflow-hidden px-2'>
        {moderators
          ?.slice(0, MAX_MODERATORS)
          ?.map((user, i) => <Avatar noTippy key={user?.id} user={user} className={cn({ ['-ml-0.5']: i > 0 })} />)}
        {moderators.length > MAX_MODERATORS && (
          <Avatar noTippy user={{ initials: `+${moderators.length - MAX_MODERATORS}` }} className='-ml-2' />
        )}
      </div>
    </Popper>
  );
};
