import { FC, ReactElement } from 'react';

import { api } from '@api/reduxApi';
import { buildRecord, buildWhoami } from 'api/builders';

const whoamiApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWhoami: builder.query<Whoami, void>({
      query: () => '/whoami',
      transformResponse: ({ data }: { data: Whoami }) => buildWhoami(data) as Whoami,
      providesTags: ['Whoami']
    }),
    updateAccount: builder.mutation<
      GQAccount,
      Partial<Pick<GQAccount, 'name' | 'workspace' | 'permissions' | 'ai_enabled'>>
    >({
      query: (account) => ({
        url: '/account',
        method: 'PUT',
        body: { account }
      }),
      transformResponse: ({ data }: { data: GQAccount }) => buildRecord(data),
      invalidatesTags: () => ['Whoami']
    }),
    updateAccountSetting: builder.mutation<void, { key: string; value: string }>({
      query: ({ key, value }) => ({
        url: `/account/settings/${key}`,
        method: 'PUT',
        body: { value }
      }),
      invalidatesTags: () => ['Whoami']
    })
  })
});

export const { useGetWhoamiQuery, useUpdateAccountMutation, useUpdateAccountSettingMutation } = whoamiApi;

export const WhoamiContainer: FC<PropsWithChildrenFn<object, { whoami: Whoami; isLoading: boolean }>> = ({
  children
}) => {
  const { data, isLoading } = useGetWhoamiQuery();
  return typeof children === 'function' ? children({ whoami: data as Whoami, isLoading }) : children;
};
