import React, { forwardRef, HTMLAttributes, useEffect, useState } from 'react';

import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import { Spinner } from '@components/common';
import { Player } from '@lib/canvas-player/Player';
import { secondsToTimestamp } from 'components/utils';

interface Props extends HTMLAttributes<HTMLDivElement> {
  player: Player;
  timestamp: number;
}

export const Thumbnail = forwardRef<HTMLDivElement, Props>(({ player, timestamp, className, ...rest }, ref) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [img, setImg] = useState<string>();
  const seconds = Math.floor(timestamp / 1000);

  const { callback } = useDebouncedCallback(async () => {
    const i = await player.getFrame(timestamp);
    setImg(i);
    setIsLoading(false);
  }, 100);

  useEffect(() => {
    setIsLoading(true);
    callback();
  }, [player, seconds]);

  return (
    <div ref={ref} className={cn('text-center', className)} {...rest}>
      {isLoading ? (
        <div className='flex h-20 w-full items-center justify-center'>
          <Spinner className='h-4 w-4' />
        </div>
      ) : (
        <img
          alt='Thumbnail preview of the video clip'
          src={img}
          className='h-20 w-full rounded border border-gray-500'
        />
      )}

      <span className='mt-1 text-xs'>{secondsToTimestamp(seconds, 4)}</span>
    </div>
  );
});
