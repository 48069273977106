import React from 'react';

import { Checkbox, Text } from '@components/common';

export const Checkboxes: React.FC<React.PropsWithChildren<AnswerInputProps<string[]>>> = ({
  options,
  value,
  onChange
}) => {
  const _ref = value || [];
  function toggleValue(option: string) {
    const newValue = _ref.includes(option) ? value.filter((v) => v !== option) : [..._ref, option];
    onChange(newValue);
  }

  return (
    <div className='flex flex-col space-y-1'>
      {(options || []).map((option, i) => (
        <label key={option} className='flex items-center space-x-2'>
          <Checkbox onChange={() => toggleValue(option)} value={option} selected={_ref.includes(option) || false} />
          <Text>{option || <i>none</i>}</Text>
        </label>
      ))}
    </div>
  );
};
