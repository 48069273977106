import React from 'react';

export const NoResultsSVG: React.FC<React.PropsWithChildren<any>> = (props) => (
  <svg {...props} width='192' height='133' viewBox='0 0 192 133' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M36.4251 28.5521C42.4813 28.5521 47.3907 23.6532 47.3907 17.61C47.3907 11.5669 42.4813 6.66797 36.4251 6.66797C30.369 6.66797 25.4595 11.5669 25.4595 17.61C25.4595 23.6532 30.369 28.5521 36.4251 28.5521Z'
      fill='#FF6584'
    />
    <path
      d='M89.777 58.6634C89.777 84.4818 74.3953 93.4966 55.4211 93.4966C36.4468 93.4966 21.0652 84.4818 21.0652 58.6634C21.0652 32.845 55.4211 0 55.4211 0C55.4211 0 89.777 32.845 89.777 58.6634Z'
      fill='#E5E7EB'
    />
    <path
      d='M54.1695 89.5472L54.5215 67.9396L69.1647 41.2078L54.5766 64.5499L54.7348 54.8342L64.8269 35.4942L54.7766 52.2632L55.061 34.7891L65.868 19.3919L55.1059 32.0413L55.2836 0L54.1664 42.4169L54.2583 40.667L43.2709 23.8851L54.0821 44.0261L53.0584 63.5416L53.0279 63.0237L40.3612 45.3632L52.9895 64.8536L52.8615 67.2942L52.8386 67.3309L52.849 67.5313L50.2517 117.044H53.722L54.1383 91.4696L66.7355 72.027L54.1695 89.5472Z'
      fill='#12102F'
    />
    <path
      d='M192 69.2375C192 90.3711 179.409 97.7502 163.878 97.7502C148.347 97.7502 135.756 90.3711 135.756 69.2375C135.756 48.104 163.878 21.2188 163.878 21.2188C163.878 21.2188 192 48.104 192 69.2375Z'
      fill='#E5E7EB'
    />
    <path
      d='M162.854 94.5174L163.142 76.8306L175.128 54.9493L163.187 74.0559L163.316 66.1032L171.577 50.2723L163.351 63.9986L163.583 49.6953L172.429 37.0919L163.62 47.4462L163.766 21.2188L162.851 55.9389L162.926 54.5067L153.932 40.7698L162.782 57.2562L161.944 73.2306L161.919 72.8066L151.551 58.3506L161.888 74.3045L161.783 76.3023L161.764 76.3324L161.773 76.4963L159.647 117.025H162.487L162.828 96.091L173.139 80.1763L162.854 94.5174Z'
      fill='#12102F'
    />
    <path
      d='M95.0227 121.135C147.502 121.135 190.045 118.989 190.045 116.342C190.045 113.695 147.502 111.549 95.0227 111.549C42.5431 111.549 0 113.695 0 116.342C0 118.989 42.5431 121.135 95.0227 121.135Z'
      fill='#12102F'
    />
    <path
      d='M152.91 125.081C161.898 125.081 169.185 124.714 169.185 124.26C169.185 123.807 161.898 123.439 152.91 123.439C143.921 123.439 136.635 123.807 136.635 124.26C136.635 124.714 143.921 125.081 152.91 125.081Z'
      fill='#12102F'
    />
    <path
      d='M94.0165 133C103.005 133 110.292 132.633 110.292 132.179C110.292 131.726 103.005 131.358 94.0165 131.358C85.028 131.358 77.7413 131.726 77.7413 132.179C77.7413 132.633 85.028 133 94.0165 133Z'
      fill='#12102F'
    />
    <path
      d='M49.3358 127.415C69.8713 127.415 86.5186 126.575 86.5186 125.54C86.5186 124.504 69.8713 123.664 49.3358 123.664C28.8003 123.664 12.153 124.504 12.153 125.54C12.153 126.575 28.8003 127.415 49.3358 127.415Z'
      fill='#12102F'
    />
    <path
      d='M16.6341 110.322C24.1097 110.322 30.1698 104.275 30.1698 96.8152C30.1698 89.3557 24.1097 83.3086 16.6341 83.3086C9.15856 83.3086 3.09842 89.3557 3.09842 96.8152C3.09842 104.275 9.15856 110.322 16.6341 110.322Z'
      fill='#12102F'
    />
    <path d='M14.453 107.39L10.3412 107.342L10.2544 114.693L14.3662 114.741L14.453 107.39Z' fill='#12102F' />
    <path d='M22.6767 107.487L18.5649 107.438L18.4781 114.79L22.5899 114.838L22.6767 107.487Z' fill='#12102F' />
    <path
      d='M17.1065 114.859C17.1148 114.151 15.5875 113.559 13.6951 113.536C11.8027 113.514 10.2618 114.07 10.2534 114.778C10.2451 115.486 11.7724 116.079 13.6648 116.101C15.5572 116.123 17.0981 115.567 17.1065 114.859Z'
      fill='#12102F'
    />
    <path
      d='M25.3321 114.785C25.3405 114.077 23.8132 113.485 21.9208 113.462C20.0283 113.44 18.4875 113.996 18.4791 114.704C18.4707 115.412 19.9981 116.004 21.8905 116.027C23.7829 116.049 25.3238 115.493 25.3321 114.785Z'
      fill='#12102F'
    />
    <path
      d='M17.0171 98.0165C19.5721 98.0165 21.6433 95.9498 21.6433 93.4004C21.6433 90.8509 19.5721 88.7842 17.0171 88.7842C14.4622 88.7842 12.391 90.8509 12.391 93.4004C12.391 95.9498 14.4622 98.0165 17.0171 98.0165Z'
      fill='white'
    />
    <path
      d='M17.0172 94.9388C17.8688 94.9388 18.5592 94.2499 18.5592 93.4001C18.5592 92.5502 17.8688 91.8613 17.0172 91.8613C16.1655 91.8613 15.4751 92.5502 15.4751 93.4001C15.4751 94.2499 16.1655 94.9388 17.0172 94.9388Z'
      fill='#12102F'
    />
    <path
      d='M3.65328 84.1006C2.61808 79.2039 6.16982 74.31 11.5863 73.1699C17.0028 72.0297 22.2329 75.075 23.2681 79.9717C24.3033 84.8684 20.7018 86.6275 15.2853 87.7677C9.8688 88.9079 4.68848 88.9973 3.65328 84.1006Z'
      fill='#5850EC'
    />
    <path
      d='M37.2818 104.35C37.2818 113.767 31.6722 117.054 24.7519 117.054C24.5915 117.054 24.4315 117.053 24.2717 117.049C23.951 117.042 23.6334 117.027 23.3197 117.006C17.0742 116.565 12.2219 113.108 12.2219 104.35C12.2219 95.2875 23.8281 83.8517 24.6999 83.0053L24.7015 83.0038C24.7351 82.9711 24.7519 82.9551 24.7519 82.9551C24.7519 82.9551 37.2818 94.934 37.2818 104.35Z'
      fill='#5850EC'
    />
    <path
      d='M24.2954 115.614L28.8781 109.224L24.2839 116.315L24.2717 117.049C23.9511 117.042 23.6335 117.027 23.3197 117.006L23.8136 107.585L23.8098 107.512L23.8182 107.498L23.8648 106.608L19.2591 99.4993L23.8793 105.941L23.89 106.13L24.2633 99.0118L20.3203 91.666L24.3114 97.7625L24.7 83.0053L24.7015 82.9551V83.0038L24.6366 94.6407L28.5621 90.0274L24.6206 95.6432L24.5167 102.016L28.1819 95.8999L24.5015 102.954L24.4435 106.497L29.7645 97.9841L24.4236 107.733L24.2954 115.614Z'
      fill='#12102F'
    />
    <path
      d='M122.076 110.175C129.551 110.175 135.611 104.128 135.611 96.6687C135.611 89.2092 129.551 83.1621 122.076 83.1621C114.6 83.1621 108.54 89.2092 108.54 96.6687C108.54 104.128 114.6 110.175 122.076 110.175Z'
      fill='#12102F'
    />
    <path d='M123.231 107.404L119.3 108.609L121.465 115.637L125.395 114.431L123.231 107.404Z' fill='#12102F' />
    <path d='M131.092 104.994L127.161 106.199L129.326 113.227L133.256 112.021L131.092 104.994Z' fill='#12102F' />
    <path
      d='M125.143 115.939C126.952 115.384 128.249 114.386 128.041 113.709C127.833 113.032 126.197 112.933 124.388 113.488C122.579 114.042 121.281 115.041 121.49 115.718C121.698 116.395 123.334 116.494 125.143 115.939Z'
      fill='#12102F'
    />
    <path
      d='M132.954 113.366C134.763 112.811 136.061 111.813 135.852 111.136C135.644 110.459 134.008 110.36 132.199 110.914C130.39 111.469 129.092 112.468 129.301 113.145C129.509 113.822 131.145 113.921 132.954 113.366Z'
      fill='#12102F'
    />
    <path
      d='M121.397 97.9159C123.952 97.9159 126.023 95.8492 126.023 93.2998C126.023 90.7503 123.952 88.6836 121.397 88.6836C118.842 88.6836 116.771 90.7503 116.771 93.2998C116.771 95.8492 118.842 97.9159 121.397 97.9159Z'
      fill='white'
    />
    <path
      d='M121.397 94.8382C122.248 94.8382 122.939 94.1493 122.939 93.2995C122.939 92.4497 122.248 91.7607 121.397 91.7607C120.545 91.7607 119.855 92.4497 119.855 93.2995C119.855 94.1493 120.545 94.8382 121.397 94.8382Z'
      fill='#12102F'
    />
    <path
      d='M105.827 88.5116C103.345 84.1633 105.231 78.4217 110.041 75.6873C114.851 72.953 120.763 74.2613 123.245 78.6096C125.728 82.9579 122.836 85.7294 118.026 88.4638C113.216 91.1981 108.31 92.8599 105.827 88.5116Z'
      fill='#5850EC'
    />
    <path
      d='M97.9355 102.625C97.9355 111.269 92.7861 114.287 86.4335 114.287C86.2864 114.287 86.1394 114.285 85.9928 114.282C85.6984 114.276 85.4069 114.262 85.1189 114.242C79.3858 113.837 74.9316 110.664 74.9316 102.625C74.9316 94.3059 85.5856 83.8084 86.3859 83.0315L86.3873 83.0301C86.4181 83 86.4336 82.9854 86.4336 82.9854C86.4336 82.9854 97.9355 93.9814 97.9355 102.625Z'
      fill='#5850EC'
    />
    <path
      d='M86.0145 112.965L90.2213 107.099L86.004 113.609L85.9927 114.282C85.6984 114.276 85.4069 114.262 85.1189 114.242L85.5723 105.594L85.5688 105.527L85.5765 105.515L85.6192 104.698L81.3914 98.1722L85.6325 104.085L85.6424 104.259L85.985 97.7247L82.3655 90.9816L86.0292 96.5778L86.3859 83.0315L86.3873 82.9854V83.0301L86.3277 93.7122L89.9311 89.4774L86.313 94.6325L86.2177 100.483L89.5821 94.8681L86.2037 101.343L86.1504 104.596L91.0349 96.7813L86.1322 105.731L86.0145 112.965Z'
      fill='#12102F'
    />
    <path
      d='M143.169 106.231C143.169 115.647 137.559 118.935 130.639 118.935C130.478 118.935 130.318 118.933 130.159 118.93C129.838 118.923 129.52 118.908 129.207 118.887C122.961 118.445 118.109 114.989 118.109 106.231C118.109 97.1683 129.715 85.7325 130.587 84.8862L130.588 84.8847C130.622 84.8519 130.639 84.8359 130.639 84.8359C130.639 84.8359 143.169 96.8148 143.169 106.231Z'
      fill='#5850EC'
    />
    <path
      d='M130.182 117.494L134.765 111.104L130.171 118.195L130.159 118.929C129.838 118.922 129.52 118.907 129.207 118.886L129.7 109.465L129.697 109.392L129.705 109.378L129.752 108.488L125.146 101.379L129.766 107.821L129.777 108.01L130.15 100.892L126.207 93.5458L130.198 99.6423L130.587 84.8852L130.588 84.835V84.8837L130.523 96.5206L134.449 91.9073L130.507 97.5231L130.404 103.896L134.069 97.7798L130.388 104.834L130.33 108.377L135.651 99.864L130.31 109.613L130.182 117.494Z'
      fill='#12102F'
    />
    <path
      d='M122.935 104.754C123.438 103.696 121.108 101.543 117.731 99.9447C114.354 98.3464 111.208 97.9084 110.706 98.9663C110.203 100.024 112.533 102.178 115.91 103.776C119.287 105.374 122.433 105.812 122.935 104.754Z'
      fill='#12102F'
    />
    <path
      d='M18.7577 103.518C19.1942 102.431 16.7356 100.425 13.2661 99.0377C9.79663 97.6501 6.63017 97.4062 6.19361 98.4931C5.75705 99.5799 8.21571 101.586 11.6852 102.973C15.1546 104.361 18.3211 104.605 18.7577 103.518Z'
      fill='#12102F'
    />
  </svg>
);
