import React from 'react';

import pluralize from 'pluralize';

import { Button, Modal, ModalHeading, ModalSubheading } from 'components/common';
import { capitalize } from 'components/utils';

const getSubheading = (type: 'cancel' | 'reschedule') => {
  switch (type) {
    case 'cancel':
      return 'Removing the session will result in cancellation.';
    case 'reschedule':
      return 'Participants will be informed.';
  }
};

interface Props {
  type: 'cancel' | 'reschedule';
  participantsNumber: number;
  onClose: () => void;
  onConfirm: () => void;
}

export const UpdateSessionModal: React.FC<React.PropsWithChildren<Props>> = ({
  type,
  participantsNumber,
  onClose,
  onConfirm
}) => {
  return (
    <Modal size='md' onClose={onClose}>
      <ModalHeading className='mb-1'>{capitalize(type)} session</ModalHeading>
      <ModalSubheading className='mb-6'>{getSubheading(type)}</ModalSubheading>
      <p className='text-sm'>
        You have <span className='font-bold'>{participantsNumber}</span> {pluralize('participant', participantsNumber)}{' '}
        scheduled for this session.
      </p>
      <p className='text-sm'>Are you sure you want to {type}?</p>
      <div className='mt-6 flex flex-wrap items-center justify-end space-x-4'></div>
      <div className='flex justify-end space-x-4'>
        <Button onClick={onClose}>Don’t {type}</Button>
        <Button onClick={onConfirm} primary>
          Continue to {type}
        </Button>
      </div>
    </Modal>
  );
};
