import React from 'react';

import { Button, Modal, ModalHeading, Text } from '@components/common';

export const NoVideoHighlightsModal: React.FC<React.PropsWithChildren<{ onClose: () => void }>> = ({ onClose }) => {
  return (
    <Modal
      icon='danger'
      onClose={onClose}
      renderFooter={() => (
        <Button onClick={onClose} primary>
          Got it
        </Button>
      )}
    >
      <ModalHeading className='mb-4'>No video highlights selected</ModalHeading>
      <section className='mb-6 mt-2'>
        <Text h='400'>You need to select video highlights in order to create a highlight reel.</Text>
      </section>
    </Modal>
  );
};
