import React, { VFC } from 'react';

import Tippy from '@tippyjs/react';

import { VideoCameraSVG } from '@components/svgs';

interface Props {
  participationName: string;
  sessionUuid?: string | null;
}

export const ParticipationCell: VFC<Props> = ({ participationName, sessionUuid }) => {
  return (
    <div className='flex w-full items-center justify-between space-x-2 truncate px-2'>
      <div className='truncate'>{participationName}</div>
      {sessionUuid && (
        <Tippy
          aria={{ content: 'labelledby', expanded: 'auto' }}
          appendTo={() => document.body}
          interactive
          arrow={false}
          content='View full session room.'
        >
          <a href={`/sessions/${sessionUuid}`} target='_blank'>
            <VideoCameraSVG className='h-4 w-4' />
          </a>
        </Tippy>
      )}
    </div>
  );
};
