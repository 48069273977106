import { useMemo } from 'react';

import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { usePermission } from '@hooks/usePermission';
import { api } from 'api/reduxApi';

interface Props {
  attr?: Attr_;
  query?: string;
  attrName: string;
}

interface Hook {
  suggestions?: { label: string; value: string }[];
  isFetching: boolean;
  isCustomAttr: boolean;
  attr: any;
  canEdit: boolean;
}

const PII_KEYS = ['name', 'email'];

export const isPii = (attr: Attr_): boolean => Boolean(PII_KEYS.includes(attr.name) || attr.is_pii);

export const useEditCandidateAttr = ({ attr: initialAttr, query, attrName }: Props): Hook => {
  const { allAttrs } = useCandidateAttrs();
  const attr = initialAttr || allAttrs.find((attr) => attr.name === attrName.slice(6));
  const isCustomAttr = attrName.startsWith('extra.');
  const canUpdate = usePermission<Candidate>('updateCandidate')();
  const canShowPii = usePermission('showPII')();
  const canEdit: boolean =
    canUpdate && (attr ? !attr.write_locked : true) && (attr ? !isPii(attr) || canShowPii : true);

  const { data, isFetching } = api.useGetCandidateAttrsSuggestionsQuery(
    { id: attr?.id, q: query || '' },
    { skip: !attr || !query, refetchOnMountOrArgChange: true }
  );

  const suggestions = useMemo(() => data?.map((v) => ({ label: v.toString(), value: v })), [data, query]);

  return { suggestions, isFetching, isCustomAttr, attr, canEdit };
};
