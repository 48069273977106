import { useEffect, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { api } from '@api/reduxApi';
import { useToaster } from '@stores/toaster';

type UseRecruitingRequest = (params: {
  study: Study;
  requestId?: number;
  skip?: boolean;
  handleUpdatedRequest?: (v: ExternalCandidatesRequest) => void;
}) => {
  request: ExternalCandidatesRequest | undefined;
  refetchRequest: () => void;
  handleNew: () => void;
  handleChange: (payload: ExternalCandidatesRequest) => void;
  requestInitialized: boolean;
  isUpdating: boolean;
  isLoading: boolean;
};

export const useRecruitingRequest: UseRecruitingRequest = ({ study, requestId, handleUpdatedRequest, skip }) => {
  const [requestInitialized, setRequestInitialized] = useState<boolean>(false);
  const [request, setRequest] = useState<ExternalCandidatesRequest>();

  const showToast = useToaster();

  const { data: externalCandidatesRequests, isLoading: requestsLoading } = api.useGetExternalCandidatesRequestsQuery(
    study.id,
    { skip: skip || !!requestId }
  );

  const {
    data: externalCandidatesRequest,
    isLoading: requestLoading,
    refetch: refetchRequest
  } = api.useGetExternalCandidatesRequestQuery(
    {
      studyId: study.id,
      externalCandidatesRequestId: requestId as number
    },
    { skip: skip || !requestId }
  );

  const [
    createExternalCandidatesRequest,
    { data: createdRequest, isLoading: createLoading, isSuccess: createSuccess, isError: createError }
  ] = api.useCreateExternalCandidatesRequestMutation();

  const [
    updateExternalCandidatesRequest,
    { data: updatedRequest, isLoading: updateLoading, isSuccess: updateSuccess, isError: updateError }
  ] = api.useUpdateExternalCandidatesRequestMutation();

  useEffect(() => {
    if (externalCandidatesRequests) {
      setRequest(externalCandidatesRequests[0]);
      setRequestInitialized(true);
    }
  }, [externalCandidatesRequests]);

  useEffect(() => {
    if (externalCandidatesRequest) {
      setRequest(externalCandidatesRequest);
      setRequestInitialized(true);
    }
  }, [externalCandidatesRequest]);

  useEffect(() => {
    if (createSuccess) {
      setRequest(createdRequest);
      setRequestInitialized(true);
      handleUpdatedRequest?.(createdRequest as ExternalCandidatesRequest);
    }
  }, [createSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      setRequest(updatedRequest);
      setRequestInitialized(true);
      handleUpdatedRequest?.(updatedRequest as ExternalCandidatesRequest);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createError || updateError) {
      showToast({
        heading: 'Error submitting request.',
        text: 'Please try again.',
        icon: 'error'
      });
    }
  }, [createError, updateError]);

  const handleNew = () => {
    setRequestInitialized(false);
    createExternalCandidatesRequest({
      project_id: study.id,
      state: 'draft',
      platform_name: 'Respondent',
      attrs: {
        market_type: 'b2b',
        public_title: '',
        public_description: '',
        maximum_candidates: Math.min(study.maximum_slots, 150)
      }
    });
  };

  const handleChange = (payload: ExternalCandidatesRequest) => {
    if (payload.id) {
      updateExternalCandidatesRequest(payload);
    } else {
      createExternalCandidatesRequest(payload);
    }
  };

  return {
    request,
    refetchRequest,
    handleNew,
    handleChange,
    requestInitialized,
    isLoading: requestsLoading || requestLoading,
    isUpdating: createLoading || updateLoading
  };
};
