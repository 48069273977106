import * as React from 'react';

import { Button, Heading } from '@components/common';
import { StudyEmail } from '@components/StudiesApp/components/StudyDraft/pages/Assets/StudyEmail';
import { HEADINGS, SUBHEADINGS } from '@components/StudiesApp/components/StudyPublished/pages/Emails/constants';
import { StepHelper } from '@components/StudiesApp/components/StudyPublished/pages/shared';
import { useMessageEditor } from '@components/StudyMessages';
import { humanize } from '@components/utils';
import { usePermission } from '@hooks/usePermission';

export interface Props extends PageProps {
  email: StudyMessageEvent;
  addWrapper?: boolean;
}

const getHeading = (email: StudyMessageEvent): string => HEADINGS[email] || humanize(email);

export const EmailPreview: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { email, study, addWrapper } = props;
  const hook = useMessageEditor({
    hideSender: false,
    study,
    event: email,
    context: 'editor'
  });

  const { setEdit, PreviewButton, editable } = hook;
  const canUpdate = usePermission<Study>('updateStudy')(study);

  const startEdit = () => setEdit(true);

  const previewBody = (
    <>
      <div className='flex justify-between'>
        <div>
          <Heading as='h2' h='500' className='mb-2 font-bold'>
            {getHeading(email)}
          </Heading>
          <StepHelper>{SUBHEADINGS[email]}</StepHelper>
        </div>
        <div className='flex flex-row items-start space-x-6'>
          {canUpdate && <PreviewButton />}

          {editable && (
            <Button onClick={startEdit} disabled={!canUpdate} icon='pencil'>
              Edit
            </Button>
          )}
        </div>
      </div>
      <StudyEmail {...props} hook={hook} key={`${email}-email`} />
    </>
  );
  return addWrapper ? (
    <div className='relative mx-auto max-w-4xl flex-1 rounded rounded-t-none border border-gray-200 bg-white p-6'>
      {previewBody}
    </div>
  ) : (
    previewBody
  );
};
