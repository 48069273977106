import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { api } from '@api/reduxApi';

import { AiChatPage } from '../types';

import { getContextObjFromUrl, isValidScope, scopeLookup } from './context';

type Result = {
  page: AiChatPage;
  context: AiChatContext;
};

export const useGetAssumedContext = (): Result | null => {
  const { pathname } = useLocation();
  const { data: scopes } = api.useGetAiScopesQuery();

  return useMemo(() => {
    const context = getContextObjFromUrl(pathname);
    if (!context) {
      return null;
    }

    const validScope = scopeLookup(context, scopes);

    return validScope ? { page: context.context_type as AiChatPage, context: validScope } : null;
  }, [scopes, pathname]);
};

export const useIsValidAiChatContext = (context: AiChatContext | null): boolean => {
  const { data: scopes } = api.useGetAiScopesQuery();

  return useMemo(() => {
    if (!context) {
      return false;
    }
    return isValidScope(context, scopes);
  }, [scopes]);
};
