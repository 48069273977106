import * as React from 'react';

import { Route, Routes } from 'react-router-dom';

import { IncentivesIndex } from './IncentivesIndex';

export const IncentivesApp: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Routes>
      <Route path='*' element={<IncentivesIndex />} />
    </Routes>
  );
};
