import React from 'react';

import { Link } from 'react-router-dom';

export const ContextInfo: React.FC<React.PropsWithChildren<{ context: AiChat['context'] }>> = ({ context }) => {
  switch (context?.context_type) {
    case 'study':
      return (
        <Link to={`/studies/${context.id}`} className='text-blue-600'>
          {context.context_name || `Study ${context.id}`}
        </Link>
      );
    case 'interview':
      return (
        <Link to={`/sessions/${context.id}`} className='text-blue-600'>
          {context.context_name || `Interview ${context.id}`}
        </Link>
      );
    default:
      return null;
  }
};
