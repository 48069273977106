import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { getUserNylasEvents } from '@api/queries';
import {
  SET_EVENTS,
  SET_FAILED,
  store
} from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/store/context';
import { getCalendarState } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/store/getters';
import { compact } from '@components/utils';

import { splitMultiDayEvent } from './NylasCalendar/utils';

type Params = {
  calendars: UserAndCalendar[];
  start_date: string;
  end_date: string;
  timezone: string;
  weekIdx?: number;
};

type Result = {
  initialFetchDone: boolean;
};

export const useFetchEvents = ({
  calendars: initialCalendars,
  start_date,
  end_date,
  timezone,
  weekIdx = -1
}: Params): Result => {
  const { state, dispatch } = useContext(store);
  const fetchedCalendars = useRef<string[]>([]);
  const fetchedWeeks = useRef<number[]>([]);

  const [initialFetchDone, setInitialFetchDone] = useState(false);

  const calendars = useMemo(() => initialCalendars.filter(({ calendarId }) => calendarId), [initialCalendars]);

  useEffect(() => {
    fetchedCalendars.current = [];
  }, [start_date, end_date, timezone]);

  useEffect(() => {
    fetchedWeeks.current = [];
  }, [calendars, timezone, start_date, end_date]);

  useEffect(() => {
    const current: UserAndCalendar[] = [];

    const requests = compact(
      calendars.map((calendar, i) => {
        const { userId, calendarId } = calendar;

        if (!fetchedCalendars.current.includes(calendarId)) {
          fetchedCalendars.current.push(calendarId);
          current.push(calendar);
          return getUserNylasEvents({
            id: userId,
            calendar_id: calendarId,
            start_date,
            end_date,
            timezone
          });
        }
      })
    );

    if (initialFetchDone && requests.length > 0) {
      setInitialFetchDone(false);
    } else if (calendars.length > 0 && requests.length === 0) {
      setInitialFetchDone(true);
    }

    Promise.allSettled(requests)
      .then((calEvents) => {
        calEvents.forEach((e, i) => {
          if (e.status === 'fulfilled' && e.value) {
            const processedEvents = e.value.events.map((e) => splitMultiDayEvent(e, timezone)).flat();

            dispatch({
              type: SET_EVENTS,
              key: { userId: e.value.user_id || 0, calendarId: e.value.calendar_id || '', weekIdx },
              events: processedEvents
            });
          }

          if (calendars.indexOf(current[i]) === calendars.length - 1) {
            fetchedWeeks.current.push(weekIdx);
          }

          setInitialFetchDone(true);
        });
      })
      .catch(() => setInitialFetchDone(true));
  }, [state, calendars, start_date, end_date, timezone]);

  return { initialFetchDone };
};
