import * as React from 'react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Button, RadioGroup, Spinner } from '@components/common';
import { useRecruitingRequest } from '@components/StudiesApp/components/StudyDraft/hooks/useRecruitingRequest';
import { REQUEST_STATE_OPTIONS } from '@components/StudiesApp/components/StudyPublished/pages/ExternalCandidatesRequest/constants';
import { EmptyBubbleSVG, FailedBubbleSVG, GreenCheckBubbleSVG, PencilSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

type Props = PublishStepItem & { noBorder: boolean; study: Study };

const GreenCheckRowItem: React.FC<React.PropsWithChildren<Props>> = ({
  study,
  id,
  noBorder,
  heading,
  subheading,
  href,
  ctaText,
  state
}) => {
  const { request, handleChange } = useRecruitingRequest({
    study: study,
    skip: id !== 'recruiting'
  });

  const [requestState, setRequestState] = useState<ExternalCandidatesState>(request?.state as ExternalCandidatesState);

  const requestStateDisabled: boolean = !!request?.can_be_funded_using_credits;
  const requestStateDisabledMessage: string =
    'When recruitment fee credits are available, requests should be submitted after study creation';

  useEffect(() => {
    if (request) setRequestState(request.state);
  }, [request]);

  const onChange = (state: ExternalCandidatesState) => {
    if (!request) return;
    setRequestState(state);
    handleChange({ ...request, state });
  };

  return (
    <div
      className={classNames(`xx-publish-item flex items-center space-x-6 py-6 xx-publish-item-${state}`, {
        'border-b border-gray-200': !noBorder
      })}
    >
      {state === 'completed' && <GreenCheckBubbleSVG className='mt-1' />}
      {state === 'failed' && <FailedBubbleSVG className='mt-1' />}
      {state === 'loading' && <Spinner className='h-4 w-4' />}
      {state === null && <EmptyBubbleSVG className='mt-1 text-gray-400' />}

      <div className='flex-1'>
        <span className='h500-bold block'>{heading}</span>
        {typeof subheading === 'string' ? <span className='h500 block'>{subheading}</span> : subheading}
        {id === 'recruiting' && request && (
          <Tooltip isDisabled={!requestStateDisabled} content={requestStateDisabledMessage}>
            <div className='mt-4'>
              <RadioGroup<ExternalCandidatesState>
                options={REQUEST_STATE_OPTIONS}
                border={false}
                selectedColor='none'
                name='requestState'
                disabled={requestStateDisabled}
                onChange={onChange}
                selected={requestState || (request.state as ExternalCandidatesState)}
              />
            </div>
          </Tooltip>
        )}
      </div>

      {(state === 'completed' || !ctaText) && (
        <Link
          to={href}
          className='flex h-10 w-10 items-center justify-center rounded-full border border-gray-200 bg-white text-gray-900 hover:border-indigo-600 hover:text-indigo-600 focus:ring-2 focus:ring-indigo-500'
        >
          <PencilSVG />
        </Link>
      )}
      {state !== 'completed' && ctaText && (
        <Link to={href}>
          <Button>{ctaText}</Button>
        </Link>
      )}
    </div>
  );
};
export { GreenCheckRowItem, Props as GreenCheckRowItemProps };
