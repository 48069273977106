import React, { forwardRef } from 'react';

import cn from 'classnames';

import { NO_OUTLINE } from '@components/CandidateAttrs';
import { Text } from '@components/common';
import { DropdownCombobox, DropdownItem } from '@components/common/DropdownCombobox';
import { usePermission } from 'hooks/usePermission';

interface Props {
  onSelect: (item: DropdownItem) => void;
  options: DropdownItem[];
}

export const AttrSelect = forwardRef<HTMLDivElement, Props>(({ onSelect, options }, ref) => {
  const canManage = usePermission('manageAttrs')();
  const DropdownItem = (item, isHighlighted) => {
    return item._isNew ? (
      <Text h='400'>
        Enter or{' '}
        <span className={cn('font-semibold', { 'text-white': isHighlighted, 'text-indigo-600': !isHighlighted })}>
          create new attribute
        </span>
      </Text>
    ) : (
      <Text h='400'>{item.label}</Text>
    );
  };

  const renderInput = (props) => (
    <input
      {...props}
      type='text'
      autoFocus
      style={NO_OUTLINE}
      className='w-full py-4'
      placeholder='Attribute label...'
      name='dropdown_combobox'
    />
  );

  return (
    <div ref={ref} className='relative border-b border-gray-200'>
      <DropdownCombobox
        key={'valueFor' + (options[0]?.label || 'empty')}
        renderItem={DropdownItem}
        defaultIsOpen
        autoFocus
        submitOnEnter
        submitLastValue
        allowCreate={canManage}
        limitedWidthDropdown
        items={options}
        placeholder='Type or select'
        renderInput={renderInput}
        onSelect={onSelect}
      />
    </div>
  );
});
