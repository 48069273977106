import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import { Grid } from 'components/common/Grid';
import { RestrictedAction } from 'components/shared/RestrictedAction';
import { incentivesZDSButtons } from 'components/shared/ZDSPage/constants';
import { RecommendedMethod } from 'components/shared/ZDSPage/RecommendedMethod';
import { ZDSButton } from 'components/shared/ZDSPage/ZDSButton';
import { usePlan } from 'hooks/usePlan';

export const IncentivesZDSBody: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { hasFeature } = usePlan();

  const navigate = useNavigate();

  const canFundWithInvoice = hasFeature('fund_with_invoice');

  return (
    <>
      <RecommendedMethod type='incentives' onClick={() => navigate(`/incentives/send`)} />
      <Grid className='mt-6' gap={6} mobile={1} tablet={2} desktop={3} monitor={3}>
        {incentivesZDSButtons.map(({ icon, heading, subheading, link }) => {
          if (link === '/incentives/invoice') {
            return (
              <RestrictedAction key={heading} feature='fund_with_invoice' spanClassName='flex'>
                {({ can, may }) => (
                  <ZDSButton
                    disabled={!can || !may}
                    key={heading}
                    heading={heading}
                    onClick={() => navigate(link)}
                    icon={icon}
                    subheading={subheading}
                  />
                )}
              </RestrictedAction>
            );
          }
          return (
            <ZDSButton
              key={heading}
              heading={heading}
              onClick={() => navigate(link)}
              icon={icon}
              subheading={subheading}
            />
          );
        })}
      </Grid>
    </>
  );
};
