import React, { cloneElement, ComponentProps, createElement, isValidElement, useEffect, useState } from 'react';

import { Spinner } from '@components/common';
import { pascalize } from '@components/utils';

import { NON_QUESTION_KINDS } from '../constants';
import * as Models from '../types/models';

interface Props<P extends ComponentProps<any>> {
  kind: Models.BlockKind;
  componentProps?: PropsWithRef<P>;
  onComponentLoad?: () => void;
}

export const BlockableComponent = <P extends ComponentProps<any> = ComponentProps<'input'>>({
  kind,
  componentProps,
  onComponentLoad
}: Props<P>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [component, setComponent] = useState<JSX.Element | null>(null);

  const handleOnError = (error: Error) => {
    console.log(error);
    setIsLoading(false);
    setComponent(null);
  };

  const getComponentPath = () => {
    const path = './Blockable';

    if (!NON_QUESTION_KINDS.includes(kind)) {
      return `${path}/questions`;
    }

    return path;
  };

  useEffect(() => {
    setComponent(null);

    const path = getComponentPath();
    const componentName = pascalize(kind);

    import(`${path}/${componentName}.tsx`)
      .then((module) => {
        const namedExport = module[componentName];

        setIsLoading(false);
        setComponent(isValidElement(namedExport) ? namedExport : createElement(namedExport));

        if (onComponentLoad) {
          requestAnimationFrame(onComponentLoad);
        }
      })
      .catch(handleOnError);

    return () => {
      setIsLoading(false);
      setComponent(null);
    };
  }, [kind]);

  if (!component) {
    return null;
  }

  if (isLoading) {
    return <Spinner className='mx-auto my-4 h-8 w-8' />;
  }

  return <div data-testid='block_body_component'>{cloneElement(component, componentProps)}</div>;
};
