import React from 'react';

import ReactDatePicker from 'react-datepicker';

export const DatePicker = ({ value, onChange }) => {
  return (
    <ReactDatePicker
      wrapperClassName='w-full'
      selected={value ? new Date(value) : null}
      onChange={(date) => onChange(date)}
      placeholderText='Enter…'
      className='w-full rounded-md border border-gray-300 text-left'
    />
  );
};
