import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { api } from '@api/reduxApi';
import { Button, FormGroup, Input, Modal, Text } from '@components/common';
import { TeamPicker } from '@components/shared/TeamPicker';
import { UserSelect } from '@components/shared/UserSelect';
import { makeRandomId } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { useFeature } from '@hooks/useFeature';
import { useToaster } from '@stores/toaster';

import * as toasts from '../../../../StudiesApp/toasts';

export type Props = {
  initialStudy: SimpleStudy;
  open: boolean;
  onClose: () => void;
  initialOwner: TeamUser;
};

export const DuplicateStudyModal: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  initialOwner,
  onClose,
  initialStudy
}) => {
  const {
    account: { team }
  } = useAccount();

  const studyTeamRequired = useFeature('team_access_control');

  const [duplicateStudy, { data, isLoading, isError }] = api.useDuplicateStudyMutation();
  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState(makeRandomId(8));
  const [owner, setOwner] = useState<TeamUser>(initialOwner);
  const [teamId, setTeamId] = useState<number | null>(null);
  const [showTeam, setShowTeam] = useState(false);

  const showToast = useToaster();

  useEffect(() => {
    if (open) {
      setTitle(`Copy of ${initialStudy.title}`);
      setOwner(initialOwner);
      setSlug(makeRandomId(8));
      setTeamId(initialStudy.team_id || null);
      setShowTeam(!!initialStudy.team_id);
    }
  }, [open]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedDuplicate());
    }
  }, [isError]);

  useEffect(() => {
    if (data) {
      window.location.href = `/studies/${data.id}`;
    }
  }, [data]);

  const handleClick = () => {
    duplicateStudy({ id: initialStudy.id, study: { title, slug, owner_id: owner.id, team_id: teamId } });
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      size='md'
      title='Duplicate study'
      onClose={isLoading ? undefined : onClose}
      renderFooter={() => (
        <div className='flex justify-end space-x-4'>
          <Button disabled={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button loading={isLoading} disabled={isLoading} primary onClick={handleClick}>
            Duplicate
          </Button>
        </div>
      )}
    >
      <Text h='400' mb='6'>
        The new draft study will copy this study’s setup, automation & notification settings. Account users, study
        participants & research results won’t be duplicated.
      </Text>

      <FormGroup>
        <Text as='label' htmlFor='title' bold h='400' mb='1'>
          Title
        </Text>

        <Input
          id='title'
          autoFocus
          className='xx-study-title w-full'
          disabled={isLoading}
          value={title}
          onChange={setTitle}
          placeholder='Enter name...'
        />
      </FormGroup>

      <FormGroup>
        <Text bold h='400' mb='1'>
          Owner
        </Text>

        <UserSelect
          users={team}
          disabled={isLoading}
          selectedId={owner.id}
          onSelect={(userId) => setOwner(team.find((u) => u.id === userId)!)}
        />
      </FormGroup>

      {showTeam && (
        <FormGroup>
          <Text bold h='400' mb='1'>
            Team
          </Text>

          <TeamPicker
            disabled={isLoading}
            placement='left'
            className='w-full'
            value={teamId}
            onChange={setTeamId}
            allowNoTeam={!studyTeamRequired}
          />
        </FormGroup>
      )}

      <div className='mb-5 w-full'></div>
    </Modal>
  );
};
