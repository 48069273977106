import * as React from 'react';

import { Route, Routes } from 'react-router-dom';

import { NotificationsIndex } from './pages';

export const AccountNotificationsApp: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Routes>
    <Route path='/' element={<NotificationsIndex />} />
  </Routes>
);
