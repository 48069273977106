import * as React from 'react';

import { useParams } from 'react-router-dom';

import { LegalIndex } from './LegalIndex';

export const TeamLegalIndex = () => {
  const { id } = useParams<{ id: string }>();

  const teamId = parseInt(id || '', 10);

  return <LegalIndex teamId={teamId || null} />;
};
