import * as React from 'react';

// hooks
import { useBeforeunload } from 'react-beforeunload';

// components
import { ProgressBar } from '@components/common';

import { PlaceholderPlayer } from './PlaceholderPlayer';

interface Props {
  progress: number;
  onClickCancel: () => void;
}

export const UploadingState: React.FC<React.PropsWithChildren<Props>> = ({ progress, onClickCancel }) => {
  useBeforeunload(() => 'Navigating away will cancel your currently uploading file.');

  return (
    <>
      <PlaceholderPlayer state='uploading' />
      <div className='rounded-b-lg border border-t-0 border-dashed border-gray-200 p-4'>
        <div className='pb-6 text-center'>
          <div className='font-bold text-gray-700'>We're uploading the video.</div>
          <div className='text-sm text-gray-500'>Please don't close the page until it finishes.</div>
        </div>

        <ProgressBar showLabel progress={progress || 0} />

        <div className='mt-4 text-right'>
          <button
            onClick={onClickCancel}
            disabled={progress === 100}
            className='text-right text-sm text-indigo-600 hover:text-indigo-700 disabled:opacity-50'
          >
            Cancel upload
          </button>
        </div>
      </div>
    </>
  );
};
