import React, { HTMLAttributes, useMemo } from 'react';

import { getTableColumnDefs } from '@components/ScreenerResults/components/CardSortResponse/components/helpers/getTableColumnDefs';
import { Table } from '@components/shared/Table';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
  setPreview: (answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>) => void;
}

export const ResponsesTable: React.FC<React.PropsWithChildren<Props>> = ({ answers, setPreview }) => {
  const columns = useMemo(() => getTableColumnDefs({ setPreview }), []);

  return (
    <div className='relative max-w-full overflow-auto'>
      <Table
        columnPinning={{ left: ['participation_name'] }}
        data={answers}
        columns={columns}
        className='w-full table-fixed border border-b-0 border-gray-200 bg-white'
      />
    </div>
  );
};
