import * as React from 'react';
import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Button, Text } from '@components/common';
import { Basic } from '@components/shared/Skeleton';
import { track } from '@components/tracking';
import { Provider as CollectionView } from '@stores/view';

import { DEFAULT_CANDIDATE_MESSAGES_VIEW, EVENT_TITLES } from './consts';
import { EventStats } from './EventStats';
import { MessagesTable } from './MessagesTable';

interface Props {
  study: Study;
  setBackgroundTasks: (tasks: BackgroundTask[]) => void;
  backgroundTasks: BackgroundTask[];
}

export const StatsDetail: React.FC<React.PropsWithChildren<Props>> = ({
  study,
  backgroundTasks,
  setBackgroundTasks
}) => {
  const { data: stats, isLoading } = api.useGetStudyStatsQuery(study.id);

  const { event = '' } = useParams<{ event: string }>();

  useEffect(() => {
    track('viewed_stats_detail', { event });
  }, []);

  return (
    <div className='px-page py-gutter'>
      <div className='mb-6'>
        <Button icon='chevronLeft' link href={`/studies/${study.id}/stats`} spa>
          All stats
        </Button>
      </div>
      <Text as='h2' h='700' bold mb='4'>
        {EVENT_TITLES[event]}
      </Text>
      {isLoading && <Basic dataTestid='stats-loader' h='24' width='100%' />}
      {stats?.[event] && <EventStats event={event} stats={stats[event]} studyId={study.id} />}
      <CollectionView scope='candidate_messages' default={DEFAULT_CANDIDATE_MESSAGES_VIEW}>
        <MessagesTable
          study={study}
          event={event}
          setBackgroundTasks={setBackgroundTasks}
          backgroundTasks={backgroundTasks}
        />
      </CollectionView>
    </div>
  );
};
