import React, { FC, useState } from 'react';

import { api } from '@api/reduxApi';
import { Button, Modal, ModalHeading, Text } from '@components/common';
import { track } from '@components/tracking';
import { BookingPage } from 'components/Public/Scheduler/pages/BookingPage';

interface Props {
  study: Study;
  onSave: (study: Study) => void;
}

export const Preview: FC<React.PropsWithChildren<Props>> = ({ study, onSave, children }) => {
  return <GQPreview study={study}>{children}</GQPreview>;
};
const GQPreview: FC<React.PropsWithChildren<{ study: Study }>> = ({ study, children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [updateStudy] = api.useUpdateStudyMutation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleButtonClick = async () => {
    setLoading(true);
    await updateStudy(study);
    setLoading(false);
    setIsOpen(true);
    track('viewed_calendar_preview', { study_state: study.state });
  };

  return (
    <>
      <Button medium={study.focus_group} icon='eye' onClick={handleButtonClick} loading={loading}>
        {children}
      </Button>
      {isOpen && (
        <Modal size='xl' onClose={() => setIsOpen(false)}>
          <ModalHeading className='mb-4'>Participant preview</ModalHeading>
          <Text h='400'>Slots that participants will see when asked to schedule time on your calendar.</Text>
          <section className='relative mb-4 mt-4 flex-1'>
            <BookingPage study={study} researcherView />
          </section>
        </Modal>
      )}
    </>
  );
};
