import * as React from 'react';

import { Select, Text } from '@components/common';
import { Tooltip } from '@components/Tooltip';

import { buildItems, QuestionSelectItemData } from './items';

type Props = {
  currentQuestion: ScreenerField;
  questions: ScreenerField[];
  value: number;
  onChange: (questionId: number) => void;
  endValue: number;
};
export const QuestionSelect: React.FC<React.PropsWithChildren<Props>> = ({
  currentQuestion,
  questions,
  value,
  onChange,
  endValue
}) => {
  const items = buildItems(currentQuestion, questions);

  const handleSelect = (value: string) => {
    const item = items.find((i) => i.value === value);
    if (!item) {
      return;
    }
    if (item.value === 'end') {
      onChange(endValue);
      return;
    }
    const id = parseInt(item.value);
    onChange(id);
  };

  return (
    <Select<string, QuestionSelectItemData>
      options={items}
      placeholder='Choose an option…'
      value={value === endValue ? 'end' : String(value)}
      onChange={handleSelect}
      className='bg-white'
      name='skip_to'
      renderLabel={({ label, disabled, data }) => {
        const { question, tooltip } = data || {};

        const color = disabled ? 'gray-500' : undefined;

        return (
          <div className='flex py-1'>
            <Tooltip isDisabled={!tooltip} content={tooltip}>
              <div className='truncate'>
                <Text color={color} h='400'>
                  {label}
                </Text>
              </div>
            </Tooltip>
          </div>
        );
      }}
    />
  );
};
