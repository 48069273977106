import * as React from 'react';

import { SlideOut } from '@components/common';
import { CancelEditModal } from 'components/shared/CancelEditModal/CancelEditModal';
import { useProfileContext } from 'hooks/useProfileContext';

import { StudyAttrsTable } from './StudyAttrsTable';

interface Props {
  onClose: () => void;
  study: Study;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StudyAttrsSlideout: React.FC<React.PropsWithChildren<Props>> = ({ onClose, study, setLoading }) => {
  const { isDirty, setCancelModal, setIsDirty, cancelModal } = useProfileContext();
  const handleClose = () => {
    if ((Object.values(isDirty) || []).includes(true)) {
      setCancelModal(true);
    } else {
      onClose();
    }
  };
  return (
    <>
      <SlideOut
        onClose={handleClose}
        closeOnEsc
        title='Custom study attributes'
        subtitle='You can filter, group and sort by these attributes later.'
      >
        <hr></hr>
        <div className='p-6'>
          <StudyAttrsTable study={study} setLoading={setLoading} />
        </div>
      </SlideOut>
      <CancelEditModal
        discardMessage="Some attributes weren't updated."
        setEdit={onClose}
        setIsDirty={setIsDirty}
        onClose={() => setCancelModal(false)}
        cancelModal={cancelModal}
      />
    </>
  );
};
