import React from 'react';

import { Button, Modal, ModalHeading } from '@components/common';

export const DeleteConfirmationModal = ({ onClose, onDelete }) => {
  return (
    <Modal icon='danger' onClose={onClose}>
      <ModalHeading className='mb-3'>Deleting a category</ModalHeading>
      <p className='h400 pb-12 text-gray-700'>
        You are about to delete a category with assigned cards. Once deleted, the cards will be moved to the sidebar.
      </p>
      <div className='flex justify-end space-x-4'>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onDelete} danger>
          Delete
        </Button>
      </div>
    </Modal>
  );
};
