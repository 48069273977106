import * as React from 'react';
import { useEffect, useState } from 'react';

import { Dropdown } from '@components/common';
import { useGetMeetingProvidersQuery } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/api';
import { UsersConnectedAccountCreatedChannel, UsersConnectedAccountsChannel, useWebsocket } from '@hooks/useWebsocket';

import { MeetingProviderDropdownContent } from './components/MeetingProviderDropdownContent';
import { MeetingProviderOption } from './components/MeetingProviderOption';

interface Props {
  disabled?: boolean;
  user: User;
  study: SimpleStudy;
  value: VideoUrlType | null;
  onClick: (option: MeetingProvider) => void;
}

export const MeetingProvidersDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  disabled,
  user,
  study,
  value,
  onClick
}) => {
  const [open, setOpen] = useState(false);
  const { data: meetingProviderOptions, refetch, isLoading } = useGetMeetingProvidersQuery({ studyId: study.id });

  const [selectedMeetingProvider, setSelectedMeetingProvider] = useState<MeetingProvider | undefined>();

  useEffect(() => {
    if (meetingProviderOptions) {
      setSelectedMeetingProvider(meetingProviderOptions?.find((o) => o.video_url_type === value));
    }
  }, [meetingProviderOptions]);

  useWebsocket<{ user_id: number }, ConnectedAccount>({
    channel: UsersConnectedAccountsChannel,
    params: { user_id: user.id },
    onReceived: refetch
  });
  useWebsocket<{ user_id: number }, ConnectedAccount>({
    channel: UsersConnectedAccountCreatedChannel,
    params: { user_id: user.id },
    onReceived: refetch
  });

  useEffect(() => {
    refetch();
  }, [study.video_url, study.video_url_type]);

  const isOwner = study.owner?.id === user.id;

  const handleClick: (e: React.MouseEvent, option: MeetingProvider) => void = (e, option) => {
    e.preventDefault();
    setSelectedMeetingProvider(option);
    setOpen(false);
    onClick(option);
  };

  return (
    <Dropdown
      disabled={disabled || !meetingProviderOptions || isLoading}
      isOpen={open}
      primary={!selectedMeetingProvider && !isLoading}
      text={<MeetingProviderDropdownContent option={selectedMeetingProvider} />}
      className='w-full'
      menuClassName='w-full'
      buttonClassName='justify-self-start w-full'
      onClick={() => setOpen(!open)}
      onClose={() => setOpen(false)}
    >
      {meetingProviderOptions &&
        meetingProviderOptions.length > 0 &&
        meetingProviderOptions.map((option) => (
          <MeetingProviderOption
            key={option.name}
            option={option}
            onClick={(e) => handleClick(e, option)}
            isOwner={isOwner}
          />
        ))}
    </Dropdown>
  );
};
