import { Plugin, PluginKey } from 'prosemirror-state';
import { findParentDomRefOfType } from 'prosemirror-utils';
import { EditorView } from 'prosemirror-view';
import { createPopper, Instance as PopperInstance, Options as PopperOptions, VirtualElement } from '@popperjs/core';
import { Editor, posToDOMRect } from '@tiptap/core';

import * as Queries from '../helpers/queries';

export interface PluginParams {
  editor: Editor;
  view: EditorView;
  element: HTMLDivElement | null;
  popperOptions?: Partial<PopperOptions>;
  pluginKey?: string;
  shouldShow?: (params: { editor: Editor; view: EditorView }) => boolean;
  onShow?: () => void;
  onHide?: () => void;
}

class BubbleMenuView {
  editor: PluginParams['editor'];
  view: PluginParams['view'];
  element: PluginParams['element'];
  popperOptions: PluginParams['popperOptions'];
  shouldShow: PluginParams['shouldShow'];
  onShow: PluginParams['onShow'];
  onHide: PluginParams['onHide'];
  popper: PopperInstance | null;
  preventShow: boolean;

  constructor({ editor, view, element, popperOptions, shouldShow, onShow, onHide }: PluginParams) {
    this.editor = editor;
    this.view = view;
    this.element = element;
    this.popperOptions = popperOptions;
    this.shouldShow = shouldShow;
    this.onShow = onShow;
    this.onHide = onHide;
    this.preventShow = false;

    view.dom.addEventListener('mousedown', this.handleOnMouseDown);
    window.addEventListener('mouseup', this.handleOnMouseUp);
    document.addEventListener('mousedown', this.handleOnClickOutside);
  }

  getReference(): VirtualElement | Element {
    let { from, to } = this.view.state.selection;
    const { empty } = this.view.state.selection;

    if (this.editor.isActive('link') && empty) {
      const mark = Queries.getActiveMarkRange(this.view.state, 'link');

      if (mark) {
        from = mark.from;
        to = mark.to;
      }
    } else {
      const element = Queries.findTranscriptNodeDomRef(this.editor, this.view);

      if (element) {
        // get the content node instead of the whole transcript node for a more accurate position
        const contentNode = element.querySelector('[data-node-view-content]');

        if (contentNode) {
          return contentNode;
        }
      } else {
        const node = findParentDomRefOfType(
          this.editor.state.schema.nodes.paragraph,
          this.view.domAtPos.bind(this.view)
        )(this.view.state.selection);

        if (node) {
          return node as Element;
        }
      }
    }

    return { getBoundingClientRect: () => posToDOMRect(this.view, from, to) };
  }

  createMenu() {
    if (!this.element) return;

    if (!this.popper) {
      this.popper = createPopper(this.getReference(), this.element, {
        strategy: 'fixed',
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: { offset: [0, 8] }
          },
          {
            name: 'preventOverflow',
            options: {
              tether: false,
              altBoundary: true
            }
          }
        ],
        ...this.popperOptions
      });
    }
  }

  hide() {
    this.element?.classList.add('invisible');
    this.onHide?.();
  }

  show() {
    this.element?.classList.remove('invisible');
    this.onShow?.();
  }

  update(view: EditorView) {
    this.createMenu();
    if (!this.editor.isFocused) {
      return;
    }

    if (this.popper) {
      this.popper.state.elements.reference = this.getReference();
      this.popper.update();
    }

    const shouldHide = this.shouldShow && !this.shouldShow({ editor: this.editor, view });

    if (shouldHide || this.preventShow) {
      this.hide();
    } else {
      this.show();
    }
  }

  destroy() {
    if (this.popper) this.popper.destroy();

    this.view.dom.removeEventListener('mousedown', this.handleOnMouseDown);
    window.removeEventListener('mouseup', this.handleOnMouseUp);
  }

  handleOnMouseDown = () => {
    this.preventShow = true;
  };

  handleOnMouseUp = () => {
    this.preventShow = false;

    this.update(this.view);
  };

  handleOnClickOutside: EventListener = (event) => {
    if (!this.element) return;

    const target = event.target as Node;
    const tagListDropdown = document.querySelector('.TagListDropdown');

    if (target && (this.element.parentNode?.contains(target) || tagListDropdown?.contains(target))) {
      return;
    }

    this.hide();
  };
}

export const BubbleMenu = ({ pluginKey, ...params }: Omit<PluginParams, 'view'>) =>
  new Plugin({
    key: new PluginKey(pluginKey),
    view: (view) => new BubbleMenuView({ view, ...params })
  });
