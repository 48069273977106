import * as React from 'react';
import { useState } from 'react';

import cn from 'classnames';
import pluralize from 'pluralize';

import { Button, List, Modal, Text } from '@components/common';
import { last } from '@components/utils';

import { InputProps } from '../types';

interface SubTaskData {
  label: string;
  completed: boolean;
  position: number;
}

interface Props {
  preview?: boolean;
  url?: string | null;
  onClose: () => void;
  options?: string[];
}

const FIGMA_URL = 'https://www.figma.com/embed';
export const FIGMA_MATCHER = /https:\/\/([\w.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/;

const urlBuilder = (url?: string | null) =>
  (url || '').match(FIGMA_MATCHER) ? `${FIGMA_URL}?embed_host=share&url=${encodeURIComponent(url as string)}` : url;

const subTaskBuilder = (subTasks: string[]): SubTaskData[] =>
  subTasks.map((label, i) => ({
    completed: false,
    position: i,
    label
  }));

const getCurrentTask = (subTasks: SubTaskData[]): SubTaskData | undefined =>
  subTasks.sort(({ position: posA }, { position: posB }) => posA - posB).filter(({ completed }) => !completed)[0] ??
  last(subTasks);

const getCompletedTasksSize = (subTasks: SubTaskData[]) =>
  Math.min(subTasks.filter(({ completed }) => completed).length + 1, subTasks.length);

function addHttp(link?: string | null): string {
  if ((link || '').search(/^http[s]?:\/\//) == -1) {
    link = 'http://' + link;
  }
  return link as string;
}

export const SubTaskStepper: React.FC<
  React.PropsWithChildren<{ value: number } & React.HTMLAttributes<SVGElement>>
> = ({ value, className, ...rest }) => (
  <svg viewBox='0 0 36 36' className={cn('h-10 w-10', className)} {...rest}>
    <path
      fill='none'
      stroke='#E5E7EB'
      strokeWidth={4}
      d='M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831'
    />
    <path
      fill='none'
      strokeDasharray={`${value}, 100`}
      stroke='#5850EC'
      strokeWidth={4}
      d='M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831'
    />
  </svg>
);

export const Task: React.FC<React.PropsWithChildren<Props>> = ({ url: defaultUrl, preview, onClose, options }) => {
  const [subTasks, setSubTasks] = useState<SubTaskData[]>(subTaskBuilder(options ?? []));
  const [showInstructions, setShowInstructions] = useState<boolean>(true);
  const currentSubTask = getCurrentTask(subTasks);
  const completedTasks = getCompletedTasksSize(subTasks);
  const stepperPercent = (completedTasks / subTasks.length) * 100;
  const taskUrl = urlBuilder(defaultUrl);

  const handleCompleteSubTask = (position: SubTaskData['position']) => {
    const _subTasks = [...subTasks];
    _subTasks[position].completed = true;
    setSubTasks(_subTasks);

    if (position === last(subTasks).position) {
      onClose();
    } else {
      setShowInstructions(true);
    }
  };

  return (
    <>
      <div className='fixed inset-0 z-30 bg-opacity-50'>
        <div className='flex h-full w-full flex-col bg-white text-left'>
          <div className='px-page z-40 flex flex-row items-center space-x-4 py-2 shadow-lg'>
            <div className='flex flex-1 items-center space-x-6'>
              <SubTaskStepper value={stepperPercent} />
              <Text h='600' bold>
                Task {completedTasks} of {subTasks.length}
              </Text>
            </div>
            <Button
              noStyle
              className='btn-custom-brand ml-auto'
              onClick={() => handleCompleteSubTask(currentSubTask?.position ?? 0)}
            >
              Complete task {completedTasks} of {subTasks.length}
            </Button>
            <Button className='btn-custom-brand-secondary' icon='blog' onClick={() => setShowInstructions(true)}>
              Task instructions
            </Button>
            {preview && (
              <Button className='btn-custom-brand-secondary' onClick={() => onClose()}>
                Close
              </Button>
            )}
          </div>
          <div className='relative h-full w-full flex-1'>
            {taskUrl && (
              <iframe
                title='Custom task URL'
                className='h-full w-full'
                sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
                src={addHttp(taskUrl)}
              />
            )}
          </div>
        </div>
      </div>
      {showInstructions && currentSubTask && (
        <Modal
          size='md'
          title='Instructions'
          subtitle='Read the instructions below and then continue to complete the task.'
          onClose={() => setShowInstructions(false)}
          renderFooter={() => <Button onClick={() => setShowInstructions(false)}>Close</Button>}
        >
          <section className='mt-6'>
            <Text bold>Task #{currentSubTask.position + 1}</Text>
            <Text>{currentSubTask.label}</Text>
          </section>
        </Modal>
      )}
    </>
  );
};

const SuccessIcon = (props: React.HTMLAttributes<SVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.5 8.71333L5.92917 10.7416C5.98338 10.8226 6.05614 10.8894 6.14141 10.9366C6.22668 10.9838 6.32198 11.0099 6.41938 11.0128C6.51679 11.0157 6.61348 10.9953 6.70141 10.9533C6.78934 10.9113 6.86597 10.8489 6.92492 10.7713L11.5 4.98291'
      stroke='#057A55'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.4375 7.99951C1.4375 9.73999 2.1289 11.4092 3.35961 12.6399C4.59032 13.8706 6.25952 14.562 8 14.562C9.74048 14.562 11.4097 13.8706 12.6404 12.6399C13.8711 11.4092 14.5625 9.73999 14.5625 7.99951C14.5625 6.25903 13.8711 4.58983 12.6404 3.35912C11.4097 2.12842 9.74048 1.43701 8 1.43701C6.25952 1.43701 4.59032 2.12842 3.35961 3.35912C2.1289 4.58983 1.4375 6.25903 1.4375 7.99951V7.99951Z'
      stroke='#057A55'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const OffSiteTaskInstructions: React.FC<
  React.PropsWithChildren<{ url: string; options: string[]; onComplete: () => void }>
> = ({ url, options, onComplete }) => {
  const [currentTaskIndex, setCurrentTaskIndex] = useState<number>(-1);
  const [showInstructions, setShowInstructions] = useState<boolean>(false);

  function nextTask() {
    if (currentTaskIndex == options.length - 1) {
      onComplete();
    }
    setCurrentTaskIndex(currentTaskIndex + 1);
  }

  if (!showInstructions) {
    return (
      <Button noStyle className='btn-custom-brand' onClick={() => setShowInstructions(true)}>
        Show instructions
      </Button>
    );
  }

  return (
    <>
      <Text>Visit {url} and complete the following:</Text>
      <List mb='6' mt='2'>
        {options.map((task, index) => (
          <li className={index < currentTaskIndex ? 'line-through' : ''}>{task}</li>
        ))}
      </List>
      {currentTaskIndex === -1 && (
        <Button noStyle className='btn-custom-brand' target='_blank' href={addHttp(url)} onClick={nextTask}>
          Start task
        </Button>
      )}
      {currentTaskIndex > -1 && currentTaskIndex < options.length && (
        <Button noStyle className='btn-custom-brand' target='_blank' onClick={nextTask}>
          Mark task {currentTaskIndex + 1} complete
        </Button>
      )}
    </>
  );
};

export const TaskInput: React.FC<React.PropsWithChildren<InputProps>> = ({
  field: { id, param, meta, options },
  register,
  setValue,
  watch
}) => {
  const [show, setShow] = useState<boolean>();
  const val = watch(String(id));

  const getTasksIncludedTitle = () => {
    const size = options?.length ?? 0;
    const plural = size !== 1;
    const isAre = plural ? 'are' : 'is';
    return `There ${isAre} ${pluralize('task', size, true)} included.`;
  };

  return (
    <div>
      <Text h='400' className='mb-5'>
        {getTasksIncludedTitle()}
      </Text>
      {!meta.can_iframe && (
        <OffSiteTaskInstructions url={param as any} options={options ?? []} onComplete={() => setValue?.(true)} />
      )}
      {val && (
        <div className='flex flex-row items-center space-x-2'>
          <SuccessIcon />
          <Text bold color='green-500'>
            Completed
          </Text>
        </div>
      )}
      {!val && meta.can_iframe && (
        <Button primary={!val} onClick={() => setShow(true)}>
          View Task
        </Button>
      )}

      <input type='hidden' name={String(id)} ref={register({ required: true })} />
      {show && (
        <Task
          url={param}
          options={options ?? []}
          onClose={() => {
            setShow(false);
            setValue?.(true);
          }}
        />
      )}
    </div>
  );
};
