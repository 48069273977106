import * as React from 'react';

import tinytime from 'tinytime';
import Tippy from '@tippyjs/react';

import { Text } from '@components/common';
import { ChevronRightSVG } from '@components/svgs';

import { useProfileSlideoutNavigate } from '../../routerContext';

import { CalendarEventStatusPill } from './CalendarEventStatusPill';

const dateFormat = tinytime('{dddd}, {MM} {DD}');
const timeFormat = tinytime('{h}:{mm}{a}');

export const EVENT_TYPE_LABELS: Record<CalendarEvent['event_type'], string> = {
  candidate: 'Candidate',
  team: 'Observer'
};

export const timeRangeStr = (calendarEvent: CalendarEvent): string =>
  `${timeFormat.render(calendarEvent.start_at)} - ${timeFormat.render(calendarEvent.end_at)}`;

type Props = {
  calendarEvent: CalendarEvent;
};

export const CalendarEventLink: React.FC<React.PropsWithChildren<Props>> = ({ calendarEvent }) => {
  const navigate = useProfileSlideoutNavigate();

  const handleClick = () => {
    navigate(`calendar_events/${calendarEvent.id}`);
  };

  return (
    <button
      title={calendarEvent.title}
      aria-label={calendarEvent.title}
      className='w-full text-left text-gray-700 hover:text-indigo-600'
      onClick={handleClick}
    >
      <div className='mb-1 flex w-full flex-1 items-center space-x-2'>
        {calendarEvent.discarded_at && (
          <Tippy content='The interview was updated. A new event was generated.'>
            <div>
              <CalendarEventStatusPill calendarEvent={calendarEvent} />
            </div>
          </Tippy>
        )}
        <Text className='flex-1' h='400' color={calendarEvent.discarded_at ? 'gray-400' : undefined} truncate>
          {calendarEvent.title}
        </Text>
        <ChevronRightSVG />
      </div>
      <Text
        h='400'
        color={calendarEvent.discarded_at ? 'gray-400' : 'gray-500'}
        className='flex items-center space-x-2'
      >
        <span>{EVENT_TYPE_LABELS[calendarEvent.event_type]}</span>
        <span>•</span>
        <span>{dateFormat.render(calendarEvent.start_at)}</span>
        <span>•</span>
        <span>{timeRangeStr(calendarEvent)}</span>
      </Text>
    </button>
  );
};
