import * as React from 'react';
import { useState } from 'react';

import { createCandidate } from '@api/queries';
import { Button, Input, Loading, Text } from '@components/common';

import { FormGroup, SmallLabel } from './shared';

interface Props {
  candidate: Candidate;
  setCandidate: (candidate: Candidate | null) => void;
}

export const NewCandidate: React.FC<React.PropsWithChildren<Props>> = ({ candidate, setCandidate }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  function change(key, v) {
    setCandidate({
      ...candidate,
      [key]: v
    });
  }

  async function handleSave() {
    setLoading(true);
    const resp = await createCandidate(candidate);
    if (resp) {
      setCandidate(resp);
    } else {
      setLoading(false);
      setError('Error creating candidate');
      return;
    }
  }
  return (
    <>
      {loading && <Loading absolute />}
      {error && (
        <Text color='red-600' bold>
          {error}
        </Text>
      )}
      <FormGroup>
        <SmallLabel>Name</SmallLabel>
        <Input ariaLabel='Name' value={candidate.name || ''} onChange={(v) => change('name', v)} className='w-full' />
      </FormGroup>
      <FormGroup>
        <SmallLabel>Email</SmallLabel>
        <Input
          ariaLabel='Email'
          value={candidate.email || ''}
          onChange={(v) => change('email', v)}
          className='w-full'
        />
      </FormGroup>
      <div className='text-right'>
        <Button small className='mr-2' onClick={() => setCandidate(null)}>
          Cancel
        </Button>
        <Button primary small onClick={handleSave} disabled={!candidate.name || !candidate.email}>
          Save
        </Button>
      </div>
    </>
  );
};
