import React, { FC } from 'react';

import { Text } from '@components/common';
import { Drawer, DrawerProvider } from '@components/common/Drawer';
import { Preview as MarkdownPreview } from '@components/common/MarkdownEditor';

import * as Types from '../types';

import { CompletedStatus } from './CompletedStatus';

export const MobileCard: FC<React.PropsWithChildren<Types.ResponsiveCardProps>> = ({
  description,
  title,
  children,
  isComplete,
  renderFooter
}) => {
  return (
    <DrawerProvider>
      <Drawer
        header={() => (
          <>
            {isComplete && (
              <CompletedStatus className='mt-4 border-b border-t border-gray-200'>Completed</CompletedStatus>
            )}

            <Text className='w-full p-4 text-base' bold>
              {title}
            </Text>
          </>
        )}
        isExpanded
      >
        {description && (
          <MarkdownPreview
            className='max-h-64 overflow-y-auto text-sm'
            components={{
              a: ({ children, href }) => (
                <a href={href} target='_blank'>
                  {children}
                </a>
              )
            }}
          >
            {description}
          </MarkdownPreview>
        )}

        <div data-testid='question-card-content'>{children}</div>

        {renderFooter && <footer className='mt-4 flex items-center justify-end'>{renderFooter()}</footer>}
      </Drawer>
    </DrawerProvider>
  );
};
