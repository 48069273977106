import * as React from 'react';

import { Text } from '@components/common';

export type Props = {
  user: TeamUser;
  calendars?: Calendar[] | null;
  type: 'observer' | 'moderator';
  isOwner?: boolean;
};

export const ModeratorCalendarPickerTitle: React.FC<React.PropsWithChildren<Props>> = ({
  user,
  calendars,
  type,
  isOwner
}) => {
  if (!calendars) {
    return null;
  }

  return (
    <div className='p-4'>
      <Text h='400' bold className='mb-1'>
        {user.name}’s calendars ({calendars.length})
      </Text>
      <Text h='400' color='gray-500' mb='4' mt='2'>
        {explanationMessage(isOwner, type)}
      </Text>
      <hr />
    </div>
  );
};

export const explanationMessage = (isOwner, type) => {
  if (isOwner) {
    return 'Used to create events, display events and determine conflicts.';
  } else if (type === 'moderator') {
    return 'Used to display events and determine conflicts.';
  } else {
    return 'Used to display events.';
  }
};
