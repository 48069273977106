import * as React from 'react';

import { Button, Modal, Text } from '@components/common';

type Props = {
  groupLabel: string;
  onSubmit: () => void;
  onClose: () => void;
};

export const ConfirmRegroupModal: React.FC<React.PropsWithChildren<Props>> = ({ groupLabel, onSubmit, onClose }) => (
  <Modal
    title='Confirm re-grouping'
    onClose={onClose}
    renderFooter={() => (
      <div className='flex w-full flex-row justify-end space-x-4'>
        <Button text onClick={onClose}>
          Cancel
        </Button>
        <Button name='Confirm' aria-label='Confirm' primary onClick={onSubmit}>
          Confirm & re-group
        </Button>
      </div>
    )}
  >
    <Text h='400'>This will permanently overwrite your current groupings. The action cannot be undone.</Text>
  </Modal>
);
