import * as React from 'react';

import { Alert, Button, Text } from '@components/common';

export const NoTeamAlert: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className }) => (
  <Alert type='error' className={className}>
    <Text bold mb='2'>
      Please join a Team
    </Text>
    <Text h='400' mb='2'>
      In order to create a study, you must be a part of a team. Please follow the link below to join a team.
    </Text>
    <Button small className='h400' icon='externalLink' target='_blank' href='/teams'>
      View Teams
    </Button>
  </Alert>
);
