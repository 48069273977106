import React, { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';

import { Text } from '@components/common';

interface Props {
  currencySymbol?: string;
  incentive?: number | null;
  isEditable?: boolean;
  onIncentiveChange?: (value: number) => void;
}

export const IncentiveCell: FC<Props> = ({ currencySymbol, incentive, isEditable, onIncentiveChange }) => {
  const [value, setValue] = useState<string>('');
  const [editMode, setEditMode] = useState(false);

  const incentiveStr = incentive?.toString() ?? '';

  const parsedValue = useMemo(() => {
    if (!value) return '-';

    return `${currencySymbol}${value}`;
  }, [currencySymbol, value]);

  const handleOnBlur = () => {
    if (value === '') {
      setValue(incentiveStr);
    } else if (value !== incentiveStr) {
      onIncentiveChange?.(+value * 100);
    }

    setEditMode(false);
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.currentTarget;

    setValue(value);
  };

  useEffect(() => {
    if (incentiveStr) {
      setValue(incentiveStr);
    }
  }, [incentiveStr]);

  if (isEditable && editMode) {
    return (
      <input
        autoFocus
        type='number'
        className='h400 w-full'
        value={value ?? ''}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        placeholder='Enter...'
      />
    );
  }

  return (
    <div className='flex h-full w-full items-center px-2' onClick={() => setEditMode(true)}>
      <Text h='400' truncate>
        {parsedValue}
      </Text>
    </div>
  );
};
