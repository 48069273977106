import React, { forwardRef, HTMLAttributes, MouseEventHandler, ReactNode } from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

interface Props extends HTMLAttributes<HTMLDivElement> {
  href?: string;
  icon?: ReactNode;
  targetBlank?: boolean;
  closePopper?: MouseEventHandler<HTMLElement>;
  remove?: boolean;
}

export const ArtifactAction = forwardRef<HTMLDivElement, Props>(
  ({ className, children, href, icon, targetBlank, closePopper, remove, onClick, ...rest }, ref) => {
    const element = (
      <div
        className={cn(
          'flex cursor-pointer items-center border-b border-gray-200 px-4 py-2 text-sm text-gray-900 hover:bg-gray-50',
          { 'text-red-600 hover:text-red-700': remove, 'text-gray-900 hover:text-indigo-600': !remove },
          className
        )}
        ref={ref}
        onClick={
          onClick &&
          ((e) => {
            closePopper?.(e);
            onClick?.(e);
          })
        }
        {...rest}
      >
        {icon && <span className='mr-2'>{icon}</span>}
        {children}
      </div>
    );

    return href ? (
      <Link target={targetBlank ? '_blank' : undefined} to={href} onClick={closePopper}>
        {element}
      </Link>
    ) : (
      element
    );
  }
);
