import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import pluralize from 'pluralize';

import { api } from '@api/reduxApi';
import { Alert, Button, Loading, Modal, RadioGroup, Select, Text } from '@components/common';
import { RadioGroupOption } from '@components/common/RadioGroup';
import * as toasts from '@components/StudiesApp/toasts';
import { currencyToSymbol, moneyFormat } from '@components/utils';
import { FunderMethodSelect, StudyFunder, useStudyFunder } from '@components/Wallet';
import { useToaster } from '@stores/toaster';

interface Props {
  study: SimpleStudy;
  onCancel?: () => void;
  onComplete?: (resp: SimpleStudy) => void;
  onClose?: () => void;
}

interface FormProps {
  study: Study;
  funder: StudyFunder;
  partyResolution: PartyAction;
  setPartyResolution: (a: PartyAction) => void;
}

export type PartyAction = 'complete' | 'cancel'; // | 'thanks';
const PARTY_ACTION_OPTIONS: RadioGroupOption<PartyAction>[] = [
  { value: 'complete', label: 'Mark as completed' },
  // { value: 'thanks', label: 'Mark as completed & send thanks' },
  { value: 'cancel', label: 'Mark as canceled' }
];

function getUSDRefund(study: Study, refund: number | undefined, markCompleted: boolean): number {
  if (markCompleted) {
    return refund !== undefined ? refund : study.funding.usd_refundable;
  } else {
    return study.funding.usd_unissued;
  }
}
function getLocalRefund(study: Study, refund: number | undefined, markCompleted: boolean): number {
  if (study.incentive_method !== 'tremendous') {
    return 0;
  }
  if (markCompleted) {
    return refund !== undefined ? refund : 0;
  } else {
    return study.funding.local_unissued;
  }
}
export const CloseInfo: React.FC<React.PropsWithChildren<FormProps>> = ({
  study,
  funder,
  partyResolution,
  setPartyResolution
}) => {
  const { title, style, incentive_method, booked_participations_count, started_participations_count } = study;
  const activePartiesCount = (booked_participations_count || 0) + (started_participations_count || 0);

  if (funder.mode === 'request' || funder.mode === 'fund') {
    return (
      <>
        <div className='mb-6'>
          <Text bold className='mb-1'>
            You have a net balance of{' '}
            {currencyToSymbol(funder.currency) + moneyFormat((funder.allocation?.local_amount_in_cents || 0) / 100)}
          </Text>
        </div>
      </>
    );
  }

  const usdRefund = getUSDRefund(study, funder.allocation?.usd_amount_in_cents, partyResolution === 'complete');
  const localRefund = getLocalRefund(study, funder.allocation?.local_amount_in_cents, partyResolution === 'complete');
  const localRefundStr = !funder.isUSD
    ? ` (${currencyToSymbol(funder.currency) + moneyFormat(localRefund / 100)})`
    : null;
  return (
    <>
      <div className='mb-6'>
        <Text bold className='mb-1'>
          You are closing:
        </Text>
        <Text>{title}</Text>
      </div>
      <div className='mb-6'>
        <Text bold className='mb-1'>
          Once closed:
        </Text>
        <ul className='mt-2 list-disc space-y-2 pl-4 text-gray-700'>
          <li>You won’t be able to {style === 'video_call' ? 'book new interviews' : 'invite more candidates'}.</li>
          {activePartiesCount > 0 && incentive_method === 'tremendous' && (
            <li>{pluralize('participants', activePartiesCount, true)} will be sent their incentive.</li>
          )}
        </ul>
      </div>
      {activePartiesCount > 0 && (
        <div className='mb-6'>
          <Text bold className='mb-1'>
            Active participations
          </Text>
          <Text className='mb-4'>
            There are <span className='font-bold'>{pluralize('active participations', activePartiesCount, true)}</span>{' '}
            on this study.
          </Text>
          <Select<PartyAction> options={PARTY_ACTION_OPTIONS} value={partyResolution} onChange={setPartyResolution} />
        </div>
      )}

      {usdRefund > 0 && (
        <div className='mb-6'>
          <Text bold className='mb-1'>
            Remaining incentives
          </Text>
          <Text className='mb-4'>
            There is <span className='font-bold'>${moneyFormat(usdRefund / 100)} USD</span>
            {localRefundStr} remaining on this study.
          </Text>
          <Text bold className='mb-1'>
            Refund to
          </Text>
          <FunderMethodSelect funder={funder} />
        </div>
      )}
    </>
  );
};

export const CloseStudyModal: React.FC<React.PropsWithChildren<Props>> = ({ study, onComplete, onClose }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [partyResolution, setPartyResolution] = useState<PartyAction>('complete');
  const [closeStudy, { data, isError, isSuccess }] = api.useCloseStudyMutation();
  const { data: fullStudy, isFetching } = api.useGetStudyQuery(study.id);
  const funder = useStudyFunder({ study: fullStudy });

  const showToast = useToaster();

  useEffect(() => {
    setLoading(!funder.allocation || isFetching);
  }, [funder.allocation, isFetching]);

  useEffect(() => {
    if (fullStudy) {
      // Setting it to manual calculates the entire refund.
      funder.prepare({ incentive_method: 'manual' });
    }
  }, [fullStudy]);

  useEffect(() => {
    if (data && isSuccess) {
      onComplete?.(data);
      showToast(toasts.successClose());
      setLoading(false);
      onClose?.();
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedClose());
      setLoading(false);
      onClose?.();
    }
  }, [isError]);

  const handleClose = async () => {
    if (!study) {
      return showToast(toasts.failedClose());
    }
    setLoading(true);
    closeStudy({ id: study.id, method: funder.selectedMethod?.kind, resolution: partyResolution });
  };

  let preventCloseMessage: string | undefined;
  if (funder.mode === 'request' || funder.mode === 'fund') {
    preventCloseMessage = 'Please fund all outstanding incentives before closing the Study';
  }
  const preventClose: boolean = !!preventCloseMessage;

  return (
    <Modal
      onClose={onClose}
      size='md'
      title='Close Study'
      renderFooter={() => (
        <div className='flex items-center space-x-4 text-left'>
          <div className='flex-1'>
            {preventClose && preventCloseMessage && (
              <Text h='200' color='red-600'>
                {preventCloseMessage}
              </Text>
            )}
          </div>
          <Button onClick={onClose}>Don't close</Button>
          <Button disabled={preventClose} primary onClick={handleClose}>
            Confirm & close
          </Button>
        </div>
      )}
    >
      {loading && <Loading absolute />}
      {!loading && fullStudy && (
        <CloseInfo
          study={fullStudy}
          funder={funder}
          partyResolution={partyResolution}
          setPartyResolution={setPartyResolution}
        />
      )}
    </Modal>
  );
};
