import { parse as dateParse } from 'date-fns';
import qs from 'qs';

import { uid } from '@components/utils';

import { FilterDefinition, FilterState, Operator, Operators } from '../types';

import { DATE_FORMAT, OP_NO_VALUE } from './encode';
import { isDate } from './isDate';

const ARRAY_OPERATORS = ['includes_any', 'includes_none', 'includes_all'];

export const splitWithTail = (str: string, delim: string, count: number): string[] => {
  const parts = str.split(delim);
  let period;

  if (parts[parts.length - 1].startsWith('period:')) {
    period = parts[parts.length - 1];
    parts.pop();
  }
  const tail = parts.slice(count).join(delim);
  const result = parts.slice(0, count);

  result.push(tail);

  if (period) {
    result.push(period);
  }

  return result;
};

const parseValue = (value: string, operator: string) => {
  if (value === '') {
    return;
  }

  if (ARRAY_OPERATORS.includes(operator)) {
    return value
      .replace(/\\,/g, '{COMMA}')
      .replace(/\\ /g, '{SPACE}')
      .split(',')
      .map((entry) => (isNaN(+entry) ? entry.replace(/\{COMMA\}/g, ',').replace(/\{SPACE\}/g, ' ') : +entry));
  }

  if (isDate(value)) {
    return dateParse(value, DATE_FORMAT, new Date());
  }

  if (value === 'true' || value === 'false') {
    return value === 'true';
  }

  return value;
};

export const parse = <D extends Record<string, any>>(
  filter: string,
  definitions: FilterDefinition<D>[]
): FilterState<D> | void => {
  const unescapedFilter = filter.replace(/\\ /g, '{SPACE}');

  const splitFilter = splitWithTail(unescapedFilter, ' ', 2) as [string, Operator, string, string];
  const [id, operator, value, period] = splitFilter.map((entry) => entry.replace(/\{SPACE\}/g, ' ')) as [
    string,
    Operator,
    string,
    string
  ];

  const definition = definitions.find((definition) => [id, value].includes(definition.id));

  if (!definition) return;

  if (!Object.values(Operators).includes(Operators[operator]) || (!OP_NO_VALUE.includes(operator) && !value)) {
    return;
  }

  let range: FilterState<D>['range'] | undefined;

  if (operator === Operators.is_between) {
    const [min, max] = value.split('~', 2);

    if (min && max) {
      range = { min: parseValue(min, operator), max: parseValue(max, operator) };
    } else {
      return;
    }
  }

  const periodValue = period?.replace('period:', '');

  return {
    id: uid(),
    operator,
    definition,
    value: range || definition.type === 'segment' ? undefined : parseValue(value, operator),
    range,
    period: periodValue
  };
};

export const decode = <D extends Record<string, any>>(
  query: string,
  definitions: FilterDefinition<D>[]
): FilterState<D>[] => {
  const { filters } = qs.parse(query, { ignoreQueryPrefix: true }) as Record<string, string>;

  if (Array.isArray(filters)) {
    return filters.map((filter) => parse<D>(filter, definitions)).filter(Boolean) as FilterState<D>[];
  }

  return [];
};
