import React from 'react';

import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import { useGetAssumedContext } from '@components/AiChat/utils/useGetAssumedContext';
import { BetaFlag, Button, Text } from '@components/common';
import { FEATURE_DATA, FeatureType } from '@components/shared/FeatureBanner/constants';
import { Props as RestrictedButtonProps, RestrictedButton } from '@components/shared/RestrictedButton';
import { StudySlideOut } from '@components/StudiesApp/types';
import { CloseSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { useAccount } from '@hooks/useAccount';
import { useCreateStudy } from '@hooks/useCreateStudy';
import { useAiChat } from '@stores/aiChat';

const RestrictedOrNot: React.FC<React.PropsWithChildren<Partial<RestrictedButtonProps> & { restricted: boolean }>> = ({
  restricted,
  children,
  action,
  ...rest
}) => {
  if (restricted) {
    return (
      <RestrictedButton limit='studies' permission='createStudy' action={action || ''} {...rest}>
        {children}
      </RestrictedButton>
    );
  } else {
    return <>{children}</>;
  }
};

interface Props {
  source: 'dashboard' | 'dashboard_zds';
  setSlideOut?: (v: StudySlideOut | null) => void;
  type: FeatureType;
  dismissedFeatures: FeatureType[] | null;
  setDismissedFeatures: (v: FeatureType[]) => void;
  translateY?: number;
}

export const FeatureBanner: React.FC<React.PropsWithChildren<Props>> = ({
  dismissedFeatures,
  setDismissedFeatures,
  type,
  source,
  setSlideOut,
  translateY
}) => {
  const isDismissed = dismissedFeatures?.includes(type);

  const {
    bannerSidesWidth = ['7/12', '5/12'],
    isBeta,
    header,
    cta,
    guide_link,
    subheader,
    options,
    cta_url,
    slideout,
    video_url,
    createStudyProps
  } = FEATURE_DATA[type];

  const {
    account: { ai_enabled: aiEnabled }
  } = useAccount();

  const { setContext, setOpen, openWith, messages } = useAiChat();
  const assumed = useGetAssumedContext();
  const { pathname } = useLocation();

  const { createStudy, isCreating } = useCreateStudy();

  if (isDismissed) return null;

  const openAiSlideout = () => {
    track('open_ask_ai', { via: 'feature_banner', path: pathname, context: assumed ?? null });

    if (messages.length > 0) {
      setOpen(true);
    } else if (assumed) {
      openWith(assumed.page, assumed.context);
    } else {
      setContext(null);
      setOpen(true);
    }
  };

  const onClick = () => {
    if (aiEnabled && type === 'ai') {
      openAiSlideout();
    } else if (setSlideOut && slideout) {
      setSlideOut(slideout);
    } else if (createStudyProps) {
      createStudy(createStudyProps);
    }
  };

  const handleDismiss = () => {
    track(`clicked_${type}_dismiss_button`, { page: source });
    setDismissedFeatures([...(dismissedFeatures || []), type]);
  };

  const getUrl = () => {
    switch (type) {
      case 'ai':
        return aiEnabled ? undefined : cta_url;
      default:
        return cta_url;
    }
  };

  const getCtaText = () => {
    switch (type) {
      case 'ai':
        return aiEnabled ? cta : 'Enable AI';
      default:
        return cta;
    }
  };

  return (
    <section
      className={cn(
        `min-h-full w-full transform rounded-md border border-gray-200 bg-indigo-50`,
        translateY && `-translate-y-${translateY}`
      )}
      data-testid={`feature-banner-${type}`}
    >
      <div className='flex items-start'>
        <div className='desktop:flex-row desktop:justify-between desktop:space-y-0 flex w-full flex-col items-center space-y-4 p-6'>
          <div className={`relative w-full desktop:w-${bannerSidesWidth[0]}`}>
            <div className='mb-2 mr-6 flex items-center space-x-2'>
              <h4>{header}</h4>
              {isBeta && <BetaFlag color='indigo-600' />}
            </div>
            <Text className='mb-6'>{subheader}</Text>
            <ul className='mb-6 ml-6 list-disc'>
              {options.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
            {cta && (
              <div className='tablet:flex-row tablet:space-y-0 tablet:space-x-2 desktop:space-x-4 flex flex-col items-center space-y-2 whitespace-nowrap'>
                <RestrictedOrNot restricted={!!createStudyProps} action={getCtaText()}>
                  <Button
                    trackEvent={`clicked_${type}_feature_banner_cta`}
                    trackProps={{ page: source, cta: cta }}
                    primary
                    href={getUrl()}
                    className='tablet:w-auto w-full'
                    disabled={isCreating}
                    loading={isCreating}
                    onClick={onClick}
                  >
                    {getCtaText()}
                  </Button>
                </RestrictedOrNot>
                <Button className='tablet:w-auto w-full' data-testid='dismiss-button' onClick={handleDismiss}>
                  Dismiss
                </Button>
                {guide_link && (
                  <Button
                    className='tablet:w-auto w-full'
                    trackEvent={`clicked_${type}_help_link`}
                    trackProps={{ page: source }}
                    link
                    iconSuffix='externalLink'
                    target='_blank'
                    href={guide_link}
                  >
                    Read help guide
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className={`mx-8 flex w-full items-center justify-center desktop:w-${bannerSidesWidth[1]} rounded-md`}>
            {video_url ? (
              <video
                aria-label='Feature banner video'
                controls
                src={video_url}
                className='h-full max-w-full rounded-md'
              />
            ) : (
              <img
                className='h-full max-w-full'
                alt=''
                src={`/images/feature-banners/${type}.png`}
                srcSet={`/images/feature-banners/${type}@2x.png 2x, /images/feature-banners/${type}@3x.png 3x`}
              />
            )}
          </div>
        </div>
        <button
          aria-label='Dismiss feature banner'
          className='absolute right-6 top-6 text-gray-700 hover:text-indigo-500'
          onClick={handleDismiss}
        >
          <CloseSVG className='h-4 w-4' />
        </button>
      </div>
    </section>
  );
};
