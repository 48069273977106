import React from 'react';

import { format as timeagoFormat } from 'timeago.js';

import { Avatar, InfoLabel, Text } from '@components/common';
import { useRenderTagsList } from '@components/RepositoryApp/hooks/useRenderTagsList';
import { formatDuration } from '@components/shared/Artifact/components/utils';
import { AskAiSVG } from '@components/svgs';
import { getHighlightDisplayTitle } from '@components/utils';

interface Props {
  clip: Pick<Clip, 'created_at' | 'from' | 'to' | 'tag_ids' | 'title'>;
  renderActions: () => React.ReactElement;
  creator?: TeamUser | null;
  ai?: boolean | null;
  sessionTitle?: string;
  renderTranscript: () => React.ReactElement;
}

export const Highlight: React.FC<React.PropsWithChildren<Props>> = ({
  renderTranscript,
  sessionTitle,
  clip,
  renderActions,
  creator,
  ai
}) => {
  const { renderTagsList } = useRenderTagsList();

  return (
    <div className='rounded-md border border-gray-200 p-4'>
      {renderActions()}
      <Text bold className='mt-1 pr-4'>
        {getHighlightDisplayTitle({ clip } as DocumentHighlight)}
      </Text>
      <div className='mt-1 flex flex-wrap items-center'>
        <div className='mr-3 mt-1 flex flex-nowrap items-center space-x-1 py-px'>
          {ai ? (
            <>
              <AskAiSVG className='mr-1 h-4 w-4' />
              <Text h='400' className='leading-5'>
                Great Question AI
              </Text>
            </>
          ) : (
            creator && (
              <>
                <Avatar user={creator} size='sm' />
                <Text color='gray-500' h='200'>
                  {creator?.name}
                </Text>
              </>
            )
          )}
        </div>
        {sessionTitle && <InfoLabel h='200' className='mr-3 mt-1 text-gray-500' icon='checklist' text={sessionTitle} />}
        {clip.created_at && (
          <InfoLabel
            h='200'
            className='mr-3 mt-1 text-gray-500'
            icon='changedAt'
            text={timeagoFormat(clip.created_at)}
          />
        )}
        <InfoLabel
          h='200'
          className='mr-3 mt-1 text-gray-500'
          icon='clock'
          text={formatDuration(Math.round(clip.to / 1000 - clip.from / 1000))}
        />
      </div>

      <section className='mt-4 border-l-2 border-gray-200 pl-2'>{renderTranscript()}</section>

      <div className='mt-3'>{renderTagsList(clip.tag_ids || [], { max: 6 })}</div>
    </div>
  );
};
