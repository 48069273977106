import * as React from 'react';

import { AlgoliaData, useSearch } from '@components/shared/Search';
import { Grid } from '@components/shared/Skeleton';
import { TableSkeleton } from 'components/shared/GridTable/components';
import { Paginator } from 'components/shared/Paginator';
import { useFeature } from 'hooks/useFeature';

import { PAGE_SIZE } from '../hits/artifacts';
import { Count } from '../hits/artifacts/Count';

type Props = {
  layout: LayoutMode;
};

export const RepositorySkeletonBody: React.FC<React.PropsWithChildren<Props>> = ({ layout }) => {
  const hasPaginatedRepository = useFeature('paginated_repository');
  const { data, prevPage, nextPage, page } = useSearch<AlgoliaData>();
  const totalCount = data?.nbHits ?? 0;
  const totalPages: number = totalCount > 0 ? Math.ceil(totalCount / PAGE_SIZE) : 0;

  return (
    <>
      <div className='mb-4 flex items-center'>
        <div className='flex-1'>
          <Count />
        </div>
        {hasPaginatedRepository && (
          <Paginator
            onClickNext={nextPage}
            onClickPrevious={prevPage}
            canClickNext={false}
            canClickPrevious={false}
            current={page + 1}
            total={totalPages}
          />
        )}
      </div>
      {layout === 'list' ? (
        <TableSkeleton data-testid='repository-table-skeleton' className='min-h-screen w-full' />
      ) : (
        <Grid cardsNumber={3} monitor={5} desktop={3} tablet={2} mobile={2} tiny={1} height={384} />
      )}
    </>
  );
};
