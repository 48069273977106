import * as React from 'react';

import classNames from 'classnames';

import { CheckMark2SVG } from 'components/svgs';

interface Props {
  sched: Sched;
  setSched: (s: Sched) => void;
}
const ArrowSVG = () => (
  <svg width='51' height='52' viewBox='0 0 51 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.71112 41.6991C6.16269 39.2887 6.60539 36.9776 7.03925 34.6665C7.12779 34.2061 7.18977 33.8811 7.20748 33.2853C7.22518 32.8429 7.24289 31.904 6.77362 31.9853C6.39289 32.0936 6.13612 32.22 5.90591 33.0054C5.32154 35.0366 5.03821 37.1401 4.74602 39.2255C4.48925 41.0672 4.32102 42.4755 4.18821 44.1186C4.12623 44.886 4.4981 45.1929 5.18873 45.1026C5.80852 45.0214 6.44602 44.9491 7.03925 44.7505C9.18196 44.0464 11.2981 43.288 13.432 42.5478C13.6976 42.4575 13.9101 42.4033 14.2111 42.2498C14.5741 42.0603 14.8486 41.9429 14.7424 41.5547C14.645 41.1846 14.1137 41.2026 13.8481 41.2658C11.6169 41.7443 7.03039 42.8096 6.77362 42.8096C7.51737 41.6269 8.18144 40.5165 8.90748 39.4422C13.9986 31.9853 19.5679 24.9616 26.7043 19.3734C32.3533 14.9498 38.4184 11.2846 45.0325 8.57622C45.4221 8.41372 45.8028 8.21511 46.1658 8.0165C46.6616 7.75469 46.8741 7.529 46.759 7.25817C46.4757 6.77969 46.095 6.86997 45.7851 6.91511C45.1653 6.99636 44.5543 7.21303 43.9611 7.42067C36.0543 10.2825 29.1304 14.8325 22.7642 20.3304C16.2299 25.9728 11.2007 32.87 6.72935 40.2276C6.508 40.6248 5.8705 41.5547 5.71112 41.6991Z'
      fill='#19DB7E'
    />
  </svg>
);

export const SchedToggle: React.FC<React.PropsWithChildren<Props>> = ({ sched, setSched }) => {
  const classes = (active: boolean, className?: string) =>
    classNames(
      'cursor-pointer py-2 text-xs font-medium rounded-full px-6',
      'whitespace-nowrap relative border w-1/2',
      'focus:outline-none focus:z-10',
      {
        'text-gray-700 bg-white border-gray-200 shadow-sm': active,
        'text-gray-400 border-transparent': !active
      },
      className
    );

  return (
    <div className='tablet:mt-16 relative mt-3 flex self-center rounded-full bg-gray-200 p-1'>
      <div className='tablet:block absolute bottom-full left-full hidden w-48'>
        <div className='-ml-2 flex flex-row'>
          <ArrowSVG />
          <p className='-mt-1 text-sm font-bold uppercase' style={{ color: '#19DB7E' }}>
            (2 months free)
          </p>
        </div>
      </div>
      <a onClick={() => setSched('monthly')} title='Monthly' className={classes(sched === 'monthly')}>
        <CheckMark2SVG className={classNames('absolute left-3 top-3 h-2 w-2', sched === 'annual' && 'invisible')} />
        <span>Monthly</span>
      </a>
      <a
        onClick={() => setSched('annual')}
        title='Annually'
        className={classes(sched === 'annual', 'ml-0.5 flex items-center rounded-full px-6 py-2')}
      >
        <CheckMark2SVG className={classNames('absolute left-3 top-3 h-2 w-2', sched === 'monthly' && 'invisible')} />{' '}
        <span>Annually</span>
      </a>
    </div>
  );
};
