import React, { useCallback, useRef, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { api } from '@api/reduxApi';
import { Option } from '@components/common';
import { SelectDropdown } from '@components/shared/SelectDropdown';

import { CellProps } from '../../Table/types';

type Props<D> = Omit<CellProps<D, string>, 'renderValue' | 'getValue'> & {
  attrId?: number;
  value: any;
  setValue: (value: any) => void;
};

export const CustomAttrsCell = <D extends Record<string, any>>(props: Props<D>) => {
  const { type = 'text', table, row, column, cell, value: initialValue, setValue, render, attrId } = props;
  const [query, setQuery] = useState<string>('');
  const [localValue, setLocalValue] = useState<string | string[]>(initialValue);

  const [editMode, setEditMode] = useState<boolean>(false);

  const { callback: debouncedSetQuery } = useDebouncedCallback(setQuery, 500);

  const {
    data: options = [],
    isLoading,
    isFetching
  } = api.useGetCandidateAttrsSuggestionsQuery(
    {
      id: attrId,
      q: query
    },
    { skip: !editMode }
  );

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleOnBlur = (selectedOptions: Option[]) => {
    const noValues = !localValue?.length && !selectedOptions?.length;
    const currentValue = Array.isArray(localValue) ? [...localValue] : [];
    const newValue = selectedOptions?.map(({ value }) => value);

    const valuesAreEqualArrays =
      Array.isArray(currentValue) &&
      Array.isArray(newValue) &&
      JSON.stringify(currentValue.sort()) === JSON.stringify(newValue.sort());

    if (noValues || valuesAreEqualArrays) {
      setEditMode(false);
      return;
    }

    setLocalValue(newValue);
    setValue(newValue);
    setEditMode(false);
  };

  const handleCellUpdate = (newValue: any) => {
    setLocalValue(newValue);
    setValue(newValue);
  };

  const itemsToChoose = options.map((v) => ({ value: v, label: v }));

  const selected = Array.isArray(localValue)
    ? localValue.map((v) => ({ value: v, label: v }))
    : [
        {
          value: localValue,
          label: localValue
        }
      ];

  const renderEdit = useCallback(() => {
    switch (type) {
      case 'text':
        return (
          <SelectDropdown
            loading={isLoading || isFetching}
            options={itemsToChoose ?? []}
            value={localValue ? selected : []}
            onInputValueChange={debouncedSetQuery}
            onChange={(newValue) => handleCellUpdate(newValue[0].value)}
            overrides={{
              Input: {
                props: {
                  placeholder: 'Search...',
                  autoFocus: true
                }
              }
            }}
            onClose={(_, inputValue) => {
              if (inputValue === '') {
                handleCellUpdate(null);
              }
              setEditMode(false);
            }}
            onCreate={({ value }) => {
              handleCellUpdate(value);
              setEditMode(false);
            }}
            defaultIsOpen
            creatable
          />
        );
      case 'multiple_choice':
        return (
          <SelectDropdown
            loading={isLoading || isFetching}
            options={itemsToChoose ?? []}
            value={localValue ? selected : []}
            onInputValueChange={debouncedSetQuery}
            onClose={handleOnBlur}
            overrides={{
              Input: {
                props: {
                  placeholder: 'Enter…',
                  autoFocus: true
                }
              },
              Item: {
                props: {
                  className: 'xx-combo-option bg-white'
                }
              }
            }}
            onCreate={({ value }) => {
              handleCellUpdate([value]);
              setEditMode(false);
            }}
            creatable
            multi
            defaultIsOpen
            keepSelectedOptions
            closeOnSelect={false}
          />
        );
      default:
        return null;
    }
  }, [localValue, options]);

  const renderValue = useCallback(() => {
    if (localValue == null || localValue === '') return '-';

    if (Array.isArray(localValue)) {
      return localValue.join(', ');
    }

    return localValue;
  }, [localValue]);

  return (
    <div
      className='inline-flex h-full w-full items-center'
      onClick={() => !editMode && setEditMode((editMode) => !editMode)}
    >
      {!editMode ? (
        <span className='truncate px-2'>{renderValue()}</span>
      ) : (
        <div className='w-full bg-white' ref={wrapperRef}>
          {renderEdit()}
        </div>
      )}
    </div>
  );
};
