import * as React from 'react';

export const CheckCircleIcon: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '' }) => (
  <svg
    className={`stroke-current ${className}`}
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.5 12.1211L7.74583 15.3083C7.83102 15.4356 7.94537 15.5407 8.07936 15.6148C8.21335 15.6889 8.36311 15.73 8.51617 15.7346C8.66924 15.7391 8.82118 15.7071 8.95936 15.6411C9.09753 15.5751 9.21795 15.477 9.31058 15.3551L16.5 6.259'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.6875 10.9991C0.6875 13.7341 1.77399 16.3572 3.70796 18.2911C5.64193 20.2251 8.26496 21.3116 11 21.3116C13.735 21.3116 16.3581 20.2251 18.292 18.2911C20.226 16.3572 21.3125 13.7341 21.3125 10.9991C21.3125 8.26404 20.226 5.64101 18.292 3.70705C16.3581 1.77308 13.735 0.686584 11 0.686584C8.26496 0.686584 5.64193 1.77308 3.70796 3.70705C1.77399 5.64101 0.6875 8.26404 0.6875 10.9991V10.9991Z'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
