import React from 'react';

import { Alert, AlertHeading, AlertMessage, Button } from '@components/common';

import { FUND_STUDY } from '../../ACTIONS';
import { AlertProps } from '../types';

type AlertMessage = {
  heading: string;
  message: string;
};

const alertMessages: { [key: string]: AlertMessage } = {
  unfunded_open_slots: {
    heading: 'Please fund your incentives',
    message: 'You have open slots that aren’t funded. Funding is required before you can send invites.'
  },
  unfunded_completed_slots: {
    heading: 'Please fund your incentives',
    message: 'You have completed participants that aren’t funded. Funding is required before you can send incentives.'
  },
  unfunded_balance: {
    heading: 'Please fund your incentives',
    message: 'You have incentives that aren’t funded. Funding is required before screened participants can join.'
  }
};

function getAlert(slots: StudySlotsBalance, study: Study): AlertMessage | null {
  if (slots.complex) {
    return alertMessages.unfunded_balance;
  }

  if (slots.target > slots.funded) {
    return alertMessages.unfunded_open_slots;
  }

  if (slots.issued > slots.funded) {
    return alertMessages.unfunded_completed_slots;
  }

  return alertMessages.unfunded_balance;
}

export const MustFund: React.FC<React.PropsWithChildren<AlertProps>> = ({ slots, study, openFundModal, canAction }) => {
  const alert = getAlert(slots, study);

  if (!alert) return null;

  return (
    <Alert
      className='my-4'
      type={study.can_delay_funding ? 'warning' : 'error'}
      cta={
        canAction && (
          <Button onClick={() => openFundModal?.()} primary>
            Fund now
          </Button>
        )
      }
    >
      <>
        <AlertHeading>{alert.heading}</AlertHeading>
        <AlertMessage>{alert.message}</AlertMessage>
      </>
    </Alert>
  );
};
