import * as React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { GovernanceApp } from '@components/GovernanceApp';
import { Container } from '@components/layouts/Container';
import { NavShortcuts } from '@components/NavShortcuts';
import { SegmentsIndex } from '@components/SegmentsApp/SegmentsIndex';
import { TagManagerPage } from '@components/TagManager/components/TagManagerPage';
import { renderComp } from '@components/utils';
import { BrandingApp } from 'components/BrandingApp';

import { AccountNotificationsApp } from './AccountNotificationsApp/AccountNotificationsApp';
import { AccountTeamsApp } from './AccountTeamsApp/AccountTeamsApp';
import { AccountUsersApp } from './AccountUsersApp/AccountUsersApp';
import { AiSettingsApp } from './AiSettingsApp/AiSettingsApp';
import { AccountAttrsApp } from './AccountAttrsApp';
import { AppLoader } from './AppLoader';
import { TrackRouteChange } from './TrackRouteChange';
import { WalletsIndex } from './Wallet';

export const SettingsRouter = () => {
  return (
    <Router>
      <AppLoader hideLoader>
        <TrackRouteChange />
        <NavShortcuts />
        <Container>
          <Routes>
            <Route path='custom_attrs/*' element={<AccountAttrsApp />} />
            <Route path='teams/:id/branding' element={<BrandingApp />} />
            <Route path='teams/*' element={<AccountTeamsApp />} />
            <Route path='notifications/*' element={<AccountNotificationsApp />} />
            <Route path='accounts/*' element={<AccountUsersApp />} />
            <Route path='invitations/*' element={<AccountUsersApp />} />
            <Route path='ai_preferences' element={<AiSettingsApp />} />
            <Route path='candidate_segments' element={<SegmentsIndex />} />
            <Route path='tags' element={<TagManagerPage fullScreen={false} withPaddings={false} />} />
            <Route path='branding' element={<BrandingApp />} />
            <Route path='governance/*' element={<GovernanceApp />} />
            <Route path='wallet/wallets' element={<WalletsIndex />} />
          </Routes>
        </Container>
      </AppLoader>
    </Router>
  );
};

export function mount(): void {
  renderComp('SettingsApp', () => <SettingsRouter />);
}
