import React from 'react';

import { MasterCardSVG, VisaSVG } from '@components/svgs';

export const CardDetails: React.FC<React.PropsWithChildren<{ card: CardPaymentMethod }>> = ({ card }) => (
  <div className='flex flex-row items-center space-x-2'>
    {card.brand.toLowerCase() === 'visa' && <VisaSVG className='mr-2 inline h-5 w-5' />}
    {card.brand.toLowerCase() === 'mastercard' && <MasterCardSVG className='mr-2 inline h-5 w-5' />}
    <span>
      {card.brand} ending in {card.last4}
    </span>
  </div>
);
