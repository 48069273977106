import { useRef } from 'react';

import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';

import {
  aiBotApi,
  useAcceptBotSuggestionMutation,
  useLazyGetBotSuggestionsQuery,
  useRejectBotSuggestionMutation
} from '../api';
import { AI, UseLazySuggestionsHookResult, UseSuggestionsHookArgs } from '../types';

export const useLazySuggestions = <
  TData extends AI.GenericData = AI.GenericData,
  TContext extends Record<string, any> = Record<string, any>
>(
  { id, context }: UseSuggestionsHookArgs<TContext>,
  options?: AI.UseQueryOptions<TContext>
): UseLazySuggestionsHookResult<TData> => {
  const fetchRef = useRef<AI.FetchResult<TData, TContext>>();

  const [fetch, { data, isFetching, isError }] = useLazyGetBotSuggestionsQuery<TData, TContext>(options);
  const [acceptSuggestion] = useAcceptBotSuggestionMutation();
  const [rejectSuggestion] = useRejectBotSuggestionMutation();

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const { clearOnAcceptOrReject = true, retryOnAcceptOrReject = false } = options ?? {};

  const fetchSuggestions = (providedContext?: Partial<TContext>) => {
    fetchRef.current = fetch({ id, context: { ...context, ...providedContext } as TContext });

    return fetchRef.current;
  };

  const retry = async () => {
    if (isFetching) {
      stop();
      requestAnimationFrame(() => fetchSuggestions());

      return;
    }

    if (fetchRef.current) {
      fetchRef.current = fetchRef.current.refetch();
    }
  };

  const clearSuggestions = async () => {
    // invalidating tags will cause a refetch, and in this case we just want to clear the data
    dispatch(aiBotApi.util.upsertQueryData('getBotSuggestions', { id, context }, null));
  };

  const accept = async (id: number) => {
    if (clearOnAcceptOrReject) {
      clearSuggestions();
    }

    await acceptSuggestion(id).unwrap();

    if (retryOnAcceptOrReject) {
      retry();
    }
  };

  const reject = async (id: number) => {
    if (clearOnAcceptOrReject) {
      clearSuggestions();
    }

    await rejectSuggestion(id).unwrap();

    if (retryOnAcceptOrReject) {
      retry();
    }
  };

  const stop = () => {
    if (fetchRef.current) {
      fetchRef.current.abort();
    }

    requestAnimationFrame(clearSuggestions);
  };

  return {
    fetch: fetchSuggestions,
    accept,
    reject,
    retry,
    stop,
    clear: clearSuggestions,
    value: data,
    isLoading: isFetching,
    isError
  };
};
