import React, { forwardRef, HTMLAttributes } from 'react';

import { Draggable, Droppable } from 'react-beautiful-dnd';

import { api } from '@api/reduxApi';
import { Heading, Loading, Text } from '@components/common';
import { ArtifactHit } from '@components/RepositoryApp/types';
import { track } from '@components/tracking';
import { move } from 'helpers/move';

import { useHighlightReelsContext } from '../../../hooks/useHighlightReelsContext';

import { Clip } from './Clip';
import { Player } from './Player';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {}

export const Preview = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { artifactIds, setArtifactIds, playerIsLoading } = useHighlightReelsContext();
  const { data: artifacts = [], isLoading } = api.useGetArtifactsQuery(artifactIds);

  const sortedArtifacts: ArtifactHit[] = artifactIds.reduce((acc, clipId) => {
    const artifact = artifacts.find(({ objectID }) => objectID === clipId);
    return [...acc, ...(artifact ? [artifact] : [])];
  }, []);

  const handChangeOrder = (index: number, newIndex: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (playerIsLoading) return;

    if (newIndex === artifactIds.length || newIndex < 0) return;
    setArtifactIds(move<string>(artifactIds, index, newIndex));
  };

  return (
    <section ref={ref} {...props}>
      <div className='relative h-full flex-grow rounded-md border border-dashed border-indigo-600 bg-gray-50 p-6'>
        <Player className='mb-6 overflow-hidden rounded-md' />
        {isLoading ? (
          <Loading absolute />
        ) : (
          <>
            {artifacts.length === 0 && (
              <div className='text-center'>
                <Heading>Highlights</Heading>
                <Text className='mt-4 text-gray-700'>
                  Add highlights from the right to create a video highlight reel. You can re-order the highlights once
                  added.
                </Text>
              </div>
            )}

            <Droppable droppableId='clips'>
              {(droppableProvided) => (
                <div ref={droppableProvided.innerRef}>
                  {sortedArtifacts.map((artifact, index) => (
                    <Draggable
                      key={artifact.objectID}
                      draggableId={artifact.objectID}
                      index={index}
                      isDragDisabled={playerIsLoading}
                    >
                      {(draggableProvided) => (
                        <div
                          ref={draggableProvided.innerRef}
                          className='mb-6 bg-white'
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={draggableProvided.draggableProps.style}
                        >
                          <Clip
                            hit={artifact}
                            isActive
                            moveDown={handChangeOrder(index, index + 1)}
                            moveUp={handChangeOrder(index, index - 1)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </>
        )}
      </div>
    </section>
  );
});
