import * as React from 'react';

import { Route, Routes } from 'react-router-dom';

import { SessionPage } from './SessionPage';

export const RepoSessionApp: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Routes>
    <Route path=':uuid*' element={<SessionPage />} />
  </Routes>
);
