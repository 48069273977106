import { format, isAfter, isSameWeek } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import tinytime from 'tinytime';

import { addDaysToDate, closest, last, startOfWeek, uniqueId } from 'components/utils';

const yyyymmdd = tinytime('{YYYY}-{Mo}-{DD}');

export const buildSlotInstance = (date: string, time: string, duration: number): SlotInstance => ({
  id: `${time}${duration}`,
  day: getDateFromString(date).getDay() as Slot['day'],
  date,
  time,
  duration
});

export const slotFromKey = (key: string): Slot => {
  const [d, time] = key.split('_');

  return {
    date: d,
    day: getDateFromString(d).getDay() as Slot['day'],
    time
  };
};

export const keyFromSlot = (slot: Omit<Slot, 'day'>): string => {
  return [slot.date, slot.time].join('_');
};

export type HourMin = { hour: number; min: number };
export const getHourMin = (time: string): HourMin => {
  const [hour, min] = time.split(':');
  return {
    hour: parseInt(hour),
    min: parseInt(min)
  };
};

export const getHours = (time: string): number => {
  const [hour, min] = time.split(':');
  return parseInt(hour) + parseInt(min) / 60;
};

export const getTime = (hourMin: HourMin): string => {
  return [String(hourMin.hour).padStart(2, '0'), String(hourMin.min).padStart(2, '0')].join(':');
};

export const setHour = (hour: number) => {
  const d = new Date();
  d.setHours(hour, 0, 0, 0);
  return d;
};

export const getAllTimesBetween = (startHour: number, endHour: number, increment = 30): string[] => {
  const start = setHour(startHour);
  const end = setHour(endHour);
  const times: string[] = [];

  while (start < end) {
    times.push(start.toTimeString().substring(0, 5));
    start.setMinutes(start.getMinutes() + increment);
  }
  return times;
};

function inLocalTime(date: string) {
  // annoying - Date.parse() accepts non-padded digits like "2021-1-1" BUT not with a tz
  // so make sure the digits are padded bcause we need this date to parse in the user's local TZ
  // otherwise Date.parse does UTC by default
  const [y, m, d] = date.split('-');
  const newDate = [y, m.padStart(2, '0'), d.padStart(2, '0')].join('-');
  return `${newDate}T00:00:00`; // local tz
}

export const getDateFromString = (date: string): Date => new Date(Date.parse(inLocalTime(date)));
export const getStringFromDate = (date: Date): string => yyyymmdd.render(date);

// Prune out invalid slots and combine overlapping/touching slots
// Call on every state update (except for dragging/resizing slots)
export const fixSlots = (slots: SlotInstance[]): SlotInstance[] => {
  const newSlots: SlotInstance[] = [];

  const slotsByDay = slots.reduce<Record<string, SlotInstance[]>>((acc, val) => {
    const key = val.single ? val.date : val.day;
    const vals = acc[key];
    return { ...acc, [key]: vals ? [...vals, val] : [val] };
  }, {});

  const days = Object.keys(slotsByDay);
  for (const day of days) {
    const slots = slotsByDay[day];
    slots.sort((a, b) => a.time.localeCompare(b.time));

    let marker = -1; // marker hour should only move forward as we scan over events
    let lastSlot: SlotInstance | null = null;
    for (const slot of slots) {
      if (slot.duration <= 0) continue;

      const hours = getHours(slot.time);
      if (hours <= marker && lastSlot) {
        // this one overlaps lastSlot
        const endTime = hours + slot.duration / 60;
        const lastSlotEndTime = getHours(lastSlot.time) + lastSlot.duration / 60;
        if (lastSlotEndTime < endTime) {
          lastSlot.duration += (endTime - lastSlotEndTime) * 60;
          marker = lastSlotEndTime;
        }
        continue;
      }
      marker = hours + slot.duration / 60;
      lastSlot = { ...slot };
      newSlots.push(lastSlot);
    }
  }

  return newSlots;
};

export const getNylasEventTimezoneOffset = (timezone: string, startDate?: number) => {
  if (!startDate) return NaN;

  const localOffset = new Date().getTimezoneOffset() * -60 * 1000;
  // converting to string and using getDateFromString to leverage from inLocalTime conversion
  const tzOffset = getTimezoneOffset(timezone, getDateFromString(format(startDate * 1000, 'yyyy-M-d')));

  return localOffset - tzOffset;
};

const WEEKDAYS = [0, 1, 2, 3, 4];

export const rawNylasEventToSlotInstance = (userId: number, event: NylasEvent): NylasSlotInstance | undefined => {
  if (event.when?.object !== 'timespan') {
    return;
  }

  const date = new Date((event.when.start_time as any) * 1000);
  const duration = ((event.when.end_time as any) - (event.when.start_time as any)) / 60;

  return {
    id: event.id || '',
    user_id: userId,
    calendar_id: event.calendar_id || '',
    date: getStringFromDate(date),
    duration,
    day: date.getDay() as NylasSlotInstance['day'],
    busy: event.busy,
    title: event.title || '',
    description: event.description,
    guests: event.participants,
    owner: event.owner,
    when: event.when,
    time: getTime({ hour: date.getHours(), min: date.getMinutes() }),
    recurrence: event.recurrence
  };
};

const calAvailableSlots = (
  startDate: string,
  endDate: string | null,
  startTime: string,
  duration: number
): CalenderAvailableSlots => ({
  start_date: startDate,
  end_date: endDate,
  slots: WEEKDAYS.map((i) => ({
    id: uniqueId(),
    duration: duration * 60,
    time: startTime,
    day: (i + 1) as any,
    date: getStringFromDate(addDaysToDate(startOfWeek(getDateFromString(startDate)), i))
  }))
});

export const updateAllSlots = (
  slots: CalenderAvailableSlots,
  startTime: string,
  endTime: string
): CalenderAvailableSlots => {
  if (startTime === null || endTime === null) {
    return { ...slots, slots: [] };
  } else {
    const parsedStartTime = getHours(startTime);
    const parsedEndTime = getHours(endTime);

    const duration = parsedEndTime - parsedStartTime;

    return calAvailableSlots(slots.start_date, slots.end_date, startTime, duration);
  }
};

export const buildInitialCalAvailableSlots = (study: Study): CalenderAvailableSlots => {
  const startDate = study.cal_available_slots ? getDateFromString(study.cal_available_slots.start_date) : new Date();
  const monday = startOfWeek(startDate);

  return {
    start_date: getStringFromDate(monday),
    end_date: study.continuous ? null : getStringFromDate(addDaysToDate(monday, 14)),
    slots: WEEKDAYS.map((i) => ({
      id: uniqueId(),
      duration: 8 * 60,
      time: '09:00',
      day: (i + 1) as any,
      date: getStringFromDate(addDaysToDate(monday, i))
    }))
  };
};

// snaps all the slots into something displayable, so events before/after the startHour/endHour are clipped
// and weird start times/durations are snapped to the increment min grid
export type NormalizedSlot<T extends SlotInstance> = T & { original: T };

export const normalizeSlots = <T extends SlotInstance>(
  slots: T[],
  startHour: number,
  endHour: number,
  increment = 30
): NormalizedSlot<T>[] => {
  const timeIncrement = Array.from({ length: 60 / increment }, (_, i) => i * increment);
  return slots
    .map((slot) => {
      let { duration } = slot;
      let { hour, min } = getHourMin(slot.time);
      if (!timeIncrement.includes(min)) {
        min = closest(min, timeIncrement);
      }
      if (hour < startHour) {
        if (hour + duration / 60 < startHour) {
          return { ...slot, duration: 0, original: slot };
        }

        duration = duration - (startHour - hour) * 60 + min;
        min = 0;
        hour = startHour;
      }

      const hours = hour + min / 60;

      if (hours + duration / 60 > endHour) {
        duration = (endHour - hours) * 60;
      }

      duration = Math.ceil(duration / increment) * increment;
      return { ...slot, time: getTime({ hour, min }), duration, original: slot };
    })
    .filter((slot) => slot.duration > 0);
};

export const isFreeAt = (slots: SlotInstance[], hour: number, min: number, eventDuration: number = 0): boolean => {
  return slots.every((e) => {
    const slotDuration = e.duration;
    const slotStartHours = getHours(e.time);
    const slotEndHours = slotStartHours + slotDuration / 60;

    const eventHourStart = hour + min / 60;
    const eventHourEnd = eventHourStart + eventDuration / 60;

    const slotStartsAndEndsBeforeEvent = slotStartHours < eventHourStart && slotEndHours <= eventHourStart;
    const slotStartsAfterEvent = slotStartHours >= eventHourEnd;

    return slotStartsAndEndsBeforeEvent || slotStartsAfterEvent;
  });
};

export const getEventBlockHeight = ({
  duration,
  rungHeight,
  increment
}: {
  duration: number;
  rungHeight: number;
  increment: number;
}): number => rungHeight * (duration / increment);

const RUNG_STYLES = {
  15: { height: 12, className: 'h-3', textClassName: '' },
  30: { height: 24, className: 'h-6', textClassName: '' },
  45: { height: 12, className: 'h-3', textClassName: '' }
};

export const getRungStyles = (increment = 30): RungStyles => RUNG_STYLES[increment];

const DAY_IN_SECONDS = 60 * 60 * 24;

const getStartOfNextDay = (time = 0) => {
  const offset = new Date().getTimezoneOffset() * 60;
  return Math.ceil((time + 1 - offset) / DAY_IN_SECONDS) * DAY_IN_SECONDS + offset;
};

const extendsBeyondDayBoundary = (event: NylasEvent) =>
  event.when?.start_time &&
  event.when?.end_time &&
  getStartOfNextDay(event.when?.start_time) < getStartOfNextDay(event.when?.end_time);

export const splitMultiDayEvent = (event: NylasEvent, timezone): NylasEvent[] => {
  if (!event.when || !event.when.start_time || !event.when.end_time) {
    return [event];
  }

  const offset = getNylasEventTimezoneOffset(timezone, event.when.start_time);

  const events = [
    {
      ...event,
      when: {
        ...event.when,
        start_time: event.when.start_time - offset / 1000,
        end_time: event.when.end_time - offset / 1000
      }
    }
  ];

  for (let i = 0; i < 7 && extendsBeyondDayBoundary(last(events)); i++) {
    const evt = last(events);

    const startOfNextDay = getStartOfNextDay(evt.when?.start_time);

    events.push({
      ...evt,
      when: { ...evt.when, start_time: startOfNextDay }
    });
    if (evt.when) {
      evt.when.end_time = startOfNextDay;
    }
  }

  return events;
};

export const getActiveWeekIndex = (weeks: { label: string; startOfWeek: Date }[]) => {
  const activeWeekIndex = weeks.findIndex(({ startOfWeek }) =>
    isSameWeek(startOfWeek, new Date(), { weekStartsOn: 1 })
  );

  if (activeWeekIndex === -1) {
    const lastWeek = [...weeks].pop();

    if (lastWeek && isAfter(new Date(), lastWeek.startOfWeek)) {
      return weeks.length - 1;
    }

    return 0;
  }

  return activeWeekIndex;
};

export const addMinutesToTime = (time: string, minutesToAdd: number, use24hr: boolean = true): string => {
  const [hoursStr, minutesStr] = time.split(':');

  let hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  //Handle AM/PM in 12-hour format
  if (!use24hr) {
    const ampm = time.slice(-2).toUpperCase();
    if (ampm === 'PM' && hours !== 12) hours += 12;
    if (ampm === 'AM' && hours === 12) hours = 0; // Midnight case
  }

  const totalMinutes = hours * 60 + minutes + minutesToAdd;
  const newHours = Math.floor(totalMinutes / 60) % 24;
  const newMinutes = totalMinutes % 60;

  if (use24hr) {
    return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
  } else {
    const period = newHours >= 12 ? 'PM' : 'AM';
    const adjustedHours = newHours % 12 || 12; // Handle midnight (0) correctly
    return `${String(adjustedHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')} ${period}`;
  }
};

const compareElements = (a: string | Record<string, string[]>, b: string | Record<string, string[]>): number => {
  const getTime = (element: string | Record<string, string[]>): string | null => {
    if (typeof element === 'string') {
      return element;
    } else if (typeof element === 'object' && element !== null) {
      return Object.keys(element)[0]; // Directly return the time string
    }
    return null;
  };

  const timeA = getTime(a);
  const timeB = getTime(b);

  if (timeA === null || timeB === null) {
    return 0; //Handle null cases
  }

  return timeA.localeCompare(timeB); //Direct string comparison
};

export const mergeTimeSlots = (timeSlots: Timeslots, conflicts: Timeslots): Timeslots => {
  const merged: Timeslots = {};

  for (const date in timeSlots) {
    merged[date] = [...timeSlots[date], ...(conflicts[date] || [])];
  }

  for (const date in conflicts) {
    if (!merged[date]) {
      merged[date] = conflicts[date];
    }
  }

  // Sort the arrays for each date
  for (const date in merged) {
    merged[date].sort(compareElements);
  }

  return merged;
};

export const hasAtLeastOneAvailableSlot = (slots: DateWithSlotsAndConflicts): boolean => {
  return slots.some((slot) => {
    if (typeof slot === 'string') {
      return true;
    }

    // If the slot is an object, check if its value is not an array
    if (typeof slot === 'object') {
      const timeKey = Object.keys(slot)[0];
      return !Array.isArray(slot[timeKey]);
    }

    return true;
  });
};
