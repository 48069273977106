import * as React from 'react';

import { Text } from '@components/common';

interface Props extends React.HTMLProps<HTMLDivElement> {
  bold?: boolean;
  className?: string;
  optional?: boolean;
}

export const LabelText: React.FC<React.PropsWithChildren<Props>> = ({
  bold = true,
  children,
  className = undefined,
  optional = false,
  ...props
}) => {
  const classNames = bold ? `font-bold ${className}` : className;

  return (
    <Text className={optional ? 'mb-2' : 'mb-8'}>
      <span className={classNames}>{children}</span> {optional && '(Optional)'}
    </Text>
  );
};
