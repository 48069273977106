import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import { Loading } from '@components/common';
import { HighlightArtifact, PreviewContentSlideout } from '@components/RepositoryApp/components/PreviewContentSlideout';
import { ArtifactCard } from '@components/RepositoryApp/hits/artifacts/ArtifactCard';
import { ArtifactHit } from '@components/RepositoryApp/types';
import { useTiptapContext } from '@components/shared/Tiptap/hooks';
import { compact } from '@components/utils';
import { RestrictedArtifactCard } from 'components/RepositoryApp/hits/artifacts/RestrictedArtifactCard';
import { ArtifactAction } from 'components/shared/Artifact';

import { ArtifactsAttributes } from '../../extensions';
import * as Icons from '../../icons';

type Props = NodeViewProps & {
  isLoading: boolean;
  isPublic?: boolean;
  data?: ArtifactHit[];
};

export const Cards = ({ isLoading, data, isPublic, ...props }: Props): React.ReactElement => {
  const { artifacts, uuid } = props.node.attrs as ArtifactsAttributes;
  const isActive = props.editor.isActive('artifacts', { uuid });

  const { slideoutView } = useTiptapContext();

  const [previewSlideout, setPreviewSlideout] = useState<HighlightArtifact | null>(null);

  useEffect(() => {
    if (!isPublic && props.node.attrs.artifacts.length === 0) {
      props.editor.commands.deleteSelection();
    }
  }, [props.node.attrs.artifacts]);

  return (
    <NodeViewWrapper>
      <section className='relative' data-uuid={uuid}>
        {isLoading ? (
          <Loading absolute />
        ) : (
          <section
            className={cn(
              'flex rounded-md',
              isActive ? 'ring-indigo-600' : 'ring-transparent',
              slideoutView
                ? 'cursor-auto flex-col'
                : 'cursor-grab -mx-3 mb-4 flex-wrap ring-2 transition-shadow duration-150'
            )}
            data-drag-handle
          >
            {artifacts?.map((objectID) => (
              <div
                key={objectID}
                className={slideoutView ? 'mx-14 my-3' : 'basis-1/3 w-1/3 p-3'}
                draggable={!slideoutView}
              >
                {(() => {
                  const artifact = data?.find((artifact) => artifact.objectID === objectID);

                  if (artifact) {
                    return (
                      <ArtifactCard
                        {...artifact}
                        highlight={false}
                        page='embedded_artifacts'
                        actions={compact([
                          !slideoutView && !isPublic && (
                            <ArtifactAction
                              onClick={() =>
                                props.editor
                                  .chain()
                                  .focus()
                                  .updateAttributes('artifacts', {
                                    artifacts: (props.node.attrs.artifacts as ArtifactsAttributes['artifacts']).filter(
                                      (objectID) => objectID !== artifact.objectID
                                    )
                                  })
                                  .run()
                              }
                              icon={<Icons.Trash />}
                            >
                              Remove artifact
                            </ArtifactAction>
                          )
                        ])}
                        isPublic={isPublic}
                        isLink={!slideoutView && !isPublic}
                        setPreviewSlideout={slideoutView || isPublic ? undefined : setPreviewSlideout}
                      />
                    );
                  }
                  return <RestrictedArtifactCard />;
                })()}
              </div>
            ))}
          </section>
        )}
      </section>
      {previewSlideout && (
        <PreviewContentSlideout closeSlideout={() => setPreviewSlideout(null)} artifact={previewSlideout} />
      )}
    </NodeViewWrapper>
  );
};
