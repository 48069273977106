import React from 'react';

import { usePermission } from '@hooks/usePermission';

import { Banner } from './components';
import { MessageRequestsProps } from './types';

export const MessageRequests: React.FC<React.PropsWithChildren<MessageRequestsProps>> = ({
  className = '-mt-6',
  study,
  messageRequests,
  canAction,
  onSent
}) => {
  if (messageRequests?.length) {
    return (
      <div className={className}>
        {messageRequests.map((messageRequest) => (
          <Banner
            key={`message_request_${messageRequest.id}`}
            study={study}
            messageRequest={messageRequest}
            canAction={canAction}
            onSent={onSent}
          />
        ))}
      </div>
    );
  }
  return <></>;
};
