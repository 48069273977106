import * as React from 'react';
import { createContext, useContext, useReducer } from 'react';

import ReactDOM from 'react-dom';

const store = createContext<ConfigStore>({} as ConfigStore);

const { Provider: ProviderEl } = store;

export const Provider: React.FC<React.PropsWithChildren<{ initialState: ConfigStore['state'] }>> = ({
  initialState,
  children
}) => {
  return <ProviderEl value={{ state: initialState }}>{children}</ProviderEl>;
};

Provider.displayName = 'Config';
export default store;
