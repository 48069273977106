import { shuffle } from '@components/utils';

import * as Models from '../types/models';

export const parseAsCollapsedWithoutSelection = (node: Models.TreeItem, randomiseOrder: boolean): Models.TreeItem => {
  return {
    ...node,
    collapsed: true,
    selected: false,
    nodes: randomiseOrder
      ? shuffle(node.nodes.map((node) => parseAsCollapsedWithoutSelection(node, randomiseOrder)))
      : node.nodes.map((node) => parseAsCollapsedWithoutSelection(node, randomiseOrder))
  };
};
