import React, { FC, MouseEventHandler, useEffect, useState } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Hit } from '@algolia/client-search/dist/client-search';

import { Avatar } from '@components/common';
import { HighlightArtifact } from '@components/RepositoryApp/components/PreviewContentSlideout';
import { SortOption } from '@components/RepositoryApp/filters/sortOptions';
import { artifactRenderer } from '@components/RepositoryApp/helpers/artifactRenderer';
import { ArtifactAttributes, ArtifactHit } from '@components/RepositoryApp/types';
import { Artifact, ArtifactAction, Info, Props as ArtifactProps } from '@components/shared/Artifact';
import { ActionsBar } from '@components/shared/Artifact/components/ActionsBar';
import { TrashSVG } from '@components/svgs';
import { compact } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { useHover } from '@hooks/useHover';
import { usePermission } from '@hooks/usePermission';

import { Context } from '../../hooks/useRepositoryContext';

interface Props extends Hit<ArtifactHit> {
  canSelect?: boolean;
  isLink?: boolean;
  selectedArtifacts?: Context['selectedArtifacts'];
  setSelectedArtifacts?: Context['setSelectedArtifacts'];
  sort?: SortOption;
  highlight?: boolean;
  actions?: ArtifactProps['actions'];
  onDelete?: (h: Pick<ArtifactAttributes, 'href' | 'title' | 'kind' | 'model_id'>) => void;
  page?: 'dashboard' | 'data' | 'study_data' | 'embedded_artifacts' | 'insights' | 'embedded_artifacts_modal';
  setPreviewSlideout?: (v: HighlightArtifact) => void;
  isPublic?: boolean;
  onClickTag?: (params: string) => void;
}

export const ArtifactCard: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    canSelect,
    isLink = true,
    title,
    created_at,
    updated_at,
    study_ids = [],
    study_title,
    objectID,
    tag_ids,
    href,
    kind,
    duration,
    views,
    subtitle,
    selectedArtifacts = [],
    setSelectedArtifacts,
    sort,
    highlight = true,
    actions = [],
    onDelete,
    model_id,
    page,
    setPreviewSlideout,
    owner_id,
    candidate_name,
    isPublic,
    onClickTag
  } = props;

  const {
    getUserById,
    account: { team }
  } = useAccount();

  const canDelete = usePermission('canCreate')();

  const owner = getUserById(owner_id);

  const [selected, setSelected] = useState(selectedArtifacts.includes(objectID));

  const { hover, hoverRootProps } = useHover();

  const navigate = useNavigate();

  const updateSelectedArtifacts = (selected: boolean) => {
    if (selected) {
      setSelectedArtifacts?.([...selectedArtifacts, objectID]);
    } else {
      setSelectedArtifacts?.(selectedArtifacts.filter((id) => id !== objectID));
    }
  };

  const isPreviewable = (kind: ArtifactAttributes['kind']) =>
    ['Clip', 'Highlight', 'Repo::Session', 'HighlightReel', 'Story'].includes(kind);

  const onClickContent = (e, hit: ArtifactHit) => {
    e.stopPropagation();
    if (isPreviewable(hit.kind)) {
      setPreviewSlideout?.(hit as HighlightArtifact);
    } else {
      navigate(hit.href);
    }
  };

  const DeleteLink: React.FC<React.PropsWithChildren<{ closePopper?: MouseEventHandler<HTMLElement> }>> = ({
    closePopper
  }) => {
    const openModal = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onDelete?.({ href, title, kind, model_id });
    };

    switch (kind) {
      case 'Story':
        return (
          <ArtifactAction closePopper={closePopper} onClick={openModal} icon={<TrashSVG />} remove>
            Delete insight
          </ArtifactAction>
        );
      case 'Clip':
      case 'Highlight':
        return (
          <ArtifactAction closePopper={closePopper} remove onClick={openModal} icon={<TrashSVG />}>
            Delete highlight
          </ArtifactAction>
        );
      case 'HighlightReel':
        return (
          <ArtifactAction closePopper={closePopper} remove onClick={openModal} icon={<TrashSVG />}>
            Delete highlight reel
          </ArtifactAction>
        );
      default:
        return null;
    }
  };

  const artifactActions = compact([
    ['Story', 'Clip', 'Highlight', 'HighlightReel'].includes(kind) && onDelete && canDelete && <DeleteLink />,
    ...actions
  ]);

  useEffect(() => {
    updateSelectedArtifacts(selected);
  }, [selected]);

  useEffect(() => {
    setSelected(selectedArtifacts.includes(objectID));
  }, [selectedArtifacts.includes(objectID)]);

  return (
    <Artifact
      kind={kind}
      className='w-full'
      canSelect={canSelect}
      copyLink={isLink ? `${location.origin}${href}` : undefined}
      linkTo={isLink ? href : undefined}
      actions={artifactActions}
      handleClickContent={(e) => {
        if (page !== 'embedded_artifacts_modal') {
          onClickContent(e, props);
        }
      }}
      studyId={study_ids[0]}
      page={page}
      {...hoverRootProps}
    >
      <section className={cn('relative h-1/2 w-full')}>
        <div className='h-full w-full overflow-hidden'>
          {artifactRenderer(props, {
            highlight,
            hover
          })}
        </div>
      </section>
      <Info
        tag_ids={tag_ids}
        studyId={study_ids[0]}
        studyTitle={study_title}
        studyStyle={props.study_style}
        title={title}
        views={views}
        candidateName={candidate_name}
        duration={duration}
        subtitle={subtitle}
        createdAt={created_at ? new Date(created_at) : null}
        updatedAt={updated_at ? new Date(updated_at) : null}
        displayDate={sort === 'updated_at' ? 'updatedAt' : 'createdAt'}
        type={kind}
        avatar={owner ? <Avatar user={owner} {...props} /> : null}
        hasLinks={!isPublic}
        onPillClick={onClickTag}
      />

      <ActionsBar
        title={title}
        selected={selected}
        setSelected={setSelected}
        kind={kind}
        canSelect={canSelect}
        copyLink={isLink ? `${location.origin}${href}` : undefined}
        linkTo={isLink ? href : undefined}
        actions={artifactActions}
        page={page}
      />
    </Artifact>
  );
};
