import * as React from 'react';

import { DropdownCombobox, FormGroup, HelperText, Option, RadioGroup, Text } from '@components/common';
import {
  Filter,
  FilterInput,
  FilterStateLookup
} from '@components/StudiesApp/components/StudyPublished/pages/ExternalCandidatesRequest/components/FilterInput';

const compareTitles = (a, b) => {
  const titleA = a.title.toUpperCase();
  const titleB = b.title.toUpperCase();
  if (titleA < titleB) return -1;
  if (titleA > titleB) return 1;
  return 0;
};

interface Props {
  filtersInitialized: boolean;
  filtersObjects: ExternalCandidatesFilterListResponse[];
  filterIds: string[];
  disabled: boolean;
  setChanged: (v: boolean) => void;
  setFilterIds: (v: string[]) => void;
  filters: FilterStateLookup;
  storeFilterValues: (filtersToStore: Filter[]) => void;
  storeFilterRange: (filtersToStore: Filter[]) => void;
}

export const ProlificFilters: React.FC<React.PropsWithChildren<Props>> = ({
  filtersInitialized,
  filtersObjects,
  filterIds,
  disabled,
  setChanged,
  setFilterIds,
  filters,
  storeFilterValues,
  storeFilterRange
}): JSX.Element => {
  const storeFilterId = (filterIdOption: Option) => {
    setFilterIds([...filterIds, filterIdOption.value]);
  };

  return (
    <div className='w-full'>
      {filtersInitialized &&
        filtersObjects &&
        filterIds
          .map((filterId) => filtersObjects.find((o) => o.filter_id === filterId))
          .map(
            (object) =>
              object && (
                <FormGroup className='mt-12' key={`filter-${object.filter_id}`}>
                  <Text bold mb='2'>
                    {object.title}
                    <span className='font-normal'> (Optional)</span>
                  </Text>
                  <FilterInput
                    object={object}
                    disabled={disabled}
                    storeFilterValues={storeFilterValues}
                    setChanged={setChanged}
                    filters={filters}
                    storeFilterRange={storeFilterRange}
                  />
                </FormGroup>
              )
          )}
      {filtersInitialized && filtersObjects && (
        <>
          <FormGroup className='my-12 border-b border-t border-gray-200 py-12' key='additionalFilters'>
            <Text bold mb='2'>
              Additional filters
              <span className='font-normal'> (Optional)</span>
            </Text>
            <HelperText>Select any additional filters to use</HelperText>

            <DropdownCombobox
              items={Array.from(filtersObjects)
                .sort(compareTitles)
                .filter((object) => !filterIds.includes(object.filter_id))
                .map((object) => ({ label: object.title, value: object.filter_id }))}
              unlimitedItems
              disabled={disabled}
              onSelect={(v) => {
                storeFilterId(v as Option);
                setChanged(true);
              }}
              resetOnSelect
              blurOnSelect
              resetOnBlur
              placeholder='Available filters'
            />
          </FormGroup>
        </>
      )}
    </div>
  );
};
