import * as React from 'react';
import { useState } from 'react';

import { Dropdown, DropdownLink } from '@components/common';
import { RestrictedAction } from '@components/shared/RestrictedAction';

export type StudyLanguages = 'en' | 'de' | 'es' | 'fr' | 'it' | 'pt-BR';
interface Props {
  selectedLanguage: StudyLanguages;
  onChange: (arg0: string) => void;
}

const languageConfig = {
  en: { icon: <img alt='' src='/temp-assets/flag-images/us.png' className='mr-2 h-5 w-5' />, label: 'English' },
  fr: { icon: <img alt='' src='/temp-assets/flag-images/fr.png' className='mr-2 h-5 w-5' />, label: 'French' },
  de: { icon: <img alt='' src='/temp-assets/flag-images/de.png' className='mr-2 h-5 w-5' />, label: 'German' },
  it: { icon: <img alt='' src='/temp-assets/flag-images/it.png' className='mr-2 h-5 w-5' />, label: 'Italian' },
  es: { icon: <img alt='' src='/temp-assets/flag-images/es.png' className='mr-2 h-5 w-5' />, label: 'Spanish' },
  'pt-BR': {
    icon: <img alt='' src='/temp-assets/flag-images/br.png' className='mr-2 h-5 w-5' />,
    label: 'Brazilian Portuguese'
  }
};

export const StudyLanguageDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  selectedLanguage = 'en',
  onChange
}) => {
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState<boolean>(false);

  const handleLanguageChange = (lang: string) => {
    setLanguageDropdownOpen(false);
    onChange(lang);
  };

  return (
    <RestrictedAction feature='study_language' spanClassName='w-full'>
      {({ can, may }) => (
        <Dropdown
          text={
            <div className='flex items-center space-x-2'>
              {languageConfig[selectedLanguage].icon}
              {languageConfig[selectedLanguage].label}
            </div>
          }
          aboveButton
          position='left'
          className='w-full'
          buttonClassName='flex justify-between'
          dropdownWidth='full'
          isOpen={languageDropdownOpen}
          onClick={() => setLanguageDropdownOpen(true)}
          onClose={() => setLanguageDropdownOpen(false)}
          disabled={!can || !may}
        >
          {Object.keys(languageConfig).map((key: string) => (
            <DropdownLink key={key} className='flex items-center' onClick={() => handleLanguageChange(key)}>
              {languageConfig[key].icon}
              {languageConfig[key].label}
            </DropdownLink>
          ))}
        </Dropdown>
      )}
    </RestrictedAction>
  );
};
