import * as React from 'react';

import { Column, useSortBy, useTable } from 'react-table';

import { Cell } from 'components/shared/Table/components';

import { Header } from './cells/Header';

const Th: React.FC<React.PropsWithChildren<unknown>> = (props) => <th className='px-6 py-3 text-left' {...props} />;
const Td: React.FC<React.PropsWithChildren<unknown>> = (props) => <td className='px-6 py-1.5' {...props} />;

interface Props<T extends AbstractTemplate> {
  templates: T[];
  columns: Column<T>[];
}

export const ListViewTable = <T extends AbstractTemplate>(props: Props<T>): React.ReactElement => {
  const { columns, templates } = props;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<T>({
    columns,
    defaultColumn: { Header, Cell },
    data: templates
  });

  return (
    <div className='overflow-x-auto'>
      <table className='h400 w-full rounded-md border border-gray-200 bg-white' {...getTableProps()}>
        <thead className='border-b border-gray-200 px-3'>
          <tr>
            {headerGroups.map((headerGroup) =>
              headerGroup.headers.map((column) => <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>)
            )}
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-200 px-3' {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
