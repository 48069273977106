import * as React from 'react';

export const SearchTeamsSVG: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <path
      d='M5.68799 5.6875H3.06299C2.36679 5.6875 1.69912 5.41094 1.20683 4.91866C0.71455 4.42637 0.437988 3.75869 0.437988 3.0625C0.437988 2.36631 0.71455 1.69863 1.20683 1.20635C1.69912 0.714062 2.36679 0.4375 3.06299 0.4375H10.063C10.5916 0.437197 11.108 0.596513 11.5446 0.894593C11.9812 1.19267 12.3176 1.61563 12.5098 2.10809C12.702 2.60055 12.7411 3.13957 12.6218 3.65459C12.5026 4.1696 12.2307 4.63662 11.8416 4.9945'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.3749 10.9375H3.06299C2.71827 10.9375 2.37692 10.8696 2.05844 10.7377C1.73996 10.6058 1.45059 10.4124 1.20683 10.1687C0.963079 9.9249 0.769723 9.63552 0.637804 9.31704C0.505886 8.99856 0.437988 8.65722 0.437988 8.3125C0.437988 7.96778 0.505886 7.62644 0.637804 7.30796C0.769723 6.98948 0.963079 6.7001 1.20683 6.45634C1.45059 6.21259 1.73996 6.01923 2.05844 5.88732C2.37692 5.7554 2.71827 5.6875 3.06299 5.6875H5.68799'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M6.12549 3.0625H10.063' stroke='white' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M9.18799 12.25C10.0002 12.25 10.7792 11.9273 11.3535 11.353C11.9278 10.7787 12.2505 9.99973 12.2505 9.1875C12.2505 8.37527 11.9278 7.59632 11.3535 7.02199C10.7792 6.44766 10.0002 6.125 9.18799 6.125C8.37576 6.125 7.5968 6.44766 7.02247 7.02199C6.44814 7.59632 6.12549 8.37527 6.12549 9.1875C6.12549 9.99973 6.44814 10.7787 7.02247 11.353C7.5968 11.9273 8.37576 12.25 9.18799 12.25Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.5632 13.5622L11.3535 11.3525'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.06299 3.28125C3.00497 3.28125 2.94933 3.2582 2.90831 3.21718C2.86729 3.17616 2.84424 3.12052 2.84424 3.0625C2.84424 3.00448 2.86729 2.94884 2.90831 2.90782C2.94933 2.8668 3.00497 2.84375 3.06299 2.84375'
      stroke='white'
      strokeWidth='0.875'
    />
    <path
      d='M3.06299 3.28125C3.121 3.28125 3.17664 3.2582 3.21767 3.21718C3.25869 3.17616 3.28174 3.12052 3.28174 3.0625C3.28174 3.00448 3.25869 2.94884 3.21767 2.90782C3.17664 2.8668 3.121 2.84375 3.06299 2.84375'
      stroke='white'
      strokeWidth='0.875'
    />
    <path
      d='M3.06299 8.53125C3.00497 8.53125 2.94933 8.5082 2.90831 8.46718C2.86729 8.42616 2.84424 8.37052 2.84424 8.3125C2.84424 8.25448 2.86729 8.19884 2.90831 8.15782C2.94933 8.1168 3.00497 8.09375 3.06299 8.09375'
      stroke='white'
      strokeWidth='0.875'
    />
    <path
      d='M3.06299 8.53125C3.121 8.53125 3.17664 8.5082 3.21767 8.46718C3.25869 8.42616 3.28174 8.37052 3.28174 8.3125C3.28174 8.25448 3.25869 8.19884 3.21767 8.15782C3.17664 8.1168 3.121 8.09375 3.06299 8.09375'
      stroke='white'
      strokeWidth='0.875'
    />
  </svg>
);
