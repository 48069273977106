import * as React from 'react';

import classNames from 'classnames';

export const COLORS = {
  red: ['red-100', 'red-600'],
  pink: ['pink-100', 'pink-600'],
  indigo: ['indigo-100', 'indigo-600'],
  yellow: ['yellow-100', 'yellow-600'],
  green: ['green-100', 'green-600'],
  blue: ['blue-100', 'blue-600'],
  white: ['white', 'gray-700', 'gray-200'],
  gray: ['gray-50', 'gray-700', 'gray-200'],
  orange: ['orange-50', 'orange-600']
};
export interface PillProps {
  className?: string;
  color: keyof typeof COLORS;
  onClick?: () => void;
  children: React.ReactNode;
}
export const Pill: React.FC<React.PropsWithChildren<PillProps>> = ({ className = '', children, color, onClick }) => {
  const [bg, text, border] = COLORS[color];

  return (
    <div
      onClick={() => onClick?.()}
      className={classNames(
        'inline-block rounded-md px-1 text-xs font-bold uppercase leading-relaxed',
        `bg-${bg}`,
        `text-${text}`,
        className,
        border && `border border-${border}`
      )}
    >
      {children}
    </div>
  );
};
