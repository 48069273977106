import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { Spinner } from '@components/common/Spinner';

import { parseIframeUrl } from './helpers/parseIframeUrl';
import { ORIGIN } from './constants';
import * as FigmaPrototypeTypes from './types';

const LOADING_TIMEOUT = 10000;

export const FigmaPrototype = forwardRef<HTMLIFrameElement, FigmaPrototypeTypes.IframeProps>(
  (
    {
      disableDefaultKeyboardNav,
      hideUi,
      hideHotspots,
      nodeId,
      scaling,
      onPrototypeInitialLoad,
      onPrototypeMousePress,
      onPrototypePresentedNodeChanged,
      onPrototypeFirstEvent,
      src,
      ...rest
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState(true);
    const timeoutRef = useRef<NodeJS.Timeout>();

    const handleOnLoad = () => {
      onPrototypeFirstEvent?.();
      setIsLoading(false);
    };

    useEffect(() => {
      const handler = (
        event: MessageEvent<FigmaPrototypeTypes.MessageEvent | FigmaPrototypeTypes.InitialLoadMessageEvent>
      ) => {
        if (event.origin === ORIGIN) {
          clearTimeout(timeoutRef.current);

          handleOnLoad();

          switch (event.data.type) {
            case FigmaPrototypeTypes.MessageType.INITIAL_LOAD:
              onPrototypeInitialLoad?.();
              break;
            case FigmaPrototypeTypes.MessageType.MOUSE_PRESS_OR_RELEASE:
              onPrototypeMousePress?.(event.data.data);
              break;
            case FigmaPrototypeTypes.MessageType.PRESENTED_NODE_CHANGED:
              onPrototypePresentedNodeChanged?.(event.data.data);
              break;
            default:
              break;
          }
        }
      };

      window.addEventListener('message', handler);

      return () => {
        window.removeEventListener('message', handler);
      };
    }, []);

    useEffect(() => {
      timeoutRef.current = setTimeout(handleOnLoad, LOADING_TIMEOUT);

      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, []);

    if (!src) {
      return null;
    }

    return (
      <div className='relative h-full w-full'>
        {isLoading && (
          <div className='absolute inset-0 flex items-center justify-center bg-white'>
            <Spinner className='h-10 w-10' />
          </div>
        )}
        <iframe
          title='Figma prototype test'
          ref={ref}
          src={parseIframeUrl(src, { disableDefaultKeyboardNav, hideUi, hideHotspots, nodeId, scaling })}
          {...rest}
        />
      </div>
    );
  }
);
