import React, { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { Button } from '@components/common';
import { useToaster } from '@stores/toaster';

interface Props {
  participationId: number;
  sessionUuid: string;
}

export const CreateZoomButton: React.FC<React.PropsWithChildren<Props>> = ({ participationId, sessionUuid }) => {
  const [createZoom, { isError, isLoading }] = api.useCreateParticipationZoomMutation();

  const showToast = useToaster();

  useEffect(() => {
    if (isError) {
      showToast({
        heading: 'Couldn’t create Zoom meeting',
        text: 'We are investigating the issue. Please try again later.',
        icon: 'error'
      });
    }
  }, [isError]);

  const handleClick = () => {
    createZoom({ id: participationId, uuid: sessionUuid });
  };

  return (
    <div className='flex items-center py-1.5 text-sm'>
      <Button onClick={handleClick} icon='link' link loading={isLoading} disabled={isLoading}>
        Add Zoom meeting
      </Button>
    </div>
  );
};
