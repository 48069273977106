import * as React from 'react';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { SaveIcon } from '@components/CandidatesApp/CandidatesIndex/svgs';
import { Dropdown, DropdownLink } from '@components/common';
import { useRecruitingRequest } from '@components/StudiesApp/components/StudyDraft/hooks/useRecruitingRequest';
import {
  AddPeopleSVG,
  ExternalCandidatesSVG,
  ImportSVG,
  LinkSVG,
  PeopleGroupSVG,
  VerticalDotsSVG
} from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

import { ADD_DROPDOWN, ADD_EXISTING, ADD_MANUAL, INVITE_VIA_LINK, StudyAction } from './ACTIONS';

interface Props {
  study: Study;
  mode?: StudyAction | null;
  canUpdate: boolean;
  closeAll: () => void;
  setMode: (mode: StudyAction) => void;
  studyLimits: StudyLimit[];
  setStudyLimit: (limit: StudyLimit | null) => void;
  renderExportCSV?: () => React.ReactNode;
}
// TODO: Merge with actions.
export const DefaultActions: React.FC<React.PropsWithChildren<Props>> = ({
  mode,
  closeAll,
  setMode,
  study,
  canUpdate,
  studyLimits,
  setStudyLimit,
  renderExportCSV
}) => {
  const [showMenu, setShowMenu] = useState<'three_dots' | null>();

  const isPending = study.state === 'pending';
  const isActive = study.state === 'active';

  const canContact = isActive && canUpdate;

  const onAddDropdownClick = () => (mode === ADD_DROPDOWN ? closeAll() : setMode(ADD_DROPDOWN));

  const onAddExistingClick = () => {
    setStudyLimit(null);
    setMode(ADD_EXISTING);
  };
  const onAddManualClick = () => setMode(ADD_MANUAL);

  const hasExternalCandidatesAccess = study.external_candidates_enabled;

  const navigate = useNavigate();

  const handleUpdatedRequest = (newRequest: ExternalCandidatesRequest) => {
    navigate(`/studies/${study.id}/recruitment_requests/${newRequest.id}`);
  };

  const { handleNew } = useRecruitingRequest({
    study: study,
    handleUpdatedRequest,
    skip: true
  });

  return (
    <>
      {canUpdate && (
        <Dropdown
          medium
          dropdownWidth='64'
          className='desktop:mx-0 mx-2 mb-4 bg-white'
          text='Add'
          icon='plus'
          isOpen={mode === ADD_DROPDOWN}
          onClose={closeAll}
          onClick={onAddDropdownClick}
        >
          <DropdownLink
            aria-label='Browse existing candidates'
            trackEvent='clicked_add_existing_candidates'
            trackProps={{ page: 'participants_table' }}
            onClick={onAddExistingClick}
          >
            <PeopleGroupSVG className='mr-2 inline' />
            Browse existing candidates
          </DropdownLink>
          <DropdownLink
            trackEvent='clicked_add_manually'
            trackProps={{ page: 'participants_table' }}
            onClick={onAddManualClick}
          >
            <AddPeopleSVG className='mr-2 inline' />
            Add candidate manually
          </DropdownLink>
          {(isActive || isPending) && (
            <Tooltip
              className='w-full'
              isDisabled={!isPending}
              content='Study requires approval before you can invite.'
            >
              <DropdownLink
                disabled={isPending}
                className='w-full'
                trackEvent='clicked_invite_via_link'
                trackProps={{ page: 'participants_table' }}
                onClick={() => setMode(INVITE_VIA_LINK)}
              >
                <LinkSVG className='mr-2 inline' />
                Invite via link
              </DropdownLink>
            </Tooltip>
          )}
          <DropdownLink
            trackEvent='clicked_csv_import'
            trackProps={{ page: 'participants_table' }}
            href={`/customer_imports/new?study_id=${study.id}`}
          >
            <ImportSVG className='mr-2 inline' />
            Import from CSV
          </DropdownLink>

          {studyLimits && studyLimits.length > 0 && <hr className='my-2' />}
          {studyLimits?.map((limit) => (
            <DropdownLink
              key={limit.id}
              trackEvent='clicked_add_candidates_from_segment'
              trackProps={{ page: 'participants_table' }}
              onClick={() => {
                setStudyLimit(limit);
                setMode(ADD_EXISTING);
              }}
            >
              <SaveIcon className='mr-2 inline h-3.5 w-3.5' />
              Add from {limit.name ? limit.name : `Segment #${limit.order}`}
            </DropdownLink>
          ))}
          {hasExternalCandidatesAccess && (
            <>
              <hr className='my-2' />
              <DropdownLink
                trackEvent='clicked_add_external_candidates'
                trackProps={{ page: 'participants_table' }}
                onClick={handleNew}
              >
                <ExternalCandidatesSVG className='mr-2 inline' />
                Recruit external candidates
              </DropdownLink>
            </>
          )}
        </Dropdown>
      )}

      {canContact && (
        <Dropdown
          medium
          primary
          text='Compose'
          icon='composeEmail'
          className='desktop:mx-0 mx-2 mb-4'
          disabled
          tooltip='Please select participants to send a message'
        />
      )}
      {renderExportCSV && (
        <Dropdown
          medium
          onClick={() => setShowMenu('three_dots')}
          isOpen={showMenu === 'three_dots'}
          onClose={() => setShowMenu(null)}
          text={<VerticalDotsSVG />}
          dataTestId='three-dots-dropdown'
          className='bg-white'
          hideCaret
        >
          {renderExportCSV()}
        </Dropdown>
      )}
    </>
  );
};
