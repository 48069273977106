import * as React from 'react';

import { AppLoader } from '@components/AppLoader';
import { renderComp } from '@components/utils';

import { AccountLegalApp } from './AccountLegalApp';

export function mount(): void {
  renderComp('AccountLegalApp', () => (
    <AppLoader hideLoader>
      <AccountLegalApp />
    </AppLoader>
  ));
}
