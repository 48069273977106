import React, { useEffect, useMemo } from 'react';

import Skeleton from 'react-loading-skeleton';

import { SelectDropdown } from '@components/shared/SelectDropdown';
import { Item } from '@components/shared/StudySelector/Item';
import { api } from 'api/reduxApi';
import { DropdownComboboxProps, DropdownItem } from 'components/common/DropdownCombobox';
import { useToaster } from 'components/stores/toaster';

interface Props extends Partial<DropdownComboboxProps> {
  onNew?: (v: Partial<Study>) => void;
  study?: SimpleStudy;
  setStudy: (study: SimpleStudy) => void;
  disabled?: boolean;
}

export const StudySelector: React.FC<React.PropsWithChildren<Props>> = ({ onNew, study, setStudy, disabled }) => {
  const { data: studies, isLoading, isError } = api.useGetSimpleStudiesQuery();

  const showToast = useToaster();

  useEffect(() => {
    if (isError) {
      showToast({
        heading: 'Error fetching studies',
        text: 'There was an error fetching studies. Please try again later.',
        icon: 'error'
      });
    }
  }, [isError]);

  const options: DropdownItem[] = useMemo(
    () =>
      (studies || [])
        // not shure what does th 'repo_only' style mean. Shoud it be there?
        .filter((s) => ['repo_only', 'video_call'].includes(s.style) && !s.focus_group)
        .map(({ id, title }) => ({ label: title, value: id.toString() })),
    [studies]
  );

  const selected: DropdownItem | undefined = useMemo(
    () => (study ? { label: study.title, value: study.id.toString() } : undefined),
    [study]
  );

  if (isLoading || !studies) {
    return <Skeleton duration={1} className='h-10 rounded-full bg-gray-50' />;
  }

  return (
    <div className='w-56'>
      <SelectDropdown
        options={options}
        onCreate={(item) => onNew?.({ title: item.value })}
        onChange={(items) => {
          const study = studies.find(({ id }) => id.toString() === items[0].value);
          study && setStudy(study);
        }}
        value={selected ? [selected] : []}
        creatable={!!onNew}
        overrides={{
          Input: {
            props: {
              placeholder: 'Enter name…',
              className: 'xx-combo-input'
            }
          },
          Item: {
            component: Item
          }
        }}
      />
    </div>
  );
};
