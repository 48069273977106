import * as React from 'react';

import ReactSkeleton from 'react-loading-skeleton';

export const Card: React.FC<
  React.PropsWithChildren<{
    h?: string;
    mb?: string;
    mt?: string;
    height?: number | string;
  }>
> = ({ h = '10', mb, mt, height = 220 }) => (
  <ReactSkeleton
    duration={1}
    height={height}
    inline={false}
    className={`block h-${h} ${mb ? `mb-${mb}` : ''} ${mt ? `mt-${mt}` : ''} rounded-md`}
  />
);
