import React from 'react';

import { Link } from 'react-router-dom';

export const Url: React.FC<React.PropsWithChildren<{ href: string }>> = ({ children, href }) => {
  return (
    <a className='text-blue-500 hover:underline' href={href} target='_blank'>
      {children}
    </a>
  );
};
