import * as React from 'react';
import { useState } from 'react';

import cn from 'classnames';
import { Droppable } from 'react-beautiful-dnd';

import { Spinner, Text } from '@components/common';
import { getBackgroundColor } from '@components/tags/colors';
import { blurOnEnter } from '@components/utils';

import { ColumnOptionsDropdown, DropdownOptionsCallback } from './ColumnOptionsDropdown';

type Props = {
  id?: number;
  name?: string;
  description?: string;
  color?: Tag['color'];
  isSolidWhite?: boolean;
  ungrouped?: boolean;
  ungroupedCount?: number;
  ungroupedLabel?: string;
  readOnly?: boolean;
  isUpdating?: boolean;
  shouldRenderOptionsDropdown?: boolean;
  shouldRenderDroppablePlaceholder?: boolean;
  onChangeTitle: (title: string) => void;
  onChangeDescription: (description: string) => void;
  onChangeColor: (color: Tag['color']) => void;
  renderExtraInput?: () => React.ReactNode;
  dropdownOptions?: DropdownOptionsCallback | false;
};

export const Column: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  children,
  name,
  description,
  color = 'default',
  isSolidWhite,
  ungrouped,
  ungroupedCount,
  ungroupedLabel,
  readOnly = false,
  isUpdating,
  shouldRenderDroppablePlaceholder,
  onChangeTitle,
  onChangeDescription,
  onChangeColor,
  renderExtraInput,
  dropdownOptions
}) => {
  const [value, setValue] = useState(name);
  const [descriptionValue, setDescriptionValue] = useState(description);

  return (
    <Droppable droppableId={ungrouped ? 'ungrouped' : String(id)}>
      {(provided, snapshot) => {
        const opacity = snapshot.isDraggingOver ? 0.3 : 0.15;
        const backgroundColor = getBackgroundColor(color, opacity);

        return (
          <div
            className={cn('relative flex h-full w-full max-w-sm flex-shrink-0 flex-col rounded-sm', {
              'border border-gray-200 bg-white': isSolidWhite,
              'bg-gray-100': !isSolidWhite
            })}
            style={isSolidWhite ? {} : { backgroundColor }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className='p-4 pb-0'>
              <div className='absolute right-5 top-5 flex'>
                {isUpdating && (
                  <div className='mr-2'>
                    <Spinner className='h-4 w-4' />
                  </div>
                )}
                {!readOnly && dropdownOptions && (
                  <ColumnOptionsDropdown
                    color={color}
                    onChangeColor={id ? onChangeColor : undefined}
                    dropdownOptions={dropdownOptions}
                  />
                )}
              </div>
              {ungrouped && (
                <Text bold mb='2'>
                  Ungrouped{typeof ungroupedCount === 'number' && ` (${ungroupedCount})`}
                </Text>
              )}
              {!ungrouped && (
                <input
                  name='name'
                  aria-label='name'
                  autoComplete='off'
                  disabled={isUpdating || readOnly}
                  className='focus:outline-none mb-2 w-72 truncate border-0 bg-transparent font-bold placeholder-gray-400'
                  value={value}
                  placeholder='Add group name'
                  onChange={(e) => setValue(e.currentTarget.value)}
                  onBlur={(e) => onChangeTitle(e.currentTarget.value)}
                  {...blurOnEnter()}
                />
              )}
              {ungrouped && (
                <Text h='400' color='gray-500' mb='10'>
                  {ungroupedLabel}
                </Text>
              )}
              {!ungrouped && (
                <textarea
                  name='description'
                  aria-label='description'
                  autoComplete='off'
                  disabled={isUpdating || readOnly}
                  className='h400 focus:outline-none mb-2 w-full border-0 bg-transparent p-1 text-gray-500 placeholder-gray-400'
                  value={descriptionValue}
                  placeholder='Add description'
                  onChange={(e) => setDescriptionValue(e.currentTarget.value)}
                  onBlur={(e) => onChangeDescription(e.currentTarget.value)}
                  rows={2}
                  {...blurOnEnter()}
                />
              )}
              {!readOnly && renderExtraInput?.()}
            </div>
            <div className='mb-2 h-full w-full space-y-2 overflow-y-auto px-4'>
              {shouldRenderDroppablePlaceholder && provided.placeholder}
              {children}
            </div>
          </div>
        );
      }}
    </Droppable>
  );
};
