import { useCallback } from 'react';

import {
  DEFAULT_DESCRIPTION_PLACEHOLDER,
  DEFAULT_RESPONSE_LABEL,
  DEFAULT_TITLE_PLACEHOLDER,
  DESCRIPTION_EXAMPLES,
  DESCRIPTION_HELPERS,
  RESPONSE_EXAMPLES,
  RESPONSE_HELPERS,
  RESPONSE_LABELS,
  TITLE_EXAMPLES,
  TITLE_HELPERS,
  TITLE_PLACEHOLDER
} from '../formConstants';
import * as Models from '../types/models';

interface Values {
  title: string;
  description: string;
  response: string;
}

interface Methods {
  getExample: () => Partial<Values>;
  getHelper: () => Partial<Values>;
  getLabel: () => string | undefined;
  getPlaceholder: () => Partial<Values>;
}

type Result = [Methods];

export const useFormHelpers = (block: Models.Block): Result => {
  const { kind } = block;

  const getLabel = useCallback(
    () =>
      Object.prototype.hasOwnProperty.call(RESPONSE_LABELS, kind) ? RESPONSE_LABELS[kind] : DEFAULT_RESPONSE_LABEL,
    [kind]
  );

  const getHelper = useCallback(
    () => ({
      title: TITLE_HELPERS[kind],
      description: DESCRIPTION_HELPERS[kind],
      response: RESPONSE_HELPERS[kind]
    }),
    [kind]
  );

  const getExample = useCallback(
    () => ({
      title: TITLE_EXAMPLES[kind],
      description: DESCRIPTION_EXAMPLES[kind],
      response: RESPONSE_EXAMPLES[kind]
    }),
    [kind]
  );

  const getPlaceholder = useCallback(
    () => ({
      title: TITLE_PLACEHOLDER[kind] ?? DEFAULT_TITLE_PLACEHOLDER,
      description: DEFAULT_DESCRIPTION_PLACEHOLDER
    }),
    [kind]
  );

  return [{ getExample, getHelper, getLabel, getPlaceholder }];
};
