import { useState } from 'react';
import * as React from 'react';

import { Dropdown, DropdownLink } from '@components/common';
import { BrowserSVG, EmailActionSVG, ExternalLinkSVG, EyeSVG } from '@components/svgs';

interface Props {
  className?: string;
  aboveButton?: boolean;
  sendPreview: () => void;
  previewCandidate?: Candidate;
  message?: StudyMessage | null;
}

export const PreviewButton: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  aboveButton,
  sendPreview,
  previewCandidate,
  message
}) => {
  const [previewMenuOpen, setPreviewMenuOpen] = useState(false);

  return !message ? null : (
    <Dropdown
      className={className}
      aboveButton={aboveButton}
      isOpen={previewMenuOpen}
      onClick={() => setPreviewMenuOpen(!previewMenuOpen)}
      onClose={() => setPreviewMenuOpen(false)}
      text={
        <span className='flex flex-row items-center space-x-2'>
          <EyeSVG />
          <span>Preview</span>
        </span>
      }
    >
      <DropdownLink
        onClick={() => {
          setPreviewMenuOpen(false);
          sendPreview();
        }}
        className='xx-send-myself'
      >
        <span className='flex flex-row items-center space-x-2'>
          <EmailActionSVG />
          <span>Send myself a test email</span>
        </span>
      </DropdownLink>
      <DropdownLink
        target='_blank'
        href={`/study_messages/${message.id}/preview?${previewCandidate ? `&candidate_id=${previewCandidate.id}` : ''}`}
        onClick={() => setPreviewMenuOpen(false)}
      >
        <span className='flex flex-row items-center space-x-2'>
          <BrowserSVG />
          <span className='flex-1'>View in browser</span>
          <ExternalLinkSVG />
        </span>
      </DropdownLink>
    </Dropdown>
  );
};
