import * as React from 'react';

import { Basic } from '@components/shared/Skeleton';

export const Skeleton: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div>
    <div className='flex flex-col space-y-4 pb-36'>
      <Basic width='100%' />
      <Basic width='100%' />
      <Basic width='100%' />
    </div>
  </div>
);
