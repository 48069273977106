import React from 'react';

import Skeleton from 'react-loading-skeleton';

import { api } from '@api/reduxApi';
import { InterviewsTable } from '@components/DashboardApp/InterviewsTable';

export const UpcomingInterviews = () => {
  const { data: participations, isLoading } = api.useGetUpcomingInterviewsQuery({});

  if (isLoading) {
    return <Skeleton className='mb-4 mt-12 h-96' />;
  }

  return <InterviewsTable participations={participations} header='Upcoming Interviews' />;
};
