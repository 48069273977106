import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import TextareaAutosize from 'react-textarea-autosize';
import { useDebouncedCallback } from 'use-debounce';

import { removeListDecorators, uid, uniqueId, without } from '@components/utils';
import { Text } from 'components/common';

import { getDefaultOperator, isArrayAnswer } from '../components/IdealAnswers/utils';

import { IdealAnswerStar } from './IdealAnswerStar';
import { XSVG } from './shared';

const ListItem: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <li {...props} className='group relative flex items-start space-x-2 text-gray-700' />
);
const Interactable: React.FC<React.PropsWithChildren<any>> = ({ className = '', ...props }) => (
  <div
    {...props}
    className={[
      className,
      props.readOnly ? '' : 'focus:outline-none hover:border-gray-400 focus:border-indigo-600',
      'flex w-full justify-items-stretch rounded-xl border border-transparent'
    ].join(' ')}
  />
);
const InteractableInner: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className='flex flex-grow flex-row items-center px-2 py-1'>{children}</div>
);
const InteractableDeleteButton: React.FC<React.PropsWithChildren<{ onClick: React.MouseEventHandler }>> = ({
  onClick
}) => (
  <button
    name='delete_option'
    aria-label='Delete option'
    className='focus:outline-none hidden w-10 items-center justify-center border-l border-gray-200 group-hover:flex'
    onClick={onClick}
  >
    <XSVG className='text-gray-500' />
  </button>
);

interface OptionWithId {
  id: number;
  value: string;
}
interface OptionsComponentProps extends QuestionBodyProps {
  bulletClass: string;
}
export const OptionsComponent: React.FC<React.PropsWithChildren<OptionsComponentProps>> = ({
  readOnly = false,
  bulletClass,
  config,
  question: q,
  onChange
}) => {
  const [options, setOptions] = useState<OptionWithId[] | undefined>(q.options?.map((value) => ({ value, id: uid() })));
  const { hasIdealAnswers } = config || {};

  const idealAnswerArray = isArrayAnswer(q);
  // TODO: this needs to consider what type of field it is
  const idealAnswerValue = idealAnswerArray ? q.ideal_answer?.value || [] : q.ideal_answer?.value;

  const save = useDebouncedCallback((newFields: Partial<ScreenerField>) => {
    onChange({
      ...q,
      ...newFields
    });
  }, 100);

  const getHtmlId = (o: OptionWithId) => `field-${q.id}-option-${o.id}`;

  const focus = (o: OptionWithId) => document.getElementById(getHtmlId(o))?.focus();

  const handleClickInsert = (pos = options?.length || 0) => {
    const emptyOption = { id: uid(), value: '' };
    const opts = options || [];
    const newOptions = [...opts.slice(0, pos), emptyOption, ...opts.slice(pos)];

    setOptions(newOptions);

    setTimeout(() => focus(emptyOption), 1);
  };

  const toggleIdealAnswer = (option: string) => {
    let value;
    if (idealAnswerArray) {
      value = idealAnswerValue.includes(option) ? without(idealAnswerValue, option) : [...idealAnswerValue, option];
    } else {
      value = idealAnswerValue === option ? null : option;
    }

    onChange({
      ...q,
      ideal_answer: {
        operator: getDefaultOperator(q),
        value
      }
    });
  };

  const optionIsAnIdealAnswer = (val?: string): boolean => {
    if (!val) return false;

    if (idealAnswerArray) {
      return idealAnswerValue.includes(val);
    } else {
      return idealAnswerValue === val;
    }
  };

  const onChangeMultipleOptions = (newValue: string, options: OptionWithId[], currentId: number) => {
    const newValuesArray = newValue.split('\n').map((v) => removeListDecorators(v));

    const newOptionsArray = newValuesArray.map((o) => ({ id: uid(), value: o }));

    const current = options.find(({ id }) => id === currentId) as OptionWithId;

    const newOptions = [
      ...options.slice(0, options.indexOf(current)),
      ...newOptionsArray,
      ...options.slice(options.indexOf(current) + 1)
    ];

    setOptions(newOptions);

    if (newValue !== '') {
      save.callback({ options: newOptions.map((o) => o.value) });
    }
  };

  const onOptionChange = (newValue: string, options: OptionWithId[], optionId: number, oldValue?: string) => {
    const optionIsIdealAnswer = optionIsAnIdealAnswer(oldValue);

    const newOptions = options.map((o) => ({
      id: o.id,
      value: o.id === optionId ? newValue : o.value
    }));

    const newIdealAnswers = idealAnswerArray ? idealAnswerValue.map((v) => (v === oldValue ? newValue : v)) : newValue;
    const newIdealAnswer = optionIsIdealAnswer
      ? {
          ideal_answer: {
            operator: q.ideal_answer?.operator,
            value: newIdealAnswers
          }
        }
      : {};

    setOptions(newOptions);

    if (newValue !== '') {
      save.callback({
        options: newOptions.map((o) => o.value),
        ...newIdealAnswer
      });
    }
  };

  const deleteOption = (options: OptionWithId[], option: OptionWithId, oldValue?: string) => {
    const newOptions = without(options, option);

    setOptions(newOptions);

    const optionIsIdealAnswer = optionIsAnIdealAnswer(oldValue);

    const newIdealAnswers = idealAnswerArray ? without(idealAnswerValue, oldValue) : null;

    const newIdealAnswer = optionIsIdealAnswer
      ? {
          ideal_answer: {
            operator: getDefaultOperator(q),
            value: newIdealAnswers
          }
        }
      : {};
    save.callback({ options: newOptions.map((o) => o.value), ...newIdealAnswer });
  };

  return (
    <ul className='space-y-2'>
      {options?.map((option, idx) => (
        <ListItem key={option.id}>
          <div className={`mt-1.5 h-4 w-4 border border-gray-400 ${bulletClass}`} />
          {hasIdealAnswers && (
            <IdealAnswerStar
              mt='1.5'
              ideal={optionIsAnIdealAnswer(q.options?.[idx])}
              onClick={() => toggleIdealAnswer(option.value)}
            />
          )}

          <Interactable readOnly={readOnly}>
            <InteractableInner>
              {readOnly ? (
                <Text>{option.value}</Text>
              ) : (
                <TextareaAutosize
                  onFocus={(e) => e.target.select()}
                  id={getHtmlId(option)}
                  className={`${
                    readOnly ? '' : 'focus:outline-none focus:border-0 focus:ring-0'
                  } w-full resize-none border-0 p-0`}
                  minRows={0}
                  value={option.value}
                  disabled={readOnly}
                  onPaste={
                    readOnly
                      ? undefined
                      : (e) => {
                          const newValue = e.clipboardData.getData('text');

                          if (newValue.includes('\n')) {
                            onChangeMultipleOptions(newValue, options, option.id);
                          } else {
                            onOptionChange(e.currentTarget.value, options, option.id, q.options?.[idx]);
                          }
                        }
                  }
                  onChange={
                    readOnly
                      ? undefined
                      : (e) => {
                          onOptionChange(e.currentTarget.value, options, option.id, q.options?.[idx]);
                        }
                  }
                  onBlur={
                    readOnly
                      ? undefined
                      : () => {
                          // delete this option if empty
                          if (option.value.trim() === '') {
                            deleteOption(options, option, q.options?.[idx]);
                          }
                        }
                  }
                  onKeyDown={(e) => {
                    // enter key saves and focuses the next option
                    if (e.key === 'Enter' && !readOnly) {
                      e.preventDefault();
                      const nextOption = options[idx + 1];
                      if (!option.value) {
                        focus(nextOption);
                      } else if (nextOption?.value) {
                        handleClickInsert(idx + 1);
                      } else {
                        e.currentTarget.blur();
                        handleClickInsert();
                      }
                    }
                  }}
                />
              )}
            </InteractableInner>

            {!readOnly && <InteractableDeleteButton onClick={() => deleteOption(options, option, q.options?.[idx])} />}
          </Interactable>
        </ListItem>
      ))}

      {!readOnly && (
        <ListItem>
          <div className={`mt-1.5 h-4 w-4 border border-gray-400 ${bulletClass}`} />

          <Interactable readOnly={readOnly}>
            <InteractableInner>
              <button className='focus:outline-none cursor-text text-gray-500' onClick={() => handleClickInsert()}>
                Enter option{!q.other && ' or'}
              </button>
              {!q.other && (
                <span>
                  &nbsp;
                  <button className='focus:outline-none' onClick={() => onChange({ ...q, other: true })}>
                    <span className='text-indigo-600 hover:underline'>add “Other”</span>
                  </button>
                </span>
              )}
            </InteractableInner>
          </Interactable>
        </ListItem>
      )}

      {q.other && (
        <ListItem>
          <div className={`mt-1.5 h-4 w-4 border border-gray-400 ${bulletClass}`} />
          <Interactable readOnly={readOnly}>
            <InteractableInner>
              <Text>Other</Text>
            </InteractableInner>
            {!readOnly && <InteractableDeleteButton onClick={() => onChange({ ...q, other: false })} />}
          </Interactable>
        </ListItem>
      )}
    </ul>
  );
};

export const Checkboxes: QuestionCardBodyComponent = (props) => {
  return <OptionsComponent bulletClass='rounded-sm' {...props} />;
};
