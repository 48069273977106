import React, { FC, Fragment, useMemo, useRef } from 'react';

import { TableInstance } from 'react-table';
import { defaultRangeExtractor, useVirtualizer, VirtualizerOptions } from '@tanstack/react-virtual';

import { GridCell } from './components/GridCell';

interface Props {
  table: TableInstance;
}

const columnVirtualizerRangeExtractor: VirtualizerOptions<Element, Element>['rangeExtractor'] = (range) => {
  // keep first cell on each row in the DOM, this is needed for sticky
  const next = new Set([0, ...defaultRangeExtractor(range)]);

  return Array.from(next).sort((a, b) => a - b);
};

export const ParticipationsGrid: FC<Props> = ({ table }) => {
  const {
    headerGroups: [{ headers }]
  } = table;

  const parentRef = useRef<HTMLDivElement>(null);

  const rows = useMemo(() => {
    const { rows: tableRows } = table;

    for (const row of tableRows) {
      table.prepareRow(row);
    }

    return tableRows;
  }, [table.rows, table.headerGroups]);

  const rowVirtualizer = useVirtualizer({
    // we need an extra row for the headers
    count: rows.length + 1,
    overscan: 5,
    estimateSize: () => 44,
    getScrollElement: () => parentRef.current
  });

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: headers.length,
    overscan: 5,
    estimateSize: (i) => headers[i].width,
    getScrollElement: () => parentRef.current,
    rangeExtractor: columnVirtualizerRangeExtractor
  });

  const wrapperStyle = useMemo(
    () => ({
      height: `${rowVirtualizer.getTotalSize()}px`,
      width: `${columnVirtualizer.getTotalSize()}px`
    }),
    [columnVirtualizer, rowVirtualizer, table.headerGroups]
  );

  return (
    <div
      ref={parentRef}
      className='xx-grid-table w-full overflow-auto border border-gray-200 bg-gray-50'
      style={{ height: 1000 }}
    >
      <div className='relative' style={wrapperStyle}>
        {/* Sticky column */}
        <div id='grid-table-sticky-column' className='sticky left-0 z-10 h-full' style={{ width: headers[0].width }}>
          {rowVirtualizer.getVirtualItems().map((virtualRow) => (
            <Fragment key={virtualRow.key as string}>
              {columnVirtualizer
                .getVirtualItems()
                .map(
                  (virtualColumn) =>
                    virtualColumn.index === 0 && (
                      <GridCell
                        key={virtualColumn.key as string}
                        headers={headers}
                        rows={rows}
                        virtualColumn={virtualColumn}
                        virtualRow={virtualRow}
                        resizeItem={columnVirtualizer.resizeItem}
                      />
                    )
                )}
            </Fragment>
          ))}
        </div>

        {rowVirtualizer.getVirtualItems().map((virtualRow) => (
          <Fragment key={virtualRow.key as string}>
            {columnVirtualizer
              .getVirtualItems()
              .map(
                (virtualColumn) =>
                  virtualColumn.index !== 0 && (
                    <GridCell
                      key={virtualColumn.key as string}
                      headers={headers}
                      rows={rows}
                      virtualColumn={virtualColumn}
                      virtualRow={virtualRow}
                      resizeItem={columnVirtualizer.resizeItem}
                    />
                  )
              )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
