import React, { forwardRef, HTMLAttributes, Ref } from 'react';

import cn from 'classnames';
import TextArea, { TextareaAutosizeProps } from 'react-textarea-autosize';

import { Text } from '../Text';

import { Icon } from './components/Icon';

interface Props extends HTMLAttributes<HTMLDivElement> {
  innerRef?: Ref<HTMLTextAreaElement>;
  resizable?: boolean;
  showSupport?: boolean;
  textAreaProps?: TextareaAutosizeProps;
}

const DEFAULT_MIN_ROWS = 3;

export const MarkdownEditor = forwardRef<HTMLDivElement, Props>(
  ({ innerRef, resizable = true, showSupport = true, textAreaProps = {}, ...rest }, ref) => {
    const { className: textAreaClassName, ...textAreaPropsRest } = textAreaProps;

    return (
      <div ref={ref} className='overflow-hidden rounded-md border border-gray-200 bg-gray-50 p-2 pb-1' {...rest}>
        <TextArea
          ref={innerRef}
          className={cn(
            'tablet:text-sm w-full rounded-md border-gray-200 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500',
            {
              'resize-none': !resizable
            },
            textAreaClassName
          )}
          minRows={DEFAULT_MIN_ROWS}
          style={{ outline: 'none', boxShadow: 'none' }}
          {...textAreaPropsRest}
        />

        <a
          href='https://www.markdownguide.org/cheat-sheet/'
          target='_blank'
          className='mt-1 inline-flex items-center space-x-1 px-1 text-gray-500 hover:text-indigo-600'
        >
          <Icon className='h-6 w-6' />
          <Text className='text-xs'>Markdown is supported</Text>
        </a>
      </div>
    );
  }
);
