import { useCallback, useRef, useState } from 'react';

import { useAudioLevelObserver, useLocalSessionId } from '@daily-co/daily-react';

interface UseMicrophoneStateArgs {
  /**
   * The time window in milliseconds to capture the audio level.
   * If the audio level is below the threshold for this duration, the `hasLowAudioVolume` state will be set to `true`.
   */
  captureWindow?: number;
  callback?: (level: number) => void;
}

interface UseMicrophoneStateReturnType {
  hasLowAudioVolume: boolean;
}

type UseMicrophoneState = (args?: UseMicrophoneStateArgs) => UseMicrophoneStateReturnType;

const TIMEOUT_WINDOW = 5000;
const LOW_VOLUME_THRESHOLD = 0.15;

export const useMicrophoneState: UseMicrophoneState = ({ captureWindow = TIMEOUT_WINDOW, callback } = {}) => {
  const [hasLowAudioVolume, setHasLowAudioVolume] = useState<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const sessionId = useLocalSessionId();

  const start = useCallback(() => {
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => setHasLowAudioVolume(true), captureWindow);
    }
  }, [captureWindow]);

  const stop = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    setHasLowAudioVolume(false);
  }, []);

  useAudioLevelObserver(
    sessionId,
    useCallback(
      (level: any) => {
        if (level <= LOW_VOLUME_THRESHOLD) {
          start();
        } else {
          stop();
        }

        callback?.(level);
      },
      [start, stop]
    )
  );

  return { hasLowAudioVolume };
};
