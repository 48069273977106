import { useCallback, useEffect } from 'react';

import copy from 'copy-to-clipboard';

import {
  useCreateEntityGroupMutation,
  useCreateOperationMutation,
  useGetStudyEntityGroupsQuery,
  useGetStudyHighlightGroupsQuery,
  useGetStudyHighlightsQuery,
  useUpdateStudyHighlightGroupsMutation
} from '../api';
import type { SynthesisContext } from '../context';
import { buildHtml, buildPlainText, moveEntitiesToGroup } from '../utils';

import { useSynthesisContext } from './useSynthesisContext';
type UseSynthesisData = SynthesisContext & {
  isLoadingData: boolean;
  isLoadingGroups: boolean;
  groups: EntityGroup[];
  highlights: DocumentHighlight[];
  getHighlightsForGroup: (id: number | null) => DocumentHighlight[];
  createNewGroup: () => Promise<EntityGroup>;
  moveSelectionToGroup: (groupId: number | null) => void;
  copyToClipboard: () => void;
  sortHighlights: (sortKey: string) => void;
  groupHighlights: (groupKey: string) => void;
};

export const useSynthesisData = (studyId: number, boardId?: number): UseSynthesisData => {
  const synthesisContext = useSynthesisContext();
  const { data, setData, selectedIds } = synthesisContext;

  const { data: highlights, isLoading: isLoadingHighlights } = useGetStudyHighlightsQuery(studyId, {
    refetchOnMountOrArgChange: true
  });
  const { data: initialData, isLoading } = useGetStudyHighlightGroupsQuery(studyId);
  const { data: groups, isLoading: isLoadingGroups } = useGetStudyEntityGroupsQuery(studyId);
  const [createOperation] = useCreateOperationMutation();
  const [updateData] = useUpdateStudyHighlightGroupsMutation();
  const [createGroup] = useCreateEntityGroupMutation();
  const isLoadingData = isLoading || isLoadingHighlights || isLoadingGroups;

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  const sortHighlights = useCallback(
    async (sortKey: string) => {
      await createOperation({ study_id: studyId, action_type: 'sort', action_value: sortKey });
    },
    [highlights, data]
  );

  const groupHighlights = useCallback(
    async (groupKey: string) => {
      await createOperation({ study_id: studyId, action_type: 'group', action_value: groupKey });
    },
    [highlights, data]
  );

  const createNewGroup = useCallback(
    async () => await createGroup({ groupable_id: boardId, groupable_type: 'Board' }).unwrap(),
    []
  );

  const getHighlightsForGroup = useCallback(
    (id: number | null) => {
      if (id === null) {
        return (highlights || []).filter((h) => data.ungrouped.includes(h.id));
      }
      const ids = data.grouped.find((g) => g.group_id === id)?.entities || [];

      return filterHighlightsByIdOrder(ids, highlights || []);
    },
    [data, highlights]
  );

  const moveSelectionToGroup = useCallback(
    (id: number | null) => {
      const newData = moveEntitiesToGroup(data, selectedIds, id);
      setData(newData);
      updateData({ study_id: studyId, groups: newData });
    },
    [data, selectedIds]
  );

  const copyToClipboard = useCallback(() => {
    const plainText = buildPlainText(highlights || [], data, selectedIds);
    const html = buildHtml(highlights || [], data, selectedIds);
    copy(plainText, {
      onCopy: (clipboardData: DataTransfer) => {
        clipboardData.items.add(plainText, 'text/plain');
        clipboardData.items.add(html, 'text/html');
      }
    });
  }, [highlights, data, selectedIds]);

  function filterHighlightsByIdOrder(ids: number[], highlights: any[]): any[] {
    const highlightsMap = new Map<number, any>(highlights.map((h) => [h.id, h]));

    return ids.reduce((acc: any[], id) => {
      const highlight = highlightsMap.get(id);
      if (highlight) {
        acc.push(highlight);
      }
      return acc;
    }, []);
  }

  useEffect(() => {
    if (!isLoadingHighlights && !isLoadingGroups) {
      synthesisContext.setIsZDS(highlights?.length === 0 && groups?.length === 0);
    }
  }, [synthesisContext.isZDS, highlights, isLoadingHighlights, groups, isLoadingGroups]);

  return {
    isLoadingData,
    isLoadingGroups,
    groups: groups || [],
    highlights: highlights || [],
    getHighlightsForGroup,
    createNewGroup,
    moveSelectionToGroup,
    copyToClipboard,
    sortHighlights,
    groupHighlights,
    ...synthesisContext
  };
};
