import React, { FC, SVGProps } from 'react';

export const RegroupSVG: FC<React.PropsWithChildren<SVGProps<SVGSVGElement>>> = (props) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1 0.999512H15V14.9995H1V0.999512Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 0.999512V14.9995'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M1 7.99951H15' stroke='currentColor' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
