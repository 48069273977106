import React from 'react';

export const CalendarSVG: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '' }) => (
  <svg
    className={'stroke-current ' + className}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.25 17.25C11.25 18.8413 11.8821 20.3674 13.0074 21.4926C14.1326 22.6179 15.6587 23.25 17.25 23.25C18.8413 23.25 20.3674 22.6179 21.4926 21.4926C22.6179 20.3674 23.25 18.8413 23.25 17.25C23.25 15.6587 22.6179 14.1326 21.4926 13.0074C20.3674 11.8821 18.8413 11.25 17.25 11.25C15.6587 11.25 14.1326 11.8821 13.0074 13.0074C11.8821 14.1326 11.25 15.6587 11.25 17.25Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19.924 15.506L17.019 19.379C16.9544 19.4648 16.8721 19.5358 16.7777 19.5872C16.6834 19.6386 16.5791 19.6691 16.4719 19.6768C16.3648 19.6845 16.2572 19.6691 16.1565 19.6317C16.0557 19.5943 15.9642 19.5357 15.888 19.46L14.388 17.96'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.25 17.25H2.25C1.85218 17.25 1.47064 17.092 1.18934 16.8107C0.908035 16.5294 0.75 16.1478 0.75 15.75V3.76501C0.75 3.36719 0.908035 2.98566 1.18934 2.70435C1.47064 2.42305 1.85218 2.26501 2.25 2.26501H15.75C16.1478 2.26501 16.5294 2.42305 16.8107 2.70435C17.092 2.98566 17.25 3.36719 17.25 3.76501V8.25001'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M0.75 6.75H17.25' stroke='currentColor' strokeWidth='1.5' strokeLinejoin='round' />
    <path d='M5.24301 3.75V0.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12.743 3.75V0.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export const CalendarAlertSVG: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '' }) => (
  <svg
    className={'stroke-current ' + className}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.25 17.25C11.25 18.8413 11.8821 20.3674 13.0074 21.4926C14.1326 22.6179 15.6587 23.25 17.25 23.25C18.8413 23.25 20.3674 22.6179 21.4926 21.4926C22.6179 20.3674 23.25 18.8413 23.25 17.25C23.25 15.6587 22.6179 14.1326 21.4926 13.0074C20.3674 11.8821 18.8413 11.25 17.25 11.25C15.6587 11.25 14.1326 11.8821 13.0074 13.0074C11.8821 14.1326 11.25 15.6587 11.25 17.25Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M17.25 20.25V17.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M17.25 14.25C17.1758 14.25 17.1033 14.272 17.0417 14.3132C16.98 14.3544 16.9319 14.413 16.9035 14.4815C16.8752 14.55 16.8677 14.6254 16.8822 14.6982C16.8967 14.7709 16.9324 14.8377 16.9848 14.8902C17.0373 14.9426 17.1041 14.9783 17.1768 14.9928C17.2496 15.0073 17.325 14.9998 17.3935 14.9715C17.462 14.9431 17.5206 14.895 17.5618 14.8333C17.603 14.7717 17.625 14.6992 17.625 14.625C17.625 14.5255 17.5855 14.4302 17.5152 14.3598C17.4448 14.2895 17.3495 14.25 17.25 14.25Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.25 17.2501H2.25C1.85218 17.2501 1.47064 17.0921 1.18934 16.8108C0.908035 16.5295 0.75 16.148 0.75 15.7501V3.76514C0.75 3.36731 0.908035 2.98578 1.18934 2.70448C1.47064 2.42317 1.85218 2.26514 2.25 2.26514H15.75C16.1478 2.26514 16.5294 2.42317 16.8107 2.70448C17.092 2.98578 17.25 3.36731 17.25 3.76514V8.25014'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M0.75 6.75H17.25' stroke='currentColor' strokeWidth='1.5' strokeLinejoin='round' />
    <path d='M5.24316 3.75V0.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12.7432 3.75V0.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
export const BatchingSVG: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '' }) => (
  <svg
    className={'fill-current ' + className}
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM9 5H10.5V10.25L15 12.92L14.25 14.15L9 11V5Z'
      fill='currentColor'
    />
  </svg>
);
