import * as React from 'react';

import { Button, Modal, ModalHeading } from '@components/common';

interface Props {
  destroyTagGroup: (tagGroupId: number) => void;
  tagGroupId: number;
  isLoading: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
}
export const DeleteTagGroupModal: React.FC<React.PropsWithChildren<Props>> = ({
  destroyTagGroup,
  isLoading,
  open,
  onClose,
  onSubmit,
  tagGroupId
}) => {
  if (!open) {
    return null;
  }

  const handleClick = () => destroyTagGroup(tagGroupId);

  return (
    <Modal icon='danger' onClose={isLoading ? undefined : onClose}>
      <ModalHeading className='mb-2'>Delete tag group?</ModalHeading>
      <p className='pb-12 text-gray-700'>
        By deleting this tag group, all tags contained will move to Ungrouped. This is not reversible.
      </p>
      <div className='flex justify-end space-x-4'>
        <Button disabled={isLoading} onClick={onClose}>
          Don’t delete
        </Button>
        <Button disabled={isLoading} onClick={handleClick} danger>
          Confirm & delete
        </Button>
      </div>
    </Modal>
  );
};
