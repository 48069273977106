import React, { createContext, useContext, useState } from 'react';

import { useGetWhoamiQuery } from '@stores/whoami';

import { Header, Stepper, Steps } from './components';

export interface Context {
  currentStep?: number;
  totalSteps?: number;
  setCurrentStep?: (step: number) => void;
}

export const OnboardingContext = createContext<Context | undefined>(undefined);

export const useOnboardingContext = () => {
  const context = useContext<Context | undefined>(OnboardingContext);

  if (!context) throw new Error();

  return context;
};

const TOTAL_STEPS = 3;

export const Onboarding: React.FC<React.PropsWithChildren<{ redirectTo: string | null }>> = ({ redirectTo }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const { data: whoami } = useGetWhoamiQuery();

  return (
    <section className='flex h-full flex-col'>
      <Header />
      <OnboardingContext.Provider
        value={{
          currentStep,
          totalSteps: TOTAL_STEPS,
          setCurrentStep
        }}
      >
        <div className='flex flex-1 flex-col'>
          {whoami?.role !== 'observer' && <Stepper />}
          <Steps redirectTo={redirectTo} />
        </div>
      </OnboardingContext.Provider>
    </section>
  );
};
