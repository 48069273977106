import * as React from 'react';

import classNames from 'classnames';
import Tippy from '@tippyjs/react';

import { ClearIcon, SaveIcon } from '@components/CandidatesApp/CandidatesIndex/svgs';

type Props = {
  anyFilters: boolean;
  onSave?: () => void;
  onClear: () => void;
};
export const SegmentActions: React.FC<React.PropsWithChildren<Props>> = ({ anyFilters, onSave, onClear }) => {
  const saveClass = classNames({
    'hover:text-indigo-600 text-gray-700 h400 flex flex-row items-center space-x-2 cursor-pointer': true,
    'pointer-events-none cursor-default opacity-50': !anyFilters
  });

  const clearClass = classNames({
    'hover:text-indigo-600 text-gray-700 h400 flex flex-row items-center space-x-2 cursor-pointer': true,
    'pointer-events-none cursor-default opacity-50': !anyFilters
  });

  return (
    <div className='flex flex-row items-center space-x-4'>
      {onSave && (
        <Tippy content='Save segment'>
          <button name='save' onClick={onSave} className={saveClass}>
            <SaveIcon />
          </button>
        </Tippy>
      )}
      <Tippy content='Clear filters'>
        <button name='clear' onClick={onClear} className={clearClass}>
          <ClearIcon />
        </button>
      </Tippy>
    </div>
  );
};
