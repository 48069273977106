import * as React from 'react';

import { useGetWhoamiQuery } from '@components/stores/whoami';
import { getCtaProps } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip/helpers';
import { UseMessageEditorHook } from '@components/StudyMessages/hooks/useMessageEditor';
import { EditorComponent } from '@components/StudyMessages/hooks/useMessageEditor/components/EditorComponent';
import { EditorSlideOut } from '@components/StudyMessages/hooks/useMessageEditor/components/EditorSlideOut';
import { getCtaUrl } from '@components/StudyMessages/utils/getCtaUrl';
import { useAccount } from '@hooks/useAccount';

export const StudyEmail: React.FC<React.PropsWithChildren<PageProps & { hook: UseMessageEditorHook }>> = ({
  study,
  hook
}) => {
  const { editorRef, editorProps, setEdit, sendPreview, handleSubmit, message } = hook;

  const { data: whoami } = useGetWhoamiQuery();
  const accountPublicBaseUrl = whoami?.account_public_domain?.base_url;

  const {
    account: { workspace }
  } = useAccount();

  if (!message) return null;

  return (
    <div className='relative mx-auto w-full max-w-2xl border border-gray-200 bg-white'>
      {/* email in preview (non edit) mode*/}
      <EditorComponent {...editorProps} sendPreview={sendPreview} editDisabled ref={editorRef} />
      {/*email in edit mode (opens in slideout)*/}
      <EditorSlideOut
        {...editorProps}
        handleSubmit={handleSubmit}
        ref={editorRef}
        setEdit={setEdit}
        updateOnlyTemplate
        ctaProps={getCtaProps({
          ctaUrl: getCtaUrl(workspace, study, message, accountPublicBaseUrl) || '',
          videoUrlType: study.video_url_type,
          studyStyle: study.style,
          screenerInviteCta: study.screener_invite_cta,
          event: message.event,
          videoUrl: study.video_url_type === 'in_person' ? study.location.address : study.video_url || '',
          tremendous: study.incentive_method === 'tremendous'
        })}
      />
    </div>
  );
};
