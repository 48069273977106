import { useMemo } from 'react';

import { EXTERNAL_ATTRS } from '@components/config';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import {
  getPreScreenerFieldId,
  getSurveyScreenerFieldId
} from 'components/StudiesApp/components/StudyPublished/components/buildParticipationsFilterDefs';
import { useFeature } from 'hooks/useFeature';

import { COLUMN_LABELS } from '../constants';

export const useParticipationsLabels = ({ study }: { study?: Study }): Record<string, string> => {
  const { candidateAttrs } = useCandidateAttrs();
  const serverSideParticipationsEnabled = useFeature('serverside_participations');

  const attributes = useMemo(() => candidateAttrs.concat(EXTERNAL_ATTRS), [candidateAttrs]);

  const preScreenerFieldLabels = useMemo<Record<string, string> | undefined>(
    () =>
      study?.pre_screener?.fields.reduce(
        (acc, { id, label }) => ({
          ...acc,
          [getPreScreenerFieldId(id, serverSideParticipationsEnabled)]: label
        }),
        {}
      ),
    [study]
  );

  const surveyScreenerFieldLabels = useMemo<Record<string, string> | undefined>(
    () =>
      study?.survey_screener?.fields.reduce(
        (acc, { id, label }) => ({
          ...acc,
          [getSurveyScreenerFieldId(id, serverSideParticipationsEnabled)]: label
        }),
        {}
      ),
    [study]
  );

  const candidateAttrsLabels = useMemo<Record<string, string>>(
    () => attributes.reduce((acc, { name, label }) => ({ ...acc, [`extra.${name}`]: label }), {}),
    [attributes]
  );

  const participationsLabels = useMemo<Record<string, string>>(
    () => ({ ...COLUMN_LABELS, ...candidateAttrsLabels, ...preScreenerFieldLabels, ...surveyScreenerFieldLabels }),
    [candidateAttrsLabels, preScreenerFieldLabels, surveyScreenerFieldLabels]
  );

  return participationsLabels;
};
