import pluralize from 'pluralize';

import { capitalizeAllWords } from '@components/utils';

export const INLINE_ERROR_MESSAGES: Record<BookabilityRule, string | ((e: BookabilityCase['extra']) => string)> = {
  moderators_need_calendar: (extra) => `${pluralize('calendars', extra?.user_ids?.length || 1, true)} disconnected`,
  moderators_need_calendar_selected: (extra) =>
    `${pluralize('calendars', extra?.user_ids?.length || 1, true)} not selected`,
  moderators_need_conferencing: (extra) =>
    `${pluralize(
      capitalizeAllWords(extra?.platform?.replace(/_/g, ' ') || 'conferencing'),
      extra?.user_ids?.length || 1,
      true
    )} disconnected`,
  under_participant_limit: 'Study has reached limit',
  needs_slots: 'No available times',
  date_range_in_past: 'Date range in the past',
  needs_availability: 'No scheduling hours added',
  invalid_call_location: 'Invalid call location',
  moderators_need_zoom_local_recording_setting: 'Missing required setting',
  no_survey_questions: 'No survey questions',
  no_screener_questions: 'No screener questions',
  invalid_email_sender: 'Email sender not configured'
};
