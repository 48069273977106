import React, { useContext, useEffect } from 'react';

import { Button, Loading } from '@components/common';
import { useAccountOrNull } from 'hooks/useAccount';
import { usePlan } from 'hooks/usePlan';

import { useCreateTrialMutation } from '../api';
import { TRIAL_LENGTH } from '../constants';

export const StartTrialLink: React.FC<React.PropsWithChildren<{ plan: Plan }>> = ({ plan }) => {
  const account = useAccountOrNull();
  const { trial_ends_at, status } = account || {};
  const [createTrial, { isSuccess, isLoading }] = useCreateTrialMutation();
  const { key } = usePlan();
  useEffect(() => {
    if (isSuccess) {
      window.location.reload();
    }
  }, [isSuccess]);

  if (!status || status === 'trial' || key == 'trial' || trial_ends_at) {
    return null;
  }

  return (
    <>
      {isLoading && <Loading />}
      <Button small className='mx-auto mt-2' link fullWidth onClick={() => createTrial()}>
        Start {TRIAL_LENGTH}-day free trial
      </Button>
    </>
  );
};
