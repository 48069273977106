import * as React from 'react';
import { useRef, useState } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';
import Tippy from '@tippyjs/react';

import { Option, PopperDropdown, PopperDropdownButton, Text } from '@components/common';
import { CheckSVG, ChevronDownSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { useOnClickOutside } from '@components/utils';

interface Props {
  value?: string | null;
  onChange: (args: Partial<CollectionView['sort']>) => void;
  options: Option[];
  trackKey?: string;
  type?: 'sort' | 'group';
  wrapperClass?: string;
  disabled?: boolean;
  className?: string;
  tooltipContent?: string;
}

export const SortDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  wrapperClass,
  className,
  type = 'sort',
  onChange,
  options,
  trackKey,
  disabled,
  tooltipContent
}) => {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const selectedOption = options.find((o) => o.value === value);
  const sortedOptions = [...options];
  sortedOptions.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
  useOnClickOutside(ref, () => open && setOpen(false));
  const defaultClassName =
    'whitespace-nowrap flex items-center justify-between rounded-md px-4 h-8 space-x-2 bg-white border-gray-200 border w-full';

  const Wrapper = tooltipContent ? Tippy : React.Fragment;
  const wrapperProps = tooltipContent ? { content: tooltipContent } : {};

  return (
    <div className={cn('relative', wrapperClass)}>
      {disabled ? (
        <Wrapper {...wrapperProps}>
          <div className={twMerge(defaultClassName, 'bg-gray-200', className)} title='Dropdown is disabled'>
            <Text h='400' className='flex-1 truncate text-left text-gray-400'>
              No grouping
            </Text>
            <ChevronDownSVG className='text-gray-400' />
          </div>
        </Wrapper>
      ) : (
        <PopperDropdown
          name={`${type}-dropdown`}
          text={
            <Text h='400' className='flex-1 truncate text-left'>
              {selectedOption?.label || 'None'}
            </Text>
          }
          popperProps={{ zIndex: 50, renderOnBodyRoot: false, className: 'w-full' }}
          closeOnDropdownClick={true}
          data-testid={`${type}-dropdown`}
          aria-label={`${type} by ${selectedOption?.label}`}
          dropdownClass='w-full max-h-72 overflow-y-auto'
          className={twMerge(defaultClassName, className)}
          tooltip={
            !selectedOption?.label ? undefined : (
              <Text h='400' className='inline' color='white'>
                Sort by: {selectedOption?.label}
              </Text>
            )
          }
        >
          {sortedOptions.map((option) => (
            <PopperDropdownButton
              key={option.value}
              data-testid={option.value}
              onClick={() => {
                onChange({ value: option.value });

                if (trackKey) {
                  track('sorted', { page: trackKey });
                }
              }}
              className='flex max-w-full items-center justify-between'
            >
              <Text
                color={option.value === value ? 'indigo-600' : undefined}
                className='mr-1 flex-1 truncate whitespace-nowrap'
                h='400'
              >
                {option.label}
              </Text>
              {option.value === value && <CheckSVG />}
            </PopperDropdownButton>
          ))}
        </PopperDropdown>
      )}
    </div>
  );
};
