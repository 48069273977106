import * as React from 'react';
import { useEffect, useState } from 'react';

import cn from 'classnames';
import tinytime from 'tinytime';

import { getParticipation } from '@api/queries';
import { Button } from '@components/common';
import { getSelectedItemPath } from '@components/common/SortableTree/utils';
import { ProfileSlideoutRouter } from '@components/RepoSessionApp/ProfileSlideout/ProfileSlideoutRouter';
import { ScreenerResponsesZDS } from '@components/ScreenerResults/components/ScreenerResponsesZDS';
import { isDisplayable } from '@components/ScreenerResults/utils';
import { ParticipationPill } from '@components/shared/ParticipationPill';
import { Card } from '@components/shared/Skeleton';
import { SEND_INVITES, StudyAction } from '@components/StudiesApp/components/StudyPublished/components/ACTIONS';
import { BulkActions } from '@components/StudiesApp/components/StudyPublished/components/BulkActions';
import { EmailSlideOuts } from '@components/StudiesApp/components/StudyPublished/components/EmailSlideouts';
import { getProfileSlideOutSections } from '@components/StudiesApp/components/StudyPublished/components/ParticipantsTab/utils';
import { setHash } from '@components/utils';
import { isAnswerOfFieldType } from '@helpers/isAnswerOfFieldType';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { usePermission } from '@hooks/usePermission';
import { ProfileContextProvider } from '@hooks/useProfileContext';
import { useToaster } from '@stores/toaster';
import { TextCarousel } from 'components/ScreenerResults/ScreenerChart/components/TextCarousel';
import { api } from 'components/SurveyBuilder';

import { ResponseDetails as CardSortResponseDetails } from './components/CardSortResponse/components/ResponseDetails';
import { ResponseDetails as TreeTestResponseDetails } from './components/TreeTestResponse/components/ResponseDetails';
import { useBrowseButtons } from './useBrowseButtons';

const dateTemplate = tinytime('{Do} {MM} {h}:{mm} {a}');

const dateOfBirth = tinytime('{DD} {MMMM} {YYYY}');

interface ScreenerAnswerProps {
  answer: ScreenerResponseAnswer;
  slideOutView?: boolean;
}

const COLS_BREAKPOINTS = {
  768: 1,
  1024: 2,
  1280: 2,
  default: 2
};

export const ScreenerAnswer: React.FC<React.PropsWithChildren<ScreenerAnswerProps>> = ({ slideOutView, answer }) => {
  const noResponse = !answer;

  const { data } = api.useGetSurveyBuilderCardSortBlockableQuery(
    { id: answer.blockable_id! },
    { skip: !answer.blockable_id || answer.field_type !== 'card_sort' }
  );

  const { data: treeTestData } = api.useGetSurveyBuilderTreeTestBlockableQuery(
    { id: answer.blockable_id! },
    { skip: !answer.blockable_id || answer.field_type !== 'tree_test' }
  );

  const renderAnswers = () => {
    if (isAnswerOfFieldType(answer, 'long_text')) {
      return <TextCarousel data={[answer.value]} total={1} />;
    }

    if (isAnswerOfFieldType(answer, 'date')) {
      return <span>{dateOfBirth.render(new Date(answer.value))}</span>;
    }

    if (isAnswerOfFieldType(answer, 'multiple_choice') && Array.isArray(answer.value)) {
      return (
        <ul>
          {answer.value.map((v) => (
            <li key={v}>{v}</li>
          ))}
        </ul>
      );
    }

    if (isAnswerOfFieldType(answer, 'prototype_test')) {
      if (answer.value) {
        return <span>{answer.value.completed ? 'Success' : 'Failed'}</span>;
      } else {
        return <span>No response</span>;
      }
    }

    if (isAnswerOfFieldType(answer, 'card_sort')) {
      if (data?.merged_categories && answer?.value) {
        return (
          <CardSortResponseDetails
            answer={answer.value}
            colsBreakpoints={slideOutView ? COLS_BREAKPOINTS : undefined}
            mergedCategories={data.merged_categories}
          />
        );
      } else {
        return null;
      }
    }

    if (isAnswerOfFieldType(answer, 'tree_test')) {
      const correctAnswers = getSelectedItemPath(treeTestData?.nodes || []);

      return (
        <TreeTestResponseDetails
          nodes={treeTestData?.nodes || []}
          answer={answer.value}
          correctAnswers={correctAnswers}
        />
      );
    }

    return <span>{typeof answer.value === 'string' && answer.value}</span>;
  };

  return (
    <div key={answer.label}>
      <span className='block pb-4 text-lg font-bold'>{answer.label}</span>
      {noResponse ? (
        <span>No response</span>
      ) : (
        <div
          className={cn(
            {
              'bg-white': isAnswerOfFieldType(answer, 'card_sort') || isAnswerOfFieldType(answer, 'tree_test'),
              'max-w-3xl bg-indigo-50 p-4':
                !isAnswerOfFieldType(answer, 'card_sort') && !isAnswerOfFieldType(answer, 'tree_test')
            },
            'font-xl relative flex w-full flex-col items-start justify-center rounded-md text-gray-700'
          )}
        >
          {renderAnswers()}
        </div>
      )}
    </div>
  );
};

interface Props {
  withBorders?: boolean;
  responses?: ScreenerResponseResults['individual'];
  isLoading?: boolean;
  study: Study;
  setBackgroundTask?: (backgroundTask: BackgroundTask) => void;
}

export const ScreenerResultsIndividual: React.FC<React.PropsWithChildren<Props>> = ({
  isLoading,
  withBorders,
  responses,
  study,
  setBackgroundTask
}) => {
  const [fetchingParticipation, setFetchingParticipation] = useState(false);

  const canUpdate = usePermission<Study>('updateStudy')(study);

  const showToast = useToaster();

  const [mode, setMode] = useState<StudyAction | null>(null);

  const [participation, setParticipation] = useState<Participation>();

  const [profileSlideoutOpen, setProfileSlideoutOpen] = useState(false);

  const { create } = useCandidateAttrs();

  const hashToArray = window.location.hash.substring(1).split('/');

  const currentRespIndex = responses?.findIndex((r) => r.customer_id === parseInt(hashToArray[hashToArray.length - 1]));

  const { thing: response, renderBrowseButtons } = useBrowseButtons<ScreenerResponseResultsIndividual>({
    things: responses,
    initialIndex: currentRespIndex === -1 ? 0 : currentRespIndex,
    hashField: 'customer_id'
  });

  useEffect(() => {
    if (response && !window.location.hash) {
      setHash(`${response.customer_id}`);
    }
  }, [response]);

  useEffect(() => {
    if (response?.participation.id) {
      setFetchingParticipation(true);
      getParticipation(response?.participation.id)
        .then((res) => {
          setParticipation(res);
        })
        .catch(() => {
          showToast({
            heading: 'Something went wrong!',
            icon: 'error',
            text: `Something went wrong getting the participant data. Please try again later.`
          });
        })
        .finally(() => {
          setFetchingParticipation(false);
        });
    }
  }, [response?.participation.id]);

  const renderProfileSlideoutHeader = () => (
    <div className='flex items-center space-x-2'>
      <span className='h600 block'>{study.title}</span>
      {participation && <ParticipationPill participation={participation} />}
    </div>
  );

  const renderProfileSlideoutActions = (activeParticipation: Participation) => () =>
    canUpdate &&
    participation && (
      <BulkActions
        onSlideoutActions
        closeAll={() => {
          setMode(null);
          setProfileSlideoutOpen(false);
        }}
        selectedIds={[activeParticipation.id]}
        study={study}
        currentStatus={participation.status}
        onSuccess={(backgroundTask) => {
          if (backgroundTask) {
            setBackgroundTask?.(backgroundTask);
          }
          setMode(null);
          setProfileSlideoutOpen(false);
        }}
        mode={mode}
        closeProfileSlideout={() => setProfileSlideoutOpen(false)}
        setMode={setMode}
        candidateId={activeParticipation.customer_id}
      />
    );

  if (isLoading || fetchingParticipation)
    return (
      <div aria-label='Loader' className='h-full'>
        <Card height='100%' />
      </div>
    );

  if (!response) {
    return <ScreenerResponsesZDS tab='individual' type='no_results' withBorders={withBorders} />;
  }

  if (!participation) return null;

  const submittedAtStr = response.answers_json[0]?.submitted_at;

  const submittedAt = submittedAtStr && dateTemplate.render(new Date(submittedAtStr));

  return (
    <div className='rounded border border-gray-200 bg-white p-6'>
      <div className='pb-8'>
        <div className='mb-4'>{renderBrowseButtons()}</div>
        <div className='flex items-center'>
          <div className='flex-grow'>
            <button
              className='cursor-pointer text-2xl hover:text-indigo-600'
              onClick={() => {
                setProfileSlideoutOpen(true);
                setHash(`candidates/${participation?.customer_id}`);
              }}
            >
              {participation?.name}
            </button>
          </div>
          <div className='flex space-x-4'>
            <Button
              data-testid='view-profile'
              onClick={() => {
                setProfileSlideoutOpen(true);
                setHash(`candidates/${participation?.customer_id}`);
              }}
              link
            >
              View profile
            </Button>
            {['shortlisted', 'started', 'fresh'].includes(participation.status) && (
              <Button data-testid='send-invite' onClick={() => setMode(SEND_INVITES)}>
                Send invite…
              </Button>
            )}
          </div>
        </div>
        {submittedAt && <span className='block pb-4 pt-2 text-sm text-gray-500'>Submitted {submittedAt}</span>}
      </div>
      <div className='space-y-8 pb-8'>
        {response.answers_json
          .filter(({ field_type }) => isDisplayable(field_type))
          .map((answer, idx) => (
            <ScreenerAnswer key={idx} answer={answer} />
          ))}
      </div>

      {profileSlideoutOpen && participation?.customer && (
        <ProfileContextProvider>
          <ProfileSlideoutRouter
            canUpdate={canUpdate}
            createAttr={create}
            header={renderProfileSlideoutHeader()}
            candidate={participation.customer}
            open={profileSlideoutOpen}
            activeParticipation={participation}
            onClose={() => setProfileSlideoutOpen(false)}
            renderActions={renderProfileSlideoutActions(participation)}
            showSections={getProfileSlideOutSections({ study, activeParticipation: participation })}
          />
        </ProfileContextProvider>
      )}

      <EmailSlideOuts
        study={study}
        participations={[participation]}
        onClose={() => setMode(null)}
        mode={mode}
        onSuccess={(backgroundTask) => {
          if (backgroundTask) {
            setBackgroundTask?.(backgroundTask);
          }
          setMode(null);
        }}
      />
    </div>
  );
};
