import * as React from 'react';

import { api } from '@api/reduxApi';

import { AssetShareAccessDropdown } from './AssetShareAccessDropdown';

type Props = {
  readOnly?: boolean;
  token: string;
  value: ShareAccess;
};
export const HighlightReelShareAccessDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  token,
  value,
  readOnly
}) => {
  const [updateHighlightReel] = api.useUpdateHighlightReelMutation();

  return (
    <AssetShareAccessDropdown
      initialValue={value}
      onUpdate={(access) => updateHighlightReel({ token, access })}
      readOnly={readOnly}
    />
  );
};
