import React, { useMemo, useRef, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { useInputState } from '@components/CandidateAttrs/hooks/useInputState';
import { getWrapperClass } from '@components/CandidateAttrs/index';
import { api } from 'api/reduxApi';
import { SelectDropdown } from 'components/shared/SelectDropdown';

export const MultipleChoiceInput: React.FC<React.PropsWithChildren<CandidateAttrInput<string[] | ''>>> = ({
  attr,
  value,
  setValue,
  autoFocus,
  onSave,
  styles,
  label,
  onFocus,
  onBlur
}) => {
  const [query, setQuery] = useState<string>('');
  const { handleFocus, focused } = useInputState(value, setValue, onBlur, onFocus);

  const ref = useRef<HTMLSpanElement[]>([]);

  const { data, isFetching } = api.useGetCandidateAttrsSuggestionsQuery({ id: attr?.id, q: query }, { skip: !attr });

  const items = useMemo(() => data?.map((v) => ({ label: v.toString(), value: v })), [data]);

  const selected = useMemo(() => {
    if (value && value.length) {
      return value?.map((v) => ({ label: v.toString(), value: v }));
    }
    return [];
  }, [value]);

  const { callback } = useDebouncedCallback((value) => {
    setQuery(value);
  }, 500);

  const valueToArray = (value: string | string[]): string[] => (Array.isArray(value) ? value : [value]);
  const values = value ? valueToArray(value) : [];

  const remove = (val) => {
    setValue(values.filter((v) => v !== val));
    onSave?.(values.filter((v) => v !== val));
  };

  return (
    <tr data-testid='attr-field' className={getWrapperClass(styles, focused)}>
      <td className={styles?.label}>{label}</td>
      <td className={styles?.value}>
        <div>
          {values.length > 0 && (
            <div className='mb-3 space-x-2'>
              {values.map((v, i) => (
                <span key={v}>
                  <div className='group inline-block space-x-2 rounded-full border border-gray-200 bg-gray-50 px-2 py-1'>
                    <span>{v}</span>
                    <span
                      ref={(el) => {
                        if (el) {
                          ref.current[i] = el;
                        }
                      }}
                      className='inline-block cursor-pointer font-bold'
                      onClick={() => remove(v)}
                      aria-label={`Remove ${v}`}
                    >
                      x
                    </span>
                  </div>
                </span>
              ))}
            </div>
          )}
          <SelectDropdown
            options={items ?? []}
            value={selected ?? []}
            loading={isFetching}
            onInputValueChange={(value) => callback(value)}
            onChange={(newValue) => {
              setValue(newValue.map((v) => v.value));
              onSave?.(newValue.map((v) => v.value));
            }}
            overrides={{
              Input: {
                props: {
                  placeholder: 'Enter…',
                  autoFocus: true,
                  onFocus: handleFocus
                }
              },
              Item: {
                props: {
                  className: 'xx-combo-option'
                }
              }
            }}
            onCreate={(newValue) => {
              setValue([...values, newValue.value]);
              onSave?.([...values, newValue.value]);
            }}
            creatable
            multi
            defaultIsOpen
            keepSelectedOptions
            closeOnSelect={false}
          />
        </div>
      </td>
    </tr>
  );
};
