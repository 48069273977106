import * as Models from '@components/SurveyBuilder/types/models';

import * as Enums from './types/enums';

export const BLOCK_KIND_LABELS: { [key in Enums.Kind]: string } = {
  [Enums.Kind.date]: 'Date',
  [Enums.Kind.email]: 'Email',
  [Enums.Kind.info]: 'Info',
  [Enums.Kind.linearScale]: 'Linear scale',
  [Enums.Kind.location]: 'Location',
  [Enums.Kind.longText]: 'Long text',
  [Enums.Kind.multiSelect]: 'Multi select',
  [Enums.Kind.number]: 'Number',
  [Enums.Kind.permissions]: 'Permissions',
  [Enums.Kind.prototypeTest]: 'Prototype test',
  [Enums.Kind.shortText]: 'Short text',
  [Enums.Kind.singleSelect]: 'Single select',
  [Enums.Kind.thankYou]: 'Thank you',
  [Enums.Kind.website]: 'Text: website URL',
  [Enums.Kind.welcome]: 'Welcome',
  [Enums.Kind.yesNo]: 'Yes / No',
  [Enums.Kind.websiteTest]: 'Website test (codeless)',
  [Enums.Kind.cardSort]: 'Card sort',
  [Enums.Kind.treeTest]: 'Tree test'
};

export const BLOCK_PALETTES: { [key in Enums.Kind]: string } = {
  [Enums.Kind.date]: '#9F46F4',
  [Enums.Kind.email]: '#48DAFD',
  [Enums.Kind.info]: '#FF4BA6',
  [Enums.Kind.linearScale]: '#0064FB',
  [Enums.Kind.location]: '#FF78FF',
  [Enums.Kind.longText]: '#FFA800',
  [Enums.Kind.multiSelect]: '#6457F9',
  [Enums.Kind.number]: '#19DB7E',
  [Enums.Kind.permissions]: '#374151',
  [Enums.Kind.prototypeTest]: '#FB5779',
  [Enums.Kind.shortText]: '#FFD100',
  [Enums.Kind.singleSelect]: '#00D4C8',
  [Enums.Kind.thankYou]: '#374151',
  [Enums.Kind.website]: '#ACE60F',
  [Enums.Kind.welcome]: '#374151',
  [Enums.Kind.yesNo]: '#FF7511',
  [Enums.Kind.websiteTest]: '#FF93AF',
  [Enums.Kind.cardSort]: '#FF4BA6',
  [Enums.Kind.treeTest]: '#A4508B'
};

export const LOCKED_BLOCK_TYPES: `${Enums.Kind}`[] = [Enums.Kind.permissions, Enums.Kind.welcome, Enums.Kind.thankYou];

export const HIDDEN_BLOCK_TYPES: `${Enums.Kind}`[] = [Enums.Kind.website];

export const BLOCK_SETTINGS_FORM_DEFAULT_VALUES: Record<
  Extract<
    Enums.Kind,
    | Enums.Kind.linearScale
    | Enums.Kind.multiSelect
    | Enums.Kind.prototypeTest
    | Enums.Kind.singleSelect
    | Enums.Kind.websiteTest
    | Enums.Kind.cardSort
    | Enums.Kind.treeTest
  >,
  unknown
> = {
  [Enums.Kind.linearScale]: {
    options: [
      { label: '', position: 1 },
      { label: '', position: 2 },
      { label: '', position: 3 },
      { label: '', position: 4 },
      { label: '', position: 5 }
    ]
  },
  [Enums.Kind.multiSelect]: { options: ['Option 1'] },
  [Enums.Kind.prototypeTest]: { url: '', vendor: 'figma' },
  [Enums.Kind.singleSelect]: { options: ['Option 1'] },
  [Enums.Kind.websiteTest]: { url: '' },
  [Enums.Kind.cardSort]: {
    sort_type: 'closed',
    cards: [],
    categories: [],
    require_all_sorted: true,
    randomise_cards: false,
    randomise_categories: false
  },
  [Enums.Kind.treeTest]: {
    randomise_tree_nodes: false,
    tree_nodes: []
  }
};

export const NON_QUESTION_KINDS: `${Enums.Kind}`[] = [
  Enums.Kind.prototypeTest,
  Enums.Kind.permissions,
  Enums.Kind.websiteTest,
  Enums.Kind.cardSort,
  Enums.Kind.treeTest
];

/**
 * @deprecated
 * Old implementation that should be removed
 */
export const _BLOCKABLE_TYPES: { [key in Enums.Kind]: string } = {
  [Enums.Kind.date]: 'Questions::Date',
  [Enums.Kind.email]: 'Questions::Email',
  [Enums.Kind.info]: 'Questions::Info',
  [Enums.Kind.linearScale]: 'Questions::LinearScale',
  [Enums.Kind.location]: 'Questions::Location',
  [Enums.Kind.longText]: 'Questions::LongText',
  [Enums.Kind.multiSelect]: 'Questions::MultiSelect',
  [Enums.Kind.number]: 'Questions::Number',
  [Enums.Kind.permissions]: 'Permissions',
  [Enums.Kind.prototypeTest]: 'PrototypeTest',
  [Enums.Kind.shortText]: 'Questions::ShortText',
  [Enums.Kind.singleSelect]: 'Questions::SingleSelect',
  [Enums.Kind.thankYou]: 'Questions::ThankYou',
  [Enums.Kind.website]: 'Questions::Website',
  [Enums.Kind.welcome]: 'Questions::Welcome',
  [Enums.Kind.yesNo]: 'Questions::YesNo',
  [Enums.Kind.websiteTest]: 'WebsiteTest',
  [Enums.Kind.cardSort]: 'CardSort',
  [Enums.Kind.treeTest]: 'TreeTest'
};

export const BETA_KINDS: Models.BlockKind[] = [Enums.Kind.cardSort, Enums.Kind.treeTest];
