import React, { useState } from 'react';

import { api } from '@api/reduxApi';
import { Text, Toggle } from '@components/common';
import { RestrictedAction } from '@components/shared/RestrictedAction';
import { track } from '@components/tracking';
import { useAccount } from '@hooks/useAccount';

export const DataRetention: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { account } = useAccount();
  const { delete_data_after_six_months } = account;
  const [updateDataRetention, {}] = api.useUpdateDataRetentionMutation();

  const change = async (on) => {
    track('toggled_data_retention', { on });

    await updateDataRetention(on);
  };

  return (
    <>
      <Text as='h1' h='700' className='mb-1'>
        Data retention
      </Text>
      <Text h='500' color='gray-500' className='monitor:mb-6 mb-4'>
        Set rules to automatically delete data from Great Question.
      </Text>
      <div className='flex-1 rounded-md border border-gray-200 bg-white p-6 pt-10'>
        <RestrictedAction feature='data_retention'>
          {({ can, may }) => (
            <div className='flex flex-row items-center space-x-2'>
              <Toggle on={delete_data_after_six_months} onToggle={(on) => change(on)} disabled={!can || !may} />
              <Text h='400'>Automatically delete all Recordings and Transcripts after 6 months.</Text>
            </div>
          )}
        </RestrictedAction>
      </div>
    </>
  );
};
