import { createContext, useContext } from 'react';

import { FieldErrors, UseFormMethods } from 'react-hook-form';

export interface FormData {
  name: string;
  job_title: string;
}

export interface Context {
  formData: FormData;
  errors: FieldErrors;
  register: UseFormMethods['register'];
  isLoading: boolean;
  currentStep?: number;
  totalSteps?: number;
  setCurrentStep?: (step: number) => void;
}

export const FormContext = createContext<Context | undefined>(undefined);

export const useFormContext = () => {
  const formContext = useContext<Context | undefined>(FormContext);

  if (!formContext) throw new Error();

  return formContext;
};

FormContext.displayName = 'FormContext';
