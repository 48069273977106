import React, { useEffect, useMemo } from 'react';

import pluralize from 'pluralize';
import { CellProps, useFlexLayout, usePagination, useResizeColumns, useSortBy, useTable } from 'react-table';

import { api } from '@api/reduxApi';
import { Button, Text } from '@components/common';
import { useCollectionView } from '@components/stores/view';
import { usePermission } from '@hooks/usePermission';

import { buildColumns } from '../utils/buildColumns';

import { AttrScope } from './AccountAttrsIndex';
import { Skeleton } from './Skeleton';

interface Props {
  scope: AttrScope;
  search: string;
  setEdit: (v: Attr_ | null) => void;
}

type ExtraProps = { tabIndex: number };

export type CellFC = React.FC<React.PropsWithChildren<CellProps<any> & ExtraProps>>;

export const Th: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <div style={{ minWidth: '150px' }} className='flex px-6 py-3 text-left' {...props} />
);

export const Td: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '', ...props }) => (
  <div
    style={{ minWidth: '150px' }}
    className={`flex items-center border-t border-gray-200 px-6 py-3 ${className}`}
    {...props}
  />
);

const queries = {
  study: api.useGetStudyAttrsQuery,
  candidate: api.useGetCandidateAttrsQuery
};

export const AttrsTable: React.FC<React.PropsWithChildren<Props>> = ({ scope, search, setEdit }) => {
  const {
    data: attrs,
    isLoading,
    isSuccess,
    isUninitialized
  } = queries[scope]({ refetchOnMountOrArgChange: true } as any);

  const canEdit = usePermission('manageAttrs')();

  const allColumns = useMemo(() => buildColumns({ canEdit, setEdit }), [canEdit]);

  const {
    view: { sort }
  } = useCollectionView();

  const filteredAttrs = useMemo(() => {
    return attrs?.filter((attr) => attr.label.toLowerCase().includes(search.toLowerCase()));
  }, [attrs, search]);

  const attrsCount = useMemo(() => {
    const count = filteredAttrs?.length || 0;

    return `${count.toLocaleString()} ${pluralize('attribute', count)}`;
  }, [filteredAttrs]);

  const data = useMemo(() => filteredAttrs || [], [filteredAttrs, sort]);

  const initialState = useMemo(
    () => ({
      pageIndex: 0,
      pageSize: 10,
      sortBy: [{ id: sort.value, desc: sort.desc }]
    }),
    [sort.value, sort.desc]
  );

  const {
    getTableProps,
    getTableBodyProps,
    page,
    setSortBy,
    canPreviousPage,
    previousPage,
    nextPage,
    canNextPage,
    headerGroups,
    pageOptions,
    prepareRow,
    state: { pageIndex }
  } = useTable<Attr_>(
    {
      columns: allColumns,
      data,
      autoResetFilters: false,
      initialState
    },
    useSortBy,
    usePagination,
    useFlexLayout,
    useResizeColumns
  );

  useEffect(() => {
    setSortBy([{ id: sort.value, desc: sort.desc }]);
  }, [sort.value, sort.desc]);

  if (!data?.length && isSuccess) return <></>;

  if (isLoading || isUninitialized) {
    return <Skeleton />;
  }

  return (
    <>
      <div className='flex items-center justify-between py-4'>
        <Text h='400' color='gray-500'>
          {attrsCount}
        </Text>
        <div className='focus:outline-none mr-2 flex items-center space-x-2 rounded-md border border-gray-200 bg-white'>
          <Button
            className='xx-prev-page'
            small
            text
            icon='chevronLeft'
            onClick={previousPage}
            disabled={!canPreviousPage}
          />
          <Text h='400'>
            Page {pageIndex + 1} of {pageOptions.length}
          </Text>
          <Button className='xx-next-page' small text icon='chevronRight' onClick={nextPage} disabled={!canNextPage} />
        </div>
      </div>
      <div className='w-full'>
        <div className='h500 w-full overflow-x-auto rounded-md border border-gray-200 bg-white' {...getTableProps()}>
          <div className='flex'>
            {headerGroups.map((headerGroup) =>
              headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps({
                    style: {
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      display: 'flex'
                    }
                  })}
                >
                  {column.render('Header')}
                </Th>
              ))
            )}
          </div>
          <div {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
