import * as React from 'react';

import { TeamIcon } from '@components/common';
import { CaretDownSVG, CaretSVG } from '@components/svgs';

import { NavItem } from '../NavItem';
import * as svgs from '../svgs';

type Props = {
  team: Team;
  pathname: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};
export const TeamNav: React.FC<React.PropsWithChildren<Props>> = ({ team, pathname, isOpen, onOpen, onClose }) => {
  const candidatesPath = `/teams/${team.id}/candidates`;

  const studiesPath = `/teams/${team.id}/studies`;

  return (
    <div>
      <button
        name='toggle'
        className='focus:outline-none h400 group block w-full flex-shrink-0 px-4 py-2 font-medium text-white visited:text-white hover:bg-gray-700 hover:text-white focus:text-white'
        onClick={() => (isOpen ? onClose() : onOpen())}
      >
        <div className='flex items-center'>
          <TeamIcon className='mr-2' team={team} />
          <div className='truncate'>{team.name}</div>
          <div className='ml-3'>
            {isOpen && <CaretDownSVG className='text-white' />}
            {!isOpen && <CaretSVG className='text-white' />}
          </div>
        </div>
      </button>
      {isOpen && (
        <div className='pl-3'>
          <NavItem name='Candidates' href={candidatesPath} isActiveNav={pathname === candidatesPath} spa>
            <svgs.candidates />
            Candidates
          </NavItem>
          <NavItem name='Studies' href={studiesPath} isActiveNav={pathname === studiesPath} spa>
            <svgs.studies />
            Studies
          </NavItem>
        </div>
      )}
    </div>
  );
};
