import { StudySlideOut } from '@components/StudiesApp/types';
import { Args as CreateStudyArgs } from '@hooks/useCreateStudy';

export type FeatureType =
  | 'external_recruitment'
  | 'calendar_v2'
  | 'reporting'
  | 'unmoderated_internal_launch'
  | 'unmoderated_upgrade'
  | 'ai'
  | 'unmoderated_card_sort';

type FeatureValues = {
  header: string;
  cta?: string;
  guide_link?: string;
  subheader: string;
  options: string[];
  cta_url?: string;
  slideout?: StudySlideOut;
  video_url?: string;
  isBeta?: boolean;
  bannerSidesWidth?: [string, string];
  createStudyProps?: CreateStudyArgs;
};

type FeatureData = Record<FeatureType, FeatureValues>;

export const FEATURE_DATA: FeatureData = {
  external_recruitment: {
    header: 'Recruit external candidates',
    cta: 'Recruit external candidates',
    guide_link: 'https://greatquestion.co/support/studies/external-participant-recruitment',
    subheader: 'Recruit from Respondent’s panel of 3M+ B2B and B2C participants',
    options: [
      'Define your target audience of participants to recruit',
      'Submit a recruitment request form to Respondent',
      'Screen participants and conduct research'
    ],
    slideout: 'NewRecruitingStudySlideout'
  },
  calendar_v2: {
    header: 'Calendar 2.0',
    cta: 'Start a new study',
    guide_link: 'https://greatquestion.co/support/studies/scheduling-tool',
    subheader: 'We’ve re-designed the experience of setting up your research schedule.',
    options: [
      'Better organization of calendar options',
      'Tab between your calendar and the participant preview',
      'Flexible options for setting availability and viewing events'
    ],
    slideout: 'NewStudySlideout'
  },
  reporting: {
    header: 'Track research metrics with Reporting',
    subheader: 'Detailed data available at your fingertips.',
    options: [
      'Visualize how much research your team is doing',
      'Track metrics on how efficient your research process is',
      'Communicate high-level data with your stakeholders'
    ],
    cta: 'View Reporting',
    cta_url: '/reporting'
  },
  unmoderated_internal_launch: {
    header: 'New Unmoderated Prototype Testing',
    cta: 'Create unmoderated test',
    subheader: 'Get fast, async user feedback on your Figma prototypes',
    options: [
      'Run browser-based tests on desktop or mobile (no apps required)',
      'Test with your own users or recruit from our external panel',
      'View performance with metrics, timelines, paths, and click maps',
      'Dig deeper with recordings and transcripts, and create highlight reels'
    ],
    slideout: 'NewStudySlideout',
    isBeta: false,
    video_url: 'https://gq-blog.s3.us-west-1.amazonaws.com/Unmoderated_Walkthrough.mp4'
  },
  unmoderated_upgrade: {
    header: 'New Unmoderated Prototype Testing',
    cta: 'Start Teams trial',
    cta_url: '/plans',
    subheader: 'Get fast, async user feedback on your Figma prototypes',
    options: [
      'Run browser-based tests on desktop or mobile (no apps required)',
      'Test with your own users or recruit from our external panel',
      'View performance with metrics, timelines, paths, and click maps',
      'Dig deeper with recordings and transcripts, and create highlight reels'
    ],
    isBeta: false
  },
  ai: {
    header: 'Ask AI',
    cta: 'Open Ask AI',
    subheader: 'Level up your research synthesis and streamline research operations.',
    options: [
      'Query studies with up to 50 hours of interview data in seconds',
      'Automatically generate summaries, chapters, highlights, and tags after interviews',
      'Trace every quote in AI responses back to its original transcript in one click'
    ],
    cta_url: '/ai_preferences',
    isBeta: true,
    bannerSidesWidth: ['2/3', '1/3']
  },
  unmoderated_card_sort: {
    header: 'Run an unmoderated card sort',
    subheader: 'Discover how people understand and categorize information.',
    options: [
      'Enhance navigation structure and labeling for better user experience',
      'Gain insights into user mental models for informed decision-making',
      'Design efficiently and with greater confidence'
    ],
    cta: 'Create unmoderated study',
    isBeta: true,
    createStudyProps: {
      style: 'unmoderated_test'
    },
    guide_link: 'https://greatquestion.co/support/research-methods/unmoderated-card-sorting'
  }
};
