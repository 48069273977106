import React, { FC } from 'react';

import { ParticipationPill } from '@components/shared/ParticipationPill';
import { CalendarWithClockSVG, ClockRefreshSVG, WaitlistedSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

interface Props {
  participation: Participation;
  onClickProposedTime: () => void;
}

export const StatusCell: FC<Props> = ({ participation, onClickProposedTime }) => {
  return (
    <div className='flex h-full w-full items-center space-x-2 px-2'>
      <ParticipationPill participation={participation} />

      {participation.waitlisted_at && (
        <Tooltip content='On waitlist'>
          <WaitlistedSVG className='text-yellow-600' aria-label='On waitlist' />
        </Tooltip>
      )}

      {participation.reschedule_requested_at && (
        <Tooltip content='Sent reschedule request'>
          <ClockRefreshSVG className='text-gray-500' />
        </Tooltip>
      )}

      {participation.time_proposal && (
        <Tooltip content='Proposed alternative times'>
          <button
            className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-gray-100'
            aria-label='Review time proposal'
            name='Review time proposal'
            onClick={onClickProposedTime}
          >
            <CalendarWithClockSVG className='text-red-600' />
          </button>
        </Tooltip>
      )}
    </div>
  );
};
