import * as React from 'react';
import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { useBulkUploadActions } from '@components/BulkUploadFlow';
import { Text } from '@components/common';
import { Basic } from '@components/shared/Skeleton';
import { StudySelector } from 'components/shared/StudySelector';
import { useStudies } from 'hooks/useStudies';

import { CellFC } from './types';

export const Study: CellFC = ({ row, studyId }) => {
  const [updateRepoSession, { isSuccess, data, isLoading }] = api.useUpdateRepoSessionMutation();

  const { loading, studies } = useStudies();

  const predefinedStudy = studies.find((s) => s.id === studyId);

  const { setItem } = useBulkUploadActions();
  const { session, id } = row.original;

  const [edit, setEdit] = useState(!predefinedStudy);

  useEffect(() => {
    if (isSuccess) {
      setItem({ id, session: data });
    }
  }, [isSuccess]);

  const [study, setStudy] = useState<Study | undefined>();

  const handleSelect = (study) => {
    if (study) {
      setStudy(study);
      updateRepoSession({
        uuid: session.uuid,
        project_id: study.id
      });
    }
    setEdit(false);
  };

  if (isLoading || loading) {
    return (
      <Text className='h-10 w-60'>
        <Basic width='w-60' h='10' />
      </Text>
    );
  }

  if (predefinedStudy) {
    return (
      <Text h='400' className='h-10 w-60 truncate px-4 py-3'>
        {predefinedStudy.title}
      </Text>
    );
  }
  if (!edit && study) {
    return (
      <Text h='400' className='h-10 w-60 truncate px-4 py-3'>
        {study.title}
      </Text>
    );
  }

  return <StudySelector disabled={!!study} study={study} setStudy={handleSelect} inputClassName='text-sm h-10' />;
};
