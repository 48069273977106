import React, { useMemo, useState } from 'react';

import pluralize from 'pluralize';
import Tippy from '@tippyjs/react';

import { api } from '@api/reduxApi';
import { Button, Pill, Text } from '@components/common';
import { CORE_ATTRS } from '@components/config';
import {
  useGetEligibilityRulesQuery,
  useUpdateEligibilityRulesMutation
} from '@components/GovernanceApp/components/Eligiblity/api';
import { LimitModal } from '@components/GovernanceApp/components/Eligiblity/components/LimitModal';
import { EligibilityRule, EligibilityRulesResp } from '@components/GovernanceApp/components/Eligiblity/types';
import { decodeRules, updateRulesArray } from '@components/GovernanceApp/components/Eligiblity/utils';
import { PlanUpgradeModal } from '@components/shared/RestrictedAction/PlanUpgradeModal';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { buildCandidateFilterDefs, useTableFilters } from '@components/shared/TableFilters';
import { FilterState } from '@components/shared/TableFilters/types';
import { parse } from '@components/shared/TableFilters/utils/encode';
import { InfoCircleIcon, PencilSVG, TrashSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { track } from '@components/tracking';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { useFeature } from '@hooks/useFeature';
import { useTeams } from '@hooks/useTeams';
import { useToaster } from '@stores/toaster';

import { buildEligibilityRuleFilterDefs } from './components/buildEligibilityRuleDefs';
import { SkeletonLoader } from './components/SkeletonLoader';
import { DeleteRuleModal, RuleSlideOut } from './components';
import * as toasts from './toasts';

const MAX_RULES = 6;

interface Props {
  canEdit: boolean;
}

export const Eligibility: React.FC<React.PropsWithChildren<Props>> = ({ canEdit }) => {
  const { data, isLoading: rulesLoading } = useGetEligibilityRulesQuery();
  const { data: segments } = api.useGetSegmentsQuery();
  const [updateRules] = useUpdateEligibilityRulesMutation();

  const showToast = useToaster();

  const enableTeams = useFeature('teams');

  const { teams } = useTeams({ skip: !enableTeams });
  const { candidateAttrs, isSuccess: attrsFetched } = useCandidateAttrs();

  const [selected, setSelected] = useState<EligibilityRule | null>(null);
  const [upgradeModal, setUpgradeModal] = useState<null | 'plan_upgrade' | 'request_role_upgrade'>(null);
  const [limitModal, setLimitModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [ruleSlideoutOpen, setRuleSlideoutOpen] = useState(false);

  const definitions = useMemo(() => {
    const eligibilityDefs = buildEligibilityRuleFilterDefs();

    const candidateDefs = buildCandidateFilterDefs({
      coreAttrs: CORE_ATTRS,
      customAttrs: candidateAttrs,
      segments: segments || [],
      enableTeams,
      teams
    });

    return [...eligibilityDefs, ...candidateDefs];
  }, [candidateAttrs, segments, teams]);

  const rulesAsFilterStates = useMemo(() => {
    if (!data) return [];

    return decodeRules<Candidate | EligibilityRule>(data, definitions);
  }, [data, definitions]);

  const filtersHook = useTableFilters<Candidate | EligibilityRule>({
    trackKey: 'eligibilityRules',
    definitions
  });

  const saveRules = async (rules: EligibilityRulesResp[]) => {
    await updateRules(rules).unwrap();
    setRuleSlideoutOpen(false);
    setSelected(null);
    filtersHook.clearFilters();
  };

  const onSave = async (filters: FilterState<EligibilityRule>[], { label, title, description }) => {
    if (!data) return;

    const rule: EligibilityRulesResp = {
      label,
      title,
      description,
      filters: filters.map(parse<EligibilityRule>).filter(Boolean) as string[]
    };

    if (!selected) {
      try {
        await saveRules([...data, rule]);
        track('eligibility_rule_created', { title });
        showToast(toasts.successCreate(title));
      } catch {
        showToast(toasts.failedCreate());
      }
      return;
    }

    const updatedRules = updateRulesArray({ oldArray: data, selected, updatedRule: rule });

    try {
      await saveRules(updatedRules);
      track('eligibility_rule_updated', { title: selected.title });
      showToast(toasts.successUpdate(selected.title));
    } catch {
      showToast(toasts.failedUpdate());
    }
  };

  const onAdd = () => {
    track('add_eligibility_rule_clicked');

    if (rulesAsFilterStates.length < MAX_RULES) {
      setRuleSlideoutOpen(true);
      track('add_eligibility_rule_slideout_opened');
    } else {
      setLimitModal(true);
      track('eligibility_rules_limit_modal_opened');
    }
  };

  const onDelete = async (rule: EligibilityRule) => {
    const newRules = data?.filter((r) => r.label !== rule.name);

    if (newRules) {
      try {
        await saveRules(newRules);
        setDeleteModalOpen(false);
        showToast(toasts.successDelete(rule.title));
        track('eligibility_rule_deleted', { title: rule.title });
      } catch {
        showToast(toasts.failedDelete());
      }
    }

    if (ruleSlideoutOpen) {
      setRuleSlideoutOpen(false);
    }
  };

  return (
    <>
      <div className='flex w-full flex-wrap items-center justify-between'>
        <div className='mb-3 mr-2 flex flex-nowrap items-center space-x-2'>
          <Text as='h1' h='700' className='whitespace-nowrap'>
            Candidate eligibility
          </Text>

          <Tippy
            content='Beta testing is our final round of testing before releasing to a wider audience.  Our objective is to uncover as many bugs or usability issues as possible, so we ask you keep that in mind if you do choose to use beta features.'
            placement='right-end'
            arrow={false}
          >
            <div>
              <Pill className='cursor-pointer' color='blue'>
                Beta
              </Pill>
            </div>
          </Tippy>
        </div>

        <div className='mb-3 flex items-center space-x-3 whitespace-nowrap'>
          <Button
            medium
            className='whitespace-nowrap'
            trackEvent='clicked_custom_eligibility_help_link'
            icon='externalLink'
            target='_blank'
            href='https://greatquestion.co/support/governance/candidate-eligibility-settings'
          >
            Read help guide
          </Button>
          {canEdit && (
            <RestrictedButton
              buttonProps={{ icon: 'plus', medium: true, ['aria-label']: 'Add eligibility rule' } as any}
              action='Add eligibility rule'
              setUpgradeModal={setUpgradeModal}
              feature='custom_eligibility'
            >
              <Button
                primary
                aria-label='Add eligibility rule'
                icon='plus'
                onClick={onAdd}
                medium
                className='whitespace-nowrap'
              >
                Create rule
              </Button>
            </RestrictedButton>
          )}
        </div>
      </div>

      <Text h='500' color='gray-500' className='monitor:mb-6 mb-4'>
        Candidates will be considered ineligible to participate if any of the following rules apply. In order to
        maintain system performance, you can have up to 6 eligibility rules.
      </Text>
      <div className='rounded-md rounded-b-none border border-gray-200 bg-white'>
        <div className='overflow-auto'>
          {rulesLoading ? (
            <SkeletonLoader />
          ) : (
            <table className='w-full'>
              <thead>
                <tr className='border-b border-gray-200'>
                  <th className='px-4 py-3 text-left text-sm'>Title</th>
                  <th className='px-4 py-3 text-left text-sm'>Description</th>
                  <th className='whitespace-nowrap px-4 py-3 text-left text-sm'>
                    Criteria{' '}
                    <Tooltip content='Determines which candidates are affected by this rule.'>
                      <InfoCircleIcon className='ml-1 mt-1 h-4 w-4' />
                    </Tooltip>
                  </th>
                  <th className='whitespace-nowrap px-4 py-3 text-left text-sm'>
                    Label{' '}
                    <Tooltip content='Eligibility Eligibility status on the participant when the criteria applies.'>
                      <InfoCircleIcon className='ml-1 mt-1 h-4 w-4' />
                    </Tooltip>
                  </th>

                  {canEdit && <th />}
                </tr>
              </thead>

              <tbody className='divide-y divide-gray-200'>
                {rulesAsFilterStates.map((rule) => {
                  const {
                    name: label,
                    title,
                    description,
                    filters: { filters }
                  } = rule;

                  return (
                    <tr key={label} className='group text-sm text-gray-700'>
                      <td className='whitespace-nowra px-4 py-3 align-top'>{title}</td>
                      <td className='min-w-80 px-4 py-3 align-top'>{description}</td>
                      <td className='whitespace-nowrap px-4 py-3 align-top'>
                        {pluralize('criterias', filters?.length, true)}
                      </td>
                      <td className='px-4 py-3 align-top'>
                        <div className='bg-orange-200 h400 inline whitespace-nowrap rounded-full px-2 py-0.5'>
                          {label}
                        </div>
                      </td>
                      {canEdit && (
                        <td className='px-4 py-1.5 align-top'>
                          <div className='flex h-full flex-nowrap items-center justify-end space-x-2'>
                            <Tippy content='Edit rule' placement='top' arrow={false}>
                              <button
                                className='focus:ring-blue focus:outline-none focus:ring-indigo rounded-full p-2 hover:bg-gray-50 hover:text-indigo-700 focus:ring active:text-indigo-700 disabled:opacity-50'
                                data-testid='edit'
                                onClick={() => {
                                  setSelected(rule);
                                  filtersHook.setFilters(filters);
                                  setRuleSlideoutOpen(true);
                                  track('edit_eligibility_rule_clicked', { title: rule.title });
                                }}
                              >
                                <PencilSVG />
                              </button>
                            </Tippy>
                            <Tippy content='Delete rule' placement='top' arrow={false}>
                              <button
                                className='focus:ring-blue focus:outline-none focus:ring-indigo rounded-full p-2 hover:bg-gray-50 hover:text-indigo-700 focus:ring active:text-indigo-700 disabled:opacity-50'
                                data-testid='delete'
                                onClick={() => {
                                  setSelected(rule);
                                  setDeleteModalOpen(true);
                                  track('delete_eligibility_rule_clicked', { title: rule.title });
                                }}
                              >
                                <TrashSVG />
                              </button>
                            </Tippy>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className='rounded-b-md border border-t-0 border-gray-200 bg-white p-4'>
        <Text h='400'>
          If none of the rules above apply candidates will be considered{' '}
          <span className='h400 inline whitespace-nowrap rounded-full bg-green-100 px-2 py-0.5'>eligible</span> to
          participate in research studies.
        </Text>
      </div>
      <RuleSlideOut
        hook={filtersHook}
        rule={selected}
        open={ruleSlideoutOpen}
        onSave={onSave}
        editable
        onClose={() => {
          setRuleSlideoutOpen(false);
          setSelected(null);
          filtersHook.clearFilters();
        }}
      />
      {selected && canEdit && (
        <DeleteRuleModal
          rule={selected}
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onSubmit={onDelete}
        />
      )}
      {upgradeModal === 'plan_upgrade' && (
        <PlanUpgradeModal
          feature='custom_eligibility'
          onClose={() => {
            setUpgradeModal(null);
            track('upgrade_modal_opened', { feature: 'custom_eligibility' });
          }}
        />
      )}

      <LimitModal open={limitModal} onClose={() => setLimitModal(false)} />
    </>
  );
};
