import React from 'react';

import format from 'date-fns/format';
import { Link } from 'react-router-dom';

import { AvatarFromId } from '@components/common';
import { InterviewsZDS } from '@components/DashboardApp/InterviewsZDS';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import { ClockSVG, LiveSVG } from '@components/svgs';
import { truncate } from '@components/utils';
import { displayUrl } from 'components/StudiesApp/components/StudyPublished/ParticipationTable/components';

interface Props {
  participations?: (Participation & { attendees: User[] })[];
  header: string;
}

export const InterviewsTable: React.FC<React.PropsWithChildren<Props>> = ({ participations, header }) => {
  if (!participations) {
    return <InterviewsZDS />;
  }
  return (
    <div>
      <h2 className='h600 mb-4 mt-12 font-bold'>{header}</h2>
      <div className='w-full rounded-md border border-gray-200 bg-white px-6'>
        <div className='overflow-x-auto'>
          {!participations?.length ? (
            <InterviewsZDS />
          ) : (
            <table className='xx-participations-table w-full divide-y divide-gray-200'>
              <thead>
                <tr>
                  <th className='px-0.5 py-4 text-left text-sm font-bold text-gray-700'>Date & Time</th>
                  <th className='px-0.5 py-4 text-left text-sm font-bold text-gray-700'>Participant</th>
                  <th className='px-0.5 py-4 text-left text-sm font-bold text-gray-700'>Study</th>
                  <th className='px-0.5 py-4 text-left text-sm font-bold text-gray-700'>Moderator</th>
                  <th className='px-0.5 py-4 text-left text-sm font-bold text-gray-700'>Call Link</th>
                  <th className='px-0.5 py-4 text-left text-sm font-bold text-gray-700'></th>
                  <th className='px-0.5 py-4 text-left text-sm font-bold text-gray-700'></th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 overflow-x-auto'>
                {participations.map((p) => (
                  <tr key={p.id}>
                    <td className={`flex flex-col whitespace-nowrap px-0.5 py-4 text-sm text-gray-700`}>
                      {p.interview_at ? (
                        <>
                          <span className='font-bold'>{format(p.interview_at, 'E, MMM d')}</span>
                          <span className='font-normal'>{format(p.interview_at, 'h:mm a O')}</span>
                        </>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td className='whitespace-nowrap px-0.5 py-4 text-sm text-gray-700'>
                      <div
                        className={`flex flex-col items-start ${
                          p.name ? 'font-medium text-gray-700' : 'text-gray-400'
                        }`}
                      >
                        <Link
                          className='w-40 truncate font-medium text-gray-700'
                          to={`/studies/${p.project_id}#candidates/${p.customer_id}`}
                        >
                          {p.name ? p.name : 'Unnamed candidate'}
                        </Link>
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-0.5 py-4 text-sm text-gray-700'>
                      <Link to={`/studies/${p.study?.id}`} className='block w-40 max-w-xs truncate text-gray-700'>
                        {p.study?.title}
                      </Link>
                    </td>
                    <td className='whitespace-nowrap px-0.5 py-4 text-sm text-gray-700'>
                      <div className='flex h-full w-full items-center'>
                        <AvatarFromId userId={p?.moderator_id} />
                      </div>
                    </td>
                    <td className='truncate whitespace-nowrap py-4 pl-0.5 text-sm text-gray-700'>
                      {p.join_url ? (
                        <div className='flex items-center'>
                          <span className='tablet:inline hidden'>{truncate(displayUrl(p.join_url), 18)}</span>
                          <CopyURLButton link className='ml-2 p-6' text={p.join_url} icon='link' />
                        </div>
                      ) : (
                        <span className='text-gray-400'>Not set</span>
                      )}
                    </td>
                    <td className='whitespace-nowrap px-0.5 py-4 text-sm text-gray-700'>
                      {p.live_stream_status === 'scheduled' && (
                        <div className='relative flex flex-row-reverse justify-center'>
                          <div className='flex items-center rounded-full border border-gray-200 px-2 py-1 font-bold uppercase'>
                            <ClockSVG className='h-3.5 w-3.5' />
                            <span className='ml-1 text-xs'>LIVE UPCOMING</span>
                          </div>
                        </div>
                      )}
                      {p.live_stream_status === 'live' && (
                        <div className='relative flex flex-row-reverse justify-center'>
                          <div className='bg-red-450 flex items-center rounded-full px-2 py-1 font-bold uppercase text-white'>
                            <LiveSVG className='h-3.5 w-3.5' />
                            <span className='ml-1 text-xs'>LIVE</span>
                          </div>
                        </div>
                      )}
                    </td>
                    <td className='text-right text-sm'>
                      <Link
                        className='whitespace-nowrap text-indigo-600 hover:text-indigo-700'
                        to={p.interview_path || ''}
                      >
                        <span className='whitespace-nowrap'>Interview room</span>
                        <svg
                          className='icon-sm inline-block fill-current'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 20 20'
                        >
                          <path
                            fill='currentColor'
                            d='M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z'
                          />
                        </svg>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};
