import React from 'react';

import { usePlan } from 'hooks/usePlan';

import { ExternalCandidatesRequestsProvider } from '../../StudyPublished/pages/ExternalCandidatesRequest/utils/ExternalCandidatesRequestsProvider';
import { StudyPublishValidator } from '../hooks/useStudyPublishValidator';

import { PublishStudyButton } from './PublishStudyButton';
import { PublishStudyButtonWithCheckout } from './PublishStudyButtonWithCheckout';

interface Props {
  hasErrors: boolean;
  study: Study;
  saving: boolean;
  setSaving: (value: boolean) => void;
}

export const PublishStudyButtonConditional: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const plan = usePlan();
  const { study } = props;
  const needPlan =
    (!plan.features['external_recruitment'] && study.external_candidates_enabled) ||
    (!plan.features['incentives'] && study.incentive_method === 'tremendous');

  if (needPlan) {
    return (
      <ExternalCandidatesRequestsProvider studyId={study.id}>
        {({ requests }) => <PublishStudyButtonWithCheckout {...props} request={requests[0]} />}
      </ExternalCandidatesRequestsProvider>
    );
  } else {
    return <PublishStudyButton {...props} />;
  }
};
