export const PORT = '8080';
export const HOST = process.env.SELENIUM_DRIVER_URL ? 'web' : 'localhost';
export const USE_SSL_IN_DEV = process.env.USE_SSL_IN_DEV === 'true';
export const PUBLIC_PATH = `${USE_SSL_IN_DEV ? 'https' : 'http'}://${HOST}:${PORT}`;
export const S3_PUBLIC_PATH = 'https://d2uhv8dffgmukf.cloudfront.net/';
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const WEBPACK_APP_ENTRY = 'application.ts';
export const WEBPACK_PUBLIC_ENTRY = 'public.ts';
export const WEBPACK_ADMIN_ENTRY = 'administrate.ts';
export const WEBPACK_OUTPUT_PUBLIC_PATH = IS_PRODUCTION ? S3_PUBLIC_PATH : `${PUBLIC_PATH}/`;
// export const WEBPACK_HMR_ENTRY = 'react-hot-loader/patch';
