import { useMemo } from 'react';

import { ColumnDef } from '@tanstack/react-table';

import { hasInterviewRoom as hasRoom } from '@components/StudiesApp/components/StudyPublished/utils';
import { useFeature } from '@hooks/useFeature';
import { usePermission } from '@hooks/usePermission';

import { activityColumnDefinitions } from '../helpers/activityColumnDefinitions';
import { baseColumnDefinitions } from '../helpers/baseColumnDefinitions';
import { customerColumnDefinitions } from '../helpers/customerColumnDefinitions';
import { extraColumnDefinitions } from '../helpers/extraColumnDefinitions';
import { piiColumnDefinitions } from '../helpers/piiColumnDefinitions';
import { screenerFieldColumnDefinitions } from '../helpers/screenerFieldColumnDefinitions';
import { studyColumnDefinitions } from '../helpers/studyColumnDefinitions';

interface UseColumnDefinitionsProps {
  candidateAttrs: Attr_[];
  study: Study;
  onParticipationClick?: (participation: Participation) => void;
  setBackgroundTask?: (task: BackgroundTask) => void;
  participationStatus: ParticipationStatus;
  limits?: boolean;
  addFilter: (filter: string) => void;
  onTimeProposalClick?: (participation: Participation) => void;
  partyIdToLimit: (id: number) => string;
  onSelectAllClick?: (selected: boolean) => void;
  getColumnLabel: (key: string) => string;
}

export const useColumnDefinitions = ({
  candidateAttrs,
  study,
  onParticipationClick,
  setBackgroundTask,
  participationStatus,
  limits,
  addFilter,
  onTimeProposalClick,
  partyIdToLimit,
  onSelectAllClick,
  getColumnLabel
}: UseColumnDefinitionsProps): ColumnDef<Participation>[] => {
  const canPII = usePermission('showPII')();
  const enableTeams = useFeature('teams');

  const participantLevelIncentives = useFeature('participant_level_incentives');
  const serverSideTableEnabled = useFeature('serverside_participations');
  const canUpdate = usePermission<Study>('updateStudy')(study);
  const hasParticipantLevelIncentives = participantLevelIncentives && canUpdate;
  const hasInterviewRoom = hasRoom({ style: study.style, status: participationStatus });

  const definitions = useMemo<ColumnDef<Participation>[]>(() => {
    return [
      ...baseColumnDefinitions({
        serverSideTableEnabled,
        getColumnLabel,
        onParticipationClick,
        addFilter,
        onSelectAllClick
      }),
      ...piiColumnDefinitions({ getColumnLabel, canPII, addFilter }),
      ...customerColumnDefinitions({ getColumnLabel, enableTeams, addFilter, participationStatus }),
      ...studyColumnDefinitions({
        study,
        getColumnLabel,
        setBackgroundTask,
        hasInterviewRoom,
        hasParticipantLevelIncentives,
        participationStatus,
        limits,
        addFilter,
        onTimeProposalClick,
        partyIdToLimit
      }),
      ...activityColumnDefinitions({ getColumnLabel, study, addFilter }),
      ...extraColumnDefinitions({ candidateAttrs, addFilter, study }),
      ...screenerFieldColumnDefinitions({ study, addFilter, serverSideTableEnabled })
    ];
  }, [
    candidateAttrs,
    canPII,
    enableTeams,
    participationStatus,
    study,
    activityColumnDefinitions,
    baseColumnDefinitions,
    customerColumnDefinitions,
    extraColumnDefinitions,
    getColumnLabel,
    piiColumnDefinitions,
    setBackgroundTask,
    studyColumnDefinitions,
    addFilter,
    limits,
    partyIdToLimit
  ]);

  return definitions;
};
