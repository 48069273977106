import * as React from 'react';

import { Text } from '@components/common';

interface Props extends React.HTMLProps<HTMLDivElement> {
  className?: string;
}

export const HelperText: React.FC<React.PropsWithChildren<Props>> = ({ children, className = undefined, ...props }) => {
  return (
    <Text className={`${className} mb-4`} color='gray-500' h='400'>
      {children}
    </Text>
  );
};
