import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';
import {
  autoUpdate,
  flip as flipMiddleware,
  inline as inlineMiddleware,
  offset as offsetMiddleware,
  safePolygon,
  shift as shiftMiddleware,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole
} from '@floating-ui/react';

import { Portal } from '@components/Portal';
import { createPolymorphicComponent } from '@helpers/react/createPolymorphicComponent';

import { PopoverProvider } from './context/PopoverContext';
import * as Types from './types';

export const Popover = createPolymorphicComponent<Types.Props, 'div'>(
  ({
    as: Component = 'div',
    children,
    className,
    colorScheme = 'light',
    handle,
    handleWrapperClassName,
    maxWidth = 'auto',
    isCompact,
    isDefaultOpen = false,
    isDisabled = false,
    isDismissable = true,
    isHoverable = false,
    isInline = false,
    isInteractive = false,
    isOpen: initialIsOpen,
    placement = 'top',
    renderRelativeTo = 'parent',
    width = 'auto',
    offset = 0,
    onClose
  }) => {
    const isControlled = initialIsOpen !== undefined;

    const [isOpen, setIsOpen] = useState<boolean>(isDefaultOpen);

    const middleware = useMemo(() => {
      const base = [offsetMiddleware(offset), shiftMiddleware(), flipMiddleware()];

      if (isInline) {
        base.push(inlineMiddleware());
      }

      return base;
    }, [isInline, offset, flipMiddleware, inlineMiddleware, offsetMiddleware, shiftMiddleware]);

    const { context, floatingStyles, refs } = useFloating({
      open: isOpen,
      middleware,
      placement,
      whileElementsMounted: autoUpdate,
      onOpenChange: (nextOpen, _event, reason) => {
        setIsOpen(nextOpen);

        if (reason === 'escape-key' || reason === 'outside-press') {
          onClose?.();
        }
      }
    });

    const hover = useHover(context, {
      enabled: !isDisabled && isHoverable,
      move: false,
      handleClose: isInteractive ? safePolygon() : null
    });

    const click = useClick(context, { enabled: !isDisabled && !isHoverable && !isControlled });

    const dismiss = useDismiss(context, {
      enabled: !isDisabled && isDismissable && !isControlled,
      referencePress: isHoverable
    });

    const role = useRole(context, { enabled: !isDisabled, role: isHoverable ? 'tooltip' : 'dialog' });

    const { getReferenceProps, getFloatingProps } = useInteractions([hover, click, dismiss, role]);

    const handleProps = useMemo<Types.HandleCallbackProps>(
      () => ({ ref: refs.setReference, ...getReferenceProps() }),
      [refs.setReference, getReferenceProps]
    );

    const handleRenderer = useMemo<ReactNode | undefined>(() => {
      if (typeof handle === 'function') {
        return handle(isOpen, handleProps);
      } else {
        return (
          <div className={twMerge('inline-flex max-w-full', handleWrapperClassName)} {...handleProps}>
            {handle}
          </div>
        );
      }
    }, [handle, handleProps, handleWrapperClassName, isOpen]);

    const renderer = () => (
      <Component
        className={twMerge(
          'z-50 rounded-lg border border-transparent shadow-md',
          cn({
            'border-gray-200 bg-white text-gray-800': colorScheme === 'light',
            'bg-gray-700 text-white': colorScheme === 'dark',
            'px-2 py-1': isCompact,
            'px-3 py-2': !isCompact
          }),
          className
        )}
        ref={refs.setFloating}
        style={{ width, maxWidth, ...floatingStyles }}
        {...getFloatingProps()}
      >
        {children}
      </Component>
    );

    useEffect(() => {
      if (isControlled) {
        setIsOpen(initialIsOpen);
      }
    }, [initialIsOpen, isControlled]);

    return (
      <PopoverProvider value={{ isOpen, setIsOpen }}>
        {handleRenderer}

        <Portal isDisabled={renderRelativeTo === 'parent'}>{isOpen && renderer()}</Portal>
      </PopoverProvider>
    );
  }
);
