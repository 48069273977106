import React, { createContext } from 'react';

import { useVideoSeekBar, VideoSeekBar } from '../hooks/useVideoSeekBar';

export const VideoSeekBarContext = createContext<VideoSeekBar | undefined>(undefined);

export const VideoSeekBarProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const videoSeekBar = useVideoSeekBar();

  return <VideoSeekBarContext.Provider value={videoSeekBar}>{children}</VideoSeekBarContext.Provider>;
};

VideoSeekBarContext.displayName = 'VideoSeekBarContext';
