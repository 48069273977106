import React from 'react';

import { ColumnDef } from '@tanstack/react-table';

import { Text } from '@components/common';
import { ClickPointer } from '@components/ScreenerResults/components/PrototypeTestResponse/components/ClickPointer';
import { ScreenThumbnail } from '@components/ScreenerResults/components/PrototypeTestResponse/components/ScreenThumbnail';
import { Table } from '@components/shared/Table';
import { ParticipationCell } from '@components/shared/Table/components/ParticipantCell';
import { PrototypeTestType } from '@components/SurveyBuilder/types/models';

export type DefinitionFnReturn = ColumnDef<NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>>[];

type Args = {
  prototypeTestId: number;
  setActivePath: (path: string[]) => void;
  setActiveClicks: (clicks: PrototypeClick[]) => void;
  testType?: PrototypeTestType;
};

export type DefinitionFn = (args: Args) => DefinitionFnReturn;

export const getTableColumnDefs: DefinitionFn = ({ prototypeTestId, setActivePath, setActiveClicks, testType }) => {
  const columns: DefinitionFnReturn = [
    {
      id: 'participation_name',
      accessorKey: 'participation_name',
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>>
          {...props}
          render={({ row }) => (
            <ParticipationCell
              participationName={row.original.participation_name || 'Unnamed'}
              sessionUuid={row.original.session_uuid}
            />
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} disableHide>
          Name
        </Table.ColumnHeader>
      )
    },
    {
      id: 'time_in_task',
      accessorFn: (row) => (row.time_in_task ? (row.time_in_task / 1000).toFixed(1) : 0),
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>>
          {...props}
          render={({ row }) => (
            <div className='px-2'>
              {row.original.time_in_task ? `${(row.original.time_in_task / 1000).toFixed(1)}s` : ''}
            </div>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} disableHide sortIconType='number'>
          Total time
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>>
          {...props}
          render={({ row }) => (
            <button
              aria-label={`View paths ${row.original.response_path.join(', ')}`}
              onClick={() => {
                setActivePath(row.original.response_path);
                setActiveClicks(row.original.clicks);
              }}
              className='inline-flex cursor-pointer items-center space-x-2 rounded border border-gray-200 px-2 hover:bg-gray-100'
            >
              {row.original.response_path.slice(0, 2).map((step, index) => (
                <ScreenThumbnail
                  key={index}
                  prototypeTestId={prototypeTestId}
                  className='flex h-8 w-10 items-center justify-center'
                  clicks={[]}
                  screenExternalElementId={step}
                  thumbnailSize={50}
                  hideClicks
                />
              ))}
              {row.original.response_path.length > 2 && (
                <Text className='text-sm text-gray-500'>+{row.original.response_path.length - 2}</Text>
              )}
            </button>
          )}
        />
      ),
      id: 'path',
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Path
        </Table.ColumnHeader>
      )
    }
  ];

  if (testType === 'open-ended') {
    columns.push({
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>>
          {...props}
          render={({ row }) => <div className='px-2'>{row.original.clicks?.length ?? 0}</div>}
        />
      ),
      id: 'clicks',
      accessorFn: (row) => row.clicks?.length ?? 0,
      header: (props) => (
        <Table.ColumnHeader {...props} disableHide sortIconType='number'>
          # of Clicks
        </Table.ColumnHeader>
      )
    });
  }

  if (testType === 'goal-based') {
    columns.push(
      {
        cell: (props) => (
          <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>>
            {...props}
            render={({ row }) => (
              <div className='px-2'>
                <ClickPointer size='lg' failure={!row.original.completed} />
              </div>
            )}
          />
        ),
        id: 'completed',
        accessorFn: (row) => row.completed,
        header: (props) => (
          <Table.ColumnHeader {...props} disableHide>
            Successful
          </Table.ColumnHeader>
        )
      },
      {
        cell: (props) => (
          <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>>
            {...props}
            render={({ row }) => (
              <div className='px-2'>{row.original.miss_clicks ? row.original.miss_clicks.toFixed(1) : 0}%</div>
            )}
          />
        ),
        id: 'miss_clicks',
        accessorFn: (row) => row.miss_clicks || 0,
        header: (props) => (
          <Table.ColumnHeader {...props} disableHide sortIconType='number'>
            Mis-click rate
          </Table.ColumnHeader>
        )
      }
    );
  }

  return columns;
};
