import * as React from 'react';

export const helpCenter: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg
    className='mr-3 h-4 w-4 text-white group-hover:text-gray-300'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.25 6.24991C6.25006 5.92909 6.33831 5.61447 6.5051 5.34042C6.6719 5.06637 6.91082 4.84344 7.19575 4.696C7.48068 4.54856 7.80065 4.48228 8.12071 4.50441C8.44076 4.52654 8.74857 4.63622 9.0105 4.82147C9.27243 5.00672 9.47839 5.26041 9.60588 5.5548C9.73337 5.8492 9.77748 6.17298 9.73339 6.49075C9.6893 6.80853 9.55871 7.10806 9.35588 7.35663C9.15305 7.60519 8.8858 7.79322 8.58333 7.90016C8.41266 7.9605 8.26491 8.07229 8.16043 8.22012C8.05596 8.36796 7.99991 8.54455 8 8.72558V8.87491'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M8 10.625C7.95674 10.625 7.91444 10.6378 7.87847 10.6619C7.8425 10.6859 7.81446 10.7201 7.7979 10.76C7.78134 10.8 7.77701 10.844 7.78545 10.8864C7.79389 10.9289 7.81473 10.9678 7.84532 10.9984C7.87591 11.029 7.91489 11.0499 7.95732 11.0583C7.99976 11.0667 8.04374 11.0624 8.08371 11.0458C8.12368 11.0293 8.15785 11.0013 8.18188 10.9653C8.20592 10.9293 8.21875 10.887 8.21875 10.8437C8.21875 10.7857 8.1957 10.7301 8.15468 10.6891C8.11366 10.648 8.05802 10.625 8 10.625Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M1.4375 1.4375H14.5625V14.5625H1.4375V1.4375Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
  </svg>
);
