import * as React from 'react';

import { Radio, Text } from '@components/common';
import { useFeature } from '@hooks/useFeature';

import { DropdownProps } from '../../types';

type Props<D = Candidate> = DropdownProps<D, boolean>;

export const Eligibility: React.FC<React.PropsWithChildren<Props>> = ({ value, onChange }) => {
  return (
    <div>
      <div className='space-y-4 pb-4'>
        <Radio
          id='eligibility_true'
          checked={!!value}
          type='radio'
          value='true'
          label='Yes'
          onChange={() => onChange({ value: true })}
        />
        <Radio
          id='eligibility_false'
          checked={!value}
          type='radio'
          value='false'
          label='No'
          onChange={() => onChange({ value: false })}
        />
      </div>
      <div>
        <Text className='w-60 whitespace-normal' h='400' color='gray-500' mb='1'>
          This excludes candidates who are over-contacted, over-polled or unresponsive.
        </Text>
        <a className='h400' href='/governance/eligibility' target='_blank'>
          Edit criteria
        </a>
      </div>
    </div>
  );
};
