import React from 'react';

import { ColumnDef } from '@tanstack/react-table';
import Tippy from '@tippyjs/react';

import { Text } from '@components/common';
import { formatMilliseconds } from '@components/ScreenerResults/components/CardSortResponse/utils';
import { ClickPointer } from '@components/ScreenerResults/components/PrototypeTestResponse/components/ClickPointer';
import { Table } from '@components/shared/Table';
import { VideoCameraSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

export type DefinitionFnReturn = ColumnDef<NonNullable<ScreenerResponseAnswerValue<'card_sort'>>>[];

type Args = { setPreview: (value: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>) => void };

export type DefinitionFn = (args: Args) => DefinitionFnReturn;

export const getTableColumnDefs: DefinitionFn = ({ setPreview }) => {
  const columns: DefinitionFnReturn = [
    {
      id: 'participation_name',
      accessorKey: 'participation_name',
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'card_sort'>>>
          {...props}
          render={({ row }) => (
            <div className='flex w-full items-center justify-between space-x-2 truncate px-2'>
              <div className='truncate'>{row.original.participation_name || 'Unnamed'}</div>
              {row.original.session_uuid && (
                <Tippy
                  aria={{ content: 'labelledby', expanded: 'auto' }}
                  appendTo={() => document.body}
                  interactive
                  arrow={false}
                  content='View full session room.'
                >
                  <a href={`/sessions/${row.original.session_uuid}`} target='_blank'>
                    <VideoCameraSVG className='h-4 w-4' />
                  </a>
                </Tippy>
              )}
            </div>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} disableHide>
          Name
        </Table.ColumnHeader>
      )
    },
    {
      id: 'outcome',
      accessorKey: 'completed',
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'card_sort'>>>
          {...props}
          render={({ row }) => (
            <div className='px-2'>
              <ClickPointer size='lg' failure={!row.original.completed} />
            </div>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} disableHide>
          Complete
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'card_sort'>>> {...props} />,
      id: 'cards_and_categories',
      accessorFn: (row) =>
        `${row.results.reduce((acc, { cards }) => acc.concat(cards), [] as string[]).length || '-'} / ${
          row.results.length || '-'
        }`,
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort disableHide>
          Cards / Categories
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'card_sort'>>>
          {...props}
          render={({ row }) => (
            <div className='px-2'>
              {row.original.time_in_task ? formatMilliseconds(row.original.time_in_task) : '0 s'}
            </div>
          )}
        />
      ),
      id: 'total_time',
      accessorFn: (row) => row.time_in_task || 0,
      header: (props) => (
        <Table.ColumnHeader {...props} disableHide sortIconType='number'>
          Total time
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'card_sort'>>>
          {...props}
          render={({ row }) => (
            <Tooltip content='Details'>
              <button
                aria-label='Details'
                onClick={() => setPreview(row.original)}
                className='mx-2 inline-flex items-center rounded border border-gray-200 p-1 pr-3 hover:bg-gray-100'
              >
                {row.original.results.slice(0, 2).map(({ cards }, index) => (
                  <div className='h200 mr-1 rounded-sm border border-gray-200 px-2 py-0.5' key={index}>
                    {cards.length || '0'}
                  </div>
                ))}
                {row.original.results.length > 2 && (
                  <Text className='ml-1 text-sm'>+{row.original.results.length - 2}</Text>
                )}
              </button>
            </Tooltip>
          )}
        />
      ),
      id: 'responses',
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Response
        </Table.ColumnHeader>
      )
    }
  ];

  return columns;
};
