import React from 'react';

import { UseFormMethods } from 'react-hook-form';

import Actions from 'components/fields/Actions';

interface Props {
  id?: string;
  name: string;
  placeholder?: string;
  register: UseFormMethods['register'];
  required?: boolean;
  errors?: UseFormMethods['errors'];
  disabled?: boolean;
}

const Input: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  name,
  placeholder,
  register,
  required = false,
  errors,
  disabled
}) => (
  <div className='mt-1.5'>
    <input
      type='text'
      name={name}
      id={id}
      ref={register({ required })}
      className={`tablet:text-sm block w-full rounded-md border-gray-200 text-gray-700 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500 ${
        disabled ? 'opacity-75' : ''
      }`}
      disabled={disabled}
      placeholder={placeholder}
    />
    {errors && errors[name] && (
      <p role='alert' aria-live='assertive' className='mt-1 text-xs text-red-600'>
        {' '}
        {name} is required{' '}
      </p>
    )}
  </div>
);

export default Input;
