import React, { useRef, useState } from 'react';

import { Button } from '@components/common';
import { DropdownItem } from '@components/common/DropdownCombobox';
import { UNSAFE_CUSTOM_ATTR_NAMES } from '@components/config';
import { NewAttrModal } from '@components/shared/NewAttrModal';
import { useToaster } from '@components/stores/toaster';
import { useOnClickOutside } from '@components/utils';

import { AttrSelect } from './components/AttrSelect';

interface Props {
  addNewAttrToList: (newCustomAttr: Attr_) => void;
  visibleAttrs: Attr_[];
  defaultButton?: boolean;
  onAdd?: () => void;
  showInput?: boolean;
  createAttr?: (attr: Attr_) => Promise<Attr_ | undefined>;
  options: DropdownItem[];
  smallButton?: boolean;
}

export const AddAttribute: React.FC<React.PropsWithChildren<Props>> = ({
  addNewAttrToList,
  showInput = false,
  defaultButton,
  onAdd,
  createAttr,
  options,
  smallButton
}) => {
  const [selectingAttr, setSelectingAttr] = useState(showInput);
  const [searchValue, setSearchValue] = useState('');
  const [attrCreating, setAttrCreating] = useState(false);
  const showToast = useToaster();

  const ref = useRef(null);

  useOnClickOutside(ref, () => setSelectingAttr(false));

  const handleAddAttr = () => {
    onAdd?.();
    setSelectingAttr(true);
  };

  const handleSelect = (attr: DropdownItem) => {
    if (attr?._isNew && UNSAFE_CUSTOM_ATTR_NAMES.includes(attr.value)) {
      showToast({
        heading: 'Invalid attribute name',
        text: `"${attr.value}" is not a permitted attribute name.`,
        icon: 'error'
      });
      return;
    }

    if (attr?._isNew) {
      setSearchValue(attr.value);
      setAttrCreating(true);
    } else {
      const selectedAttr = attr.data as Attr_;
      if (selectedAttr) {
        addNewAttrToList(selectedAttr);
      }
    }

    setSelectingAttr(false);
  };

  const onSubmit = (attr) => {
    addNewAttrToList(attr);
    setSelectingAttr(false);
  };

  return (
    <>
      <div className='inline text-sm font-medium leading-5'>
        {selectingAttr ? (
          <AttrSelect ref={ref} onSelect={handleSelect} options={options} />
        ) : (
          <Button
            small={smallButton}
            className='xx-add-attr my-4'
            link={!defaultButton}
            onClick={handleAddAttr}
            icon='plus'
          >
            Add attribute
          </Button>
        )}
      </div>
      <NewAttrModal
        createAttr={createAttr}
        defaultLabel={searchValue}
        open={attrCreating}
        onClose={() => setAttrCreating(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};
