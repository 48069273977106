import React, { ReactElement } from 'react';

import { SlideOut } from '@components/common';
import { RestrictedAction } from '@components/shared/RestrictedAction';
import { useCreateStudy } from '@hooks/useCreateStudy';
import { useDisabledFeatures } from '@hooks/useDisabledFeatures';
import { useFeature } from '@hooks/useFeature';
import { usePlan } from '@hooks/usePlan';
import { useUser } from '@hooks/useUser';
import { UserTeamsChannel, useWebsocket } from '@hooks/useWebsocket';
import { useGetWhoamiQuery } from '@stores/whoami';

import { StudyButton } from '../StudyButton';

import { NoTeamAlert } from './NoTeamAlert';

interface ButtonsProps {
  disabled?: boolean;
  teamId?: number | null;
  onClose?: () => void;
  externalCandidatesDefaulted?: boolean;
}

type Props = ButtonsProps & { isVisible: boolean };

export const NewRecruitingStudySlideout = ({ teamId, isVisible, onClose }: Props): ReactElement | null => {
  return <NewStudySlideout teamId={teamId} isVisible={isVisible} onClose={onClose} externalCandidatesDefaulted />;
};

export const NewStudyButtons = ({
  disabled: baseDisabled = false,
  teamId,
  onClose,
  externalCandidatesDefaulted = false
}: ButtonsProps): ReactElement | null => {
  const { createStudy, isCreating } = useCreateStudy();
  const { hasFeature } = usePlan();
  const disabled = isCreating || baseDisabled;

  const focusGroupsEnabled = useFeature('focus_groups');
  const unmoderatedTestsEnabled = hasFeature('unmoderated');

  const disabledFeatures = useDisabledFeatures();

  const handleOnClick = async (style: Study['style'], focus_group = false, continuous = false) => {
    const external_candidates_enabled: boolean = externalCandidatesDefaulted;
    const has_screener: boolean = externalCandidatesDefaulted;

    await createStudy({
      style,
      focus_group,
      continuous,
      external_candidates_enabled,
      has_screener,
      team_id: teamId,
      onSuccess: onClose
    });
  };

  return (
    <>
      <StudyButton disabled={disabled} kind='video_call' className='mb-4' onClick={() => handleOnClick('video_call')} />

      {focusGroupsEnabled && (
        <RestrictedAction feature='focus_groups'>
          {({ may, can, show }) => (
            <StudyButton
              kind='focus_group'
              className='mb-4'
              disabled={disabled}
              data-testid='video_call'
              onClick={() => {
                if (!may || !can) {
                  show?.();
                  return;
                }
                handleOnClick('video_call', true, false);
              }}
            />
          )}
        </RestrictedAction>
      )}

      {!disabledFeatures.survey && (
        <StudyButton disabled={disabled} kind='survey' className='mb-4' onClick={() => handleOnClick('survey')} />
      )}

      {unmoderatedTestsEnabled && !disabledFeatures.unmoderated_test && (
        <StudyButton
          disabled={disabled}
          kind='unmoderated_test'
          className='mb-4'
          onClick={() => handleOnClick('unmoderated_test')}
        />
      )}

      {!disabledFeatures.online_task && (
        <StudyButton
          disabled={disabled}
          kind='online_task'
          className='mb-4'
          onClick={() => handleOnClick('online_task')}
        />
      )}

      {!externalCandidatesDefaulted && !disabledFeatures.panel && (
        <StudyButton disabled={disabled} kind='panel' onClick={() => handleOnClick('panel')} />
      )}
    </>
  );
};

export const NewStudySlideout = ({
  teamId,
  isVisible,
  onClose,
  externalCandidatesDefaulted = false
}: Props): ReactElement | null => {
  const teamAccessControlEnabled = useFeature('team_access_control');
  const { id: user_id } = useUser();
  const { data: whoami, isLoading, refetch } = useGetWhoamiQuery();

  const teams = whoami?.user?.teams || [];

  useWebsocket<{ user_id: number }, void>({
    channel: UserTeamsChannel,
    params: { user_id },
    onReceived: refetch
  });

  if (!isVisible) {
    return null;
  }

  const showNeedsTeamError = teamAccessControlEnabled && !teams?.length;

  return (
    <section>
      <SlideOut
        title='Create new study'
        subtitle='Choose a research method to run with internal or external candidates.'
        onClose={onClose}
      >
        <div className='border-t border-gray-200 p-6'>
          {showNeedsTeamError && <NoTeamAlert className='mb-6' />}
          <NewStudyButtons
            disabled={showNeedsTeamError || isLoading}
            teamId={teamId}
            onClose={onClose}
            externalCandidatesDefaulted={externalCandidatesDefaulted}
          />
        </div>
      </SlideOut>
    </section>
  );
};
