import * as React from 'react';

import { Text } from '@components/common';
import { noop } from '@components/utils';

import {
  BookingDetailsFormFieldComponentProps,
  CalendarEvents,
  DateField,
  Duration,
  Guests,
  LiveStreamEnabled,
  Location,
  Time
} from './fields';

export type Props = {
  study: SimpleStudy;
  participationId: number;
  editMode: boolean;
  bookingDetail: CalendarBookingDetail;
  onChange?: (bookingDetail: DeepPartial<CalendarBookingDetail>) => void;
  onClose: () => void;
};

export const BookingDetailsForm: React.FC<Props> = ({
  study,
  participationId,
  editMode,
  bookingDetail,
  onChange = noop,
  onClose
}) => {
  const fieldProps: BookingDetailsFormFieldComponentProps = {
    study,
    editMode,
    bookingDetail,
    participationId,
    onChange
  };

  return (
    <div className='flex flex-col space-y-10'>
      <div>
        <Text as='h3' h='600' bold mb='6'>
          Session details
        </Text>
        <DateField {...fieldProps} />
        <Time {...fieldProps} />
        <Duration {...fieldProps} />
      </div>
      <div>
        <Text as='h3' h='600' bold mb='6'>
          Moderator settings
        </Text>
        <Location {...fieldProps} />
        <CalendarEvents {...fieldProps} participationId={participationId} onClose={onClose} />
        <Guests {...fieldProps} />
      </div>
      <div>
        <Text as='h3' h='600' bold mb='6'>
          Observer settings
        </Text>
        <LiveStreamEnabled type='team_event' {...fieldProps} />
        <Location type='team_event' {...fieldProps} />
        <CalendarEvents type='team_event' {...fieldProps} participationId={participationId} onClose={onClose} />
        <Guests type='team_event' {...fieldProps} />
      </div>
    </div>
  );
};
