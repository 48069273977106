import React, { SyntheticEvent, useState } from 'react';

import { Editor } from '@tiptap/core';

import { Button, Modal, ModalHeading, Radio } from '@components/common';

import { TranscriptAttributes } from '../extensions';

interface Props {
  editor: Editor;
  speaker: Partial<TranscriptAttributes['speaker']> | null;
  isOpen: boolean;
  onClose: () => void;
}

export const UpdateSpeakerModal = ({ editor, speaker, isOpen, onClose }: Props) => {
  const [updateAllSpeakers, setUpdateAllSpeakers] = useState<boolean>(true);
  const attributes = editor.getAttributes('transcript') as TranscriptAttributes;

  const handleOnFormSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    if (!speaker) return;

    if (updateAllSpeakers) {
      editor.chain().focus().updateAllSpeakers(speaker).run();
    } else {
      editor.chain().focus().updateSpeaker(speaker).run();
    }

    onClose();
  };

  if (!isOpen || !attributes.speaker) return null;

  return (
    <Modal onClose={onClose}>
      <ModalHeading className='mb-4'>Change speaker name</ModalHeading>
      <form onSubmit={handleOnFormSubmit}>
        <fieldset className='mb-2'>
          <Radio
            id='all-instances'
            className='mr-2 h-4 w-4 cursor-pointer border-gray-200 text-indigo-600 focus:ring-indigo-500'
            type='radio'
            name='change-speaker'
            value='1'
            label={`All instances of this speaker`}
            onChange={() => setUpdateAllSpeakers(true)}
            checked={updateAllSpeakers}
          />
        </fieldset>
        <fieldset>
          <Radio
            id='single-instance'
            className='mr-2 h-4 w-4 cursor-pointer border-gray-200 text-indigo-600 focus:ring-indigo-500'
            type='radio'
            name='change-speaker'
            value='0'
            label='This instance only'
            onChange={() => setUpdateAllSpeakers(false)}
            checked={!updateAllSpeakers}
          />
        </fieldset>
        <div className='mt-4 flex'>
          <Button type='submit' primary>
            Confirm
          </Button>
          <Button onClick={onClose} link>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};
