import React, { useEffect, useMemo, useState } from 'react';

import pluralize from 'pluralize';

import { api } from '@api/reduxApi';
import {
  Alert,
  AlertHeading,
  AlertLink,
  AlertMessage,
  Button,
  LayoutToggle,
  Spinner,
  Tabs,
  Text,
  Toggle
} from '@components/common';
import { Grid } from '@components/common/Grid';
import { DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { PageHeader } from '@components/shared/PageHeader';
import { Paginator } from '@components/shared/Paginator';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { SortDropdown } from '@components/shared/SortDropdown';
import { AnyAllToggle, SegmentActions, TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { parse } from '@components/shared/TableFilters/utils/encode';
import { ZDSPage } from '@components/shared/ZDS/components/ZDSPage';
import { useCollectionView } from '@components/stores/view';
import { Tooltip } from '@components/Tooltip';
import { track } from '@components/tracking';
import { useAccount } from '@hooks/useAccount';
import { usePlan } from '@hooks/usePlan';
import { PAGINATED_STUDIES_INTERCOM_SURVEY_ID } from 'components/StudiesApp/constants';
import { GroupBySVG, InfoCircleIcon } from 'components/svgs';
import { usePaginatedStudiesToggle } from 'hooks/usePaginatedStudiesToggle';

import { NewStudyAction } from '../StudyNew';

import { ListViewTable } from './components/ListViewTable';
import { NoResults } from './components/NoResults';
import { RowViewTable } from './components/RowViewTable';
import { StudyCard } from './components/StudyCard';
import { buildStudiesAttributes } from './filters/buildStudiesAttributes';
import { DISABLED_GROUPED_STUDIES_VIEW_MESSAGE, PAGINATED_STUDIES_VIEW_MESSAGE } from './contants';
import { Skeleton, TableBodySkeleton } from './Skeleton';

const TAB_LABELS = {
  my: 'My studies',
  all: 'All studies'
};

interface Props {
  team?: Team | null;
  isPopperOpen: boolean;
}

export const StudiesListPage: React.FC<React.PropsWithChildren<Props>> = ({ team, isPopperOpen }) => {
  const {
    account: { status, team: accountMembers }
  } = useAccount();

  const { limits, hasQuota } = usePlan();

  const viewHook = useCollectionView();
  const {
    view: { sort, layout, groupBy, tab, toggle },
    setView
  } = viewHook;

  function setLayout(layout) {
    setView({ layout });
  }

  function setSort(sort) {
    setView({ sort });
  }

  function setGroupBy(groupBy) {
    setView({ groupBy });
  }

  function setToggle(toggle) {
    setView({ toggle });
  }
  const onGroupByChange = ({ value }: { value: string }): void => {
    setGroupBy(value);
  };

  function startIntercomSurvey() {
    if (window.Intercom) {
      window.Intercom('startSurvey', PAGINATED_STUDIES_INTERCOM_SURVEY_ID);
    } else {
      console.warn('[Intercom] Failed to start survey: Intercom is not available.');
    }
  }

  const [value, setValue] = useState<string>('');
  const { paginatedStudiesView, paginatedStudiesFeature, togglePaginatedStudiesView } = usePaginatedStudiesToggle();

  const { data: studyAttrs, isLoading: attrsLoading, isSuccess, isError } = api.useGetStudyAttrsQuery();

  const attrs = useMemo(
    () => buildStudiesAttributes({ onlyBackendFilters: true, team: accountMembers, studyAttrs: studyAttrs || [] }),
    [studyAttrs, accountMembers]
  );

  const filtersHook = useTableFilters<Study>({
    definitions: attrs.filters,
    trackKey: 'studies',
    syncWithURL: isSuccess || isError
  });

  const parsedFilters = useMemo(() => filtersHook.filters.map(parse).filter(Boolean), [filtersHook.filters]);

  const [page, setPage] = useState(0);
  const { data: totalCount, isLoading: isLoadingCount } = api.useGetStudiesCountQuery();

  const queryArgs = {
    q: value,
    page: page + 1,
    teamId: team?.id,
    tab: tab || 'all',
    filters: parsedFilters,
    sort: sort.value,
    sortDesc: sort.desc,
    includeArchived: toggle
  };

  const { data, isFetching, isLoading } = api.useGetStudiesPagedQuery(queryArgs);

  const studies = data?.data || [];
  const currentPage = data?.meta?.page || 0;
  const totalPages = data?.meta?.pages || 0;
  const canNextPage = page < totalPages - 1;
  const canPreviousPage = page > 0;

  const nextPage = () => setPage((page) => page + 1);
  const previousPage = () => setPage((page) => page - 1);

  const sortDescFor = ['state'];
  const onSortChange = ({ value }) => {
    setSort({ value, desc: sortDescFor.includes(value) });
  };

  const onSearchChange = (value: string) => {
    setValue(value);
  };

  useEffect(() => {
    if (page > 0) {
      setPage(0);
    }
  }, [parsedFilters, value]);

  const renderStudies = useMemo(() => {
    if (studies.length === 0) {
      return <NoResults />;
    }

    if (layout === 'grid') {
      return (
        <Grid gap={6} monitor={4} desktop={3} tablet={2} mobile={1}>
          {studies?.map((s) => s && <StudyCard key={s.id} study={s} />)}
        </Grid>
      );
    }

    if (layout === 'row') {
      return <RowViewTable records={studies} />;
    }

    return <ListViewTable setSort={setSort} studyAttrs={studyAttrs} records={studies} onlyBackendFilters />;
  }, [studies, layout]);

  const renderFilters = () => (
    <div className='px-page flex items-center border-t border-gray-200'>
      <div className='flex-1'>
        <TableFilters<Study> hook={filtersHook} defaultShowInput={!filtersHook.filters.length} />
      </div>
      <div className='flex space-x-3 border-l border-gray-200 py-3 pl-3'>
        <AnyAllToggle value={filtersHook.op} onChange={filtersHook.setOp} />
        <SegmentActions onClear={filtersHook.clearFilters} anyFilters={filtersHook.filters.length > 0} />
      </div>
    </div>
  );

  if (isLoadingCount || isLoading || attrsLoading) {
    return <Skeleton />;
  }

  if (totalCount === 0) {
    return <ZDSPage types={['studies']} teamId={team?.id} />;
  }

  return (
    <DashboardLayout>
      <PageHeader
        h1='Studies'
        renderSortBy={() => (
          <SortDropdown wrapperClass='w-full' options={attrs.sortOptions} value={sort.value} onChange={onSortChange} />
        )}
        renderLayout={() => <LayoutToggle withRow value={layout} onChange={setLayout} />}
        searchProps={{
          placeholder: 'Search…',
          addDebounce: true,
          onSearch: onSearchChange,
          value
        }}
        renderTabs={() => (
          <Tabs
            current={tab || 'all'}
            labels={TAB_LABELS}
            onSelect={(tab) => {
              track('changed_study_owner_tab', { tab });
              setView({ tab });
              setPage(0);
            }}
            className='px-page flex items-center border-t border-gray-200'
            tabs={['my', 'all']}
          />
        )}
        renderFilters={renderFilters}
        filtersApplied={!!filtersHook.filters.length}
        renderCta={() => (
          <NewStudyAction>
            {({ openSlideout }) => (
              <RestrictedButton
                limit='studies'
                permission='createStudy'
                action='New study'
                className='xx-new-study-cta'
              >
                <Button onClick={() => openSlideout({ teamId: team?.id })} primary medium>
                  New study
                </Button>
              </RestrictedButton>
            )}
          </NewStudyAction>
        )}
        renderToggle={() => (
          <>
            <div className='flex items-center justify-between'>
              <div className='text-sm'>Show archived studies</div>
              <Toggle name='Show archived studies' onToggle={setToggle} on={toggle} />
            </div>
            {paginatedStudiesFeature && (
              <div className='flex items-center justify-between'>
                <div className='text-sm'>
                  Paginate studies
                  <Tooltip content={PAGINATED_STUDIES_VIEW_MESSAGE}>
                    <InfoCircleIcon className='ml-2 h-4 w-4' />
                  </Tooltip>
                </div>
                <Toggle name='Paginate studies' onToggle={togglePaginatedStudiesView} on={paginatedStudiesView} />
              </div>
            )}
          </>
        )}
        isPopperOpen={isPopperOpen}
        renderGroupBy={() => (
          <div className='flex flex-1'>
            <div className='flex w-1/2 items-center whitespace-nowrap text-sm font-bold'>
              <GroupBySVG className='mr-2' />
              Group by
              {paginatedStudiesView && (
                <Tooltip content={DISABLED_GROUPED_STUDIES_VIEW_MESSAGE}>
                  <InfoCircleIcon className='ml-2 h-4 w-4' />
                </Tooltip>
              )}
            </div>
            <div className='w-1/2'>
              <SortDropdown
                wrapperClass='w-full'
                disabled={paginatedStudiesView}
                tooltipContent={DISABLED_GROUPED_STUDIES_VIEW_MESSAGE}
                type='group'
                options={attrs.groupOptions}
                value={groupBy}
                onChange={onGroupByChange}
              />
            </div>
          </div>
        )}
        team={team}
      />

      <DashboardLayoutBody>
        <Alert className='my-4' type='promo' dismissKey='paginated-studies-index'>
          <AlertHeading>We've re-built the Studies page to improve performance</AlertHeading>
          <AlertMessage>
            The new table introduces pagination for faster loading.{' '}
            <AlertLink href='#' handleClick={startIntercomSurvey} className='underline'>
              Please let us know
            </AlertLink>{' '}
            if you have any feedback!
          </AlertMessage>
        </Alert>
        <div className='mb-4 flex items-center'>
          <Text color='gray-500' h='400'>
            {pluralize('studies', data?.meta?.count || 0, true)}
          </Text>
          <div className='mx-4 flex-1'>{isFetching && <Spinner className='h-4 w-4' />}</div>
          <Paginator
            canClickNext={canNextPage}
            canClickPrevious={canPreviousPage}
            onClickNext={nextPage}
            onClickPrevious={previousPage}
            current={currentPage}
            total={totalPages}
          />
        </div>
        {isFetching ? <TableBodySkeleton layout={layout} /> : !!studies.length && renderStudies}
      </DashboardLayoutBody>
    </DashboardLayout>
  );
};
