import * as React from 'react';

import { Button } from 'components/common';
import { useFeature } from 'hooks/useFeature';

interface Props {
  provider: string;
  primary?: boolean;
  small?: boolean;
  className?: string;
  children: React.ReactNode;
}

const IntegrationButton: React.FC<React.PropsWithChildren<Props>> = ({ primary, small, className, children }) => {
  return (
    <Button primary={primary} small={small} className={className} target='_blank' href='/user/connected_accounts'>
      {children}
    </Button>
  );
};

const DeprecatedAuthButton: React.FC<React.PropsWithChildren<Props>> = ({
  provider,
  primary,
  small,
  className,
  children
}) => {
  const authButtonProps = (provider) => ({
    'data-method': 'post',
    href: `/users/auth/${provider}`
  });

  return (
    <Button
      primary={primary}
      small={small}
      className={className}
      {...authButtonProps(provider)}
      target='auth_popup'
      onClick={() => {
        window.open('about:blank', 'auth_popup', 'width=1000,height=800');
      }}
    >
      {children}
    </Button>
  );
};

export const AuthButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const hasNativeGoogleIntegration = useFeature('google_oauth2_custom_auth');
  const ButtonComponent = hasNativeGoogleIntegration ? IntegrationButton : DeprecatedAuthButton;
  return <ButtonComponent {...props} />;
};
