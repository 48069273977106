import React, { forwardRef, SVGProps } from 'react';

export const FillScreen = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2940_17876)'>
      <path
        d='M15.5029 5.00005V1.49805C15.5029 1.23283 15.3976 0.978476 15.21 0.79094C15.0225 0.603404 14.7681 0.498047 14.5029 0.498047H11.0029'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.0029 15.498H14.5029C14.7681 15.498 15.0225 15.3926 15.21 15.2051C15.3976 15.0176 15.5029 14.7632 15.5029 14.498V11'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.50293 11V14.5C0.50293 14.7652 0.608287 15.0196 0.795823 15.2071C0.983359 15.3946 1.23771 15.5 1.50293 15.5H5.00293'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.00293 0.498047H1.50293C1.23771 0.498047 0.983359 0.603404 0.795823 0.79094C0.608287 0.978476 0.50293 1.23283 0.50293 1.49805V5.00005'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2940_17876'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
));
