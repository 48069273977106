import React from 'react';

import { Button, Modal, ModalHeading, Text } from '@components/common';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  desc: string;
  ctaText: string;
}

export const SettingsMovedModal: React.FC<React.PropsWithChildren<Props>> = ({
  onClose,
  onConfirm,
  title,
  desc,
  ctaText
}) => {
  return (
    <Modal
      onClose={onClose}
      renderFooter={() => (
        <div className='space-x-6'>
          <Button onClick={onClose}>Cancel</Button>
          <Button primary onClick={onConfirm}>
            {ctaText}
          </Button>
        </div>
      )}
    >
      <ModalHeading className='mb-6'>{title}</ModalHeading>
      <Text h='400' className='mb-12'>
        {desc}
      </Text>
    </Modal>
  );
};
