import { useEffect, useState } from 'react';

import { UniqueIdentifier } from '@dnd-kit/core';

type UseGetDirect = (selectedAnswer: UniqueIdentifier[]) => {
  direct: boolean;
  setCurrentPath: React.Dispatch<React.SetStateAction<number[]>>;
};

export const useGetDirect: UseGetDirect = (selectedAnswer) => {
  const [direct, setDirect] = useState<boolean>(true);
  const [currentPath, setCurrentPath] = useState<number[]>([]);

  const checkIsDirect = () => {
    const isDirect = currentPath.every((item, index) => selectedAnswer[index] === item);

    setDirect(isDirect);
  };

  useEffect(() => {
    if (!currentPath.length || !selectedAnswer.length) return;

    checkIsDirect();
  }, [currentPath]);

  return { direct, setCurrentPath };
};
