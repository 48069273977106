import React, { FC } from 'react';

import { uniq } from '@components/utils';

import { ScreensPreview } from './ScreensPreview';

interface Props {
  answers: NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>[];
  prototypeTestId: number;
}

export const Screens: FC<Props> = ({ answers, prototypeTestId }) => {
  const path = uniq(answers.map((answer) => answer.response_path).flat());
  const clicks = answers.flatMap((answer) => answer.clicks);

  return (
    <div className='overflow-hidden' style={{ maxHeight: 670 }}>
      <ScreensPreview prototypeTestId={prototypeTestId} path={path} clicks={clicks} />
    </div>
  );
};
