import React, { FC } from 'react';

import { useDeviceType } from '@hooks/useDeviceType';

import * as Types from '../types';

import { Card } from './Card';
import { MobileCard } from './MobileCard';

export const ResponsiveCard: FC<React.PropsWithChildren<Types.ResponsiveCardProps>> = (props) => {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return <MobileCard {...props} />;
  }

  return <Card {...props} />;
};
