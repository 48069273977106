import * as React from 'react';
import { useState } from 'react';

import { AddTeamIcon, UserAvatars } from '@components/shared/UserAvatars';

import { StudyUsersModal } from './components';

interface Props {
  study: Study;
}

export const StudyUsers: React.FC<React.PropsWithChildren<Props>> = ({ study }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        aria-label='Study users'
        onClick={() => setShowModal(true)}
        className='xx-study-users group flex h-10 cursor-pointer items-center rounded-md border border-gray-200 hover:bg-gray-50'
      >
        <div className='h-10 border-r border-gray-200 px-3 py-2'>
          <UserAvatars truncate={3} user_ids={study.user_ids} size='md' />
        </div>
        <div className='rounded-full bg-white px-2 py-3 text-white group-hover:bg-gray-50'>
          <AddTeamIcon className='h-3.5 w-3.5 text-gray-700' />
        </div>
      </button>
      {showModal && <StudyUsersModal study={study} onClose={() => setShowModal(false)} />}
    </>
  );
};
