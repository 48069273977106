import * as React from 'react';

import { api } from '@api/reduxApi';
import { Text } from '@components/common';
import { BookingDetailsForm } from '@components/shared/BookingDetailsForm';
import { Basic } from '@components/shared/Skeleton';

type Props = {
  studyId: number;
  participationId: number;
  onClose: () => void;
};

export const BookingDetails: React.FC<React.PropsWithChildren<Props>> = ({ studyId, participationId: id, onClose }) => {
  const { data: bookingDetail, isLoading: isLoadingBookingDetail } = api.useGetParticipationCalendarBookingDetailsQuery(
    {
      id
    }
  );
  const { data: study, isLoading: isLoadingStudy } = api.useGetSimpleStudyQuery(studyId);
  const isLoading = isLoadingBookingDetail || isLoadingStudy;

  const hasBooking = !!bookingDetail?.candidate_event;

  return (
    <div className='flex flex-col space-y-3'>
      {isLoading && <Basic />}

      {!isLoading && !hasBooking && <Text color='gray-500'>No booking details.</Text>}

      {!isLoading && hasBooking && study && (
        <div className='mb-6 mt-3'>
          <BookingDetailsForm
            study={study}
            editMode={false}
            bookingDetail={bookingDetail}
            participationId={id}
            onClose={onClose}
          />
        </div>
      )}
    </div>
  );
};
