import React, { forwardRef, HTMLAttributes } from 'react';

import cn from 'classnames';

import { ChevronDownSVG } from '@components/svgs';
import { composeEventHandlers } from 'helpers/composeEventHandlers';

import { useAccordionContext } from '../hooks/useAccordionContext';
import { useSectionContext } from '../hooks/useSectionContext';

export const Header = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, onClick, className, ...rest }, ref) => {
    const { expandedSections, expandSection, getHeaderProps } = useAccordionContext();
    const sectionIndex = useSectionContext();
    const isExpanded = expandedSections.includes(sectionIndex);

    const handleOnClick = composeEventHandlers(onClick, () => expandSection(sectionIndex));

    return (
      <header
        {...getHeaderProps(sectionIndex)}
        ref={ref}
        onClick={handleOnClick}
        className={cn('flex items-center justify-between px-6 py-5', className)}
        {...rest}
      >
        {children}
        <ChevronDownSVG className={cn('h-4 w-4 transform', { ['rotate-180']: isExpanded })} />
      </header>
    );
  }
);
