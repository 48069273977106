import { useEffect, useMemo, useState } from 'react';

import consumer from '@helpers/consumer';
import { TranscriptsChannel } from '@hooks/useWebsocket';

export const useTranscriptStatus = (transcript?: Transcript | null) => {
  const [message, setMessage] = useState<Transcript['state']>();

  const channel = useMemo(
    () =>
      transcript?.id
        ? consumer.subscriptions.create({
            channel: TranscriptsChannel,
            transcript_id: transcript.id
          })
        : null,
    [consumer, transcript?.id]
  );

  useEffect(() => {
    if (channel) {
      channel.received = (data) => {
        setMessage(data.message);
      };
    }
  }, [channel]);

  useEffect(() => {
    if (transcript?.state) {
      setMessage(transcript.state);
    }
  }, [transcript?.state]);

  return { message };
};
