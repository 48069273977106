import React, { useState } from 'react';

import { Button, Dropdown, DropdownLink } from '@components/common';
import { ShortlistSVG, SolidKebabSVG } from '@components/svgs';
import { usePermission } from '@hooks/usePermission';
import { useStudies } from '@hooks/useStudies';

export const HeaderButtons: React.FC<React.PropsWithChildren<{ setAction: (action) => void }>> = ({ setAction }) => {
  const [menu, setMenu] = useState<null | 'primary' | 'options'>();
  const canDelete = usePermission('deleteCandidate')();

  const { panelStudies } = useStudies();

  const showAction = (slideout) => {
    setMenu(null);
    setAction(slideout);
  };

  return (
    <>
      <Button className='xx-shortlist' onClick={() => showAction('shortlist')}>
        <ShortlistSVG />
        <div className='ml-2'>Shortlist</div>
      </Button>
      <Dropdown
        primary
        text='Compose'
        icon='composeEmail'
        isOpen={menu === 'primary'}
        onClose={() => setMenu(null)}
        onClick={() => setMenu('primary')}
      >
        <DropdownLink className='xx-bulk-invite border-b' onClick={() => showAction('invite')}>
          Participation invite
        </DropdownLink>
        <DropdownLink className='xx-bulk-screener border-b' onClick={() => showAction('sendScreener')}>
          Screener invite
        </DropdownLink>
        {!!panelStudies?.length && (
          <DropdownLink className='xx-bulk-panel border-b' onClick={() => showAction('inviteToPanel')}>
            Panel opt-in form
          </DropdownLink>
        )}
        <DropdownLink disabled>Emails won't be sent yet</DropdownLink>
      </Dropdown>
      {canDelete && (
        <Dropdown
          hideCaret
          textStyle
          text={<SolidKebabSVG className='h-4 w-4' />}
          isOpen={menu === 'options'}
          onClose={() => setMenu(null)}
          onClick={() => setMenu('options')}
        >
          <DropdownLink onClick={() => showAction('unsubscribe')}>Unsubscribe…</DropdownLink>
          <DropdownLink color='red-600' hoverColor='red-700' onClick={() => showAction('delete')}>
            Delete…
          </DropdownLink>
        </Dropdown>
      )}
    </>
  );
};
