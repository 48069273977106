export type StudyTabKey =
  | 'participants'
  | 'recruitment_requests'
  | 'interviews'
  | 'stats'
  | 'plan'
  | 'calendar'
  | 'task'
  | 'survey'
  | 'test'
  | 'screener'
  | 'emails'
  | 'pages'
  | 'report'
  | 'repository'
  | 'tags'
  | 'overview'
  | 'insights'
  | 'synthesis'
  | 'automation'
  | 'notifications'
  | 'interview_guide'
  | 'incentives'
  | 'screener_responses'
  | 'summary';

export type StudyTab = {
  key: StudyTabKey;
  disabled?: boolean;
  disabled_reason?: string;
  errored?: boolean;
  warning?: boolean;
};

export const buildTabs = (study: Study, canUpdate: boolean, hasExternalCandidatesAccess: boolean): StudyTab[] => {
  switch (study.style) {
    case 'video_call':
      return [
        { key: 'participants' },
        { key: 'screener_responses', disabled: !study.has_screener },
        { key: 'recruitment_requests', disabled: !hasExternalCandidatesAccess },
        { key: 'stats' },
        { key: 'tags', disabled: !!study.focus_group },
        { key: 'synthesis', disabled: !!study.focus_group },
        { key: 'insights', disabled: !!study.focus_group },
        { key: 'plan' },
        { key: 'screener', disabled: !study.has_screener },
        { key: 'calendar', disabled: !canUpdate, disabled_reason: "You don't have permission to edit this study" },
        { key: 'incentives', disabled: !study.has_incentive },
        { key: 'emails' },
        { key: 'pages' },
        { key: 'interview_guide' },
        { key: 'repository', disabled: !!study.focus_group },
        { key: 'automation' },
        { key: 'notifications' }
      ];
    case 'survey':
      return [
        { key: 'participants' },
        { key: 'recruitment_requests', disabled: !hasExternalCandidatesAccess },
        { key: 'stats' },
        { key: 'plan' },
        { key: 'screener', disabled: !study.has_screener },
        { key: 'survey' },
        { key: 'incentives', disabled: !study.has_incentive },
        { key: 'emails' },
        { key: 'pages' },
        { key: 'repository' },
        { key: 'automation' },
        { key: 'notifications' },
        { key: 'screener_responses', disabled: !study.has_screener },
        { key: 'summary' }
      ];
    case 'unmoderated_test':
      return [
        { key: 'participants' },
        { key: 'recruitment_requests', disabled: !hasExternalCandidatesAccess },
        { key: 'stats' },
        { key: 'plan' },
        { key: 'screener', disabled: !study.has_screener },
        { key: 'test' },
        { key: 'incentives', disabled: !study.has_incentive },
        { key: 'emails' },
        { key: 'pages' },
        { key: 'tags' },
        { key: 'synthesis' },
        { key: 'repository' },
        { key: 'automation' },
        { key: 'notifications' },
        { key: 'screener_responses', disabled: !study.has_screener },
        { key: 'summary' }
      ];
    case 'online_task':
      return [
        { key: 'participants' },
        { key: 'screener_responses', disabled: !study.has_screener },
        { key: 'recruitment_requests', disabled: !hasExternalCandidatesAccess },
        { key: 'stats' },
        { key: 'plan' },
        { key: 'task' },
        { key: 'incentives', disabled: !study.has_incentive },
        { key: 'screener', disabled: !study.has_screener },
        { key: 'emails' },
        { key: 'pages' },
        { key: 'repository' },
        { key: 'automation' },
        { key: 'notifications' }
      ];
    case 'panel':
      return [
        { key: 'plan' },
        { key: 'screener', disabled: !study.has_screener },
        { key: 'participants' },
        { key: 'recruitment_requests', disabled: !hasExternalCandidatesAccess },
        { key: 'survey' },
        { key: 'stats' },
        { key: 'repository' },
        { key: 'emails' },
        { key: 'pages' },
        { key: 'automation' },
        { key: 'notifications' },
        { key: 'screener_responses', disabled: !study.has_screener },
        { key: 'summary' }
      ];
    default:
      return [];
  }
};
