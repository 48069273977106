import * as React from 'react';
import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Loading } from '@components/common';
import { useToaster } from '@stores/toaster';

import { InsightPage } from './InsightPage';
import * as toasts from './toasts';

export const InsightsShow: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [insight, setInsight] = useState<Insight | null>(null);
  const params = useParams();
  const showToast = useToaster();

  const { data, isLoading, isError } = api.useGetInsightQuery(params.slug as string, { skip: !params.slug });

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedGet());
    }
  }, [isError]);

  useEffect(() => {
    if (data) {
      setInsight(data);
    }
  }, [data]);

  return (
    <>
      {isLoading && <Loading absolute />}
      {insight && <InsightPage key={insight.id} insight={insight} />}
    </>
  );
};
