import * as React from 'react';

import classNames from 'classnames';

import { FirstIcon, LastIcon, NextIcon, PrevIcon } from '../svgs';

interface Props {
  disabled: boolean;
  onClick: () => void;
  type: 'first' | 'prev' | 'next' | 'last';
}

export const ArrowButton: React.FC<React.PropsWithChildren<Props>> = ({ disabled, onClick, type }) => {
  const Icon = {
    first: FirstIcon,
    prev: PrevIcon,
    next: NextIcon,
    last: LastIcon
  }[type];

  const buttonClassname = classNames({
    'mr-2': type === 'first',
    'ml-2': type === 'last',
    'text-gray-500': disabled,
    'text-indigo-600 cursor-pointer hover:border-indigo-600': !disabled,
    'rounded-full flex items-center justify-center border border-gray-200 p-3 h-10 w-10': true
  });

  const onArrowClick = () => {
    !disabled && onClick();
  };

  return (
    <button data-testid={type} disabled={disabled} onClick={onArrowClick} className={buttonClassname}>
      <Icon />
    </button>
  );
};
