import * as React from 'react';

import { useParams } from 'react-router-dom';

import { Loading } from '@components/common';

import { useGuide } from './hooks/useGuide';
import { GuidePage } from './GuidePage';

export const GuidesEdit: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { token } = useParams<{ token: string }>();
  const { loading, guide } = useGuide(token || '');

  return (
    <>
      {loading && <Loading />}
      {guide && <GuidePage editable guide={guide} />}
    </>
  );
};
