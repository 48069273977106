import * as React from 'react';

import { CalendarSVG } from '@components/svgs';

export const Date: QuestionCardBodyComponent = () => {
  return (
    <div className='max-w-md rounded-md border border-gray-200 px-2 py-2 text-gray-400'>
      <div className='flex items-center'>
        <CalendarSVG className='mx-2 text-gray-700' />
        <span>Date</span>
      </div>
    </div>
  );
};
