import React from 'react';
import { useEffect, useState } from 'react';

import { createConsentForm, getConsentForms } from '@api/queries';
import { Button, PopperDropdown, PopperDropdownButton, Text } from '@components/common';
import { RestrictedAction } from '@components/shared/RestrictedAction';
import { UploadFileModal } from '@components/shared/UploadFileModal';
import { ConsentPreviewModal } from '@components/StudiesApp/components/StudyDraft/pages/Plan/components/ConsentPreviewModal';
import { compact } from '@components/utils';
import { usePermission } from '@hooks/usePermission';
import { useToaster } from '@stores/toaster';

interface Props {
  study: Study;
  onSave: (study: { id: number } & Partial<Study>) => void;
}

export const ConsentOptions: React.FC<React.PropsWithChildren<Props>> = ({ study, onSave }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [consentForms, setConsentForms] = useState<ConsentForm[] | null>(null);
  const isAdmin = usePermission('isAdmin')();

  const showToast = useToaster();

  useEffect(() => {
    getConsentForms().then((resp) => {
      if (resp) {
        setConsentForms(resp.filter((consentForm) => !consentForm.team_id || consentForm.team_id == study.team_id));
      }
    });
  }, []);

  const onDrop = async ([file]) => {
    const [err, consentForm] = await createConsentForm({ file });
    if (consentForm) {
      setConsentForms([...(consentForms || []), consentForm]);
      await onSave({ id: study.id, consent_form_id: consentForm.id });
      setOpen(false);
      showToast({
        icon: 'success',
        heading: 'Consent form uploaded!',
        text: 'Consent form was successfully uploaded.'
      });
    } else if (err) {
      showToast({
        icon: 'error',
        heading: 'Failed to upload a consent form',
        text: 'Something went wrong. Please try again later.'
      });
    }
  };

  const defaultOption = {
    value: 'null',
    label: 'Standard GQ consent checkboxes'
  };
  const currentForm = consentForms?.find((form) => study.consent_form_id === form.id);

  const options = compact([
    defaultOption,
    ...(consentForms || []).map((form) => ({ label: form.title, value: String(form.id) })),
    isAdmin && { value: '__NEW__', label: 'Upload new…' }
  ]);

  const selectedOption = options.find((option) => option.value === String(study.consent_form_id));

  const onChange = (valueStr: string) => {
    if (valueStr === '__NEW__') {
      setOpen(true);
      return;
    }

    const value = valueStr === 'null' ? null : parseInt(valueStr);
    onSave({ id: study.id, consent_form_id: value as any });
  };

  return (
    <div className='py-6'>
      <RestrictedAction feature='custom_consent'>
        {({ can, may }) => (
          <>
            <Text h='500' bold>
              Consent
            </Text>
            <Text className='mb-4 mt-2' h='400' color='gray-500'>
              This is what participants will be required to agree to before participating in your studies.
            </Text>
            <div className='flex items-center space-x-4'>
              <PopperDropdown
                buttonClassName='flex-1 truncate flex items-center justify-between font-normal'
                aria-label='Consent form select'
                dropdownClass='w-full truncate'
                data-testid='select-button'
                popperProps={{
                  closeOnClickOutside: true,
                  renderOnBodyRoot: false,
                  placement: 'bottom',
                  syncWidth: true,
                  zIndex: 20
                }}
                text={<Text className='h400 flex-1 truncate text-left'>{selectedOption?.label}</Text>}
              >
                {options.map((option) => (
                  <PopperDropdownButton className='truncate' key={option.value} onClick={() => onChange(option.value)}>
                    {option.label}
                  </PopperDropdownButton>
                ))}
              </PopperDropdown>

              <Button data-testid='preview-consent-form' icon='eye' onClick={() => setPreviewOpen(true)}>
                Preview
              </Button>
            </div>
            <ConsentPreviewModal form={currentForm} onClose={() => setPreviewOpen(false)} open={previewOpen} />
            <UploadFileModal
              open={open}
              onClose={() => setOpen(false)}
              onDrop={onDrop}
              heading='Upload consent form'
              fileTypesText='We support pdf files of any size.'
              supportedFileTypes={['pdf']}
            />
          </>
        )}
      </RestrictedAction>
    </div>
  );
};
