import * as React from 'react';

import ReactSkeleton from 'react-loading-skeleton';

export const Skeleton: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div data-testid='attributes-table-skeleton' className='w-full'>
    <div className='flex justify-between py-4'>
      <ReactSkeleton duration={1} width={150} className='h-8 rounded-md bg-gray-50' />
      <ReactSkeleton duration={1} width={175} className='h-8 rounded-md bg-gray-50' />
    </div>

    <ReactSkeleton duration={1} className='block h-8 w-full rounded-md bg-gray-50' count={12} />
  </div>
);
