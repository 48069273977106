import * as React from 'react';

import { ScreenSharingProvider } from '@components/ScreenSharingProvider';
import { Models } from '@components/SurveyBuilder';
import { render } from '@helpers/render';

import { UnmoderatedProvider } from './hooks/useUnmoderatedContext';
import { Unmoderated } from './Unmoderated';
import { inferPermissionsFromBlocks } from './utils';

interface ClientProps {
  blocks: Models.Block[];
  deviceType: Study['device_type'];
  participation: Participation;
  surveyId: number;
  topBarUnmoderatedLayout: boolean;
}
interface ServerProps {
  blocks: Models.Block[];
  participation: Participation;
  device_type: Study['device_type'];
  top_bar_unmoderated_layout: boolean;
}

export const mount = () =>
  render<ClientProps, ServerProps>({
    component: ({ blocks, deviceType, participation, surveyId, topBarUnmoderatedLayout }) => (
      <UnmoderatedProvider value={{ blocks, deviceType, topBarUnmoderatedLayout }}>
        <ScreenSharingProvider
          participation={participation}
          permissions={inferPermissionsFromBlocks(blocks)}
          device={deviceType ?? 'desktop'}
        >
          <Unmoderated participation={participation} surveyId={surveyId} />
        </ScreenSharingProvider>
      </UnmoderatedProvider>
    ),
    id: 'unmoderated',
    props: ({ blocks, participation, device_type, top_bar_unmoderated_layout }) => ({
      blocks,
      participation,
      surveyId: participation.project_id,
      deviceType: device_type,
      topBarUnmoderatedLayout: top_bar_unmoderated_layout
    })
  });
