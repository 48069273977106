import React from 'react';

import { FullStarSVG, StarSVG } from '@components/svgs';

export const IdealAnswerStar: React.FC<
  React.PropsWithChildren<{ mt?: string; ideal: boolean; onClick: () => void }>
> = ({ mt, ideal, onClick }) => (
  <span className={`absolute -left-8 ${mt ? `mt-${mt}` : ''}`} onClick={() => onClick()}>
    {ideal ? <FullStarSVG width='16' height='16' /> : <StarSVG width='16' height='16' className='text-gray-400' />}
  </span>
);
