import * as React from 'react';
import { useState } from 'react';

import { Button, Loading, Modal, ModalHeading } from '@components/common';
import { ButtonProps } from '@components/common/Button';

export type StudyActionModalProps = {
  id: number;
  title: string;
  style?: Study['style'];
  open: boolean;
  onClose: () => void;
  onAfterSubmit?: () => void;
};

interface Props extends StudyActionModalProps {
  heading: string;
  loading?: boolean;
  icon?: 'danger';
  primaryButtonProps?: Partial<ButtonProps>;
  onSubmit: () => Promise<void>;
}
export const StudyActionModal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  open,
  onClose,
  loading,
  onSubmit,
  heading,
  icon,
  primaryButtonProps
}) => {
  const [saving, setSaving] = useState(false);

  if (!open) {
    return null;
  }

  async function handleClick() {
    setSaving(true);
    await onSubmit();
    setSaving(false);
  }

  return (
    <Modal
      icon={icon}
      onClose={saving ? undefined : onClose}
      size='md'
      renderFooter={() => (
        <div className='space-x-4'>
          <Button disabled={saving} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={saving} onClick={handleClick} {...primaryButtonProps} />
        </div>
      )}
    >
      {loading && <Loading absolute />}
      <ModalHeading className='mb-2'>{heading}</ModalHeading>
      {children}
    </Modal>
  );
};
