import * as React from 'react';

import { SUPPORT_EMAIL } from '@constants/emails';

import { Heading, Text } from '@components/common';
import { NoResultsSVG } from '@components/RepositoryApp/components/NoResultsSVG';

export const NoResults: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div className='mx-auto max-w-xl text-center'>
    <NoResultsSVG className='mx-auto mb-4' />
    <Heading className='mb-2'>Sorry, there are no studies that match your filters.</Heading>
    <Text>
      It may be hiding somewhere. Double check your filter criteria or you can always drop us a line at
      {SUPPORT_EMAIL} if you need help finding something.
    </Text>
  </div>
);
