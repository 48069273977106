import * as React from 'react';

import { Provider } from 'react-redux';

import { store as reduxStore } from '@api/reduxApi';
import { Loading } from '@components/common';
import { Context, SentryProvider } from '@components/providers';

import { useGetWhoamiQuery } from './stores/whoami';
import { AppErroredPage } from './AppErroredPage';
import { noop } from './utils';

import 'i18n/translations';

export const AppLoading: React.FC<React.PropsWithChildren<{ blockOnLoad: boolean }>> = ({ blockOnLoad }) =>
  blockOnLoad ? (
    <div className='fixed inset-0 z-50 bg-white'>
      <Loading absolute bg='transparent' />
    </div>
  ) : (
    <Loading absolute bg='transparent' />
  );

const Whoami: React.FC<
  PropsWithChildrenFn<
    {
      blockOnLoad?: boolean;
      hideLoader?: boolean;
    },
    { whoami: Whoami }
  >
> = ({ blockOnLoad = false, hideLoader = false, children }) => {
  const { data: whoami, isLoading, isError } = useGetWhoamiQuery();

  if (isLoading && !hideLoader) {
    return <AppLoading blockOnLoad={blockOnLoad} />;
  }

  if (isError) {
    return (
      <AppErroredPage
        error={{ name: 'Loading whoami failed', message: 'Got an error fetching whoami' }}
        componentStack={null}
      />
    );
  }
  if (!whoami) {
    return null;
  }

  return typeof children === 'function' ? children({ whoami: whoami as Whoami }) : children;
};

export const AppLoader: React.FC<React.PropsWithChildren<{ blockOnLoad?: boolean; hideLoader?: boolean }>> = ({
  hideLoader = false,
  blockOnLoad = false,
  children
}) => {
  return (
    <Provider store={reduxStore}>
      <Whoami blockOnLoad={blockOnLoad} hideLoader={hideLoader}>
        {({ whoami }) => (
          <Context whoami={whoami}>
            <SentryProvider whoami={whoami} renderComponent={(props) => <AppErroredPage {...props} />}>
              {children}
            </SentryProvider>
          </Context>
        )}
      </Whoami>
    </Provider>
  );
};
