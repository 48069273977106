import * as React from 'react';
import { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { getCandidateParticipations } from '@api/queries';
import { ParticipationPill } from '@components/shared/ParticipationPill';

interface Props {
  candidateId: number;
}
export const CandidateStudies: React.FC<React.PropsWithChildren<Props>> = ({ candidateId }) => {
  const [loading, setLoading] = useState(true);
  const [parties, setParties] = useState<Participation[] | null>(null);

  useEffect(() => {
    getCandidateParticipations(candidateId).then((resp) => {
      setParties(resp);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div>
        <Skeleton duration={1} className='block h-12 w-full rounded-md bg-gray-50' count={2} />
      </div>
    );
  }

  return (
    <div className='divide-y-gray-200 divide-y'>
      {parties?.map((party) => (
        <div key={party.id} className='py-4'>
          {party.study && (
            <Link
              className='block text-gray-700 hover:text-indigo-600'
              to={`/studies/${party.study.id}#candidates/${party.customer_id}`}
            >
              <div className='flex items-center'>
                <span className='flex-1 leading-loose'>{party.study.title}</span>
                <svg
                  className='text-indigo-600'
                  width='8'
                  height='12'
                  viewBox='0 0 8 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.70504 0L0.295044 1.41L4.87504 6L0.295044 10.59L1.70504 12L7.70504 6L1.70504 0Z'
                    fill='currentColor'
                  />
                </svg>
              </div>
              <ParticipationPill participation={party} />
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};
