import * as React from 'react';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useBeforeunload } from 'react-beforeunload';
import Daily from '@daily-co/daily-js';
import { useDaily, useDevices, useLocalParticipant, useRecording, useScreenShare } from '@daily-co/daily-react';

import { clearStorage } from '@components/Unmoderated/utils';

import { api } from '../api';
import { Props } from '../ScreenSharingProvider';
import { buildRoomUrl } from '../utils';

type ScreenShareContext = Pick<ReturnType<typeof useScreenShare>, 'startScreenShare' | 'isSharingScreen'>;

type RecordingContext = Pick<ReturnType<typeof useRecording>, 'isRecording' | 'recordingId' | 'startRecording'>;

type DevicesContext = Pick<
  ReturnType<typeof useDevices>,
  'cameras' | 'setCamera' | 'microphones' | 'setMicrophone' | 'camState' | 'micState' | 'hasCamError' | 'hasMicError'
>;

export type Context = {
  participation?: Participation;
  roomUrl: string | null;
  isEnabled: boolean;
  readyToJoin: boolean;
  isJoined: boolean;
  isCanceled: boolean;
  canStartRecording: boolean;
  isDisabled: boolean;
  joinCall: () => void;
  enableRecording: () => void;
  endRecording: (early: boolean) => void;
} & ScreenShareContext &
  RecordingContext &
  DevicesContext;

export const context = createContext<Context>({} as any);

export const useParticipantRecording = (): Context => useContext(context);

export const ParticipantRecordingProvider: FC<React.PropsWithChildren<Props>> = ({
  children,
  participation,
  permissions,
  device
}) => {
  const call = useDaily();
  const localParticipant = useLocalParticipant();
  const { cameras, setCamera, microphones, setMicrophone, camState, micState, hasCamError, hasMicError } = useDevices();
  const { startRecording, stopRecording, isRecording, recordingId } = useRecording();
  const { startScreenShare, isSharingScreen } = useScreenShare();
  const [isEnabled, setIsEnabled] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  const [createRoom, { data }] = api.useCreateDailyRoomMutation();

  const roomUrl = useMemo(() => (data ? buildRoomUrl(data.name) : null), [data]);

  const browser = Daily.supportedBrowser();

  const isJoined = !!localParticipant;

  const cameraPermission = permissions?.camera || 'required';
  const micPermission = permissions?.microphone || 'required';
  const screenPermission = permissions?.screen || 'required';

  const isDisabled =
    cameraPermission === 'disabled' &&
    micPermission === 'disabled' &&
    (screenPermission === 'disabled' || device === 'mobile');

  useEffect(() => {
    if (isEnabled && participation) {
      createRoom({ token: participation.token });
    }
  }, [isEnabled, participation]);

  const canStartRecording = useMemo(() => {
    const cameraReady = camState === 'granted' || cameraPermission === 'disabled';
    const micReady = micState === 'granted' || micPermission === 'disabled';
    const screenReady = isSharingScreen || screenPermission === 'disabled' || device === 'mobile';

    return cameraReady && micReady && screenReady;
  }, [
    camState,
    micState,
    isSharingScreen,
    cameraPermission,
    micPermission,
    screenPermission,
    browser.supportsScreenShare
  ]);

  const endRecording = useCallback(
    (early: boolean) => {
      stopRecording();

      if (early) {
        if (participation) {
          clearStorage(participation);
        }
        setIsCanceled(true);
      } else {
        setIsEnded(true);
      }
    },
    [stopRecording]
  );

  const enableRecording = useCallback(() => setIsEnabled(true), []);

  const readyToJoin = Boolean(isEnabled && call && roomUrl && !isJoined);

  const joinCall = useCallback(() => {
    if (call && readyToJoin && roomUrl) {
      call.join({ url: roomUrl });
    }
  }, [call, roomUrl, readyToJoin]);

  useBeforeunload(() => {
    if (isRecording && participation) {
      clearStorage(participation);
    }
  });

  return (
    <context.Provider
      value={{
        participation,
        roomUrl,
        recordingId,
        startScreenShare,
        isEnabled,
        readyToJoin,
        isJoined,
        joinCall,
        enableRecording,
        endRecording,
        isCanceled,
        isSharingScreen,
        isRecording,
        camState,
        micState,
        hasCamError,
        hasMicError,
        canStartRecording,
        startRecording,
        cameras,
        setCamera,
        microphones,
        setMicrophone,
        isDisabled
      }}
    >
      {children}
    </context.Provider>
  );
};
