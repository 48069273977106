import { TabValues } from '@components/ScreenerResults/components/CardSortResponse/types';

export const TABS: TabValues[] = ['table', 'cards', 'categories', 'agreement_matrix'];

export const LABELS: Record<TabValues, string> = {
  table: 'Table',
  cards: 'Cards',
  categories: 'Categories',
  agreement_matrix: 'Agreement matrix'
};

export const LEGEND_ITEMS = [
  {
    style: { backgroundColor: '#F0F5FF' },
    from: 1,
    to: 40
  },
  {
    style: { backgroundColor: '#76A9FA' },
    from: 41,
    to: 60
  },
  {
    style: { backgroundColor: '#5850EC' },
    from: 61,
    to: 80
  },
  {
    style: { backgroundColor: '#12102F' },
    from: 81,
    to: 100
  }
];
