import React, { useMemo } from 'react';

import { Text } from '@components/common';

import { LABELS } from '../constants';
import { Job, Provider } from '../types';
import { isFailed, syncText } from '../utils';

import { JobButton } from './JobButton';

export const JobStatus: React.FC<
  React.PropsWithChildren<{
    job: Job;
    lastFinishedAt: string | undefined;
    task: BackgroundTask | undefined;
    provider: Provider;
    expired?: boolean;
  }>
> = ({ job, lastFinishedAt, task, provider, expired = false }) => {
  const failed = useMemo(() => isFailed(task), [task]);
  const label = useMemo(() => LABELS[job], [job]);
  const latestSyncText = useMemo(() => syncText(label, task, lastFinishedAt), [task]);

  // only let you run an import every ten minutes
  const disabled: boolean = (!!task && new Date(task.created_at) > new Date(Date.now() - 10 * 60 * 1000)) || expired;

  const inProgress = task && ['pending', 'processing'].includes(task.status);

  if (task && inProgress) {
    return (
      <>
        <Text bold>Active {label} running</Text>
        <Text className='text-sm'>{latestSyncText}</Text>
        <JobButton job={job} provider={provider} disabled={disabled} />
      </>
    );
  }

  if (task && !failed) {
    return (
      <>
        <Text bold>Last {label} successful</Text>
        <Text className='text-sm'>{latestSyncText}</Text>
        <JobButton job={job} provider={provider} disabled={disabled} />
      </>
    );
  }
  if (task) {
    return (
      <>
        <Text bold>Last {label} failed</Text>
        <Text className='text-sm'>
          Error: <br />
          <code>{task.error_message}</code>
        </Text>
        <JobButton job={job} provider={provider} />
      </>
    );
  }
  return (
    <>
      <Text bold>{latestSyncText}</Text>
      <JobButton job={job} provider={provider} />
    </>
  );
};
