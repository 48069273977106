import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { useUser } from '@hooks/useUser';
import { UsersConnectedAccountCreatedChannel, UsersConnectedAccountsChannel, useWebsocket } from '@hooks/useWebsocket';

import { bookabilityHasError, bookabilityHasWarning } from '../utils';

const POLLING_INTERVAL = 3000;

type Props = {
  studyId: number;
  event?: BookabilityEvent;
};

type Hook = {
  bookability?: Bookability;
  isLoading: boolean;
  hasError: boolean;
  hasWarning: boolean;
  refetch: () => void;
  startPolling: () => void;
};

export const useStudyBookability = ({ studyId, event }: Props): Hook => {
  const [polling, setPolling] = useState(false);

  const {
    data: bookability,
    isLoading,
    refetch
  } = api.useGetStudyBookabilityQuery(
    { studyId, event: event || 'invite' },
    { skip: !event, pollingInterval: polling ? POLLING_INTERVAL : 0 }
  );

  const user = useUser();

  useWebsocket<{ user_id: number }, ConnectedAccount>({
    channel: UsersConnectedAccountsChannel,
    params: { user_id: user.id },
    onReceived: refetch
  });
  useWebsocket<{ user_id: number }, ConnectedAccount>({
    channel: UsersConnectedAccountCreatedChannel,
    params: { user_id: user.id },
    onReceived: refetch
  });

  useEffect(() => {
    if (polling && bookability && Object.keys(bookability).length === 0) {
      setPolling(false);
    }
  }, [polling, bookability]);

  return {
    bookability,
    isLoading,
    hasError: bookabilityHasError(bookability),
    hasWarning: bookabilityHasWarning(bookability),
    refetch,
    startPolling: () => setPolling(true)
  };
};
