import * as React from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { GlobeSVG } from '@components/svgs';
import { findCurrentTimeZoneName } from '@components/utils/tz';

export const TimezoneIndicator: React.FC<React.PropsWithChildren<{ className?: string; timezone?: jstz.TimeZone }>> = ({
  className,
  timezone
}) => (
  <div className={cn('flex items-center space-x-2', className)}>
    <GlobeSVG />
    <Text h='400'>{timezone ? timezone.name() : findCurrentTimeZoneName()}</Text>
  </div>
);
