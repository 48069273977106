import * as React from 'react';

export const navCollapse: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg width='24' height='16' viewBox='0 0 24 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.09362 12.2188L2.07362 8.19875C2.04749 8.17266 2.02677 8.14168 2.01263 8.10758C1.99849 8.07348 1.99121 8.03692 1.99121 8C1.99121 7.96308 1.99849 7.92652 2.01263 7.89242C2.02677 7.85832 2.04749 7.82734 2.07362 7.80125L6.09362 3.78125'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path d='M9.5 12.002H22.5' stroke='white' strokeLinecap='round' strokeLinejoin='round'></path>
    <path d='M9.5 8.00201H22.5' stroke='white' strokeLinecap='round' strokeLinejoin='round'></path>
    <path d='M9.5 4.00201H22.5' stroke='white' strokeLinecap='round' strokeLinejoin='round'></path>
  </svg>
);
