import React, { forwardRef, HtmlHTMLAttributes } from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { AskAiSVG, StopSVG } from '@components/svgs';

interface Props extends HtmlHTMLAttributes<HTMLDivElement> {
  stop?: () => void;
}

export const Loading = forwardRef<HTMLDListElement, Props>(({ className, children, stop, ...rest }, ref) => {
  return (
    <section
      ref={ref}
      className={cn(
        'flex items-center rounded border border-gray-200 bg-white px-4 text-indigo-600 shadow-md',
        className
      )}
      {...rest}
    >
      <AskAiSVG className='mr-3 h-4 w-4' />
      <Text h='400'>{children}</Text>

      {stop && (
        <button onClick={stop} className='focus:outline-none ml-auto' aria-label='Stop generating suggestions'>
          <StopSVG />
        </button>
      )}
    </section>
  );
});
