import * as React from 'react';
import { useState } from 'react';

import tinytime from 'tinytime';

import { Button } from '@components/common';
import { ApproveStudyModal } from '@components/StudiesApp/components/modals/ApproveStudyModal';
import { RequestApprovalStudyModal } from '@components/StudiesApp/components/modals/RequestApprovalStudyModal';
import { Tooltip } from '@components/Tooltip';
import { usePermission } from '@hooks/usePermission';

const dateFormat = tinytime('{MM} {DD} at {h}:{mm}{a}');

export type ApprovalButtonProps = {
  study: Study;
  approvalRequest?: ApprovalRequest;
};

export const ApprovalButton: React.FC<React.PropsWithChildren<ApprovalButtonProps>> = ({ approvalRequest, study }) => {
  const [requestApprovalModal, setRequestApprovalModal] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);

  const canApproveStudy = usePermission('approveStudy')();

  const isPendingApproval =
    study.state === 'pending' && (study.pending_reasons?.includes('approval') || study.pending_reasons?.length === 0);
  const showApproveStudyButton = isPendingApproval && canApproveStudy;
  const showRequestApprovalButton = isPendingApproval && !canApproveStudy;

  const isApproveDisabled = !!approvalRequest;

  const tooltipCopy = approvalRequest ? `Request submitted ${dateFormat.render(approvalRequest.created_at)}` : null;

  return (
    <>
      {showRequestApprovalButton && (
        <Tooltip isDisabled={!isApproveDisabled} content={tooltipCopy}>
          <Button disabled={isApproveDisabled} primary onClick={() => setRequestApprovalModal(true)}>
            Request approval
          </Button>
        </Tooltip>
      )}
      {showApproveStudyButton && (
        <Button primary onClick={() => setApproveModalOpen(true)}>
          Approve study
        </Button>
      )}
      <RequestApprovalStudyModal
        id={study.id}
        open={requestApprovalModal}
        title={study.title}
        onClose={() => setRequestApprovalModal(false)}
      />
      <ApproveStudyModal
        id={study.id}
        open={approveModalOpen}
        title={study.title}
        onClose={() => setApproveModalOpen(false)}
      />
    </>
  );
};
