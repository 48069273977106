import { Models } from '@components/SurveyBuilder';
import { useLocalStorage } from '@hooks/useLocalStorage';

import { parseStorageKey, UnmoderatedStorageKeys } from '../utils';

type UseGetLocalActiveBlock = (participation?: Participation) => Models.Block | null;

export const useGetLocalActiveBlock: UseGetLocalActiveBlock = (participation) => {
  const [storedBlock] = useLocalStorage<Models.Block>(
    parseStorageKey({ key: UnmoderatedStorageKeys.ACTIVE_BLOCK, participation })
  );

  return storedBlock;
};
