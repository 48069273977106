import * as React from 'react';
import { HTMLAttributes } from 'react';

import ReactSkeleton from 'react-loading-skeleton';

export const TableSkeleton: React.FC<React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = (props) => (
  <div className='min-h-screen w-full' {...props}>
    <ReactSkeleton duration={1} className='block h-8 w-full rounded-md bg-gray-50' count={15} />
  </div>
);
