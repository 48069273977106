import React, { useState } from 'react';

import { Alert, Text, Toggle } from '@components/common';
import { useAccount } from '@hooks/useAccount';
import { usePermission } from '@hooks/usePermission';

import { TermsModal } from './TermsModal';

interface Props {
  onEnable: () => void;
}
export const EnableToggle = ({ onEnable }) => {
  const canManage = usePermission('isAdmin')();
  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
  const {
    update,
    account: { ai_enabled, created_at }
  } = useAccount();

  const hasNewTerms = new Date(created_at) > new Date('2023-09-01');

  function tryToggleAi(v: boolean) {
    if (v && !hasNewTerms) {
      setShowTermsModal(true);
    } else {
      handleToggleAI(v);
    }
  }

  async function handleToggleAI(v: boolean) {
    await update({ ai_enabled: v });
    if (v) {
      onEnable();
    }
  }

  return (
    <>
      <div className='mt-2 flex flex-row items-center space-x-2'>
        <Toggle on={ai_enabled} onToggle={tryToggleAi} disabled={!canManage} />
        <Text h='400' color={canManage ? 'gray-700' : 'gray-500'}>
          Enable AI features on your account.
        </Text>
      </div>
      {!canManage && !ai_enabled && (
        <Alert type='warning' className='mt-4'>
          Please contact your account admin for access to this feature
        </Alert>
      )}
      {showTermsModal && (
        <TermsModal
          onAccept={() => {
            handleToggleAI(true);
            setShowTermsModal(false);
          }}
          onClose={() => setShowTermsModal(false)}
        />
      )}
    </>
  );
};
