import * as React from 'react';
// state
import { HTMLAttributes, useRef, useState } from 'react';

import cn from 'classnames';

// components
import { SolidKebabSVG } from '@components/svgs';
import { useOnClickOutside } from 'components/utils';

type Props = {
  dropdownClassName?: string;
  className?: string;
  svg?: React.ReactNode;
};

export const OptionsDropdown: React.FC<React.PropsWithChildren<Props & HTMLAttributes<HTMLButtonElement>>> = ({
  dropdownClassName,
  className,
  children,
  svg,
  ...rest
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const closeDropdown = () => setDropdownOpen(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, closeDropdown);

  return (
    <button onClick={toggleDropdown} className={cn('focus:outline-none relative', className)} name='more' {...rest}>
      {svg || <SolidKebabSVG className='h-4 w-4' />}
      {dropdownOpen && (
        <div
          ref={dropdownRef}
          className={cn(
            'absolute right-0 z-10 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5',
            dropdownClassName
          )}
        >
          {children}
        </div>
      )}
    </button>
  );
};
