import React, { forwardRef, SVGProps } from 'react';

export const FitWidth = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2940_17854)'>
      <rect x='0.5' y='0.5' width='15' height='15' rx='1.5' stroke='currentColor' />
      <path
        d='M4.5625 9.25L3.3125 8L4.5625 6.75'
        stroke='currentColor'
        strokeWidth='0.625'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.4375 6.75L12.6875 8L11.4375 9.25'
        stroke='currentColor'
        strokeWidth='0.625'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.3125 8H12.6875'
        stroke='currentColor'
        strokeWidth='0.625'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2940_17854'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
));
