export const ZDS_TEXT = {
  no_results: {
    title: 'No responses',
    description: 'None of the participants have added a response yet.'
  },
  no_answers: {
    title: 'Results are no longer available',
    description: 'The results are no longer available because the blocks have been removed from the builder.'
  }
};
