import React from 'react';

import pluralize from 'pluralize';

import { Alert } from '@components/common';

export const AlreadyParticipatedAlert: React.FC<React.PropsWithChildren<{ diff: number; total: number }>> = ({
  diff,
  total
}) => {
  if (diff === 0) {
    return null;
  }
  const isAll = total === diff;
  let prefix = pluralize('candidates', diff, true);
  if (total === diff) {
    prefix = total > 1 ? 'All candidates' : 'Candidate';
  }
  return (
    <Alert type={isAll ? 'error' : 'warning'} className='mb-4'>
      {prefix} {diff > 1 ? 'have' : 'has'} already participated in this study and will not be reinvited.
    </Alert>
  );
};
