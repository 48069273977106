import * as React from 'react';
import { useEffect, useState } from 'react';

import pluralize from 'pluralize';
import tinytime from 'tinytime';

import { Button, Checkbox, Input, Loading, Modal, Text } from '@components/common';
import { useToaster } from '@components/stores/toaster';
import { without } from '@components/utils';
import { useSearch } from '@hooks/useSearch';
import { api } from 'api/reduxApi';

import * as toasts from '../toasts';

const timeTemplate = tinytime('{h}:{mm} {a} {dddd}, {MM} {DD}, {YYYY}');

const Recording: React.FC<
  React.PropsWithChildren<{ recording: ZoomRecording; onToggle: () => void; selected: boolean }>
> = ({ selected, onToggle, recording: { start_time, duration, topic } }) => {
  return (
    <div
      className={`mb-4 flex cursor-pointer flex-row space-x-4 rounded border border-gray-200 px-4 py-4 ${
        selected ? 'border-indigo-600 bg-indigo-100' : ''
      }`}
      onClick={() => onToggle()}
    >
      <Checkbox selected={selected} />
      <div className='w-full'>
        <Text h='500' bold>
          {topic}
        </Text>
        <div className='mt-2 flex flex-row justify-between'>
          <Text h='400' color='gray-500'>
            {timeTemplate.render(start_time)}
          </Text>
          <Text h='400' color='gray-500'>
            {duration} minutes
          </Text>
        </div>
      </div>
    </div>
  );
};

interface Props {
  open: boolean;
  allowMultiple?: boolean;
  onClose: () => void;
  onSelect: (recordings: ZoomRecording[]) => void;
}

export const ZoomRecordingsModal: React.FC<React.PropsWithChildren<Props>> = ({
  allowMultiple,
  open,
  onClose,
  onSelect
}) => {
  const showToast = useToaster();

  const [search, setSearch] = useState('');
  const [selectedRecordings, setSelectedRecordings] = useState<ZoomRecording[]>([]);

  const { data: recordings = [], isError, isSuccess } = api.useGetZoomRecordingsQuery(undefined, { skip: !open });

  const selectedRecordingUuids = selectedRecordings.map((recording) => recording.uuid);

  const selectRecording = (recording: ZoomRecording) => {
    if (!allowMultiple) {
      setSelectedRecordings([recording]);
    } else if (selectedRecordingUuids.includes(recording.uuid)) {
      setSelectedRecordings(without(selectedRecordings, recording));
    } else {
      setSelectedRecordings([...selectedRecordings, recording]);
    }
  };

  useEffect(() => {
    if (isError && open) {
      showToast(toasts.failedFetching());
      onClose();
    }
  }, [isError, open]);

  const { results: filteredRecordings } = useSearch(recordings, ['topic'], search);

  if (!open) {
    return <></>;
  }

  return (
    <Modal
      title='Select Zoom Recording'
      size='md'
      onClose={onClose}
      renderFooter={() => (
        <div className='flex items-center space-x-4 text-left'>
          <div className='flex-1'>
            {allowMultiple && selectedRecordingUuids.length > 0 && (
              <Text>{pluralize('recordings', selectedRecordingUuids.length, true)} selected</Text>
            )}
          </div>
          <Button link onClick={onClose}>
            Cancel
          </Button>
          <Button primary disabled={selectedRecordings.length < 1} onClick={() => onSelect(selectedRecordings)}>
            Select
          </Button>
        </div>
      )}
    >
      {isSuccess ? (
        <div>
          {recordings.length > 0 ? (
            <>
              <div className='pb-6'>
                <Input
                  className='w-full'
                  type='search'
                  placeholder='Search recordings...'
                  value={search}
                  onChange={setSearch}
                />
              </div>
              <div className='max-h-80 overflow-y-scroll'>
                {filteredRecordings.map((r) => (
                  <Recording
                    key={r.uuid}
                    recording={r}
                    selected={selectedRecordingUuids.includes(r.uuid)}
                    onToggle={() => selectRecording(r)}
                  />
                ))}
              </div>
            </>
          ) : (
            <Text h='400' color='gray-500'>
              We couldn’t find any recent recordings in your connected Zoom account. Make sure you opt-into recording at
              the start of your interviews.
            </Text>
          )}
        </div>
      ) : (
        <Loading absolute />
      )}
    </Modal>
  );
};
