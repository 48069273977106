import * as React from 'react';

import { Link } from 'react-router-dom';
import tinytime from 'tinytime';

import { Pill, PopperDropdown, PopperDropdownButton } from '@components/common';
import { COLORS as PILL_COLORS } from '@components/common/Pill/Pill';
import { IncentiveAction } from '@components/IncentivesApp/types';
import { ColumnDef, Table } from '@components/shared/Table';
import { currencyToSymbol, humanize, moneyFormat } from '@components/utils';
import { useAccount } from '@hooks/useAccount';

const template = tinytime('{MMMM} {DD} at {h}:{mm}{a}');

const STATUS_MAPPING = {
  pending: 'Not sent'
};

const COLORS: Record<Incentive['status'], keyof typeof PILL_COLORS> = {
  pending: 'red',
  sent: 'yellow',
  redeemed: 'green',
  canceled: 'gray',
  used: 'gray'
};

function statusText(incentive: Incentive) {
  if (incentive.resent_at) {
    return 'Resent';
  } else {
    return humanize(STATUS_MAPPING[incentive.status] || incentive.status);
  }
}

function activityText(incentive: Incentive) {
  switch (incentive.status) {
    case 'redeemed':
      return template.render(incentive.redeemed_at);
    case 'sent':
      return template.render(incentive.sent_at);
    case 'canceled':
      return template.render(incentive.updated_at);
    default:
      return '-';
  }
}

interface Props {
  incentives: Incentive[];
  handleSelect: (i: Incentive, a: IncentiveAction) => void;
}

export const IncentivesTable: React.FC<React.PropsWithChildren<Props>> = ({ incentives, handleSelect }) => {
  const { getUserById } = useAccount();

  const columns = React.useMemo<ColumnDef<Incentive>[]>(() => {
    return [
      {
        id: 'candidate',
        minSize: 90,
        size: 130,
        accessorKey: 'candidate',
        header: (props) => (
          <Table.ColumnHeader isStatic {...props}>
            Participant
          </Table.ColumnHeader>
        ),
        cell: (props) => (
          <Table.Cell
            {...props}
            render={({ row }) => (
              <span className='truncate whitespace-nowrap px-2 text-sm font-bold leading-5 text-gray-900'>
                {row.original.study.deleted_at ? (
                  row.original.candidate.name
                ) : (
                  <Link
                    className='truncate px-2'
                    to={`/studies/${row.original.study.id}#candidates/${row.original.candidate.id}`}
                  >
                    {row.original.candidate.name}
                  </Link>
                )}
              </span>
            )}
          />
        )
      },
      {
        id: 'study_title',
        size: 200,
        minSize: 150,
        accessorFn: (originalRow) => `${originalRow.study.id}${originalRow.study.title}`,
        header: (props) => (
          <Table.ColumnHeader isStatic {...props}>
            Study
          </Table.ColumnHeader>
        ),
        cell: (props) => (
          <Table.Cell
            {...props}
            render={({ row }) => {
              return row.original.study.deleted_at ? (
                <span className='truncate px-2'>{row.original.study.title}</span>
              ) : (
                <Link className='truncate px-2' to={`/studies/${row.original.study.id}`}>
                  {row.original.study.title}
                </Link>
              );
            }}
          />
        )
      },
      {
        id: 'owner',
        minSize: 80,
        size: 120,
        accessorFn: (originalRow) => `${originalRow.study.owner_id}`,
        header: (props) => (
          <Table.ColumnHeader isStatic {...props}>
            Owner
          </Table.ColumnHeader>
        ),
        cell: (props) => (
          <Table.Cell
            {...props}
            render={({ row }) => (
              <span className='truncate px-2'>{getUserById(row.original.study.owner_id)?.name || 'Unknown'}</span>
            )}
          />
        )
      },
      {
        id: 'amount',
        minSize: 50,
        size: 80,
        accessorFn: (originalRow) => `${originalRow.local_amount_in_cents}`,
        header: (props) => (
          <Table.ColumnHeader isStatic {...props}>
            Amount
          </Table.ColumnHeader>
        ),
        cell: (props) => (
          <Table.Cell
            {...props}
            render={({ row }) => (
              <span className='px-2'>
                {currencyToSymbol(row.original.currency)}
                {moneyFormat(row.original.local_amount_in_cents / 100)}
              </span>
            )}
          />
        )
      },
      {
        id: 'recruitment_fee',
        minSize: 50,
        size: 80,
        accessorFn: (originalRow) => `${originalRow.fee_local_amount_in_cents}`,
        header: (props) => (
          <Table.ColumnHeader isStatic {...props}>
            Recruitment Fee
          </Table.ColumnHeader>
        ),
        cell: (props) => (
          <Table.Cell
            {...props}
            render={({ row }) => (
              <span className='px-2'>
                {currencyToSymbol(row.original.currency)}
                {moneyFormat(row.original.fee_local_amount_in_cents / 100)}
              </span>
            )}
          />
        )
      },
      {
        id: 'status',
        minSize: 70,
        size: 90,
        accessorFn: (originalRow) => `${originalRow.status}`,
        header: (props) => (
          <Table.ColumnHeader isStatic {...props}>
            Status
          </Table.ColumnHeader>
        ),
        cell: (props) => (
          <Table.Cell
            {...props}
            render={({ row }) => (
              <div className='px-2'>
                <Pill color={COLORS[row.original.status]}>{statusText(row.original)}</Pill>
              </div>
            )}
          />
        )
      },
      {
        id: 'last_activity',
        size: 80,
        minSize: 120,
        accessorFn: (originalRow) => `${activityText(originalRow)}`,
        header: (props) => (
          <Table.ColumnHeader isStatic {...props}>
            Last activity
          </Table.ColumnHeader>
        ),
        cell: (props) => (
          <Table.Cell
            {...props}
            render={({ row }) => <span className='truncate px-2'>{activityText(row.original)}</span>}
          />
        )
      },
      {
        id: 'actions',
        accessorFn: (originalRow) => `${originalRow.id}${originalRow.id}`,
        size: 116,
        header: (props) => <Table.ColumnHeader isStatic {...props} />,
        cell: (props) => (
          <Table.Cell
            {...props}
            render={({ row }) => {
              const hasActions = !['redeemed', 'used', 'canceled'].includes(row.original.status);

              return (
                <div className='flex w-full justify-end p-1 px-2'>
                  {hasActions && (
                    <PopperDropdown
                      aria-label='options'
                      medium
                      popperProps={{ placement: 'bottom-start', offset: [-64, 8] }}
                      text='Actions'
                    >
                      <PopperDropdownButton
                        aria-label='Send incentive'
                        onClick={() => handleSelect(row.original, 'send')}
                        className='xx-send-incentive'
                      >
                        {row.original.status == 'sent' ? 'Resend' : 'Send'} incentive…
                      </PopperDropdownButton>
                      <PopperDropdownButton
                        aria-label='Cancel incentive'
                        onClick={() => handleSelect(row.original, 'cancel')}
                      >
                        Cancel incentive…
                      </PopperDropdownButton>
                      <PopperDropdownButton
                        aria-label='Mark no-show'
                        onClick={() => handleSelect(row.original, 'no_show')}
                      >
                        Mark no-showed…
                      </PopperDropdownButton>
                    </PopperDropdown>
                  )}
                </div>
              );
            }}
          />
        )
      }
    ];
  }, [incentives]);

  return (
    <div className={`relative max-w-full overflow-auto border-l border-r border-gray-200`}>
      <Table data={incentives} columns={columns} className={`w-full table-fixed border-t border-gray-200 bg-white`} />
    </div>
  );
};
