import * as React from 'react';

export const chat: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='mr-3 h-4 w-4 text-white group-hover:text-gray-300'
  >
    <g clipPath='url(#clip0_2799_36718)'>
      <path
        d='M3.625 11.5H3.1875C2.72337 11.5 2.27825 11.3156 1.95006 10.9874C1.62187 10.6592 1.4375 10.2141 1.4375 9.75V8C1.4375 7.53587 1.62187 7.09075 1.95006 6.76256C2.27825 6.43437 2.72337 6.25 3.1875 6.25H3.625C3.74103 6.25 3.85231 6.29609 3.93436 6.37814C4.01641 6.46019 4.0625 6.57147 4.0625 6.6875V11.0625C4.0625 11.1785 4.01641 11.2898 3.93436 11.3719C3.85231 11.4539 3.74103 11.5 3.625 11.5Z'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.8125 11.5H12.375C12.259 11.5 12.1477 11.4539 12.0656 11.3719C11.9836 11.2898 11.9375 11.1785 11.9375 11.0625V6.6875C11.9375 6.57147 11.9836 6.46019 12.0656 6.37814C12.1477 6.29609 12.259 6.25 12.375 6.25H12.8125C13.2766 6.25 13.7217 6.43437 14.0499 6.76256C14.3781 7.09075 14.5625 7.53587 14.5625 8V9.75C14.5625 10.2141 14.3781 10.6592 14.0499 10.9874C13.7217 11.3156 13.2766 11.5 12.8125 11.5Z'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.1875 6.25C3.1875 4.97365 3.69453 3.74957 4.59705 2.84705C5.49957 1.94453 6.72365 1.4375 8 1.4375C9.27635 1.4375 10.5004 1.94453 11.403 2.84705C12.3055 3.74957 12.8125 4.97365 12.8125 6.25'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.75 13.6875H11.0625C11.5266 13.6875 11.9717 13.5031 12.2999 13.1749C12.6281 12.8467 12.8125 12.4016 12.8125 11.9375V11.5'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.875 14.5625H8C7.76794 14.5625 7.54538 14.4703 7.38128 14.3062C7.21719 14.1421 7.125 13.9196 7.125 13.6875C7.125 13.4554 7.21719 13.2329 7.38128 13.0688C7.54538 12.9047 7.76794 12.8125 8 12.8125H8.875C9.10706 12.8125 9.32962 12.9047 9.49372 13.0688C9.65781 13.2329 9.75 13.4554 9.75 13.6875C9.75 13.9196 9.65781 14.1421 9.49372 14.3062C9.32962 14.4703 9.10706 14.5625 8.875 14.5625Z'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.25 10.0078C6.72852 10.4439 7.3526 10.6856 8 10.6856C8.6474 10.6856 9.27148 10.4439 9.75 10.0078'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M6.25 6.82446V7.5898' stroke='white' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.75 6.82446V7.5898' stroke='white' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_2799_36718'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
