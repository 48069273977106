import { useCallback, useContext, useEffect } from 'react';

import { Blocker, History } from 'history';
import { UNSAFE_NavigationContext } from 'react-router-dom';

export const useBlocker = (blocker: Blocker, when = false) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx) => {
      const autoUnblockTx = {
        ...tx,
        retry: () => {
          unblock();
          tx.retry();
        }
      };

      blocker(autoUnblockTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

export const usePrompt = (message: string, when = false) => {
  const blocker: Blocker = useCallback(
    (tx) => {
      if (window.confirm(message)) {
        tx.retry();
      }
    },
    [message, when]
  );

  useBlocker(blocker, when);
};
