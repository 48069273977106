import qs from 'qs';

import { buildRecord } from '@api/builders';
import { api } from '@api/reduxApi';

const accountTeamsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query<Team[], { includeWallet?: boolean }>({
      query: ({ includeWallet }) => ({
        url: `/teams?${qs.stringify({ include_wallet: includeWallet })}`,
        method: 'GET'
      }),
      transformResponse: (resp: any) => resp.data.map(buildRecord),
      providesTags: (q, i, { includeWallet }) =>
        includeWallet
          ? [
              { type: 'Team', id: 'LIST' },
              { type: 'Team', id: 'LIST_WITH_WALLETS' }
            ]
          : [{ type: 'Team', id: 'LIST' }]
    }),
    createTeam: builder.mutation<void, { name: string; user_ids: number[]; color: string | null; icon: string | null }>(
      {
        query: (team) => ({
          url: '/teams',
          method: 'POST',
          body: { team }
        }),
        invalidatesTags: [{ type: 'Team', id: 'LIST' }, 'Whoami']
      }
    ),
    updateTeam: builder.mutation<void, Partial<Team> & { id: number; user_ids?: number[] }>({
      query: ({ id, ...team }) => ({
        url: `/teams/${id}`,
        method: 'PUT',
        body: { team }
      }),
      invalidatesTags: [{ type: 'Team', id: 'LIST' }]
    }),
    deleteTeam: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/teams/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Team', id: 'LIST' }]
    })
  })
});

export const { useGetTeamsQuery, useCreateTeamMutation, useUpdateTeamMutation, useDeleteTeamMutation } =
  accountTeamsApi;
