import React, { FC } from 'react';

import { Button, Modal, ModalHeading, Text } from '@components/common';

interface Props {
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const CancelConfirmModal: FC<Props> = ({ onCancel, onConfirm }) => (
  <Modal
    size='sm'
    renderFooter={() => (
      <div className='flex items-center justify-end space-x-2'>
        <Button onClick={onCancel}>Go back</Button>
        <Button secondary onClick={onConfirm}>
          Confirm and end session
        </Button>
      </div>
    )}
  >
    <ModalHeading className='mb-6'>Are you sure?</ModalHeading>

    <Text h='400' mb='4'>
      Stopping the recording will end this test early, and you will have to start at the beginning to proceed.
    </Text>
  </Modal>
);
