import * as React from 'react';

import TextareaAutosize from 'react-textarea-autosize';

import { compact } from '@components/utils';

interface Props extends Omit<React.HTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  id?: string;
  error?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  disabled?: boolean;
  onKeyDown?: (e: any) => void;
  onBlur?: () => void;
  placeholder?: string;
  minRows?: number;
  maxRows?: number;
  name?: string;
  ariaLabel?: string;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      id,
      error,
      className: propsClassName = '',
      value,
      onChange,
      onBlur,
      onKeyDown,
      required,
      disabled,
      placeholder,
      minRows = 4,
      maxRows,
      name,
      ariaLabel,
      ...rest
    },
    ref
  ) => {
    const className = compact([
      'h400 block w-full p-3 placeholder-gray-400 transition duration-150 ease-in-out border-gray-200 rounded-md resize-none',
      error ? 'focus:ring-red-600 focus:border-red-600' : 'focus:ring-indigo-500 focus:border-indigo-500',
      error && 'border-red-600',
      propsClassName
    ]).join(' ');

    function handleChange(e: React.FormEvent<HTMLTextAreaElement>) {
      onChange?.(e.currentTarget.value);
    }
    return (
      <TextareaAutosize
        id={id}
        name={name}
        required={required}
        disabled={disabled}
        minRows={minRows}
        maxRows={maxRows}
        placeholder={placeholder}
        onBlur={() => onBlur?.()}
        onKeyDown={(e) => onKeyDown?.(e)}
        onChange={handleChange}
        className={className}
        value={value}
        ref={ref}
        aria-label={ariaLabel}
        {...rest}
      />
    );
  }
);
