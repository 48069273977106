import { Reducer, useEffect, useMemo, useReducer, useState } from 'react';

import * as Sentry from '@sentry/react';

import { Player, VideoLayer } from '@lib/canvas-player/Player';
import { ArtifactHit } from 'components/RepositoryApp/types';

import PlayerError from '../PlayerError';

enum Actions {
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_PLAYING = 'SET_IS_PLAYING',
  SET_ERRORS = 'SET_ERRORS',
  SET_CURRENT_TIME = 'SET_CURRENT_TIME',
  SET_DURATION = 'SET_DURATION',
  SET_VOLUME = 'SET_VOLUME',
  SET_PLAYBACK_RATE = 'SET_PLAYBACK_RATE'
}

type Action =
  | GenericAction<Actions.SET_IS_LOADING, boolean>
  | GenericAction<Actions.SET_IS_PLAYING, boolean>
  | GenericAction<Actions.SET_ERRORS, ICanvasLayerError[]>
  | GenericAction<Actions.SET_CURRENT_TIME, number>
  | GenericAction<Actions.SET_DURATION, number>
  | GenericAction<Actions.SET_VOLUME, number>
  | GenericAction<Actions.SET_PLAYBACK_RATE, number>;

interface State {
  isLoading: boolean;
  isPlaying: boolean;
  errors: ICanvasLayerError[];
  currentTime: number;
  duration: number;
  volume: number;
  playbackRate: number;
}

export interface Args {
  currentTime?: number;
  volume?: number;
  playbackRate?: number;
  artifacts: ArtifactHit[];
}

export interface Hook {
  setRef: (e: HTMLDivElement) => void;
  state: State;
  player: Player | null;
  compile: () => Promise<void>;
}

export const ERROR_MSG = 'Highlight reel clips are failing to load';

const INITIAL_STATE: State = {
  isLoading: false,
  isPlaying: false,
  errors: [],
  currentTime: 0,
  duration: 0,
  volume: 1,
  playbackRate: 1
};

const CANVAS_SIZE = {
  width: 1280,
  height: 720
};

const reducer = (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case Actions.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case Actions.SET_IS_PLAYING:
      return { ...state, isPlaying: action.payload };
    case Actions.SET_ERRORS:
      return { ...state, errors: action.payload };
    case Actions.SET_CURRENT_TIME:
      return { ...state, currentTime: action.payload };
    case Actions.SET_DURATION:
      return { ...state, duration: action.payload };
    case Actions.SET_VOLUME:
      return { ...state, volume: action.payload };
    default:
      return state;
  }
};

export const useCanvasPlayer = ({
  currentTime: initialCurrentTime = 0,
  volume: initialVolume = 1,
  playbackRate: initialPlaybackRate = 1,
  artifacts
}: Args): Hook => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, INITIAL_STATE);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const player = useMemo(() => (ref ? new Player(ref) : null), [ref]);

  const onPlay = () => dispatch({ type: Actions.SET_IS_PLAYING, payload: true });

  const onPause = () => dispatch({ type: Actions.SET_IS_PLAYING, payload: false });

  const onStop = () => {
    dispatch({ type: Actions.SET_IS_PLAYING, payload: false });
    dispatch({ type: Actions.SET_CURRENT_TIME, payload: 0 });
  };

  const onTimeUpdate = (t: number) => dispatch({ type: Actions.SET_CURRENT_TIME, payload: t / 1000 });

  const onReady = () => {
    if (player) {
      dispatch({ type: Actions.SET_IS_LOADING, payload: false });
      dispatch({ type: Actions.SET_DURATION, payload: player.duration / 1000 });
    }
  };

  const onError = () => {
    if (player) {
      dispatch({ type: Actions.SET_IS_LOADING, payload: false });
      dispatch({ type: Actions.SET_ERRORS, payload: player.errors });
      Sentry.captureException(new PlayerError(ERROR_MSG, player.errors));
    }
  };

  const getPoster = () => {
    if (artifacts.length && player) {
      const [artifact] = artifacts;
      const { thumbnail_url: poster = '' } = artifact;
      return poster;
    }
  };

  const setupPlayerLayers = async () => {
    const addedLayers: Promise<void>[] = [];

    if (player) {
      artifacts.forEach(({ stream_url, title, objectID }) => {
        if (stream_url) {
          const layer = new VideoLayer(stream_url, player.ctx);
          layer.name = title;
          layer.metadata = { objectID };

          addedLayers.push(player.addLayer(layer));
        }
      });
    }

    return new Promise<void>((resolve) =>
      setTimeout(async () => {
        await Promise.allSettled(addedLayers);
        resolve();
      }, 0)
    );
  };

  const compile = async () => {
    if (player && artifacts.length) {
      player.reset();

      const poster = getPoster();
      if (poster) {
        player.poster = poster;
      }

      dispatch({ type: Actions.SET_IS_LOADING, payload: true });
      dispatch({ type: Actions.SET_ERRORS, payload: [] });

      await setupPlayerLayers();

      onReady();
    }
  };

  useEffect(() => {
    if (player) {
      player.width = CANVAS_SIZE.width;
      player.height = CANVAS_SIZE.height;
      player.canvas.style.maxWidth = '100%';
      player.onPlay = onPlay;
      player.onPause = onPause;
      player.onStop = onStop;
      player.onTimeUpdate = onTimeUpdate;
      player.onError = onError;
    }
  }, [player]);

  useEffect(() => {
    if (player && player.ready) {
      player.setTime(initialCurrentTime);
    }
  }, [player, initialCurrentTime]);

  useEffect(() => {
    if (player && player.ready) {
      player.volume = initialVolume;
      dispatch({ type: Actions.SET_VOLUME, payload: initialVolume });
    }
  }, [player, initialVolume]);

  useEffect(() => {
    if (player && player.ready) {
      player.playbackRate = initialPlaybackRate;
      dispatch({ type: Actions.SET_PLAYBACK_RATE, payload: initialPlaybackRate });
    }
  }, [player, initialPlaybackRate]);

  return { setRef, state, player, compile };
};
