import * as React from 'react';
// hooks
import { forwardRef, useEffect, useState } from 'react';

import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import type { MutableRefObject, SyntheticEvent } from 'react';

// api
import { api } from '@api/reduxApi';
import VideoPlayer, { UseVideoPlayer } from 'components/VideoPlayer';

import { scrollToHighlight } from '../helpers';

import { ThumbnailPlayer } from './ThumbnailPlayer';
type PlaybackUrls = { stream_url: string; thumbnail_url: string; gif_url: string };

interface Props {
  bgClass?: string;
  recordingId: number;
  thumbnailOnly?: boolean;
  errored?: boolean;
  processing?: boolean;
  showMiniPlayer?: boolean;
  editorState?: EditorState;
  editorView?: EditorView;
  enableTranscriptScroll?: boolean;
}

const THUMBNAIL_RATIO = '16:9';

const getScrollableParent = (node: Element | null): Element | null => {
  if (node == null) return null;

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollableParent(node.parentNode as Element);
  }
};

const Player = forwardRef<UseVideoPlayer, Props>((props, ref) => {
  const { bgClass, thumbnailOnly, recordingId, processing, showMiniPlayer } = props;
  const [initialTs, setInitialTs] = useState<number | null>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  // state
  const [urls, setUrls] = useState<PlaybackUrls>(null as any);
  const [errored, setErrored] = useState(false);

  const { data: recording } = api.useGetRecordingQuery({ id: recordingId }, { skip: !recordingId });
  const [createMuxPlaybackUrls] = api.useCreateMuxPlaybackUrlsMutation();

  // effects
  useEffect(() => {
    if (recordingId && recording?.mux_video?.status === 'ready') {
      const { playback_id: playbackId } = recording.mux_video;

      createMuxPlaybackUrls({ playbackId })
        .unwrap()
        .then((resp) => {
          resp ? setUrls(resp) : setErrored(true);
        })
        .catch((e) => console.error(e));
    }
  }, [recordingId, recording]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const ts = queryParams.get('ts');
    if (ts) {
      scrollToHighlight(props.editorView?.dom, +ts);
    }
  }, [window.location.search, props.editorView?.dom]);

  if (processing) {
    return <ThumbnailPlayer aspectRatio={THUMBNAIL_RATIO} state='processing' />;
  }

  if (!recordingId) {
    return <ThumbnailPlayer aspectRatio={THUMBNAIL_RATIO} />;
  }

  if (errored) {
    return <ThumbnailPlayer aspectRatio={THUMBNAIL_RATIO} state='errored' />;
  }

  if (thumbnailOnly) {
    return (
      <ThumbnailPlayer
        aspectRatio={THUMBNAIL_RATIO}
        bg={bgClass}
        thumbnail={urls?.thumbnail_url}
        gif={urls?.gif_url}
        state={recording?.mux_video?.status === 'errored' ? 'errored' : undefined}
      />
    );
  }

  return null;
});

export { Player };
