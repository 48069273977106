import React from 'react';

import { Button } from '@components/common';
import { usePermission } from '@hooks/usePermission';
import { ExportCsvButton } from 'components/common/ExportCsvButton';
import { useAccount } from 'hooks/useAccount';

const HEADERS = [
  'name',
  'email',
  'created_at',
  'status',
  'amount',
  'recruitment_fee',
  'currency',
  'study_title',
  'study_owner'
];

export const CsvExport: React.FC<React.PropsWithChildren<{ incentives: Incentive[] }>> = ({ incentives }) => {
  const { getUserById } = useAccount();
  const isAdmin = usePermission('isAdmin')();
  if (!isAdmin) {
    return null;
  }
  const data = (incentives || []).map((incentive) => {
    const owner = getUserById(incentive.study.owner_id);
    return [
      incentive.candidate.name,
      incentive.candidate.email,
      incentive.created_at,
      incentive.status,
      incentive.local_amount_in_cents / 100,
      incentive.fee_local_amount_in_cents / 100,
      incentive.currency,
      incentive.study.title,
      owner?.name || 'Unknown'
    ];
  });

  return <ExportCsvButton data={data} medium headers={HEADERS} filename={`incentives-export.csv`} />;
};
