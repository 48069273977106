import React, { FC, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useGetWhoamiQuery } from '@components/stores/whoami';
import { Toast, useToaster } from '@stores/toaster';

import { useOnboardingInfoMutation } from '../api';
import { FormContext, FormData } from '../hooks';
import { getFormData } from '../utils';

export const failedUpdate = (): Toast => ({
  heading: 'An error occurred!',
  icon: 'error',
  text: 'Something went wrong updating your profile. Please try again later.'
});

interface Props {
  onNext: () => void;
  redirectTo: string | null;
}

export const Form: FC<React.PropsWithChildren<Props>> = ({ children, onNext, redirectTo }) => {
  const [updateOnboardingInfo, { isLoading, isSuccess, isError }] = useOnboardingInfoMutation();
  const { data: whoami } = useGetWhoamiQuery();
  const showToast = useToaster();
  const formData = getFormData(whoami as Whoami) as FormData;

  const { errors, register, handleSubmit } = useForm();

  const onSubmit = (data: Partial<FormData>) => {
    updateOnboardingInfo(data);
  };

  // when the form is submitted, the data is updated and the user is redirected to the next step
  // if the user is admin or creator, he is redirected to the next step
  // if the user is observer, he is redirected to the dashboard

  useEffect(() => {
    if (isSuccess && whoami) {
      ['admin', 'creator'].includes(whoami.role) ? onNext() : window.location.assign(redirectTo || '/');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(failedUpdate());
    }
  }, [isError]);

  return (
    <FormContext.Provider
      value={{
        formData,
        errors,
        register,
        isLoading
      }}
    >
      <form className='flex flex-1 flex-col' onSubmit={handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormContext.Provider>
  );
};
