import * as React from 'react';

import cn from 'classnames';

import { CaretSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

interface Props {
  disablePrevWeekButton?: boolean;
  onClick: () => void;
  withBorder?: boolean;
}

export const PreviousWeekButton: React.FC<React.PropsWithChildren<Props>> = ({
  withBorder,
  disablePrevWeekButton,
  onClick
}) => (
  <Tooltip isDisabled={!disablePrevWeekButton} content='Previous week is out of the date range'>
    <button
      disabled={disablePrevWeekButton}
      className={cn('focus:outline-none flex h-6 w-6 items-center justify-center rounded-full disabled:text-gray-400', {
        'hover:bg-gray-50 hover:text-indigo-600': !disablePrevWeekButton,
        'border border-gray-200': withBorder
      })}
      onClick={onClick}
      name='previous week'
      aria-label='Previous week'
    >
      <CaretSVG className='rotate-180 transform' />
    </button>
  </Tooltip>
);
