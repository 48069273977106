import React, { forwardRef, HTMLAttributes, useEffect, useMemo, useState } from 'react';

import { useBeforeunload } from 'react-beforeunload';
import { useForm } from 'react-hook-form';

import { DraggableCardProvider } from '@components/common/DraggableCard';
import { useParticipantRecording } from '@components/ScreenSharingProvider/hooks/useParticipantRecording';
import { Enums, Models } from '@components/SurveyBuilder';
import { merge } from '@helpers/merge';
import { useLocalStorage } from '@hooks/useLocalStorage';

import { CardSortTask } from './components/CardSortTask';
import { ImageRenderer } from './components/ImageRenderer';
import { Permissions } from './components/Permissions';
import { PrototypeTestTask } from './components/PrototypeTestTask';
import { Question } from './components/Question';
import { TreeTestTask } from './components/TreeTestTask';
import { WebsiteTestTask } from './components/WebsiteTestTask';
import { WrongDevice } from './components/WrongDevice';
import { useCreateResponse } from './hooks/useCreateResponse';
import { useIsCorrectDevice } from './hooks/useIsCorrectDevice';
import { useTaskTimings } from './hooks/useTaskTimings';
import { useUnmoderatedContext } from './hooks/useUnmoderatedContext';
import * as Types from './types';
import { parseStorageKey, UnmoderatedStorageKeys } from './utils';

export interface Props extends HTMLAttributes<HTMLFormElement> {
  participation?: Participation;
  surveyId: number;
  onSubmitAwnsers?: (answers: Types.Answer[]) => void;
}

// this ID references the "full screen" element that will be used to display cartain tasks
const FULL_SCREEN_ELEMENT_ID = 'fullScreenTask';

export const Unmoderated = forwardRef<HTMLFormElement, Props>(
  ({ participation, surveyId, onSubmitAwnsers, ...rest }, ref) => {
    const [activeId, setActiveId] = useState<Models.Block['id']>();
    const [answers, setAnswers] = useState<Types.Answer[]>([]);

    const { control, formState, handleSubmit, reset } = useForm<Types.FormData>({
      defaultValues: { value: '' },
      mode: 'onChange'
    });

    const { blocks, deviceType, topBarUnmoderatedLayout } = useUnmoderatedContext();

    const activeBlock = useMemo(() => blocks?.find(({ id }) => id === activeId), [blocks, activeId]);

    const [storedBlock, setStoredBlock] = useLocalStorage<Models.Block>(
      parseStorageKey({ key: UnmoderatedStorageKeys.ACTIVE_BLOCK, participation })
    );

    const [storedAnswers, setStoredAnswers] = useLocalStorage<Types.Answer[]>(
      parseStorageKey({ key: UnmoderatedStorageKeys.ANSWERS, participation })
    );

    const {
      enableRecording,
      endRecording,
      isRecording,
      isEnabled: isRecordingEnabled,
      isCanceled: isRecordingCanceled
    } = useParticipantRecording();

    const [createResponse, { isLoading: isLoadingCreateResponse }] = useCreateResponse();
    const { hasError: hasMismatchedDeviceError } = useIsCorrectDevice(deviceType);
    const { startAt, start, stop } = useTaskTimings();

    const onSubmit = (formData: Types.FormData) => {
      if (!blocks || blocks.length === 0 || !activeBlock) {
        return;
      }

      if (activeBlock.kind === Enums.Kind.thankYou) {
        location.reload();
        return;
      }

      const position = activeBlock.position + 1;
      const nextBlock = blocks.find((block) => block.position === position);
      const { duration, endAt } = stop();

      if (nextBlock) {
        let newAnswers = [...answers];

        if (participation && storedAnswers) {
          // get anwsers from storage if needed
          newAnswers = merge<Types.Answer>(newAnswers, storedAnswers, (a, b) => a.block.id === b.block.id);
        }

        const timings: Partial<Types.Timings> = {
          start_at: startAt,
          end_at: endAt,
          duration: duration ?? 0
        };

        newAnswers.push({
          block: activeBlock,
          value: formData.value,
          prototype_test_task: formData.prototype_test_task,
          card_sort_task: formData.card_sort_task,
          tree_test_task: formData.tree_test_task,
          timings
        });

        setAnswers(newAnswers);
        setActiveId(nextBlock.id);

        if (participation) {
          // not having a participation means we are in preview mode
          // and we don't want to store the block and answers
          setStoredBlock(nextBlock);
          setStoredAnswers(newAnswers);
        }

        if (nextBlock.kind === Enums.Kind.thankYou) {
          onSubmitAwnsers?.(newAnswers);

          if (isRecording) {
            endRecording(false);
          }

          if (participation) {
            createResponse(newAnswers, participation);
          }

          setStoredBlock(null);
          setStoredAnswers(null);
        }

        start();
      }

      reset({ value: '' });
    };

    const renderer = () => {
      if (!activeBlock) return <></>;

      switch (activeBlock.kind) {
        case Enums.Kind.prototypeTest:
          return (
            <PrototypeTestTask
              key={activeBlock.id}
              block={activeBlock}
              control={control}
              iframePortalId={FULL_SCREEN_ELEMENT_ID}
              onSubmit={handleSubmit(onSubmit)}
            />
          );

        case Enums.Kind.permissions:
          return <Permissions block={activeBlock} isPreview={!participation} onSubmit={handleSubmit(onSubmit)} />;

        case Enums.Kind.websiteTest:
          return <WebsiteTestTask key={activeBlock.id} block={activeBlock} iframePortalId={FULL_SCREEN_ELEMENT_ID} />;

        case Enums.Kind.cardSort:
          return (
            <CardSortTask
              key={activeBlock.id}
              block={activeBlock}
              control={control}
              fullScreenPortalId={FULL_SCREEN_ELEMENT_ID}
            />
          );

        case Enums.Kind.treeTest:
          return (
            <TreeTestTask
              key={activeBlock.id}
              block={activeBlock}
              control={control}
              fullScreenPortalId={FULL_SCREEN_ELEMENT_ID}
            />
          );

        case Enums.Kind.thankYou:
          return <Question block={activeBlock} control={control} isValid={!isRecording && !isLoadingCreateResponse} />;

        default:
          return <Question key={activeBlock.id} block={activeBlock} control={control} isValid={formState.isValid} />;
      }
    };

    useEffect(() => {
      if (activeBlock && activeBlock.kind === Enums.Kind.welcome) {
        start();
      }
    }, [activeBlock]);

    useEffect(() => {
      if (blocks && blocks.length > 0 && !activeId) {
        if (storedBlock) {
          setActiveId(storedBlock.id);
        } else {
          setActiveId(blocks[0].id);
        }
      }
    }, [blocks, activeId, storedBlock]);

    useEffect(() => {
      if (!isRecordingEnabled && activeBlock?.kind === Enums.Kind.permissions) {
        enableRecording();
      }
    }, [isRecordingEnabled, activeBlock]);

    useBeforeunload((event) => {
      if (isRecording && !isRecordingCanceled) {
        event.preventDefault();
      }
    });

    if (!blocks || !blocks.length || !activeBlock) {
      return null;
    }

    if (hasMismatchedDeviceError) {
      return <WrongDevice surveyDevice={deviceType} />;
    }

    if (deviceType === 'desktop' && topBarUnmoderatedLayout) {
      return (
        <form
          className='flex h-full w-full flex-col items-center border-gray-50'
          ref={ref}
          onSubmit={handleSubmit(onSubmit)}
          {...rest}
        >
          {activeBlock && renderer()}
        </form>
      );
    }

    return (
      <DraggableCardProvider>
        <form className='tablet:mt-4 tablet:py-32 flex-1' ref={ref} onSubmit={handleSubmit(onSubmit)} {...rest}>
          <div className='fixed inset-0 overflow-hidden p-6' id='unmoderatedCanvas'>
            <ImageRenderer block={activeBlock} />

            <div id={FULL_SCREEN_ELEMENT_ID} className='fixed inset-0' />

            {activeBlock && renderer()}
          </div>
        </form>
      </DraggableCardProvider>
    );
  }
);
