import * as React from 'react';

import { UseFormMethods } from 'react-hook-form';

import { Button, SlideOut } from '@components/common';
import { MessageEditor } from '@components/StudyMessages';
import { CtaProps } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip/helpers';
import { EditorProps } from '@components/StudyMessages/hooks/useMessageEditor';
import { EditorRef } from '@components/StudyMessages/MessageEditor';

const TITLES: Record<StudyMessageEvent, string> = {
  ad_hoc: 'Edit ad hoc message',
  invite: 'Edit participation invite',
  screener: 'Edit screener invite',
  invited_reminder: 'Edit invitation reminder',
  booked: 'Edit interview scheduled',
  booked_reminder: 'Edit interview reminder',
  started_reminder: 'Edit started reminder',
  reschedule_request: 'Edit reschedule request',
  cancel_task: 'Edit cancellation notice',
  cancel_interview: 'Edit cancellation notice',
  welcome: 'Edit welcome email',
  thanks: 'Edit thanks email'
};

interface Props extends EditorProps {
  handleSubmit: UseFormMethods['handleSubmit'];
  updateOnlyTemplate?: boolean;
  ctaProps?: CtaProps;
}

export const EditorSlideOut = React.forwardRef<EditorRef, Props>((props, ref) => {
  const {
    message,
    edit,
    event,
    save,
    setEdit,
    handleSubmit,
    isDirty,
    setIsDirty,
    setCancelModal,
    setUpdateModal,
    senderChanged,
    setSenderChanged,
    updateOnlyTemplate,
    ctaProps
  } = props;

  const onSave = () => {
    if (senderChanged) {
      setUpdateModal(true);
    } else if (isDirty) {
      save();
    } else {
      setEdit(false);
    }
  };

  const onCancel = () => {
    isDirty ? setCancelModal(true) : setEdit(false);
    if (senderChanged) {
      setSenderChanged(false);
    }
    setIsDirty(false);
  };

  if (!edit || !message) return null;

  return (
    <SlideOut
      title={TITLES[event]}
      subtitle='Changes aren’t live until you save them.'
      onClose={onCancel}
      size='xl'
      renderFooter={() => (
        <div className='flex flex-row flex-wrap'>
          <Button className='mb-1 mr-6 whitespace-nowrap' primary disabled={!isDirty} onClick={handleSubmit(onSave)}>
            Save changes
          </Button>
          <Button className='mb-1 mr-6 whitespace-nowrap' link onClick={onCancel}>
            Cancel changes
          </Button>
        </div>
      )}
    >
      <div className='border-t border-gray-200 p-6'>
        <MessageEditor
          updateOnlyTemplate={updateOnlyTemplate}
          ref={ref}
          type='sender'
          editSlideOut
          {...props}
          message={message}
          setIsDirty={setIsDirty}
          ctaProps={ctaProps}
        />
      </div>
    </SlideOut>
  );
});
