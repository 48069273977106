import React, { useState } from 'react';

import { VictoryLabel, VictoryPie } from 'victory';

import { InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { Dropdown, Text } from 'components/common';
import { ShoppingCartSVG } from 'components/svgs/ShoppingCartSVG';
import { track } from 'components/tracking';
import { usePlan } from 'hooks/usePlan';

interface ChartProps {
  size: number;
  radius: number;
  innerRadius: number;
  className?: string;
  data: any;
  renderLabels?: () => React.ReactNode;
  width?: string;
}

const REMAINED_COLOR = '#e5e7eb'; //gray-200
const USED_COLOR = '#16bdca'; //teal-400
const LABEL_COLOR = '#6b7280'; //gray-500

const Chart: React.FC<React.PropsWithChildren<ChartProps>> = ({
  size,
  radius,
  innerRadius,
  renderLabels,
  className,
  data,
  width
}) => (
  <svg className={className} viewBox={`0 0 ${size} ${size}`} style={{ width, height: 'auto' }}>
    <VictoryPie
      standalone={false}
      width={size}
      height={size}
      radius={radius}
      innerRadius={innerRadius}
      style={{
        labels: { display: 'none', color: LABEL_COLOR },
        data: { fill: ({ datum }) => datum.fill }
      }}
      data={data}
    />
    {renderLabels?.()}
  </svg>
);

export const CandidatesLimitWidget: React.FC<React.PropsWithChildren<unknown>> = () => {
  const plan = usePlan();
  const [open, setOpen] = useState(false);
  const toggleMenu = () => setOpen(!open);
  const closeMenu = () => setOpen(false);

  const limit = plan.limits.contacted_candidates;
  const usedLimit = limit - plan.getQuota('candidates');

  const restLimit = limit - usedLimit;

  const limitPercentage = Math.round((usedLimit / limit) * 100);

  const data = [
    { y: usedLimit, x: 'Used', fill: USED_COLOR },
    { y: restLimit, x: 'Limit', fill: REMAINED_COLOR }
  ];

  if (!limit) {
    return null;
  }

  return (
    <Dropdown
      medium
      hideCaret
      dropdownWidth='auto'
      renderIcon={() => <Chart radius={8} innerRadius={5.5} width='16' data={data} size={16} className='mr-2' />}
      className='xx-candidates-limit-menu mr-2 bg-white'
      text={`${limitPercentage}% of limit`}
      isOpen={open}
      onClick={toggleMenu}
      onClose={closeMenu}
    >
      <div className='w-64 space-y-2 px-4 py-2'>
        <Text bold h='400'>
          Tracked Candidate Limit
        </Text>
        <Text color='gray-500' h='400'>
          Any candidate who has been contacted for research.
          <Tooltip
            content='Being contacted for research includes screener invites, participation invites, panel opt-in invites, and
            incentives.'
          >
            <InfoCircleIcon className='ml-1.5 h-4 w-4 flex-shrink-0 text-gray-700' />
          </Tooltip>
        </Text>
        <Chart
          width='full'
          radius={80}
          innerRadius={65}
          data={data}
          size={200}
          renderLabels={() => (
            <>
              <VictoryLabel
                textAnchor='middle'
                style={{ fontSize: 20, fontWeight: 'bold' }}
                x={100}
                y={90}
                text={usedLimit}
              />
              <VictoryLabel
                textAnchor='middle'
                style={{ fill: LABEL_COLOR }}
                x={100}
                y={110}
                text={`used of ${limit}`}
              />
            </>
          )}
        />

        <a
          data-testid='upgrade-plan-cta'
          href='/plans/annual'
          onClick={() => track('upgrade_plan_cta', { page: 'candidate_limit_widget' })}
          className='flex w-full cursor-pointer items-center justify-center font-bold text-gray-700'
        >
          <ShoppingCartSVG className='mr-2' />
          <Text h='400' medium className='underline'>
            Upgrade to increase limit
          </Text>
        </a>
      </div>
    </Dropdown>
  );
};
