import * as React from 'react';

import cn from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import Tippy from '@tippyjs/react';

import { AskAiSVG } from '@components/svgs';
import { BaseTagPill } from '@components/tags';

import { TagDropdown } from './TagDropdown';

type Props = {
  id: number;
  tag: Tag;
  onChange: React.FormEventHandler;
  checked: boolean;
  readOnly?: boolean;
  isGhosting: boolean;
  selectedCount: number;
  studyId: number | undefined;
  onDeleteTag: (e: React.MouseEvent<any>) => void;
};

export const Tag: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  tag,
  studyId,
  onChange,
  checked,
  isGhosting,
  selectedCount,
  readOnly = false,
  onDeleteTag
}) => {
  const backgroundColor = () => {
    if (isGhosting) {
      return 'bg-blue-50';
    }

    return 'bg-white';
  };

  return (
    <Tippy maxWidth='full' arrow={false} content={tag.name}>
      <div>
        <Draggable draggableId={String(id)} index={0} isDragDisabled={readOnly}>
          {(provided, snapshot) => {
            const shouldShowCount = snapshot.isDragging && selectedCount > 0;

            return (
              <div
                role='button'
                className={cn(
                  'relative flex flex-row items-center justify-between rounded border border-gray-200 hover:bg-gray-50',
                  backgroundColor()
                )}
                tabIndex={0}
                onClick={onChange}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <div className='flex flex-shrink flex-nowrap items-center overflow-hidden px-2 py-2.5'>
                  {!readOnly && (
                    <input
                      type='checkbox'
                      onChange={onChange}
                      checked={checked}
                      className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500'
                    />
                  )}
                  <BaseTagPill
                    tag={tag.name}
                    count={tag.taggings_count}
                    color={tag.color}
                    className='ml-2 truncate'
                    size='sm'
                  />
                  {tag.ai && <AskAiSVG className='ml-1 h-4 w-4 opacity-50' />}
                </div>
                {!readOnly && <TagDropdown tag={tag} onDeleteTag={onDeleteTag} studyId={studyId} />}
                {shouldShowCount && (
                  <span className='absolute -right-3 -top-3 rounded border bg-indigo-600 px-1.5 text-sm text-white'>
                    {selectedCount}
                  </span>
                )}
              </div>
            );
          }}
        </Draggable>
      </div>
    </Tippy>
  );
};
