import * as React from 'react';
import { useMemo, useState } from 'react';

import pluralize from 'pluralize';
import { Link, useNavigate } from 'react-router-dom';

import { Button, Option, Text } from '@components/common';
import { Skeleton } from '@components/GuidesApp/components/Skeleton';
import { buildGuidesFilterDefs } from '@components/GuidesApp/filters/buildGuidesFilterDefs';
import { sortGuides } from '@components/GuidesApp/utils';
import { DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { PageHeader } from '@components/shared/PageHeader';
import { SortDropdown } from '@components/shared/SortDropdown';
import { AnyAllToggle, SegmentActions, TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { usePermission } from '@hooks/usePermission';
import { useSearch } from '@hooks/useSearch';
import { useToaster } from '@stores/toaster';
import { Grid } from 'components/common/Grid';
import { PageTitle } from 'components/common/helmets';
import { useCollectionView } from 'components/stores/view';

import { GuideCard } from './components/GuideCard';
import { OptionsDropdown } from './components/OptionsDropdown';
import { useGuides } from './hooks/useGuides';
import * as toasts from './toasts';

const options: Option[] = [
  { label: 'Last updated', value: 'updated_at' },
  { label: 'A—Z', value: 'title' },
  { label: 'Date created', value: 'created_at' }
];

export const GuidesIndex: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [creating, setCreating] = useState(false);
  const canCreate = usePermission('canCreate')();
  const showToast = useToaster();
  const navigate = useNavigate();

  const { createGuide, guides, loading, fetch: fetchGuides } = useGuides();

  const viewHook = useCollectionView();
  const { view, setView } = viewHook;
  const { sort } = view;

  function setSort(sort) {
    setView({ sort });
  }

  const [value, setValue] = useState<string>('');

  const { results: searchedGuides } = useSearch<Guide>(guides || [], ['title'], value);

  const definitions = useMemo(() => buildGuidesFilterDefs(), []);

  const filtersHook = useTableFilters<Guide>({
    trackKey: 'guides',
    definitions,
    syncWithURL: true
  });

  const guidesToDisplay = useMemo(() => {
    return filtersHook.rawFilter(sortGuides(searchedGuides, sort.value) as Guide[]);
  }, [searchedGuides, filtersHook.filters, filtersHook.op, sort]);

  const handleClickCreate = async () => {
    setCreating(true);

    try {
      const guide = await createGuide().unwrap();
      navigate(`/guides/${guide.token}`);
    } catch {
      showToast(toasts.failedCreate());
    }

    setCreating(false);
  };

  const handleRemoveGuideSuccess = () => {
    fetchGuides();
  };

  const onSortChange = ({ value }) => {
    setSort({ value, desc: false });
  };

  const renderFilters = () => (
    <div className='px-page flex items-center border-t border-gray-200'>
      <div className='flex-1'>
        <TableFilters<Guide> hook={filtersHook} defaultShowInput={!filtersHook.filters.length} />
      </div>
      <div className='flex space-x-3 border-l border-gray-200 py-3 pl-3'>
        <AnyAllToggle value={filtersHook.op} onChange={filtersHook.setOp} />
        <SegmentActions onClear={filtersHook.clearFilters} anyFilters={filtersHook.filters.length > 0} />
      </div>
    </div>
  );

  if (loading) {
    return <Skeleton />;
  }

  return (
    <DashboardLayout>
      <PageTitle>Guides</PageTitle>
      {guides && (
        <>
          <PageHeader
            h1='Guides'
            filtersApplied={!!filtersHook.filters.length}
            renderSortBy={() => (
              <SortDropdown wrapperClass='w-full' options={options} value={sort.value} onChange={onSortChange} />
            )}
            renderCta={() => (
              <>
                {canCreate && (
                  <Button className='whitespace-nowrap' medium disabled={creating} primary onClick={handleClickCreate}>
                    Create new guide
                  </Button>
                )}
              </>
            )}
            searchProps={{
              onSearch: setValue,
              value,
              placeholder: 'Search by title…'
            }}
            renderFilters={renderFilters}
          />

          <DashboardLayoutBody>
            <div className='mb-4 flex items-center'>
              <div className='flex-grow'>
                <Text color='gray-500' h='400'>
                  {pluralize('guides', guidesToDisplay.length, true)}
                </Text>
              </div>
            </div>
            <Grid gap={4} mobile={1} tablet={2} desktop={3} monitor={4}>
              {guidesToDisplay.map((g) => (
                <div key={g.id} className='relative'>
                  <Link className='text-gray-700' key={g.token} to={`/guides/${g.token}`}>
                    <GuideCard guide={g} />
                  </Link>
                  <div className='absolute right-4 top-4'>
                    <OptionsDropdown guide={g} onRemoveGuide={handleRemoveGuideSuccess} />
                  </div>
                </div>
              ))}
            </Grid>
          </DashboardLayoutBody>
        </>
      )}
    </DashboardLayout>
  );
};
