import React, { forwardRef } from 'react';

import cn from 'classnames';
import { Rnd } from 'react-rnd';

import { Portal } from '@components/Portal';

import { useVideoPlayer, useVideoPlayerHls } from '../hooks';
import { MiniPlayerProps, UseVideoPlayer } from '../types';

import { ControlsLite } from './ControlsLite';

export const Miniplayer = forwardRef<UseVideoPlayer, MiniPlayerProps>(
  ({ src, autoPlay, onClose, className, ...rest }, ref) => {
    const {
      getVideoProps,
      player,
      state: { isPlaying, duration, currentTime, buffer, volume },
      play,
      pause,
      setVolume,
      rewind,
      fastForward,
      setCurrentTime
    } = useVideoPlayer({
      autoPlay,
      videoRef: ref
    });

    const getRndDefaults = () => ({
      width: 375,
      height: 'auto',
      x: window.innerWidth - 375 - 24,
      y: window.innerHeight - 190 - 42
    });

    const handleOnProgressBarChange = (value: number) => {
      setCurrentTime((value * duration) / 100);
    };

    useVideoPlayerHls({ player: player, src });

    return (
      <Portal>
        <div className='pointer-events-none fixed inset-0 z-30'>
          <Rnd default={getRndDefaults()} className='pointer-events-auto relative' bounds='parent' lockAspectRatio>
            <div style={{ paddingBottom: '56.25%' }}>
              <div className='absolute inset-0 h-full w-full'>
                <div className={cn('absolute h-full w-full overflow-hidden rounded-lg')}>
                  <video {...getVideoProps()} className={cn('h-full w-full', className)} {...rest} />
                  <ControlsLite
                    isPlaying={isPlaying}
                    currentTime={currentTime}
                    volume={volume}
                    play={play}
                    pause={pause}
                    setVolume={setVolume}
                    rewind={rewind}
                    fastForward={fastForward}
                    onClose={onClose}
                    buffer={buffer}
                    duration={duration}
                    handleOnProgressBarChange={handleOnProgressBarChange}
                  />
                </div>
              </div>
            </div>
          </Rnd>
        </div>
      </Portal>
    );
  }
);
