import React, { FC, useEffect, useState } from 'react';

import cn from 'classnames';

import { Select, SelectOption, Text } from '@components/common';
import { Tooltip } from '@components/Tooltip';

interface Props {
  questions: ScreenerField[];
  question: ScreenerField;
  skipLogic: SkipLogic[];
  onChange: (questionId: number | null) => void;
}

const isRecordingField = (question: ScreenerField, questions: ScreenerField[]) => {
  const startLoom = questions.find((q) => q.field_type === 'start_loom');
  const stopLoom = questions.find((q) => q.field_type === 'stop_loom');

  if (!startLoom || !stopLoom) {
    return false;
  }

  return question.position > startLoom.position && question.position <= stopLoom.position;
};

export const DefaultQuestionSelect: FC<Props> = ({ questions, question: currentQuestion, skipLogic, onChange }) => {
  const [value, setValue] = useState<number | null>(currentQuestion.next_screener_field_id);

  const skipLogicTo = skipLogic.reduce<number[]>((acc, skip_logic) => {
    if (skip_logic) {
      return [...acc, skip_logic.to];
    }
    return acc;
  }, []);

  const items = questions.reduce<SelectOption<string>[]>((acc, question, index) => {
    if (question.id !== currentQuestion.id && question.position > currentQuestion.position) {
      let disabled = false;
      let label = question.label;
      let tooltip = '';

      if (isRecordingField(currentQuestion, questions)) {
        disabled = !isRecordingField(question, questions);
        tooltip = 'Cannot skip over the stop recording step';
      } else {
        disabled = isRecordingField(question, questions);
        tooltip = 'Cannot skip over the start recording step';
      }

      if (question.field_type === 'start_loom') {
        label = 'Start recording';
      }

      if (question.field_type === 'stop_loom') {
        label = 'End recording';
      }

      return [
        ...acc,
        {
          label: `${question.position}. ${label}`,
          value: String(question.id),
          disabled: skipLogicTo?.includes(question.id) || disabled,
          data: { tooltip }
        }
      ];
    }
    return acc;
  }, []);

  const labelRenderer = ({ label, disabled, data }: SelectOption<string>) => {
    return (
      <div className={cn({ 'text-gray-500': disabled })}>
        <Tooltip isDisabled={!disabled} content={data.tooltip}>
          <div className='truncate'>
            <Text h='400'>{label}</Text>
          </div>
        </Tooltip>
      </div>
    );
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <Select<string>
      options={items}
      value={typeof value === 'number' ? String(value) : items[0]?.value}
      renderLabel={labelRenderer}
      onChange={(id) => setValue(+id)}
    />
  );
};
