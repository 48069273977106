// libs
import React, { forwardRef } from 'react';

// components
import { Text } from '@components/common';
import { useFeature } from '@hooks/useFeature';

import { DescAndStatus } from './components/descAndStatus';
import { EventDate } from './components/eventDate';
import { NylasEventGuests } from './components/eventGuests';

interface Props {
  event: NylasSlotInstance;
  timezone: Timezone;
  onChange?: (event: Partial<NylasEvent>) => void;
}

export const NylasEventPopup = forwardRef<HTMLDivElement, Props>(({ timezone, event, onChange }, ref) => {
  const hasVirtualCalendar = useFeature('virtual_calendar');

  return (
    <div
      data-testid={event.description}
      tabIndex={0}
      role='menu'
      ref={ref}
      className='min-w-60 mr-2 max-w-lg rounded-md bg-white shadow-lg'
    >
      {event.owner && !hasVirtualCalendar && (
        <div className='flex items-center border-b border-gray-200 px-6 py-3'>
          <div className='mr-2 h-4 w-4 rounded-sm bg-yellow-200' />
          <Text h='400'>{event.owner}</Text>
        </div>
      )}

      <div className='px-6 pb-6 pt-3'>
        <Text className='whitespace-pre-line pb-5 pt-2'>{event.title}</Text>

        <EventDate timezone={timezone} when={event.when} />

        {!!event.guests?.length && <NylasEventGuests guests={event.guests} />}

        <DescAndStatus
          description={event.description}
          busy={event.busy}
          onChangeBusy={onChange && ((busy) => onChange({ busy }))}
        />
      </div>
    </div>
  );
});
