import * as React from 'react';

import { ELocalStorageKeys } from 'constants/localStorageKeys';
import { HeaderProps } from 'react-table';

import { PopperDropdown, Text } from '@components/common';
import { ColumnsActionsDropdown } from '@components/shared/GridTable/components/shared';
import { renderTooltip } from '@components/shared/GridTable/components/utils';
import { ChevronDownSVG } from '@components/svgs';

interface OtherHeaderProps<T extends { [K in keyof T]: T[K] }> extends HeaderProps<T> {
  setSortValue?: (value: string, desc: boolean) => void;
  storageKey: ELocalStorageKeys;
  description?: string;
  disableSort?: boolean;
}

type OtherHeader = <T>(props: OtherHeaderProps<T>) => React.ReactElement<OtherHeaderProps<T>>;

export const OtherHeader: OtherHeader = ({
  storageKey,
  column,
  visibleColumns,
  setColumnOrder,
  setSortValue,
  description,
  addFilter,
  disableSort
}) => {
  const handleChangeOrder = (id, indexChange) => {
    const visibleColumnsIds = visibleColumns.map((column) => column.id);

    const fromIndex = visibleColumnsIds.indexOf(id);
    // prevent from changing "name" column position (it always remains 1st)
    if (fromIndex === 1 && indexChange === -1) {
      return;
    }

    visibleColumnsIds.splice(fromIndex, 1);
    visibleColumnsIds.splice(fromIndex + indexChange, 0, id);

    setColumnOrder(visibleColumnsIds);

    localStorage.setItem(storageKey, JSON.stringify(visibleColumnsIds));
  };

  const hideColumn = () => {
    column.toggleColVisibility(column.id);
  };

  const changeSort = (descending: boolean): void => {
    setSortValue?.(column.id, descending);
    column.toggleSortBy?.(descending);
  };

  const canHide = !['name', 'interview', 'recording'].includes(column.id);

  return (
    <div className='h400 flex h-full w-full cursor-pointer items-center overflow-hidden font-bold hover:bg-gray-50 hover:text-indigo-600'>
      <PopperDropdown
        hideCaret
        popperProps={{ placement: 'bottom-end', renderOnBodyRoot: false }}
        className='h400 group flex h-full flex-1 flex-shrink-0 cursor-pointer flex-col items-stretch truncate px-2 font-bold'
        text={
          <div className='flex w-full justify-between'>
            <span className='xx-column-header truncate font-bold'>{column.headerLabel}</span>
            <ChevronDownSVG className='ml-1 hidden flex-shrink-0 text-gray-500 group-hover:block' />
          </div>
        }
        tooltip={renderTooltip(column.headerLabel, description)}
      >
        <ColumnsActionsDropdown
          addFilter={addFilter}
          changeSort={setSortValue ? changeSort : undefined}
          columnAccessor={column.id}
          sortIconType={column.sortIconType}
          hideColumn={canHide ? hideColumn : undefined}
          handleChangeOrder={handleChangeOrder}
          disableSort={disableSort}
        />
      </PopperDropdown>
    </div>
  );
};
