import React from 'react';

import { format } from 'date-fns';

import { Text } from '@components/common';
import { CalendarSVG, ClockSVG } from '@components/svgs';

interface Props {
  startTime?: Date;
}

export const EventDate: React.FC<React.PropsWithChildren<Props>> = ({ startTime }) => {
  if (!startTime) return null;

  return (
    <div className='flex flex-row items-center space-x-4 py-1.5'>
      <Text h='400' className='flex items-start whitespace-nowrap'>
        <CalendarSVG className='mr-2' />
        {format(startTime, 'E, MMM d, R')}
      </Text>
      <Text h='400' className='flex items-center whitespace-nowrap'>
        <ClockSVG className='mr-2' />
        {format(startTime, 'h:mm a O')}
      </Text>
    </div>
  );
};
