import * as React from 'react';

import { Button, Text } from '@components/common';
import { CheckSVG, XSVG } from '@components/svgs';
import { GQSurveyBuilder } from 'components/GQSurveyBuilder';
import { DocumentPreview } from 'components/StudyMessages/components/DocumentPreview';

type Props = {
  template: StudyTemplate;
  onSubmit: () => void;
};
export const TemplatePreview: React.FC<React.PropsWithChildren<Props>> = ({ template, onSubmit }) => (
  <div>
    <div className='rounded-md bg-gray-50 p-12 text-center'>
      {template.illustration_url && <img alt='' className='mx-auto mb-6' src={template.illustration_url} />}
      <Text h='800' color='indigo-1000' bold className='font-marketing' mb='4'>
        {template.title || 'Untitled template'}
      </Text>
      <Text h='600' color='gray-500' mb='6'>
        {template.tagline}
      </Text>
      <Button onClick={onSubmit} primary>
        Use this template
      </Button>
    </div>

    <div className='mx-auto max-w-3xl p-6'>
      {template.document_id && (
        <>
          <Text as='h4' bold h='400' mb='4'>
            Interview Guide
          </Text>
          <div className='mb-6 max-h-96 overflow-y-scroll border border-gray-200 bg-gray-50 p-4'>
            <DocumentPreview id={template.document_id} />
          </div>
        </>
      )}
      {template.screener && (
        <>
          <Text as='h4' bold h='400' mb='4'>
            Survey questions preview
          </Text>
          <div className='mb-6 max-h-96 overflow-y-scroll border border-gray-200 bg-gray-50 p-4'>
            <GQSurveyBuilder
              page='template'
              screener={template.screener}
              editable={false}
              noImgUpload
              save={async (fields) => null}
            />
          </div>
        </>
      )}
      {template.description && (
        <>
          <Text as='h3' h='600' bold mb='4'>
            Description of the method
          </Text>
          <div className='mb-12'>
            {(template.description || '').split('\n').map((p, i) => (
              <Text mb='4' key={i}>
                {p}
              </Text>
            ))}
          </div>
        </>
      )}

      {template.benefits && template.benefits.length > 0 && (
        <section className='mb-6'>
          <Text as='h3' h='600' bold mb='4'>
            What Great Questions can you answer?
          </Text>
          <ul className='space-y-2'>
            {template.benefits.map((advantage, i) => (
              <li key={i} className='flex items-center rounded-md border border-gray-200 px-3 py-4'>
                <div className='mr-3 flex h-6 w-6 items-center justify-center rounded-sm bg-gray-700'>
                  <Text h='600' color='white' bold>
                    ?
                  </Text>
                </div>
                <Text h='400'>{advantage}</Text>
              </li>
            ))}
          </ul>
        </section>
      )}
      {template.advantages && template.advantages.length > 0 && (
        <section className='mb-6'>
          <Text as='h3' h='600' bold mb='4'>
            Advantages of the method
          </Text>
          <ul className='space-y-2'>
            {template.advantages.map((advantage, i) => (
              <li key={i} className='flex items-center rounded-md border border-gray-200 px-3 py-4'>
                <div className='mr-3 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-green-600'>
                  <CheckSVG className='h-3 w-3 text-white' />
                </div>
                <Text h='400'>{advantage}</Text>
              </li>
            ))}
          </ul>
        </section>
      )}
      {template.disadvantages && template.disadvantages.length > 0 && (
        <section className='mb-12'>
          <Text as='h3' h='600' bold mb='4'>
            Disadvantages of the method
          </Text>
          <ul className='space-y-2'>
            {template.disadvantages.map((advantage, i) => (
              <li key={i} className='flex items-center rounded-md border border-gray-200 px-3 py-4'>
                <div className='mr-3 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-red-600'>
                  <XSVG className='h-3 w-3 text-white' />
                </div>
                <Text h='400'>{advantage}</Text>
              </li>
            ))}
          </ul>
        </section>
      )}
      <div className='text-center'>
        <Button primary onClick={onSubmit}>
          Use this template
        </Button>
      </div>
    </div>
  </div>
);
