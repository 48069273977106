import * as React from 'react';
import { FC, useCallback, useMemo, useState } from 'react';

import { StateRouterContext } from './context';
import { Switch } from './Switch';

type Props = {
  context: React.Context<StateRouterContext>;
  initialPath?: string;
};

export const StateRouter: FC<React.PropsWithChildren<Props>> = ({ children, context, initialPath = '' }) => {
  const [pathStack, setPathStack] = useState<string[]>([initialPath]);

  const pushPath = useCallback((newPath: string) => {
    setPathStack((prevStack) => [...prevStack, newPath]);
  }, []);

  const popPath = useCallback(() => {
    setPathStack((prevStack) => prevStack.slice(0, -1));
  }, []);

  const currentPath = useMemo(() => {
    return pathStack.length > 0 ? pathStack[pathStack.length - 1] : '';
  }, [pathStack]);

  const hasPrevious = useMemo(() => {
    return pathStack.length > 1;
  }, [pathStack]);

  return (
    <context.Provider value={{ pushPath, popPath, currentPath, hasPrevious }}>
      <Switch context={context}>{children}</Switch>
    </context.Provider>
  );
};
