import * as React from 'react';
import { useState } from 'react';

import { Avatar, AvatarFromId, Dropdown, DropdownLink, Pill, Text } from '@components/common';
import { KeysSVG, PersonMinusSVG } from '@components/svgs';
import { useAccount } from '@hooks/useAccount';

const HOST_REMOVE_TOOLTIP = 'Please assign another host first.';
const EXTERNAL_GUEST_REMOVE_TOOLTIP =
  'This guest can only be removed via the same external provider they were added on.';
const EXTERNAL_GUEST_HOST_TOOLTIP = 'Only on-platform users can be made host.';

type Props = {
  isHost?: boolean;
  isAdditional?: boolean;
  guest: CalendarEventGuest;
  onClickRemove: () => void;
  onClickMakeHost?: (userId: number, email: string) => void;
};

export const GuestDropdownButton: React.FC<Props> = ({
  isHost,
  isAdditional,
  guest,
  onClickRemove,
  onClickMakeHost
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { account } = useAccount();
  const user = account.team.find((user) => user.email.toLowerCase() === guest.email.toLowerCase());

  return (
    <Dropdown
      className='w-full'
      menuClassName='w-full'
      buttonClassName='border-none w-full text-left'
      onClick={() => setIsOpen(!isOpen)}
      onClose={() => setIsOpen(false)}
      text={
        <div className='flex w-full items-center space-x-3'>
          {user ? <Avatar size='lg' user={user} /> : <AvatarFromId size='lg' name={guest.name || guest.email} />}
          <div className='flex-1'>
            {guest.name && (
              <Text h='400' bold>
                {guest.name}
              </Text>
            )}
            {guest.email && <Text h='400'>{guest.email}</Text>}
          </div>
          {isHost && <Pill color='indigo'>Host</Pill>}
        </div>
      }
      isOpen={isOpen}
    >
      {!isHost && onClickMakeHost && (
        <DropdownLink
          className='flex items-center space-x-3'
          onClick={() => {
            onClickMakeHost(user!.id, user!.email);
            setIsOpen(false);
          }}
          disabled={!user?.id}
          disabledTooltip={EXTERNAL_GUEST_HOST_TOOLTIP}
        >
          <KeysSVG />
          <Text h='400'>Make host</Text>
        </DropdownLink>
      )}
      <DropdownLink
        disabled={isHost || isAdditional}
        className='flex items-center space-x-3'
        onClick={onClickRemove}
        disabledTooltip={isHost ? HOST_REMOVE_TOOLTIP : EXTERNAL_GUEST_REMOVE_TOOLTIP}
      >
        <PersonMinusSVG />
        <Text h='400'>Remove attendee</Text>
      </DropdownLink>
    </Dropdown>
  );
};
