import React, { SVGProps } from 'react';

export const PersonMinusSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13.333 17.5V15.8333C13.333 14.9493 12.9818 14.1014 12.3567 13.4763C11.7316 12.8512 10.8837 12.5 9.99967 12.5H4.16634C3.28229 12.5 2.43444 12.8512 1.80932 13.4763C1.1842 14.1014 0.833008 14.9493 0.833008 15.8333V17.5M19.1663 9.16667H14.1663M10.4163 5.83333C10.4163 7.67428 8.92396 9.16667 7.08301 9.16667C5.24206 9.16667 3.74967 7.67428 3.74967 5.83333C3.74967 3.99238 5.24206 2.5 7.08301 2.5C8.92396 2.5 10.4163 3.99238 10.4163 5.83333Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
