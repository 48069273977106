import { uniq } from '@components/utils';

export const buildInitialData = (): GroupedEntities => ({
  ungrouped: [],
  grouped: []
});

export const moveEntitiesToGroup = (data: GroupedEntities, entityIds: number[], groupId: number | null) => {
  const notInSet = (id: number) => !entityIds.includes(id);
  const addEntityIds = (set: number[]) => uniq([...set, ...entityIds]);

  if (groupId === null) {
    return {
      grouped: data.grouped.map((group) => ({
        group_id: group.group_id,
        entities: group.entities.filter(notInSet)
      })),
      ungrouped: addEntityIds(data.ungrouped)
    };
  }
  const grouped: GroupedEntities['grouped'] = data.grouped.map(({ group_id, entities }) => ({
    group_id: group_id,
    entities: group_id === groupId ? addEntityIds(entities) : entities.filter(notInSet)
  }));
  if (!data.grouped.map((g) => g.group_id).includes(groupId)) {
    grouped.push({ group_id: groupId, entities: entityIds });
  }
  return {
    grouped,
    ungrouped: data.ungrouped.filter(notInSet)
  };
};

const buildTable = (
  highlights: DocumentHighlight[],
  data: GroupedEntities,
  selectedIds: number[],
  newline = '\n'
): string[][] => {
  const numRows = Math.max(data.ungrouped.length, ...data.grouped.map((g) => g.entities.length));
  const numCols = data.grouped.length + 1;

  const highlightById = (id: number) => highlights?.find((h) => h.id === id);

  const isSelected = (id: number) => selectedIds.length === 0 || selectedIds.includes(id);

  const highlightToText = (h: DocumentHighlight | undefined) =>
    h && isSelected(h.id) ? `${h.clip?.title || 'Highlight'}${newline}${h.text}` : null;

  const rows: string[][] = [];

  for (let i = 0; i < numRows; i++) {
    rows.push([]);
    for (let j = 0; j < numCols; j++) {
      const id = j === 0 ? data.ungrouped[i] : data.grouped[j - 1]?.entities[i];
      const txt = id && highlightToText(highlightById(id));
      if (!id || !txt) {
        continue;
      }
      rows[i].push(txt);
    }
  }

  return rows;
};

export const buildHtml = (highlights: DocumentHighlight[], data: GroupedEntities, selectedIds: number[]) => {
  const rows = buildTable(highlights, data, selectedIds, '<br />');

  const trs = rows.map((row) => `<tr>${row.map((d) => `<td>${d}</td>`).join('')}</tr>`);

  return `<table><tbody>${trs.join('')}</tbody></table>`;
};

export const buildPlainText = (highlights: DocumentHighlight[], data: GroupedEntities, selectedIds: number[]) => {
  const rows = buildTable(highlights, data, selectedIds);

  return rows.map((row) => row.join('\t')).join('\n');
};
