import * as React from 'react';
import { useState } from 'react';

import { DragStart, DropResult } from 'react-beautiful-dnd';

import { HighlightArtifact, PreviewContentSlideout } from '@components/RepositoryApp/components/PreviewContentSlideout';
import { ColumnBoard } from '@components/shared/ColumnBoard';
import { track } from '@components/tracking';
import { usePermission } from '@hooks/usePermission';

import { useSynthesisContext } from '../hooks/useSynthesisContext';
import { useSynthesisData } from '../hooks/useSynthesisData';

import { HighlightGroupColumn } from './HighlightGroupColumn';

type Props = {
  study: Study;
  boardId?: number;
};

export const HighlightGroups: React.FC<React.PropsWithChildren<Props>> = ({ study, boardId }) => {
  const canSynthesize = usePermission<Study>('updateStudy')(study);
  const [previewSlideout, setPreviewSlideout] = useState<HighlightArtifact | null>(null);
  const { selectedIds, setSelectedIds, setDraggingId } = useSynthesisContext();

  const { isLoadingData, isLoadingGroups, createNewGroup, getHighlightsForGroup, groups, moveSelectionToGroup } =
    useSynthesisData(study.id, boardId);

  const onDragStart = (start: DragStart) => {
    const id = parseInt(start.draggableId);
    if (!selectedIds.includes(id)) {
      setSelectedIds([id]);
    }
    setDraggingId(id);
  };

  const onDragEnd = async ({ reason, destination }: DropResult) => {
    if (!canSynthesize) {
      return;
    }
    if (reason === 'DROP' && destination) {
      let groupTrackKey = 'group';
      const { droppableId } = destination;

      if (droppableId === 'new_group') {
        const newGroup = await createNewGroup();
        moveSelectionToGroup(newGroup.id);
        groupTrackKey = 'new group';
      } else if (droppableId === 'ungrouped') {
        moveSelectionToGroup(null);
        groupTrackKey = 'ungrouped';
      } else {
        moveSelectionToGroup(parseInt(droppableId));
      }

      setSelectedIds([]);
      setDraggingId(null);
      track('board_dragged_cards', {
        to_group: groupTrackKey,
        count: selectedIds.length
      });
    }

    if (!destination || reason === 'CANCEL') {
      setDraggingId(null);
    }
  };

  return (
    <>
      <ColumnBoard
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        showSkeleton={isLoadingGroups}
        placeholderColumnText='Drag & drop highlights to create a new group'
        showPlaceholder={!isLoadingData && canSynthesize}
        onCreateColumn={async () => {
          await createNewGroup();
          track('board_clicked_create_group');
        }}
      >
        <HighlightGroupColumn
          setPreviewSlideout={setPreviewSlideout}
          ungrouped
          readOnly={!canSynthesize}
          highlights={getHighlightsForGroup(null)}
        />

        {groups.map((group) => (
          <HighlightGroupColumn
            key={group.id}
            group={group}
            setPreviewSlideout={setPreviewSlideout}
            readOnly={!canSynthesize}
            highlights={getHighlightsForGroup(group.id)}
          />
        ))}
      </ColumnBoard>
      {previewSlideout && (
        <PreviewContentSlideout artifact={previewSlideout} closeSlideout={() => setPreviewSlideout(null)} />
      )}
    </>
  );
};
