import * as React from 'react';

import { Text } from '@components/common';

import { MeetingProviderIcon } from './MeetingProviderIcon';

export const MeetingProviderDropdownContent: React.FC<
  React.PropsWithChildren<{ option: MeetingProvider | undefined }>
> = ({ option }) => {
  if (option == undefined) {
    return <div className='flex-grow'>Add location</div>;
  }

  return (
    <div className='flex w-full flex-1 items-center text-left'>
      <MeetingProviderIcon option={option} />

      <Text h='400' className='flex-1'>
        {option.name}
      </Text>
    </div>
  );
};
