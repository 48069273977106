import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { useCombobox } from 'downshift';

import { Input, TeamIcon, Text } from '@components/common';
import { TeamSVG } from '@components/svgs';
import { compact } from '@components/utils';
import { usePermission } from '@hooks/usePermission';
import { usePopUp } from '@hooks/usePopUp';
import { useTeams } from '@hooks/useTeams';
import { useUser } from '@hooks/useUser';

const NO_TEAM = 'NO_TEAM';

/**
 * Transforms a string by:
 * 1. Converting all letters to lowercase
 * 2. Replacing spaces with hyphens
 * 3. Adding "-button" suffix
 *
 * @param value The input string to transform
 * @returns The transformed string
 */
const formatTestId = (value: string): string => {
  if (!value) return 'button';

  return value
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .concat('-button'); // Add button suffix
};

type TeamPickerItem = Team | typeof NO_TEAM;

type Props = {
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
  allowNoTeam?: boolean;
  label?: string;
  value?: number | null;
  onChange: (teamId: number | null) => void;
  placement?: 'right' | 'left';
};
export const TeamPicker: React.FC<React.PropsWithChildren<Props>> = ({
  disabled,
  className,
  label = 'Change team',
  allowNoTeam = false,
  value,
  placement = 'right',
  onChange
}) => {
  const { teams: allTeams, findTeam } = useTeams();
  const team = value ? findTeam(value) : null;
  const user = useUser();
  const userTeams = useMemo(
    () => allTeams?.filter((team) => user.teams?.map((t) => t.id).includes(team.id)),
    [allTeams, user.teams]
  );
  const canSeeAllTeams = usePermission('manageTeams')();

  const { PopUp, ref, open, togglePopUp, closePopUp } = usePopUp();

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (open) {
      inputRef?.current?.focus();
    }
  }, [open, inputRef]);

  const items: TeamPickerItem[] = useMemo(
    () =>
      compact([
        !inputValue && allowNoTeam && NO_TEAM,
        ...((canSeeAllTeams ? allTeams : userTeams)?.filter((team) =>
          team.name.toLowerCase().includes(inputValue.toLowerCase())
        ) || [])
      ]),
    [userTeams, allowNoTeam, inputValue]
  );

  const { highlightedIndex, getComboboxProps, getLabelProps, getItemProps, getInputProps, getMenuProps, reset } =
    useCombobox<TeamPickerItem>({
      inputValue,
      items,
      itemToString: (item) => (item === NO_TEAM ? 'No team' : item?.name || ''),
      onSelectedItemChange: ({ selectedItem }) => {
        if (selectedItem) {
          reset();
          closePopUp();
          onChange(selectedItem === NO_TEAM ? null : selectedItem?.id);
        }
      },
      onIsOpenChange: ({ isOpen }) => {
        if (!isOpen) {
          setInputValue('');
        }
      }
    });

  return (
    <div className='relative' ref={ref}>
      <button
        disabled={disabled}
        className={cn('max-w-64 group truncate rounded-md border border-gray-200 px-3 py-2.5', className, {
          'hover:bg-gray-50 hover:text-indigo-600': !disabled
        })}
        name={label}
        aria-label={label}
        data-testid={label}
        onClick={togglePopUp}
      >
        {team && (
          <div className='flex items-center space-x-2'>
            <TeamIcon team={team} />
            <Text h='400'>{team.name || 'Untitled team'}</Text>
          </div>
        )}
        {!team && (
          <div className='flex items-center'>
            <TeamSVG className='mr-2' />
            <Text className='text-gray-500 group-hover:text-indigo-600' h='400'>
              No team
            </Text>
          </div>
        )}
      </button>
      <PopUp
        zIndex={30}
        renderOffScreen
        className={cn(
          { 'right-0': placement === 'right', 'left-0': placement === 'left' },
          'mt-2 rounded-md border border-gray-200 bg-white shadow-sm'
        )}
        open={open}
      >
        <div {...getComboboxProps()}>
          <div className='px-4 pt-4'>
            <Text {...getLabelProps()} h='200' color='gray-500' uppercase bold mb='1'>
              {label}
            </Text>
          </div>

          <div className='px-4 py-3'>
            <Input
              {...getInputProps({ ref: inputRef })}
              className='h400 w-80'
              value={inputValue}
              onChange={setInputValue}
              placeholder='Search…'
            />
          </div>

          {items.length === 0 && (
            <div className='px-4 py-4 text-center'>
              <Text h='400' color='gray-500'>
                No results
              </Text>
            </div>
          )}

          <ul {...getMenuProps()} className='mb-2 max-h-80 w-full max-w-sm overflow-auto'>
            {items.map((team, index) => (
              <li
                {...getItemProps({ item: team, index })}
                key={team === NO_TEAM ? 'no-team' : team.id}
                aria-label={team === NO_TEAM ? 'No team' : team.name}
                className={cn('w-full cursor-pointer p-4 px-4 hover:bg-gray-50', {
                  'bg-gray-50': highlightedIndex === index
                })}
              >
                <div className='flex items-center'>
                  <TeamIcon className='mr-2' team={team === NO_TEAM ? null : team} />
                  <Text truncate h='400'>
                    {team === NO_TEAM ? 'No team' : team.name}
                  </Text>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </PopUp>
    </div>
  );
};
