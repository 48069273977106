import * as React from 'react';
import { forwardRef, useRef } from 'react';

import cn from 'classnames';

import { Text } from '@components/common';

import { getEventBlockHeight, getHourMin, getRungStyles } from './utils';

export const EVENT_COLORS: UserColor[] = [
  ['yellow', 200, 100, false],
  ['gray', 700, 600, true],
  ['teal', 400, 300, false],
  ['orange', 200, 100, false]
];

interface Props {
  event: NylasSlotInstance;
  color?: UserColor;
  timezone: Timezone;
  schedulingIncrement: number;
  maxHeight?: number;
}

export const NylasEvent = forwardRef<HTMLButtonElement, Props>(
  ({ event, color, maxHeight, schedulingIncrement }, ref) => {
    const eventRef = useRef(null);

    const eventStyle = {
      height: getEventBlockHeight({
        duration: event.duration,
        rungHeight: getRungStyles(schedulingIncrement).height,
        increment: schedulingIncrement
      }),
      maxHeight
    };

    const [bgColor, grade, hoverGrade, hasWhiteText] = color || [EVENT_COLORS[0]];

    const bgClass = `bg-${bgColor}-${grade}`;
    const hoverBgClass = `hover:bg-${bgColor}-${hoverGrade}`;

    const styles = 'rounded-sm border border-white';

    return (
      <button
        ref={ref}
        data-testid={`event-${event.id}`}
        className={cn('group flex w-full flex-1 flex-col whitespace-nowrap p-0 text-left', styles)}
        style={eventStyle}
        aria-label={event.title || 'Event'}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <div ref={eventRef} className={cn('h-full w-full select-none rounded-sm', bgClass, hoverBgClass)}>
          <Text color={hasWhiteText ? 'white' : undefined} h='400' className='px-1' truncate>
            {event.title}
          </Text>
        </div>
      </button>
    );
  }
);
