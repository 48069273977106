import { api } from '@api/reduxApi';

enum Features {
  unmoderated_test = 'Unmoderated test',
  survey = 'Survey',
  online_task = 'Online task',
  livestream = 'Livestream',
  incentives = 'Incentives',
  external_recruitment = 'External Recruitment',
  panel = 'Panel'
}

type ValidFeatures = keyof typeof Features;

type Result = Record<ValidFeatures, boolean>;

const DEFAULT_RESULT = Object.keys(Features).reduce<Result>(
  (acc, feature) => ({ ...acc, [feature]: false }),
  {} as Result
);

export const useDisabledFeatures = (): Result => {
  const { data } = api.useGetAccountDisabledFeaturesQuery();
  const features = data?.disabled_features;

  if (!features) {
    return DEFAULT_RESULT;
  }

  return features.reduce<Result>((acc, feature) => ({ ...acc, [feature]: true }), { ...DEFAULT_RESULT });
};
