import * as React from 'react';
import { forwardRef } from 'react';

import cn from 'classnames';

import { Text } from './';

interface Props {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  name?: string;
  onChange?: () => void;
  type?: 'radio' | 'checkbox';
  value?: string;
  label?: string;
  checked?: boolean;
}
const Radio = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { id, disabled, onChange, name, label, children, checked, value, type = 'radio' } = props;

  const className = cn('cursor-pointer', { 'border-gray-200': disabled }, props.className);

  return (
    <div className='flex items-center space-x-2'>
      <input
        id={id}
        ref={ref}
        type={type}
        name={name}
        aria-label={label}
        className={className}
        disabled={disabled}
        value={value}
        onChange={!disabled && onChange ? onChange : undefined}
        checked={checked}
      />
      {label && (
        <Text htmlFor={id} color={disabled ? 'gray-500' : undefined} h='400' as='label' className='cursor-pointer'>
          {label}
        </Text>
      )}
      {children}
    </div>
  );
});

export { Radio as default, Props as RadioProps };
