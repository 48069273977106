import * as React from 'react';

import { Button, Modal, ModalHeading } from '@components/common';
import { EligibilityRule } from '@components/GovernanceApp/components/Eligiblity/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (rule: EligibilityRule) => void;
  rule: EligibilityRule;
  isLoading?: boolean;
}

export const DeleteRuleModal: React.FC<React.PropsWithChildren<Props>> = ({
  isLoading,
  open,
  onClose,
  onSubmit,
  rule
}) => {
  if (!open) {
    return null;
  }

  return (
    <Modal onClose={isLoading ? undefined : onClose}>
      <ModalHeading className='mb-2 text-left'>Delete eligibility rule</ModalHeading>
      <p className='pb-12 text-left text-gray-700'>You are about to delete the rule "{rule.title}".</p>
      <div className='flex justify-end space-x-4'>
        <Button disabled={isLoading} onClick={onClose}>
          Don’t delete
        </Button>
        <Button disabled={isLoading} onClick={() => onSubmit(rule)} danger>
          Delete
        </Button>
      </div>
    </Modal>
  );
};
