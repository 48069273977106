import { FigmaPrototypeTypes } from '@components/common/FigmaPrototype';
import { ActualSize, FillScreen, FitWidth, FitWidthAndHeight } from '@components/svgs/figma-scaling';

import * as Enums from './types/enums';

export const TITLE_HELPERS: Partial<Record<Enums.Kind, string>> = {
  [Enums.Kind.prototypeTest]: 'Give participants a task to accomplish with the prototype.'
};

export const TITLE_EXAMPLES: Partial<Record<Enums.Kind, string>> = {
  [Enums.Kind.prototypeTest]: 'Example: Book a flight',
  [Enums.Kind.websiteTest]: 'Example: Book a flight'
};

export const DESCRIPTION_HELPERS: Partial<Record<Enums.Kind, string>> = {
  [Enums.Kind.prototypeTest]: 'Give participants a scenario to assume while trying to accomplish the task.',
  [Enums.Kind.websiteTest]: 'Give participants a scenario to assume while trying to accomplish the task.'
};

export const DESCRIPTION_EXAMPLES: Partial<Record<Enums.Kind, string>> = {
  [Enums.Kind.prototypeTest]:
    "Example: Imagine you're looking to buy flight tickets for a round-trip vacation to Barcelona this summer.",
  [Enums.Kind.websiteTest]:
    "Example: Imagine you're looking to buy flight tickets for a round-trip vacation to Barcelona this summer."
};

export const RESPONSE_LABELS: Partial<Record<Enums.Kind, string>> = {
  [Enums.Kind.info]: '',
  [Enums.Kind.permissions]: '',
  [Enums.Kind.prototypeTest]: '',
  [Enums.Kind.thankYou]: '',
  [Enums.Kind.welcome]: '',
  [Enums.Kind.websiteTest]: '',
  [Enums.Kind.cardSort]: '',
  [Enums.Kind.treeTest]: ''
};

export const RESPONSE_HELPERS: Partial<Record<Enums.Kind, string>> = {};

export const RESPONSE_EXAMPLES: Partial<Record<Enums.Kind, string>> = {};

export const TITLE_PLACEHOLDER: Partial<Record<Enums.Kind, string>> = {
  [Enums.Kind.prototypeTest]: 'Write your task...'
};

export const PROTOTYPE_TEST_TOOLTIP =
  'Currently, participants will only be able to complete prototypes from a desktop device. Test links cannot be opened on mobile or tablet devices.';

export const DEFAULT_RESPONSE_LABEL = 'Response';

export const DEFAULT_DESCRIPTION_PLACEHOLDER = 'Give testers details to complete the task...';

export const DEFAULT_TITLE_PLACEHOLDER = 'Write your question...';

export const PROTOTYPE_SCALING_OPTIONS: { [key in FigmaPrototypeTypes.Scaling]: string } = {
  [FigmaPrototypeTypes.Scaling.minZoom]: 'Actual size (100%)',
  [FigmaPrototypeTypes.Scaling.scaleDown]: 'Fit width and height',
  [FigmaPrototypeTypes.Scaling.scaleDownWidth]: 'Fit width',
  [FigmaPrototypeTypes.Scaling.contain]: 'Fill screen'
};

export const SCALE_ICONS = {
  [FigmaPrototypeTypes.Scaling.minZoom]: ActualSize,
  [FigmaPrototypeTypes.Scaling.scaleDown]: FitWidthAndHeight,
  [FigmaPrototypeTypes.Scaling.scaleDownWidth]: FitWidth,
  [FigmaPrototypeTypes.Scaling.contain]: FillScreen
};

export const PROTOTYPE_DISABLE_KEYBOARD_NAV_TOOLTIP =
  "Unless keyboard shortcuts are an intended part of your prototype tests (e.g. arrow keys to move backward/forward, 'R' to restart) this option should be toggled off to prevent any unintended navigation during tests.";

export const PROTOTYPE_LINK_TOOLTIP =
  'To find the link, launch your prototype and click on the blue Share prototype button in the top right corner, and select Copy link. The permissions must be set to Anyone and can view.';

export const INFO_TOOLTIPS: Partial<Record<Enums.Kind, string>> = {
  [Enums.Kind.cardSort]: 'Discover how people understand and categorize information.',
  [Enums.Kind.treeTest]: "Assess your website or app's structure and content discoverability."
};
