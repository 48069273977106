import React, { useEffect } from 'react';

import { useDebouncedCallback } from 'use-debounce';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

import { DropdownCombobox, FormGroup, Input, Textarea } from '@components/common';
import { Label } from '@components/common/Label';
import { Checklist2SVG, LocationSVG } from '@components/svgs';
import { useForwardedRef } from '@hooks/useForwardedRef';
import { useToaster } from 'components/stores/toaster';
import * as toasts from 'components/StudiesApp/components/StudyDraft/toasts';

import { useGetStudyLocationQuery, useUpdateStudyLocationMutation } from './api';

const DEBOUNCE_RATE = process.env.NODE_ENV === 'test' ? 500 : 1000;

const fetchDetail = async (place_id: string) => {
  const detail = await getDetails({
    placeId: place_id,
    fields: ['name', 'formatted_address', 'geometry', 'address_components']
  });

  if (typeof detail !== 'string') {
    const newValue = {
      address: detail.formatted_address,
      address_components: detail.address_components,
      coordinates: {
        latitude: detail.geometry?.location?.lat(),
        longitude: detail.geometry?.location?.lng()
      }
    };

    return newValue;
  }
};

export const StudyLocationInput = React.forwardRef<HTMLInputElement, { study_id: number }>(({ study_id }, ref) => {
  const inputRef = useForwardedRef<HTMLInputElement>(ref);
  const showToast = useToaster();
  const [studyLocation, setStudyLocation] = React.useState<StudyLocation>();
  const [items, setItems] = React.useState<{ label: string; value: string }[]>([]);
  const [placeId, setPlaceId] = React.useState<string>('');
  const [updateStudyLocation, { isError: locationUpdateError }] = useUpdateStudyLocationMutation();
  const { data: location, isLoading } = useGetStudyLocationQuery(study_id);
  const {
    suggestions: { data },
    setValue: setAutocompleteValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    callbackName: 'initGoogleMap',
    defaultValue: studyLocation?.address,
    requestOptions: {
      types: []
    },
    debounce: 300
  });

  const onSelect = (item) => {
    setPlaceId(item.data.place_id);
  };

  useEffect(() => {
    if (placeId) {
      fetchDetail(placeId).then((detail) => {
        setStudyLocation({ study_id, ...detail });
      });
    }
  }, [placeId]);

  const _save = async (studyLocation: StudyLocation): Promise<void> => {
    try {
      await updateStudyLocation({ study_id, ...studyLocation });
    } catch {
      showToast(toasts.failedUpdate());
    }
  };

  const debouncedSave = useDebouncedCallback(_save, DEBOUNCE_RATE);

  const onCustomAddressClick = () => {
    clearSuggestions();
    setStudyLocation({ ...studyLocation, address: studyLocation?.address });
  };

  const studyLocationToItem = ({ address }: StudyLocation) => {
    return { label: address, value: address };
  };

  useEffect(() => {
    if (studyLocation) {
      debouncedSave.callback(studyLocation);
    }
  }, [studyLocation]);

  useEffect(() => {
    if (location && location.address && !studyLocation) {
      setStudyLocation(location);
    }
  }, [location]);

  useEffect(() => {
    if (locationUpdateError) {
      showToast(toasts.failedUpdate());
    }
  }, [locationUpdateError]);

  useEffect(() => {
    return () => {
      debouncedSave.flush();
    };
  }, []);

  useEffect(() => {
    if (data) {
      setItems(
        data.map((suggestion) => ({
          label: suggestion.structured_formatting.main_text,
          value: suggestion.structured_formatting.main_text,
          data: { secondary_text: suggestion.structured_formatting.secondary_text, place_id: suggestion.place_id }
        }))
      );
    }
  }, [data]);

  if (isLoading) {
    return null;
  }

  return (
    <div className='mt-6'>
      <FormGroup>
        <Label tooltip='The address will be linked in the calendar invite.'>Address</Label>
        <DropdownCombobox
          required
          error={!studyLocation?.address}
          items={items}
          selectedItem={studyLocation ? studyLocationToItem(studyLocation) : (null as any)}
          placeholder={'Search Google Maps or enter address manually'}
          onSelect={onSelect}
          onInputChange={(v) => {
            setStudyLocation({ ...studyLocation, address: v });
            setAutocompleteValue(v);
          }}
          renderItem={(item) => (
            <>
              <strong>{item.label}</strong>
              <small className='ml-2'>{item.data.secondary_text}</small>
            </>
          )}
        />
        {!studyLocation?.address && <p className='mt-2 text-xs text-red-600'> Address is required </p>}
      </FormGroup>
      <FormGroup>
        <Label tooltip='This will be added to the top of the calendar invite description.'>Instructions</Label>
        <Textarea
          className='block w-full rounded-md border-gray-200'
          placeholder='Eg. Enter through the front entrance'
          name='location-notes'
          value={studyLocation?.notes}
          onChange={(v) => {
            setStudyLocation({ ...studyLocation, notes: v });
          }}
        />
      </FormGroup>
    </div>
  );
});
