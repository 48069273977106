import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { format as timeagoFormat } from 'timeago.js';

import { createCandidate, createParticipation, getParticipations } from '@api/queries';
import { Alert, Avatar, Button, DropdownCombobox, Input, Loading, SlideOut, Text } from '@components/common';
import { DropdownItem } from '@components/common/DropdownCombobox';
import { useToaster } from '@stores/toaster';
import { api } from 'api/reduxApi';
import { StudySelector } from 'components/shared/StudySelector';
import { useUser } from 'hooks/useUser';

const SelectorSkeleton = () => <Skeleton duration={1} className='h-10 rounded-full bg-gray-50' />;

function CandidateSelector({ candidate, setCandidate, onNew }) {
  const [search, setSearch] = useState<string>('');

  const { data: fetchedData, isLoading } = api.useGetServerSideCandidatesQuery({
    items: 30,
    page: 1,
    searchQuery: search
  });

  const candidates = useMemo(() => (fetchedData ? fetchedData.data : []), [fetchedData]);

  function handleSelect(selectedId) {
    const selectedCandidate = candidates?.find(({ id }) => id.toString() === selectedId);
    setCandidate(selectedCandidate);
  }

  const options = useMemo(
    () =>
      candidates
        ? candidates.map(({ id, name, email }) => ({
            label: name || 'Unnamed candidate',
            value: id.toString(),
            search: [name, email].join(' ')
          }))
        : [],
    [candidates]
  );

  const selected: DropdownItem | undefined = useMemo(
    () => (candidate ? { label: candidate.name, value: candidate.id } : undefined),
    [candidate]
  );

  if (isLoading) {
    return <SelectorSkeleton />;
  }

  return (
    <div className='mb-6'>
      <DropdownCombobox
        inputClassName='xx-candidate-selector'
        allowCreate
        items={options}
        selectedItem={selected}
        placeholder='Enter name or email…'
        onInputChange={setSearch}
        onSelect={(item) => {
          if (item?._isNew) {
            onNew({ text: item.value });
          } else {
            handleSelect(item?.value);
          }
        }}
        renderItem={(item, isHighlighted) => {
          const candidate = candidates?.find(({ id }) => item.value === id.toString());
          return item._isNew ? (
            <div className='xx-save-new-candidate'>
              <span className={isHighlighted ? 'text-white' : 'text-indigo-600'}>{`”${item.value}”`}</span>
              <span className='text-xs'>Save as new candidate</span>
            </div>
          ) : (
            <div className='flex flex-row items-center space-x-4'>
              {candidate && <Avatar user={candidate as Partial<TeamUser>} />}
              <span className='flex flex-grow flex-col'>
                <span>{item.label}</span>
                <span className='mt-1 text-sm'>{candidate?.email}</span>
              </span>
              <span
                className={'flex-0 text-xs font-semibold ' + (isHighlighted ? 'text-white' : 'text-gray-500')}
              ></span>
            </div>
          );
        }}
      />
    </div>
  );
}

// TODO Create basic (reusable) candidate modal
function NewCandidate({ candidate, setCandidate }) {
  function change(key, v) {
    setCandidate({
      ...candidate,
      [key]: v
    });
  }
  return (
    <>
      <FormGroup>
        <SubLabel>Name</SubLabel>
        <Input value={candidate.name} onChange={(v) => change('name', v)} className='w-full' />
      </FormGroup>
      <FormGroup>
        <SubLabel>Email</SubLabel>
        <Input value={candidate.email} onChange={(v) => change('email', v)} className='xx-email w-full' />
      </FormGroup>
    </>
  );
}

// TODO replace with study modal!!
function NewStudy({ study, setStudy }) {
  function change(key, v) {
    setStudy({
      ...study,
      [key]: v
    });
  }
  return (
    <div>
      <label htmlFor='new_interview_title'>Title</label>
      <Input
        id='new_interview_title'
        value={study.title}
        autoFocus
        onChange={(v) => change('title', v)}
        className='w-full'
      />
    </div>
  );
}

const FormGroup = ({ children }) => <div className='mb-6'>{children}</div>;
const Label = ({ children }) => (
  <Text h='500' bold className='mb-1'>
    {children}
  </Text>
);
const SubLabel = ({ children }) => (
  <Text h='400' color='gray-500' className='mb-4'>
    {children}
  </Text>
);

export const NewInterview: React.FC<React.PropsWithChildren<{ study?: SimpleStudy; onClose?: () => void }>> = ({
  study: initialStudy,
  onClose
}) => {
  const navigate = useNavigate();
  const showToast = useToaster();
  const user = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [study, setStudy] = useState<SimpleStudy | undefined>(initialStudy);
  const [candidate, setCandidate] = useState<Candidate>();
  const [party, setParty] = useState<Participation | null>(null);
  const [newCandidate, setNewCandidate] = useState<boolean>(false);
  const [newStudy, setNewStudy] = useState<boolean>(false);
  const [state, setState] = useState<'create' | 'creating' | 'created' | null>(null);
  const [error, setError] = useState<string | null>();

  const [createStudy] = api.useCreateStudyMutation();

  function handleClose() {
    onClose ? onClose() : navigate('/repository');
  }
  async function handleNewStudy({ title }) {
    setStudy({ title } as Study);
    setNewStudy(true);
  }
  async function create() {
    setState('creating');
    const resp = await createParticipation({
      projectId: study?.id.toString() as any,
      customerId: candidate?.id.toString() as any,
      transition: 'complete',
      source: 'library',
      email: candidate?.email || undefined,
      repo_only: true,
      force: true
    });
    if (resp) {
      setLoading(false);
      setState('created');
      // Using location as different React routers break  things
      window.location.href = `/interviews/${resp.token}`;
      // setParty(resp)
      // showToast()
    } else {
      setLoading(false);
      setState('create');
      showToast({
        heading: 'Error creating interview.',
        text: 'Please try again later.',
        icon: 'error'
      });
    }
  }

  useEffect(() => {
    if (party) {
      setParty(null);
    }
    if (!party && study?.id && candidate?.id) {
      // See if there is already a participation…
      getParticipations(study.id, { candidate_id: candidate.id }).then((parties) => {
        setParty(parties[0]);
      });
    }
    if (state === 'create' && study?.id && candidate?.id) {
      create();
    }
  }, [state, study?.id, candidate?.id]);

  async function handleNewCandidate({ text }) {
    // email of name?
    if (text && text.match(/@/)) {
      setCandidate({ email: text } as Candidate);
    } else {
      setCandidate({ name: text } as Candidate);
    }
    setNewCandidate(true);
  }
  async function handleSubmit() {
    setLoading(true);
    setError(null);
    if (newCandidate) {
      const cResp = await createCandidate(candidate, { repo_only: true });
      if (cResp) {
        setCandidate(cResp);
      } else {
        setLoading(false);
        setState(null);
        setError('Error creating candidate');
        return;
      }
    } else if (!candidate?.id) {
      setLoading(false);
      setState(null);
      setError('Please select a candidate');
      return;
    }
    if (newStudy) {
      try {
        const sResp = await createStudy({
          ...study,
          owner_id: user.id,
          style: 'video_call',
          is_phantom: true
        }).unwrap();
        setStudy(sResp);
      } catch {
        setLoading(false);
        setState(null);
        setError('Error creating study');
        return;
      }
    } else if (!study?.id) {
      setLoading(false);
      setState(null);
      setError('Please set a study');
      return;
    }
    setState('create');
  }
  // if (party?.id) {
  //   return <Modal>
  //     <ModalHeading> Created! </ModalHeading>
  //     <p>Some image</p>
  //     <p>Some Copy</p>
  //     <Button primary href={`/interviews/${party.token}`}>View Interview</Button>

  //   </Modal>
  // }
  const canSubmit = (newCandidate || candidate?.id) && (newStudy || study?.id);

  return (
    <SlideOut
      size='xl'
      closeOnEsc={false}
      title='Add Interview'
      onClose={handleClose}
      renderFooter={() => (
        <div className='flex w-full flex-row space-x-6'>
          <Button primary onClick={handleSubmit} disabled={!canSubmit}>
            Add {party ? 'New ' : ''}Interview
          </Button>
          {party && (
            <Button target='_blank' href={`/interviews/${party.token}`}>
              View existing interview
            </Button>
          )}
        </div>
      )}
    >
      {loading && <Loading absolute />}
      <div className='p-6'>
        {error && (
          <FormGroup>
            <Alert type='error'>{error}</Alert>
          </FormGroup>
        )}
        <FormGroup>
          <Label>Candidate</Label>
          <SubLabel>Who do you want to import an interview for?</SubLabel>
          {!newCandidate && (
            <CandidateSelector candidate={candidate} setCandidate={setCandidate} onNew={handleNewCandidate} />
          )}
          {!newCandidate && candidate && (
            <div className='flex flex-row items-center space-x-4'>
              <span>{candidate.name}</span>
              <span>{candidate.email}</span>
            </div>
          )}
        </FormGroup>
        {newCandidate && candidate && <NewCandidate candidate={candidate} setCandidate={setCandidate} />}
        {(candidate || initialStudy) && (
          <FormGroup>
            <Label>Study</Label>
            <SubLabel>What study is this interview for?</SubLabel>
            {!newStudy && (
              <StudySelector study={study} disabled={!!initialStudy} setStudy={setStudy} onNew={handleNewStudy} />
            )}
            {newStudy && study && <NewStudy study={study} setStudy={setStudy} />}
          </FormGroup>
        )}
        {!newStudy && study && (
          <div className='mt-4 flex flex-row space-x-4'>
            <span>{study.title}</span>
            <span>created: {timeagoFormat(study.created_at as any)}</span>
          </div>
        )}
        {party && state !== 'created' && (
          <FormGroup>{candidate && <Alert>{candidate.name} has already participated in this study</Alert>}</FormGroup>
        )}
      </div>
    </SlideOut>
  );
};
