import React from 'react';
import { useState } from 'react';

import { format } from 'date-fns';

import { Button, CheckboxesGroup, FormGroup, Input, Modal, Text, Textarea } from '@components/common';
import { getChangeFn, humanize } from 'components/utils';

interface Props {
  consentForm: ConsentForm;
  onSave: (params: Partial<ConsentForm>) => Promise<void>;
  onCancel: () => void;
}

const FIELDS = ['name', 'email', 'title', 'address', 'phone_number', 'signature'];

export const ConsentFormFieldsModal: React.FC<React.PropsWithChildren<Props>> = ({
  consentForm: initialConsentForm,
  onSave,
  onCancel
}) => {
  const [consentForm, setConsentForm] = useState<ConsentForm>(initialConsentForm);
  const [loading, setLoading] = useState<boolean>(false);
  const change = getChangeFn<ConsentForm>(consentForm, setConsentForm);

  return (
    <Modal
      title='Edit consent form fields'
      size='md'
      onClose={onCancel}
      renderFooter={() => (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button primary onClick={() => onSave(consentForm)}>
            Save
          </Button>
        </>
      )}
    >
      <div className='text-left'>
        <Text bold mb='4'>
          What fields do you want to collect?
        </Text>
        <CheckboxesGroup
          selected={consentForm.form_fields}
          options={FIELDS.map((f) => ({
            label: f.replace('_', ' '),
            helper: '',
            value: f,
            disabled: f === 'signature'
          }))}
          onChange={(v) => change?.('form_fields', v)}
        />
      </div>
    </Modal>
  );
};
