import React, { FC } from 'react';

import { Select, SelectOption, Text } from '@components/common';

interface Props {
  question: ScreenerField;
  value: string;
  onChange: (operator: string) => void;
}

const FIELD_TYPES = {
  options: ['yes_no', 'multiple_choice', 'single_choice'],
  text: ['long_text', 'short_text', 'email', 'website'],
  number: ['number', 'number_range'],
  date: ['date'],
  location: ['location']
};

const LABELS = {
  equal: 'Equals',
  not_equal: 'Not equals',
  matches_all: 'Contains all of',
  matches_any: 'Contains any of',
  matches_none: 'Contains none of',
  match_not_exact: 'Does not perfectly match',
  contains: 'Contains',
  not_contains: "Doesn't contain",
  starts_with: 'Starts with',
  ends_with: 'Ends with',
  greater_than: 'Greater than',
  less_than: 'Lesser than',
  any_answer: 'Any answer',
  no_answer: 'No answer'
};

export const OperatorSelect: FC<Props> = ({ question, value: defaultValue, onChange }) => {
  const getOptions = () => {
    const options: string[] = [];

    if (FIELD_TYPES.options.includes(question.field_type)) {
      const multi = question.field_type === 'multiple_choice';
      const single = question.field_type === 'single_choice';

      options.push('equal');
      options.push('matches_any');
      if (multi) {
        options.push('matches_all');
        options.push('matches_none');
        options.push('match_not_exact');
      }
    }

    if (FIELD_TYPES.text.includes(question.field_type)) {
      options.push('contains', 'not_contains', 'starts_with', 'ends_with');
    }

    if (FIELD_TYPES.number.includes(question.field_type)) {
      options.push('equal', 'greater_than', 'less_than');
    }

    if (FIELD_TYPES.date.includes(question.field_type)) {
      options.push('equal', 'greater_than', 'less_than');
    }

    if (FIELD_TYPES.location.includes(question.field_type)) {
      options.push('equal', 'not_equal');
    }

    if (question.field_type !== 'multiple_choice') {
      options.push('any_answer', 'no_answer');
    }

    return options;
  };

  const items: SelectOption<string>[] = getOptions().map((option) => ({
    label: LABELS[option] ?? '',
    value: option
  }));

  return (
    <Select<string>
      className='bg-white'
      options={items}
      value={defaultValue || items[0].value}
      onChange={onChange}
      name='op'
    />
  );
};
