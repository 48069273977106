import * as React from 'react';
import { createContext, useReducer } from 'react';

import type { EditorState } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';
import type { FC, Reducer } from 'react';

import { UseVideoPlayer } from 'components/VideoPlayer';

enum Actions {
  UPDATE_CURRENT_EDITOR = 'UPDATE_CURRENT_EDITOR',
  SET_TRANSCRIPT_VIDEO_HANDLE = 'SET_TRANSCRIPT_VIDEO_HANDLE',
  SET_TRANSCRIPT_SPEAKERS = 'SET_TRANSCRIPT_SPEAKERS',
  SET_SHOW_MINI_PLAYER = 'SET_SHOW_MINI_PLAYER',
  SET_VIDEO_PLAYER_IN_VIEW = 'SET_VIDEO_PLAYER_IN_VIEW',
  SET_REPO_SESSION = 'SET_REPO_SESSION'
}

export interface Editor {
  state: EditorState;
  view: EditorView;
  documentId: number | null;
}

interface State {
  activeEditor: Editor | null;
  transcriptVideoHandle: UseVideoPlayer | null;
  transcriptSpeakers: TranscriptSpeaker[];
  showMiniPlayer: boolean;
  videoPlayerInView: boolean;
  repoSession: RepoSession | null;
}

type Action =
  | { type: Actions.UPDATE_CURRENT_EDITOR; payload: Editor }
  | { type: Actions.SET_TRANSCRIPT_VIDEO_HANDLE; payload: State['transcriptVideoHandle'] }
  | { type: Actions.SET_TRANSCRIPT_SPEAKERS; payload: TranscriptSpeaker[] }
  | { type: Actions.SET_SHOW_MINI_PLAYER; payload: boolean }
  | { type: Actions.SET_VIDEO_PLAYER_IN_VIEW; payload: boolean }
  | { type: Actions.SET_REPO_SESSION; payload: RepoSession };

export interface Context {
  state: State;
  updateActiveEditor: (editor: Editor) => void;
  setTranscriptVideoHandle: (videoHandle: State['transcriptVideoHandle']) => void;
  setTranscriptSpeakers: (speakers: TranscriptSpeaker[]) => void;
  setShowMiniPlayer: (showMiniPlayer: boolean) => void;
  setVideoPlayerInView: (videoPlayerInView: boolean) => void;
  setRepoSession: (repoSession: RepoSession) => void;
}

export const INITIAL_STATE: State = {
  activeEditor: null,
  transcriptVideoHandle: null,
  transcriptSpeakers: [],
  showMiniPlayer: false,
  videoPlayerInView: true,
  repoSession: null
};

const Store = createContext<Context>({} as Context);

const reducer = (state: State = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Actions.UPDATE_CURRENT_EDITOR:
      return { ...state, activeEditor: action.payload };
    case Actions.SET_TRANSCRIPT_VIDEO_HANDLE:
      return { ...state, transcriptVideoHandle: action.payload };
    case Actions.SET_TRANSCRIPT_SPEAKERS:
      return { ...state, transcriptSpeakers: action.payload };
    case Actions.SET_SHOW_MINI_PLAYER:
      return { ...state, showMiniPlayer: action.payload };
    case Actions.SET_VIDEO_PLAYER_IN_VIEW:
      return { ...state, videoPlayerInView: action.payload };
    case Actions.SET_REPO_SESSION:
      return { ...state, repoSession: action.payload };
    default:
      return state;
  }
};

export const Provider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, INITIAL_STATE);

  const updateActiveEditor = (editor: Editor): void => {
    dispatch({ type: Actions.UPDATE_CURRENT_EDITOR, payload: editor });
  };

  const setTranscriptVideoHandle = (videoHandle: State['transcriptVideoHandle']): void => {
    dispatch({ type: Actions.SET_TRANSCRIPT_VIDEO_HANDLE, payload: videoHandle });
  };

  const setTranscriptSpeakers = (speakers: TranscriptSpeaker[]) => {
    dispatch({
      type: Actions.SET_TRANSCRIPT_SPEAKERS,
      payload: speakers
    });
  };

  const setShowMiniPlayer = (showMiniPlayer: boolean) => {
    dispatch({ type: Actions.SET_SHOW_MINI_PLAYER, payload: showMiniPlayer });
  };

  const setVideoPlayerInView = (videoPlayerInView: boolean) => {
    dispatch({ type: Actions.SET_VIDEO_PLAYER_IN_VIEW, payload: videoPlayerInView });
  };

  const setRepoSession = (repoSession: RepoSession) => {
    dispatch({ type: Actions.SET_REPO_SESSION, payload: repoSession });
  };

  const store = {
    state,
    updateActiveEditor,
    setTranscriptVideoHandle,
    setTranscriptSpeakers,
    setShowMiniPlayer,
    setVideoPlayerInView,
    setRepoSession
  };

  return <Store.Provider value={store}>{children}</Store.Provider>;
};

Store.displayName = 'RepoSessionPageStore';

export default Store;
