import * as React from 'react';

import { Stat } from '@components/common';
// import { useEffect, useState } from 'react';
import { moneyFormat } from '@components/utils';
import { useAccount } from '@hooks/useAccount';

export const WalletStats: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    account: { wallet }
  } = useAccount();
  return (
    <div className='px-page flex flex-1 flex-wrap border-t border-gray-200'>
      <Stat title='Available' tip='This is the balance available on your account that can be used for research.'>
        ${moneyFormat(wallet.available / 100)}
      </Stat>
      <Stat
        title='Allocated'
        tip="This is the amount that's been allocated to active research studies, but haven't been sent out yet. "
      >
        ${moneyFormat(wallet.allocated / 100)}
      </Stat>
      <Stat title='Spent' tip='This is the amount that has been sent to research participants.'>
        ${moneyFormat(wallet.spent / 100)}
      </Stat>
      <Stat title='Average per Study' tip='This is the average amount allocated or spent per research study.'>
        ${moneyFormat(wallet.average / 100)}
      </Stat>
    </div>
  );
};
