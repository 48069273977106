import * as React from 'react';

import { QuestionCard, QuestionInner, QuestionWrapper } from 'components/Public/GQSurvey/QuestionCard';

export const SurveyPreview: React.FC<React.PropsWithChildren<{ brand: Brand; accountName: string }>> = ({
  brand,
  accountName
}) => {
  const { logo } = brand;

  return (
    <div className='brand relative flex h-full flex-1 flex-col'>
      <div className='tablet:fixed tablet:z-10 tablet:border-b w-full border-gray-200 bg-white pb-6 text-center'>
        {logo && (
          <div className={`desktop:pt-8 desktop:px-12 logo-align-brand block w-full bg-white p-4`}>
            <div className='inline w-auto'>
              <img alt={`${accountName} logo`} className='desktop:max-h-32 inline max-h-24 w-auto' src={logo} />
            </div>
          </div>
        )}
        <span
          style={{ paddingTop: logo ? undefined : '24px' }}
          className='text-custom-brand block text-center text-xl font-bold'
        >
          Help us build a better product experience at {accountName}.
        </span>
      </div>
      <div className='tablet:py-6 tablet:px-8 tablet:py-48 mx-auto max-w-4xl flex-1 pb-12'>
        <form>
          <QuestionWrapper id={1}>
            <QuestionCard isActive>
              <div className='text-custom-brand w-8 text-xl font-bold'>1.</div>
              <QuestionInner>
                <div className='text-custom-brand flex items-start space-x-1 text-xl font-bold leading-normal'>
                  <span>What’s your favorite place to work from?</span>
                </div>

                <div className='mt-5 w-full'>
                  <label className='cursor-pointer py-2 text-base font-normal normal-case'>
                    <input
                      type='radio'
                      className='focus-ring-custom-brand input-custom-brand mr-2 h-4 w-4 border-gray-400'
                    />
                    Home
                  </label>
                  <label className='cursor-pointer py-2 text-base font-normal normal-case'>
                    <input
                      type='radio'
                      className='focus-ring-custom-brand input-custom-brand mr-2 h-4 w-4 border-gray-400'
                    />
                    Office
                  </label>
                  <label className='cursor-pointer py-2 text-base font-normal normal-case'>
                    <input
                      type='radio'
                      className='focus-ring-custom-brand input-custom-brand mr-2 h-4 w-4 border-gray-400'
                    />
                    Other
                  </label>
                </div>
              </QuestionInner>
            </QuestionCard>
          </QuestionWrapper>

          <div className='tablet:flex-row tablet:p-0 flex flex-col items-center justify-end px-10'>
            <div className='flex-1'>
              <span className='text-custom-brand-secondary'>0 of 3</span>
            </div>

            <button className='btn-custom-brand focus:ring-blue focus:outline-none tablet:w-auto tablet:mb-0mb-4 relative block inline-flex cursor-pointer items-center justify-center rounded-md bg-indigo-600 px-4 py-2.5 text-sm font-medium leading-5 text-white focus:ring disabled:opacity-50'>
              Continue
            </button>
          </div>
        </form>
      </div>
      <div className='flex-0 monitor:px-36 tablet:px-16 desktop:px-20 monitor:px-24 tablet:pb-10 tablet:border-0 tablet:items-end flex w-full items-center justify-between border-t border-gray-200 bg-white px-10 py-4 text-center'>
        <p className='h400 capitalize'>* Required</p>
        <div className='inline-block'>
          <a href='greatquestion.co' className='xx-powered-by flex flex-col justify-center text-center'>
            <span className='text-xs font-light uppercase text-gray-500'>Powered by</span>
            <img src='/temp-assets/logo.png' className='h-6 w-auto' alt='Great Question' />
          </a>
        </div>
      </div>
    </div>
  );
};
