import * as React from 'react';

import { getDefaultOperator } from '../components/IdealAnswers/utils';

import { IdealAnswerStar } from './IdealAnswerStar';

export const YesNo: QuestionCardBodyComponent = ({ question: q, onChange, config }) => {
  const { hasIdealAnswers } = config || {};
  const idealAnswerValue = (q.ideal_answer || {}).value;

  function toggleIdealAnswer(option: string) {
    const value = idealAnswerValue === option ? null : option;
    onChange({
      ...q,
      ideal_answer: {
        operator: getDefaultOperator(q),
        value
      }
    });
  }

  return (
    <div className=''>
      <ul className='space-y-3'>
        <li className='relative flex items-center space-x-2 text-gray-700'>
          <div className='h-4 w-4 rounded-full border border-gray-400' />
          {hasIdealAnswers && (
            <IdealAnswerStar ideal={idealAnswerValue === 'Yes'} onClick={() => toggleIdealAnswer('Yes')} />
          )}
          <span className='text-gray-700'>Yes</span>
        </li>
        <li className='relative flex items-center space-x-2 text-gray-700'>
          <div className='h-4 w-4 rounded-full border border-gray-400' />
          {hasIdealAnswers && (
            <IdealAnswerStar ideal={idealAnswerValue === 'No'} onClick={() => toggleIdealAnswer('No')} />
          )}
          <span className='text-gray-700'>No</span>
        </li>
      </ul>
    </div>
  );
};
