import * as React from 'react';

import pluralize from 'pluralize';

import { Select, Text } from '@components/common';
import { DURATION_OPTIONS } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/constants';

import { FieldRow } from '../components/FieldRow';

import type { BookingDetailsFormFieldComponentProps } from './types';

export const durationDisplayText = (duration = 0) => pluralize('minutes', duration, true);

export const Duration: React.FC<BookingDetailsFormFieldComponentProps> = ({ editMode, bookingDetail, onChange }) => {
  const event = bookingDetail.candidate_event;

  if (!event) return null;

  const handleChangeDuration = (v: number) => {
    onChange({ candidate_event: { time: { duration_in_minutes: v } } });
  };

  const filteredDurationOptions = DURATION_OPTIONS.filter((option) => option.value !== 'custom');

  return (
    <FieldRow label='Duration'>
      {!editMode && (
        <div className='overflow-auto'>
          <Text className='py-2.5' h='400'>
            {durationDisplayText(event.time.duration_in_minutes)}
          </Text>
        </div>
      )}
      {editMode && (
        <div className='w-full'>
          <Select
            name='duration'
            options={filteredDurationOptions}
            value={String(event.time.duration_in_minutes)}
            onChange={(v) => handleChangeDuration(parseInt(v))}
          />
        </div>
      )}
    </FieldRow>
  );
};
