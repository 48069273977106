import * as React from 'react';
import { createContext, useEffect, useState } from 'react';

import { getPlans } from '@api/queries';
import { Loading } from '@components/common';

export const context = createContext<{ plans: Plan[] }>({ plans: null as any });

export const PlansProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState<Plan[]>(null as any);

  useEffect(() => {
    getPlans()
      .then((plans) => {
        if (plans) {
          setPlans(plans);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return <context.Provider value={{ plans }}>{children}</context.Provider>;
};
