import React from 'react';

import pluralize from 'pluralize';

import { Text, Toggle } from 'components/common';

import { Batching } from '../types';

type BatchFieldsProps = {
  batch: Batching;
  event: string;
  setBatch: (batch: Batching) => void;
  target?: boolean;
  title?: string;
};

export const BatchFields: React.FC<React.PropsWithChildren<BatchFieldsProps>> = ({
  batch,
  event,
  setBatch,
  target = false,
  title = 'messages'
}) => (
  <div className='form-group mb-6 mt-6 flex flex-col'>
    <div className='flex flex-row space-x-2'>
      <Toggle name='batch_on' on={batch.on} onToggle={(v) => setBatch({ ...batch, on: v })} />
      <div className='mb-2 mt-1 flex items-center'>
        <Text h='400' bold>
          Send {title} in batches
        </Text>
      </div>
    </div>
    {batch.on && (
      <>
        <div className='mb-2 ml-14 flex w-full items-center'>
          <input
            type='number'
            name='batch_size'
            value={batch.size}
            onChange={(e) => setBatch({ ...batch, size: parseInt(e.currentTarget.value) })}
            className='no_arrows w-12 rounded-md border-gray-300 px-0 text-center shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
          />
          <span className='px-1.5'>{title} sent every</span>
          <input
            type='number'
            name='batch_wait_hours'
            value={batch.wait_hours}
            onChange={(e) => setBatch({ ...batch, wait_hours: parseInt(e.currentTarget.value) })}
            className='no_arrows w-12 rounded-md border-gray-300 px-0 text-center shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
          />
          <span className='px-1.5'>hours</span>
        </div>
        {target && (
          <div className='ml-14 flex w-full items-center'>
            <span className='px-1.5'>Until</span>
            <input
              type='number'
              name='batch_target'
              value={batch.target}
              onChange={(e) => setBatch({ ...batch, target: parseInt(e.currentTarget.value) })}
              className='no_arrows w-12 rounded-md border-gray-300 px-0 text-center shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
            />
            <span className='px-1.5'>{pluralize(event)} from this batch are completed</span>
          </div>
        )}
        <div className='ml-14 flex w-full'>
          <p className='mt-2 text-sm text-gray-600'>Note: Participants will be selected at random.</p>
        </div>
      </>
    )}
  </div>
);
