import React from 'react';
import { useMemo, useState } from 'react';

import pluralize from 'pluralize';

import { Button, Modal, ModalHeading, Text } from '@components/common';
import { buildRepoFilterDefs } from '@components/RepositoryApp/filters/buildRepoFilterDefs';
import { ArtifactCard } from '@components/RepositoryApp/hits/artifacts/ArtifactCard';
import { useAlgoliaFilters } from '@components/RepositoryApp/hooks/useAlgoliaFilters';
import { ArtifactHit } from '@components/RepositoryApp/types';
import { SearchInput } from '@components/shared/SearchInput';
import { AnyAllToggle, SegmentActions, TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { api } from '@components/SurveyBuilder';
import { compact, uniq } from '@components/utils';
import { AlgoliaData, algoliaProxyAdapter, AlgoliaProxyConfig, Search, useSearch } from 'components/shared/Search';

import { useTiptapContext } from '../hooks';

type HitResultsProps = {
  selected: ArtifactHit[];
  setSelected: (hits: ArtifactHit[]) => void;
  initialSelectedHits?: ArtifactHit['objectID'][];
};

type Props = {
  isOpen: boolean;
  selectedHits?: ArtifactHit['objectID'][];
  onClose: () => void;
  onSelect?: (hits: ArtifactHit[]) => void;
};

const Hits = ({ selected, setSelected, initialSelectedHits }: HitResultsProps) => {
  const { data } = useSearch<AlgoliaData>();
  const { hits = [] } = data ?? {};
  const selectedHitIds = selected.map(({ objectID }) => objectID).concat(initialSelectedHits ?? []);

  return (
    <div className={'grid grid-cols-3 gap-4'}>
      {hits.map((hit) => {
        return (
          <ArtifactCard
            key={hit.objectID}
            {...(hit as ArtifactHit)}
            isLink={false}
            page='embedded_artifacts_modal'
            canSelect
            selectedArtifacts={uniq(selectedHitIds)}
            setSelectedArtifacts={(artifactIds) => {
              setSelected(compact(artifactIds.map((id) => hits.find((h) => h.objectID === id))) as ArtifactHit[]);
            }}
          />
        );
      })}
    </div>
  );
};

export const EmbedArtifactModal = ({ isOpen, selectedHits: initialSelectedHits, onClose, onSelect }: Props) => {
  const { studyId } = useTiptapContext();

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedHits, setSelectedHits] = useState<ArtifactHit[]>([]);

  const definitions = useMemo(() => buildRepoFilterDefs(studyId), []);

  const filtersHook = useTableFilters<ArtifactHit>({
    definitions,
    defaultFilters: [],
    trackKey: 'embed_artifact_modal'
  });

  const filters = useAlgoliaFilters(filtersHook);

  const [fetch] = api.useLazyGetRepoArtifactsQuery();

  const algoliaProxyConfig = useMemo<AlgoliaProxyConfig>(
    () => ({
      fetch,
      sortBy: 'created_at',
      hitsPerPage: 100
    }),
    [fetch]
  );

  const reset = () => {
    setSearchValue('');
    setSelectedHits([]);
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      footerHeight='20'
      noPadding
      size='xl'
      onClose={reset}
      scrollOverflow
      renderFooter={() => (
        <div>
          <div className='flex items-center justify-end'>
            <Text className='flex-grow text-left text-gray-400'>
              {pluralize('artifact', selectedHits.length, true)} selected
            </Text>
            <div className='space-x-2'>
              <Button text onClick={onClose}>
                Cancel
              </Button>
              <Button
                primary
                disabled={selectedHits.length < 1}
                onClick={() => {
                  onSelect?.(selectedHits);
                  reset();
                }}
              >
                Embed
              </Button>
            </div>
          </div>
        </div>
      )}
    >
      <Search<AlgoliaData, AlgoliaProxyConfig>
        query={searchValue}
        filters={filters}
        adapter={algoliaProxyAdapter}
        config={algoliaProxyConfig}
        infiniteResults
      >
        <div className='p-6'>
          <ModalHeading>Embed {pluralize('artifact')}</ModalHeading>

          <div className='pt-6'>
            <SearchInput
              placeholder={`Search ${pluralize('artifact')}…`}
              onSearch={setSearchValue}
              value={searchValue}
            />
          </div>
          <div className='-mb-4 flex items-center'>
            <div className='z-50 flex-1'>
              <TableFilters<ArtifactHit> hook={filtersHook} />
            </div>
            <div className='flex space-x-3 border-l border-gray-200 py-3 pl-3'>
              <AnyAllToggle value={filtersHook.op} onChange={filtersHook.setOp} />
              <SegmentActions onClear={filtersHook.clearFilters} anyFilters={filtersHook.filters.length > 0} />
            </div>
          </div>
        </div>

        <div className='overflow-y-scroll border-b border-t border-gray-200 p-6'>
          <Hits selected={selectedHits} setSelected={setSelectedHits} initialSelectedHits={initialSelectedHits} />
        </div>
      </Search>
    </Modal>
  );
};
