import * as React from 'react';

import { ArrowRightSVG } from '@components/svgs';

type Props = {
  leftSide: React.ReactNode | string;
  rightSide: React.ReactNode | string;
};

export const DiffRow: React.FC<React.PropsWithChildren<Props>> = ({ leftSide, rightSide }) => (
  <div className='flex w-full items-center py-2.5'>
    <div className='min-w-0 flex-1'>
      {typeof leftSide === 'string' ? <span className='line-through'>{leftSide}</span> : leftSide}
    </div>
    <div className='mx-2 flex-shrink-0'>
      <ArrowRightSVG />
    </div>
    <div className='min-w-0 flex-1 text-right'>
      {typeof rightSide === 'string' ? <span>{rightSide}</span> : rightSide}
    </div>
  </div>
);
