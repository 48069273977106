import React, { FC } from 'react';

import { Column, Grid } from '@components/common/Grid';
import { Basic } from '@components/shared/Skeleton';

export const SurveyBuilderSkeleton: FC = () => {
  return (
    <div data-testid='Skeleton' className='w-full rounded border border-gray-200 bg-white'>
      <Grid desktop={12} className='overflow-hidden'>
        <Column
          id='surveyBuilderBlocks'
          desktop={4}
          className='border-r border-gray-200'
          style={{ contain: 'size', height: '628px' }}
        >
          <div className='relative flex h-full flex-col items-stretch p-6'>
            {Array(3)
              .fill(3)
              .map((_, i) => (
                <Basic key={i} className='mb-4 rounded' width='100%' height={72} />
              ))}
            <Basic width={105} height={34} className='mb-4' />
            <Basic className='mb-4 rounded' height={72} width='100%' />
          </div>
        </Column>
        <Column desktop={8} style={{ height: 'fit-content' }}>
          <div className='flex flex-col items-stretch'>
            <header className='flex h-20 flex-row items-center justify-between border-b border-gray-200 p-6'>
              <Basic height={30} width={90} />
            </header>

            <section className='h-full p-6'>
              <Basic width='100%' height={500} />
            </section>
          </div>
        </Column>
      </Grid>
    </div>
  );
};
