import React from 'react';

import { Alert, AlertHeading, AlertMessage } from '@components/common';
import { useAccount } from '@hooks/useAccount';

import { AlertProps } from '../types';

export const PendingFunding: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    account: {
      owner: { name }
    }
  } = useAccount();
  return (
    <Alert className='my-4' type='warning'>
      <>
        <AlertHeading>Waiting on incentives funding</AlertHeading>
        <AlertMessage>{`Your account administrator, ${name}, must approve the incentives funding before you can send invites.`}</AlertMessage>
      </>
    </Alert>
  );
};
