import React, { useState } from 'react';

import { ExportCsvModal } from '@components/shared/ExportCsvModal';
import { Tooltip } from '@components/Tooltip';

import { Button } from './Button';

interface Props {
  disabled?: boolean;
  disabledTooltip?: string;
  subjectString: string;
  params: CsvExportParams;
}

export const ExportCsvButtonServerSide: React.FC<React.PropsWithChildren<Props>> = ({
  disabled,
  disabledTooltip,
  subjectString,
  params
}) => {
  const [open, setOpen] = useState<boolean>(false);

  if (disabled) {
    return (
      <Tooltip content={disabledTooltip} isDisabled={!disabledTooltip}>
        <div>
          <Button disabled aria-label='Export CSV' iconSuffix='export'>
            Export CSV
          </Button>
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <Button aria-label='Export CSV' iconSuffix='export' onClick={() => setOpen(true)}>
        Export CSV
      </Button>
      {open && <ExportCsvModal setOpen={setOpen} subjectString={subjectString} params={params} />}
    </>
  );
};
