import React from 'react';

import { useOnboardingContext } from '@components/Onboarding/Onboarding';

export const Stepper = () => {
  const { currentStep, totalSteps } = useOnboardingContext();
  const fillPercentage = ((currentStep || 0) / (totalSteps || 1)) * 100;

  return (
    <div className='w-full px-4'>
      <div className='mx-auto my-4 h-2 w-full max-w-3xl overflow-hidden rounded bg-gray-200'>
        <div className='h-full rounded bg-indigo-600' style={{ width: `${fillPercentage}%` }} />
      </div>
    </div>
  );
};
