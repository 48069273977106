import React, { forwardRef, HTMLAttributes, MouseEventHandler } from 'react';

import cn from 'classnames';
import { Editor } from '@tiptap/react';

import * as Icons from '../icons';

import { MenuBarButton } from './MenuBarButton';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  editor: Editor;
  isVisible?: boolean;
  openModal: () => void;
}

export const LinkMenu = forwardRef<HTMLDivElement, Props>(
  ({ editor, className, openModal, isVisible: _, ...rest }, ref) => {
    const { href } = editor.getAttributes('link');

    let fixedHref: string = href || '';
    if (!fixedHref.toLowerCase().match(/^https?:\/\//)) {
      fixedHref = `http://${fixedHref}`;
    }

    return (
      <section ref={ref} className={cn('flex w-64 p-2', className)} {...rest}>
        <MenuBarButton className='relative mr-1 h-8 min-w-0 px-2 text-sm text-indigo-600'>
          <a title={`open custom link: ${fixedHref}`} href={fixedHref} className='absolute inset-0' target='_blank' />
          <Icons.ExternalLink className='mr-2 flex-shrink-0' />
          <span className='truncate'>{href}</span>
        </MenuBarButton>
        <div className='ml-auto flex items-center space-x-1'>
          <MenuBarButton className='h-8 w-8' tooltip='Edit link' onClick={openModal}>
            <Icons.Pencil />
          </MenuBarButton>
          <div className='mx-2 h-6 border-r border-gray-200' />
          <MenuBarButton
            className='h-8 w-8'
            tooltip='Delete link'
            onClick={() => editor.chain().focus().unsetLink().run()}
          >
            <Icons.Trash />
          </MenuBarButton>
        </div>
      </section>
    );
  }
);
