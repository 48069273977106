import * as React from 'react';

import { DeleteClipModal } from '@components/RepoSessionApp/ClipPage/components';
import { DeleteSessionModal } from '@components/RepoSessionApp/Header/components/DeleteSessionModal';
import { ArtifactAttributes } from '@components/RepositoryApp/types';
import { DeleteHighlightReelModal } from 'components/HighlightReelsApp/components/Show/components/DeleteHighlightReelModal';
import { DeleteInsightModal } from 'components/InsightsApp/InsightPage/DeleteInsightModal';

interface Props {
  reload?: () => void;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalOpen: boolean;
  onConfirm: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  selected?: ArtifactAttributes;
}

export const DeleteModal: React.FC<React.PropsWithChildren<Props>> = ({
  selected,
  reload,
  setModalOpen,
  modalOpen,
  onConfirm
}) => {
  if (!selected) return null;

  switch (selected.kind) {
    case 'Story':
      return (
        <DeleteInsightModal
          reload={reload}
          onSubmit={onConfirm}
          navigateToInsights={false}
          slug={selected.href.slice(10)}
          title={selected.title}
          onClose={() => setModalOpen(false)}
          open={modalOpen}
        />
      );
    case 'Clip':
    case 'Highlight':
      return (
        <DeleteClipModal
          highlightUuid={String(selected.model_id)}
          isOpen={modalOpen}
          onConfirm={onConfirm}
          onClose={() => setModalOpen(false)}
        />
      );
    case 'HighlightReel':
      return (
        <DeleteHighlightReelModal
          open={modalOpen}
          token={selected.href.slice(17)}
          onClose={() => setModalOpen(false)}
          onSubmit={() => {
            reload?.();
            onConfirm();
          }}
        />
      );

    case 'Repo::Session':
      return (
        <DeleteSessionModal
          uuid={selected.href.slice(10)}
          title={selected.title}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onSubmit={onConfirm}
        />
      );
    default:
      return null;
  }
};
