import * as React from 'react';
import { useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import cn from 'classnames';
import { add, differenceInMinutes, format, set } from 'date-fns';
import { Draggable } from 'react-beautiful-dnd';
import mergeRefs from 'react-merge-refs';

import { Popper } from '@components/common/Popper';
import {
  getBlockDate,
  onDragTop
} from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/AttendeesCalendars/components/utils';
import {
  getEventBlockHeight,
  getHourMin,
  getHours as getHoursNumber,
  getRungStyles,
  NormalizedSlot
} from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/utils';
import { ConflictChecker } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/utils/conflictChecker';
import { track } from '@components/tracking';
import { rangeMap, useOnClickOutside } from '@components/utils';
import { useTrapFocus } from '@hooks/useTrapFocus';

import { GetCalendarStateSet } from '../../NylasCalendar/store/getters';
import { useDragToResize, UseDragToResizeHookState } from '../../NylasCalendar/useDragToResize';

import { HoveringSlotControls } from './HoveringSlotControls';

const MIN_BLOCK_HEIGHT = 24;
const ONE_HOUR_HEIGHT_IN_PIXELS = 48;
const SLOT_HORIZONTAL_PADDING = 4;

interface Props {
  event: NormalizedSlot<SlotInstance>;
  disallowEdit: boolean;
  idx: number;
  startHour: number;
  endHour: number;
  studyDuration: number;
  canResize?: boolean;
  onUpdate: (event: Partial<NormalizedSlot<SlotInstance>>, debounce?: boolean) => void;
  onClickDelete: () => void;
  schedulingIncrement: number;
  rungTime: string;
  conflictChecker?: ConflictChecker;
  hasEvents: boolean;
  showConflicts: boolean;
  dragResizeState: UseDragToResizeHookState;
  setDragResizeState: (state: UseDragToResizeHookState) => void;
  calendarStates: GetCalendarStateSet;
  date: string;
}

export const AvailabilityBlock: React.FC<React.PropsWithChildren<Props>> = ({
  event,
  disallowEdit,
  idx,
  startHour,
  endHour,
  studyDuration,
  canResize = true,
  onUpdate,
  onClickDelete,
  schedulingIncrement,
  rungTime,
  conflictChecker,
  hasEvents,
  showConflicts,
  dragResizeState,
  setDragResizeState,
  calendarStates,
  date
}) => {
  const [focus, setFocus] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLButtonElement>(null);

  const { ref: trapRef } = useTrapFocus();

  const eventDate = event.date.split('-').join('/');

  const startTime = new Date(eventDate);

  const { hour, min } = getHourMin(event.time);

  const rungStyles = getRungStyles(schedulingIncrement);

  startTime.setHours(hour);
  startTime.setMinutes(min);

  const topHandle = useDragToResize({
    id: `${event.id}-top`,
    dragResizeState,
    setDragResizeState,
    snapHeight: rungStyles.height,
    onDragEnd: (pxDiff) => onDragTop({ pxDiff, event, startHour, schedulingIncrement, rungStyles, onUpdate })
  });

  const bottomHandle = useDragToResize({
    id: `${event.id}-bottom`,
    dragResizeState,
    setDragResizeState,
    snapHeight: rungStyles.height,
    onDragEnd: (pxDiff) => {
      const diffInMinutes = pxDiff * (schedulingIncrement / rungStyles.height);
      const maxDuration = (endHour - (hour + min / 60)) * 60;
      const duration = Math.min(maxDuration, event.duration + diffInMinutes);

      track('availability_slot_updated', { place: 'availability_block_resize' });

      const newSlot: Partial<NormalizedSlot<SlotInstance>> = { ...event, duration };

      delete newSlot.original;

      onUpdate(newSlot, true);
    }
  });

  const handleClickDelete: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    onClickDelete();
    track('availability_slot_deleted', { place: 'availability_block_popup' });
  };

  // handle top gap for increments lower than current selected
  // ex: show block starting at 7:15 in a 30 min scheduling increment
  const getTopGapDiff = () => {
    if (rungTime !== event.time) {
      if (min === 0) return 0;
      // get rung height based on event minutes (ex: 15 min should get rung styles for that increment)
      const { height: heightDiff } = getRungStyles(min);
      return rungStyles.height - heightDiff + 1; // border 1px
    }
    return 0;
  };

  useOnClickOutside(ref, () => {
    if (focus) {
      setFocus(false);
    }
  });

  const initialHeight = useMemo(
    () =>
      getEventBlockHeight({
        duration: event.duration,
        rungHeight: rungStyles.height,
        increment: schedulingIncrement
      }),
    [event.duration, rungStyles.height, schedulingIncrement]
  );

  const trueHeight = initialHeight + bottomHandle.pxDiff - topHandle.pxDiff;

  const maxHeightBottomIsDragging = (endHour - getHoursNumber(event.time)) * ONE_HOUR_HEIGHT_IN_PIXELS;

  const maxHeightTopIsDragging =
    (getHoursNumber(event.time) - startHour) * ONE_HOUR_HEIGHT_IN_PIXELS +
    (event.duration / 60) * ONE_HOUR_HEIGHT_IN_PIXELS;

  const maxHeight = dragResizeState.handle?.endsWith('top') ? maxHeightTopIsDragging : maxHeightBottomIsDragging;

  const blockHeight = Math.min(trueHeight, maxHeight);

  const [down, setDown] = useState(false);

  const getBlockRungs = () => {
    const date = getBlockDate(event.date);
    const [hours, minutes] = rungTime.split(':').map(Number);
    const start = set(date, { hours, minutes });
    const end = set(date, { hours, minutes: minutes + event.duration });
    const diff = differenceInMinutes(end, start) / schedulingIncrement;
    return rangeMap(0, diff, (i) => format(add(start, { minutes: i * schedulingIncrement }), 'HH:mm'));
  };

  const getConflictTopPos = React.useCallback(
    (conflict: SlotInstance) => {
      const date = getBlockDate(event.date);
      const [eventHours, eventMinutes] = rungTime.split(':').map(Number);
      const [conflictHours, conflictMinutes] = conflict.time.split(':').map(Number);
      const start = set(date, { hours: eventHours, minutes: eventMinutes });
      const end = set(date, { hours: conflictHours, minutes: conflictMinutes });
      const diff = differenceInMinutes(end, start) / schedulingIncrement;
      return rungStyles.height * diff;
    },
    [event.date, rungTime]
  );

  const blockRungs = React.useMemo(getBlockRungs, [event.date, event.duration]);

  const getAllConflicts = () =>
    blockRungs
      .map((t) => {
        const conflict = conflictChecker?.getConflictAt(t);
        if (conflict) {
          return conflict;
        }
      })
      .filter(Boolean);

  // TODO: Check deps one more time; maybe move it outside
  const conflicts = useMemo(
    () => getAllConflicts(),
    [calendarStates, blockRungs, event, startHour, endHour, hasEvents]
  );
  const blockStyles = classNames('border border-dashed border-indigo-600 bg-indigo-50', hasEvents && 'w-7');

  const innerStyles = classNames('z-10', { 'py-4 items-start': blockHeight > MIN_BLOCK_HEIGHT });

  const getTop = () => {
    if (
      Math.abs(getTopGapDiff() + topHandle.pxDiff) <
      (getHoursNumber(event.time) - startHour) * ONE_HOUR_HEIGHT_IN_PIXELS
    ) {
      return getTopGapDiff() + topHandle.pxDiff;
    } else if (getTopGapDiff() + topHandle.pxDiff < 0) {
      return -1 * (getHoursNumber(event.time) - startHour) * ONE_HOUR_HEIGHT_IN_PIXELS;
    } else {
      return getTopGapDiff() + topHandle.pxDiff;
    }
  };

  return (
    <Draggable isDragDisabled={disallowEdit} draggableId={String(event.id)} index={idx}>
      {(provided, snapshot) => (
        <Popper
          content={({ closePopper }) => (
            <HoveringSlotControls
              ref={mergeRefs([trapRef, dropdownRef])}
              slot={event}
              date={date}
              closePopper={closePopper}
              blockMode='availability'
              studyDuration={studyDuration}
              onChange={(newEvent: NormalizedSlot<SlotInstance>) => {
                onUpdate({ ...event.original, ...newEvent });
                track('availability_slot_updated', { place: 'availability_block_popup' });
              }}
              onRemove={handleClickDelete}
              schedulingIncrement={schedulingIncrement}
            />
          )}
          onOpen={() => requestAnimationFrame(() => dropdownRef.current?.focus())}
          onClose={() => requestAnimationFrame(() => ref.current?.focus())}
          isDisabled={disallowEdit}
          offset={[0, 10]}
          zIndex={40}
          closeOnClickOutside
        >
          <div className='w-full'>
            <div
              ref={provided.innerRef}
              className={classNames(
                'focus:outline-none group absolute flex flex-col overflow-hidden whitespace-nowrap rounded bg-green-500 text-center font-bold focus:ring',
                blockStyles
              )}
              aria-label='Availability block'
              {...provided.draggableProps}
              style={{
                height: blockHeight,
                right: hasEvents ? undefined : SLOT_HORIZONTAL_PADDING,
                left: hasEvents ? undefined : SLOT_HORIZONTAL_PADDING,
                top: getTop(),
                zIndex: focus ? 10 : 1,
                ...provided.draggableProps.style
              }}
            >
              {showConflicts &&
                conflicts.map((conflict) =>
                  conflict ? (
                    <div
                      className={cn('calendar-event-conflict-styling-v2 absolute w-full', {
                        'opacity-0': focus || down
                      })}
                      style={{
                        zIndex: 2,
                        height: getEventBlockHeight({
                          duration: conflict.duration,
                          rungHeight: rungStyles.height,
                          increment: schedulingIncrement
                        }),
                        top: getConflictTopPos(conflict)
                      }}
                    />
                  ) : null
                )}
              {!disallowEdit && canResize && (
                <div
                  className={classNames('max-h-1/4 absolute left-0 top-0 z-20 h-4 w-full', {
                    'cursor-ns-resize': !topHandle.isDragging
                  })}
                  onMouseDown={topHandle.onMouseDown}
                />
              )}
              {(!canResize || disallowEdit) && <div className='h-4' />}
              <button
                ref={ref}
                className={cn(
                  'h400 relative flex w-full flex-1 select-none whitespace-normal px-1 text-white',
                  {
                    'items-center': blockHeight === MIN_BLOCK_HEIGHT
                  },
                  innerStyles
                )}
                onMouseDown={
                  !disallowEdit
                    ? (e) => {
                        e.stopPropagation(); // prevent from re-creating event
                        setDown(true);
                      }
                    : undefined
                }
                onMouseUp={
                  !disallowEdit
                    ? (e) => {
                        if (!snapshot.isDragging && down) {
                          setFocus(!focus);
                        }
                        setDown(false);
                      }
                    : undefined
                }
                {...provided.dragHandleProps}
              />
              {!disallowEdit && canResize && (
                <div
                  className={classNames('max-h-1/4 absolute bottom-0 left-0 z-20 h-4 w-full', {
                    'cursor-ns-resize': !bottomHandle.isDragging
                  })}
                  onMouseDown={bottomHandle.onMouseDown}
                />
              )}
            </div>
          </div>
        </Popper>
      )}
    </Draggable>
  );
};
