import * as React from 'react';

import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import Skeleton from 'react-loading-skeleton';

import { PlaceholderColumn } from './PlaceholderColumn';

type Props = {
  onDragStart: (start: DragStart) => void;
  onDragEnd: (result: DropResult) => void;
  showSkeleton?: boolean;
  showPlaceholder?: boolean;
  placeholderColumnText?: string;
  onCreateColumn: () => void;
};

export const ColumnBoard: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onDragStart,
  onDragEnd,
  showSkeleton,
  showPlaceholder,
  placeholderColumnText = '',
  onCreateColumn
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div className='px-page mt-4 flex h-full w-full space-x-6 overflow-x-auto'>
        {showSkeleton && (
          <div className='relative flex h-full w-full max-w-sm flex-shrink-0 flex-col'>
            <Skeleton className='h-12 bg-gray-50' />
          </div>
        )}

        {children}

        {showPlaceholder && <PlaceholderColumn text={placeholderColumnText} onClickCreate={onCreateColumn} />}
      </div>
    </DragDropContext>
  );
};
