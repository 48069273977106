import * as React from 'react';
import { useMemo } from 'react';

import { api } from '@api/reduxApi';
import { Button, SlideOut, Spinner } from '@components/common';
import { BookingDetailsForm } from '@components/shared/BookingDetailsForm';
import { mergeDeep } from '@helpers/mergeDeep';

import { filterUnchangedValues } from './filterUnchangedValues';
import { useProfileSlideoutNavigate } from './routerContext';

export type Props = {
  onClose: () => void;
  participation: Participation;
  changes: DeepPartial<CalendarBookingDetail>;
  setChanges: React.Dispatch<React.SetStateAction<DeepPartial<CalendarBookingDetail>>>;
};

export const EditBookingDetailsSlideout: React.FC<React.PropsWithChildren<Props>> = ({
  onClose,
  participation,
  changes,
  setChanges
}) => {
  const navigate = useProfileSlideoutNavigate();
  const onBack = () => navigate('profile');

  const { data: initialBookingDetail, isLoading: isLoadingBookingDetail } =
    api.useGetParticipationCalendarBookingDetailsQuery({
      id: participation.id
    });
  const { data: study, isLoading: isLoadingStudy } = api.useGetSimpleStudyQuery(participation.project_id);
  const isLoading = isLoadingBookingDetail || isLoadingStudy;

  const handleClickReview = () => navigate('review_booking_detail');
  const onChange = (newChanges: DeepPartial<CalendarBookingDetail>) => {
    setChanges(filterUnchangedValues(mergeDeep(changes, newChanges), initialBookingDetail));
  };
  const bookingDetail = useMemo<CalendarBookingDetail | null>(
    () => (initialBookingDetail ? mergeDeep(initialBookingDetail, changes) : null),
    [initialBookingDetail, changes]
  );
  const changesEmpty = Object.keys(changes).length === 0;

  return (
    <>
      <SlideOut
        title='Edit booking detail'
        onClose={onClose}
        onBack={onBack}
        size='2xl'
        renderFooter={() => (
          <div className='flex items-center justify-end space-x-4'>
            <Button primary onClick={handleClickReview} disabled={changesEmpty}>
              Review changes
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </div>
        )}
      >
        <div className='px-6 pb-12'>
          {(!study || !bookingDetail) && isLoading && (
            <div className='mx-auto py-12'>
              <Spinner className='h-10 w-10' />
            </div>
          )}
          {study && bookingDetail && (
            <BookingDetailsForm
              study={study}
              editMode
              bookingDetail={bookingDetail}
              participationId={participation.id}
              onChange={onChange}
              onClose={onClose}
            />
          )}
        </div>
      </SlideOut>
    </>
  );
};
