import React, { forwardRef, useState } from 'react';

import cn from 'classnames';
// libs
import { Controller, FieldErrors } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { ValidationRules } from 'react-hook-form/dist/types/validator';
import { Link } from 'react-router-dom';

// styles
import { NO_OUTLINE } from '@components/CandidateAttrs';
// components
import { Input, Text } from '@components/common';
import { ErrorSvg } from '@components/svgs';

type Props = {
  control: Control;
  label?: string;
  name: string;
  errors: FieldErrors;
  rules?: ValidationRules;
  onChange?: (value: string) => void;
  id?: string;
  defaultValue?: string;
  handleKeyDown: (e, id) => void;
  styles: React.CSSProperties;
  autoFocus?: boolean;
};

export const ControlledInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      onChange: onInputChange,
      control,
      styles,
      defaultValue = '',
      label,
      name,
      errors,
      rules,
      id,
      handleKeyDown,
      ...rest
    },
    ref
  ) => {
    const [focused, setFocused] = useState<boolean | null>(null);

    const onKeyDown = (e) => {
      handleKeyDown(e, id);
    };

    const inputClass = cn('group border-b w-full relative', styles.row, {
      'border-red-600': errors[name],
      'border-gray-200': !errors[name],
      'border-indigo-600': focused && !errors[name]
    });

    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ onChange, value }) => (
          <tr className={inputClass}>
            <td className={styles.label}>{label}</td>
            <td className={styles.value}>
              <div className=''>
                <Input
                  id={id}
                  ref={ref}
                  className='focus:outline-none active:outline-none no-autocomplete h-full w-full border-0 px-4 py-2.5 text-sm'
                  name={name}
                  placeholder='Enter…'
                  error={errors[name]}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  onChange={(v) => {
                    onInputChange?.(v);
                    onChange(v);
                  }}
                  value={value}
                  style={NO_OUTLINE}
                  onKeyDown={onKeyDown}
                  {...rest}
                />
              </div>
            </td>
            {errors[name] && errors[name].type !== 'manual' && (
              <Text color='red-600' h='400' className='col-span-3 flex items-center truncate'>
                <ErrorSvg className='mr-2' />
                {errors[name].message}
              </Text>
            )}

            {errors[name] && errors[name].type === 'manual' && (
              <Text color='red-600' h='400' className='col-span-3 flex items-center truncate'>
                <ErrorSvg className='mr-2' />
                Candidate already exists.
                <Link className='ml-2 text-red-600 underline' to={`/candidates/${errors[name].message}`}>
                  See profile.
                </Link>
              </Text>
            )}
          </tr>
        )}
      />
    );
  }
);
