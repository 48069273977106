import * as React from 'react';

import { Droppable } from 'react-beautiful-dnd';

import { Button, Spinner, Text } from '@components/common';

import { SVG } from './SVG';

type Props = {
  loading?: boolean;
  text: string;
  onClickCreate: () => void;
};
export const PlaceholderColumn: React.FC<React.PropsWithChildren<Props>> = ({ loading, text, onClickCreate }) => (
  <div className='relative flex h-full w-full max-w-sm flex-shrink-0 flex-col'>
    <Button disabled={loading} className='mb-2' icon='plus' fullWidth onClick={onClickCreate}>
      Create a new group
    </Button>
    <Droppable droppableId='new_group'>
      {(provided) => (
        <div
          className='flex flex-1 flex-col items-center justify-center rounded-sm border border-dashed border-gray-400 bg-gray-50 p-4'
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {loading && <Spinner className='w-8' />}
          {!loading && (
            <>
              <SVG className='mb-6' />
              <Text h='400'>{text}</Text>
            </>
          )}
        </div>
      )}
    </Droppable>
  </div>
);
