import * as React from 'react';
import { useEffect, useState } from 'react';

import cn from 'classnames';

import { api } from '@api/reduxApi';
import { Button, Text } from '@components/common';
import { WindowHeader, WindowLayout, WindowLayoutBody } from '@components/layouts/WindowLayout';
import { getBackgroundColor } from '@components/tags/colors';
import { track } from '@components/tracking';
import { usePermission } from '@hooks/usePermission';

import { DeleteTagModal } from './GroupColumn/DeleteTagModal';
import { MoveTagDropdown } from './MoveTagDropdown';
import { PermissionsMenu } from './PermissionsMenu';
import { TagManager } from './TagManager';
import { TagMerge } from './TagMerge';

type Props = {
  onClickBack?: () => void;
  fullScreen?: boolean;
  withPaddings?: boolean;
};

const PageLayout = ({ children, fullScreen, withPaddings = true }) => {
  return fullScreen ? (
    <div className='fixed inset-0 z-30 bg-white'>
      <WindowLayout>{children}</WindowLayout>
    </div>
  ) : (
    <div className={cn('min-h-192 flex h-full flex-col', { 'px-page py-gutter': withPaddings })}>
      <div className='flex flex-1 flex-col overflow-hidden rounded-md border border-gray-200 bg-white'>{children}</div>
    </div>
  );
};

export const TagManagerPage: React.FC<React.PropsWithChildren<Props>> = ({
  withPaddings,
  fullScreen = true,
  onClickBack
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Array<Tag>>([]);

  const { data: tags } = api.useGetTagsQuery();
  const { data: tagGroups } = api.useGetTagGroupsQuery();
  const [destroyTags, { isLoading: isDeletingTags }] = api.useBulkDestroyTagsMutation();

  const canManage = usePermission('manageTags')();

  const handleDelete = () => {
    const taggingCount = selectedTags.reduce((m, { taggings }) => m + taggings.length, 0);

    if (taggingCount > 0) {
      setShowDeleteModal(true);
    } else {
      destroyTags({ ids: selectedTags.map(({ id }) => id) });
    }
  };

  useEffect(() => {
    track('viewed_tag_manager', {});
  }, []);

  return (
    <>
      {showDeleteModal && (
        <DeleteTagModal
          tags={selectedTags}
          onClose={() => setShowDeleteModal(false)}
          onSubmit={() => setShowDeleteModal(false)}
        />
      )}
      <PageLayout fullScreen={fullScreen} withPaddings={withPaddings}>
        <WindowHeader>
          <div className='flex flex-1 items-center space-x-6'>
            {fullScreen && (
              <Button
                icon='chevronLeft'
                onClick={() => {
                  if (onClickBack) {
                    onClickBack();
                  } else {
                    history.go(-1);
                  }
                }}
              >
                Back
              </Button>
            )}
            <Text h='800'>Tag manager</Text>
          </div>
          {selectedTags.length === 0 && <PermissionsMenu />}
          {selectedTags.length > 0 && canManage && (
            <TagMerge selectedTags={selectedTags} allTags={tags ?? []} onSuccess={() => setSelectedTags([])} />
          )}
          <div className='ml-4'>
            {selectedTags.length !== 0 && canManage && (
              <MoveTagDropdown selectedTags={selectedTags} setSelectedTags={setSelectedTags} tagGroups={tagGroups} />
            )}
            {selectedTags.length !== 0 && canManage && (
              <Button name='close' onClick={handleDelete} icon='trash' className='mr-4' loading={isDeletingTags}>
                Delete
              </Button>
            )}
          </div>
        </WindowHeader>
        <WindowLayoutBody className='h-full overflow-y-auto'>
          <div className='h-full'>
            <TagManager canManage={canManage} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
          </div>
        </WindowLayoutBody>
      </PageLayout>
    </>
  );
};
