import React, { PropsWithChildren, useEffect } from 'react';

import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';

import { DropRegions } from './components/DropRegions';
import { Sheet } from './components/Sheet';
import { useDrawerContext } from './hooks/useDrawerContext';
import { useModifiers } from './hooks/useModifiers';
import { useSwipeVelocity } from './hooks/useSwipeVelocity';
import { POINTER_SENSOR_ACTIVATION, Region, TOUCH_SENSOR_ACTIVATION } from './constants';

interface Props {
  isExpanded?: boolean;
  header: (isExpanded: boolean) => JSX.Element;
  onChange?: (isExpanded: boolean) => void;
}

export const Drawer = ({ children, isExpanded, header, onChange }: PropsWithChildren<Props>) => {
  const { setIsExpanded } = useDrawerContext();

  const modifiers = useModifiers();
  const { onSwipe, velocity } = useSwipeVelocity();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: POINTER_SENSOR_ACTIVATION
    }),
    useSensor(TouchSensor, {
      activationConstraint: TOUCH_SENSOR_ACTIVATION
    }),
    useSensor(KeyboardSensor)
  );

  const handleOnDragEnd = ({ over }: DragEndEvent) => {
    if (velocity > 500) {
      setIsExpanded(true);
      onChange?.(true);
    } else if (over) {
      const isExpanded = over.id === Region.Expand;

      setIsExpanded(isExpanded);
      onChange?.(isExpanded);
    }
  };

  const handleOnDragMove = ({ delta }: DragMoveEvent) => {
    onSwipe(delta);
  };

  useEffect(() => {
    setIsExpanded(Boolean(isExpanded));
  }, [isExpanded]);

  return (
    <DndContext
      autoScroll={false}
      modifiers={modifiers}
      sensors={sensors}
      onDragMove={handleOnDragMove}
      onDragEnd={handleOnDragEnd}
    >
      <div className='pointer-events-none fixed inset-0 flex items-end justify-center overflow-hidden'>
        <Sheet
          header={header}
          className='pointer-events-auto absolute z-10 flex w-96 max-w-full flex-col overflow-hidden rounded-tl-2xl rounded-tr-2xl border border-gray-200 shadow-lg'
        >
          {children}
        </Sheet>

        <DropRegions />
      </div>
    </DndContext>
  );
};
