import * as React from 'react';
import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Button, Modal } from '@components/common';
import { useToaster } from '@stores/toaster';

import * as toasts from '../toasts';

interface Props {
  open: boolean;
  onClose: (e?: React.MouseEvent) => void;
  onSubmit?: () => void;
  id: number;
  title: string;
  kind: TemplateKind;
}
export const DeleteTemplateModal: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  onSubmit,
  id,
  kind
}) => {
  const showToast = useToaster();
  const navigate = useNavigate();

  const useDestroyMutation: typeof api.useDestroyInterviewTemplateMutation = {
    EmailTemplate: api.useDestroyEmailTemplateMutation,
    InterviewTemplate: api.useDestroyInterviewTemplateMutation,
    SurveyTemplate: api.useDestroySurveyTemplateMutation
  }[kind];
  const [deleteInterviewTemplate, { isLoading, isSuccess }] = useDestroyMutation();

  async function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    deleteInterviewTemplate(id);
  }

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successDelete());
      onClose();
      if (onSubmit) {
        onSubmit();
      } else {
        navigate(
          {
            EmailTemplate: '/study_templates/emails',
            SurveyTemplate: '/study_templates/surveys',
            InterviewTemplate: '/study_templates/interviews'
          }[kind]
        );
      }
    }
  }, [isSuccess]);

  if (!open) {
    return null;
  }

  return (
    <div className='text-left'>
      <Modal
        title='Delete template'
        renderFooter={() => (
          <>
            <Button disabled={isLoading} onClick={onClose}>
              Don’t delete
            </Button>
            <Button disabled={isLoading} onClick={handleClick} danger>
              Confirm & delete
            </Button>
          </>
        )}
        icon='danger'
        onClose={onClose}
      >
        Are you sure you want to delete the template? It will be removed from all instances.
      </Modal>
    </div>
  );
};
