import { StudyTabKey } from '@components/StudiesApp/components/StudyPublished/helpers/buildTabs';

export const TAB_LABELS: Record<StudyTabKey, string> = {
  report: 'Report',
  participants: 'Participants',
  recruitment_requests: 'Recruitment requests',
  stats: 'Stats',
  repository: 'Repository',
  interviews: 'Interviews',
  screener: 'Screener',
  calendar: 'Calendar',
  survey: 'Survey',
  test: 'Test',
  plan: 'Plan',
  emails: 'Emails',
  pages: 'Pages',
  task: 'Task',
  tags: 'Tags',
  insights: 'Insights',
  overview: 'Overview',
  synthesis: 'Synthesis',
  automation: 'Automation',
  notifications: 'Notifications',
  interview_guide: 'Interview guide',
  incentives: 'Incentives',
  screener_responses: 'Screener responses',
  summary: 'Summary'
};

export const PANEL_TAB_LABELS: Partial<Record<StudyTabKey, string>> = {
  survey: 'Signup form'
};
