import * as React from 'react';
import { useState } from 'react';

import { ProfileSlideout, ProfileSlideoutProps } from '@components/RepoSessionApp/ProfileSlideout';
import { CalendarEventSlideOut } from '@components/shared/CalendarEventSlideOut';
import { Route, StateRouter } from '@components/StateRouter';

import { EditBookingDetailsSlideout } from './EditBookingDetailsSlideout';
import { ReviewBookingDetailsSlideout } from './ReviewBookingDetailsSlideout';
import { profileSlideoutRouterContext } from './routerContext';

type Props = ProfileSlideoutProps;

export const ProfileSlideoutRouter: React.FC<React.PropsWithChildren<Props>> = ({ ...slideoutProps }) => {
  const [changes, setChanges] = useState<DeepPartial<CalendarBookingDetail>>({});

  const clearChanges = () => setChanges({});

  return (
    <StateRouter context={profileSlideoutRouterContext} initialPath='profile'>
      <Route path='profile' element={<ProfileSlideout {...slideoutProps} />} />
      <Route
        path='calendar_events/:id'
        element={({ id }: { id: number }) => <CalendarEventSlideOut {...slideoutProps} id={id} />}
      />
      {slideoutProps.activeParticipation && (
        <Route
          path='edit_booking_detail'
          element={
            <EditBookingDetailsSlideout
              changes={changes}
              setChanges={setChanges}
              participation={slideoutProps.activeParticipation}
              onClose={slideoutProps.onClose}
            />
          }
        />
      )}
      {slideoutProps.activeParticipation && (
        <Route
          path='review_booking_detail'
          element={
            <ReviewBookingDetailsSlideout
              participation={slideoutProps.activeParticipation}
              changes={changes}
              clearChanges={clearChanges}
              onClose={slideoutProps.onClose}
            />
          }
        />
      )}
    </StateRouter>
  );
};
