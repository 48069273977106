import * as React from 'react';

import { LinkSVG } from '@components/svgs';

export const Website: QuestionCardBodyComponent = () => {
  return (
    <div className='max-w-md rounded-md border border-gray-200 px-2 py-2 text-gray-400'>
      <div className='flex items-center'>
        <LinkSVG className='mx-2 text-gray-700' />
        <span>Website</span>
      </div>
    </div>
  );
};
