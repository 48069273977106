import React from 'react';

import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';

import { Avatar, AvatarFromId, Text } from '@components/common';
import { Table } from '@components/shared/Table';
import { OptionsDropdown } from '@components/StudiesApp/components/StudiesIndex/components/OptionsDropdown';
import { getStudyProgress } from '@components/StudiesApp/components/StudiesIndex/hooks/getStudyProgress';
import { StudyStatus } from '@components/StudiesApp/components/StudyPublished/components/StudyStatus';

import { ModeratorsCell } from '../components/ModeratorsCell';

export interface DefinitionFnArgs {
  getColumnLabel: (key: string) => string;
  groupBy?: string;
  getStudyModerators?: (moderator_ids?: number[]) => TeamUser[];
  getStudyCreator?: (creator_id: number) => TeamUser | undefined;
}

export type DefinitionFnReturn = ColumnDef<SimpleStudy>[];

export type DefinitionFn = (args: DefinitionFnArgs) => DefinitionFnReturn;

export const baseStudiesColumnDefs: DefinitionFn = ({
  getColumnLabel,
  groupBy,
  getStudyModerators,
  getStudyCreator
}) => {
  const columns: DefinitionFnReturn = [
    {
      id: 'title',
      accessorKey: 'title',
      size: 200,
      minSize: 150,
      header: (props) => (
        <Table.ColumnHeader disableHide {...props}>
          {getColumnLabel('title')}
        </Table.ColumnHeader>
      ),
      cell: (props) => (
        <Table.Cell
          {...props}
          render={({ row }) => {
            const url =
              row.original.state === 'draft' ? `/studies/${row.original.id}/edit` : `/studies/${row.original.id}`;

            return (
              <Link className='truncate px-2' to={url} title={row.original.title || 'Untitled'}>
                {row.original.title || 'Untitled'}
              </Link>
            );
          }}
        />
      )
    },
    {
      id: 'completed',
      minSize: 50,
      size: 100,
      accessorFn: (originalRow) => `${originalRow.id}${originalRow.completed_participations_count}`,
      header: (props) => (
        <Table.ColumnHeader disableSort {...props}>
          {getColumnLabel('completed')}
        </Table.ColumnHeader>
      ),
      cell: (props) => (
        <Table.Cell
          {...props}
          render={({ row }) => {
            const progress = getStudyProgress(row.original as Study);

            if (progress.show) {
              return (
                <div className='flex flex-1 items-center'>
                  <div className='mr-2 w-full'>{progress.renderProgressBar?.()}</div>
                  <Text h='200' color='gray-500' uppercase className='flex-1 text-right'>
                    {progress.text}
                  </Text>
                </div>
              );
            }
            return <></>;
          }}
        />
      )
    },
    {
      id: 'options',
      accessorFn: (originalRow) => `${originalRow.id}${originalRow.id}`,
      size: 50,
      header: (props) => (
        <Table.ColumnHeader isStatic {...props}>
          {getColumnLabel('options')}
        </Table.ColumnHeader>
      ),
      cell: (props) => (
        <Table.Cell
          {...props}
          render={({ row }) => (
            <div className='flex w-full justify-end p-1 px-3'>
              <OptionsDropdown study={row.original} />
            </div>
          )}
        />
      )
    },
    {
      id: 'creator_id',
      accessorFn: (originalRow) => getStudyCreator?.(originalRow.creator_id),
      sortingFn: (a, b) =>
        (getStudyCreator?.(a?.original.creator_id)?.name || '').localeCompare(
          getStudyCreator?.(b?.original.creator_id)?.name || ''
        ),
      header: (props) => <Table.ColumnHeader {...props}>{getColumnLabel?.('creator_id')}</Table.ColumnHeader>,
      cell: (props) => (
        <Table.Cell
          {...props}
          render={({ value: creator }) => (
            <div className='flex flex-nowrap items-center truncate'>
              {creator && <Avatar className='truncate px-2' noTippy user={creator} />}
              {creator?.name && (
                <Text h='400' className='truncate'>
                  {creator?.name}
                </Text>
              )}
            </div>
          )}
        />
      )
    },
    {
      id: 'moderator_ids',
      accessorFn: (originalRow) => getStudyModerators?.(originalRow.moderator_ids),
      header: (props) => (
        <Table.ColumnHeader disableSort {...props}>
          {getColumnLabel?.('moderator_ids')}
        </Table.ColumnHeader>
      ),
      cell: (props) => (
        <Table.Cell render={(props) => <ModeratorsCell moderators={props.value} {...props} />} {...props} />
      )
    }
  ];

  if (groupBy !== 'state') {
    columns.push({
      id: 'state',
      minSize: 50,
      size: 100,
      accessorKey: 'state',
      header: (props) => <Table.ColumnHeader {...props}>{getColumnLabel('state')}</Table.ColumnHeader>,
      cell: (props) => (
        <Table.Cell
          {...props}
          render={({ row }) => <StudyStatus className='truncate px-2' status={row.original.state} />}
        />
      )
    });
  }

  if (groupBy !== 'style') {
    columns.push({
      id: 'style',
      minSize: 50,
      size: 100,
      accessorKey: 'label',
      header: (props) => <Table.ColumnHeader {...props}>{getColumnLabel('style')}</Table.ColumnHeader>,
      cell: (props) => <Table.Cell type='text' {...props} />
    });
  }

  if (groupBy !== 'owner_id') {
    columns.push({
      id: 'owner_id',
      accessorFn: (originalRow) => `${originalRow.owner?.name}`,
      header: (props) => <Table.ColumnHeader {...props}>{getColumnLabel('owner_id')}</Table.ColumnHeader>,
      cell: (props) => (
        <Table.Cell
          {...props}
          render={({ row }) => (
            <div className='flex flex-nowrap items-center truncate'>
              <AvatarFromId className='truncate px-2' noTippy userId={row.original.owner_id} />
              {row.original.owner?.name && (
                <Text h='400' className='truncate'>
                  {row.original.owner?.name}
                </Text>
              )}
            </div>
          )}
        />
      )
    });
  }

  return columns;
};
