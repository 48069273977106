import * as React from 'react';

import { api } from '@api/reduxApi';
import { Button, Modal, ModalHeading, Spinner, Text } from '@components/common';
import { ExternalLinkSVG } from '@components/svgs';

interface Props {
  isOpen: boolean;
  highlightUuid: string;
  onClose: () => void;
  onConfirm?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const DeleteClipModal: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  highlightUuid,
  onClose,
  onConfirm
}) => {
  const { data, isLoading } = api.useGetClipHighlightReelsQuery({ highlightUuid }, { skip: !highlightUuid });

  const anyHighlightReels = data && data.length > 0;

  if (!isOpen) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeading className='mb-4'>Delete a highlight</ModalHeading>
      <section className='mb-6 mt-4'>
        <Text as='span' h='400'>
          Are you sure you want to delete the highlight?
        </Text>
        {anyHighlightReels && (
          <Text as='span' h='400'>
            &nbsp;It will also be removed from the following highlight reels:
          </Text>
        )}
        {anyHighlightReels && (
          <div className='mt-3'>
            <ul className='ml-4 list-disc'>
              {data.map((highlightReel) => (
                <li key={highlightReel.id} className='h400 leading-loose'>
                  <a href={`/highlight_reels/${highlightReel.token}`} target='_blank'>
                    {highlightReel.title || 'Untitled highlight reel'}
                    <ExternalLinkSVG className='ml-2 inline' />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>
      <div className='flex'>
        <Button data-testid='confirm-delete' disabled={isLoading} onClick={onConfirm} danger>
          {isLoading && <Spinner className='mr-2 h-4 w-4' />}
          Confirm &amp; delete
        </Button>
        <Button className='ml-4' onClick={onClose} link>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
