import React, { useCallback, useState } from 'react';

import { EditConfirmationModal } from '@components/shared/GridTable/components';

import { CellProps } from '../../Table/types';

export const CellWithConfirmation = <D extends Record<string, any>, O extends string = string>(
  props: Omit<CellProps<D, O>, 'getValue' | 'renderValue' | 'render' | 'options'>
) => {
  const { type, table, row, column, cell } = props;

  const initialValue = cell.getValue() as string;

  const [value, setValue] = useState<string>(initialValue || '');

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [editMode, setEditMode] = useState<boolean>(false);

  const handleOnBlur = () => {
    if (value !== initialValue) {
      setModalOpen(true);
      return;
    }

    setEditMode(false);
  };

  const renderEdit = useCallback(() => {
    switch (type) {
      case 'text':
        return (
          <input
            type={type}
            value={value ?? ''}
            onChange={(e) => setValue(e.target.value)}
            onBlur={handleOnBlur}
            onKeyDown={(e) => e.key === 'Enter' && handleOnBlur()}
            placeholder='Enter…'
            className='h400 w-full'
            autoFocus
          />
        );

      default:
        return null;
    }
  }, [value]);

  return (
    <>
      {editMode ? (
        <div className='w-full'>{renderEdit()}</div>
      ) : (
        <div className='flex h-11 w-full items-center px-2' onClick={() => setEditMode(true)}>
          <span className='max-w-full truncate'>{value}</span>
        </div>
      )}

      {modalOpen && (
        <EditConfirmationModal
          onClose={() => {
            setModalOpen(false);
            setValue(initialValue);
            setEditMode(false);
          }}
          previousValue={initialValue}
          currentValue={value}
          candidateName={row.original.name || 'Unnamed candidate'}
          attrName={column.id}
          onConfirm={() => {
            table.options.meta?.updateCellData?.({ row, columnId: column.id, value });
            setEditMode(false);
            setModalOpen(false);
          }}
        />
      )}
    </>
  );
};
