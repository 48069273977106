import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { Button, Input, Modal, Radio } from '@components/common';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { BookabilityAlerts } from '@components/shared/BookabilityAlerts';
import { MeetingProvidersDropdown } from '@components/shared/MeetingProvidersDropdown';
import { useToaster } from '@components/stores/toaster';
import * as toasts from '@components/StudiesApp/components/StudyDraft/toasts';
import { StudyLocationInput } from '@components/StudyLocationInput';
import { useStudyBookability } from '@components/StudyMessages/hooks/useStudyBookability';
import { InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { useDisabledFeatures } from '@hooks/useDisabledFeatures';
import { VideoUrl } from '@lib/video-url/VideoUrl';

import { useUpdateStudyCalendarMutation } from '../api';

interface Props {
  disabled?: boolean;
  user: User;
  study: Study;
  onChange: (value: Partial<Study>) => void;
  addDebounce?: boolean;
  calendar?: StudyCalendar;
  dataTestId?: string;
}

const DEBOUNCE_RATE = process.env.NODE_ENV === 'test' ? 10 : 300;

export const VideoURLInput: React.FC<React.PropsWithChildren<Props>> = ({
  calendar,
  disabled,
  user,
  study,
  onChange,
  addDebounce,
  dataTestId
}) => {
  const { owner } = study;
  const [videoUrlType, setVideoUrlType] = useState<Study['video_url_type'] | null>(
    study.video_url_type === 'manual' && !study.video_url ? null : study.video_url_type
  );
  const [videoUrl, setVideoUrl] = useState<VideoUrl>(new VideoUrl(study.video_url));
  const [liveStreamEnabled, setLiveStreamEnabled] = useState<boolean>(calendar?.live_stream_enabled || false);
  const [liveStreamModal, setLiveStreamModal] = useState<boolean>(false);
  const [updateStudyCalendar, { isError: calendarUpdateError }] = useUpdateStudyCalendarMutation();
  const debouncedOnChange = useDebouncedCallback(onChange, DEBOUNCE_RATE);

  const {
    bookability,
    startPolling,
    refetch: recheck
  } = useStudyBookability({
    studyId: study.id,
    event: 'conference'
  });

  const showToast = useToaster();

  const disabledFeatures = useDisabledFeatures();

  useEffect(() => {
    if (calendarUpdateError) {
      showToast(toasts.failedUpdate());
    }
  }, [calendarUpdateError]);

  useEffect(() => {
    setLiveStreamEnabled(calendar?.live_stream_enabled || false);
  }, [calendar?.live_stream_enabled]);

  useEffect(() => {
    recheck();
  }, [study.video_url, study.video_url_type]);

  const onUrlChange = (v: string) => {
    setVideoUrl(new VideoUrl(v));
    addDebounce ? debouncedOnChange.callback({ video_url: v }) : onChange({ video_url: v });
  };

  const handleOptionClick = (option: MeetingProvider) => {
    updateLiveStreamEnabled(false);
    setVideoUrlType(option.video_url_type);

    let videoUrl: string | null = null;

    if (option.video_url_type === 'manual') {
      videoUrl = study.video_url || owner?.default_video_url || null;
    }

    onChange({ video_url_type: option.video_url_type, video_url: videoUrl });
  };

  const updateLiveStreamEnabled = (v: boolean) => {
    updateStudyCalendar({
      studyId: study.id,
      calendar: { live_stream_enabled: v }
    });

    setLiveStreamEnabled(v);
  };

  const liveStreamAvailable = useMemo(() => {
    return (
      videoUrlType === 'zoom' ||
      videoUrlType === 'webex' ||
      videoUrlType === 'microsoft_teams' ||
      videoUrlType === 'google_meet' ||
      (videoUrlType === 'manual' && videoUrl.isSupported())
    );
  }, [videoUrlType, videoUrl]);

  return (
    <>
      <MeetingProvidersDropdown
        disabled={disabled}
        user={user}
        study={study}
        value={videoUrlType}
        onClick={handleOptionClick}
      />

      {videoUrlType === 'manual' && (
        <Input
          disabled={disabled}
          name='video_url'
          className='mt-2'
          placeholder='e.g Zoom, Hangouts'
          value={videoUrl.toString() || ''}
          onChange={onUrlChange}
          size='lg'
          dataTestId={dataTestId}
        />
      )}
      {videoUrlType === 'in_person' && <StudyLocationInput study_id={study.id} />}
      {liveStreamModal && (
        <Modal
          size='md'
          onClose={() => setLiveStreamModal(false)}
          title={`${liveStreamEnabled ? 'Disable' : 'Enable'} Observer live streams`}
          renderFooter={() => (
            <>
              <Button onClick={() => setLiveStreamModal(false)}>
                Don't {liveStreamEnabled ? 'disable' : 'enable'}
              </Button>
              <Button
                primary
                onClick={() => {
                  setLiveStreamEnabled(!liveStreamEnabled);
                  updateLiveStreamEnabled(!liveStreamEnabled);
                  setLiveStreamModal(false);
                }}
              >
                Confirm & {liveStreamEnabled ? 'disable' : 'enable'}
              </Button>
            </>
          )}
        >
          This will apply for all new interviews that get scheduled on this study.
        </Modal>
      )}

      {liveStreamAvailable && !study.focus_group && (
        <div className='mt-4'>
          <Radio
            type='checkbox'
            checked={liveStreamEnabled}
            id='live_stream_enabled'
            name='live_stream_enabled'
            disabled={study.focus_group || disabledFeatures.livestream}
            onChange={() => {
              if (study.state == 'active') {
                setLiveStreamModal(true);
              } else {
                updateLiveStreamEnabled(!liveStreamEnabled);
              }
            }}
            label='Live stream for Observers'
          >
            <Tooltip
              content={
                <>
                  {disabledFeatures.livestream ? (
                    <p>Disabled at account level.</p>
                  ) : (
                    <>
                      <p className='mb-2'>
                        When checked, the Great Question Bot will join your call with the participant and it will be
                        live streamed in a separate room for observers to watch. The participant won't be able to see
                        the observers viewing the live stream.
                      </p>
                      <p>
                        The bot will join the call at the interview time and wait 15 minutes, if the call does not start
                        the bot will leave the call.
                      </p>
                    </>
                  )}
                </>
              }
            >
              <InfoCircleIcon className='h-4 w-4' />
            </Tooltip>
          </Radio>
        </div>
      )}

      {bookability && (
        <ErrorBoundary>
          <div className='mt-4'>
            <BookabilityAlerts study={study} bookability={bookability} onStartPolling={startPolling} />
          </div>
        </ErrorBoundary>
      )}
    </>
  );
};
