import React from 'react';

import type { Option } from '@components/common';

import { DropdownProps } from '../../types';

import { Choose } from './Choose';

type Props<D = any> = DropdownProps<D, string[]> & {
  teams: Team[];
};

export const ContactAccess: React.FC<React.PropsWithChildren<Props>> = ({ teams, onChange, ...props }) => {
  const options: Option[] = [
    { label: 'Anyone on the workspace', value: 'all' },
    ...teams.map((team) => ({ label: team.name, value: String(team.id) })),
    { label: 'No one', value: 'none' }
  ];

  const value = props.value?.map(String) || [];

  const handleChange: typeof onChange = ({ value }) => {
    const lastAdded = value?.[value.length - 1];

    if (lastAdded === 'all') return onChange({ value: ['all'], operator: 'includes_all' });

    if (lastAdded === 'none') return onChange({ value: ['none'], operator: 'includes_any' });

    const idsOnly = value?.filter((v) => !['all', 'none'].includes(v));

    return onChange({ value: idsOnly });
  };

  return <Choose {...props} onChange={handleChange} value={value} options={options} />;
};
