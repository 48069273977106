import React, { HTMLAttributes, PropsWithChildren, PropsWithRef, useEffect, useRef, useState } from 'react';

import { usePopper } from 'react-popper';
import { VirtualElement } from '@popperjs/core';

interface Props extends PropsWithRef<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> {
  position: {
    x: number;
    y: number;
  };
}

export const Thumbnail = ({ position, children, ...rest }: Props) => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const rect = useRef<VirtualElement>();
  const { styles, attributes, update } = usePopper(rect.current, element, { placement: 'top' });

  useEffect(() => {
    rect.current = {
      getBoundingClientRect: () =>
        ({
          width: 0,
          height: 0,
          left: position.x,
          top: position.y - 12,
          x: position.x,
          y: position.y
        }) as DOMRect
    };
    update?.();
  }, [position.x, position.y]);

  return (
    <div ref={setElement} style={styles.popper} {...attributes.popper} {...rest}>
      {children}
    </div>
  );
};
