import React, { useState } from 'react';

import cn from 'classnames';
import pluralize from 'pluralize';

import { Button, Option, PopperDropdown, PopperDropdownButton, Text } from '@components/common';
import { RadioInput } from '@components/common/RadioGroup';
import { PastParticipation as PastParticipationSVG } from '@components/StudiesApp/components/StudyPublished/pages/ExternalCandidatesRequest/components/svgs';
import { ChevronDownSVG, InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

interface Props {
  options: Option[];
  disabled?: boolean;
  onChange: (v: ExternalCandidatesFilterPastParticipation) => void;
  value: ExternalCandidatesFilterPastParticipation;
  accountName: string;
}

export const PastParticipations: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  options,
  disabled,
  onChange,
  accountName
}) => {
  const RADIO_OPTIONS = ['-1', 'custom', ''];

  const [selectedOption, setSelectedOption] = useState<string>(
    RADIO_OPTIONS.includes(value) ? value : value ? 'custom' : '-1'
  );
  const [selectedMonth, setSelectedMonth] = useState<string>(value && !RADIO_OPTIONS.includes(value) ? value : '1');
  const [open, setOpen] = useState(false);

  const renderValues = () => {
    const duration = pluralize('month', Number(selectedMonth), true);

    switch (selectedOption) {
      case '-1':
        return `Has never completed a study with ${accountName}`;
      case 'custom':
        return `${duration} (Has not completed a study with ${accountName} in the last ${duration})`;
      default:
        return 'N/A';
    }
  };
  const monthOptions = options.filter(({ value }) => !RADIO_OPTIONS.includes(value));

  const handleChangeOption = (v) => {
    setSelectedOption(v);
    onChange((v === 'custom' ? selectedMonth : v) as ExternalCandidatesFilterPastParticipation);
  };

  const handleChangeMonth = (v) => {
    setSelectedOption('custom');
    setSelectedMonth(v);
    onChange(v);
  };

  const renderMonthOption = () => {
    return (
      <div className='flex flex-wrap items-center'>
        <Text as='span' h='400' className='mr-2'>
          Has not completed a study with {accountName} in the last
        </Text>
        <div className='mr-2 inline'>
          <PopperDropdown
            aria-label='Month options'
            medium
            disabled={disabled}
            popperProps={{ placement: 'bottom-start', syncWidth: true }}
            text={selectedMonth}
            dropdownClass='w-full'
          >
            {monthOptions.map((option, i) => (
              <PopperDropdownButton key={i} onClick={() => handleChangeMonth(option.value)}>
                {option.label}
              </PopperDropdownButton>
            ))}
          </PopperDropdown>
        </div>
        <Text as='span' h='400'>
          month(s)
        </Text>
      </div>
    );
  };

  return (
    <div className='flex items-start border-b border-gray-200 py-4'>
      <div className='flex flex-shrink-0 items-center justify-center p-1' onClick={() => setOpen(!open)}>
        <PastParticipationSVG />
      </div>
      <div className='ml-4 flex-1 flex-shrink-0'>
        <div className='flex flex-1 items-center justify-between'>
          <div onClick={() => setOpen(!open)}>
            <Text bold>Past participation</Text>
            {!open && (
              <Text h='400' className='mt-1'>
                {renderValues()}
              </Text>
            )}
            {open && (
              <div className='flex flex-nowrap items-center space-x-2'>
                <Text h='400' className='mt-1'>
                  Allow participants to apply only if they meet the following criteria.
                </Text>
                <Tooltip content='The participant must have been completed and sent their incentive to count as a past participant. Past participation filtering will take effect within 4 hours of their incentive being sent.'>
                  <InfoCircleIcon className='h-4 w-4' />
                </Tooltip>
              </div>
            )}
          </div>
          <Button
            className='xx-toggle-past-participation rounded-full pb-3 pl-3 pr-3 pt-3'
            text
            aria-label='Past participation'
            onClick={() => setOpen(!open)}
          >
            <ChevronDownSVG className={cn('h-4 w-4 transform', { ['rotate-180']: open })} />
          </Button>
        </div>
        {open && (
          <div className={`mt-6 -space-y-px rounded-md bg-white ${disabled ? 'opacity-75' : ''}`}>
            {RADIO_OPTIONS.map((v, i) => {
              const option = options.find(({ value }) => value === v);

              return (
                <div key={i} className='w-full'>
                  <label
                    className={cn(
                      'flex w-full flex-row items-start space-x-3',
                      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                      i == 2 && 'pt-2.5',
                      i == 0 && 'pb-1.5'
                    )}
                  >
                    <div className={cn('flex items-center', v === 'custom' && 'h-8')}>
                      <RadioInput
                        value={v}
                        name={v}
                        disabled={disabled}
                        onChange={(e) => handleChangeOption(e.target.value)}
                        checked={selectedOption === v}
                      />
                    </div>

                    {v === 'custom' ? renderMonthOption() : <Text h='400'>{option?.label}</Text>}
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
