import React from 'react';

import { format } from 'date-fns';
import pluralize from 'pluralize';

import { buildUser } from '@api/testHelpers';
import { BuildingSvg } from '@components/AccountSwitcher/svgs';
import { Avatar, Button, Heading, Pill, Text } from '@components/common';

interface Props {
  account: GQAccount;
  onAccept: () => void;
  onDecline: () => void;
  invitationDate: Date;
}

export const WorkspaceInvitation: React.FC<React.PropsWithChildren<Props>> = ({
  account,
  onAccept,
  onDecline,
  invitationDate
}) => {
  const owner = buildUser();

  const date = format(invitationDate, 'MMM d, yyyy');

  return (
    <section className='mt-6'>
      <Heading as='h1' h='700' className='mb-2'>
        Join {account.workspace}’s workspace on Great Question
      </Heading>
      <Text as='p' h='500' color='gray-500' className='mb-6'>
        You’ll be joining as a Creator, where you can create and edit content.
      </Text>
      <div className='mb-6 flex w-full flex-row flex-nowrap items-center space-x-3 border-l-4 border-gray-200 px-3 py-2'>
        {owner && <Avatar user={owner} size='lg' />}
        <div className='flex-grow'>
          <Text h='500' bold>
            {owner?.name}
          </Text>

          <Text color='gray-500' h='400' className='-mt-1' mb='1'>
            Invited sent you an invitation to join on {date}
          </Text>
        </div>
      </div>
      <div className='flex flex-col items-center space-x-5 rounded border border-gray-200 p-6'>
        <div className='mb-6 flex w-full flex-row flex-nowrap items-center space-x-4'>
          {account.logo ? (
            <div className='flex w-16 items-center justify-center'>
              <img alt={`${account.name} logo`} src={account.logo} className='w-block' />
            </div>
          ) : (
            <div className='flex h-14 w-16 items-center justify-center rounded border border-gray-200 bg-gray-50'>
              <BuildingSvg />
            </div>
          )}
          <div className='flex-grow'>
            <div className='mb-1 flex items-center space-x-6'>
              <Text h='500'>{account.name}</Text>
              {account.sandbox && <Pill color='orange'>Demo</Pill>}
            </div>
            <Text h='400' className='pb-1.5' color='gray-500'>
              {pluralize('members', account.team.length, true)}. Created by {account.owner.name}
            </Text>
          </div>
        </div>
        <div className='flex w-full items-center justify-end space-x-4'>
          <Button onClick={onDecline}>Decline invitation</Button>

          <Button primary onClick={onAccept}>
            Accept invitation
          </Button>
        </div>
      </div>
    </section>
  );
};
