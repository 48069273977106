import React, { FC, forwardRef, HTMLAttributes, useMemo } from 'react';

import cn from 'classnames';

import { Pill, Text } from '@components/common';

import * as icons from '../../svgs';

import * as consts from './constants';

interface Props extends Omit<HTMLAttributes<HTMLButtonElement>, 'children'> {
  kind: Exclude<Study['style'], 'repo_only'> | 'focus_group';
  showBadge?: boolean | string;
  disabled?: boolean;
}

type Mapping = Record<
  Exclude<Study['style'] | 'focus_group', 'repo_only'>,
  {
    title: string;
    subtitle: string;
    icon: FC;
  }
>;

export const mapping: Mapping = {
  video_call: {
    title: consts.VIDEO_CALL_TITLE,
    subtitle: consts.VIDEO_CALL_SUBTITLE,
    icon: icons.CustomerInterviewsSVG
  },
  focus_group: {
    title: consts.FOCUS_GROUP_TITLE,
    subtitle: consts.FOCUS_GROUP_SUBTITLE,
    icon: icons.FocusGroupSVG
  },
  survey: {
    title: consts.SURVEY_TITLE,
    subtitle: consts.SURVEY_SUBTITLE,
    icon: icons.SurveysSVG
  },
  unmoderated_test: {
    title: consts.UNMODERATED_TITLE,
    subtitle: consts.UNMODERATED_SUBTITLE,
    icon: icons.UnmoderatedSVG
  },
  online_task: {
    title: consts.ONLINE_TASK_TITLE,
    subtitle: consts.ONLINE_TASK_SUBTITLE,
    icon: icons.OnlineTasksSVG
  },
  panel: {
    title: consts.PANEL_TITLE,
    subtitle: consts.PANEL_SUBTITLE,
    icon: icons.PanelSVG
  }
};

export const StudyButton = forwardRef<HTMLButtonElement, Props>(
  ({ kind, showBadge, className, disabled = false, onClick, ...rest }: Props, ref) => {
    const SvgIcon = useMemo(() => mapping[kind].icon, [kind]);

    return (
      <button
        ref={ref}
        data-testid={`study-button-${kind}`}
        aria-disabled={disabled}
        className={cn(
          'flex w-full items-center rounded border border-gray-200 bg-white px-3 py-4',
          {
            'hover:border-indigo-600': !disabled,
            'bg-gray-50 text-gray-500': disabled
          },
          className
        )}
        onClick={disabled ? undefined : onClick}
        {...rest}
      >
        <SvgIcon />
        <section className='flex flex-grow-0 flex-col items-start pl-4 text-left'>
          <Text className='mb-1' bold>
            {mapping[kind].title}
            {(showBadge === true || showBadge === 'beta') && (
              <>
                {' '}
                <Pill color='blue'>Beta</Pill>
              </>
            )}
            {showBadge === 'free_trial' && (
              <>
                {' '}
                <Pill color='blue'>Free Trial</Pill>
              </>
            )}
          </Text>
          <Text h='400' className='text-gray-500'>
            {mapping[kind].subtitle}
          </Text>
        </section>
      </button>
    );
  }
);
