import React, { forwardRef, HTMLProps, ReactElement, useState } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { Spinner } from '@components/common';

type Props = HTMLProps<HTMLInputElement> & {
  start?: ReactElement | null;
  end?: ReactElement | null;
  wrapperClassName?: string;
  isLoading?: boolean;
};

export const MediaInput = forwardRef<HTMLInputElement, Props>(
  ({ style, wrapperClassName, className, start, end, onFocus, onBlur, isLoading, ...rest }, ref) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <div
        style={style}
        className={cn(
          'tablet:text-sm flex items-stretch rounded-md border border-gray-200 bg-white transition-colors',
          isFocused && 'border-indigo-500 ring-indigo-500',
          wrapperClassName
        )}
      >
        {start && <div>{start}</div>}
        <div className='relative flex flex-1 items-center px-4'>
          <input
            ref={ref}
            className={twMerge('outline-none h-10 w-full border-none placeholder-gray-400', className)}
            onFocus={(e) => {
              setIsFocused?.(true);
              onFocus?.(e);
            }}
            onBlur={(e) => {
              setIsFocused?.(false);
              onBlur?.(e);
            }}
            {...rest}
          />
          {isFocused && isLoading && <Spinner className='absolute right-1 h-5 w-5' />}
        </div>
        {end && <div>{end}</div>}
      </div>
    );
  }
);
