import React, { forwardRef, HTMLAttributes, useEffect, useRef } from 'react';

import cn from 'classnames';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { api } from '@api/reduxApi';
import { Input, Text, Toggle, ZoomableImage } from '@components/common';
import { MarkdownEditor } from '@components/common/MarkdownEditor';
import { UploadButton } from '@components/common/UploadButton';
import { Label } from '@components/fields';
import { TrashSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

import { BLOCK_KIND_LABELS, LOCKED_BLOCK_TYPES, NON_QUESTION_KINDS } from '../constants';
import { populateFormData } from '../helpers/populateFormData';
import { useFormHelpers } from '../hooks/useFormHelpers';
import { usePatchBlocksQuery } from '../hooks/usePatchBlocksQuery';
import * as BlockableComponents from '../types/blockable-components';
import * as Enums from '../types/enums';
import * as Forms from '../types/forms';
import * as Models from '../types/models';

import { BlockableComponent } from './BlockableComponent';
import { FormExample } from './FormExample';

export const DEBOUNCE_DELAY = 500;

const DEFAULT_VALUES: Forms.Data = {
  config: null,
  description: '',
  required: false,
  title: ''
};

const DEFAULT_ENABLED_KINDS: `${Enums.Kind}`[] = [
  Enums.Kind.info,
  Enums.Kind.permissions,
  Enums.Kind.prototypeTest,
  Enums.Kind.welcome,
  Enums.Kind.thankYou,
  Enums.Kind.websiteTest,
  Enums.Kind.cardSort,
  Enums.Kind.treeTest
];

interface Props extends Omit<HTMLAttributes<HTMLFormElement>, 'children' | 'onChange'> {
  block: Models.Block;
  onChange?: (formData: Forms.Data) => void;
  onSave?: (formData: Forms.Data) => void;
}

export const BlockSettings = forwardRef<HTMLFormElement, Props>(
  ({ block, className, onChange, onSave, ...rest }, ref) => {
    const form = useForm<Forms.Data>({ defaultValues: DEFAULT_VALUES, shouldFocusError: false });
    const preventSaveRef = useRef<boolean>(false);

    const [{ getExample, getHelper, getLabel, getPlaceholder }] = useFormHelpers(block);

    const [{ update: updateBlock }] = usePatchBlocksQuery(block.study_id);

    const [updateImage] = api.useUpdateStudyScreenerFieldImageMutation();

    const example = getExample();
    const helper = getHelper();
    const label = getLabel();
    const placeholder = getPlaceholder();

    const watchForm: Forms.Data = form.watch(['config', 'description', 'required', 'settings', 'title']);

    const showRequiredField = (kind: Models.BlockKind) => !DEFAULT_ENABLED_KINDS.includes(kind);

    const renderer: Forms.FormController = ({ onChange, value }) => {
      const { kind } = block;

      switch (kind) {
        case Enums.Kind.linearScale:
          return (
            <BlockableComponent<BlockableComponents.LinearScale>
              kind={kind}
              componentProps={{
                onChangeOptions: (options) => onChange({ options }),
                options: value?.options,
                isEditable: true
              }}
            />
          );

        case Enums.Kind.multiSelect:
        case Enums.Kind.singleSelect:
          return (
            <BlockableComponent<BlockableComponents.MultiSelect | BlockableComponents.SingleSelect>
              kind={kind}
              componentProps={{
                onChangeOptions: (options) => {
                  onChange({ ...watchForm.config, options });
                },
                onChangeOther: (other) => {
                  onChange({ ...watchForm.config, other });
                },
                options: value?.options,
                isEditable: true,
                other: value?.other
              }}
            />
          );

        case Enums.Kind.prototypeTest:
          return (
            <BlockableComponent<BlockableComponents.PrototypeTest>
              kind={kind}
              componentProps={{
                block,
                url: value?.url,
                title: value?.title,
                paths: value?.paths,
                settings: form.getValues('settings'),
                onChange: ({ settings, ...data }) => {
                  form.setValue('settings', settings, { shouldDirty: true });

                  onChange(data);
                }
              }}
            />
          );

        case Enums.Kind.permissions:
          return (
            <BlockableComponent<BlockableComponents.Permissions>
              kind={kind}
              componentProps={{
                camera: value?.camera,
                microphone: value?.microphone,
                screen: value?.screen,
                settings: form.getValues('settings'),
                onChange: ({ settings, ...data }) => {
                  form.setValue('settings', settings, { shouldDirty: true });

                  onChange(data);
                }
              }}
            />
          );

        case Enums.Kind.yesNo:
          return (
            <BlockableComponent<BlockableComponents.YesNo> kind={kind} componentProps={{ options: value?.options }} />
          );

        case Enums.Kind.websiteTest:
          return (
            <BlockableComponent<BlockableComponents.WebsiteTest>
              kind={kind}
              componentProps={{
                block,
                url: value?.url,
                settings: form.getValues('settings'),
                onChange: ({ url, settings }) => {
                  form.setValue('settings', settings, { shouldDirty: true });

                  onChange({ url });
                }
              }}
            />
          );

        case Enums.Kind.cardSort:
          return (
            <BlockableComponent<BlockableComponents.CardSort>
              kind={kind}
              componentProps={{
                sort_type: value?.sort_type,
                cards: value?.cards,
                categories: value?.categories,
                require_all_sorted: value?.require_all_sorted,
                randomise_cards: value?.randomise_cards,
                randomise_categories: value?.randomise_categories,
                onChange: (data) => form.setValue('config', { ...watchForm.config, ...data }, { shouldDirty: true })
              }}
            />
          );

        case Enums.Kind.treeTest:
          return (
            <BlockableComponent<BlockableComponents.TreeTest>
              kind={kind}
              componentProps={{
                block,
                nodes: value?.nodes ?? [],
                randomise_tree_nodes: value?.randomise_tree_nodes,
                onChange: (data, preventSave) => {
                  preventSaveRef.current = Boolean(preventSave);

                  form.setValue('config', { ...watchForm.config, ...data }, { shouldDirty: true });
                }
              }}
            />
          );

        default:
          return <BlockableComponent kind={kind} componentProps={{ readOnly: true }} />;
      }
    };

    const handleOnToggle = (value: boolean) => {
      form.setValue('required', value, { shouldDirty: true });
    };

    const handleOnImageUpload = async ([file]: File[]) => {
      if (block.screener_field_id && file) {
        const body = new FormData();
        body.append('screener_field[image]', file);

        const result = await updateImage({ id: block.screener_field_id, body }).unwrap();

        if (result.image_url) {
          updateBlock(block.id, { image_url: result.image_url });
        }
      }
    };

    const handleOnImageDelete = () => {
      if (block.screener_field_id) {
        const body = new FormData();
        body.append('screener_field[image]', 'remove');

        updateImage({ id: block.screener_field_id, body });

        updateBlock(block.id, { image_url: null });
      }
    };

    const onSubmit = (formData: Forms.Data) => {
      if (!preventSaveRef.current) {
        onSave?.(formData);
      } else {
        preventSaveRef.current = false;
      }
    };

    const { callback: onSubmitCallback } = useDebouncedCallback(form.handleSubmit(onSubmit), DEBOUNCE_DELAY);

    useEffect(() => {
      form.register('description');
      form.register('required');
      form.register('settings');
      form.register('title');
    }, [form.register]);

    useEffect(() => {
      const formData = populateFormData(block);

      if (formData) {
        form.reset(formData);
      }
    }, [block]);

    useDeepCompareEffect(() => {
      if (form.formState.isDirty) {
        onSubmitCallback();
        onChange?.(watchForm);
      }
    }, [watchForm, form.formState.isDirty]);

    return (
      <FormProvider {...form}>
        <form
          ref={ref}
          className={cn('flex h-full flex-col items-stretch', className)}
          onSubmit={form.handleSubmit(onSubmit)}
          {...rest}
        >
          <header className='flex h-20 flex-row items-center justify-between border-b border-gray-200 p-6'>
            <Text as='h2' className='text-xl font-bold'>
              {BLOCK_KIND_LABELS[block.kind]}
            </Text>

            <div className='flex flex-row space-x-1'>
              {![...NON_QUESTION_KINDS, ...LOCKED_BLOCK_TYPES].includes(block.kind) && (
                <Tooltip content='Upload image'>
                  <UploadButton
                    supportedFileTypes={['png', 'eps', 'jpg', 'jpeg', 'gif']}
                    onUploadFiles={handleOnImageUpload}
                    icon='picture'
                    aria-label='Upload image'
                    text
                    hasFileUploadZone
                  />
                </Tooltip>
              )}

              {showRequiredField(block.kind) && (
                <div className='flex flex-row items-center space-x-1'>
                  <Toggle onToggle={handleOnToggle} on={watchForm.required} testId='toggle_required' />
                  <Text className='text-xs'>Required</Text>
                </div>
              )}
            </div>
          </header>

          <section className='h-full p-6'>
            <fieldset className='mb-6'>
              <Label className='mb-1 text-base' labelFor='title'>
                Title
              </Label>

              {helper.title && (
                <Text h='400' color='gray-500' className='mb-2'>
                  {helper.title}
                </Text>
              )}

              <Input
                id='title'
                className='w-full'
                name='title'
                placeholder={placeholder.title}
                error={!!form.errors.title}
                onChange={(value) => form.setValue('title', value, { shouldDirty: true })}
                value={watchForm.title ?? ''}
              />

              {example.title && <FormExample className='mb-2 mt-4'>{example.title}</FormExample>}
            </fieldset>

            <fieldset className='mb-6'>
              <Label className='mb-1 text-base' labelFor='description'>
                Description
              </Label>

              {helper.description && (
                <Text h='400' color='gray-500' className='mb-2'>
                  {helper.description}
                </Text>
              )}

              <MarkdownEditor
                textAreaProps={{
                  id: 'description',
                  name: 'description',
                  placeholder: placeholder.description,
                  minRows: 1,
                  maxRows: 9,
                  onChange: (e) => form.setValue('description', e.currentTarget.value, { shouldDirty: true }),
                  value: watchForm.description ?? ''
                }}
              />

              {example.description && <FormExample className='mb-2 mt-4'>{example.description}</FormExample>}
            </fieldset>

            {block.image_url && (
              <fieldset>
                <Label className='mb-1 text-base'>Image</Label>

                <div className='relative mb-6 flex justify-center rounded-md border border-gray-200'>
                  <ZoomableImage
                    src={block.image_url}
                    alt='Question attachment'
                    className='h-48 w-96 object-contain object-center'
                  />

                  <div className='absolute right-2 top-2'>
                    <Tooltip content='Delete image'>
                      <button onClick={handleOnImageDelete} className='shadow-elevationSm rounded bg-white p-2'>
                        <TrashSVG />
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </fieldset>
            )}

            <fieldset className='min-w-0'>
              {label && <Label className='mb-1 text-base'>{label}</Label>}

              {helper.response && (
                <Text h='400' color='gray-500' className='mb-2'>
                  {helper.response}
                </Text>
              )}

              <Controller control={form.control} name='config' render={renderer} key={block.id} />

              {example.response && <FormExample className='mb-2 mt-4'>{example.response}</FormExample>}
            </fieldset>
          </section>
          <footer className='mt-auto px-6 py-4'></footer>
        </form>
      </FormProvider>
    );
  }
);
