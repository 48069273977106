import * as React from 'react';

import cn from 'classnames';

import { AvatarFromId, Pill, Text } from '@components/common';

type Props = {
  className?: string;
  guest: CalendarEventGuest;
  isHost?: boolean;
};

export const GuestDisplay: React.FC<Props> = ({ className, guest, isHost }) => (
  <div className={cn('flex items-center space-x-3', className)}>
    <AvatarFromId size='lg' name={guest.name || guest.email} />
    <div className='flex-1 truncate'>
      {guest.name && (
        <Text h='400' bold truncate>
          {guest.name}
        </Text>
      )}
      {guest.email && (
        <Text h='400' truncate>
          {guest.email}
        </Text>
      )}
    </div>
    {isHost && <Pill color='indigo'>Host</Pill>}
  </div>
);
