import { useCallback, useEffect, useMemo, useState } from 'react';

import consumer from '@helpers/consumer';
import { DocumentsChannel, useWebsocket } from '@hooks/useWebsocket';

import { useGetDocumentQuery } from './api';
import { Props as TiptapProps, TiptapHook, useTiptap } from './useTiptap';

type Props = TiptapProps & {
  documentId?: number;
  localDoc?: ApiDocument['doc'];
  liveUpdate?: boolean;
};

export const useTiptapFromDocumentId = (props: Props): TiptapHook => {
  const { documentId } = props;

  const [localDoc, setLocalDoc] = useState<ApiDocument['doc'] | undefined>(props.localDoc);

  const { data, isLoading, isUninitialized } = useGetDocumentQuery(props.documentId as number, {
    skip: !documentId,
    refetchOnMountOrArgChange: true
  });

  const docData = useMemo(() => (localDoc ? { ...data, doc: localDoc } : data), [localDoc, data]) as ApiDocument;

  const replaceDocument = useCallback((data: ApiDocument) => {
    setLocalDoc(data.doc);
  }, []);

  const websocketParams = useMemo(() => ({ document_id: props.documentId as number }), [props.documentId]);

  useWebsocket<{ document_id: number }, ApiDocument>({
    channel: DocumentsChannel,
    params: websocketParams,
    onReceived: replaceDocument
  });

  return useTiptap({
    ...props,
    data: docData
  });
};
