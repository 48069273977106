import React from 'react';

import tinytime from 'tinytime';

import { Message } from '@components/AiChat/components';
import { Card, Text } from '@components/common';
import { useAccount } from '@hooks/useAccount';

import { ContextInfo } from './ContextInfo';

const template = tinytime('{MMMM} {DD} at {h}:{mm}{a}');

export const ChatCard = ({ chat }: { chat: AiChat }) => {
  const { getUserById } = useAccount();
  const messages = chat?.messages || [];

  return (
    <Card>
      <div className='mb-4'>
        <Text bold mb='1'>
          Date
        </Text>
        <Text>{template.render(new Date(chat.created_at))}</Text>
      </div>
      <div className='mb-4'>
        <Text bold mb='1'>
          User
        </Text>
        <Text>{getUserById(chat.user_id)?.name || 'unknown'}</Text>
      </div>
      <div className='mb-4'>
        <Text bold mb='1'>
          Context
        </Text>
        <ContextInfo context={chat.context} />
      </div>
      <Text bold mb='1'>
        Messages
      </Text>
      <div className='border border-r border-gray-200'>
        {messages.map((message, i) => (
          <Message {...message} key={`ai-message-${i}`} />
        ))}
      </div>
    </Card>
  );
};
