import * as React from 'react';
import { useEffect, useState } from 'react';

import { TEAM_COLORS } from '@components/AccountTeamsApp/constants';
import { Button, Modal, ModalHeading } from '@components/common';
import { track } from '@components/tracking';
import { useToaster } from '@stores/toaster';

import { useUpdateTeamMutation } from '../api';
import { TeamForm, TeamFormErrors } from '../components/TeamForm';
import * as toasts from '../toasts';

type Props = {
  team: Team;
  open: boolean;
  onClose: () => void;
};

export const EditTeamModal: React.FC<React.PropsWithChildren<Props>> = ({ team, open, onClose }) => {
  const [name, setName] = useState(team.name);
  const [userIds, setUserIds] = useState<number[]>(team.users.map((u) => u.id));
  const [color, setColor] = useState(team.color || TEAM_COLORS[0]);
  const [icon, setIcon] = useState(team.icon);

  const [errors, setErrors] = useState<TeamFormErrors>({});

  const showToast = useToaster();

  const [updateTeam, { isLoading, isSuccess, isError }] = useUpdateTeamMutation();

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successUpdateTeam());
      onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedUpdateTeam());
    }
  }, [isError]);

  useEffect(() => {
    if (open) {
      track('viewed_edit_team_modal');
    }
  }, [open]);

  useEffect(() => {
    if (!!name && errors.name) {
      setErrors({});
    }
  }, [name, errors]);

  const handleSubmit = () => {
    if (!name) {
      setErrors({ name: 'required' });
      return;
    }
    updateTeam({
      id: team.id,
      name,
      user_ids: userIds,
      color,
      icon
    });
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      size='md'
      renderFooter={() => (
        <div className='flex justify-end space-x-4'>
          <Button disabled={isLoading} onClick={onClose}>
            Cancel changes
          </Button>
          <Button loading={isLoading} disabled={isLoading} primary onClick={handleSubmit}>
            Save changes
          </Button>
        </div>
      )}
    >
      <ModalHeading className='mb-6'>Edit Team</ModalHeading>

      <TeamForm
        errors={errors}
        setColor={setColor}
        setIcon={setIcon}
        color={color}
        icon={icon}
        name={name}
        userIds={userIds}
        onChangeUserIds={setUserIds}
        onChangeName={setName}
      />
    </Modal>
  );
};
