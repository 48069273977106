import * as React from 'react';

import ReactSkeleton from 'react-loading-skeleton';

import { PageTitle } from 'components/common/helmets';
import { DashboardHeader, DashboardLayout, DashboardLayoutBody } from 'components/layouts/DashboardLayout';
import { Basic, Grid } from 'components/shared/Skeleton';

export const Skeleton: React.FC<React.PropsWithChildren<unknown>> = () => (
  <DashboardLayout>
    <PageTitle>Incentives</PageTitle>
    <div className='px-page desktop:flex-row flex flex-col items-center border-b border-gray-200 py-4'>
      <h1 className='h700 desktop:mb-0 mb-2 font-bold'>Incentives</h1>
      <div className='tablet:flex-row tablet:space-y-0 tablet:space-x-3 flex flex-1 flex-col items-center justify-end space-y-2'>
        <Basic h='8' width={90} />
        <Basic h='8' width={95} />
        <Basic h='8' width={90} />
        <Basic h='8' width={70} />
      </div>
    </div>

    <DashboardLayoutBody className='h-full'>
      <Basic h='6' width={90} mb='4' />
      <Grid />
    </DashboardLayoutBody>
  </DashboardLayout>
);
