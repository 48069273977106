import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { Control } from 'react-hook-form';
import { UniqueIdentifier } from '@dnd-kit/core';

import { Button } from '@components/common';
import { Props as TreeItemProps } from '@components/common/SortableTree/components/TreeItem';
import { Portal } from '@components/Portal';
import { Enums, getBlockLabel, Models } from '@components/SurveyBuilder';
import { parseAsCollapsedWithoutSelection } from '@components/SurveyBuilder/helpers/treeTestParsers';
import { CheckSVG, ChevronDownSVG } from '@components/svgs';
import { TaskHeader } from '@components/Unmoderated/components/TaskHeader';
import { TaskLoadedBlocker } from '@components/Unmoderated/components/TaskLoadedBlocker';
import { useUnmoderatedContext } from '@components/Unmoderated/hooks/useUnmoderatedContext';
import * as Types from '@components/Unmoderated/types';
import { findItemDeep, SortableTree } from 'components/common/SortableTree';
import { useTreeTestTaskTracking } from 'components/Unmoderated/hooks/useTreeTestTaskTracking';
import { shuffle } from 'components/utils';

import { ResponsiveCard } from '../ResponsiveCard';

import { useGetDirect } from './hooks';

const renderItem = (props: TreeItemProps & { onItemClick: (id: number) => void }) => {
  const {
    id,
    onCollapse,
    onSelect,
    canSelect,
    childCount,
    value,
    selected,
    indentationWidth,
    depth,
    hasSelectedNode,
    collapsed,
    onItemClick
  } = props;

  const marginLeft = indentationWidth * depth;

  return (
    <div className='mb-1 flex items-center justify-between'>
      <button
        onClick={() => {
          onItemClick(Number(id));
          canSelect ? onSelect?.(id) : onCollapse?.(id);
        }}
        type='button'
        className={cn(
          'h400 w-100 flex flex-shrink-0 items-center rounded-md border border-gray-200 transition duration-200 hover:border-gray-700',
          {
            'bg-green-50': selected || hasSelectedNode,
            'bg-white': !selected && !hasSelectedNode
          }
        )}
        style={
          {
            marginLeft: `${marginLeft}px`
          } as React.CSSProperties
        }
      >
        <div className='flex h-10 w-full items-center'>
          <div className='flex h-full w-10 items-center justify-center border-r border-gray-200'>
            {onCollapse && (
              <div
                className={cn(
                  'flex h-6 w-6 transform items-center justify-center rounded-full transition duration-200 ease-in-out',
                  {
                    ['-rotate-90']: collapsed || !onCollapse
                  }
                )}
                aria-label={collapsed ? 'Expand' : 'Collapse'}
              >
                <ChevronDownSVG className={childCount && childCount > 0 ? 'text-gray-700' : 'text-gray-400'} />
              </div>
            )}
          </div>
          <div className='flex h-full flex-1 items-center justify-between truncate'>
            <div className='ml-3 flex w-full items-center justify-between truncate whitespace-nowrap'>
              {selected ? (
                <>
                  <span className='truncate'>{value}</span>
                  <CheckSVG className='mr-4 text-green-600' />
                </>
              ) : (
                <span className='truncate'>{value}</span>
              )}
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};

interface Props {
  block: Models.Block<Enums.Kind.treeTest>;
  fullScreenPortalId: string;
  control: Control<Types.FormData>;
}

export const TreeTestTask: React.FC<Props> = ({ block, fullScreenPortalId, control }) => {
  const [selectedAnswer, setSelectedAnswer] = useState<UniqueIdentifier[]>([]);
  const [isMinimized, setIsMinimized] = useState(false);
  const [blocker, setBlocker] = useState<boolean>(true);

  const { blocks, deviceType, topBarUnmoderatedLayout } = useUnmoderatedContext();

  const { direct, setCurrentPath } = useGetDirect(selectedAnswer);

  const { events, onDeselectNode, onCollapseNode, onSelectNode, onExpandNode } = useTreeTestTaskTracking({});

  const defaultItems = useMemo(() => {
    const parsedNodes = block.blockable.nodes.map((nodes) =>
      parseAsCollapsedWithoutSelection(nodes, block.blockable.randomise_tree_nodes)
    );

    return block.blockable.randomise_tree_nodes ? shuffle(parsedNodes) : parsedNodes;
  }, [block.blockable.nodes]);

  const [items, setItems] = useState(defaultItems);

  const onSelectItem = (nodeId: UniqueIdentifier, selectedAnswerArray: UniqueIdentifier[][]) => {
    if (selectedAnswerArray.length === 0) {
      onDeselectNode(Number(nodeId));
      setSelectedAnswer([]);
      return;
    }

    onSelectNode(Number(nodeId));
    setSelectedAnswer(selectedAnswerArray[0]);
  };

  const onItemClick = (id: number) => {
    if (!direct) return;

    setCurrentPath((prev) => [...prev, id]);
  };

  useEffect(() => {
    control.register('tree_test_task');
  }, [control]);

  useEffect(() => {
    control.setValue('tree_test_task', {
      events,
      isComplete: !!selectedAnswer.length,
      result: {
        answer: selectedAnswer,
        answer_labels: selectedAnswer.map((id) => findItemDeep(block.blockable.nodes, id)?.label || ''),
        direct
      }
    });
  }, [events, selectedAnswer, direct]);

  const renderActions = () => {
    return (
      <SortableTree
        allowMultipleSelection={false}
        onSelectItem={onSelectItem}
        onCollapseNode={onCollapseNode}
        onExpandNode={onExpandNode}
        collapsible
        renderItem={(props) => renderItem({ ...props, onItemClick })}
        items={items}
        setItems={setItems}
      />
    );
  };

  const renderFooter = () => (
    <Button
      disabled={!selectedAnswer.length}
      aria-label='Continue'
      className='btn-custom-brand'
      icon='arrowRight'
      type='submit'
      small
      noStyle
    >
      Continue
    </Button>
  );

  if (deviceType === 'desktop' && topBarUnmoderatedLayout) {
    return (
      <div className='flex h-screen w-full flex-col overflow-hidden bg-white pt-4'>
        <TaskHeader
          isMinimized={isMinimized}
          setIsMinimized={setIsMinimized}
          block={block}
          renderOptions={renderFooter}
        />
        <div className='relative flex w-full flex-1 overflow-hidden bg-gray-50'>
          <div className='mt-6 flex w-full flex-1 flex-col items-center overflow-auto'>{renderActions()}</div>
          {blocker && (
            <TaskLoadedBlocker
              blockKind={block.kind}
              onBeginTask={() => {
                setBlocker(false);
                setIsMinimized(true);
              }}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <ResponsiveCard
      blockKind={getBlockLabel(block.kind)}
      blockPosition={block.position}
      description={block.description}
      title={block.title}
      totalBlocks={blocks.length}
      renderFooter={renderFooter}
    >
      <Portal target={document.getElementById(fullScreenPortalId)}>
        <div className='absolute flex h-full w-full flex-col items-center overflow-y-scroll bg-white p-8'>
          {renderActions()}
        </div>
      </Portal>
    </ResponsiveCard>
  );
};
