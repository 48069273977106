import React, { useEffect, useMemo, useState } from 'react';

import pluralize from 'pluralize';

import { api } from '@api/reduxApi';
import { Alert, AlertLink, Button, LayoutToggle, Tabs, Text, Toggle } from '@components/common';
import { Grid } from '@components/common/Grid';
import { DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { PageHeader } from '@components/shared/PageHeader';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { SortDropdown } from '@components/shared/SortDropdown';
import { AnyAllToggle, SegmentActions, TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { ZDSPage } from '@components/shared/ZDS/components/ZDSPage';
import { useCollectionView } from '@components/stores/view';
import { InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { track } from '@components/tracking';
import { useAccount } from '@hooks/useAccount';
import { usePlan } from '@hooks/usePlan';
import { useUser } from '@hooks/useUser';
import { GroupBySVG } from 'components/svgs/GroupBySVG';
import { usePaginatedStudiesToggle } from 'hooks/usePaginatedStudiesToggle';

import { NewStudyAction } from '../StudyNew';

import { GroupedTable } from './components/GroupedTable';
import { ListViewTable } from './components/ListViewTable';
import { NoResults } from './components/NoResults';
import { RowViewTable } from './components/RowViewTable';
import { StudyCard } from './components/StudyCard';
import { buildStudiesAttributes } from './filters/buildStudiesAttributes';
import { DISABLED_GROUPED_STUDIES_VIEW_MESSAGE, PAGINATED_STUDIES_VIEW_MESSAGE } from './contants';
import { sortStudies } from './utils';

const TAB_LABELS = {
  my: 'My studies',
  all: 'All studies'
};

interface Props {
  studies: SimpleStudy[];
  team?: Team | null;
  isPopperOpen: boolean;
}

export const OldStudiesListPage: React.FC<React.PropsWithChildren<Props>> = ({ studies, team, isPopperOpen }) => {
  const user = useUser();

  const {
    account: { id: account_id, status, team: accountMembers }
  } = useAccount();

  const { limits, hasQuota } = usePlan();

  const viewHook = useCollectionView();
  const {
    view: { sort, layout, groupBy, tab, toggle },
    setView
  } = viewHook;

  function setLayout(layout) {
    setView({ layout });
  }

  function setSort(sort) {
    setView({ sort });
  }

  function setGroupBy(groupBy) {
    setView({ groupBy });
  }

  function setToggle(toggle) {
    setView({ toggle });
  }

  const [filteredBySearch, setFilteredBySearch] = useState(studies);
  const [filteredStudies, setFilteredStudies] = useState<SimpleStudy[]>(filteredBySearch);
  const { paginatedStudiesView, paginatedStudiesFeature, togglePaginatedStudiesView } = usePaginatedStudiesToggle();
  const [value, setValue] = useState<string>('');

  const { data: studyAttrs, isLoading: attrsLoading, isSuccess, isError } = api.useGetStudyAttrsQuery();

  const attrs = useMemo(
    () => buildStudiesAttributes({ team: accountMembers, studyAttrs: studyAttrs || [] }),
    [studyAttrs, accountMembers]
  );

  const filtersHook = useTableFilters<SimpleStudy>({
    definitions: attrs.filters,
    trackKey: 'studies',
    syncWithURL: isSuccess || isError
  });

  const filterStudies = (studies: SimpleStudy[]) => {
    const sortAttr = attrs.find(({ id }) => id === sort.value);
    const filteredStudies = filtersHook.rawFilter(sortStudies(studies, sortAttr) || []);
    setFilteredStudies(filteredStudies);
  };

  const onSortChange = ({ value }) => {
    setSort({ value, desc: false });
  };

  const onSearchChange = (value: string) => {
    setValue(value);
  };

  const searchStudies = (value: string) => {
    const initialStudies = toggle ? studies : studies.filter(({ state }) => state !== 'archived');

    if (!value) {
      setFilteredBySearch(initialStudies);
      return;
    }
    const filteredStudies = initialStudies.filter(({ title }) => {
      const lowerCaseTitle = title.toLowerCase();
      const lowerCaseValue = value.toLowerCase();

      return lowerCaseTitle.includes(lowerCaseValue);
    });

    setFilteredBySearch(filteredStudies);
  };

  const onGroupByChange = ({ value }: { value: string }): void => {
    setGroupBy(value);
  };

  const filteredStudiesDeps = filteredStudies.map(({ id }) => id).join(',');

  useEffect(() => {
    if (tab === 'my') {
      const userStudies = filteredBySearch.filter(({ owner_id }) => owner_id === user.id);
      filterStudies(userStudies);
    } else {
      filterStudies(filteredBySearch);
    }
  }, [tab, filteredBySearch, filtersHook.filters, filtersHook.op, sort]);

  useEffect(() => {
    searchStudies(value);
  }, [studies, toggle]);

  const renderStudies = useMemo(() => {
    if (filteredStudies.length === 0) {
      return <NoResults />;
    }

    if (layout === 'grid') {
      return (
        <GroupedTable<SimpleStudy>
          Table={({ records: studies }) => (
            <Grid gap={6} monitor={4} desktop={3} tablet={2} mobile={1}>
              {studies?.map((s) => s && <StudyCard key={s.id} study={s} />)}
            </Grid>
          )}
          groupBy={groupBy || ''}
          groupByOptions={attrs.groups}
          records={filteredStudies}
        />
      );
    }

    if (layout === 'row') {
      return (
        <GroupedTable<SimpleStudy>
          Table={RowViewTable}
          groupBy={groupBy || ''}
          groupByOptions={attrs.groups}
          records={filteredStudies}
        />
      );
    }

    return (
      <GroupedTable<SimpleStudy>
        Table={ListViewTable}
        studyAttrs={studyAttrs}
        groupBy={groupBy || ''}
        groupByOptions={attrs.groups}
        records={filteredStudies}
        setSort={setSort}
      />
    );
  }, [
    filteredStudiesDeps,
    JSON.stringify(filteredStudies),
    attrs.groups,
    groupBy,
    layout,
    ListViewTable,
    studyAttrs,
    RowViewTable
  ]);

  const renderFilters = () => (
    <div className='px-page flex items-center border-t border-gray-200'>
      <div className='flex-1'>
        <TableFilters<SimpleStudy> hook={filtersHook} defaultShowInput={!filtersHook.filters.length} />
      </div>
      <div className='flex space-x-3 border-l border-gray-200 py-3 pl-3'>
        <AnyAllToggle value={filtersHook.op} onChange={filtersHook.setOp} />
        <SegmentActions onClear={filtersHook.clearFilters} anyFilters={filtersHook.filters.length > 0} />
      </div>
    </div>
  );

  const renderTabs = () => (
    <Tabs
      current={tab || 'all'}
      labels={TAB_LABELS}
      onSelect={(tab) => {
        track('changed_study_owner_tab', { tab });
        setView({ tab });
      }}
      className='px-page flex items-center border-t border-gray-200'
      tabs={['my', 'all']}
    />
  );

  useEffect(() => {
    track('rendered_old_studies_page', { user_id: user.id, account_id: account_id });
  }, []);

  if (!studies.length) {
    return <ZDSPage types={['studies']} teamId={team?.id} />;
  }

  return (
    <DashboardLayout>
      <PageHeader
        h1='Studies'
        renderSortBy={() => (
          <SortDropdown wrapperClass='w-full' options={attrs.sortOptions} value={sort.value} onChange={onSortChange} />
        )}
        renderLayout={() => <LayoutToggle withRow value={layout} onChange={setLayout} />}
        renderGroupBy={() => (
          <div className='flex flex-1'>
            <div className='flex w-1/2 items-center whitespace-nowrap text-sm font-bold'>
              <GroupBySVG className='mr-2' />
              Group by
              {paginatedStudiesView && (
                <Tooltip content={DISABLED_GROUPED_STUDIES_VIEW_MESSAGE}>
                  <InfoCircleIcon className='ml-2 h-4 w-4' />
                </Tooltip>
              )}
            </div>
            <div className='w-1/2'>
              <SortDropdown
                wrapperClass='w-full'
                disabled={paginatedStudiesView}
                type='group'
                options={attrs.groupOptions}
                value={groupBy}
                onChange={onGroupByChange}
              />
            </div>
          </div>
        )}
        searchProps={{
          placeholder: 'Search…',
          addDebounce: true,
          onSearch: onSearchChange,
          onEnd: searchStudies,
          value
        }}
        renderTabs={renderTabs}
        renderFilters={renderFilters}
        filtersApplied={!!filtersHook.filters.length}
        renderCta={() => (
          <NewStudyAction>
            {({ openSlideout }) => (
              <RestrictedButton
                limit='studies'
                permission='createStudy'
                action='New study'
                className='xx-new-study-cta'
              >
                <Button onClick={() => openSlideout({ teamId: team?.id })} primary medium>
                  New study
                </Button>
              </RestrictedButton>
            )}
          </NewStudyAction>
        )}
        renderToggle={() => (
          <>
            <div className='flex items-center justify-between'>
              <div className='text-sm'>Show archived studies</div>
              <Toggle name='Show archived studies' onToggle={setToggle} on={toggle} />
            </div>
            {paginatedStudiesFeature && (
              <div className='flex items-center justify-between'>
                <div className='text-sm'>
                  Paginate studies
                  <Tooltip content={PAGINATED_STUDIES_VIEW_MESSAGE}>
                    <InfoCircleIcon className='ml-2 h-4 w-4' />
                  </Tooltip>
                </div>
                <Toggle name='Paginate studies' onToggle={togglePaginatedStudiesView} on={paginatedStudiesView} />
              </div>
            )}
          </>
        )}
        team={team}
        isPopperOpen={isPopperOpen}
      />

      <DashboardLayoutBody>
        <div className='tablet:flex-row tablet:items-center mb-4 flex flex-col-reverse items-start'>
          <div className='tablet:mt-0 mt-4 flex-grow'>
            <Text color='gray-500' h='400'>
              {pluralize('studies', filteredStudies.length, true)}
            </Text>
          </div>
        </div>
        {!!studies.length && renderStudies}
      </DashboardLayoutBody>
    </DashboardLayout>
  );
};
