import * as React from 'react';

import tinytime from 'tinytime';

import ZDSIcon from './ScreenerResponsesZDS';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

const ZDS: React.FC<React.PropsWithChildren<{ first_name?: string | null }>> = ({ first_name }) => (
  <div className='py-6 text-center'>
    <span className='mx-auto'>
      <ZDSIcon />
    </span>
    <p className='mt-4 text-gray-500'>We'll show {first_name ? `${first_name}'s` : 'their'} screener responses here.</p>
  </div>
);

interface AnswerProps<T extends ScreenerFieldType> extends ScreenerResponseAnswer<T> {
  showInfo?: boolean;
  title?: string;
}

const Answer = <T extends ScreenerFieldType>({
  label,
  value,
  submitted_at,
  title,
  field_type,
  showInfo
}: AnswerProps<T>) => {
  const renderer = () => {
    switch (field_type) {
      case 'multiple_choice':
        return (Array.isArray(value) ? value : [value]).map((v) => (
          <span key={v} className='block'>
            {v}
          </span>
        ));

      case 'prototype_test':
      case 'tree_test':
      case 'card_sort':
        return (
          <span>
            {(value as ScreenerResponseAnswerValue<'prototype_test' | 'tree_test' | 'card_sort'>)?.completed
              ? 'Success'
              : 'Failed'}
          </span>
        );

      default:
        if (typeof value !== 'string') {
          console.error('Cannot render Answer for field type', field_type, 'with value', value);
          return <span>(Unable to display answer)</span>;
        }
        return value;
    }
  };

  return (
    <li className='border-b border-gray-100 py-4'>
      <div className=''>
        <h3 className='mb-2 flex-1 text-sm font-medium text-gray-700'>{label}</h3>
        <h3 className='flex-1 text-sm font-normal text-gray-700'>{renderer()}</h3>
      </div>
      {showInfo && (
        <div className='mt-2 flex flex-row items-center text-xs leading-normal text-gray-500'>
          {submitted_at && <span className='mr-2'>{dateTemplate.render(new Date(submitted_at))}</span>}
          <svg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='2' cy='2' r='2' fill='#E5E7EB' />
          </svg>
          <span className='ml-2'>{title}</span>
        </div>
      )}
    </li>
  );
};

interface Props {
  candidate: Candidate;
  answers: ScreenerResponseAnswer[];
  hidden_answers: any[];
  showInfo?: boolean;
}
const ScreenerResponses: React.FC<React.PropsWithChildren<Props>> = ({
  candidate,
  answers,
  hidden_answers,
  showInfo = true
}) => {
  const hiddenKeys = Object.keys(hidden_answers || {});
  if (answers.length === 0) {
    return <ZDS first_name={candidate.first_name} />;
  }

  return (
    <ul>
      {answers.map((answer) => (
        <Answer key={answer.label} {...answer} showInfo={showInfo} />
      ))}
      {hiddenKeys.length > 0 && (
        <li className='border-b border-gray-100 py-4'>
          <h3 className='mb-2 flex-1 text-sm font-medium text-gray-700'>Hidden fields</h3>
          {hiddenKeys.map((key) => (
            <h3 key={key} className='flex-1 text-sm font-normal text-gray-700'>
              {key}: {hidden_answers[key]}
            </h3>
          ))}
        </li>
      )}
    </ul>
  );
};
export default ScreenerResponses;
