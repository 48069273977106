import * as React from 'react';
import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Button, Loading, TeamIcon, Text } from '@components/common';
import { useTeams } from '@hooks/useTeams';

import { DeleteTeamModal } from '../../modals/DeleteTeamModal';
import { EditAccessModal } from '../../modals/EditAccessModal';
import { EditTeamModal } from '../../modals/EditTeamModal';

export const TeamProfile: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<{ id: string }>();
  const teamId = parseInt(id || '', 10);
  const { findTeam } = useTeams();
  const team = !!teamId && findTeam(teamId);

  const navigate = useNavigate();

  const [accessModalOpen, setAccessModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  if (!team) {
    return <Loading />;
  }

  return (
    <>
      <Text h='700' className='monitor:mb-6 mb-4'>
        Profile
      </Text>

      <div className='mb-4 rounded-md border border-gray-200 bg-white p-6'>
        <div className='flex'>
          <div className='flex-1'>
            <Text h='400' bold>
              Name & icon
            </Text>
          </div>
          <div>
            <Button medium icon='pencil' small onClick={() => setEditModalOpen(true)}>
              Edit
            </Button>
          </div>
        </div>
        <div className='flex items-center'>
          <TeamIcon team={team} className='mr-2' />
          <Text h='400'>{team.name}</Text>
        </div>
      </div>

      <div className='mb-4 rounded-md border border-gray-200 bg-white p-6'>
        <div className='flex'>
          <div className='flex-1'>
            <Text h='400' bold>
              Access
            </Text>
          </div>
          <div>
            <Button icon='pencil' small onClick={() => setAccessModalOpen(true)}>
              Edit
            </Button>
          </div>
        </div>
        <Text h='400'>
          {team.allow_join ? 'Open: Can be joined by anyone in the workspace.' : 'Closed: Only admins can add members.'}
        </Text>
      </div>

      <Button danger outline small onClick={() => setDeleteModalOpen(true)}>
        Delete team
      </Button>

      {accessModalOpen && <EditAccessModal open team={team} onClose={() => setAccessModalOpen(false)} />}

      {deleteModalOpen && (
        <DeleteTeamModal
          open
          team={team}
          onClose={() => setDeleteModalOpen(false)}
          onSuccess={() => navigate('/teams')}
        />
      )}

      {editModalOpen && <EditTeamModal open team={team} onClose={() => setEditModalOpen(false)} />}
    </>
  );
};
