import { CategoriesTableData } from '@components/ScreenerResults/components/CardSortResponse/components';
import { average } from '@components/utils';

type Args = {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
  categories: string[];
  avgAgreements: { category: string; avgAgreement: number }[];
  mergedCategories: { name: string; categories: string[] }[];
};

export const buildCategoriesTableData = ({
  answers,
  categories,
  avgAgreements,
  mergedCategories
}: Args): CategoriesTableData[] => {
  return categories.map((category) => {
    const allCardsInCategory = answers.reduce((acc, answer) => {
      return [...acc, ...(answer.results.find(({ name }) => name === category)?.cards || [])];
    }, []);

    const uniqueCards = Array.from(new Set(allCardsInCategory));

    const cardsWithFrequency = uniqueCards.map((card) => {
      const frequency = answers.reduce((acc, answer) => {
        const currentResult = answer.results.find(({ name }) => name === category);

        if (currentResult?.cards.includes(card)) {
          acc += 1;
        }

        return acc;
      }, 0);

      return {
        name: card,
        frequency
      };
    });

    const sortedCardsWithFrequency = cardsWithFrequency.sort((a, b) => b.frequency - a.frequency);

    const isMergedCategory = mergedCategories.find(({ name }) => name === category);

    const avgAgreement = isMergedCategory
      ? // if category is merged category, calculate average agreement for all categories in merged category
        average(
          isMergedCategory.categories.map(
            (category) => avgAgreements.find(({ category: current }) => current === category)?.avgAgreement || 0
          )
        )
      : // else, calculate average agreement for current category
        avgAgreements.find(({ category: current }) => current === category)?.avgAgreement || 0;

    const result = {
      category,
      cards: sortedCardsWithFrequency,
      avgAgreement
    };

    return result;
  });
};
