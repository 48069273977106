import * as React from 'react';

import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

import { Card, CardTitle } from '@components/common';
import { EmailPreview } from '@components/StudiesApp/components/StudyPublished/pages/Emails/components/EmailPreview';
import { humanize } from '@components/utils';

import { Tab } from '../shared';

import { HEADINGS } from './constants';
import { getEvents } from './utils';

export interface Props extends PageProps {
  email: StudyMessageEvent;
  addWrapper?: boolean;
}

export const Emails: React.FC<React.PropsWithChildren<PageProps>> = (props) => {
  const match = useMatch('studies/:id/emails/:email');

  const { study } = props;

  const emails = getEvents(study);

  return (
    <div className='px-page py-gutter flex flex-1 flex-col'>
      <div className='relative w-full rounded-t border border-b-0 border-gray-200 bg-white p-6 pb-1.5'>
        <CardTitle className='mb-4'>Emails</CardTitle>
        <div className='flex'>
          {/* z-10 to position tab underline on the gray border*/}
          <div className='z-10 space-x-4'>
            {emails.map((email) => (
              <Tab
                key={email}
                active={match?.params.email === email}
                href={`/studies/${props.study.id}/emails/${email}`}
                tabName={email}
              >
                {HEADINGS[email] || humanize(email)}
              </Tab>
            ))}
          </div>
        </div>
      </div>
      <Card className='flex-1 rounded-t-none p-6'>
        <Routes>
          {emails.map((email) => (
            <Route key={email} path={email} element={<EmailPreview {...props} email={email} key={email} />} />
          ))}
          <Route path='*' element={<Navigate to='invite' replace />} />
        </Routes>
      </Card>
    </div>
  );
};
