import { Options } from '@popperjs/core';
import Link from '@tiptap/extension-link';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { Extension, Extensions } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { compact } from 'components/utils';

import { DEFAULT_PLACEHOLDER } from '../constants';
import {
  Artifacts,
  Bold,
  BulletList,
  ColumnExtension,
  Cta,
  Heading,
  Highlight,
  HighlightWidget,
  If,
  ImageResize,
  Italic,
  LoadDoc,
  MergeTag,
  Recording,
  Table,
  TextSelection
} from '../extensions';

export interface Config {
  base: boolean;
  columns: boolean;
  headings: boolean;
  artifacts: boolean;
  image: {
    enable: boolean;
    inline?: boolean;
    allowBase64?: boolean;
    useFigure?: boolean;
    HTMLAttributes?: Record<string, any>;
  };
  templates: boolean;
  placeholder: string;
  link: {
    enable: boolean;
    menuPlacement?: Options['placement'];
  };
  highlight: {
    enable: boolean;
    menuPlacement?: Options['placement'];
    readOnly?: boolean;
  };
  mergeTags: {
    tags: string[];
  };
  cta: {
    enable: boolean;
    as?: 'button' | 'link';
    default?: string;
  };
  transcript: {
    defaultSpeakers: (TeamUser | Candidate)[];
  };
  tables: boolean;
  import_document: boolean;
}

const getBaseExtensions = (headings?: boolean) => [
  StarterKit.configure({ bold: false, italic: false, paragraph: false, heading: false, bulletList: false }),
  Paragraph.configure({
    HTMLAttributes: {
      class: 'leading-7'
    }
  }),
  Bold,
  Italic,
  Underline,
  TextAlign.configure({ types: compact([headings && 'heading', 'paragraph']) }),
  BulletList,
  TextSelection,
  LoadDoc,
  If,
  Recording
];

export const buildExtensions = (config: Partial<Config>, extra?: Extension | Extensions): Extensions => {
  const extensions = 'base' in config && !config.base ? [] : [...getBaseExtensions(config.headings)];
  if (config.columns) {
    extensions.push(ColumnExtension);
  }

  if (config.headings) {
    extensions.push(Heading);
  }

  if (config.link) {
    extensions.push(Link.configure({ openOnClick: false, HTMLAttributes: { class: 'underline' } }));
  }

  if (config.artifacts) {
    extensions.push(Artifacts as Extension);
  }

  if (config.image) {
    extensions.push(ImageResize.configure({ inline: true, ...config.image }));
  }

  if (config.highlight?.enable) {
    extensions.push(HighlightWidget, Highlight.configure({ HTMLAttributes: { class: 'py-1' } }));
  }

  if (config.mergeTags) {
    extensions.push(MergeTag);
  }

  if (config.cta?.enable) {
    if (config.cta.as === 'link') {
      extensions.push(
        Cta.configure({ HTMLAttributes: { class: 'underline font-bold mt-4 mb-4 block' }, default: config.cta.default })
      );
    } else {
      extensions.push(Cta.configure({ default: config.cta.default }));
    }
  }

  if (config.tables) {
    extensions.push(
      Table.configure({
        resizable: true
      }),
      TableCell,
      TableHeader,
      TableRow
    );
  }

  extensions.push(Placeholder.configure({ placeholder: config.placeholder || DEFAULT_PLACEHOLDER }));

  return extensions.concat(extra ?? []);
};
