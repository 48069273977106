import * as React from 'react';

import ReactDatePicker from 'react-datepicker';

import { Text } from '@components/common';

import { FieldRow } from '../components/FieldRow';
import { formatDate } from '../utils';

import type { BookingDetailsFormFieldComponentProps } from './types';

export const DateField: React.FC<BookingDetailsFormFieldComponentProps> = ({ editMode, bookingDetail, onChange }) => {
  const event = bookingDetail.candidate_event;

  if (!event) return null;

  const handleDateChange = (date: Date) => {
    const startTime = new Date(event.time.start_time);
    startTime.setFullYear(date.getFullYear());
    startTime.setMonth(date.getMonth());
    startTime.setDate(date.getDate());
    onChange({ candidate_event: { time: { start_time: startTime } } });
  };

  return (
    <FieldRow label='Date'>
      {!editMode && (
        <div className='overflow-auto'>
          <Text className='py-2.5' h='400'>
            {formatDate(event.time.start_time)}
          </Text>
        </div>
      )}
      {editMode && (
        <ReactDatePicker
          title='Start Date'
          wrapperClassName='w-full'
          selected={event.time.start_time}
          onChange={handleDateChange}
          placeholderText='Enter…'
          className='h400 w-full rounded-md border border-gray-200 text-left'
        />
      )}
    </FieldRow>
  );
};
