import React from 'react';

import { LinkSVG } from 'components/svgs';
import { cleanUrl } from 'components/utils';

import { eventUrl } from '../../helpers';

import { CreateZoomButton } from './CreateZoomButton';

interface Props {
  participation: Participation;
  session: RepoSession;
}

export const EventUrl: React.FC<React.PropsWithChildren<Props>> = ({ participation, session }) => {
  const url = eventUrl(participation);

  const inFuture = !participation.interview_at || participation.interview_at > new Date();
  const isZoom = participation.study?.video_url_type === 'zoom';
  const wasBooked =
    ['booked', 'completed', 'canceled', 'no_show'].includes(participation.status) &&
    participation.event_details?.start_time;

  if (!url && inFuture && isZoom && wasBooked) {
    return <CreateZoomButton sessionUuid={session.uuid} participationId={participation.id} />;
  }

  if (!url) return null;

  return (
    <a href={url} target='_blank' className='max-w-64 flex items-center py-1.5 text-sm underline'>
      <LinkSVG className='mr-2 inline flex-shrink-0 text-gray-700' />
      <span className='truncate'>{cleanUrl(url)}</span>
    </a>
  );
};
