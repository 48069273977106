import * as React from 'react';

import cn from 'classnames';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

import { Button, Card, CardTitle, Spinner, Text } from '@components/common';
import {
  ScreenerResultsIndividual as IndividualTab,
  ScreenerResultsSummary as SummaryTab
} from '@components/ScreenerResults';
import { compact } from '@components/utils';
import { useScreenerResults } from '@hooks/useScreenerResults';

import { Tab } from '../../pages/shared';

const LABELS: Record<'summary' | 'individual', string> = {
  summary: 'Summary',
  individual: 'Individual'
};

interface Props {
  study: Study;
  setBackgroundTasks?: (backgroundTask: BackgroundTask) => void;
}

export const ScreenerResultsTab: React.FC<React.PropsWithChildren<Props>> = ({ study, setBackgroundTasks }) => {
  const match = useMatch(`studies/:id/screener_responses/:tab`);

  const { isLoading, results, count, total } = useScreenerResults(study.id, study.pre_screener?.id);

  const showIndividualTab = results && results.individual.length > 0;

  const tabs = compact(['summary', showIndividualTab && 'individual']);

  return (
    <div className='px-page py-gutter relative flex h-full flex-col'>
      <div
        className={cn(
          'relative w-full rounded-t border border-b-0 border-gray-200 bg-white p-6',
          tabs.length > 1 && 'pb-0'
        )}
      >
        <div className='flex flex-row items-start'>
          <CardTitle className='mb-0 mr-6 pb-0'>Screener responses</CardTitle>
          {isLoading && (
            <div className='flex items-center'>
              {total && (
                <Text color='gray-500' className='mr-2'>
                  {(count || 0).toLocaleString()}/{total.toLocaleString()}
                </Text>
              )}
              <Spinner className='h-4 w-4' />
            </div>
          )}
          {study.pre_screener && (
            <div className='flex flex-1 items-center justify-end'>
              <Button
                target='_blank'
                href={`/studies/${study.id}/screeners/${study.pre_screener.id}/export`}
                iconSuffix='export'
              >
                Export to CSV
              </Button>
            </div>
          )}
        </div>

        {tabs.length > 1 && (
          <div className='desktop:space-x-5 relative flex items-center space-x-3 overflow-x-auto overflow-y-visible'>
            {tabs.map((tab) => (
              <Tab
                key={tab}
                active={match?.params.tab === tab}
                href={`/studies/${study.id}/screener_responses/${tab}`}
                tabName={tab}
              >
                {LABELS[tab]}
              </Tab>
            ))}
          </div>
        )}
      </div>
      <Card className='flex-1 rounded-t-none p-6'>
        <div className='h-full pt-4'>
          <Routes>
            <Route path='summary' element={<SummaryTab isLoading={isLoading} results={results?.summary as any} />} />
            {tabs.includes('individual') && (
              <Route
                path='individual'
                element={
                  <IndividualTab
                    study={study}
                    isLoading={isLoading}
                    responses={results?.individual as any}
                    setBackgroundTask={setBackgroundTasks}
                  />
                }
              />
            )}
            {match?.params.tab !== 'individual' && <Route path='*' element={<Navigate to='summary' replace />} />}
          </Routes>
        </div>
      </Card>
    </div>
  );
};
