import { api } from 'api/reduxApi';

type UseAccountUsageHook = {
  usage: number;
  remaining: number;
  refetch: () => void;
  isLoading: boolean;
  isError: boolean;
};

export function useAccountUsage(key: PlanLimit): UseAccountUsageHook {
  const { data, isLoading, isError, refetch } = api.useGetAccountUsageQuery(key);
  const usage: number = data?.usage || 0;
  const remaining: number = data ? data.limit - data.usage : 0;

  return { remaining, usage, refetch, isLoading, isError };
}
