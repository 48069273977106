import React from 'react';

import cn from 'classnames';

export const QuestionWrapper: React.FC<React.PropsWithChildren<{ id: any }>> = ({ id, children }) => (
  <div
    id={`step-${id}`}
    key={id}
    aria-live='polite'
    className='tablet:p-6 my-6 flex rounded-sm border border-gray-200 p-4'
  >
    {children}
  </div>
);

export const QuestionCard: React.FC<React.PropsWithChildren<{ isActive: boolean; onClick?: () => void }>> = ({
  isActive,
  children,
  onClick
}) => {
  return (
    <div className={cn('flex w-full', { 'opacity-50': !isActive })} onClick={onClick || undefined}>
      {children}
    </div>
  );
};

export const QuestionInner: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className='max-w-40 flex-1'>{children}</div>
);

interface QuestionTitleProps {
  required?: boolean;
  htmlFor?: string;
}

export const QuestionTitle: React.FC<React.PropsWithChildren<QuestionTitleProps>> = ({
  htmlFor,
  children,
  required
}) => (
  <label
    htmlFor={htmlFor}
    className={cn('text-custom-brand flex items-start space-x-1 text-xl font-bold leading-normal')}
  >
    <span>{children}</span>
    {required && <span className='h400'>*</span>}
  </label>
);
export const QuestionNumber: React.FC<React.PropsWithChildren<{ number: number }>> = ({ number }) => (
  <div className='text-custom-brand w-8 text-xl font-bold'>{number}.</div>
);
