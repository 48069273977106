import * as React from 'react';

import cn from 'classnames';

export interface AddonInputProps {
  id?: string;
  value: string | number | null;
  className?: string;
  disabled?: boolean;
  ariaLabel?: string;
  prefix?: string;
  suffix?: string;
  type?: 'text' | 'number';
  placeholder?: string;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange: (value: string | number) => void;
  icon?: React.ReactNode;
  pr?: string;
  pl?: string;
  error?: boolean;
  width?: string;
  min?: number;
  dataTestId?: string;
}

export const InputWithAddons: React.FC<React.PropsWithChildren<AddonInputProps>> = ({
  id,
  type = 'text',
  className = '',
  ariaLabel,
  value,
  disabled = false,
  onChange,
  onEnter,
  onBlur,
  placeholder = 'Enter amount',
  prefix = '$',
  suffix = 'USD',
  name,
  icon,
  pr = 12,
  pl,
  error,
  width = 'full',
  min,
  dataTestId
}) => {
  function handleChange(e: React.FormEvent<HTMLInputElement>): void {
    const val = e.currentTarget.value;
    if (type === 'number') {
      let num = parseInt(val);
      if (min && num < min) {
        num = min;
      }
      onChange(num);
    } else {
      onChange(val);
    }
  }
  return (
    <div className={cn('relative mt-1', 'w-' + width)}>
      <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
        <span className='h400 text-gray-500'>{prefix}</span>
        {icon && <span className='h400 mr-2.5 text-gray-500'>{icon}</span>}
      </div>
      <input
        id={id}
        min={min}
        type={type}
        value={value as any}
        aria-label={ariaLabel}
        disabled={disabled}
        name={name}
        data-testid={dataTestId}
        onWheel={(e) => {
          type === 'number' && e.currentTarget.blur();
        }}
        onKeyDown={(e) => e.key === 'Enter' && onEnter?.(e)}
        onBlur={onBlur}
        className={cn(
          className,
          'h400 block w-full rounded-md pl-7 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500',
          `pr-${pr}`,
          { [`pl-${pl}`]: pl },
          { 'pl-10': icon && !pl, 'pl-7': !icon && !pl },
          { 'border-red-600': error },
          { 'border-gray-200': !error }
        )}
        placeholder={placeholder}
        onChange={!disabled ? handleChange : undefined}
      />
      {suffix && (
        <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
          <span className='h400 text-gray-500' id='price-currency'>
            {suffix}
          </span>
        </div>
      )}
    </div>
  );
};
