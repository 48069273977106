import * as React from 'react';

export const studies: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg
    className='mr-3 h-4 w-4'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.1991 9.67408L9.75 14.1249L7.5625 14.5624L8 12.3749L12.4514 7.92408C12.5659 7.80938 12.7019 7.71839 12.8516 7.6563C13.0013 7.59421 13.1617 7.56226 13.3238 7.56226C13.4858 7.56226 13.6463 7.59421 13.796 7.6563C13.9457 7.71839 14.0817 7.80938 14.1962 7.92408L14.2014 7.92875C14.4325 8.16053 14.5621 8.4746 14.5616 8.8019C14.5612 9.12919 14.4308 9.44292 14.1991 9.67408Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M5.81018 12.8125H2.31018C2.07812 12.8125 1.85556 12.7203 1.69146 12.5562C1.52737 12.3921 1.43518 12.1696 1.43518 11.9375V2.3125C1.43518 2.08044 1.52737 1.85788 1.69146 1.69378C1.85556 1.52969 2.07812 1.4375 2.31018 1.4375H11.0602C11.2922 1.4375 11.5148 1.52969 11.6789 1.69378C11.843 1.85788 11.9352 2.08044 11.9352 2.3125V6.25'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M5.81018 4.0625H9.31018'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M4.06018 6.6875H9.31018'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M4.06018 9.3125H8.43518'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
  </svg>
);
