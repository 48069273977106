import * as React from 'react';

import tinytime from 'tinytime';

import { Avatar, Pill, Text } from '@components/common';

import { getReadingTime } from '../utils';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

const mdDot = <div className='tablet:block hidden rounded-full border-2 border-gray-400' />;

interface Props {
  guide: Guide;
}
export const GuideCard: React.FC<React.PropsWithChildren<Props>> = ({ guide }) => {
  const { bg_color } = guide;

  const style = { backgroundColor: `#${bg_color || '374151'}` };

  return (
    <div className='flex h-64 flex-col rounded-md p-1' style={style}>
      <div className='flex-grow pl-5 pt-4'>{!guide.published && <Pill color='white'>draft</Pill>}</div>
      <div className='bg-white p-4'>
        {guide.account.logo_url && (
          <div className='flex pb-2'>
            <img
              className='h-6 flex-shrink-0'
              src={guide.account.logo_url}
              alt={guide.account.name}
              title={guide.account.name}
            />
          </div>
        )}
        <div className='flex pb-4'>
          <div className='flex-grow'>
            <Text h='600' bold className='line-clamp-3'>
              {guide.title || 'Untitled guide'}
            </Text>
          </div>
        </div>
        <div className='flex items-center'>
          <div className='flex flex-grow items-center'>
            <Avatar user={guide.creator} />
          </div>
          <div className='flex items-center space-x-2'>
            <Text truncate h='400' color='gray-500'>
              {guide.published
                ? dateTemplate.render(guide.published_at)
                : `Created ${dateTemplate.render(guide.created_at)}`}
            </Text>
            {guide.published && mdDot}
            {guide.published && (
              <Text truncate h='400' color='gray-500'>
                {getReadingTime(guide.word_count)} read
              </Text>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
