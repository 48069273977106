import React, { forwardRef, HTMLAttributes } from 'react';

import { twMerge } from 'tailwind-merge';

import { Text } from '@components/common';
import { CheckmarkInCircleSVG } from '@components/svgs';

export const CompletedStatus = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & { textClassName?: string }>(
  ({ children, textClassName, className, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={twMerge('flex h-8 w-full flex-nowrap items-center justify-center space-x-1 bg-green-50', className)}
        {...rest}
      >
        <CheckmarkInCircleSVG className={twMerge('text-green-500', textClassName)} />
        <Text h='200' className={twMerge('no-branding text-green-500', textClassName)} bold uppercase>
          {children}
        </Text>
      </div>
    );
  }
);
