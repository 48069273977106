import cn from 'classnames';

import { PopperDropdownProps } from '@components/common/Dropdown';

const STYLES = {
  primary: 'bg-indigo-600 hover:bg-indigo-500 text-white',
  text: 'border-0',
  default: 'border-gray-200 border hover:bg-gray-50 bg-white'
};

type Params = Pick<
  PopperDropdownProps,
  | 'primary'
  | 'medium'
  | 'small'
  | 'danger'
  | 'disabled'
  | 'error'
  | 'dropdownClass'
  | 'buttonClassName'
  | 'textStyle'
  | 'aboveButton'
>;

type UseStyles = (props: Params) => {
  mainButtonClass: string;
  buttonIconClass: string;
  svgClass: string;
  dropdownWrapperClass: string;
};

export const useStyles: UseStyles = ({
  buttonClassName,
  primary,
  textStyle,
  aboveButton,
  medium,
  small,
  danger,
  disabled,
  error,
  dropdownClass
}) => {
  const svgClass = cn('w-4 h-4 ml-2', { 'transform rotate-180': aboveButton });
  const buttonIconClass = cn({ 'mr-2': medium, 'mr-1': !medium, 'text-red-500': danger });

  let style = 'default';

  if (primary) {
    style = 'primary';
  } else if (textStyle) {
    style = 'text';
  }

  const buttonClass = cn(
    'whitespace-nowrap inline-flex items-center justify-center rounded-md text-sm font-medium focus:ring focus:ring-blue focus:outline-none',
    STYLES[style],
    buttonClassName,
    { 'px-2 py-1.5': small, 'px-3 py-1.5 h-8': medium, 'px-4 py-2.5': !small && !medium }
  );

  const dropdownWrapperClass = cn(
    `inline-block outline-none text-left relative py-2 bg-white border border-gray-200 rounded-md shadow-lg ${dropdownClass}`,
    { 'opacity-50': disabled }
  );

  const mainButtonClass = cn({ 'border-red-600 shadow-sm ring-red': error }, buttonClass);

  return {
    mainButtonClass,
    buttonIconClass,
    svgClass,
    dropdownWrapperClass
  };
};
