import React, { useEffect } from 'react';

import { Button, Text } from 'components/common';
import { AddRecallSurveySVG } from 'components/svgs/AddRecallSurveySVG';
import { PersonaSurveySVG } from 'components/svgs/PersonaSurvey';
import { track } from 'components/tracking';

interface Props {
  redirectTo?: string | null;
}

export const StartResearch: React.FC<React.PropsWithChildren<Props>> = ({ redirectTo }) => {
  useEffect(() => {
    track('viewed_start_research');
  }, []);

  return (
    <div className='tablet:my-12 desktop:my-20 my-6 flex flex-col items-center'>
      <div className='tablet:px-12 desktop:flex-row desktop:space-y-0 desktop:space-x-6 flex flex-col space-y-6 px-4 text-center'>
        <div className='tablet:p-8 desktop:p-16 flex flex-1 flex-col items-center space-y-6 rounded-md border border-gray-200 p-4'>
          <AddRecallSurveySVG />
          <Text bold className='text-2xl'>
            Kickoff a new research study
          </Text>
          <Text>Schedule customer interviews, setup a survey or run a prototype test in minutes.</Text>
          <Button
            trackEvent='clicked_onboarding_page_cta'
            trackProps={{ cta: 'Start new study' }}
            outline
            primary
            href='/studies'
          >
            Start new study
          </Button>
        </div>
        <div className='tablet:p-8 desktop:p-16 flex flex-1 flex-col items-center space-y-6 rounded-md border border-gray-200 p-4'>
          <PersonaSurveySVG />
          <Text bold className='text-2xl'>
            Synthesize existing research
          </Text>
          <Text>Automatically transcribe, summarize, and create clips & highlight reels to share with your team.</Text>

          <Button
            outline
            primary
            trackEvent='clicked_onboarding_page_cta'
            trackProps={{ cta: 'Get started', action: 'repo' }}
            href='/repository'
          >
            Get started
          </Button>
        </div>
      </div>

      <Button
        trackEvent='clicked_onboarding_page_cta'
        trackProps={{ cta: 'Skip for now' }}
        className='mt-10'
        href={redirectTo || '/'}
      >
        Skip for now
      </Button>
    </div>
  );
};
