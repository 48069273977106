import React, { useRef } from 'react';

import { DomainTuple } from 'victory';

import { Card, Loading, Text } from '@components/common';
import { COLORS, LABELS } from '@components/ReportingApp/constants';
import { InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { useWindowSize } from '@components/utils';

import { useGetMetricQuery } from '../api';
import { BarMetric, MetricData, MetricDef, MetricParams, NumberMetric, StackedBarMetric } from '../types';

import { BarChart } from './BarChart';
import { NumberChart } from './NumberChart';
import { StackedBarChart } from './StackedBarChart';

function parseDate(date: string): Date {
  const [year, monthIndex, day] = date.split('-').map(Number);
  const parsedDate = new Date(year, monthIndex - 1, day);
  return parsedDate;
}

function seriesToChartData(data: Record<string, number>): any[] {
  return Object.keys(data)
    .reduce((acc, date) => {
      // do we need to dot his at all?
      const value = data[date] ? parseFloat(data[date].toString()) : null;
      return [...acc, { x: parseDate(date), y: value }];
    }, [])
    .sort((a, b) => a.x.getTime() - b.x.getTime());
}

function stackedSeriesToChartData(data: Record<string, Record<string, number>>): Record<string, any[]> {
  return Object.keys(data).reduce((all, series) => {
    all[series] = seriesToChartData(data[series]);
    return all;
  }, {});
}

function isBarMetric(metric: MetricData): metric is BarMetric {
  return metric.type === 'single_bar';
}

function isStackedBarMetric(metric: MetricData): metric is StackedBarMetric {
  return metric.type === 'stacked_bar';
}

function isNumberMetric(metric: MetricData): metric is NumberMetric {
  return metric.type === 'number';
}

interface ChartProps {
  metric: MetricData;
  size?: { width: number; height: number };
}

const Chart: React.FC<React.PropsWithChildren<ChartProps>> = ({ size, metric }) => {
  const domain = { x: [new Date(metric.starts_at), new Date(metric.ends_at)] } as { x?: DomainTuple };
  if (isNumberMetric(metric)) {
    return <NumberChart size={size} data={metric.data} unit={metric.unit} />;
  } else if (isBarMetric(metric)) {
    return (
      <BarChart
        size={size}
        period={metric.period}
        data={seriesToChartData(metric.data)}
        unit={metric.unit}
        domain={domain}
      />
    );
  } else if (isStackedBarMetric(metric)) {
    return (
      <StackedBarChart
        size={size}
        period={metric.period}
        unit={metric.unit}
        data={stackedSeriesToChartData(metric.data)}
        domain={domain}
      />
    );
  } else {
    return null;
  }
};

// this should really just be chart props?
type Props = MetricDef & MetricParams;

const Legend = ({ data }: { data: MetricData['data'] }) => {
  return (
    <div className='flex flex-wrap pr-4'>
      {Object.keys(data).map((key, index) => (
        <div key={key} className='mr-2 flex items-center space-x-2'>
          <div className='h-2 w-2 flex-shrink-0' style={{ backgroundColor: COLORS[index % COLORS.length] }} />
          <Text h='400' className='whitespace-nowrap'>
            {LABELS[key]}
          </Text>
        </div>
      ))}
    </div>
  );
};

export const Metric: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  label,
  info,
  starts_at,
  period = 'month',
  type
}) => {
  const { data: metric, isLoading, isError } = useGetMetricQuery({ id, type, starts_at, period });
  const ref = useRef<HTMLDivElement>(null);
  const { width: cardWidth = 0 } = useWindowSize(ref);

  return (
    <Card ref={ref} paddingClass='py-6' className='flex flex-col'>
      <div className='flex flex-grow items-start justify-between'>
        <div className='flex flex-grow items-center space-x-2 px-6'>
          <Text h='400' className='whitespace-nowrap' bold>
            {label}
          </Text>
          <Tooltip content={info}>
            <InfoCircleIcon className='h-4 w-4' />
          </Tooltip>
        </div>
        {metric && isStackedBarMetric(metric) && <Legend data={metric.data} />}
      </div>

      <div className='flex items-center justify-center' style={{ width: cardWidth, height: cardWidth / 2 }}>
        {isLoading && (
          <div className='relative mx-6 h-full w-full'>
            <Loading absolute />
          </div>
        )}
        {metric && <Chart size={{ width: cardWidth, height: cardWidth / 2 }} metric={metric} />}
        {isError && (
          <Text mt='4' h='400'>
            There was an error loading this metric.
          </Text>
        )}
      </div>
    </Card>
  );
};
