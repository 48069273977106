import React, { FC, useMemo } from 'react';

import { UniqueIdentifier } from '@dnd-kit/core';

import { Grid } from '@components/common/Grid';
import { SummaryColumn } from '@components/ScreenerResults/components';
import { formatMilliseconds } from '@components/ScreenerResults/components/CardSortResponse/utils';
import { getSuccessCount } from '@components/ScreenerResults/components/TreeTestResponse/utils';

interface Props {
  answers: NonNullable<ScreenerResponseAnswerValue<'tree_test'>>[];
  correctAnswers: UniqueIdentifier[][];
}

export const TreeTestSummary: FC<Props> = ({ answers, correctAnswers }) => {
  const completedAnswers = answers.filter((answer) => answer.completed);
  const completed = completedAnswers.length;

  const totalSuccess = useMemo(
    () =>
      correctAnswers.reduce((acc, path) => {
        const count = getSuccessCount(answers, path);

        return acc + count;
      }, 0),
    [correctAnswers, answers]
  );

  const overallSuccess = Math.round((totalSuccess / answers.length) * 100);

  const overallDirectness = Math.round(
    (answers.filter((answer) => answer.result.direct).length / answers.length) * 100
  );

  const avgDuration = useMemo(() => {
    const totalDuration = completedAnswers.reduce((acc, answer) => acc + (answer.time_in_task ?? 0), 0);

    return totalDuration / (completedAnswers.length || 1);
  }, [answers, completed]);

  return (
    <section className='mb-2 rounded border border-gray-200 p-4'>
      <Grid desktop={4} tablet={4} mobile={2}>
        <SummaryColumn
          value={completed}
          label='Completed'
          tooltip='The number of users who completed the test'
          iconHref='#prototypeTaskMetrics-person'
        />
        <SummaryColumn
          tooltip='The percentage of users who selected the right answer'
          value={`${overallSuccess}%`}
          label='Success rate'
          iconHref='#prototypeTaskMetrics-success'
        />
        <SummaryColumn
          tooltip='Percentage of users who responded directly (only clicking along one path), regardless of whether the answer was correct or not'
          value={`${overallDirectness}%`}
          label='Directness'
          iconHref='#prototypeTaskMetrics-rocket'
        />
        <SummaryColumn
          tooltip='How long it took users to complete the task'
          value={formatMilliseconds(avgDuration)}
          label='Avg. Duration'
          iconHref='#prototypeTaskMetrics-avgDuration'
        />
      </Grid>
    </section>
  );
};
