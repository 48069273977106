import * as React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import { AppLoader } from '@components/AppLoader';
import { SettingsNavbar } from '@components/SettingsApp/components/SettingsNavbar';
import { Unrouter } from '@components/Sidebar/mount';
import { TrackRouteChange } from '@components/TrackRouteChange';
import { exactlyOnce, renderComponent } from '@components/utils';

const AccSidebar = () => (
  <Router>
    <Unrouter />
    <TrackRouteChange />
    <AppLoader>
      <SettingsNavbar />
    </AppLoader>
  </Router>
);

export function mount(): void {
  const sidebarId = window.screen.width >= 768 ? 'sidebar-portal' : 'sidebar-mobile-portal';
  const sidebarMount = document.getElementById('account-sidebar');
  const sidebarPortal = document.getElementById(sidebarId);
  const skeleton = document.getElementById('account-sidebar-skeleton');
  if (!sidebarMount || !sidebarPortal || !skeleton) {
    return;
  }

  // watch sidebar-portal for when component is mounted
  // remove the skeleton then to prevent flash of layout change
  const onSidebarMounted = exactlyOnce(() => {
    skeleton.remove();
    sidebarPortal.removeEventListener('DOMNodeInserted', onSidebarMounted);
  });

  const observer = new MutationObserver((mutationList) =>
    mutationList
      .filter((m) => m.type === 'childList')
      .forEach((m) => {
        m.addedNodes.forEach(onSidebarMounted);
      })
  );

  observer.observe(sidebarPortal, { childList: true, subtree: true });

  renderComponent(AccSidebar, 'account-sidebar');
}
