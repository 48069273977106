import * as React from 'react';

import { Text } from '@components/common';
import { Basic } from '@components/shared/Skeleton';

export const Skeleton: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <table className='min-w-4xl mt-6 w-full overflow-auto'>
      <thead>
        <tr>
          <th className='pl-0 text-left text-sm'>
            <Basic h='5' width={45} height={20} />
          </th>
          <th className='p-3 text-left text-sm'>
            <Basic h='5' width={55} height={20} />
          </th>
          <th className='p-3 text-left text-sm'>
            <Basic h='5' width={60} height={20} />
          </th>
          <th></th>
        </tr>
      </thead>

      <tbody className='divide-y divide-gray-200'>
        <tr className='group text-sm text-gray-700'>
          <td className='p-3 pl-0'>
            <Basic h='5' width={65} height={20} />
          </td>
          <td className='p-3'>
            <Basic h='5' width={70} height={20} />
          </td>
          <td className='p-3'>
            <Basic h='5' width={150} height={20} />
          </td>
          <td className='p-3'></td>
          <td className='flex justify-end space-x-4 p-3'>
            <Basic h='5' width={20} height={20} />
            <Basic h='5' width={20} height={20} />
          </td>
        </tr>
        <tr className='group text-sm text-gray-700'>
          <td className='p-3 pl-0'>
            <Basic h='5' width={65} height={20} />
          </td>
          <td className='p-3'>
            <Basic h='5' width={70} height={20} />
          </td>
          <td className='p-3'>
            <Basic h='5' width={150} height={20} />
          </td>
          <td className='p-3'></td>
          <td className='flex justify-end space-x-4 p-3'>
            <Basic h='5' width={20} height={20} />
            <Basic h='5' width={20} height={20} />
          </td>
        </tr>
        <tr className='group text-sm text-gray-700'>
          <td className='p-3 pl-0'>
            <Basic h='5' width={65} height={20} />
          </td>
          <td className='p-3'>
            <Basic h='5' width={70} height={20} />
          </td>
          <td className='p-3'>
            <Basic h='5' width={150} height={20} />
          </td>
          <td className='p-3'></td>
          <td className='flex justify-end space-x-4 p-3'>
            <Basic h='5' width={20} height={20} />
            <Basic h='5' width={20} height={20} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
