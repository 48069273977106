import * as React from 'react';

import { useInputState } from '@components/CandidateAttrs/hooks/useInputState';
import { getWrapperClass, NO_OUTLINE } from '@components/CandidateAttrs/index';
import { Input } from '@components/common';

export const NumberInput: React.FC<React.PropsWithChildren<CandidateAttrInput<number>>> = ({
  value,
  styles,
  label,
  setValue,
  onSave,
  autoFocus,
  onFocus,
  onBlur
}) => {
  const { handleBlur, handleFocus, focused } = useInputState(value, setValue, onBlur, onFocus);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSave?.(value);
    }
  };

  const onClickOutside = () => {
    onSave?.(value);
    handleBlur();
  };

  return (
    <tr data-testid='attr-field' className={getWrapperClass(styles, focused)}>
      <td className={styles?.label}>{label}</td>
      <td className={styles?.value}>
        <Input
          type='number'
          onFocus={handleFocus}
          onBlur={onClickOutside}
          autoFocus={autoFocus}
          onKeyDown={handleKeyDown}
          style={NO_OUTLINE}
          value={value ? value.toString() : undefined}
          onChange={(v) => (v ? setValue(parseInt(v)) : undefined)}
          placeholder='Enter…'
          className='tablet:text-base w-full px-4 py-2.5'
        />
      </td>
    </tr>
  );
};
