import React from 'react';

import { useNavigate } from 'react-router-dom';

import { useKeyboardShortcut } from '@hooks/useKeyboardShortcut';

import { getKeysObject, NAV_SHORTCUT_DEFINITIONS } from './layouts/Sidebar/consts';

const SHORTCUTS = Object.values(NAV_SHORTCUT_DEFINITIONS);

export const NavShortcuts: React.FC<React.PropsWithChildren<{ spa?: boolean }>> = ({ spa }) => {
  const navigate = useNavigate();

  for (const def of SHORTCUTS) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useKeyboardShortcut({
      keys: getKeysObject(def),
      handler: () => {
        if (spa) {
          navigate(def.href);
        } else {
          window.location.href = def.href;
        }
      },
      disableWhenInputFocused: true
    });
  }

  return null;
};
