import * as React from 'react';
import { useState } from 'react';

import cn from 'classnames';

import { CaretSVG } from '@components/svgs';
import { ChangeFn } from '@components/utils';
import { RadioGroup, Select } from 'components/common';
import { StudyAttrsTable } from 'components/StudiesApp/components/StudyPublished/components/StudyAttrsTable';
import { useAccount } from 'hooks/useAccount';
import { useFeature } from 'hooks/useFeature';

import { StudyLanguageDropdown, StudyLanguages } from './components/StudyLanguage';

interface Props {
  study: Study;
  change?: ChangeFn;
}

export const AdditionalOptions: React.FC<React.PropsWithChildren<Props>> = ({ study, change }) => {
  const [maximumAsStartedInMinutes, setMaximumAsStartedInMinutes] = useState<MaximumAsStartedInMinutes>(
    study.maximum_as_started_in_minutes
  );
  const [selectedLanguage, setSelectedLanguage] = useState<StudyLanguages>(study.language || 'en');
  const [showMore, setShowMore] = useState(false);
  const hasScreenerInviteCta = useFeature('screener_invite_cta');

  const {
    account: { unique_id_attr }
  } = useAccount();

  const handleMaximumAsStartedInMinutes = (inMinutes: MaximumAsStartedInMinutes) => {
    change?.('maximum_as_started_in_minutes', inMinutes);
    setMaximumAsStartedInMinutes(inMinutes);
  };

  const handleLanguageChange = (language: StudyLanguages) => {
    change?.('language', language);
    setSelectedLanguage(language);
  };

  return (
    <>
      <a
        className='h400 block pb-6'
        role='button'
        tabIndex={0}
        onClick={() => {
          setShowMore(!showMore);
        }}
      >
        {showMore ? <span>Additional setup</span> : <span>Show additional setup</span>}
        <CaretSVG className={cn('ml-2 inline transform', { 'rotate-90': !showMore, '-rotate-90': showMore })} />
      </a>
      {showMore && (
        <>
          {unique_id_attr === 'phone_number' && (
            <div>
              <label htmlFor='comms_medium' className='h500-bold mb-2 block'>
                I will contact candidates:
              </label>

              <RadioGroup<Study['comms_medium']>
                id='comms_medium'
                options={[
                  { label: 'By Email', helper: '', value: 'email' },
                  { label: 'By SMS', helper: '', value: 'sms' }
                ]}
                selected={study.comms_medium}
                onChange={(v) => change?.('comms_medium', v)}
              />
            </div>
          )}
          <div>
            <div className='h500-bold mb-2 block'>Custom study attributes</div>
            <span className='h400 block text-gray-500'>Make your study more recognizable and organize better.</span>
            <StudyAttrsTable study={study} smallButton />
          </div>
          {study.style !== 'video_call' && (
            <div className='mb-6'>
              <div className='h500-bold mb-2 block'>Automatically release participant slots</div>
              <span className='h400 mb-2 block text-gray-500'>
                If a participant starts your study but does not finish within the specified time their slot will be
                released. If they eventually attempt to complete the study they will still be able to do so.
              </span>
              <RadioGroup
                id='maximum_as_started_in_minutes'
                options={[
                  { label: '15 minutes', helper: '', value: '15' },
                  { label: '1 hour', helper: '', value: '60' },
                  { label: '4 hours', helper: '', value: '240' },
                  { label: 'Never', helper: '', value: '' }
                ]}
                selected={(maximumAsStartedInMinutes || '').toString()}
                onChange={(v) =>
                  handleMaximumAsStartedInMinutes(v === '' ? null : (Number(v) as MaximumAsStartedInMinutes))
                }
              />
            </div>
          )}
          <div className='mb-6'>
            <div className='h500-bold mb-2 block'>Participant experience language</div>
            <span className='h400 mb-2 block text-gray-500'>
              We’ll translate study landing pages, web pages, and other communications.
            </span>
            <StudyLanguageDropdown selectedLanguage={selectedLanguage} onChange={handleLanguageChange} />
          </div>
        </>
      )}
    </>
  );
};
