import React from 'react';

import { GoogleMeetSVG, LinkSVG, LocationSVG, MicrosoftTeamsSVG, WebexSVG, ZoomSVG } from '@components/svgs';

export const PROVIDERS: Record<
  Study['video_url_type'],
  {
    name: string;
    icon?: React.FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>;
  }
> = {
  zoom: {
    name: 'Zoom',
    icon: ZoomSVG
  },
  google_meet: {
    name: 'Google Meet',
    icon: GoogleMeetSVG
  },
  webex: {
    name: 'Webex',
    icon: WebexSVG
  },
  microsoft_teams: {
    name: 'Microsoft Teams',
    icon: MicrosoftTeamsSVG
  },
  in_person: {
    name: 'In Person',
    icon: LocationSVG
  },
  manual: {
    name: 'Manual',
    icon: LinkSVG
  }
};
