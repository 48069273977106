import React, { FC, useMemo, useState } from 'react';

import cn from 'classnames';
import { Placement } from '@floating-ui/react';

import { Text } from '@components/common';
import { Popover } from '@components/Popover';
import { Enums, Models } from '@components/SurveyBuilder';
import { CloseSVG, MicrophoneSVG } from '@components/svgs';
import { useUnmoderatedContext } from 'components/Unmoderated/hooks/useUnmoderatedContext';

import { useMicrophoneState } from '../hooks/useMicrophoneState';

interface Props {
  isDisabled?: boolean;
  message: string;
}

export const MicrophoneWarning: FC<Props> = ({ isDisabled, message }) => {
  const [keepClosed, setKeepClosed] = useState<boolean>(false);

  const { blocks, deviceType, topBarUnmoderatedLayout } = useUnmoderatedContext();

  const permissionsBlock: Models.Block<Enums.Kind.permissions> | undefined = blocks.find(
    ({ kind }) => kind === Enums.Kind.permissions
  );

  const { hasLowAudioVolume } = useMicrophoneState();

  const showSetting = permissionsBlock?.blockable.settings?.thinking_out_loud_notice;

  const showMessage = !isDisabled && !keepClosed && hasLowAudioVolume;

  const messagePlacement = useMemo(() => {
    let placement: Placement;

    if (deviceType === 'desktop') {
      if (topBarUnmoderatedLayout) {
        placement = 'right';
      } else {
        placement = 'top';
      }
    } else {
      placement = 'bottom';
    }

    return placement;
  }, [deviceType, topBarUnmoderatedLayout]);

  if (!showSetting) {
    return null;
  }

  return (
    <Popover
      className='flex items-start'
      colorScheme='dark'
      handle={<MicrophoneSVG className={cn({ 'text-red-600': showMessage })} />}
      isCompact
      isOpen={showMessage}
      offset={topBarUnmoderatedLayout ? -4 : 8}
      placement={messagePlacement}
      width={273}
    >
      <Text h='200' style={{ color: 'white' }}>
        {message}
      </Text>

      <button
        type='button'
        aria-label='Close microphone level warning'
        className='ml-1 mt-1 text-white'
        onClick={() => setKeepClosed(true)}
      >
        <CloseSVG className='h-3 w-3' />
      </button>
    </Popover>
  );
};
