import * as React from 'react';

import { Provider as CollectionView } from 'components/stores/view';

import CandidatesListPage from './CandidatesListPage';

const DEFAULT_VISIBLE_COLUMNS = [
  'name',
  'extra.title',
  'extra.company',
  'email',
  'participations_count',
  'last_contacted_at',
  'extra.location',
  'extra.notes',
  'average_rating'
];

export const DEFAULT_CANDIDATES_VIEW: CollectionView = {
  sort: {
    value: 'name',
    desc: false
  },
  filters: '?filters[]=eligibility%20is%20true',
  layout: 'list',
  columns: DEFAULT_VISIBLE_COLUMNS
};

export const CandidatesIndex: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <CollectionView scope='candidates' defaultable default={DEFAULT_CANDIDATES_VIEW}>
      <CandidatesListPage />
    </CollectionView>
  );
};
