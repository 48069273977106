import React, { CSSProperties, forwardRef, HTMLAttributes, useMemo } from 'react';

import { useVideoSeekBarContext } from '../hooks/useVideoSeekBarContext';

import { Progress } from './Progress';

interface Props extends HTMLAttributes<HTMLDivElement> {
  startTime: number;
  endTime: number;
}

export const Chapter = forwardRef<HTMLDivElement, Props>(({ endTime, startTime, ...rest }, ref) => {
  const {
    state: { currentTime, bufferTime, hoverTime, max, trackWidth }
  } = useVideoSeekBarContext();

  const hasGap = startTime === 0 || endTime < max;

  const left = useMemo(() => (startTime / max) * trackWidth, [max, startTime, trackWidth]);

  const width = useMemo(() => {
    let width = ((endTime - startTime) / max) * trackWidth;

    if (hasGap) {
      width -= 2;
    }

    return width;
  }, [endTime, max, startTime, trackWidth]);

  const style: CSSProperties = {
    left,
    width,
    marginRight: hasGap ? 2 : 0
  };

  return (
    <div
      ref={ref}
      className={'absolute h-full transform bg-white bg-opacity-20 hover:scale-y-150'}
      style={style}
      {...rest}
    >
      <Progress chapterEndTime={endTime} chapterStartTime={startTime} kind='buffer' time={bufferTime} />
      <Progress chapterEndTime={endTime} chapterStartTime={startTime} kind='hover' time={hoverTime} />
      <Progress chapterEndTime={endTime} chapterStartTime={startTime} kind='play' time={currentTime} />
    </div>
  );
});
