import * as React from 'react';

import { Text } from '@components/common';
import { UserCombobox } from '@components/shared/UserCombobox';
import { noop, without } from '@components/utils';

import { FieldRow } from '../../components/FieldRow';
import type { BookingDetailsFormFieldComponentProps } from '../types';

import { GuestDisplay } from './GuestDisplay';
import { GuestDropdownButton } from './GuestDropdownButton';

export const Guests: React.FC<BookingDetailsFormFieldComponentProps & { participationId: number }> = ({
  editMode,
  bookingDetail,
  participationId,
  onChange,
  type = 'candidate_event'
}) => {
  const event = bookingDetail[type];

  const label = type === 'candidate_event' ? 'Moderators' : 'Observers';

  const eventGuestsKey = type === 'candidate_event' ? 'moderators' : 'observers';

  const guests: CalendarEventGuest[] = event?.[eventGuestsKey] || [];
  const additionalGuests: CalendarEventGuest[] = event?.additional_guests || [];

  const eventType: CalendarEventType = type === 'candidate_event' ? 'candidate' : 'team';

  const handleMakeHost = (userId: number, email: string) => {
    if (type === 'candidate_event') {
      onChange({
        [type]: {
          moderator_id: userId,
          moderator_email: email
        }
      });
    }
  };

  return (
    <FieldRow label={label}>
      <div className='flex w-full flex-col space-y-3'>
        {editMode && (
          <UserCombobox
            context={{ participation_id: participationId, calendar_event_type: eventType }}
            placeholder='Enter name or email…'
            onSelect={(items) => {
              if (items[0].type === 'user') {
                const {
                  user: { name, email }
                } = items[0];
                onChange({
                  [type]: {
                    [eventGuestsKey]: [...(guests || []), { name, email }]
                  }
                });
              }
            }}
          />
        )}
        {guests && (
          <div className='flex flex-col space-y-4 py-2.5'>
            {guests.map((guest) =>
              editMode ? (
                <GuestDropdownButton
                  key={guest.email}
                  guest={guest}
                  isHost={type === 'candidate_event' && guest.email === bookingDetail.candidate_event?.moderator_email}
                  onClickRemove={() =>
                    onChange({
                      [type]: {
                        [eventGuestsKey]: without(guests, guest)
                      }
                    })
                  }
                  onClickMakeHost={type === 'candidate_event' ? handleMakeHost : undefined}
                />
              ) : (
                <GuestDisplay key={guest.email} guest={guest} />
              )
            )}
            {additionalGuests.map((guest) =>
              editMode ? (
                <GuestDropdownButton
                  key={guest.email}
                  guest={guest}
                  isHost={false}
                  isAdditional={true}
                  onClickRemove={noop}
                />
              ) : (
                <GuestDisplay key={guest.email} guest={guest} />
              )
            )}
            {!editMode && guests.length === 0 && (
              <Text color='gray-500' h='400'>
                None.
              </Text>
            )}
          </div>
        )}
      </div>
    </FieldRow>
  );
};
