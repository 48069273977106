import * as React from 'react';

export const invite: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg
    className='mr-3 h-4 w-4 text-white group-hover:text-gray-300'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.5625 11.0625C7.5625 11.9908 7.93125 12.881 8.58763 13.5374C9.244 14.1938 10.1342 14.5625 11.0625 14.5625C11.9908 14.5625 12.881 14.1938 13.5374 13.5374C14.1938 12.881 14.5625 11.9908 14.5625 11.0625C14.5625 10.1342 14.1938 9.244 13.5374 8.58763C12.881 7.93125 11.9908 7.5625 11.0625 7.5625C10.1342 7.5625 9.244 7.93125 8.58763 8.58763C7.93125 9.244 7.5625 10.1342 7.5625 11.0625Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M11.0625 9.3125V12.8125'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M9.3125 11.0625H12.8125'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M1.4375 11.0625C1.43794 10.4116 1.59969 9.77091 1.90828 9.19776C2.21688 8.62461 2.66269 8.13686 3.20587 7.77814C3.74905 7.41941 4.37266 7.20087 5.02094 7.14208C5.66923 7.08328 6.32198 7.18606 6.92083 7.44121'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M2.96875 3.84375C2.96875 4.48193 3.22226 5.09397 3.67352 5.54523C4.12478 5.99648 4.73682 6.25 5.375 6.25C6.01318 6.25 6.62522 5.99648 7.07648 5.54523C7.52774 5.09397 7.78125 4.48193 7.78125 3.84375C7.78125 3.20557 7.52774 2.59353 7.07648 2.14227C6.62522 1.69101 6.01318 1.4375 5.375 1.4375C4.73682 1.4375 4.12478 1.69101 3.67352 2.14227C3.22226 2.59353 2.96875 3.20557 2.96875 3.84375V3.84375Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
  </svg>
);
