import * as React from 'react';
import { useEffect, useState } from 'react';

import pluralize from 'pluralize';
import qs from 'qs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { api } from '@api/reduxApi';
import { Alert, Button, Spinner, Text } from '@components/common';
import { WindowLayout, WindowLayoutBody, WindowStickyHeader } from '@components/layouts/WindowLayout';
import { compact } from '@components/utils';
import { useSidebar } from '@hooks/useSidebar';
import { useTeams } from '@hooks/useTeams';
import { useToaster } from '@stores/toaster';

import * as toasts from '../toasts';
import { getDuplicateMappings, getUnmappedColumns, hasMultipleUniqueIds, isMissingUniqueId } from '../utils';

import { MappingTable } from './MappingTable';
import { ReviewSlideout } from './ReviewSlideout';
import { SettingsPanel } from './SettingsPanel';
import { WelcomeModal } from './WelcomeModal';

const DEBOUNCE_RATE = 500;

export type ValidationError =
  | 'unique_id_required'
  | 'multiple_unique_ids'
  | 'duplicate_mappings'
  | 'contact_access_needs_public'
  | 'contact_access_missing_team';

export const EditCustomerImportPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const sidebar = useSidebar();
  const { id: idStr } = useParams<{ id: string }>();
  const id = parseInt(idStr || '0');
  const { search } = useLocation();
  const studyIdStr = qs.parse(search, { ignoreQueryPrefix: true }).study_id as string;
  const studyId = studyIdStr ? parseInt(studyIdStr) : null;
  const [customerImport, setCustomerImport] = useState<CustomerImport | null>(null);

  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
  const [duplicateColumnIndexes, setDuplicateColumnIndexes] = useState<number[]>([]);
  const [erroredColumnIndexes, setErroredColumnIndexes] = useState<number[]>([]);
  const [showErrors, setShowErrors] = useState(false);

  const [reviewSlideoutOpen, setReviewSlideoutOpen] = useState(false);

  const navigate = useNavigate();
  const showToast = useToaster();

  const { data: study, isLoading: isLoadingStudy } = api.useGetSimpleStudyQuery(customerImport?.project_id || 0, {
    skip: !customerImport?.project_id
  });
  const { findTeam, isLoading: isLoadingTeams } = useTeams();
  const studyTeamId = study?.team_id || null;
  const studyHasTeam = study && !!studyTeamId;
  const studyTeam = findTeam(studyTeamId || 0);
  const { data } = api.useGetCustomerImportQuery(id, { skip: !id });
  const [update, { isLoading: isUpdating }] = api.useUpdateCustomerImportMutation();
  const [run, { isLoading: isRunning, isSuccess }] = api.useRunCustomerImportMutation();

  const { callback: debouncedUpdate } = useDebouncedCallback(update, DEBOUNCE_RATE);

  useEffect(() => {
    if (studyId && customerImport && !customerImport?.project_id) {
      setCustomerImport({ ...customerImport, project_id: studyId });
    }
  }, [studyId, customerImport?.project_id]);

  useEffect(() => {
    if (data && !customerImport) {
      setCustomerImport(data);
    }
  }, [data, customerImport]);

  useEffect(() => {
    sidebar.setHidden(true);

    return () => sidebar.setHidden(false);
  }, []);

  useEffect(() => {
    if (customerImport && isSuccess) {
      setReviewSlideoutOpen(false);
      showToast(toasts.SUCCESS_RUN);
      const url = customerImport.project_id
        ? `/studies/${customerImport.project_id}/participations?status=shortlisted`
        : '/candidates';
      navigate(url);
    }
  }, [isSuccess]);

  const handleChangeCustomerImport = (customerImport: CustomerImport) => {
    setErroredColumnIndexes(erroredColumnIndexes.filter((index) => !customerImport.hidden_column_ids.includes(index)));
    setCustomerImport(customerImport);
    debouncedUpdate(customerImport);

    if (showErrors) {
      validate(customerImport);
    }
  };

  const handleSubmit = () => {
    if (customerImport) {
      run({ id: customerImport.id });
    }
  };

  const handleClickReview = () => {
    if (customerImport) {
      const isValid = validate(customerImport);

      if (isValid) {
        setReviewSlideoutOpen(true);
      } else {
        setShowErrors(true);
      }
    }
  };

  const validate = (customerImport: CustomerImport): boolean => {
    const duplicateMappings = getDuplicateMappings(customerImport);
    const duplicateMappedColumnIndexes = compact(
      customerImport.mapping.map((col, i) => duplicateMappings.includes(col) && i)
    );
    const newValidationErrors: ValidationError[] = compact([
      isMissingUniqueId(customerImport) && 'unique_id_required',
      hasMultipleUniqueIds(customerImport) && 'multiple_unique_ids',
      duplicateMappedColumnIndexes.length > 0 && 'duplicate_mappings',
      !!study && !studyHasTeam && customerImport.contact_access !== 'public' && 'contact_access_needs_public',
      !!study &&
        studyHasTeam &&
        customerImport.contact_access === 'needs_team' &&
        !customerImport.team_ids.includes(studyTeamId) &&
        'contact_access_missing_team'
    ]);

    const unmappedColumns = getUnmappedColumns(customerImport);
    setErroredColumnIndexes(unmappedColumns);
    setDuplicateColumnIndexes(duplicateMappedColumnIndexes);
    setValidationErrors(newValidationErrors);

    const isValid = newValidationErrors.length === 0 && unmappedColumns.length === 0;
    if (isValid && showErrors) {
      setShowErrors(false);
    }

    return isValid;
  };

  return (
    <WindowLayout>
      <WindowStickyHeader>
        <div className='flex-1'>
          <Text h='700'>Import CSV</Text>
        </div>
        {isUpdating && (
          <div className='mr-4 flex items-center space-x-2'>
            <Spinner className='h-4 w-4' />
            <Text h='400' color='gray-500'>
              Saving…
            </Text>
          </div>
        )}
        <Button
          loading={isRunning || isLoadingStudy || isLoadingTeams}
          disabled={!customerImport || isLoadingStudy || isLoadingTeams || isRunning}
          primary
          onClick={handleClickReview}
        >
          Review & import
        </Button>
      </WindowStickyHeader>
      <WindowLayoutBody className='px-page py-6'>
        {erroredColumnIndexes.length > 0 && (
          <Alert
            type='error'
            heading={`${erroredColumnIndexes.length} unmapped ${pluralize('columns', erroredColumnIndexes.length)}`}
            className='mb-4'
          >
            Please map all CSV columns to an attribute, or uncheck the columns you don't need
          </Alert>
        )}
        {validationErrors.includes('unique_id_required') && (
          <Alert
            type='error'
            heading={`'${customerImport?.id_attr || 'id'}' must be mapped to import`}
            className='mb-4'
          >
            Please select a column for candidates’ {customerImport?.id_attr || 'id'} to continue
          </Alert>
        )}
        {validationErrors.includes('multiple_unique_ids') && (
          <Alert type='error' heading='Multiple unique IDs selected' className='mb-4'>
            Cannot import email alongside another unique ID
          </Alert>
        )}
        {validationErrors.includes('duplicate_mappings') && (
          <Alert type='error' heading='Multiple mappings for the same column' className='mb-4'>
            Some columns are mapped to the same field.
          </Alert>
        )}
        {validationErrors.includes('contact_access_needs_public') && (
          <Alert type='error' heading='Contact access must be public' className='mb-4'>
            {study?.title} doesn’t belong to any team, so contact access must be set to “Anyone on the workspace”.
          </Alert>
        )}
        {validationErrors.includes('contact_access_missing_team') && (
          <Alert type='error' heading={`Contact access must include ${studyTeam?.name}`} className='mb-4'>
            {study?.title} belongs to {studyTeam?.name}, so contact access must include this team.
          </Alert>
        )}
        <div className='mb-4'>
          <Text mb='1' bold>
            Review import
          </Text>
          <Text h='400'>
            Map CSV columns to attributes, and manage how data will be imported. Uncheck columns you don’t need.
          </Text>
        </div>
        <div className='desktop:flex-row flex w-full flex-col-reverse gap-4'>
          <div className='desktop:w-auto w-full flex-1 flex-shrink overflow-x-auto'>
            {customerImport && (
              <MappingTable
                duplicateColumnIndexes={duplicateColumnIndexes}
                erroredColumnIndexes={erroredColumnIndexes}
                model={customerImport}
                save={handleChangeCustomerImport}
              />
            )}
          </div>
          <div className='desktop:w-80 monitor:w-112 w-full flex-shrink-0'>
            {customerImport && (
              <SettingsPanel
                validationErrors={validationErrors}
                customerImport={customerImport}
                onChange={handleChangeCustomerImport}
              />
            )}
          </div>
        </div>
        <WelcomeModal />
      </WindowLayoutBody>
      {reviewSlideoutOpen && data && (
        <ReviewSlideout
          isSubmitting={isRunning}
          customerImport={data}
          onClose={() => setReviewSlideoutOpen(false)}
          onSubmit={handleSubmit}
        />
      )}
    </WindowLayout>
  );
};
