import React, { useState } from 'react';

import { api } from '@api/reduxApi';
import { Button } from '@components/common';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { StudyCheckout } from '@components/StudyCheckout';
import { useToaster } from '@stores/toaster';
import { useTrackDuration } from 'hooks/useTrackDuration';

import { StudyPublishValidator } from '../hooks/useStudyPublishValidator';
import * as toasts from '../toasts';

interface Props {
  hasErrors: boolean;
  study: Study;
  saving: boolean;
  setSaving: (value: boolean) => void;
  request: ExternalCandidatesRequest | null;
}

// TODO: this should use the same as study
type EditStudyTab = 'calendar' | 'task' | 'survey';

function getErrorMessage(study: Study, data: Record<string, any>): string | null {
  if (data == null) return null;

  const errKeys: EditStudyTab[] = [];
  if (data.start_url) {
    switch (study.style) {
      case 'video_call':
        errKeys.push('calendar');
        break;
      case 'online_task':
      case 'unmoderated_test':
        errKeys.push('task');
        break;
      case 'survey':
        errKeys.push('survey');
        break;
    }
  }
  return errKeys.length > 0 ? `Please check your ${errKeys.join(' and ')} settings.` : null;
}

const getButtonText: (params: { fundingRequired: boolean; request: ExternalCandidatesRequest | null }) => string = ({
  fundingRequired,
  request
}) => {
  if (!fundingRequired) {
    return 'Create';
  } else if (request?.state === 'submitted_publish') {
    return 'Fund & Publish Recruitment…';
  } else {
    return 'Create & Fund…';
  }
};
export const PublishStudyButtonWithCheckout: React.FC<React.PropsWithChildren<Props>> = ({
  hasErrors,
  study,
  saving,
  setSaving,
  request
}) => {
  const [checkoutOpen, setCheckoutOpen] = useState<boolean>(false);
  const showToast = useToaster();
  const trackPublishStudy = useTrackDuration({ action: 'publish_study' });

  const [transitionStudy] = api.useTransitionStudyMutation();

  // TODO: Do I need to pay - call backend?
  const fundingRequired = study.external_candidates_enabled || study.incentive_method === 'tremendous';
  const createButtonText = getButtonText({ fundingRequired, request });

  async function openCheckout() {
    setCheckoutOpen(true);
  }

  async function handleSubmit() {
    trackPublishStudy.start();
    setCheckoutOpen(false);
    setSaving(true);

    try {
      // if funding is ok - try to publish
      await transitionStudy({ id: study.id, transition: 'activate' }).unwrap();
    } catch (err) {
      setSaving(false);
      // if error - stop execution and show error
      return showToast(toasts.failedPublish('activate', getErrorMessage(study, err.data as any) as any));
    }

    const path = study.external_candidates_enabled
      ? `/studies/${study.id}/recruitment_requests`
      : `/studies/${study.id}`;

    // is it ok that we don't call trackPublishStudy.stop() if funding or publish request fails?
    trackPublishStudy.stop();
    window.location.href = path;
  }

  return (
    <>
      <RestrictedButton action='Publish study' limit='studies' permission='createStudy'>
        {hasErrors && <Button inactive>{createButtonText}</Button>}
        {!hasErrors && (
          <Button onClick={openCheckout} primary>
            {createButtonText}
          </Button>
        )}
      </RestrictedButton>
      {checkoutOpen && <StudyCheckout study={study} onClose={() => setCheckoutOpen(false)} onSuccess={handleSubmit} />}
    </>
  );
};
