import { endOfSurveyIds } from '@components/GQSurveyBuilder/components/SkipLogicSlideOut';
import { compact } from 'components/utils';

type Labels = { label: string | string[] };

// returns true if the answer meets the condition
export const evaluateSkipLogic = (
  question: ScreenerField,
  answerAsStr: string | Labels,
  skipLogic: SkipLogic | null
): boolean => {
  if (['number', 'number_range'].includes(question.field_type)) {
    return evaluateNumber(question, answerAsStr as string, skipLogic);
  }
  if (question.field_type === 'date') {
    return evaluateDate(question, answerAsStr as string, skipLogic);
  }
  if (question.field_type === 'location') {
    return evaluateLocation(question, answerAsStr as string, skipLogic);
  }
  if (['yes_no', 'single_choice'].includes(question.field_type)) {
    return evaluateOptionsQuestion(question, [(answerAsStr as Labels).label as string], skipLogic);
  }
  if (['multiple_choice'].includes(question.field_type)) {
    return evaluateOptionsQuestion(question, (answerAsStr as Labels).label as string[], skipLogic);
  }

  const value = loose(skipLogic?.value as any);
  const answer = loose(answerAsStr as string);

  switch (skipLogic?.op) {
    case 'equal':
      return value === answer;
    case 'starts_with':
      return answer.startsWith(value);
    case 'ends_with':
      return answer.endsWith(value);
    case 'contains':
      return answer.includes(value);
    case 'not_contains':
      return !answer.includes(value);
    case 'any_answer':
      return !!answerAsStr;
    case 'no_answer':
      return !answerAsStr;
    default:
      return false;
  }
};

const evaluateNumber = (question: ScreenerField, answerAsStr: string, skipLogic: SkipLogic | null): boolean => {
  const value = parseInt(skipLogic?.value as any, 10);
  const answer = parseInt(answerAsStr, 10);

  switch (skipLogic?.op) {
    case 'equal':
      return answer === value;
    case 'greater_than':
      return answer > value;
    case 'less_than':
      return answer < value;
    case 'any_answer':
      return !!answerAsStr;
    case 'no_answer':
      return !answerAsStr;
    default:
      return false;
  }
};

const evaluateDate = (question: ScreenerField, answerAsStr: string, skipLogic: SkipLogic | null): boolean => {
  const value = parseInt(skipLogic?.value as any, 10);
  const answer = Date.parse(answerAsStr);

  switch (skipLogic?.op) {
    case 'equal':
      return answer === value;
    case 'greater_than':
      return answer > value;
    case 'less_than':
      return answer < value;
    case 'any_answer':
      return !!answerAsStr;
    case 'no_answer':
      return !answerAsStr;
    default:
      return false;
  }
};

const evaluateLocation = (question: ScreenerField, answerAsStr: string, skipLogic: SkipLogic | null): boolean => {
  const haystack = (skipLogic?.value || '').trim().toLowerCase();
  const needle = answerAsStr.trim().toLowerCase();

  switch (skipLogic?.op) {
    case 'equal':
      return haystack === needle;
    case 'not_equal':
      return haystack !== needle;
    case 'any_answer':
      return !!answerAsStr;
    case 'no_answer':
      return !answerAsStr;
    default:
      return false;
  }
};

const evaluateOptionsQuestion = (
  question: ScreenerField,
  answersStr: string[],
  skipLogic: SkipLogic | null
): boolean => {
  const isArray = skipLogicValueIsArray(question.field_type, skipLogic);
  const rawValue = skipLogic?.value || '';

  const values = (isArray ? rawValue.split(',').filter((opt) => opt && opt.length > 0) : [rawValue])
    .filter((v) => v !== '')
    .map(loose);

  const answers = answersStr.map((v) => escapedCommas(loose(v)));

  if (!values) return false;

  switch (skipLogic?.op) {
    case 'equal':
      return values.every((val) => answers.includes(val)) && values.length === answers.length;
    case 'matches_all':
      return values.every((val) => answers.includes(val));
    case 'matches_any':
      return values.some((val) => answers.includes(val));
    case 'match_not_exact':
      return values.length !== answers.length || values.some((val) => !answers.includes(val));
    case 'matches_none':
      return !values.some((val) => answers.includes(val));
    case 'any_answer':
      return Boolean(answers.length && !!answers[0]);
    case 'no_answer':
      return Boolean(!answers.length || !answers[0]);
    default:
      return false;
  }
};

export const fixSkipLogicQuestions = (originalQuestions: ScreenerField[]): ScreenerField[] => {
  const ids = originalQuestions.map((q) => q.id);
  const exists = (id: number) => ids.includes(id);

  const questions = originalQuestions.map((q) => {
    const multiSkipLogic = (q.skip_logic ?? []).map((sl: SkipLogic | null | undefined) => {
      const isArray = skipLogicValueIsArray(q.field_type, sl);
      const current_skip_logic = sl;
      let skip_logic: any = { ...current_skip_logic };
      if (
        current_skip_logic &&
        ['multiple_choice', 'single_choice'].includes(q.field_type) &&
        !['no_answer', 'any_answer'].includes(current_skip_logic.op)
      ) {
        const current_value = current_skip_logic.value || '';
        const values = isArray ? slValueToArray(current_value) : [current_value];

        skip_logic.value = slValueFromArray(
          values.filter((v) => (v === 'Other' && q.other) || q.options?.includes(unescapedCommas(v)))
        );

        if (!skip_logic.value) skip_logic = null;
      }

      if (skip_logic && !([null, ...endOfSurveyIds].includes(skip_logic.to) || exists(skip_logic.to))) {
        skip_logic = null;
      }

      return skip_logic;
    });

    const clean = compact(multiSkipLogic);

    return {
      ...q,
      skip_logic: clean.length > 0 ? clean : null
    };
  });

  if (questions.length > 0) {
    questions[questions.length - 1].skip_logic = null;
  }

  return questions;
};

export const skipLogicValueIsArray = (field_type: string, sl: SkipLogic | null | undefined) =>
  field_type === 'multiple_choice' ||
  ['matches_all', 'matches_any', 'match_not_exact', 'matches_none'].includes(sl?.op || '');

export const slValueToArray = (str: string): string[] =>
  str
    .split(',')
    .map((opt) => unescapedCommas(opt))
    .filter((opt) => opt && opt.length > 0);

export const slValueFromArray = (arr: string[]): string =>
  arr
    .map((opt) => escapedCommas(opt))
    .filter((opt) => opt && opt.length > 0)
    .join(',');

const loose = (str: string) => str.toLowerCase().trim();

export const escapedCommas = (str: string): string => str.replace(/,/g, '⌑');
export const unescapedCommas = (str: string): string => str.replace(/⌑/g, ',');
