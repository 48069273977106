import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button, Loading, Modal, Text } from '@components/common';
import { Toast, useToaster } from '@components/stores/toaster';
import { moneyFormat } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { PaymentMethodSelect, useStudyFunder } from 'components/Wallet';

interface Props {
  study: Study;
  amountInCents: number;
  onCancel: () => void;
  onSuccess: () => void;
}
export const failedPublish = (step: 'transition' | 'funding' | 'invites'): Toast => ({
  heading: 'Failed to publish study!',
  icon: 'error',
  text: {
    transition: 'Something went wrong while trying to activate this study. Please try again.',
    funding: 'Something went wrong while trying to fund this study. Please try again or check your payment details.',
    invites: 'Something went wrong while scheduling your invite emails.'
  }[step]
});

export const FundStudyModal: React.FC<React.PropsWithChildren<Props>> = ({
  study,
  amountInCents,
  onCancel,
  onSuccess
}) => {
  const {
    account: { card_type, card_last4 }
  } = useAccount();
  const [loading, setLoading] = useState<boolean>(true);
  const showToast = useToaster();
  const funder = useStudyFunder({ study });

  const funderProps = React.useMemo(() => {
    if (study.funding.usd_outstanding >= amountInCents) {
      return {};
    } else {
      return { participant_limit: (study.participant_limit || 0) + 1 };
    }
  }, [study]);

  useEffect(() => {
    (async () => {
      await funder.prepare(funderProps);
      setLoading(false);
    })();
  }, []);

  async function handleSubmit() {
    setLoading(true);

    const res = await funder.process(funderProps);
    if (res === 'error') {
      setLoading(false);
      showToast(failedPublish('funding'));
    } else {
      onSuccess();
    }
  }

  return (
    <Modal
      size='sm'
      title='Fund study'
      onClose={onCancel}
      renderFooter={() =>
        !loading && funder ? (
          <>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={handleSubmit} primary>
              Fund {study.currencySymbol}
              {moneyFormat(funder.total / 100)}
            </Button>
          </>
        ) : undefined
      }
    >
      {loading && <Loading absolute />}
      {!loading && funder && (
        <>
          <Text>In order to send these incentives you will need to first fund the study.</Text>
          <Text mt='2' mb='4'>
            Amount: <span className='font-bold'>{funder.displayTotal}</span>
          </Text>
          <PaymentMethodSelect
            disabled={loading}
            selected={funder.selectedMethod}
            methods={funder.methods}
            onSelect={funder.selectMethod}
          />
          {funder && funder.currency !== 'USD' && (
            <p className='h400 mt-4 text-gray-700'>
              <span className='font-bold'>${moneyFormat(funder.total / 100)}</span> USD will be charged
              {funder.currency === 'USD'
                ? '.'
                : `based on the current
            USD/${funder.currency} exchange rate ${funder.allocation ? `of ${funder.allocation.exchange_rate}.` : '.'}`}
            </p>
          )}
        </>
      )}
    </Modal>
  );
};
