import React, { useMemo, useState } from 'react';

import { Button, Modal, ModalHeading, Text } from '@components/common';
import { DropdownCombobox, DropdownItem } from '@components/common/DropdownCombobox';
import { UNSAFE_CUSTOM_ATTR_NAMES } from '@components/config';
import { FIELD_TYPE_LABELS } from '@components/GQSurveyBuilder/components/AttributeSelector/constants';
import {
  invalidAttr,
  invalidAttrName,
  readOnlyAttr
} from '@components/GQSurveyBuilder/components/AttributeSelector/toasts';
import { canMapToAttribute } from '@components/GQSurveyBuilder/components/AttributeSelector/utils';
import { AttributeSVG } from '@components/svgs';
import { find } from '@components/utils';
import { useToaster } from '@stores/toaster';
import { usePermission } from 'hooks/usePermission';
import { usePopUp } from 'hooks/usePopUp';

const renderDropdownItem = (item: DropdownItem, isHighlighted: boolean) => (
  <div className={`flex flex-col space-y-1 ${item.disabled ? 'opacity-50' : ''}`}>
    {!item._isNew && (
      <div className='flex items-center justify-between'>
        <span>{item.label}</span>
        {item.data?.attr_type && <Text h='400'>{FIELD_TYPE_LABELS[item.data.attr_type]}</Text>}
      </div>
    )}

    {item._isNew && <span className={isHighlighted ? 'text-white' : 'text-indigo-600'}>{`”${item.value}”`}</span>}
    {item._isNew && <span className='text-xs'>Save this as a new attribute</span>}
  </div>
);

interface Props {
  editable: boolean;
  attributable: boolean;
  question: ScreenerField;
  hasResponses: boolean;
  onChange: (question: ScreenerField) => void;
  attrs: Attr_[];
}

export const AttributeSelector: React.FC<React.PropsWithChildren<Props>> = ({
  editable,
  attributable,
  question,
  hasResponses,
  onChange,
  attrs
}) => {
  const { open, openPopUp, closePopUp, PopUp, ref } = usePopUp();
  const [modalOpen, setModalOpen] = useState(false);
  const showToast = useToaster();
  const canCreate = usePermission('manageAttrs')();

  const dropdownItems: DropdownItem[] = useMemo(
    () =>
      attrs.map((a) => ({
        label: a.label,
        value: a.name,
        search: [a.label, a.name].join(' '),
        disabled: !canMapToAttribute(question.field_type, a.attr_type) || a.write_locked,
        data: a
      })),
    [attrs, question.field_type]
  );

  const selectedItem = useMemo(
    () => find(dropdownItems, ({ value }) => value === question.candidate_attr),
    [attrs, question.candidate_attr]
  );

  const sortedItems = dropdownItems.sort((a, b) => {
    if (a.disabled == b.disabled) {
      return a.label.localeCompare(b.label);
    } else if (a.disabled) {
      return 1;
    } else {
      return -1;
    }
  });

  const isSet = editable && question.candidate_attr && question.candidate_attr != '' && attributable;

  const okayToProceed = (proceed) => {
    proceed ? openPopUp() : closePopUp();
    setModalOpen(false);
  };

  const onSelect = (attr: DropdownItem | null) => {
    if (attr?.disabled && attr?.data?.write_locked) {
      showToast(readOnlyAttr());
      return;
    }
    if (attr?.disabled) {
      showToast(invalidAttr(attr.data?.attr_type || '', question.field_type));
      return;
    }
    if (attr?._isNew && UNSAFE_CUSTOM_ATTR_NAMES.includes(attr.value)) {
      showToast(invalidAttrName(attr.value));
      return;
    }
    onChange({
      ...question,
      candidate_attr: attr ? attr.value : '',
      _create_attr: attr?._isNew
    });
    closePopUp();
  };

  const onDropdownClick = () => {
    if (open) {
      closePopUp();
    } else {
      if (hasResponses) {
        setModalOpen(true);
      } else {
        openPopUp();
      }
    }
  };

  return (
    <div ref={ref} className='relative'>
      {modalOpen && (
        <Modal
          onClose={() => okayToProceed(false)}
          renderFooter={() => (
            <div className='flex w-full flex-row justify-end space-x-4'>
              <Button onClick={() => okayToProceed(false)}>Cancel</Button>
              <Button primary onClick={() => okayToProceed(true)}>
                Continue
              </Button>
            </div>
          )}
        >
          <ModalHeading>Note</ModalHeading>
          <Text h='400' className='mt-2'>
            Any existing responses to this question will be used to set the attribute you select -- potentially
            overriding existing attribute data.
          </Text>
        </Modal>
      )}

      <Button small primary={!!isSet} disabled={!editable} outline onClick={onDropdownClick}>
        <AttributeSVG />
        <span className='ml-2'>{question.candidate_attr || 'Attribute'}</span>
      </Button>

      <PopUp open={open} className='top-10'>
        <div className='min-w-80 rounded-md border border-gray-200 bg-white p-4 shadow-lg'>
          <DropdownCombobox
            absolute={false}
            allowCreate={canCreate}
            autoFocus
            noBorder
            items={sortedItems}
            selectedItem={selectedItem}
            inputClassName='text-sm mb-3'
            placeholder='Type or select'
            submitOnEnter
            onSelect={onSelect}
            renderItem={renderDropdownItem}
          />

          {question.candidate_attr && (
            <div className='mt-6 flex justify-end'>
              <Button
                link
                onClick={() => {
                  onChange({ ...question, candidate_attr: null });
                  closePopUp();
                }}
              >
                Remove
              </Button>
            </div>
          )}
        </div>
      </PopUp>
    </div>
  );
};
