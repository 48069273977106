import { useState } from 'react';

import { ChannelName, useWebsocket } from '@hooks/useWebsocket';

export const useRecordSync = <Type extends { id: number }>(record: Type, channel: ChannelName): Type => {
  const [syncedRecord, setRecord] = useState(record);

  useWebsocket<{ id: Type['id'] }, Type>({
    channel,
    params: { id: syncedRecord.id },
    onReceived: setRecord
  });

  return syncedRecord;
};
