import * as React from 'react';
import { useMemo, useState } from 'react';

import cn from 'classnames';
import Tippy from '@tippyjs/react';

import { api } from '@api/reduxApi';
import { Button, Checkbox, Text } from '@components/common';
import { CORE_ATTRS } from '@components/config';
import { Error } from '@components/shared/AI';
import { NewAttrModal } from '@components/shared/NewAttrModal';
import { ArrowRightSVG, ChevronLeftSVG, ChevronRightSVG, InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { without } from '@components/utils';

import { SelectAttribute } from './SelectAttribute';

export type MappingTableProps = {
  model: CustomerImport;
  save: (model: CustomerImport) => void | Promise<void>;
  duplicateColumnIndexes: number[];
  erroredColumnIndexes: number[];
};

export const MappingTable: React.FC<React.PropsWithChildren<MappingTableProps>> = ({
  duplicateColumnIndexes,
  erroredColumnIndexes,
  model,
  save
}) => {
  const { data: candidateAttrs } = api.useGetCandidateAttrsQuery();

  const [createAttrModalOpen, setCreateAttrModalOpen] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);
  const maxPreviewIndex = model.preview?.length - 1 || 0;

  const attrs = useMemo(
    () => [...CORE_ATTRS, ...(candidateAttrs || []).map((a) => ({ ...a, name: `extra:${a.name}` }))],
    [candidateAttrs]
  );
  const isHidden = (index: number) => model.hidden_column_ids.includes(index);

  const mapping = model.mapping && model.mapping.length > 0 ? model.mapping : model.headers.map(() => '');

  const onToggleHidden = (index: number) => {
    save({
      ...model,
      hidden_column_ids: isHidden(index) ? without(model.hidden_column_ids, index) : [...model.hidden_column_ids, index]
    });
  };

  const areAllSelected = model.hidden_column_ids.length === 0;
  const onToggleSelectAll = () => {
    save({
      ...model,
      hidden_column_ids: areAllSelected ? model.headers.map((_, i) => i) : []
    });
  };

  return (
    <div className='min-w-140 w-full overflow-x-auto rounded border border-gray-200 bg-white'>
      <div className='border-b border-gray-200 p-4'>
        <div className='flex items-center'>
          <div className='inline-flex w-1/3 items-center space-x-2 pr-4'>
            <Checkbox value='Select all' selected={areAllSelected} onChange={onToggleSelectAll} />
            <Text h='400' bold>
              CSV column
            </Text>
          </div>
          <div className='inline-flex w-1/3 items-center pr-4'>
            <Text h='400' bold className='mr-2'>
              Attribute
            </Text>
            <Tooltip content='Attributes are various types of data that get stored against each person.'>
              <InfoCircleIcon className='mr-1 h-4 w-4 flex-shrink-0' />
            </Tooltip>
            <Button className='ml-auto' icon='plus' small link onClick={() => setCreateAttrModalOpen(true)}>
              Create
            </Button>
          </div>
          <div className='inline-flex w-1/3 items-center space-x-2'>
            <Text h='400' bold>
              Example
            </Text>
            <Tooltip content='Example of data from your CSV file'>
              <InfoCircleIcon className='h-4 w-4 flex-shrink-0' />
            </Tooltip>
            <div className='flex flex-1 justify-end space-x-2'>
              <Tippy content='See previous example'>
                <button
                  aria-label='Previous example'
                  disabled={previewIndex === 0}
                  className={cn('rounded-full p-1', {
                    'hover:bg-gray-50 hover:text-indigo-600': previewIndex > 0,
                    'text-gray-400': previewIndex === 0
                  })}
                  onClick={() => setPreviewIndex(previewIndex - 1)}
                >
                  <ChevronLeftSVG />
                </button>
              </Tippy>
              <Tippy content='See next example'>
                <button
                  aria-label='Next example'
                  disabled={previewIndex >= maxPreviewIndex}
                  className={cn('rounded-full p-1', {
                    'hover:bg-gray-50 hover:text-indigo-600': previewIndex < maxPreviewIndex,
                    'text-gray-400': previewIndex >= maxPreviewIndex
                  })}
                  onClick={() => setPreviewIndex(previewIndex + 1)}
                >
                  <ChevronRightSVG />
                </button>
              </Tippy>
            </div>
          </div>
        </div>
      </div>
      <div className='px-4 py-2'>
        <div className='divide-y divide-gray-200'>
          {model.headers.map((header, i) => (
            <div key={header} className='flex items-center py-2'>
              <div className='inline-flex w-1/3 items-center space-x-2 pr-4'>
                <Checkbox value={header} selected={!isHidden(i)} onChange={() => onToggleHidden(i)} />
                <Text className='flex-grow' h='400' truncate>
                  {header}
                </Text>
                <ArrowRightSVG className='flex-shrink-0' />
              </div>
              <div className='w-1/3 pr-4'>
                <SelectAttribute
                  isErrored={erroredColumnIndexes.includes(i) || duplicateColumnIndexes.includes(i)}
                  disabled={isHidden(i)}
                  defaultLabel={header}
                  attrs={attrs}
                  value={mapping[i]}
                  onChange={async (value) => {
                    const newMapping = [...mapping];
                    newMapping[i] = value;
                    await save({ ...model, mapping: newMapping });
                  }}
                />
                {duplicateColumnIndexes.includes(i) && (
                  <div className='mt-2'>
                    <Error>Multiple CSV columns are mapped to this attribute</Error>
                  </div>
                )}
                {erroredColumnIndexes.includes(i) && (
                  <div className='mt-2'>
                    <Error>Select attribute or uncheck the row</Error>
                  </div>
                )}
              </div>
              <div className='w-1/3 truncate pr-4'>
                <Text as='span' h='400'>
                  {model.preview?.[previewIndex]?.[i] || '—'}
                </Text>
              </div>
            </div>
          ))}
        </div>
      </div>
      <NewAttrModal
        open={createAttrModalOpen}
        onClose={() => setCreateAttrModalOpen(false)}
        onSubmit={() => setCreateAttrModalOpen(false)}
      />
    </div>
  );
};
