import React from 'react';

import { UniqueIdentifier } from '@dnd-kit/core';
import { ColumnDef } from '@tanstack/react-table';
import Tippy from '@tippyjs/react';

import { formatMilliseconds } from '@components/ScreenerResults/components/CardSortResponse/utils';
import { ClickPointer } from '@components/ScreenerResults/components/PrototypeTestResponse/components/ClickPointer';
import { Table } from '@components/shared/Table';
import { TreeItem } from '@components/SurveyBuilder/types/models';
import { InfoCircleIcon, VideoCameraSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

import { getAnswerLabels } from '../../utils';

export type DefinitionFnReturn = ColumnDef<NonNullable<ScreenerResponseAnswerValue<'tree_test'>>>[];

type Args = {
  setPreview: (value: NonNullable<ScreenerResponseAnswerValue<'tree_test'>>) => void;
  nodes: TreeItem[];
  correctAnswers: UniqueIdentifier[][];
};

export type DefinitionFn = (args: Args) => DefinitionFnReturn;

export const getTableColumnDefs: DefinitionFn = ({ correctAnswers, setPreview, nodes }) => {
  const columns: DefinitionFnReturn = [
    {
      id: 'participation_name',
      accessorKey: 'participation_name',
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'tree_test'>>>
          {...props}
          render={({ row }) => (
            <div className='flex w-full items-center justify-between space-x-2 truncate px-2'>
              <div className='truncate'>{row.original.participation_name || 'Unnamed'}</div>
              {row.original.session_uuid && (
                <Tippy
                  aria={{ content: 'labelledby', expanded: 'auto' }}
                  appendTo={() => document.body}
                  interactive
                  arrow={false}
                  content='View full session room.'
                >
                  <a href={`/sessions/${row.original.session_uuid}`} target='_blank'>
                    <VideoCameraSVG className='h-4 w-4' />
                  </a>
                </Tippy>
              )}
            </div>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Name
        </Table.ColumnHeader>
      )
    },

    {
      id: 'completed',
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'tree_test'>>>
          {...props}
          render={({ row }) => {
            return (
              <div className='px-2'>
                <ClickPointer size='lg' failure={!row.original.completed} />
              </div>
            );
          }}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Complete
          <Tooltip content='Percentage of participants who completed the sort.'>
            <InfoCircleIcon className='ml-1 h-4 w-4' />
          </Tooltip>
        </Table.ColumnHeader>
      )
    },
    {
      id: 'successful',
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'tree_test'>>>
          {...props}
          render={({ row }) => {
            const isCorrectAnswer = correctAnswers?.some(
              (answer) => answer.join(',') === row.original.result.answer.join(',')
            );

            return (
              <div className='px-2'>
                <ClickPointer size='lg' failure={!isCorrectAnswer} />
              </div>
            );
          }}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Successful
          <Tooltip content='Users who selected the correct answer'>
            <InfoCircleIcon className='ml-1 h-4 w-4' />
          </Tooltip>
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'tree_test'>>>
          render={({ row }) => (
            <div className='px-2'>
              <ClickPointer size='lg' failure={!row.original.result.direct} />
            </div>
          )}
          {...props}
        />
      ),
      id: 'direct',
      accessorFn: (row) => row.result.direct,
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Direct
          <Tooltip content='Users who navigated to their response directly (without clicking off-path)'>
            <InfoCircleIcon className='ml-1 h-4 w-4' />
          </Tooltip>
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'tree_test'>>> {...props} />,
      id: 'total_time',
      accessorFn: (row) => (row.time_in_task ? formatMilliseconds(row.time_in_task) : '0 s'),
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Total time
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'tree_test'>>>
          {...props}
          render={({ row }) => {
            const answerLabels = getAnswerLabels(row.original.result, nodes);

            const detailsText =
              answerLabels.length > 1
                ? `+${answerLabels.length - 1} > ${answerLabels[answerLabels.length - 1]}`
                : answerLabels[answerLabels.length - 1];

            return (
              <Tooltip content='Details'>
                <button
                  aria-label='Details'
                  onClick={() => setPreview(row.original)}
                  className='h-400 mx-2 inline-flex w-full items-center overflow-hidden rounded border border-gray-200 p-1 hover:bg-gray-100'
                >
                  <span className='truncate px-2'>{detailsText}</span>
                </button>
              </Tooltip>
            );
          }}
        />
      ),
      id: 'responses',
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Response
        </Table.ColumnHeader>
      )
    }
  ];

  return columns;
};
