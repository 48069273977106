import * as React from 'react';
import { useEffect } from 'react';

import pluralize from 'pluralize';

import { Button, Modal, ModalHeading, Text } from '@components/common';
import { useToaster } from '@stores/toaster';

import { useResetTranscriptMutation } from '../api';

interface Props {
  clipsCount: number;
  isOpen: boolean;
  onClose: () => void;
  transcript: Transcript;
}

export const ResetTranscriptModal = ({ clipsCount, isOpen, onClose, transcript }: Props) => {
  const [resetTranscript, { isLoading, isError }] = useResetTranscriptMutation();

  const handleConfirmButtonClick = async () => {
    await resetTranscript(transcript);
    onClose();
  };

  const showToast = useToaster();

  useEffect(() => {
    if (isError) {
      showToast({
        heading: 'Could not reset transcript',
        text: 'Something went wrong. Please try again or contact support.',
        icon: 'error'
      });
    }
  }, [isError]);

  if (!isOpen) return null;

  return (
    <Modal onClose={onClose}>
      <ModalHeading className='mb-4'>Restore original transcript?</ModalHeading>
      <div className='mb-2'>
        <Text mb='4' h='400'>
          Any prior transcript edits will be restored to the original transcript version.
        </Text>
        {clipsCount > 0 && (
          <Text mb='4' h='400'>
            {pluralize('highlights', clipsCount, true)} will be removed.
          </Text>
        )}
      </div>
      <div className='flex justify-end'>
        <Button onClick={onClose} link>
          Cancel
        </Button>
        <Button onClick={handleConfirmButtonClick} loading={isLoading} primary>
          Confirm &amp; restore
        </Button>
      </div>
    </Modal>
  );
};
