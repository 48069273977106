import { useDispatch } from 'react-redux';
import { AnyAction, current, ThunkDispatch } from '@reduxjs/toolkit';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';

import { mergeDeep } from '@helpers/mergeDeep';

import { surveyBuilderApi as api } from '../api';
import * as Enums from '../types/enums';
import * as Models from '../types/models';

type Result = [
  {
    add: (block: Models.Block) => void;
    remove: (block: Models.Block) => void;
    update: <T extends `${Enums.Kind}`>(
      id: number,
      block: Partial<Omit<Models.Block<T>, 'blockable'>> & { blockable?: Partial<Models.Blockable<T>> }
    ) => void;
  },
  {
    blocks?: Models.Block[];
  }
];

export const usePatchBlocksQuery = (surveyId: number): Result => {
  const { data: blocks } = api.useGetSurveyBuilderBlocksQuery(surveyId);

  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, AnyAction>>();

  const reorder = (draft: MaybeDrafted<Models.Block[]>) => {
    for (let i = 0; i < draft.length; i++) {
      draft[i] = { ...draft[i], position: i + 1 };
    }
  };

  const add: Result[0]['add'] = (block) => {
    const patch = api.util.updateQueryData('getSurveyBuilderBlocks', surveyId, (draft) => {
      const thankYouBlockIndex = draft.findIndex(({ kind }) => kind === Enums.Kind.thankYou);

      if (thankYouBlockIndex > -1) {
        draft.splice(thankYouBlockIndex, 0, block);
      } else {
        draft.push(block);
      }

      reorder(draft);
    });

    dispatch(patch);
  };

  const remove: Result[0]['remove'] = (block) => {
    const patch = api.util.updateQueryData('getSurveyBuilderBlocks', surveyId, (draft) => {
      const index = draft.findIndex(({ id }) => id === block.id);
      draft.splice(index, 1);

      reorder(draft);
    });

    dispatch(patch);
  };

  const update: Result[0]['update'] = (id, block) => {
    const patch = api.util.updateQueryData('getSurveyBuilderBlocks', surveyId, (draft) => {
      const index = draft.findIndex((b) => b.id === id);
      draft[index] = mergeDeep(current(draft[index]), block);

      reorder(draft);
    });

    dispatch(patch);
  };

  return [{ add, remove, update }, { blocks }];
};
