import * as React from 'react';
import { useState } from 'react';

import pluralize from 'pluralize';
import tinytime from 'tinytime';

import { Select } from '@components/common';
import { formatBarLabels } from 'components/ScreenerResults/ScreenerChart/helpers';
import { compact, moneyFormat } from 'components/utils';

import { StudyFunder } from './types';

const dateFormat = tinytime('{YYYY}-{Mo}-{DD}', { padMonth: true });

interface FunderSelectorProps {
  funder: StudyFunder;
  allowExternalIncentive?: boolean;
  allowExternalCredit?: boolean;
}

interface PaymentSelectorProps {
  selected: PaymentMethod;
  methods: PaymentMethod[];
  disabled?: boolean;
  onSelect: (method: PaymentMethod) => void;
  allowExternalIncentive?: boolean;
  allowExternalCredit?: boolean;
  isError?: 'funding' | 'external_incentive' | 'external_credit' | 'not_selected' | null;
}

export const FunderMethodSelect: React.FC<React.PropsWithChildren<FunderSelectorProps>> = ({
  funder,
  allowExternalIncentive = false,
  allowExternalCredit = false
}) => {
  return (
    <PaymentMethodSelect
      methods={funder.methods}
      onSelect={funder.selectMethod}
      selected={funder.selectedMethod}
      allowExternalIncentive={allowExternalIncentive}
      allowExternalCredit={allowExternalCredit}
    />
  );
};

function cardLabel(card: CardPaymentMethod): string {
  if (card.processor_id === 'card') {
    return 'Card used for payment';
  }
  const cardDetails = `${card.brand} ending in ${card.last4}`;
  if (card.label) {
    return `${card.label} (${cardDetails})`;
  } else {
    return cardDetails;
  }
}

export const PaymentMethodSelect: React.FC<React.PropsWithChildren<PaymentSelectorProps>> = ({
  disabled,
  selected,
  methods,
  onSelect,
  allowExternalIncentive = false,
  allowExternalCredit = false,
  isError
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(selected);
  const options = compact(
    methods.map((method) => {
      if (method.kind === 'wallet') {
        return {
          label: `${method.processor_id === 'account' ? 'Wallet' : 'Team Wallet'} (balance: $${moneyFormat(
            method.amount_in_cents / 100
          )})`,
          value: method.processor_id
        };
      } else if (method.kind === 'external_incentive') {
        return {
          label: `Recruitment Incentive Credits (balance: $${moneyFormat(method.amount_in_cents / 100)})`,
          value: method.processor_id,
          disabled: !allowExternalIncentive
        };
      } else if (method.kind === 'external_credit') {
        const availableString =
          method.denomination === 'credits'
            ? pluralize('credit', method.available, true)
            : `$${moneyFormat(method.available)}`;
        const expirationString = method.expires_at ? dateFormat.render(new Date(method.expires_at)) : 'N/A';
        return {
          label: `Recruitment Fee Credits (balance: ${availableString}, expiration: ${expirationString})`,
          value: method.processor_id,
          disabled: !allowExternalCredit
        };
      } else {
        return {
          label: cardLabel(method as CardPaymentMethod),
          value: method.processor_id
        };
      }
    })
  ) as any;

  function onChangeHandler(v) {
    const selectedPaymentMethod = methods.find(({ processor_id }) => processor_id === v);

    if (selectedPaymentMethod) {
      onSelect(selectedPaymentMethod);
      setSelectedPaymentMethod(selectedPaymentMethod);
    }
  }

  return (
    <Select
      error={!!isError}
      placeholder='Choose payment method'
      value={selectedPaymentMethod?.processor_id}
      options={options}
      onChange={onChangeHandler}
      disabled={disabled}
      className='payment-method-select'
    />
  );
};
