import * as React from 'react';

const AssetsZDSIcon: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className }) => (
  <svg className={className} width='72' height='61' viewBox='0 0 72 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.41104 13.2988C4.29184 13.299 4.17755 13.3464 4.09326 13.4307C4.00897 13.515 3.96156 13.6292 3.96143 13.7484V60.2394C3.96156 60.3586 4.00897 60.4729 4.09326 60.5572C4.17755 60.6415 4.29183 60.6889 4.41104 60.6891H62.7014C62.8206 60.6889 62.9349 60.6415 63.0192 60.5572C63.1034 60.4729 63.1508 60.3586 63.1509 60.2394V21.2112C63.1508 21.092 63.1034 20.9777 63.0192 20.8934C62.9349 20.8091 62.8206 20.7617 62.7014 20.7615H29.2465C28.9069 20.7618 28.5795 20.6353 28.3283 20.4067L20.8071 13.5729C20.6131 13.3964 20.3601 13.2986 20.0978 13.2988H4.41104Z'
      fill='white'
    />
    <path
      d='M31.3318 7.29092C31.259 7.24823 31.1722 7.23618 31.0905 7.2574C31.0087 7.27862 30.9388 7.33139 30.8959 7.40412L13.6338 36.7788C13.5912 36.8516 13.5791 36.9384 13.6003 37.0201C13.6215 37.1018 13.6743 37.1718 13.747 37.2146L37.4115 51.1211C37.4843 51.1638 37.5711 51.1758 37.6528 51.1546C37.7345 51.1334 37.8045 51.0806 37.8473 51.0079L55.1094 21.6332C55.1521 21.5604 55.1641 21.4736 55.1429 21.3919C55.1217 21.3102 55.0689 21.2402 54.9962 21.1974L31.3318 7.29092Z'
      fill='#FACA15'
    />
    <path
      d='M31.6941 8.68579C31.6212 8.6431 31.5344 8.63104 31.4527 8.65227C31.371 8.67349 31.3011 8.72625 31.2582 8.79899L15.0286 36.4167C14.9859 36.4895 14.9739 36.5763 14.9951 36.658C15.0163 36.7397 15.0691 36.8097 15.1418 36.8525L37.0493 49.7265C37.1221 49.7692 37.2089 49.7812 37.2906 49.76C37.3723 49.7388 37.4423 49.686 37.4851 49.6133L53.7147 21.9956C53.7574 21.9228 53.7695 21.836 53.7482 21.7543C53.727 21.6726 53.6742 21.6026 53.6015 21.5598L31.6941 8.68579Z'
      fill='white'
    />
    <path
      d='M27.5551 22.3287L43.6975 31.8147C43.7515 31.8465 43.8114 31.8673 43.8735 31.876C43.9356 31.8847 43.9988 31.881 44.0595 31.8652C44.1202 31.8495 44.1772 31.8219 44.2273 31.7841C44.2774 31.7463 44.3195 31.699 44.3513 31.6449C44.383 31.5908 44.4039 31.531 44.4125 31.4689C44.4212 31.4068 44.4175 31.3436 44.4018 31.2829C44.386 31.2222 44.3584 31.1651 44.3206 31.1151C44.2828 31.065 44.2355 31.0229 44.1814 30.9911L28.0391 21.5051C27.985 21.4732 27.9252 21.4522 27.863 21.4435C27.8008 21.4348 27.7376 21.4384 27.6768 21.4541C27.616 21.4698 27.5589 21.4974 27.5088 21.5352C27.4587 21.573 27.4165 21.6204 27.3847 21.6745C27.3529 21.7286 27.3321 21.7885 27.3234 21.8507C27.3148 21.9129 27.3184 21.9761 27.3343 22.0369C27.3501 22.0977 27.3778 22.1547 27.4157 22.2048C27.4536 22.2548 27.501 22.2969 27.5551 22.3286V22.3287Z'
      fill='#FACA15'
    />
    <path
      d='M31.926 14.5741L42.6353 20.8674C42.7862 20.9561 43.0168 20.8436 43.1505 20.6162C43.2841 20.3887 43.2701 20.1325 43.1193 20.0438L32.4099 13.7505C32.259 13.6618 32.0284 13.7743 31.8948 14.0017C31.7611 14.2291 31.7751 14.4854 31.926 14.5741Z'
      fill='#FACA15'
    />
    <path
      d='M30.5385 16.935L41.2478 23.2284C41.3987 23.317 41.6294 23.2045 41.763 22.9771C41.8967 22.7497 41.8827 22.4934 41.7318 22.4048L31.0225 16.1114C30.8716 16.0228 30.6409 16.1353 30.5073 16.3627C30.3737 16.5901 30.3876 16.8463 30.5385 16.935Z'
      fill='#FACA15'
    />
    <path
      d='M25.9904 24.9914L42.1327 34.4775C42.2419 34.5415 42.3721 34.5596 42.4946 34.5277C42.6172 34.4959 42.722 34.4166 42.7862 34.3075C42.8503 34.1983 42.8685 34.0682 42.8367 33.9456C42.8049 33.8231 42.7258 33.7182 42.6167 33.6539L26.4744 24.1678C26.3652 24.1038 26.235 24.0857 26.1125 24.1176C25.99 24.1495 25.8851 24.2287 25.821 24.3378C25.7568 24.447 25.7386 24.5772 25.7704 24.6997C25.8022 24.8223 25.8813 24.9272 25.9904 24.9914Z'
      fill='#FACA15'
    />
    <path
      d='M24.4261 27.6543L40.5684 37.1404C40.6776 37.2044 40.8078 37.2225 40.9303 37.1906C41.0528 37.1587 41.1577 37.0795 41.2218 36.9704C41.286 36.8612 41.3042 36.7311 41.2724 36.6085C41.2406 36.4859 41.1615 36.381 41.0524 36.3168L24.9101 26.8307C24.856 26.7989 24.7962 26.7781 24.734 26.7694C24.6719 26.7608 24.6087 26.7644 24.548 26.7802C24.4873 26.796 24.4303 26.8235 24.3802 26.8613C24.3302 26.8991 24.288 26.9464 24.2563 27.0005C24.2245 27.0546 24.2037 27.1144 24.195 27.1765C24.1863 27.2386 24.19 27.3019 24.2058 27.3626C24.2215 27.4233 24.2491 27.4803 24.2869 27.5303C24.3247 27.5804 24.372 27.6225 24.4261 27.6543V27.6543Z'
      fill='#FACA15'
    />
    <path
      d='M22.8604 30.3174L39.0027 39.8035C39.1119 39.8674 39.2421 39.8854 39.3645 39.8535C39.487 39.8216 39.5918 39.7424 39.6559 39.6333C39.72 39.5242 39.7382 39.3941 39.7065 39.2716C39.6748 39.1491 39.5957 39.0442 39.4867 38.9799L23.3444 29.4938C23.2903 29.462 23.2305 29.4412 23.1684 29.4326C23.1063 29.4239 23.043 29.4276 22.9823 29.4433C22.9216 29.4591 22.8646 29.4867 22.8145 29.5245C22.7645 29.5623 22.7224 29.6095 22.6906 29.6636C22.6588 29.7177 22.638 29.7775 22.6293 29.8396C22.6207 29.9018 22.6243 29.965 22.6401 30.0257C22.6559 30.0864 22.6834 30.1434 22.7212 30.1935C22.759 30.2435 22.8063 30.2856 22.8604 30.3174V30.3174Z'
      fill='#FACA15'
    />
    <path
      d='M21.2962 32.9803L37.4385 42.4664C37.4926 42.4982 37.5524 42.519 37.6145 42.5277C37.6767 42.5363 37.7399 42.5327 37.8006 42.5169C37.8613 42.5011 37.9183 42.4736 37.9684 42.4358C38.0184 42.398 38.0605 42.3507 38.0923 42.2966C38.1241 42.2425 38.1449 42.1827 38.1536 42.1206C38.1622 42.0585 38.1586 41.9952 38.1428 41.9345C38.127 41.8738 38.0995 41.8168 38.0617 41.7668C38.0239 41.7167 37.9766 41.6746 37.9225 41.6428L21.7802 32.1567C21.671 32.0925 21.5407 32.0744 21.4181 32.1062C21.2955 32.1381 21.1906 32.2173 21.1264 32.3265C21.0622 32.4357 21.044 32.566 21.0759 32.6886C21.1077 32.8112 21.187 32.9161 21.2962 32.9803Z'
      fill='#FACA15'
    />
    <path
      d='M19.731 35.6434L35.8733 45.1295C35.9826 45.1937 36.1128 45.2119 36.2354 45.18C36.358 45.1482 36.4629 45.0689 36.5271 44.9597C36.5913 44.8505 36.6095 44.7203 36.5776 44.5976C36.5458 44.475 36.4665 44.3701 36.3573 44.3059L20.215 34.8198C20.1609 34.7881 20.1011 34.7672 20.039 34.7586C19.9769 34.7499 19.9136 34.7536 19.8529 34.7693C19.7922 34.7851 19.7352 34.8127 19.6852 34.8505C19.6351 34.8883 19.593 34.9356 19.5612 34.9896C19.5294 35.0437 19.5086 35.1035 19.4999 35.1656C19.4913 35.2278 19.4949 35.291 19.5107 35.3517C19.5265 35.4124 19.554 35.4694 19.5918 35.5195C19.6296 35.5695 19.6769 35.6116 19.731 35.6434Z'
      fill='#FACA15'
    />
    <path
      d='M45.9816 26.4204C47.3709 26.4204 48.4971 25.2942 48.4971 23.9049C48.4971 22.5156 47.3709 21.3893 45.9816 21.3893C44.5923 21.3893 43.4661 22.5156 43.4661 23.9049C43.4661 25.2942 44.5923 26.4204 45.9816 26.4204Z'
      fill='#FACA15'
    />
    <path
      d='M11.2486 15.7505C11.166 15.7331 11.0798 15.7491 11.009 15.795C10.9382 15.841 10.8885 15.9132 10.8709 15.9957L3.78278 49.3215C3.76531 49.4041 3.78132 49.4903 3.82729 49.5611C3.87326 49.6319 3.94544 49.6816 4.02799 49.6992L30.8755 55.4095C30.9581 55.4269 31.0442 55.4109 31.115 55.3649C31.1858 55.319 31.2355 55.2468 31.2532 55.1642L38.3413 21.8384C38.3587 21.7558 38.3427 21.6697 38.2967 21.5989C38.2508 21.5281 38.1786 21.4784 38.096 21.4607L11.2486 15.7505Z'
      fill='#FACA15'
    />
    <path
      d='M12.0333 16.959C11.9508 16.9415 11.8646 16.9575 11.7938 17.0035C11.723 17.0495 11.6733 17.1216 11.6557 17.2042L4.99152 48.5367C4.97405 48.6193 4.99006 48.7054 5.03603 48.7762C5.082 48.847 5.15417 48.8967 5.23673 48.9144L30.0909 54.2006C30.1735 54.2181 30.2597 54.2021 30.3305 54.1561C30.4013 54.1102 30.451 54.038 30.4686 53.9554L37.1328 22.623C37.1502 22.5404 37.1342 22.4542 37.0882 22.3834C37.0423 22.3126 36.9701 22.2629 36.8875 22.2453L12.0333 16.959Z'
      fill='white'
    />
    <path
      d='M30.7333 35.1062L12.4196 31.211C12.3582 31.1981 12.2999 31.1732 12.2481 31.1376C12.1963 31.1021 12.152 31.0568 12.1178 31.0041C12.0835 30.9515 12.06 30.8926 12.0486 30.8309C12.0371 30.7691 12.0379 30.7057 12.051 30.6443C12.064 30.5829 12.0891 30.5247 12.1247 30.4729C12.1603 30.4212 12.2057 30.377 12.2584 30.3428C12.3111 30.3087 12.3701 30.2853 12.4318 30.2739C12.4936 30.2626 12.557 30.2635 12.6184 30.2767L30.932 34.1718C30.9934 34.1848 31.0517 34.2097 31.1035 34.2452C31.1553 34.2807 31.1996 34.3261 31.2338 34.3787C31.2681 34.4314 31.2916 34.4902 31.3031 34.552C31.3145 34.6137 31.3137 34.6771 31.3006 34.7385C31.2876 34.8 31.2625 34.8582 31.2269 34.9099C31.1913 34.9617 31.1459 35.0059 31.0932 35.04C31.0405 35.0742 30.9816 35.0976 30.9198 35.1089C30.858 35.1203 30.7946 35.1194 30.7333 35.1062Z'
      fill='#FACA15'
    />
    <path
      d='M32.7427 26.4345L20.5929 23.8503C20.4217 23.8139 20.3274 23.5753 20.3823 23.3172C20.4372 23.0592 20.6205 22.8796 20.7916 22.916L32.9414 25.5001C33.1126 25.5365 33.2069 25.7752 33.152 26.0332C33.0971 26.2913 32.9139 26.4709 32.7427 26.4345Z'
      fill='#FACA15'
    />
    <path
      d='M32.1727 29.1128L20.0228 26.5286C19.8517 26.4922 19.7574 26.2535 19.8123 25.9955C19.8671 25.7375 20.0504 25.5578 20.2216 25.5942L32.3714 28.1784C32.5426 28.2148 32.6368 28.4535 32.582 28.7115C32.5271 28.9695 32.3438 29.1492 32.1727 29.1128Z'
      fill='#FACA15'
    />
    <path
      d='M30.0912 38.1271L11.7776 34.232C11.6539 34.2054 11.5457 34.1309 11.4769 34.0247C11.4081 33.9185 11.3843 33.7894 11.4106 33.6656C11.4369 33.5418 11.5113 33.4336 11.6173 33.3646C11.7234 33.2956 11.8525 33.2715 11.9763 33.2976L30.2899 37.1927C30.3513 37.2058 30.4095 37.2308 30.4612 37.2663C30.5129 37.3019 30.557 37.3472 30.5912 37.3998C30.6253 37.4524 30.6488 37.5113 30.6602 37.5729C30.6717 37.6346 30.6708 37.6979 30.6578 37.7593C30.6447 37.8206 30.6197 37.8788 30.5842 37.9305C30.5486 37.9822 30.5033 38.0264 30.4507 38.0605C30.3981 38.0947 30.3392 38.1182 30.2776 38.1296C30.2159 38.141 30.1526 38.1402 30.0912 38.1271Z'
      fill='#FACA15'
    />
    <path
      d='M29.4489 41.1481L11.1353 37.253C11.0115 37.2265 10.9034 37.1519 10.8346 37.0457C10.7658 36.9395 10.7419 36.8104 10.7683 36.6866C10.7946 36.5629 10.8689 36.4546 10.975 36.3856C11.0811 36.3166 11.2102 36.2925 11.334 36.3186L29.6476 40.2138C29.709 40.2268 29.7671 40.2518 29.8188 40.2874C29.8705 40.3229 29.9147 40.3683 29.9488 40.4209C29.983 40.4735 30.0065 40.5323 30.0179 40.594C30.0293 40.6556 30.0285 40.719 30.0154 40.7803C30.0024 40.8417 29.9774 40.8998 29.9418 40.9515C29.9063 41.0032 29.8609 41.0474 29.8083 41.0816C29.7557 41.1157 29.6969 41.1392 29.6352 41.1506C29.5735 41.162 29.5102 41.1612 29.4489 41.1481Z'
      fill='#FACA15'
    />
    <path
      d='M28.8062 44.1693L10.4926 40.2742C10.4312 40.2612 10.3729 40.2363 10.3211 40.2008C10.2693 40.1652 10.225 40.1199 10.1908 40.0672C10.1565 40.0146 10.133 39.9557 10.1216 39.894C10.1101 39.8323 10.1109 39.7689 10.124 39.7074C10.137 39.646 10.1621 39.5878 10.1977 39.536C10.2333 39.4843 10.2787 39.4401 10.3314 39.406C10.3841 39.3718 10.443 39.3484 10.5048 39.337C10.5666 39.3257 10.63 39.3266 10.6914 39.3398L29.005 43.2349C29.1287 43.2615 29.2368 43.336 29.3056 43.4422C29.3744 43.5484 29.3983 43.6775 29.372 43.8013C29.3456 43.9251 29.2713 44.0333 29.1652 44.1023C29.0592 44.1713 28.93 44.1954 28.8062 44.1693Z'
      fill='#FACA15'
    />
    <path
      d='M28.1634 47.1906L9.8498 43.2954C9.78836 43.2825 9.73007 43.2575 9.67828 43.222C9.62648 43.1865 9.5822 43.1412 9.54796 43.0885C9.51372 43.0359 9.49019 42.977 9.47873 42.9153C9.46727 42.8535 9.46809 42.7901 9.48115 42.7287C9.49422 42.6673 9.51926 42.609 9.55486 42.5573C9.59046 42.5056 9.63591 42.4614 9.68861 42.4272C9.74131 42.3931 9.80022 42.3697 9.86198 42.3583C9.92374 42.347 9.98714 42.3479 10.0485 42.3611L28.3622 46.2562C28.4236 46.2692 28.4819 46.2941 28.5337 46.3296C28.5855 46.3651 28.6298 46.4105 28.664 46.4631C28.6982 46.5158 28.7218 46.5746 28.7332 46.6364C28.7447 46.6981 28.7439 46.7615 28.7308 46.8229C28.7177 46.8843 28.6927 46.9426 28.6571 46.9943C28.6215 47.0461 28.5761 47.0903 28.5234 47.1244C28.4707 47.1586 28.4117 47.182 28.35 47.1933C28.2882 47.2047 28.2248 47.2037 28.1634 47.1906Z'
      fill='#FACA15'
    />
    <path
      d='M27.5209 50.2117L9.20727 46.3166C9.08356 46.29 8.97543 46.2155 8.90663 46.1093C8.83782 46.0031 8.81396 45.874 8.84029 45.7502C8.86661 45.6265 8.94096 45.5182 9.04703 45.4492C9.15309 45.3802 9.2822 45.3561 9.406 45.3822L27.7196 49.2774C27.7811 49.2903 27.8394 49.3153 27.8911 49.3508C27.9429 49.3863 27.9872 49.4316 28.0215 49.4843C28.0557 49.5369 28.0792 49.5958 28.0907 49.6575C28.1022 49.7193 28.1013 49.7827 28.0883 49.8441C28.0752 49.9055 28.0502 49.9638 28.0146 50.0155C27.979 50.0672 27.9335 50.1114 27.8808 50.1456C27.8281 50.1797 27.7692 50.2031 27.7074 50.2145C27.6457 50.2258 27.5823 50.2249 27.5209 50.2117Z'
      fill='#FACA15'
    />
    <path
      d='M16.0732 26.3484C17.4625 26.3484 18.5887 25.2221 18.5887 23.8328C18.5887 22.4435 17.4625 21.3173 16.0732 21.3173C14.6839 21.3173 13.5576 22.4435 13.5576 23.8328C13.5576 25.2221 14.6839 26.3484 16.0732 26.3484Z'
      fill='#FACA15'
    />
    <path
      d='M48.148 0.0493685C48.0767 0.00424376 47.9904 -0.0107421 47.908 0.0077035C47.8256 0.0261491 47.7539 0.0765205 47.7086 0.147762L29.4627 28.9217C29.4176 28.993 29.4026 29.0793 29.421 29.1617C29.4395 29.2441 29.4899 29.3158 29.5611 29.3611L52.7415 44.0601C52.8129 44.1052 52.8992 44.1202 52.9816 44.1018C53.064 44.0833 53.1357 44.033 53.181 43.9617L71.4269 15.1878C71.472 15.1165 71.487 15.0301 71.4685 14.9478C71.4501 14.8654 71.3997 14.7937 71.3285 14.7484L48.148 0.0493685Z'
      fill='#6C63FF'
    />
    <path
      d='M48.4629 1.45553C48.3916 1.4104 48.3053 1.39542 48.2229 1.41386C48.1405 1.43231 48.0688 1.48268 48.0235 1.55392L30.8689 28.6068C30.8238 28.6781 30.8088 28.7644 30.8273 28.8468C30.8457 28.9292 30.8961 29.0009 30.9673 29.0462L52.4268 42.6539C52.4981 42.699 52.5844 42.714 52.6668 42.6955C52.7492 42.6771 52.8209 42.6267 52.8662 42.5555L70.0207 15.5026C70.0659 15.4313 70.0809 15.345 70.0624 15.2626C70.044 15.1802 69.9936 15.1085 69.9224 15.0632L48.4629 1.45553Z'
      fill='white'
    />
    <path
      d='M59.6766 24.9774L43.8644 14.9506C43.8115 14.9171 43.7656 14.8734 43.7295 14.8221C43.6935 14.7708 43.6678 14.7128 43.6541 14.6516C43.6404 14.5904 43.6389 14.5271 43.6497 14.4653C43.6604 14.4035 43.6833 14.3445 43.7168 14.2915C43.7504 14.2385 43.7941 14.1927 43.8454 14.1566C43.8967 14.1205 43.9546 14.0949 44.0158 14.0812C44.0771 14.0675 44.1404 14.066 44.2022 14.0767C44.264 14.0875 44.323 14.1103 44.376 14.1439L60.1882 24.1706C60.2412 24.2042 60.287 24.2479 60.3231 24.2992C60.3592 24.3505 60.3848 24.4084 60.3985 24.4696C60.4122 24.5308 60.4137 24.5941 60.403 24.6559C60.3922 24.7177 60.3694 24.7768 60.3358 24.8298C60.3022 24.8827 60.2585 24.9286 60.2072 24.9647C60.1559 25.0008 60.098 25.0264 60.0368 25.0401C59.9756 25.0538 59.9123 25.0553 59.8505 25.0445C59.7887 25.0338 59.7296 25.011 59.6766 24.9774Z'
      fill='#6C63FF'
    />
    <path
      d='M64.5788 17.5473L54.0885 10.8953C53.9407 10.8015 53.9354 10.545 54.0767 10.3222C54.218 10.0994 54.4523 9.9948 54.6001 10.0885L65.0904 16.7406C65.2382 16.8343 65.2435 17.0908 65.1022 17.3136C64.9609 17.5364 64.7266 17.641 64.5788 17.5473Z'
      fill='#6C63FF'
    />
    <path
      d='M63.112 19.86L52.6217 13.208C52.4739 13.1142 52.4686 12.8577 52.6099 12.6349C52.7512 12.4121 52.9855 12.3075 53.1333 12.4012L63.6236 19.0532C63.7714 19.147 63.7767 19.4035 63.6354 19.6263C63.4941 19.8491 63.2598 19.9537 63.112 19.86Z'
      fill='#6C63FF'
    />
    <path
      d='M58.0225 27.5859L42.2103 17.5592C42.1035 17.4912 42.0281 17.3837 42.0005 17.2602C41.9729 17.1367 41.9955 17.0072 42.0633 16.9003C42.1311 16.7935 42.2385 16.7178 42.362 16.6901C42.4855 16.6624 42.6149 16.6848 42.7219 16.7524L58.5341 26.7791C58.5871 26.8127 58.6329 26.8564 58.669 26.9077C58.7051 26.959 58.7307 27.0169 58.7444 27.0781C58.7581 27.1394 58.7596 27.2027 58.7489 27.2645C58.7381 27.3263 58.7153 27.3853 58.6817 27.4383C58.6481 27.4913 58.6044 27.5371 58.5531 27.5732C58.5018 27.6093 58.4439 27.6349 58.3827 27.6486C58.3215 27.6623 58.2582 27.6638 58.1964 27.6531C58.1346 27.6423 58.0755 27.6195 58.0225 27.5859H58.0225Z'
      fill='#6C63FF'
    />
    <path
      d='M56.368 30.194L40.5558 20.1673C40.449 20.0994 40.3735 19.9918 40.3459 19.8683C40.3184 19.7448 40.341 19.6154 40.4087 19.5085C40.4765 19.4016 40.5839 19.326 40.7074 19.2982C40.8309 19.2705 40.9604 19.2929 41.0674 19.3605L56.8795 29.3873C56.9326 29.4208 56.9785 29.4645 57.0147 29.5158C57.0509 29.5671 57.0766 29.6251 57.0903 29.6863C57.1041 29.7476 57.1056 29.8109 57.0949 29.8728C57.0841 29.9346 57.0613 29.9937 57.0277 30.0468C56.9941 30.0998 56.9503 30.1457 56.899 30.1818C56.8476 30.2179 56.7896 30.2435 56.7284 30.2571C56.6671 30.2708 56.6037 30.2723 56.5419 30.2614C56.48 30.2506 56.421 30.2277 56.368 30.194Z'
      fill='#6C63FF'
    />
    <path
      d='M54.714 32.8026L38.9018 22.7759C38.8488 22.7423 38.8028 22.6986 38.7667 22.6473C38.7305 22.596 38.7048 22.5381 38.6911 22.4768C38.6773 22.4156 38.6757 22.3522 38.6865 22.2903C38.6972 22.2285 38.7201 22.1694 38.7537 22.1164C38.7873 22.0633 38.8311 22.0175 38.8824 21.9814C38.9338 21.9453 38.9918 21.9196 39.053 21.906C39.1143 21.8923 39.1777 21.8908 39.2395 21.9017C39.3013 21.9125 39.3604 21.9354 39.4134 21.9691L55.2256 31.9958C55.3324 32.0638 55.4079 32.1713 55.4354 32.2948C55.463 32.4183 55.4404 32.5478 55.3727 32.6546C55.3049 32.7615 55.1974 32.8371 55.0739 32.8649C54.9505 32.8926 54.821 32.8702 54.714 32.8026Z'
      fill='#6C63FF'
    />
    <path
      d='M53.0599 35.4111L37.2477 25.3844C37.1407 25.3165 37.0651 25.209 37.0374 25.0854C37.0097 24.9617 37.0323 24.8322 37.1001 24.7252C37.168 24.6182 37.2755 24.5426 37.3992 24.5149C37.5228 24.4872 37.6523 24.5098 37.7593 24.5776L53.5715 34.6043C53.6783 34.6723 53.7538 34.7798 53.7813 34.9033C53.8089 35.0269 53.7863 35.1563 53.7185 35.2632C53.6508 35.37 53.5433 35.4457 53.4198 35.4734C53.2964 35.5011 53.1669 35.4787 53.0599 35.4111Z'
      fill='#6C63FF'
    />
    <path
      d='M51.4059 38.0197L35.5937 27.9929C35.5407 27.9594 35.4949 27.9157 35.4588 27.8644C35.4227 27.8131 35.3971 27.7551 35.3834 27.6939C35.3697 27.6327 35.3681 27.5694 35.3789 27.5076C35.3897 27.4458 35.4125 27.3868 35.4461 27.3338C35.4797 27.2808 35.5234 27.235 35.5747 27.1989C35.626 27.1628 35.6839 27.1372 35.7451 27.1235C35.8063 27.1098 35.8696 27.1083 35.9314 27.119C35.9932 27.1298 36.0523 27.1526 36.1052 27.1862L51.9174 37.2129C52.0244 37.2808 52.1001 37.3883 52.1278 37.5119C52.1554 37.6355 52.1329 37.7651 52.065 37.8721C51.9972 37.9791 51.8896 38.0547 51.766 38.0824C51.6424 38.1101 51.5128 38.0875 51.4059 38.0197Z'
      fill='#6C63FF'
    />
    <path
      d='M49.8571 11.8212C51.2464 11.8212 52.3726 10.6949 52.3726 9.30561C52.3726 7.91632 51.2464 6.79007 49.8571 6.79007C48.4678 6.79007 47.3416 7.91632 47.3416 9.30561C47.3416 10.6949 48.4678 11.8212 49.8571 11.8212Z'
      fill='#6C63FF'
    />
    <path
      d='M0.449634 25.5098C0.386339 25.5098 0.323756 25.5231 0.265979 25.549C0.208202 25.5748 0.156532 25.6126 0.114354 25.6598C0.0721763 25.707 0.0404427 25.7626 0.0212243 25.8229C0.002006 25.8832 -0.00426169 25.9468 0.00283279 26.0097L3.90043 60.6007C3.91274 60.7105 3.96512 60.812 4.04755 60.8857C4.12998 60.9593 4.23668 61 4.34723 61H62.7661C62.8767 61 62.9834 60.9594 63.0658 60.8857C63.1483 60.812 63.2006 60.7105 63.2129 60.6007L67.1104 26.0097C67.1175 25.9469 67.1113 25.8832 67.0921 25.8229C67.0729 25.7626 67.0412 25.707 66.999 25.6598C66.957 25.6124 66.9054 25.5744 66.8476 25.5486C66.7898 25.5227 66.7271 25.5095 66.6638 25.5098H0.449634Z'
      fill='#FACA15'
    />
    <path
      d='M5.76415 31.3196L24.4871 31.2182C24.6137 31.2173 24.7347 31.1662 24.8236 31.0762C24.9125 30.9861 24.962 30.8645 24.9613 30.738C24.9606 30.6115 24.9098 30.4904 24.8199 30.4013C24.73 30.3122 24.6085 30.2625 24.482 30.2629L5.75898 30.3643C5.63231 30.365 5.51109 30.416 5.422 30.5061C5.33292 30.5961 5.28326 30.7179 5.28394 30.8445C5.28463 30.9712 5.3356 31.0924 5.42566 31.1815C5.51572 31.2706 5.63748 31.3203 5.76415 31.3196Z'
      fill='white'
    />
    <path
      d='M5.76415 34.2734L16.8694 34.172C16.996 34.171 17.117 34.12 17.2059 34.0299C17.2948 33.9399 17.3443 33.8183 17.3436 33.6918C17.3429 33.5652 17.2921 33.4441 17.2022 33.3551C17.1123 33.266 16.9908 33.2163 16.8643 33.2167L5.75898 33.3181C5.63231 33.3188 5.51109 33.3698 5.422 33.4598C5.33292 33.5499 5.28326 33.6716 5.28394 33.7983C5.28463 33.925 5.3356 34.0462 5.42566 34.1353C5.51572 34.2244 5.63748 34.274 5.76415 34.2734Z'
      fill='white'
    />
  </svg>
);
export default AssetsZDSIcon;
