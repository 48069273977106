import React from 'react';

import {
  AgeSVG,
  EmployerSVG,
  GenderSVG,
  JobTitleSVG,
  LanguageSVG,
  LocationSVG,
  QuestionBankSVG,
  TagSVG,
  TimesSVG
} from '@components/svgs';

export const Icon: React.FC<React.PropsWithChildren<{ field: ScreenerField }>> = ({
  field: { candidate_attr: attr }
}) => {
  switch (attr) {
    case 'language':
      return <LanguageSVG />;
    case 'gender':
      return <GenderSVG />;
    case 'location':
      return <LocationSVG />;
    case 'employer':
      return <EmployerSVG />;
    case 'job_title':
      return <JobTitleSVG />;
    default:
      return <TagSVG />;
  }
};
