import * as React from 'react';

import cn from 'classnames';
import Tippy from '@tippyjs/react';

import { useAccount } from '@hooks/useAccount';

const SIZE_CLASSES = {
  sm: 'h-4 w-4',
  md: 'h-6 w-6',
  lg: 'h-8 w-8',
  xl: 'h-10 w-10',
  '2xl': 'h-16 w-16',
  '3xl': 'h-20 w-20'
};

const INITIALS_TEXT_SIZE_CLASSES = {
  sm: 'text-xs',
  md: 'text-xs',
  lg: 'text-sm',
  xl: 'text-sm',
  '2xl': 'text-sm',
  '3xl': 'text-sm'
};

type InnerProps = {
  user?: Partial<TeamUser>;
  className?: string;
  size?: keyof typeof SIZE_CLASSES;
};

const AvatarInner: React.FC<InnerProps> = ({ user, className = '', size = 'md' }) => {
  const sizeClass = SIZE_CLASSES[size];

  if (!user) {
    return <span className={`${sizeClass} ${className} rounded-full bg-gray-200`} />;
  } else if (user.img) {
    return (
      <img
        src={user.img}
        alt={user.name}
        className={`${sizeClass} ${className} flex-shrink-0 rounded-full border-gray-200 text-gray-500`}
      />
    );
  } else {
    const initials =
      user.initials ||
      (user.name || '')
        .split(/\s/)
        .map((w) => w[0])
        .slice(0, 2)
        .join('');

    return <AvatarInitials size={size} className={className} initials={initials} colorKey={user.name} />;
  }
};

export const AvatarInitials = ({ initials = '', size = 'md', className = '', colorKey = '' }) => {
  return (
    <span
      className={`${SIZE_CLASSES[size]} inline-flex items-center justify-center rounded-full border border-gray-200 bg-gray-200 ${className}`}
    >
      <span className={cn('text-xs', 'font-medium', 'text-gray-400', INITIALS_TEXT_SIZE_CLASSES[size])}>
        {initials.toUpperCase()}
      </span>
    </span>
  );
};

interface AvatarProps {
  user: Partial<TeamUser>;
  noTippy?: boolean;
  className?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
}

type AvatarFromIdProps = {
  userId?: number;
  name?: string;
} & Omit<AvatarProps, 'user'>;

export const AvatarFromId: React.FC<React.PropsWithChildren<AvatarFromIdProps>> = ({ userId, name = '', ...props }) => {
  const { getUserById } = useAccount();

  const user = (userId && getUserById(userId)) || ({ name } as TeamUser);

  return <Avatar user={user} {...props} />;
};

export const Avatar: React.FC<React.PropsWithChildren<AvatarProps>> = ({
  user,
  noTippy = false,
  className = '',
  size = 'md'
}) => {
  const inner = (
    <div aria-label='Avatar' className={cn('flex flex-shrink-0 items-center', className)}>
      <AvatarInner user={user} size={size} />
    </div>
  );
  if (noTippy || !user || !user.name) return inner;
  return <Tippy content={user.name}>{inner}</Tippy>;
};

export const AvatarSkeleton: React.FC<React.PropsWithChildren<Pick<AvatarProps, 'size' | 'className'>>> = ({
  size = 'md',
  className
}) => <div className={cn('flex items-center justify-center rounded-full bg-gray-50', SIZE_CLASSES[size], className)} />;
