import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import { Loading } from '@components/common';

import { useGetSetupIntentQuery } from '../api';
import { SetupIntent } from '../types';

import { PaymentDetails } from './SetupForm';

const stripeMeta = document.querySelector('meta[name="stripe-key"]');

const stripeKey = stripeMeta?.getAttribute('content');

if (!stripeKey) {
  throw new Error('Stripe key not found');
}
const stripePromise = loadStripe(stripeKey);

const withSetupIntent = (WrappedComponent: React.FC<React.PropsWithChildren<{ setupIntent: SetupIntent }>>) => () => {
  const { data: setupIntent, isLoading } = useGetSetupIntentQuery();

  if (isLoading || !setupIntent) {
    return <Loading absolute />;
  }
  return <WrappedComponent setupIntent={setupIntent} />;
};

const NewCardWithIntent: React.FC<React.PropsWithChildren<{ setupIntent: SetupIntent }>> = ({ setupIntent }) => {
  const options: StripeElementsOptions = {
    clientSecret: setupIntent.client_secret,
    appearance: {
      theme: 'flat'
    }
  };

  function handleSuccess() {
    window.location.href = '/subscription';
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentDetails onSuccess={handleSuccess} cta='Add Card' />
    </Elements>
  );
};
export const NewCard: React.FC<React.PropsWithChildren<unknown>> = withSetupIntent(NewCardWithIntent);
