import * as React from 'react';

import { Route, Routes, useParams } from 'react-router-dom';

import { WindowLayout } from 'components/layouts/WindowLayout';
import { GQSurveyPreview } from 'components/Public/GQSurvey/GQSurveyPreview';

import { StudiesIndex } from './components/StudiesIndex';
import { StudyDraft } from './components/StudyDraft';
import { StudyNew } from './components/StudyNew';
import { StudyPublished } from './components/StudyPublished';
import { StudyLoader } from './components/StudyPublished/StudyLoader';

const StudyDraftRoute: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }
  return (
    <StudyLoader type='edit' id={parseInt(id)}>
      {({ study, refetch }) => <StudyDraft study={study} refetch={refetch} />}
    </StudyLoader>
  );
};

const StudyPublishedRoute: React.FC<React.PropsWithChildren<{ screenerPreview?: boolean }>> = ({ screenerPreview }) => {
  const { id, screenerTypeParam } = useParams();

  let screenerType;
  if (screenerTypeParam === 'screener') screenerType = 'pre';
  if (screenerTypeParam === 'survey') screenerType = 'survey';

  if (!id) {
    return null;
  }

  if (screenerPreview && screenerType) {
    return <GQSurveyPreview studyId={parseInt(id)} screenerType={screenerType as ScreenerType} />;
  }

  return (
    <WindowLayout>
      <StudyLoader type='show' id={parseInt(id)}>
        {({ study, refetch }) => <StudyPublished study={study} refetch={refetch} />}
      </StudyLoader>
    </WindowLayout>
  );
};

export const StudiesApp: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Routes>
    <Route path='new' element={<StudyNew />} />
    <Route path=':id/edit/*' element={<StudyDraftRoute />} />
    <Route path=':id/edit/preview/:screenerTypeParam' element={<StudyPublishedRoute screenerPreview />} />
    <Route path=':id/*' element={<StudyPublishedRoute />} />
    <Route path=':id/preview/:screenerTypeParam' element={<StudyPublishedRoute screenerPreview />} />
    <Route path='' element={<StudiesIndex />} />
  </Routes>
);
