import React, { useEffect, useState } from 'react';

import qs from 'qs';
import { useNavigate } from 'react-router-dom';

import { createInsight } from '@api/queries';
import { api } from '@api/reduxApi';
import { useBulkUploadActions } from '@components/BulkUploadFlow';
import { AddCustomers } from '@components/CandidatesApp/CandidatesIndex/AddCustomers';
import { CandidateSlideOut } from '@components/CandidatesApp/CandidatesIndex/types';
import { Button, Text } from '@components/common';
import * as toasts from '@components/InsightsApp/toasts';
import { DashboardLayout } from '@components/layouts/DashboardLayout';
import { HeaderCta } from '@components/RepositoryApp/components/HeaderCta';
import { PageHeader } from '@components/shared/PageHeader';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { ZDS_DATA, ZdsType } from '@components/shared/ZDS/constants';
import { NewStudyAction } from '@components/StudiesApp/components/StudyNew';
import { StudySlideOut } from '@components/StudiesApp/types';
import { SalesforceLogoSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { capitalize } from '@components/utils';
import { useCreateStudy } from '@hooks/useCreateStudy';
import { usePlan } from '@hooks/usePlan';
import { useToaster } from '@stores/toaster';

interface PageProps {
  types: ZdsType[];
  setSlideOut?: (v: CandidateSlideOut | StudySlideOut | null) => void;
  renderBackgroundTasks?: () => React.ReactElement;
  studyId?: number;
  teamId?: number | null;
  withHeader?: boolean;
  openPanelStudyModal?: () => void;
}

interface SectionProps {
  type: ZdsType;
  setSlideOut?: (v: CandidateSlideOut | StudySlideOut | null) => void;
  studyId?: number;
  teamId?: number | null;
  openPanelStudyModal?: () => void;
}

const ZDSSection: React.FC<React.PropsWithChildren<SectionProps>> = ({
  openPanelStudyModal,
  studyId,
  teamId,
  type,
  setSlideOut
}) => {
  const { header, subheader, options, features } = ZDS_DATA[type];

  const [create, { isLoading }] = api.useCreateInsightMutation();

  const { createStudy, isCreating } = useCreateStudy();

  const { hasQuota } = usePlan();

  const navigate = useNavigate();

  const showToast = useToaster();

  const [creating, setCreating] = useState(false);
  const { openManagerZDS } = useBulkUploadActions();

  useEffect(() => {
    track('viewed_zds', { type: 'page', page: `${studyId ? 'study' : 'global'} ${type}` });
  }, []);

  const createStudyInsight = async () => {
    track('clicked_new_story', { cta: 'insights zds', page: 'study insights' });
    const story = await create({ project_id: studyId || 0 }).unwrap();
    navigate(`/insights/${story.slug}`);
  };

  const createGlobalInsight = async () => {
    setCreating(true);
    track('clicked_new_story', { cta: 'insights zds', page: 'global repo' });
    const story = await createInsight({ project_id: studyId });
    if (story) {
      navigate(`/insights/${story.slug}`);
      return; // keep spinner state during redirect
    } else {
      showToast(toasts.failedCreate());
    }
    setCreating(false);
  };

  const handleClickNewVideo = (page) => {
    track('clicked_new_video_upload', { cta: `${page} zds`, page: studyId ? 'study data' : 'global data' });
    openManagerZDS(studyId);
  };

  const renderCTA = () => {
    switch (type) {
      case 'studies': {
        return (
          hasQuota('studies') && (
            <NewStudyAction>
              {({ openSlideout }) => (
                <Button
                  trackEvent='clicked_zds_page_cta'
                  trackProps={{ page: type, cta: 'Create new study' }}
                  medium
                  className='tablet:w-auto w-full whitespace-nowrap'
                  primary
                  onClick={() => openSlideout({ teamId })}
                >
                  Create new study
                </Button>
              )}
            </NewStudyAction>
          )
        );
      }
      case 'candidates':
        if (!setSlideOut) return null;
        return (
          <RestrictedButton action='Add candidates' permission='addCandidate' limit='candidates'>
            <AddCustomers
              openPanelStudyModal={openPanelStudyModal}
              component='candidates_zds_banner'
              setSlideOut={setSlideOut}
            />
          </RestrictedButton>
        );
      case 'external_recruitment': {
        return (
          <NewStudyAction>
            {({ openSlideout }) => (
              <RestrictedButton limit='studies' permission='createStudy' action='New study'>
                <Button
                  trackEvent='clicked_zds_page_cta'
                  trackProps={{ page: type, cta: 'Recruit external candidates' }}
                  medium
                  className='tablet:w-auto w-full whitespace-nowrap'
                  icon='externalCandidates'
                  onClick={() => openSlideout({ teamId, externalCandidatesDefaulted: true })}
                >
                  Recruit external candidates
                </Button>
              </RestrictedButton>
            )}
          </NewStudyAction>
        );
      }
      case 'insights':
      case 'global_insights':
        return (
          <RestrictedButton action='New insight' permission='canCreate'>
            <Button
              medium
              onClick={studyId ? createStudyInsight : createGlobalInsight}
              disabled={isLoading || creating}
              loading={isLoading}
              className='tablet:w-auto w-full whitespace-nowrap'
              primary
            >
              Create insight
            </Button>
          </RestrictedButton>
        );

      case 'global_highlights':
        return (
          <RestrictedButton action='New upload' permission='canCreate'>
            <Button
              medium
              onClick={() => handleClickNewVideo('highlights')}
              disabled={isCreating}
              loading={isCreating}
              className='tablet:w-auto w-full whitespace-nowrap'
              primary
            >
              Upload interviews to highlight
            </Button>
          </RestrictedButton>
        );

      case 'global_interviews':
        return (
          <div className='tablet:space-y-0 tablet:flex-row flex flex-col items-center space-x-6 space-y-4'>
            <RestrictedButton action='New upload' permission='canCreate'>
              <Button
                medium
                onClick={() => handleClickNewVideo('interviews')}
                disabled={isLoading || creating}
                loading={isLoading}
                primary
                className='tablet:w-auto w-full whitespace-nowrap'
              >
                Upload interviews
              </Button>
            </RestrictedButton>
            <RestrictedButton action='New study' permission='createStudy'>
              <Button
                medium
                onClick={() =>
                  createStudy({
                    style: 'video_call',
                    trackEvent: 'clicked_new_study',
                    trackProps: {
                      cta: 'interviews zds',
                      page: 'global repo'
                    }
                  })
                }
                disabled={isCreating}
                loading={isCreating}
                className='tablet:w-auto w-full whitespace-nowrap'
              >
                Start new interview study
              </Button>
            </RestrictedButton>
          </div>
        );
      case 'global_recordings':
        return (
          <div className='tablet:space-y-0 tablet:space-x-6 tablet:flex-row flex flex-col items-center space-y-4'>
            <RestrictedButton action='New upload' permission='canCreate'>
              <Button
                medium
                onClick={() => handleClickNewVideo('interviews')}
                disabled={isLoading || creating}
                loading={isLoading}
                primary
                className='tablet:w-auto w-full whitespace-nowrap'
              >
                Upload recordings
              </Button>
            </RestrictedButton>
            <RestrictedButton action='New study' permission='createStudy'>
              <Button
                medium
                className='tablet:w-auto w-full whitespace-nowrap'
                onClick={() =>
                  createStudy({
                    style: 'unmoderated_test',
                    trackEvent: 'clicked_new_study',
                    trackProps: {
                      cta: 'interviews zds',
                      page: 'global repo'
                    }
                  })
                }
                disabled={isCreating}
                loading={isCreating}
              >
                Start new unmoderated prototype test
              </Button>
            </RestrictedButton>
          </div>
        );
      case 'global_survey_results':
        return (
          <RestrictedButton action='New study' permission='createStudy'>
            <Button
              className='tablet:w-auto w-full whitespace-nowrap'
              medium
              onClick={() =>
                createStudy({
                  style: 'survey',
                  trackEvent: 'clicked_new_study',
                  trackProps: {
                    cta: 'surveys zds',
                    page: 'global repo'
                  }
                })
              }
              disabled={isCreating}
              primary
            >
              Start new survey study
            </Button>
          </RestrictedButton>
        );
      case 'global_reels':
        return (
          <RestrictedButton action='Create new reel' permission='canCreate' buttonProps={{ medium: true }}>
            <Button
              medium
              className='tablet:w-auto w-full whitespace-nowrap'
              href={`/highlight_reels/new${qs.stringify({ project_id: studyId }, { addQueryPrefix: true })}`}
              spa
              loading={isCreating}
              primary
            >
              Create new reel
            </Button>
          </RestrictedButton>
        );
      case 'data':
        return <HeaderCta position='left' studyId={studyId} handleClickNewVideo={() => handleClickNewVideo('data')} />;
    }
  };

  return (
    <section className='relative mb-10 rounded border border-gray-200 bg-white'>
      <div className='desktop:flex-row desktop:justify-between flex flex-col p-6'>
        <div className='desktop:w-1/2 monitor:w-5/12 desktop:mt-0 mt-6'>
          <h4 className='mb-2'>{header}</h4>
          <p className='mb-4'>{subheader}</p>
          <ul className='mb-10 ml-6 list-disc'>
            {options.map((item) => (
              <li className='text-sm' key={item}>
                {item}
              </li>
            ))}
          </ul>
          {renderCTA()}
          {type === 'external_recruitment' && (
            <div className='my-8'>
              <img
                className='tablet:w-4/12 monitor:w-4/12'
                src='/temp-assets/candidates/respondent.png'
                alt='respondent-logo'
              />
            </div>
          )}
        </div>
        <div className='desktop:w-1/2 desktop:ml-8 monitor:w-5/12 desktop:mt-0 mt-8 w-full rounded-md'>
          <img
            className='w-full max-w-lg'
            alt='different kinds of study results'
            src={`/temp-assets/zds/${type}.png`}
            srcSet={`/temp-assets/zds/${type}@2x.png 2x, /temp-assets/zds/${type}@3x.png 3x`}
          />
        </div>
      </div>
      {features && (
        <div className='p-6 pt-10'>
          <Text bold className='mb-4'>
            Key features
          </Text>
          <ul className='mb-6 ml-6 list-disc'>
            {features.map((item) => (
              <li key={item} className='text-sm'>
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
      {type === 'candidates' && (
        <div className='tablet:flex-row tablet:space-x-10 tablet:space-y-0 mx-4 flex flex-col items-center'>
          <SalesforceLogoSVG className='tablet:w-1/12 monitor:w-1/12' />
          <img
            className='tablet:w-1/12 monitor:w-1/12'
            src='/temp-assets/candidates/snowflake.png'
            alt='snowflake-logo'
          />
          <img className='tablet:w-1/12 monitor:w-1/12' src='/temp-assets/candidates/zapier.png' alt='zapier-logo' />
        </div>
      )}
    </section>
  );
};

export const ZDSPage: React.FC<React.PropsWithChildren<PageProps>> = ({
  studyId,
  teamId,
  types,
  setSlideOut,
  renderBackgroundTasks,
  withHeader = true,
  openPanelStudyModal
}) => {
  return (
    <DashboardLayout>
      {withHeader && <PageHeader transparent={!!studyId} h1={capitalize(types[0])} hideDefaultButtons />}
      {renderBackgroundTasks?.()}
      <div className='px-page py-gutter relative'>
        {types.map((type, i) => (
          <ZDSSection
            key={i}
            openPanelStudyModal={openPanelStudyModal}
            type={type}
            studyId={studyId}
            teamId={teamId}
            setSlideOut={setSlideOut}
          />
        ))}
      </div>
    </DashboardLayout>
  );
};
