import React, { FormEvent, useEffect, useState } from 'react';

import { Editor, Mark, MarkRange, Range } from '@tiptap/core';

import { Button, Input, Modal, ModalHeading } from '@components/common';
import { mergeDeep } from 'helpers/mergeDeep';

import * as Commands from '../helpers/commands';
import * as Queries from '../helpers/queries';

interface Props {
  editor: Editor;
  isOpen: boolean;
  onClose: () => void;
}

export const LinkModal = ({ editor, isOpen, onClose }: Props) => {
  const isActive = editor.isActive('link');
  const attributes = editor.getAttributes('link');
  const range = Queries.getActiveMarkRange(editor.state, 'link');

  const [href, setHref] = useState<string>('');
  const [text, setText] = useState<string>('');

  const getLinkText = () => {
    let { from, to } = editor.state.selection;

    if (isActive) {
      const range = Queries.getActiveMarkRange(editor.state, 'link');

      if (range) ({ from, to } = range);
    }

    return Queries.getTextSelection(editor, { from, to });
  };

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isActive && range) {
      const newMarkRange = { ...range };
      // @ts-expect-error - attrs is NOT readonly
      newMarkRange.mark.attrs = mergeDeep(range.mark.attrs, { href });

      Commands.replaceMark(editor, [range, newMarkRange as MarkRange]);
      Commands.replaceTextAtRange(editor, text, range);
    } else {
      const { from, to } = editor.state.selection;

      editor.commands.setLink({ href });
      Commands.replaceTextAtRange(editor, text, { from, to });
    }

    editor.commands.focus();
    onClose();
  };

  useEffect(() => {
    if (attributes) {
      setHref(attributes.href ?? '');
      setText(getLinkText());
    } else {
      setHref('');
      setText('');
    }
  }, [isOpen]);

  return isOpen ? (
    <Modal size='sm' onClose={onClose}>
      <ModalHeading className='mb-6'>{isActive ? 'Edit link' : 'Create link'}</ModalHeading>
      <form onSubmit={handleOnSubmit}>
        <fieldset className='mb-6'>
          <label htmlFor='linkUrl' className='mb-2'>
            Link URL
          </label>
          <Input id='linkUrl' className='w-full' onChange={(value) => setHref(value)} value={href} autoFocus />
        </fieldset>
        <fieldset>
          <label htmlFor='textToDisplay' className='mb-2'>
            Text to display
          </label>
          <Input id='textToDisplay' className='w-full' onChange={(value) => setText(value)} value={text} />
        </fieldset>
        <fieldset className='mt-10 flex space-x-2'>
          <Button type='submit' disabled={!href || !text} small primary>
            Save
          </Button>
          <Button onClick={onClose} small>
            Cancel
          </Button>
        </fieldset>
      </form>
    </Modal>
  ) : null;
};
