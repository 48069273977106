import * as React from 'react';

import { Text, TimeSelect } from '@components/common';
import type { TimeOption } from '@components/common/TimeSelect';
import { TimezoneIndicator } from '@components/shared/TimezoneIndicator';

import { FieldRow } from '../components/FieldRow';
import { formatTime } from '../utils';

import type { BookingDetailsFormFieldComponentProps } from './types';

export const Time: React.FC<BookingDetailsFormFieldComponentProps> = ({ editMode, bookingDetail, onChange }) => {
  const event = bookingDetail.candidate_event;

  if (!event) return null;

  const handleTimeChange = ({ hour, min }: TimeOption) => {
    const startTime = new Date(event.time!.start_time);
    startTime.setHours(hour);
    startTime.setMinutes(min);
    onChange({ candidate_event: { time: { start_time: startTime } } });
  };

  return (
    <FieldRow label='Start time'>
      {!editMode && (
        <div className='overflow-auto'>
          <Text className='py-2.5' h='400'>
            {formatTime(new Date(event.time.start_time))} - {formatTime(new Date(event.time.end_time))}
          </Text>
          <div className='py-2.5'>
            <TimezoneIndicator />
          </div>
        </div>
      )}
      {editMode && (
        <div className='w-full'>
          <TimeSelect
            label='Start Time'
            inputClassName=' py-2.5'
            inputWidth='full'
            minHour={0}
            maxHour={24}
            increment={15}
            initialValue={{
              hour: event.time.start_time.getHours(),
              min: event.time.start_time.getMinutes()
            }}
            onChange={handleTimeChange}
          />
        </div>
      )}
    </FieldRow>
  );
};
