import React, { useState } from 'react';

import { Avatar, Checkbox, Dropdown, Select, Text, TimeSelect, Toggle } from '@components/common';
import {
  getHourMin,
  getTime
} from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/utils';
import { VIEW_SETTINGS_OPTIONS } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/constants';
import { without } from '@components/utils';

import { CalendarSettings } from '../GQCalendar';
import { FormGroup, Label } from '../shared';

interface Props {
  disabled?: boolean;
  viewSettings: CalenderViewSettings;
  changeViewSettings: (v: Partial<CalenderViewSettings>) => void;
  moderators: TeamUser[] | null | undefined;
  observers: TeamUser[] | null | undefined;
  setCalSettings: (v: CalendarSettings | null) => void;
  calSettings: CalendarSettings | null;
  hasObservers?: boolean;
}

export const ViewSettings: React.FC<React.PropsWithChildren<Props>> = ({
  moderators,
  observers,
  disabled,
  viewSettings,
  changeViewSettings,
  calSettings,
  setCalSettings,
  hasObservers
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const { hiddenEvents = [], hiddenIds = [], moderatorsDisabled, observersDisabled } = calSettings ?? {};

  const UserCheckbox: React.FC<React.PropsWithChildren<{ user: TeamUser }>> = ({ user }) => (
    <label className='flex cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-gray-50'>
      <Checkbox
        value={user.id}
        name={user.id.toString()}
        selected={!hiddenIds?.includes(user.id)}
        onChange={(v) =>
          v
            ? setCalSettings({ ...(calSettings || {}), hiddenIds: without(hiddenIds, user.id) })
            : setCalSettings({ ...(calSettings || {}), hiddenIds: [...hiddenIds, user.id] })
        }
      />
      <Avatar className='ml-2' user={user} />
      <Text truncate h='400'>
        {user?.name}
      </Text>
    </label>
  );

  const toggleModeratorsCalendars = (v: boolean) =>
    setCalSettings({
      ...(calSettings || {}),
      moderatorsDisabled: !v
    });

  const toggleObserversCalendars = (v: boolean) =>
    setCalSettings({
      ...(calSettings || {}),
      observersDisabled: !v
    });

  return (
    <Dropdown
      medium
      position='right'
      dropdownWidth='auto'
      icon='view'
      className='xx-view-settings bg-white'
      text='View'
      isOpen={open}
      onClick={() => setOpen(!open)}
      onClose={() => setOpen(false)}
    >
      <div className='w-80 space-y-3 px-4 py-2'>
        <FormGroup>
          <Label h='500'>Days and times</Label>
          <div className='mb-4 flex flex-row space-x-2'>
            <TimeSelect
              minHour={0}
              maxHour={24}
              increment={60}
              disabled={disabled}
              initialValue={getHourMin(viewSettings.start_time)}
              onChange={(v) => changeViewSettings({ start_time: getTime(v) })}
              label='start time'
            />
            <span className='h400 self-center'>to</span>
            <TimeSelect
              minHour={0}
              maxHour={24}
              increment={60}
              disabled={disabled}
              initialValue={getHourMin(viewSettings.end_time)}
              onChange={(v) => changeViewSettings({ end_time: getTime(v) })}
              label='end time'
            />
          </div>
          <div>
            <Select
              options={VIEW_SETTINGS_OPTIONS}
              value={viewSettings.week_days_only ? '1' : '0'}
              onChange={(v) => changeViewSettings({ week_days_only: Boolean(+v) })}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label>Display</Label>
          <div className='mb-3 flex'>
            <Toggle name='Moderator calendar events' on={!moderatorsDisabled} onToggle={toggleModeratorsCalendars} />
            <div className='ml-3 flex flex-col overflow-hidden'>
              <Text className='my-1 h-5' bold h='400'>
                Moderator calendar events
              </Text>
              {!moderatorsDisabled && moderators?.map((user) => <UserCheckbox user={user} key={user.id} />)}
            </div>
          </div>
          {hasObservers && (
            <div className='flex'>
              <Toggle name='Observer calendar events' on={!observersDisabled} onToggle={toggleObserversCalendars} />
              <div className='ml-3 flex flex-col overflow-hidden'>
                <Text className='my-1 h-5' bold h='400'>
                  Observer calendar events
                </Text>
                {!observersDisabled && observers?.map((user) => <UserCheckbox user={user} key={user.id} />)}
              </div>
            </div>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Events to display</Label>
          <div className='mb-3 mt-4 flex flex-nowrap items-center space-x-2'>
            <Checkbox
              value='busy'
              name='busy'
              id='busy'
              selected={!hiddenEvents?.includes('busy')}
              onChange={(v) =>
                v
                  ? setCalSettings({
                      ...(calSettings || {}),
                      hiddenEvents: without(hiddenEvents, 'busy')
                    })
                  : setCalSettings({
                      ...(calSettings || {}),
                      hiddenEvents: [...hiddenEvents, 'busy']
                    })
              }
            />
            <label className='h400 cursor-pointer' htmlFor='busy'>
              Busy events
            </label>
          </div>
          <div className='flex flex-nowrap items-center space-x-2'>
            <Checkbox
              value='free'
              name='free'
              id='free'
              selected={!hiddenEvents?.includes('free')}
              onChange={(v) =>
                v
                  ? setCalSettings({
                      ...(calSettings || {}),
                      hiddenEvents: without(hiddenEvents, 'free')
                    })
                  : setCalSettings({
                      ...(calSettings || {}),
                      hiddenEvents: [...hiddenEvents, 'free']
                    })
              }
            />
            <label className='h400 cursor-pointer' htmlFor='free'>
              Free events
            </label>
          </div>
        </FormGroup>
      </div>
    </Dropdown>
  );
};
