import { useEffect, useRef, useState } from 'react';

import { getCandidateParticipations } from '@api/queries';

interface Hook {
  participations?: Participation[];
}

export const useCandidateParticipations = (id: number): Hook => {
  const [participations, setParticipations] = useState<Participation[]>([]);

  const isMountedRef = useRef(true);

  useEffect(() => {
    if (isMountedRef) {
      getCandidateParticipations(id)
        .then((resp) => {
          if (resp) {
            setParticipations(resp);
          }
        })
        .catch(() => {
          if (isMountedRef.current) {
            console.log('interviews fetching failed');
          }
        });
    }
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return { participations };
};
