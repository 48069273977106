import * as React from 'react';

import { ButtonProps } from '@components/common/Button';
import { compact } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { useUser } from '@hooks/useUser';

import { BookabilityRuleAlert } from './BookabilityRuleAlert';

const WARNING_COPY = 'Participants will see reduced availability.';
const ERROR_COPY = 'Events won’t get scheduled or synced. Please ask they re-connect.';
const ERROR_COPY_AS_USER = 'Events won’t get scheduled or synced.';

export const ModeratorsNeedCalendarAlert: BookabilityRuleAlertComponent = ({ level, extra, onClickCta }) => {
  const {
    account: { team }
  } = useAccount();
  const user = useUser();
  const userIds = extra?.user_ids || [];
  const users = compact(userIds.map((id) => team.find((u) => u.id === id)));

  let message = level === 'error' ? ERROR_COPY : WARNING_COPY;

  let heading = 'Moderators’ calendars are disconnected';
  if (users.length === 1) {
    heading = `${users[0].name}’s calendar is disconnected`;
    if (users[0].id === user.id) {
      heading = 'Your calendar is disconnected';
      if (level === 'error') message = ERROR_COPY_AS_USER;
    }
  }

  const ctas: ButtonProps[] = userIds.includes(user.id)
    ? [
        {
          children: 'Re-connect',
          href: '/user/connected_accounts',
          onClick: onClickCta
        }
      ]
    : [];

  return <BookabilityRuleAlert level={level} heading={heading} message={message} ctas={ctas} />;
};
