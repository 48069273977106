import * as React from 'react';

import { Basic } from '@components/shared/Skeleton';

export const ReelSkeleton = () => {
  return (
    <div className='px-6 py-3'>
      <div className='mb-2 w-full'>
        <Basic h='8' width='85px' />
        <Basic h='24' width='full' />
      </div>
      <div className='my-2 w-full'>
        <Basic h='8' width='115px' />
        <Basic h='32' width='full' />
      </div>
      <div className='mb-2 w-full'>
        <Basic h='8' width='105px' />
        <Basic h='28' width='full' />
      </div>
    </div>
  );
};
