import React, { useEffect, useState } from 'react';

import { Avatar, Text } from '@components/common';
import { SenderCombobox } from 'components/shared/SenderCombobox';
import { useAccount } from 'hooks/useAccount';

import { AddUserToStudyModal } from './AddUserToStudyModal';

interface Props {
  studyMessage: StudyMessage;
  study: SimpleStudy;
  sender?: EmailSender;
  setSender: (v: EmailSender | undefined) => void;
  onSelect: (newSender: EmailSender) => void;
}

export const SenderSelect: React.FC<React.PropsWithChildren<Props>> = ({
  studyMessage,
  study,
  sender,
  setSender,
  onSelect: parentOnSelect
}) => {
  const [addUserToStudyModalOpen, setAddUserToStudyModalOpen] = useState<boolean>(false);
  const [teamUserToAdd, setTeamUserToAdd] = useState<TeamUser>();
  const [emailSenderSelected, setEmailSenderSelected] = useState<EmailSender>();
  const [senderUser, setSenderUser] = useState<TeamUser | undefined>();

  const {
    account: { team },
    getUserById
  } = useAccount();

  useEffect(() => {
    const teamUser =
      getUserById(sender?.user_id || 0) ||
      ({
        name: sender?.name || '',
        email: sender?.email || ''
      } as TeamUser);
    setSenderUser(teamUser);
  }, [sender]);

  useEffect(() => {
    if (!sender) return;
    if (!senderUser) return;

    if ('valid_email_integration' in senderUser && senderUser.valid_email_integration === false) {
      setSender(undefined);
      setSenderUser(undefined);
    }
  }, [sender, senderUser]);

  const onCreateStudyUser = () => {
    if (emailSenderSelected) onConfirmedSelect(emailSenderSelected);
    setEmailSenderSelected(undefined);
    setTeamUserToAdd(undefined);
    setAddUserToStudyModalOpen(false);
  };

  const onCancelCreateStudyUser = () => {
    setEmailSenderSelected(undefined);
    setTeamUserToAdd(undefined);
    setAddUserToStudyModalOpen(false);
  };

  const currentlySelected: EmailSender | undefined = sender
    ? ({
        id: sender.user_id,
        name: sender.name || '',
        email: sender.email
      } as EmailSender)
    : undefined;

  const onSelect = (items: EmailSender[]) => {
    const newSender = items[0];

    if (!newSender.user_id || study.user_ids.includes(newSender.user_id)) {
      onConfirmedSelect(newSender);
    } else {
      const teamUser = team.find((u) => u.id === newSender.user_id);
      if (teamUser) {
        setEmailSenderSelected(newSender);
        setTeamUserToAdd(teamUser);
        setAddUserToStudyModalOpen(true);
      } else {
        onConfirmedSelect(newSender);
      }
    }
  };

  const onConfirmedSelect = (newSender: EmailSender) => {
    setSender(newSender);
    parentOnSelect(newSender);
  };

  return (
    <>
      <SenderCombobox
        errorMessage={sender ? undefined : 'Please select a sender.'}
        context={{ study_message_id: studyMessage.id }}
        onSelect={onSelect}
        showCurrentlySelected={currentlySelected}
        placeholder={
          sender && senderUser ? (
            <div className='flex h-full items-center px-3'>
              <Avatar user={senderUser} className='mr-2' />
              <Text h='400'>{sender.name}</Text>
              <Text h='200' color='gray-500' className='ml-2'>
                ({sender.email})
              </Text>
            </div>
          ) : (
            'Enter a name or email'
          )
        }
      />
      <AddUserToStudyModal
        open={addUserToStudyModalOpen}
        study={study}
        teamUserToAdd={teamUserToAdd}
        onSubmit={onCreateStudyUser}
        onCancel={onCancelCreateStudyUser}
      />
    </>
  );
};
