import React from 'react';

import { Text } from '@components/common';
import { TextProps } from '@components/common/Text';
import { InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

interface Props extends TextProps {
  tooltip?: string;
  h?: TextProps['h'];
}

export const Label: React.FC<React.PropsWithChildren<Props>> = ({ h = '400', children, tooltip, ...rest }) => (
  <label className='mb-2 flex items-center space-x-2'>
    <Text h={h} bold {...rest}>
      {children}
    </Text>
    {tooltip && (
      <Tooltip content={tooltip}>
        <InfoCircleIcon className='h-4 w-4 flex-shrink-0' />
      </Tooltip>
    )}
  </label>
);
