import * as React from 'react';

export const AdvertisingMegaphoneIcon: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  className = ''
}) => (
  <svg
    className={`stroke-current ${className}`}
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.87101 11.7333L3.28168 10.6562L2.32285 8.14091C2.254 7.95948 2.24919 7.75998 2.3092 7.57544C2.36922 7.3909 2.49045 7.23239 2.65285 7.12616L4.90601 5.65491L5.6091 3.05707C5.65989 2.86973 5.77323 2.70539 5.9303 2.59134C6.08737 2.47728 6.27873 2.42038 6.4726 2.43007L9.16026 2.56666L11.2558 0.875407C11.407 0.753456 11.5954 0.686951 11.7897 0.686951C11.984 0.686951 12.1724 0.753456 12.3237 0.875407L14.4192 2.56666L17.1068 2.43282C17.3007 2.42313 17.4921 2.48003 17.6491 2.59409C17.8062 2.70814 17.9195 2.87248 17.9703 3.05982L18.6734 5.65766L20.9266 7.12891C21.0891 7.23502 21.2105 7.39353 21.2705 7.57811C21.3305 7.76269 21.3256 7.96225 21.2566 8.14366L20.2987 10.659L21.2566 13.1734C21.3257 13.355 21.3306 13.5547 21.2706 13.7394C21.2106 13.9241 21.0892 14.0828 20.9266 14.1891L18.6734 15.6557L17.9667 18.2545C17.9162 18.4421 17.803 18.6067 17.6459 18.721C17.4887 18.8353 17.2972 18.8922 17.1032 18.8824L14.4155 18.7486L12.32 20.4362'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.6875 18.1216L2.2055 20.5132'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.08228 7.46808L14.8518 16.5577'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.5485 16.0793L1.90213 20.0356L0.990967 18.6001L9.3858 7.94659L14.5485 16.0793Z'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.63464 19.1803L5.43764 20.4142C5.57042 20.6318 5.74628 20.8201 5.95444 20.9673C6.16259 21.1146 6.39865 21.2177 6.64811 21.2704C6.89757 21.3231 7.15517 21.3243 7.4051 21.2738C7.65502 21.2234 7.892 21.1224 8.10148 20.977C8.52973 20.6688 8.82398 20.2086 8.92401 19.6905C9.02404 19.1725 8.92228 18.6358 8.63956 18.1903L8.49014 17.974'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
