import * as React from 'react';

import { CopilotKit, useCoAgent } from '@copilotkit/react-core';
import { CopilotPopup } from '@copilotkit/react-ui';

import { getToken } from '@api/client';
import { AiChatSlideOut } from '@components/AiChat';
import { Portal } from '@components/Portal';
import { BackgroundTasksSlideOut } from '@components/shared/BackgroundTasks';
import { useDeviceType } from '@hooks/useDeviceType';
import { useFeature } from '@hooks/useFeature';
import { useAiChat } from '@stores/aiChat';
import { emptyFallback, ErrorBoundary } from 'components/ErrorBoundary';
import { InvalidConnectedAccount } from 'components/shared/InvalidConnectedAccount';

import { Provider as SidebarProvider, Sidebar } from './Sidebar';

type Props = {
  sidebar?: boolean;
  aiChat?: boolean;
};
type AgentState = {
  configurable: {
    gqApiToken: string;
  };
};

const CopilotWrapper: React.FC<React.PropsWithChildren<{ apiKey: string | null; agent?: string }>> = ({
  children,
  apiKey, // This is the API key for the Copilot service
  agent = 'main'
}) => {
  const token = getToken();

  if (apiKey) {
    return (
      <CopilotKit publicApiKey={apiKey} agent={agent} properties={{ authorization: token ?? '' }}>
        <CopilotAgentContext token={token ?? ''} agent={agent} />
        {children}
      </CopilotKit>
    );
  }
  return <>{children}</>;
};

const CopilotAgentContext: React.FC<{ token: string; agent: string }> = ({ token, agent }) => {
  useCoAgent<AgentState>({
    name: agent,
    configurable: {
      gqApiToken: token
    }
  });
  return null;
};

function useCopilotUiApiKey(): string | null {
  const tokenEl = document.getElementById('copilot-ui-api-key');
  const hasAiCopilot = useFeature('ai_copilot');

  if (hasAiCopilot && tokenEl && tokenEl instanceof HTMLMetaElement) {
    return tokenEl.content;
  }
  return null;
}

export const Container: React.FC<React.PropsWithChildren<Props>> = ({ children, sidebar, aiChat }) => {
  const { isMobile } = useDeviceType();
  const { enabled, mode } = useAiChat();
  const hasBackgroundTasks = useFeature('background_tasks_slideout');
  const copilotKey = useCopilotUiApiKey();

  const content = (
    <div id='Container' className='flex h-full'>
      <SidebarProvider>
        {!!copilotKey && (
          <CopilotPopup
            instructions={
              'You are assisting the user as best as you can. Answer in the best way possible given the data you have.'
            }
            labels={{
              title: 'Popup Assistant',
              initial: 'Need any help?'
            }}
          />
        )}
        {sidebar && (
          <Portal target={document.getElementById(isMobile ? 'sidebar-mobile-portal' : 'sidebar-portal')}>
            <Sidebar />
          </Portal>
        )}
        <div className='relative flex max-w-full flex-1'>
          <div className='max-w-full flex-1'>
            {sidebar && (
              <ErrorBoundary fallback={emptyFallback}>
                <Portal target={document.getElementById('invalid-account-portal')}>
                  <InvalidConnectedAccount />
                </Portal>
              </ErrorBoundary>
            )}
            {children}
          </div>
        </div>
        {aiChat && enabled && (
          <Portal target={document.getElementById('ask-ai-slideout-portal')}>
            {mode === 'slideout' && <AiChatSlideOut />}
          </Portal>
        )}
        {hasBackgroundTasks && (
          <Portal target={document.getElementById('tasks-slideout-portal')}>
            <BackgroundTasksSlideOut />
          </Portal>
        )}
      </SidebarProvider>
    </div>
  );

  return <CopilotWrapper apiKey={copilotKey}>{content}</CopilotWrapper>;
};
