import React, { CSSProperties, forwardRef, HTMLAttributes } from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { CheckMark2SVG, XSVG } from '@components/svgs';

interface Props extends HTMLAttributes<HTMLDivElement> {
  size: 'sm' | 'md' | 'lg';
  failure?: boolean;
}

export const POINTER_SIZE_MAP = {
  sm: 12,
  md: 16,
  lg: 24
};

const POINTER_SIZE_ICON_MAP = {
  small: 8,
  medium: 12,
  large: 16
};

export const ClickPointer = forwardRef<HTMLDivElement, Props>(
  ({ children, className, failure, size, style, ...rest }, ref) => {
    const pointerDimension = POINTER_SIZE_MAP[size];
    const pointerIconDimension = POINTER_SIZE_ICON_MAP[size];

    const pointerStyle: CSSProperties = {
      width: pointerDimension,
      height: pointerDimension
    };

    const iconStyle: CSSProperties = {
      width: pointerIconDimension,
      height: pointerIconDimension
    };

    return (
      <div
        ref={ref}
        className={cn(
          'flex items-center justify-center rounded-full border',
          {
            'border-indigo-600 bg-indigo-400 text-white': !failure,
            'border-red-600 bg-red-400 text-white': failure
          },
          className
        )}
        style={{ ...pointerStyle, ...style }}
        aria-label={`Click pointer ${failure ? 'failure' : 'success'}`}
        {...rest}
      >
        <>
          {children ? (
            <Text className='text-sm'>{children}</Text>
          ) : (
            <>{failure ? <XSVG style={iconStyle} /> : <CheckMark2SVG style={iconStyle} />}</>
          )}
        </>
      </div>
    );
  }
);
