import * as React from 'react';

import { createRoot } from 'react-dom/client';

export function renderComp<T = any>(id: string, Component: React.FC<React.PropsWithChildren<T>>): void {
  return renderComponent(Component, id);
}

export function renderComponent<T extends Record<any, any>>(
  Component: React.FC<React.PropsWithChildren<T>>,
  id: string,
  parserFn?: (data: any) => any
): void {
  const el = document.getElementById(id);
  if (!el) {
    return;
  }
  const data = JSON.parse(el.dataset.props || '{}');
  const props: T = parserFn ? parserFn(data) : data;
  const root = createRoot(el);
  root.render(<Component {...props} />);
}
