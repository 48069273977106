import React, { FC, useMemo, useState } from 'react';

import { Button, Tabs } from '@components/common';
import { MergeCells } from '@components/shared/Tiptap/icons';
import { api } from '@components/SurveyBuilder';
import { compact } from '@components/utils';
import { useToaster } from '@stores/toaster';

import {
  AgreementMatrix,
  CardSortSummary,
  CardsTable,
  CategoriesTable,
  MergeCategoriesModal,
  ResponseDetailsModal,
  ResponsesTable,
  UnmergeCategoryModal
} from './components';
import { LABELS, TABS } from './constants';
import * as toasts from './toasts';
import { AgreementRate, TabValues } from './types';
import { consolidateCards, getCustomCategories } from './utils';

interface Props {
  answers: ScreenerResponseAnswerValue<'card_sort'>[];
  blockableId: number;
}

export const CardSortResponse: FC<Props> = ({ answers, blockableId }) => {
  const [activeTab, setActiveTab] = useState<TabValues>('table');
  const [mergeModal, setMergeModal] = useState<{ name?: string; categories?: string[] } | null>(null);
  const [unmergeModal, setUnmergeModal] = useState<string | null>(null);
  const [preview, setPreview] = useState<NonNullable<ScreenerResponseAnswerValue<'card_sort'>> | null>(null);

  const showToast = useToaster();

  const { data, refetch } = api.useGetSurveyBuilderCardSortBlockableQuery({ id: blockableId });

  const [updateBlockable, { isLoading }] = api.useUpdateSurveyBuilderBlockableMutation();

  const validAnswers = compact(answers) as NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];

  const categories = useMemo(
    () => (data?.sort_type === 'closed' ? data?.categories || [] : getCustomCategories(validAnswers)),
    [data?.categories, data?.sort_type, validAnswers]
  );

  const agreementRates: AgreementRate[] = useMemo(() => {
    if (!data?.cards) return [];

    return categories.map((category) => {
      const agreements: { card: string; agreement: number }[] = data?.cards.map((card) => {
        const cardAgreements = validAnswers.reduce((acc, answer) => {
          const currentResult = answer.results.find(({ name }) => name === category);

          if (currentResult?.cards.includes(card)) {
            acc += 1;
          }

          return acc;
        }, 0);

        return {
          card,
          agreement: parseFloat((cardAgreements / validAnswers.length).toFixed(2))
        };
      });

      const nonZeroAgreements = agreements.filter(({ agreement }) => agreement > 0);

      return {
        category,
        agreements: nonZeroAgreements
      };
    });
  }, [categories, data?.cards, validAnswers]);

  const categoryAvgAgreements = useMemo(() => {
    return agreementRates?.map(({ category, agreements }) => {
      const avgAgreement = agreements.reduce((acc, { agreement }) => acc + agreement, 0) / agreements.length;

      return {
        category,
        avgAgreement
      };
    });
  }, [agreementRates]);

  const overallAgreement = useMemo(() => {
    const nonEmptyAgreements = categoryAvgAgreements?.filter(({ avgAgreement }) => !isNaN(avgAgreement));

    return Math.round(
      ((nonEmptyAgreements?.reduce((acc, { avgAgreement }) => acc + avgAgreement, 0) ?? 0) * 100) /
        (nonEmptyAgreements?.length || 1)
    );
  }, [categoryAvgAgreements]);

  const onMerge = async ({ name, categories }: { name: string; categories: string[] }) => {
    if (!data) return;

    const mergedCategories = data.merged_categories?.filter((merged) => {
      return !categories.some((category) => merged.categories.includes(category));
    });

    const config = {
      ...data,
      merged_categories: [...(mergedCategories || []), { name, categories }]
    };

    await updateBlockable({
      id: blockableId,
      kind: 'card_sort',
      config
    })
      .then(() => {
        refetch();
        setMergeModal(null);
      })
      .catch(() => showToast(toasts.failedMerge()));
  };

  const onUnmerge = async (category: string) => {
    if (!data) return;

    const config = {
      ...data,
      merged_categories: [...(data.merged_categories?.filter((merged) => merged.name !== category) || [])]
    };

    await updateBlockable({
      id: blockableId,
      kind: 'card_sort',
      config
    })
      .unwrap()
      .then(() => {
        refetch();
        setUnmergeModal(null);
      })
      .catch(() => showToast(toasts.failedUnmerge()));
  };

  const mergedCategoriesNames = useMemo(() => {
    if (!data?.merged_categories?.length) return [];

    const withoutMerged = categories.filter(
      (category) => !data.merged_categories?.some((merged) => merged.categories.includes(category))
    );

    return [...withoutMerged, ...data.merged_categories.map((merged) => merged.name)].sort();
  }, [data?.merged_categories, categories]);

  const answersWithMergedCategories = useMemo(() => {
    if (!data?.merged_categories?.length) return validAnswers;

    return validAnswers.map((answer) => {
      const a = answer.results.map((result) => {
        const mergedCategory = data?.merged_categories?.find((merged) => merged.categories.includes(result.name));

        if (mergedCategory) {
          return {
            ...result,
            name: mergedCategory.name
          };
        }

        return result;
      });

      return { ...answer, results: consolidateCards(a) };
    });
  }, [data?.merged_categories, validAnswers]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'agreement_matrix':
        return (
          <AgreementMatrix
            showMergedCategoriesToggle={data?.sort_type === 'open'}
            mergedCategoriesNames={mergedCategoriesNames}
            categories={categories}
            mergedCategories={data?.merged_categories || []}
            cards={data?.cards || []}
            answers={validAnswers}
            answersWithMergedCategories={answersWithMergedCategories}
          />
        );
      case 'cards':
        return (
          <CardsTable
            categories={mergedCategoriesNames.length ? mergedCategoriesNames : categories}
            cards={data?.cards || []}
            answers={answersWithMergedCategories}
            mergedCategories={data?.merged_categories || []}
          />
        );
      case 'categories':
        return (
          <CategoriesTable
            avgAgreements={categoryAvgAgreements || []}
            answers={answersWithMergedCategories}
            mergedCategories={data?.merged_categories || []}
            setMergeModal={setMergeModal}
            setUnmergeModal={setUnmergeModal}
            categories={mergedCategoriesNames.length ? mergedCategoriesNames : categories}
            renderMergeButton={() => (
              <div className='flex h-11 items-center justify-end border-b border-gray-200 bg-white px-4'>
                {data?.sort_type === 'open' ? (
                  <Button medium aria-label='Merge categories' onClick={() => setMergeModal({})}>
                    <div className='flex items-center space-x-2'>
                      <MergeCells /> <span>Merge</span>
                    </div>
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            )}
          />
        );
      case 'table':
        return <ResponsesTable setPreview={setPreview} answers={validAnswers} />;
      default:
        return null;
    }
  };

  if (validAnswers.length === 0) {
    return null;
  }

  return (
    <div>
      <CardSortSummary
        overallAgreement={overallAgreement}
        sortType={data?.sort_type || 'closed'}
        answers={validAnswers}
        totalCards={data?.cards.length || 0}
        totalCategories={categories.length}
      />
      <Tabs<TabValues> className='mb-4' current={activeTab} tabs={TABS} labels={LABELS} onSelect={setActiveTab} />
      {renderTabContent()}
      {mergeModal && (
        <MergeCategoriesModal
          isLoading={isLoading}
          onMerge={onMerge}
          onClose={() => setMergeModal(null)}
          categories={categories}
          defaultSelected={mergeModal?.categories}
          defaultMergedCategory={mergeModal?.name}
        />
      )}
      {unmergeModal && (
        <UnmergeCategoryModal
          isLoading={isLoading}
          onUnmerge={() => onUnmerge(unmergeModal)}
          onClose={() => setUnmergeModal(null)}
        />
      )}
      {preview && (
        <ResponseDetailsModal
          mergedCategories={data?.merged_categories || []}
          response={preview}
          onClose={() => setPreview(null)}
        />
      )}
    </div>
  );
};
