import React, { CSSProperties } from 'react';

import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { FlattenedItem } from '@components/common/SortableTree/types';

import { iOS } from '../utils';

import { Props as TreeItemProps, TreeItem } from './TreeItem';

interface Props extends TreeItemProps {
  canSelect?: boolean;
  hasSelectedNode?: boolean;
  renderItem?: (props: TreeItemProps) => React.ReactElement;
  items?: FlattenedItem[];
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
  isSorting || wasDragging ? false : true;

export const SortableTreeItem = ({ id, items, depth, renderItem, ...props }: Props) => {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition
  } = useSortable({
    id,
    animateLayoutChanges,
    disabled: props.hasSelectedNode || props.selected
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  const itemProps = {
    id,
    depth,
    items,
    style,
    ghost: isDragging,
    disableSelection: iOS,
    disableInteraction: isSorting || props.hasSelectedNode || props.selected,
    handleProps: {
      ...attributes,
      ...listeners
    },
    ref: setDraggableNodeRef,
    wrapperRef: setDroppableNodeRef,
    ...props
  };

  return renderItem ? renderItem(itemProps) : <TreeItem {...itemProps} />;
};
