import * as React from 'react';

import { formatDistanceStrict } from 'date-fns';
import Skeleton from 'react-loading-skeleton';

import { Avatar, AvatarSkeleton, Text } from '@components/common';
import { useAccount } from '@hooks/useAccount';
import { AskAiSVG } from 'components/svgs';

type Props = {
  createdAt?: Date;
  creatorId?: number;
  ai: boolean;
};
export const HighlightCreatorInfo: React.FC<React.PropsWithChildren<Props>> = ({ createdAt, creatorId, ai }) => {
  const {
    account: { team }
  } = useAccount();

  const creator = ai ? { name: 'Great Question AI' } : team.find((u) => u.id === creatorId);

  return (
    <div className='flex h-10 w-full items-center space-x-2 border-t border-gray-200 bg-gray-50 px-4'>
      {!creatorId && <AvatarSkeleton className='bg-gray-200' />}

      {!ai && creatorId && creator && <Avatar user={creator} />}
      {ai && <AskAiSVG className='mr-2 h-4 w-4' />}

      {creator && (
        <Text h='200' color='gray-500' className='flex-shrink-0'>
          {creator.name}
        </Text>
      )}

      <Text h='200' color={creator ? 'gray-500' : 'gray-200'}>
        &bull;
      </Text>

      {createdAt && (
        <Text h='200' color='gray-500'>
          {formatDistanceStrict(createdAt, new Date(), { addSuffix: true })}
        </Text>
      )}

      {!createdAt && <Skeleton containerClassName='flex-1' className='h-4 flex-1 rounded-full bg-gray-200' />}
    </div>
  );
};
