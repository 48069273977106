import React from 'react';

import { api } from '@api/reduxApi';
import { Loading, Text } from '@components/common';

export const ConsentLoader: React.FC<PropsWithChildrenFn<{ id: number }, { consents: ConsentCheckbox[] }>> = ({
  id,
  children
}) => {
  const { data, isLoading, isError } = api.useGetStudyConsentCheckboxesQuery(id);
  if (isLoading) {
    return <Loading absolute />;
  }

  return (
    <>
      {typeof children === 'function' && children({ consents: data || [] })}

      {isError && (
        <Text h='400' color='red'>
          Error loading consents
        </Text>
      )}
    </>
  );
};

const ConsentItem: React.FC<React.PropsWithChildren<{ consent: ConsentCheckbox }>> = ({ consent }) => {
  const { label, pre_html, html_id: htmlId, html_name: name, format } = consent;

  return (
    <>
      {pre_html && <div dangerouslySetInnerHTML={{ __html: pre_html }} />}
      <li key={htmlId} className='flex flex-row items-start space-x-2 text-lg'>
        <input
          disabled
          id={htmlId}
          type='checkbox'
          className='mt-1 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
          name={name}
        />
        <label htmlFor={htmlId} className={'text-base font-normal'}>
          {format === 'html' ? <div dangerouslySetInnerHTML={{ __html: label }} /> : label}
        </label>
      </li>
    </>
  );
};

export const Consents: React.FC<React.PropsWithChildren<{ studyId: number }>> = ({ studyId }) => {
  return (
    <ConsentLoader id={studyId}>
      {({ consents }) => (
        <ul className='space-y-2'>
          {consents.map((consent) => (
            <ConsentItem consent={consent} key={consent.key} />
          ))}
        </ul>
      )}
    </ConsentLoader>
  );
};
