import * as React from 'react';

const ZDS: React.FC<React.PropsWithChildren<{ Icon: React.FC<React.PropsWithChildren<{ className?: string }>> }>> = ({
  Icon,
  children
}) => (
  <div className='py-6 text-center'>
    <span className='mx-auto'>
      <Icon className='mx-auto' />
    </span>
    <p className='mt-4 text-gray-500'>{children}</p>
  </div>
);
export default ZDS;
