import * as React from 'react';
import { useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Button } from '@components/common';
import { PageTitle } from '@components/common/helmets';
import { GQSurveyBuilder } from '@components/GQSurveyBuilder';
import { WindowLayout, WindowLayoutBody, WindowStickyHeader } from '@components/layouts/WindowLayout';
import { BackArrowSVG } from '@components/svgs';
import * as toasts from '@components/TemplatesApp/toasts';
import { useAccount } from '@hooks/useAccount';
import { usePermission } from '@hooks/usePermission';
import { useUser } from '@hooks/useUser';
import { useToaster } from '@stores/toaster';

import { DeleteTemplateButton } from '../../components/DeleteTemplateButton';
import { PublishedTitle } from '../../components/PublishedTitle';

interface Props {
  editable?: boolean;
  surveyTemplate: SurveyTemplate;
  setSurveyTemplate: (i: SurveyTemplate) => void;
}
export const SurveyTemplatePage: React.FC<React.PropsWithChildren<Props>> = ({
  editable,
  surveyTemplate,
  setSurveyTemplate
}) => {
  const navigate = useNavigate();

  const showToast = useToaster();

  const canUpdate = usePermission<SurveyTemplate>('updateInterviewTemplate')(surveyTemplate);

  const [update] = api.useUpdateSurveyTemplateScreenerMutation();
  const [updateTemplate, { isError, isSuccess }] = api.useUpdateInterviewTemplateMutation();

  const handleAutosave = async (fields: ScreenerField[]): Promise<Screener | null> => {
    return await update({ id: surveyTemplate.id, fields })
      .unwrap()
      .catch(() => {
        return null;
      });
  };

  const { gq_admin } = useUser();

  const {
    account: { id: accountId }
  } = useAccount();
  // const isFromThisAccount = accountId === surveyTemplate.account_id;

  const redirect = () => {
    if (document.referrer === '') {
      navigate('/study_templates/surveys');
    } else {
      navigate(-1);
    }
  };

  function saveTemplate() {
    updateTemplate({
      id: surveyTemplate.id,
      title: surveyTemplate.title
    });
  }

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedUpdate());
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successUpdate());
    }
  }, [isSuccess]);

  return (
    <WindowLayout>
      <PageTitle>{surveyTemplate.title}</PageTitle>
      <WindowStickyHeader>
        <div className={`flex w-full flex-col space-y-6 pt-3 ${editable ? 'h-46' : ''}`}>
          <button title='back' onClick={redirect}>
            <BackArrowSVG className='text-indigo-600' />
          </button>
          <div className='tablet:flex-row flex w-full flex-col items-center truncate'>
            {editable && canUpdate ? (
              <input
                autoFocus={!surveyTemplate.title}
                name='title'
                className='tablet:text-left tablet:w-auto tablet:mb-0 tablet:mr-4 h800 focus:outline-none h700 mb-2 w-full flex-grow truncate whitespace-nowrap rounded-md text-center placeholder-gray-400'
                value={surveyTemplate.title}
                onChange={(e) => setSurveyTemplate({ ...surveyTemplate, title: e.currentTarget.value })}
                onBlur={saveTemplate}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.currentTarget.blur();
                  }
                }}
                placeholder='Survey template title…'
              />
            ) : (
              <PublishedTitle
                className='tablet:text-left tablet:w-auto tablet:mr-4 w-full flex-grow truncate whitespace-nowrap text-center'
                template={surveyTemplate}
                kind='SurveyTemplate'
              />
            )}
            <div className='flex flex-shrink space-x-2 text-right'>
              {gq_admin && (
                <Button
                  className='whitespace-nowrap'
                  href={`/admin/template/studies/${surveyTemplate.id}/metadata/edit`}
                >
                  Admin settings
                </Button>
              )}
              {canUpdate && (!surveyTemplate.global || gq_admin) && !editable && (
                <Button spa href='edit'>
                  Edit
                </Button>
              )}
              {canUpdate && (
                <DeleteTemplateButton
                  buttonClassName='ml-4'
                  onSubmit={redirect}
                  template={surveyTemplate}
                  kind='SurveyTemplate'
                />
              )}
            </div>
          </div>
        </div>
      </WindowStickyHeader>
      <WindowLayoutBody fixedHeader className='h-full w-full bg-white'>
        {surveyTemplate.screener && (
          <>
            <div id='survey-template-actions' className='px-page flex justify-end pt-4'></div>

            <GQSurveyBuilder
              editable={!!((!surveyTemplate.global || gq_admin) && canUpdate && editable)}
              screener={surveyTemplate.screener}
              save={handleAutosave}
              noImgUpload
              page='templateBuilder'
            />
          </>
        )}
      </WindowLayoutBody>
    </WindowLayout>
  );
};
