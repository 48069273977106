import * as React from 'react';
import { useEffect } from 'react';

import { Button, Modal, ModalHeading, Text } from '@components/common';
import { track } from '@components/tracking';

type Props = {
  ownerName: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const ConfirmChangeModerationStyleModal: React.FC<React.PropsWithChildren<Props>> = ({
  ownerName,
  isLoading,
  onClose,
  onSubmit
}) => {
  useEffect(() => {
    track('viewed_change_moderation_style_modal');
  }, []);

  const handleSubmit = () => {
    track('submitted_change_moderation_style_modal', { action: 'Switch to 1:1' });
    onSubmit();
  };

  return (
    <Modal
      size='sm'
      onClose={onClose}
      renderFooter={() => (
        <div className='flex items-center justify-end space-x-4'>
          <Button onClick={onClose}>Cancel</Button>
          <Button disabled={isLoading} loading={isLoading} primary onClick={handleSubmit}>
            Switch to 1:1
          </Button>
        </div>
      )}
    >
      <ModalHeading className='mb-2'>Switch to 1:1?</ModalHeading>
      <Text h='400'>
        1:1 sessions can only have one moderator. Switching to a 1:1 scheduling style will switch all but {ownerName} to
        study observers.
      </Text>
    </Modal>
  );
};
