import React, { FC } from 'react';

import cn from 'classnames';
import { Droppable } from 'react-beautiful-dnd';

import { GridGallery, GridGalleryBlock, Text } from '@components/common';
import { PlusSVG } from '@components/svgs';
import { CardSortResult, EditCategoryArgs } from '@components/Unmoderated/types';

import { CategoryCard } from './components/CategoryCard';
import { BREAKPOINT_COLS } from './constants';

interface Props {
  canCreate?: boolean;
  categories: CardSortResult[];
  onCategorySelect: (params: {
    card: string;
    newCategory?: string;
    initialCategory?: string;
    categories: CardSortResult[];
  }) => void;
  onNameChange: (args: EditCategoryArgs) => void;
  onCategoryCreate: (categories: CardSortResult[]) => CardSortResult[];
  onCategoryDelete: (category: CardSortResult) => void;
}

export const Categories: FC<Props> = ({
  onCategoryDelete,
  onCategoryCreate,
  canCreate,
  categories,
  onCategorySelect,
  onNameChange
}) => {
  return (
    <section id='card_sort-categories-list' className='flex h-full flex-grow flex-col bg-gray-50 px-2 py-4'>
      <div className='flex flex-col space-y-1 px-4 pb-2'>
        <Text h='600' bold>
          Categories
        </Text>
        <Text h='400' className='mt-1'>
          These are the categories that you bring the cards into.
        </Text>
      </div>
      <div className='flex-1 overflow-auto p-4 pr-0'>
        <GridGallery breakpointCols={BREAKPOINT_COLS}>
          {categories.map((category, i) => (
            <GridGalleryBlock key={i}>
              <Droppable droppableId={category.name}>
                {(dropProvided, { isDraggingOver }) => (
                  <CategoryCard
                    isDraggingOver={isDraggingOver}
                    categories={categories}
                    dropProvided={dropProvided}
                    cards={category.cards || []}
                    category={category}
                    onCategorySelect={onCategorySelect}
                    onNameChange={onNameChange}
                    onCategoryDelete={onCategoryDelete}
                  />
                )}
              </Droppable>
            </GridGalleryBlock>
          ))}
          {canCreate && (
            <GridGalleryBlock>
              <Droppable droppableId='new-category'>
                {(dropProvided, { isDraggingOver }) => (
                  <button
                    type='button'
                    aria-label='Create new category'
                    onClick={() => onCategoryCreate(categories)}
                    className={cn(
                      'flex w-full items-center space-x-2 truncate whitespace-nowrap rounded border bg-white p-4',
                      isDraggingOver ? 'border-dashed border-indigo-600' : 'border-gray-200'
                    )}
                    ref={dropProvided.innerRef}
                    {...dropProvided.droppableProps}
                  >
                    <PlusSVG className='flex-shrink-0' />
                    <span className='truncate'>
                      {isDraggingOver ? 'Drop here to create a category' : 'New category'}
                    </span>
                  </button>
                )}
              </Droppable>
            </GridGalleryBlock>
          )}
        </GridGallery>
      </div>
    </section>
  );
};
