import Bowser from 'bowser';

export enum ErrorMessages {
  Desktop = 'Please open on a desktop device',
  Mobile = 'Please open on a mobile device'
}

export enum ErrorDescriptions {
  Desktop = 'This test link is required to be completed on a desktop device, not tablet or mobile.',
  Mobile = 'This test link is required to be completed on a mobile device, not tablet or desktop.'
}

type Result = {
  hasError: boolean;
  errorMessage?: `${ErrorMessages}`;
  errorDescription?: `${ErrorDescriptions}`;
  userDevice: string;
};

export const useIsCorrectDevice = (studyDeviceType: Study['device_type']): Result => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const userDevice = browser.getPlatformType(true);

  const mobileError = studyDeviceType === 'mobile' && userDevice === 'desktop';
  const desktopError = studyDeviceType === 'desktop' && (userDevice === 'mobile' || userDevice === 'tablet');

  const getErrorInfo = (): Pick<Result, 'errorDescription' | 'errorMessage'> | void => {
    if (desktopError) {
      return {
        errorMessage: ErrorMessages.Desktop,
        errorDescription: ErrorDescriptions.Desktop
      };
    }

    if (mobileError) {
      return {
        errorMessage: ErrorMessages.Mobile,
        errorDescription: ErrorDescriptions.Mobile
      };
    }
  };

  return {
    hasError: desktopError || mobileError,
    userDevice,
    ...getErrorInfo()
  };
};
