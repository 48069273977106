import * as React from 'react';
import { useContext, useMemo, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { GridContext } from '@components/CandidatesApp/CandidatesIndex/CandidatesListPage';
import { EditableCell } from '@components/shared/GridTable/components/inputs/types';
import { CellWrapper } from '@components/shared/GridTable/components/shared';
import { getStateKey, onSelectValue } from '@components/shared/GridTable/components/utils';
import { SelectDropdown } from '@components/shared/SelectDropdown';
import { useEditCandidateAttr } from '@hooks/useEditCandidateAttr';

export const MultipleChoiceCell: EditableCell = (props) => {
  const { state, setState } = useContext(GridContext);

  const { value: initialValue, row, column, updateCellData } = props;

  const stateKey = getStateKey(row);

  const parsedInitialValue: string[] = useMemo(() => {
    if (Array.isArray(initialValue)) {
      return initialValue.filter((x): x is string => typeof x === 'string' && x !== null);
    } else {
      return [];
    }
  }, [initialValue]);

  const [query, setQuery] = useState<string>('');
  const { suggestions, isFetching, canEdit } = useEditCandidateAttr({ query, attrName: column.id });
  const [editing, setEditing] = useState(false);

  const cellValue: string[] = parsedInitialValue ?? [];

  const selected = useMemo(() => cellValue?.map((v) => ({ label: v.toString(), value: v })), [cellValue]);

  const previousValue = state?.[stateKey]?.[column?.id] || parsedInitialValue || [];

  const { callback } = useDebouncedCallback((value) => {
    setQuery(value);
  }, 500);

  const isCustomAttr = column.id.slice(0, 5) === 'extra';

  if (!isCustomAttr) {
    return (
      <div className='truncate'>
        <CellWrapper data-testid='Multiple choice cell'>{initialValue?.join(', ') || ''}</CellWrapper>
      </div>
    );
  }

  const onBlur = (currentValue: string[]): void => {
    const noValues = !currentValue?.length && !previousValue?.length;

    const valuesAreEqualArrays =
      Array.isArray(currentValue) &&
      Array.isArray(previousValue) &&
      JSON.stringify(currentValue.sort()) === JSON.stringify(previousValue.sort());

    if (noValues || valuesAreEqualArrays) {
      setEditing(false);
      return;
    }

    setEditing(false);

    onSelectValue({
      currentValue,
      initialValue,
      rowId: String(stateKey),
      setState,
      updateCellData,
      columnId: column.id
    });
  };

  if (!editing) {
    return (
      <CellWrapper
        data-testid='Multiple choice cell'
        wrapperClass='cursor-pointer hover:bg-indigo-50 hover:text-indigo-600'
        onClick={() => setEditing(true)}
      >
        <div className='truncate'>{cellValue?.join(', ') || ''}</div>
      </CellWrapper>
    );
  }

  return (
    <SelectDropdown
      options={suggestions ?? []}
      value={selected ?? []}
      loading={isFetching}
      onInputValueChange={callback}
      onClose={(value) => {
        onBlur(value.map((v) => v.value));
      }}
      overrides={{
        Input: {
          props: {
            placeholder: 'Enter…',
            autoFocus: true
          }
        },
        Item: {
          props: {
            className: 'xx-combo-option'
          }
        }
      }}
      onCreate={({ value }) => onBlur([value])}
      creatable
      multi
      defaultIsOpen
      keepSelectedOptions
      closeOnSelect={false}
    />
  );
};
