import { useSelector } from 'react-redux';

import { RootState } from 'api/reduxApi';

type Params = {
  state: RootState['api'];
  mutations?: string[];
  queries?: string[];
};

type Hook = (params: { mutations?: string[]; queries?: string[] }) => boolean;

export const getpendingRequests = ({ state, mutations, queries }: Params): unknown[] => {
  let pendingRequests: unknown[] = [];

  if (!mutations && !queries) return pendingRequests;

  if (mutations && state?.mutations) {
    const pendingMutations = Object.values(state.mutations).filter(
      (m) => m?.status === 'pending' && mutations.includes(m.endpointName)
    );
    pendingRequests = [...pendingRequests, ...pendingMutations];
  }

  if (queries && state.queries) {
    const pendingQueries = Object.values(state.queries).filter(
      (q) => q?.status === 'pending' && queries.includes(q.endpointName)
    );
    pendingRequests = [...pendingRequests, ...pendingQueries];
  }

  return pendingRequests;
};

export const usePendingRequests: Hook = ({ mutations, queries }) => {
  const pendingRequests = useSelector((state: RootState) =>
    getpendingRequests({ state: state.api, mutations, queries })
  );

  return !!pendingRequests?.length;
};
