import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { SUPPORT_EMAIL } from '@constants/emails';
import Tippy from '@tippyjs/react';

import { Alert, Button, Input, Loading, Text } from '@components/common';
import { compact, noop } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { usePermission } from '@hooks/usePermission';
import { usePlan } from '@hooks/usePlan';
import { useUser } from '@hooks/useUser';
import { Toast, useToaster } from '@stores/toaster';
import { api } from 'api/reduxApi';

import { RoleOptions } from '../components/RoleOptions';
import { SeatInfo } from '../components/SeatInfo';
import { ConfirmModal, RemoveUserModal, UpgradeModal } from '../modals';

const ERRORS = {
  active_studies: 'The user currently has active studies.',
  no_other_admins: 'This user is the only admin user.',
  invalid_new_owner: 'The new owner of their studies is invalid.',
  account_owner: 'The user is the account owner. Please contact support.'
};

function errorMessage(data: any): string {
  if (data?.email) {
    return 'There was a problem with that email or they have already been invited';
  } else {
    const errors = compact(data?.errors || []).map((code: string) => ERRORS[code]);
    return errors.length > 0 ? errors.join(' ') : `Please contact ${SUPPORT_EMAIL}`;
  }
}

interface InviteProps {
  type: 'Invitation' | 'User';
  method: 'update' | 'create';
  record?: any;
  title?: string;
  accountUserId?: number;
  onUpdate: (params: { role: AccountRole; name: string; email: string }) => Promise<AccountInvitation | WhoamiPlan>;
}

const successToast = (params: Pick<InviteProps, 'type' | 'method'> & { name: string; inviteRole: string }): Toast => {
  const { type, method, name, inviteRole } = params;
  if (type == 'User') {
    return {
      heading: 'Role change success',
      text: `${name} is now a ${inviteRole}.`,
      icon: 'success'
    };
  } else if (method == 'create') {
    return {
      heading: 'Invitation sent',
      text: 'Your team mate will receive an email shortly to join the account.',
      icon: 'success'
    };
  } else {
    return {
      heading: 'Invitation updated',
      text: "Your team mate's invitation has been updated",
      icon: 'success'
    };
  }
};
export const Invite: React.FC<React.PropsWithChildren<InviteProps>> = ({
  onUpdate,
  type,
  method,
  accountUserId,
  title = 'Invite teammate',
  record = {}
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [name, setName] = useState<string>(record.name || '');
  const [email, setEmail] = useState<string>(record.email || '');

  const { id: currentUserId } = useUser();

  const [errors, setErrors] = useState<any>({});
  const [inviteRole, setInviteRole] = useState<AccountRole>(record.role);
  const showToast = useToaster();
  const { account } = useAccount();
  const { hasQuota, getQuota, buySeats, refresh, costs } = usePlan();
  const canUseSeat = usePermission('canUseSeat')();
  const canBuySeat = usePermission('canBuySeat')() && !!costs.seat;
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const [deleteInvitation] = api.useDeleteInvitationMutation();

  async function handleOpen() {
    if (!email || email === '') {
      setErrors({ email: 'required' });
      return;
    }
    if (!inviteRole) {
      setErrors({ role: 'required' });
      return;
    }
    if (inviteRole === 'deactivated') {
      setErrors({ role: 'required' });
      return;
    }
    setErrors({});
    setConfirm(true);
  }

  const handleRoleChange = (role: AccountRole) => {
    if (role === 'deactivated') {
      setErrors({ role: 'required' });
      return;
    }
    setErrors({});
    setInviteRole(role);
  };

  // const can = inviteRole === 'observer' && (!record.id || isAdmin)
  const canFree =
    inviteRole === 'observer' || (hasQuota('seats') && canUseSeat) || (record.id && record.role !== 'observer');
  const canBuy = !canFree && canBuySeat;
  const cannotBuy = !canFree && !canBuySeat && !hasQuota('seats');
  // TODO: this could actually get pulled into the modals..
  async function handleSend() {
    setLoading(true);
    if (!canFree && canBuy) {
      const updated = await buySeats(1);
      if (!updated) {
        setLoading(false);
        showToast({
          heading: 'An error occurred',
          text: 'We were unable to increase your seats limit - please try again later.',
          icon: 'error'
        });
        return;
      }
    }

    try {
      await onUpdate({ role: inviteRole, name, email });

      setConfirm(false);
      refresh();
      showToast(successToast({ type, method, name, inviteRole }));
      navigate(`/accounts/${account.id}`);
    } catch (err) {
      setLoading(false);
      showToast({
        heading: 'There was an error sending the invitation',
        text: errorMessage(err?.data),
        icon: 'error'
      });
    }
  }

  async function handleDelete() {
    await deleteInvitation(record.token);
    refresh();
    navigate(`/accounts/${account.id}`);
  }

  let newDelta = 0;
  if (record.role === 'deactivated' && inviteRole !== 'deactivated') {
    newDelta = inviteRole === 'observer' ? 0 : 1;
  } else if ((!record.role || record.role === 'observer') && inviteRole !== 'observer') {
    newDelta = 0 - 1;
  } else if (record.role && record.role !== 'observer' && inviteRole === 'observer') {
    newDelta = 1;
  }

  function canInvite(role) {
    return role === 'observer' || (canUseSeat && hasQuota('seats')) || canBuySeat || canFree;
  }

  const seatsQuota = getQuota('seats');

  const isAdmin = record.role === 'admin';
  const isDeactivated = record.role === 'deactivated';
  const isCurrent = record.id === currentUserId;
  const canRemove = accountUserId && !isCurrent && !isAdmin && !isDeactivated;

  const inviteCta = record.id ? `Update ${type}` : 'Send Invitation';
  const reactivateCta = `Reactivate ${type}`;

  return (
    <div className='desktop:p-10 rounded-lg border border-gray-200 bg-white p-4'>
      {loading && <Loading absolute />}
      <Text h='800' className='mb-2'>
        {title}
      </Text>
      <SeatInfo />
      <div className='mb-6'>
        <Text h='400' bold className='mb-1'>
          Name
        </Text>
        <Input
          disabled={record.id}
          value={name}
          name='name'
          onChange={setName}
          placeholder='Enter their name.'
          className='w-full'
        ></Input>
      </div>
      <div className='mb-6'>
        <Text h='400' bold className='mb-1'>
          Email address
        </Text>
        <Input
          disabled={record.id}
          error={errors.email}
          name='email'
          value={email}
          onChange={setEmail}
          placeholder='Enter or paste email address.'
          className='w-full'
        ></Input>
        {errors.email && (
          <Text h='200' color='red-600' className='mt-1'>
            Please provide a valid email address
          </Text>
        )}
      </div>
      {cannotBuy && (
        <Alert type='warning' className='mb-6' heading='Seat limit reached'>
          You have reached your seat limit and can only invite observers. Please contact your Customer Success Manager
          or support@greatquestion.co to add more seats.
        </Alert>
      )}
      <div className='mb-6'>
        <Text h='400' bold className='mb-1'>
          Role
        </Text>
        <RoleOptions
          isDisabled={(r) => !canInvite(r)}
          onChange={handleRoleChange}
          selected={inviteRole}
          error={errors.role}
        />
        {errors.role && (
          <Text h='200' color='red-600' className='mt-1'>
            Please select a role
          </Text>
        )}
      </div>
      <div className='flex justify-between'>
        <div className='space-x-4'>
          <Button primary onClick={() => handleOpen()}>
            {isDeactivated ? reactivateCta : inviteCta}
          </Button>
          <Button link spa href={`/accounts/${account.id}`}>
            Cancel
          </Button>
        </div>
        {record.id && type === 'Invitation' && (
          <Button link onClick={() => handleDelete()}>
            <span className='text-red-500'>Cancel invite</span>
          </Button>
        )}
        {accountUserId && canRemove && <Button onClick={() => setRemoveModalOpen(true)}>Remove account member</Button>}
        {accountUserId && !canRemove && !isDeactivated && (
          <Tippy
            content={
              isAdmin
                ? "Admin users can't be removed from an account. Remove admin permissions first."
                : "You can't remove yourself from an account."
            }
          >
            <span>
              <Button onClick={noop} disabled>
                Remove account member
              </Button>
            </span>
          </Tippy>
        )}
      </div>
      {confirm && canFree && !isDeactivated && (
        <ConfirmModal
          type={type}
          method={method}
          role={inviteRole}
          onCancel={() => setConfirm(false)}
          onSubmit={handleSend}
          newQuota={seatsQuota + newDelta}
          cta={record.id ? 'Update' : 'Confirm & Send'}
        />
      )}
      {confirm && canFree && isDeactivated && (
        <ConfirmModal
          type={type}
          method={'reactivate'}
          role={inviteRole}
          onCancel={() => setConfirm(false)}
          onSubmit={handleSend}
          newQuota={seatsQuota + newDelta}
          title={'Confirm reactivation'}
          cta={'Reactivate'}
        />
      )}
      {confirm && canBuy && (
        <UpgradeModal
          type={type}
          method={method}
          role={inviteRole}
          onCancel={() => setConfirm(false)}
          onSubmit={handleSend}
          cta={record.id ? 'Update' : 'Confirm & Send'}
        />
      )}
      {canRemove && (
        <RemoveUserModal
          open={removeModalOpen}
          accountUserId={accountUserId}
          name={name}
          onClose={() => setRemoveModalOpen(false)}
          onComplete={() => {
            setRemoveModalOpen(false);
            navigate(`/accounts/${account.id}`);
          }}
          newQuota={record.role === 'observer' ? seatsQuota : seatsQuota - 1}
        />
      )}
    </div>
  );
};
