import * as React from 'react';
import { useEffect, useState } from 'react';

import cn from 'classnames';
import ReactSkeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { Text } from '@components/common';

interface DashboardHeaderProps {
  h1?: string;
  study?: boolean;
  breadcrumb?: [string, string];
  renderHeaderButtons?: () => React.ReactNode;
  sticky?: boolean;
  withTabs?: boolean;
  addCandidatesModal?: boolean;
  headerWrapperClass?: string;
  headerButtonsClass?: string;
}
export const DashboardHeaderSkeleton: React.FC<React.PropsWithChildren<unknown>> = ({}) => (
  <div className='px-page pt-gutter border-b border-gray-200 bg-white pb-4'>
    <div className='flex pb-2'>
      <ReactSkeleton duration={1} width={500} className='h-10 rounded-md bg-gray-50' />
    </div>
  </div>
);

export const DashboardBreadcrumb: React.FC<React.PropsWithChildren<{ to: string }>> = ({ to, children }) => (
  <nav className='mb-3 flex items-center text-sm leading-5 text-gray-500'>
    <Link className='gray-500' to={to}>
      <Text h='400' color='gray-500'>
        {children}
      </Text>
    </Link>
    <span className='pl-2'>/</span>
  </nav>
);

export const DashboardHeader: React.FC<React.PropsWithChildren<DashboardHeaderProps>> = ({
  h1,
  study,
  breadcrumb,
  renderHeaderButtons,
  sticky = false,
  children,
  withTabs,
  addCandidatesModal,
  headerWrapperClass,
  headerButtonsClass
}) => {
  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const wrapper = document.getElementById('app-main');

    if (!wrapper || !sticky) return;

    const handler = () => {
      setShrunk((isShrunk) => {
        if (!isShrunk && wrapper.scrollTop > 20) {
          return true;
        }

        if (isShrunk && wrapper.scrollTop < 4) {
          return false;
        }

        return isShrunk;
      });
    };

    wrapper.addEventListener('scroll', handler);
    return () => wrapper.removeEventListener('scroll', handler);
  }, []);

  return (
    <div
      className={cn('px-page border-b border-gray-200 bg-white', {
        'sticky top-0 z-30': sticky,
        'pt-8': withTabs && !addCandidatesModal && !study,
        'py-8': !withTabs && !study,
        'pb-4': study
      })}
    >
      {breadcrumb && <DashboardBreadcrumb to={breadcrumb[1]}> {breadcrumb[0]} </DashboardBreadcrumb>}
      <div
        className={cn(
          'transition-height transition-padding mb-2 flex items-center duration-300 ease-in-out',
          headerWrapperClass,
          {
            'max-h-0 overflow-hidden pb-0': isShrunk,
            'max-h-128': sticky && !isShrunk
          }
        )}
      >
        {study ? <h2 className='h700 leading-relaxed'>{h1}</h2> : <h1 className='h800 leading-relaxed'>{h1}</h1>}
        {renderHeaderButtons && (
          <div className={cn('flex flex-grow items-center justify-end space-x-4', headerButtonsClass)}>
            {renderHeaderButtons()}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export const DashboardHeaderShelf: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className='px-page border-b border-gray-200 bg-white py-4'>{children}</div>
);

export const DashboardLayout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className='mx-auto flex h-full flex-col'>{children}</div>
);

export const DashboardLayoutBody: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  className = '',
  children
}) => <div className={'px-page py-gutter relative ' + className}>{children}</div>;
