import { get } from 'react-hook-form';
import { UniqueIdentifier } from '@dnd-kit/core';

import { getPathById } from '@components/common/SortableTree';
import { TreeItem } from '@components/SurveyBuilder/types/models';
import { compact } from '@components/utils';

export const getStyle = (value, total) => ({
  backgroundColor: value ? `rgba(104, 117, 245, ${value / total})` : '#E5E7EB',
  color: value / total > 0.5 ? 'white' : '#374151'
});

type GetValue = (args: { showPercentage: boolean; value: number; totalAnswers: number }) => string;

export const getValue: GetValue = ({ value, totalAnswers, showPercentage }) => {
  if (!value) return '';

  const cell = showPercentage ? `${Math.round(((value || 0) / totalAnswers || 1) * 100)} %` : value.toString();

  return cell;
};

export const findNodeDeep = <T extends TreeItem>(items: T[], itemId: UniqueIdentifier): T | undefined => {
  for (const item of items) {
    const { id, nodes } = item;

    if (id === itemId) {
      return item;
    }

    if (nodes.length) {
      const node = findNodeDeep(nodes as TreeItem[], itemId) as T | undefined;

      if (node) {
        return node;
      }
    }
  }

  return undefined;
};

export const getSuccessCount = (
  answers: NonNullable<ScreenerResponseAnswerValue<'tree_test'>>[],
  path: UniqueIdentifier[]
) => {
  const count = answers.reduce((acc, answer) => {
    return answer.result.answer.join(',').startsWith(path.join(',')) ? acc + 1 : acc;
  }, 0);

  return count;
};

export const getDirectCount = (
  answers: NonNullable<ScreenerResponseAnswerValue<'tree_test'>>[],
  nodeId: string | number
) => {
  const count = answers.reduce((acc, answer) => {
    return answer.result.direct && answer.result.answer.includes(nodeId) ? acc + 1 : acc;
  }, 0);

  return count;
};

export const parseItems = (
  currentItems: TreeItem[],
  allItems: TreeItem[],
  answers: NonNullable<ScreenerResponseAnswerValue<'tree_test'>>[]
) => {
  return currentItems.map((item) => {
    const path = getPathById(allItems, item.id);
    const count = getSuccessCount(answers, path);
    const directCount = getDirectCount(answers, item.id);

    return {
      ...item,
      nodes: parseItems(item.nodes || [], allItems, answers),
      count,
      directCount,
      collapsed: !count
    };
  });
};

// this function is used to get the labels of the answers
// we used to store only node IDs as a result of the tree test
// but removing the node from the task broke the results
// that's why we store the labels of the answers as well
// but we need to support the old results as well (without labels)
// as it may affect the beta-testing users

export const getAnswerLabels = (
  result: NonNullable<ScreenerResponseAnswerValue<'tree_test'>>['result'],
  nodes: TreeItem[]
) => {
  if (result.answer_labels) {
    return result.answer_labels;
  } else {
    return compact(result.answer.map((answerId) => findNodeDeep(nodes, answerId)?.label));
  }
};
