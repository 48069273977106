import React, { CSSProperties, forwardRef, HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';

import mergeRefs from 'react-merge-refs';

import { api, BlockableComponents } from '@components/SurveyBuilder';
import { WarningSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

import { ClickPointer, POINTER_SIZE_MAP } from './ClickPointer';

type Dimensions = { width: number; height: number };

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  prototypeTestId: number;
  clicks: PrototypeClick[];
  hideClicks?: boolean;
  pointerSize?: 'sm' | 'md' | 'lg';
  screenExternalElementId: string;
  thumbnailSize?: number;
}

const resizeImage = (boxDimensions: Dimensions, imageDimensions: Dimensions): Dimensions => {
  const { width: boxWidth, height: boxHeight } = boxDimensions;
  const { width: imgWidth, height: imgHeight } = imageDimensions;

  if (imgWidth <= boxWidth && imgHeight <= boxHeight) {
    return { width: imgWidth, height: imgHeight };
  }

  const boxAspectRatio = boxWidth / boxHeight;
  const imgAspectRatio = imgWidth / imgHeight;

  let newWidth: number, newHeight: number;

  if (imgAspectRatio > boxAspectRatio) {
    newWidth = boxWidth;
    newHeight = boxWidth / imgAspectRatio;
  } else {
    newHeight = boxHeight;
    newWidth = boxHeight * imgAspectRatio;
  }

  return { width: newWidth, height: newHeight };
};

export const ScreenThumbnail = forwardRef<HTMLDivElement, Props>(
  (
    { clicks, hideClicks, pointerSize = 'sm', prototypeTestId, screenExternalElementId, thumbnailSize, ...rest },
    ref
  ) => {
    const [screen, setScreen] = useState<BlockableComponents.PrototypeTestScreen>();
    const [imageError, setImageError] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const { data: screens, isLoading: isLoadingScreens } = api.useGetPrototypeTestScreensQuery(prototypeTestId);

    const relativeDimensions = useMemo<Dimensions>(() => {
      if (screen && screen.metadata && screen.metadata.dimensions && wrapperRef.current) {
        const [width, height] = screen.metadata.dimensions;

        const wrapperDimensions = {
          width: wrapperRef.current.offsetWidth,
          height: wrapperRef.current.offsetHeight
        };

        return resizeImage(wrapperDimensions, { width, height });
      }

      return { width: 0, height: 0 };
    }, [screen, wrapperRef.current]);

    const pointerDimension = POINTER_SIZE_MAP[pointerSize];

    const style: CSSProperties = {
      width: relativeDimensions.width,
      height: relativeDimensions.height
    };

    const thumbnailUrl = useMemo(() => {
      if (!screen) {
        return null;
      }

      if (thumbnailSize && screen.thumbnail_variants) {
        return screen.thumbnail_variants[thumbnailSize.toString()] || screen.thumbnail_url;
      }

      return screen.thumbnail_url;
    }, [screen, thumbnailSize]);

    const clickPointerStyle = (click: PrototypeClick): CSSProperties => {
      const pointer = { left: 0, top: 0, width: pointerDimension, height: pointerDimension };

      if (screen && screen.metadata && screen.metadata.dimensions) {
        const [width, height] = screen.metadata.dimensions;

        const x = click.position?.x ?? 0;
        const y = click.position?.y ?? 0;

        const calculatedLeft = (x / width) * relativeDimensions.width - pointer.width / 2;
        const calculatedTop = (y / height) * relativeDimensions.height - pointer.height / 2;

        pointer.left = Math.max(0, calculatedLeft);
        pointer.top = Math.max(0, calculatedTop);
      }

      return pointer;
    };

    const handleImageError = () => {
      setImageError(true);
    };

    useEffect(() => {
      if (screens && screens.length) {
        setScreen(screens.find((s) => s.external_element_id === screenExternalElementId));
      }
    }, [screens, screenExternalElementId]);

    return (
      <div ref={mergeRefs([wrapperRef, ref])} {...rest}>
        {!isLoadingScreens && !screen && (
          <Tooltip content={'Re-sync your prototype in the\nTest section to view thumbnails'}>
            <WarningSVG />
          </Tooltip>
        )}
        <div className='relative overflow-hidden rounded border border-gray-200' style={style}>
          {screen && (
            <>
              <div
                className='pointer-events-none absolute inset-0'
                aria-label={`Prototype screen ${screen.external_element_id} clicks`}
              >
                {!hideClicks &&
                  clicks.map((click, index) => (
                    <ClickPointer
                      key={index}
                      size={pointerSize}
                      failure={!click.handled}
                      style={clickPointerStyle(click)}
                      className='absolute'
                    />
                  ))}
              </div>

              {thumbnailUrl && !imageError && (
                <img
                  src={thumbnailUrl}
                  alt={`Prototype screen ${screen.external_element_id}`}
                  onError={handleImageError}
                />
              )}
            </>
          )}
        </div>
        {imageError && (
          <div className='absolute'>
            <Tooltip content={'Re-sync your prototype in the\nTest section to view thumbnails'}>
              <WarningSVG />
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
);
