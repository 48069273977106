import * as React from 'react';
import { useState } from 'react';

import { Button, Tabs, Text } from '@components/common';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { useFeature } from '@hooks/useFeature';
import { usePermission } from '@hooks/usePermission';

import { UsersList } from './UsersList';

export const UsersIndex: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [currentTab, setCurrentTab] = useState('Members');
  const isAdmin = usePermission('isAdmin')();

  const enableTeams = useFeature('teams');

  return (
    <>
      <div className='monitor:mb-6 mb-4 flex items-end justify-between'>
        <div className='flex items-center'>
          <Text h='700' as='h1' bold>
            {enableTeams ? 'Members' : 'Team'}
          </Text>
        </div>
        <div>
          <RestrictedButton action='Invite new user' permission='createAccountInvitation'>
            <Button medium spa primary href='/invitations/new'>
              Invite new user
            </Button>
          </RestrictedButton>
        </div>
      </div>
      <div className='desktop:p-10 rounded-lg border border-gray-200 bg-white p-4'>
        {isAdmin && <Tabs current={currentTab} tabs={['Members', 'Deactivated']} onSelect={(t) => setCurrentTab(t)} />}
        <div className='pt-4'>
          {currentTab == 'Members' && <UsersList list='active' />}
          {currentTab == 'Deactivated' && <UsersList list='deactivated' />}
        </div>
      </div>
    </>
  );
};
