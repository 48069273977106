import React from 'react';

import pluralize from 'pluralize';

import { Alert } from '@components/common';

import { Batching } from '../types';

type ExistingBatchProps = {
  batch: Batching;
  addToBatch: boolean;
  setAddToBatch: (v: boolean) => void;
};
export const ExistingBatch: React.FC<React.PropsWithChildren<ExistingBatchProps>> = ({
  batch,
  addToBatch,
  setAddToBatch
}) => (
  <Alert noIcon className='mt-6'>
    <>
      <span className='mb-2 block'>Invites are currently being sent in batches.</span>
      <label className='mb-2'>
        <input type='radio' className='mr-2' checked={addToBatch} onChange={() => setAddToBatch(true)} />
        <span>
          Add to current batch (<b>{pluralize('invites', batch.size, true)} </b>every <b>{batch.wait_hours} hours</b>)
        </span>
      </label>
      <label>
        <input type='radio' className='mr-2' checked={!addToBatch} onChange={() => setAddToBatch(false)} />
        Send immediately
      </label>
    </>
  </Alert>
);
