import * as React from 'react';

import {
  AD_HOC_MESSAGE_SLIDEOUT,
  CANCEL_INTERVIEW_SLIDEOUT,
  CANCEL_TASK_SLIDEOUT,
  COMPLETE_AND_THANKS,
  FUND_STUDY,
  MANUAL_RESCHEDULE_MODAL,
  MANUAL_SCHEDULE_SLIDEOUT,
  RESCHEDULE_REQUEST_SLIDEOUT,
  SEND_INVITED_REMINDER,
  SEND_INVITES,
  SEND_REMINDERS,
  SEND_SCREENER,
  SEND_THANKS,
  StudyAction,
  UPDATE_MODERATOR
} from '@components/StudiesApp/components/StudyPublished/components/ACTIONS';
import { ManualRescheduleModal } from '@components/StudiesApp/components/StudyPublished/components/ManualRescheduleModal';
import { UpdateModeratorModal } from '@components/StudiesApp/components/StudyPublished/components/UpdateModeratorModal';
import {
  CancelSlideOut,
  CancelTaskSlideOut,
  GenericMessageSlideOut,
  ManualScheduleSlideOut,
  ParticipationsInviteSlideOut,
  ParticipationsScreenerSlideOut,
  ThanksSlideOut
} from '@components/StudyMessages';
import { ChangeIncentiveModal, useStudyFunder } from '@components/Wallet';
import { ParticipationsInviteSlideOutServerSide } from 'components/StudyMessages/ParticipationsInviteSlideOutServerSide';
import { ParticipationsScreenerSlideOutServerSide } from 'components/StudyMessages/ParticipationsScreenerSlideOutServerSide';
import { useFeature } from 'hooks/useFeature';

interface Props {
  mode: StudyAction | null;
  study: Study;
  participations: Participation[];
  participationIds?: number[];
  onClose: () => void;
  onSuccess: (backgroundTask?: BackgroundTask, mode?: StudyAction | null) => void;
  allSelected?: boolean;
  query?: ServerFilterQuery;
}

export const EmailSlideOuts: React.FC<React.PropsWithChildren<Props>> = ({
  query,
  mode,
  study,
  participations,
  participationIds = [],
  onClose,
  onSuccess,
  allSelected
}) => {
  const funder = useStudyFunder({ study });
  const serverSideParticipationsEnabled = useFeature('serverside_participations');

  const handleSuccess = (backgroundTask?: BackgroundTask) => {
    onSuccess(backgroundTask, mode);
  };

  const previewParticipation: Participation | undefined = participations[0];
  const selectedParticipationIds: number[] | undefined = React.useMemo(() => {
    return allSelected ? undefined : Array.from(new Set(participationIds.concat(participations.map((p) => p.id))));
  }, [allSelected, participationIds, participations]);
  const selectedCandidateIds: number[] | undefined = allSelected ? undefined : participations.map((p) => p.customer_id);

  const props = {
    allSelected,
    study,
    previewParticipation,
    query,
    selectedParticipationIds,
    selectedCandidateIds,
    participations,
    onClose,
    onSuccess: handleSuccess
  };

  return (
    <>
      {mode === AD_HOC_MESSAGE_SLIDEOUT && (
        <GenericMessageSlideOut title='Ad hoc message' cta='Send message' event='ad_hoc' {...props} />
      )}
      {mode === SEND_INVITES && !serverSideParticipationsEnabled && <ParticipationsInviteSlideOut {...props} />}
      {mode === SEND_INVITES && serverSideParticipationsEnabled && (
        <ParticipationsInviteSlideOutServerSide {...props} />
      )}
      {mode === SEND_SCREENER && !serverSideParticipationsEnabled && <ParticipationsScreenerSlideOut {...props} />}
      {mode === SEND_SCREENER && serverSideParticipationsEnabled && (
        <ParticipationsScreenerSlideOutServerSide {...props} />
      )}
      {mode === FUND_STUDY && (
        <ChangeIncentiveModal
          onConfirm={handleSuccess}
          limitCount={study.maximum_slots}
          study={study}
          funder={funder}
          onCancel={onClose}
        />
      )}
      {mode === SEND_INVITED_REMINDER && (
        <GenericMessageSlideOut
          title='Send invitation reminder'
          cta='Send reminder'
          event='invited_reminder'
          {...props}
        />
      )}
      {mode === SEND_REMINDERS && (
        <GenericMessageSlideOut
          title='Send reminder'
          cta='Send reminder'
          event={`${study.moderated ? 'booked' : 'started'}_reminder` as StudyMessageEvent}
          {...props}
        />
      )}
      {mode === SEND_THANKS && <ThanksSlideOut messageOnly {...props} />}
      {mode === COMPLETE_AND_THANKS && <ThanksSlideOut {...props} />}
      {mode === RESCHEDULE_REQUEST_SLIDEOUT && (
        <GenericMessageSlideOut title='Reschedule request' cta='Send request' event='reschedule_request' {...props} />
      )}
      {mode === UPDATE_MODERATOR && <UpdateModeratorModal {...props} />}
      {mode === MANUAL_SCHEDULE_SLIDEOUT && <ManualScheduleSlideOut {...props} />}
      {mode === MANUAL_RESCHEDULE_MODAL && (
        <ManualRescheduleModal
          study={study}
          participation={participations[0]}
          onClose={onClose}
          onSuccess={handleSuccess}
        />
      )}
      {mode === CANCEL_INTERVIEW_SLIDEOUT && <CancelSlideOut {...props} />}
      {mode === CANCEL_TASK_SLIDEOUT && <CancelTaskSlideOut {...props} />}
    </>
  );
};
