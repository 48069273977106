import * as React from 'react';
import { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { Button, SlideOut, Spinner, Text } from '@components/common';
import { FieldRow } from '@components/shared/BookingDetailsForm/components/FieldRow';
import { durationDisplayText } from '@components/shared/BookingDetailsForm/fields/Duration';
import { formatDate, formatTime } from '@components/shared/BookingDetailsForm/utils';
import { useToaster } from '@stores/toaster';

import { useProfileSlideoutNavigate } from '../routerContext';

import { DiffRow } from './DiffRow';
import { EmailChanges } from './EmailChanges';
import { GuestsDiff } from './GuestsDiff';
import { LocationDiff } from './LocationDiff';
import * as toasts from './toasts';
import {
  getEmailChanges,
  getValueFor,
  hasChangeFor,
  hasDateChange,
  hasEmailChanges,
  hasModeratorChanges,
  hasObserverChanges,
  hasSettingsChanges,
  hasTimeChange
} from './utils';

export type Props = {
  onClose: () => void;
  participation: Participation;
  changes: DeepPartial<CalendarBookingDetail>;
  clearChanges: () => void;
};

export const ReviewBookingDetailsSlideout: React.FC<React.PropsWithChildren<Props>> = ({
  changes,
  clearChanges,
  onClose,
  participation
}) => {
  const navigate = useProfileSlideoutNavigate();

  const onBack = () => navigate('edit_booking_detail');

  const showToast = useToaster();
  const { data: bookingDetail, isLoading: isLoadingBookingDetail } = api.useGetParticipationCalendarBookingDetailsQuery(
    {
      id: participation.id
    }
  );
  const { data: study, isLoading: isLoadingStudy } = api.useGetSimpleStudyQuery(participation.project_id);
  const isLoading = isLoadingBookingDetail || isLoadingStudy;
  const [updateBookingDetail, { isLoading: isUpdating, isSuccess, isError }] =
    api.useUpdateParticipationCalendarBookingDetailsMutation();

  const handleClickSave = () => updateBookingDetail({ id: participation.id, bookingDetail: changes });

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successUpdate);
      clearChanges();
      navigate('profile');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedUpdate);
    }
  }, [isError]);

  return (
    <>
      <SlideOut
        title='Review changes'
        onClose={onClose}
        onBack={onBack}
        size='2xl'
        renderFooter={() => (
          <div className='flex items-center justify-end space-x-4'>
            <Button loading={isUpdating} primary onClick={handleClickSave} disabled={isUpdating}>
              Confirm changes and send updates
            </Button>
            <Button onClick={onBack}>Back to editing</Button>
          </div>
        )}
      >
        <div className='px-6 pb-12'>
          {(!study || !bookingDetail) && isLoading && (
            <div className='mx-auto py-12'>
              <Spinner className='h-10 w-10' />
            </div>
          )}
          {study && bookingDetail && (
            <div className='flex flex-col space-y-10'>
              {hasSettingsChanges(changes) && (
                <div>
                  <Text as='h3' h='600' bold mb='6'>
                    Settings
                  </Text>
                  {hasDateChange(bookingDetail, changes) && (
                    <FieldRow label='Date'>
                      <DiffRow
                        leftSide={formatDate(getValueFor<Date>('start_time', bookingDetail, new Date()))}
                        rightSide={formatDate(getValueFor<Date>('start_time', changes, new Date()))}
                      />
                    </FieldRow>
                  )}
                  {hasTimeChange(bookingDetail, changes) && (
                    <FieldRow label='Time'>
                      <DiffRow
                        leftSide={formatTime(getValueFor<Date>('start_time', bookingDetail, new Date()))}
                        rightSide={formatTime(getValueFor<Date>('start_time', changes, new Date()))}
                      />
                    </FieldRow>
                  )}
                  {hasChangeFor('duration', bookingDetail, changes) && (
                    <FieldRow label='Duration'>
                      <DiffRow
                        leftSide={durationDisplayText(getValueFor<number>('duration', bookingDetail, 0))}
                        rightSide={durationDisplayText(getValueFor<number>('duration', changes, 0))}
                      />
                    </FieldRow>
                  )}
                </div>
              )}
              {hasModeratorChanges(changes) && (
                <div>
                  <Text as='h3' h='600' bold mb='6'>
                    Moderator settings
                  </Text>
                  {hasChangeFor('moderators', bookingDetail, changes) && (
                    <FieldRow label='Moderators'>
                      <GuestsDiff
                        before={getValueFor<CalendarEventGuest[]>('moderators', bookingDetail, [])}
                        after={getValueFor<CalendarEventGuest[]>('moderators', changes, [])}
                        bookingDetail={bookingDetail}
                        changes={changes}
                      />
                    </FieldRow>
                  )}
                  {hasChangeFor('candidate_location', bookingDetail, changes) && (
                    <FieldRow label='Location'>
                      <LocationDiff before={bookingDetail.candidate_event} after={changes.candidate_event} />
                    </FieldRow>
                  )}
                </div>
              )}
              {hasObserverChanges(changes) && (
                <div>
                  <Text as='h3' h='600' bold mb='6'>
                    Observer settings
                  </Text>
                  {hasChangeFor('observers', bookingDetail, changes) && (
                    <FieldRow label='Observers'>
                      <GuestsDiff
                        before={getValueFor<CalendarEventGuest[]>('observers', bookingDetail, [])}
                        after={getValueFor<CalendarEventGuest[]>('observers', changes, [])}
                        bookingDetail={bookingDetail}
                        changes={changes}
                      />
                    </FieldRow>
                  )}
                  {hasChangeFor('team_location', bookingDetail, changes) && (
                    <FieldRow label='Location'>
                      {bookingDetail.team_event?.conferencing && changes.team_event?.conferencing && (
                        <LocationDiff before={bookingDetail.team_event} after={changes.team_event} />
                      )}
                    </FieldRow>
                  )}
                  {hasChangeFor('live_stream_enabled', bookingDetail, changes) && (
                    <FieldRow label='Live stream?'>
                      <DiffRow
                        leftSide={
                          getValueFor<boolean>('live_stream_enabled', bookingDetail, false) ? 'Enabled' : 'Disabled'
                        }
                        rightSide={getValueFor<boolean>('live_stream_enabled', changes, false) ? 'Enabled' : 'Disabled'}
                      />
                    </FieldRow>
                  )}
                </div>
              )}
              {hasEmailChanges(bookingDetail, changes) && (
                <div className='pt-20'>
                  <EmailChanges changes={getEmailChanges(bookingDetail, changes)} />
                </div>
              )}
            </div>
          )}
        </div>
      </SlideOut>
    </>
  );
};
