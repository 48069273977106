import { useEffect } from 'react';

import { SOURCE_STREAM_EXT, STREAM_VALID_PLAY_TYPE } from '../constants';
import { attachHlsToPlayer, getSourceExtension } from '../utils';

interface Params {
  player?: HTMLVideoElement;
  src?: string;
}

export const useVideoPlayerHls = ({ player, src }: Params) => {
  useEffect(() => {
    if (!player || !src) return;

    let hls: any;
    // streaming url not supported on current browser
    if (getSourceExtension(src) === SOURCE_STREAM_EXT && !player.canPlayType(STREAM_VALID_PLAY_TYPE)) {
      hls = attachHlsToPlayer(player, src);
    } else {
      player.src = src;
    }

    return () => {
      if (hls) hls.destroy();
    };
  }, [player, src]);
};
