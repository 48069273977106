import { getBookabilitySignificantErrorLevel } from '@components/shared/BookabilityAlerts/utils';
import { StepperProps } from '@components/shared/Stepper';
import { compact } from '@components/utils';

export type StudyDraftStep =
  | 'plan'
  | 'screener'
  | 'recruiting'
  | 'task'
  | 'unmoderated'
  | 'calendar'
  | 'survey'
  | 'signup_form'
  | 'review';

export const STEP_HREFS: Record<StudyDraftStep, string> = {
  plan: '/plan',
  screener: '/screener',
  recruiting: '/recruiting',
  task: '/task',
  unmoderated: '/test',
  calendar: '/calendar',
  survey: '/survey',
  signup_form: '/survey',
  review: '/review'
};

export const STEP_LABELS: Record<StudyDraftStep, string> = {
  plan: 'Plan',
  screener: 'Screener',
  recruiting: 'Recruiting',
  calendar: 'Calendar',
  task: 'Task',
  unmoderated: 'Test',
  signup_form: 'Signup form',
  survey: 'Survey',
  review: 'Review'
};

type Step = StepperProps['steps'][0];

export const RULES_PER_STEP: Partial<Record<StudyDraftStep, BookabilityRule[]>> = {
  calendar: [
    'invalid_call_location',
    'moderators_need_calendar',
    'moderators_need_conferencing',
    'moderators_need_zoom_local_recording_setting',
    'date_range_in_past',
    'needs_availability',
    'needs_slots'
  ],
  survey: ['no_survey_questions'],
  screener: ['no_screener_questions']
};

export const getStudyDraftSteps = (study: Study, planOptions: string[]): StudyDraftStep[] =>
  compact([
    'plan',
    planOptions.includes('screener') && 'screener',
    planOptions.includes('external_candidates') && 'recruiting',
    study.style === 'online_task' && 'task',
    study.style === 'unmoderated_test' && 'unmoderated',
    study.style === 'video_call' && 'calendar',
    study.style === 'survey' && 'survey',
    study.style === 'panel' && 'signup_form',
    study.state === 'draft' && 'review'
  ]);

interface BuildSteps {
  steps: Step[];
  currentStep: Step | undefined;
  nextHref: string;
}
export const buildSteps = (
  baseUrl: string,
  steps: StudyDraftStep[],
  bookability: Bookability,
  currentPath: string
): BuildSteps => {
  const stepperSteps: Step[] = steps.map((s, i) => ({
    name: STEP_LABELS[s],
    id: `${i + 1}`,
    status: 'upcoming',
    href: `${baseUrl}/edit${STEP_HREFS[s]}`
  }));

  const currentStep = stepperSteps.find((step) => currentPath.includes(step.href));
  // this loop does two things: figure out what the link to the next step is,
  // and color all the finished steps bold up until the current
  // one (like in the designs)
  let nextHref;
  for (let i = 0; i < stepperSteps.length; i++) {
    const crumb = stepperSteps[i];
    const draftStep = steps[i];
    if (crumb === currentStep) {
      crumb.status = 'current';
      nextHref = stepperSteps[i + 1]?.href;
      break;
    }
    crumb.status = 'complete';

    const significantLevel = getBookabilitySignificantErrorLevel(bookability, RULES_PER_STEP[draftStep] || []);
    if (significantLevel) {
      crumb.status = significantLevel;
    }
  }

  return {
    steps: stepperSteps,
    currentStep,
    nextHref
  };
};
