import * as React from 'react';
import { useState } from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { DropdownCombobox, DropdownItem, RenderDropdownItem } from '@components/common/DropdownCombobox';
import { NewAttrModal } from '@components/shared/NewAttrModal';
import { CaretDownSVG } from '@components/svgs';
import { useOnEscape } from '@components/utils';
import { usePermission } from '@hooks/usePermission';
import { useToaster } from 'components/stores/toaster';

interface Props {
  className?: string;
  disabled?: boolean;
  defaultLabel: string;
  isErrored?: boolean;
  attrs: Attr_[];
  value: string;
  onChange: (value: string) => Promise<void>;
}

const ComboboxItem: RenderDropdownItem = (item, isHighlighted) => (
  <div className={`flex flex-col space-y-1 ${item.disabled ? 'cursor-not-allowed opacity-50' : ''}`}>
    {item?._isNew ? (
      <>
        <span
          className={cn({ 'text-white': isHighlighted, 'text-indigo-600': !isHighlighted })}
        >{`”${item?.value}”`}</span>
        <span className='text-xs'>Save this as a new attribute</span>
      </>
    ) : (
      <span className='text-sm'>{item?.label}</span>
    )}
  </div>
);

const Input = (inputProps: React.HTMLProps<HTMLInputElement>): React.ReactElement => (
  <input
    {...inputProps}
    className='h-8 w-full rounded-md border border-gray-200 px-3 text-sm text-gray-700 placeholder-gray-400'
    type='text'
    placeholder='Select or create…'
    autoFocus
    autoComplete='off'
    name='dropdown_combobox'
    style={{ outline: 'none', outlineOffset: 'none', boxShadow: 'none' }}
  />
);

export const SelectAttribute: React.FC<React.PropsWithChildren<Props>> = ({
  className = '',
  defaultLabel: initialDefaultLabel,
  disabled,
  isErrored,
  attrs,
  value,
  onChange
}) => {
  const showToast = useToaster();
  const [modalOpen, setModalOpen] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const [defaultLabel, setDefaultLabel] = useState(initialDefaultLabel);

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const canCreate = usePermission('manageAttrs')();

  const options = attrs
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
    .map(({ name, label, write_locked }) => ({ label, value: name, disabled: !!write_locked }));

  const selectedItem = options.find((o) => o.value === value);

  const onSelect = (attr: DropdownItem) => {
    setSelecting(false);
    requestAnimationFrame(() => buttonRef.current?.focus());

    if (attr.disabled) {
      showToast({
        heading: 'Locked attribute',
        icon: 'error',
        text: 'This attribute is locked and cannot be selected'
      });

      return;
    }

    if (attr._isNew) {
      setDefaultLabel(attr.value);
      setModalOpen(true);
    } else {
      onChange(attr.value);
    }
  };

  useOnEscape(() => {
    if (!selecting) {
      requestAnimationFrame(() => buttonRef.current?.focus());
    }
  }, [buttonRef.current, selecting]);

  return (
    <div className={cn(className, { 'bg-gray-50': disabled })}>
      {selecting ? (
        <DropdownCombobox
          renderOnBodyRoot
          allowCreate={canCreate}
          unlimitedItems
          items={options}
          adjustablePopper
          syncWidth
          selectedItem={selectedItem}
          placeholder='Select or create…'
          onClickOutside={() => setSelecting(false)}
          onSelect={onSelect}
          renderInput={Input}
          renderItem={ComboboxItem}
        />
      ) : (
        <button
          ref={buttonRef}
          disabled={disabled}
          className={cn(
            'focus:ring-blue focus:outline-none h400 flex h-8 w-full items-center justify-between truncate rounded-md border border-gray-200 px-3 hover:bg-gray-50 focus:ring',
            {
              'cursor-default text-gray-400': disabled,
              'border-red-600': isErrored
            }
          )}
          onClick={() => setSelecting(true)}
        >
          <Text as='span' className='truncate' color={selectedItem ? undefined : 'gray-400'} h='400'>
            {selectedItem?.label || 'Select or create…'}
          </Text>
          <CaretDownSVG className='ml-2 flex-shrink-0' />
        </button>
      )}

      <NewAttrModal
        defaultLabel={defaultLabel}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          requestAnimationFrame(() => buttonRef.current?.focus());
        }}
        onSubmit={async (attr: Attr_) => {
          await onChange(`extra:${attr.name}`);
          requestAnimationFrame(() => buttonRef.current?.focus());
        }}
      />
    </div>
  );
};
