import * as React from 'react';

import cn from 'classnames';
import pluralize from 'pluralize';

import { Button, Pill, Text } from '@components/common';

import { BuildingSvg } from './svgs';

interface Props {
  account: PartialAccount;
  current: boolean;
  onSelect: (id: number) => void;
}

export const Account: React.FC<React.PropsWithChildren<Props>> = ({ account, current, onSelect }) => {
  const disabled = account.status === 'disabled';
  return (
    <li
      className={cn('flex flex-row items-center space-x-5 border border-gray-200 p-8', {
        'opacity-50': disabled
      })}
    >
      {account.logo ? (
        <div className='flex w-16 items-center justify-center'>
          <img alt={`${account.name} logo`} src={account.logo} className='w-block' />
        </div>
      ) : (
        <div className='flex h-14 w-16 items-center justify-center rounded border border-gray-200 bg-gray-50'>
          <BuildingSvg />
        </div>
      )}
      <div className='flex-grow'>
        <div className='mb-1 flex items-center space-x-6'>
          <Text h='700'>{account.name}</Text>
          {account.sandbox && <Pill color='orange'>Demo</Pill>}
        </div>
        {account.workspace_name && (
          <Text h='500' className='-mt-1' mb='1'>
            {account.workspace_name}
          </Text>
        )}
        <Text h='400' className='pb-1.5' color='gray-500'>
          {pluralize('members', account.team.length, true)}. Created by {account.owner.name}
        </Text>
      </div>
      {current ? (
        <Button onClick={() => onSelect(account.id)}>Current</Button>
      ) : (
        <Button primary disabled={disabled} onClick={() => onSelect(account.id)}>
          {disabled ? 'Disabled' : 'Select'}
        </Button>
      )}
    </li>
  );
};
