import * as React from 'react';

import { BookabilityRuleAlert } from './BookabilityRuleAlert';

export const InvalidEmailSenderAlert: BookabilityRuleAlertComponent = ({ level }) => (
  <BookabilityRuleAlert
    level={level}
    heading='Email sender not configured'
    message='To prevent email deliverability issues with participant communications, please contact support@greatquestion.co to get email sending configured on your account.'
    ctas={[]}
  />
);
