import React from 'react';

import { Text } from '@components/common';
import * as svgs from '@components/shared/TableFilters/components/svgs';
import { FilterType } from '@components/shared/TableFilters/types';
import { humanize } from '@components/utils';

import { buildFilterDefinition } from '../TableFilters/builders/buildFIlterDefinitions';

import { CollectionAttribute, PartialCollection } from './types';
import { getGroupFunc } from './utils';

const FILTER_ICONS: Partial<Record<FilterType, React.FC<React.PropsWithChildren<unknown>>>> = {
  number: svgs.number,
  boolean: svgs.boolean,
  date: svgs.date,
  multiple_choice: svgs.multipleChoice
};

const DEFAULTS = {
  pii: false,
  viewable: true,
  editable: false,
  sortable: true,
  groupable: false,
  filterable: true,
  renderValue: (v: any) => (v ? humanize(v) : 'None')
};

// CREATE FULL CONFIG
export function buildCollectionAttribute<D>(attrs: PartialCollection<D>): CollectionAttribute<D> {
  const { id, filter, type, group, ...base } = attrs;
  const root: CollectionAttribute<D> = {
    id,
    type,
    name: type === 'date' ? humanize(id).replace(' at', ' date') : humanize(id),
    Icon: FILTER_ICONS[type] || svgs.text,
    getValue: (record: D) => record[id],
    ...DEFAULTS,
    ...base
  };
  // move to a buildGroup
  const DEFAULT_GROUP = {
    id: id,
    name: root.name,
    options: [],
    func: getGroupFunc<D>(root),
    Header: ({ value }) => {
      return (
        <div className='flex items-center space-x-2'>
          {attrs.Icon ? <attrs.Icon /> : ''}
          <Text bold h='400'>
            {root.renderValue(value)}
          </Text>
        </div>
      );
    }
  };
  return {
    ...root,
    filter: root.filterable
      ? buildFilterDefinition({
          id,
          type,
          name: root.name,
          Icon: root.Icon,
          ...filter
        })
      : undefined,
    group: root.groupable
      ? {
          ...DEFAULT_GROUP,
          ...group
        }
      : undefined
  };
}
