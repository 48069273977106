import * as React from 'react';

import { Basic } from '@components/shared/Skeleton';
import { Circle } from '@components/shared/Skeleton/Circle';

export const InterviewSkeleton = () => {
  return (
    <>
      <div className='desktop:space-x-5 relative flex items-center space-x-3 overflow-x-auto overflow-y-visible border-b border-gray-200 px-6 pb-1'>
        <Basic h='6' width='75px' />
        <Basic h='6' width='85px' />
        <Basic h='6' width='70px' />
        <Basic h='6' width='90px' />
      </div>
      <div className='px-6 py-3'>
        <div className='mb-2 flex items-center space-x-2'>
          <Circle width={20} h='5' />
          <Basic h='6' width='115px' />
        </div>
        <Basic h='24' width='full' />
        <div className='my-2 flex items-center space-x-2'>
          <Circle width={20} h='5' />
          <Basic h='6' width='115px' />
        </div>
        <Basic h='28' width='full' />
        <div className='my-2 flex items-center space-x-2'>
          <Circle width={20} h='5' />
          <Basic h='6' width='115px' />
        </div>
        <Basic h='20' width='full' />
      </div>
    </>
  );
};
