import * as React from 'react';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Dropdown, DropdownLink } from '@components/common';
import { RestrictedAction } from '@components/shared/RestrictedAction';
import { HighlightReelSVG, LightBulbSVG, UploadInterviewSVG } from '@components/svgs';

type Props = {
  studyId?: number | null;
  handleClickNewVideo: () => void;
  handleClickNewInsight?: () => void;
  position?: 'left' | 'right' | 'center';
};

export const HeaderCta: React.FC<React.PropsWithChildren<Props>> = ({
  studyId,
  handleClickNewVideo,
  handleClickNewInsight,
  position
}) => {
  const { data: study, isSuccess } = api.useGetSimpleStudyQuery(studyId as number, { skip: !studyId });

  const [open, setOpen] = useState<boolean>();

  const navigate = useNavigate();

  const canUploadRecordings =
    !studyId || (isSuccess && ['video_call', 'unmoderated_test'].includes(study?.style) && !study.focus_group);

  return (
    <RestrictedAction permission='canCreate'>
      {({ can, may }) => (
        <>
          <Dropdown
            disabled={!can || !may}
            medium
            primary
            text='New'
            isOpen={open}
            onClick={() => setOpen(!open)}
            onClose={() => setOpen(false)}
            menuClassName='w-auto'
            position={position}
          >
            {handleClickNewInsight && (
              <DropdownLink
                disabled={!can || !may}
                onClick={() => {
                  handleClickNewInsight();
                  setOpen(false);
                }}
              >
                <LightBulbSVG className='mr-1 inline h-3.5 w-3.5' />
                New insight
              </DropdownLink>
            )}
            {canUploadRecordings && (
              <RestrictedAction limit='storage'>
                {({ can, may }) => (
                  <DropdownLink
                    disabled={!can || !may}
                    onClick={() => {
                      handleClickNewVideo();
                      setOpen(false);
                    }}
                  >
                    <UploadInterviewSVG className='mr-1 inline' />
                    Upload recordings
                  </DropdownLink>
                )}
              </RestrictedAction>
            )}
            <RestrictedAction feature='highlight_reels' autoShow>
              {({ can, may }) => (
                <DropdownLink
                  disabled={!can || !may}
                  onClick={() => navigate(`/highlight_reels/new${studyId ? `?project_id=${studyId}` : ''}`)}
                  className='whitespace-nowrap'
                >
                  <HighlightReelSVG className='mr-1 inline' />
                  New reel
                </DropdownLink>
              )}
            </RestrictedAction>
          </Dropdown>
        </>
      )}
    </RestrictedAction>
  );
};
