import * as React from 'react';

import cn from 'classnames';
import ReactSkeleton from 'react-loading-skeleton';
import { SkeletonProps } from 'react-loading-skeleton/dist/Skeleton';

interface Props extends SkeletonProps {
  h?: string;
  mb?: string;
  mt?: string;
  className?: string;
  dataTestid?: string;
}

export const Basic: React.FC<React.PropsWithChildren<Props>> = ({
  dataTestid,
  className,
  h = '10',
  mb,
  mt,
  width = 500,
  ...rest
}) => (
  <ReactSkeleton
    duration={1}
    width={width}
    containerTestId={dataTestid}
    className={cn(`rounded-md leading-none h-${h}`, className, {
      [`mt-${mt}`]: mt,
      [`mb-${mb}`]: mb,
      block: !rest.inline
    })}
    {...rest}
  />
);
