import { useState } from 'react';

import { StudyAllocationProps } from '@api/queries';
import { asyncNoop, currencyToSymbol, moneyFormat } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { usePermission } from '@hooks/usePermission';
import { api } from 'api/reduxApi';

import { FundMode, StudyFunder } from '../types';

interface Params {
  study?: Study;
}

type Method = PaymentMethod & { selected?: boolean };

const cardMethod: CardPaymentMethod = {
  kind: 'card',
  processor_id: 'card',
  last4: '',
  brand: ''
};
export const useStudyFunder = ({ study }: Params): StudyFunder => {
  const [allocation, setAllocation] = useState<WalletAllocation>();

  const {
    account: { cards, external_incentives, external_credits }
  } = useAccount();
  const canFund = usePermission('fundStudy')();
  const [methods, setMethods] = useState<Method[]>([]);

  const account_external_credits = external_credits.filter((ec) => !ec.delegatable_type);
  const team_external_credits = external_credits.filter(
    (ec) => ec.delegatable_type === 'Team' && ec.delegatable_id === study?.team_id
  );

  const [createStudyAllocation] = api.useCreateStudyAllocationMutation();
  const [confirmStudyAllocation] = api.useConfirmStudyAllocationMutation();
  const [requestStudyAllocation] = api.useRequestStudyAllocationMutation();

  let mode: FundMode = 'fund';

  if (allocation?.usd_amount_in_cents === 0) {
    mode = 'none';
  } else if (!canFund && !allocation?.is_credit) {
    mode = 'request';
  } else if (allocation?.is_credit) {
    mode = 'refund';
  } else {
    mode = 'fund';
  }

  const selectedMethod = methods.find((m) => m.selected);

  const total: number = allocation?.local_amount_in_cents || 0;
  const displayTotal = allocation ? currencyToSymbol(allocation.local_currency) + moneyFormat(total / 100) : '-';

  const feeAmount: number = allocation?.recruitment_fees || 0;
  const displayFeeAmount = allocation
    ? currencyToSymbol(allocation.local_currency) + moneyFormat(feeAmount / 100)
    : '-';

  const incentivesAmount: number = total - feeAmount;
  const displayIncentivesAmount = allocation
    ? currencyToSymbol(allocation.local_currency) + moneyFormat(incentivesAmount / 100)
    : '-';

  function setUpAllocation(allocation: WalletAllocation) {
    if (!study) return;

    const walletMethod: WalletPaymentMethod = {
      kind: 'wallet',
      processor_id: study.funding.uses_team_wallet ? 'team' : 'account',
      amount_in_cents: study.funding.wallet_balance
    };

    if (!allocation) {
      return;
    }
    if (allocation.usd_amount_in_cents != 0) {
      if (study.funding.uses_team_wallet) {
        const allowDefaultSelected = !team_external_credits.length;
        setMethods([{ selected: allowDefaultSelected, ...walletMethod }, ...team_external_credits]);
      } else if (allocation.is_credit) {
        if (study.funding.is_card_refundable) {
          setMethods([{ selected: true, ...walletMethod }, cardMethod]);
        } else {
          setMethods([{ selected: true, ...walletMethod }]);
        }
      } else if (canFund) {
        const allowDefaultSelected =
          !external_incentives.length && !team_external_credits.length && !account_external_credits.length;
        let newMethods: Method[] = [];
        if (study.funding.wallet_balance >= allocation.usd_amount_in_cents) {
          newMethods = [{ selected: allowDefaultSelected, ...walletMethod }, ...cards];
        } else {
          newMethods = cards.map((c) => ({ selected: c.is_default && allowDefaultSelected, ...c }));
        }
        newMethods = [...newMethods, ...external_incentives, ...team_external_credits, ...account_external_credits];
        setMethods(newMethods);
      }
    }

    setAllocation(allocation);
  }

  async function prepare(props: StudyAllocationProps = {}) {
    if (!study) return false;
    try {
      const allocation = await createStudyAllocation({ id: study.id, allocation: props }).unwrap();
      setUpAllocation(allocation);
      return allocation.usd_amount_in_cents !== 0;
    } catch {
      return true;
    }
  }

  function setMethod(selected: PaymentMethod) {
    const newMethods = methods.map((method) =>
      method.processor_id === selected.processor_id ? { ...method, selected: true } : { ...method, selected: false }
    );
    setMethods(newMethods);
  }

  async function process(props: StudyAllocationProps = {}) {
    if (!allocation) return;
    if (!study) return;

    switch (mode) {
      case 'fund':
      case 'refund':
        try {
          await confirmStudyAllocation({
            id: allocation.id,
            allocation: {
              method: selectedMethod?.kind as any,
              processor_id: selectedMethod?.processor_id,
              study_id: study.id,
              usd_amount_in_cents: allocation?.usd_amount_in_cents as any,
              ...props
            }
          }).unwrap();
          return 'funded';
        } catch {
          return 'error';
        }

      case 'request':
        try {
          await requestStudyAllocation({
            id: allocation.id,
            allocation: { study_id: study.id, ...props }
          }).unwrap();
          return 'requested';
        } catch {
          return 'error';
        }
      default:
        await asyncNoop();
        return 'funded';
    }
  }

  return {
    total,
    displayTotal,
    incentivesAmount,
    displayIncentivesAmount,
    feeAmount,
    displayFeeAmount,
    mode,
    selectedMethod: selectedMethod as any,
    methods,
    selectMethod: setMethod,
    currency: allocation?.local_currency || 'USD',
    isUSD: !allocation || allocation?.local_currency === 'USD',
    allocation,
    prepare,
    process
  };
};
