import * as React from 'react';

import cn from 'classnames';

import { Text } from '@components/common';
import { useProfileSlideoutNavigate } from '@components/RepoSessionApp/ProfileSlideout/routerContext';
import { ChevronRightSVG } from '@components/svgs';

import { CalendarEventSVG, ReplacedCalendarEventSVG } from '../components';
import { formatShortDate, formatTime } from '../utils';

export const EVENT_TYPE_LABELS: Record<CalendarEvent['event_type'], string> = {
  candidate: 'Candidate',
  team: 'Observer'
};

export const timeRangeStr = (calendarEvent: CalendarEvent): string =>
  `${formatTime(calendarEvent.start_at)} - ${formatTime(calendarEvent.end_at)}`;

type Props = {
  calendarEvent: CalendarEvent;
};

export const CalendarEventLink: React.FC<React.PropsWithChildren<Props>> = ({ calendarEvent }) => {
  const navigate = useProfileSlideoutNavigate();

  return (
    <button
      aria-label={calendarEvent.title}
      className='flex w-full border-0 text-left text-gray-700 hover:text-indigo-600'
      onClick={() => {
        navigate(`calendar_events/${calendarEvent.id}`);
      }}
    >
      <div className='mr-2 flex-shrink-0'>
        {calendarEvent.discarded_at ? <ReplacedCalendarEventSVG /> : <CalendarEventSVG />}
      </div>
      <div className='w-full overflow-auto'>
        <div className='mb-1 flex w-full flex-1 items-center space-x-2'>
          <Text
            className={cn('flex-1', {
              'line-through': calendarEvent.discarded_at
            })}
            h='400'
            bold
            truncate
          >
            {calendarEvent.title}
          </Text>
          <ChevronRightSVG />
        </div>
        <Text
          h='400'
          color='gray-500'
          className={cn('flex items-center space-x-2', { 'line-through': calendarEvent.discarded_at })}
        >
          <span>{EVENT_TYPE_LABELS[calendarEvent.event_type]}</span>
          <span>•</span>
          <span>{formatShortDate(calendarEvent.start_at)}</span>
          <span>•</span>
          <span>{timeRangeStr(calendarEvent)}</span>
        </Text>
      </div>
    </button>
  );
};
