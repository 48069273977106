import { useContext } from 'react';

import { useDispatch } from 'react-redux';

import { updateSubscriptionSeats } from '@api/queries';
import { context } from '@stores/account';
import { api } from 'api/reduxApi';

type UsePlanHook = Pick<WhoamiPlan, 'key' | 'name' | 'id' | 'costs' | 'limits' | 'interval' | 'features'> & {
  getQuota: (key: PlanLimit) => number;
  hasQuota: (key: PlanLimit, value?: number) => boolean;
  hasFeature: (feature: PlanFeature) => boolean;
  refresh: () => void;
  buySeats: (amount: number) => Promise<boolean>;
  subscribed: boolean;
};

export function usePlan(): UsePlanHook {
  const { plan } = useContext<AccountContext>(context);

  const dispatch = useDispatch();

  function getQuota(key: PlanLimit): number {
    if (!plan.quotas) {
      return 0;
    }
    return plan.quotas[key] as any;
  }

  function hasQuota(key: PlanLimit, value = 1): boolean {
    if (!plan.quotas) {
      return false;
    }
    const val = plan.quotas[key];
    return val === undefined || val === null || (plan.quotas[key] as any) >= value;
  }

  function hasFeature(feature: PlanFeature): boolean {
    if (!plan.features) {
      return false;
    }
    return !!plan.features[feature];
  }
  async function buySeats(seats: number): Promise<boolean> {
    const [err, _] = await updateSubscriptionSeats({ seats });
    if (err) {
      return false;
    } else {
      await refresh();
      return true;
    }
  }

  async function refresh() {
    dispatch(api.util.invalidateTags(['Whoami']));
  }

  const { key, id, name, costs, limits, interval, features } = plan;

  return {
    key,
    id,
    name,
    costs,
    limits,
    interval,
    getQuota,
    hasQuota,
    hasFeature,
    refresh,
    buySeats,
    features,
    subscribed: !['free', 'trial'].includes(key)
  };
}
