import { api } from '@api/reduxApi';
import { pick } from '@components/utils';

const synthesisApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudyHighlights: builder.query<DocumentHighlight[], number>({
      query: (id) => `/studies/${id}/highlights`,
      transformResponse: (resp: any) => resp.data.map(({ attributes }) => attributes),
      providesTags: [{ type: 'StudyHighlights', id: 'LIST' }]
    }),
    getStudyHighlightGroups: builder.query<GroupedEntities, number>({
      query: (id) => `/studies/${id}/highlight_groups`,
      providesTags: (q, i, id) => [{ type: 'StudyHighlightGroups', id }]
    }),
    updateStudyHighlightGroups: builder.mutation<void, { study_id: number; groups: GroupedEntities }>({
      query: ({ study_id, groups }) => ({
        method: 'PUT',
        url: `/studies/${study_id}/entity_groups/bulk`,
        body: groups
      }),
      invalidatesTags: (q, i, { study_id: id }) => [{ type: 'StudyHighlightGroups', id }]
    }),
    getStudyEntityGroups: builder.query<EntityGroup[], number>({
      query: (id) => `/studies/${id}/entity_groups`,
      providesTags: () => [{ type: 'EntityGroups' }],
      transformResponse: (resp: any) => resp.data.map(({ attributes }) => attributes)
    }),
    createEntityGroup: builder.mutation<EntityGroup, Partial<EntityGroup>>({
      query: (entity_group) => ({
        method: 'POST',
        url: `/entity_groups`,
        body: { entity_group }
      }),
      invalidatesTags: () => [{ type: 'EntityGroups' }],
      transformResponse: (resp: any) => resp.data.attributes
    }),
    updateEntityGroup: builder.mutation<EntityGroup, { id: number } & Partial<EntityGroup>>({
      query: (entity_group) => ({
        method: 'PUT',
        url: `/entity_groups/${entity_group.id}`,
        body: { entity_group }
      }),
      invalidatesTags: () => [{ type: 'EntityGroups' }]
    }),
    deleteEntityGroup: builder.mutation<void, number>({
      query: (id) => ({
        method: 'DELETE',
        url: `/entity_groups/${id}`
      }),
      invalidatesTags: () => [{ type: 'EntityGroups' }]
    }),
    createOperation: builder.mutation<void, { study_id: number; action_type: 'sort' | 'group'; action_value: string }>({
      query: ({ study_id, action_type, action_value }) => ({
        method: 'POST',
        url: `/studies/${study_id}/highlight_board/operations`,
        body: { action_type: action_type, action_value: action_value }
      }),
      invalidatesTags: (q, i, { study_id: id }) => [{ type: 'EntityGroups' }, { type: 'StudyHighlightGroups', id }]
    })
  })
});

export const {
  useGetStudyHighlightGroupsQuery,
  useUpdateStudyHighlightGroupsMutation,
  useGetStudyEntityGroupsQuery,
  useCreateEntityGroupMutation,
  useUpdateEntityGroupMutation,
  useDeleteEntityGroupMutation,
  useGetStudyHighlightsQuery,
  useCreateOperationMutation
} = synthesisApi;
