import React, { forwardRef, HTMLAttributes } from 'react';

import cn from 'classnames';
import { useInView } from 'react-intersection-observer';

import { Portal } from '@components/Portal';

import * as Icons from '../icons';

import { MenuBarButton } from './MenuBarButton';

export const ScrollToTop = forwardRef<HTMLButtonElement, HTMLAttributes<HTMLButtonElement>>(
  ({ className, ...rest }, ref) => {
    const { ref: anchorRef, inView } = useInView({ initialInView: false });

    return (
      <>
        <Portal target={document.getElementById('gq-editor')}>
          <>
            <div
              ref={anchorRef}
              style={{ top: '100vh', height: `calc(100% - 100vh)` }}
              className='pointer-events-none absolute h-px w-px'
            />
            <MenuBarButton
              ref={ref}
              className={cn(
                'opacity-0 transition-opacity duration-150',
                'sticky bottom-4 right-2 ml-auto mr-4 h-8 w-8 border border-gray-200 bg-white',
                {
                  'pointer-events-none': !inView,
                  'pointer-events-all opacity-100': inView
                },
                className
              )}
              tooltip='Back to top'
              {...rest}
            >
              <Icons.ChevronUp />
            </MenuBarButton>
          </>
        </Portal>
      </>
    );
  }
);
