import * as React from 'react';

import { CellProps } from 'react-table';
import { format as timeagoFormat } from 'timeago.js';

import { Text } from '@components/common';

export const LastUpdated = <T extends AbstractTemplate>({ row }: CellProps<T>) => (
  <Text h='400' className='whitespace-nowrap'>
    {timeagoFormat(row.original.updated_at)}
  </Text>
);
