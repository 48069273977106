import * as React from 'react';

import cn from 'classnames';

import { Select, SelectOption, Text } from '@components/common';
import { CandidateRefreshSVG, NoCircleSVG } from '@components/svgs';

type Props = {
  className?: string;
  disabled?: boolean;
  value: boolean;
  onChangeValue: (value: string) => void;
};

const DESCRIPTIONS = {
  true: 'If candidates already exist, we’ll update their data with data from this import.',
  false: 'If candidates already exist, we won’t update their data with data from this import.'
};

const Icons = {
  update_existing: CandidateRefreshSVG,
  dont_update: NoCircleSVG
};

const OPTIONS: SelectOption[] = [
  {
    label: 'Update existing candidates',
    value: 'true',
    Icon: Icons.update_existing
  },
  {
    label: 'Don’t update existing candidates',
    value: 'false',
    Icon: Icons.dont_update
  }
];

export const SelectUpdateExisting: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChangeValue,
  className,
  disabled
}) => (
  <Select
    disabled={disabled}
    options={OPTIONS}
    className={cn('w-full', className)}
    overflowClass='visible'
    value={String(value)}
    onChange={onChangeValue}
    renderAriaLabel={(option) => option.label}
    renderLabel={(option) => (
      <div className='my-1 flex'>
        <div className='mr-3'>
          {option.value === 'true' && <Icons.update_existing className='h-4 w-4' />}
          {option.value === 'false' && <Icons.dont_update className='h-4 w-4' />}
        </div>
        <div>
          <Text bold mb='2' className='leading-none'>
            {option.label}
          </Text>
          <Text h='400'>{DESCRIPTIONS[option.value]}</Text>
        </div>
      </div>
    )}
  />
);
