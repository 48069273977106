import * as React from 'react';

import { Button, DropdownCombobox } from '@components/common';
import { CloseIcon } from '@components/common/Modal';

import { DropdownComboboxProps } from '../DropdownCombobox';

interface Props extends DropdownComboboxProps {
  values?: string[];
  renderValue: (value: string) => string;
  onDeselect?: (value: string) => void;
}

export const SelectWithSelectedButtons: React.FC<React.PropsWithChildren<Props>> = ({
  values,
  onDeselect,
  renderValue,
  disabled,
  ...rest
}) => {
  return (
    <>
      <DropdownCombobox unlimitedItems resetOnSelect blurOnSelect resetOnBlur disabled={disabled} {...rest} />
      {values && (
        <div className='my-1'>
          {values.map((value) => (
            <Button
              key={value}
              className='my-1 mr-2'
              primary
              outline
              medium
              disabled={disabled}
              onClick={() => onDeselect?.(value)}
            >
              {renderValue(value) || value}
              <CloseIcon className='ml-2' />
            </Button>
          ))}
        </div>
      )}
    </>
  );
};
