import React from 'react';

import pluralize from 'pluralize';

import { Button, Modal, Text } from '@components/common';
import { MAX_CLIPS } from '@components/HighlightReelsApp/consts';

interface Props {
  onClose: () => void;
  existingClips?: number;
  clipsToAdd?: number;
}

export const HighlightsLimitModal: React.FC<React.PropsWithChildren<Props>> = ({
  onClose,
  clipsToAdd,
  existingClips
}) => {
  let modalText;

  if (clipsToAdd && existingClips) {
    modalText = `Sorry, this highlight reel already has ${existingClips} ${pluralize(
      'highlight',
      existingClips
    )} and adding these ${existingClips} ${pluralize(
      'highlight',
      existingClips
    )} exceeds the ${MAX_CLIPS} highlights limit. Reels can only have ${MAX_CLIPS} highlights for performance reasons.`;
  } else {
    modalText = `Sorry, highlight reels can only contain ${MAX_CLIPS} highlights for performance reasons. Please select fewer highlights to add to your reel.`;
  }

  return (
    <Modal
      onClose={onClose}
      size='md'
      icon='danger'
      title='Too many highlights'
      renderFooter={() => (
        <Button primary onClick={onClose}>
          Got it
        </Button>
      )}
    >
      <Text className='text-sm'>{modalText}</Text>
    </Modal>
  );
};
