import * as FigmaPrototypeTypes from '../types';

type Options = Pick<
  FigmaPrototypeTypes.IframeProps,
  'disableDefaultKeyboardNav' | 'hideHotspots' | 'hideUi' | 'nodeId' | 'scaling'
>;

interface PrototypeFileInfo {
  fileKey: string;
  fileName: string;
  nodeId: string | null;
  query: URLSearchParams;
}

const EMBED_URL = 'https://embed.figma.com/proto';
const DEV_CLIENT_ID = 'gQXlxDoUhFCOqHVzei5Sw4';
const PROD_CLIENT_ID = 'IUCdGUrUjdYJSrHZZJ5uUh';

const getClientId = (): string => {
  if (process.env.NODE_ENV === 'development') {
    return DEV_CLIENT_ID;
  } else if (process.env.NODE_ENV === 'test') {
    return 'test-client-id';
  } else {
    return PROD_CLIENT_ID;
  }
};

const extractPrototypeFileInfo = (url: string): PrototypeFileInfo => {
  const parsedUrl = new URL(url);
  const fileKey = parsedUrl.pathname.split('/')[2];
  const fileName = parsedUrl.pathname.split('/')[3];
  const query = parsedUrl.searchParams;
  const nodeId = query.get('starting-point-node-id') ?? query.get('node-id');

  return { fileKey, fileName, nodeId, query };
};

export const parseIframeUrl = (url: string, options: Options): string => {
  const { fileKey, fileName, nodeId: initialNodeId, query } = extractPrototypeFileInfo(url);
  const fullUrl = `${EMBED_URL}/${fileKey}/${fileName}?${query.toString()}`;

  return parsePrototypeUrl(fullUrl, options, initialNodeId ?? '');
};

const parsePrototypeUrl = (url: string, options: Options, initialNodeId: string): string => {
  const { disableDefaultKeyboardNav, hideUi, nodeId, scaling, hideHotspots } = options;

  const parsedUrl = new URL(url);
  const params = parsedUrl.searchParams;

  params.set('client-id', getClientId());
  params.set('embed-host', 'great-question');
  params.set('node-id', initialNodeId);

  if (disableDefaultKeyboardNav) {
    params.set('disable-default-keyboard-nav', '1');
  }

  if (hideUi) {
    params.set('hide-ui', '1');
  }

  if (nodeId) {
    params.set('node-id', nodeId);
  }

  if (scaling) {
    params.set('scaling', scaling);
  }

  if (hideHotspots) {
    params.set('hotspot-hints', '0');
  }

  return `${parsedUrl.origin}${parsedUrl.pathname}?${params.toString()}`;
};
