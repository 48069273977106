import { useEffect, useState } from 'react';

import { parseImageFragment } from '../helpers/parseImageFragment';
import * as Types from '../types';

import { useVideoSeekBarContext } from './useVideoSeekBarContext';

export const useGetImageFragment = (textTrack?: TextTrack): Types.ImageFragment | undefined => {
  const [imageFragment, setImageFragment] = useState<Types.ImageFragment>();

  const {
    state: { hoverTime }
  } = useVideoSeekBarContext();

  useEffect(() => {
    if (textTrack) {
      if (textTrack.mode !== 'hidden') {
        textTrack.mode = 'hidden';
      }

      if (textTrack.cues) {
        const cues = Array.from(textTrack.cues) as VTTCue[];

        for (const cue of cues) {
          const startTime = cue.startTime * 1000;
          const endTime = cue.endTime * 1000;

          if (startTime <= hoverTime && endTime >= hoverTime) {
            setImageFragment(parseImageFragment(cue.text));
          }
        }
      }
    }
  }, [hoverTime, textTrack]);

  return imageFragment;
};
