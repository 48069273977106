import * as React from 'react';

import { Button, Popper, Text } from '@components/common';
import { ChevronDownSVG } from '@components/svgs';

import { Role } from '../TeamUserRow';

interface Props {
  disabled?: boolean;
  studyRole: Role;
}

export const MemberDropdown: React.FC<React.PropsWithChildren<Props>> = ({ children, disabled = false, studyRole }) => {
  return (
    <Popper
      zIndex={50}
      offset={[0, 8]}
      placement='bottom-end'
      renderOnBodyRoot={false}
      closeOnClickOutside
      closeOnEscape
      content={({ closePopper }) => (
        <div className='w-40 overflow-hidden rounded bg-white shadow-lg' onClick={closePopper}>
          {children}
        </div>
      )}
    >
      <Button
        className='m-1 w-32 justify-between'
        aria-label={`Change role, currently ${studyRole}`}
        disabled={disabled}
      >
        <Text>{studyRole}</Text>
        <ChevronDownSVG className='ml-2' />
      </Button>
    </Popper>
  );
};
