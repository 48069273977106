import React, { useEffect, useMemo, useState } from 'react';

import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Control } from 'react-hook-form';

import { Button } from '@components/common';
import { Portal } from '@components/Portal';
import { Enums, getBlockLabel, Models } from '@components/SurveyBuilder';
import { DeleteConfirmationModal } from '@components/Unmoderated/components/CardSortTask/components/DeleteConfirmationModal';
import { TaskHeader } from '@components/Unmoderated/components/TaskHeader';
import { TaskLoadedBlocker } from '@components/Unmoderated/components/TaskLoadedBlocker';
import { shuffle } from '@components/utils';

import { useCardSortTaskTracking } from '../../hooks/useCardSortTaskTracking';
import { useUnmoderatedContext } from '../../hooks/useUnmoderatedContext';
import * as Types from '../../types';
import { CardSortResult, EditCategoryArgs } from '../../types';
import { ResponsiveCard } from '../ResponsiveCard';

import { CardList } from './components/CardList';
import { Categories } from './components/Categories';
import { reorderCards } from './utils';

interface Props {
  block: Models.Block<Enums.Kind.cardSort>;
  control: Control<Types.FormData>;
  fullScreenPortalId: string;
}

export const CardSortTask: React.FC<Props> = ({ control, block, fullScreenPortalId }) => {
  const { blocks, deviceType, topBarUnmoderatedLayout } = useUnmoderatedContext();

  const [confirmModal, setConfirmModal] = useState<CardSortResult | null>(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const [blocker, setBlocker] = useState<boolean>(true);

  const { events, onCreateCategory, onEditCategory, onMoveCard, onDeleteCategory } = useCardSortTaskTracking({});

  const initialCategories = useMemo(() => {
    if (block.blockable.sort_type === 'open') {
      return [];
    }

    const categories = block.blockable.categories.map((name) => ({
      name,
      custom: false,
      cards: [] as string[]
    }));

    return block.blockable.randomise_categories ? shuffle(categories) : categories;
  }, []);

  const [categories, setCategories] = useState(initialCategories);

  const initialCards = useMemo(
    () => (block.blockable.randomise_cards ? shuffle(block.blockable.cards) : block.blockable.cards),
    []
  );

  const [cards, setCards] = useState(initialCards);

  const onCategorySelect = ({
    card,
    newCategory,
    initialCategory,
    categories,
    source,
    destination
  }: {
    source?: DropResult['source'];
    destination?: DropResult['destination'];
    card: string;
    newCategory?: string;
    initialCategory?: string;
    categories: CardSortResult[];
  }) => {
    if (!initialCategory) {
      setCards(cards.filter((c) => c !== card));
    }

    if (!newCategory) {
      setCards([...cards, card]);
    }

    if (!source || !destination) {
      const newCategories = categories.map((c) => {
        if (c.name === initialCategory) {
          return {
            ...c,
            cards: c.cards.filter((c) => c !== card)
          };
        }

        if (c.name === newCategory) {
          return {
            ...c,
            cards: [...(c.cards || []), card] as string[]
          };
        }
        return c;
      });

      onMoveCard({ card, from: initialCategory || null, to: newCategory || null });
      setCategories(newCategories);
      return;
    }

    const withReorderedCards = reorderCards({
      destination,
      source,
      draggableId: card,
      categories
    });

    onMoveCard({ card, from: initialCategory || null, to: newCategory || null });
    setCategories(withReorderedCards);
  };

  const onCategoryCreate = (categories: CardSortResult[]) => {
    onCreateCategory(`Untitled ${categories.length + 1}`);

    const newCategories = [
      ...categories,
      {
        name: `Untitled ${categories.length + 1}`,
        custom: true,
        cards: []
      }
    ];

    setCategories(newCategories);

    return newCategories;
  };

  const deleteCategory = (category: CardSortResult) => {
    if (category.cards.length) {
      setCards([...cards, ...category.cards]);
    }

    const newCategories = categories.filter((c) => c.name !== category.name);

    onDeleteCategory(category.name);
    setCategories(newCategories);
  };

  const onCategoryDelete = (category: CardSortResult) => {
    if (category.cards.length && !confirmModal) {
      setConfirmModal(category);
      return;
    }

    deleteCategory(category);
  };

  const handleOnDragEnd = ({ source, destination, draggableId }: DropResult) => {
    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const newCategories = reorderCards({ destination, source, draggableId, categories });

      setCategories(newCategories);
      return;
    }

    if (destination.droppableId === 'new-category') {
      const newCategories = onCategoryCreate(categories);
      onCategorySelect({
        source,
        destination: { ...destination, droppableId: newCategories[newCategories.length - 1].name },
        card: draggableId,
        newCategory: newCategories[newCategories.length - 1].name,
        categories: newCategories
      });

      return;
    }

    const initialCategory = source.droppableId === 'cards-list' ? undefined : source.droppableId;
    const newCategory = destination.droppableId === 'cards-list' ? undefined : destination.droppableId;

    onCategorySelect({ source, destination, card: draggableId, initialCategory, newCategory, categories });
  };

  const onNameChange = ({ category, previous }: EditCategoryArgs) => {
    onEditCategory({ category, previous });

    const updatedCategories = categories.map((c) => {
      return c.name === previous ? { ...c, name: category } : c;
    });

    setCategories(updatedCategories);
  };

  useEffect(() => {
    control.register('card_sort_task');
  }, [control]);

  useEffect(() => {
    control.setValue('card_sort_task', { events, isComplete: cards.length === 0, results: categories });
  }, [events, cards, categories]);

  const renderActions = () => {
    return (
      <div className='flex w-full flex-1'>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <CardList
            totalCards={block.blockable.cards.length}
            onCategorySelect={onCategorySelect}
            categories={categories}
            onCategoryCreate={onCategoryCreate}
            cards={cards}
          />
          <Categories
            onCategoryCreate={onCategoryCreate}
            onCategorySelect={onCategorySelect}
            onCategoryDelete={onCategoryDelete}
            onNameChange={onNameChange}
            categories={categories}
            canCreate={block.blockable.sort_type === 'open'}
          />
        </DragDropContext>

        {confirmModal && (
          <DeleteConfirmationModal
            onDelete={() => {
              deleteCategory(confirmModal);
              setConfirmModal(null);
            }}
            onClose={() => setConfirmModal(null)}
          />
        )}
      </div>
    );
  };

  const renderFooter = () => (
    <Button
      aria-label='Continue'
      disabled={cards.length !== 0 && block.blockable.require_all_sorted}
      className='btn-custom-brand'
      icon='arrowRight'
      type='submit'
      small
      noStyle
    >
      Continue
    </Button>
  );

  if (deviceType === 'desktop' && topBarUnmoderatedLayout) {
    return (
      <div className='flex h-screen w-full flex-col overflow-hidden bg-white pt-4'>
        <TaskHeader
          setIsMinimized={setIsMinimized}
          isMinimized={isMinimized}
          block={block}
          renderOptions={renderFooter}
        />
        <div className='relative flex w-full flex-1 overflow-hidden bg-gray-50'>
          <div className='flex w-full flex-1 flex-col items-center overflow-auto'>{renderActions()}</div>
          {blocker && (
            <TaskLoadedBlocker
              blockKind={block.kind}
              onBeginTask={() => {
                setBlocker(false);
                setIsMinimized(true);
              }}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <ResponsiveCard
      blockKind={getBlockLabel(block.kind)}
      blockPosition={block.position}
      description={block.description}
      title={block.title}
      totalBlocks={blocks.length}
      renderFooter={renderFooter}
    >
      <Portal target={document.getElementById(fullScreenPortalId)}>
        <div className='absolute flex h-full w-full'>{renderActions()}</div>
      </Portal>
    </ResponsiveCard>
  );
};
