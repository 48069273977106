import * as React from 'react';
// hooks
import { useState } from 'react';

// components
import { ViewAllLink } from '@components/RepositoryApp/components/ViewAllLink';
import { useRenderAvatars } from '@components/RepositoryApp/hooks/useRenderAvatars';
import { InterviewCard } from '@components/shared/InterviewCard';
import { InterviewsZDSIcon } from 'components/CandidatesApp/CandidatesShow/components/InterviewsZDSIcon';
import { useCandidateParticipations } from 'hooks/useCandidateParticipations';

const ZDS: React.FC<React.PropsWithChildren<{ firstName?: string }>> = ({ firstName }) => (
  <div className='flex flex-col items-center py-6'>
    <div className='mx-auto'>
      <InterviewsZDSIcon />
    </div>
    <p className='mt-4 text-gray-500'>We'll show {firstName ? `${firstName}'s` : 'their'} interviews here.</p>
  </div>
);

interface Props {
  candidateId: number;
  firstName: string | null;
}

export const Interviews: React.FC<React.PropsWithChildren<Props>> = ({ candidateId, firstName }) => {
  const [showAllInterviews, setShowAllInterviews] = useState(false);

  const { participations } = useCandidateParticipations(candidateId);

  const interviews = participations?.filter((item) => item.style === 'video_call');

  const { renderAvatars } = useRenderAvatars();

  const count = interviews?.length || 0;

  const interviewsToShow = showAllInterviews ? interviews : interviews?.slice(0, 3);

  if (interviewsToShow?.length === 0 && firstName) return <ZDS firstName={firstName} />;

  return (
    <div className='pb-10'>
      <div className='-mx-3 flex flex-wrap pb-4'>
        {interviewsToShow?.map((interview) => (
          <InterviewCard
            className='m-3'
            key={interview.id}
            interviewAt={interview.interview_at}
            href={`/interviews/${interview.token}`}
            candidateName={interview.name || ''}
            token={interview.token}
            studyTitle={interview.study?.title}
            userIds={[interview.customer_id]}
            renderAvatars={renderAvatars}
            recordingId={interview.recording_id as any}
          />
        ))}
      </div>
      {!showAllInterviews && interviewsToShow && count > interviewsToShow.length && (
        <ViewAllLink count={count} onClick={() => setShowAllInterviews(true)} />
      )}
    </div>
  );
};
