import React, { PropsWithChildren, ReactNode, useEffect } from 'react';

import { DndContext, DragEndEvent } from '@dnd-kit/core';

import { Container } from './components/Container';
import { useDraggableCardContext } from './hooks/useDraggableCardContext';

type Props = {
  boundary?: 'window' | 'parent';
  isMinimized?: boolean;
  startPosition?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'center';
  width: number;
  header?: (isMinimized: boolean) => ReactNode;
  prepend?: (isMinimized: boolean) => ReactNode;
};

export const DraggableCard = ({
  boundary = 'window',
  children,
  isMinimized,
  startPosition = 'center',
  width,
  header,
  prepend
}: PropsWithChildren<Props>) => {
  const { setIsTouched, setCoordinates, setIsMinimized, setStartPosition, setWidth } = useDraggableCardContext();

  const handleOnDragEnd = (event: DragEndEvent) => {
    const { active } = event;
    const rect = active.rect.current.translated;

    if (rect) {
      setCoordinates({ x: rect.left, y: rect.top });
    }

    setIsTouched(true);
  };

  useEffect(() => {
    setIsMinimized(Boolean(isMinimized));
  }, [isMinimized]);

  useEffect(() => {
    setWidth(width);
  }, [width]);

  useEffect(() => {
    if (startPosition) {
      setStartPosition(startPosition);
    }
  }, [startPosition]);

  return (
    <DndContext onDragEnd={handleOnDragEnd}>
      <Container boundary={boundary} header={header} prepend={prepend}>
        {children}
      </Container>
    </DndContext>
  );
};
