import React, { useState } from 'react';

import { Button, Select, SelectOption, Text } from '@components/common';
import { InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

import { getDefaultOperator, transformAnswerValue } from '../../utils';
import { NULL_ANSWER } from '../constants';

import { ValueInput } from './ValueInput';

interface Props {
  field: ScreenerField;
  onSave: (answer: IdealAnswer) => void;
  options: SelectOption<IdealAnswerOperator>[];
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({ field, options, onSave }) => {
  const [answer, setAnswer] = useState<IdealAnswer>({
    value: (field.ideal_answer || {}).value,
    operator: getDefaultOperator(field)
  });

  function handleSave() {
    onSave(answer);
  }
  function handleRemove() {
    setAnswer(NULL_ANSWER);
    onSave(NULL_ANSWER);
  }

  return (
    <div className='min-w-80 max-w-screen rounded-md border border-gray-200 bg-white p-4 shadow-lg'>
      <div className='mb-3 flex items-center space-x-2'>
        <Text bold h='400'>
          Ideal answer
        </Text>
        <Tooltip content='Set the ideal answers for your preferred responses to speed up the screening process.'>
          <InfoCircleIcon className='h-4 w-4' />
        </Tooltip>
      </div>
      <Select<IdealAnswerOperator>
        value={answer.operator || ''}
        options={options}
        onChange={(operator) =>
          setAnswer({
            operator,
            value: transformAnswerValue({
              prev: answer.operator,
              next: operator,
              fieldType: field.field_type,
              value: answer.value
            })
          })
        }
        className='mb-4'
      />
      <ValueInput field={field} answer={answer} onChange={(value) => setAnswer({ ...answer, value })} />
      <div className='mt-6 flex items-center space-x-4'>
        <Button primary onClick={handleSave}>
          Save
        </Button>
        <Button link onClick={handleRemove}>
          Remove
        </Button>
      </div>
    </div>
  );
};
