import { useState } from 'react';

import { api } from 'api/reduxApi';

type UseSelectSubsetReturnType = [
  number[],
  {
    isLoading: boolean;
    setSubset: (subset: Subset) => void;
    reset: () => void;
  }
];

interface UseSelectSubsetArgs {
  query: ServerFilterQuery;
  studyId: number;
  onError?: () => void;
  onSuccess?: (ids: number[]) => void;
}

export const useSelectSubset = ({
  query,
  studyId,
  onError,
  onSuccess
}: UseSelectSubsetArgs): UseSelectSubsetReturnType => {
  const [subsetIds, setSubsetIds] = useState<number[]>([]);

  const [subsetMutation, { isLoading }] = api.useCreateParticipationSubsetMutation();

  const reset = () => setSubsetIds([]);

  const setSubset = async (subset: Subset) => {
    try {
      const { participation_ids } = await subsetMutation({ query, subset, studyId }).unwrap();

      setSubsetIds(participation_ids);

      onSuccess?.(participation_ids);
    } catch (_) {
      reset();
      onError?.();
    }
  };

  return [subsetIds, { isLoading, setSubset, reset }];
};
