import * as React from 'react';
import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Button, Modal, ModalHeading } from '@components/common';
import { useToaster } from '@stores/toaster';

import * as toasts from '../toasts';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  slug: string;
  title: string;
  navigateToInsights?: boolean;
  reload?: () => void;
}
export const DeleteInsightModal: React.FC<React.PropsWithChildren<Props>> = ({
  navigateToInsights = true,
  open,
  onClose,
  onSubmit,
  slug,
  title,
  reload
}) => {
  const [deleteInsight, { isLoading, isSuccess }] = api.useDeleteInsightMutation();
  const showToast = useToaster();
  const navigate = useNavigate();

  const handleClick = async (e) => {
    deleteInsight(slug);
  };

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successDelete(title || 'Untitled insight'));
      onClose();
      if (onSubmit) {
        onSubmit();
      } else if (navigateToInsights) {
        navigate('/insights');
      }
      reload?.();
    }
  }, [isSuccess]);

  if (!open) {
    return null;
  }

  return (
    <Modal icon='danger' onClose={isLoading ? undefined : onClose}>
      <ModalHeading className='mb-2'>Delete insight</ModalHeading>
      <p className='pb-12 text-gray-700'>
        All text, data and sharing links for <span className='font-bold'>{title || 'Untitled insight'}</span> will be
        permanently deleted.
      </p>
      <div className='flex justify-end space-x-4'>
        <Button disabled={isLoading} onClick={onClose}>
          Keep insight
        </Button>
        <Button data-testid='confirm-delete' disabled={isLoading} onClick={handleClick} danger>
          Delete insight
        </Button>
      </div>
    </Modal>
  );
};
