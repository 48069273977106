import React, { FC } from 'react';

import Markdown, { Components, Options } from 'react-markdown';

interface Props extends Options {
  className?: string;
}

const defaultComponents: Components = {
  h1: ({ children }) => <h1 className='mb-2 mt-4 text-xl font-bold'>{children}</h1>,
  h2: ({ children }) => <h2 className='mb-2 mt-4 text-xl font-bold'>{children}</h2>,
  h3: ({ children }) => <h3 className='mb-2 mt-4 text-lg font-bold'>{children}</h3>,
  h4: ({ children }) => <h4 className='mb-2 mt-4 text-base font-bold'>{children}</h4>,
  h5: ({ children }) => <h5 className='mb-2 mt-4 text-base font-bold'>{children}</h5>,
  h6: ({ children }) => <h6 className='mb-2 mt-4 text-sm font-bold'>{children}</h6>,
  strong: ({ children }) => <strong className='font-bold'>{children}</strong>,
  p: ({ children }) => <p className='mb-2'>{children}</p>,
  ul: ({ children }) => <ul className='mb-1 list-disc pl-8 pt-2'>{children}</ul>,
  ol: ({ children }) => <ol className='mb-1 list-decimal pl-8 pt-2'>{children}</ol>,
  li: ({ children }) => <li className='mb-2'>{children}</li>,
  em: ({ children }) => <em className='italic'>{children}</em>,
  hr: () => <hr className='my-4 border border-gray-200' />
};

export const Preview: FC<React.PropsWithChildren<Props>> = ({ children, className, components, ...rest }) => {
  return (
    <div className={className}>
      <Markdown {...rest} components={{ ...defaultComponents, ...components }}>
        {children}
      </Markdown>
    </div>
  );
};
