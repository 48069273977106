import React from 'react';

import { Button, Modal, Text } from '@components/common';

interface Props {
  attrName: string;
  onClose: () => void;
  open: boolean;
  invalidValue: string;
}

export const ErrorModal: React.FC<React.PropsWithChildren<Props>> = ({ attrName, onClose, open, invalidValue }) => {
  if (!open) return null;

  return (
    <Modal
      icon='danger'
      title={`Invalid ${attrName}`}
      size='md'
      onClose={onClose}
      renderFooter={() => (
        <Button onClick={onClose} primary>
          Got it
        </Button>
      )}
    >
      <Text className='mb-1'>
        You entered <span className='font-semibold'>{invalidValue}</span> which is an invalid {attrName} format.
      </Text>
      <Text>
        Please use a valid {attrName} format in order to change the {attrName}.
      </Text>
    </Modal>
  );
};
