import * as React from 'react';
import { useRef, useState } from 'react';

import { Button, Input, Text } from '@components/common';
import { useOnClickOutside } from '@components/utils';

interface Props {
  subsetButtonIsHidden: boolean;
  onSelect: (subset: Subset) => void;
}

const MAX_SUBSET_SIZE = 5000;

export const SelectSubsetDropdown: React.FC<React.PropsWithChildren<Props>> = ({ subsetButtonIsHidden, onSelect }) => {
  const [subset, setSubset] = useState<Subset>({ amount: 5, type: 'top' });
  const [visible, setVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (visible) {
      setVisible(false);
    }
  });

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.stopPropagation();
    e.preventDefault();

    setVisible(!visible);
  };

  const onToggleSubsetType = (v: Subset['type']) => {
    setSubset({ ...subset, type: v });
  };

  const handleSelect = () => {
    setVisible(false);
    onSelect(subset);
  };

  const handleEnter = (e): void => {
    if (e.key === 'Enter') {
      handleSelect();
    }
  };

  const onSubsetAmountChange = (value: string): void => {
    const parsedValue = parseInt(value);
    setSubset({ ...subset, amount: Math.min(parsedValue, MAX_SUBSET_SIZE) });
  };

  if (subsetButtonIsHidden) return null;

  return (
    <div ref={ref} className='inline-block overflow-visible'>
      <Button small link onClick={handleClick} className='mx-4'>
        Select subset
      </Button>

      {visible && (
        <div className='origin-top-0 absolute top-12 z-40 w-60 rounded-md shadow-lg'>
          <div className='relative rounded-md border border-gray-200 bg-white p-4 text-left ring-1 ring-black ring-opacity-5'>
            <Text h='400' bold className='mb-4'>
              Select a specific amount
            </Text>

            <div>
              <div className='relative'>
                <Input
                  autoFocus
                  id='amount'
                  className='mb-6 w-20'
                  name='Number of candidates'
                  ariaLabel='Number of candidates'
                  type='number'
                  max={MAX_SUBSET_SIZE}
                  min={1}
                  value={subset.amount.toString()}
                  onChange={onSubsetAmountChange}
                  onKeyDown={handleEnter}
                />
                <Text className='absolute right-8 top-2 text-gray-500'>candidates</Text>
              </div>
              <div className='mb-4 flex items-center'>
                <Input
                  type='radio'
                  id='top'
                  value='top'
                  className='rounded-full'
                  onChange={onToggleSubsetType}
                  checked={subset.type === 'top'}
                />
                <label htmlFor='top'>
                  <Text h='400' className='ml-2'>
                    Start at the top of the list
                  </Text>
                </label>
              </div>
              <div className='mb-4 flex items-center'>
                <Input
                  type='radio'
                  id='random'
                  value='random'
                  className='rounded-full'
                  onChange={onToggleSubsetType}
                  checked={subset.type === 'random'}
                />
                <label htmlFor='random'>
                  <Text h='400' className='ml-2'>
                    Select in random order
                  </Text>
                </label>
              </div>
            </div>
            <Button primary className='mt-4' onClick={handleSelect}>
              Select
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
