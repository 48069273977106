import React from 'react';

import { useTranslation } from 'react-i18next';

import { Text } from '@components/common';
import { useDeviceType } from '@hooks/useDeviceType';

import { UnsupportedSvg } from '../helpers/UnsupportedSvg';

export const UnsupportedLoomPage = () => {
  const { isMobile } = useDeviceType();
  const { t } = useTranslation('UnsupportedLoomPage');

  return (
    <div className='flex flex-col items-center'>
      <UnsupportedSvg />
      {isMobile ? (
        <>
          <Text mt='6' mb='6' h='700'>
            {t('desktop_title')}
          </Text>
          <Text>{t('desktop_text')}</Text>
        </>
      ) : (
        <>
          <Text mt='6' mb='4' h='700'>
            {t('browser_not_supported')}
          </Text>
          <Text>
            {t('supported_browsers_explanation_text')}
            <ul className='ml-6 mt-4 list-disc space-y-1'>
              <li>Google Chrome v90+</li>
              <li>Microsoft Edge v90+</li>
              <li>Brave v1.22+</li>
            </ul>
          </Text>
        </>
      )}
    </div>
  );
};
