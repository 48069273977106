import * as React from 'react';
import { useState } from 'react';

import { Link } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Dropdown, DropdownLink, Text } from '@components/common';
import { AvatarFromId } from '@components/common/Avatar';
import { PageTitle } from '@components/common/helmets';
import { InsightCoverImage } from '@components/InsightsApp/InsightPage/InsightCoverImage';
import { ArtifactBreadcrumbs } from '@components/RepositoryApp/components/ArtifactBreadcrumbs';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import { EditableHeader } from '@components/shared/EditableHeader';
import { InsightShareAccessDropdown } from '@components/shared/ShareAccess/InsightShareAccessDropdown';
import { TextEditorWrapper } from '@components/shared/TextEditorWrapper';
import { useTiptapFromDocumentId } from '@components/shared/Tiptap';
import { Views } from '@components/shared/Views';
import { BubbleKebabSVG } from '@components/svgs';
import { TagList } from '@components/tags';
import { track } from '@components/tracking';
import { useFeature } from '@hooks/useFeature';
import { usePermission } from '@hooks/usePermission';
import { useToaster } from '@stores/toaster';
import { UploadFileModal } from 'components/shared/UploadFileModal';
import { uploadToS3 } from 'components/shared/uploadToS3';

import { failedUpdate, successUpdate } from '../toasts';
import * as toasts from '../toasts';

import { DeleteInsightModal } from './DeleteInsightModal';

const FileTypesText = () => (
  <div className='flex flex-col items-center'>
    <span className='text-xs'>We support JPEG and PNG files only.</span>
    <span className='text-xs'>Recommended size is 884x264 px.</span>
  </div>
);

interface Props {
  insight: Insight;
}

export const InsightPage: React.FC<React.PropsWithChildren<Props>> = ({ insight }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateInsight, { isLoading }] = api.useUpdateInsightMutation();
  const [updateInsightCoverImage] = api.useUpdateInsightCoverImageMutation();
  const [deleteInsightCoverImage] = api.useDeleteInsightCoverImageMutation();
  const [attachImageOpen, setAttachImageOpen] = useState<boolean>(false);
  const [uploading, setUploading] = useState(false);
  const [saving, setSaving] = useState(false);

  const showToast = useToaster();

  const canEdit = usePermission<Insight>('updateInsight')(insight);
  const canDelete = usePermission<Insight>('deleteInsight')(insight);

  const handleDrop = async (file: File[]) => {
    try {
      setAttachImageOpen(false);
      setUploading(true);
      const signedId = await uploadToS3(file[0]);
      await updateInsightCoverImage({ slug: insight.slug, signed_id: signedId }).unwrap();
      showToast(successUpdate());
    } catch {
      showToast(failedUpdate());
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveImage = async () => {
    try {
      setUploading(true);
      await deleteInsightCoverImage({ slug: insight.slug }).unwrap();
      showToast(successUpdate());
    } catch {
      showToast(failedUpdate());
    } finally {
      setUploading(false);
    }
  };

  const tiptap = useTiptapFromDocumentId({
    documentId: insight.document_id,
    studyId: insight.project_id,
    liveUpdate: true,
    config: {
      import_document: useFeature('import_document'),
      columns: true,
      image: { enable: true },
      link: { enable: true },
      artifacts: true,
      templates: true,
      placeholder: 'Write learnings, embed interviews, and so much more…',
      tables: true
    }
  });

  const handleTitleOrDescChanged = async (mode: 'title' | 'description', value: string) => {
    try {
      setSaving?.(true);
      track(`updated_story_${mode}`, { story_id: insight.id });
      await updateInsight({ slug: insight.slug, [mode]: value }).unwrap();
    } catch {
      showToast(toasts.failedUpdate());
    } finally {
      setSaving?.(false);
    }
  };

  React.useEffect(() => {
    setSaving(tiptap.isLoading ?? false);
  }, [tiptap.isLoading]);

  return (
    <div className='desktop:h-screen h-screen-w-navbar mx-auto flex flex-col'>
      <PageTitle>{insight.title || 'New Insight'}</PageTitle>
      <header className='desktop:px-12 border-b border-gray-200 bg-white px-4 pb-2 pt-4'>
        <ArtifactBreadcrumbs artifactTitle={insight.title || 'New Insight'} study={insight.study} />

        <div className='tablet:flex mb-1 items-start'>
          {canEdit ? (
            <div className='flex-grow'>
              <EditableHeader
                className='w-full truncate'
                placeholder='Insight title...'
                initialValue={insight.title}
                onBlur={(value) => handleTitleOrDescChanged('title', value)}
              />
              <EditableHeader
                mode='description'
                className='mt-4 w-full truncate'
                placeholder='Add description'
                initialValue={insight.description}
                onBlur={(value) => handleTitleOrDescChanged('description', value)}
              />
            </div>
          ) : (
            <div className='flex-grow'>
              <Text h='600' bold>
                {insight.title || 'Untitled insight'}
              </Text>

              {insight.description ? (
                <Text h='400' bold className='mt-4 w-full'>
                  {insight.description}
                </Text>
              ) : null}
            </div>
          )}

          <div className='tablet:ml-10 tablet:justify-end flex items-center space-x-2'>
            <AvatarFromId size='xl' userId={insight.creator_id} />
            <CopyURLButton icon='link' text={window.location.href} />
            {insight.views && <Views views={insight.views} />}
            <InsightShareAccessDropdown insight={insight} readOnly={!canEdit} />
            {canDelete && (
              <Dropdown
                onClick={() => setDropdownOpen(!dropdownOpen)}
                onClose={() => setDropdownOpen(false)}
                isOpen={dropdownOpen}
                hideCaret
                textStyle
                text={<BubbleKebabSVG />}
              >
                <DropdownLink
                  onClick={() => {
                    setDeleteModalOpen(true);
                    setDropdownOpen(false);
                  }}
                >
                  Delete insight…
                </DropdownLink>
              </Dropdown>
            )}
            <DeleteInsightModal
              open={deleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
              slug={insight.slug}
              title={insight.title}
            />
          </div>
        </div>

        <div className='max-w-sm'>
          <TagList
            studyId={insight.project_id}
            tagIds={insight.tag_ids}
            readOnly={!canEdit}
            onChange={(tag_ids) => {
              updateInsight({ ...insight, tag_ids });
              track('saved_tags_to_story', { story_id: insight.id });
            }}
            className='focus:outline-none mt-2 flex h-10 w-full items-center space-x-2 rounded px-4 hover:bg-gray-50 focus:bg-gray-50'
            placement='bottom'
          />
        </div>
      </header>

      <TextEditorWrapper
        saving={saving || isLoading}
        tiptap={tiptap}
        renderCover={() => (
          <InsightCoverImage
            openAttachModal={() => setAttachImageOpen(true)}
            onRemove={handleRemoveImage}
            imageUrl={insight.cover_image_url}
          />
        )}
      />

      <UploadFileModal
        uploading={uploading}
        heading={`${insight.cover_image_url ? 'Change' : 'Add'} cover photo`}
        subheading='Larger images will be automatically cropped to visible size.'
        fileTypesText={<FileTypesText />}
        supportedFileTypes={['png', 'jpeg', 'jpg']}
        open={attachImageOpen}
        onClose={() => setAttachImageOpen(false)}
        onDrop={handleDrop}
      />
    </div>
  );
};
