import React, { useEffect } from 'react';

import pluralize from 'pluralize';
import tinytime from 'tinytime';

import { CardSortResponse } from '@components/ScreenerResults/components';
import { HorizontalBarChart } from '@components/ScreenerResults/ScreenerChart/components/HorizontalBarChart';
import { PieChart } from '@components/ScreenerResults/ScreenerChart/components/PieChart';
import { TextCarousel } from '@components/ScreenerResults/ScreenerChart/components/TextCarousel';
import { VerticalBarChart } from '@components/ScreenerResults/ScreenerChart/components/VerticalBarChart';
import { CopyURLButton } from 'components/shared/CopyURLButton';
import { compact, humanize, removeHash } from 'components/utils';

import { PrototypeTestResponse } from '../components/PrototypeTestResponse/PrototypeTestResponse';
import { TreeTestResponse } from '../components/TreeTestResponse';

import { ChartDataPoint } from './types';

type ChartComponent = React.FC<
  React.PropsWithChildren<{
    data: any[];
    total: number;
  }>
>;

type ChartableType = ScreenerFieldType | Attr_['attr_type'] | 'prototype_test';

const CHARTS_BY_FIELD_TYPE: Record<ChartableType, ChartComponent | null> = {
  info: null,
  start_loom: null,
  stop_loom: null,
  task: null,
  multiple_choice: HorizontalBarChart,
  single_choice: PieChart,
  text: TextCarousel,
  short_text: TextCarousel,
  long_text: TextCarousel,
  free_text: TextCarousel,
  email: TextCarousel,
  website: TextCarousel,
  url: TextCarousel,
  number: VerticalBarChart,
  number_range: PieChart,
  boolean: PieChart,
  yes_no: PieChart,
  date: TextCarousel,
  datetime: TextCarousel,
  location: TextCarousel,
  prototype_test: HorizontalBarChart,
  card_sort: null,
  tree_test: null
};

const dateOfBirth = tinytime('{DD} {MMMM} {YYYY}');

const calcData = (type: ChartableType, answers: any[]): ChartDataPoint[] => {
  let resultObj = {};

  switch (type) {
    case 'multiple_choice':
      resultObj = compact(answers).reduce((data, aa) => {
        const answers = Array.isArray(aa) ? aa : [aa];

        answers.forEach((a) => (data[a] = data[a] ? data[a] + 1 : 1));

        return data;
      }, {});
      break;
    case 'number_range':
    case 'single_choice':
    case 'boolean':
    case 'yes_no':
    case 'prototype_test':
    case 'card_sort':
      resultObj = compact(answers).reduce((data, a) => {
        data[a] = data[a] ? data[a] + 1 : 1;
        return data;
      }, {});
      break;
    case 'number':
      resultObj = compact(answers)
        .map((a) => parseInt(a))
        .sort()
        .reduce((data, a) => {
          data[a] = data[a] ? data[a] + 1 : 1;
          return data;
        }, {});
      break;

    case 'datetime':
    case 'date':
      return answers.map((a) => dateOfBirth.render(new Date(a)));

    default:
      return compact(answers);
  }

  return Object.keys(resultObj)
    .map((v) => ({ x: v, y: resultObj[v] }))
    .reverse();
};

const Title = ({ children }) => <p className='text-lg font-bold text-gray-700'>{children}</p>;

const Subtitle = ({ children }) => <p className='mb-6 text-sm text-gray-500'>{children}</p>;

interface ScreenerChartProps {
  type: ChartableType;
  answers: any[];
  total?: number;
}

export const ScreenerChart: React.FC<React.PropsWithChildren<ScreenerChartProps>> = ({ type, answers, total }) => {
  const Chart = CHARTS_BY_FIELD_TYPE[type];

  const data = calcData(type, answers);
  if (total && total >= answers.length) {
    data.push({ x: 'Blank', y: total - answers.length });
  }

  const dataToDisplay = type == 'number' ? data.reverse() : data;

  const answeredTotal = answers.filter((a) => a).length;
  if (total === 0 || !Chart) {
    return null;
  }
  return <Chart data={dataToDisplay} total={answeredTotal} />;
};

interface ScreenerChartWithTitleProps {
  title: string;
  type: ChartableType;
  answers: ScreenerResponseResultsSummary['answers'];
  id: number;
  blockableId: number | null;
}

export const ScreenerChartWithTitle: React.FC<React.PropsWithChildren<ScreenerChartWithTitleProps>> = ({
  title,
  type,
  answers,
  id,
  blockableId
}) => {
  const total = answers.filter((a) => a).length;

  const subtitle = pluralize('responses', total, true);

  const scrollTo = (id: string) => {
    document.getElementById(id)?.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
  };

  useEffect(() => {
    scrollTo(window.location.hash.substring(1));
    removeHash();
  }, []);

  if (['info', 'start_loom', 'stop_loom', 'task', 'permissions'].includes(type)) return null;

  const renderResponse = () => {
    switch (type) {
      case 'prototype_test':
        return blockableId ? (
          <PrototypeTestResponse
            prototypeTestId={blockableId}
            answers={answers as ScreenerResponseAnswerValue<'prototype_test'>[]}
          />
        ) : null;
      case 'card_sort':
        return blockableId ? (
          <CardSortResponse blockableId={blockableId} answers={answers as ScreenerResponseAnswerValue<'card_sort'>[]} />
        ) : null;
      case 'tree_test':
        return blockableId ? (
          <TreeTestResponse
            title={title}
            blockableId={blockableId}
            answers={answers as ScreenerResponseAnswerValue<'tree_test'>[]}
          />
        ) : null;
      default:
        return <ScreenerChart type={type} answers={answers} total={total} />;
    }
  };

  return (
    <div className='mb-10'>
      <div id={id.toString()} className='mb-1 flex items-center'>
        <Title>{title || 'Untitled question'}</Title>
        <CopyURLButton
          data-testid='copy-url-button'
          className='ml-2'
          small
          link
          icon='link'
          noTippy
          text={`${window.location.href}#${id}`}
          trackEvent='copied_question_link'
        />
      </div>
      <Subtitle>
        {humanize(type)} | {subtitle}
      </Subtitle>
      {renderResponse()}
    </div>
  );
};
