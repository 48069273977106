import React, { useMemo, useRef, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { api } from '@api/reduxApi';
import { Text } from '@components/common';
import { ImperativeMethods, SelectDropdown } from '@components/shared/SelectDropdown';
import { bigIntToString } from '@components/shared/TableFilters/utils/bigIntToString';
import { CloseSVG } from '@components/svgs';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';

import { DropdownProps } from '../../types';

type Props<D = any> = DropdownProps<D, string[]>;

const valueMapper = (value: string) => {
  const formatted = typeof value === 'string' ? bigIntToString(value) : value;

  return { label: formatted, value: formatted };
};

export const MultipleChoice: React.FC<React.PropsWithChildren<Props>> = ({
  values,
  value,
  onChange,
  operator,
  definition
}) => {
  const { allAttrs } = useCandidateAttrs();
  const [query, setQuery] = useState<string>('');
  const controlRef = useRef<ImperativeMethods>(null);

  const candidateAttr = useMemo(() => {
    const idWithoutPrefix = definition.id.replace(/^customer:/, '');
    return allAttrs.find(({ lookup }) => lookup === idWithoutPrefix);
  }, [allAttrs, definition.id]);

  const { data, isFetching } = api.useGetCandidateAttrsSuggestionsQuery(
    { id: candidateAttr?.id, q: query },
    { skip: !candidateAttr || !!values, refetchOnMountOrArgChange: true }
  );

  const selected = useMemo(() => (Array.isArray(value) ? value?.map(valueMapper) : undefined), [value]);

  const options = useMemo(() => {
    return values ? values.map(valueMapper) : data?.map(valueMapper);
  }, [data, values]);

  const { callback } = useDebouncedCallback((value) => {
    setQuery(value);
  }, 500);

  const handleRemoveItem = (valueToRemove: string) => {
    const filtered = value?.filter((v) => valueToRemove !== v);
    onChange({ value: filtered });

    if (controlRef.current) {
      controlRef.current.setValue(filtered?.map(valueMapper) ?? []);
    }
  };

  return (
    <div className='w-60'>
      <SelectDropdown
        options={options ?? []}
        value={selected ?? []}
        loading={isFetching}
        controlRef={controlRef}
        onInputValueChange={(value) => callback(value)}
        onChange={(newValue) => onChange({ value: newValue.map(({ value }) => value) })}
        overrides={{
          Input: {
            props: {
              autoFocus: true
            }
          }
        }}
        multi
        defaultIsOpen
      />
      <div className='pt-4'>
        {selected?.map((v, i) => (
          <div className='mb-1 flex flex-row items-center justify-between' key={i}>
            <Text h='400' truncate>
              {v.label}
            </Text>
            <button onClick={() => handleRemoveItem(v.value)}>
              <CloseSVG className='h-3 w-3 text-indigo-600' />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
