import React from 'react';

export const List: React.FC<React.PropsWithChildren<{ mt?: string; mb?: string }>> = ({ mt, mb, children }) => {
  let cn = 'ml-6 space-y-1 list-disc';
  if (mt) {
    cn += ` mt-${mt}`;
  }
  if (mb) {
    cn += ` mb-${mb}`;
  }

  return <ul className={cn}>{children}</ul>;
};
