export const GQ_PORTAL_ID = 'gq_portal';

export const getPortalTarget = (): HTMLElement => {
  const element = document.getElementById(GQ_PORTAL_ID);

  if (element) {
    return element;
  } else {
    const root = document.createElement('div');
    root.id = GQ_PORTAL_ID;

    document.body.appendChild(root);

    return root;
  }
};
