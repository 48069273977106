import React, { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Alert, Text } from '@components/common';
import { WindowLayout, WindowLayoutBody } from '@components/layouts/WindowLayout';
import { Highlight } from '@components/shared/Highlight';
import { useCreateMuxPlaybackUrlsMutation } from '@components/shared/Tiptap/api';
import { ErrorSvg, PlayButtonSVG, VideoSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { noop } from '@components/utils';
import Player, { updateClipsTime, UseVideoPlayer } from '@components/VideoPlayer';
import { useAccount } from '@hooks/useAccount';
import { useUser } from '@hooks/useUser';
import { useToaster } from '@stores/toaster';

import { HighlightReelsContext } from '../../hooks/useHighlightReelsContext';
import * as toasts from '../../toasts';

import { UnstitchedReelPlayer } from './components/UnstitchedReelPlayer';
import { Header, Skeleton } from './components';

const POLLING_INTERVAL = 1000;

export const Show = () => {
  const [playbackId, setPlaybackId] = useState<string>();
  const [muxUrls, setMuxUrls] = useState<MuxPlaybackUrls>();
  const videoPlayerRef = useRef<UseVideoPlayer | null>(null);

  const { token = '' } = useParams<{ token: string }>();
  const { id: userId } = useUser();
  const { getUserById } = useAccount();
  const showToast = useToaster();

  const {
    data: highlightReel,
    isError,
    isLoading
  } = api.useGetHighlightReelQuery(token, {
    pollingInterval: playbackId ? undefined : POLLING_INTERVAL,
    skip: !token
  });

  const muxStatus: MuxVideo['status'] = highlightReel?.mux_video?.status ?? 'preparing';
  const clips = highlightReel?.clips ?? [];
  const artifactIds = clips.map((c) => `Clip_${c.id}`);

  const { data: artifacts = [] } = api.useGetArtifactsQuery(artifactIds, {
    skip: !artifactIds
  });

  const [createMuxUrls] = useCreateMuxPlaybackUrlsMutation();

  const chapters = updateClipsTime<Clip>(clips).map((clip) => ({
    from: clip.from,
    metadata: { title: clip.title || 'Untitled clip' }
  }));

  useEffect(() => {
    if (highlightReel) {
      track('viewed_highlight_reel', {
        layout: 'page',
        artifact_type: 'highlight_reel',
        token: highlightReel.token,
        is_creator: userId === highlightReel.creator_id,
        is_guest: false,
        sample: false
      });
    }
  }, [!!highlightReel]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedGet());
    }
  }, [isError]);

  useEffect(() => {
    if (highlightReel && highlightReel.mux_video) {
      if (highlightReel.mux_video.status === 'ready') {
        setPlaybackId(highlightReel.mux_video.playback_id);
      }
    }
  }, [highlightReel]);

  useEffect(() => {
    if (playbackId) {
      (async () => {
        try {
          const urls = await createMuxUrls({ playbackId: playbackId }).unwrap();
          setMuxUrls(urls);
        } catch {
          showToast(toasts.failedGet());
        }
      })();
    }
  }, [playbackId]);

  const renderVideoPlaceholder = () => {
    if (clips.length === 0) {
      return (
        <div className='absolute inset-0 flex flex-col items-center justify-center text-white'>
          <VideoSVG className='mb-2 h-7 w-7' />
          <Text className='text-xs' color='white'>
            Video highlight reel will appear once highlights are added.
          </Text>
        </div>
      );
    }

    if (muxStatus === 'errored') {
      return (
        <div className='absolute inset-0 flex flex-col items-center justify-center text-white'>
          <ErrorSvg className='mb-2 h-7 w-7' />
          <Text className='text-xs' color='white'>
            Video error
          </Text>
        </div>
      );
    }

    return null;
  };

  const renderVideoPlayer = () => {
    if (muxStatus === 'ready' && muxUrls) {
      return (
        <Player
          ref={videoPlayerRef}
          src={muxUrls.stream_url}
          storyboardUrl={muxUrls.storyboard_url}
          chapters={chapters}
        />
      );
    }

    if (muxStatus === 'preparing') {
      return <UnstitchedReelPlayer highlightReelToken={token} artifactIds={artifactIds} chapters={chapters} />;
    }

    return null;
  };

  if (isLoading) {
    return (
      <WindowLayout noSidebar>
        <Skeleton />
      </WindowLayout>
    );
  }

  return (
    <HighlightReelsContext.Provider
      value={{
        highlightReelToken: token,
        setArtifactIds: noop,
        artifactIds: []
      }}
    >
      <WindowLayout>
        <Header
          highlightReel={highlightReel}
          className='desktop:px-12 border-b border-gray-200 bg-white px-4 pb-2 pt-4'
        />
        <WindowLayoutBody className='h-full'>
          <div className='py-gutter mx-auto w-full max-w-3xl'>
            {muxStatus === 'errored' && (
              <Alert className='mb-6' type='error' heading='There was an error creating the highlight reel'>
                The clips stitching failed. We're looking into what could have caused it. Try creating a new highlight
                reel.
              </Alert>
            )}

            {muxStatus === 'preparing' || muxStatus === 'ready' ? (
              <div className='relative h-full w-full'>
                <div className='absolute right-4 top-4 z-10 text-sm text-white'></div>
                <div className='mb-6 w-full border border-gray-200 bg-white'>{renderVideoPlayer()}</div>
              </div>
            ) : (
              <section className='group relative h-96 bg-gray-700'>{renderVideoPlaceholder()}</section>
            )}

            <div className='space-y-4 rounded border border-gray-200 bg-white p-4'>
              {artifacts.map((artifact, index) => (
                <Highlight
                  key={artifact.objectID}
                  clip={{
                    created_at: artifact.created_at,
                    from: artifact.from ?? 0,
                    to: artifact.to ?? 0,
                    title: artifact.title,
                    tag_ids: artifact.tag_ids
                  }}
                  creator={getUserById(artifact.owner_id)}
                  renderActions={() => (
                    <div className='flex items-center justify-end space-x-2'>
                      {videoPlayerRef.current && (
                        <div
                          role='button'
                          tabIndex={0}
                          className='focus:outline-none'
                          onClick={() => {
                            if (videoPlayerRef.current) {
                              videoPlayerRef.current.setCurrentTime(chapters[index].from / 1000);
                              videoPlayerRef.current.play();
                            }
                          }}
                        >
                          <PlayButtonSVG className='h-4 w-4 text-indigo-600' />
                        </div>
                      )}
                    </div>
                  )}
                  renderTranscript={() => <Text className='text-sm'>{artifact.body}</Text>}
                />
              ))}
            </div>
          </div>
        </WindowLayoutBody>
      </WindowLayout>
    </HighlightReelsContext.Provider>
  );
};
