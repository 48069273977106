import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { handleHover, handleToggle } from '@components/shared/GridTable/components/utils';

import { OnHover, OnToggle } from '../../types';

type Hook = (setIds?: React.Dispatch<React.SetStateAction<number[]>>) => {
  resetOnClick: () => void;
  handleToggleCheckBox: OnToggle;
  handleMouseOver: OnHover;
  hoveredRows: number[];
};

export const UseRowSelect: Hook = (setIds) => {
  const [hoveredRows, setHoveredRows] = useState<number[]>([]);
  const clicked = useRef<MutableRefObject<any> | null>(null);

  const handleToggleCheckBox: OnToggle = useCallback(
    (props) => {
      handleToggle({ ...props, clicked, setIds });
    },
    [clicked]
  );

  const handleMouseOver: OnHover = useCallback(
    (props) => {
      handleHover({ ...props, clicked, setHoveredRows });
    },
    [clicked]
  );

  // change back the color of the checkboxes on shift key up
  useEffect(() => {
    const keyupHandler = (event) => {
      if (event.key === 'Shift' && event.type === 'keyup' && hoveredRows.length) {
        setHoveredRows([]);
      }
    };
    window.addEventListener('keyup', keyupHandler);

    return () => {
      window.removeEventListener('keyup', keyupHandler);
    };
  }, [hoveredRows]);

  const resetOnClick = useCallback((): void => {
    if (clicked.current) {
      clicked.current = null;
    }
  }, []);

  return {
    resetOnClick,
    handleToggleCheckBox,
    handleMouseOver,
    hoveredRows
  };
};
