import React from 'react';

import { ActivitiesZDSSVG } from '@components/svgs';

export const ActivitiesZDS: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div className='rounded-md border border-gray-200 bg-white p-10'>
    <div className='flex flex-grow flex-col items-center'>
      <ActivitiesZDSSVG />
      <h3 className='h600 mb-2 mt-4 font-bold'>No activity yet</h3>
      <p className='h500 text-gray-500'>We’ll show the activity of you, your teammates and participants.</p>
    </div>
  </div>
);
