import React, { forwardRef, HTMLAttributes, useMemo, useState } from 'react';

import { Modal } from '@components/common';
import { Table } from '@components/shared/Table';
import { PrototypeTestType } from '@components/SurveyBuilder/types/models';

import { getTableColumnDefs } from './helpers/getTableColumnDefs';
import { ScreensPreview } from './ScreensPreview';

const GOAL_BASED_COLUMN_ORDER = ['participation_name', 'time_in_task', 'completed', 'miss_clicks', 'path'];
const OPEN_ENDED_COLUMN_ORDER = ['participation_name', 'time_in_task', 'clicks', 'path'];

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  prototypeTestId: number;
  answers: NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>[];
  testType?: PrototypeTestType;
}

export const ResponsesTable = forwardRef<HTMLDivElement, Props>(({ answers, prototypeTestId, testType }, ref) => {
  const [activePath, setActivePath] = useState<string[]>([]);
  const [activeClicks, setActiveClicks] = useState<PrototypeClick[]>([]);

  const handleOnModalClose = () => {
    setActivePath([]);
    setActiveClicks([]);
  };

  const columns = useMemo(
    () => getTableColumnDefs({ prototypeTestId, setActivePath, setActiveClicks, testType }),
    [prototypeTestId, setActivePath, setActiveClicks, testType]
  );

  return (
    <div ref={ref} className='relative max-w-full overflow-auto'>
      <Table
        columnPinning={{ left: ['participation_name'] }}
        data={answers}
        columns={columns}
        columnOrder={testType === 'goal-based' ? GOAL_BASED_COLUMN_ORDER : OPEN_ENDED_COLUMN_ORDER}
        className='w-full table-fixed border border-b-0 border-gray-200 bg-white'
      />
      {activePath.length > 0 && (
        <Modal onClose={handleOnModalClose} size='xl'>
          <ScreensPreview prototypeTestId={prototypeTestId} path={activePath} clicks={activeClicks} />
        </Modal>
      )}
    </div>
  );
});
