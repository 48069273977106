import * as React from 'react';

import { Textarea } from 'components/common';

export const Info: QuestionCardBodyComponent = ({ question: q, onChange }) => {
  return (
    <div className='mt-6'>
      <Textarea
        // className='block w-full px-0 py-0 mb-8 leading-normal text-gray-700 placeholder-gray-400 border-0 outline-none resize-none'
        placeholder='Add information for the candidate…'
        value={q.helper}
        onChange={(value) => onChange({ ...q, helper: value })}
        // onKeyDown={(e) => {
        //   if (e.key === 'Enter' && !e.shiftKey) e.currentTarget.blur();
        // }}
      />
    </div>
  );
};
