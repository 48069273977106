import React, { FC, useEffect, useState } from 'react';

import cn from 'classnames';

import { Spinner } from '@components/common';
import { Models } from '@components/SurveyBuilder';

interface Props {
  block?: Models.Block;
}

export const ImageRenderer: FC<Props> = ({ block }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState<string>();

  useEffect(() => {
    setImageLoaded(false);

    if (block?.image_url) {
      setCurrentImageUrl(block.image_url);
    } else {
      setCurrentImageUrl(undefined);
    }
  }, [block?.image_url]);

  useEffect(() => {
    if (!currentImageUrl) return;

    const img = new Image();

    img.src = currentImageUrl;

    img.onload = () => {
      setImageLoaded(true);
    };

    return () => {
      img.onload = null;
    };
  }, [currentImageUrl]);

  return (
    <>
      {currentImageUrl && (
        <div className={cn('absolute inset-0 flex items-center justify-center', !imageLoaded && 'bg-white')}>
          {imageLoaded ? (
            <img src={currentImageUrl} alt='Question attachment' className='max-h-full max-w-full object-contain' />
          ) : (
            <Spinner className='h-10 w-10' />
          )}
        </div>
      )}
    </>
  );
};
