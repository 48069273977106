import * as React from 'react';

import classNames from 'classnames';
import { format } from 'date-fns';
import { DateObj } from 'dayzed';

import { hasAtLeastOneAvailableSlot } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/utils';

export const DateButton: React.FC<
  React.PropsWithChildren<{
    todayKey: string;
    dateObj: DateObj | '';
    timeslots?: Timeslots;
    weekIndex: number;
    getDateProps: any;
    withDivider?: boolean;
    troubleshootMode?: boolean;
  }>
> = ({ todayKey, dateObj, timeslots, weekIndex, getDateProps, withDivider = false, troubleshootMode }) => {
  if (!dateObj) {
    return (
      <div className={classNames(weekIndex > 0 && withDivider && 'border-custom-brand-secondary border-t', 'py-2')}>
        <span className='mx-auto flex h-8 w-8 items-center justify-center rounded-full'>&nbsp;</span>
      </div>
    );
  }

  const { date, selected } = dateObj;
  const dateKey = format(date, 'yyyy-MM-dd');

  let slots: string[] | undefined;
  if (timeslots) {
    slots = timeslots[dateKey] as string[];
  }

  const selectable = (slots && slots.length > 0 && hasAtLeastOneAvailableSlot(slots)) || (slots && troubleshootMode);

  return (
    <div className={classNames(weekIndex > 0 && withDivider && 'border-custom-brand-secondary border-t', 'py-2')}>
      {selectable && (
        <button
          className={classNames(
            selected && 'bg-custom-brand-button text-white',
            'border-custom-brand border font-bold',
            'focus:outline-none mx-auto flex h-8 w-8 items-center justify-center rounded-full'
          )}
          {...getDateProps({ dateObj })}
        >
          <time dateTime={date.toISOString()}>{format(date, 'd')}</time>
        </button>
      )}
      {!selectable && (
        <span
          className={classNames(
            dateKey >= todayKey ? 'text-custom-brand' : 'text-custom-brand-secondary',
            'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
          )}
        >
          <time dateTime={date.toISOString()}>{format(date, 'd')}</time>
        </span>
      )}
    </div>
  );
};
