import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { api } from '@api/reduxApi';

export type GetTag = (id: number) => Tag | undefined;

interface Context {
  tags?: Tag[];
  tagGroups?: TagGroup[];
  getTag: GetTag;
}

export const context = createContext<Context>({} as Context);
const { Provider: BaseProvider } = context;

export const useTags = (): Context => useContext(context);

export const buildInitialCounts = (tags: Tag[]) =>
  tags.reduce(
    (acc, tag) => {
      if (tag?.id && typeof tag.taggings_count === 'number') {
        acc[tag.id] = tag.taggings_count;
      }
      return acc;
    },
    {} as Record<number, number>
  );

const inSessionPage = () => window.location.pathname.includes('/sessions');

export const Provider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { data: tags } = api.useGetTagsQuery({}, { refetchOnFocus: inSessionPage() });
  const { data: tagGroups } = api.useGetTagGroupsQuery();

  const getTag = useCallback((tagId: any) => tags?.find((tag) => tag.id === tagId), [tags]);

  return <BaseProvider value={{ tags, tagGroups, getTag }}>{children}</BaseProvider>;
};
