import React, { SVGProps } from 'react';

export const AccountingDocumentSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.5 14.5C14.5 14.7652 14.3946 15.0196 14.2071 15.2071C14.0196 15.3946 13.7652 15.5 13.5 15.5H2.5C2.23478 15.5 1.98043 15.3946 1.79289 15.2071C1.60536 15.0196 1.5 14.7652 1.5 14.5V1.5C1.5 1.23478 1.60536 0.98043 1.79289 0.792893C1.98043 0.605357 2.23478 0.5 2.5 0.5H9.586C9.85103 0.500057 10.1052 0.605321 10.2927 0.792667L14.2073 4.70733C14.3947 4.8948 14.4999 5.14897 14.5 5.414V14.5Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.5 5.5H10.5C10.2348 5.5 9.98043 5.39464 9.79289 5.20711C9.60536 5.01957 9.5 4.76522 9.5 4.5V0.5'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.13333 7.72967C4.27771 7.9193 4.46568 8.07133 4.6813 8.17288C4.89693 8.27442 5.13385 8.32249 5.372 8.31301C6.13133 8.31301 6.74733 7.85101 6.74733 7.28167C6.74733 6.71234 6.13333 6.25101 5.37467 6.25101C4.616 6.25101 4 5.78901 4 5.21901C4 4.64901 4.616 4.18767 5.37467 4.18767C5.61284 4.17802 5.84982 4.22601 6.06547 4.32757C6.28113 4.42913 6.46907 4.58125 6.61333 4.77101'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M5.37463 8.3125V8.99983' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M5.37463 3.5V4.18733' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8 10H12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4.5 13H12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
