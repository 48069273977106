import React, { ReactNode } from 'react';

import { Text } from '@components/common';
import { HighlightedText } from '@components/RepositoryApp/components/HighlightedText';
import { HighlightedParagraph } from '@components/shared/Artifact';
import { Interview } from '@components/shared/Artifact/components/Interview';
import { LightBulbSVG } from '@components/svgs';

import { ArtifactHit } from '../types';

interface Options {
  hideHeader?: boolean;
  fallback?: () => ReactNode;
  highlight?: boolean;
  hover?: boolean;
}

export const artifactRenderer = (hit: ArtifactHit, options?: Options) => {
  const { content, cover_image_url, thumbnail_url, stream_url, title, color, kind, _highlightResult, clips } = hit;
  const { fallback, hover, highlight = true } = options || {};

  const matchLevel = _highlightResult?.body?.matchLevel;
  const isMatchingSearch = matchLevel && matchLevel !== 'none';

  const surveyQuestion = hit.body?.substring(0, hit.body?.indexOf('\n'));
  const hitWithoutQuestion = hit.body?.replace(surveyQuestion + '\n', '');
  const surveyAnswer = hitWithoutQuestion?.substring(0, hitWithoutQuestion?.indexOf('\n'));

  const renderContent = (content: HighlightContentElement[]) => {
    const containImages = content.some((c) => c.type === 'image');
    const containText = content.some((c) => c.type === 'text');

    if (!containImages) {
      const text = content.map((c: TextContentElement) => c.text).join(' ');
      return (
        <Text h='500' as='span' className='line-clamp-6 inline overflow-hidden'>
          “{text}”
        </Text>
      );
    }

    if (!containText) {
      return <img src={(content[0] as ImageContentElement).src} alt='' className='h-full w-full object-contain' />;
    }

    const contentAsText = content.map((c, i) => (c.type === 'text' ? c.text : '[image]'));

    return (
      <Text h='500' as='span' className='line-clamp-6 overflow-hidden'>
        “{contentAsText.join(' ')}”
      </Text>
    );
  };

  const renderSurveyResult = () => {
    return (
      <div className='align-center flex h-full flex-col justify-center overflow-hidden'>
        <p className='underline'>{surveyQuestion}</p>
        <p>{surveyAnswer}</p>
      </div>
    );
  };

  switch (kind) {
    case 'Clip':
      return (
        <HighlightedParagraph className='whitespace-pre-wrap' color={color}>
          {isMatchingSearch && highlight ? (
            <HighlightedText hit={hit} attribute='body' />
          ) : (
            hit.body && <span className='line-clamp-6 overflow-hidden'>“{hit.body}”</span>
          )}
        </HighlightedParagraph>
      );
    case 'Highlight':
      return (
        <HighlightedParagraph className='whitespace-pre-wrap' color={color}>
          {isMatchingSearch && highlight ? (
            <HighlightedText hit={hit} attribute='body' />
          ) : content?.length ? (
            renderContent(content)
          ) : (
            hit.body && <span className='line-clamp-6 overflow-hidden'>“{hit.body}”</span>
          )}
        </HighlightedParagraph>
      );
    case 'HighlightReel':
      return (
        <Interview hover={hover} thumbnail={thumbnail_url} streamUrl={stream_url} modalTitle={title} clips={clips} />
      );
    case 'Repo::Session':
      if (isMatchingSearch && highlight) {
        return (
          <HighlightedParagraph className='whitespace-pre-wrap'>
            <HighlightedText hit={hit} attribute='body' />
          </HighlightedParagraph>
        );
      }
      return <Interview hover={hover} thumbnail={thumbnail_url} streamUrl={stream_url} modalTitle={title} />;
    case 'Story':
      return (
        <div className='flex h-full w-full flex-col'>
          {cover_image_url ? (
            <img alt='Cover' className='h-full w-full object-cover' src={cover_image_url} />
          ) : (
            <div className='flex h-full w-full items-center justify-center bg-gray-200'>
              <LightBulbSVG className='h-8 w-8 text-gray-400' />
            </div>
          )}
        </div>
      );
    case 'ScreenerResponse':
      return (
        <HighlightedParagraph className='whitespace-pre-wrap bg-indigo-50' color={color}>
          {isMatchingSearch && highlight ? <HighlightedText hit={hit} attribute='body' /> : renderSurveyResult()}
        </HighlightedParagraph>
      );
    default:
      return fallback ? fallback() : null;
  }
};
