import * as React from 'react';

import { Row } from 'react-table';
// libs
import Tippy from '@tippyjs/react';

// components
import { ParticipationPill } from '@components/shared/ParticipationPill';
import { CalendarWithClockSVG, ClockRefreshSVG } from '@components/svgs';
import { WaitlistedSVG } from 'components/svgs';

import { ParticipationTableItem } from '../../helpers/buildParticipantsColumns';

interface Props {
  row: Row<ParticipationTableItem>;
  onClickReviewTimeProposal: () => void;
}

export const StatusCell: React.FC<React.PropsWithChildren<Props>> = ({ row, onClickReviewTimeProposal }) => {
  return (
    <div className='inline-flex items-center space-x-2 pl-2'>
      <ParticipationPill participation={row.original.participation} />

      {row.original.participation.waitlisted_at && (
        <Tippy content='On waitlist'>
          <span>
            <WaitlistedSVG className='text-yellow-600' aria-label='On waitlist' />
          </span>
        </Tippy>
      )}

      {row.original.participation.reschedule_requested_at && (
        <Tippy content='Sent reschedule request'>
          <span>
            <ClockRefreshSVG className='text-gray-500' />
          </span>
        </Tippy>
      )}

      {row.original.participation.time_proposal && (
        <Tippy content='Proposed alternative times'>
          <button
            className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-gray-100'
            aria-label='Review time proposal'
            name='Review time proposal'
            onClick={onClickReviewTimeProposal}
          >
            <CalendarWithClockSVG className='text-red-600' />
          </button>
        </Tippy>
      )}
    </div>
  );
};
