import * as React from 'react';

import classNames from 'classnames';

export interface TextProps extends React.HTMLProps<HTMLDivElement> {
  h?: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';
  as?: string;
  color?: string;
  mt?: string;
  mb?: string;
  light?: boolean;
  medium?: boolean;
  bold?: boolean;
  italic?: boolean;
  truncate?: boolean;
  uppercase?: boolean;
}

export const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  h = '500',
  color = 'gray-700',
  as = 'div',
  mt,
  mb,
  children,
  className,
  light,
  medium,
  bold,
  italic,
  truncate,
  uppercase,
  ...props
}) => {
  const classes = classNames(
    `h${h}`,
    color !== 'gray-700' && `text-${color}`,
    mb && `mb-${mb}`,
    mt && `mt-${mt}`,
    {
      'font-light': light,
      'font-medium': medium,
      'font-bold': bold,
      italic: italic,
      truncate: truncate,
      uppercase: uppercase
    },
    className
  );

  return React.createElement(as, {
    className: classes,
    children,
    ...props
  });
};
