import * as React from 'react';
import { createContext, FC, useState } from 'react';

import { buildInitialData } from '../utils';

export type SynthesisContext = {
  draggingId: number | null;
  setDraggingId: (id: number | null) => void;
  selectedIds: number[];
  setSelectedIds: (ids: number[]) => void;
  isZDS: boolean;
  setIsZDS: (isZDS: boolean) => void;
  data: GroupedEntities;
  setData: (data: GroupedEntities) => void;
};

export const context = createContext<SynthesisContext>({} as any);

export const SynthesisProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [draggingId, setDraggingId] = useState<number | null>(null);
  const [data, setData] = useState<GroupedEntities>(buildInitialData);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isZDS, setIsZDS] = useState<boolean>(false);

  return (
    <context.Provider
      value={{ draggingId, setDraggingId, data, setData, selectedIds, setSelectedIds, isZDS, setIsZDS }}
    >
      {children}
    </context.Provider>
  );
};
