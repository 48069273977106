import React, { useState } from 'react';

import cn from 'classnames';

import { ChevronDownSVG } from '@components/svgs';
import { GroupByOption } from 'components/shared/CollectionAttributes/types';

interface Props<D> {
  groupBy: string;
  groupByOptions: GroupByOption<D>[];
  records: D[];
  studyAttrs?: Attr_[];
  setSort?: (sort: CollectionView['sort']) => void;
  Table: React.FC<
    React.PropsWithChildren<{
      records: D[];
      groupBy?: string;
      studyAttrs?: Attr_[];
      setSort?: (sort: CollectionView['sort']) => void;
    }>
  >;
}

interface TableGroupProps<D> extends Props<D> {
  func: any;
  option: string;
  GroupHeader: React.FC<React.PropsWithChildren<{ value: string }>>;
  setSort?: (sort: CollectionView['sort']) => void;
}

const TableGroup = <T,>({
  func,
  GroupHeader,
  records,
  option,
  groupBy,
  Table,
  setSort,
  studyAttrs
}: TableGroupProps<T>): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(true);

  const grouped = func(records, option);

  if (!grouped.length) return <></>;

  return (
    <div className='mt-8'>
      <button
        className='focus:outline-none mb-3 flex cursor-pointer flex-nowrap items-center px-4 py-2.5'
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <div className='mr-2'>
          <GroupHeader value={option} />
        </div>
        <ChevronDownSVG
          className={cn('h-4 w-4 transform transition duration-200 ease-in-out', { ['-rotate-90']: !isOpen })}
        />
      </button>

      {isOpen && <Table studyAttrs={studyAttrs} setSort={setSort} records={grouped} groupBy={groupBy} />}
    </div>
  );
};

export const GroupedTable = <D,>(props: Props<D>): React.ReactElement => {
  const { groupBy, setSort, groupByOptions, records, Table } = props;
  // not sure this should actually go here - should it just get the groups?
  if (!groupBy || !groupByOptions.find((option) => option.id === groupBy))
    return <Table setSort={setSort} studyAttrs={props.studyAttrs} records={records} />;

  return (
    <>
      {groupByOptions.map((groupByOption) => {
        if (groupBy !== groupByOption.id || (groupBy === 'focus_groups' && groupByOption.id !== 'video_call'))
          return null;

        return groupByOption.options.map((option) => (
          <TableGroup<D>
            key={option}
            {...props}
            setSort={setSort}
            GroupHeader={groupByOption.Header}
            option={option}
            func={groupByOption.func}
          />
        ));
      })}
    </>
  );
};
