import * as React from 'react';

import cn from 'classnames';

import { getBackgroundColor } from '@components/tags/colors';

type Props = {
  className?: string;
  color?: Tag['color'];
};
export const Swatch: React.FC<React.PropsWithChildren<Props>> = ({ className, color = 'default' }) => (
  <div className={cn('h-4 w-4 rounded', className)} style={{ backgroundColor: getBackgroundColor(color, 0.2) }} />
);
