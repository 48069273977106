import * as React from 'react';

import { Select, SelectOption } from '@components/common';
import {
  AvailiabilityBasedSVG,
  FairnessBasedSVG,
  MultipleActionsSVG,
  MultipleUsersSVG,
  SingleUserSVG
} from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { useFeature } from '@hooks/useFeature';
import { IconLabel } from 'components/common/Select';
import { RestrictedAction } from 'components/shared/RestrictedAction';

type Data = {
  icon: React.ReactElement;
  description: string;
};

const MODERATION_STYLE_OPTIONS: SelectOption<ModerationStyle>[] = [
  {
    label: 'Standard 1:1',
    value: 'single',
    data: { icon: <SingleUserSVG />, description: 'Schedule calls based on the availability of 1 moderator.' }
  },
  {
    label: 'Collective',
    value: 'collective',
    data: { icon: <MultipleActionsSVG />, description: 'Schedule calls based on the availability of multiple people.' }
  },
  {
    label: 'Round robin',
    value: 'round_robin',
    data: {
      icon: <MultipleUsersSVG />,
      description: 'Rotate moderators for each call based on fairness or availability.'
    }
  }
];

const ROUND_ROBIN_STYLE_OPTIONS: SelectOption<RoundRobinStyle>[] = [
  {
    label: 'Availability-based',
    value: 'availability',
    data: {
      icon: <AvailiabilityBasedSVG />,
      description: 'Give participants more times to choose from.'
    }
  },
  {
    label: 'Fairness-based',
    value: 'fairness',
    data: {
      icon: <FairnessBasedSVG />,
      description: 'Ensures moderators conduct the same number of calls.'
    }
  }
];

interface Props {
  study: Study;
  onChangeModerationStyle: (v: ModerationStyle) => void;
  onChangeRoundRobinStyle: (v: RoundRobinStyle) => void;
  renderSelected: (item: SelectOption<ModerationStyle | RoundRobinStyle, Data>) => React.ReactNode;
}

export const ModerationStyleDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  study,
  onChangeModerationStyle,
  onChangeRoundRobinStyle,
  renderSelected
}) => {
  MODERATION_STYLE_OPTIONS[1].disabled = useFeature('virtual_calendar');
  MODERATION_STYLE_OPTIONS[2].disabled = useFeature('virtual_calendar');

  return (
    <>
      <RestrictedAction feature='round_robin' autoShow={false}>
        {({ can, may, show }) => (
          <Select<ModerationStyle, Data>
            options={MODERATION_STYLE_OPTIONS}
            className='w-full'
            ulClassName='divide-y divide-gray-200 xx-moderation-style'
            overflowClass='visible'
            value={study.moderation_style || 'single'}
            renderLabel={(option, highlighted) => {
              if (option.disabled) {
                return (
                  <Tooltip content="Given your current calendar setup doesn't share event data with us, we're unable to schedule based on availability of multiple people">
                    <IconLabel option={option} highlighted={highlighted} />;
                  </Tooltip>
                );
              } else {
                return <IconLabel option={option} highlighted={highlighted} />;
              }
            }}
            renderSelected={renderSelected}
            onChange={(v) => {
              if (v === 'round_robin' && (!can || !may)) {
                show?.();
                return;
              }
              onChangeModerationStyle(v);
            }}
          />
        )}
      </RestrictedAction>
      {study.moderation_style === 'round_robin' && (
        <Select<RoundRobinStyle, Data>
          className='mt-3'
          ulClassName='divide-y divide-gray-200'
          options={ROUND_ROBIN_STYLE_OPTIONS}
          renderLabel={(option, highlighted) => <IconLabel option={option} highlighted={highlighted} />}
          renderSelected={renderSelected}
          value={study.round_robin_style || 'availability'}
          onChange={onChangeRoundRobinStyle}
        />
      )}
    </>
  );
};
