import React, { useEffect, useMemo, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { Alert, InputWithAddons, Select, Text } from '@components/common';
import { useGetStudyCalendarQuery } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/api';
import { EVENT_COLORS } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/NylasEvent';
import { InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { useToaster } from 'components/stores/toaster';
import * as toasts from 'components/StudiesApp/components/StudyDraft/toasts';
import { noop } from 'components/utils';
import { useUser } from 'hooks/useUser';

import { DEFAULT_DURATION_VALUES, DURATION_OPTIONS } from '../../constants';
import { CallModerators } from '../NylasCalendar/CallModerators';
import { Moderator } from '../NylasCalendar/CallModerators/Moderator';
import { getCalendarAlertsForUser } from '../NylasCalendar/CallModerators/utils';
import { VideoURLInput } from '../NylasCalendar/components/VideoURLInput';
import { useStudyUsers } from '../NylasCalendar/hooks/useStudyUsers';

const FormGroup = ({ children }) => <div className='mb-6'>{children}</div>;
const Label: React.FC<React.PropsWithChildren<{ tooltip?: string }>> = ({ children, tooltip }) => (
  <label className='mb-2 flex items-center space-x-2'>
    <Text bold>{children}</Text>
    {tooltip && (
      <Tooltip content={tooltip}>
        <InfoCircleIcon className='h-4 w-4' />
      </Tooltip>
    )}
  </label>
);

interface Props {
  study: Study;
  onBack: () => void;
  readOnly?: boolean;
  onSave: (study: { id: number } & Partial<Study>) => void;
  setConfirmValue: (v: Calendar | null) => void;
  bookability?: Bookability;
}

export const CalBasicSettings: React.FC<React.PropsWithChildren<Props>> = ({
  study,
  onSave,
  onBack,
  readOnly,
  setConfirmValue,
  bookability
}) => {
  const [customDuration, setCustomDuration] = useState<number | undefined>(study.duration_in_minutes || undefined);
  const [showDurationInput, setShowDurationInput] = useState(false);
  const user = useUser();
  const showToast = useToaster();

  const { data: calendar } = useGetStudyCalendarQuery({ studyId: study.id }, { refetchOnMountOrArgChange: true });

  const saveConfig = (patch: { id: number } & Partial<Study>) => {
    if (study.duration_in_minutes && study.duration_in_minutes >= 1) {
      onSave(patch);
    } else {
      showToast(toasts.failedSetDuration());
    }
  };
  const isDraft = study.state === 'draft';
  const studyUsersHook = useStudyUsers({
    studyId: study.id,
    ownerId: study.owner_id as any,
    isRoundRobin: study.moderation_style === 'round_robin'
  });

  const studyUserColors: Record<number, UserColor | undefined> = useMemo(() => {
    const colors: Record<number, UserColor | undefined> = {};

    if (!studyUsersHook.allStudyUsers) return colors;

    studyUsersHook.allStudyUsers.forEach(({ teamUser }, i) => {
      const userColor: UserColor = teamUser.is_phantom
        ? (['gray', 400, 100, false] as UserColor)
        : EVENT_COLORS[i % EVENT_COLORS.length];

      colors[teamUser.id] = userColor;
    });

    return colors;
  }, [studyUsersHook.allStudyUsers]);

  const getDurationValue = (v: number | null) => {
    if (!v) return 'custom';
    if (DEFAULT_DURATION_VALUES.includes(v) && !showDurationInput) return String(v);
    return 'custom';
  };

  useEffect(() => {
    setShowDurationInput(getDurationValue(study.duration_in_minutes) === 'custom');
  }, [study.duration_in_minutes]);

  const anyPhantoms = studyUsersHook.allStudyUsers?.map((m) => m.teamUser)?.some((u) => u?.is_phantom);

  const moderator = studyUsersHook.allStudyUsers?.find((m) => m.studyUser.role === 'moderator');

  const { callback: debouncedSaveConfig } = useDebouncedCallback(saveConfig, process.env.NODE_ENV === 'test' ? 5 : 300);

  function confirmCalendarChange(cal: Calendar) {
    if (isDraft) {
      onSave({ id: study.id, calendar_id: cal.id });
    } else {
      setConfirmValue(cal);
    }
  }

  const onDurationChange = (v: string) => {
    // if user select custom option just render an input with empty value
    if (v === 'custom') {
      setCustomDuration(undefined);
      setShowDurationInput(true);
      return;
    }
    // if user select predefined option save value and hide custom input
    setShowDurationInput(false);
    const attrs: Partial<Study> = { duration_in_minutes: parseInt(v) };

    onSave({ id: study.id, ...attrs });
  };

  const handleCustomDuration = (v: string) => {
    const parsedValue = parseInt(v) ? parseInt(v) : 0;
    setCustomDuration(parsedValue);
    const attrs: Partial<Study> = { duration_in_minutes: parsedValue };
    const toSave = { id: study.id, ...attrs };
    debouncedSaveConfig(toSave);
  };
  return (
    <>
      {study.moderation_style === 'single' && moderator && (
        <>
          <FormGroup>
            <Label>Moderator</Label>
            <Moderator
              studyUserColors={studyUserColors}
              type='moderator'
              disabled={!!readOnly}
              user={moderator.teamUser}
              isOwner
              requireCalendar
              onClickRemove={noop}
              calendarId={moderator.studyUser.calendar_id}
              onChangeCalendar={confirmCalendarChange}
              onClickSwitchToCalendly={study.state === 'draft' ? onBack : undefined}
              inlineErrors={getCalendarAlertsForUser(moderator.teamUser.id, bookability)}
            />
            {moderator.teamUser.nylas_invalid && (
              <div className='pt-3'>
                <Alert type='error'>
                  {moderator.teamUser.name} must re-authorize their {moderator.teamUser.nylas_provider || 'connected'}{' '}
                  calendar.
                </Alert>
              </div>
            )}
          </FormGroup>
        </>
      )}
      {(study.moderation_style === 'collective' || study.moderation_style === 'round_robin') && study.owner && (
        <FormGroup>
          <Label>Moderators</Label>
          <div className='pb-4'>
            <CallModerators
              studyUserColors={studyUserColors}
              studyUsersHook={studyUsersHook}
              study={study}
              type='moderator'
              requireCalendar
            />
          </div>
        </FormGroup>
      )}
      <FormGroup>
        <Label tooltip='Call observers will receive separate, internal calendar invites for each interview that gets scheduled, but they won’t be included on the invite with the participant.'>
          Observers
        </Label>

        <CallModerators
          studyUserColors={studyUserColors}
          disabled={readOnly}
          type='observer'
          requireCalendar={false}
          studyUsersHook={studyUsersHook}
          study={study}
        />
        {anyPhantoms && (
          <Text color='gray-500' h='200'>
            *Some calendars can not be shown.
          </Text>
        )}
      </FormGroup>
      <FormGroup>
        <Label>Location</Label>
        <VideoURLInput
          calendar={calendar}
          disabled={readOnly}
          user={user}
          study={study}
          addDebounce
          onChange={(s) => onSave({ id: study.id, ...s })}
        />
      </FormGroup>
      <FormGroup>
        <Label>Duration</Label>
        <Select
          name='duration'
          disabled={readOnly}
          options={DURATION_OPTIONS}
          value={getDurationValue(study.duration_in_minutes)}
          onChange={onDurationChange}
        />
        {showDurationInput && (
          <InputWithAddons
            disabled={readOnly}
            type='number'
            name='custom_duration'
            className='xx-custom-duration no_arrows mt-2'
            value={customDuration ? customDuration.toString() : ''}
            onChange={handleCustomDuration}
            prefix=''
            placeholder='Enter duration...'
            suffix='minutes'
          />
        )}
      </FormGroup>
    </>
  );
};
