import * as React from 'react';

import { Text, Toggle } from '@components/common';

import { FieldRow } from '../components/FieldRow';

import type { BookingDetailsFormFieldComponentProps } from './types';

export const LiveStreamEnabled: React.FC<BookingDetailsFormFieldComponentProps> = ({
  bookingDetail,
  editMode,
  onChange
}) => {
  const value = bookingDetail.team_event?.live_stream_enabled;

  return (
    <FieldRow label='Live stream?'>
      {!editMode && (
        <div className='overflow-auto py-2.5'>
          <Text h='400'>{value ? 'Enabled' : 'Disabled'}</Text>
        </div>
      )}
      {editMode && (
        <div className='py-2.5'>
          <div className='mb-2'>
            <Toggle
              on={value}
              onToggle={() => {
                onChange({ team_event: { live_stream_enabled: !value } });
              }}
            />
          </div>
          <Text h='400' color='gray-500'>
            {value
              ? 'Enabled: observers will get a private observation room link'
              : 'Disabled: observers will be on the call with the participant and moderator'}
          </Text>
        </div>
      )}
    </FieldRow>
  );
};
