import React, { FC, useCallback, useState } from 'react';

import cn from 'classnames';

import { Button } from '@components/common';
import { Portal } from '@components/Portal';
import { Enums, getBlockLabel, Models } from '@components/SurveyBuilder';
import { TaskHeader } from '@components/Unmoderated/components/TaskHeader';

import { useUnmoderatedContext } from '../hooks/useUnmoderatedContext';

import { ResponsiveCard } from './ResponsiveCard';

interface Props {
  block: Models.Block<Enums.Kind.websiteTest>;
  iframePortalId: string;
}

export const WebsiteTestTask: FC<Props> = ({ block, iframePortalId }) => {
  const { blocks, topBarUnmoderatedLayout, deviceType } = useUnmoderatedContext();

  const [isMinimized, setIsMinimized] = useState(false);

  const isIframable = block.blockable.settings?.open_in_new_tab ? false : block.blockable.is_iframable;

  const openWebsiteInNewTab = () => {
    let { url } = block.blockable;

    if (url) {
      if (!/^https?:\/\//i.test(url)) {
        url = `https://${url}`;
      }

      window.open(url, '_blank');
    }
  };

  const footerRenderer = useCallback(() => {
    return (
      <div className={cn('flex space-x-2', !topBarUnmoderatedLayout && 'items-center')}>
        {!isIframable && (
          <Button
            aria-label='Open website'
            type='button'
            icon='externalLink'
            onClick={openWebsiteInNewTab}
            small
            noStyle
          >
            Open website
          </Button>
        )}

        <Button aria-label='End task' className='btn-custom-brand' type='submit' icon='checkInCircle' small noStyle>
          End task
        </Button>
      </div>
    );
  }, []);

  if (deviceType === 'desktop' && topBarUnmoderatedLayout) {
    return (
      <div className='flex h-screen w-full flex-col overflow-hidden bg-white pt-4'>
        <TaskHeader
          isMinimized={isMinimized}
          setIsMinimized={setIsMinimized}
          block={block}
          renderOptions={footerRenderer}
        />
        <div className='flex w-full flex-1 overflow-hidden bg-gray-50'>
          <div className='flex w-full flex-1 flex-col items-center overflow-auto p-6'>
            {isIframable && (
              <iframe
                src={block.blockable.url ?? ''}
                title='Website task website'
                className='h-full w-full border border-gray-700'
              ></iframe>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <ResponsiveCard
      blockKind={getBlockLabel(block.kind)}
      blockPosition={block.position}
      description={block.description}
      title={block.title}
      totalBlocks={blocks.length}
      renderFooter={footerRenderer}
    >
      {isIframable && (
        <Portal target={document.getElementById(iframePortalId)}>
          <iframe src={block.blockable.url ?? ''} title='Website task website' className='h-full w-full'></iframe>
        </Portal>
      )}
    </ResponsiveCard>
  );
};
